import { UserService } from 'app/shared/services/user.service';
import { AuthService } from 'app/shared/services/auth.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import * as moment from 'moment';
import 'moment/min/locales';
import 'moment-timezone';
import { TranslateService } from "../services/translate.service";

@Pipe({
	name: 'mtmDate',
	pure: false
})
export class MtmDatePipe implements PipeTransform {

	private lang: string
	private value: string
	private dateFormat: string
	private originalValue: string


	constructor(private authService: AuthService, private translateService: TranslateService) {
		this.lang = this.translateService.getLang() as string
		this.value = null
		this.originalValue = null
		this.dateFormat = null
	}

	transform(value: any): string {
		// Get user settings
		let dateFormat = this.authService.getUserSettings('dateFormat');
		if (!dateFormat)
			dateFormat = 'YYYY-MM-DD';

		if (this.dateFormat == dateFormat && this.lang == this.translateService.getLang() && this.value != null && this.value != "" &&
			(this.value == value || this.originalValue != null && this.originalValue != "" && this.originalValue == value)) {
			return this.value;
		} else {
			this.lang = this.translateService.getLang() as string
			this.dateFormat = dateFormat
			this.originalValue = value
		}

		// If Date object
		if (value instanceof Date) {
			this.value = moment(value).locale(this.lang).format(dateFormat);
		}
		else {
			let dateTry = new Date(value);
			if (!dateTry.getTime()) {
				this.value = '';
			} else {
				this.value = moment(value).locale(this.lang).format(dateFormat);
			}
		}

		return this.value

	}

}
