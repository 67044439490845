import { Injectable } from "@angular/core";
import { from, Observable, Subject } from "rxjs";
import { DownloadChunkRecord, DownloadRecord, TransferDb, UploadChunkRecord, UploadRecord } from "./idx-schema";
import { TransferStatus } from "./transfer-status";


@Injectable({
  providedIn: 'root'
})
export class TransferDbManagerService {
  db: TransferDb;
  expiryDays: number = 2;

  constructor() {
    this.db = new TransferDb();
  }

  private getExpiryTime(): number {
    const diff = this.expiryDays * 86400000;
    const dateLimit = +new Date() - diff;
    return dateLimit;
  }

  deleteExpiredTransfers(): Observable<void> {
    const db = this.db;
    const dateLimit = this.getExpiryTime();

    const method = async (): Promise<void> => {
      await db.transaction('rw', db.uploads, db.uploadChunks, db.downloadChunks, db.downloads,
        async () => {
          const uploads = await db.uploads.where('startDate').below(dateLimit).toArray();
          for (const upload of uploads) {
            await db.uploads.where('id').equals(upload.id).delete();
            await db.uploadChunks.where('uploadId').equals(upload.id).delete();
          }
          const downloads = await db.downloads.where('startDate').below(dateLimit).toArray();
          for (const download of downloads) {
            await db.downloads.where('id').equals(download.id).delete();
            await db.downloadChunks.where('downloadId').equals(download.id).delete();
          }
        });
    }

    return from(method());
  }


  getAllUploads(owner: string): Observable<Array<UploadRecord>> {
    const db = this.db;
    const dateLimit = this.getExpiryTime();

    const method = async (): Promise<Array<UploadRecord>> => {
      const uploads = await db.uploads.where('owner').equals(owner)
        .and(upload => upload.startDate >= dateLimit).toArray();
      return uploads.filter((upload: UploadRecord) => {
        return upload.status !== TransferStatus.COMPLETED;
      });
    }

    return from(method());
  }

  getAllDownloads(owner: string): Observable<Array<DownloadRecord>> {
    const db = this.db;
    const dateLimit = this.getExpiryTime();

    const method = async (): Promise<Array<DownloadRecord>> => {
      const downloads = await db.downloads.where('owner').equals(owner)
        .and(download => download.startDate >= dateLimit)
        .toArray();
      return downloads.filter((download: DownloadRecord) => {
        return download.status !== TransferStatus.COMPLETED;
      });
    }

    return from(method());
  }

  getUpload(uploadId: string): Observable<UploadRecord | undefined> {
    const db = this.db;
    const method = async (): Promise<UploadRecord | undefined> => {
      return db.uploads.get(uploadId);
    }

    return from(method());
  }

  getDownload(downloadId: string): Observable<DownloadRecord | undefined> {
    const db = this.db;
    const method = async (): Promise<DownloadRecord | undefined> => {
      return db.downloads.get(downloadId);
    };

    return from(method());
  }

  getUploadChunk(uploadId: string, part: number): Observable<UploadChunkRecord | undefined> {
    const db = this.db;
    const method = async (): Promise<UploadChunkRecord | undefined> => {
      return db.uploadChunks.get({ uploadId, part });
    }
    return from(method());
  }

  getUploadChunks(uploadId: string): Observable<Array<UploadChunkRecord | undefined>> {
    const db = this.db;
    const method = async (): Promise<Array<UploadChunkRecord | undefined>> => {
      return db.uploadChunks.where({ uploadId }).toArray();
    }
    return from(method());
  }

  countUploadChunks(uploadId: string): Observable<number> {
    const db = this.db;
    const method = async (): Promise<number> => {
      return db.uploadChunks.where('uploadId').equals(uploadId).count();
    }
    return from(method());
  }

  //restore blob from indexed db
  getUploadBlob(uploadId: string): Observable<Blob | undefined> {
    const subject = new Subject<Blob | undefined>();

    this.getUploadChunks(uploadId).subscribe((chunks: Array<UploadChunkRecord | undefined>) => {
      if (!chunks || chunks.length === 0) {
        subject.next(undefined);
        subject.complete();
      }
      chunks.sort((c1, c2) => c1.part - c2.part);

      let arr = new Uint8Array(0);
      chunks.forEach(chunk => {
        let merged = new Uint8Array(arr.length + chunk.content.length);
        merged.set(arr);
        merged.set(chunk.content, arr.length);
        arr = merged;
      });
      const buffer = arr.buffer.slice(arr.byteOffset, arr.byteLength + arr.byteOffset);
      subject.next(new Blob([buffer]));
      subject.complete();
    });

    return subject.asObservable();
  }

  getDownloadChunks(downloadId: string): Observable<Array<DownloadChunkRecord | undefined>> {
    const db = this.db;
    const method = async (): Promise<Array<DownloadChunkRecord | undefined>> => {
      return db.downloadChunks.where({ downloadId }).toArray();
    }
    return from(method());
  }

  addUpload(upload: UploadRecord): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.uploads.add(upload);
    };

    return from(method());
  }

  addUploadChunk(chunk: UploadChunkRecord): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.uploadChunks.add(chunk);
    };
    return from(method());
  }

  addDownload(download: DownloadRecord): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.downloads.add(download);
    };
    return from(method());
  }

  addDownloadChunk(chunk: DownloadChunkRecord): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.downloadChunks.add(chunk);
    };

    return from(method());
  }

  updateUpload(uploadId: string, change: any): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.uploads.update(uploadId, change);
    };
    return from(method());
  }

  updateDownload(downloadId: string, change: any): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.downloads.update(downloadId, change);
    }
    return from(method());
  }

  deleteUpload(uploadId: string): Observable<any> {
    const db = this.db;
    const method = async () => {
      return db.transaction('rw', db.uploads, db.uploadChunks, async () => {
          await db.uploads.where('id').equals(uploadId).delete();
          await db.uploadChunks.where('uploadId').equals(uploadId).delete();
      });
    };
    return from(method());
  }

  deleteUploadChunk(uploadId: string, part: number): Observable<any> {
    const db = this.db;
    const method = async () => {
      await db.uploadChunks.where({
        uploadId,
        part
      }).delete();
    };
    return from(method());
  }

  deleteDownload(downloadId: string): Observable<any> {
    const db = this.db;
    const method = async () => {
      return db.transaction('rw', db.downloads, db.downloadChunks, async () => {
        await db.downloads.where('id').equals(downloadId).delete();
        await db.downloadChunks.where('downloadId').equals(downloadId).delete();
      });
    };
    return from(method());
  }

}
