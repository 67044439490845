import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "app/shared/services/project.service";
import { FormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
// import { NGValidators } from "ng-validators";
import { UserService } from "app/shared/services/user.service";
import { RegistrationService } from "app/shared/services/registration.service";
import { NotificationService } from 'app/shared/services/notification.service';

@Component({
	selector: 'mtm-invite-company-project-detail-modal',
	templateUrl: './invite-company-project-detail-modal.component.html',
	styleUrls: ['./invite-company-project-detail-modal.component.scss']
})
export class InviteCompanyProjectDetailModalComponent {
	@Input() selector: 'TEXT' | 'COMPANY_PROJECT_DETAILS' = 'TEXT';
	@ViewChild('mtmProjectCompany') mtmProjectCompany: any;



	constructor(
		public userService: UserService,
		public service: ProjectService,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private registrationService: RegistrationService,
		private notificationService: NotificationService
	) { }

	onSubmitForTEXT() {

	}

	onCompanyProfileSubmit() {
		this.mtmProjectCompany.onSubmit(() => {
			this.onCloseModal();
		})
	}

	onCloseModal() {
		this.activeModal.close();
	}
}
