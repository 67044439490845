<div [ngClass]="cssClass">
	<div class="modal-header">
		<h4 class="modal-title"></h4>
		<button type="button" class="close" aria-label="Close" (click)="onCancelModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<div class="container">
			<div *ngIf="paymentType=='DOWNPAYMENT'" class="col">
				<div class="subtitle final-payment">
					<div>{{'paymentWarningTitle' | translate}}</div>
					<div>{{'dawnPaymentWarningTitle' | translate}}</div>
				</div>
				<div *ngIf="endTimeStatus==false" class="description mt-2">
					{{'downPaymentWarningMesage'| translate}}
					{{ calculateDownPaymentAllowedDay()}}
					{{'downPaymentWarningMessage' | translate}}
					{{this.prodCompany? this.prodCompany.name:''}}
					{{'downPaymentWarningMessage2' | translate}}
				</div>
				<div *ngIf="endTimeStatus==true" class="description mt-2">
					{{'downPaymentAddTimeEndWarningMessage'| translate}}
					<span class="payment-issue">payments@mtm.video</span>
				</div>
				<div *ngIf="endTimeStatus==false" class="row justify-content-center mt-4">
					<button class="btn" (click)="onClickPayHere()">{{'payHere' | translate}}</button>
				</div>
			</div>
			<div *ngIf="paymentType=='FINAL_PAYMENT'" class="col">
				<div class="subtitle final-payment">
					<div>{{'paymentWarningTitle' | translate}}</div>
					<div>{{'finalPaymentWarningTitle' | translate}}</div>
				</div>
				<div *ngIf="endTimeStatus==false" class="description mt-2">
					{{'downPaymentWarningMesage'| translate}}
					{{ calculateFinalPaymentAllowedDay()}}
					{{'finalPaymentWarningMessage' | translate}}
					{{this.prodCompany? this.prodCompany.name:''}}
					{{'finalPaymentWarningMessage2' | translate}}
				</div>
				<div *ngIf="endTimeStatus==true" class="description mt-2">
					{{'finalPaymentAddTimeEndWarningMessage'| translate}}
					<span class="payment-issue">payments@mtm.video</span>
				</div>
				<div *ngIf="endTimeStatus==false" class="row justify-content-center mt-4">
					<button [disabled]="!permissionService.hasAuthority(authority.U)" class="btn"
						(click)="onClickPayHere()">{{'payHere' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>