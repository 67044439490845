import { NgModule } from "@angular/core";
import { SubscriptionUpgradeComponent } from "./layout/upgrade.component";
import { SubscriptionUpgradeRoutingModule } from "./upgrade.route";
import { CommonModule } from "@angular/common";
import { SubscriptionUpgradeSelectPlanComponent } from "./pages/select-plan/select-plan.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "../../shared/services/auth.service";
import { CommonService } from "../../shared/services/common.service";
import { SubscriptionUpgradeProduceComponent } from "./pages/produce/produce.component";
import { SubscriptionUpgradeComparePlanComponent } from "./pages/compare-plan/compare-plan.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SubscriptionUpgradeConfirmPlanComponent } from "./pages/confirm-plan/confirm-plan.component";
import { SubscriptionConfirmDowngradeComponent } from "./pages/confirm-downgrade/confirm-downgrade.component";
import { SubscriptionUpgradePaymentComponent } from "./pages/payment/payment.component";
import { UpgradePlanGuard } from "../core/upgrade-plan.guard";
import { SharedModule } from "app/sub-modules/SharedModule";

@NgModule({
    declarations: [
        SubscriptionUpgradeComponent,
        SubscriptionUpgradeSelectPlanComponent,
        SubscriptionUpgradeProduceComponent,
        SubscriptionUpgradeComparePlanComponent,
        SubscriptionUpgradeConfirmPlanComponent,
        SubscriptionConfirmDowngradeComponent,
        SubscriptionUpgradePaymentComponent
    ],
    imports: [
        SubscriptionUpgradeRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
    ],
    exports: [],
    providers: [
        AuthService,
        CommonService,
        UpgradePlanGuard
    ]
})
export class SubscriptionUpgradeModule {
}

