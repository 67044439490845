<div class="container proposal-container">
	<h5 class="text-left mt-3 pl-3" *ngIf="!project">Project Proposal</h5>
	<h5 class="text-left mt-3 pl-3" *ngIf="project">{{project.name}}</h5>

	<p class="text-left pl-3" *ngIf="proposal && !project">
		{{proposal.invitationProjectName}}
	</p>
	<ul class="list-inline steps" style="display: block;">
		<li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active"
			*ngFor="let link of links; let i = index" [ngClass]="{'step-complete': isComplete(i)}">
			<a href="#" [routerLink]="link.url">
				<i *ngIf="isComplete(i)" class="fa fa-check-circle" aria-hidden="true"></i>
				<div *ngIf="!isComplete(i)" class="step-number">{{i + 1}}</div>
				<span class="text-uppercase">{{link.title}}</span>
			</a>
		</li>
	</ul>
</div>
<router-outlet></router-outlet>