import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';

@Component({
  selector: 'mtm-self-trainer-modal',
  templateUrl: './self-trainer-modal.component.html',
  styleUrls: ['./self-trainer-modal.component.scss']
})
export class SelfTrainerModalComponent implements OnInit, AfterViewInit {

  @Input() stepData: any = {};

  modalLeft: string = '';
  modalTop: string = '';
  arrowLeft: string = '';
  arrowTop: string = '';
  arrowRotate: string = '0deg';
  modalContainerRect: any;
  heightVariable: number = 1.5;
  windowWidth: number = 0;
  windowHeight: number = 0;
  arrowPopup: any;
  modalPopup: any;

  constructor(
    public stService: SelfTrainerService,
  ) {
  }

  ngOnInit(): void {
    console.log("stepData: ", this.stepData);
    // const targetElement = document.getElementById(this.stService.activeMission.targetElementId);
    // const elementRect = targetElement.getBoundingClientRect();
    // console.log("rect inside modal: ", elementRect);
    // this.stepData.rect = elementRect;
  }

  ngAfterViewInit(): void {
    const container = document.getElementById("popup-container");
    this.arrowPopup = document.getElementById('pop-arrow') as HTMLElement;
    this.modalPopup = container as HTMLElement;
    const elementRect = container.getBoundingClientRect();
    this.modalContainerRect = elementRect;
    console.log("modalContainerRect: ", this.modalContainerRect);
    this.setModalPosition();
  }

  setModalPosition() {
    const position = this.stepData.position;
    switch (position) {
      case 'top':
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top - 100}px`;
        this.arrowLeft = `${this.stepData.rect.left + 20}px`;
        this.arrowTop = `${this.stepData.rect.top - 40}px`;
        this.arrowRotate = '270deg';
        break;

      case 'bottom':
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top + this.stepData.rect.height + 50}px`;
        this.arrowLeft = `${this.stepData.rect.left + 20}px`;
        this.arrowTop = `${this.stepData.rect.top + this.stepData.rect.height + 10}px`;
        this.arrowRotate = '270deg';
        break;
      case 'left':
        this.modalLeft = `${this.stepData.rect.left + 20}px`;
        this.modalTop = `${this.stepData.rect.top + (this.stepData.rect.height / 2)}px`;
        this.arrowLeft = `${this.stepData.rect.left + 35}px`;
        this.arrowTop = `${this.stepData.rect.top + (this.stepData.rect.height / 2) - 42}px`;
        this.arrowRotate = '0deg';
        break;

      case 'right':
        this.modalLeft = `${this.stepData.rect.left + (this.stepData.rect.width / 2) + 30}px`;
        this.modalTop = `${this.stepData.rect.top + (this.stepData.rect.height / 2)}px`;
        this.arrowLeft = `${this.stepData.rect.left + (this.stepData.rect.width / 2) + 15}px`;
        this.arrowTop = `${this.stepData.rect.top + (this.stepData.rect.height / 2) - 42}px`;
        this.arrowRotate = '90deg';
        break;
      case 'workspacenameInput':
        this.modalLeft = `${this.stepData.rect.left }px`;
        this.modalTop = `${this.stepData.rect.top + 315 }px`;
        this.arrowLeft = `${this.stepData.rect.left }px`;
        this.arrowTop = `${this.stepData.rect.top + 280}px`;
        this.arrowRotate = '270deg';
        break;
      case 'workspaceSaveBtn':
        this.modalLeft = `${this.stepData.rect.left - 275}px`;
        this.modalTop = `${this.stepData.rect.top + 126}px`;
        this.arrowLeft = `${this.stepData.rect.left - 120}px`;
        this.arrowTop = `${this.stepData.rect.top +181}px`;
        this.arrowRotate = '270deg';
        break;
      case 'videoPlayBtn':
        this.modalLeft = `${this.stepData.rect.left - 300}px`;
        this.modalTop = `${this.stepData.rect.top + 328}px`;
        this.arrowLeft = `${this.stepData.rect.left - 124}px`;
        this.arrowTop = `${this.stepData.rect.top + 383}px`;
        this.arrowRotate = '270deg';
        break;
      case 'annotationComment':
        this.arrowLeft = `${this.stepData.rect.left  }px`;
        this.arrowTop = `${this.stepData.rect.top  + 447}px`;
        this.modalLeft = `${this.stepData.rect.left }px`;
        this.modalTop = `${this.stepData.rect.top + 485}px`;
        this.arrowRotate = '110deg';
        break;
      case 'annotationCommentSendBtn':
        this.arrowLeft = `${this.stepData.rect.left -45  }px`;
        this.arrowTop = `${this.stepData.rect.top  + 505}px`;
        this.modalLeft = `${this.stepData.rect.left - 260}px`;
        this.modalTop = `${this.stepData.rect.top + 550}px`;
        this.arrowRotate = '110deg';
        break;
      case 'annotationPencil':
        this.arrowLeft = `${this.stepData.rect.left -62 }px`;
        this.arrowTop = `${this.stepData.rect.top  + 423}px`;
        this.modalLeft = `${this.stepData.rect.left -47 }px`;
        this.modalTop = `${this.stepData.rect.top + 459}px`;
        this.arrowRotate = '110deg';
        break;
      case 'annotationVersion':
        this.arrowLeft = `${this.stepData.rect.left  }px`;
        this.arrowTop = `${this.stepData.rect.top + 441 }px`;
        this.modalLeft = `${this.stepData.rect.left }px`;
        this.modalTop = `${this.stepData.rect.top + 480 }px`;
        this.arrowRotate = '110deg';
        break;
      case 'annotationComparebtn':
        this.arrowLeft = `${this.stepData.rect.left + 814 }px`;
        this.arrowTop = `${this.stepData.rect.top +436 }px`;
        this.modalLeft = `${this.stepData.rect.left + 614}px`;
        this.modalTop = `${this.stepData.rect.top  + 470}px`;
        this.arrowRotate = '110deg';
        break;
      case 'simultaneousplaybtn':
        this.arrowLeft = `${this.stepData.rect.left - 57}px`;
        this.arrowTop = `${this.stepData.rect.top  +357}px`;
        this.modalLeft = `${this.stepData.rect.left - 22}px`;
        this.modalTop = `${this.stepData.rect.top +352}px`;
        this.arrowRotate = '270deg';
        break;
      case 'comparisonMode':
        this.arrowLeft = `${this.stepData.rect.left - 15 }px`;
        this.arrowTop = `${this.stepData.rect.top +351}px`;
        this.modalLeft = `${this.stepData.rect.left +24 }px`;
        this.modalTop = `${this.stepData.rect.top + 353}px`;
        this.arrowRotate = '270deg';
        break;
      case 'approveVersion':
        this.arrowLeft = `${this.stepData.rect.left + 65 }px`;
        this.arrowTop = `${this.stepData.rect.top + 365}px`;
        this.modalLeft = `${this.stepData.rect.left  }px`;
        this.modalTop = `${this.stepData.rect.top + 400}px`;
        this.arrowRotate = '100deg';
        break;
      case 'taskBtn':
        this.arrowLeft = `${this.stepData.rect.left }px`;
        this.arrowTop = `${this.stepData.rect.top +390}px`;
        this.modalLeft = `${this.stepData.rect.left  }px`;
        this.modalTop = `${this.stepData.rect.top + 425 }px`;
        this.modalPopup.style.zIndex = '9999999';
        this.arrowRotate = '100deg';
        break;
      case 'creatTaskbtn':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top + 276}px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top + 177}px`;
        this.arrowRotate = '-25deg';
        break;
      case 'reviewlinkbtn':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top + 360}px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top +400 }px`;
        this.arrowRotate = '100deg';
        break;
      case 'sharebtn':
        this.arrowLeft = `${this.stepData.rect.left -33 }px`;
        this.arrowTop = `${this.stepData.rect.top  + 303 }px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top +263 }px`;
        this.arrowRotate = '-25deg';
        break;
      case 'projectSettingbtn':
        this.arrowLeft = `${this.stepData.rect.left + 8}px`;
        this.arrowTop = `${this.stepData.rect.top +348}px`;
        this.modalLeft = `${this.stepData.rect.left + 50}px`;
        this.modalTop = `${this.stepData.rect.top +348 }px`;
        this.arrowRotate = '44deg';
        break;
      case 'permissionBtn':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top + 222}px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top + 265 }px`;
        this.arrowRotate = '-50deg';
        break;
      case 'addUserBtn':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top + 795 }px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top + 837 }px`;
        this.arrowRotate = '-50deg';
        break;
      case 'gobacktoDashboard':
        this.arrowLeft = `${this.stepData.rect.left +10 }px`;
        this.arrowTop = `${this.stepData.rect.top + 750}px`;
        this.modalLeft = `${this.stepData.rect.left + 50}px`;
        this.modalTop = `${this.stepData.rect.top + 750}px`;
        this.arrowRotate = '50deg';
        break;
      case 'chatbar':
        this.arrowLeft = `${this.stepData.rect.left - 115 }px`;
        this.arrowTop = `${this.stepData.rect.top - 13 }px`;
        this.modalLeft = `${this.stepData.rect.left -490 }px`;
        this.modalTop = `${this.stepData.rect.top - 63 }px`;
        this.modalPopup.style.zIndex = '99999999';
        this.arrowPopup.style.zIndex = '99999999';
        this.arrowRotate = '-100deg';
        break;
      case 'createGroup':
        this.arrowLeft = `${this.stepData.rect.left -121}px`;
        this.arrowTop = `${this.stepData.rect.top -5 }px`;
        this.modalLeft = `${this.stepData.rect.left -478 }px`;
        this.modalTop = `${this.stepData.rect.top - 71}px`;
        this.arrowRotate = '-121deg';
        this.modalPopup.style.zIndex = '99999999';
        this.arrowPopup.style.zIndex = '99999999';
        this.arrowPopup.style.setProperty('border-left', '2px solid #000');
        this.arrowPopup.style.setProperty('border-top', '2px solid #000');
        break;
      case 'openworkspacedash':
        this.arrowLeft = `${this.stepData.rect.left + 8}px`;
        this.arrowTop = `${this.stepData.rect.top  }px`;
        this.modalLeft = `${this.stepData.rect.left + 50 }px`;
        this.modalTop = `${this.stepData.rect.top}px`;
        this.arrowRotate = '18deg'
        break;
      case 'workspacedash':
        this.arrowLeft = `${this.stepData.rect.left + 5 }px`;
        this.arrowTop = `${this.stepData.rect.top + 36 }px`;
        this.modalLeft = `${this.stepData.rect.left + 46 }px`;
        this.modalTop = `${this.stepData.rect.top +33 }px`;
        this.arrowRotate = '18deg'
        break;
      case 'trainerOpenWorkspace':
        this.arrowLeft = `${this.stepData.rect.left }px`;
        this.arrowTop = `${this.stepData.rect.top + 610}px`;
        this.modalLeft = `${this.stepData.rect.left }px`;
        this.modalTop = `${this.stepData.rect.top +650}px`;
        this.arrowRotate = '-50deg';
        break;
      case 'trainerOpenDriveCampaign':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top+625}px`;
        this.modalLeft = `${this.stepData.rect.left}px`;
        this.modalTop = `${this.stepData.rect.top+670}px`;
        this.arrowRotate = '-50deg';
        break;
      case 'openmilestone':
        this.arrowLeft = `${this.stepData.rect.left}px`;
        this.arrowTop = `${this.stepData.rect.top +175}px`;
        this.modalLeft = `${this.stepData.rect.left -90}px`;
        this.modalTop = `${this.stepData.rect.top +218}px`;
        this.arrowRotate = '118deg';
        break;
      case 'changeStatus':
        this.arrowLeft = `${this.stepData.rect.left -40}px`;
        this.arrowTop = `${this.stepData.rect.top + 320}px`;
        this.modalLeft = `${this.stepData.rect.left -20}px`;
        this.modalTop = `${this.stepData.rect.top +360}px`;
        this.arrowRotate = '-50deg';
        break;
    default:
        break;
    }
  }
}
