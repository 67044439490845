import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import AudioVisualizer from "./audioVisualizer.class";

@Component({
    selector: 'mtm-audio-mic-visualizer',
    template: '<div class="audio-mic-visualizer"><main #visualMainElement></main></div>'
})
export class AudioMicVisualizer implements AfterViewInit, OnDestroy {

    @ViewChild('visualMainElement') visualMainElement: ElementRef;
    visualValueCount = 16;
    visualElements: any;
    dataMap: any = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
    audioVisualizer: any;

    ngOnDestroy(): void {
        this.audioVisualizer.stop();
    }

    ngAfterViewInit() {
        this.startVisualization();
    }

    processFrame(data) {
        const values = Object.values(data);
        for (let i = 0; i < this.visualValueCount; ++i) {
            const value: any = <any>values[this.dataMap[i]] / 255;
            const elmStyles = this.visualElements[i].style;
            elmStyles.transform = `scaleY( ${value} )`;
            elmStyles.opacity = Math.max(.25, value);
        }
    }

    startVisualization() {
        const audioContext = new AudioContext();
        this.visualMainElement.nativeElement.innerHTML = '';
        this.createDOMElements();
        this.audioVisualizer = new AudioVisualizer(audioContext, this.processFrame.bind(this), this.processError.bind(this));
    }

    createDOMElements() {
        for (let i = 0; i < this.visualValueCount; ++i) {
            const elm = document.createElement('div');
            this.visualMainElement.nativeElement.appendChild(elm);
        }
        this.visualElements = document.querySelectorAll('main div');
    }

    processError() {
        this.visualMainElement.nativeElement.classList.add('error');
        this.visualMainElement.nativeElement.innerText = 'Please allow access to your microphone.';
    }
}