import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'mtm-project-proposal-stages',
    templateUrl: './project-proposal-stages.component.html',
    styleUrls: ['./project-proposal-stages.component.scss']
})

export class ProjectProposalStages implements OnInit {

    ngOnInit() {

    }
}