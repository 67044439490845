import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBase } from "app/shared/bases/loading.base";
import { LoadingDataComponent } from "app/shared/components/loading-data/loading-data.component";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import { SubscriptionPlanService } from "app/subscriptions/services/plan.service";
import { SubscriptionRegisterContactUsComponent } from "app/subscriptions/shared/components/contact-us/contact-us.component";
import { SubscriptionService } from "../../../services/subscription.service";

@Component({
	selector: 'mtm-subscription-select-plan',
	templateUrl: './select-plan.component.html',
	styleUrls: ['./select-plan.component.scss']
})
export class SubscriptionRegisterSelectPlanComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('loadingRef', { static: true }) public loadingRef: LoadingDataComponent;
	subscriptionService: SubscriptionService;
	plans: any[] = [];
	addons: any[] = [];
	isLoading: boolean = false;
	euroCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];

	constructor(
		private modalService: NgbModal,
		private _subscriptionService: SubscriptionService,
		private planService: SubscriptionPlanService,
		private authService: AuthService,
		private companyService: CompanyService
	) {
		this.subscriptionService = _subscriptionService;
	}

	ngOnInit() {
		this.loadingRef.initParameters('', '', []);
		this.isLoading = true;
		this.loadingRef.showLoading();
		this.subscriptionService.getCurrentPlan()
			.subscribe(currentPlan => {
				this.authService.setAuthUserSubscription(currentPlan);
				this.companyService.getCompanyById(this.authService.getAuthUser().companyId)
					.subscribe(company => {
						this.subscriptionService.currency = this.planService.getCompanyBillingCurrency(company);
						this.subscriptionService.registrationState = 1;
						if (!this.subscriptionService.plans.length) {
							this.planService.getPlans()
								.subscribe((planData) => {
									this.subscriptionService.plans = this.plans = planData.plans.sort((a: any, b: any) => { return a.order - b.order });
									this.subscriptionService.addons = this.addons = planData.addon;
									this.isLoading = false;
									this.loadingRef.hide();
								}, () => {
									this.isLoading = false;
									this.loadingRef.hide();
								});
						} else {
							this.plans = this.subscriptionService.plans;
							this.isLoading = false;
							this.loadingRef.hide();
						}
					});
			}, err => {
				console.error(err)
			})
	}

	ngAfterViewInit() { }

	ngOnChanges(changes: SimpleChanges) { }

	openContactUsModal() {
		this.modalService.open(SubscriptionRegisterContactUsComponent);
	}

	getCompanyCurrency(company) {
		return this.euroCountries.indexOf(company.billingAddress.country) > -1 ? 'EUR' : 'USD';
	}

}
