<div class="container p-0" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
	<!-- top buttons -->
	<div class="d-flex justify-content-between my-4 ml-1 mr-1">
		<button *ngIf="!isProposal" class="btn btn-danger btn-lg mr-2" (click)="continue()">
			<span>{{'returnToProject' | translate}}</span>
		</button>
		<button *ngIf="!isProposal" class="btn btn-danger btn-lg" (click)="goToTeamFiles()">
			<span>{{ 'project_goToTeamFiles' | translate }}</span>
		</button>
		<button *ngIf="isProposal" style="margin-right: 0;margin-left: auto;" class="btn btn-danger btn-lg"
			(click)="goToProjectStagesProposalSide()">
			<span>{{'goProjectStages' | translate}}</span>
		</button>
	</div>
	<div class="card project-card">
		<div class="d-block">
			<p class="text-black font-weight-bold m-0" *ngIf="!isProposal">{{'validationMode' | translate}}</p>
			<p class="text-black font-weight-bold m-0" *ngIf="isProposal">Recommendation mode</p>
			<p class="card-text" *ngIf="!isProposal">{{'validationSubTitle' | translate}}</p>
			<p class="card-text" *ngIf="isProposal">How do you like your team members to recommend?</p>
			<div class="row mt-2 d-flex py-3 recommendation-row">
				<div class="col">
					<div class="position " *ngIf="!isProposal" [ngClass]="{'position-active': decision}"
						(click)="changeDecision(true, isProposal)">
						<div>
							<img src="https://img.icons8.com/ios/26/1b1b1b/moderator-male.png">
							<img src="https://img.icons8.com/ios/30/000000/moderator-male.png" class="margin-lr">
							<img src="https://img.icons8.com/ios/26/1b1b1b/moderator-male.png">
						</div>
						<h6 class="font-weight-bold">{{'unanimity' | translate}}</h6>
						<p [ngClass]="{'position-font': decision}">
							{{'unanimitySpecify' | translate}}
						</p>
					</div>
					<div class="position" *ngIf="isProposal" [ngClass]="{'position-active': decision}"
						(click)="changeDecision(true, isProposal)">
						<i class="fa fa-users fa-2x" aria-hidden="true"></i>
						<h6 class="font-weight-bold">Unanimity</h6>
						<p [ngClass]="{'position-font': decision}">
							The team members with the permission to recommend should all greenlight on element for
							presentation to client
							The more members the more difficult the recommendation.
						</p>
					</div>
				</div>
				<div class="col">
					<div class="position" *ngIf="!isProposal" [ngClass]="{'position-active': !decision}"
						(click)="changeDecision(false, isProposal)" style="height:100%">
						<img src="https://img.icons8.com/ios/30/000000/moderator-male.png">
						<h6 class="font-weight-bold">{{'oneDecision' | translate}}</h6>
						<p [ngClass]="{'position-font': !decision}">
							{{'oneDecisionSpecify' | translate}}
						</p>
					</div>
					<div class="position" *ngIf="isProposal" [ngClass]="{'position-active': !decision}"
						(click)="changeDecision(false, isProposal)" style="height:100%">
						<i class="fa fa-user fa-2x" aria-hidden="true"></i>
						<h6 class="font-weight-bold">One Decision maker</h6>
						<p [ngClass]="{'position-font': !decision}">
							One Team member is the only one to recommend the elements presented to the client.
							Choose who by ticking the “Validation”
							box.
						</p>
					</div>
				</div>
				<div class="unauth-overlay tooltipped"
					*ngIf="! authUser || authUser.globalRole.startsWith('PRODUCTION')"
					[attr.data-title]="'NoChangeAllowed' | translate">
				</div>
			</div>

			<div class="d-flex flex-column py-2 user-header-row">
				<div class="mb-3">{{'selectTeamMember' | translate}}</div>
				<div class="d-flex align-items-center mb-3 button-nav" >
					<button id="trainerAddUser" class="btn btn-danger d-flex align-items-center justify-content-center btn-lg btn-add flex-fill mr-2" (click)="addNewUserModal();trainerAddUser()">
						<i class="mtm-icon mtm-icon-add-user mr-3"></i>
						<span>{{'addUser' | translate}}</span>
					</button>
					<button class="btn btn-danger d-flex align-items-center justify-content-center btn-lg btn-add flex-fill mr-2" (click)="createTeam()"
					[disabled]="! brandId">
						<i class="mtm-icon mtm-icon-add-group mr-3"></i>
						<span>{{'createTeam' | translate}}</span>
					</button>
					<button class="btn btn-danger d-flex align-items-center justify-content-center btn-lg btn-add flex-fill ml-2" (click)="addNewUserModal()"
					[disabled]="true">
						<i class="mtm-icon mtm-icon-import-team mr-3"></i>
						<span>{{'importTeam' | translate}}</span>
					</button>
				</div>
				<div class="d-flex align-items-center mb-1">
					<div class="d-flex align-items-center mr-auto">
						<select class="form-control mr-3" [(ngModel)]="tableView" [style.width]="'180px'" [style.height]="'auto'" [style.padding]="'6px 8px'">
							<option [ngValue]="'userView'">{{'team_usersView' | translate }}</option>
							<option [ngValue]="'teamView'">{{'team_teamsView' | translate }}</option>
						</select>
						<select class="form-control" [style.height]="'auto'" [style.width]="'180px'" [style.padding]="'6px 8px'" *ngIf="tableView=='userView'">
							<option [ngValue]="''">{{'Select team' | translate }}</option>
						</select>
					</div>
					<div class="d-flex align-items-center ml-auto">
						<a href="#" class="text-danger d-flex align-items-center" [style.white-space]="'nowrap'"
							(click)="onOpenPermissionInfo();false">
							<i class="fa fa-cog fa-fw p-1 mr-1" aria-hidden="true"></i>
							<span class="text-uppercase font-weight-bold"
								[innerHTML]="'permissionDetails' | translate"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tableView=='userView'" class="d-block">
			<div *ngIf="!!(localFilterParams?.fullName || localFilterParams?.email || localFilterParams?.roles.length)"
				class="d-flex justify-content-end">
				<label class="p-2">Total: {{(users | FilterUser: localFilterParams?.fullName : localFilterParams?.email
					: localFilterParams?.roles).length}} records</label>
			</div>
			<ag-grid-angular #projectPermissionTable
				[rowData]="users | FilterUser: localFilterParams?.fullName : localFilterParams?.email : localFilterParams?.roles : localFilterParams?.decisionMake"
				[debounceVerticalScrollbar]="true" [rowHeight]="120" [defaultColDef]="defaultColDef"
				class="ag-theme-alpine" [headerHeight]="72" [columnDefs]="projectPermissionColumnsDef"
				[enableCellTextSelection]="true" [ensureDomOrder]="true" style="width: 100%; height: 680px;"
				(columnVisible)="autoSizeColumnWidth()" (rowDataUpdated)="autoSizeColumnWidth()"
				(gridReady)="onGridReady($event)" [context]="tableContext" [components]="components">
			</ag-grid-angular>
		</div>
		<div *ngIf="tableView=='teamView'" class="d-block">
			<ag-grid-angular #projectTeamTable
				[rowData]="teams"
				[debounceVerticalScrollbar]="true" [rowHeight]="54" [defaultColDef]="defaultTeamColDef"
				class="ag-theme-alpine project-team-table" [headerHeight]="54" [columnDefs]="projectTeamColumnsDef"
				[enableCellTextSelection]="true" [ensureDomOrder]="true" style="width: 100%; height: 680px;"
                      
					   (rowClicked)="editTeam($event)"
                       (gridReady)="fitTableColumns($event)" [context]="teamTableContext" [components]="teamComponents">
			</ag-grid-angular>
		</div>
		<div *ngIf="tableView=='userView'" class="d-flex justify-content-between mt-3 ml-1 mr-1">
			<button class="btn btn-danger btn-lg btn-add" (click)="addNewUserModal()">
				<span>{{'addUser' | translate}}</span>
			</button>
		</div>
	</div>

	<!-- bottom buttons -->

	<div class="d-flex justify-content-between my-4 ml-1 mr-1">
		<button *ngIf="!isProposal" class="btn btn-danger btn-lg mr-2" (click)="continue()">
			<span>{{'returnToProject' | translate}}</span>
		</button>
		<button *ngIf="!isProposal" class="btn btn-danger btn-lg" (click)="goToTeamFiles()">
			<span>{{ 'project_goToTeamFiles' | translate }}</span>
		</button>
		<button *ngIf="isProposal" style="margin-right: 0;margin-left: auto;" class="btn btn-danger btn-lg"
			(click)="goToProjectStagesProposalSide()">
			<span>{{'goProjectStages' | translate}}</span>
		</button>
	</div>
</div>
