<div class="modal-body">
	<h5 class="text-center">Re- Invite {{ user.fullName }}</h5>
	<p class="text-muted mt-3 text-center">This user will be invited again</p>
	<div class="row">
		<div class="col">
			<button class="btn btn-outline-secondary btn-lg btn-block"
				(click)="activeModal.close()"><span>Cancel</span></button>
		</div>
		<div class="col">
			<button class="btn btn-green btn-lg btn-block"
				(click)="mthdReInvite(user.username,user.selectedRoles)"><span>Invite</span></button>
		</div>
	</div>
</div>