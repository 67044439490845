import { AfterViewInit, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from 'app/shared/services/overlayService';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { hallmark, product } from "../../../shared/interfaces";
import { ProjectV2ServiceService } from "../../../shared/services/project-v2-service.service";
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { AuthService } from 'app/shared/services/auth.service';
import { ApiService } from 'app/shared/services/api.service';
import { UploadService } from 'app/shared/services/upload.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent } from 'app/on-boarding/profile-avatar/upload-modal/upload-modal.component';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'app/shared/services/helper.service';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { UserService } from 'app/shared/services/user.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

@Component({
  selector: 'mtm-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit, OnDestroy{

  generalInfoForm: UntypedFormGroup;
  criteriaForm: UntypedFormGroup;
  ngUnsubscribe = new Subject();
  workspaces: any[] = []
  expDate: any;
  isSelected = false;
  isOpen: boolean = false;
  isDropdownOpen: boolean = false;
  priorityLevel: string = 'Please select...';
  selectedProductCategory: string;
  selectedComplexityCategory: string;;
  hallmarkSelectedComplexityCategory: string;
  selectedLCampaignTypeCategory: string;
  selectedCoffretType: string;
  isproductDropdownOpen: boolean = false;
  isComplexityDropdownOpen: boolean = false;
  isHallmarkComplexityDropdownOpen: boolean = false;
  isCampaignTypeDropdownOpen: boolean = false;
  isProjectSelected: boolean = false;
  productCampaign = 'Product Campaign';
  hallmarks = 'Hallmarks';
  isProductCampaign: boolean = false;
  isCampaignType: boolean = true;
  isChinaSpecific: boolean = false;
  isPOSMselected: boolean = true;
  isCoffretSelected: boolean = true;
  isCollectorSelected: boolean = true;
  isDmiProduced: boolean = true;
  isCoffretDmiProduced: boolean = true;
  isCampaignChanged: boolean = true;
  isXmas: boolean = false;
  isCoffretTypeDropdownOpen: boolean = false;
  isProductLaunch: boolean = false;
  categoryComplexity: any[] = [];
  grayStar = '../../../assets/img/project-v2/gray-star.svg';
  blackStar = '../../../assets/img/project-v2/filled-star.svg';
  criticalRightArrow = '';
  midiumRighArrow = '';
  lowRightArrow = '';
  highRightArrow = '';
  highblackStar = this.blackStar;
  lowblackStar = this.blackStar;
  midblackStar = this.blackStar;
  tempCampiagnType: any[] = [];
  selectedProjectType: string;
  timelineResult: any;
  expectedMadDate: any;
  isOtherPriorityLevel: boolean = false;
  criteriaFormData: any;
  generalFormData: any;
  timelineId: string;
  removeHallmarkData = ['Hallmarks Xmas', 'China Specific', 'Collector', 'Collector Complexity', 'Set', 'Set DMI Produced', 'Coffret Type']
  removeProductCampaignData = ['Product Launch', 'Product Launch Category', 'Complexity', '360 Campaign', 'Campaign Type']
  submitted: boolean = false;
  driveCampaignImage: any;
  timeline: any;
  selectWorkspace: 'Select Workspace';
  isCriticalPriorityLevel: boolean = false; levelsOfPriority = [
    { id: 'Low' },
    { id: 'Medium' },
    { id: 'High' }
  ]

  productCategory = [
    {
      name: 'normalcosmetic',
      complexity: [
        { name: '1T/2T' },
        { name: '3T/4T' }]
    },
    {
      name: 'specialCosmetic',
      complexity: [
        { name: '1T/2T' },
        { name: '3T' },
        { name: '4T' }]
    },
    {
      name: 'specialCosmeticWithNanoparticles',
      complexity: [
        { name: '1T/2T' },
        { name: '3T' },
        { name: '4T' }]
    },
  ];

  complexityCategory = [
    { name: '1T/2T' },
    { name: '3T/4T' },
    { name: '3T' },
    { name: '4T' }
  ];

  hallmarksComplexicity = [
    { name: '1T/2T' },
    { name: '3T/4T' },
  ];

  campaignTypeCategory = []

  campaignTypeCategory360 = [
    { name: 'launchReference' },
    { name: 'launchNewTerritory' }
  ]

  ProductLaunchcampaignTypeCategory = [
    { name: 'repushNewTerritory' },
    { name: 'repushReference' },
    { name: 'fastTrackReddit' }
  ]

  typeOfCoffret = [
    { name: 'Standard' },
    { name: 'Premium' },
    { name: 'Both' }
  ];
  workspaceId: string;
  authUser: any;
  selectedWorkspace: any;
  selectedWorkspaceName: string;
  workspaceFormControl: UntypedFormControl;
  modal: NgbModalRef;
  breadcrumbs: any;
  canEditOwner: boolean = false;
  banner: any;
  status: string;
  cacheBuster: string = new Date().getTime().toString();
  brandData: any = [];
  participants: any[] = [];
  brandOwners: any[] = [];
  // fieldErrors: any = {};
  backupParticipants: any[] = [];
  existingLeaders: any[] = [];
  editModeData: any;
  isParticipantsLoaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public projectV2ServiceService: ProjectV2ServiceService,
    private overlayService: OverlayService,
    private timelineService: TimelineService,
    private workspaceService: WorkspaceService,
    private authService: AuthService,
    private api: ApiService,
    private permissionService: PermissionService,
    public uploadService: UploadService,
    private dataTransferService: DataTransferBetweenComponentsService,
    private modalService: NgbModal,
    private cryptoService: CryptoService,
    private userService: UserService,
    private analyticService: AnalyticsService
  ) {
    this.timelineService.isDriveNav.emit(false);
  }

  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.canEditOwner = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.U));
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.add('campaign-drive-general');
    }

    // for dashboard image
    // this.timelineService.componentName.next('generalComponent');
    this.timelineService.componentName.next({
      componentName: 'generalComponent',
      bannerUrl: ''
    })
    this.workspaceId = this.route.snapshot.params['workspaceId'];
    if (!this.workspaceId) {
      this.getWorkspaces();
    }
    if (this.workspaceId) {
      this.timelineService.setworksapceId.next(this.workspaceId);
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 1, `/workspaces/campaign-drive?wid=${this.cryptoService.encryptString(this.workspaceId)}`);
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 2, `/workspaces/campaigndrive/${this.workspaceId}/general`);
      this.workspaceFormControl = new UntypedFormControl('');
      this.getWorkspaces();
    }
    this.categoryComplexity = [
      { name: '1T/2T' },
      { name: '3T/4T' },
      { name: '3T' },
      { name: '4T' }
    ];
    this.tempCampiagnType = this.campaignTypeCategory;

    // General Info Form
    this.generateGeneralInforForm();

    // Criteria Form
    this.generateCritariaForm();
    this.getProjectData();
    if (this.projectV2ServiceService.timelineId) {
      const formData: any = this.projectV2ServiceService.getFormData();
      this.editModeData = formData;
      this.generalInfoForm.patchValue(formData.general);
      this.criteriaForm.patchValue(formData.criteria);
      this.expectedMadDate = this.formatDate(formData.general.expectedMad);
      this.selectProjectType(formData.criteria['Project Type']);
      if (formData.criteria['Product Launch Category']) {
        this.updateCategoryComplexity(formData.criteria['Product Launch Category']);
      }
      if (formData.general['timelines']) {
        this.timeline = formData.general['timelines'];
      }
      if (formData.general['logo']) {
        this.driveCampaignImage = formData.general['logo'];
      }
      if (formData.general['status']) {
        this.status = formData.general['status'];
      }
      if (formData.general['banner']) {
        this.banner = formData.general['banner'];
      }
      if (formData.general['priority'] == 'OTHER') {
        this.getOtherPriority();
      } else {
        this.getCriticalPriority();
      }
      if (formData.criteria['Project Type'] == this.productCampaign) {
        this.removeObjectFromCriteriaForm(['Hallmarks Xmas', 'China Specific', 'Collector', 'Collector Complexity', 'Set', 'Set DMI Produced', 'Coffret Type', 'Gift'])
      } else {
        this.removeObjectFromCriteriaForm(['Product Launch', 'Product Launch Category', 'Complexity', '360 Campaign', 'Campaign Type']);
      }
      if (formData.criteria['POSM'] == 'No') {
        this.removeObjectFromCriteriaForm(['POSM DMI Produced']);
      }
      if (formData.criteria['Set'] == 'No') {
        this.removeObjectFromCriteriaForm(['Set DMI Produced', 'Coffret Type', 'Gift']);
      }
      if (formData.criteria['Set DMI Produced'] == 'No') {
        this.removeObjectFromCriteriaForm(['Coffret Type', 'Gift']);
      }
      console.log('formData.criteria[\'360 Campaign\']', formData.criteria['360 Campaign'])
      if (formData.criteria['360 Campaign'] && formData.criteria['360 Campaign'] == 'No') {
        console.log('formData.criteria[\'360 Campaign\'] == \'Yes\'', formData.criteria['360 Campaign'] == 'Yes')
        this.removeObjectFromCriteriaForm(['Campaign Type', 'POSM', 'POSM DMI Produced']);
      }
      if (formData.criteria['Product Launch'] && formData.criteria['Product Launch'] == 'Yes') {
        this.addObjectInCriteriaForm(['Product Launch Category', 'Complexity', '360 Campaign']);
        this.updateCampaignCategory(true);
      } else if (!formData.criteria['Product Launch']) {
        this.updateCampaignCategory(true);
      } else {
        this.removeObjectFromCriteriaForm(['Product Launch Category', 'Complexity', '360 Campaign', 'Gift'])
        this.updateCampaignCategory(false);
      }
    } else {
      this.updateCampaignCategory(true);
    }
    this.getParticipants();
  }
  // get form controls
  get gif() { return this.generalInfoForm.controls; }
  get cf() { return this.criteriaForm.controls; }

  ngOnDestroy(): void {
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.remove('campaign-drive-general');
    }
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  // update category complexity in edit mode
  updateCategoryComplexity(productCategoryName: string): void {
    const selectedProduct = this.productCategory.find(product => {
      switch (productCategoryName) {
        case 'Normal Cosmetic':
          return product.name === 'normalcosmetic';
        case 'specialCosmeticWithNanoparticles':
          return product.name === 'specialCosmeticWithNanoparticles';
        case 'specialCosmetic':
          return product.name === 'specialCosmetic';
        default:
          return false;
      }
    });
    if (selectedProduct) {
      try {
        this.categoryComplexity = selectedProduct.complexity;
      } catch (error) {
        console.error('error', error);
      }
    }
  }

  generateGeneralInforForm() {
    this.generalInfoForm = this.fb.group({
      "id": new UntypedFormControl('', []),
      "name": new UntypedFormControl('', [Validators.required]),
      "description": new UntypedFormControl('', [Validators.required]),
      "logo": new UntypedFormControl(null, []),
      "workspaceId": new UntypedFormControl(null, []),
      "projectLeaders": new UntypedFormControl(null, [Validators.required]),
      "expectedMad": new UntypedFormControl('', []),
      "priority": new UntypedFormControl('', []),
      "workspace": new UntypedFormControl('', [Validators.required]),
      "timelines": new UntypedFormControl('', []),
      "banner": new UntypedFormControl(null, [])
    });
  }

  generateCritariaForm() {
    this.criteriaForm = this.fb.group({
      // Common Fields
      'Project Type': new UntypedFormControl('', [Validators.required]),
      'expectedDate': new UntypedFormControl('', [Validators.required]),
      'priority': new UntypedFormControl('', [Validators.required]),

      // Product Fields
      'Product Launch': new UntypedFormControl('Yes', [Validators.required]),
      'Product Launch Category': new UntypedFormControl('', [Validators.required]),
      'Complexity': new UntypedFormControl('', [Validators.required]),
      '360 Campaign': new UntypedFormControl('Yes', [Validators.required]),
      'Campaign Type': new UntypedFormControl('', [Validators.required]),

      // Hallmark Fields
      'Hallmarks Xmas': new UntypedFormControl('No', [Validators.required]),
      'China Specific': new UntypedFormControl('No', [Validators.required]),
      'Collector': new UntypedFormControl('Yes', [Validators.required]),
      'Collector Complexity': new UntypedFormControl(null, [Validators.required]),
      'Set': new UntypedFormControl('Yes', [Validators.required]),
      'Set DMI Produced': new UntypedFormControl('', [Validators.required]),
      'Coffret Type': new UntypedFormControl('', [Validators.required]),
      'Gift': new UntypedFormControl('', [Validators.required]),
      'POSM': new UntypedFormControl('', [Validators.required]),
      'POSM DMI Produced': new UntypedFormControl('', [Validators.required]),
    });
  }

  // validator function for criteria form fields
  isValid(field: string): boolean {
    const formField = this.criteriaForm.get(field);
    return formField ? formField.invalid && this.submitted : false;
  }

  // validator function for general form fields
  isValidGeneralForm(field: string): boolean {
    const formField = this.generalInfoForm.get(field);
    return formField ? formField.invalid && this.submitted : false;
  }

  dropDown(value) {
    this.isOpen = value;
    this.isOpen = !this.isOpen
  }

  onHoverSelector() {
    this.isSelected = !this.isSelected
  }

  filterDateChanged() {
    if (this.expectedMadDate) {
      this.expDate = new Date("" + this.expectedMadDate.year + "-" + this.expectedMadDate.month + "-" + this.expectedMadDate.day).toISOString();
    }
  }

  getPriorityLevel(closeDropDown, value) {
    this.isOpen = closeDropDown;
    this.isOpen = !this.isOpen;
    this.priorityLevel = value;
  }

  productCatDropDown(value) {
    this.isproductDropdownOpen = value;
    this.isproductDropdownOpen = !this.isproductDropdownOpen;
  }

  complexityCatDropDown(value) {
    this.isComplexityDropdownOpen = !value;
  }
  hallmarkComplexityCatDropDown(value) {
    this.isHallmarkComplexityDropdownOpen = !value;
  }
  campaignTypeTypeCatDropDown(value) {
    this.isCampaignTypeDropdownOpen = !value;
  }

 async getProductCategory(category: any) {
   console.log('11category', category)
   if (category.name) {
     switch (category.name) {
       case 'normalcosmetic':
       case 'Normal Cosmetic':
         category.name = 'Normal Cosmetic';
         break;
       case 'specialCosmeticWithNanoparticles':
       case 'Special Cosmetic With Nanoparticles':
         category.name = 'Special Cosmetic With Nanoparticles';
         break;
       case 'specialCosmetic':
       case 'Special Cosmetic':
         category.name = 'Special Cosmetic';
         break;
       default:
         return false;
     }
   }

  console.log('category', category)
  // reset complexity when change category
  this.selectedComplexityCategory ='';
  this.cf['Complexity'].setValue('');
    this.selectedProductCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Product Launch Category');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Product Launch Category control is null.');
    }
    const selectedProduct = this.productCategory.find(product => product.name === this.cf['Product Launch Category'].value);
    if (selectedProduct) {
      try {
        this.categoryComplexity = [];
        this.categoryComplexity = selectedProduct.complexity;
      } catch (error) {
        console.error('error', error)
      }
    }
 
  }

  getComplexity(category) {
    this.selectedComplexityCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Complexity');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Complexity control is null.');
    }
  }

  getHallmarkComplexity(category) {
    this.hallmarkSelectedComplexityCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Collector Complexity');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Complexity control is null.');
    }
    // this.isHallmarkComplexityDropdownOpen = !isDropdownOpen;
  }

  getCampaignType(category) {
    if (category.name === 'fastTrackReddit') {
      this.removeObjectFromCriteriaForm(['POSM DMI Produced', 'POSM']);
    } else {
      this.addObjectInCriteriaForm(['POSM DMI Produced', 'POSM']);
    }
    if (category.name) {
      switch (category.name) {
        case 'launchReference':
          category.name = 'Launch Reference';
          break;
        case 'launchNewTerritory':
          category.name = 'Launch New Territory';
          break;
        case 'repushNewTerritory':
          category.name = 'Repush New Territory';
          break;
        case 'repushReference':
          category.name = 'Repush Reference';
          break;
       case 'fastTrackReddit':
          category.name = 'Fast Track Reddit';
          break;
        default:
          return false;
      }
    }
    const productLaunchCategoryControl = this.criteriaForm.get('Campaign Type');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Campaign Type Category control is null.');
    }
  }

  updateCampaignCategory(isCampaignTypeChanged?) {
    this.campaignTypeCategory = [];
    if (isCampaignTypeChanged) {
      this.campaignTypeCategory = this.campaignTypeCategory360;
    } else {
      this.campaignTypeCategory = this.ProductLaunchcampaignTypeCategory;
    }
  }

  changeProjectType() {
    this.isProjectSelected = false;
  }

  selectProjectType(type) {
    this.selectedProjectType = type;
    this.isOtherPriorityLevel = false;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      "Project Type": this.selectedProjectType
    });
    if (type == this.productCampaign) {
      this.isProductCampaign = true;
    } else if (type == this.hallmarks) {
      this.isProductCampaign = false;
    }
    this.isProjectSelected = true;
  }

  coffretType(value) {
    this.isCoffretTypeDropdownOpen = !value;
  }

  getCoffretType(category) {
    this.selectedCoffretType = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Coffret Type');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Coffret Type control is null.');
    }
    // this.isCoffretTypeDropdownOpen = !isDropdownOpen;
  }

  onContinue() {
    this.submitted = true;
    if (this.criteriaForm && this.criteriaForm.valid) {
      this.onSubmit();
    } else {
    }
  }

  getProjectData() {
    this.projectV2ServiceService.getMasterData().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
    });
  }

  getOtherPriority() {
    this.isOtherPriorityLevel = true;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      priority: 'OTHER'
    });
  }

  getCriticalPriority() {
    this.isOtherPriorityLevel = false;
    this.isCriticalPriorityLevel = true;
    this.criteriaForm.patchValue({
      priority: 'CRITICAL'
    });
  }

  onSubmit() {
    const generalFormData = this.generalInfoForm.value;
    console.log('generalFormData', generalFormData)
    const criteriaFormData = this.removeBlankFields(this.criteriaForm.value);
    console.log('criteriaFormData', criteriaFormData)
    let madDate: any;
    let payload = generalFormData;
    if (this.workspaceId) {
      generalFormData.workspaceId = this.workspaceId;
    }

    if (typeof payload.projectLeaders == 'string') {
      payload.projectLeaders = payload.projectLeaders.split(',');
    }

    if (this.existingLeaders) {
      payload.projectLeaders = this.existingLeaders.map(owner => owner.username);
    }

    if (criteriaFormData.expectedDate) {
      // date to send, for product timeline component
      madDate = `${criteriaFormData.expectedDate.day}.${criteriaFormData.expectedDate.month}.${criteriaFormData.expectedDate.year}`;

      // add expected date and priority
      payload.expectedMad = this.expDate;
      payload.priority = criteriaFormData.priority;
      payload.status = this.status ? this.status : 'IN_PROGRESS';
      // delete extra keys
      delete criteriaFormData.expectedDate;
      delete criteriaFormData.priority;

      // add criteria object
      criteriaFormData['Company ID'] = this.authUser.companyId;
      payload['criteria'] = JSON.stringify(criteriaFormData);
      console.log('payload', payload)

      //update timeline
      console.log('this.projectV2ServiceService.timelineId', this.projectV2ServiceService.timelineId)
      if (payload.id || this.projectV2ServiceService.timelineId) {
        // payload.id = this.projectV2ServiceService.timelineId;
        payload.timelines = this.projectV2ServiceService.timelineData;
        //remove object from criteria based on project type
        if (this.cf['Project Type'].value == this.productCampaign) {
          const object = JSON.parse(payload.criteria);
          const keysToRemove = this.removeHallmarkData;
          keysToRemove.forEach(key => delete object[key]);
          payload['criteria'] = JSON.stringify(object)
        } else {
          const object = JSON.parse(payload.criteria);
          const keysToRemove = this.removeProductCampaignData;
          keysToRemove.forEach(key => delete object[key]);
          payload['criteria'] = JSON.stringify(object)
        }
        if (this.timeline) {
          payload['timelines'] = this.timeline;
        }
        this.projectV2ServiceService.createTimeline(payload).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (result: any) => {
            this.uploadAvatarImage(
              this.driveCampaignImage,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/logo`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resAvatarURL: any) => {
                generalFormData.banner = resAvatarURL.logo;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },
            });
            // for banner
            this.uploadAvatarImage(
              this.banner,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/banner`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resbannerURL: any) => {
                generalFormData.banner = resbannerURL.banner;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },

            });
            this.timelineResult = result;
            console.log('edittimelineResult', this.timelineResult)
            this.projectV2ServiceService.updateFormData(generalFormData, criteriaFormData);
            this.projectV2ServiceService.timelineId = result.id;
            this.projectV2ServiceService.timelineData = result.timelines;
            this.projectV2ServiceService.sendData(result, madDate)
            this.router.navigate([
              `workspaces/campaigndrive/${this.workspaceId}/recap`], {
              queryParams: {
                wid: this.cryptoService.encryptString(this.workspaceId),
                dc: this.cryptoService.encryptString(result.id),
              }
            });
          },
          error(err) {
            this.overlayService.showError('Unable to update timeline data.', 'Error');
            this.expectedMadDate = null;
          },
          complete() {

          },
        },)
      } else {
        //create new timeline
        this.projectV2ServiceService.createTimeline(payload).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (result: any) => {
            // for logo
            this.uploadAvatarImage(
              this.driveCampaignImage,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/logo`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resAvatarURL: any) => {
                generalFormData.banner = resAvatarURL.logo;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },
            });
            // for banner
            this.uploadAvatarImage(
              this.banner,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/banner`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resbannerURL: any) => {
                generalFormData.banner = resbannerURL.banner;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },

            });
            this.timelineResult = result;
            console.log('new this.timelineResult', this.timelineResult)
            this.projectV2ServiceService.updateFormData(generalFormData, this.criteriaForm.value);
            this.projectV2ServiceService.timelineId = result.id;
            this.projectV2ServiceService.timelineData = result.timelines;
            this.projectV2ServiceService.sendData(result, madDate)
            this.router.navigate([
              `workspaces/campaigndrive/${this.workspaceId}/recap`],
              {
                queryParams: {
                  wid: this.cryptoService.encryptString(this.workspaceId),
                  dc: this.cryptoService.encryptString(result.id),
                }
              });

          },
          error: (err) => {
            console.log('err', err)
            this.overlayService.showError(`Server Error: ${err.message}`,'Error');
          },
          complete: () => {
          },
        });
      }
    } else {
      this.overlayService.showError('Please select expected date.', 'Error');
    }

  }

  addObjectInCriteriaForm(items: any) {
    items.forEach(elem => {
      this.criteriaForm.addControl(elem, new UntypedFormControl(null, []));
    });
  }

  removeObjectFromCriteriaForm(items: any) {
    items.forEach(elem => {
      this.criteriaForm.removeControl(elem);
    });
  }

  resetCriteriaForm() {
    this.generateCritariaForm();
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = month;
    }
    if (day.length < 2) {
      day = day;
    }
    const dt = { 'year': year, 'month': parseInt(month), 'day': parseInt(day) };
    return dt;
  }

  //remove Blank Fields from forms
  removeBlankFields(obj: any): any {
    const result: any = {};
    for (const key in obj) {
      const value = obj[key];
      if (value !== '' || value == null) {
        result[key] = value;
      }
    }
    return result;
  }

  getWorkspaces() {
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner  
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);;

              this.workspaces.forEach(workspace => {
                // Remove name space from start
                workspace.name = workspace.name.trim();
              });

              // Sort the workspaces based on their names
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
              if (this.workspaceId) {
                // Set the selectedWorkspace based on your condition (e.g., workspace ID match)
                this.selectedWorkspace = this.workspaces.find(workspace => workspace.id === this.workspaceId);
                this.selectedWorkspaceName = this.selectedWorkspace.name;
                this.projectV2ServiceService.workspaceName = this.selectedWorkspace.name;
                // Set the initial value of the FormControl
                this.generalInfoForm.patchValue({ workspace: this.selectedWorkspace ? this.selectedWorkspace.name : '' });
              }
            }
          });
        },
        error: (err) => {
          console.error('err', err)
        },
        complete: () => {
          console.log('workspace loaded')
        }
      });
  }
  
  /**
   * Used to send image to second cropper
   * @param $event
   */
  fileChangeListener($event): void {
    let files = [];
    if (!$event.event) {
      files = $event.files[0];
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setProfileImage(files);
      }
    }
    else {
      files = $event.event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event.event;
        this.setProfileImage(files[0]);
      }
    }
  }

  brandFileChangeListener($event): void {
    let files = [];
    if (!$event.event) {
      files = $event.files[0];
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setBrandProfileImage(files);
      }
    }
    else {
      files = $event.event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event.event;
        this.setBrandProfileImage(files[0]);
      }
    }
  }

  private setBrandProfileImage(file) {
    let fileReader = new FileReader();
    // this.openModal();
    fileReader.onload = () => {
      this.banner = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  }

  private setProfileImage(file) {
    let fileReader = new FileReader();
    // this.openModal();
    fileReader.onload = () => {
      this.driveCampaignImage = fileReader.result;
    };

    fileReader.readAsDataURL(file);
  }
  /**
   * Open crop modal
   */
  private openModal() {
    this.modal = this.modalService.open(UploadModalComponent, { size: 'lg' });
    this.modal.componentInstance.setCrop(false);
  }

  changeWorkspace() {
    const selectedWorkspaceName = this.generalInfoForm.get('workspace').value;
    this.selectedWorkspace = this.workspaces.find(workspace => workspace.name === selectedWorkspaceName);
    this.workspaceId = this.selectedWorkspace.id;
    this.selectedWorkspaceName = this.selectedWorkspace.name;
    this.projectV2ServiceService.workspaceName = this.selectedWorkspace.name;
  }

  private uploadAvatarImage(image, partialURL): Observable<any> {
    return new Observable((observer) => {
      this.uploadService
        .uploadImage(image, this.api.baseUrl + partialURL).pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (resAvatar) => {
            if (!resAvatar.logo) return;

            observer.next(resAvatar.logo);
          },
          (err) => {
            observer.error();
          }
        );
    });
  }

  returnToProject() {
    const path = `workspaces/campaigndrive/dashboard`;
    this.router.navigate([path], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
        dc: this.cryptoService.encryptString(this.projectV2ServiceService.timelineId),
      }
    });
  }

  refreshImageVersion() {
    this.cacheBuster = new Date().getTime().toString();
  }

  async getParticipants() {
    try {
      this.isParticipantsLoaded = false;
      let result = await firstValueFrom(
        this.userService.getUsersByCompanyId(this.authUser.companyId).pipe(takeUntil(this.ngUnsubscribe))
      );  
      result = result.filter(user => user.fullName && user.fullName.trim() !== '');  
      this.participants = result;
      this.backupParticipants = result;
      this.isParticipantsLoaded = true;
  
      // Check if participants are available before processing
      if (this.participants && this.participants.length > 0) {
        if (this.brandData.owners) {
          const ownerUsernames = this.brandData.owners.map(owner => owner.toLowerCase());
  
          // Filter existing leaders
          this.existingLeaders = this.participants.filter(participant =>
            ownerUsernames.includes(participant.username.toLowerCase())
          );
  
          // Mark participants as selected based on ownership
          this.participants.filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selected = ownerUsernames.includes(usernameLower);
            return participant;
          });
  
        }
  
        // Handle existing leaders based on projectLeaders in formData
        if (this.editModeData && this.editModeData.general && this.editModeData.general['projectLeaders']) {
          const projectLeaders = this.editModeData.general['projectLeaders'];
  
          const matchedLeaders = this.participants
            .filter(leader => projectLeaders.includes(leader.username))
            .map(participant => ({ ...participant, selected: true }));
  
          // Mark participants as selected based on ownership
          this.participants.filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selected = projectLeaders.includes(usernameLower);
            return participant;
          });
  
          // Push the matched objects into the existingLeaders and brandOwners arrays
          this.existingLeaders.push(...matchedLeaders);
          this.brandOwners.push(...matchedLeaders);
        }
  
        // sort leaders
        this.participants.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
        this.backupParticipants.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
        this.existingLeaders.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
        this.brandOwners.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
      } else {
      }
    } catch (error) {
      console.error('Error loading participants', error);
    }
  }
  
  searchParticipants(e) {
    const participants = this.backupParticipants.filter(p => !!p.fullName.trim());
    if (e.target.value) {
      this.participants = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
    } else {
      this.participants = participants.filter(p => !!p.fullName.trim() && !(p.roles.length == 1 && p.roles[0] == 'COMPANY_OCCASIONAL'));
    }
  }

  ownerSelected(e, participant) {
    const { username } = participant;

    const indexOfUsername = this.existingLeaders.findIndex(owner => owner.username === username);

    if (indexOfUsername === -1) {
      // The participant is not in the list, so add them.
      this.brandOwners.push(username);
      this.existingLeaders.push(participant);
      participant.selected = true;
    } else {
      // The participant is already in the list, so remove them.
      this.brandOwners = this.brandOwners.filter(owner => owner !== username);
      this.existingLeaders.splice(indexOfUsername, 1); // Remove the participant by index.
      participant.selected = false;
    }
  }
}
