<div class="modal-header">
	<h4 class="modal-title">Authenticate Your Account</h4>
</div>
<div class="modal-body">
	<form *ngIf="verifyForm" (ngSubmit)="verify()" [formGroup]="verifyForm">
		<div class="container">
			<div class="row">
				<div class="form-group verification-group">
					<label class="mr-2">Please enter your verification code</label>
					<input #tfacode type="text" formControlName="code" name="code" [attr.maxlength]="maxLength"
						autocomplete="off">
				</div>
			</div>
			<div class="row" *ngIf="remainingTime">
				<div class="remaining-time">
					Remaining time {{remainingTime}}
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-orange" (click)="verify()" [disabled]="isLoading">Verify</button>
</div>