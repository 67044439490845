<div class="file-upload-dropdown-container">
	<div ngbDropdown class="d-inline-block position-relative">
		<button (click)="switchDropdownPanel(!dropdownStatus);"
			class="btn drpdwn-max-height d-flex align-items-center justify-content-center" id="fileupload"
			ngbDropdownToggle>
			<i class="fa fa-cloud-upload-img" aria-hidden="true"
				*ngIf="!uploadingCount || !downloadingCount"></i>
			<div class="d-flex mr-3" *ngIf="uploadingCount || downloadingCount">
				<mtm-download-upload-progress-icon
					[isOnLoading]="true" [widthInPixel]="28" [iconColor]="'white'">
				</mtm-download-upload-progress-icon>
			</div>
		</button>
		<div *ngIf="dropdownStatus" [style.max-height]="dropdownContainerMaxHeight + 'px'"
			(clickOutside)="switchDropdownPanel(false)" aria-labelledby="fileupload"
			class="combobox combobox-2 combobox-3 position-absolute d-flex flex-column"
			[style.width]="publicReviewLink ? '500px' : 'auto'">
			<button (click)="onMoveScroll(filesAreaContainer, 'UP')" [disabled]="filesAreaContainer?.scrollTop <= 0"
				*ngIf="filesAreaContainer?.scrollHeight>filesAreaContainer?.offsetHeight"
				class="btn btn-sm btn-outline-light directional-btn">
				<i class="fa fa-chevron-up text-muted" aria-hidden="true"></i>
			</button>
			<div class="mtm-tab-content-container d-flex">
				<div class="mtm-tab-content flex-fill d-flex align-items-center justify-content-center"
					*ngIf="!publicReviewLink" [ngClass]="{ 'active': activeTab === 'upload' }"
					(click)="activeTab='upload'">
					<div class="tab-icon upload-icon mr-2"></div>
					<div class="mtm-tab-title">Upload</div>
				</div>
				<div class="mtm-tab-content flex-fill d-flex align-items-center justify-content-center"
					[ngClass]="{ 'active': activeTab === 'download' }" (click)="activeTab='download'">
					<div class="tab-icon download-icon mr-2"></div>
					<div class="mtm-tab-title">Download</div>
				</div>
			</div>
			<div class="container-uploading-files d-flex flex-column" *ngIf="activeTab==='upload'" #filesAreaContainer>
				<span *ngIf="!allActivitiesSum" class="text-info d-block m-auto">{{'noFileUpload' |
					translate}}</span>
				<div class="p-2" *ngFor="let file of listLatestFourItems">
					<selector-file-upload-item [file]=file>
					</selector-file-upload-item>
				</div>
			</div>
			<div class="container-downloading-files d-flex flex-column" *ngIf="activeTab==='download'"
				#filesAreaContainer>
				<span *ngIf="!downloadList.length" class="text-info d-block m-auto">{{'noFileDownload' |
					translate}}</span>
				<mtm-file-download-list *ngIf="downloadList.length"></mtm-file-download-list>
			</div>
			<button (click)="onMoveScroll(filesAreaContainer, 'DOWN')"
				[disabled]="filesAreaContainer?.scrollTop + filesAreaContainer?.offsetHeight == filesAreaContainer?.scrollHeight"
				*ngIf="filesAreaContainer?.scrollHeight > filesAreaContainer?.offsetHeight"
				class="btn btn-sm btn-outline-light directional-btn">
				<i class="fa fa-chevron-down text-muted" aria-hidden="true"></i>
			</button>
			<div
				class="w-100 text-right position-absolute alwasys-run-background alwasys-run-background-ext-1 alwasys-run-background-ext-2 alwasys-run-background-ext-3 pt-1 pb-1 border-top">
				<label class="w-100 mb-0 pl-3 pr-3 d-flex flex-row align-items-center justify-content-end">
					<input class="mr-1" type="checkbox" name="bgMode" [(ngModel)]="isBackground"
						(ngModelChange)="onChangeBackgroundMode()">
					<span class="text-danger">Always run in background.</span>
				</label>
			</div>
		</div>
	</div>
</div>
