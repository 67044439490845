<div class="component-container subscription-register-component pl-3 pr-3 pt-3 pb-5 m-auto">
	<div class="component-head d-flex flex-column">
		<div [style.text-transform]="'uppercase'" [style.color]="'#F4A143'"
			class="component-title d-flex align-items-center justify-content-center pt-3">
			<span class="_600">{{'subscription_upgrade_workflow_title' | translate}}</span>
		</div>
		<div [style.color]="'#03041F'" [style.font-size]="'1.25rem'"
			class="component-sub-title d-flex align-items-center justify-content-center p-3">
			<span class="font-weight-bold">{{'subscription_upgrade_workflow_chooseYourPlan' | translate}}</span>
		</div>
	</div>
	<div class="component-body d-flex flex-column" [style.font-size]="'13px'">
		<div class="register-step-tab flex-fill d-flex align-items-center justify-content-center flex-nowrap"
			[style.font-weight]="'600'">

			<div *ngFor="let tab of tabList"
				class="tab-container p-3 d-flex align-items-center justify-content-center flex-fill tab-item"
				[style.background-color]="tab.bgColor" [class.disabled]="tab.disabled">
				<a [routerLink]="[tab.url]">
					<div class="d-flex align-items-center">
						<span class="mr-2">{{tab.index+1}}</span>
						<span class="d-inline-block">{{ tab.label | translate }}</span>
					</div>
				</a>
			</div>
		</div>
		<div class="content-container flex-fill" [style.background-color]="'#FFFFFF'">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>