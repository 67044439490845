import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ProdPartnerService {

	constructor(private api: ApiService, public auth: AuthService) {
	}

	public saveProdPartnerContent(projectId: string, content: any, isOneShot?: boolean): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/prodPartner/${isOneShot ? 'one-shot/' : ''}content`, content)
	}

	public sendEmailToMTMAdmin(projectId: string, contactInfo: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/prodPartner/one-shot/sendContactInformationToAdmin`, contactInfo)
	}

	public getProdPartnerContent(projectId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/prodPartner/content`)
	}

	public saveProdPartnerScheduleAndPricing(projectId: string, scheduleAndPricing: any, isOneShot?: boolean): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/prodPartner/${isOneShot ? 'one-shot/' : ''}scheduleAndPricing`, scheduleAndPricing)
	}

	public getProdPartnerScheduleAndPricing(projectId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/prodPartner/scheduleAndPricing`)
	}

	public savePreferences(preferences: any, stripeAccountToken): Observable<any> {
		return this.api.httpPost(`/api/companies/${preferences.companyId}/companyPortfolioPreferences/${stripeAccountToken}`, preferences)
	}

	public getPreferences(companyId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/companyPortfolioPreferences`)
	}

	public getProdPartnerValues(projectId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/prodPartnerValues`)
	}

	public saveProjectInvitationSuggestions(projectId: string, company: string): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/prodPartner/invitationSuggestion`, company)
	}
}
