import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { HelperService } from '../services/helper.service';
import { ProjectService } from '../services/project.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { Location } from '@angular/common';
import { TranslatePipe } from '../pipes/translate.pipe';
import { Authority, PermissionService } from '../services/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    ngUnsubscribe = new Subject();
    private users: any;
    projectId: any;
    userBlocked: boolean;
    userName: any = '';
    urlProjectId: any = '';
    currentProjectId: any[] = [];
    pId: any[] = [];

    constructor(private route: Router, private auth: AuthService,
        private service: ProjectService,
        private userService: UserService,
        private notificationService: NotificationService,
        private location: Location,
        private permissionsService: PermissionService,
        private translatePipe: TranslatePipe
    ) {
        if (this.auth.getAuthUser() === null || this.auth.isTokenExpired()) {
            this.urlProjectId = location.prepareExternalUrl(location.path()).split('/')
            this.getProjectId();
            this.loadUsers();
            this.userAccess();
        }
    }

    /**
     * Validate if user is logged in. If not, redirect to Login page
     * @param route
     * @param state
     * @returns {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const authUser = this.auth.getAuthUser();
        const isAdminUser = this.isAdmin();
        if (this.auth.getAuthUser() === null || this.auth.isTokenExpired()) {
            this.route.navigate(['/entry'], { queryParams: { returnUrl: state.url } });
            return false;
        } else {
            if (this.urlProjectId[2] === this.projectId && this.userBlocked === true) {
                return true;
            } else {
                const subscription = this.auth.getAuthUserSubscription();
                if (HelperService.isObjectEmpty(subscription) || isAdminUser || authUser.globalRole === 'ADMIN') {
                    return true;
                } else {
                    if (!subscription.planType) {
                        this.route.navigate(['/subscription/register']);
                    } else {
                        return true;
                    }
                }
            }
        }
    }

    isAdmin() {
        return this.permissionsService.hasAuthority(Authority.Z, null);
    }

    public getProjectId() {
        this.service.getProjects().pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data: any) => {
            // const newdata = data.filter(x => x.id);
            this.currentProjectId = data.filter(val => val.id === this.urlProjectId[2]);
            this.pId = this.currentProjectId.map(p => p.id);
            this.projectId = this.pId[0];
        });
    }

    public userAccess() {
        this.service.getProjects().pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data: any) => {
            const currentProjectId = data.filter(val => val.id === this.urlProjectId[2]);
            const participant = currentProjectId.map(p => p.participants)
            participant.forEach(data => {
                data.forEach(x => {
                    if (this.userName.username == x.username) {
                        this.userBlocked = x.blocked;
                        if (this.userBlocked === true) {
                            this.notificationService.open({
                                title: this.translatePipe.transform('userBlocked'),
                                description: this.translatePipe.transform('userBlocked_Text'),
                                confirmBtn: this.translatePipe.transform('ok')
                            })

                        }
                    }
                })

            });

        });
    }

    public loadUsers() {
        this.users = Array<any>();
        let getUserByUsername = () => {
            this.userService
                .findUserByUsername(this.auth.getAuthUserName(), false, true).pipe(
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe((data) => {
                    this.userName = data;
                });
        }
        getUserByUsername();
    }
}
