
import { from as observableFrom, Subject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import * as moment from "moment";
import "moment-timezone";
import { environment } from 'environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { tap, share, finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private cache: any = {};
	private cachedObservable: any = {};

	public usersList: any = {};
	public users: any = [];

	constructor(
		private authService: AuthService,
		private apiService: ApiService,
		private _http: HttpClient
	) { }

	getToken(): Observable<any> {
		return this.apiService.httpGet('/api/users/token');
	}

	getUserByUsername(username: string): Observable<any> {
		if (!this.usersList[username]) {
			this.usersList[username] = {
				username: username
			};
			let promise = new Promise((resolve, reject) => {
				let user = _.find(this.users, (usr) => usr.username === username);
				resolve(user || {});
			});
			return observableFrom(promise);
		} else {
			let promise = new Promise((resolve, reject) => {
				resolve(this.usersList[username]);
			});
			return observableFrom(promise);
		}
	}

	findUserByUsername(username: string, fromCache: boolean, cacheRequest: boolean): Observable<any> {
		if (fromCache) {
			let user = _.find(this.users, (usr) => usr.username === username);
			return of(user || { username });
		} else {
			let observable: Observable<any>;
			if (cacheRequest) {
				if (this.cache[username]) {
					observable = of(this.cache[username]);
				} else if (this.cachedObservable[username]) {
					observable = this.cachedObservable[username];
				} else {
					this.cachedObservable[username] = this.apiService.httpGet('/api/users/' + username)
						.pipe(
							tap(res => this.cache[username] = res),
							share(),
							finalize(() => {
								this.cachedObservable[username] = void 0;
								this.cache[username] = void 0;
							})
						)
					observable = this.cachedObservable[username];
				}
			} else {
				observable = this.apiService.httpGet('/api/users/' + username);
			}
			return observable;
		}
	}

	getSingleUserInfo(username): Observable<any> {
		return this.apiService.httpGet('/api/users/' + username);
	}

	getUsersByCompanyId(companyId: string): Observable<any> {
		return this.apiService.httpGet('/api/users/company/' + companyId);
	}

	getProposalTeam(projectId: string) {
		let subject = new Subject<any[]>();
		this.apiService.httpGet('/api/users/proposal/proposalTeam/' + projectId).subscribe(
			(data: any) => {
				let users = new Array<any>();
				data.forEach((user: any) => {
					if (!user.avatarUrl)
						user.avatarUrl = HelperService.getAvatarNoImage();
					else
						user.avatarUrl = HelperService.getFixedAvatarUrl(user.avatarUrl);

					users.push(user);
				});

				subject.next(users);
			},
			(err: any) => {
				subject.next(null);
			});
		return subject.asObservable();
	}

	updateProfileSettings(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + '/profile-settings', data);
	}

	getUserProfileSettings(): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName() + '/profile-settings');
	}

	getPortfolioPreferences(username: string = null): Observable<any> {
		if (!username) {
			username = this.authService.getAuthUserName();
		}
		return this.apiService.httpGet(`/api/users/${username}/portfolio/preferences`);
	}

	getAccountSettings(): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName());
	}

	updateUser(data: any, extend?: boolean): Observable<any> {
		let subject = new Subject<any>();
		this.apiService.httpPut('/api/users/' + data.username, extend ? data : {
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.phone,
			email: data.email,
			jobTitle: data.jobTitle,
			password: data.password
		}).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			});

		return subject.asObservable();
	}

	checkUserPassword(password: string): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + '/userprofilesettings/checkPassword', password);
	}

	updateUserPhone(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + data.username + '/phone/' + data.phone, null);
	}

	changePassword(currentPassword: string, newPassword: string, reNewPassword: string): Observable<any> {
		return this.apiService.httpPut('/api/users/' + this.authService.getAuthUserName() + '/password', {
			currentPassword: currentPassword,
			newPassword: newPassword,
			reNewPassword: reNewPassword,
			token: this.authService.getAuthToken()
		});
	}

	getUsersByActivationStatus(activationStatus: string, pagingState: string = null, includeBrandId?: boolean): Observable<any> {
		let subject = new Subject<any[]>();
		let url = `/api/users/activation-status/${activationStatus}`;
		if (pagingState && pagingState != "") {
			url = url + `?pagingState=${pagingState}`;
		}
		if (includeBrandId) {
			url += ((pagingState && pagingState != "") ? '&includeBrandId=true' : 'includeBrandId=true')
		}
		this.apiService.httpGet(url).subscribe(
			(result: any) => {
				result.data.forEach((user: any) => {
					if (!user.avatarUrl) {
						user.avatarUrl = HelperService.getAvatarNoImage();
					}
					else {
						user.avatarUrl = HelperService.getFixedAvatarUrl(user.avatarUrl);
					}
				});
				subject.next(result);
			},
			(err: any) => {
				subject.next(null);
			});
		return subject.asObservable();
	}

	getUsersByActivationStatusWithQueryParams(
		activationStatus: string,
		pagingState: string,
		fullName: string,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		includeBrandId?: boolean
	): Observable<any[]> {
		let subject = new Subject<any[]>();
		let url = `/api/users/activation-status/${activationStatus}?fullName=${fullName}&firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}&`;
		if (pagingState && pagingState != "") {
			url = url + `&pagingState=${pagingState}`;
		}
		if (includeBrandId) {
			url += ((pagingState && pagingState != "") ? '&includeBrandId=true' : 'includeBrandId=true')
		}
		this.apiService.httpGet(url).subscribe(
			(result: any) => {
				result.data.forEach((user: any) => {
					if (!user.avatarUrl) {
						user.avatarUrl = HelperService.getAvatarNoImage();
					}
					else {
						user.avatarUrl = HelperService.getFixedAvatarUrl(user.avatarUrl);
					}
				});
				subject.next(result);
			},
			(err: any) => {
				subject.next(null);
			});
		return subject.asObservable();

	}

	getPortfolioWorks(username: string = null): Observable<any> {
		if (!username) {
			username = this.authService.getAuthUserName();
		}
		return this.apiService.httpGet(`/api/users/${username}/portfolio/works`);
	}

	getPortfolioWork(username: any, id: any): Observable<any> {
		let subject = new Subject<any>();
		if (!username) {
			username = this.authService.getAuthUserName();
		}
		this.apiService.httpGet(`/api/users/${username}/portfolio/works`).subscribe(
			(data: any) => {
				let workFound = null;
				if (data && Array.isArray(data)) {
					data.forEach((work: any) => {
						if (work.id == id) {
							workFound = work;
						}
					})
				}
				subject.next(workFound);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
	 * Return list of experiences
	 * If username is null, then take logged in user
	 * @param username
	 */
	getPortfolioExperiences(username: string = null): Observable<any> {
		let subject = new Subject<any>();
		if (username == null) {
			username = this.authService.getAuthUserName();
		}
		this.apiService.httpGet(`/api/users/${username}/portfolio/experiences`).subscribe(
			(data: any) => {
				let experiences = [];
				if (data && Array.isArray(data)) {
					data.forEach((exp: any) => {
						exp.startDate = (exp.startDate) ? DateHelperService.fromTimestampToDate(exp.startDate) : null;
						exp.endDate = (exp.endDate) ? DateHelperService.fromTimestampToDate(exp.endDate) : null;
						experiences.push(exp);
					});
				}
				subject.next(experiences);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
	 * Save (create or update) a portfolio work
	 * @param data
	 */
	savePortfolioWork(data: any): Observable<any> {
		if (!data.id) {
			return this.createPortfolioWork(data);
		}
		else {
			return this.updatePortfolioWork(data);
		}
	}

	/**
	 * Create a new portfolio work
	 * @param data
	 */
	private createPortfolioWork(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/portfolio/works', data);
	}

	/**
	 * Save an existing portfolio work
	 * @param data
	 */
	private updatePortfolioWork(data: any): Observable<any> {
		let id = data.id;
		delete data.id;
		return this.apiService.httpPut(`/api/users/portfolio/works/${id}`, data);
	}

	/**
	 * Delete portfolio work by id
	 * @param workId
	 */
	deletePortfolioWork(workId: string): Observable<any> {
		return this.apiService.httpDelete(`/api/users/portfolio/works/${workId}`);
	}

	/**
	 * Return files from portfolio work
	 */
	getPortfolioWorkFiles(workId: string) {
		return this.apiService.httpGet(`/api/users/portfolio/works/${workId}/files`);
	}

	/**
	 * Save (create or update) a user experience
	 * @param data
	 */
	savePortfolioExperience(data: any): Observable<any> {
		if (!data.id) {
			return this.createPortfolioExperience(data);
		}
		else {
			return this.updatePortfolioExperience(data);
		}
	}

	/**
	 * Create a new user experience
	 * @param data
	 */
	private createPortfolioExperience(data: any): Observable<any> {
		let subject = new Subject<any>();
		this.apiService.httpPost('/api/users/portfolio/experiences', data).subscribe(
			(data: any) => {
				if (data) {
					data.startDate = (data.startDate) ? DateHelperService.fromTimestampToDate(data.startDate) : null;
					data.endDate = (data.endDate) ? DateHelperService.fromTimestampToDate(data.endDate) : null;
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
	 * Update an existing experience
	 * @param data
	 */
	private updatePortfolioExperience(data: any): Observable<any> {
		let subject = new Subject<any>();
		let id = data.id;
		delete data.id;
		this.apiService.httpPut(`/api/users/portfolio/experiences/${id}`, data).subscribe(
			(data: any) => {
				if (data) {
					data.startDate = (data.startDate) ? DateHelperService.fromTimestampToDate(data.startDate) : null;
					data.endDate = (data.endDate) ? DateHelperService.fromTimestampToDate(data.endDate) : null;
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
	 * Delete exprience from user
	 * @param experienceId
	 */
	deletePortfolioExperience(experienceId): Observable<any> {
		return this.apiService.httpDelete(`/api/users/portfolio/experiences/${experienceId}`);
	}

	/**
	 * Delete file from portfolio work
	 * @param workId
	 * @param fileId
	 */
	deletePortfolioWorkFile(workId: string, fileId: string): Observable<any> {
		return this.apiService.httpDelete(`/api/users/portfolio/works/${workId}/files/${fileId}`);
	}

	/**
	 * Return list of awards
	 * If username is null, then take logged in user
	 * @param username
	 */
	getPortfolioAwards(username: string = null): Observable<any> {
		let subject = new Subject<any>();
		if (username == null) {
			username = this.authService.getAuthUserName();
		}
		this.apiService.httpGet(`/api/users/${username}/portfolio/awards`).subscribe(
			(data: any) => {
				let awards = [];
				if (data && Array.isArray(data)) {
					data.forEach((award: any) => {
						award.startDate = (award.startDate) ? DateHelperService.fromTimestampToDate(award.startDate) : null;
						award.endDate = (award.endDate) ? DateHelperService.fromTimestampToDate(award.endDate) : null;
						awards.push(award);
					});
				}
				subject.next(awards);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
 * Save (create or update) a user experience
 * @param data
 */
	savePortfolioAward(data: any): Observable<any> {
		if (!data.id)
			return this.createPortfolioAward(data);
		else
			return this.updatePortfolioAward(data);
	}


	/**
	 * Create a new user award
	 * @param data
	 */
	private createPortfolioAward(data: any): Observable<any> {

		let subject = new Subject<any>();

		this.apiService.httpPost('/api/users/portfolio/awards', data).subscribe(
			(data: any) => {
				if (data) {
					data.startDate = (data.startDate) ? DateHelperService.fromTimestampToDate(data.startDate) : null;
					data.endDate = (data.endDate) ? DateHelperService.fromTimestampToDate(data.endDate) : null;
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}


	/**
	 * Update an existing award
	 * @param data
	 */
	private updatePortfolioAward(data: any): Observable<any> {

		let subject = new Subject<any>();

		let id = data.id;
		delete data.id;

		this.apiService.httpPut(`/api/users/portfolio/awards/${id}`, data).subscribe(
			(data: any) => {
				if (data) {
					data.startDate = (data.startDate) ? DateHelperService.fromTimestampToDate(data.startDate) : null;
					data.endDate = (data.endDate) ? DateHelperService.fromTimestampToDate(data.endDate) : null;
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}


	/**
	 * Delete exprience from user
	 * @param experienceId
	 */
	deletePortfolioAward(awardId): Observable<any> {
		return this.apiService.httpDelete(`/api/users/portfolio/awards/${awardId}`);
	}



	/**
	 * Save portfolio preferences
	 * @param data
	 */
	savePortfolioPreferences(data: any) {
		return this.apiService.httpPost('/api/users/portfolio/preferences', data);
	}

	getAuthenticatorInfo(username: string) {
		return this.apiService.httpGet(`/api/users/${username}/profile-settings/mfa/authenticator`);
	}

	updateAuthenticatorInfo(username: string, token: string, factorId: string) {
		return this.apiService.httpPost(
			`/api/users/${username}/profile-settings/mfa/authenticator?token=${token}&factorId=${factorId}`,
			{});
	}

	/**
	 * Delete user
	 * @param user
	 */
	deleteUser(user: any): Observable<any> {
		return this.apiService.httpDelete('/api/users/' + user.username);
	}

	deleteMultipleUsers(usernames: string[]) {
		return this.apiService.httpDelete(`/api/users/multiDeleteUser`, false, usernames);
	}

	sendInvitationEmail(email: any): Observable<any> {
		return this.apiService.httpPost('/api/registration/resendEmail/' + email, {});
	}

	disableUser(email: string) {
		return this.apiService.httpPut(`/api/users/${email}/block`, {});
	}

	enableUser(email: string) {
		return this.apiService.httpPut(`/api/users/${email}/unblock`, {});
	}


	/**
	 * Return array of languages
	 */
	getLanguages() {

		// TODO Move to hardcode options
		return [
			{ id: 'en_us', label: 'English', i18n: 'LANG_ENGLISH' },
			{ id: 'fr', label: 'Français (France)', i18n: 'LANG_FRANCAIS' },
			{ id: 'es', label: 'Spanish', i18n: 'LANG_SPANISH' },
			{ id: 'pt', label: 'Portuguese', i18n: 'LANG_PORTUGUESE' },
			{ id: 'zh', label: 'Simplified Chinese', i18n: 'LANG_CHINESE' }
		];
	}


	/**
	 * Return start week on list
	 */
	getStartWeekOns() {
		return [
			{ id: 'su', label: 'Sunday', i18n: 'DAY_SUNDAY' },
			{ id: 'mo', label: 'Monday', i18m: 'DAY_MONDAY' },
			{ id: 'st', label: 'Saturday', i18m: 'DAY_SATURDAY' },
		]
	}


	/**
	 * Return date formats
	 */
	getDateFormats() {
		return [
			{ id: 'MM/DD/YYYY', label: 'MM/DD/YYYY (eg. 03/20/2017)', i18n: 'DATE_FORMAT_MMDDYYYY' },
			{ id: 'DD/MM/YYYY', label: 'DD/MM/YYYY (eg. 20/03/2017)', i18n: 'DATE_FORMAT_DDMMYYYY' },
			{ id: 'YYYY-MM-DD', label: 'YYYY-MM-DD (eg. 2017-03-20)', i18n: 'DATE_FORMAT_YYYYMMDD' },
			{ id: 'YYYY-DD-MM', label: 'YYYY-DD-MM (eg. 2017-20-03)', i18n: 'DATE_FORMAT_YYYY-DD-MM' },
			{ id: 'YYYY/DD/MM', label: 'YYYY/DD/MM (eg. 2017/20/03)', i18n: 'DATE_FORMAT_YYYY/DD/MM' },
			{ id: 'YYYY/MM/DD', label: 'YYYY/MM/DD (eg. 2017/03/20)', i18n: 'DATE_FORMAT_YYYY/MM/DD' },
			{ id: 'MMMM Do, YYYY', label: 'MMMM Do, YYYY (eg. March 20th, 2017)', i18n: 'DATE_FORMAT_MMMMDOYYYY' },
		]
	}


	/**
	 * Return time formats
	 */
	getTimeFormats() {
		return [
			{ id: '24hs', label: '24 hour clock (eg. 16:20)', i18n: 'TIME_FORMAT_24HS' },
			{ id: '12hs', label: '12 hour clock (eg. 4:20pm)', i18n: 'TIME_FORMAT_12HS' }
		]
	}


	/**
	 * Return time displays
	 */
	getTimeDisplays() {
		return [
			{ id: 'HH:MM', label: 'HH:MM (eg. 02:15)', i18n: 'TIME_DISPLAY_HH_MM' },
			{ id: 'HHMM', label: 'HHMM (eg. 0215)', i18n: 'TIME_DISPLAY_HHMM' }
		]
	}


	/**
	 * Return number formats
	 */
	getNumberFormats() {
		return [
			{ id: 'us', label: '1,234.56', i18n: 'NUMBER_FORMAT_US' },
			{ id: 'int', label: '1.234,56', i18n: 'NUMBER_FORMAT_INT' },
			{ id: 'fr-CA', label: '1 234', i18n: 'NUMBER_FORMAT_FR' }
		]
	}


	/**
	 * Return currencies
	 */
	getCurrencies() {
		return [
			//{ id: 'AUD', label: 'Australian Dollar (AUD)', symbol: 'AUD', i18n: 'CURRENCY_AUD' },
			//{ id: 'BRL', label: 'Brazilian Real (R$)', symbol: 'R$', i18n: 'CURRENCY_BRL' },
			//{ id: 'CAD', label: 'Canadian Dollar (CAD)', symbol: 'CAD', i18n: 'CURRENCY_CAD' },
			{ id: 'EUR', label: 'Euro (€)', symbol: '€', i18n: 'CURRENCY_EURO' },
			//{ id: 'HDK', label: 'Hong Kong Dollar (HK$)', symbol: 'HK$', i18n: 'CURRENCY_HDK' },
			//{ id: 'JPY', label: 'Japanese Yen (¥)', symbol: '¥', i18n: 'CURRENCY_JPY' },
			//{ id: 'MXN', label: 'Mexican Peso (MXN)', symbol: 'MXN', i18n: 'CURRENCY_MXN' },
			//{ id: 'GBP', label: 'Pound sterling (£)', symbol: '£', i18n: 'CURRENCY_GBP' },
			//{ id: 'CHF', label: 'Swiss Franc (CHF)', symbol: 'CHF', i18n: 'CURRENCY_CHF' },
			//{ id: 'TRY', label: 'Turkish Lira (₺)', symbol: '₺', i18n: 'CURRENCY_try' },
			{ id: 'USD', label: 'U.S. Dollar ($US)', symbol: '$US', i18n: 'CURRENCY_USD' }
		]
	}


	/**
	 * Return list of timezones
	 */
	getTimezones() {

		let timezones = new Array<any>();

		let momentTimezones = moment.tz.names();
		momentTimezones.forEach(tzName => {
			timezones.push({ id: tzName, label: HelperService.formatTimezoneName(tzName) });
		})

		return timezones;
	}



	getNotificationsSettings(workspacId: string): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName() + `/${workspacId}/usernotificationssettings`);
	}

	getUserNotificationsSettings(workspacId: string, projectId: string): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName() + `/${workspacId}/${projectId}/usernotificationssettings`);
	}

	getProjectNotificationsSettings(workspaceId): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName() + `/${workspaceId}/userprojectsnotificationssettings`);
	}

	getPlatformNotificationsSettings(): Observable<any> {
		return this.apiService.httpGet('/api/users/' + this.authService.getAuthUserName() + `/usernotificationssettings`);
	}

	updateProjectNotificationsSettings(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + `/${data.workspaceId}/${data.projectId}/usernotificationssettings`, data);
	}

	updateNotificationsSettings(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + `/${data.workspaceId}/usernotificationssettings`, data);
	}

	updateUserNotificationsSettings(workspaceId: string, projectId: string, data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + `/${workspaceId}/${projectId}/usernotificationssettings`, data);
	}

	updatePlatformNotificationsSettings(data: any): Observable<any> {
		return this.apiService.httpPost('/api/users/' + this.authService.getAuthUserName() + `/usernotificationssettings`, data);
	}
	public jops: any[] = [
		{ code: 'MANAGER', label: 'Manager' },
		{ code: 'EDITOR', label: 'Editor' },
		{ code: 'CORDINATOR', label: 'Cordinator' },
		{ code: 'STAJER', label: 'Stajer' },
		{ code: 'DIRECTOR', label: 'Director' },
	];

	changeUserDelegation(username): Observable<any> {
		return this.apiService.httpPut(`/api/users/${username}/changeDelegation`, null);
	}

	changeUserProfileSettingLang(lang): Observable<any> {
		return this.apiService.httpPost(`/api/users/${this.authService.getAuthUserName()}/profile-settings/lang/${lang}`, {})
	}

	public getProfileBlob(username: any, version: any) {
		if (username) {
			this._http.get<Blob>(
				environment.web.avatarUrl.replace('%USERNAME%', username) + '&_v=' + version,
				{
					observe: 'response',
					responseType: 'blob' as 'json'
				}
			).subscribe((res: HttpResponse<Blob>) => {
				let binaryData = [];
				binaryData.push(res.body);
				if (!this.usersList[username]) {
					this.usersList[username] = {
						username: username
					};
				}
				let data = this.users.find((user) => user.username === username);
				this.usersList[username] = {
					blobPicture: window.URL.createObjectURL(new Blob(binaryData, { type: 'image' })),
					...data
				}
			}, (err) => {
				if (!this.usersList[username]) {
					this.usersList[username] = {
						username: username
					};
				}
			});
		}
	}

	public getUserInfo(email) {
		return _.trim((this.users.find(user => user.username === email) || {}).fullName) || (email || '').toLowerCase();
	}
	
	getTeams(companyId: string){
		return this.apiService.httpGet(`/api/teams/${companyId}`)
	}
	
	getWorkspaceTeams(workspaceId: string){
		return this.apiService.httpGet(`/api/teams/by-workspace/${workspaceId}`)
	}

	addTeamMember(data) {
		return this.apiService.httpPut('/api/teams/add-members',data);
	}
	
	removeTeamMember(data) {
		return this.apiService.httpPut('/api/teams/remove-members',data);
	}
	
	public getPossibleOwnersForProject(projectId: string, username: string): Observable<any>{
		return this.apiService.httpGet(`/api/users/${username}/${projectId}/availableUsersProject`);
	}

	transferOriginalOwnership(projectId: string, oldOwner: string, newOwner: string): Observable<any> {
		return this.apiService.httpPost(`/api/users/${oldOwner}/originalownership?projectId=${encodeURIComponent(projectId)}&newUserName=${encodeURIComponent(newOwner)}`, {});
	}

	transferOwnership(username: string, targets: Record<string, string[]>, removeUser: boolean): Observable<any> {
		return this.apiService.httpPost(`/api/users/${username}/ownership?removeUser=${removeUser}`, targets);
	}
}
