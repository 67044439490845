import { Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
	selector: 'mtm-download-upload-progress-icon',
	templateUrl: './mtm-download-upload-progress-icon.component.html',
	styleUrls: ['./mtm-download-upload-progress-icon.component.scss']
})
export class MTMDownloadUploadProgressIcon implements OnInit {
	@Input() isOnLoading: boolean = false;
	@Input() widthInPixel: any = 'auto';
	@Input() iconColor: any = 'white';

	constructor(private elementRef: ElementRef) { }

	ngOnInit(): void { }
}
