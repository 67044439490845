import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { Authority, PermissionService } from "../services/permissions.service";

@Injectable()
export class ProjectCreationGuard implements CanActivate {
    hasLtgPermission: boolean = false;

    constructor(private route: Router, private authService: AuthService, private permissionService: PermissionService) {
		this.hasLtgPermission = JSON.parse(localStorage.getItem('hasLtgPermission'));
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        // If no logged in, redirect to /login
        if (!this.authService.getAuthUser()) {
            this.route.navigate(['/entry']);
            return false;
        }

        if (this.permissionService.hasAuthority(Authority.P, null)) {
            return true;
        } else { 
            if (this.hasLtgPermission) {
                this.route.navigate(['/workspaces/projects']);
            } else {
                this.route.navigate(['/projects']);
            }
            return false;
        }
    }
}