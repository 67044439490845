<div class="modal-header">
	<h5 class="modal-title ml-3">{{ subSection.name }}</h5>
	<button type="button" class="close mr-3" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body" style="min-width: 400px;">
	<form [formGroup]="editSubSectionForm" (ngSubmit)="onSubmit()">
		<ng-template #popContent let-error="message">{{ error }}!</ng-template>
		<div class="row" [ngbPopover]="popContent" triggers="manual" #popover="ngbPopover"
			container="body" placement="bottom" (click)="popover.isOpen() ? popover.close() : null">
			<div class="col">
				<div class="form-group mb-1" [ngClass]="{'has-danger': isValidDate()}">
					<div class="input-group">
						<input class="form-control date-picker" formControlName="start_date" ngbDatepicker
							#start="ngbDatepicker" placeholder="Start Date" firstDayOfWeek="1" [minDate]="minDate"
							[maxDate]="maxDate" (click)="start.toggle()">
						<div class="input-group-addon" (click)="start.toggle()" [mtmDatePickerDirective]=start>
							<i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="form-group mb-1 " [ngClass]="{'has-danger': isValidDate()}">
					<div class="input-group">
						<input class="form-control date-picker" formControlName="end_date" ngbDatepicker
							#end="ngbDatepicker" placeholder="End Date" firstDayOfWeek="1" [minDate]="minDate"
							[maxDate]="maxDate" (click)="end.toggle()">
						<div class="input-group-addon" (click)="end.toggle()" [mtmDatePickerDirective]=end>
							<i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div *ngIf="isValidDate()" class="col text-danger mb-3">
				Start date should be before the end date
			</div>
		</div>

		<div class="row">
			<div class="col">
				<label class="form-group has-float-label" [ngClass]="{'has-danger': isValid('description')}">
					<textarea class="form-control" placeholder=" " formControlName="description" rows="8"></textarea>
					<span class="notes-label">Description</span>
				</label>
			</div>
		</div>
		<div class="row justify-content-between">
			<div class="col d-flex align-center">
				<button *ngIf="exists()" class="btn btn-outline-secondary" type="button" (click)="delete()">
					<i class="fa fa-trash fa-fw" aria-hidden="true"></i>
					<span class="text-uppercase">Remove Sub-Stage</span>
				</button>
			</div>
			<div class="col text-right">
				<button class="btn btn-danger btn-lg" type="submit" [disabled]="sending">
					<span>Save Changes</span>
				</button>
			</div>
		</div>
	</form>
</div>