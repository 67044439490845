import { DomSanitizer } from "@angular/platform-browser";

var domSanitizer: DomSanitizer;

export const setDOMSanitizer = sane => {
    domSanitizer = sane;
}

export const getIconURL = (file) => {
    if (file.id)
        return domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/' + file.contentType.substr(file.contentType.indexOf('/') + 1) + '.svg');
    else
        return domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/' + (file.fileType || file.type).substr((file.fileType || file.type).indexOf('/') + 1) + '.svg');
}

export const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; }
    else if (bytes > 1) { bytes = bytes + ' bytes'; }
    else if (bytes == 1) { bytes = bytes + ' byte'; }
    else { bytes = '0 byte'; }
    return bytes;
}