import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    // Event store
    private _keys: { [ID: string]: any } = {};

    constructor(private apiService: ApiService) { }

    getRoles(): Observable<any> {

        let subject = new Subject<any>();

        // If roles saved
        if (this._keys['roles']) {
            setTimeout(() => {
                subject.next(this._keys['roles']);
            }, 100)
        }
        else {

            this.apiService.httpGet('/api/util/roles').subscribe(
                (data: any) => {
                    this._keys['roles'] = data;
                    subject.next(data);
                },
                (err: any) => {
                    subject.error(err);
                });
        }

        return subject.asObservable();
    }
}
