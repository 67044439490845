import * as _ from 'lodash';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import {
	onCancelAddingAnnotation,
	onImageZoomChanged,
	onImagePluginReady,
	onAnnotationsLoaded,
	onAddingAnnotation,
	onUserColourCreated,
	AnnotationService
} from "app/shared/services/annotation.service";
import { AuthService } from 'app/shared/services/auth.service';
import { ImageAnnotationPlugin } from "./plugin/image-annotation.plugin";
import { TranslateService } from "app/shared/services/translate.service";
import { EmitterService } from 'app/shared/services/emitter.service';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { wsListenerANNOTATION_CREATED } from 'app/shared/services/mtm-websocket.service';
import { commentFileAdd } from 'app/shared/services/comments.service';
import { first, takeUntil } from "rxjs/operators";
import { AnnotationPrivateCondition } from "../../interfaces";
import { Subject } from 'rxjs';

@Component({
	selector: 'mtm-image-annotation',
	styleUrls: ['./image-annotation.component.scss'],
	templateUrl: './image-annotation.component.html'
})
export class ImageAnnotation implements OnInit, OnDestroy {
	@ViewChild('imageAnnotationMarkerContainer') imageAnnotationMarkerContainer: ElementRef;
	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() projectParticipants: any[];
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() item: any;
	@Input() annotations: any[];
	@Input() authUser: any;
	@ViewChild('imageAsset') imageAsset: ElementRef;

	annotatingMode: boolean = false;
	editingMode: boolean = false;
	isColorOpen: boolean = false;
	activeShape: string;
	config: any;
	annotationComment: string = '';
	pluginReady: boolean = false;
	annotationPlugin: ImageAnnotationPlugin;
	imageInitReady: boolean = false;
	imageReadyWait: boolean = false;
	activeAnnotation: any; // handled inside plugin
	isAnnotationModeActive: boolean = false;
	userInfo: any;
	userColors: any = {};
	isAnnotationDisplayEnabled: boolean = false; // handled inside plugin
	lang: any;
	drawingSelected: boolean = false // handled inside plugin
	private _imageZoom: number = 0.5;
	editingAnnotation: any;
	annotationToolbarActive: boolean = true;
	wsId: string;
	newFiles: any = [];
	privateCondition: AnnotationPrivateCondition = null;


	files: any = [];
	videoRecordFiles: any = [];
	audioRecordFiles: any = [];
	ngUnsubscribe = new Subject();

	constructor(
		private elementRef: ElementRef,
		private authService: AuthService,
		private stompService: MtmStompService,
		private translateService: TranslateService,
		private annotationService: AnnotationService
	) {
	}

	ngAfterViewInit(): void {
		this.wsId = this.stompService.subscribeToService();
		onAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationHandler(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onImageZoomChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setImageZoom(args.zoom)
		})
		onAnnotationsLoaded.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateMarker(args)
		})
		onUserColourCreated.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateBorders(args)
		})
		
		this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);
	}

	ngOnInit(): void {
		this.userInfo = _.find(this.projectParticipants, { username: this.authUser.username }) as any;
		this.lang = this.translateService.getLang();
		this.onImageInitReady();
		this.annotationService.privateConditionChange$.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (value: AnnotationPrivateCondition) => {
				this.privateCondition = value;
			}
		})
	}

	wsOnAnnotationCreate(args) {
		commentFileAdd.emit({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			comment: args.mediaAnnotation.comments[0],
			annotationId: args.mediaAnnotation.id,
			files: this.newFiles
		});
		this.cleanUpFiles();
	}

	checkInvalidUser() {
		let roles = this.userInfo?.roles || [];
		let isInvalidUser = false;
		if (roles.includes("PROJECT_STAFF") || roles.includes("PROJECT_SENIOR_STAFF") || roles.includes("PRODUCTION_STAFF") || roles.includes("PRODUCTION_SENIOR_STAFF")) {
			isInvalidUser = true;
		}

		return isInvalidUser;
	}

	onImageInitReady() {
		let _this2 = this;
		this.config = {
			userInfo: this.authUser,
			item: this.item,
			annotationObjects: this.annotations
		}
		// this.annotations = args.annotations;
		if (!this.imageInitReady) this.initPlugin();
		this.imageInitReady = true;
		// if (!this.imageReadyWait) this.imageReady();
	}

	filteredAnnotations() {
		let filteredAnnotations = this.annotationPlugin.annotationState.annotations.filter((a) => { if (!a.range.isGeneralComment) return a });
		return filteredAnnotations;
	}

	initPlugin() {
		this.annotationPlugin = new ImageAnnotationPlugin(this, this.config);
		// this.userInfo = this.config.userInfo;

		this.annotationPlugin.onReady(() => {
			this.pluginReady = true;
			onImagePluginReady.emit({
				act: 'onImagePluginReady',
				annotationPlugin: this.annotationPlugin
			});
			EmitterService.get('imageAnnotation.ready').emit();
		});
	}

	ngOnDestroy(): void { 
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.stompService.unsubscribeToService(this.wsId);
	}

	onCancelAddingAnnotationHandler(args: any) {
		this.resetUIComponent();
		this.isAnnotationModeActive = false;
	}

	onAddingAnnotationHandler(args: any) {
		this.isAnnotationModeActive = true;
	}

	toolbarClicked(type: string) {
		if (this.annotationPlugin.isImageMovable && type !== 'none') {
			this.toggleImageMove();
		}
		if (type === 'color') {
			this.isColorOpen = false;
		} else {
			if (type !== 'undo' && type !== 'redo' && type !== 'clear') {
				this.activeShape = type;
			}
		}
		this.annotationPlugin.fire("changeActiveTool", { tool: type });
	}

	isInValidInput() {
		return !this.annotationComment && !this.newFiles.length;
	}

	isAnnotActiveUser() {
		let isValid = false;
		if (this.activeAnnotation && this.activeAnnotation.type === "click") {
			if (this.activeAnnotation.annotation.comments[0].meta.user_id === this.authUser.username) {
				isValid = true;
			}
		}
		return isValid;
	}

	editAnnotation() {
		if (this.annotationPlugin && this.pluginReady && this.activeAnnotation && this.activeAnnotation.type === "click") {
			this.annotationPlugin.fire('editAnnotation', { annotation: this.activeAnnotation.annotation });
		}
	}

	saveAnnotation() {
		if (this.newFiles.length && !this.annotationComment) {
			this.annotationComment = ' ';
		}
		if (!this.editingMode) {
			this.addNewAnnotation();
		}
		else if (!this.annotatingMode && this.editingMode) {
			this.saveEditedAnnotation();
		}
	}

	saveEditedAnnotation() {
		if (this.annotationPlugin && this.editingMode && this.pluginReady && this.editingAnnotation) {
			this.annotationPlugin.fire('saveEditedAnnotation', { annotationId: this.editingAnnotation.id });
		}
	}

	addNewAnnotation() {
		if (this.annotationPlugin && this.annotatingMode && this.pluginReady) {
			this.annotationPlugin.fire('saveAnnotation');
		}
		else if (this.annotationPlugin && this.pluginReady && !this.annotatingMode && !this.editingMode) {
			this.annotationPlugin.fire('saveGeneralComment');
		}
	}

	canceladdNewAnnotation() {
		if (this.annotationPlugin && (this.annotatingMode || this.editingMode) && this.pluginReady) {
			this.annotationPlugin.fire('cancelAddingAnnotation');
		}
	}

	resetUIComponent() {
		this.annotationComment = '';
		this.isColorOpen = false;
		this.activeShape = '';
	}

	// TOGGLE ANNOTATION DISPLAY SECTION
	toggleAnnotationDisplay() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('toggleAnnotationMode');
		}
	}

	// MARKER SECTION
	updateBorders(args: any) {
		// Disabled function, use args.nameColor when enabling
		if (!(args.username in this.userColors)) {
			this.userColors[args.username] = "#FFFFFF";
		}
	}

	updateMarker(args: any) {
		if (args.itemId == this.item.id) {			
			this.annotations = args.data;
			this.annotationPlugin.teardown();

			this.config = {
				userInfo: this.authUser,
				item: this.item,
				annotationObjects: this.annotations
			}
			this.initPlugin();
			this.isAnnotationDisplayEnabled = true;
		} 
		if (_.get(this.activeAnnotation, 'annotation')) {
			this.activeAnnotation.annotation = _.find(args.data, { id: _.get(this.activeAnnotation, 'annotation.id') });
			if (args.previewAnnotation && this.activeAnnotation.annotation) {
				args.previewAnnotation = this.activeAnnotation.annotation;
			}
		}
	}

	hoverInAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverInAnnotation', { annotation: annotation });
		}
	}

	hoverOutAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverOutAnnotation', { annotation: annotation });
		}
	}

	annotationBarClick(annotation) {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('clickInAnnotation', { annotation: annotation });
		}
	}


	// IMAGE MOVE/ZOOM SECTION
	get imageZoom() {
		return this._imageZoom;
	}

	set imageZoom(zoom) {
		if (this.annotationPlugin && this.pluginReady) {
			this._imageZoom = zoom;
		}
	}

	setImageZoom(zoom, fromAng = false) {
		this.imageZoom = zoom;
		if (fromAng) {
			this.annotationPlugin.fire('setZoom', { zoom: this.imageZoom });
		}
	}

	setZoom(change) {
		let zoom = this.imageZoom + change;
		this.setImageZoom(zoom, true)
	}

	resetImageOrientation() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('resetImageOrientation');
		}
	}

	toggleImageMove() {
		if (this.annotationPlugin && this.pluginReady) {
			if (this.annotatingMode) {
				this.toolbarClicked('none')
			}
			this.annotationPlugin.fire('toggleImageMove')
		}
	}

	// Markers scroll
	getImageAnnotationCommentModalLeft(index) {
		let beforeElementOffset = 36;
		let scrollPosition = this.imageAnnotationMarkerContainer.nativeElement.pageXOffset || this.imageAnnotationMarkerContainer.nativeElement.scrollLeft;
		return (((index * 32) + (index * 8)) + (16 - scrollPosition)) + beforeElementOffset + 42 + 'px';
	}

	startAnnotationMode() {
		if (this.annotatingMode === false && this.annotationPlugin && this.pluginReady && !this.editingMode && this.annotationToolbarActive) {
			this.annotationPlugin.fire('addingAnnotation');
		}
	}

	/** Gets the comment input place holder text depending on general or annot comment */
	getPlaceholderText() {
		let placeholderText;
		if (this.annotationPlugin && this.annotationPlugin.controls && (this.annotationPlugin.controls.uiState.adding || this.annotationPlugin.controls.uiState.editing || this.annotationToolbarActive)) {
			placeholderText = "Write an annotation comment..."
		}
		else {
			placeholderText = "Write a general comment..."
		}

		return placeholderText;
	}

	/** Toggle annotation toolbar display on/off using toggle */
	toggleAnnotationToolbarDisplay(annotationToolbarActive) {
		if (annotationToolbarActive == true) {
			this.annotationPlugin.fire("togglePiklorColors", true); // Changes colors in palette to depict greyed out

		}
		else {
			this.canceladdNewAnnotation(); // Triggers canceladdnew event on the plugin
			this.annotationPlugin.fire("togglePiklorColors", false);
		}
	}

	cleanUpFiles() {
		this.newFiles = [];
		this.files = [];
		this.videoRecordFiles = [];
		this.audioRecordFiles = [];
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		files.forEach(file => {
			if (file.method == 'audioRecord') {
				this.audioRecordFiles.push(file);
			} else if (file.method == 'videoRecord') {
				this.videoRecordFiles.push(file);
			} else {
				this.files.push(file);
			}
		});
		this.newFiles.push(...files);
	}

	onFileDeleted(file) {
		this.newFiles = this.newFiles.filter(f => f.lastModified != file.lastModified);
		this.audioRecordFiles = this.newFiles.filter(f => f.method == 'audioRecord');
		this.videoRecordFiles = this.newFiles.filter(f => f.method == 'videoRecord');
		this.files = this.newFiles.filter(f => !f.method);
	}

	handlePrivateConditionChange(value: AnnotationPrivateCondition) {
		this.annotationService.updatePrivateCondition(value);
	}
}
