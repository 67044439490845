import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimelineService } from 'app/shared/services/timeline.service';
import { BreadcrumbService } from 'ng5-breadcrumb';

@Component({
  selector: 'mtm-stand-alone-projects',
  templateUrl: './stand-alone-projects.component.html',
  styleUrls: ['./stand-alone-projects.component.scss']
})
export class StandAloneProjectsComponent implements OnInit,OnDestroy {
  url: any;
  projects: any []=[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private timelineService: TimelineService,
    private router: Router
  ){
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.url = event.url
      }
    });
  }

  ngOnInit(): void {
    if (this.projects) {
      this.prepareBreadcrumbs();
    } else {
      const route = new URL(this.url).pathname;
      this.breadcrumbService.hideRoute(route);
    }
  }

  ngOnDestroy(): void {
    
  }

  prepareBreadcrumbs(): void {
    if (this.url) {
      const route = this.url.split('?')[0];
      const searchParams = new URLSearchParams(this.url.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }
      this.breadcrumbService.hideRoute(route);
      const newRoute = `${route}?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      const newRouteName = this.timelineService.workspaceName;
      this.breadcrumbService.addFriendlyNameForRoute(newRoute, newRouteName);
    }
  }
}
