<div class="completion-date-component">
    <i class="fa fa-times pointer" (click)="cancel()"></i>
    <div class="modal-body p-4">
        <div class="form-group m-0">
            <div class="d-flex flex-column mb-1">
                <label class="control-label _600 m-0 text-center">Completion Date</label>
                <span class="text-center" [style.font-size]="'13px'">Choose the project completion date</span>
            </div>
            <div>
                <input name="startDate" class="form-control" placeholder="Start date..." ngbDatepicker
                    #startDate="ngbDatepicker" [maxDate]="maxDate" firstDayOfWeek="1" (click)="startDate.toggle()"
                    [(ngModel)]="date" />
                <i class="mtm-icon mtm-icon-calendar-black" (click)="startDate.toggle()"></i>
            </div>
            <button class="btn mtm-button w-100 mt-3 b-mtm-green c-full-white _600 pt-2 pb-2 pl-3 pr-3 mr-2"
                (click)="submit()">Continue</button>
        </div>
    </div>
</div>