import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, of, Subject } from "rxjs";


enum ScriptLoadState {
    NotLoaded,
    Loading,
    Loaded
}


@Injectable()
export class GoogleService {
    private loadState: ScriptLoadState = ScriptLoadState.NotLoaded;
    private loadedEvent: Subject<boolean> = new Subject<boolean>();

    constructor() {

    }

    load(): Observable<any> {
        if (this.loadState == ScriptLoadState.Loaded) {
            return of(true);
        }

        if (this.loadState == ScriptLoadState.Loading) {
            return new Observable(subscriber => {
                const loadedSub = this.loadedEvent.subscribe(() => {
                    subscriber.next(true);
                    subscriber.complete();
                    loadedSub.unsubscribe();
                });

            });
        }

        if (this.loadState == ScriptLoadState.NotLoaded) {
            const script = document.createElement('script');
            const apiKey = environment.google.apiKey;
            //for now only use places library
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initGoogleMap`;
            script.async = true;
            this.loadState = ScriptLoadState.Loading;

            (<any>window).initGoogleMap = () => {
                this.loadState = ScriptLoadState.Loaded;
                this.loadedEvent.next(true);
            };
            document.head.appendChild(script);

            return new Observable(subscriber => {
                const loadedSub = this.loadedEvent.subscribe(() => {
                    subscriber.next(true);
                    subscriber.complete();
                    loadedSub.unsubscribe();
                });
            });
        }
    }
}