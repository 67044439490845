import { Component } from '@angular/core';
@Component({
	selector: 'maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
	constructor() {
	}
}
