import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "app/shared/services/project.service";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
// import { NGValidators } from "ng-validators";
import { UserService } from "app/shared/services/user.service";
import { RegistrationService } from "app/shared/services/registration.service";
import { NotificationService } from 'app/shared/services/notification.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { CompanyService } from 'app/shared/services/company.service';
import { LimitReachedPopupComponent } from 'app/subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component';
import { SUBSCRIPTION_LIMIT } from 'app/subscriptions/models/const';
import { forkJoin, of, Subject } from 'rxjs';
import { ProposalInvitationService } from 'app/shared/services/proposal-invitation.service';
import { AuthService } from 'app/shared/services/auth.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { UserRole } from "app/shared/interfaces";

@Component({
	selector: 'mtm-new-user-project',
	templateUrl: './new-user-project.component.html',
	styleUrls: ['./new-user-project.component.scss']
})
export class NewUserProjectComponent implements OnInit, OnDestroy {
	@Input() roles: UserRole[] = [];
	@Input() users: any[] = [];
	@Input() decision: boolean;
	@Input() project: any = null;

	addUserForm: UntypedFormGroup;
	submitted: boolean = false;
	projectId: string = null;
	formRoles: any[] = [];
	ngUnsubscribe = new Subject();
	companyUsers: any = [];
	invitedUsers: any = [];
	authUser: any = null;

	constructor(public userService: UserService,
		private authService: AuthService,
		public service: ProjectService,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private fb: UntypedFormBuilder,
		private translatePipe: TranslatePipe,
		private overlayService: OverlayService,
		private permissionService: PermissionService,
		private notificationService: NotificationService,
		private proposalInvitationService: ProposalInvitationService,
		private companyService: CompanyService) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.loadFormGroup();
		this.init();
	}

	ngOnChanges() {
		this.init();
	}

	private init() {
		this.authUser = this.authService.getAuthUser();

		this.companyService.getCompanyUsers()
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((res) => {
				this.companyUsers = res.filter((user: any) => {
					return user.globalRole !== 'COMPANY_OCCASIONAL';
				});
				if (this.decision)
					this.formRoles = JSON.parse(JSON.stringify(this.roles));
				else {
					this.formRoles = this.roles.filter(k => k.id.indexOf('PROJECT_OWNER') < 0);
				}
				this.loadFormGroup();
			});

	}

	private loadFormGroup() {
		this.addUserForm = this.fb.group({
			username: ['', [Validators.required, Validators.email]],
			selectedRoles: [[], [Validators.required]],
		});
	}

	onSubmit(): void {
		let index = this.users.findIndex(k => k.username == this.addUserForm.value.username);
		if (index > -1) {
			this.showInfo(null, null, 'There is user in the user list');
			return;
		}

		if (this.addUserForm.valid) {
			this.submitted = true;

			if (this.project.projectType === 'SUBSCRIPTION') {
				this.inviteUser(this.addUserForm.value.username, this.addUserForm.value.selectedRoles);
			} else {
				this.inviteForMarketplaceAdminProject();
			}
		}

	}

	//for marketplace/admin project
	private inviteForMarketplaceAdminProject() {
		if (this.permissionService.hasAuthority(Authority.Z, null)) {
			this.inviteUser(this.addUserForm.value.username, this.addUserForm.value.selectedRoles);
			return;
		}

		if (this.authUser.company.id == this.project.company.id) {
			this.inviteUser(this.addUserForm.value.username, this.addUserForm.value.selectedRoles);
			return;
		}

		//if there is accepted proposal & invited must be from production company that wins the pitch
		forkJoin([
			this.userService.getSingleUserInfo(this.addUserForm.value.username)
				.pipe(
					takeUntil(this.ngUnsubscribe),
					catchError(err => {
						return of(err);
					})
				),
			this.proposalInvitationService.getProposalByProjectId(this.service.project.id)
				.pipe(
					takeUntil(this.ngUnsubscribe),
					map(
						(proposals: any) =>
							proposals.filter(
								(proposal: any) => proposal.status === "ACCEPTED"
							)
					)
				)
		]).subscribe((res: any) => {
			const user = res[0];
			if (user.errorCode) {
				this.inviteUser(this.addUserForm.value.username, this.addUserForm.value.selectedRoles);
				return;
			}

			const proposals = res[1];
			if (!proposals.length) {
				this.overlayService.showError(this.translatePipe.transform('project_addUserError_noAcceptedProposal'), 'Error');
			} else {
				const proposalsFromInvitedUserCompany = proposals.filter(proposal => user.companyId === proposal.prodCompanyId);
				if (!proposalsFromInvitedUserCompany.length) {
					this.overlayService.showError(this.translatePipe.transform('project_addUserError_noAcceptedProposal'), 'Error');
				} else {
					this.inviteUser(this.addUserForm.value.username, this.addUserForm.value.selectedRoles);
				}
			}
		});
	}

	inviteUser(email: any, selectedRoles): void {
		let tempUser = {
			email: email,
			username: email,
			activationStatus: 'PENDING_INPROGRESS',
			selectedRoles: selectedRoles
		};
		let model = { invited: true, user: tempUser };

		this.userService.getUserByUsername(email).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.addUser(email, selectedRoles).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res2 => {
				this.closeModal({ user: res2.user, selectedRoles: selectedRoles });
			}, err => this.checkLicenseExceeded(err, model));
		}, (err: any) => {

			this.addUser(email, selectedRoles).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res2 => {
				this.closeModal(model);
			}, err => this.checkLicenseExceeded(err, model));
		});
	}

	private checkLicenseExceeded(err, model) {
		if (err.errorCode == 'SUBSCRIPTION_FULL') {
			let modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
			modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.LICENSE_USERS;
			modalRef.componentInstance.forceRedirect = false;
			return;
		}
		this.showInfo(this.callbackForCloseModal, model);
	}

	private addUser(username, data): Observable<any> {
		let projectId = this.projectId ? this.projectId : this.service.project.id;
		return this.service.addUser(projectId, username, data);
	}

	closeModal(model?) {
		this.activeModal.close(model);
	}

	private callbackForCloseModal = (model) => {
		this.closeModal(model);
	}

	private showInfo(callback, model, message = null) {
		this.notificationService.open({
			title: 'User Invitation',
			description: message ? message : this.translatePipe.transform('userRoleError'),
			confirmBtn: 'Accept'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			if (callback)
				callback(model)
		}, err => {
			if (callback)
				callback(model);
		})
	}

	isValid(field: string): boolean {
		if (field == 'username')
			this.addUserForm.patchValue({
				username: this.addUserForm.get(field).value.trim(),
				selectedRoles: this.addUserForm.get('selectedRoles').value
			});
		return (this.addUserForm.get(field).invalid && this.submitted);
	}

	selectedInvitersChange(e) {
		const username = e.value.length && !e.invalid ? e.value[0] : '';
		this.addUserForm.setValue({
			username,
			selectedRoles: this.addUserForm.get('selectedRoles').value
		});
	}
}
