import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { NGValidators } from "ng-validators";
import { AuthService } from './../../shared/services/auth.service';
import { ErrorMessageService } from './../../shared/services/error-message.service';
import { NotificationService } from './../../shared/services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	templateUrl: 'login-with-email.component.html',
	styleUrls: ['./login-with-email.component.scss']
})
export class LoginWithEmailComponent implements OnInit, OnDestroy {

	formGroup: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	responseMsg: string = null;
	emailSent: boolean = false;
	emailError: boolean = false;
	ngUnsubscribe = new Subject();

	constructor(private fb: UntypedFormBuilder, private router: Router, private authService: AuthService,
		private route: ActivatedRoute, public activeModal: NgbActiveModal, private notificationService: NotificationService) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		// Load form group
		this.loadFormGroup();

	}


	onSubmit() {

		this.submitted = true;
		this.emailSent = false;
		this.emailError = false;

		if (this.formGroup.valid) {

			let formGroup = this.formGroup;

			// Send verification link
			this.authService.sendLoginEmailVerificationLink(this.formGroup.controls['email'].value).pipe(
				takeUntil(this.ngUnsubscribe)
			)
				.subscribe(
					(data: any) => {
						this.emailSent = true;
					},
					(err: any) => {

						formGroup.controls['email'].setErrors(err.errorCode);
						this.onValueChanged();
						// this.emailError = true;
					});
		}

	}


	/**
	 * Load form group
	 */
	private loadFormGroup(token: string = null) {

		this.formGroup = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			token: [token]
		});

		// Subscribe to changes to get validation errors
		this.formGroup.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.formGroup)
			this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);
	}

}
