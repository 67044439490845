import * as _ from "lodash";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "app/shared/services/user.service";
import { AuthService } from "app/shared/services/auth.service";
import { ProductionTeamService } from "../../../../shared/services/proposal-services/productionteam.service";
import { Project } from "../../../../shared/interfaces/project";
import { NotificationService } from 'app/shared/services/notification.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: 'mtm-project-proposal-project-details',
	templateUrl: './project-proposal-projectDetails.component.html',
	styleUrls: ['./project-proposal-projectDetails.component.scss']
})

export class ProjectProposalProjectDetails implements OnInit, OnDestroy {
	managers: any[] = [];
	productionOwners = [];
	selectedManager = { username: "", email: "" };
	description = "";
	proposalId;
	company: any;
	invitationProjectId: string;
	ngUnsubscribe = new Subject();

	constructor(public auth: AuthService,
		public userService: UserService,
		public service: ProjectService,
		private router: Router,
		private modalService: NgbModal,
		private productionTeamService: ProductionTeamService,
		private activatedRoute: ActivatedRoute,
		private serviceNotification: NotificationService) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.invitationProjectId = this.activatedRoute.parent.snapshot.params['projectId'];
		this.proposalId = this.activatedRoute.parent.snapshot.params['proposalId'];
		this.getManageUsers();
		this.getProject();
	}



	goToTeam() {
		this.updateProject();
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'userPermissions']);
	}

	private getManageUsers() {
		this.userService.getUsersByCompanyId(this.productionTeamService.getCompanyId()).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(users => {
			this.managers = users;
		})
	}

	private getProject() {
		this.service.getProject(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.productionOwners = _.filter(res.participants, (user) => _.findIndex(user.roles, (role) => role === "PRODUCTION_OWNER") > -1) || [];
			this.description = res.description;
			this.company = res.company;
			let index = this.managers.findIndex(m => m.email == res.manager);
			if (index > -1)
				this.selectedManager = this.managers[index];
			else {
				let indexParticipant = res.participants.findIndex(p => p.email == res.manager);
				if (indexParticipant > -1) {
					this.managers.push(res.participants[indexParticipant]);
					this.selectedManager = this.managers[this.managers.length - 1];
				}
			}
		});
	}
	private updateProject() {
		this.service.getCompany(this.productionTeamService.getCompanyId()).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			let project: Project = {
				id: this.invitationProjectId,
				description: this.description,
				manager: this.selectedManager.username,
				company: this.company
			};
			this.service.saveProject(project).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				console.log(res);
			});
		});
	}

	onSelectManager(selected) {
		this.selectedManager = this.productionOwners.find(po => po.username == selected);
	}
}
