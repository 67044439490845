import * as _ from 'lodash';
import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { onAddingAnnotation, onAnnotationClickClosed, onAnnotationClickOpened, onAnnotationFocused, onAnnotationHoverClosed, onAnnotationHoveredIn, onAnnotationHoveredOut, onAnnotationHoverOpened, onAnnotationMarkerSelectedActiveClick, onAnnotationMarkerSelectedActiveHover, onAnnotationMarkerSelectedInActiveClick, onAnnotationMarkerSelectedInActiveHover, onCancelAddingAnnotation, onEditingAnnotation, onFirstRowMarkersReady, onOpenAnnotation, onVideoPaused, onVideoPlay, wsAnnotationChange } from 'app/shared/services/annotation.service';
import { HelperService } from 'app/shared/services/helper.service';
import { NotificationService } from 'app/shared/services/notification.service';
import * as $ from 'jquery';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	selector: 'mtm-videojs-annotation-bar-list',
	templateUrl: './videojs-annotation-bar-list.component.html',
	styleUrls: ['./videojs-annotation-bar-list.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideojsAnnotationBarList implements OnInit, DoCheck, OnChanges, OnDestroy, AfterViewInit {

	@ViewChild('annotationCommentModal') annotationCommentModal: ElementRef;
	@ViewChild('annotationConcat') annotationConcat: ElementRef;
	@Input() annotations: any = [];
	@Input() player: any;
	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() projectParticipants: any[];
	@Input() item: any;
	@Input() annotationPlugin: any;
	@Input() isPlayerReady: boolean = false;
	@Input() isAudio: boolean = false;
	@Input() isVideo: boolean = false;
	@Input() isPlaying: boolean = false;
	// @Input() activePluginAnnotation: any;

	isShowMoreAnnotations: boolean = false;
	duration: number = 0;
	activeAnnotation: any;
	modalPosition: any;
	concatPosition: any;
	annotationActiveToggled: boolean = false;
	annotationIn2DMatrixDataModel: any[];
	isAnnotationActive: boolean = false;
	isVideoPaused: boolean = true;
	ngUnsubscribe = new Subject();

	constructor(private notificationService: NotificationService) {
		window['bar'] = this;
	}

	ngAfterViewInit(): void {
		onAnnotationHoverOpened.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationHoverOpened(args)
		})
		onAnnotationHoverClosed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationHoverClosed(args)
		})
		onAnnotationClickOpened.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationClickOpened(args)
		})
		onAnnotationClickClosed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationClickClosed(args)
		})
		onAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationHandler(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onEditingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onEditingAnnotationHandeler(args)
		})
		onVideoPaused.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPausedHandler(args)
		})
		onVideoPlay.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPlayHandler(args)
		})

		// //Audio Events
		// this.addSubscribe('ON_ANNOTATION_HOVERED_IN', onAnnotationHoveredIn, (args: any) => this.onAnnotationClickOpened(args));
		// this.addSubscribe('ON_ANNOTATION_HOVERED_OUT', onAnnotationHoveredOut, (args: any) => this.onAnnotationClickClosed(args));

		wsAnnotationChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => { this.mapAnnotation2DMatrix() });
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.annotations && changes.annotations.currentValue) {
			this.mappingAnnotationData();
		}
	}

	ngDoCheck(): void {
		if (this.annotationCommentModal && this.annotationCommentModal.nativeElement) {
			this.calculateModalPosition();
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	onAnnotationHoverOpened(args: any) {
		this.onTimeBarMouseEnter(args.annotation);
	}

	onAnnotationHoverClosed(args: any) {
		this.onTimeBarMouseLeave(args.annotation);
	}

	onAnnotationClickOpened(args: any) {
		this.activeAnnotation = args.annotation;
		this.annotationActiveToggled = true;

	}

	onAnnotationClickClosed(args: any) {
		this.annotationActiveToggled = false;
		this.activeAnnotation = void 0;
	}

	onAddingAnnotationHandler(args: any) {
		this.annotationActiveToggled = false;
		this.activeAnnotation = void 0;
		this.isAnnotationActive = true;
	}

	onEditingAnnotationHandeler(args: any) {
		this.annotationActiveToggled = false;
		this.activeAnnotation = void 0;
		this.isAnnotationActive = true;
	}

	onCancelAddingAnnotationHandler(args: any) {
		this.isAnnotationActive = false;
	}

	onVideoPausedHandler(args: any) {
		this.isVideoPaused = true;
	}

	onVideoPlayHandler(args) {
		this.isVideoPaused = false;
	}

	mappingAnnotationData() {
		_.map(this.annotations || [], (annotation) => {
			let shapes = JSON.parse(annotation.shape);
			if (_.get(shapes, 'objects', []).length > 0) {
				if (parseInt(annotation.range.start) === parseInt(annotation.range.end)) {
					annotation.isCommentInTimeRange = false;
				} else {
					annotation.isCommentInTimeRange = true;
				}
			} else {
				if (parseInt(annotation.range.start) === parseInt(annotation.range.end)) {
					annotation.isCommentInTimeRange = false;
				} else {
					annotation.isCommentInTimeRange = true;
				}
			}
			annotation.color = '#A9A9A9';
			annotation.colorOpacity = HelperService.hexToRgbA(annotation.color, 0.4);
		});
		let isActiveAnnotationExist = _.find(this.annotations, { id: _.get(this.activeAnnotation, 'id') });
		if (!isActiveAnnotationExist) {
			this.annotationActiveToggled = false;
			this.activeAnnotation = void 0;
		}
		// this.sortAnnotations(); // Not being used anymore and causing issues as it's modifying the variable inside plugin
		this.mapAnnotation2DMatrix();
	}

	sortAnnotations() {
		this.annotations.sort((annotationA: any, annotationB: any) => {
			let annotationALastReply = Math.max(...[...annotationA.comments.map((comment: any) => comment.meta.updatetime)]);
			let annotationBLastReply = Math.max(...[...annotationB.comments.map((comment: any) => comment.meta.updatetime)]);
			return annotationBLastReply - annotationALastReply;
		});
	}

	mapAnnotation2DMatrix() {
		let annotationsIn2DMatrix = [];
		for (let i = 0; i < this.annotations.length; i++) {
			let j: number;
			for (j = 0; j < annotationsIn2DMatrix.length; j++) {
				let rows = annotationsIn2DMatrix[j], k: number;
				for (k = 0; k < rows.length; k++) {
					if (this.annotations[i].range.start === rows[k].range.start) {
						break;
					}
				}
				if (k === rows.length) {
					rows.push(this.annotations[i]);
					break;
				}
			}
			if (!annotationsIn2DMatrix.length) {
				annotationsIn2DMatrix.push([this.annotations[i]]);
			} else if (j === annotationsIn2DMatrix.length) {
				annotationsIn2DMatrix.push([this.annotations[i]]);
			}
		}
		this.annotationIn2DMatrixDataModel = annotationsIn2DMatrix;
		onFirstRowMarkersReady.emit({
			firstRowAnnotation: annotationsIn2DMatrix[0]
		});
	}

	showMoreAnnotations() {
		this.activeAnnotation = void 0;
		this.isShowMoreAnnotations = !this.isShowMoreAnnotations;
	}

	onTimeBarMouseEnter(annotation: any, fireEventToPlugin?: boolean) {
		if (this.annotationActiveToggled) return;
		this.activeAnnotation = annotation;
		if (fireEventToPlugin) {
			onAnnotationMarkerSelectedActiveHover.emit({
				annotation: annotation
			});
			if (this.isAudio && this.annotationPlugin) {
				this.annotationPlugin.fire('hoverInAnnotation', { annotation: annotation });
			}
			// if (this.isAudio && this.annotationPlugin) {
			// 	this.annotationPlugin.primordial.hoverInAnnotation(annotation);
			// }
		}
	}

	onTimeBarMouseLeave(annotation: any, fireEventToPlugin?: boolean) {
		if (this.annotationActiveToggled) return;
		this.activeAnnotation = void 0;
		if (fireEventToPlugin) {
			onAnnotationMarkerSelectedInActiveHover.emit({
				annotation: annotation
			});
			if (this.isAudio && this.annotationPlugin) {
				this.annotationPlugin.fire('hoverOutAnnotation', { annotation: annotation });
			}
			// if (this.isAudio && this.annotationPlugin) {
			// 	this.annotationPlugin.primordial.hoverOutAnnotation(annotation);
			// }
		}
	}

	getDuration() {
		let duration;
		if (this.isAudio && this.annotationPlugin) {
			duration = this.annotationPlugin.controls.duration();
		}
		else if (this.isVideo && this.annotationPlugin && this.player && this.isPlayerReady) {
			duration = this.player.duration();
		}

		return duration;
	}

	getAnnotationWidth(annotation: any) {
		let width = 0;
		let duration = this.getDuration();
		if (((this.isVideo && this.player && this.isPlayerReady) || this.isAudio) && this.annotationPlugin && annotation) {
			if (_.isNaN(duration)) {
				return 0;
			}
			if (_.isNaN(annotation.range.start) || _.isUndefined(annotation.range.start) || _.isNull(annotation.range.start)) {
				annotation.range.start = 0;
			}
			if (_.isNaN(annotation.range.end) || _.isUndefined(annotation.range.end) || _.isNull(annotation.range.end)) {
				annotation.range.end = 0;
			}
			if (annotation.range.start > annotation.range.end) {
				annotation.range.end = annotation.range.start;
			}
			width = annotation.range.end / duration * 100 - (annotation.range.start / duration * 100);
		}
		return width === 0 ? 1 : width;
	}

	getAnnotationLeft(annotation: any) {
		let left = 0;
		let duration = this.getDuration();
		if (((this.isVideo && this.player && this.isPlayerReady) || this.isAudio) && this.annotationPlugin && annotation) {
			left = annotation.range.start / duration * 100;
		}
		return left;
	}

	getAnnotationTop(row: any, index: any) {
		return row * 24 - (index * 24);
	}

	getAnnotationZIndex(annotation: any) {
		return _.get(annotation, 'range.start', 0) * 10;
	}

	annotationBarClick(annotation: any, fireEventToPlugin?: boolean) {
		if (this.isAnnotationActive) {
			this.notificationService.open({
				description: 'You have an unsaved annotation/comment. Continue without saving?',
				confirmBtn: 'Continue',
				cancelBtn: 'Cancel',
				windowClass: 'discard-annotation-modal',
				icon: 'warning'
			}).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(confirm => {
				if (confirm) {
					if (this.annotationActiveToggled) {
						this.annotationActiveToggled = false;
						this.activeAnnotation = void 0;
						if (fireEventToPlugin) {
							onAnnotationMarkerSelectedInActiveClick.emit();
						}
					} else {
						this.annotationActiveToggled = true;
						this.activeAnnotation = annotation;
						onAnnotationFocused.emit('onAnnotationFocused');
						onOpenAnnotation.emit({
							act: 'openAnnotation',
							annotation: annotation
						});
						if (fireEventToPlugin) {
							onAnnotationMarkerSelectedActiveClick.emit({
								annotation: annotation
							});
						}
					}
					onCancelAddingAnnotation.emit('cancelAddingAnnotation');
				}
			});
		} else {
			if (this.annotationActiveToggled) {
				this.annotationActiveToggled = false;
				this.activeAnnotation = void 0;
				if (fireEventToPlugin) {
					onAnnotationMarkerSelectedInActiveClick.emit();
				}
			} else {
				this.annotationActiveToggled = true;
				this.activeAnnotation = annotation;
				onAnnotationFocused.emit('onAnnotationFocused');
				onOpenAnnotation.emit({
					act: 'openAnnotation',
					annotation: annotation
				});
				if (fireEventToPlugin) {
					onAnnotationMarkerSelectedActiveClick.emit({
						annotation: annotation
					});
				}
			}
		}
	}

	calculateModalPosition() {
		let $element = $('.annotation-time-range-bar.annotation-active');
		let $parentContainer = $('.videojs-annotation-control-bar-list');
		if ($element.length) {
			let boundModal = this.annotationCommentModal.nativeElement.getBoundingClientRect();
			let position = $element.position();
			if (1 - (position.left / ($parentContainer[0].getBoundingClientRect().left + ($parentContainer[0].getBoundingClientRect().width / 2))) >= 0.5) {
				this.modalPosition = {
					top: position.top - boundModal.height - 48,
					left: position.left
				}
				this.concatPosition = {
					bottom: (boundModal.top + boundModal.height) - ($element[0].getBoundingClientRect().top + 2),
					height: $element[0].getBoundingClientRect().top - (boundModal.top + boundModal.height) + 3,
					left: 1
				}
			} else {
				this.modalPosition = {
					top: position.top - boundModal.height - 48,
					left: position.left - boundModal.width + 8
				}
				this.concatPosition = {
					bottom: (boundModal.top + boundModal.height) - ($element[0].getBoundingClientRect().top + 2),
					height: $element[0].getBoundingClientRect().top - (boundModal.top + boundModal.height) + 3,
					left: boundModal.width - 7
				}
			}
		}
	}
}
