import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";


@Component({
    selector: 'mtm-project-proposal-userPermissions',
    templateUrl: './project-proposal-userPermissions.component.html',
    styleUrls: ['./project-proposal-userPermissions.component.scss']
})

export class ProjectProposalUserPermissions implements OnInit {
    projectId;
    proposalId;

    constructor(private activeRouter: ActivatedRoute) { }
    ngOnInit() {
        this.projectId = this.activeRouter.parent.snapshot.params['projectId'];
        this.proposalId = this.activeRouter.parent.snapshot.params['proposalId'];
    }
}