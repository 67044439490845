import { OverrideRolesCellComponent } from "./renderer/override-roles/override-roles-cell.component";
import { ActionCellComponent } from "./renderer/action/action-cell.component";

export const workspaceTeamTableColDefs = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    flex: 1,
    minWidth: 300,
    cellClass: 'd-flex align-items-center justify-content-center',
    headerClass: 'd-flex align-items-center justify-content-center',
  },
  {
    field: 'members.length',
    headerName: 'Users',
    sortable: true,
    autoHeight: true,
    cellClass: 'd-flex align-items-center',
    headerClass: 'd-flex align-items-center justify-content-center',
  },
  /*
  {
    field: 'rolesOverride',
    headerName: 'Override Roles',
    sortable: false,
    autoHeight: true,
    cellClass: 'rights-cell',
    minWidth: 350,
    suppressSizeToFit: true,
    flex: 1,
    resizable: false,
    cellRenderer: OverrideRolesCellComponent,
  },
   */
  {
    field: 'action',
    headerName: '',
    sortable: false,
    cellRenderer: ActionCellComponent,
    cellClass: 'd-flex align-items-center justify-content-center',
    headerClass: 'd-flex align-items-center justify-content-center',
  }
];
