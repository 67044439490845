import { Injectable } from "@angular/core";
import { SubscriptionRegister } from "../register/models/subscription-register.model";
import { Observable } from "rxjs";
import { SubscriptionUpgrade } from "../upgrade/models/subscription-upgrade.model";
import { ApiService } from "app/shared/services/api.service";


@Injectable()
export class SubscriptionService {
	registerData: SubscriptionRegister;
	registrationState: number;
	upgradeData: SubscriptionUpgrade;
	plans: any[] = [];
	addons: any = [];
	planSelected: boolean = false;
	paymentSuccessful: boolean = false;
	planConfirmed: boolean = false;
	planCompared: boolean = false;
	currency: any;

	constructor(private apiService: ApiService) {
		this.registerData = new SubscriptionRegister();
		this.registrationState = 0
		this.upgradeData = new SubscriptionUpgrade();
	}

	subscribeFreePlan() {
		return this.apiService.httpPost('/api/subscription/createFree', null);
	}

	subscriptionWithAddonCreate(paymentMethodId: string, addons: any) {
		return this.apiService.httpPost(`/api/subscription/createSubscription?paymentMethodId=${paymentMethodId}`, addons);
	}

	getCurrentPlan(): Observable<any> {
		return this.apiService.httpGet(`/api/subscription`);
	}

	cancelSubscription(): Observable<any> {
		return this.apiService.httpPost(`/api/subscription/cancel`, null);
	}

	resumeSubscription(): Observable<any> {
		return this.apiService.httpPost(`/api/subscription/resumeCancelled`, null);
	}

	getSubscriptionInfo() {
		return this.apiService.httpGet(`/api/subscription/info`)
	}

	getPhaseInfo() {
		return this.apiService.httpGet(`/api/subscription/phaseInfo`);
	}
	
	update(newPlans: any[], isNewInterval = false) {
		return this.apiService.httpPut(`/api/subscription/updateAll?isNewInterval=${isNewInterval}`, newPlans);
	}

	downgradeToFreePlan() {
		return this.apiService.httpPost('/api/subscription/downgradeFree', null);
	}

	transferOwnership(newOwnerEmail: string) {
		return this.apiService.httpPost(`/api/subscription/ownership?email=${newOwnerEmail}`, null);
	}

	getSubscriptionFlagsInfo(email: string) {
		return this.apiService.httpGet(`/api/subscription/getSubscriptionFlagsInfo?name=${email}`);
	}

	retryPayment() {
		return this.apiService.httpPut(`/api/subscription/retry`, null);
	}

	extendTrialPeriod(companyId, extendDay) {
		return this.apiService.httpPut(`/api/subscription/extendTrialPeriod?companyId=${companyId}&extendByDay=${extendDay}`, null)
	}

	enableOrExtendEnterprise(companyId, licence, startDate, endDate, days, activeStorageInGB) {
		let endpoint = `/api/subscription/enableOrExtendEnterprise?companyId=${companyId}&licence=${licence}`;
		if (startDate) {
			endpoint += `&startDate=${startDate}`;
		}
		if (endDate) {
			endpoint += `&endDate=${endDate}`;
		}
		if (days) {
			endpoint += `&days=${days}`;
		}
		if (activeStorageInGB) {
			endpoint += `&activeStorageInGB=${activeStorageInGB}`;
		}
		return this.apiService.httpPut(endpoint, null);
	}

	init() {
		this.registerData = new SubscriptionRegister();
		this.registrationState = 0;
		this.upgradeData = new SubscriptionUpgrade();
		this.plans = [];
		this.addons = [];
		this.planSelected = false;
		this.paymentSuccessful = false;
		this.planConfirmed = false;
		this.planCompared = false;
	}
}
