
import { from as observableFrom, Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { isValid } from "ein-validator";

@Injectable({
	providedIn: 'root'
})
export class ValidatorService {

	constructor(private apiService: ApiService) {

	}


	/**
	 * Validate always as valid
	 * @param control
	 */
	static isAlwaysValid(control: UntypedFormControl): any {
		return null;
	}

	/**
	 * Validate if number (integer or double)
	 * @param control
	 */
	static isNumber(control: UntypedFormControl): any {
		if (control.value) {
			if (!(!isNaN(parseFloat(control.value)) && isFinite(control.value)))
				return { number: { valid: false } };
		}
		return null;
	}


	/**
	 * Validate if integer
	 * @param control
	 */
	static isInteger(control: UntypedFormControl): any {
		if (control.value) {
			if (!(+control.value === parseInt(control.value, 10)))
				return { integer: { valid: false } };
		}
		return null;
	}


	/**
	 * Validate if not null
	 * @param control
	 */
	static isNotNull(control: UntypedFormControl): any {
		if (control.value) {
			if (control.value === null)
				return { isNotNull: { valid: false } };
		}
		return null;
	}


	/**
	 * Validate if greater or equals to zero
	 * @param control
	 */
	static isGreaterOrEqualsToZero(control: UntypedFormControl): any {
		if (control.value) {
			if (!(+control.value === parseInt(control.value, 10)))
				return { isGreaterOrEqualsToZero: { valid: false } };
			else if (parseInt(control.value, 10) < 0)
				return { isGreaterOrEqualsToZero: { valid: false } };
		}
		return null;
	}


	/**
	 * Validate if number and decimal (only 2)
	 * Accepted: 100 or 100.00 or 100.5 or 100.50
	 * Not accepted: 100.500
	 * @param control
	 */
	static isMoney(control: UntypedFormControl): any {
		if (control.value) {
			if (!(!isNaN(parseFloat(control.value)) && isFinite(control.value)))
				return { isMoney: { valid: false } };
			else {
				if (/^\d*\.?\d{0,2}$/.test(control.value))
					return null;
				else
					return { isMoney: { valid: false } };
			}
		}
		return null;
	}

	/**
	 * Check TAX Validation
	 * @Param companyTaxId
	 * @Param countryCode
	 */
	/*
	checkTaxValidation(countryCode: string, companyTaxId: String): Observable<any> {
		if (countryCode == 'US') {
			let isValidId = true;

			try {
				isValidId = isValid(companyTaxId);
			} catch (e) {

			}

			return of( { valid: isValidId} );
		}

		return this.apiService.httpPost(`/api/registration/checkCompanyTaxId/${companyTaxId}/${countryCode}`, {});
	}
	*/

	//disable tax id with name validation, due to MTM-3918
	/*
	checkTaxIdWithName(companyName: string, countryCode: string, taxId: string): Observable<any> {
		const payload = {
			taxId,
			countryCode,
			companyName
		};
		return this.apiService.httpPost('/api/registration/validateEUCompanyTaxId', payload);
	}
	*/
}
