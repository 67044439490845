import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { SubscriptionService } from "../services/subscription.service";

@Injectable()
export class UpgradePlanGuard implements CanActivate {
	constructor(
		private router: Router,
		private subscriptionService: SubscriptionService
	) {

	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		if (!this.subscriptionService.upgradeData || !this.subscriptionService.upgradeData.plan || !this.subscriptionService.upgradeData.plansInfo) {
			this.router.navigate(['/subscription/upgrade/select-plan']);
			return false;
		}

		return true;
	}
}