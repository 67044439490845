<div *ngIf="banner  && (!flagInfos || !flagInfos?.bannerHide)"
	class="subscription-banner-component d-flex justify-content-center p-2">
	<i class="fa fa-times p-2 pointer" (click)="closeBanner()" *ngIf="flagInfos"></i>
	<ng-container *ngIf="bannerType !== bannerTypes.MaintenanceOnly && subscription">
		<span *ngIf="banner === 'PLAN_DOWNGRADE'" class="_700">
			Your subscription will be downgraded to {{downgradedToPlan}} on {{downgradedDate}} at {{ downgradedTime }}
			UTC{{downgradedTime | date: 'ZZ'}}.
			<a class="learn-more-nav pointer" (click)="learnMoreClick()">Learn More</a>
		</span>
		<span *ngIf="banner === 'PLAN_CANCELLATION'" class="_700">
			Your subscription will be cancelled on {{subscription.endDate * 1000 | mtmDateTime }} at {{
			subscription.endDate * 1000 | mtmTimePipe }} UTC{{subscription.endDate | date: 'ZZ'}}.
			<a class="learn-more-nav pointer" (click)="learnMoreClick()">Learn More</a>
		</span>
		<span *ngIf="banner === 'PLAN_RENEWAL_FAILED'" class="_700">
			An error occurred while processing payment for the renewal of your subscription plan. Please update your payment
			method.
			<a class="learn-more-nav pointer" (click)="learnMoreClick()">Learn More</a>
		</span>
		<span
			*ngIf="banner === 'APPROACHING_PLAN_LIMITS_LICENSE' || banner === 'APPROACHING_PLAN_LIMITS_ACTIVE' || banner === 'APPROACHING_PLAN_LIMITS_ARCHIVE'"
			class="_700">
			Your account is approaching its {{approachingLimitItem}} plan limits. Upgrade your plan to increase your plan
			limits.
			<a class="learn-more-nav pointer" (click)="learnMoreClick()">Learn More</a>
		</span>
	</ng-container>

	<span *ngIf="banner === 'SCHEDULED_MAINTENANCE' && scheduledMaintenance" class="_700">
		<ng-container *ngIf="authUser?.username">
			{{'banner_maintenance_message' | translate: { 
				start: scheduledMaintenance?.startTime | mtmDateTime, 
				end: scheduledMaintenance?.endTime | mtmDateTime, 
				timezone: scheduledMaintenance?.timezone
			} }}
		</ng-container>
		<ng-container *ngIf="! authUser?.username">
			{{'banner_maintenance_message' | translate: { 
				start: scheduledMaintenance?.startTime | date: 'd MMMM yyyy hh:mm a', 
				end: scheduledMaintenance?.endTime | date:'d MMMM yyyy hh:mm a', 
				timezone: scheduledMaintenance?.timezone
			} }}

		</ng-container>
	</span>
</div>
