import { Component, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { DateHelperService } from "../../shared/services/date-helper.service";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { ProposalInvitationService } from "../../shared/services/proposal-invitation.service";
import { AuthService } from "../../shared/services/auth.service";
import { CompanyService } from "../../shared/services/company.service";
import { MtmDatePipe } from "../../shared/pipes/mtm-date.pipe";
import { Authority, PermissionService } from "../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	selector: 'mtm-payment-warning-modal',
	templateUrl: './payment-warning-modal.component.html',
	styleUrls: ['./payment-warning-modal.component.scss']
})
export class PaymentWarningModalComponent implements OnInit, OnDestroy {

	project: any;
	proposal: any;
	paymentType: string = "";
	cssClass: string = 'down-payment';
	allowedDayTime: number = 172800000;
	endTimeStatus: boolean;
	allowedDate: string;
	prodCompany: any;
	authority = Authority;
	ngUnsubscribe = new Subject();

	constructor(public activeModal: NgbActiveModal,
		private translatePipe: TranslatePipe,
		private proposalService: ProposalInvitationService,
		private companyService: CompanyService,
		public auth: AuthService,
		private router: Router,
		private mtmDatePipe: MtmDatePipe,
		public permissionService: PermissionService
	) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() { }

	setParam(project: any, paymentType: string, endTimeStatus: boolean) {
		this.project = project;
		this.paymentType = paymentType;
		this.endTimeStatus = endTimeStatus;
		this.proposalService.getProposalByProjectId(project.id).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data) => {
				this.proposal = data[0];
			}
		);

		this.companyService.getCompanyById(this.project.prodCompanyId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(data => {
			this.prodCompany = data;
		})

		if (paymentType == "DOWNPAYMENT")
			this.allowedDate = this.calculateDownPaymentAllowedDay();
		if (paymentType == "FINAL_PAYMENT")
			this.allowedDate = this.calculateFinalPaymentAllowedDay();
	}
	onConfirmModal() {
		this.activeModal.close(true);
	}

	onCancelModal() {
		this.activeModal.close(false);
		this.router.navigate(['projects']);
	}
	onClickPayHere() {
		this.activeModal.close(false);
		this.router.navigate(['projects', this.project.id, "projectpayment", this.paymentType.toLowerCase().replace('_', ''), this.proposal.id]);
	}
	calculateDownPaymentAllowedDay(): string {
		let date = DateHelperService.beutifyDateObject(DateHelperService.fromDateToDatepicker
			(DateHelperService.fromTimestampToDate(DateHelperService.endOfDay(this.project.downPaymentDeadline) + this.allowedDayTime)));
		return this.translatePipe.transform('paymentWarningDueDate', { date: this.mtmDatePipe.transform(date) });
	}
	calculateFinalPaymentAllowedDay(): string {
		let date = DateHelperService.beutifyDateObject(DateHelperService.fromDateToDatepicker
			(DateHelperService.fromTimestampToDate(DateHelperService.endOfDay(this.project.finalPaymentDeadline) + this.allowedDayTime / 2)));
		return this.translatePipe.transform('paymentWarningDueDate', { date: this.mtmDatePipe.transform(date) });
	}

}
