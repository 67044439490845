import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'filterTelCountry'
})
@Injectable({
    providedIn: 'root'
})
export class MTMTelInputPipe implements PipeTransform {
    transform(items: any[], args: any): any[] {
        return args == '' ? items : items.filter(k => k.countryName.toLowerCase().startsWith(args.toLowerCase()));
    }
}
