<svg [style.width]="svgWidth" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.8563 12.0928H11.9045C11.3416 12.0928 10.8853 12.5491 10.8853 13.112V20.0638C10.8853 20.6267 11.3416 21.0831 11.9045 21.0831H18.8563C19.4192 21.0831 19.8756 20.6267 19.8756 20.0638V13.112C19.8756 12.5491 19.4192 12.0928 18.8563 12.0928Z"
         />
    <path
        d="M7.97106 12.0928H1.01925C0.456334 12.0928 0 12.5491 0 13.112V20.0638C0 20.6267 0.456334 21.0831 1.01925 21.0831H7.97106C8.53398 21.0831 8.99031 20.6267 8.99031 20.0638V13.112C8.99031 12.5491 8.53398 12.0928 7.97106 12.0928Z"
         />
    <path
        d="M14.5677 1.10675L10.9502 4.7242C10.5024 5.172 10.5024 5.89802 10.9502 6.34582L14.5677 9.96327C15.0155 10.4111 15.7415 10.4111 16.1893 9.96327L19.8067 6.34582C20.2545 5.89802 20.2545 5.172 19.8067 4.7242L16.1893 1.10675C15.7415 0.658953 15.0155 0.658953 14.5677 1.10675Z"
         />
    <path
        d="M7.97106 1.04004H1.01925C0.456334 1.04004 0 1.49637 0 2.05929V9.0111C0 9.57401 0.456334 10.0303 1.01925 10.0303H7.97106C8.53398 10.0303 8.99031 9.57401 8.99031 9.0111V2.05929C8.99031 1.49637 8.53398 1.04004 7.97106 1.04004Z"
         />
</svg>