import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from "app/shared/services/project.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'mtm-block-user-project',
	templateUrl: './block-user-project.component.html',
	styleUrls: ['./block-user-project.component.scss']
})
export class BlockUserProjectComponent implements OnInit, OnDestroy {

	public user: any;
	ngUnsubscribe = new Subject();

	constructor(public service: ProjectService, public activeModal: NgbActiveModal) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
	}


	private block(): void {
		this.service.blockUser(this.user.username).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			console.log(res);
			this.activeModal.close(true);
		});
	}

	private unblock(): void {
		this.service.unblockUser(this.user.username).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			console.log(res);
			this.activeModal.close(true);
		});
	}
}
