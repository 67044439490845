import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from "ng5-breadcrumb";
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { HelperService } from "app/shared/services/helper.service";
import { NotificationService } from "app/shared/services/notification.service";
import { ProductionTeamService } from 'app/shared/services/proposal-services/productionteam.service';
import { EmitterService } from "../../../shared/services/emitter.service";

import { AuthService } from "../../../shared/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectProposalNDAModalComponent } from "./project-proposal-NDA/project-proposal-NDA";
import { UserService } from "../../../shared/services/user.service";
import { Authority, PermissionService } from "../../../shared/services/permissions.service";
import { get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'mtm-project-proposal',
	templateUrl: './project-proposal.component.html',
	styleUrls: ['./project-proposal.component.scss']
})
export class ProjectProposalComponent implements OnInit, OnDestroy {

	projectId: string = null;
	proposalId: string = '';
	project: any = null;
	projectName: string = null;
	companyName: string = null;
	startDate: number = null;
	proposal: any;
	username: any;
	brandName: any;
	ndaApproved: boolean = false;
	usersList: any = {};
	sub: any;
	modal: any;
	linksProposalNotAccepted: any[] = [
		{ url: 'brief', title: 'Production Brief', display: 'ACCEPTED' },
		{ url: 'team', title: 'Proposal Team', display: 'ACCEPTED' },
		{ url: 'qaSession', title: 'Q&A', display: 'ACCEPTED' },
		{ url: 'uploadProposal', title: 'Upload Proposal', display: 'ACCEPTED' },
		{ url: 'discussion', title: 'Proposal Discussion', display: 'ACCEPTED' }
	];
	linksProposalAccepted: any[] = [
		{ url: 'brief', title: 'Production Brief', display: 'ACCEPTED' },
		{ url: 'team', title: 'Proposal Team', display: 'ACCEPTED' },
		{ url: 'qaSession', title: 'Q&A', display: 'ACCEPTED' },
		{ url: 'uploadProposal', title: 'Upload Proposal', display: 'ACCEPTED' },
		{ url: 'discussion', title: 'Proposal Discussion', display: 'ACCEPTED' },
		{ url: 'projectDetails', title: 'Project Details', display: 'ACCEPTED' },
		{ url: 'userPermissions', title: 'User & Permissions', display: 'ACCEPTED' },
		{ url: 'projectStages', title: 'Project Stages', display: 'ACCEPTED' }
	];
	links: any[] = [];
	ngUnsubscribe = new Subject();

	constructor(private projectService: ProjectService,
		private route: ActivatedRoute,
		private router: Router,
		private breadcrumb: BreadcrumbService,
		private notificationService: NotificationService,
		private serviceProductionTeam: ProductionTeamService,
		private authService: AuthService,
		private modalService: NgbModal,
		public userService: UserService,
		public permissionService: PermissionService) {

		EmitterService.get("getProjectName").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(projectName => {
			this.breadcrumb.addFriendlyNameForRoute(
				"/projects/{projectId}/proposal",
				'Project Proposal  >  ' + projectName
			);
		});

		onLoadProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(project) => {
				this.project = project;
				this.doBreadcrumb();
			}
		)
	}

	ngOnInit() {
		this.route.params.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params) => {
			if (!HelperService.isUUID(params['projectId']))
				return;

			this.projectId = params['projectId'];
			this.proposalId = params['proposalId'];
			this.getUserCompany();
			this.getProjectProposal(this.projectId);

			this.doBreadcrumb();
		});
	}

	ngOnDestroy() {
		if (this.modal) {
			this.modal.close('OK');
		}
		let breadcrumb = $('.breadcrumb');
		breadcrumb.removeClass('proposal-page-breadcrumb');
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	getUserCompany() {
		this.username = this.authService.getAuthUserName();
		this.userService.findUserByUsername(this.username, false, true).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(userData => {
			this.usersList[this.username] = userData;
			this.brandName = userData.company.name;
		}, err => {
			console.log(err);
		});
	}

	openNDAModal(ndaApproved) {

		if (ndaApproved)
			return;

		this.modal = this.modalService.open(ProjectProposalNDAModalComponent, {
			size: 'lg',
			windowClass: 'company-matched-blur-color',
			backdrop: 'static'
		});
		this.modal.componentInstance.projectId = this.projectId;
		this.modal.componentInstance.projectName = this.projectName;
		this.modal.componentInstance.companyName = this.companyName;
		this.modal.componentInstance.startDate = this.startDate;
		if (this.brandName)
			this.modal.componentInstance.brandName = this.brandName;
		else
			this.modal.componentInstance.brandName = JSON.parse(localStorage.getItem('auth_user')).company.name;
		this.modal.componentInstance.ndaApproved = this.ndaApproved;
		this.modal.result
			.then(async (res) => {
				if (res == 'ACCEPTED') {
					try {
						this.projectService.saveProposalNDA(this.projectId, this.proposalId).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(result => {
							this.proposal = result;
							this.projectService.proposal = result;
							this.openNDAModal(true);
						})
					} catch (error) {
						this.openNDAModal(false);
					}
				}
				if (res == 'CLOSED')
					this.router.navigate(['projects/project-proposals']);

			})
			.catch((err) => { this.openNDAModal(false) })
	}

	getPageId(id: any) {
		if (id === 'brief') {
			return 'Production Brief';
		} else if (id === 'team') {
			return 'Production Team';
		} else if (id === 'qaSession') {
			return 'Q&A';
		} else if (id === 'uploadProposal') {
			return 'Upload Proposal';
		} else if (id === 'discussion') {
			return 'Proposal Discussion';
		} else if (id === 'projectDetails') {
			return 'Project Details';
		} else if (id === 'userPermissions') {
			return 'Users & Permissions';
		} else if (id === 'projectStages') {
			return 'Project Stages';
		}
	}

	private doBreadcrumb() {
		this.breadcrumb.addFriendlyNameForRoute('/projects', 'My Projects');
		this.breadcrumb.addFriendlyNameForRoute('/projects/new', 'New Project');
		this.breadcrumb.hideRoute('/projects/new/company');
		this.breadcrumb.hideRouteRegex('/projects/new/details/*');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/company');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/details');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/stages');
		this.breadcrumb.addCallbackForRouteRegex('/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/proposal', (id) => {
			let breadcrumb = $('.breadcrumb');
			let breadcrumbElements = breadcrumb.find('li');
			for (let i = 0; i < breadcrumbElements.length; i++) {
				if (!i) {
					$(breadcrumb.find('li')[0]).css('display', 'none');
				} else {
					$(breadcrumb.find('li')[i]).css('pointer-events', 'none');
				}
			}
			breadcrumb.addClass('proposal-page-breadcrumb');
			if (id === 'proposal') {
				return this.projectService.project.name;
			} else if (id === this.projectService.proposal.id) {
				return 'Bidding Process';
			} else {
				return this.getPageId(id);
			}
		});
		this.breadcrumb.addFriendlyNameForRoute(
			"/projects/proposal",
			'Project Proposal'
		);
	}

	getProjectProposal(projectId) {
		this.projectService.getProposalByUsername(projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			this.links = this.linksProposalNotAccepted;
			this.projectService.proposal = proposal;
			this.proposal = proposal;
			this.companyName = proposal.clientCompanyName;
			this.projectName = proposal.invitationProjectName;
			this.startDate = proposal.startDate;
			this.openNDAModal(proposal.ndaApproved);

			EmitterService.get("getProjectName").emit(this.proposal.invitationProjectName);
			if (proposal.status == "ACCEPTED") {
				this.projectService.prepareProjectInit(this.projectId);
				this.serviceProductionTeam.initProposalTeamUsers(this.projectId, this.proposalId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((res) => {
					if (this.permissionService.hasAuthority(Authority.U, this.projectId))
						this.links = this.linksProposalAccepted;
				});
			}
		});
	}

	/**
	 * Check if a step given is complete
	 *
	 * @param i
	 */
	isComplete(i: number) {
		let index: number;
		this.links.map((link: any) => {
			if (this.isActive(link.url))
				index = this.links.indexOf(link);
		});

		return i < index;
	}

	filterLink() {
		this.links = this.links.filter(l => l.display == 'ACCEPTED');
	}

	/**
	 * Check if an url given is active
	 *
	 * @param url
	 */
	private isActive(url: string): boolean {
		if (this.projectService.project.id)
			return this.router.isActive('/projects/edit/' + this.projectId + '/' + url, false);
		else
			return this.router.isActive('/projects/new/' + url, false);
	}
}
