import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { NGValidators } from "ng-validators";
import { AuthService } from './../../shared/services/auth.service';
import { ErrorMessageService } from './../../shared/services/error-message.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from 'app/shared/services/notification.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';

@Component({
	templateUrl: 'recovery-password.component.html',
	styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit, OnDestroy {

	formGroup: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	responseMsg: string = null;
	emailSent: boolean = false;
	ngUnsubscribe = new Subject();

	constructor(private fb: UntypedFormBuilder,
		private router: Router,
		private authService: AuthService,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private translatePipe: TranslatePipe,
		public activeModal: NgbActiveModal) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		// Read params
		this.route.queryParams.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(params => {

			let token = (params['token']) ? params['token'] : null;

			// TODO Validate token

			// Load form group
			this.loadFormGroup(token);

		});
	}


	onSubmit() {

		this.submitted = true;

		if (this.formGroup.valid) {

			this.authService.requestVerificationSendPasswordToken(this.formGroup.controls['email'].value).pipe(
				takeUntil(this.ngUnsubscribe)
			)
				.subscribe((data: any) => {

					this.emailSent = true;
				}, (err) => {
					if (err && err.errorCode == 'NOT_FOUND') {
						this.notificationService.open({
							title: this.translatePipe.transform('signInForgot'),
							description: this.translatePipe.transform('signInForgot_emailNotFound'),
							confirmBtn: this.translatePipe.transform('accept')
						});
					}
				});
		}
	}


	/**
	 * Load form group
	 */
	private loadFormGroup(token: string = null) {

		this.formGroup = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			token: [token]
		});

		// Subscribe to changes to get validation errors
		this.formGroup.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.formGroup)
			this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);
	}

}
