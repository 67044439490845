import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { movieCreated } from 'app/layouts/ai-reframing/cores/ai-framing.events';
import { Movie, MovieDetail } from 'app/layouts/ai-reframing/cores/models/movie';
import { AiReframingProject } from 'app/layouts/ai-reframing/cores/models/project';
import { AIReframingMovieService } from 'app/layouts/ai-reframing/services/ai-reframing-movie.service';
import { AIReframingProjectService } from 'app/layouts/ai-reframing/services/ai-reframing-project.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'mtm-ai-framing-movie-builder',
    template: '<div></div>'
})
export class AiFramingMovieBuilderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() guid: any;
    @Input() folderId: any;
    @Input() movie: any;

    folderData: AiReframingProject;
    ngUnsubscribe = new Subject();

    constructor(
        private aIReframingMovieService: AIReframingMovieService,
        private aIReframingProjectService: AIReframingProjectService
    ) { }

    ngOnInit(): void {
        if (this.folderId) {
            this.aIReframingMovieService.createMovie(this.movie)
                .subscribe((movie: Movie) => {
                    this.aIReframingProjectService.createProjectMovies(this.folderId, [movie.id])
                        .subscribe(res => {
                            movieCreated.emit({ guid: this.guid, folder: this.folderId, movie: movie });
                        });
                });
        } else {
            this.aIReframingMovieService.createMovie(this.movie)
                .subscribe((movie: MovieDetail) => {
                    movieCreated.emit({ guid: this.guid, folder: this.folderId, movie: movie });
                })
        }
    }

    ngAfterViewInit(): void { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);
        this.ngUnsubscribe.complete();
    }
}
