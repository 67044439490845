<div class="select-plan-component">
  <form class="select-plan-form d-flex flex-column">
    <div class="d-flex flex-column align-items-center select-plan-form main-input mb-4">
      <div class="d-flex flex-column select-plan-billing-cycle select-plan-billing-cycle">
        <div class="d-flex align-items-center justify-content-center">
          <span class="c-mtm-brand _bold mt-3 mb-5" [style.font-size]="'2rem'">
            {{'subscription_register_select_plan_collaborativePlatform'|translate}}
          </span>
        </div>
        <div class="select-plan-duration-selector m-auto" [style.width]="'500px'">
          <div class="pricing-selector">
            <span class="selector mb-3 mx-auto"></span>
            <ul class="d-flex _bold">
              <li class="d-flex align-items-center flex-column w-25 pointer" (click)="chooseBillingType('monthlyPrice')"
                [style.text-transform]="'uppercase'" [style.position]="'relative'">
                <div class="plan-selection"
                  [ngClass]="{ 'selected': subscriptionService.upgradeData.billingType==='monthlyPrice' }">
                  <span class="checked-plan-icon">✓</span>
                </div>
                <span>{{'subscription_register_select_plan_1month'|translate}}</span>
              </li>
              <li class="d-flex align-items-center flex-column w-25 pointer"
                (click)="chooseBillingType('quarterlyPrice')" [style.text-transform]="'uppercase'"
                [style.position]="'relative'">
                <div class="plan-selection"
                  [ngClass]="{ 'selected': subscriptionService.upgradeData.billingType==='quarterlyPrice' }">
                  <span class="checked-plan-icon">✓</span>
                </div>
                <span>{{'subscription_register_select_plan_3months'|translate}}</span>
                <b class="c-mtm-brand">{{'subscription_register_select_plan_save5%'|translate}}</b>
              </li>
              <li class="d-flex align-items-center flex-column w-25 pointer"
                (click)="chooseBillingType('biannualPrice')" [style.text-transform]="'uppercase'"
                [style.position]="'relative'">
                <div class="plan-selection"
                  [ngClass]="{ 'selected': subscriptionService.upgradeData.billingType==='biannualPrice' }">
                  <span class="checked-plan-icon">✓</span>
                </div>
                <span>{{'subscription_register_select_plan_6months'|translate}}</span>
                <b class="c-mtm-brand">{{'subscription_register_select_plan_save10%'|translate}}</b>
              </li>
              <li class="d-flex align-items-center flex-column w-25 pointer" (click)="chooseBillingType('annualPrice')"
                [style.text-transform]="'uppercase'" [style.position]="'relative'">
                <div class="plan-selection"
                  [ngClass]="{ 'selected': subscriptionService.upgradeData.billingType==='annualPrice' }">
                  <span class="checked-plan-icon">✓</span>
                </div>
                <span>{{'subscription_register_select_plan_1year'|translate}}</span>
                <b class="c-mtm-brand">{{'subscription_register_select_plan_save20%'|translate}}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex select-plan-items w-100" [style.line-height]="'1.7'" [style.border-radius]="'1rem'"
        [style.position]="'relative'">
        <div *ngFor="let plan of plans"
          [ngClass]="{ 'selected': subscriptionService.upgradeData.plan.code === plan.code }" (click)="choosePlan(plan)"
          class="plan-item {{plan?.code}} d-flex flex-column flex-fill w-25 pointer">
          <div class="d-flex flex-column plan-item-head p-4 plan-info text-center" [style.color]="'#ffffff'">
            <div class="plan-detail mb-2">
              <span class="mb-1 _bold"
                [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
                [style.text-transform]="'uppercase'">{{'subscription_register_select_plan_'+plan?.code|translate}}</span>
              <div *ngIf="plan?.code==='ENTERPRISE_PLAN'" class="plan-detail mb-2">
                <div class="d-flex _bold mt-2 mb-2" [style.font-size]="'1.375rem'">
                  <span [style.line-height]="'1'" class="enterprise-button pointer"
                    (click)="openContactUsModal()">{{'subscription_register_select_plan_contactUs'|translate}}</span>
                </div>
              </div>
              <div *ngIf="plan?.code!=='ENTERPRISE_PLAN'" class="d-flex flex-column" [style.font-size]="'1rem'">
                <div class="d-flex align-items-center justify-content-center _600">
                  <div class="d-flex align-items-center pricing-label">
                    <span>
                      {{(subscriptionService.currency || 'USD') === 'EUR' ? '€' : '$'}}
                    </span>
                    <span>{{plan?.basePrice[subscriptionService?.upgradeData?.billingType]?.perMonthValue ||
                      '0'}}</span>
                  </div>
                  <span>/user/mo</span>
                </div>
                <span class="_600">
                  {{
                  (subscriptionService.upgradeData.billingType === 'monthlyPrice'
                  ? 'monthly' : subscriptionService.upgradeData.billingType === 'quarterlyPrice'
                  ? 'quarterly' : subscriptionService.upgradeData.billingType === 'biannualPrice'
                  ? 'semi_annually' : 'annually') | translate
                  }}
                </span>
              </div>
            </div>
            <div class="plan-user mb-2">
              <span class="mb-1 _bold"
                [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
                [style.text-transform]="'uppercase'">
                {{'subscription_register_select_plan_minimumUsers'|translate}}
              </span>
              <div>
                <span class="_600">{{plan?.minUsers}}</span>
              </div>
            </div>
            <div class="plan-upto mb-2">
              <span class="mb-1 _bold"
                [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
                [style.text-transform]="'uppercase'">
                {{'subscription_register_select_plan_upTo'|translate}}
              </span>
              <div class="_600">
                <span *ngIf="plan?.code==='ENTERPRISE_PLAN'" [style.text-transform]="'uppercase'"
                  class="mr-2">{{plan?.code==='ENTERPRISE_PLAN' ?
                  ('subscription_register_select_plan_unlimited'|translate) : plan?.maxUsers}}</span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN'" class="mr-2">{{plan?.maxUsers}}</span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN'"
                  [style.text-transform]="'uppercase'">{{'subscription_register_select_plan_activeUsers'|translate}}</span>
              </div>
            </div>
            <div class="plan-active-storage mb-2">
              <span class="mb-1 _bold"
                [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
                [style.text-transform]="'uppercase'">
                {{'subscription_register_select_plan_activeStorage'|translate}}
              </span>
              <div class="_600">
                <span *ngIf="plan?.code==='ENTERPRISE_PLAN'" [style.text-transform]="'uppercase'" class="mr-2">
                  {{plan?.code==='ENTERPRISE_PLAN' ? ('subscription_register_select_plan_from2Tb'|translate) :
                  plan?.maxUsers}}
                </span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN'" class="mr-2">
                  {{(plan?.activeStorage?.value || 0) >= 1000 ? (plan?.activeStorage?.value / 1000) :
                  plan?.activeStorage?.value }}
                </span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN'" [style.text-transform]="'uppercase'">
                  {{(plan?.activeStorage?.value || 0) >= 1000 ? 'TB' : plan?.activeStorage?.metric}}
                </span>
              </div>
            </div>
            <div class="plan-archival">
              <span class="mb-1 _bold"
                [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
                [style.text-transform]="'uppercase'">
                {{'subscription_register_select_plan_archival'|translate}}
              </span>
              <div class="_600">
                <span *ngIf="plan?.code==='ENTERPRISE_PLAN'" [style.text-transform]="'uppercase'" class="mr-2">
                  {{plan?.code==='ENTERPRISE_PLAN' ? ('subscription_register_select_plan_from4Tb'|translate) :
                  plan?.maxUsers}}
                </span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN'" class="mr-2">
                  {{plan?.archivalStorage?.value ? ((plan?.archivalStorage?.value || 0) >= 1000 ?
                  (plan?.archivalStorage?.value / 1000) : plan?.archivalStorage?.value) : '/'}}
                </span>
                <span *ngIf="plan?.code!=='ENTERPRISE_PLAN' && plan?.archivalStorage?.value"
                  [style.text-transform]="'uppercase'">
                  {{(plan?.archivalStorage?.value || 0) >= 1000 ? 'TB' : plan?.archivalStorage?.metric}}
                </span>
              </div>
            </div>
          </div>
          <div class="flex-fill p-4" [style.background-color]="'rgba(0,0,0,.15)'" [style.color]="'#ffffff'">
            <div class="mb-2"
              [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
              [style.text-transform]="'uppercase'">
              {{'subscription_register_select_plan_mainFeatures'|translate}}</div>
            <ul class="d-flex flex-column _600">
              <li class="d-flex mb-1" *ngFor="let feature of plan.features">
                <span class="mr-2">✓</span>
                <span>{{'subscription_register_select_plan_'+plan?.code+'_'+feature|translate}}</span>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <a class="_bold p-3 learn-more-button"
              [ngClass]="{ 'c-mtm-brand': plan?.code!=='ENTERPRISE_PLAN', 'c-mtm-black': plan?.code==='ENTERPRISE_PLAN' }"
              [style.text-transform]="'uppercase'" [style.font-size]="'0.875rem'"
              href="https://mtm.video/workspace/pricing/"
              target="_blank">{{'subscription_register_select_plan_learnMore'|translate}}</a>
          </div>
        </div>
        <div class="select-plan-items-shadow-selection d-flex">
          <div *ngFor="let plan of plans"
            [ngClass]="{ 'selected': subscriptionService.upgradeData.plan?.code === plan?.code }"
            class="plan-shadow {{plan.code}}-plan-shadow w-25 pointer" [style.height]="'100%'"
            [style.position]="'relative'">
            <span class="checked-plan-icon">✓</span>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!isLoading" class="d-block" [ngClass]="{ 'mb-5 loading-icon': isLoading }">
      <loading-data #loadingRef></loading-data>
    </div>
    <div class="d-flex align-items-center justify-content-center flex-column select-plan-form-navigation">
      <div class="d-inline-flex">
        <a class="btn mtm-button mtm-main-button" (click)="proceed()" [style.color]="'#FFFFFF'"
          [style.text-transform]="'uppercase'">
          {{'subscription_register_select_plan_choosePlan'|translate}}
        </a>
      </div>
    </div>
  </form>
</div>