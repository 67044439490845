<div class="container setting-header" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
    <h4 class="text-left mt-3 pl-3"> {{projectName}} </h4>
    <p class="text-left pl-3">
    </p>
    <ul class="list-inline steps" style="display: block;">
        <li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active">
            <a>
                <span class="text-uppercase setting-header-title">{{'notification_settings_project' | translate }}</span>
            </a>
        </li>
    </ul>
</div>
<router-outlet></router-outlet>

<div class="row my-4 ml-4 justify-content-between custom-max-width-notification ml-auto mr-auto">
    <button class="btn btn-lg btn-danger mr-2" type="button" (click)="returnToProject()">
        <span>{{'returnProject' | translate}}</span>
    </button>
</div>

<div class="container container-inner custom-max-width-notification mt-3 mb-3">
    <div class="row mt-30 mb-30 ml-10">
        <div class="col-8">
            <h6 class="fw-700 notification">{{'notification_settings_projectTitle' | translate}}: {{projectName}}</h6>
            <p class="notificationDesc">{{'notification_settings_projectDescription' | translate }}</p>
        </div>
        <div class="col-4 pr-2">
            <div class="d-flex align-items-center justify-content-end">
                <span class=" notification-text mr-2">{{'toggleAll' | translate }}</span>
                <div class="switch-btn pull-right">
                    <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                        [(ngModel)]="isAllNotificationsSelected"
                        (click)="toggleNotifications()"></ui-switch>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-content-between row ml-10 mb-30">
        <div class="col-9">
            <h6 class="fw-700 notification">{{'notification_settings_selfNotifications' | translate}}</h6>
            <p class="notificationDesc mb-30">{{'notification_settings_selfNotificationsDescription' | translate}}</p>
        </div>
        <div class="col-3 text-right pr-2">
            <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                        [(ngModel)]="userNotification.seeOwnActions"
                        (click)="changeNotificationsSettings(userNotification)"></ui-switch>
        </div>
    </div>
    <hr />
    <h3 class="text-center section-title">{{'notification_settings_periodicEmailSummaryNotifications' | translate }}</h3>
    <div>
        <div class="d-flex justify-content-between row ml-10 mt-30 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'notificationsSummaryTitle' | translate}}</h6>
                <p class="notificationDesc">{{'notificationsSummarySubTitle' | translate}}</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="col-3">
                    <select class="notification-time-select" [(ngModel)]="userNotification.notificationSummaryDuration"
                        (ngModelChange)="changeNotificationsSettings(userNotification)">
                        <option *ngFor="let interval of notificationIntervals" [value]="interval.value">{{interval.text
                            |
                            translate}}</option>
                    </select>
                </div>
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.notificationSummaryEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>

        <hr />
        <h3 class="text-center section-title text-capitalize">{{'notification_settings_realTimeNotifications' | translate }}</h3>
        <div class="d-flex justify-content-between row ml-10 mt-30 mb-30">
            <div class="col-3 d-flex justify-content-between align-items-center fw-700">{{'notification_settings_allBelow' | translate }}</div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center justify-content-end ml-3 mr-2">
                    
                    <img class="mr-1" src="/assets/img/icons/gloab.svg" alt="">
                    <span class="notification-text mr-2">MTM Platform</span>
                    <!--<span class="notification-text mr-2">{{ isAllPlatformNotificationsSelected ? 'On' : 'Off' }}</span>-->
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="isAllPlatformNotificationsSelected"
                            (click)="togglePlatformNotifications()"></ui-switch>
                    </div>
                    
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center justify-content-end ml-3 mr-2">
                    <img class="mr-1" src="/assets/img/icons/mail-box.svg" alt="">
                    <span class="notification-text mr-2">Email</span>
                    <!--<span
                    class=" notification-text mr-2">{{ isAllEmailNotificationsSelected ? 'On' : 'Off' }}</span>-->
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="isAllEmailNotificationsSelected"
                            (click)="toggleEmailNotifications()"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center justify-content-end ml-3 mr-2">
                    <img class="mr-1" src="/assets/img/icons/cell-phone.svg" alt="">
                    <span class="notification-text mr-2">SMS</span>
                    <!--<span
                        class="notification-text mr-2">{{ isAllSMSNotificationsSelected ? 'On' : 'Off' }}</span>-->
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="isAllSMSNotificationsSelected"
                            (click)="toggleSMSNotifications()"></ui-switch>
                    </div>
                   
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between row ml-10 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'Validation' | translate}}</h6>
                <p class="notificationDesc">{{'notification_settings_validationDescription' | translate}}..</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center ml-3 mr-2"><img class="mr-1"
                        src="../../../assets/img/icons/gloab.svg" alt=""><span class="notification-text mr-2"> MTM
                        Platform </span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="userNotification.validationsWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.validationsEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
                        class="notification-text mr-2">SMS</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.validationsMobile"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between row ml-10 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'notificationEditing' | translate}}</h6>
                <p class="notificationDesc">{{'notification_settings_editingDescription' | translate}}..</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center ml-3 mr-2"><img class="mr-1"
                        src="../../../assets/img/icons/gloab.svg" alt=""><span class="notification-text mr-2"> MTM
                        Platform </span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="userNotification.editingWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.editingEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
                        class="notification-text mr-2">SMS</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.editingMobile"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between row ml-10 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'projectCommunication' | translate}}</h6>
                <p class="notificationDesc">{{'notification_settings_projectCommunicationDescription' | translate}}..</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center ml-3 mr-2"><img class="mr-1"
                        src="../../../assets/img/icons/gloab.svg" alt=""><span class="notification-text mr-2"> MTM
                        Platform </span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectCommunicationWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectCommunicationEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
                        class="notification-text mr-2">SMS</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectCommunicationMobile"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>

        </div>
		<div class="d-flex justify-content-between row ml-10 mb-30">
			<div class="col-3">
				<h6 class="fw-700 notification">{{'taskNotificationSetting' | translate}}</h6>
			</div>
			<div class="row d-flex justify-content-between align-items-center">
				<div class="row align-items-center ml-3 mr-2"><img class="mr-1"
						src="../../../assets/img/icons/gloab.svg" alt=""><span
						class="notification-text mr-2"> MTM Platform </span>
					<div class="switch-btn">
						<ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
							switchColor="#efefef" [(ngModel)]="userNotification.taskWeb"
							(click)="changeNotificationsSettings(userNotification)"></ui-switch>
					</div>
				</div>
				<hr class="verticle-line">
				<div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
						src="../../../assets/img/icons/mail-box.svg" alt=""> <span
						class=" notification-text mr-2">Email</span>
					<div class="switch-btn">
						<ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
							[(ngModel)]="userNotification.taskEmail"
							(click)="changeNotificationsSettings(userNotification)"></ui-switch>
					</div>
				</div>
				<hr class="verticle-line">
				<div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
						src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
						class="notification-text mr-2">SMS</span>
					<div class="switch-btn">
						<ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
							[(ngModel)]="userNotification.taskMobile"
							(click)="changeNotificationsSettings(userNotification)"></ui-switch>
					</div>
				</div>
			</div>
		</div>
        <div class="d-flex justify-content-between row ml-10 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'projectSchedule' | translate}}</h6>
                <p class="notificationDesc">{{'notification_settings_projectScheduleDescription' | translate}}..</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center ml-3 mr-2"><img class="mr-1"
                        src="../../../assets/img/icons/gloab.svg" alt=""><span class="notification-text mr-2"> MTM
                        Platform </span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectScheduleWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectScheduleEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
                        class="notification-text mr-2">SMS</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.projectScheduleMobile"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between row ml-10 mb-30">
            <div class="col-3">
                <h6 class="fw-700 notification">{{'teamBuilding' | translate}}</h6>
                <p class="notificationDesc">{{'notification_settings_teamScheduleDescription' | translate}}..</p>
            </div>
            <div class="row d-flex justify-content-between align-items-center">
                <div class="row align-items-center ml-3 mr-2"><img class="mr-1"
                        src="../../../assets/img/icons/gloab.svg" alt=""><span class="notification-text mr-2"> MTM
                        Platform </span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef" switchColor="#efefef"
                            [(ngModel)]="userNotification.teamBuildingWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.teamBuildingEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
                <hr class="verticle-line">
                <div class="row align-items-center ml-3 mr-2"> <img class="mr-1"
                        src="../../../assets/img/icons/cell-phone.svg" alt=""> <span
                        class="notification-text mr-2">SMS</span>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.teamBuildingMobile"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row my-4 ml-4 justify-content-between custom-max-width-notification ml-auto mr-auto">
    <button class="btn btn-lg btn-danger mr-2" type="button" (click)="returnToProject()">
        <span>{{'returnProject' | translate}}</span>
    </button>
</div>
