import { AudioComp } from "../lib/audio-component";

export class DraggableMarker extends AudioComp {
	range: any;
	$parent: JQuery<HTMLElement>;
	dragging: boolean;
	clickTime: any;
	markerDuration: any;

	constructor(plugin, range) {
		super(plugin);
		this.range = range;
		this.$parent = $('.waveform-container');
		this.initTemplate();
		this.render();
		this.bindEvents();
	}

	initTemplate() {

		// Render the draggable marker on UI
		let $waveformContainer = $(document.getElementById("waveform"));

		let $markerContainer = $("<div class='draggable-marker-container' id='markerContainer'></div>");
		let $draggableContainer = $("<div class='draggable-marker'></div>");
		let $leftArrow = $("<div class='before-arrow'><i class='fa fa-play'></i></div>");
		let $rightArrow = $("<div class='after-arrow'><i class='fa fa-play'</i></div>");

		$markerContainer.append($draggableContainer, $leftArrow, $rightArrow);

		$waveformContainer.append($markerContainer);
		this.render();
	}

	render() {
		$('.draggable-marker-container').css('left', this.getPercentage(this.range.start) + '%');
		$('.draggable-marker-container').css('width', this.getPercentage(this.range.end - this.range.start) + '%');
	}

	percentValFromXpos(xpos) {
		var x = Math.max(0, xpos - this.$parent.offset().left); // px val
		var max = this.$parent.innerWidth();
		var per = x / max;

		if (per > 1) per = 1;
		if (per < 0) per = 0;
		return per;
	}

	bindEvents() {
		let _this2 = this;

		// ON DRAG ON BEFORE ICON
		$('.draggable-marker-container .before-arrow').on('mousedown', function (e) {
			e.preventDefault();
			_this2.dragging = true;
			$(document).on('mousemove.draggable-marker-container .before-arrow', (event) => {
				if (_this2.dragging) _this2.onBeforeDrag(event);
			});
		});

		$(document).off('mouseup.draggable-marker-container .before-arrow');
		$(document).on('mouseup.draggable-marker-container .before-arrow', function (e) {
			if (!_this2.dragging) return;
			$(document).off('mousemove.draggable-marker-container .before-arrow');
			setTimeout(function () { _this2.dragging = false; }, 250);
		});

		// ON DRAG ON AFTER ICON
		$('.draggable-marker-container .after-arrow').on('mousedown', function (e) {
			e.preventDefault();
			_this2.dragging = true;
			$(document).on('mousemove.draggable-marker-container .after-arrow', (event) => {
				if (_this2.dragging) _this2.onAfterDrag(event);
			});
		});

		$(document).off('mouseup.draggable-marker-container .after-arrow');
		$(document).on('mouseup.draggable-marker-container .after-arrow', function (e) {
			if (!_this2.dragging) return;
			$(document).off('mousemove.draggable-marker-container .after-arrow');
			setTimeout(function () { _this2.dragging = false; }, 250);
		});

		// On Drag Entire Marker
		$('.draggable-marker-container .draggable-marker').on('mousedown', function (e) {
			e.preventDefault();
			_this2.dragging = true;
			_this2.clickTime = _this2.duration() * _this2.percentValFromXpos(e.pageX);
			_this2.markerDuration = _this2.range.end - _this2.range.start;
			$(document).on('mousemove.draggable-marker-container .draggable-marker', (event) => {
				if (_this2.dragging) _this2.onMarkerDrag(event)
			});
		});

		$(document).off('mouseup.draggable-marker-container .draggable-marker');
		$(document).on('mouseup.draggable-marker-container .draggable-marker', function (e) {
			// _this2.plugin.player.controlBar.progressControl.enable();
			if (!_this2.dragging) return;
			$(document).off('mousemove.draggable-marker-container .draggable-marker');
			setTimeout(function () { _this2.dragging = false; }, 250);
		});

	}

	onMarkerDrag(e) {
		let markerDuration = this.markerDuration;
		let dragPercent = this.percentValFromXpos(e.pageX);
		// secVal current position converted to duration
		let secVal = this.duration() * dragPercent;
		let timeToMove = this.clickTime - secVal;
		this.clickTime = secVal;

		let start = this.range.start - timeToMove;
		let end = this.range.end - timeToMove;

		if (start < 0) start = 0;
		if (start > this.duration() - markerDuration) start = this.duration() - markerDuration;

		if (end > this.duration()) end = this.duration();
		if (end < markerDuration) end = markerDuration;

		this.range.start = start;
		this.range.end = end;

		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	onBeforeDrag(e) {
		let dragPercent = this.percentValFromXpos(e.pageX);
		// secVal current position converted to duration
		let secVal = this.duration() * dragPercent;
		if (secVal < this.range.end) {
			this.range.start = secVal;
		}
		else if (secVal >= this.range.end) {
			this.range.start = this.range.end
		}
		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	onAfterDrag(e) {
		let dragPercent = this.percentValFromXpos(e.pageX);
		// secVal current position converted to duration
		let secVal = this.duration() * dragPercent;
		if (secVal < this.range.start) {
			this.range.end = this.range.start;
		}
		else if (secVal >= this.range.start) {
			this.range.end = secVal;
		}
		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	timeUpdateChange() {
		this.range.start = Math.round(this.currentTime() * 10) / 10;
		this.range.end = this.range.start;
		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	onPlay() {
		this.range.start = Math.round(this.currentTime() * 10) / 10;
		this.range.end = this.range.start;
		$('.draggable-marker-container').hide();
		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	onBreak() {
		this.range.start = Math.round(this.currentTime() * 10) / 10;
		this.range.end = this.range.start;
		$('.draggable-marker-container').show();
		this.render();
		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	updateStart(change) {
		let newStart = this.range.start + change;
		if (newStart < 0) {
			this.range.start = 0;
		}
		else if (newStart > this.duration()) {
			this.range.start = this.duration();
		}
		else {
			this.range.start = newStart;
		}
		if (this.range.start >= this.range.end) {
			this.range.end = this.range.start;
		}
		this.range.end = this.range.start;
		this.render();

		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	updateEnd(change) {
		var newEnd = this.range.end + change;

		if (newEnd < this.range.start) {
			this.range.end = this.range.start;
		}
		else if (newEnd > this.duration()) {
			this.range.end = this.duration();
		}
		else {
			this.range.end = newEnd;
		}
		this.render();

		this.plugin.fire('addingAnnotationDataChanged', { range: this.range });
	}

	teardown() {
		$('.draggable-marker-container .after-arrow').off('mousedown');
		$('.draggable-marker-container .after-arrow').off('mousedown');
		$('.draggable-marker-container .draggable-marker').off('mousedown');

		let $waveformContainer = $(document.getElementById('markerContainer'));
		$waveformContainer.remove();
	}
}