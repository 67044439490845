<div class="card">

	<div class="card-block p-0" *ngIf="ratingToken">
		<div class="col pr-15 title">
			<div class="row">
				<div class="company-avatar" [style.background-image]="'url(' + clientCompRate.userCompanyAvatar + ')'">
				</div>
				<p class="company-name">{{ratingToken.clientCompanyName}}</p>

			</div>

			<div class="col">
				<div class="comment-container">
					<div class="comments">


						<form class="col" [formGroup]="clientCompRateForm" (ngSubmit)="onSubmit()">


							<p class="description">Please rate {{ratingToken.clientCompanyName}} on following criteria
							</p>

							<div class="row">
								<div class="col-md-3">
									<p class="rateText">Clarity of the Brief</p>
								</div>

								<div class="col-md-2">
									<ng-template #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning" aria-hidden="true"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="brief" [readonly]="true">
									</ngb-rating>

								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input class="form-control" type="text"
											placeholder="Please add additional comments & Feedback on clarity of the brief."
											formControlName="clarityOfTheBriefFeedBack">
									</div>

								</div>
							</div>

							<div class="row">

								<div class="col-md-3">
									<p class="rateText">Openness To Ideas</p>
								</div>

								<div class="col-md-2">
									<ng-template #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning" aria-hidden="true"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="budget" [readonly]="true">
									</ngb-rating>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input class="form-control" type="text"
											placeholder="Please add additional comments & Feedback on client's openness to ideas.."
											formControlName="opennessToIdeasFeedBack">
									</div>
								</div>
							</div>

							<div class="row">

								<div class="col-md-3">
									<p class="rateText">Feed Back Quality & Speed</p>
								</div>

								<div class="col-md-2">
									<ng-template #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning" aria-hidden="true"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="outOfBox" [readonly]="true">
									</ngb-rating>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input class="form-control" type="text"
											placeholder="Please add additional comments & Feedback on client's quality & speed."
											formControlName="feedBackQualitySpeedFeedBack">
									</div>
								</div>
							</div>


							<div class="row">

								<div class="col-md-3">
									<p class="rateText">Good Listener</p>
								</div>

								<div class="col-md-2">
									<ng-template #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning" aria-hidden="true"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="goodListener" [readonly]="true">
									</ngb-rating>

								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input class="form-control" type="text"
											placeholder="Please add additional comments & Feedback on payment experience with client."
											formControlName="goodListenerFeedBack">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-3">
									<p class="rateText">Good Payer</p>
								</div>

								<div class="col-md-2">
									<ng-template #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning" aria-hidden="true"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="goodPlayer" [readonly]="true">
									</ngb-rating>


								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input class="form-control" type="text"
											placeholder="Please add additional comments & Feedback on clients ability to listen to your comments & feedbacks."
											formControlName="goodPlayerFeedBack">
									</div>
								</div>
							</div>


							<div class="row">

								<div class="col-md-3">
									<p class="rateText">Global Rating</p>
								</div>

								<div class="col-md-2">
									<ng-template class="star" #t let-fill="fill">
										<span class="star" [class.full]="fill === 100">
											<span class="half globalRating" [style.width.%]="fill"><em
													class="fa fa-star text-warning "></em></span><em
												class="fa fa-star-o text-warning cursor "></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="global" [readonly]="true">
									</ngb-rating>

								</div>
								<div class="col-md-3">

								</div>
							</div>


							<div class="row">

								<div class="col-md-9">
									<p class="rateText">Reviews</p>

									<div class="form-group">
										<textarea class="form-control "
											placeholder="Feel free to express your global experience with the Client"
											formControlName="review" rows="5"></textarea>
									</div>

								</div>

							</div>
						</form>


						<label class="container">
							<input type="checkbox" checked="checked" [(ngModel)]="checkBoxValue">
							<span class="checkmark">I agree to share my comments & reviews on the MTM Platform, if
								needed and agreed by the Client.</span>
						</label>

						<div class="col-md-3 text-right">
							<button class="btn btn-lg btn-danger w-100" type="button"
								[disabled]="sending || !checkBoxValue" (click)="onSubmit()"
								[ngClass]="{'submitted' : isSubmitted }">
								<span>Submit</span>

							</button>

						</div>

					</div>
				</div>

			</div>

		</div>
	</div>
</div>