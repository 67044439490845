import { AnnotationState } from "./components/annotationState";
import { Controls } from "./components/controls";
import { EventDispatcher } from "./lib/event-dispatcher";

export class AudioAnnotationPlugin {
	eventDispatcher: any;
	_readyCallbacks: any[];
	annotationComments: Object;
	annotationState: AnnotationState;
	controls: Controls;
	options: any;
	amplitudejs: any;
	active: boolean;
	meta: any;
	primordial: any;

	constructor(primordial: any, config: any) {
		window['VAC_DEBUG'] = false;

		this.options = config;
		this.meta = {
			user_id: config.userInfo.username,
			user_name: config.userInfo.fullName
		};
		this.primordial = primordial;
		this.eventDispatcher = new EventDispatcher();
		this.eventDispatcher.registerListenersFor(this, 'AnnotationComments');
		this._readyCallbacks = [];

		this.annotationComments = () => {
			return this;
		};

		this.amplitudejs = Amplitude;

		this.postLoadDataConstructor();
	}

	postLoadDataConstructor() {
		let _this2 = this;
		window['ac'] = this;

		this.annotationState = new AnnotationState(this);
		this.controls = new Controls(this);

		this.annotationState.annotations = this.options.annotationObjects;

		this.toggleAnnotationMode();

		this.pluginReady();
	}

	getItem() {
		return this.options.item;
	}

	toggleAnnotationMode() {
		this.controls.cancelAddNew();
		this.active = !this.active;
		this.primordial.isAnnotationDisplayEnabled = !this.primordial.isAnnotationDisplayEnabled;

		this.annotationState.enabled = this.active;
	}

	// A wrapper func to make it easier to use EventDispatcher from the client
	// Ex: plugin.fire(type, data);
	fire(type, data = {}) {
		this.eventDispatcher.fire(type, data);
	}

	// A wrapper func to make it easier to listen to API events from the client
	registerListener(type, callback) {
		this.eventDispatcher.registerListener(type, callback, true);
	}

	// Mark plugin as ready and fire any pending callbacks
	pluginReady() {
		this.eventDispatcher.pluginReady = true;
		while (this._readyCallbacks.length) {
			this._readyCallbacks.pop()();
		}
		this.fire('pluginReady');
	}

	// Public function to register a callback for when plugin is ready
	onReady(callback) {
		if (this.eventDispatcher.pluginReady) {
			return callback();
		}
		this._readyCallbacks.push(callback);
	}

	disposeAll() {
		this.amplitudejs.stop();
		this.controls = this.controls.teardown();
		this.annotationState = this.annotationState.teardown();
		this.eventDispatcher = this.eventDispatcher.teardown();
	}

}
