import { EventEmitter, Injectable } from '@angular/core';
import { ROOT_FOLDER } from 'app/layouts/post-prod/subtitles/subtitle.const';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export let addSubtitleStart: EventEmitter<any> = new EventEmitter<any>();
export let importSubtitleStart: EventEmitter<any> = new EventEmitter<any>();
export let newSubtitleAdded: EventEmitter<any> = new EventEmitter<any>();
export let subtitleThumbnailAdded: EventEmitter<any> = new EventEmitter<any>();
export let subtitleGenerated: EventEmitter<any> = new EventEmitter<any>();

@Injectable({
	providedIn: 'root'
})
export class SubtitleGenerationService {

	constructor(private api: ApiService, public auth: AuthService) { }

	orderSubtitleGeneration(projectId: any, sectionId: any, subsectionId: any) {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/subtitle-generation/order`);
	}

	getSubtitleGenerations(projectId: any, sectionId: any, subsectionId: any, directoryId: any = ROOT_FOLDER) {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/subtitle-generations?directoryId=${directoryId}`);
	}

	saveSubtitleGenerations(projectId: any, sectionId: any, subsectionId: any, data: any) {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/subtitle-generations`, data);
	}

	getSubtitleGenerationById(projectId: any, sectionId: any, subsectionId: any, id: any, directoryId: any = ROOT_FOLDER) {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/subtitle-generations/${id}?directoryId=${directoryId}`);
	}

	deleteSubtitleGenerationById(projectId: any, sectionId: any, subsectionId: any, id: any, directoryId: any = ROOT_FOLDER) {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/subtitle-generations/${id}?directoryId=${directoryId}`);
	}

	deleteSubtitleFile(projectId: any, sectionId: any, subsectionId: any, id: any, fileId: any) {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${id}/files/${fileId}`);
	}

	getSignedUrlInfo(projectId, fileId) {
		return this.api.httpGet(`/api/signed-url/get-redirect-info/${projectId}/${fileId}`);
	}

	getImportedEditing(projectId: any, sectionId: any, subSectionId: any) {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subSectionId}/subtitle-generations/imported/editings`);
	}

	isSameLanguage(languageA, languageB) {
		if (!languageA || !languageB) {
			return false;
		}
		return languageA.length === languageB.length ? languageA.toLowerCase() === languageB.toLowerCase() : languageA.split('-')[0].toLowerCase() === languageB.split('-')[0].toLowerCase();
	}

}