import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleaner',
  pure: false
})
export class CleanerPipe implements PipeTransform {

  transform(items: Array<any>, args?: any): any {
    return items.filter((i) => i !== undefined);
  }

}
