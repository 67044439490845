<div *ngIf="selector!='COMPANY_PROJECT_DETAILS'" class="modal-header pt-1 pb-1">
	<h4 class="modal-title" id="modal-basic-title"></h4>
	<button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div *ngIf="selector=='COMPANY_PROJECT_DETAILS'" class="modal-header pt-1 pb-1 bg-danger">
	<div class="w-100">
		<h3 class="w-100 text-white mb-1">{{'inviteToPitchProjectCompanyNewProject' | translate}}</h3>
		<h5 class="w-100 text-white mb-3">{{'inviteToPitchProjectCompanyNewProjectSubtitle' | translate}}</h5>
		<h6 class="w-100 text-white d-flex flex-row align-items-center">
			<div class="bounce-cmp-prj-detail">
				<div class="bounce-cmp-prj-detail"></div>
			</div>
			{{'inviteToPitchProjectCompanyNewProjectRadioBtnText' | translate}}
		</h6>
	</div>
	<button type="button" class="close text-white" aria-label="Close" (click)="onCloseModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form *ngIf="selector=='TEXT'">
		<h5 class="text-center"><b>{{'companyProfilesInviteToPitchDesc1' | translate}}</b></h5>
		<h6 class="text-center"><b>{{'companyProfilesInviteToPitchDesc2' | translate}}</b></h6>
		<h7 class="text-center"><b [innerHTML]="'companyProfilesInviteToPitchDesc3' | translate"></b></h7>
		<div class="row mt-4">
			<div class="col">
				<button class="btn btn-danger btn-lg btn-block" type="submit" (click)="onSubmitForTEXT()">
					<span>{{'companyProfilesInviteToPitchDescBtn' | translate}}</span>
				</button>
			</div>
		</div>
	</form>
	<div class="w-100 text-center pb-3">
		<mtm-project-company *ngIf="selector=='COMPANY_PROJECT_DETAILS'" [isCompanyProfiles]=true #mtmProjectCompany>
		</mtm-project-company>
	</div>
	<div *ngIf="selector=='COMPANY_PROJECT_DETAILS'" class="card" style="border: unset">
		<div class="row">
			<div class="col mt-3 mb-3 text-center">
				<button *ngIf="service.project.id" class="btn btn-lg btn-danger mr-2" type="button"
					(click)="onCompanyProfileSubmit()">
					<span>View Profiles</span>
				</button>
			</div>
		</div>
	</div>
</div>