export class AudioComp {
    _plugin: any;
    isPlaying: boolean = false;

    constructor(plugin) {
        this._plugin = plugin;
    }

    get plugin() {
        return this._plugin.annotationComments();
    }

    currentTime() {
        return this.plugin.amplitudejs.getSongPlayedSeconds();
    }

    currentPercentage() {
        return this.plugin.amplitudejs.getSongPlayedPercentage();
    }

    getPercentage(val) {
        return (val / this.duration()) * 100;
    }

    duration() {
        return this.plugin.amplitudejs.getSongDuration();
    }

    pause() {
        this.plugin.amplitudejs.pause();
    }

    setCurrentTime(time) {
        let percentage = (time / this.duration()) * 100;
        this.plugin.amplitudejs.setSongPlayedPercentage(percentage);
    }

    // Register all events in the EventRegistry matching this className
    initAPI(obj, className) {
        this.plugin.eventDispatcher.registerListenersFor(obj, className);
    }

    // Nullify player reference so objects can be removed safely
    // All components should call super.teardown() within their teardown funcs
    teardown(destroy = false) {
        if (destroy) this._plugin = null;
    }
}
