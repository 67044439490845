<div class="card-img">
	<!-- <p class="file-name">{{item.name}}</p> -->
	<img *ngIf="mediaType=='IMAGE' || mediaType=='FILE'" class="thumpnail-img image" #previewImage>

	<video *ngIf="mediaType=='VIDEO'" class="video-js thumpnail-img" controls #mtmVideoPlayer></video>

	<img *ngIf="mediaType=='AUDIO'" class="thumpnail-img audio image" src="assets/img/thumpnails/audio-file.png">
	<audio *ngIf="mediaType=='AUDIO'" class="thumpnail-img" controls #mtmAudioPlayer>
		<source>
	</audio>

	<embed-video *ngIf="mediaType=='URL'" [fix]="fix" [height]="(mode == 'view') ? '400px' : '100%'" [width]="'100%'"
		#mtmEmbedVideo></embed-video>
</div>