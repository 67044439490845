import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'app/shared/services/helper.service';
import { MediaPlayerComponent } from '../../media-player/media-player.component';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';

@Component({
	selector: 'hr-badgebtn-subcmp',
	templateUrl: './badge-btn-list.component.html',
	styleUrls: ['./badge-btn-list.component.scss']
})
export class HRBadgebtn implements OnChanges, OnInit {
	@Input() file: any;
	@Input() isDisplayTrashBtn: boolean = true;
	@Input() moveButtonShown: boolean = false;
	@Input() localFile: boolean = false;

	@Input() uploading: boolean = false;

	@Input() directPreview: boolean = false;
	
	@Output() onTrashFile: EventEmitter<any> = new EventEmitter<any>();
	@Output() fileMove: EventEmitter<any> = new EventEmitter<any>();
	fileData: any;
	isMediaFile: boolean = false;

	constructor(private modalService: NgbModal) {

	}

	ngOnInit(): void {
		// TODO:- Commented below code due to file upload issue in company profile remove after confirmation
		// if (!this.file.id && this.file.files.length > 0) {
		// 	this.fileData = this.file.files[0];
		// }
		if (!this.file.id) {
			this.fileData = this.file;
		}
		if (this.file.id) {
			this.fileData = this.file;
		}
	}

	ngOnChanges() {
		this.isMediaFile = HelperService.isMediaFile(this.file);
	}

	onTrash(file) {
		// TODO:- Commented below code due to file upload issue in company profile remove after confirmation
		// if (!file.id && file.files.length > 0) {
		// 	this.onTrashFile.emit(file.files[0]);
		// }
		if (!file.id) {
			this.onTrashFile.emit(file);
		}
		if (file.id) {
			this.onTrashFile.emit(file);
		}
	}

	onDownloadFile(file: any) {
		if (!this.file.id)
			return;

		window.open(this.file.signedURL);
	}

	previewFile(file) {
		const modal = this.modalService.open(FilePreviewModalComponent, { windowClass: 'media-player-preview' });
		modal.componentInstance.directPreview = this.directPreview;
		// TODO:- Commented below code due to file upload issue in company profile remove after confirmation
		// if (!file.id && file.files.length > 0) {
		// 	modal.componentInstance.file = file.files[0];
		// }
		if (!file.id) {
			modal.componentInstance.file = file;
		}
		if (file.id) {
			modal.componentInstance.file = file;
		}
	}


	moveFile(file: any) {
    this.fileMove.emit(file);
	}
}
