import { Injectable } from "@angular/core";
import { DownPaymentInfoModalComponent } from "../../payment/downpayment-info-modal/downpayment-info-modal.component";
import { CreditCardDetailsModalComponent } from "../../payment/credit-card-details/credit-card-details-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "./project.service";
import { Router } from "@angular/router";
import { NotificationService } from "./notification.service";

@Injectable({
	providedIn: 'root'
})
export class FinalpaymentService {

	private projectID;

	constructor(private modalService: NgbModal,
		private projectService: ProjectService,
		private router: Router) {

	}


	public async openFinalpaymentModals(proposalId) {
		let proposal;
		this.projectID = this.projectService.project.id;
		try {
			proposal = await this.projectService.getProposalById(this.projectID, proposalId).toPromise();
		} catch (e) {
			return
		}
		let modal_2 = this.modalService.open(CreditCardDetailsModalComponent, {
			size: 'lg',
			windowClass: 'company-matched-blur-color',
			backdrop: false,
			keyboard: false
		});
		modal_2.componentInstance.projectId = this.projectID;
		modal_2.componentInstance.projectType = this.projectService.project.projectType;
		modal_2.componentInstance.selectedProposal = proposal;
		modal_2.componentInstance.paymentType = 'FINAL_PAYMENT';
		modal_2.componentInstance.totalMTM = 3242;
		modal_2.result.then(this.naigateToProjectDashboard).catch(this.naigateToProjectDashboard)

	}

	naigateToProjectDashboard = () => {
		this.router.navigate(['/projects/' + this.projectID + '/dashboard']);
	}

}
