import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubscriptionService } from "../../../services/subscription.service";
import { SubscriptionPlanService } from "../../../services/plan.service";
import { Subject } from "rxjs";
import {
	CostItem,
	PlanAddOn, PlanAddOnTypes,
	PlanInfo
} from "../../../models/plan";
import { BILLING_TYPE, PLANS, getBillingPeriodMultiplier } from "../../../models/const";
import { takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubscriptionConfirmDowngradeComponent } from "../confirm-downgrade/confirm-downgrade.component";
import { Router } from "@angular/router";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { EmitterService } from "app/shared/services/emitter.service";
import { UpgradePlanStateChanged } from "../../models/upgrade-tab.model";
import { NotificationService } from "app/shared/services/notification.service";
import * as moment from "moment";

@Component({
	selector: 'mtm-subscription-upgrade-confirm-plan',
	templateUrl: './confirm-plan.component.html',
	styleUrls: ['./confirm-plan.component.scss']
})
export class SubscriptionUpgradeConfirmPlanComponent implements OnInit, OnDestroy {
	private ngUnsubscribe = new Subject();
	plans: PlanInfo[] = [];
	planName: string = PLANS.STANDARD;
	prevPlanName: string = PLANS.BUSINESS;
	currentPlan: PlanInfo = null;
	prevPlan: PlanInfo = null;
	//userAddOn: PlanAddOn = null;
	activeStorageAddOn: PlanAddOn = null;
	archivalStorageAddOn: PlanAddOn = null;
	planCost: CostItem = null;
	totalPlanCost: number = 0;
	selectedPeriod: string = BILLING_TYPE.MONTHLY;
	userCost: CostItem = null;
	activeStorageCost: CostItem = null;
	archivalStorageCost: CostItem = null;
	userCount: number = 0;
	activeStorageCount: number = 0;
	archivalStorageCount: number = 0;
	totalUserCost: number = 0;
	totalActiveStorageCost: number = 0;
	totalArchivalStorageCost: number = 0;
	totalUserCostPerMonth: number = 0;
	totalActiveStorageCostPerMonth: number = 0;
	totalArchivalStorageCostPerMonth: number = 0;
	totalAddOnCost: number = 0;
	totalPayment: number = 0;
	isFree: boolean = false;
	subscriptionInfo: any;
	trialEnded: boolean = true;

	constructor(
		public subscriptionService: SubscriptionService,
		private planService: SubscriptionPlanService,
		private modalService: NgbModal,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private router: Router,
		private notificationService: NotificationService,
	) {
		this.getSubscriptionInfo();
	}

	ngOnInit() {
		this.subscriptionService.registrationState = 3;

		if (this.subscriptionService.upgradeData) {
			if (this.subscriptionService.upgradeData.plan)
				this.planName = this.subscriptionService.upgradeData.plan.code;
			if (this.subscriptionService.upgradeData.billingType)
				this.selectedPeriod = this.subscriptionService.upgradeData.billingType;
			if (this.subscriptionService.upgradeData.previousPlan)
				this.prevPlanName = this.subscriptionService.upgradeData.previousPlan.code;
		}

		this.isFree = this.planName == PLANS.FREE;
		//use cache for now
		this.plans = this.subscriptionService.upgradeData.plansInfo.plans;
		this.initPlan(this.subscriptionService.upgradeData.plansInfo);

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	initPlan(data: any) {
		this.currentPlan = this.plans.find(p => p.code == this.planName);
		this.prevPlan = this.plans.find(p => p.code == this.prevPlanName);
		this.planCost = this.currentPlan.basePrice.monthlyPrice;
		this.activeStorageAddOn = data.addon.find(a => a.code == 'ACTIVE_STORAGE_PLAN');
		this.archivalStorageAddOn = data.addon.find(a => a.code == 'ARCHIVE_STORAGE_PLAN');
		this.changePeriod(this.selectedPeriod);
	}

	changePeriod(period: string) {
		this.selectedPeriod = period;
		this.subscriptionService.upgradeData.billingType = period;
		this.planCost = this.currentPlan.basePrice[period];
		this.userCost = this.currentPlan.basePrice[period];
		this.activeStorageCost = this.activeStorageAddOn.basePrice[period];
		this.archivalStorageCost = this.archivalStorageAddOn.basePrice[period];
		this.updateTotalCost(PlanAddOnTypes.Users);
		this.updateTotalCost(PlanAddOnTypes.ActiveStorage);
		this.updateTotalCost(PlanAddOnTypes.ArchivalStorage);
	}

	get AddOnTypes(): typeof PlanAddOnTypes {
		return PlanAddOnTypes;
	}

	get BillingType() {
		return BILLING_TYPE;
	}

	increment(type: PlanAddOnTypes) {
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount < this.currentPlan.maxUsers - this.currentPlan.minUsers) {
					this.userCount++;
				}
				break;
			case PlanAddOnTypes.ActiveStorage:
				this.activeStorageCount++;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				this.archivalStorageCount++;
				break;
		}
		this.updateTotalCost(type);
	}

	decrement(type: PlanAddOnTypes) {
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount > 0)
					this.userCount--;
				break;
			case PlanAddOnTypes.ActiveStorage:
				if (this.activeStorageCount > 0)
					this.activeStorageCount--;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				if (this.archivalStorageCount > 0)
					this.archivalStorageCount--;
				break;
		}
		this.updateTotalCost(type);
	}


	resetField(action: () => void) {
		setTimeout(() => action(), 100);
	}

	updateTotalCost(type: PlanAddOnTypes) {
		const multiplier = getBillingPeriodMultiplier(this.selectedPeriod);
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount < 0) {
					this.resetField(() => { this.userCount = 0; this.updateTotalCost(PlanAddOnTypes.Users); });
				}
				if (this.userCount > (this.currentPlan.maxUsers - this.currentPlan.minUsers)) {
					this.resetField(() => { this.userCount = this.currentPlan.maxUsers - this.currentPlan.minUsers; this.updateTotalCost(PlanAddOnTypes.Users); });
				}
				this.totalUserCostPerMonth = this.userCount * this.userCost.perMonthValue;
				this.totalUserCost = this.totalUserCostPerMonth * multiplier;
				break;
			case PlanAddOnTypes.ActiveStorage:
				if (this.activeStorageCount < 0) {
					this.resetField(() => { this.activeStorageCount = 0; this.updateTotalCost(PlanAddOnTypes.ActiveStorage); });
				}
				this.totalActiveStorageCostPerMonth = this.activeStorageCount * this.activeStorageCost.perMonthValue;
				this.totalActiveStorageCost = this.totalActiveStorageCostPerMonth * multiplier;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				if (this.archivalStorageCount < 0) {
					this.resetField(() => { this.archivalStorageCount = 0; this.updateTotalCost(PlanAddOnTypes.ArchivalStorage); });
				}
				this.totalArchivalStorageCostPerMonth = this.archivalStorageCount * this.archivalStorageCost.perMonthValue;
				this.totalArchivalStorageCost = this.totalArchivalStorageCostPerMonth * multiplier;
				break;
		}
		this.totalPlanCost = this.planCost.value * this.currentPlan.minUsers;
		this.totalAddOnCost = this.totalUserCost + this.totalActiveStorageCost + this.totalArchivalStorageCost;
		this.totalPayment = this.totalPlanCost + this.totalAddOnCost;
		this.updateData();
	}

	updateData() {
		const model = this.subscriptionService.upgradeData;
		model.billingType = this.selectedPeriod;
		model.billingInfo.extraUserCount = this.userCount;
		model.billingInfo.extraActiveStorageCount = this.activeStorageCount;
		model.billingInfo.extraArchivalStorageCount = this.archivalStorageCount;
	}

	proceed() {
		if (!this.prevPlan || !this.currentPlan)
			return;

		if (this.currentPlan.order < this.prevPlan.order) {
			if (this.trialEnded === true) {
				const modalRef = this.modalService.open(SubscriptionConfirmDowngradeComponent, {
					windowClass: 'rounded-modal'
				});
				modalRef.result.then(result => {
					if (result) {
						this.goToNextStep();
					}
				})
			} else {
				this.downGradePlan();
			}
		} else {
			this.goToNextStep();
		}
	}

	validate(): boolean {
		if (this.userCount < 0 || this.userCount > (this.currentPlan.maxUsers - this.currentPlan.minUsers)
			|| this.activeStorageCount < 0 || this.archivalStorageCount < 0) {
			return false;
		}

		return true;
	}

	private goToNextStep() {
		if (!this.validate()) {
			this.notificationService.open({
				title: this.translatePipe.transform('error'),
				description: this.translatePipe.transform('subscription_register_confirm_plan_verifyAddOn'),
				confirmBtn: this.translatePipe.transform('accept')
			});
			return;
		}

		this.subscriptionService.upgradeData.planConfirmed = true;

		if (this.subscriptionService.upgradeData.plan.code == 'FREE_PLAN') {
			this.subscriptionService.downgradeToFreePlan()
				.subscribe(result => {
					this.subscriptionService.upgradeData.paymentDone = true;
					EmitterService.get(UpgradePlanStateChanged).emit({ planConfirmed: true, paymentDone: true });
					this.router.navigate(['/subscription/upgrade/produce']);
				}, err => {
					console.log(err);
					this.overlayService.showError(this.translatePipe.transform('overlayErrorOccurred'), 'Error');
				});

		} else {
			EmitterService.get(UpgradePlanStateChanged).emit({ planConfirmed: true })
			this.router.navigate(['/subscription/upgrade/payment']);
		}
	}
	/**
	 * trial info
	 */
	getSubscriptionInfo() {
		this.subscriptionService.getSubscriptionInfo()
			.subscribe((res: any) => {
				this.subscriptionInfo = res;
				this.trialEnded = res.trialEnded;
				if (this.trialEnded === undefined) {
					this.trialEnded = true;
				} else {
					this.trialEnded = false;
				}
			})
	}

	/* notification modal will open when user downgrade a paid plan to free plan
	*/
	downGradePlan() {
		if (this.trialEnded === false && this.subscriptionService.upgradeData.plan.code == 'FREE_PLAN') {
			this.notificationService.open({
				title: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_title'),
				notificationType: 'success',
				centerHeader: true,
				notificationInfo: 'Subscription plan downgrade will come into effect immediately. This will therefore end any ongoing 7 day trial period.',
				question2: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_continueQuestion'),
				confirmBtn: this.translatePipe.transform('confirm'),
				cancelBtn: this.translatePipe.transform('cancel'),
				reverseButton: true
			}).subscribe(confirm => {
				if (confirm === true) {
					this.goToNextStep();
				}
			});
		} else {
			this.goToNextStep();
		}
	}
}
