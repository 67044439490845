import { AuthService } from 'app/shared/services/auth.service';
import { Subject, Observable } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { Injectable } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

import { VideoChatIncomingComponent } from 'app/layouts/video-chat/video-chat-incoming/video-chat-incoming.component';

export interface DesktopNotification {
	id?: string,
	title: string,
	body: string,
	icon?: string,
	sound?: any
}

@Injectable({
	providedIn: 'root'
})
export class DesktopNotificationService {
	private lastestVideoChatConversationWSMessage: any;
	private lastestVideoChatConversationTime: any;
	private audioPlayPromise: any;

	constructor(private authService: AuthService,
		private modalService: NgbModal) {
	}

	/* ----------- * - * ----------- */

	private setVideoChatRefs(lastestVideoChatConversationWSMessage = null, lastestVideoChatConversationTime = null) {
		this.lastestVideoChatConversationWSMessage = lastestVideoChatConversationWSMessage;
		this.lastestVideoChatConversationTime = lastestVideoChatConversationTime;
	}

	public queueVideoChatIncoming(projectId: string, wsMessage: any, playSound: boolean = true) {
		if (wsMessage.projectId != projectId || wsMessage.object.caller.username == this.authService.getAuthUserName()) {
			return;
		}

		if (this.lastestVideoChatConversationTime && moment(this.lastestVideoChatConversationTime).fromNow() == "a few seconds ago") {
			return;
		}

		this.setVideoChatRefs(wsMessage, moment.now())
		let modalRef = this.modalService.open(VideoChatIncomingComponent, { size: 'lg' });
		if (playSound) {
			modalRef.componentInstance.playSoundRinging();
		}
		modalRef.componentInstance.setParams(wsMessage.object);
		modalRef.result
			.then((isAccepted) => {
				if (!isAccepted)
					this.setVideoChatRefs(null, null);
			})
			.catch(res => {
				//if modal close when clicked outside of video-chat-incoming-component on the modal
				this.setVideoChatRefs(null, null);
			});

	}

	/* ----------- * - * ----------- */

	/**
	 * Add DesktopNotification to queue
	 * @param projectId
	 * @param notif
	 */
	public queueTextMessage(projectId: string, notif: DesktopNotification): Observable<any> {
		let subject = new Subject<any>();

		// Use timeout to avoid running this same code, at the same time, in +2 tabs/pages
		// So, avoid displaying multiple desktop notifications
		setTimeout(() => {
			if (!projectId || projectId == '') {
				subject.next(false);
				return;
			}

			var projectNotifications = [];
			if (localStorage.getItem('notifications.' + projectId))
				projectNotifications = JSON.parse(localStorage.getItem('notifications.' + projectId));
			localStorage.setItem('notifications.' + projectId, JSON.stringify([]));

			// Add notification if not found
			let notifFound = projectNotifications.findIndex((notifStorage: DesktopNotification) => notifStorage.id == notif.id) < 0 ? false : true;

			// If notification not found in localStorage, then add it and display desktop notification
			// So, it means this page/tab is the first to receive web socket notification -> and will display desktop notification
			if (!notifFound) {
				projectNotifications.push(notif);
				localStorage.setItem('notifications.' + projectId, JSON.stringify(projectNotifications));

				// Display desktop notification
				this.playSound(projectId, notif);

				// If more than 5 notifications, then clean
				if (projectNotifications.length > 5)
					localStorage.removeItem('notifications.' + projectId);
			}

			subject.next(!notifFound);
		}, HelperService.genRandomNumber(1, 2000));

		return subject.asObservable();
	}

	/**
	 * Show notification
	 * @param projectId
	 */
	private playSound(projectId: string, notif: DesktopNotification) {
		if (!notif.sound)
			return;

		// Check user settings in order to play or not beep sound
		let playSound = true;
		let authSettings = this.authService.getAuthUserSettings();
		if (authSettings && notif.sound.type == 'beep' && (authSettings.muteConversations === true || authSettings.muteConversations === "true")) {
			playSound = false;
		}

		if (!playSound)
			return;

		let audio = new Audio();
		audio.src = `assets/sound/${notif.sound.type}.mp3`;

		if (notif.sound.loop) {
			let loopCount = 0, _ctrl = this;
			audio.addEventListener('ended', function () {
				loopCount++;
				if (loopCount < 5) {
					_ctrl.audioPlayPromise = this.play();
				}
			})
		}

		audio.load();
		this.audioPlayPromise = audio.play();
	}
}
