import { MainLayoutComponent } from "./layouts/main-layout.component";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "./shared/guards/auth.guard";
import { MtmAdminGuard } from "./shared/guards/mtm-admin.guard";

import { Error404Component } from "app/errors/404/error-404.component";
import { SuccessComponent } from "./success/success.component";
import { FailureComponent } from "./errors/failure/failure.component";
import { SignupGuard } from "./signup/shareds/core/signup.guard";
import { VerificationGuard } from "./verification/shareds/core/verification.guard";
import { canActivateShortener } from "./shared/guards/shortener.guard";

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'entry',
		pathMatch: 'full',
	},
	{
		path: 'mtm-tcp',
		loadChildren: () => import('./sub-modules/tcp-module/tcp-module').then(m => m.TCPModule)
	},
	{
		path: 'entry',
		loadChildren: () => import('./sub-modules/entry-module/entry-module').then(m => m.EntryModule)
	},
	{
		path: 'admin',
		component: MainLayoutComponent,
		canActivate: [AuthGuard, MtmAdminGuard],
		loadChildren: () => import('./sub-modules/admin-module/admin-module').then(m => m.AdminModule)
	},
	{
		path: 'account',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./sub-modules/account-module/account-module').then(m => m.AccountModule)
	},
	{
		path: 'workspace',
		component: MainLayoutComponent,
		loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule)
		// loadChildren: './workspace/workspace.module#WorkspaceModule'
	},
	{
		path: 'projects',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./sub-modules/projects-module/projects-module').then(m => m.ProjectsModule)
	},
	{
		path: 'success',
		component: SuccessComponent
	},
	{
		path: 'failure',
		component: FailureComponent
	},
	{
		path: 'short/:slug',
		component: MainLayoutComponent,
		canActivate: [canActivateShortener],
	},
	{
		path: 'projects/:projectId',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		data: {
			showNav: true,
		},
		loadChildren: () => import('./sub-modules/project-modules/project-RootModule').then(m => m.ProjectRootModule)
	},
	{
		path: 'video-chat/:projectId/:id/:roomId',
		loadChildren: () => import('./sub-modules/videoChat-module/videoChat-module').then(m => m.VideoChatModule)
	},
	{
		path: 'review/:reviewLinkId',
		component: MainLayoutComponent,
		loadChildren: () => import('./sub-modules/review-link/review-link.module').then(m => m.ReviewLinkModule),
		data: {
			publicReviewLink: true
		}
	},
	{
		path: 'subscription',
		component: MainLayoutComponent,
		loadChildren: () => import('./subscriptions/subscription.module').then(m => m.SubscriptionModule)
	},
	{
		path: 'signup',
		component: MainLayoutComponent,
		canActivate: [SignupGuard],
		loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
		data: {
			publicPage: true
		}
	},
	{
		path: 'verification',
		component: MainLayoutComponent,
		canActivate: [VerificationGuard],
		loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
		data: {
			publicPage: true
		}
	},
	{
		path: 'tasks',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./sub-modules/task-module/task.module').then(m => m.TaskModule)
	},
	{
		path: 'workspaces',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./workspace-v2/workspace-v2.module').then(m => m.WorkspaceV2Module)
	},
	{
		path: 'kanban',
		component: MainLayoutComponent,
		// canActivate: [AuthGuard],
		loadChildren: () => import('./kanban/project/project.module').then(m => m.KanbanProjectModule)
	},
	{
		path: '404',
		component: Error404Component
	},
	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
