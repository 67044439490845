import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { Authority, PermissionService } from "../services/permissions.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectManagerGuard implements CanActivate {

    constructor(private route: Router, private authService: AuthService, public permissionService: PermissionService) { }

    /**
     * Validate if user is logged in. If not, redirect to Login page
     * @param route
     * @param state
     * @returns {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // If no logged in, redirect to /login
        if (!this.authService.getAuthUser()) {
            this.route.navigate(['/entry']);
            return false;
        }

        // Get roles of user in current project
        const projectId = route.params.projectId

        let hasAccess = this.permissionService.hasAuthority(Authority.U, projectId);

        // If no access, redirect to projects dashboard
        if (!hasAccess) {
            this.route.navigate(['/projects/' + projectId + '/dashboard']);
            return false;
        }
        else
            return true;

    }

}
