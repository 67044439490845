import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
	transform(int) {
		const ones = +int % 10, tens = +int % 100 - ones;
		if (localStorage.getItem('auth_user_language') == 'en_us')
			return ["th", "st", "nd", "rd"][tens === 10 || ones > 3 ? 0 : ones];
		else
			return ["e"];

	}
}
