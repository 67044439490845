<ng-template ngbModalContainer></ng-template>
<mtm-banner [bannerType]="bannerTypes.MaintenanceOnly"></mtm-banner>
<div class="container-fluid main-layout-container" >
	<div class="row main-row">
		<div class="col menubar mx-0">
			<mtm-menubar *ngIf="!publicPage && authUser"></mtm-menubar>
		</div>

		<div class="col mainarea pb-0 mx-0 flex-grow-1 px-0">
			<div class="d-flex">
				<div class="main-container flex-grow-1" [ngClass]="{'has-right-pane': themeOptions?.rightPane}">
					<header [ngClass]="{
						'has-custom-header': themeOptions?.header,
						'no-cover': !workspaceCover && !companyCover,
						'has-cover': workspaceCover || companyCover,
						'full-width-content': themeOptions?.fullWidthContent,
						'position-relative': themeOptions?.header,
					  }" [style.background-image]="themeOptions?.header && workspaceCover ? workspaceCover : (companyCover ? companyCover : '')">


						<div class="d-flex mx-auto top-header align-items-center">
							<div>
								<breadcrumb [useBootstrap]="true" #breadcrumbComponent></breadcrumb>
							</div>
							<top-nav></top-nav>
						</div>

						<div *ngIf="themeOptions?.header">
							<ng-container [ngTemplateOutlet]="themeOptions.header"></ng-container>
						</div>
					</header>

					<div #mainLayoutContainer [style.padding-bottom]="'105px'" class="" [ngClass]="{'top-shift': false}">
						<router-outlet></router-outlet>
					</div>
					<mtm-usertourprogress></mtm-usertourprogress>
				</div>
				<div *ngIf="themeOptions?.rightPane">
					<ng-container [ngTemplateOutlet]="themeOptions.rightPane"></ng-container>
				</div>
			</div>

		</div>
	</div>
</div>
<selector-file-reorder-modal-component *ngIf="serviceFileReorder.isFileReorderShowState">
</selector-file-reorder-modal-component>
<mtm-subtitle-uploader-container></mtm-subtitle-uploader-container>
<mtm-comment-file-uploader-container></mtm-comment-file-uploader-container>
<mtm-ai-framing-folder-builder-container></mtm-ai-framing-folder-builder-container>
<mtm-ai-framing-movie-builder-container></mtm-ai-framing-movie-builder-container>
