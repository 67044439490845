import { Injectable } from "@angular/core";
import { DownPaymentInfoModalComponent } from "../../payment/downpayment-info-modal/downpayment-info-modal.component";
import { CreditCardDetailsModalComponent } from "../../payment/credit-card-details/credit-card-details-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "./project.service";
import { Router } from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class DownpaymentService {

	private projectID;

	constructor(private modalService: NgbModal,
		private projectService: ProjectService,
		private router: Router) {

	}

	public async openDownpaymentModals(proposalId) {
		let proposal;
		this.projectID = this.projectService.project.id;

		try {
			proposal = await this.projectService.getProposalById(this.projectID, proposalId).toPromise();
		} catch (e) {
			return
		}
		let modal = this.modalService.open(DownPaymentInfoModalComponent, {
			size: 'lg',
			windowClass: 'company-matched-blur-color',
			backdrop: false
		});
		modal.componentInstance.projectId = this.projectID;
		modal.componentInstance.proposalSelected = proposal;
		modal.componentInstance.isComingFromMailorNot = true;
		modal.result.then((res: any) => {
			if (res == 'CLOSED') {
				this.naigateToProjectDashboard();
			}
		}).catch(this.naigateToProjectDashboard);
	}

	public openDownpaymentModalCreditCard(proposal, totalMtm, mtmFee, projectId, projectType) {
		let modal_2 = this.modalService.open(CreditCardDetailsModalComponent, {
			size: 'lg',
			windowClass: 'company-matched-blur-color',
			backdrop: false
		});
		modal_2.componentInstance.projectId = projectId;
		modal_2.componentInstance.projectType = projectType;
		modal_2.componentInstance.selectedProposal = proposal;
		modal_2.componentInstance.paymentType = 'DOWNPAYMENT';
		modal_2.componentInstance.totalMTM = totalMtm;
		modal_2.componentInstance.mtmFee = mtmFee;
		modal_2.result.then(this.naigateToProjectDashboard).catch(this.naigateToProjectDashboard)
	}

	naigateToProjectDashboard = () => {
		this.router.navigate(['/projects/' + this.projectID + '/dashboard']);
	}
}
