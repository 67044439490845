export interface SimpleUser {
    email: string;
    fullName: string;
    username: string;
    roles: string[];
    rsvp: string;
    blocked: boolean;
    avatarUrl: string;
    jobTitle: string;
}

export interface SimpleCompany {
    id: string;
    name: string;
    description: string;
    companyType: "ADVERTISER" | "PRODUCER";
}

export interface User {
    username: string;
    firstName: string;
    lastName: string;
    company: SimpleCompany;
    email: string;
    phone: string;
    message: string | null;
    avatarUrl: string | null;
    verified: boolean;
    blocked: boolean | null;
    blockDate: boolean | null;
    unblockDate: number | null;
    companyId: string;
    activationDate: number;
    activationStatus: string;
    globalRole: string;
    userConnectionId: string | null;
    termsAndConditionsAgree: boolean;
    deleted: boolean;
    inviter: string;
    jobTitle: string;
    termsAndConditionsAcceptedDate: number | null;
    lastSeen: number | null;
    lastauthattempt: number | null;
    lastauthsuccess: number | null;
    oneshotaccess: boolean;
    subscriptionaccess: boolean;
    brandId: string | null;
    fullName: string;
    storagePath: string;
    domainEmail: string;
}

export enum OwnershipTransferTypes {
    PROJECT = "PROJECT",
    WORKSPACE = "WORKSPACE"
}

export interface UserRole {
    id: string;
    name: string;
}

export interface RoleInfo {
    code: string;
    label: string;
}
