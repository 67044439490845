import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectService } from "app/shared/services/project.service";
import { ProposalCommentService } from 'app/shared/services/proposal-services/comment.service';

import { CommentModel } from '../comment.model';
import { ProposalFileService } from '../../../../shared/services/proposal-services/proposalfile.service';
import * as moment from 'moment';
import 'moment-timezone';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import ProposalConversationBase from '../proposal-conversation.base';
import { SignedUrlService } from "../../../../shared/services/signedurl.service";
import { UserService } from "../../../../shared/services/user.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import DOMPurify from 'dompurify';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';

@Component({
	selector: 'mtm-project-proposal-discussion',
	templateUrl: './project-proposal-discussion.component.html',
	styleUrls: ['./project-proposal-discussion.component.scss']
})
export class ProjectProposalDiscussionComponent extends ProposalConversationBase implements OnInit, OnDestroy {
	@ViewChild('commentContainer', { static: true }) commentContainer: ElementRef;
	discussionSectionID: string = '2ae63754-3323-11e8-b467-0ed5f89f718b';
	uploadSectionId: string = "36ab19aa-43a5-225a-4380-0d537d84bedd";
	sectionId: string = "30dbc93a-2aca-5838-418c-5b529e3bbdf8";
	subsectionId: string = "05d52e21-d6dd-7dc0-d899-6a9dc035e914";
	projectId: string = '';
	proposalId: string = '';
	projectData: any = {};
	allMessages: any[] = [];
	authUser: any;
	uploadedFiles: any[] = [];
	availableExtensions = ['avi', 'doc', 'fla', 'jpg', 'jpeg', 'mp3', 'mp4', 'pdf', 'png', 'ppt', 'psd',
		'rtf', 'svg', 'txt', 'xls', 'zip'];
	views: any = {
		'grid': 1,
		'list': 2,
		'upload': 3
	};
	view: number = this.views['grid'];
	newComment: any;
	project: any;
	ngUnsubscribe = new Subject();
	constructor(
		public userService: UserService,
		private router: Router,
		private activeRouter: ActivatedRoute,
		private serviceAuth: AuthService,
		private serviceProposalComment: ProposalCommentService,
		private signedUrlService: SignedUrlService,
		private proposalFileService: ProposalFileService,
		private sane: DomSanitizer,
		stompService: MtmStompService,
		private projectService: ProjectService,
		injector: Injector) {
		super(injector, stompService, serviceAuth);

		this.setFncReceiveMessageFromWS(this.receivedMessageFromWS);
	}


	ngOnInit() {
		this.projectId = this.activeRouter.parent.snapshot.params['projectId'];
		this.proposalId = this.activeRouter.parent.snapshot.params['proposalId'];
		this.getMessages(this.proposalId);
		this.getFiles(this.proposalId);

		this.subscribeWS_ProposalConversation(this.proposalId);

		//this.getMessages();
		this.authUser = this.serviceAuth.getAuthUser();
		this.projectService.getProject(this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((project) => {
			this.projectService.project = project;
			this.projectService.project.conversations = [];
			this.project = this.projectService.project;
		})
	}

	ngOnDestroy() {
		this.unsubscribes();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private receivedMessageFromWS(data) {
		if (data.code != "PROPOSAL_MESSAGE_DISCUSSION")
			return;
		let username = data.username;

		this.userService.findUserByUsername(username, false, true).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				this.allMessages.push({
					fullName: res.fullName,
					avatarUrl: res.avatarUrl,
					username: username,
					message: JSON.parse(data.objectJson).message,
					time: data.time
				});
				setTimeout(() => {
					this.commentContainer.nativeElement.scrollTop = this.commentContainer.nativeElement.scrollHeight;
				}, 0);
			});
	}

	private getMessages(proposalId) {
		this.serviceProposalComment.getAllDiscussionComment(this.projectId, proposalId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			data => {
				if (!Array.isArray(data))
					return;
				let messages: CommentModel[] = data;

				messages.map((k: any) => {
					this.userService.findUserByUsername(k.username, false, true).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						res => {
							k.fullName = res.fullName;
							k.avatarUrl = res.avatarUrl;
							this.allMessages.push(k);
							this.allMessages.sort((a, b) => {
								return a.time - b.time;
							});
							setTimeout(() => {
								this.commentContainer.nativeElement.scrollTop = this.commentContainer.nativeElement.scrollHeight;
							}, 0);
						});
				});
			});
	}

	onSendComment() {
		if (this.isTextContentEmpty(this.newComment))
			return;

		let comment = new CommentModel(this.projectId, this.proposalId, this.sectionId, this.serviceAuth.getAuthUser().username, this.newComment);

		this.serviceProposalComment.setDiscussionComment(this.projectId, this.proposalId, comment).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data: any) => {
				this.newComment = '';
			},
			err => {
				this.newComment = '';
			});
	}

	private getFiles(proposalId) {
		this.proposalFileService.getProposalFiles(this.projectId, this.sectionId, this.subsectionId, proposalId).pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe((res: any[]) => {
				res = res.filter(f => f.projectId == this.projectId);
				if (Array.isArray(res)) {
					res.map((f) => {
						f.uploadedDate = this.getUploadedDate(f.timeCreated);
						f.type = this.getIconUrl(f);
						f.displaySize = this.formatSizeUnits(f.size);
					});
					this.uploadedFiles = res;
				}
			});

	}
	
	private getIconUrl(file: any) {
		const fileExtension = file.displayName.substr(file.displayName.lastIndexOf('.') + 1, 3);
		if (this.availableExtensions.indexOf(fileExtension) >= 0)
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/' + fileExtension + '.svg'));
		else
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/file.svg'));
	}

	private formatSizeUnits(bytes) {
		if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; }
		else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; }
		else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; }
		else if (bytes > 1) { bytes = bytes + ' bytes'; }
		else if (bytes == 1) { bytes = bytes + ' byte'; }
		else { bytes = '0 byte'; }
		return bytes;
	}
	private getUploadedDate(time: number): Date {
		return moment(time).toDate();
	}


	goToUploadProposal() {
		this.router.navigate(['projects', this.projectId, 'proposal', this.proposalId, 'uploadProposal']);
	}


	private isTextContentEmpty(textContent): boolean {
		if (!textContent || textContent == '')
			return true;

		return false;
	}

	public downloadFile(file: any, control: any) {
		this.signedUrlService.downloadSignedUrl(file, {
			control: control
		});
	}
}
