import { Injectable } from '@angular/core';

import { SURLFileUploadService, MTMCustomFile, TransferStatus } from './index';

import setProfileAndImagesProb from 'app/shared/base-functions/item-profile.base.function';

@Injectable({
	providedIn: 'root'
})
export class SURLFUManagerService {
	public static serviceSURL: SURLFileUploadService;

	setRefService(SURLS: SURLFileUploadService) {
		SURLFUManagerService.serviceSURL = SURLS;
	}

	public static syncBetweenPageAndService(files: any[], preUploadURL: string) {
		return SURLFUManagerService.serviceSURL.syncBetweenPageAndUploadingFiles(files, preUploadURL);
	}

	public static uploadNewFiles(files: any[], newFiles: MTMCustomFile[], preUploadURL: string, postUploadURL: string, label: string, card: any, projectId: any, silentUpload = false, asReference = false, refFiles?: any[], parentId?: any, nativeUploadMethod?: any) {
		SURLFUManagerService.serviceSURL.uploadNewFiles(files, newFiles, preUploadURL, postUploadURL, label, card, projectId, silentUpload, asReference, refFiles, parentId, nativeUploadMethod);
	}

	/* ----------- * - * ----------- */

	public static findProfileImageWithElement(element) {
		if (!element || !element.id || element.id == '')
			return;

		let foundItem = SURLFUManagerService.serviceSURL.listFilesUploadStruct.find(k => k.card && k.card.id == element.id && !k.isDeleted);
		if (!foundItem)
			return;

		foundItem.fileUploadObject.uploadFiles
			.filter(item => item.uploadStatusCode == TransferStatus.COMPLETED && !element.files.find(file => file.id == item.dbFileObject.id))
			.forEach(newFile => {
				element.items = element.items.filter(file => file.id !== newFile.dbFileObject.id);
				element.items.unshift(newFile.dbFileObject);
			});

		setProfileAndImagesProb(element, foundItem.fileUploadObject.files);
	}

	public markAsDeletedFromStruct(file) {
		let fileCardId = `/api/projects/${file.projectId}/sections/${file.sectionId}/subsections/${file.subSectionId}/items/${file.sourceId}/preUploadFiles`;
		let index = SURLFUManagerService.serviceSURL.listFilesUploadStruct.findIndex(k => k.id == fileCardId);
		if (index < 0)
			return;
		else
			SURLFUManagerService.serviceSURL.listFilesUploadStruct[index].isDeleted = true;

	}
}
