import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";

//this is general purpose icon component
//which will show initial if imageUrl is not supplied
@Component({
	selector: 'mtm-item-icon',
	templateUrl: './mtm-item-icon.component.html',
	styleUrls: ['./mtm-item-icon.component.scss']
})
export class MtmItemIconComponent implements OnInit, OnDestroy, OnChanges {
	@Input() name: string;
	@Input() imageUrl: string = '';
	@Input() height: string = '24px';
	@Input() width: string = '24px';
	@Input() cacheBuster: string = '';
	@Input() generatedTextColor: boolean = false;
	@Input() generatedBackground: string = '#000';
	@Input() containerWidth: string = '';
	@Input() containerHeight: string = '';
	@Input() containerBackground: string = 'transparent';
	@Input() isSquare: boolean = false;
	@Input() isFullImage: boolean = false;
	@Input() fontSize: string = '';
	@Input() fontBold: boolean = false;
  	@Input() borderRadius: string = '';

	initialColor: string = '';
	initialName: string = '';
	finalImageUrl: string = '';

	constructor() {

	}

	public get wrapperWidth(): string {
		return this.containerWidth ? this.containerWidth : this.width;
	}

	public get wrapperHeight(): string {
		return this.containerHeight ? this.containerHeight : this.height;
	}

	public get imageWidth(): string {
		return this.isFullImage ? '100%' : this.width;
	}

	public get imageHeight(): string {
		return this.isFullImage ? '100%' : this.height;
	}

	ngOnInit() {
		this.setValues();
	}

	ngOnDestroy() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setValues();
	}

	private setValues() {
		this.initialName = this.getInitial(this.name);
		this.initialColor = this.stringToHslColor(this.initialName);
		this.finalImageUrl = this.cacheBuster ? `${this.imageUrl}?v=${this.cacheBuster}` : this.imageUrl;
	}

	private getInitial(fullName = '') {
		if(! fullName){
			return '';
		}
		const allNames = fullName.trim().split(' ');
		const initials = allNames.reduce((acc, curr, index) => {
			if (index === 0 || index === allNames.length - 1) {
				acc = `${acc}${curr.charAt(0).toUpperCase()}`;
			}
			return acc;
		}, '');
		return initials;
	}

	stringToHslColor(initial: string) {
		if (!initial) return;
		let hash = 0;
		for (let i = 0; i < initial.length; i++) {
			hash = initial.charCodeAt(i) + ((hash << 5) - hash);
		}
		let h = hash % 360;
		return 'hsl(' + h + ', 75%, 62%)';
	}

}
