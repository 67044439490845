import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { BreadcrumbService } from "ng5-breadcrumb";
import { BILLING_TYPE, PLANS } from "../../models/const";
import { SubscriptionService } from "../../services/subscription.service";

@Component({
	selector: 'mtm-subscription-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class SubscriptionRegisterComponent implements OnInit, OnChanges, AfterViewInit {

	registerService: SubscriptionService;

	constructor(private _registerService: SubscriptionService, private breadcrumbService: BreadcrumbService) {
		this.registerService = _registerService;
	}
	ngAfterViewInit(): void {
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/register/select-plan",
			"Select Plan"
		);
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/register/confirm-plan",
			"Confirm Plan"
		);
	}

	ngOnInit() {
		this.registerService.registerData.plan = PLANS.FREE;
		this.registerService.registerData.billingType = BILLING_TYPE.MONTHLY;
	}

	ngAfter

	ngOnChanges(changes: SimpleChanges) { }

}
