import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "../../../../shared/services/auth.service";
import { ProposalInvitationService } from "app/shared/services/proposal-invitation.service";
import { NotificationService } from 'app/shared/services/notification.service';
import { TranslatePipe } from "../../../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
	templateUrl: './decline-proposal-popup.component.html',
	styleUrls: ['./decline-proposal-popup.component.scss']
})

export class DeclineProposalPopupComponent implements OnInit, OnDestroy {
	@Input() invitation: any = {};
	@Input() index: number;
	@Input() authUser: any;

	projectId: string;
	id: string;
	roomId: any;
	conversation: any;
	openNewPage: boolean = true;
	//private declineReason: any;
	private declineValue: any;
	private isSubmitted: boolean = false;
	isOther: boolean = false;
	ngUnsubscribe = new Subject();

	declineReasons: { id: string, name: string }[] = [
		{ id: 'Impossible schedule', name: 'Impossible schedule' },
		{ id: 'Imposible budget', name: 'Imposible budget' },
		{ id: 'Ethical reasons (alcohol, cigarettes...)', name: 'Ethical reasons (alcohol, cigarettes...)' },
		{ id: 'Work overload', name: 'Work overload' },
		{ id: 'Client rating', name: 'Client rating' },
		{ id: 'Non-competition clause', name: 'Non-competition clause' },
		{ id: 'Other', name: 'Other' }
	];
	declineReason: any[] = [];

	constructor(private authService: AuthService,
		public activeModal: NgbActiveModal,
		private serviceNotification: NotificationService,
		private proposalInvitationService: ProposalInvitationService,
		private translatePipe: TranslatePipe) {
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

	}

	/**
	 * Close popup
	 */
	private onClose() {
		this.activeModal.close(this.index);
	}
	saveChanges() {
		this.isSubmitted = true;

		if (!this.declineReason)
			return;

		this.proposalInvitationService.declineInvitation(this.invitation, this.authUser).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				this.showNotfy(false);
			},
			err => {
				this.showNotfy(true);
			});
	}

	private showNotfy(isError) {
		this.serviceNotification.open({
			title: isError ? 'Warning' : 'Success',
			description: isError ? this.translatePipe.transform('overlayReorderingContact') : 'Process completed',
			confirmBtn: isError ? 'Ok' : 'Accept'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			if (!isError)
				this.onClose();
		})
	}
	// selectedChange() {
	// 	if(this.declineReason.label == 'Other')
	// 		this.isOther = true;
	// 	else
	// 		this.isOther = false;
	// 	this.invitation['declineReason'] = this.declineReason.value;
	// }

	otherReasonChange() {
		this.declineReason = [this.declineValue];
		this.invitation['declineReason'] = this.declineReason;
	}

	selectedReasons() {
		let index = this.declineReason.indexOf("Other");
		if (index >= 0) {
			this.isOther = true;
			this.declineReason = [this.declineReason[index]];
		}
		else {
			if (this.isOther) {
				this.declineReason = this.declineReason.slice(1, this.declineReason.length);
			} else {
				this.declineReason = [this.declineReason];
			}
			this.invitation['declineReason'] = this.declineReason;
			this.isOther = false;
		}

	}

}
