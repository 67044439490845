<div #content class="model-info-text">
	<div class="modal-body">
		<h4 class="text-center font-weight-bold">Greate News!</h4>
		<p class="text-center font-weight-bold">Your proposal has been accepted!</p>
		<p class="text-center font-weight-bold">Please start to schedule Project Stages</p>
	</div>
</div>

<div class="row mt-2 mb-2 pb-4">
	<div class="col text-center">
		<button class="btn btn-lg btn-danger" (click)="onModalHide()">Fantastic, let's start!</button>
	</div>
</div>