import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { DirectoryService } from 'app/shared/services/directory.service';
import { HelperService } from 'app/shared/services/helper.service';
import { OverlayService } from 'app/shared/services/overlayService';

@Component({
    selector: 'mtm-asset-name',
    templateUrl: './asset-name.component.html',
    styleUrls: ['./asset-name.component.scss']
})
export class MtmAssetNameComponent implements OnInit, OnChanges {
    @Input() projectId: string;
    @Input() file: any;
    @Input() nameAttribute: string;
    @Input() allowFilenameEdit: boolean;

    @Output() nameClick: EventEmitter<any> = new EventEmitter<any>();

    editMode: boolean = false;
    fileName: string;
    fileExtension: string;

    constructor(
        private overlayService: OverlayService,
        private translatePipe: TranslatePipe,
        private directoryService: DirectoryService
    ) { }

    ngOnInit(): void {
        if (!this.file) {
            return;
        }
        this.file.printName = this.file.displayName || this.file.name || this.file.title;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    saveName() {
        this.editMode = false;
        const newFilename = this.fileName + '_' + moment().unix() + this.fileExtension;
        this.file.displayName = this.file.name = this.file.title = this.file.printName = newFilename;
        this.directoryService.renameFolder(this.projectId || this.file.projectId, this.file.id, newFilename)
            .subscribe({
                next: (res: any) => {
                    this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
                },
                error: (err) => {
                    this.overlayService.showError(err.message, 'Error');
                }
            })
    }

    handleNameClick() {
        if (this.allowFilenameEdit) {
            this.editMode = !this.editMode;
            this.fileName = this.getDisplay(this.file.displayName).split('.').slice(0, -1).join('.');
        } else {
            this.nameClick.emit(this.file);
        }
    }

    getDisplay(name: any) {
        if (!name) {
            return '';
        }
        let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
        let nameArr = name.split('_');
        let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
        let savedName = '';
        if (isTime) {
            savedName = nameArr.length ? nameArr.slice(0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_') : name.split('.')[0];
        } else {
            let nameSplit = name.split('.');
            savedName = nameSplit.slice(0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
        }
        let fileDisplayName = savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
		this.fileExtension = '.' + fileDisplayName.split('.')[1];
        return fileDisplayName;
    }

}