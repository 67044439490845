<div class="confirm-downgrade">
	<div class="head">
		<h1 class="title">
			{{'subscription_confirm_downgrade_title'|translate}}
		</h1>
	</div>
	<div class="body">
		<div class="message">
			<p>
				{{'subscription_confirm_downgrade_limitWarning'| translate}}
			</p>
			<p>
				{{'subscription_confirm_downgrade_adjustWarning'|translate}}
			</p>
			<p>
				{{'subscription_confirm_downgrade_continueQuestion'|translate}}
			</p>
		</div>
		<div class="buttons">
			<a class="continue" (click)="continue()">{{'continue'|translate}}</a>
			<a class="cancel" (click)="cancel()">{{'cancel'|translate}}</a>
		</div>
	</div>
</div>