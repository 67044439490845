<ng-template ngbModalContainer></ng-template>
<div class="container">
	<div class="col">
		<div class="row mt-1">
			<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
		</div>
		<div class="row text-center justify-content-center">
			<h3 class="mt-4">Upload profile image</h3>
		</div>
		<div class="row justify-content-center mt-4">
			<div class="card px-2 py-4 mt-1">
				<form class="verification-form px-5" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
					<p class="h5 text-center" *ngIf="!uploading">Please upload your profile image...</p>
					<p class="h5 text-center" *ngIf="uploading">Uploading your profile image...</p>
					<div class="row justify-content-center mt-5 mx-auto ng2-file-drop drop-zone">
						<ngx-file-drop class="row justify-content-center mt-5 mx-auto ng2-file-drop"
							dropZoneClassName="drop-zone" ng2FileDrop (onFileDrop)="dragFileAccepted($event)"
							(onFileOver)="isDragFile = true" (onFileDrop)="isDragFile = false">
							<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

								<a href="#" class="upload-link text-center" [class.on-drag-file]="isDragFile"
									(click)="upload()" *ngIf="!uploading">
									<i class="fa fa-upload fa-3x" aria-hidden="true"></i>
									<h6 class="text-uppercase mt-4">Select a file</h6>
									<p class="text-uppercase font-weight-bold">Or drag & drop<br>an image here</p>
								</a>
								<div class="circle-progress" *ngIf="uploading">
									<circle-progress #uploadProgress [color]="'#49b092'" [img]="thumbnail" [time]="1/2"
										[fontSize]="40" [fontColor]="'#49b092'"
										[fontFamily]="'\'Montserrat\',sans-serif'" [percent]="upService.progress"
										[boxSize]="200" [radius]="98" [border]="7"></circle-progress>
								</div>
							</ng-template>
						</ngx-file-drop>
					</div>
					<input #fileInput class="invisible" type="file" accept="image/png,image/bmp,image/jpeg,image/jpg"
						(change)="fileChangeListener($event)">
					<button *ngIf="upService.progress == 100" class="mt-3 btn btn-secondary btn-block btn-lg"
						(click)="continue()">Continue</button>
					<div class="col">
						<div class="row justify-content-end">
							<a href="" class="text-primary" style="margin-top: 20px" (click)="continue()">Set-up Later
								&nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
						</div>
					</div>


				</form>
			</div>
		</div>
	</div>
</div>