import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy
} from "@angular/core";
import { AudioRecordingService } from 'app/shared/services/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'audio-recorder-modal',
    templateUrl: './audio-recorder-modal.component.html',
    styleUrls: ['./audio-recorder-modal.component.scss']
})
export class AudioRecorderModalComponent implements AfterViewInit, OnDestroy {
    isPlaying = false;
    displayControls = true;
    isAudioRecording = false;
    audioRecordedTime: any;
    audioBlobUrl: any;
    audioBlob: any;
    audioName: any;
    audioStream: any;
    audioConf = { audio: true }

    minutes: number = 0;
    seconds: number = 0;
    timeInterval: any;

    constructor(
        private ref: ChangeDetectorRef,
        private audioRecordingService: AudioRecordingService,
        private sanitizer: DomSanitizer,
        private activeModal: NgbActiveModal
    ) {
        this.audioRecordingService.recordingFailed()
            .subscribe(() => {
                this.isAudioRecording = false;
                this.ref.detectChanges();
            });

        this.audioRecordingService.getRecordedTime()
            .subscribe((time) => {
                this.audioRecordedTime = time;
                this.ref.detectChanges();
            });

        this.audioRecordingService.getRecordedBlob()
            .subscribe((data) => {
                this.audioBlob = data.blob;
                this.audioName = data.title;
                this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
                this.ref.detectChanges();
            });
    }

    ngAfterViewInit(): void { }

    ngOnDestroy(): void { }

    startAudioRecording() {
        this.clearAudioRecordedData();
        if (!this.isAudioRecording) {
            this.isAudioRecording = true;
            this.audioRecordingService.startRecording();
        }
        this.timeInterval = setInterval(() => {
            this.seconds++;
            if (this.seconds == 60) {
                this.seconds = 0;
                this.minutes++;
            }
        }, 1000);
    }

    abortAudioRecording() {
        if (this.isAudioRecording) {
            this.isAudioRecording = false;
            this.audioRecordingService.abortRecording();
        }
        this.clearTimeInterval();
    }

    stopAudioRecording() {
        if (this.isAudioRecording) {
            this.audioRecordingService.stopRecording();
            this.isAudioRecording = false;
        }
        this.clearTimeInterval();
    }

    clearTimeInterval() {
        this.minutes = 0;
        this.seconds = 0;
        clearInterval(this.timeInterval);
    }

    clearAudioRecordedData() {
        this.audioBlobUrl = null;
    }

    downloadAudioRecordedData() {
        this.activeModal.close(new File([new Blob([this.audioBlob])], this.audioName, { type: "audio/mp3", lastModified: new Date().getTime() }));
    }

    closeModal() {
        this.audioRecordingService.stopStreamer();
        this.activeModal.dismiss();
    }
}