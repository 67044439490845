export class CommentModel {
    message: string;
    projectId: string;
    proposalId: string;
    sectionId: string;
    time: any;
    username: string;

    constructor(projectId: string, proposalId: string, sectionId: string, username: string, message: string) {
        this.projectId = projectId;
        this.proposalId = proposalId;
        this.sectionId = sectionId;
        this.username = username;
        this.message = message;
        this.time = Date.now();
    }
}
