import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular'
import { IHeaderParams } from 'ag-grid-community'
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'user-permission-table-header',
    templateUrl: 'user-permission-table-header.component.html',
    styleUrls: ['./user-permission-table-header.component.scss']
})
export class UserPermissionTableHeader implements IHeaderAngularComp, OnInit, OnChanges, OnDestroy {
    @ViewChild('roleCell', { static: false }) roleCell: ElementRef;
    colId: any;
    public params: IHeaderParams;

    searchValue: any;
    ngUnsubscribe = new Subject();

    order: any = '';

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.colId = params.column.getColId();
    }

    refresh(params: IHeaderParams<any>): boolean {
        return true;
    }

    onSortRequested(event: any) {
        if (this.params.column.isSortAscending()) {
            this.order = 'desc';
        } else if (this.params.column.isSortDescending()) {
            this.order = '';
        } else {
            this.order = 'asc';
        }
        this.params.setSort(this.order, event.shiftKey);
    }

    searchUserByQueryParams(e: any, variableName: any) {
        this[variableName].next(e);
    }

    dropdownOpen(event) {
        if (event.isOpen) {
            let row = this.roleCell.nativeElement.closest('.ag-root');
            row.classList.add("header-dropdown-open");
        } else {
            let row = this.roleCell.nativeElement.closest('.ag-root');
            row.classList.remove("header-dropdown-open");
        }
    }


}