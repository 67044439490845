import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ApiService } from '../api.service';

@Injectable({
	providedIn: 'root'
})
export class ProposalFileService {
	constructor(private api: ApiService) { }
	public getProposalFiles(projectId: string, sectionId: string, subsectionId: string, itemId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/files`);
	}

	public getProposalBriefFiles(projectId: string, sectionId: string, subsectionId: string, itemId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${itemId}/briefFiles`);
	}
}


