import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SubscriptionConfirmPlanGuard } from "../core/subscribtion.confirm-plan.guard";
import { SubscriptionPaymentGuard } from "../core/subscription.payment.guard";
import { SubscriptionProduceGuard } from "../core/subscription.produce.guard";
import { SubscriptionSelectPlanGuard } from "../core/subscription.select-plan.guard";
import { SubscriptionRegisterConfirmPlanComponent } from "./pages/confirm-plan/confirm-plan.component";
import { SubscriptionRegisterPaymentComponent } from "./pages/payment/payment.component";
import { SubscriptionRegisterProduceComponent } from "./pages/produce/produce.component";
import { SubscriptionRegisterSelectPlanComponent } from "./pages/select-plan/select-plan.component";

export const routes: Routes = [{
	path: '',
	redirectTo: 'select-plan',
	pathMatch: 'full'
}, {
	path: 'select-plan',
	component: SubscriptionRegisterSelectPlanComponent,
	canActivate: [SubscriptionSelectPlanGuard]
}, {
	path: 'confirm-plan',
	component: SubscriptionRegisterConfirmPlanComponent,
	canActivate: [SubscriptionConfirmPlanGuard]
}, {
	path: 'payment',
	component: SubscriptionRegisterPaymentComponent,
	canActivate: [SubscriptionPaymentGuard]
}, {
	path: 'produce',
	component: SubscriptionRegisterProduceComponent,
	canActivate: [SubscriptionProduceGuard]
}];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SubscriptionRegisterRoutingModule { }
