<div class="modal-header">
	<h4 class="modal-title">Forgot your password?</h4>
	<button id="forgot-password-close-btn" type="button" class="close" aria-label="Close"
		(click)="activeModal.close(responseMsg)">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">

	<form class="signup-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="!emailSent">
		<div class="container">
			<div class="row">
				<div class="col">
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': formGroup.controls.email.invalid && submitted}">
						<input id="forgot-password-email-tb" class="form-control" type="text" formControlName="email"
							placeholder=" "
							[ngClass]="{'form-control-danger': formGroup.controls.email.invalid && submitted}">
						<span *ngIf="!(submitted && formErrors.email)">Email address</span>
						<span class="text-danger" *ngIf="submitted && formErrors.email">{{formErrors.email}}</span>
					</label>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="row btn-footer justify-content-end">
						<button id="forgot-password-submit-btn" type="submit" class="btn btn-lg btn-orange">
							Send Reset Password Email
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="row mt-2 response-msg" *ngIf="emailSent">
		<div class="col text-center">
			<h5>We have sent you an email with a recovery link. Please, check your inbox.</h5>
			<button id="forgot-password-msg-close" class="btn btn-primary btn-lg"
				(click)="activeModal.close(responseMsg)">Close</button>
		</div>
	</div>
</div>