import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimelineGeneratorRoutingModule } from './timeline-generator-routing.module';
import { IndexComponent } from './index/index.component';
import { NewProjectV2Component } from './new-project-v2/new-project-v2.component';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { GeneralComponent } from './general/general.component';
import { UsersPermissionsComponent } from './users-permissions/users-permissions.component';
import { ProductionTimelinesComponent } from './production-timelines/production-timelines.component';

import { NgbAccordionModule, NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailedTimelineComponent } from './production-timelines/detailed-timeline/detailed-timeline.component';
import { RecapComponent } from './recap/recap.component';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { TimelineBlocksComponent } from './timeline-blocks/timeline-blocks.component';
import { DownloadTimelineComponent } from './recap/download-timeline/download-timeline.component';
import { TimelineFullViewComponent } from './timeline-full-view/timeline-full-view.component';
import { SummaryBlockComponent } from './summary-block/summary-block.component';
import { LorealworkspaceComponent } from './lorealworkspace/lorealworkspace.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MtmChatModule } from 'app/sub-modules/mtm-chat-module/mtm-chat.module';


@NgModule({
  declarations: [
    IndexComponent,
    NewProjectV2Component,
    GeneralComponent,
    UsersPermissionsComponent,
    ProductionTimelinesComponent,
    DetailedTimelineComponent,
    RecapComponent,
    TimelineBlocksComponent,
    DownloadTimelineComponent,
    TimelineFullViewComponent,
    SummaryBlockComponent,
    LorealworkspaceComponent
  ],
  imports: [
    CommonModule,
    TimelineGeneratorRoutingModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAccordionModule,
    DragDropModule,
    NgSelectModule,
    MtmChatModule
  ],
  exports: [
    TimelineBlocksComponent
  ]
})
export class TimelineGeneratorModule { }
