import { ColDef } from "ag-grid-community";
import { ActionCellComponent } from "./cell-renderer/action/action-cell.component";
import { RoleCellComponent } from "./cell-renderer/role/role-cell.component";
import { UserCellComponent } from "./cell-renderer/user/user-cell.component";

export const userPermissionColumnsDef: ColDef[] = [
    {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        minWidth: 280,
        cellRenderer: ActionCellComponent
    },
    {
        field: 'fullName',
        headerName: 'User',
        sortable: true,
        flex: 1,
        minWidth: 320,
        cellRenderer: UserCellComponent
    },
    {
        field: 'username',
        headerName: 'Email',
        sortable: true,
        flex: 1,
        minWidth: 240,
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        flex: 1,
        minWidth: 240,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        flex: 1,
        minWidth: 240,
    },
    {
        field: 'roleLabel',
        headerName: 'Role',
        sortable: true,
        flex: 1,
        minWidth: 240,
        cellRenderer: RoleCellComponent
    }
]