<div class="sidebar sidebar-left" [ngClass]="{'side-hover': activeUserMenu || activeProjectsMenu}">
	<div class="logo-container">
		<a href="#" class="logo-link" (click)="homePage();false" title="v.1.5.2">
			<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="35px" height="30px"
				viewBox="0 0 127.000000 111.000000" preserveAspectRatio="xMidYMid meet">
				<g transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)" fill="#F96416" stroke="none">
					<path d="M595 1100 c-22 -5 -56 -9 -76 -9 -36 -1 -139 -37 -150 -53 -3 -5 -16
  -10 -28 -13 -31 -6 -66 -29 -122 -82 -34 -32 -45 -49 -42 -64 4 -13 1 -18 -8
  -14 -8 2 -21 -1 -30 -8 -21 -16 -90 -153 -81 -161 3 -4 1 -11 -6 -15 -36 -22
  -49 -280 -17 -356 7 -16 23 -58 36 -93 13 -34 30 -62 37 -62 7 0 10 -5 7 -11
  -8 -12 32 -73 80 -119 l35 -34 0 287 c0 281 0 287 19 277 11 -5 27 -7 37 -3
  13 5 16 3 11 -10 -4 -12 21 -33 104 -87 61 -40 119 -75 130 -77 10 -3 19 -9
  19 -13 0 -5 20 -22 44 -38 l44 -30 78 48 c88 56 124 87 124 108 0 9 6 12 16 8
  17 -6 76 24 145 73 23 17 44 29 46 27 3 -3 5 -131 5 -286 l0 -282 45 43 c24
  24 53 60 62 79 10 19 30 56 44 81 40 68 82 289 59 303 -5 3 -7 19 -4 35 4 30
  -20 119 -43 156 -6 11 -15 34 -19 50 -13 52 -55 115 -77 115 -12 0 -17 4 -13
  11 9 15 -72 96 -116 117 -19 9 -56 29 -81 44 -42 26 -102 44 -169 52 -14 2
  -43 5 -65 9 -22 3 -58 1 -80 -3z m350 -384 l-36 -65 -66 0 c-43 0 -62 4 -56
  10 15 15 188 126 191 123 2 -1 -13 -32 -33 -68z m-596 42 c20 -12 39 -26 42
  -31 3 -5 9 -8 13 -8 3 0 27 -14 52 -32 l46 -32 -63 -3 c-56 -3 -65 0 -81 20
  -10 13 -18 28 -18 33 0 6 -9 21 -20 35 -35 45 -26 51 29 18z" />
				</g>
			</svg>
		</a>
	</div>


	<ul class="nav flex-column align-bottom left-nav">
		<div *ngIf="!isReviewLinkUser">
			<li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" href="#" [routerLink]="['/projects', project.id, 'dashboard']" id="trainerDashboard"
					routerLinkActive="active" (click)="trainerDashboard()">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-creative-icon></mtm-creative-icon>
					</i>
					<span class="text-capitalize">{{'leftBarDashboard' | translate}}</span>
				</a>
			</li>
			<!--
			<li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" href="#" [routerLink]="['/projects', project.id, 'real-time']"
					routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<real-time-icon></real-time-icon>
					</i>
					<span class="text-capitalize">{{'dashboard_liveStatus' | translate }}</span>
				</a>
			</li>
			-->
			<li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" [routerLink]="['/projects', project.id, 'calendar']" routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-calendar-icon></mtm-calendar-icon>
					</i>
					<span class="text-capitalize">{{'leftBarCalendar' | translate}}</span>
				</a>
			</li>
			<li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" routerLinkActive="active" (click)="setProjectId(project?.id)">
					<i class="fa fa-2x text-center" aria-hidden="true">
						<mtm-tasks-icon></mtm-tasks-icon>
					</i>
					<span class="text-capitalize">{{'tasks' | translate}}</span>
				</a>
			</li>
			<li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" href="#" [routerLink]="['/projects', project.id, 'teamfiles']"
					routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-team-files-icon></mtm-team-files-icon>
					</i>
					<span class="text-capitalize">{{'leftBarFiles' | translate}}</span>
				</a>
			</li>
			<li class="nav-item"
				*ngIf="project && project.id && !publicReviewLink && !isProductionOrProjectStaff() && !showAllWorkspaces">
				<a class="d-flex nav-link" href="#" [routerLink]="['/projects', project.id, 'review-links']"
					routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-review-link-icon></mtm-review-link-icon>
					</i>
					<span class="text-capitalize">{{'reviewLinks' | translate}}</span>
				</a>
			</li>
			<li class="nav-item"
				*ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces && hasSubtitlesAccess">
				<a class="nav-link" href="#" (click)="$event.preventDefault(); goToSubtitles(); "
					routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-subtitles-icon></mtm-subtitles-icon>
					</i>
					<span class="text-capitalize">{{'Subtitles' | translate}}</span>
				</a>
			</li>
			<!-- <li class="nav-item" *ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces">
				<a class="d-flex nav-link" routerLinkActive="active" (click)="setProjectId(project?.id)">
					<i class="fa fa-2x text-center" aria-hidden="true">
						<mtm-tasks-icon></mtm-tasks-icon>
					</i>
					<span class="text-capitalize">kanaban task</span>
				</a>
			</li> -->
			<li class="nav-item"
				*ngIf="project && project.id && !publicReviewLink && !showAllWorkspaces && isElieAccount">
				<a class="nav-link" href="#" (click)="$event.preventDefault(); goToAIReframingUI(); "
					routerLinkActive="active">
					<i class="fa fa-2x" aria-hidden="true">
						<mtm-ai-reframing-icon></mtm-ai-reframing-icon>
					</i>
					<span>{{'AI Reframing' | translate}}</span>
				</a>
			</li>
		</div>

		<div *ngIf="!isReviewLinkUser" class="d-flex flex-column justify-content-start workspace-wrapper"
			style="flex: 1;min-height:0;">

			<div class="workspace-scroller" style="overflow-x:hidden; overflow-y:auto; ">
				<li *ngIf="!project?.id || showAllWorkspaces" class="px-3 py-2 workspaces-label" id="trainerWorkspaceDashboard">
					<div class="d-flex align-items-center workspaces-link" (click)="$event.stopPropagation();openWorkspaceList()">
						{{'dashboard_workspacesDashboard' | translate }}
					</div>
				</li>

				<ng-container *ngIf="!project?.id || showAllWorkspaces">
					<li class="pl-3 pr-2 py-2 other-workspaces-label" *ngIf="otherWorkspaces && otherWorkspaces.length>0">
						<div class="d-flex justify-content-between align-items-center workspace-toggle" (click)="toggleAccordion('other')">
							<span>{{'workspace_otherWorkspaces' | translate }}</span>

							<span class="ml-2">
								<i class="fa" aria-hidden="true" [class.fa-chevron-down]="!accordionState['other']"
									[class.fa-chevron-up]="accordionState['other']"
								></i>
							</span>

						</div>
					</li>

					<div class="workspace-accordion collapse" [class.show]="accordionState['other']">
						<li *ngFor="let workspace of otherWorkspaces" class="workspace-link"
							[class.active]="activeWorkspace && workspace.id == activeWorkspace.id">
							<div class="d-flex align-items-center inner-wrapper">
								<a class="nav-link select-link" (click)="setWorkspace(workspace)">
									<mtm-item-icon [name]="workspace.name" [imageUrl]="workspace.avatarUrl"
										[width]="'36px'" [generatedTextColor]="true" [height]="'36px'"
										[containerWidth]="'55px'"
										[cacheBuster]="workspace.cacheBuster ? workspace.cacheBuster : cacheBuster">
									</mtm-item-icon>
									<div style="font-size: 0.8em; margin-left: 1rem;"
										title="{{ workspace.name + ' - ' + workspace?.company?.name  }}">
										<span class="mb-1 workspace-name">{{workspace.name}}</span>
										<span class="company" *ngIf="workspace?.company?.name">{{ workspace.company.name
											}}</span>
									</div>
								</a>
								<a class="workspace-settings" [routerLink]="['/workspace/edit',workspace.id]"
									*ngIf="hasWorkspaceSettings[workspace.id]">
									<i class="fa fa-sliders ml-auto" aria-hidden="true"></i>
								</a>
							</div>
						</li>
					</div>

					<li *ngIf="otherWorkspaces && otherWorkspaces.length>0" class="workspace-separator"></li>
					<li class="pl-3 pr-2 py-2 my-workspaces-label"
						*ngIf="(myWorkspaces && myWorkspaces.length > 0) || isProductionCompany">
						<div class="d-flex justify-content-between align-items-center workspace-toggle" (click)="toggleAccordion('my')">
							<span>{{'workspace_myWorkspaces' | translate }}</span>
							<span class="ml-2">
								<i class="fa" aria-hidden="true" [class.fa-chevron-down]="!accordionState['my']"
									[class.fa-chevron-up]="accordionState['my']"
								></i>
							</span>
						</div>
					</li>
					<div class="workspace-accordion collapse" [class.show]="accordionState['my']">
						<li *ngFor="let workspace of myWorkspaces" class="workspace-link"
							[class.active]="activeWorkspace && workspace.id == activeWorkspace.id">
							<div class="d-flex align-items-center inner-wrapper">
								<a class="nav-link select-link" (click)="$event.stopPropagation();isLoreal ? openDriveCampaign(workspace) : setWorkspace(workspace)">
									<mtm-item-icon [name]="workspace.name" [imageUrl]="workspace.avatarUrl"
										[width]="'36px'" [generatedTextColor]="true" [height]="'36px'"
										[containerWidth]="'55px'"
										[cacheBuster]="workspace.cacheBuster ? workspace.cacheBuster : cacheBuster">
									</mtm-item-icon>
									<div style="font-size: 0.8em; margin-left: 1rem;"
										title="{{ workspace.name + ' - ' + workspace?.company?.name  }}">
										<span class="mb-1 workspace-name">{{workspace.name}}</span>
										<span class="company" *ngIf="workspace?.company?.name">{{ workspace.company.name
											}}</span>
									</div>
								</a>
								<a class="workspace-settings" [routerLink]="['/workspace/edit',workspace.id]"
									*ngIf="hasWorkspaceSettings[workspace.id]">
									<i class="fa fa-sliders ml-auto" aria-hidden="true"></i>
								</a>
							</div>
						</li>
					</div>
					<ng-container *ngIf="hasMarketplaceAccess && (! project?.id || showAllWorkspaces)">
						<li class="workspace-link in-marketplace" [class.active]="inMarketplaceWorkspace && !project?.id">
							<div class="d-flex align-items-center inner-wrapper">
								<a class="nav-link select-link" (click)="setMarketplaceWorkspace()">
									<mtm-item-icon [imageUrl]="marketplaceAvatar" [name]="'Marketplace'" [width]="'36px'" [height]="'36px'"
									[generatedTextColor]="true" >
									</mtm-item-icon>
									<span style="font-size: 0.8em; margin-left: 1rem;">MTM Marketplace</span>
								</a>
							</div>
						</li>
					</ng-container>
					<li class="workspace-link new-workspace" *ngIf="canCreateWorkspace">
						<a class="nav-link select-link" href="#" [routerLink]="['/workspace/new']">
							<span class="icon-wrapper">
								<i class="fa fa-plus  fa-2x" aria-hidden="true"></i>
							</span>
							<span class="text-span">{{'workspace_createNewWorkspace'|translate}}</span>
						</a>
					</li>
					<li *ngIf="isLoreal" class="workspace-link in-marketplace">
						<div class="d-flex align-items-center inner-wrapper" >
							<a class="nav-link select-link ml-3" (click)="setAnalytics()">
								<img id="projectDashboardview" src="../.../../../../assets/img/project-v2//analytics.svg" alt="">
								<span style="font-size: 0.8em; margin-left: 2rem;">{{'projectDashboard' | translate}}</span>
							</a>
						</div>
					</li>
					<!-- <li *ngIf="isLoreal" class="workspace-link in-marketplace">
						<div class="d-flex align-items-center inner-wrapper" >
							<a class="nav-link select-link ml-3" (click)="openGamification()">
								<img id="projectDashboardview" src="../.../../../../assets/svg/gamification-console.svg" alt="">
								<span style="font-size: 0.8em; margin-left: 2rem;"> Gamification</span>
							</a>
						</div>
					</li> -->
					<li class="workspace-link return-project" *ngIf="project && project.id">
						<a class="nav-link select-link" (click)="returnToProject()">
							<span class="icon-wrapper">
								<i class="fa fa-chevron-right fa-2x" aria-hidden="true"></i>
							</span>
							<span class="text-span">{{'leftBarReturn'|translate}}</span>
						</a>
					</li>
				</ng-container>
				<ng-container *ngIf="(project?.id || inMarketplaceWorkspace) && !showAllWorkspaces">
					<li class="workspace-link" *ngIf="activeWorkspace">
						<div class="d-flex align-items-center inner-wrapper">
							<a class="nav-link select-link" (click)="$event.stopPropagation();isLoreal ? openDriveCampaign(activeWorkspace) : setWorkspace(activeWorkspace)">
								<mtm-item-icon [name]="activeWorkspace.name" [imageUrl]="activeWorkspace.avatarUrl"
									[generatedTextColor]="true" [width]="'36px'" [height]="'36px'"
									[containerWidth]="'55px'">
								</mtm-item-icon>
								<div style="font-size: 0.8em; margin-left: 1rem;">
									<span class="mb-1 workspace-name">{{activeWorkspace.name}}</span>
									<span class="company" *ngIf="activeWorkspace?.company?.name">{{
										activeWorkspace.company.name }}</span>
								</div>
							</a>
							<a class="workspace-settings" [routerLink]="['/workspace/edit', activeWorkspace.id]"
								*ngIf="hasWorkspaceSettings[activeWorkspace.id]">
								<i class="fa fa-sliders ml-auto" aria-hidden="true"></i>
							</a>
						</div>
					</li>
					<li class="workspace-link active" *ngIf="project && project.id">
						<a class="nav-link select-link" href="#" [routerLink]="['/projects',  project.id]">
							<div style="position: relative;">
								<mtm-item-icon [name]="project.name" [imageUrl]="project.avatarUrl" [width]="'36px'"
									[height]="'36px'" [generatedTextColor]="true" [cacheBuster]="projectCacheBuster"
									[containerWidth]="'55px'">
								</mtm-item-icon>
								<div class="dot" *ngIf="hasUnseenNotifications" aria-hidden="true"
									style="right:12px;top: 3px;"></div>
							</div>
							<span class="text-span">{{ project.name }}</span>
						</a>
					</li>
					<li  class="nav-item project-settings-nav project-setting-btn" *ngIf="project && project.id" hasUserAccess
						[authority]="authority.U" id="trainerProjectSetting" [projectId]="project.id" >
						<a class="d-flex nav-link" href="#" routerLinkActive="active"(click)="trainerProjectSetting()"
							[routerLink]="['/projects', 'edit', project.id, initState || 'company']">
							<i class="fa fa-sliders fa-2x" aria-hidden="true"></i>
							<span>{{'leftBarProjectSettings'|translate}}</span>
						</a>
					</li>
					<li class="nav-item project-settings-nav" *ngIf="project && project.id" [projectId]="project.id">
						<a class="d-flex nav-link" href="#" routerLinkActive="active"
							[routerLink]="['/projects', project.id, 'notification-setting']">
							<i _ngcontent-oca-c175="" aria-hidden="true" class="fa fa-bell-o fa-fw fa-2x"></i>
							<span>{{'leftBarNotificationSettings'| translate}}</span>
						</a>
					</li>
					<li class="workspace-link new-workspace all-workspace" *ngIf="project?.id">
						<a class="nav-link select-link" (click)="backToWorkspaces()" id="trainerOpenWorkspaceDashboard">
							<span class="icon-wrapper">
								<i class="fa fa-chevron-left fa-2x" aria-hidden="true"></i>
							</span>
							<span class="text-span text-left">{{'leftBarShowAllWorkspaces'|translate}}</span>
						</a>
					</li>
				</ng-container>

			</div>
		</div>

		<li class="nav-item user-nav-item" [class.forceBottom]="true">
			<a class="nav-link d-flex align-items-center" href="#" (click)="$event.preventDefault()"
				(mouseover)="userHover()" (mouseleave)="userLeave()">
				<mtm-user-profile-picture [forceLoadProfile]="true" [user]="authUser" [username]="authUser?.username"
					[height]="36" [width]="36" style="width: 55px;">
				</mtm-user-profile-picture>
				<span>{{displayName}}</span>
			</a>
		</li>
	</ul>
</div>
<!-- User submenu -->

<div #userMenu class="user-menu" [hidden]="!activeUserMenu" (mouseenter)="userHover()" (mouseleave)="userLeave()">
	<ul class="nav flex-column">
		<li *ngIf="!publicReviewLink" class="nav-item user-menu-item">
			<a href="#" class="nav-link text-nowrap pr-4" [routerLink]="'/account/manage'">{{'leftBarAccountManage' |
				translate}}</a>
			<div #manageAccountMenu [ngClass]="{'overlapped-height': displayCompanyProfileMenuItem }"
				class="manage-company-menu" (mouseover)="manageCompanyMenuHover()"
				(mouseleave)="manageCompanyMenuHover()">
				<ul class="nav flex-column">
					<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.S">
						<a href="#" class="nav-link text-nowrap pr-4" [routerLink]="'/account/company-detail'"
							fragment="company-details">{{'leftBarAccountCompanyDetails'|translate}}</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.S">
						<a href="#" class="nav-link text-nowrap pr-4" [routerLink]="'/account/company-security-policy'">
							{{'leftBarAccountCompanySecurityPolicy'|translate}}
						</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.S">
						<a href="#" class="nav-link text-nowrap pr-4"
							[routerLink]="'/account/manage'">{{'leftBarAccountManageUser' | translate}}</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.S">
						<a href="#" class="nav-link text-nowrap pr-4"
							[routerLink]="'/account/brands'">Brands & Workspaces</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.S">
						<a href="#" class="nav-link text-nowrap pr-4"
							[routerLink]="'/account/manage/billing'">{{'leftBarAccountManageBilling' | translate}}</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item">
						<a href="#" class="nav-link text-nowrap pr-4"
							[routerLink]="'/account/settings'">{{'leftBarAccountSettings'
							| translate}}</a>
					</li>
					<li *ngIf="!publicReviewLink" class="nav-item">
						<a href="#" class="nav-link text-nowrap pr-4"
							[routerLink]="'/account/preferences'">{{'leftBarAccountPreferences' | translate}}</a>
					</li>
				</ul>
			</div>
		</li>
		<li *ngIf="!publicReviewLink && isSelectedWorkspace" class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4"
			[routerLink]="['/account', selectedWorkspaceId,'notifications-settings']" >{{'leftBarNotificationSettings' | translate}}</a>
			<!-- [routerLink]="'/account/notifications-settings'">{{'leftBarNotificationSettings' | translate}}</a> -->
		</li>
		<li class="nav-item" *ngIf="displayCompanyProfileMenuItem && !publicReviewLink" hasUserAccess
			[authority]="authority.E">
			<a href="#" class="nav-link text-nowrap pr-4" [routerLink]="'/account/company'">{{'companyProfile' | translate }}</a>
		</li>
		<li *ngIf="!publicReviewLink" class="nav-item" hasUserAccess [authority]="authority.Z">
			<a href="#" class="nav-link text-nowrap pr-4" [routerLink]="'/admin'">Admin</a>
		</li>
		<li class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4" (click)="onLogout()">{{'leftBarLogout' | translate}}</a>
		</li>
	</ul>
</div>
<!-- / User submenu -->

<!-- Projects submenu -->
<div class="projects-menu" [hidden]="!activeProjectsMenu" (mouseover)="projectsHover()" (mouseleave)="projectsLeave()">
	<ul class="nav flex-column">
		<li class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4">Project 1</a>
		</li>
		<li class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4">Project 2</a>
		</li>
		<li class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4">Project 3</a>
		</li>
		<li class="nav-item">
			<a href="#" class="nav-link text-nowrap pr-4">Project 4</a>
		</li>
	</ul>
</div>
<!-- / Projects submenu -->

<!-- self trainer overlay -->
<div *ngIf="this.stService.startSelfTrainer" class="trainer-overlay">
</div>
