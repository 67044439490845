import { Injectable } from "@angular/core";
import { Observable, Subject, forkJoin } from "rxjs";
// import { JwtHelper } from 'angular2-jwt';
import { ApiService } from "./api.service";
import { HelperService } from './helper.service';
import { IAllowedOperation } from "./permissions.service";
import { EmitterService } from "./emitter.service";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { contentLanguageUpdated } from "./notification.service";
import { HelpHeroService } from "./help-hero.service";

export interface CompanySimple {
	id?: string,
	name: string,
	description: string,
	companyType: string
}

export interface User {
	id?: string,
	username: string,
	firstName: string,
	lastName: string,
	company: CompanySimple,
	email: string,
	phone: string,
	avatarUrl?: string,
	globalRole: string
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private isProduction: boolean = null;
	private isProject: boolean = null;
	private GLOBAL_SCOPE_PROJECT_ID: string = "ffffffff-ffff-ffff-ffff-ffffffffffff";

	constructor(private apiService: ApiService,
		private subscriptionService: SubscriptionService,
		private helpHeroService: HelpHeroService) { }

	private proceedLogin(subject: Subject<any>, data: any) {
		const { email_verified, phone_verified, hasLtgPermission } = data;
		if (typeof email_verified !== 'undefined' || typeof phone_verified !== 'undefined') {
			subject.next({ verificationNeed: true, auth: data.auth, phone: data.phone, hasLtgPermission });
			subject.complete();
		} else {
			this.setAuthUser(data.user);
			// Save token and user on localStorage

			const checkSubscription = () => {
				this.subscriptionService.getCurrentPlan().subscribe(res => {
					this.setAuthUserSubscription(res);
				});
			}

			this.setAuthTokenWithTests(data.auth.token, data.user).subscribe({
				next: () => {
					// Add timestamp
					this.setAuthSession();

					console.log('set session');
					//Add default language
					//if (!localStorage.getItem('auth_user_language')) {
					//	this.setAuthLanguage("en_us");
					//}

					if (data.userProfileSettings)
						this.setAuthUserSettings(data.userProfileSettings);

					// this.helpHeroService.identifyUser(data.user);

					checkSubscription();
					console.log('set user profiles');
					subject.next({ challenge: false, hasLtgPermission });
					subject.complete();
				},
				error: (err) => {
					checkSubscription();
					subject.error(err);
				}
			})

		}
	}

	verify2fa(email: string, code: string, mechanism: string) {
		const endpoint = `/api/auth/${email}/verification/${code}/${mechanism}`;
		const subject = new Subject<any>();
		this.apiService.httpPost(endpoint, {}).subscribe(
			(data: any) => {
				localStorage.clear();
				sessionStorage.clear();
				this.proceedLogin(subject, data);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	newLogin(email: string, password: string, remember: boolean = false, projectId: string,isTrainer?:boolean,trainerUserName?:string) {
		let body = {};
		// for self trainer 
		if (isTrainer) {
			body={"username": trainerUserName};
		} else {
			body = {
				email: email,
				password: password,
			};
		}
		HelperService.deleteAuthCookie();
		let subject = new Subject<any>();
		let url:string;
		if (isTrainer) {
			url = '/api/auth/login/training';
		} else {
			url ='/api/auth';
		}
		console.log('url', url)
		if (projectId != null)
			url += '/' + projectId;
		this.apiService.httpPost(url, body).subscribe(
			(data: any) => {
				// set ltg permission
				localStorage.setItem('hasLtgPermission', JSON.stringify(data.hasLtgPermission));
				const { twoFa = false, twoFaMechanism = '' } = data;
				if (!twoFa) {
					this.proceedLogin(subject, data);
					return;
				}
				this.changeToken(data.auth.token);
				subject.next({ challenge: true, mechanism: twoFaMechanism });
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	login(email: string, password: string, remember: boolean = false, projectId: string) {
		// Build body
		let body = {
			email: email,
			password: password,
		};

		HelperService.deleteAuthCookie();
		let subject = new Subject<any>();
		let url = '/api/auth';
		if (projectId != null)
			url += '/' + projectId;
		this.apiService.httpPost(url, body).subscribe(
			(data: any) => {

				this.setAuthUser(data.user);
				// Save token and user on localStorage
				this.setAuthToken(data.auth.token).subscribe(() => {
					// Add timestamp
					this.setAuthSession();

					//Add default language
					if (!localStorage.getItem('auth_user_language')) {
						this.setAuthLanguage("en_us");
					}

					if (data.userProfileSettings)
						this.setAuthUserSettings(data.userProfileSettings);

					subject.next(data);
				})
			},
			(err: any) => {
				subject.error(err);
			});

		return subject.asObservable();
	}

	loginExternalUser(token: string, user: any) {
		this.setAuthToken(token, true);
		this.setAuthUser(user, true);
	}

	changeToken(token) {
		this.setAuthToken(token);
		this.setAuthSession();
	}

	logout(): boolean {
		// this.helpHeroService.resetUser();
		this.apiService.httpPost('/api/auth/logout', null).subscribe(
			(data: any) => {
				console.log('logout success');
			},
			(err: any) => {
				console.log('logout error', err);
				HelperService.deleteAuthCookie();
			}
		)

		let lang = localStorage.getItem('auth_user_language');
		localStorage.clear();
		sessionStorage.clear();
		if (lang) {
			localStorage.setItem('auth_user_language', lang)
		}

		return true;
	}

	getAuthUserName() {
		let authUser = this.getAuthUser();
		if (!authUser) {
			const registerData = JSON.parse(localStorage.getItem('register_data'));
			if (registerData) {
				authUser = { username: registerData.user.email };
			}
		}
		return authUser ? authUser.username : null;
	}

	getAuthUser(external: boolean = false): any {
		let user = null;
		if (!external)
			user = localStorage.getItem('auth_user');
		else
			user = localStorage.getItem('auth_user_external');

		return (user) ? JSON.parse(user) : null;
	}

	getAuthUserSettings() {
		if (localStorage.getItem('auth_user_settings'))
			return JSON.parse(localStorage.getItem('auth_user_settings'));
		else
			return null;
	}

	getAuthToken(): any {
		if (localStorage.getItem('token'))
			return localStorage.getItem('token');
		else if (localStorage.getItem('token_external'))
			return localStorage.getItem('token_external');
		else
			return null;
	}

	getAuthSession(): any {
		if (localStorage.getItem('auth_session'))
			return localStorage.getItem('auth_session');
		else
			return null;
	}

	isTokenExpired(): boolean {
		if (localStorage.getItem('token')) {
			let jwtHelper: JwtHelperService = new JwtHelperService();
			let token = localStorage.getItem('token');
			return (jwtHelper.isTokenExpired(token));
		}
		return false;
	}

	printTokenInfo() {
		if (localStorage.getItem('token')) {
			let jwtHelper: JwtHelperService = new JwtHelperService();
			let token = localStorage.getItem('token');
		}
	}

	setAuthUser(user: any, external: boolean = false) {
		if (!user.globalRole || !user.companyId) {
			return
		}
		if (!user.avatarUrl)
			user.avatarUrl = HelperService.getAvatarNoImage();
		else
			user.avatarUrl = HelperService.getFixedAvatarUrl(user.avatarUrl);

		if (!external)
			localStorage.setItem('auth_user', JSON.stringify(user));
		else
			localStorage.setItem('auth_user_external', JSON.stringify(user));
	}

	updateAuthUserCompany(newCompany: CompanySimple, external: boolean = false) {
		const authUser = this.getAuthUser(external);
		const oldCompany = authUser.company;

		if (oldCompany.id == newCompany.id && oldCompany.name == newCompany.name
			&& oldCompany.description == newCompany.description && oldCompany.companyType == newCompany.companyType) {
			return;
		}

		authUser.company = newCompany;
		authUser.companyId = newCompany.id;
		this.setAuthUser(authUser, external);
		EmitterService.get('authUser.updateCompany').emit();
	}

	setAuthUserSubscription(subscription: any) {
		localStorage.setItem('auth_user_company_subscription', JSON.stringify(subscription));
	}

	setAuthUserUpcomingBilling(upcomingBilling: any) {
		localStorage.setItem('auth_user_company_upcoming_billing_subscription', JSON.stringify(upcomingBilling));
	}

	getAuthUserUpcomingBilling() {
		return JSON.parse(localStorage.getItem('auth_user_company_upcoming_billing_subscription'));
	}

	getAuthUserSubscription() {
		return JSON.parse(localStorage.getItem('auth_user_company_subscription'));
	}

	hasActiveSubscription() {
		const subInfo = this.getAuthUserSubscription();
		return subInfo && subInfo.accountStatus == 'ACTIVE';
	}

	hasFreeSubscription() {
		const subInfo = this.getAuthUserSubscription();
		return subInfo && subInfo.accountStatus == 'ACTIVE' && subInfo.planType == 'FREE';
	}

	hasPaidSubscription() {
		const subInfo = this.getAuthUserSubscription();
		return subInfo && subInfo.accountStatus == 'ACTIVE' && subInfo.planType != 'FREE';
	}

	setAuthUserSettings(userSettings) {
		localStorage.setItem('auth_user_settings', JSON.stringify(userSettings));
	}

	private testSomeEndpoints() {

	}

	//this is test method. will remove later
	setAuthTokenWithTests(token: string, user: any, external: boolean = false) {
		if (!external)
			localStorage.setItem('token', token);
		else
			localStorage.setItem('token_external', token);

		const subject = new Subject<any>();

		const complete = () => {
			this.updateAllowedOperations()
				.subscribe({
					next: () => {
						subject.next(undefined);
						subject.complete();
					},
					error: (err) => {
						subject.error(err);
					}
				});
		}

		forkJoin([
			this.apiService.httpGet(`/api/verify-session`),
			//this.apiService.httpGet(`/api/teams/${user.companyId}`),
			//this.apiService.httpGet(`/api/loreal-timeline/drive-campaigns/custom-views`),
		]).subscribe({
			next: (res) => {
				console.log('test api result', res)
				complete();
			},
			error: (err) => {
				console.error('test api error', err);
				complete();
			}
		})

		return subject.asObservable();
	}


	setAuthToken(token: string, external: boolean = false) {
		if (!external)
			localStorage.setItem('token', token);
		else
			localStorage.setItem('token_external', token);

		return this.updateAllowedOperations();
	}

	setAuthSession() {
		let timestamp = "" + (new Date()).getTime();
		localStorage.setItem('auth_session', timestamp);
	}

	sendVerificationCode(code: number): Observable<any> {
		let subject = new Subject<any>();

		let authUser = this.getAuthUser();

		this.apiService.httpPost('/api/users/' + authUser.username + '/verification/' + code, null)
			.subscribe(
				(data: any) => {
					subject.next(data);
				},
				(err: any) => {
					subject.error(err);
				});

		return subject.asObservable();
	}

	resendVerificationCode(): Observable<any> {

		let subject = new Subject<any>();
		let authUser = this.getAuthUser();

		this.apiService
			.httpGet('/api/users/' + authUser.username + '/verification')
			.subscribe(
				(data: any) => {
					subject.next(data);
				}, (err: any) => {
					subject.error(err);
				});

		return subject.asObservable();
	}

	resendVerificationCodeToAlternate(phoneNumber: string): Observable<any> {

		let subject = new Subject<any>();

		let authUser = this.getAuthUser();
		if (!authUser) {
			const registerData = JSON.parse(localStorage.getItem('register_data'));
			authUser = { username: registerData.user.email };
		}

		this.apiService
			.httpPost('/api/users/' + authUser.username + '/verification/' + phoneNumber + '/alternate', null)
			.subscribe(
				(data: any) => {
					subject.next(data);
				}, (err: any) => {
					subject.error(err);
				});

		return subject.asObservable();
	}

	getAuthUserFromServer() {

		let subject = new Subject<any>();

		// Get user data and save on localStorage
		this.apiService.httpGet('/api/users/me').subscribe((data: any) => {
			subject.next(data);
		})

		return subject.asObservable();
	}

	setActivationStatus(activationStatus: string) {
		let authUser = this.getAuthUser();
		authUser.activationStatus = activationStatus;
		this.setAuthUser(authUser);
	}

	requestVerificationSendPasswordToken(email: string): Observable<any> {
		return new Observable((subscriber) => {
			this.apiService.httpPost('/api/auth/password?email=' + encodeURIComponent(email), null).subscribe((data: any) => {
				subscriber.next(data);
			}, (err) => {
				subscriber.error(err);
			});
		})
	}

	verifyUserPasswordToken(token: string): Observable<any> {

		let subject = new Subject<any>();

		this.apiService.httpGet('/api/auth/password/' + token)
			.subscribe((data: any) => {
				let res = {
					firstName: data.firstName,
					lastName: data.lastName,
					token: token,
					companyId: data.companyId
				}

				subject.next(res);
			});

		return subject.asObservable();

	}

	resetUserPassword(token: string, newPassword: string = null, reNewPassword: string = null) {

		let subject = new Subject<any>();

		let body = {
			token: token,
			newPassword: newPassword,
			reNewPassword: reNewPassword
		};

		this.apiService.httpPut('/api/auth/password', body)
			.subscribe((data: any) => {
				subject.next(data);
			})

		return subject.asObservable();

	}

	sendLoginEmailVerificationLink(email: string): Observable<any> {

		let subject = new Subject<any>();

		this.apiService.httpGet('/api/auth/login/' + email)
			.subscribe(
				(data: any) => {
					subject.next(data);
				},
				(err: any) => {
					console.error('Error sending email verification link', err);
					subject.error(err);
				});

		return subject.asObservable();
	}

	isAuthUser(external: boolean = false) {
		return (this.getAuthUser(external) !== null);
	}

	getUserSettings(name: string) {
		let settings = this.getAuthUserSettings();
		if (settings) {
			if (settings[name])
				return settings[name];
		}
		return null;
	}

	public updateAllowedOperations(projectId = null, sections = null): Observable<IAllowedOperation[]> {
		let self = this;

		function addSectionIdsToOperation(operation) {
			(sections || []).filter(section => operation.phase == section.phase && operation.section == section.section && Array.isArray(section.subsections))
				.forEach(section => {
					let subsection = section.subsections.find(ss => operation.subSection == ss.subSection);
					operation.projectSectionId = section.id;
					operation.projectSubSectionId = subsection ? subsection.id : null;
				});
		}

		function addShootingSectionsToOperations(operations: any[]) {
			let shootingSection = (sections || []).find(section => Array.isArray(section.subsections) && section.section === 'SHOOTING') || { subsections: [] };
			for (let index = 0; index < shootingSection.subsections.length; index++) {
				let result = operations.find(k => k.section == 'SHOOTING');
				if (!result || (Array.isArray(result) && result.length <= 0))
					return operations;
				if (Array.isArray(result))
					result = result[0];
				let DTO: IAllowedOperation = {
					phase: result.phase,
					role: result.role,
					permission: result.permission,
					global: false,
					section: shootingSection.section,
					subSection: shootingSection.subsections[index].subSection,
					projectId: projectId,
					projectSectionId: shootingSection.id,
					projectSubSectionId: shootingSection.subsections[index].id
				};
				operations.push(DTO);
			}
		}

		function addAllowedOperationsForRoles(userProjectRoles, projectId, projectPermissions) {
			let projectRoles = userProjectRoles[projectId];
			projectRoles = projectRoles && Array.isArray(projectRoles) ? projectRoles : [];
			let operations = []
			for (let index = 0; index < projectRoles.length; index++) {
				let role = projectRoles[index]
				if (projectPermissions[role]) {
					operations = operations.concat(projectPermissions[role].map(operation => {
						operation.global = false;
						operation.projectId = projectId
						if (sections) {
							addSectionIdsToOperation(operation);
						}
						return operation;
					}));
				}
			}
			addShootingSectionsToOperations(operations);
			return operations;
		}

		function addAllowedOperationsForRoleGlobal(projectRoles, projectPermissions) {
			let operations = [];
			Object.keys(projectRoles).forEach(pid => {
				let permissionSet = new Set();
				projectRoles[pid].forEach(role => {
					projectPermissions[role].forEach(operation => operation.permission.forEach(p => permissionSet.add(p)));
				});
				operations.push({
					permission: Array.from(permissionSet).join(''),
					global: true,
					projectId: pid
				});
			});
			return operations;
		}

		function updateAuthUser(allowedOperations: any[], authOperations, observer, storeOperations = true) {
			authOperations[projectId || self.GLOBAL_SCOPE_PROJECT_ID] = allowedOperations;
			if (storeOperations) {
				localStorage.setItem('auth_user_allowed_operations', JSON.stringify(allowedOperations));
				localStorage.setItem('auth_project_operations', JSON.stringify(authOperations))
			}
			observer.next(allowedOperations);
			observer.complete();
			EmitterService.get('authUser.update').emit();
		}

		return new Observable(observer => {
			let authOperations = JSON.parse(localStorage.getItem('auth_project_operations')) || {};
			function complete() {
				let roles = localStorage.getItem('auth_user_project_roles');
				let permissions = localStorage.getItem('auth_user_project_role_permissions');
				if (roles != null && permissions != null) {
					let userProjectRoles = roles ? JSON.parse(roles) : {};
					let projectPermissions = permissions ? JSON.parse(permissions) : [];

					self.isProduction = !!userProjectRoles[self.GLOBAL_SCOPE_PROJECT_ID].find(r => r.startsWith("PRODUCTION"));
					self.isProject = !!userProjectRoles[self.GLOBAL_SCOPE_PROJECT_ID].find(r => r.startsWith("PROJECT"));
					let allowedOperations = addAllowedOperationsForRoleGlobal(userProjectRoles, projectPermissions);
					if (projectId != null && sections) {
						allowedOperations = allowedOperations.concat(addAllowedOperationsForRoles(userProjectRoles, projectId, projectPermissions));
					}

					if (allowedOperations.find(op => op.projectSectionId || op.projectSubSectionId) || projectId == null) {
						updateAuthUser(allowedOperations, authOperations, observer);
					} else {
						updateAuthUser(allowedOperations, authOperations, observer, false);
					}
				}
			}

			let restored = authOperations[projectId || self.GLOBAL_SCOPE_PROJECT_ID];
			if (typeof restored == "undefined" || projectId != null && [...restored].filter(r => r.projectId == projectId).length == 0) {
				const authUser = this.getAuthUser();
				if (authUser) {
					forkJoin([
						this.apiService.httpGet(`/api/projects/users/${this.getAuthUser().username}/roles`),
						this.apiService.httpGet(`/api/projects/users/${this.getAuthUser().username}/role-permissions`)
					]).subscribe((result) => {
						localStorage.setItem('auth_user_project_roles', JSON.stringify(result[0]));
						localStorage.setItem('auth_user_project_role_permissions', JSON.stringify(result[1]));
						complete();
					}, () => {
						console.error('Failed to get role / role-permission');
						observer.error()
					})
					// this.apiService.httpGet(`/api/projects/users/${this.getAuthUser().username}/roles`).subscribe((res) => {
					// 	localStorage.setItem('auth_user_project_roles', JSON.stringify(res));
					// 	complete();
					// }, () => {
					// 	console.error('user project role update error');
					// 	observer.error()
					// });

					// this.apiService.httpGet(`/api/projects/users/${this.getAuthUser().username}/role-permissions`).subscribe((res) => {
					// 	localStorage.setItem('auth_user_project_role_permissions', JSON.stringify(res));
					// 	complete();
					// }, () => {
					// 	console.error('user project role permissions update error');
					// 	observer.error()
					// });
				} else {
					updateAuthUser(self.getAllowedOperations(), authOperations, observer, false);
				}
			} else {
				if (sections) {
					restored.filter(op => op.section).forEach(op => addSectionIdsToOperation(op));
				}
				addShootingSectionsToOperations(restored);
				updateAuthUser(restored, authOperations, observer);
			}
		});
	}

	public getAllowedOperations(): IAllowedOperation[] {
		if (localStorage.getItem('auth_user_allowed_operations')) {
			return JSON.parse(localStorage.getItem('auth_user_allowed_operations'));
		}
		return [];
	}

	/**
	 * Login with token. Used for social login
	 * @param token
	 */
	loginWithToken(token: string, username: string) {

		let subject = new Subject<any>();

		// Set auth token
		this.setAuthToken(token).subscribe(() => {
			// Get user from server
			this.apiService.httpGet('/api/users/' + username)
				.subscribe(
					(user: any) => {
						this.setAuthUser(user);
						this.apiService.httpGet('/api/users/' + username + '/profile-settings').subscribe(
							(profile_settings: any) => {
								this.setAuthUserSettings(profile_settings);
							}
						);
						this.subscriptionService.getCurrentPlan().subscribe({
							next: (res) => {
								this.setAuthUserSubscription(res);
							}
						})
						this.updateAllowedOperations().subscribe(() => subject.next(undefined));
					},
					(err: any) => {
						console.log('AuthService.loginWithToken()', err);
						subject.error(err);
					});

		})
		return subject.asObservable();
	}

	setAuthLanguage(lang) {
		contentLanguageUpdated.emit(lang);
		localStorage.setItem('auth_user_language', lang);
	}

	getAuthLanguage(useDefault: boolean = true): string | null {
		const lang = localStorage.getItem('auth_user_language');
		if (lang) {
			return lang;
		}
		return useDefault ? 'en_us' : null;
	}

	setJitsiToken(token: string, external: boolean = false) {
		if (!external)
			localStorage.setItem('jitsi_token', token);
		else
			localStorage.setItem('jitsi_token_external', token);
	}

	loginWithTokenForOneShot(token: string, username: string, projectId: string) {

		let subject = new Subject<any>();
		this.setAuthToken(token).subscribe(() => {
			this.apiService.httpGet('/api/users/' + username + '/oneShotRequest/' + projectId)
				.subscribe(
					(user: any) => {
						this.setAuthUser(user);
						this.apiService.httpGet('/api/users/' + username + '/profile-settings').subscribe(
							(profile_settings: any) => {
								this.setAuthUserSettings(profile_settings);
							}
						);
						subject.next(undefined);
					},
					(err: any) => {
						console.log('AuthService.loginWithToken()', err);
						subject.error(err);
					});
		});
		return subject.asObservable();
	}

	hasProductionRole() {
		if (this.isProduction == null) {
			let authUser = this.getAuthUser();
			if (authUser && (authUser.globalRole === "ADMIN" || authUser.globalRole.startsWith('PRODUCTION'))) {
				this.isProduction = authUser.company.companyType == "PRODUCER"
			}
		}
		return this.isProduction;
	}

	hasProjectRole() {
		if (this.isProject == null) {
			let authUser = this.getAuthUser();
			if (authUser && (authUser.globalRole === "ADMIN" || authUser.globalRole.startsWith('PROJECT'))) {
				this.isProject = authUser.company.companyType == "ADVERTISER"
			}
		}
		return this.isProject;
	}

	authenticate(data: any): Observable<IAllowedOperation[]> {
		// Save token and user on localStorage
		this.setAuthToken(data.auth.token);
		// Add timestamp
		this.setAuthSession();

		//Add default language
		if (!localStorage.getItem('auth_user_language')) {
			this.setAuthLanguage("en_us");
		}

		this.setAuthUser(data.user);
		if (data.userProfileSettings)
			this.setAuthUserSettings(data.userProfileSettings);

		return this.updateAllowedOperations()
	}

	setSubscriptionCompanyFlagInfos(flagInfos: any) {
		localStorage.setItem('company_subscription_flag_infos', JSON.stringify(flagInfos));
	}

	getSubscriptionCompanyFlagInfos() {
		return JSON.parse(localStorage.getItem('company_subscription_flag_infos'));
	}

	setPlatformEvents(platformEvents: any) {
		localStorage.setItem('platform_events_infos', JSON.stringify(platformEvents));
	}

	getPlatformEvents() {
		return JSON.parse(localStorage.getItem('platform_events_infos'));
	}

	getMyBrand() {
		return JSON.parse(localStorage.getItem('brand'));
	}

	setMyBrand(brand: any) {
		localStorage.setItem('brand', JSON.stringify(brand));
	}
}
