import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

//copied from SO answer https://stackoverflow.com/questions/46068908/how-to-cancel-unsubscribe-all-pending-http-requests-in-angular-4
@Injectable()
export class HttpCancelService {
	private cancelPendingRequests$ = new Subject<void>()

	constructor() {
	}

	// Cancels all pending HTTP requests. Use with caution
	public cancelPendingRequests() {
		this.cancelPendingRequests$.next(undefined)
	}

	public onCancelPendingRequests() {
		return this.cancelPendingRequests$.asObservable()
	}
}
