import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RegisterPaymentDetail } from "../../models/subscription-register.model";
import { SubscriptionService } from "../../../services/subscription.service";
import { PLANS } from "app/subscriptions/models/const";
import { environment } from "environments/environment";
import { PlanInfo } from "app/subscriptions/models/plan";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ValidatorService } from "app/shared/services/validator.service";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { CountryCodes } from "app/shared/components/mtm-tel-input/models/country-codes.model";
import { PaymentMethodService } from "app/shared/services/payment-method.service";
import { USAStates } from "app/shared/models/usa-states.model";

@Component({
	selector: 'mtm-subscription-register-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class SubscriptionRegisterPaymentComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('creditCardNumber') creditCardNumber: ElementRef;
	@ViewChild('expiry') expiry: ElementRef;
	@ViewChild('cvcNumber') cvcNumber: ElementRef;
	@ViewChild('zipCode') zipCode: ElementRef;

	paymentForm: UntypedFormGroup;
	isFree: boolean = true;
	stripe: any;
	stripeElements: any;
	subscription: any;
	company: any;
	user: any;
	isLoading: boolean = false;

	creditCard: any;
	creditCardExpiry: any;
	creditCardCvcNumber: any;
	creditCardPostal: any;

	cardNumberValid: boolean = false;
	cardExpiryValid: boolean = false;
	cardCvcValid: boolean = false;
	postalCodeValid: boolean = false;
	isTaxIdValid: boolean = true;
	listCountryCodes: any[] = new CountryCodes().allCountries;
	private usaStates: any[] = new USAStates().states;

	constructor(
		private router: Router,
		private authService: AuthService,
		private companyService: CompanyService,
		private subscriptionService: SubscriptionService,
		private validatorService: ValidatorService,
		private overlayService: OverlayService,
		private paymentMethodService: PaymentMethodService,
		private fb: UntypedFormBuilder,
		private translatePipe: TranslatePipe
	) {
		this.user = this.authService.getAuthUser();
		this.buildForm();
	}

	ngAfterViewInit(): void {
		this.companyService.getCompanyById(this.user.companyId)
			.subscribe(res => {
				this.company = res;
				this.handleLoadedCompanyData(this.company);
				this.paymentForm.patchValue({
					companyName: this.company.name
				});
			});
	}

	ngOnInit() {
		this.subscriptionService.registrationState = 4;
		this.subscriptionService.registerData.payment = new RegisterPaymentDetail();
		this.isFree = this.subscriptionService.registerData.plan === PLANS.FREE;
		this.subscriptionService.getCurrentPlan().subscribe((res: any) => {
			const publishKey = this.paymentMethodService.getPublishableKey(this.subscriptionService.currency);
			this.subscription = res;
			this.stripe = Stripe(publishKey);
			this.initStripElements();
		});
	}

	initStripElements() {
		let __this = this;
		this.stripeElements = this.stripe.elements();

		this.creditCard = this.stripeElements.create('cardNumber');
		this.creditCard.mount(this.creditCardNumber.nativeElement);
		this.creditCard.addEventListener('change', (e) => {
			__this.cardNumberValid = e.complete;
		});

		this.creditCardExpiry = this.stripeElements.create('cardExpiry');
		this.creditCardExpiry.mount(this.expiry.nativeElement);
		this.creditCardExpiry.addEventListener('change', (e) => {
			__this.cardExpiryValid = e.complete;
		});

		this.creditCardCvcNumber = this.stripeElements.create('cardCvc');
		this.creditCardCvcNumber.mount(this.cvcNumber.nativeElement);
		this.creditCardCvcNumber.addEventListener('change', (e) => {
			__this.cardCvcValid = e.complete;
		});

		this.creditCardPostal = this.stripeElements.create('postalCode');
		this.creditCardPostal.mount(this.zipCode.nativeElement);
		this.creditCardPostal.addEventListener('change', (e) => {
			__this.postalCodeValid = e.complete;
		});
	}

	ngOnChanges(changes: SimpleChanges) { }

	buildForm() {
		this.paymentForm = this.fb.group({
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			companyName: ['', [Validators.required]],
			address: ['', [Validators.required]],
			city: ['', [Validators.required]],
			zipCode: ['', [Validators.required]],
			country: ['', [Validators.required]],
			state: ['', [Validators.required]],
			taxIdNumber: ['', [Validators.required]],
			billingCcEmail: ['', [Validators.required, Validators.email]]
		});
		this.paymentForm.valueChanges.subscribe(
			(res) => {
				this.subscriptionService.registerData.payment.invoice = {
					...this.subscriptionService.registerData.payment.invoice,
					...{
						firstName: res.firstName,
						lastName: res.lastName,
						companyName: res.companyName,
						address: res.address,
						city: res.city,
						zipCode: res.zipCode,
						country: res.country,
						state: res.state,
						taxIdNumber: res.taxIdNumber,
						billingCcEmail: res.billingCcEmail
					}
				};
			}, (err) => {
				console.error(err);
			});
	}

	updateCompany() {
		const invoiceDetail = this.subscriptionService.registerData.payment.invoice;
		this.company.billingAddress = {
			...this.company.billingAddress,
			address: invoiceDetail.address,
			city: invoiceDetail.city,
			postalCode: invoiceDetail.zipCode,
			country: invoiceDetail.country,
			state: invoiceDetail.state
		};

		this.company.taxId = invoiceDetail.taxIdNumber;
		this.company.billingDetails = {
			...this.company.billingDetails,
			accountRepName: invoiceDetail.firstName + ' ' + invoiceDetail.lastName,
			companyName: invoiceDetail.companyName
		}
		this.company.billingEmail = invoiceDetail.billingCcEmail;
		this.companyService.updateCompany(this.company.id, this.company)
			.subscribe(res => this.submitPayment(), err => {
				switch (err.errorCode) {
					case 'INVALID_TAX_INFORMATION':
						this.overlayService.showError(this.translatePipe.transform('companyDetail_IncorrectTaxId'), 'Error');
						break;
					default:
						this.overlayService.showError(this.translatePipe.transform('overlayErrorOccurred'), 'Error');
						break;
				}
			});
	}

	submitPayment() {
		this.stripe.createPaymentMethod({
			type: 'card',
			card: this.creditCard,
			billing_details: {
				email: this.subscription.email,
			}
		}).then((res: any) => {
			const paymentMethod = res.paymentMethod;

			const selectedPlan: PlanInfo = this.subscriptionService.plans.find((plan) => plan.code === this.subscriptionService.registerData.plan);

			let product: any, addons = [];
			product = {
				priceId: selectedPlan.basePrice[this.subscriptionService.registerData.billingType].priceId,
				quantity: selectedPlan.minUsers + this.subscriptionService.registerData.billingInfo.extraUserCount
			};

			if (this.subscriptionService.registerData.billingInfo.extraActiveStorageCount) {
				const addOn = this.subscriptionService.addons.find(addon => addon.code === 'ACTIVE_STORAGE_PLAN');
				addons.push({
					priceId: addOn.basePrice[this.subscriptionService.registerData.billingType].priceId,
					quantity: this.subscriptionService.registerData.billingInfo.extraActiveStorageCount
				});
			}

			if (this.subscriptionService.registerData.billingInfo.extraArchivalStorageCount) {
				const addOn = this.subscriptionService.addons.find(addon => addon.code === 'ARCHIVE_STORAGE_PLAN');
				addons.push({
					priceId: addOn.basePrice[this.subscriptionService.registerData.billingType].priceId,
					quantity: this.subscriptionService.registerData.billingInfo.extraArchivalStorageCount
				});
			}

			this.subscriptionService.subscriptionWithAddonCreate(paymentMethod.id, [product, ...addons])
				.subscribe(res => {
					this.isLoading = false;
					this.subscriptionService.paymentSuccessful = true;
					this.router.navigate(['/subscription/register/produce']);
				}, err => {
					this.isLoading = false;
					this.overlayService.showError(JSON.stringify(err));
				});
		});
	}

	private handleLoadedCompanyData(company: any) {
		let { billingAddress, billingDetails, taxId } = company;

		if (taxId) {
			this.paymentForm.patchValue({
				taxIdNumber: taxId
			});
		}

		if (billingAddress) {
			this.paymentForm.patchValue({
				address: billingAddress.address,
				city: billingAddress.city,
				zipCode: billingAddress.postalCode,
				country: billingAddress.country,
				state: billingAddress.state
			});
		}
		if (billingDetails) {
			this.paymentForm.patchValue({
				companyName: billingDetails.companyName,
				zipCode: billingAddress.postalCode,
				country: billingAddress.country
			});
			if (billingDetails.accountRepName) {
				const names = billingDetails.accountRepName.split(' ');
				if (names.length > 0) {
					this.paymentForm.patchValue({
						firstName: names[0]
					});
				}
				if (names.length > 1) {
					names.shift();
					this.paymentForm.patchValue({
						lastName: names.join(' ')
					});
				}
			}
		}
		this.paymentForm.patchValue({
			billingCcEmail: company.billingEmail
		});
	}

	submitData() {
		this.isLoading = true;
		this.updateCompany();
	}

	//currently we skip tax id validation, because of MTM-3452
	/*
	validateTaxId() {
		const taxIdNumber: string = this.paymentForm.value.taxIdNumber;
		const country: string = this.paymentForm.value.country;
		const companyName: string = this.company.name;

		if(! taxIdNumber){
			return;
		}

		this.validatorService.checkTaxIdWithName(companyName, country, taxIdNumber)
			.subscribe(value => {
				this.isTaxIdValid = true;
			}, error => {
				if (error) {
					this.isTaxIdValid = false;
					this.overlayService.showError(this.translatePipe.transform('companyDetail_IncorrectTaxId'), 'Error');
				}
			});
	}
	*/
}
