import { ConversationService } from 'app/shared/services/conversation.service';
import { HelperService } from 'app/shared/services/helper.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AudioService } from './../../../shared/services/audio.service';
import { AuthService } from "../../../shared/services/auth.service";
import { ProjectService } from 'app/shared/services/project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/* ----------- * - * ----------- */

const VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES = {
	TIME_OUT: 'TIME_OUT',
	REJECTED: 'REJECTED',
	ACCEPTED: 'ACCEPTED'
}

/* ----------- * - * ----------- */

@Component({
	templateUrl: './video-chat-incoming.component.html',
	styleUrls: ['./video-chat-incoming.component.scss']
})
export class VideoChatIncomingComponent implements OnInit, OnDestroy {
	private modalCloseType: string;
	private caller: any = null;
	private conversation: any = null;
	project: any;
	ngUnsubscribe = new Subject();

	constructor(public activeModal: NgbActiveModal,
		private projectService: ProjectService,
		private conversationService: ConversationService,
		public audioService: AudioService,
		private authService: AuthService) {
	}

	ngOnInit() {
		// close video call window after 30 seconds
		setTimeout(() => this.timeoutVideoChatIncoming(), 30000);
	}

	ngOnDestroy() {
		this.audioService.pauseRing();
		if (!this.caller || !this.conversation)
			return;
		switch (this.modalCloseType) {
			case VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.TIME_OUT:
				this.missedVideoChatConversation();
				break;
			case VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.REJECTED:
				this.conversationService.rejectCall(this.conversation.projectId, this.conversation.id, this.caller.username);
				break;
			case VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.ACCEPTED:
				let videoChatUrl = HelperService.getVideoCallUrl(this.conversation.projectId, this.conversation.id, this.conversation.videoCallId);
				HelperService.openNewPopup(videoChatUrl);
				break;
			default:
				this.missedVideoChatConversation();
				break;
		}
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	public playSoundRinging() {
		this.audioService.playRing();
	}

	private timeoutVideoChatIncoming() {
		if (this.modalCloseType) {
			return;
		}
		this.setStatusModalCloseType(VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.TIME_OUT);
		this.closeActiveModal();
	}

	private missedVideoChatConversation() {
		this.conversationService.missedCall(this.conversation.projectId, this.conversation.id, this.caller.username);
	}

	private setStatusModalCloseType(type) {
		this.modalCloseType = type;
	}

	private closeActiveModal(isAccepted: boolean = false) {
		this.activeModal.close(isAccepted);
	}

	onAcceptCall() {
		this.authService.setJitsiToken(this.conversation.jitsiToken);
		this.setStatusModalCloseType(VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.ACCEPTED);
		this.closeActiveModal(true);
	}

	onRejectCall() {
		this.setStatusModalCloseType(VIDEO_CHAT_INCOMING_MODAL_CLOSE_TYPES.REJECTED);
		this.closeActiveModal();
	}

	setParams(data = null) {
		if (!data || typeof data != 'object') {
			this.closeActiveModal();
		} else {
			this.projectService.getProjectById(data.conversation.projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((project: any) => {
				this.project = project;
				if (data.caller) {
					this.caller = data.caller;
				}
				if (data.conversation) {
					this.conversation = data.conversation;
				}
				this.audioService.setIsLoading(false);
			});
		}
	}
}
