import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { HelperService } from 'app/shared/services/helper.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectService, onLoadProject } from 'app/shared/services/project.service';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from 'app/shared/services/datepicker-adapter';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Location } from '@angular/common';
import { CryptoService } from 'app/shared/services/crypto.service';
import { TimelineService } from 'app/shared/services/timeline.service';

@Component({
	selector: 'mtm-timeline-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
	index: number = 0;
	subscription: Subscription;
	workspaceId: string;
	driveCampaignId: string;
	totalSections: number = 7;
	// projectId: string = "4a893946-f0b7-4a7f-84c5-6c2a86e38647";
	project: any = null
	links: any[] = [];
	private userAccessed: boolean = false;
	user: any;
	ngUnsubscribe = new Subject();
	status: string = 'newProject';
	isTimeLineId: boolean = false;
	isNotificationView: boolean;
	productionView: boolean;


	constructor(public service: ProjectService, private route: ActivatedRoute, private router: Router, public auth: AuthService,
		private breadcrumb: BreadcrumbService, private notificationService: NotificationService, private location: Location,
		public permissionService: PermissionService, private transferService: DataTransferBetweenComponentsService,
		private cryptoService: CryptoService,
		private projectV2ServiceService: ProjectV2ServiceService,
		private timelineService: TimelineService) {
		this.workspaceId = this.route.snapshot.params['workspaceId'];

	}

	ngOnInit(): void {
		this.timelineService.getWorkspaceId$.subscribe((data: any) => {
			this.workspaceId = data;
			if (this.timelineService.workspaceId) {
				HelperService.changeBreadcrumbUrl(this.transferService.breadcrumbComponent, 1, `/workspaces/campaign-drive?wid=${this.cryptoService.encryptString(this.timelineService.workspaceId)}`);
			} else {
				HelperService.changeBreadcrumbUrl(this.transferService.breadcrumbComponent, 1, `/workspaces`);
			}
			this.links = [
				{
					url: `${this.workspaceId}/general`,
					title: "general",
					text: "newPojectTile",
					subText: '',
					authority: Authority.E,
					globalScope: true
				},
				// {
				// 	url: 'production-timelines',
				// 	title: "PRODUCTION TIMELINES",
				// 	text: "newPojectTile",
				// 	subText: "",
				// 	authority: Authority.E,
				// 	globalScope: false
				// },
				{
					url: `${this.workspaceId}/recap`,
					title: "recap",
					text: "newPojectTile",
					subText: "",
					authority: Authority.E,
					globalScope: false
				}
				// TODO:- revert url when work on user permission
				// {
				// 	url: 'users-permissions',
				// 	title: "USER & PERMISSIONS",
				// 	text: "newPojectTile",
				// 	subText: "",
				// 	authority: Authority.E,
				// 	globalScope: false
				// },
			];
			this.router.events.subscribe((event: any) => {
				if (event.url) {
					if (event.url && event.url === '/workspaces/campaigndrive/timeline-setting') {
						this.productionView = true;
					} else {
						this.productionView = false;
					}
				} else {
					return;
				}
			});
			this.route.queryParams.subscribe(query => {
				if (query.from == 'notification') {
					this.isNotificationView = true;
				} else {
					this.isNotificationView = false;
				}
			})
			this.subscription = this.projectV2ServiceService.currentTimelineData.subscribe(() => {
				if (this.projectV2ServiceService.timelineId) {
					this.isTimeLineId = true;
				} else {
					this.isTimeLineId = false;
				}
			});
		});

		// get component detail and hide header
		this.timelineService.imageEventEmitter.subscribe((data: any) => {
			this.productionView = data.hideGeneralComponent;
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private isActive(url: string): boolean {
		if (this.service.project.id)
			return this.router.isActive(`/workspaces/campaigndrive/` + url, false);
		else
			return this.router.isActive(`/workspaces/campaigndrive/` + url, false);
	}

	setUserAccess() {
		if (this.project && this.user) {
			if (this.permissionService.hasAuthority(Authority.Z, null)) {
				return;
			}

			if (this.permissionService.hasAuthority(Authority.P, null)) {
				return;
			}

			this.links = _.filter(this.links, link => this.permissionService.hasAuthority(link.authority,
				link.globalScope ? null : this.project.id));
			this.userAccessed = true;
			this.router.navigate(['/projects', 'edit', this.project.id, this.links[0].url]);
		}
	}

	isComplete(i: number) {
		let index: number;
		this.links.map((link: any) => {
			if (this.isActive(link.url)) {
				index = this.links.indexOf(link);
				this.index = index;
			}
		});
		return i < index;
	}

	resetForms() {
		window.location.replace('/workspaces');
	}
}
