import { Component, OnInit, Input } from '@angular/core';
import { FilterPipe } from '../../../shared/pipes/search-file.pipe';
@Component({
  selector: 'mtm-team-files-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [FilterPipe]
})
export class TeamFilesGridComponent implements OnInit {

  @Input() files: any[];
  @Input() projectId: string;
  @Input() queryString: string;

  constructor() { }

  ngOnInit() {
  }

}
