import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { TranslatePipe } from "../pipes/translate.pipe";

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	constructor(private apiService: ApiService,
		private translatePipe: TranslatePipe) {

	}


	/**
	 * Retrieve all subsections
	 */
	public getSubsectionsAll(): Observable<any> {
		return this.apiService.httpGet('/api/commons/subsections/all');
	}


	/**
	 * Retrieve all languages
	 */
	public getLanguages(): Observable<any> {
		return this.apiService.httpGet('/api/commons/languages');
	}

	public getOneShotLanguages(): Observable<any> {
		return this.apiService.httpGet('/api/commons/one-shot/languages');
	}


	/**
	 * Return type of projects
	 */
	public getProjectTypes() {
		return [
			{ value: 'WEBSERIES', label: 'Web Series' },
			{ value: 'LIVE_CAPTATION', label: 'Live Captation' },
			{ value: 'GAMES', label: 'Games' },
			{ value: 'INTERVIEWS', label: 'Interviews' },
			{ value: 'BEHIND_THE_SCENES', label: 'Behind The Scenes' },
			{ value: 'PRODUCT_SHOTS', label: 'Product Shots' },
			{ value: 'MOOD_FILMS', label: 'Mood Films' },
			{ value: 'DOCUMENTARY', label: 'Documentary' },
			{ value: 'SHORT_FILM', label: 'Short Film' },
			{ value: 'TUTORIAL', label: 'Tutorial' },
			{ value: 'STORY_TELLING_FILM', label: 'Story Telling Film' },
			{ value: 'USER_GENERATED_CONTENT', label: 'User Generated Content' },
			{ value: 'INFLUENCERS', label: 'Influencers' },
			{ value: 'GIF', label: 'GIF' },
			{ value: 'PODCAST', label: 'Podcast' },
			{ value: 'SOUND_IDENTITY', label: 'Sound Identity' },
			{ value: 'OTHER', label: 'Other' }
		]
			.map(entry => {
				entry.label = this.translatePipe.transform(entry.label);
				return entry;
			})
			.sort((a, b) => a.label.localeCompare(b.label));
	}

	public getCompanySector() {
		return [
			{ value: 'PRODUCER', label: 'Producer' },
			{ value: 'ADVERTISER', label: 'Advertiser' }
		];
	}

	/**
	 * Retrieve Client Sector list
	 */
	public getClientSector(isOneShot?: boolean): Observable<any> {
		return this.apiService.httpGet(`/api/commons/${isOneShot ? 'one-shot/' : ''}sectors`);
	}

	public getProductionCompanyCountries(): Observable<any> {
		return this.apiService.httpGet('/api/commons/production-company-countries');
	}

	/**
	 * @description
	 * GET post-production export format aspect ratio for video
	 *
	 * @returns
	 */
	getPostProductionExportAspectRatioVideo() {
		return this.apiService.httpGet('/api/commons/post-production/exports/AspectRatio_Video');
	}

	/**
	 * @description
	 * GET post-production export format aspect ratio for video
	 *
	 * @returns
	 */
	getPostProductionExportAspectRatioImage() {
		return this.apiService.httpGet('/api/commons/post-production/exports/AspectRatio_Image');
	}

	/**
	 * @description
	 * GET post-production export format placements for video
	 *
	 * @returns
	 */
	getPostProductionExportPlacementVideo() {
		return this.apiService.httpGet('/api/commons/post-production/exports/Placement_Video');
	}

	/**
	 * @description
	 * GET post-production export format placements for image
	 *
	 * @returns
	 */
	getPostProductionExportPlacementImage() {
		return this.apiService.httpGet('/api/commons/post-production/exports/Placement_Image');
	}
}
