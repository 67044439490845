import { AnnotationState } from "./components/annotationState";
import { Controls } from "./components/controls";
import { EventDispatcher } from "./lib/event-dispatcher";
import { fabric } from "fabric";

export class ImageAnnotationPlugin {
	annotationComments: Object;
	primordial: any; // The parent angular component Instance
	meta: Object;
	options: any;
	canvasFabric: fabric.Canvas;
	colourPicker: any;
	controls: Controls;
	annotationState: AnnotationState;
	active: boolean;
	eventDispatcher: any;
	viewportTransform: any;
	canvasZoom: number;
	item: any;
	_readyCallbacks: any[];
	defaultZoom: number;
	defaulViewport: any;
	isImageMovable: boolean = false;
	active_colors = [
		"#FFF735", // Yellow
		"#FF8126", // Orange
		"#FF4B4B", // Red
		"#27CBFF", // Light Blue
		"#47FF5A", // Green
	];

	constructor(primordial: any, config: any) {
		// window['VAC_DEBUG'] = true;
		this.primordial = primordial;
		this.options = config;
		this.meta = {
			user_id: config.userInfo.username,
			user_name: config.userInfo.fullName
		};
		this.item = config.item;

		this.eventDispatcher = new EventDispatcher();
		this.eventDispatcher.registerListenersFor(this, 'AnnotationComments');
		this._readyCallbacks = [];

		this.annotationComments = () => {
			return this;
		};

		this.postLoadDataConstructor();
	}

	postLoadDataConstructor() {
		let _this2 = this;

		// Fabric canvas initialisation
		const canvasFabric = new fabric.Canvas('canvas-fabric-image', {
			isDrawingMode: false,
			selection: false,
			stopContextMenu: true, // prevent context menu from showing
		});

		let parentEl = document.querySelector(".vac-canvas-d");
		canvasFabric.setWidth($(parentEl).width());
		canvasFabric.setHeight($(parentEl).height());
		this.canvasFabric = canvasFabric;

		this.viewportTransform = this.canvasFabric.viewportTransform;
		this.canvasZoom = this.canvasFabric.getZoom();


		this.canvasFabric.setBackgroundImage(this.options.item.signedURL, img => {
			_this2.canvasFabric.renderAll();
			_this2.centerImage(true);
			_this2.canvasFabric.clipPath = _this2.canvasFabric.backgroundImage;
		}, {
			excludeFromExport: true,
			selectable: false,
			hoverCursor: 'cursor'
		}
		);

		this.enableImageMove();
		this.enableImageZoom();

		// Piklor colour chooser initialisation
		// const colours = [
		// 	"#FF0000", // Red
		// 	"#66FF00", // Bright Green
		// 	"#654321", // Brown
		// 	"#B93977", // Darkish Pink
		// 	"#00FFFF", // Cyan
		// 	"#FFFF00", // Yellow
		// ];

		const colourPicker = new Piklor('.annotation-color-picker', this.active_colors,
		{
			template: "<div data-col=\"{color}\" id=\"{color}\" style=\"background-color: {color}\"></div>",
			autoclose: false
		});

		colourPicker.set("#FFF735");

		// const wrapperEl = colourPicker.getElm(".selected-color")

		colourPicker.colorChosen(function (col) {
			canvasFabric.drawColor = col;
			let colorElements = [...colourPicker.elm.getElementsByTagName("*")]
			if (colorElements.length > 0) {
			  colorElements.forEach(colorElement => {
				if (colorElement.id == col) {
				  colorElement.classList.add("active")
				}
				else{
				  colorElement.classList.remove("active")
				}
			  })
			}
			// wrapperEl.style.backgroundColor = col;
			if (canvasFabric.isDrawingMode == true) {
				canvasFabric.freeDrawingBrush.color = col;
			}
		});

		this.colourPicker = colourPicker;

		this.annotationState = new AnnotationState(this);
		this.controls = new Controls(this);

		this.annotationState.annotations = this.options.annotationObjects;

		this.toggleAnnotationMode();

		this.pluginReady();
	}

	centerImage(reset = false) {
		let _this2 = this;
		let canvasFabric = this.canvasFabric;
		let img = canvasFabric.backgroundImage;
		let widthChange = false;
		let heightChange = false;
		let zoom = canvasFabric.getZoom();
		if (_this2.canvasZoom && !reset) {
			zoom = _this2.canvasZoom;
		}
		else {
			if (!img || !canvasFabric) return;
			if (img.width > canvasFabric.width) widthChange = true;
			if (img.height > canvasFabric.height) heightChange = true;

			if (widthChange && heightChange) {
				if ((img.width - canvasFabric.width) > (img.height - canvasFabric.height)) {
					zoom = canvasFabric.height / img.height;
				}
				else {
					zoom = canvasFabric.width / img.width;
				}
			}
			else if (widthChange && !heightChange) {
				zoom = canvasFabric.width / img.width;
			}
			else if (!widthChange && heightChange) {
				zoom = canvasFabric.height / img.height;
			}
			this.centerViewport();
			this.defaultZoom = zoom;
			this.defaulViewport = this.canvasFabric.viewportTransform;
		}

		this.setCanvasZoom(zoom);
		this.canvasZoom = zoom;
	}

	// Sets default zoom and viewport
	resetImageOrientation() {
		this.canvasFabric.setViewportTransform(this.defaulViewport);
		this.setCanvasZoom(this.defaultZoom);
	}

	centerViewport() {
		let backImg = this.canvasFabric.backgroundImage;

		let rect = new fabric.Rect({
			width: backImg.width,
			height: backImg.height,
			excludeFromExport: true,
			selectable: false,
			hoverCursor: 'cursor',
			fill: 'rgba(0,0,0,0)',
			stroke: "#FFFFFF",
			originX: 'center',
			originY: 'center'
		});
		this.canvasFabric.add(rect);
		this.canvasFabric.renderAll();

		rect.center();
		let rectCoords = rect.getCoords();

		let vpt = this.canvasFabric.viewportTransform;
		// let imageCoords = this.canvasFabric.backgroundImage.calcCoords();
		// let width = Math.abs(imageCoords.ml.x - imageCoords.mr.x);
		// let height = Math.abs(imageCoords.mt.y - imageCoords.mb.y);

		// let centerX = imageCoords.mb.x;
		// let centerY = imageCoords.ml.y;

		vpt[4] = rectCoords[0].x;
		vpt[5] = rectCoords[1].y;
		this.canvasFabric.setViewportTransform(vpt);
		this.canvasFabric.remove(rect);

	}

	//
	setCanvasZoom(zoom, pointer = null) {
		// let backgroundImage = this.canvasFabric.backgroundImage;
		// let midX = (imageCoords.ml.x + imageCoords.mr.y) / 2;
		// let midY = (imageCoords.mt.x + imageCoords.mb.y) / 2;

		// let imageCenterPoint = new fabric.Point(midX, midY)

		// let imageCenterPoint = new fabric.Point(backgroundImage.left + backgroundImage.width/2, backgroundImage.top + backgroundImage.height/2);

		let imageCenterPoint;

		if (pointer) {
			imageCenterPoint = { x: pointer.offsetX, y: pointer.offsetY }
		}
		else {
			imageCenterPoint = new fabric.Point(this.canvasFabric.width / 2, this.canvasFabric.height / 2);
		}

		this.canvasFabric.zoomToPoint(imageCenterPoint, zoom);
		this.canvasZoom = zoom;
		this.fire('imageZoomChanged', { zoom: zoom });
	}

	// Zoom and unzoom image and objects
	enableImageZoom() {
		let _this2 = this;
		let minZoom = 0.1;
		let maxZoom = 3;
		$(this.canvasFabric.upperCanvasEl).on('wheel', function (opt) {
			let evt = opt.originalEvent as any;
			if (evt.altKey === true) {
				var delta = evt.deltaY;
				var zoom = _this2.canvasFabric.getZoom();
				zoom *= 0.999 ** delta;
				if (zoom > maxZoom) zoom = maxZoom;
				if (zoom < minZoom) zoom = minZoom;
				_this2.setCanvasZoom(zoom, evt);
				evt.preventDefault();
				evt.stopPropagation();
			}
		});
	}

	toggleImageMove() {
		this.isImageMovable = !this.isImageMovable;
	}

	// Move image and objects accordingly
	enableImageMove() {
		let _this2 = this;
		this.canvasFabric.on('mouse:down', function (opt) {
			var evt = opt.e;
			if (_this2.isImageMovable) {
				this.isDragging = true;
				this.lastPosX = evt.clientX;
				this.lastPosY = evt.clientY;
			}
		});
		this.canvasFabric.on('mouse:move', function (opt) {
			if (this.isDragging) {
				let e = opt.e;
				let vpt = this.viewportTransform;

				let imageWidth = this.backgroundImage.width * this.getZoom();
				let imageHeight = this.backgroundImage.height * this.getZoom();

				let extraWidth, extraHeight;

				if (this.getZoom() > _this2.defaultZoom) {
					// extraWidth = Math.abs(this.width - (this.width * this.getZoom()));
					// extraHeight = Math.abs(this.height - (this.height * this.getZoom()));
					extraWidth = Math.abs(this.width - imageWidth);
					extraHeight = Math.abs(this.height - imageHeight);
				}
				else {
					extraWidth = 0;
					extraHeight = 0;
				}

				vpt[4] += e.clientX - this.lastPosX;
				vpt[5] += e.clientY - this.lastPosY;
				vpt[4] = Math.max(0 - extraWidth, vpt[4]);
				vpt[4] = Math.min(vpt[4], this.width - imageWidth + extraWidth);

				vpt[5] = Math.max(0 - extraHeight, vpt[5]);
				vpt[5] = Math.min(vpt[5], this.height - imageHeight + extraHeight);
				this.requestRenderAll();
				this.lastPosX = e.clientX;
				this.lastPosY = e.clientY;
			}
		});
		this.canvasFabric.on('mouse:up', function (opt) {
			// on mouse up we want to recalculate new interaction
			// for all objects, so we call setViewportTransform
			this.setViewportTransform(this.viewportTransform);
			_this2.viewportTransform = this.viewportTransform;
			this.isDragging = false;
		});
	}

	getItem() {
		return this.item;
	}

	toggleAnnotationMode() {
		this.controls.cancelAddNew();
		this.active = !this.active;
		this.primordial.isAnnotationDisplayEnabled = !this.primordial.isAnnotationDisplayEnabled;

		this.annotationState.enabled = this.active;
	}

	togglePiklorColors(active) {
		const disabled_colors = Array(5).fill("#595B6B");

		this.colourPicker.colors = active ? this.active_colors : disabled_colors;
		this.colourPicker.render();
	}

	// A wrapper func to make it easier to use EventDispatcher from the client
	// Ex: plugin.fire(type, data);
	fire(type, data = {}) {
		this.eventDispatcher.fire(type, data);
	}

	// A wrapper func to make it easier to listen to API events from the client
	registerListener(type, callback) {
		this.eventDispatcher.registerListener(type, callback, true);
	}

	// Mark plugin as ready and fire any pending callbacks
	pluginReady() {
		this.eventDispatcher.pluginReady = true;
		while (this._readyCallbacks.length) {
			this._readyCallbacks.pop()();
		}
		this.fire('pluginReady');
	}

	// Public function to register a callback for when plugin is ready
	onReady(callback) {
		if (this.eventDispatcher.pluginReady) {
			return callback();
		}
		this._readyCallbacks.push(callback);
	}

	disposeAll() {
		this.controls = this.controls.teardown();
		this.annotationState = this.annotationState.teardown();
		this.teardown();
		this.eventDispatcher = this.eventDispatcher.teardown();
	}

	teardown() {

		this.canvasFabric.clear();
		this.canvasFabric.dispose();
		$(this.canvasFabric.wrapperEl).remove();
	}

}
