<div class="item">
    <div class="row justify-content-center">
        <div class="col-12 d-flex justify-content-center" *ngFor="let _ of repeatArray">
            <ngx-skeleton-loader [appearance]="apperance" [count]="gridCount" animation="progress-dark" [theme]="{
                'border-radius.px': borderRadius,'width.px': widthSizeInPixels,
                'height.px': HeightSizeInPixels , 'margin':'10px',
                'background-color': color,
                'animations': animation
            }"></ngx-skeleton-loader>
        </div>
    </div>
</div>