import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Component } from "@angular/core";

@Component({
    selector: 'project-status-cell',
    templateUrl: './project-status-cell.component.html'
})
export class ProjectStatusCellComponent implements ICellRendererAngularComp {
    status: string;

    agInit(params: ICellRendererParams) {
        this.status = params.value?.toString() || '';
    }

    refresh(params: ICellRendererParams): boolean {
        this.status = params.value?.toString() || '';
        return false;
    }
}
