import { Injectable, EventEmitter } from '@angular/core';
import { StompService } from 'ng2-stomp-service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import { webSocket } from 'rxjs/webSocket';
import { MtmStompService } from './mtm-stomp.service';

/* ----------- * * ----------- */

export const listenerWSConnectionChange: EventEmitter<string> = new EventEmitter<string>();
export const listenerWSConnected: EventEmitter<string> = new EventEmitter<string>();
export const listenerWSUnconnected: EventEmitter<string> = new EventEmitter<string>();

/* ----------- * * ----------- */

export const WS_STATUS_CLOSED = 'CLOSED';
export const WS_STATUS_CONNECTING = 'CONNECTING';
export const WS_STATUS_CONNECTED = 'CONNECTED';

/* ----------- * * ----------- */

export const wsListenerAUTH_USER_NOTIFY: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerAUTH_USER_POPUP_NOTIFY: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerACTIVE_PROJECT_USERS: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerACTIVE_PROJECT_CONVERSATIONS: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerPROPOSAL_CONVERSATIONS: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerACTIVE_PROJECT_CONVERSATION_USERS: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerProposalMessaging: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerPROJECT_CONVERSATION: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerCOMMENT_ADD: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerPROPOSAL_COMMENT_ADD: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerPROPOSAL_QA_COMMENT_ADD: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerVOTE_ADD: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerANNOTATION_ADDED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerUNSEEN_NOTIFICATION_UPDATED: EventEmitter<any> = new EventEmitter();
export const wsListenerWORKSPACE_DELETE: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerCONVERSION_UPDATE: EventEmitter<any> = new EventEmitter<any>();

export const wsListenerANNOTATION_CREATED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerANNOTATION_EDITED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerANNOTATION_DELETED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerCOMMENT_CREATED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerCOMMENT_EDITED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerCOMMENT_DELETED: EventEmitter<any> = new EventEmitter<any>();

export const wsListenerCONVERSATION: EventEmitter<any> = new EventEmitter<any>();

export const wsListenerCHAT_ADDED: EventEmitter<any> = new EventEmitter<any>();
export const wsListenerUSER_UPDATED: EventEmitter<any> = new EventEmitter<any>();

/* ----------- * * ----------- */

export const WS_SEND_DESTINATION_TYPES = {
	ONLINE_USER: 'ONLINE_USER',
	PROJECT_CONVERSATION_JOIN: 'PROJECT_CONVERSATION_JOIN',
	ACTIVE_PROJECT_CONVERSATION: 'ACTIVE_PROJECT_CONVERSATION',
	PROPOSAL_MESSAGE: 'PROPOSAL_MESSAGE',
	ACTIVE_PROJECT: 'ACTIVE_PROJECT'
}

export const WS_SUBSCRIBE_TOPIC_TYPES = {
	USER_NOTIFY: 'USER_NOTIFY',
	USER_POPUP_NOTIFY: 'USER_POPUP_NOTIFY',
	ACTIVE_PROJECT_USERS: 'PROJECT_ONLINE_USERS',
	ACTIVE_PROJECT_CONVERSATIONS: 'ACTIVE_PROJECT_CONVERSATIONS',
	PROPOSAL_CONVERSATIONS: 'PROPOSAL_CONVERSATIONS',
	ACTIVE_PROJECT_CONVERSATION_USERS: 'ACTIVE_PROJECT_CONVERSATION_USERS',
	PROJECT_CONVERSATION: 'PROJECT_CONVERSATION',
	NEW_COMMENT_ADDED: 'NEW_COMMENT_ADDED',
	PROPOSAL_COMMENT_ADDED: 'PROPOSAL_COMMENT_ADDED',
	PROPOSAL_QA_COMMENT_ADDED: 'PROPOSAL_QA_COMMENT_ADDED',
	VOTE_ADDED: 'VOTE_ADDED',
	NEW_ANNOTATION_ADDED: 'NEW_ANNOTATION_ADDED',
	NEW_CHAT_MESSAGE: 'NEW_CHAT_MESSAGE',
	USER_UPDATED: 'USER_UPDATED',
	UNSEEN_NOTIFICATION_UPDATED: 'UNSEEN_NOTIFICATION_UPDATED',
	COMMENT_DELETED: 'COMMENT_DELETED',
	ANNOTATION_CREATED: 'ANNOTATION_CREATED',
	ANNOTATION_EDITED: 'ANNOTATION_EDITED',
	ANNOTATION_DELETED: 'ANNOTATION_DELETED',
	COMMENT_CREATED: 'COMMENT_CREATED',
	COMMENT_EDITED: 'COMMENT_EDITED',
	CONVERSATION: 'CONVERSATION',
	WORKSPACE_DELETE: 'WORKSPACE_DELETE',
	CONVERSION_UPDATE: 'CONVERSION_UPDATE'
}

/* ----------- * * ----------- */

const WS_SEND_DESTINATION_TOPICS = {
	[WS_SEND_DESTINATION_TYPES.ONLINE_USER]: `/mtm/active`,
	[WS_SEND_DESTINATION_TYPES.PROJECT_CONVERSATION_JOIN]: `/mtm/projects.{0}.conversations.{1}.join`,
	[WS_SEND_DESTINATION_TYPES.ACTIVE_PROJECT_CONVERSATION]: `/mtm/active-project-conversation.{0}.{1}`,
	[WS_SEND_DESTINATION_TYPES.PROPOSAL_MESSAGE]: `/topic/proposals.{0}`,
	[WS_SEND_DESTINATION_TYPES.ACTIVE_PROJECT]: `/mtm/active-project.{0}`
}

export const WS_SUBSCRIBE_TOPICS = {
	[WS_SUBSCRIBE_TOPIC_TYPES.USER_POPUP_NOTIFY]: {
		topicRef: '/topic/notify.popup.user.{0}',
		wsListener: wsListenerAUTH_USER_POPUP_NOTIFY,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.USER_NOTIFY]: {
		topicRef: '/topic/notify.user.{0}',
		wsListener: wsListenerAUTH_USER_NOTIFY,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.UNSEEN_NOTIFICATION_UPDATED]: {
		topicRef: '/topic/notify.user.{0}.count',
		wsListener: wsListenerUNSEEN_NOTIFICATION_UPDATED,
		preFunc: (wsMessage): object => {
			return wsMessage
		},
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ACTIVE_PROJECT_USERS]: {
		topicRef: `/topic/active.project.users.{0}`,
		wsListener: wsListenerACTIVE_PROJECT_USERS,
		preFunc: (wsMessage): object => defaultPreFunc(wsMessage),
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ACTIVE_PROJECT_CONVERSATIONS]: {
		topicRef: `/topic/active.project.conversations.{0}`,
		wsListener: wsListenerACTIVE_PROJECT_CONVERSATIONS,
		preFunc: (wsMessage): object => activeProjectConversation(wsMessage),
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_CONVERSATIONS]: {
		topicRef: `/topic/proposals.{0}`,
		wsListener: wsListenerPROPOSAL_CONVERSATIONS,
		preFunc: (wsMessage): object => defaultPreFunc(wsMessage),
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ACTIVE_PROJECT_CONVERSATION_USERS]: {
		topicRef: `/topic/active.project.conversation.users.{0}.{1}`,
		wsListener: wsListenerACTIVE_PROJECT_CONVERSATION_USERS,
		preFunc: (wsMessage): object => defaultPreFunc(wsMessage),
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.PROJECT_CONVERSATION]: {
		topicRef: `/topic/projects.{0}.conversations.{1}`,
		wsListener: wsListenerPROJECT_CONVERSATION,
		preFunc: (wsMessage): object => defaultPreFunc(wsMessage),
		isDiff: false,
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.NEW_COMMENT_ADDED]: {
		topicRef: '/topic/comments.{0}.{1}.{2}',
		wsListener: wsListenerCOMMENT_ADD,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_COMMENT_ADDED]: {
		topicRef: '/topic/proposal-discussion.{0}.{1}',
		wsListener: wsListenerPROPOSAL_COMMENT_ADD,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_QA_COMMENT_ADDED]: {
		topicRef: '/topic/proposal-qa.{0}.{1}',
		wsListener: wsListenerPROPOSAL_QA_COMMENT_ADD,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.VOTE_ADDED]: {
		topicRef: '/topic/votes.{0}.{1}.{2}.{3}',
		wsListener: wsListenerVOTE_ADD,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.NEW_ANNOTATION_ADDED]: {
		topicRef: '/topic/media-annotations.{0}.{1}.{2}',
		wsListener: wsListenerANNOTATION_ADDED,
		preFunc: (wsMessage): object => { console.log("newannot created"); return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_CREATED]: {
		topicRef: '/topic/mediaAnnotations.annotationCreated.{0}.{1}.{2}',
		wsListener: wsListenerANNOTATION_CREATED,
		preFunc: (wsMessage): object => { console.log("annot created"); return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_EDITED]: {
		topicRef: '/topic/mediaAnnotations.annotationEdited.{0}.{1}.{2}',
		wsListener: wsListenerANNOTATION_EDITED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_DELETED]: {
		topicRef: '/topic/mediaAnnotations.annotationDeleted.{0}.{1}.{2}',
		wsListener: wsListenerANNOTATION_DELETED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_CREATED]: {
		topicRef: '/topic/mediaAnnotations.commentCreated.{0}.{1}.{2}',
		wsListener: wsListenerCOMMENT_CREATED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_EDITED]: {
		topicRef: '/topic/mediaAnnotations.commentEdited.{0}.{1}.{2}',
		wsListener: wsListenerCOMMENT_EDITED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_DELETED]: {
		topicRef: '/topic/mediaAnnotations.commentDeleted.{0}.{1}.{2}',
		wsListener: wsListenerCOMMENT_DELETED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.NEW_CHAT_MESSAGE]: {
		topicRef: '/topic/projects.{0}.conversations.user.{1}',
		wsListener: wsListenerCHAT_ADDED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.USER_UPDATED]: {
		topicRef: '/topic/user.{0}',
		wsListener: wsListenerUSER_UPDATED,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.CONVERSATION]: {
		topicRef: '/topic/conversation.user.{0}',
		wsListener: wsListenerCONVERSATION,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.WORKSPACE_DELETE]: {
		topicRef: '/topic/workspace.delete.user.{0}',
		wsListener: wsListenerWORKSPACE_DELETE,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	},
	[WS_SUBSCRIBE_TOPIC_TYPES.CONVERSION_UPDATE]: {
		topicRef: '/topic/conversionUpdate',
		wsListener: wsListenerCONVERSION_UPDATE,
		preFunc: (wsMessage): object => { return wsMessage },
		isDiff: false
	}
}

/* ----------- * * ----------- */

const defaultPreFunc = (wsMessage): object => {
	return wsMessage;
}

const activeProjectConversation = (wsMessage): object => {
	let _activeConversations = {};
	if (wsMessage && Array.isArray(wsMessage) && (wsMessage.length > 0)) {
		wsMessage.forEach((conversationId: any) => {
			_activeConversations[conversationId] = true;
		})
	}
	return _activeConversations;
}


/* ----------- * * ----------- */

export const prepareTopic = (topic: string, topicParams: (string | number)[]): string => {
	topicParams.forEach((param, index) => {
		if (param) {
			topic = topic.replace(`{${index}}`, param.toString());
		}
	});

	return topic;
}

const isDiffSubscribe = (SUBSCRIBE_TOPIC_TYPE) => {
	return !WS_SUBSCRIBE_TOPICS[SUBSCRIBE_TOPIC_TYPE].isDiff;
}

/* ----------- * * ----------- */
const SUBSCRIBE_ITEM_CALL_TYPES = {
	SUBSCRIBE: 'SUBSCRIBE',
	UNSUBSCRIBE: 'UNSUBSCRIBE'
}

export class SUBSCRIBE_ITEM {
	SUBSCRIBE_TOPIC_TYPE: string;
	topicParams: (string | number)[];
	subscribeWsItem: any;
	callback: Function;

	constructor(SUBSCRIBE_TOPIC_TYPE: string, topicParams: (string | number)[]) {
		this.SUBSCRIBE_TOPIC_TYPE = SUBSCRIBE_TOPIC_TYPE;
		this.topicParams = topicParams;
	}

	call(stompService: StompService, methodName) {
		if (!stompService || stompService.status != WS_STATUS_CONNECTED)
			return this;

		return this[methodName].call(this, stompService);
	}

	private SUBSCRIBE(stompService: StompService): SUBSCRIBE_ITEM {
		let wst = WS_SUBSCRIBE_TOPICS[this.SUBSCRIBE_TOPIC_TYPE];
		let topic = prepareTopic(wst.topicRef, this.topicParams);

		this.subscribeWsItem = stompService.subscribe(topic, data => {
			if (data && data.objectJson && typeof data.objectJson == 'string')
				data.object = JSON.parse(data.objectJson);

			let lastData = wst.preFunc(data);
			wst.wsListener.emit(lastData);
		});

		return this;
	}

	private UNSUBSCRIBE(stompService: StompService) {
		stompService.unsubscribe(this.subscribeWsItem);

		return this;
	}
}

/* ----------- * * ----------- */

@Injectable({
	providedIn: 'root'
})
export class MtmWebSocketService {
	private listTopicSubscribes: SUBSCRIBE_ITEM[] = [];

	private wsConf: any = null;
	private socketConnectionRequired: boolean = true;

	private intervalProjectActiveVideoConversation: any;
	private lastTimeOFReceivedProjectActiveVideoConversation;
	wsId: any;
	authUser: any;

	constructor(
		public newStompService: MtmStompService,
		public stompService: StompService,
		private authService: AuthService
	) {
		this.wsId = this.newStompService.subscribeToService();
		this.authUser = this.authService.getAuthUserName();
		this.setWSConfig();

		if (this.authUser) {
			this.newStompService.subscribeToListener('ACTIVE_PROJECT_CONVERSATIONS', this.wsId, [this.authUser.username],
				(message: any) => this.lastTimeOFReceivedProjectActiveVideoConversation = moment.now(), wsListenerACTIVE_PROJECT_CONVERSATIONS);
		}
		this.buildintervalProjectActiveVideoConversation();
	}

	private breakintervalProjectActiveVideoConversation() {
		if (this.intervalProjectActiveVideoConversation)
			clearInterval(this.intervalProjectActiveVideoConversation);
	}

	private buildintervalProjectActiveVideoConversation() {
		this.breakintervalProjectActiveVideoConversation();

		this.intervalProjectActiveVideoConversation = setInterval(() => {
			if (!this.lastTimeOFReceivedProjectActiveVideoConversation)
				return;

			if (moment(moment.now()).diff(this.lastTimeOFReceivedProjectActiveVideoConversation, 'second') < 5)
				return;

			wsListenerACTIVE_PROJECT_CONVERSATIONS.emit({});
		}, 5000);
	}


	private setWSConfig() {
		this.wsConf = {
			host: environment.websocket.chatBaseUrl,
			debug: false,
			headers: {
				'Authorization': 'Bearer ' + this.authService.getAuthToken()
			},
			heartbeatIn: 60000,
			heartbeatOut: 60000
		};

		this.stompService.configure(this.wsConf);
	}

	public initConnectWS() {
		this.stompService.onError = (e) => {
			this.socketConnectionRequired = true;
			listenerWSConnectionChange.emit(WS_STATUS_CLOSED);
			listenerWSUnconnected.emit();
			this.stompService.disconnect();
			setTimeout(() => this.forceConnect(), 2001);
		};

		if (this.stompService.status == WS_STATUS_CLOSED) {
			this.setWSConfig();
			this.forceConnect();
		}
	}

	public disconnectWS() {
		// console.log(WS_STATUS_CLOSED);
		if (this.stompService.status != WS_STATUS_CLOSED)
			this.stompService.disconnect();
	}

	private forceConnect() {
		listenerWSConnectionChange.emit(WS_STATUS_CONNECTING);
		this.setWSConfig();

		this.stompService.startConnect()
			.then(() => {
				this.waitConnected();
			}, () => {
				this.waitConnected();
			})
			.catch(value => {
				if (!this.socketConnectionRequired)
					setTimeout(() => this.forceConnect(), 3501);
			})
	}

	private waitConnected() {
		if (this.stompService.status == WS_STATUS_CLOSED) {
			this.socketConnectionRequired = true;
			return;
		}
		if (this.stompService.status == WS_STATUS_CONNECTED) {
			this.socketConnectionRequired = false;
			this.socketConnected();
			return;
		}
		setTimeout(() => this.waitConnected(), 2501);
	}

	private socketConnected() {
		// console.log(WS_STATUS_CONNECTED, this.stompService);
		listenerWSConnectionChange.emit(WS_STATUS_CONNECTED);
		listenerWSConnected.emit();
		this.resubscribeAllSubscribeList();
	}

	/* ----------- * * ----------- */

	public sendToWS(SEND_TOPIC_TYPE, topicParams: (string | number)[], body = undefined, headers: object = {}): boolean {
		if (this.stompService.status != WS_STATUS_CONNECTED)
			return false;

		let topic = prepareTopic(WS_SEND_DESTINATION_TOPICS[SEND_TOPIC_TYPE], topicParams);

		this.stompService.send(
			topic,
			body ? JSON.stringify(body) : {},
			headers
		);

		return true;
	}

	/* ----------- * * ----------- */

	public addWSTopicSubscribe(SUBSCRIBE_TOPIC_TYPE, topicParams: (string | number)[]) {
		let item = new SUBSCRIBE_ITEM(SUBSCRIBE_TOPIC_TYPE, topicParams).call(this.stompService, SUBSCRIBE_ITEM_CALL_TYPES.SUBSCRIBE);
		this.listTopicSubscribes.push(item);
	}

	private async resubscribeAllSubscribeList() {
		this.listTopicSubscribes.map(item => {
			item.call(this.stompService, SUBSCRIBE_ITEM_CALL_TYPES.SUBSCRIBE);
		});
	}

	/* ----------- * * ----------- */

	public unsubscribeWSTopicSubscribeWithSUBSCRIBE_TOPIC_TYPE(SUBSCRIBE_TOPIC_TYPE: string) {
		this.listTopicSubscribes.filter(item => { return item.SUBSCRIBE_TOPIC_TYPE == SUBSCRIBE_TOPIC_TYPE })
			.map(item => {
				item.call(this.stompService, SUBSCRIBE_ITEM_CALL_TYPES.UNSUBSCRIBE);
				let index = this.listTopicSubscribes.findIndex(k => { return k.SUBSCRIBE_TOPIC_TYPE == SUBSCRIBE_TOPIC_TYPE });
				if (index >= 0)
					this.listTopicSubscribes.splice(index, 1);
			})
	}

	public unsubscribeOtherWSTopic(SUBSCRIBE_TOPIC_TYPE, topicParams: (string | number)[]) {
		if (isDiffSubscribe(SUBSCRIBE_TOPIC_TYPE))
			this.unsubscribeWSTopicSubscribeWithSUBSCRIBE_TOPIC_TYPE(SUBSCRIBE_TOPIC_TYPE);
	}

}
