import { CommentPlugin } from "../lib/comment-plugin";
import { Annotation } from "./annotation";

export class Controls extends CommentPlugin {

    uiState: any;

    constructor(plugin: Object) {
        super(plugin);

        this.uiState = { adding: false, editing: false }
        this.initAPI(this, 'Controls');
    }

    cancelAddNew() {
        this.uiState.adding = false;
        this.uiState.editing = false;
        this.plugin.primordial.annotatingMode = false;
        this.plugin.primordial.editingMode = false;
        this.plugin.primordial.editingAnnotation = null;
        this.plugin.fire('exitingAddingAnnotation');
    }

    saveNewGeneralComment() {
        const resolution = {
            height: null,
            width: null
        }
        const range = {
            start: null,
            end: null,
            isGeneralComment: true
        }
        let shape = null;
        const annotation = Annotation.newFromData(
            this.plugin.primordial.annotationComment,
            null,
            this.plugin,
            null,
            range,
            this.plugin.primordial.privateCondition
        );
        this.plugin.annotationState.addNewAnnotation(annotation);
        this.cancelAddNew();
    }

    editingStart(annotation) {
        this.uiState.editing = true;

        let body = annotation.commentList.comments[0].body;
        this.plugin.primordial.annotationComment = body;
        $("#annotCommentArea").text(body);
        annotation.openAnnotationEdit();

        this.plugin.primordial.editingMode = true;
        this.plugin.primordial.editingAnnotation = annotation;

    }

    teardown() {
        super.teardown();

        return null;
    }
}
