import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MTMFileDownload } from "../../../services/signed-url/mtm-file-download";
import { FileTransferService, TransferStatus } from "app/shared/services/signed-url";

@Component({
	selector: 'mtm-file-download-upload-preview',
	templateUrl: './mtm-file-download-upload-preview.component.html',
	styleUrls: ['./mtm-file-download-upload-preview.component.scss']
})
export class MTMFileDownloadUploadPreview implements OnInit {
	@Input() file: MTMFileDownload;
	DownloadStatus = TransferStatus;
	resumableStatusCodes = [TransferStatus.PAUSED, TransferStatus.INACTIVE, TransferStatus.ERROR];

	constructor(private transferService: FileTransferService) { }

	ngOnInit() { }

	removeDownloadedFile() {
		this.transferService.cancelDownload(this.file);
	}

	pauseDownload() {
		this.transferService.pauseDownload(this.file);
	}

	resumeDownload() {
		this.transferService.resumeDownload(this.file);
	}

	getFileTypeIcon(fileType: string) {
		return "assets/img/icons" + fileType.substring(fileType.lastIndexOf("/")) + ".svg";
	}
}
