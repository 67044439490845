import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: 'cleanUrl',
	pure: false
})
export class CleanUrl implements PipeTransform {

	urls: any = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
	transform(text: string) {
		return this.cleanUrl(text);
	}

	private cleanUrl(text: string) {
		// Find/Replace URL's in text
		if (text.match(this.urls)) {
			text = text.replace(this.urls, function replacer($1, $2, $3) {
				let lastSlash = /\/$/gim;
				let url: any = $1;
				let urlClean: any = url.replace("" + $3 + "://" + "www.", "");
				if (urlClean.match(lastSlash)) {
					urlClean = urlClean.replace(new RegExp(lastSlash), "");
					return urlClean;
				}
				return urlClean;
			});
		}

		return text;
	}
}
