import { ImageComp } from "../lib/image-component";
import { utils } from "../lib/utils";
import { CommentList } from "./commentList";
import { Shape } from "./shape";
import { Comment } from "./comment";
import { AnnotationPrivateCondition } from "../../../../interfaces";


export class Annotation extends ImageComp {
	id: string;
	shape: any;
	resolution: any;
	range: any;
	isOpen: boolean;
	comments: Comment[];
	commentList: CommentList;
	annotationShape: Shape;
	viewportTransform: any[];
	privateConditionKey: AnnotationPrivateCondition = null;

	constructor(data: any, image: any) {
		super(image);
		this.id = data.id || utils.guid();
		this.resolution = (typeof data.range == 'string') ? JSON.parse(data.resolution) : data.resolution;
		this.shape = data.shape || '{}';
		this.range = (typeof data.range == 'string') ? JSON.parse(data.range) : (data.range || { start: null, end: null });
		this.viewportTransform = JSON.parse(this.shape).viewportTransform
		this.privateConditionKey = data.privateConditionKey;

		this.buildComments(data);
		this.buildShape();

		this.isOpen = false;
	}

	buildComments(data) {
		this.commentList = new CommentList({ comments: data.comments, annotation: this }, this.plugin);
		this.comments = this.commentList.comments;
	}

	buildShape() {
		this.annotationShape = new Shape(this.plugin, this.shape);
	}

	get data() {
		return {
			id: this.id,
			shape: this.shape,
			comments: this.commentList.data,
			resolution: this.resolution,
			range: this.range,
			privateConditionKey: this.privateConditionKey
		}
	}

	open() {
		this.annotationShape.openShape(this.data.id, this.data.resolution);
		this.revertOpacity();
		this.plugin.fire('annotationOpened', this.data);
	}

	openAnnotationEdit() {
		this.annotationShape.openShape(this.data.id, this.data.resolution, false);
	}

	updateAnnotation(data) {
		this.resolution = data.resolution;
		this.shape = data.shape;
		this.range = data.range;
		this.viewportTransform = JSON.parse(this.shape).viewportTransform
		this.privateConditionKey = data.privateConditionKey;

		this.buildComments(data);
		this.buildShape();
	}

	updateEditedAnnotation(isWSCall = false) {
		let shape = JSON.parse(JSON.stringify(this.plugin.canvasFabric));
		shape["viewportTransform"] = this.plugin.canvasFabric.viewportTransform
			;
		this.shape = JSON.stringify(shape);
		this.viewportTransform = shape["viewportTransform"];
		this.resolution = {
			height: this.plugin.canvasFabric.getHeight(),
			width: this.plugin.canvasFabric.getWidth()
		}
		this.buildShape();
		if (!isWSCall) {
			this.plugin.fire("updateComment", {
				annotationId: this.id,
				commentId: this.commentList.comments[0].id,
				body: this.plugin.primordial.annotationComment
			});
			this.plugin.annotationState.stateChanged();
			this.plugin.fire("annotationEdited", { annotation: this.data, allAnnotations: this.plugin.annotationState.data });
		}
	}

	hoverOpen() {
		this.increaseOpacity();
		this.plugin.fire('annotationHoveredIn', this.data);
	}

	hoverClose() {
		this.revertOpacity();
		this.plugin.fire('annotationHoveredOut', this.data);
	}

	clickOpen() {
		this.increaseOpacity();
		if (this.viewportTransform) {
			this.plugin.canvasFabric.setViewportTransform(this.viewportTransform);
		}
		this.isOpen = true;
		this.plugin.fire('annotationClickedIn', this.data);
	}

	clickClose() {
		this.revertOpacity();
		this.isOpen = false;
		this.plugin.fire('annotationClickedOut', this.data);
	}

	close() {
		this.removeDrawing();
	}

	increaseOpacity() {
		const annotationId = this.data.id;
		const canvasFabric = this.plugin.canvasFabric;

		canvasFabric.getObjects().forEach(function (obj) {
			if (obj.id == annotationId) {
				obj.set({
					opacity: 1
				});
				canvasFabric.renderAll();
			}
		});
	}

	revertOpacity() {
		const annotationId = this.data.id;
		const canvasFabric = this.plugin.canvasFabric;

		canvasFabric.getObjects().forEach(function (obj) {
			if (obj.id == annotationId) {
				obj.set({
					opacity: 0.5
				});
				canvasFabric.renderAll();
			}
		});
	}

	removeDrawing() {
		this.annotationShape.closeShape(this.data.id);
	}

	teardown(removeFromCollection = true) {
		this.close();
		if (this.commentList) this.commentList.teardown(removeFromCollection);
		if (removeFromCollection) this.plugin.annotationState.removeAnnotation(this);
	}

	static newFromData(shape, commentStr, parentId, resolution, plugin, id = null, range = null, privateConditionKey = null) {
		var comment = Comment.dataObj(commentStr, parentId, plugin);
		var data = {
			id: id,
			resolution: resolution,
			shape: shape,
			comments: [comment],
			range: range,
			privateConditionKey: privateConditionKey
		};
		return new Annotation(data, plugin);

	}

	static createNew({
		shape,
		comment,
		parentId,
		resolution,
		plugin,
		id = null,
		range = null,
		privateConditionKey = null
	}: {
		shape: any,
		comment: string,
		parentId: string,
		resolution: any,
		plugin: any,
		id?: string,
		range?: any,
		privateConditionKey?: AnnotationPrivateCondition
	}) {
		const commentData = Comment.dataObj(comment, parentId, plugin);
		const data = {
			id,
			resolution,
			shape,
			comments: [commentData],
			range,
			privateConditionKey
		};
		return new Annotation(data, plugin);
	}

}
