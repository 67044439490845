import { Component, Input } from '@angular/core';
import { onRedirectToTCP_FNC } from '../../shared/base-functions/terms-conditions.base-function';
import { TranslateService } from "../../shared/services/translate.service";

@Component({
    selector: 'mtm-terms-conditions-check',
    templateUrl: './terms-condition-check.component.html'
})
export class MTMTermConditionCheck {
    @Input() classObj: any;
    @Input() accessor: string;
    @Input() companyType: string;

    isTermsCondition: boolean = false;

    constructor(
        private translateService: TranslateService
    ) { }

    ngOnInit() { this.init() }
    ngOnChanges() { this.init() }

    init() {
        if (this.classObj && this.accessor)
            this.isTermsCondition = this.classObj[this.accessor];
    }

    onModelChanged() {
        if (this.classObj && this.accessor)
            this.classObj[this.accessor] = this.isTermsCondition;
    }

    getLocale() {
        return this.translateService.getLang()
    }

    onRedirectToTCP(selector: string): boolean {
        return onRedirectToTCP_FNC(selector);
    }
}
