<div class="modal-body">
  <h5 class="text-center" *ngIf="!user.blocked">Block {{ user.fullName }}?</h5>
  <h5 class="text-center" *ngIf="user.blocked">Unblock {{ user.fullName }}?</h5>
  <p class="text-muted mt-3 text-center" *ngIf="!user.blocked">This person will no longer be able to participate or
    contribute to this project.</p>
  <p class="text-muted mt-3 text-center" *ngIf="user.blocked">This person will be able to participate or contribute to
    this project.</p>
  <div class="row">
    <div class="col">
      <button class="btn btn-outline-secondary btn-lg btn-block"
        (click)="activeModal.close()"><span>Cancel</span></button>
    </div>
    <div class="col">
      <button *ngIf="!user.blocked" class="btn btn-danger btn-lg btn-block"
        (click)="block()"><span>Block</span></button>
      <button *ngIf="user.blocked" class="btn btn-danger btn-lg btn-block"
        (click)="unblock()"><span>Unblock</span></button>
    </div>
  </div>
</div>