import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from './../shared/services/auth.service';
import { ErrorMessageService } from './../shared/services/error-message.service';
import { NotificationService } from './../shared/services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

	formGroup: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	firstName: any = null;
	lastName: any = null;
	token: string = null;
	companyId: string = null;
	ngUnsubscribe = new Subject();
	isPasswordValid: boolean = false;
	strongPassword: boolean = false;

	constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService,
		private notificationService: NotificationService) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		// Read params
		this.route.queryParams.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params: Params) => {

			this.firstName = params['firstName'];
			this.lastName = params['lastName'];
			this.token = params['token'];
			this.companyId = params['companyId'];

			// Load form group
			this.loadFormGroup();
		});

	}


	/**
	 * Submit form
	 */
	onSubmit() {
		if (!this.isPasswordValid) {
			return;
		}

		this.submitted = true;

		if (this.formGroup.valid) {

			// Reset password
			this.authService.resetUserPassword(
				this.token,
				this.formGroup.controls['newPassword'].value,
				this.formGroup.controls['reNewPassword'].value
			).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((data: any) => {

				// Display notification
				this.notificationService.open({
					title: 'Reset Password',
					description: `Your password has been reset`,
					confirmBtn: 'Accept'
				}).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(() => {

					// Navigate to profile avatar page
					this.router.navigate(['/entry/login']);

				})


			});
		}

	}



	// ****************************************************************************************


	/**
	 * Load form group
	 */
	private loadFormGroup() {

		this.formGroup = this.fb.group({
			newPassword: ['', [Validators.required, Validators.minLength(6)]],
			reNewPassword: ['', [Validators.required, Validators.minLength(6)]]
		});

		// Subscribe to changes to get validation errors
		this.formGroup.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.formGroup)
			this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);
	}

	passwordValidated(val) {
		this.isPasswordValid = val;
	}

	onPasswordStrengthChanged(event: boolean) {
		this.strongPassword = event;
	}


}
