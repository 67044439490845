import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtm-creative-icon',
  templateUrl: './creative-icon.component.html'
})
export class CreativeIconComponent implements OnInit {
  @Input() svgWidth: string = "25px";

  constructor() { }

  ngOnInit() {
  }

}
