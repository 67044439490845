<div class="col mt-3 project-container">
	<div class="row pl-5 bg-primary my-projects-header">
		<div class="media my-5">
			<mtm-user-profile-picture [forceLoadProfile]="true" [user]="user" [username]="user.username" [height]="50"
				[width]="50">
			</mtm-user-profile-picture>
			<div class="media-body align-self-center ml-3 text-white">
				<h4 class="media-heading">{{'dashboardHi' | translate}} {{ user.firstName }}</h4>
				<p>{{'dashboardWelcome' | translate}}</p>
			</div>
		</div>
	</div>
	<div class="row justify-content-between projects-toolbar py-2">
		<div class="row w-100">
			<div class="col-sm-4 text-left">
			</div>

			<div class="col-sm-5 text-center">
			</div>

			<div class="col-sm-3 text-right">
				<div ngbDropdown class="d-inline-block">
					<button class="btn" id="notifications" ngbDropdownToggle>
						<i class="fa fa-flag fa-fw text-primary" aria-hidden="true"></i>
						<span>Project Invitations</span>
						<i class="fa fa-angle-down fa-fw" aria-hidden="true"></i>
					</button>
					<div ngbDropdownMenu aria-labelledby="notifications" class="pb-2">
						<div class="w-100 list-container">
							<button class="dropdown-item" (click)="inProgress()">{{'ongoingProjects' |
								translate}}</button>
							<button *ngIf="this.auth.hasProductionRole()" class="dropdown-item" hasUserAccess
								[authority]="authority.I" (click)="projectProposals()">Project Proposals</button>
							<button class="dropdown-item" hasUserAccess [authority]="authority.X"
								(click)="archivedProjects()">{{'archivedProjects' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<invitations [viewType]="'IN_PROGRESS'" [isInv]="true" style="min-width:100%"></invitations>
	</div>
</div>