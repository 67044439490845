import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/services/auth.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { UserService } from 'app/shared/services/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mtm-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit, AfterViewInit {
  @ViewChild('visualizationNameElement') visualizationNameElement;
  @ViewChild('editInput') editInput;
  @ViewChild('addUserInput', { static: true }) addUserInput!: ElementRef;

  groupName: string = " Create New group...";
  editMode = false;
  selectedUser: any[] = [];
  selectedUserDetail: any;
  selectedTeam: any;
  searchInput: string;
  isDropDownOpen: boolean = false;
  participants: any[] = [];
  backupParticipants: any[] = [];
  ngUnsubscribe = new Subject();
  authUser: any;
  teams: any = [];

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    public timelineService: TimelineService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.getParticipants();
    this.getTeams();
  }

  ngAfterViewInit() {
    // remove focus
    setTimeout(() => {
      this.addUserInput.nativeElement.blur();
    }, 0);
  }

  cancel() {
    this.activeModal.close();
  }

  editGroupName(item) {
    console.log('item', item)
  }

  toggleEditName() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      setTimeout(() => {
        // Set focus to the input field when entering edit mode
        this.editInput.nativeElement.focus();
      });
    } else {
      // Save changes or perform other actions when leaving edit mode
      // For now, you can just update the name in your data
      console.log('Updated group name:', this.groupName);
    }
  }

  getParticipants() {
    this.userService.getUsersByCompanyId(this.authUser.companyId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (users) => {
          this.participants = users;
          this.backupParticipants = users;
          this.participants.filter(participant => {
            participant.selected = false;
            return participant;
          });
          this.backupParticipants.filter(participant => {
            participant.selected = false;
            return participant;
          });
        },
        error: (err) => {
          console.error('err', err);
        },
        complete: () => {

        },
      })
  }
  
  getTeams() {
    this.userService.getTeams(this.authUser.companyId).subscribe({
      next: (teams) => {
        this.teams = teams;
        console.log('this.teams', this.teams)
      },
      error: (err) => {
        console.error('err', err);
      },
      complete: () => {

      },
    })
  }

  searchParticipants() {
    this.isDropDownOpen = true;
    if (this.searchInput) {
      this.participants = this.backupParticipants.filter(user => {
        let isMatched = true;
        if (!user.fullName || !user.email) {
          isMatched = false;
        } else {
          const fullNameMatch = user.fullName.toLowerCase().includes(this.searchInput.toLowerCase());
          const emailMatch = user.email.toLowerCase().includes(this.searchInput.toLowerCase());
          console.log('emailMatch', emailMatch)

          isMatched = fullNameMatch || emailMatch;
        }
        return isMatched;
      });
    } else {
      this.participants = this.backupParticipants;
    }

  }

  selectUser(user) {
    console.log('user', user);

    const { username } = user;
    const indexOfUsername = this.selectedUser.findIndex(selectedUser => selectedUser.username === username);

    if (indexOfUsername === -1) {
      // The participant is not in the list, so add them.
      this.selectedUser.push(user);
      user.selected = true;
    } else {
      // The participant is already in the list, so remove them.
      this.selectedUser.splice(indexOfUsername, 1);
      user.selected = false;
    }
    console.log('this.selectedUser', this.selectedUser)
  }

  onTeamSelect(event: any) {
    const selectedValue = event.target.value;
    this.selectedTeam = this.teams.find(team => team.id === selectedValue);
  }
}
