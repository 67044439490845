import { Component, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { ApiService } from "app/shared/services/api.service";
import { FileReorderService } from 'app/shared/services/files-reorder.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { TranslatePipe } from "../../pipes/translate.pipe";

@Component({
	selector: 'selector-file-reorder-modal-component',
	templateUrl: './file-reorder-modal.component.html',
	styleUrls: ['./file-reorder-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileReorderModalComponent implements AfterViewInit {

	@ViewChild('tilesContainer', { static: true }) tilesContainer: ElementRef;

	/* ------- ------- */

	slideValue: number = 5;
	slideValueMax: number = 120;

	tileWidth: number = 250;
	tileHeight: number = 150;
	tileWidthMax: number = 250;
	diffTileWidth: number = 144;

	dragulaKey: string = 'files-reorder-modal';
	dropSubscribe;
	backupElementList: any[] = [];
	reorderRequestStatus: boolean = false;
	disabled = false;

	constructor(private dragulaService: DragulaService,
		private api: ApiService,
		public serviceFileReorder: FileReorderService,
		private serviceOverlay: OverlayService,
		private translatePipe: TranslatePipe) {
	}

	ngOnInit() {
		this.dragulaService.createGroup(this.dragulaKey, {
			moves: (el: any, container: any, handle: any) => {
				return !this.reorderRequestStatus;
			}
		});
		this.dropSubscribe = this.dragulaService.drop(this.dragulaKey).subscribe((dropValue) => {
			this.disabled = true;
			this.setValueToReorderRequestStatus(true);
			setTimeout(() => this.dropFunc(dropValue), 1500);
		});

		// this.changeSlideValue();
		this.calcMaxWidthForPreviewMode();
		this.init();
		window.addEventListener('resize', () => {
			setTimeout(() => {
				this.calcMaxWidthForPreviewMode();
				this.onChange();
			}, 250);
		})
		this.serviceFileReorder.removedEmitFileForReorderModal.subscribe(res => {
			let index = this.backupElementList.findIndex(k => k.id == res.id);
			if (index >= 0)
				this.backupElementList.splice(index, 1);
		});
	}

	ngOnChanges() {
		this.init();
	}

	private init() {
		setTimeout(() => {
			this.backupElementList = new Array<any>();
			this.serviceFileReorder.files.forEach(x => {
				this.backupElementList.push(x);
			});
		}, 55);
	}


	ngAfterViewInit() {
		setTimeout(() => {
			this.calcMaxWidthForPreviewMode();
		}, 5000);
	}

	ngOnDestroy(): void {
		this.dragulaService.destroy(this.dragulaKey);
		if (this.dropSubscribe) {
			this.dropSubscribe.unsubscribe();
		}
	}

	private calcMaxWidthForPreviewMode() {
		let containerWidth = this.tilesContainer.nativeElement.clientWidth;
		this.tileWidthMax = (containerWidth - 15) / 3.0;
		this.diffTileWidth = this.tileWidthMax - this.tileWidthMax / 2.0;
	}

	disableTrue(val: any) {
		this.disabled = true;
	}

	disableFalse(val: any) {
		this.disabled = false;
	}

	onChange(e?) {
		if (!this.disabled) {
			let k = (this.slideValueMax - this.slideValue) / 100.0;
			this.tileWidth = this.tileWidthMax - (this.diffTileWidth * k);
			this.tileHeight = this.tileWidth * 109.0 / 142.0;
		}
	}

	public deleteFile(file) {
		if (this.serviceFileReorder.deleteFileOverrideMethod) {
			this.serviceFileReorder.deleteFileOverrideMethod(file);
		}
	}

	private dropFunc(dropValue: any) {
		if (!this.serviceFileReorder.files && !this.serviceFileReorder.files.length && !this.serviceFileReorder.item) {
			this.init();
			this.setValueToReorderRequestStatus(false);
		} else {
			let list = this.backupElementList.map(f => f.id).filter(id => (id !== null && id !== undefined));
			this.api.updateFilesOrder(
				this.serviceFileReorder.projectId,
				this.serviceFileReorder.sectionId,
				this.serviceFileReorder.subSectionId,
				this.serviceFileReorder.item.id, list.reverse()
			).subscribe(
				(res) => {
					this.setValueToReorderRequestStatus(false);
					let elementIndex = 0;
					let referencesElement: any;
					for (let i = 0; i < this.backupElementList.length; i++) {
						elementIndex = this.serviceFileReorder.files.findIndex(l => l.id == this.backupElementList[i].id);
						if (elementIndex != i) {
							referencesElement = this.serviceFileReorder.files[i];
							this.serviceFileReorder.files[i] = this.serviceFileReorder.files[elementIndex];
							this.serviceFileReorder.files[elementIndex] = referencesElement;
						}
					}
					this.serviceOverlay.showSuccess(this.translatePipe.transform('overlayReorderSuccess'), 'Files Reordering');
				}, err => {
					this.setValueToReorderRequestStatus(false);
					this.serviceOverlay.showError(this.translatePipe.transform('overlayReorderingContact'), 'Files Reordering');
				}
			);
		}
	}

	private setValueToReorderRequestStatus(status: boolean) {
		this.reorderRequestStatus = status;
	}
}
