import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { SubscriptionPlan } from "../../layouts/build-plan-circle/build-plan-circle.component";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    subscriptionPlan: SubscriptionPlan;

    constructor(private api: ApiService,) {
    }
}