import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ProjectService } from "../services/project.service";
import { Authority, PermissionService } from "../services/permissions.service";

@Directive({
    selector: '[oneShotDownPayment]'
})

export class OneShotDownpaymentDirective {

    @Input() project: any = null;
    @Input() section: any = null;
    @Input() index: number = null;
    isCompanyAuthUser: boolean = null;

    constructor(public el: ElementRef, public renderer: Renderer2, private projectService: ProjectService, public permissionService: PermissionService) {
        if (!this.project) {
            this.project = this.projectService.project;
        }
        this.isCompanyAuthUser = permissionService.hasAuthority(Authority.U, this.project.id)
    }


    ngOnInit() {
        setTimeout(() => this.init(), 750);
    }

    public init() {
        if (!this.project)
            this.project = this.projectService.project;
        if (this.project && this.section && this.index != null && this.isCompanyAuthUser && this.project.projectType != 'FREE') {
            if (this.section.section == 'CREATIVE_PARTNER_SELECTION'
                || this.section.section == 'CREATIVE_BRIEF' || this.section.section == 'CREATIVE_WORK' || this.section.section == 'SELF_FIX_EDIT' ||
                (this.project.projectType == 'ONE_SHOT' && ((this.section.section == 'PRE_PRODUCTION'
                    && this.project.paymentStatus == 'MUST_PAY')
                    || (this.section.section == 'SHOOTING' && this.project.paymentStatus == 'MUST_PAY')
                    || (this.section.section == 'POST_PRODUCTION' && this.project.paymentStatus == 'MUST_PAY')))) {
                this.cursorNotAllowed();
            }
        } else {
            if (this.project && this.section && this.index != null && this.isCompanyAuthUser && this.project.projectType == 'FREE') {
                if (this.section.section == 'PRODUCTION_PARTNER_SELECTION' || this.section.section == 'PRODUCTION_BRIEF' ||
                    this.section.section == 'PRODUCTION_COMPANY_PROPOSALS' || this.section.section == 'CREATIVE_PARTNER_SELECTION' ||
                    this.section.section == 'CREATIVE_BRIEF' || this.section.section == 'CREATIVE_WORK' || this.section.section == 'SELF_FIX_EDIT') {
                    this.cursorNotAllowed();
                }
            }
        }
    }
    private cursorNotAllowed() {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
    }


}
