<div class="mtm-group-chat-picture" [style.cursor]="showHoverIcon ? 'pointer' : 'default'">
	<div class="group-chat-picture-container d-flex justify-content-center" *ngIf="conversation.users">
		<div class="group-container d-flex align-items-start justify-content-center"
			*ngIf="conversation.users.length === 1" [style.height]="height+'px'" [style.width]="width+'px'">
			<div class="conversation-singe-user-container d-flex align-items-start">
				<div class="conversation-user">
					<mtm-user-profile-picture [username]="conversation.users[0]" [height]="height" [width]="width"
						[squareIcon]="true" [fontSize]="'8px'">
					</mtm-user-profile-picture>
				</div>
			</div>
		</div>
		<div class="group-container d-flex align-items-start justify-content-center"
			*ngIf="conversation.users.length === 2" [style.height]="height+'px'" [style.width]="width+'px'">
			<div class="conversation-two-users-container d-flex align-items-start">
				<div class="conversation-user">
					<mtm-user-profile-picture [username]="conversation.users[0]" [height]="height/2" [width]="width/2"
						[squareIcon]="true" [fontSize]="'8px'">
					</mtm-user-profile-picture>
				</div>
				<div class="conversation-user">
					<mtm-user-profile-picture [username]="conversation.users[1]" [height]="height/2" [width]="width/2"
						[squareIcon]="true" [fontSize]="'8px'">
					</mtm-user-profile-picture>
				</div>
			</div>
		</div>
		<div class="group-container d-flex align-items-start justify-content-center"
			*ngIf="conversation.users.length > 2" [style.height]="height+'px'" [style.width]="width+'px'">
			<div class="conversation-triple-users-container d-flex flex-column align-items-start">
				<div class="conversation-user-container d-flex align-items-center">
					<div class="conversation-user">
						<mtm-user-profile-picture [username]="conversation.users[0]" [height]="height/2"
							[width]="width/2" [squareIcon]="true" [fontSize]="'8px'">
						</mtm-user-profile-picture>
					</div>
					<div class="conversation-user">
						<mtm-user-profile-picture [username]="conversation.users[1]" [height]="height/2"
							[width]="width/2" [squareIcon]="true" [fontSize]="'8px'">
						</mtm-user-profile-picture>
					</div>
				</div>
				<div class="conversation-user-container d-flex align-items-start">
					<div class="conversation-user">
						<mtm-user-profile-picture [username]="conversation.users[2]" [height]="height/2"
							[width]="width/2" [squareIcon]="true" [fontSize]="'8px'">
						</mtm-user-profile-picture>
					</div>
					<div class="conversation-user" *ngIf="conversation.users.length > 3">
						<div class="total-hide-users d-flex align-items-center justify-content-center"
							[style.height]="iconSize" [style.width]="iconSize" [style.fontSize]="fontSize"
							[style.fontWeight]="600">
							+ {{ conversation.users.length - 3 }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<i *ngIf="showHoverIcon"
			class="fa fa-comments d-flex align-items-center justify-content-center w-100 h-100"></i>
	</div>
</div>