<div class="card mt-5">
    <h6 class="header-title">User & Permissions</h6>
    <p class="header-description">For each Sub Project, you can define team and voting system. You will be able to back
        to this step later.</p>
    <ngb-accordion class="test" *ngFor="let option of timelineOption">
        <ngb-panel title="{{option.name}}">
            <ng-template ngbPanelContent>
                <div class="d-block">
                    <div class="row mt-2 d-flex py-3 recommendation-row">
                        <div class="col">
                            <div class="position " [ngClass]="{'position-active': decision}"
                                (click)="changeDecision(isProposal)">
                                <div>
                                    <img src="../../../assets/img/unnamity.svg">
                                </div>
                                <h6 class="font-weight-bold mt-2"
                                    [ngClass]="{'color:#898D98' : !decision,  'text-white':decision}">{{'unanimity' |
                                    translate}}</h6>
                                <p [ngClass]="{'position-font': decision}">
                                    {{'unanimitySpecify' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col">
                            <div class="position" [ngClass]="{'position-active': !decision}"
                                (click)="changeDecision(false, isProposal)" style="height:100%">
                                <img src="../../../assets/img/OneDecisionMaker.svg">
                                <h6 class="font-weight-bold mt-2"
                                    [ngClass]="{'color:#898D98' : decision,  'text-white':!decision}">One Decision maker
                                </h6>
                                <p [ngClass]="{'position-font': !decision}">
                                    One Team member is the only one to recommend the elements presented to the client.
                                    Choose who by ticking the “Validation”
                                    box.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="min-width-15">
                                <span [style.white-space]="'nowrap'">{{'name' | translate}}</span>
                            </th>
                            <th class="role-dropdown">
                                <span [style.white-space]="'nowrap'">{{'email' | translate}}</span>
                            </th>
                            <th class="role-dropdown">
                                <span [style.white-space]="'nowrap'">Last Seen</span>
                            </th>
                            <th class="role-dropdown">
                                <span [style.white-space]="'nowrap'">{{'role' | translate}}</span>
                            </th>
                            <th class="action-button-container">
                                <span [style.white-space]="'nowrap'">{{'decisionMaker' | translate}}</span>
                            </th>
                            <th class="action-button-container">
                                <span [style.white-space]="'nowrap'">Activate</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of users; let i = index">
                            <td>
                                <h6 >{{ user?.firstName }} {{ user?.lastName }}</h6>
                            </td>
                            <td class="w-25">
                                <h6>{{ user?.email }}</h6>
                            </td>
                            <td>{{user.lastSeen | mtmDateTime}}</td>
                            <td>
                                <h6>{{ user?.globalRole }}</h6>
                            </td>
                            <td>
                                <div class="d-flex align-items-center justify-content-center">
                                    <label class="mtm-checkbox mtm-checkbox-green m-0">
                                        <input class="form-check-input"
                                            type="checkbox" [checked]="user.activationStatus === 'ACTIVE_COMPLETED'">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center justify-content-center">
                                    <label class="mtm-checkbox mtm-checkbox-green m-0">
                                        <input class="form-check-input"
                                            type="checkbox" [checked]="user.activationStatus === 'ACTIVE_COMPLETED'">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <div class="row mt-3">
        <div class="m-0 p-0" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
            <div class="row mb-3">
                <div class="col-sm ml-3">
                    <button class="bg-white return-to-project-button" type="button">
                        <span class="_700 f-12 text-uppercase">{{'returnToProject' | translate}}</span>
                    </button>
                </div>
                <div class="col-sm p-0 d-flex justify-content-end">
                    <button class="next-button" type="button">
                        <span class="_700 f-12 text-light text-uppercase">next</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>