import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class ProposalCommentService {

    constructor(private serviceApi: ApiService) {
    }

    getUser(email): Observable<any> {
        return this.serviceApi.httpGet(`/api/users/email/${email}`);
    }

    getAllDiscussionComment(projectId, proposalId): Observable<any> {
        return this.serviceApi.httpGet(`/api/proposals/${projectId}/proposal/${proposalId}/section/discussion`);
    }

    setDiscussionComment(projectId, proposalId, data): Observable<any> {
        return this.serviceApi.httpPost(`/api/proposals/${projectId}/proposal/${proposalId}/section/discussion`, data);
    }


    getAllQaComment(projectId, proposalId): Observable<any> {
        return this.serviceApi.httpGet(`/api/proposals/${projectId}/proposal/${proposalId}/section/qa`);
    }

    setQaComment(projectId, proposalId, data): Observable<any> {
        return this.serviceApi.httpPost(`/api/proposals/${projectId}/proposal/${proposalId}/section/qa`, data);
    }
}


