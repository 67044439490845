import * as _ from 'lodash';
import { Component, Input, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectService } from "app/shared/services/project.service";
import { DeclineProposalPopupComponent } from "../decline-proposal-popup/decline-proposal-popup.component";
import { CompanyProfilePortfolioComponent } from "../../../prod-partner/company-profiles/company-profile-portfolio/company-profile-portfolio.component";
import { ProposalInvitationService } from "app/shared/services/proposal-invitation.service";
import { ProdPartnerService } from "app/shared/services/prod-partner.service";
import { DateHelperService } from "app/shared/services/date-helper.service";
import { LoadingBase } from 'app/shared/bases/loading.base';
import { CommonService } from 'app/shared/services/common.service';
import { CurrencyService } from 'app/shared/services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';

@Component({
	selector: 'invitations',
	templateUrl: './invitation.component.html',
	styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent extends LoadingBase implements OnInit, OnDestroy {
	@Input() viewType: string = 'IN_PROGRESS';
	@Input() isInv: boolean = false;
	@Input() proposalStatusFilter: string = null;

	data: any[] = [];
	private globalRating: number = 0;
	private projectId: any;
	private noneImg = "./assets/img/samples/No_Image_Available.png";
	private authUser: any;
	sectors: any[];
	ngUnsubscribe = new Subject();

	constructor(private router: Router,
		private modalService: NgbModal,
		private service: ProjectService,
		private commonService: CommonService,
		private serviceProdPartner: ProdPartnerService,
		private proposalInvitationService: ProposalInvitationService,
		private authService: AuthService,
		private permissionService: PermissionService,
		private currencyService: CurrencyService) {
		super();
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		this.initLoadingParameters(undefined, undefined, ['w-100']);
		this.init();
	}

	ngOnChanges(changes: any) {
		if (changes.isInv && !changes.isInv.firstChange) {
			this.init();
		} else if (changes.viewType && !changes.viewType.firstChange) {
			this.init();
		} else if (changes.proposalStatusFilter && !changes.proposalStatusFilter.firstChange) {
			this.init();
		}
	}

	private async init() {
		if (!this.permissionService.hasAuthority(Authority.I, null)) {
			return;
		}
		this.showLoading();
		this.commonService.getClientSector().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.sectors = res || [];
		}, () => {

		});
		if (this.isInv) {
			this.showLoading();
			forkJoin([
				this.proposalInvitationService.getInvitedCompanies(),
				this.proposalInvitationService.getInvitedCompaniesByUserProject(),
				this.proposalInvitationService.getInvitedCompaniesContentByUserProject()
			]).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				const data = res[0];
				_.map(data, invitation => {
					let project = _.find(res[1], (project) => project.id === invitation.projectId);
					if (project) {
						invitation.description = project.description;
						invitation.currency = project.currency;
					}
					let userProject = _.find(res[2], (project) => project.projectId === invitation.projectId);
					if (userProject) {
						invitation.clientSector = userProject.clientSector;
						invitation.clientSubSector = userProject.clientSubSector;
						invitation.exportChannels = userProject.exportChannels;
					}
					if (invitation.exportChannels) {
						let exportChannels = [];
						_.map(invitation.exportChannels, (channel: string) => {
							exportChannels.push(channel[0].toLocaleUpperCase() + channel.substring(1, channel.length));
						});
						invitation.exportChannels = exportChannels.join(', ');
					}
				});
				if (!data || !Array.isArray(data)) {
					this.gettingDataError();
					this.showLoadingError();
				} else {
					if (this.viewType == 'IN_PROGRESS') {
						this.currencyService.getMyCountryDetail().pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe((c_data: any) => {
							this.data = data.filter(k => k.status == this.viewType);
							this.data.forEach(d => {
								this.serviceProdPartner.getProdPartnerContent(d.projectId).pipe(
									takeUntil(this.ngUnsubscribe)
								).subscribe(content => {
									if (d.avatarUrl) {
										d.avatarUrl = d.avatarUrl + '?' + (new Date).getTime();
									}
									this.proposalInvitationService.getClientCompanyAvgRate(d.clientCompanyId).pipe(
										takeUntil(this.ngUnsubscribe)
									).subscribe(avgRate => {
										avgRate.globalRatingAvg != "NaN" ? d.globalRating = avgRate.globalRatingAvg : d.globalRating = 0;
									}, err => {
										console.log(err);
									});
									d.scriptValue = this.checkScriptValue(content);
									d.myCurrency = c_data.currency.code;
								});
								let due = DateHelperService.fromTimestampToDate(d.endDate);
								d.displayDueDate = new Date(due).getTime();
							});
							this.showInfoForArrayData(this.data, 'You don’t have any project invitation');
						});
					}
					else {
						this.data = data.filter(k => k.status != 'IN_PROGRESS' && k.status != 'DECLINED');
						this.showInfoForArrayData(this.data, 'You don’t have any project invitation');
					}
					this.projectId = data;
				}
			}, err => {
				this.gettingDataError();
				this.showLoadingError();
			})

		} else {
			this.showLoading();
			forkJoin([
				this.proposalInvitationService.getProposals(),
				this.proposalInvitationService.getProposalsByUserProject(),
				this.proposalInvitationService.getProposalsContentByUserProject()
			]).subscribe(res => {
				this.data = res[0];
				_.map(this.data, proposal => {
					proposal.description = _.find(res[1], (project) => project.id === proposal.projectId).description;
					proposal.clientSector = _.find(res[2], (project) => project.projectId === proposal.projectId).clientSector;
					proposal.clientSubSector = _.find(res[2], (project) => project.projectId === proposal.projectId).clientSubSector;
					proposal.exportChannels = _.find(res[2], (project) => project.projectId === proposal.projectId).exportChannels;
					proposal.currency = _.find(res[2], (project) => project.projectId === proposal.projectId).currency;
					if (proposal.exportChannels) {
						let exportChannels = [];
						_.map(proposal.exportChannels, (channel: string) => {
							exportChannels.push(channel[0].toLocaleUpperCase() + channel.substring(1, channel.length));
						});
						proposal.exportChannels = exportChannels.join(', ');
					}
				});

				if (this.proposalStatusFilter) {
					let statusFilter = [];
					switch (this.proposalStatusFilter) {
						case 'ongoing':
							statusFilter = ['CREATED', 'IN_PROGRESS', 'UPLOADED'];
							break;
						case 'accepted':
							statusFilter = ['ACCEPTED'];
							break;
						case 'declined':
							statusFilter = ['DECLINED'];
							break;
					}
					this.data = this.data.filter(p => statusFilter.indexOf(p.status) > -1);
				}

				if (this.data.length > 0) {
					this.data.forEach(d => {
						this.serviceProdPartner.getProdPartnerContent(d.projectId).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(content => {
							if (d.avatarUrl) {
								d.avatarUrl = d.avatarUrl + '?' + (new Date).getTime();
							}
							this.proposalInvitationService.getClientCompanyAvgRate(d.clientCompanyId).pipe(
								takeUntil(this.ngUnsubscribe)
							).subscribe(avgRate => {
								avgRate.globalRatingAvg != "NaN" ? d.globalRating = avgRate.globalRatingAvg : d.globalRating = 0;
							}, err => {
								console.log(err);
							});
							d.scriptValue = this.checkScriptValue(content);
						});
						let due = DateHelperService.fromTimestampToDate(d.endDate);
						d.displayDueDate = new Date(due).getTime();
					});
					this.hideLoading();
				}
				else {
					this.showInfoForArrayData(this.data, 'You don’t have any project proposal');
				}
			}, err => {
				this.showLoadingError();
			});
		}
	}

	private checkScriptValue(data: any) {
		return data.script ? "I have a script" : "We need help writing a script";
	}

	private declineInvitation(invitation, index): void {
		let modal = this.modalService.open(DeclineProposalPopupComponent, { size: 'lg' });
		modal.componentInstance.invitation = invitation;
		modal.componentInstance.index = index;
		modal.componentInstance.authUser = this.authUser.username;
		modal.result.then(res => {
			if (!res && res != 0)
				return;

			this.data.splice(res, 1);
		});
	}

	private viewProfileModal(projectId, clientId, selectedData): void {
		let modal = this.modalService.open(CompanyProfilePortfolioComponent, { size: 'lg' });
		modal.componentInstance.modalRef = modal;
		modal.componentInstance.isClient = false;
		modal.componentInstance.projectId = projectId;
		modal.componentInstance.clientId = clientId;
		modal.componentInstance.companyInfo = selectedData;
	}

	private gettingDataError() {
		/*this.notificationService.open({
			title: 'Warning',
			description: `Didn't take production companies,Please, try again...`,
			confirmBtn: 'Accept'
		});*/
	}

	private proposal(invitation) {
		let proposalDTO: any = {};
		proposalDTO = {
			projectId: invitation.projectId,
			prodCompanyId: invitation.productionCompanyId,
			clientCompanyName: invitation.clientCompanyName,
			avatarUrl: invitation.avatarUrl,
			prodCompany: '',
			clientCompanyId: invitation.clientCompanyId,
			status: 'CREATED',
			endDate: invitation.endDate,
			contractApprovedByProducer: null,
			contractApprovedByAdvertiser: null
		};
		this.service.uploadProposal(proposalDTO, invitation.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			this.service.proposal = proposal;
			this.acceptedInvitation(invitation, proposal);
		});
	}

	private acceptedInvitation(invitation, proposal) {
		invitation.status = "ACCEPTED";
		this.proposalInvitationService.setInvitationStatus(invitation).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data) => {
				this.routeToProposalPage(invitation.projectId, proposal.id);
			});
	}

	private openProposal(invitation) {
		this.service.getProposalByUsername(invitation.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.service.proposal = res;
			this.service.getProject(res.projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(project => {
				this.service.project = project;
				this.routeToProposalPage(invitation.projectId, res.id);
			}, (err) => {
				this.routeToProposalPage(invitation.projectId, res.id);
			})
		})
	}

	private routeToProposalPage(projectId, proposalId) {
		this.router.navigate(['projects', projectId, 'proposal', proposalId])
	}

	getClientSectorSubSectorName(company) {
		if (this.sectors && this.sectors.length) {
			const sector = this.sectors.find(sector => sector.industrycode === company.clientSector && sector.subindustrycode === company.clientSubSector);
			if (sector) {
				company.sectorName = sector.industryname;
				company.subSectorName = sector.subindustryname;
			}
			return company.sectorName + ' - ' + company.subSectorName;
		}
		return '';
	}
}
