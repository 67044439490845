import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {OwnershipTransferTypes, Project, SimpleUser, User} from "../../interfaces";
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HelperService } from "app/shared/services/helper.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../services/user.service";
import { forkJoin, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProjectService } from "../../services/project.service";
import { OverlayService } from "../../services/overlayService";
import { TranslatePipe } from "../../pipes/translate.pipe";
import _ from "lodash";

interface RightTransferForm {
  sourceUserId: FormControl<string>;
  selectedProjectIds: FormControl<string[]>;
  targetUserIds: FormControl<string[] | string>;
  deleteSourceUser: FormControl<boolean>;
}

@Component({
  templateUrl: './projects-ownership-transfer.component.html',
  styleUrls: ['./projects-ownership-transfer.component.scss']
})
export class ProjectsOwnershipTransferComponent implements OnInit, OnDestroy {

  sourceUser: SimpleUser;
  availableTargets: User[] = [];
  projects: Project[] = [];
  selectedUsers: User[] = [];
  selectedProjects: Project[] = [];
  isProjectManagerTransfer: boolean = false;
  transferType: OwnershipTransferTypes = OwnershipTransferTypes.PROJECT;
  transferForm = this.fb.group<RightTransferForm>({
    sourceUserId: new FormControl<string>('', [Validators.required]),
    selectedProjectIds: new FormControl<string[]>([], [Validators.required]),
    targetUserIds: new FormControl<string[] | string>([], [Validators.required]),
    deleteSourceUser: new FormControl<boolean>(false)
  });
  companyUsers: any[] = [];
  ngUnsubscribe = new Subject<boolean>();
  isLoading: boolean = false;


  constructor(private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private projectService: ProjectService,
    private overlayService: OverlayService,
    private translatePipe: TranslatePipe) {
  }

  ngOnInit(): void {
    const companyId = this.projects[0].company.id;
    this.isLoading = true;

    const request$: Observable<any> = this.transferType == OwnershipTransferTypes.WORKSPACE ?
      this.userService.getUsersByCompanyId(companyId):  //need to change with multi api
      this.userService.getPossibleOwnersForProject(this.projects[0].id, this.sourceUser.username);

      request$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (users: User[]) => {
          users.forEach((u: any) => {
            (<any>u).label = u.fullName.trim() || u.username;
          });

          users.sort((a, b) => (<any>a).label.localeCompare((<any>b).label));

          this.transferForm.patchValue({
            sourceUserId: this.sourceUser.username,
            selectedProjectIds: this.transferType == OwnershipTransferTypes.WORKSPACE ? [] : [this.projects[0].id],
            targetUserIds: [],
          });

          this.companyUsers = _.uniqBy(users, u => u.username);
          this.availableTargets = this.companyUsers.filter(u => u.username !== this.sourceUser.username);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  //currently supports project ownership transfer only
  submit() {
    HelperService.checkFormFields(this.transferForm);

    if (!this.transferForm.valid) {
      return;
    }

    let transfer$: Observable<any> = null;

    if(this.isProjectManagerTransfer) {
      transfer$ = this.userService.transferOriginalOwnership(this.projects[0].id, this.sourceUser.username,
        this.transferForm.value.targetUserIds.toString());
    } else {
      const transferInfo: Record<string, string[]> = {};
      const targetUsernames = [...this.transferForm.value.targetUserIds];
      this.transferForm.value.selectedProjectIds.map((projectId: string) => {
        transferInfo[projectId] = targetUsernames;
      });
      transfer$ = this.userService.transferOwnership(this.sourceUser.username,
        transferInfo, this.transferForm.value.deleteSourceUser)
    }

    this.isLoading = true;
      transfer$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.activeModal.close(true);
        },
        error: (err) => {

        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  onTargetUsersChange() {
    this.selectedUsers = this.companyUsers.filter(u => this.transferForm.value.targetUserIds.includes(u.username));
  }

  removeSelectedUser(username: string) {
    const newIds = (<string[]> this.transferForm.value.targetUserIds).filter(u => u !== username);
    this.transferForm.patchValue({
      targetUserIds: newIds
    });
    this.selectedUsers = this.companyUsers.filter(u => newIds.includes(u.username));
  }

  protected readonly OwnershipTransferTypes = OwnershipTransferTypes;
}

