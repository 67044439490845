import { EventEmitter } from "@angular/core";

const EVENTS = {
    DRAG_OVER: 'dragover',
    DRAG_LEAVE: 'dragleave',
    DRAG_END: 'dragend',
    DROP: 'drop'
}

const addListener = (event, callback) => window.addEventListener(event, callback);

const removeListener = (event, callback) => window.removeEventListener(event, callback);

const callbackDRAG_OVER = (e) => emitActive();
const callbackDRAG_LEAVE = (e) => emitDeactive();
const callbackDRAG_END = (e) => emitDeactive();
const callbackDROP = (e) => emitDeactive();

removeListener(EVENTS.DRAG_OVER, callbackDRAG_OVER);
removeListener(EVENTS.DRAG_LEAVE, callbackDRAG_LEAVE);
removeListener(EVENTS.DRAG_END, callbackDRAG_END);
removeListener(EVENTS.DROP, callbackDROP);

addListener(EVENTS.DRAG_OVER, callbackDRAG_OVER);
addListener(EVENTS.DRAG_LEAVE, callbackDRAG_LEAVE);
addListener(EVENTS.DRAG_END, callbackDRAG_END);
addListener(EVENTS.DROP, callbackDROP);

export const emitActive = () => listenerDragActiveStatus.emit(true);
export const emitDeactive = () => listenerDragActiveStatus.emit(false);

export const listenerDragActiveStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
