import { Component, HostBinding, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { HelperService } from "app/shared/services/helper.service";
import { ProjectListViewComponent } from "../../../project-list-view.component";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { AuthService } from "app/shared/services/auth.service";
import { Project } from "app/shared/interfaces";

@Component({
  selector: 'project-action-cell',
  templateUrl: './project-action-cell.component.html'
})
export class ProjectActionsCellComponent implements OnInit, ICellRendererAngularComp {
  @HostBinding('class') classes = 'd-block w-100';
  params: any;
  parentComponent: ProjectListViewComponent;
	authority = Authority;
  isAdmin: boolean;
  isManager: boolean = false;
  currentUserName: string;
  isArchived: boolean = false;

  constructor(private router: Router, private permissionService: PermissionService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.isAdmin = this.permissionService.hasAuthority(Authority.Z, null);
    this.currentUserName = this.authService.getAuthUserName();
  }

  agInit(params: ICellRendererParams): void {
    const project: Project = params.data as Project;
    this.isManager = project?.manager === this.currentUserName;
    this.isArchived = project?.status === 'ARCHIVED';
    this.params = params;
    this.parentComponent = params.context.componentParent;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.parentComponent = params.context.componentParent;
    const project: Project = params.data as Project;
    this.isManager = project?.manager === this.currentUserName;
    this.isArchived = project?.status === 'ARCHIVED';
    return true;
  }

}
