import { environment } from './../../../environments/environment';
import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from "app/shared/services/auth.service";

@Directive({
	selector: '[userAvatar]',
	host: {
		'[src]': '(!error) ? getUserAvatarPath() : defaultImg',
		'(error)': 'onError()',
		'[style.display]': 'src === this.defaultImg && name ? \'none\' : \'inline-block\''
	}
})
export class UserAvatarDirective {
	@Input() src: string;
	@Input() username: any;
	@Input() name: any;
	@Input() notifiedAvatar: any;

	avatarUrl: string = null;
	error: boolean = false;

	public defaultImg: string = 'assets/img/misc/avatar-no-image.png';
	private version = null;

	constructor(private authService: AuthService, private elementRef: ElementRef) { }

	public ngOnInit() {
		this.version = this.authService.getAuthSession();
	}

	stringToHslColor(str, s, l) {
		var hash = 0;
		for (var i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}

		var h = hash % 360;
		return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
	}

	public onError() {
		this.error = true;
		this.src = this.defaultImg;
		let parent = this.elementRef.nativeElement.parentElement;
		if (this.name) {
			let initialIcon = document.createElement('div'), nameArray = this.name.split(' '), initialName = nameArray[0][0];
			if (nameArray.length > 1) {
				initialName += nameArray[1][0];
			}
			initialIcon.classList.add('user-profile-initial-icon');
			initialIcon.innerHTML = initialName;
			initialIcon.style.width = '32px';
			initialIcon.style.height = '32px';
			initialIcon.style.alignItems = "center";
			initialIcon.style.justifyContent = "center";
			initialIcon.style.display = "inline-flex";
			initialIcon.style.backgroundColor = this.stringToHslColor(initialName, 75, 62);
			initialIcon.style.borderRadius = "500px";
			initialIcon.style.color = "#ffffff";
			initialIcon.style.fontWeight = "bold";
			initialIcon.style.fontSize = "12px";
			initialIcon.style.border = "2px solid " + this.stringToHslColor(initialName, 75, 62);
			parent.insertBefore(initialIcon, this.elementRef.nativeElement.nextSibling);
		}
	}

	public getUserAvatarPath() {
		let imagePath = this.defaultImg;
		if (this.username)
			imagePath = environment.web.avatarUrl.replace('%USERNAME%', this.username) + '&_v=' + this.version;
		if (this.notifiedAvatar)
			imagePath = this.notifiedAvatar + "?alt=media&_v=" + this.version;
		return imagePath;
	}

}
