import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Subject, Observable } from "rxjs";
import { User, AuthService } from "./auth.service";
import { HelperService } from "./helper.service";


@Injectable({
  providedIn: 'root'
})
export class JitsiService {
  readonly isJitsiEnabled = false;

  constructor(private apiService: ApiService, private authService: AuthService) {

  }


  /**
   * Create a new room on jitsi
   */
  createRoom(): Observable<any> {

    let subject = new Subject<Comment>();
    this.apiService.httpPost('/api/jitsi/rooms', null)
      .subscribe((data: any) => {
        subject.next(data);
      });

    return subject.asObservable();
  }



  /**
   * Invite user to room
   * @param projectId
   * @param roomId
   * @param username
   */
  inviteUser(projectId: string, roomId: string, username: any): Observable<any> {

    let subject = new Subject<any>();

    let endpoint = `/api/jitsi/project/${projectId}/rooms/${roomId}/invite/${username}`;

    this.apiService.httpPost(endpoint, null)
      .subscribe((data: any) => {
        subject.next(undefined);
      });

    return subject.asObservable();
  }


  /**
   * Validate jitsi token
   * @param projectId
   * @param roomId
   * @param jitsiToken
   */
  validateJitsiToken(projectId: string, roomId: string, jitsiToken: any): Observable<any> {
    let endpoint = '/api/jitsi/rooms/' + roomId + '/tokens/' + jitsiToken;

    return this.apiService.httpGet(endpoint);
  }


  /**
   * Reject invitation
   * @param roomId
   * @param username
   */
  rejectInvitation(projectId: string, roomId: string, username: string): Observable<any> {
    let endpoint = `/api/jitsi/project/${projectId}/rooms/${roomId}/reject/$s{username}`;

    return this.apiService.httpPost(endpoint, null);
  }

  onLeftCall(params: any): Observable<any> {
    return this.apiService.httpPost(`/api/jitsi/callFinish`, params);
  }

}
