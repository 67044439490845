<ng-template #htmlContent>
	<div [style.minWidth]="'175px'" class="p-1">
		<p class="mt-1 mb-1 text-info">
			Supported Files
		</p>
		<table class="w-100">
			<tr *ngFor="let item of accept" class="text-left">
				<td>{{ item }}</td>
			</tr>
		</table>
		<div class="text-left" *ngIf="isImageFilter">{{'fileSizeLimitExceed' | translate:{ LIMIT_SIZE: (maxSizeMB || 1)}
			}}</div>
	</div>
</ng-template>
<ngx-file-drop #container ng2FileDrop [multiple]="true" dropZoneClassName="drop-zone"
	[ngClass]="{ 'compact-style': compactStyle }" [disableTooltip]="disableTooltip"
	(onFileOver)="ng2FileDropHoverStart($event)" (onFileLeave)="ng2FileDropHoverEnd($event)"
	(onFileDrop)="dragFilesDropped($event)" href="#"
	class="upload-link text-center d-flex flex-column justify-content-center align-items-center a-min-height h-100 position-relative"
	[class.on-drag-file]="isDragFile" [class.rounded-circle]="isRoundedCircle" [class.drag-border-color]="isDragFile"
	[class.p-0]="container.offsetHeight < 115" (click)="upload()" data-toggle="tooltip" data-placement="top"
	[ngbTooltip]="htmlContent" [style.cursor]="disabled ? 'not-allowed' : 'pointer'">
	<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
		<div *ngIf="!compactStyle" class="d-block"[ngClass]="isProjectV2Brand ? 'd-flex justify-content-center align-items-center' : 'd-block'">
			<i *ngIf="!isDragFile && !(imageURL && imageURL != '') && !changeStyle && !isProjectV2 && !isProjectV2Brand"
				class="fa fa-upload fa-3x grey" aria-hidden="true"></i>
			<img *ngIf="!isDragFile && !(imageURL && imageURL != '') && changeStyle && !isProjectV2 && !isProjectV2Brand" class="mt-3"
				src=".../../../../../../assets/img/selection-grid-edit/browse-file.svg" alt="">
			<img *ngIf="isProjectV2 && !imageURL" class="mt-3"
				src=".../../../../../../assets/img/selection-grid-edit/browse-file-black.svg" alt="">
				<img *ngIf="isProjectV2Brand && !imageURL" width="20px" class="m-0"
				src=".../../../../../../assets/img/selection-grid-edit/browse-file-black.svg" alt="">
			<i *ngIf="isDragFile && !(imageURL && imageURL != '') && !isProjectV2 && !isProjectV2Brand"
				class="fa fa-plus-square fa-3x text-success" aria-hidden="true"></i>
			<h6 *ngIf="!(imageURL && imageURL != '') && changeStyle" class="file-drag-title mt-1">Drag’n Drop single or
				multiple Assets</h6>
			<p *ngIf="isProjectV2Brand && !imageURL" class="ml-3 mb-0 _700 project-v2-file-drag-title">{{ buttonTitle | translate}}</p>

			<h6 *ngIf="isProjectV2 && !imageURL" class="_700 project-v2-file-drag-title mt-1">{{'Illustration' | translate}},</h6>
			<p *ngIf="isProjectV2 && !imageURL" class="_700 project-v2-file-drag-title">{{'logoOrPicture' | translate}}</p>
			<p *ngIf="!(imageURL && imageURL != '') && changeStyle" class="file-drag-type-title ">{{'allCommonFileFormats' | translate}}</p>
			<h6 *ngIf="!(imageURL && imageURL != '') && !changeStyle && !isProjectV2 && !isProjectV2Brand" class="text-uppercase mt-4 grey"
				[innerHtml]="inputText ? inputText : ('dragAndDropSelectFilesText' | translate)"></h6>
			<p *ngIf="!(imageURL && imageURL != '') && !changeStyle && !isProjectV2 && !isProjectV2Brand"
				class="text-uppercase font-weight-bold grey"
				[innerHtml]="secondaryText ? secondaryText : ('dragAndDropOrDragText' | translate)"></p>
			<img *ngIf="!!imageURL" [hidden]="isRedirectImageLoadError && isImageLoadError"
				class="area-background-image" [src]="imageURL" (error)="onImageLoadError($event)"
				[class.drag-border-color]="isDragFile">
		</div>
		<div *ngIf="compactStyle" class="d-block">
			<div class="d-flex align-items-center">
				<div class="mr-3">
					<i *ngIf="!isDragFile && !(imageURL && imageURL != '')" class="fa fa-upload fa-3x"
						aria-hidden="true"></i>
					<i *ngIf="isDragFile && !(imageURL && imageURL != '')" class="fa fa-plus-square fa-3x text-success"
						aria-hidden="true"></i>
				</div>
				<div class="d-flex flex-column">
					<h5 *ngIf="!(imageURL && imageURL != '')" class="text-left text-uppercase mt-3"
						[innerHtml]="inputText ? inputText : ('dragAndDropSelectFilesText' | translate)"></h5>
					<p *ngIf="!(imageURL && imageURL != '')" class="ttext-left text-uppercase font-weight-bold"
						[innerHtml]="secondaryText ? secondaryText : ('dragAndDropOrDragText' | translate)"></p>
				</div>
			</div>
			<img *ngIf="!!imageURL" [hidden]="isRedirectImageLoadError && isImageLoadError"
				class="area-background-image" [src]="imageURL" (error)="onImageLoadError($event)"
				[class.drag-border-color]="isDragFile">
		</div>
	</ng-template>
</ngx-file-drop>
<span _ngcontent-oej-c231="" class="que-button">
	<img *ngIf="isProjectV2" class="mt-3" src=".../../../../../../assets/img/selection-grid-edit/questionmarkicon.svg"
		alt="">
</span>
<input #fileInput class="input-file" type="file" multiple (change)="fileChangeListener($event)" [disabled]="disabled">