export interface StringPair {
	label: string;
	value: string;
}

export const AngleTypes: StringPair[] = [
	{ label: 'alternateAngle', value: 'ALTERNATE_ANGLE' },
	{ label: 'closeUp', value: 'CLOSE_UP' },
	{ label: 'counterPlunging', value: 'COUNTER_PLUNGING' },
	{ label: 'lateral', value: 'LATERAL' },
	{ label: 'paronamicShot', value: 'PANORAMIC_SHOT' },
	{ label: 'plunging', value: 'PLUNGING' },
	{ label: 'regularShot', value: 'REGULAR_SHOT' },
	{ label: 'reverseAngle', value: 'REVERSE_ANGLE' },
	{ label: 'trackingShotBackward', value: 'TRACKING_SHOT_BACKWARD' },
	{ label: 'trackingShotForward', value: 'TRACKING_SHOT_FORWARD' }
];


