<ng-template ngbModalContainer></ng-template>
<div class="container mt-5">
    <div class="col">
        <div class="row">
            <img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
        </div>

        <!-- Fill form -->
        <div class="text-center justify-content-center" *ngIf="!success">
            <h3>Thank you for your interest in Master the Monster.</h3>
            <h4>
                MTM is only accessible by invitation.
            </h4>
        </div>

        <div class="container-fluid mt-5">
            <div class="row justify-content-center">
                <button class="btn btn-lg btn-danger btn-signup" (click)="onOpenSignupModal()">Request
                    Invitation</button>
            </div>
        </div>

        <!-- div class="row justify-content-center mt-2" *ngIf="!success">
            <div class="card px-5 py-5 mt-4">

                <form class="signup-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                    <div class="container">
                        <div class="row">
                            <div class="col mx-2">
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.firstName.invalid && submitted}">
                                    <input class="form-control" type="text" formControlName="firstName" placeholder=" "
                                        [ngClass]="{'form-control-danger': signupForm.controls.firstName.invalid && submitted}">
                                    <span *ngIf="!(submitted && formErrors.firstName)">First name</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.firstName">{{formErrors.firstName}}</span>
                                </label>
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.lastName.invalid && submitted}">
                                    <input class="form-control" type="text" formControlName="lastName" placeholder=" "
                                        [ngClass]="{'form-control-danger': signupForm.controls.lastName.invalid && submitted}">
                                    <span *ngIf="!(submitted && formErrors.lastName)">Last name</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.lastName">{{formErrors.lastName}}</span>
                                </label>
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.company.invalid && submitted}">
                                    <input class="form-control" type="text" formControlName="company" placeholder=" "
                                        [ngClass]="{'form-control-danger': signupForm.controls.company.invalid && submitted}">
                                    <span *ngIf="!(submitted && formErrors.company)">Company name</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.company">{{formErrors.company}}</span>
                                </label>
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.email.invalid && submitted}">
                                    <input class="form-control" type="text" formControlName="email" placeholder=" "
                                        [ngClass]="{'form-control-danger': signupForm.controls.email.invalid && submitted}">
                                    <span *ngIf="!(submitted && formErrors.email)">Email address</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.email">{{formErrors.email}}</span>
                                </label>
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.phone.invalid && submitted}">
                                    <input class="form-control" type="text" formControlName="phone" placeholder=" "
                                        [ngClass]="{'form-control-danger': signupForm.controls.phone.invalid && submitted}">
                                    <span *ngIf="!(submitted && formErrors.phone)">Phone number</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.phone">{{formErrors.phone}}</span>
                                </label>
                            </div>
                            <div class="col mx-2">
                                <label class="form-group row has-float-label"
                                    [ngClass]="{'has-danger': signupForm.controls.message.invalid && submitted}">
                                    <textarea class="form-control" placeholder=" " formControlName="message" rows="10"></textarea>
                                    <span *ngIf="!(submitted && formErrors.message)">Message</span>
                                    <span class="text-danger"
                                        *ngIf="submitted && formErrors.message">{{formErrors.message}}</span>
                                </label>

                                <div class="row">
                                    <button type="submit" class="btn btn-lg btn-green btn-block">
                                        Sign up to discover MTM
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div -->
        <!-- / Fill form -->


        <!-- Form success -->
        <div class="row text-center justify-content-center" *ngIf="success">
            <h3>
                Thank you, {{signupForm.controls['firstName'].value}}.<br />We have received your request, we will get
                back to you shortly.
            </h3>
        </div>
        <!-- / Form success -->

    </div>
</div>