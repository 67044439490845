<!-- completion message modal -->
<div  *ngIf="getPermission()">
  <div *ngIf="this.stService.showCompletionMessage" class="welcome-message-parent">
    <div class="welcome-message shadow text-center">
      <h2 class="font-weight-bold">Great Job!</h2>
      <div class="my-4 w-100 d-flex align-items-center justify-content-center">
        <img width="60px" height="60px" src="../../../assets/img/thumb.png" alt="thumbs up">
      </div>
      <h4 *ngIf="!this.stService.allMissionsCommpleted" class="my-4">
        {{this.stService.activeMission?.completionMessage}}
      </h4>
      <h4 *ngIf="this.stService.allMissionsCommpleted" class="my-4">
        You have successfully completed all the missions. You can start again for more practice.
      </h4>
      <button *ngIf="!this.stService.allMissionsCommpleted" class="btn btn-danger btn-lg btn-block"
        (click)="stService.handleCompletionModalNextBtn()">NEXT
      </button>
      <button *ngIf="this.stService.allMissionsCommpleted" class="btn btn-danger btn-lg btn-block"
        (click)="stService.handleStartAgainBtn()">START AGAIN
      </button>
    </div>
  </div>

  <!-- self trainer get started div -->
  <div *ngIf="!this.stService.startSelfTrainer" class="welcome-message-parent">
    <div class="welcome-message shadow text-center">
      <h1>Hi {{authService.getAuthUser().firstName}}!</h1>
      <h2>Welcome to Self Trainer</h2>
      <p>Start your self-training journey with our step-by-step guidance and resources to help you achieve your learning
        goals effectively.</p>
      <button class="btn btn-danger btn-lg" (click)="stService.startSelfTrainerMission()">GET STARTED</button>
    </div>
  </div>

  <!-- spinner loader -->
  <div *ngIf="this.stService.isLoading && !this.stService.showCompletionMessage" class="welcome-message-parent">
    <div class="spinner-border text-danger spinner-loader" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div style="z-index: 99999" class="p-3">
      <p class="text-white mb-0">Please wait...</p>
    </div>
  </div>

  <!-- spinner, get started and completion message modal overlay -->
  <div *ngIf="!this.stService.startSelfTrainer || this.stService.isLoading || this.stService.showCompletionMessage"
    class="get-started-overlay">
  </div>

  <!-- red danger overlay to show when user click's on wrong element -->
  <div *ngIf="stService.showDangerOverlay" class="danger-overlay"></div>

  <!-- self trainer custom modal and overlay start-->
  <div *ngIf="stService.showSelfTrainerModal && !stService.isMissionPaused && !stService.isLoading">
    <mtm-self-trainer-modal [stepData]="stService.selfTrainerStepData"></mtm-self-trainer-modal>
  </div>
  <div *ngIf="stService.startSelfTrainer && !stService.isUserPerformingTask && !stService.isMissionPaused"
    id="main-overlay" [ngStyle]="{position: this.stService.mainOverlayPosition}" [ngClass]="getMainOverlayBgClass()">
  </div>
  <!-- end -->

  <div class="footer-progress" *ngIf="stService.startSelfTrainer">
    <div class="row">
      <div class="col position-relative" [style.left]="'100px'">
        <!-- topic detail -->
        <div class="row">
          <div class="circle-div">
            <img width="112px" height="112px" src="../../../assets/img/user-modal-img.png" alt="">
          </div>
          <!-- stService.activeMission -->
          <div class="mt-3 title-subtitle-sec" *ngIf="!stService.isMissionPaused">
            <div class="col"><span class="c-mtm-full-white f-20 _700"> {{ stService.activeMission?.exerciseTitle
                }}</span>
            </div>
            <div class="col mt-2"><span class="c-mtm-full-white f-16 _400"
                [style.line-height]="'0'">{{stService.getHelpText()}}</span></div>
          </div>
          <!-- !stService.activeMission -->
          <div class="mt-3 title-subtitle-sec" *ngIf="stService.isMissionPaused">
            <div class="col"><span class="c-mtm-full-white f-20 _700">Click on next button</span>
            </div>
            <div class="col mt-2"><span class="c-mtm-full-white f-16 _400" [style.line-height]="'0'">Please click on
                next button when ever you done performing the task.</span></div>
          </div>
        </div>
      </div>
      <div class="col" [ngClass]="{'has-right-pane': themeOptions?.rightPane}">
        <!-- progress bar -->
        <div class="row justify-content-end">
          <div class="mr-5 mt-3 progress-container d-flex">
            <ngb-progressbar type="success" [value]="(stService.scoredPoints / stService.totalPoints) * 100"
              class="w-100">
              <div class="w-100 ml-3 d-flex justify-content-start align-items-center" [style.gap]="'5px'">
                <span><i class="fa fa-star c-mtm-full-black" aria-hidden="true"></i></span>
                <span class="f-10 _600 c-mtm-full-black"> {{stService.scoredPoints}} pts <span class="f-10 _600"
                    [style.color]="'#685613'" [style.opacity]="'0.8'"> / {{stService.totalPoints}} pts </span> </span>
              </div>
            </ngb-progressbar>
          </div>
        </div>
        <!-- buttons -->
        <div class="row mt-2 mr-4 row justify-content-end">
          <!-- play pause button -->
          <div class="d-flex" *ngIf="stService.isMissionPaused">
            <button type="button" class="tutorial-lib-btn c-mtm-full-white mr-2 text-uppercase f-14 _700"
              (click)="playPausebtn()">Next</button>
          </div>

          <div *ngIf="!stService.isMissionPaused">
            <button type="button" class="tutorial-lib-btn c-mtm-full-white mr-2 text-uppercase f-14 _700">TUTORIALS
              LIBRARY</button>
          </div>

          <div class="d-flex">
            <button type="submit" class="save-btn c-mtm-full-black mr-2 text-uppercase f-14 _700">SAVE & QUIT</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>