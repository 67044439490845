<div class="container">
  <div class="close" (click)="dismissModal()">
    <i class="fa fa-window-close" aria-hidden="true"></i>
  </div>
  <div class="row justify-content-center title">
    SUBSCRIPTION
  </div>
  <div class="row justify-content-center description">
    {{prompt}}
  </div>
  <div class="row ">
    <div class="col-2"></div>
    <div class="col-8 frame">
      <div class="row justify-content-center title">
        {{subtitle}}

      </div>
      <div class="row justify-content-center">
        <ul>
          <li>{{subscriptionPlan.users}} Users</li>
          <li>{{subscriptionPlan.projects}} Projects</li>
          <li>{{subscriptionPlan.guests}} Guests</li>
          <li>{{subscriptionPlan.storage}} GB Storage</li>
          <li>{{subscriptionPlan.duration}} Months</li>

        </ul>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row justify-content-center discount-prompt" *ngIf="additionalDiscount">
    Additional Discount Applied: 15%
  </div>
  <div class="row justify-content-center discount-prompt" *ngIf="additionalDiscount">
    Additional Discount: ZZ€
  </div>
  <div class="row justify-content-center discount-prompt" *ngIf="additionalDiscount">
    You are saving xxx€ as discount on your advance payment
  </div>
  <div class="row justify-content-center total-be-paid">
    {{toBePaidPrompt}}: {{totalToBePaid}}€
  </div>
  <div class="row justify-content-center">
    <label class="checkbox-container">
      <input type="checkbox" [(ngModel)]="istermsAgreed" />
      I agree to the terms & conditions
      <span class="checkmark"></span>
    </label>

  </div>
  <div class="row justify-content-center">
    <button class="btn" (click)="navigateLoginPage()">I SUBSCRIBE</button>
  </div>

</div>