<div class="card">
	<div class="card-block">
		<div class="row">
			<div class="col">
				<p class="text-black font-weight-bold m-0">{{'scheduleSchedule' | translate}}</p>
				<p class="card-text">{{'scheduleScheduleSubTitle' | translate}}</p>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="project-dates">
					<div class="start-date-block"
						[class.project-date-invalid]="isValidProjectDates(date.startDate, true)">
						<div class="date-label">
							<span>{{'startDate' | translate}}</span>
							<h4>{{ getDateLabel(date.startDate) }}</h4>
						</div>
						<div class="change-button" [ngClass]="{'div-disabled': proposalAccepted}">
							<button class="btn btn-default"
								(click)="d.toggle(); d2.close(); d3.close();$event.stopPropagation();"
								[mtmDatePickerDirective]=d>
								<i class="fa fa-calendar fa-fw" aria-hidden="true"></i>
								<span class="text-uppercase">{{'changeDate' | translate}}</span>
							</button>
							<input class="form-control" placeholder="yyyy-mm-dd" type="hidden" [maxDate]="date.airDate"
								[(ngModel)]="date.startDate" (ngModelChange)="onChangedProjectDate()" ngbDatepicker
								#d="ngbDatepicker">
						</div>
					</div>
					<div class="end-date-block" [class.project-date-invalid]="isValidProjectDates(date.dueDate)">
						<div class="date-label">
							<span>{{'dueDate' | translate}}</span>
							<h4>{{ getDateLabel(date.dueDate) }}</h4>
						</div>
						<div class="change-button" [ngClass]="{'div-disabled': proposalAccepted}">
							<button class="btn btn-default" (click)="d2.toggle(); d.close(); d3.close()"
								[mtmDatePickerDirective]=d2>
								<i class="fa fa-calendar fa-fw" aria-hidden="true"></i>
								<span class="text-uppercase"> {{'changeDate' | translate}}</span>
							</button>
							<input class="form-control" placeholder="yyyy-mm-dd" type="hidden" [maxDate]="date.airDate"
								[(ngModel)]="date.dueDate" (ngModelChange)="onChangedProjectDate()" ngbDatepicker
								#d2="ngbDatepicker">
						</div>
					</div>
					<div class="air-date-block" [class.project-date-invalid]="isValidProjectDates(date.airDate)">
						<div class="date-label">
							<span>{{'airDate' | translate}}</span>
							<h4>{{ getDateLabel(date.airDate) }}</h4>
						</div>
						<div class="change-button" [ngClass]="{'div-disabled': proposalAccepted}">
							<button class="btn btn-default" (click)="d3.toggle(); d.close(); d2.close()"
								[mtmDatePickerDirective]=d3>
								<i class="fa fa-calendar fa-fw" aria-hidden="true"></i>
								<span class="text-uppercase"> {{'changeDate' | translate}}</span>
							</button>
							<input class="form-control" placeholder="yyyy-mm-dd" type="hidden"
								[minDate]="date.startDate" [(ngModel)]="date.airDate"
								(ngModelChange)="onChangedProjectDate()" ngbDatepicker #d3="ngbDatepicker">
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col">
				<p class="text-black font-weight-bold m-0">{{'scheduleWhatPosition' | translate}}</p>
				<p class="card-text">{{'scheduleWhatPositionSubtitle' | translate}}</p>
				<div class="row my-3">
					<div class="col">
						<div class="position h-100"
							[ngClass]="{'position-active': budgetMenu, 'div-disabled': proposalAccepted}"
							(click)="selectedBudgetMenu(true)">
							<h6 class="font-weight-bold">{{'scheduleIKnowBudget' | translate}}</h6>
							<p class="text-muted">{{'scheduleIKnowBudgetSubtitle' | translate}}</p>
						</div>
					</div>
					<div class="col">
						<div class="position h-100"
							[ngClass]="{'position-active': !budgetMenu, 'div-disabled': proposalAccepted}"
							(click)="selectedBudgetMenu(false)">
							<h6 class="font-weight-bold">{{'scheduleIDontKnowBudget' | translate}}</h6>
							<p class="text-muted">{{'scheduleIDontKnowBudgetSubtitle' | translate}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div class="col" *ngIf="!budgetMenu" [class.budget-invalid]="isValidBudget()"
			[ngClass]="{'div-disabled': proposalAccepted}">
			<div class="d-inline-flex">
				<div class="d-inline-block">
					<h6 class="text-black font-weight-bold m-0">{{'scheduleBudget' | translate}}</h6>
					<p [style.max-width]="'472px'" class="card-text">{{'scheduleBudgetChooseSubtitle' | translate}}</p>
				</div>
				<div class="d-flex flex-column ml-3">
					<p class="text-black font-weight-bold ml-2 mb-0">{{'currency' | translate}}</p>
					<select class="dropdown-toggle ml-2" [(ngModel)]="projectService.project.currency"
						(ngModelChange)="onCurrencyChange($event)">
						<option value="{{ currency?.id }}" *ngFor="let currency of currencies">
							{{currency.label}}
						</option>
					</select>
				</div>
			</div>
			<p class="cart-text options text-center mt-2">
				<span *ngFor="let p of budgetRange" class="badge badge-option"
					[class.badge-option-selected]="p.selected" (click)="toggleOption(p,false)">{{ "budgetRange" +
					p.title + "_" + p.currency | translate}}</span>
			</p>
		</div>
		<div class="col" *ngIf="budgetMenu" [class.budget-invalid]="isValidBudget()"
			[ngClass]="{'div-disabled': proposalAccepted}">
			<div class="d-flex">
				<div class="d-flex flex-column">
					<div class="d-inline-block">
						<h6 class="text-black font-weight-bold m-0">{{'scheduleBudget' | translate}}</h6>
						<p class="card-text m-0">{{'scheduleBudgetSubtitle' | translate}}</p>
					</div>
					<p class="cart-text options m-0">
						<span class="badge badge-option p-0 w-100">
							<input class="budget-input form-control p-2" (focus)="onAmountFocused($event)"
								(blur)="onAmountBlur($event)" [disabled]="proposalAccepted" #plannedBudgetElement
								type="{{currencyFocused ? 'number'  : 'text'}}"
								placeholder="{{'scheduleEnterAmount' | translate}}" [(ngModel)]="knowbudget"
								(ngModelChange)="toggleOption(null,true)">
						</span>
					</p>
				</div>
				<div class="d-flex flex-column ml-3">
					<p class="text-black font-weight-bold ml-2" [style.margin-bottom]="'22px'">Currency</p>
					<select class="dropdown-toggle ml-2" [(ngModel)]="projectService.project.currency"
						(ngModelChange)="onCurrencyChange($event)">
						<option value="{{ currency?.id }}" *ngFor="let currency of currencies">
							{{currency.label}}
						</option>
					</select>
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col">
				<p class="text-black font-weight-bold m-0">{{'scheduleProductionCompanyBased' | translate}}</p>
				<br>
				<div class="row mt-2">
					<div class="col">
						<div class="position" [ngClass]="{'position-active': selectedLocatedType == 'GLOBAL'}"
							(click)="onSelectLocated(null,'GLOBAL')" style="height:100%">
							<h6 class="font-weight-bold">{{'scheduleGlobal' | translate}}</h6>
							<p class="text-muted">{{'scheduleGlobalSubtitle' | translate}}</p>
						</div>
					</div>
					<div class="col">
						<div class="position" [ngClass]="{'position-active': selectedLocatedType == 'LOCAL'}">
							<h6 class="font-weight-bold">{{'scheduleLocal' | translate}}</h6>
							<p class="text-muted">{{'scheduleLocalSubtitle' | translate}}</p>
							<select class="form-control dropdown-toggle" [(ngModel)]="selectedLocated"
								(ngModelChange)="onSelectLocated($event, 'LOCAL')"
								[ngModelOptions]="{standalone: true}">
								<option value="null" [disabled]="true">{{'scheduleSelectCountry' | translate}}</option>
								<option [value]="item.id" *ngFor="let item of timezones">{{item.name}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col">
				<p class="text-black font-weight-bold m-0">{{'scheduleConsultant' | translate}}</p>
				<p class="card-text">{{'scheduleConsultantSubtitle' | translate}}</p>
				<div class="row">
					<div class="col-6 pt-3" *ngFor="let c of consultantOptions">
						<div class="badge-option text-center" [class.badge-option-selected]="c.selected"
							(click)="consultantButtonOption(c)">{{c.label | translate}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<p class="text-black font-weight-bold m-0">{{'scheduleLanguages' | translate}}</p>
		<p>{{'scheduleLanguagesSubtitle' | translate}}</p>
		<br>
		<div class="row">
			<div class="col">
				<p class="font-weight-bold mb-0">{{'selectMainLanguage' | translate}}</p>
				<mtm-dropdown [title]="'Select Languages'" [emptyListInfoText]="''"
					[dotItemSelectedText]="'main language selected'" [dotItemsSelectedText]="'main languages selected'"
					[items]=languages [itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'"
					[isMultipleSelect]=true [refObject]=this [objectVariableAccessor]="'langSelected'">
				</mtm-dropdown>
			</div>
			<div class="col">
				<p class="font-weight-bold mb-0">{{'selectSubtitleLanguage' | translate}}</p>
				<mtm-dropdown [title]="'Subtitle Languages'" [emptyListInfoText]="''"
					[dotItemSelectedText]="'subtitle language selected'"
					[dotItemsSelectedText]="'subtitle languages selected'" [items]=languages
					[itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'" [isMultipleSelect]=true
					[refObject]=this [objectVariableAccessor]="'subTitleLanguagesSelected'">
				</mtm-dropdown>
			</div>
			<div class="col">
				<p class="font-weight-bold mb-0">{{'selectDubbingLanguage' | translate}}</p>
				<mtm-dropdown [title]="'Dubbing Languages'" [emptyListInfoText]="''"
					[dotItemSelectedText]="'dubbing language selected'"
					[dotItemsSelectedText]="'dubbing languages selected'" [items]=languages
					[itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'" [isMultipleSelect]=true
					[refObject]=this [objectVariableAccessor]="'dubbingLanguagesSelected'">
				</mtm-dropdown>
			</div>
		</div>
		<hr>
		<p class="text-black font-weight-bold m-0">{{'scheduleVideoLength' | translate}}</p>
		<div class="row mt-2">
			<div class="col">
				<p class="font-weight-bold mb-0">{{'hours' | translate}}</p>
				<label class="form-group has-float-label form-group-video-length">
					<input class="form-control control-background-color" [(ngModel)]="videoLength.hour" type="number"
						placeholder="hh">
				</label>
			</div>
			<div class="col">
				<p class="font-weight-bold mb-0">{{'minutes' | translate}}</p>
				<label class="form-group has-float-label form-group-video-length">
					<input class="form-control control-background-color" [(ngModel)]="videoLength.minutes" type="number"
						placeholder="mm">
				</label>
			</div>
			<div class="col">
				<p class="font-weight-bold mb-0">{{'seconds' | translate}}</p>
				<label class="form-group has-float-label form-group-video-length">
					<input class="form-control control-background-color" [(ngModel)]="videoLength.seconds" type="number"
						placeholder="ss">
				</label>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="onSubmit()" [disabled]="sending">Continue
				</button>
			</div>
		</div>
	</div>
</div>