import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'mtmFileSize'
})
export class MtmFileSizePipe implements PipeTransform {

    //taken from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
    transform(value: number, fractionDigits: number = 2): string {
      let u = 0, s=1024;
      while (value >= s || -value >= s) {
        value /= s;
        u++;
      }
      return (u ? value.toFixed(fractionDigits) + ' ' : value) + ' KMGTPEZY'[u] + 'B';
    }
}
