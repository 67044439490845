import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UnseenTotal, WorkspaceCoverUpdatedEvent } from 'app/shared/interfaces';
import { AuthService } from 'app/shared/services/auth.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { UnseenNotifierService } from 'app/shared/services/unseen-notifier.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'mtm-usertourdashboard',
  templateUrl: './usertourdashboard.component.html',
  styleUrls: ['./usertourdashboard.component.scss']
})
export class UsertourdashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
  private ngUnsubscribe = new Subject();
	user: any = {};
  activeGamification: boolean = null;
	availableTabs: any[] = [];
	subs = new Subscription();
	unseenWsSubscription: Subscription;
	additionalData: Record<string, any> = {};
  isUltimateCertificatesToggles:boolean = false;
  isAllDivison: boolean = false;
	selectedTab: string = '';

  medals = {
    gold: "../../../assets/img/medals/gold.svg",
    silver: "../../../assets/img/medals/silver.svg",
    bronze: "../../../assets/img/medals/bronze.svg",
    none: "../../../assets/img/medals/none.svg",
  };

  circularProgressBar = {
    "speed": 30,
    "percent": 75,
    "colorSlice": "#DD2C00",
    "colorCircle": "#f1f1f1",
    "round": true
  };

  achievements = [
    {
      title: "Digital Explorer",
      description: "Ability to navigate anywhere.",
      points: "1000",
      medal: "gold",
    },
    {
      title: "CUSTOMIZATION ENGINEER",
      description: "Tailoring and edit all parameters.",
      points: "800",
      medal: "silver",
    },
    {
      title: "Collaborative Navigator",
      description: "Forging Connections and Invitations",
      points: "600",
      medal: "bronze",
    },
    {
      title: "AI ARTISAN",
      description: "Sculpting the Future with Intelligence",
      points: "0",
      medal: "none",
    },
  ];
  mydivisionachievements = [
    {
      name: "Ethan Carter",
      role:" Brand Strategy Director",
      company: "c&c.",
      points: "1000",
      medal: "gold",
    },
    {
      name: "John Macdrive ",
      role:"John Macdrive",
      company: "c&c.",
      points: "1000",
      medal: "gold",
    },
    {
      name: "Sophia Turner",
      role:" Chief Experience Officer",
      company: "c&c.",
      points: "1000",
      medal: "gold",
    },
    {
      name: "Jackson Hayes",
      role:"Luxury Marketing Manager",
      company: "c&c.",
      points: "1000",
      medal: "gold",
    },
  ];
selectedMissionExercice = [];
ultimatemtmcertification =[
  {
    title: "ULTIMATE MTM CERTFICATION",
    description: "Able to accomplish everything",
    completionPercentage: "70",
    points: "800",
    missionExercise : [
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 1',
        points:10,
        bonusPoints: 5,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 2',
        points:10,
        bonusPoints: null,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 3',
        points:10,
        bonusPoints: null,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 4',
        points:10,
        bonusPoints: 10,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 5',
        points:10,
        bonusPoints: 5,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 6',
        points:10,
        bonusPoints: null,
      },
      {
        topic: 'TOPIC’s TITLE',
        excercice: 'Exercice 7',
        points:10,
        bonusPoints: null,
      }
    ]
  }
]

  leaderboardData = [
    {
      name: "Ethan Carter",
      avatar: "../../../assets/img/male-model.png",
      role: "Brand Strategy Director",
      department: "C&C",
      points: "6600"
    }
  ];

  constructor(
    private themeService: ThemeService,
    public stService: SelfTrainerService,
    public auth: AuthService,
    private unseenNotifier: UnseenNotifierService
  ) { }

  ngOnInit(): void {
    this.user = this.auth.getAuthUser();
    this.activeGamification = true;
    let circularProgress: any = document.querySelector(".circular-progress"),
      progressValue = document.querySelector(".progress-value");

    let progressStartValue = 0,
      progressEndValue = 90,
      speed = 100;

    // let progress = setInterval(() => {
    //   progressStartValue++;
    //   progressValue.textContent = `${progressStartValue}%`
    //   circularProgress.style.background = `conic-gradient(#7d2ae8 ${progressStartValue * 3.6}deg, #ededed 0deg)`
    //   if (progressStartValue == progressEndValue) {
    //     clearInterval(progress);
    //   }
    // }, speed);
    this.initializeTabs();
  }

  ngAfterViewInit(): void {
    this.themeService.updateContent({
      backgroundColor: '',
      rightPane: null,
      backgroundImage: "../../../assets/img/footer-tour-img.png",
      header: this.headerContent,
      fullWidthContent: true
    });
    EmitterService.get(WorkspaceCoverUpdatedEvent).emit("../../../assets/img/footer-tour-img.png");
  }

  ngOnDestroy() {
    if (this.unseenWsSubscription) {
      this.unseenWsSubscription.unsubscribe();
    }
    this.subs.unsubscribe();
    this.ngUnsubscribe.next(true);;
    this.ngUnsubscribe.complete();
    this.themeService.updateContent({
      backgroundColor: '',
      rightPane: null,
      backgroundImage: null,
      header: null,
      fullWidthContent: false
    });
  }

  getMedalImage(value: string) {
    return this.medals[value];
  }

  private initializeTabs() {
		const companyType = this.user.company.companyType;
		this.availableTabs = [];
    this.availableTabs.push(
      {
        label: 'PLAY NOW',
        value: 'play',
      },
      {
        label: 'REPLAY',
        value: 'replay',
      },
      {
        label: 'TUTORIALs LIBRARY',
        value: 'tutorial',
      },
    );
		}
  
  //   private initWSSubscription() {
  //     this.unseenWsSubscription = this.unseenNotifier.subscribe(data => {
  //       this.updateUnseenData(data);
  //     });
  //   }
  //   //update the unseen models with data from either web socket / web service
	// updateUnseenData(data: Record<string, UnseenTotal>) {
	// 	if (!data)
	// 		return;
	// 	for (let key in data) {
	// 		if (!(key in this.additionalData)) {
	// 			this.additionalData[key] = {};
	// 		}
	// 		Object.assign(this.additionalData[key], data[key]);
	// 		delete this.additionalData[key].notifications;
	// 	}
	// }

  toggleultimateCertificate(ultcertificate){
    this.selectedMissionExercice = [];
    this.selectedMissionExercice.push(...ultcertificate.missionExercise)
    console.log('this.selectedMissionExercice', this.selectedMissionExercice)
    this.isUltimateCertificatesToggles = !this.isUltimateCertificatesToggles;
    console.log('this.isUltimateCertificatesToggles', this.isUltimateCertificatesToggles)
  }
  

	toggleTab(value: string) {
		this.selectedTab = value;
	}
}
