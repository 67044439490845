<!-- Header for video call -->
<div class="modal-header" *ngIf="createType == 'video'">
	<h6 class="modal-title" *ngIf="conversation && (conversation.type != 'ONE_TO_ONE')">Invite People</h6>
	<h6 class="modal-title" *ngIf="conversation && (conversation.type == 'ONE_TO_ONE')">Create Group Call</h6>
	<h6 class="modal-title" *ngIf="!conversation">Create Group Call</h6>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<!-- / Header for video call -->
<!-- Header for group chat -->
<div class="modal-header pb-0" *ngIf="createType == 'chat'">
	<div class="d-flex align-items-center w-100">
		<h6 class="modal-title mr-auto p-0">Create Group Chat</h6>
		<button type="button" class="close ml-auto p-0 m-0" data-dismiss="modal" aria-label="Close"
			(click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
</div>
<!-- / Header for group chat -->
<div class="modal-body">
	<!-- Only allow enter name when creating a new group conversation -->
	<div class="row" *ngIf="!conversation || conversation.type == 'ONE_TO_ONE'">
		<div class="col name">
			<form [formGroup]="formGroup">
				<label class="form-group has-float-label"
					[ngClass]="{'has-danger': formGroup.controls.name.invalid && submitted}">
					<input class="form-control fc-name" type="text" formControlName="name" placeholder=" "
						[ngClass]="{'form-control-danger': formGroup.controls.name.invalid && submitted}"
						maxlength="36" />
					<span>Group Name</span>
					<span class="text-danger" *ngIf="formGroup.controls.name.invalid && submitted">
						Group name required
					</span>
				</label>
			</form>
		</div>
	</div>
	<!-- / Only allow enter name when creating a new group conversation -->
	<div class="row">
		<div class="col inviter-dropdown">
			<ng-template #rt let-user="result" let-t="term">
				<div class="d-flex align-items-center">
					<div class="mr-2">
						<mtm-user-profile-picture [user]="user" [username]="user.username" [height]="24" [width]="24"
							[forceLoadProfile]="true">
						</mtm-user-profile-picture>
					</div>
					<span [style.white-space]="'nowrap'">{{user.firstName}} {{user.lastName}}</span>
				</div>
			</ng-template>
			<mtm-inviter-dropdown class="flex-fill mr-1" [data]="users" [(value)]="invitedUseData"
				[componentId]="conversation?.id" [internalUsersOnly]="true" [forceLoadProfile]="true"
				(selectedInvitersChange)="selectedInvitersChange($event)" [iconSize]="24" [userInfo]="'nameOnly'"
				[inputPlaceholder]="'+ Add people by name or email address...'" [hideModelPreview]="true">
			</mtm-inviter-dropdown>
			<div *ngIf="invitedKeys.length" class="invited-users d-flex align-items-center flex-wrap">
				<div *ngFor="let key of invitedKeys"
					class="d-flex flex-column align-items-center justify-content-center p-3">
					<mtm-user-profile-picture [username]="key" [height]="32" [width]="32" [forceLoadProfile]="true">
					</mtm-user-profile-picture>
					<div class="d-flex align-items-center">
						<i *ngIf="!invitedUsers[key].avatarUrl" class="fa fa-envelope-o mr-1" aria-hidden="true"></i>
						<span>{{invitedUsers[key].fullName}}</span>
						<a *ngIf="invitedUsers[key].canRemove" href="#" class="text-danger"
							(click)="onRemoveInvitedUser(key); false">
							<i class="fa fa-close fa-lg fa-fw" aria-hidden="true"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="usersSelected">
		<div class="col" *ngFor="let user of usersSelected">
			<div class="media media-user">
				<mtm-user-profile-picture [user]="user" [username]="user.username" [height]="24" [width]="24"
					[forceLoadProfile]="true">
				</mtm-user-profile-picture>
				<div class="media-body align-self-center">
					<h6 class="media-heading">{{user.fullName}}</h6>
					<a href="#" class="text-danger" (click)="removeUser(user);false">
						<i class="fa fa-close fa-lg fa-fw" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="buttons d-flex">
		<!-- Buttons to create video call -->
		<div class="d-flex flex-fill" *ngIf="createType == 'video'">
			<button *ngIf="conversation && (conversation.type != 'ONE_TO_ONE')" [disabled]="disableBtn"
				class="btn btn-danger flex-fill" (click)="onInviteAndClose()">
				Invite
			</button>
			<button *ngIf="conversation && (conversation.type == 'ONE_TO_ONE')" [disabled]="disableBtn"
				class="btn btn-danger" (click)="onCreateGroupCallAndClose()">
				Create Group Call
			</button>
			<button *ngIf="!conversation" class="btn btn-danger flex-fill"
				[disabled]="disableBtn || invitedKeys.length == 0" (click)="onCreateGroupCallAndClose()">
				Create Group Call
			</button>
			<button class="btn btn-outline-secondary" (click)="onClose()">Close</button>
		</div>
		<!-- / Buttons to create video call -->
		<!-- Buttons to create group chat -->
		<div class="d-flex flex-fill" *ngIf="createType == 'chat'">
			<button [disabled]="disableBtn" class="btn btn-danger flex-fill" (click)="onCreateGroupCallAndClose()">
				Create Group Chat
			</button>
			<button class="btn btn-outline-secondary flex-fill" (click)="onClose()">
				Close
			</button>
		</div>
		<!-- / Buttons to create group chat -->
	</div>
</div>