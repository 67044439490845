<div class="permission-actions text-center">
    <div *ngIf="params.context.componentParent.reInviteStyleUserControl(params.data)==true" class="mt-1 mb-1">
        <a href="#" class="text-green" (click)="params.context.componentParent.reinviteUserModal(params.data);false">
            <i class="fa fa-refresh fa-fw" aria-hidden="true"></i>
            <span class="text-uppercase font-weight-bold">{{'reInvite' | translate}}</span>
        </a>
    </div>
    <div *ngIf="params.data.memberOf == 'PRODUCTION' && params.context.componentParent.isProductionSide"
        class="mt-1 mb-1">
        <a href="#" class="text-orange" (click)="params.context.componentParent.blockUserModel(params.data);false"
            *ngIf="params.data.username != params.context.componentParent.authUser.username">
            <i class="fa fa-ban fa-fw" aria-hidden="true"></i>
            <span class="text-uppercase font-weight-bold" *ngIf="!params.data.blocked">
                {{'block' | translate}}
            </span>
            <span class="text-uppercase font-weight-bold" *ngIf="params.data.blocked">{{'Unblock'|translate}}</span>
        </a>
    </div>
    <div *ngIf="!params.context.componentParent.isProductionSide" class="mt-1 mb-1">
        <a href="#" class="text-orange" [style.white-space]="'nowrap'"
            (click)="params.context.componentParent.blockUserModel(params.data);false"
            *ngIf="params.data.username != params.context.componentParent.username">
            <i class="fa fa-ban fa-fw" aria-hidden="true"></i>
            <span class="text-uppercase font-weight-bold" *ngIf="!params.data.blocked">
                {{'block' | translate}}
            </span>
            <span class="text-uppercase font-weight-bold" *ngIf="params.data.blocked">{{'Unblock'|translate}}</span>
        </a>
    </div>
    <div *ngIf="!params.context.componentParent.isProductionSide" class="mt-1 mb-1">
        <a href="#" class="text-danger" [style.white-space]="'nowrap'"
            (click)="params.context.componentParent.deleteUserModal(params.data);false"
            *ngIf="params.data.username != params.context.componentParent.authUser.username">
            <i class="fa fa-trash fa-fw" aria-hidden="true"></i>
            <span class="text-uppercase font-weight-bold">{{'delete' | translate}}</span>
        </a>
    </div>
</div>