export default class AudioVisualizer {

    audioContext: any;
    processFrame: any;
    analyser: any;
    frequencyData: any;
    stream: any;

    constructor(audioContext, processFrame, processError) {
        this.audioContext = audioContext;
        this.processFrame = processFrame;
        this.connectStream = this.connectStream.bind(this);
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(this.connectStream)
            .catch((error) => {
                if (processError) {
                    processError(error);
                }
            });
    }

    connectStream(stream) {
        this.stream = stream;
        this.analyser = this.audioContext.createAnalyser();
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.analyser.smoothingTimeConstant = 0.5;
        this.analyser.fftSize = 32;

        this.initRenderLoop();
    }

    initRenderLoop() {
        this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        this.processFrame = this.processFrame || (() => { });
        requestAnimationFrame(this.renderFrame.bind(this));
    }

    renderFrame() {
        this.analyser.getByteFrequencyData(this.frequencyData);
        this.processFrame(this.frequencyData);
        requestAnimationFrame(this.renderFrame.bind(this));
    }
    stop() {
        if (this.stream) {
            this.stream.getAudioTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }
}