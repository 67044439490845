import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import {
  SignedURL_FU_Base,
  WithSignedURLUploadInfo,
  WithUploadBaseDependencies
} from 'app/shared/bases/signed-url-file-upload.base';
import { AuthService } from 'app/shared/services/auth.service';
import { MovieTranslationService } from 'app/shared/services/movie-translation.service';
import { ProjectService } from 'app/shared/services/project.service';
import { listenerFileUploadCompleted } from 'app/shared/services/signed-url';
import { newSubtitleAdded, SubtitleGenerationService, subtitleThumbnailAdded, subtitleGenerated } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SUBTITLE_TYPE } from '../../subtitle.const';
import { FileTransferService } from '../../../../../shared/services/signed-url/file-transfer.service';

@Component({
	selector: 'mtm-subtitle-creator',
	template: '<div></div>'
})
export class SubtitleCreatorComponent extends SignedURL_FU_Base implements OnInit, AfterViewInit, OnDestroy {
	@Input() guid: any;
	@Input() subtitleData: any;

	subtitle: any;
	items: any = [];
	ngUnsubscribe = new Subject();
	projectId: any;
	authUser: any;

	constructor(
		transferService: FileTransferService,
		private service: SubtitleGenerationService,
		private movieTranslationService: MovieTranslationService,
		private projectService: ProjectService,
		authService: AuthService,
	) {
    /*
		super(
			transferService,
			() => { return `/api/projects/${this.subtitleData.projectId}/sections/${this.subtitleData.sectionId}/subsections/${this.subtitleData.subSectionId}/items/${this.items[this.items.length - 1]}/` },
			() => { return 'Subtitle Generation' },
			() => { return this.subtitle },
			() => { return this.subtitleData.projectId },
			() => { return [" ", this.subtitle.name.replace(/[^a-zA-Z0-9 ]/g, "") || this.subtitle.id] }
		);
     */
    super(
      WithUploadBaseDependencies(authService, transferService),
      WithSignedURLUploadInfo({
		uploadPathGetter: ()=> `/api/projects/${this.subtitleData.projectId}/sections/${this.subtitleData.sectionId}/subsections/${this.subtitleData.subSectionId}/items/${this.items[this.items.length - 1]}/`,
        labelGetter: ()=> 'Subtitle Generation',
        entityGetter: ()=> this.subtitle,
        projectIdGetter: ()=> this.subtitleData.projectId,
        postUploadParamsGetter: ()=> [" ", this.subtitle.name.replace(/[^a-zA-Z0-9 ]/g, "") || this.subtitle.id],
		resumablePreUploadSlug: 'preUploadFilesR'
      })
    )

		listenerFileUploadCompleted.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(this.getSubtitle.bind(this));
	}

	ngOnInit(): void {
		const { subtitle } = this.subtitleData;
		this.items.push(subtitle.directoryId);
		this.projectId = this.projectService.project.id;
		this.authUser = this.authService.getAuthUser();
	}

	ngAfterViewInit(): void {
		const { subtitle } = this.subtitleData;
		this.service.saveSubtitleGenerations(this.subtitleData.projectId, this.subtitleData.sectionId, this.subtitleData.subSectionId, subtitle)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((data: any) => {
				this.subtitle = data;
				this.items.push(data.id);
				newSubtitleAdded.emit(data);
				this.prepareFilesForUpload(this.subtitleData.files);
				if (this.subtitle.id && this.newFiles.length > 0) {
					this.startUpload();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	getSubtitle(file) {
		if (file && this.subtitleData.subSectionId !== file.dbFileObject.subSectionId) {
			return;
		}
		this.items.pop();
		if (this.items.length) {
			this.startReferenceUpload([file.dbFileObject]);
			//this.startUploadFiles(false, false, true, [file.dbFileObject]);
		} else {
			const { projectId, sectionId, subSectionId } = this.subtitleData;
			this.service.getSubtitleGenerationById(projectId, sectionId, subSectionId, this.subtitle.id, this.subtitle.directoryId)
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(data => {
					this.subtitle = data;
					subtitleThumbnailAdded.emit(data);
					if (this.subtitle.type === SUBTITLE_TYPE.ITEM) {
						this.service.getSignedUrlInfo(projectId, this.subtitle.files[0].id)
							.pipe(
								takeUntil(this.ngUnsubscribe)
							).subscribe((res: any) => {
								const { projectSubSectionId, directoryId, id } = this.subtitle;
								this.movieTranslationService.createMovieSubtitle(this.subtitleData.sectionId, projectSubSectionId, directoryId, id, this.subtitle.files[0].id, this.subtitle.files[0].displayName, res.second, this.projectId, this.authUser.username)
									.pipe(
										takeUntil(this.ngUnsubscribe)
									).subscribe(res => {
										subtitleGenerated.emit({ data, guid: this.guid });
									});
							});
					}
				})
		}
	}
}
