import './polyfills.ts';

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { enableProdMode } from '@angular/core';
// import { environment } from './environments/environment';
// import { AppModule } from './app/app.module';

// if (environment.production) {
//   enableProdMode();
// }

// if (!environment.logging) {
//   window.console.log = () => { }
// }

// platformBrowserDynamic().bootstrapModule(AppModule);
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if (!environment.logging) {
  window.console.log = () => { }
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
