<div class="file-list-view w-100" [ngClass]="{'small-list-view': isSmallDisplay}">
	<div class="d-flex align-items-center">
		<div class="file-info d-flex align-items-center">
			<img [src]="file.typeIcon" class="file" onError="this.src='assets/img/icons/file.svg';"
				(click)="handleIconClick()" />
			<div *ngIf="!editMode" title="{{getDisplay(file.printName)}}"
				class="_600 c-mtm-full-black text-left file-name text-truncate nameFileFolder" fileNameDisplay
				[fileName]="file.printName" (click)="handleNameClick()"></div>
			<input *ngIf="editMode" [(ngModel)]="fileName" (blur)="saveName($event)" (keydown.enter)="saveName($event)"
				class="mx-2 p-1 w-100" [style.height]="'32px'" />
			<!-- TODO: when will security issue resolve we will implement these-->
			<!-- <div title="{{getDisplay(file.printName)}}" [hidden]="clicked" class="file-name m-0 text-truncate mt-1"
				fileNameDisplay [fileName]="file.printName"></div>
			<div class="align-itmes-center font-weight-bold text-capitalize title edit-icon ">
				<form [formGroup]="formGroup" *ngIf=" clicked">
					<div class="form-group w-100 mt-3 mr-5 position-relative">
						<input class="form-control w-75" autofocus type="text" (keydown.Escape)="close()"
							(keydown.Enter)="save()" placeholder=" " formControlName="displayName"
							[(ngModel)]="newfileName[0]">
						<i class="fa fa-fw fa-check save-name" aria-hidden="true" (click)="save()"></i>
						<i class="fa fa-times close-icon" aria-hidden="true" (click)="close()"></i>
						<p class="file-extention ">{{fileExtension}}</p>
					</div>
				</form>
				<i (click)="editFileName(file)" class="fa fa-pencil pencil-icon" [hidden]="clicked"
					aria-hidden="true"></i>
			</div> -->
		</div>
		<!-- TODO:- content section remove after confirmation -->
		<div *ngIf="showNumberOfFiles" class="file-info"></div>
		<div class="file-info mb-0 d-flex" *ngIf="!isSmallDisplay">
			<span class="c-mtm-purple _500">{{file.displayTime | mtmDateTime }}</span>
		</div>
		<div class="file-info mb-0 d-flex" *ngIf="!isSmallDisplay">
			<span class="c-mtm-purple _500">{{file.displaySize}}</span>
		</div>
		<div *ngIf="showUploadedBy && !isSmallDisplay" class="file-info mb-0 d-flex">
			<span class="c-mtm-purple _500">
				<mtm-user-profile-picture placement="top" container="body"
				[ngbTooltip]="selectedParticipantTooltip" [username]="file?.createdBy" [height]="38" [width]="38">
				</mtm-user-profile-picture>
				<ng-template #selectedParticipantTooltip>
					<div class="user-tooltip d-flex flex-column align-items-start p-1">
						<h6 class="user-name m-0">{{getUserInfo(file, file?.createdBy)}}</h6>
					</div>
				</ng-template>
			</span>
		</div>
	</div>
</div>