<div class="card p-3">
	<div class="row p-profile">
		<div class="col">
			<div class="nav-close-times" (click)="modalCloseClick()">&times;</div>
			<div class="row">
				<div class="col-9">
					<div class="row">
						<img class="media-left media-object rounded-circle" width="64" height="64"
							*ngIf="companyInfo.projectAvatarUrl" [src]=companyInfo.projectAvatarUrl>
						<img class="media-left media-object rounded-circle" width="64" height="64"
							*ngIf="!companyInfo.projectAvatarUrl && companyInfo.avatarUrl" [src]=companyInfo.avatarUrl>
						<img class="media-left media-object rounded-circle" width="64" height="64"
							*ngIf="!companyInfo.projectAvatarUrl && !companyInfo.avatarUrl" [src]=noneImg>
						<div class="media-body pl-3">
							<h5 class="media-heading m-0 company-name-text" *ngIf="isClient">{{companyInfo.name}}</h5>
							<h5 class="media-heading m-0 company-name-text" *ngIf="!isClient">
								{{companyInfo.clientCompanyName}}</h5>
							<a class="text-danger" *ngIf="companyInfo.companyUrl && !isHavePrefix"
								[href]="'//'+ companyInfo.companyUrl" target="_blank">
								<span>{{companyInfo.companyUrl | cleanUrl }}</span>
							</a>
							<a class="text-danger" *ngIf="companyInfo.companyUrl && isHavePrefix "
								[href]="companyInfo.companyUrl" target="_blank">
								<span>{{companyInfo.companyUrl | cleanUrl }}</span>
							</a>
							<p class="text-muted m-0">{{companyInfo.description}}</p>
						</div>
					</div>
				</div>
				<div class="col-3">
					<div class="row ">
						<a class="social-btn" *ngIf="companyInfo.facebookUrl"
							[href]="companyInfo.facebookPrefix+companyInfo.facebookUrl.trim()" target="_blank">
							<span class="badge facebook-background">
								<i class="fa fa-facebook fa-2x"></i>
							</span>
						</a>
						<a class="social-btn" *ngIf="companyInfo.instagramUrl"
							[href]="companyInfo.instagramPrefix+companyInfo.instagramUrl.trim()" target="_blank">
							<span class="badge instagram-background">
								<i class="fa fa-instagram fa-2x"></i>
							</span>
						</a>
						<a class="social-btn" *ngIf="companyInfo.linkedinUrl"
							[href]="companyInfo.linkedinPrefix+companyInfo.linkedinUrl.trim()" target="_blank">
							<span class="badge linkedin-background">
								<i class="fa fa-linkedin fa-2x"></i>
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row" [style.minWidth]="'700px'">
		<div class="col-6 rating">
			<h5>Rating</h5>
			<div class="row">
				<div class="col">
					<p class="m-0 text-uppercase text-muted" *ngIf="isClient">On Brief</p>
					<p class="m-0 text-uppercase text-muted" *ngIf="!isClient">Clarity of the brief</p>
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half" [style.width.%]="fill"><em
									class="fa fa-star text-warning"></em></span><em
								class="fa fa-star-o blank cursor"></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="brief" [readonly]="true">
					</ngb-rating>
				</div>
				<div class="col">
					<p class="m-0 text-uppercase text-muted" *ngIf="isClient">On Budget</p>
					<p class="m-0 text-uppercase text-muted" *ngIf="!isClient">Openness to ideas</p>
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half" [style.width.%]="fill"><em
									class="fa fa-star text-warning"></em></span><em
								class="fa fa-star-o text-warning cursor"></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="budget" [readonly]="true">
					</ngb-rating>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<p class="m-0 text-uppercase text-muted" *ngIf="isClient">Out of the Box</p>
					<p class="m-0 text-uppercase text-muted" *ngIf="!isClient">Good listener</p>
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half" [style.width.%]="fill"><em
									class="fa fa-star text-warning"></em></span><em
								class="fa fa-star-o text-warning cursor"></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="outOfBox" [readonly]="true">
					</ngb-rating>
				</div>
				<div class="col">
					<p class="m-0 text-uppercase text-muted" *ngIf="isClient">On Time</p>
					<p class="m-0 text-uppercase text-muted" *ngIf="!isClient">Good Payer</p>
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half" [style.width.%]="fill"><em
									class="fa fa-star text-warning"></em></span><em
								class="fa fa-star-o text-warning cursor"></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="time" [readonly]="true">
					</ngb-rating>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<p class="m-0 text-uppercase text-muted" *ngIf="isClient">Good Listener</p>
					<p class="m-0 text-uppercase text-muted" *ngIf="!isClient">Feed back quality & speed</p>
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half" [style.width.%]="fill"><em class="fa fa-star text-warning"
									aria-hidden="true"></em></span><em class="fa fa-star-o text-warning cursor"></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="goodListener" [readonly]="true">
					</ngb-rating>

				</div>
				<div class="col"></div>
			</div>
			<div class="row review-global">
				<div class="col" id="gstar">
					<p class="m-0 text-uppercase text-muted">Global rating</p>
					<ng-template class="star" #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							<span class="half globalRating" [style.width.%]="fill"><em
									class="fa fa-star text-warning "></em></span><em
								class="fa fa-star-o text-warning cursor "></em>
						</span>
					</ng-template>
					<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="global" [readonly]="true">
					</ngb-rating>
				</div>
			</div>
		</div>
		<div class="col-3 overflow mtm-color-scroll" *ngIf="isClient">
			<h5>Experience</h5>
			<div class="row" *ngFor="let experience of companyInfo.experiences">
				<div class="col mb-3">
					<small class="text-uppercase text-muted font-weight-bold">{{experience.startDate | mtmDate}}</small>
					<div class="experience">{{experience.title}}</div>
					<div>
						<small class="text-muted">{{experience.description}}</small>
					</div>
					<small class="text-muted">{{(experience.formatNames || []).join(", ")}}</small>
				</div>
			</div>
		</div>
		<div class="col-3 overflow mtm-color-scroll" *ngIf="isClient">
			<h5>Awards</h5>
			<div class="row" *ngFor="let award of companyInfo.awards ">
				<div class="col mb-3">
					<small class="text-uppercase text-muted font-weight-bold">{{award.date | mtmDate}}</small>
					<div *ngIf="!award.url" class="experience">{{award.name}}</div>
					<a class="d-block" *ngIf="award.url" href="{{award.url}}" target="_blank">{{award.name}}</a>
					<div>
						<small class="text-muted">{{award.description}}</small>
					</div>
					<small class="text-muted">{{(award.formatNames || []).join(", ")}}</small>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col reviews">
			<h5>Reviews</h5>
			<div class="m-pad">
				<div class="row scroll-row">
					<div class="col">
						<div class="row" *ngIf="reviews.length == 0">
							<div class="col text-center">
								<p class="text-danger font-weight-bold">
									No reviews yet
								</p>
							</div>
						</div>
						<div class="media-body" *ngFor="let review of reviews| slice:0:itemLoaded  let i=index ">
							<div class="row">
								<div class="col-1">
									<div class="media-left media-object">
										<img class="review-company-logo rounded-circle" width="48" height="48"
											[src]="review.userCompanyAvatar "
											onError="this.src='assets/img/samples/logo-1.jpg';">
										<img class="review-profile-pic rounded-circle" width="48" height="48"
											[src]="review.userAvatar "
											onError="this.src='./assets/img/misc/avatar-no-image.png';">
									</div>
								</div>
								<div class="col-11" #reviewsRef>
									<p class="text-uppercase"
										style="margin-bottom: 0;margin-left: 25px; color: #787676eb; font-weight: bold; ">
										{{review.userProjectName}} - {{review.time | mtmDate}}</p>
									<p class="text-uppercase text-muted font-weight-bold" style="margin-left:25px">
										{{review.userName}} &emsp; ({{review.userCompanyName}})</p>
									<p class="text-muted" style="margin-left:25px">
										{{review.reviewSlice}} <br>
										<a class="text-danger font-weight-bold" href="#"
											*ngIf="review.reviewSlice != review.review"
											(click)="showAllReview(review,i); false">Read more...</a>
									</p>
								</div>
							</div>
							<hr>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="reviews.length > 1 &&  reviews.length > itemLoaded">
		<div class="col text-center">
			<button class="btn btn-lg btn-danger" (click)="showMoreReviews()">
				<span>Load More</span>
			</button>
		</div>
	</div>
	<div class="row profiles-container" *ngIf="isClient">
		<div class="col">
			<div class="row link-container">
				<div [ngClass]="{'toogleSelection': toggleSection == true }" (click)="onSelectToggle(true)">
					Portfolio
				</div>
				<div [ngClass]="{'toogleSelection': toggleSection == false || toggleSection == 2 }"
					(click)="onSelectToggle(false)">
					Directors
				</div>
				<div class="tags">
					Tags
					<label class="switch">
						<input type="checkbox">
						<span (click)="tagsVisible()" class="slider round"></span>
					</label>
				</div>
			</div>
			<div *ngIf="isFilmsEmpty" class="row items-container">
				<h4 class="text-danger mt-4 not-items">Films not available.</h4>
			</div>
			<div *ngIf="isDirectorsEmpty" class="row items-container">
				<h4 class="text-danger mt-4 not-items">Directors not available.</h4>
			</div>
			<div *ngIf="isPortfolioEmpty && isWorkLoaded" class="row items-container">
				<h4 class="text-danger mt-4 not-items">Portfolio not available.</h4>
			</div>
			<ngx-loading [show]="!isWorkLoaded"
				[config]="{ backdropBorderRadius: '3px', primaryColour: '#e44a27', secondaryColour: '#e44a27',tertiaryColour: '#e44a27'}">
			</ngx-loading>
			<div *ngIf="toggleViewArray.length > 0" class="row items-container">
				<div class="item item-mb" *ngFor="let view of toggleViewArray">
					<div class="item-header">
						<p class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{view.title}}">
							{{view?.title}}</p>
						<p class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{view.desc}}">
							{{view?.desc}}</p>
						<p [hidden]="isTagsVisible" class="text-truncate" data-toggle="tooltip" data-placement="top"
							title="{{view.tag}}">
							{{view?.tag}}</p>
						<h6 *ngIf="view?.file?.displayName" class="text-truncate h6-video-name" data-toggle="tooltip"
							data-placement="top" title="{{view?.file?.displayName}}" fileNameDisplay
							[fileName]="view?.file?.displayName">
						</h6>
					</div>
					<div class="video-container portfolio-video-container" [ngClass]="{'mt-3': !isTagsVisible}">
						<div class=" icons">
							<i aria-hidden="true" (click)="downloadItem(view)"
								class="download-preview-icon text-danger mr-2 ml-2 fa fa-download fa-lg"></i>
							<i aria-hidden="true" (click)="previewFile(view.file)"
								class="download-preview-icon text-danger mr-2 ml-2 fa fa-eye fa-lg "></i>
						</div>
						<mtm-media-player *ngIf="toggleSection || toggleSection == 2" [item]="view.file"
							[miniPlayer]="true" [portfolioPreview]="true" [previewContainerHeight]="'7.9rem'"
							[isClickableImage]="true">
						</mtm-media-player>
						<div class="card" *ngIf="!toggleSection" style="background-color: black"
							(click)="goToDirectorsFilms(view.id)">
							<div class="card-img" [ngStyle]="{'background-image': 'url(' + view.file + ')'}">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>