import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { SharedModule } from "app/sub-modules/SharedModule";
import { SubscriptionConfirmPlanGuard } from "./core/subscribtion.confirm-plan.guard";
import { SubscriptionGuard } from "./core/subscription.guard";
import { SubscriptionPaymentGuard } from "./core/subscription.payment.guard";
import { SubscriptionProduceGuard } from "./core/subscription.produce.guard";
import { SubscriptionSelectPlanGuard } from "./core/subscription.select-plan.guard";
import { SubscriptionRegisterModule } from "./register/register.module";
import { SubscriptionPlanService } from "./services/plan.service";
import { SubscriptionService } from "./services/subscription.service";
import { SubscriptionRegisterContactUsComponent } from "./shared/components/contact-us/contact-us.component";
import { LimitReachedPopupComponent } from "./shared/components/limit-reached-popup/limit-reached-popup.component";
import { PlanRenewalFailedComponent } from "./shared/components/subscription-banner/plan-renewal-failed/plan-renewal-failed.component";
import { SubscriptionRoutingModule } from "./subscription.route";
import { SubscriptionUpgradeModule } from "./upgrade/upgrade.module";

@NgModule({
    declarations: [
        SubscriptionRegisterContactUsComponent,
        LimitReachedPopupComponent,
        PlanRenewalFailedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
        SubscriptionRegisterModule,
        SubscriptionUpgradeModule,
        SubscriptionRoutingModule
    ],
    exports: [
        LimitReachedPopupComponent
    ],
    providers: [
        AuthService,
        SubscriptionGuard,
        SubscriptionSelectPlanGuard,
        SubscriptionConfirmPlanGuard,
        SubscriptionPaymentGuard,
        SubscriptionProduceGuard,
        SubscriptionPlanService,
        SubscriptionService
    ]
})
export class SubscriptionModule { }
