<div class="cardContainer">
	<div class="cardDetailsContainer" [ngStyle]="{'width': paymentType=='ENTRANCE_FEE' ? '550px' : '1000px'}">
		<div *ngIf="paymentType=='ENTRANCE_FEE'">
			<button type="button" class="close text-black" aria-label="Close" (click)="onCloseModal()">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="projectHeader">{{'entranceFeeTitle' | translate}}</div>
			<p style="text-align: center">{{'entranceFeeSubTitle' | translate}}</p>

		</div>
		<div *ngIf="paymentType=='DOWNPAYMENT'">
			<div class="projectHeader">{{'iPayTheDownpayments' | translate}}
				<button type="button" class="close text-black" aria-label="Close" (click)="onCloseModal()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<br>
		</div>
		<div *ngIf="paymentType=='FINAL_PAYMENT'">
			<button type="button" class="close text-black" aria-label="Close" (click)="onCloseModal()">
				<span aria-hidden="true">&times;</span>
			</button>
			<br>
		</div>

		<div *ngIf="paymentType=='FINAL_PAYMENT'">
			<div class="projectHeader"> {{'finalPaymentBoxCongratsText1' | translate}}</div>
			<div class="projectHeader"> {{'finalPaymentBoxCongratsText2' | translate}}</div>
		</div>
		<div style="display: flex;">
			<div *ngIf="paymentType=='FINAL_PAYMENT'" class="cardDetails col-6" style="margin: 10px 10px 0 10px">
				<div class="subtitle" style="margin-bottom: 10px; text-align: center">
					{{'finalPaymentBoxPaymentDetailsText' | translate}}</div>
				<div class="paymentDetails">
					<hr>
					<div>
						<p>{{'finalPaymentBoxTotalBudget' | translate}}</p>
						<p class="end">{{project.budget | mtmCurrency}} {{project.currency}}</p>
					</div>
					<hr>
					<div>
						<p>{{'finalPaymentBoxDownpaymentPaid' | translate}} {{project.downPaymentPayDate | mtmDate}}</p>
						<p class="end">{{downpayment | mtmCurrency}} {{project.currency}}</p>
					</div>
					<hr>
					<div>
						<p>{{'finalPaymentDeadline' | translate}}</p>
						<p class="end">{{project.finalPaymentDeadline | mtmDate}}</p>
					</div>
					<hr>
					<div style="color: #E6431E">
						<p> {{'finalPaymentBoxBalanceDueNow' | translate}}</p>
						<p class="end">{{finalpayment | mtmCurrency}} {{project.currency}}</p>
					</div>
					<hr>
				</div>
				<br>
				<p>{{"finalPaymentBoxInfoText" | translate}}</p>
			</div>
			<div *ngIf="paymentType=='DOWNPAYMENT'" class="cardDetails col-5"
				style="margin-right: 50px; margin-top: 20px">
				<div class="subtitle">{{"paymentDetailsTitle" | translate}}</div>
				<div class="paymentDetails">
					<hr>
					<div>
						<p>{{"downpaymentToPay" | translate}}</p>
						<p class="end">= {{downpayment | mtmCurrency}} {{selectedProposal?.currency}}</p>
					</div>
					<hr>
					<div>
						<p>{{'mtmFee' | translate}} </p>
						<p class="end">= {{mtmFee | mtmCurrency}} {{selectedProposal?.currency}}</p>
					</div>
					<hr>


				</div>
			</div>
			<div class="col">
				<div class="row paymentKindBtnRow">
					<button class="btn" *ngIf="paymentType != 'ENTRANCE_FEE'"
						[ngClass]="{'paymentKindBtn' : !isSepaDebit , 'selectedPaymentKindBtn' : isSepaDebit}"
						(click)="selectSepaDebit()">
						{{'paymentKindSepa' | translate}}
					</button>
					<button class="btn" *ngIf="isCardPaymentAllowed" [disabled]="!isCardPaymentAllowed"
						[ngClass]="{'paymentKindBtn' : isSepaDebit , 'selectedPaymentKindBtn' : !isSepaDebit}"
						(click)="selectCreditCard()">
						{{'paymentKindCreditCard' | translate}}
					</button>
				</div>


				<form [formGroup]="formGroup" class="cardDetails">


					<!--<form [formGroup]="formGroup">-->
					<div class="subtitle" *ngIf="!isSepaDebit">{{'cardDetails' | translate}}</div>
					<div class="subtitle" *ngIf="isSepaDebit"> {{'enterYourDetails' | translate}}</div>
					<div class="inner">
						<div>
							<input class="w-95" placeholder="{{'firstName' | translate}}"
								[(ngModel)]="paymentDTO.firstName" formControlName="firstName" required>
						</div>
						<div>
							<input class="w-95" placeholder="{{'lastName' | translate}}"
								[(ngModel)]="paymentDTO.lastName" formControlName="lastName" required>
						</div>
					</div>
					<div class="inner">
						<div class="text-danger">
							<small
								*ngIf="formGroup.controls['firstName'].invalid && formGroup.controls['firstName'].touched">
								*{{'Invalid First Name!'}}
							</small>
						</div>
						<div class="text-danger">
							<small
								*ngIf="formGroup.controls['lastName'].invalid && formGroup.controls['lastName'].touched">
								*{{'Invalid Last Name!'}}
							</small>
						</div>
					</div>
					<div class="inner" *ngIf="!isSepaDebit">
						<div #cardElement>

						</div>
						<!--					<input placeholder="{{'cardNumber' | translate}}" [(ngModel)]="paymentDTO.cardNumber" formControlName="cardNumber">-->
					</div>

					<div class="inner">
						<div #ibanElement *ngIf="isSepaDebit"></div>
					</div>

					<!--				<div class="inner">-->
					<!--					<div class="text-danger">-->
					<!--						<small *ngIf="formGroup.controls['cardNumber'].invalid && formGroup.controls['cardNumber'].touched">-->
					<!--							*{{'Invalid Card Number!'}}-->
					<!--						</small>-->
					<!--					</div>-->
					<!--				</div>-->
					<!--				<div class="inner">-->
					<!--					<input style="width: 33%" placeholder="{{'month' | translate}}" [(ngModel)]="paymentDTO.expireMonth"-->
					<!--						   formControlName="expireMonth">/-->
					<!--					<input style="width: 33%; margin-right: 10%" placeholder="{{'year' | translate}}" [(ngModel)]="paymentDTO.expireYear"-->
					<!--						   formControlName="expireYear">-->
					<!--					<input style="width: 24%" placeholder="CVV" [(ngModel)]="paymentDTO.cvv" formControlName="cvv">-->
					<!--				</div>-->
					<!--				<div class="inner">-->
					<!--					<div class="text-danger">-->
					<!--						<small-->
					<!--							*ngIf="(formGroup.controls['expireMonth'].invalid || invalidExpireMonth) && formGroup.controls['expireMonth'].touched">-->
					<!--							*{{'Invalid Expire Month!'}}-->
					<!--						</small>-->
					<!--					</div>-->
					<!--					<div class="text-danger">-->
					<!--						<small-->
					<!--							*ngIf="(formGroup.controls['expireYear'].invalid || invalidExpireYear) && formGroup.controls['expireYear'].touched">-->
					<!--							*{{'Invalid Expire Year!'}}-->
					<!--						</small>-->
					<!--					</div>-->
					<!--					<div class="text-danger" style="margin-left: 6%">-->
					<!--						<small *ngIf="formGroup.controls['cvv'].invalid && formGroup.controls['cvv'].touched">-->
					<!--							*{{'Invalid Cvc!'}}-->
					<!--						</small>-->
					<!--					</div>-->
					<!--				</div>-->
					<!--				<div class="inner">-->
					<!--					&lt;!&ndash;<div>&ndash;&gt;-->
					<!--					&lt;!&ndash;<input class="radioButton" type="radio" value="paypal" name="cardType"&ndash;&gt;-->
					<!--					&lt;!&ndash;[(ngModel)]="paymentDTO.cardType"&ndash;&gt;-->
					<!--					&lt;!&ndash;formControlName="cardType">&ndash;&gt;-->
					<!--					&lt;!&ndash;<img src="assets/img/payment/paypal.png">&ndash;&gt;-->
					<!--					&lt;!&ndash;</div>&ndash;&gt;-->
					<!--					<div>-->
					<!--						<input class="radioButton" type="radio" value="visa" name="cardType"-->
					<!--							   [(ngModel)]="paymentDTO.cardType"-->
					<!--							   formControlName="cardType">-->
					<!--						<img src="assets/img/payment/visa.png">-->
					<!--					</div>-->
					<!--					<div>-->
					<!--						<input class="radioButton" type="radio" value="mastercard" name="cardType"-->
					<!--							   [(ngModel)]="paymentDTO.cardType"-->
					<!--							   formControlName="cardType">-->
					<!--						<img src="assets/img/payment/mastercard.png">-->
					<!--					</div>-->
					<!--					<div>-->
					<!--						<input class="radioButton" type="radio" value="discover" name="cardType"-->
					<!--							   [(ngModel)]="paymentDTO.cardType"-->
					<!--							   formControlName="cardType">-->
					<!--						<img src="assets/img/payment/discover.png">-->
					<!--					</div>-->
					<!--					<div>-->
					<!--						<input class="radioButton" type="radio" value="amex" name="cardType"-->
					<!--							   [(ngModel)]="paymentDTO.cardType"-->
					<!--							   formControlName="cardType">-->
					<!--						<img src="assets/img/payment/amex.png">-->
					<!--					</div>-->
					<!--				</div>-->
					<div class="subtitle">{{'billingAddress' | translate}}</div>
					<div class="inner">
						<input placeholder="{{'address' | translate}}" [(ngModel)]="paymentDTO.address"
							formControlName="address">
					</div>
					<div class="inner">
						<div class="text-danger">
							<small
								*ngIf="formGroup.controls['address'].invalid && formGroup.controls['address'].touched">
								*{{'Invalid Address!'}}
							</small>
						</div>
					</div>
					<div class="inner">
						<input placeholder="{{'city' | translate}}" [(ngModel)]="paymentDTO.city"
							formControlName="city">
					</div>
					<div class="inner">
						<div class="text-danger">
							<small *ngIf="formGroup.controls['city'].invalid && formGroup.controls['city'].touched">
								*{{'Invalid City!'}}
							</small>
						</div>
					</div>
					<div class="inner">
						<input placeholder="{{'country' | translate}}" [(ngModel)]="paymentDTO.country"
							formControlName="country">
					</div>
					<div class="inner">
						<div class="text-danger">
							<small
								*ngIf="formGroup.controls['country'].invalid && formGroup.controls['country'].touched">
								*{{'Invalid Country!'}}
							</small>
						</div>
					</div>
					<div class="inner">
						<div>
							<input class="w-95" placeholder="{{'state' | translate}}" [(ngModel)]="paymentDTO.state"
								formControlName="state" required="required">
						</div>
						<div>
							<input class="w-95" placeholder="{{'postalCode' | translate}}"
								[(ngModel)]="paymentDTO.zipCode" required="required" formControlName="zipCode">
						</div>
					</div>
					<div class="inner">
						<div class="text-danger">
							<small *ngIf="formGroup.controls['state'].invalid && formGroup.controls['state'].touched">
								*{{'Invalid State!'}}
							</small>
						</div>
						<div class="text-danger">
							<small
								*ngIf="formGroup.controls['zipCode'].invalid && formGroup.controls['zipCode'].touched">
								*{{'Invalid Zip Code!'}}
							</small>
						</div>
					</div>
				</form>
			</div>

			<!--			<form [formGroup]="formGroup" class="cardDetails" *ngIf="isSepaDebit">-->


			<!--				&lt;!&ndash;<form [formGroup]="formGroup">&ndash;&gt;-->
			<!--				<div class="subtitle">{{'cardDetails' | translate}}</div>-->
			<!--				<div class="inner">-->
			<!--					<div>-->
			<!--						<input class="w-95" placeholder="{{'firstName' | translate}}" [(ngModel)]="paymentDTO.firstName"-->
			<!--							   formControlName="firstName"-->
			<!--							   required>-->
			<!--					</div>-->
			<!--					<div>-->
			<!--						<input class="w-95" placeholder="{{'lastName' | translate}}" [(ngModel)]="paymentDTO.lastName"-->
			<!--							   formControlName="lastName"-->
			<!--							   required>-->
			<!--					</div>-->
			<!--				</div>-->
			<!--				<div class="inner">-->
			<!--					<div class="text-danger">-->
			<!--						<small *ngIf="formGroup.controls['firstName'].invalid && formGroup.controls['firstName'].touched">-->
			<!--							*{{'Invalid First Name!'}}-->
			<!--						</small>-->
			<!--					</div>-->
			<!--					<div class="text-danger">-->
			<!--						<small *ngIf="formGroup.controls['lastName'].invalid && formGroup.controls['lastName'].touched">-->
			<!--							*{{'Invalid Last Name!'}}-->
			<!--						</small>-->
			<!--					</div>-->
			<!--				</div>-->

			<!--				<div class="inner">-->
			<!--					<div #ibanElement></div>-->
			<!--				</div>-->
			<!--			</form>-->










		</div>
		<label class="col checkbox-container" *ngIf="isSepaDebit">
			<input type="checkbox" [(ngModel)]="sepaConfirmation" (ngModelChange)="sepaConfirmationM()" />

			{{'sepaAcceptText' | translate}}

			<span class="checkmark"></span>
		</label>
		<br>
		<p *ngIf="paymentType!='ENTRANCE_FEE' && selectedProposal?.currency==='EUR'">{{'VATNote' | translate}}</p>
		<div class="d-flex flex-row justify-content-center align-items-center mt-3" style="text-align: center">
			<button class="btn btn-payment mt-0"
				[disabled]="formGroup.invalid || !isCompleteButtonClickable || !cardElementValid"
				(click)="completePayment()" data-secret="{{client_secret}}" *ngIf="!isSepaDebit">
				{{'finalPaymentBoxComplete' | translate}}
			</button>

			<button class="btn btn-payment mt-0"
				[disabled]="formGroup.invalid || !isCompleteButtonClickable || !ibanElementValid || !sepaConfirmation"
				(click)="completePayment()" data-secret="{{client_secret}}" *ngIf="isSepaDebit">
				{{'finalPaymentBoxConfirm' | translate}}
			</button>
			<!--			<label *ngIf="isDownpayment" class="mb-0">-->
			<!--				<span class="ml-1 mr-1">or</span>-->
			<!--				<span class="skipPayment" (click)="skipPayment()">Skip Payment</span>-->
			<!--			</label>-->

		</div>
		<loading-data #loadingRef></loading-data>
	</div>
</div>