import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import moment from 'moment';

@Component({
	selector: 'mtm-project-completion-date',
	templateUrl: './project-completion-date.component.html',
	styleUrls: ['./project-completion-date.component.scss']
})

export class ProjectCompletionDateComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() project: any;

	maxDate: any;
	minDate: any;

	date: any = DateHelperService.fromDateToDatepicker(new Date(moment().toISOString()));

	constructor(private activeModal: NgbActiveModal) { }

	ngOnInit(): void { }

	ngOnDestroy(): void { }

	ngAfterViewInit(): void { }

	setParams(args) {
		this.project = args.project;
		this.maxDate = {
			year: parseInt(moment().format('YYYY')),
			month: parseInt(moment().format('M')),
			day: parseInt(moment().format('D'))
		}
		this.minDate = {
			year: parseInt(moment(this.project.startDate).format('YYYY')),
			month: parseInt(moment(this.project.startDate).format('M')),
			day: parseInt(moment(this.project.startDate).format('D'))
		}
	}

	submit() {
		this.activeModal.close({ completionDate: DateHelperService.fromDateToApi(DateHelperService.fromDatePickerToDate(this.date)) });
	}

	cancel() {
		this.activeModal.close();
	}
}