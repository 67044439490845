import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Router } from "@angular/router";

@Component({
  selector: 'project-owner-cell',
  templateUrl: './project-owner-cell.component.html'
})
export class ProjectOwnerCellComponent implements ICellRendererAngularComp {
  user: any;
  project: any;
  constructor(private router: Router) { }

  agInit(params: ICellRendererParams): void {
    this.project = params.data;
    this.user = params.data.participants.find(p => p.username == this.project.manager);
  }

  refresh(params: ICellRendererParams): boolean {
    this.project = params.data;
    this.user = params.data.participants.find(p => p.username == this.project.manager);
    return true;
  }
}
