import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter, DoCheck, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { HelperService } from "app/shared/services/helper.service";
import { DomSanitizer, SafeUrl, SafeStyle } from "@angular/platform-browser";
import { AuthService } from 'app/shared/services/auth.service';
import { Annotation, AnnotationService, onAddingAnnotation, onAddingAnnotationDataChanged, onAddNewAnnotation, onAnnotationClickClosed, onAnnotationClickOpened, onAnnotationHoverClosed, onAnnotationHoverOpened, onAnnotationMarkerSelectedActiveClick, onAnnotationMarkerSelectedActiveHover, onAnnotationMarkerSelectedInActiveClick, onAnnotationMarkerSelectedInActiveHover, onAnnotationsStateChanged, onCancelAddingAnnotation, onCloseActiveAnnotation, onDeleteAnnotation, onDeleteCommentAnnotation, onOpenAnnotation, onPlayerReady, onReplyCommentAnnotation, onVideoPaused, onVideoPlay, onVideoPlayerDisposed, onVideoPlayerInitialized, onTimeFormatChange, onTimeFormatChangePlugin, onImageInitReady, onImageZoomChanged, onImagePluginReady, onAudioPluginReady, onAnnotationHoveredIn, onAnnotationClickedIn, onAnnotationClickedOut, onAnnotationHoveredOut, onVideoPlayerErrorLoaded, onEditingAnnotation, onSaveEditedAnnotation, onCommentPluginReady, wsAnnotationChange, onAnnotationsLoaded } from 'app/shared/services/annotation.service';
import { Subject } from 'rxjs';
import { MtmWebSocketService, wsListenerANNOTATION_ADDED, wsListenerANNOTATION_CREATED, wsListenerANNOTATION_DELETED, wsListenerANNOTATION_EDITED, wsListenerCOMMENT_CREATED, wsListenerCOMMENT_DELETED, wsListenerCOMMENT_EDITED, wsListenerCONVERSATION, WS_SUBSCRIBE_TOPIC_TYPES } from 'app/shared/services/mtm-websocket.service';
import { MTMFileDownloadService, onDownloadStart } from '../mtm-file-download/mtm-file-download.service';
import { MTMFileDownloadModel } from '../mtm-file-download/mtm-file-download.model';
import { takeUntil } from 'rxjs/operators';
import { EmitterService } from 'app/shared/services/emitter.service';
import { UUID } from "angular2-uuid";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FileTransferService } from 'app/shared/services/signed-url';
import { MTMFileDownload } from 'app/shared/services/signed-url/mtm-file-download';
import { MtmStompService } from "../../services/mtm-stomp.service";
import { AnnotationPrivateCondition, TextEditorSelectPrivateCondition, TextEditorSelectPrivateConditionProps } from "../../interfaces";
// import 'videojs-resolution-switcher';
// import '@xiaoyexiang/videojs-resolution-switcher-v7'

@Component({
	selector: 'mtm-media-player',
	templateUrl: './media-player.component.html',
	styleUrls: ['./media-player.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MediaPlayerComponent implements OnInit, OnChanges, DoCheck, OnDestroy, AfterViewInit {
	@Output() stateChanged: EventEmitter<any> = new EventEmitter();
	@Input() hideAnnotationToolbar: boolean;
	@Input() enableVideoAnnotation: boolean;
	@Input() enableImageAnnotation: boolean;
	@Input() enableAudioAnnotation: boolean;
	@Input() enableGeneralComments: boolean = true;
	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() item: any = null;
	@Input() ngClassState: boolean = false;
	@Input() containerWidth = '320';
	@Input() width: string = '400';
	@Input() height: string = '300';
	@Input() fix: boolean = false;
	@Input() fillContainer: boolean = false;
	@Input() mode: string = null;
	@Input() isSideImg: boolean = false;
	@Input() vrProjection: string;
	@Input() projectParticipants: any[];
	@Input() canAddAnnotationComment: boolean = false;
	@Input() miniPlayer: boolean = false;
	@Input() customSize: boolean = false;
	@Input() autoPlayVideo: boolean = false;
	@Input() showDownloadButtonIfNotPreviewAble: boolean = false;
	@Input() portfolioPreview: boolean = false;
	@Input() reorderPreview: boolean = false;
	@Input() previewContainerHeight: string;
	@Input() subTitleFile: boolean = false;
	@Input() filePreview: boolean = false;
	@Input() enableSubtitle: boolean = false;
	@Input() subtitle: any;
	@Input() isExportFormat: boolean = false;
	@Input() isClickableImage: boolean = false;
	@Input() videoSubtitle: any;
	@Input() videoPreloadMethod: any;
	@Input() audioPreloadMethod: string = 'metadata';
	@Input() customPaddingTop: string | null = null;
	@Input() respondToTaskQueue: boolean = false;
	@Input() directPreview: boolean = false;
	@Input() previewSubtitle: boolean = false;
	privateCondition: AnnotationPrivateCondition = null;

	@ViewChild('mtmAudioPlayer') mtmAudioPlayer: ElementRef = null;
	@ViewChild('audioEl') audioEl: ElementRef;
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	@ViewChild('youtube') youtube: any;
	@ViewChild('loadingVideoContainer') loadingVideoContainer: ElementRef;
	@ViewChild('videoContainer') videoContainer: ElementRef;
	@ViewChild('videoTools') videoTools: ElementRef;

	player: any;
	annotations: any = [];
	videoOptions: any;
	isPlayerReady: boolean = false;
	isImagePluginReady: boolean = false;
	isVideoPlayerLoaded: boolean = false;
	isAudioPlayerLoaded: boolean = false;

	private file: any = null;
	mediaType: string = null;
	audioImage: string = null;
	imgSrc: SafeStyle;
	audioSource: any;
	videoSource: SafeUrl;
	private isVideoSourceSet: boolean = false;
	private videoSourceCheckInterval: any;
	private videoTypeCheckInterval: any;
	private isBusy: boolean = false;
	annotationPlugin: any;
	private componentState: any = {
		drawingMode: false
	};

	activeAnnotationId: any;
	fileDownloadInfo: MTMFileDownloadModel;
	isAudioPluginReady: boolean;
	userInfo: any;
	ngUnsubscribe = new Subject();
	wavesurfer: any;
	videoValidCheckInterval: any;
	isError: boolean = false;
	uuid: any;
	currentFontSubtitle: any;
	currentJsonStyleSubtitle: any;
	isCommentPluginReady: boolean;
	annotCreateListener: any;
	wsListeners: any[] = [];
	wsId: string;
	officeFileTranscodingStatus: string = 'success';

	constructor(
		private elementRef: ElementRef,
		private sanitizer: DomSanitizer,
		private authService: AuthService,
		private annotationService: AnnotationService,
		private _http: HttpClient,
		private mtmFileDownloadService: MTMFileDownloadService,
		private transferService: FileTransferService,
		private stompService: MtmStompService,
		private webSocketService: MtmWebSocketService
	) {
	}

	ngAfterViewInit() {
		this.wsId = this.stompService.subscribeToService();

		onAddNewAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddNewAnnotationHandler(args)
		})

		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})

		onDeleteAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onDeleteAnnotation(args)
		})

		onOpenAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onOpenAnnotation(args)
		})

		onCloseActiveAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCloseActiveAnnotation(args)
		})

		onReplyCommentAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onReplyCommentAnnotation(args)
		})

		onDeleteCommentAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onDeleteCommentAnnotation(args)
		})

		onDeleteCommentAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onDeleteCommentAnnotation(args)
		})

		onAnnotationMarkerSelectedActiveHover.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationMarkerSelectedActiveHover(args)
		})

		onAnnotationMarkerSelectedInActiveHover.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationMarkerSelectedInActiveHover(args)
		})

		onAnnotationMarkerSelectedActiveClick.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationMarkerSelectedActiveClick(args)
		})

		onAnnotationMarkerSelectedInActiveClick.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationMarkerSelectedInActiveClick(args)
		})

		onTimeFormatChange.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onTimeFormatChange(args)
		})

		onImagePluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setImageAnnotationConfig(args)
		})

		onAudioPluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setAudioAnnotationConfig(args)
		})

		onCommentPluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setCommentPluginConfig(args)
		})

		onEditingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onEditingAnnotationHandler(args)
		})

		onSaveEditedAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onSaveEditedAnnotationHandler(args)
		})

		// this.wsRxSubscribe('ANNOTATION_CREATED', wsListenerANNOTATION_CREATED, (args: any) => this.wsOnAnnotationCreate(args));
		// this.wsRxSubscribe('ANNOTATION_EDITED', wsListenerANNOTATION_EDITED, (args: any) => this.wsOnAnnotationEdit(args));
		// this.wsRxSubscribe('ANNOTATION_DELETED', wsListenerANNOTATION_DELETED, (args: any) => this.wsOnAnnotationDelete(args));
		// this.wsRxSubscribe('COMMENT_CREATED', wsListenerCOMMENT_CREATED, (args: any) => this.wsOnCommentCreate(args));
		// this.wsRxSubscribe('COMMENT_EDITED', wsListenerCOMMENT_EDITED, (args: any) => this.wsOnCommentEdit(args));
		// this.wsRxSubscribe('COMMENT_DELETED', wsListenerCOMMENT_DELETED, (args: any) => this.wsOnCommentDelete(args));

		this.stompService.subscribeToListener('NEW_ANNOTATION_ADDED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args) => this.wsNewAnnotationAddedHandler(args), wsListenerANNOTATION_ADDED);
		this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);
		this.stompService.subscribeToListener('ANNOTATION_EDITED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationEdit(args), wsListenerANNOTATION_EDITED);
		this.stompService.subscribeToListener('ANNOTATION_DELETED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationDelete(args), wsListenerANNOTATION_DELETED);
		this.stompService.subscribeToListener('COMMENT_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnCommentCreate(args), wsListenerCOMMENT_CREATED);
		this.stompService.subscribeToListener('COMMENT_EDITED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnCommentEdit(args), wsListenerCOMMENT_EDITED);
		this.stompService.subscribeToListener('COMMENT_DELETED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnCommentDelete(args), wsListenerCOMMENT_DELETED);


		// this.wsRxSubscribe('CONVERSATION', wsListenerCONVERSATION, (args: any) => this.wsconversation(args));

		// this.stompService.subscribeToTopic(WS_SUBSCRIBE_TOPIC_TYPES['CONVERSATION'], [this.authService.getAuthUser().username]);
		// let listener = wsListenerCONVERSATION.subscribe((data) => this.wsconversation(JSON.parse(data.body)));

		// this.wsListeners.push({"topic": 'CONVERSATION', "listener": listener});
	}

	/**
	 * WEBSOCKET IMPLENTATION FUNCTIONS
	 */

	wsRxSubscribe(topic, event, func) {
		this.stompService.subscribeToTopic(WS_SUBSCRIBE_TOPIC_TYPES[topic], [this.projectId, this.sectionId, this.subsectionId]);
		let listener = event.subscribe((data) => func(JSON.parse(data.body)));

		this.wsListeners.push({ "topic": topic, "listener": listener });
	}

	wsRxUnsubscribe(topic, listener) {
		this.stompService.unsubscribeTopic(WS_SUBSCRIBE_TOPIC_TYPES[topic], [this.projectId, this.sectionId, this.subsectionId]);
		listener.unsubscribe();
	}

	wsconversation(eventData) {
		console.log("newmesage", eventData);
	}

	wsOnAnnotationCreate(eventData) {
		let annotation = eventData.mediaAnnotation;

		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			annotation = this.annotationService.getAnnotationViewModel(annotation, this.projectParticipants);
			annotation.isWSCall = true;
			annotation.privateConditionKey = this.privateCondition;
			this.annotationPlugin.fire("newAnnotationUpdate", annotation);
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}
	}

	wsOnAnnotationEdit(eventData) {
		let annotation = eventData.mediaAnnotation;
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			annotation = this.annotationService.getAnnotationViewModel(annotation, this.projectParticipants);
			annotation.isWSCall = true;
			if (this.mediaType !== "video") {
				this.annotationPlugin.fire("updateAnnotation", annotation);
			}
			else if (this.mediaType === "video") {
				annotation.commentStr = annotation.comments[0].body;
				annotation.files = annotation.comments[0].files;
				let annotationData = { "annotationId": annotation.id, "data": annotation, "isWSCall": true };
				this.annotationPlugin.fire("saveEditedAnnotation", annotationData);
			}
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}

	}

	wsOnAnnotationDelete(eventData) {
		let annotation = eventData.mediaAnnotation;
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			annotation = this.annotationService.getAnnotationViewModel(annotation, this.projectParticipants);
			annotation.isWSCall = true;
			this.annotationPlugin.fire("destroyAnnotation", { "id": annotation.id, "annotation": annotation });
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}
	}

	wsOnCommentCreate(eventData) {
		let annotation = eventData.mediaAnnotation;
		let comment = this.annotationService.getCommentViewModel(eventData.commentCreated, this.projectParticipants);
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			let comment_obj = { "body": comment.body, "commentCreated": comment, "annotationId": annotation.id, "commentId": comment.id, "isWSCall": true }
			this.annotationPlugin.fire("newComment", comment_obj);
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}
	}

	wsOnCommentEdit(eventData) {
		let annotation = eventData.mediaAnnotation;
		let comment = this.annotationService.getCommentViewModel(eventData.commentEdited, this.projectParticipants);
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			let comment_obj = {
				"body": comment.body,
				"files": comment.files,
				"commentId": comment.id,
				"updatetime": comment.meta.updatetime,
				"annotationId": annotation.id,
				"isWSCall": true
			}
			this.annotationPlugin.fire("updateComment", comment_obj);
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}
	}

	wsOnCommentDelete(eventData) {
		let annotation = eventData.mediaAnnotation;
		let comment = this.annotationService.getCommentViewModel(eventData.commentDeleted, this.projectParticipants);
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			let event_obj = { "annotation": annotation, "id": comment.id, "isWSCall": true }
			this.annotationPlugin.fire("destroyComment", event_obj);
			this.annotations = this.annotationPlugin.annotationState.annotations;
			wsAnnotationChange.emit();
		}
	}

	ngOnInit() {
		this.createPlaybackRateComponent();
		this.uuid = UUID.UUID()
		// this.prepareAnnotationSubscription();
		// this.prepareCommentSubscription();
		// window["mediaPlayer"] = this;

		onAnnotationsLoaded.
			pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (result: any) => {
					this.annotations = result.data;
				}
			});

		this.annotationService.privateConditionChange$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((newValue: AnnotationPrivateCondition) => {
				this.privateCondition = newValue;
			});

		if (this.respondToTaskQueue) {
			this.handleTaskQueueEvents();
		}
	}

	private handleTaskQueueEvents() {
		EmitterService.get(TextEditorSelectPrivateCondition)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (data: TextEditorSelectPrivateConditionProps) => {
					const { privateCondition, resolve } = data;
					if (this.privateCondition == privateCondition) {
						resolve(true);
						return;
					}

					this.annotationService.updatePrivateCondition(privateCondition);
					resolve(true);
				}
			})
	}

	onCancelAddingAnnotationHandler(args: any) {
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('cancelAddingAnnotation');
		}
	}

	onAnnotationMarkerSelectedActiveHover(args: any) {
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('openAnnotationHover', args.annotation);
		}
		if (this.annotationPlugin && this.mediaType === "audio") {

		}
	}

	onAnnotationMarkerSelectedInActiveHover(args: any) {
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('closeAnnotationHover');
		}
	}

	onAnnotationMarkerSelectedActiveClick(args: any) {
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('openAnnotationClicked', args.annotation);
		}
	}

	onAnnotationMarkerSelectedInActiveClick(args: any) {
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('closeAnnotationClicked');
		}
	}

	onEditingAnnotationHandler(args: any) {
		if (this.annotationPlugin) {
			this.annotationPlugin.fire('editAnnotation', args.annotation);
		}
	}

	onSaveEditedAnnotationHandler(args: any) {
		if (this.annotationPlugin) {

			let shape = JSON.stringify(this.player.canvasFabric);
			args.data = {
				...args.data,
				shape: shape,
				resolution: {
					height: this.player.canvasFabric.getHeight(),
					width: this.player.canvasFabric.getWidth()
				}
			}
			// let annotIndex = this.annotations.findIndex((el) => el.id === args.annotationId)
			// if (annotIndex) this.annotations[annotIndex] = args.data;


			this.annotationPlugin.fire('saveEditedAnnotation', { data: args.data, annotationId: args.annotationId });
			// this.saveAnnotation(args.data);
		}
	}

	onAddNewAnnotationHandler(args: any) {
		if (!this.player || !this.isPlayerReady) {
			return;
		}
		let shape = JSON.stringify(this.player.canvasFabric);
		args.annotation = {
			...args.annotation,
			...{
				shape: JSON.parse(shape),
				resolution: {
					height: this.player.canvasFabric.getHeight(),
					width: this.player.canvasFabric.getWidth()
				}
			}
		}
		if (this.annotationPlugin && this.isPlayerReady) {
			this.annotationPlugin.fire('newAnnotation', args.annotation);
		}
	}

	// prepareAnnotationSubscription() {
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_CREATED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_CREATED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_EDITED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_EDITED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_DELETED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.ANNOTATION_DELETED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// }

	// prepareCommentSubscription() {
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_CREATED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_CREATED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_EDITED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_EDITED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// 	this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_DELETED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	setTimeout(() => {
	// 		this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.COMMENT_DELETED, [this.projectId, this.sectionId, this.subsectionId]);
	// 	}, 350);
	// }

	wsNewAnnotationAddedHandler(data: any) {
		console.log("data", data);
		if (this.annotationPlugin && this.item) {
			data = _.filter(data, (comment) => (comment.typeId === this.typeId) && (comment.itemId === this.item.id));
			let annotations = new Array<Annotation>();
			_.map(data, (annotation: any) => {
				let parent = _.filter(annotation.comments, (comment) => {
					return HelperService.isObjectEmpty(comment.parentId);
				});
				let replies = _.filter(annotation.comments, (comment) => {
					return !HelperService.isObjectEmpty(comment.parentId);
				});
				replies.sort((commentA: any, commentB: any) => {
					return _.get(commentA, 'meta.datetime') - _.get(commentB, 'meta.datetime');
				});
				annotation.comments = [...parent, ...replies];
				let entity = this.annotationService.getAnnotationViewModel(annotation, this.projectParticipants);
				annotations.push(entity);
			});
			annotations.sort((annotationA: any, annotationB: any) => {
				return _.get(annotationB.comments, '[0].meta.datetime') - _.get(annotationA.comments, '[0].meta.datetime');
			});
			let newAnnotations = annotations;
			if (newAnnotations.length) {
				let i: number;
				for (i = 0; i < newAnnotations.length; i++) {
					let oldAnnotationValue = _.find(this.annotations, { id: newAnnotations[i].id }) as any;
					if (!oldAnnotationValue) {
						this.annotationPlugin.fire('newAnnotationUpdate', {
							comments: newAnnotations[i].comments,
							id: newAnnotations[i].id,
							range: newAnnotations[i].range,
							resolution: newAnnotations[i].resolution,
							shape: newAnnotations[i].shape
						});
					} else {
						for (let j = 0; j < newAnnotations[i].comments.length; j++) {
							let oldAnnotationCommentsValue = _.find(oldAnnotationValue.comments, { id: newAnnotations[i].comments[j].id });
							if (!oldAnnotationCommentsValue) {
								this.annotationPlugin.fire('newCommentUpdate', {
									annotationId: newAnnotations[i].id,
									comment: newAnnotations[i].comments[j]
								});
							}
						}
					}
				}
			}
			for (let k = 0; k < this.annotations.length; k++) {
				let annotation: any = _.find(newAnnotations, { id: this.annotations[k].id });
				if (!annotation) {
					this.annotationPlugin.fire('destroyAnnotation', { id: this.annotations[k].id });
					if (!this.activeAnnotationId || this.activeAnnotationId === this.annotations[k].id) {
						this.annotationPlugin.fire('closeActiveAnnotation');
						this.activeAnnotationId = void 0;
					}
				} else {
					let comments = annotation.comments;
					for (let l = 0; l < comments.length; l++) {
						let oldComment = _.find(this.annotations[k].comments, { id: comments[l].id }) as any;
						if (oldComment) {
							if (oldComment.body !== comments[l].body) {
								this.annotationPlugin.fire('updateComment', {
									annotationId: annotation.id,
									commentId: comments[l].id,
									body: comments[l].body
								});
							}
						}
					}
				}
			}
			this.annotations = annotations;
		}
	}

	onDeleteAnnotation(args: any) {
		if (args.annotation && this.annotationPlugin) {
			this.annotationPlugin.fire('destroyAnnotation', { id: args.annotation.id });
		}
	}

	onOpenAnnotation(args: any) {
		if (this.mediaType === "video" && args.annotation && this.annotationPlugin && this.player && this.isPlayerReady) {
			this.annotationPlugin.fire('openAnnotation', { id: args.annotation.id });
			this.activeAnnotationId = args.annotation.id;
		}
		else if (this.mediaType === "audio" && this.annotationPlugin && this.isAudioPluginReady) {
			this.annotationPlugin.fire('clickInAnnotation', { annotation: args.annotation });
		}
	}

	onCloseActiveAnnotation(args: any) {
		if (args.annotation && this.annotationPlugin && this.player && this.isPlayerReady) {
			this.annotationPlugin.fire('closeActiveAnnotation');
		}
	}

	onReplyCommentAnnotation(args: any) {
		if (args.data.annotation && this.annotationPlugin && args.data.commentBody && ((this.mediaType == 'audio' && this.isAudioPluginReady) || (this.mediaType == 'image' && this.isImagePluginReady) || (this.player && this.isPlayerReady) || this.mediaType) && (this.item.id == this.annotationPlugin.getItem().id)) {
			this.annotationPlugin.fire('newComment', { annotationId: args.data.annotation.id, body: args.data.commentBody, files: args.data.files });
		}
	}

	onDeleteCommentAnnotation(args: any) {
		if (args.comment && this.annotationPlugin) {
			if (this.mediaType === "video" && this.player && this.isPlayerReady) {
				this.annotationPlugin.fire('destroyComment', { id: args.comment.id });
			}
			else if ((this.mediaType === "audio" && this.isAudioPluginReady) || (this.mediaType === "image" && this.isImagePluginReady)) {
				this.annotationPlugin.fire('destroyComment', { id: args.comment.id, annotation: args.annotation });
			}
		}
	}

	onTimeFormatChange(args: any) {
		if (args.format && this.annotationPlugin && this.player && this.isPlayerReady) {
			this.annotationPlugin.fire(args.act, { format: args.format });
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			if (changes.item && changes.item.currentValue) {
				this.updatePreviewSize();
			}
			if (changes.videoSubtitle) {
				this.setVideoSubtitles();
			}
			if (changes.selectedSubtitle && changes.selectedSubtitle.currentValue && !changes.selectedSubtitle.firstChange) {
				if (this.player) {
					const subtitle = this.player.textTracks();
					for (var i = 0; i < subtitle.tracks_.length; i++) {
						var track = subtitle.tracks_[i];
						if (track.kind === 'captions' && track.language === changes.selectedSubtitle.currentValue) {
							track.mode = 'showing';
						} else {
							track.mode = 'hidden';
						}
					}
				}
			}
			if (changes.previewSubtitle?.currentValue) {
				if (this.mediaType === 'video' && this.player) {
					this.player.pause();
					this.player.currentTime(0.1);
				}
			}
		}
	}

	ngDoCheck(): void {
		let isVacDisablePlay = false;
		if (!this.player || !this.isPlayerReady) {
			isVacDisablePlay = false;
		} else {
			isVacDisablePlay = this.player.el().classList.contains('vac-active') && this.player.el().classList.contains('vac-disable-play');
		}
		if (isVacDisablePlay !== this.componentState.drawingMode) {
			this.componentState = { ...this.componentState, ...{ drawingMode: isVacDisablePlay } };
			this.onStateChanged(this.componentState);
		}
		if (this.player && !this.isPlayerReady) {
			let isVacActive = this.player.el().classList.contains('vac-active');
			if (!_.isNaN(this.player.duration()) && isVacActive) {
				onPlayerReady.emit({
					act: 'onPlayerReady',
					player: this.player,
					annotationPlugin: this.annotationPlugin,
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id,
					metadata: this.item.metadata
				});
				this.isPlayerReady = true;
			}
		}
	}

	ngOnDestroy() {
		this.disposePlayerIfExist();
		this.disposeImagePluginIfExist();
		this.disposeAudioPluginIfExist();
		if (this.videoTypeCheckInterval) clearTimeout(this.videoTypeCheckInterval);
		if (this.videoSourceCheckInterval) clearTimeout(this.videoSourceCheckInterval);
		setTimeout(() => {
			let mediaElements = this.elementRef.nativeElement.querySelectorAll('[src]');
			_.map(mediaElements, (element) => {
				if (element.nodeName === 'VIDEO') {
					element.pause();
					element.removeAttribute('src');
					element.load();
				} else {
					element.removeAttribute('src');
				}
			});
		}, 350);

		// this.wsListeners.forEach(listener => {
		// 	this.wsRxUnsubscribe(listener.topic, listener.listener);
		// });
		this.stompService.unsubscribeToService(this.wsId);

		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	detachSubtitleStyle() {
		this.currentFontSubtitle = this.currentJsonStyleSubtitle = void 0;
		HelperService.addOrRemoveStyle('remove', this.item.id);
	}

	attachSubtitleStyle(jsonStyle, font) {
		this.currentJsonStyleSubtitle = jsonStyle;
		if (this.currentFontSubtitle?.name !== font.name) {
			this._http.get<Blob>(font.font_url, { observe: 'response', responseType: 'blob' as 'json' })
				.subscribe((res: HttpResponse<Blob>) => {
					let binaryData = [];
					binaryData.push(res.body);
					window.URL.createObjectURL(new Blob(binaryData, { type: 'font/ttf' }));
					this.currentFontSubtitle = {
						name: font.name,
						font_url: window.URL.createObjectURL(new Blob(binaryData, { type: 'font/ttf' }))
					}
					const elementStyleString = this.generateVideoJsTrackCustomStyle(jsonStyle, this.currentFontSubtitle);
					const validatedJsonStyle = HelperService.validateCssString(elementStyleString);
					const style = HelperService.wrapStyle(validatedJsonStyle);
					HelperService.addOrRemoveStyle('load', this.item.id, style);
				}, err => {
					console.log(err);
				});
		} else {
			const elementStyleString = this.generateVideoJsTrackCustomStyle(jsonStyle, this.currentFontSubtitle);
			const validatedJsonStyle = HelperService.validateCssString(elementStyleString);
			const style = HelperService.wrapStyle(validatedJsonStyle);
			HelperService.addOrRemoveStyle('load', this.item.id, style);
		}
	}

	private generateVideoJsTrackCustomStyle(jsonStyle, font) {
		const videoHeight = this.player.videoHeight(), videoWidth = this.player.videoWidth();
		const containerHeight = this.player.isFullscreen() ? window.outerHeight : 360.0;
		const containerWidth = videoWidth / (videoHeight / containerHeight);
		let elementStyleString = '';
		if (font) {
			elementStyleString += '@font-face {';
			elementStyleString += `font-family: ${font.name};`;
			elementStyleString += `src: url(${font.font_url});`;
			elementStyleString += `}`;
		}
		elementStyleString += '.vjs-text-track-display {'
		elementStyleString += 'width:' + containerWidth + 'px !important;';
		elementStyleString += 'margin: 0 auto 0 auto !important';
		elementStyleString += '}';
		elementStyleString += '.vjs-text-track-display .vjs-text-track-cue { ';
		elementStyleString += 'height: auto !important;';
		elementStyleString += 'width: auto !important;';
		elementStyleString += 'text-align:' + jsonStyle.hAlignment + ' !important;';
		elementStyleString += 'top:' + (jsonStyle.vAlignment === 'top' ? '0' : 'auto') + ' !important;';
		elementStyleString += 'bottom:' + (jsonStyle.vAlignment === 'bottom' ? '0' : 'auto') + ' !important;';
		elementStyleString += '}';
		elementStyleString += '.vjs-text-track-display .vjs-text-track-cue > div { ';
		elementStyleString += 'background-color:' + (jsonStyle.text_background.enabled ? jsonStyle.text_background.value : 'inherit') + ' !important;';
		elementStyleString += 'color:' + jsonStyle.font_color + ' !important;';
		elementStyleString += 'font-size:' + jsonStyle.font_size + 'px !important;';
		elementStyleString += 'text-shadow:' + (jsonStyle.text_shadow.enabled ? '2px 2px ' + jsonStyle.text_shadow.value : 'none') + ' !important;';
		elementStyleString += 'font-family:' + jsonStyle.font_family + ' !important;';
		elementStyleString += '}';
		return elementStyleString;
	}

	onStateChanged(state: any) {
		this.stateChanged.emit(state);
	}

	updatePreviewSize() {
		this.updateContainerSize(this.item);
		this.isImagePluginReady = false;
		this.isAudioPluginReady = false;
		this.disposePlayerIfExist();
		this.disposeImagePluginIfExist();
		this.disposeAudioPluginIfExist();
		this.mediaType = void 0;
		this.isError = false;
		this.isImagePluginReady = false;
		this.isAudioPluginReady = false;
		if (this.videoTypeCheckInterval) clearTimeout(this.videoTypeCheckInterval);
		if (this.videoSourceCheckInterval) clearTimeout(this.videoSourceCheckInterval);
		if (this.ngUnsubscribe) {
			this.ngUnsubscribe.next(undefined);;
			this.ngUnsubscribe.complete();
		}
		setTimeout(() => {
			this.annotations = [];
			this.init();
		}, 0);
	}

	updateContainerSize(item: any) {
		// Class list updated here, css updated in slider component
		let type = this.getMediaType(item);
		let previewContainer = document.getElementById('previewContainer');
		let assetContainer = document.getElementById('assetContainer');

		if (type === "video" && previewContainer && assetContainer) {
			previewContainer.classList.add('video-selected');
			previewContainer.classList.remove('audio-selected', 'image-selected');
			assetContainer.classList.add('video-selected');
			assetContainer.classList.remove('audio-selected', 'image-selected');
		}
		else if (type === "audio" && previewContainer && assetContainer) {
			previewContainer.classList.add('audio-selected');
			previewContainer.classList.remove('video-selected', 'image-selected');
			assetContainer.classList.add('audio-selected');
			assetContainer.classList.remove('video-selected', 'image-selected');
		}
		else if (type === "image" && previewContainer && assetContainer) {
			previewContainer.classList.add('image-selected');
			previewContainer.classList.remove('video-selected', 'audio-selected');
			assetContainer.classList.add('image-selected');
			assetContainer.classList.remove('video-selected', 'audio-selected');
		}
	}

	getMediaType(item) {
		let type;
		if (HelperService.isImageFile(item)) {
			type = "image";
		}
		else if (HelperService.isAudioFile(item)) {
			type = "audio";
		}
		else if (HelperService.isVideoFile(item)) {
			type = "video";
		} else if (HelperService.isSubtitleFile(item)) {
			type = "subtitle";
		}

		return type;
	}

	getAnnotations() {
		let subject = new Subject<any>();

		/*
		this.annotationService
				.getAnnotations(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, this.projectParticipants)
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(data) => {
						subject.next(data);
					},
					(err) => {
						subject.error(err);
					}
				);
		 */

		this.annotationService.getMediaAnnotations({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subsectionId: this.subsectionId,
			typeId: this.typeId,
			itemId: this.item.id,
			participants: this.projectParticipants,
			privateConditionRequest: this.privateCondition
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (data: any) => {
				subject.next(data);
			},
			error: (err) => {
				subject.error(err);
			}
		});

		return subject.asObservable();
	}

	disposePlayerIfExist() {
		if (this.player && this.isPlayerReady) {
			this.player.dispose();
			this.player = void 0;
			this.isVideoPlayerLoaded = false;
			this.isPlayerReady = false;
			this.annotationPlugin = void 0;
			onVideoPlayerDisposed.emit({
				act: 'videoPlayerDisposed',
				player: this.player,
				annotationPlugin: this.annotationPlugin,
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: _.get(this.item, 'id')
			});
			$(document).off("click");
		}
	}

	disposeImagePluginIfExist() {
		if (this.annotationPlugin && this.annotationPlugin.eventDispatcher.pluginReady && this.mediaType === "image") {

			this.annotationPlugin.fire('dispose');
			this.annotationPlugin = void 0;
		}
	}

	disposeAudioPluginIfExist() {
		if (this.annotationPlugin && this.annotationPlugin.eventDispatcher.pluginReady && this.mediaType === "audio") {

			this.annotationPlugin.fire('dispose');
			this.annotationPlugin = void 0;
		}
	}

	setDefaultVideoOptions() {
		let metadata = this.file.metadata;
		if (!metadata || JSON.parse(metadata).length == 0) {
			return;
		}
		let videoMetaData: any;
		if (this.file && this.file.metadata) {
			videoMetaData = JSON.parse(this.file.metadata).filter(item => item.codecType === "VIDEO")[0];
		}
		let downloadControlId: any;
		if (this.file && this.file.projectId && this.file.sectionId && this.file.subSectionId && this.file.id) {
			downloadControlId = 'video-js-' + this.file.projectId + '-' + this.file.sectionId + '-' + this.file.subSectionId + '-' + this.file.id;
		} else {
			// Handle the case where one or more of these attributes are null or undefined.
		}
		console.log('downloadControlId', downloadControlId)

		// let videoMetaData = JSON.parse(this.file.metadata).filter(item => item.codecType === "VIDEO")[0];

		// const downloadControlId = 'video-js-'
		// 	+ this.file.projectId
		// 	+ '-' + this.file.sectionId + '-'
		// 	+ this.file.subSectionId
		// 	+ '-' + this.file.id;
		const ignore_qualities = ["_SMALL_211_121", "_SMALL_209_241", "_TINY_211_121", "_TINY_209_241", "_TINY", "_SMALL"]
		this.videoOptions = {
			aspectRatio: '4:3',
			autoplay: this.autoPlayVideo,
			fluid: true,
			height: _.isNumber(this.height) ? parseInt(this.height) : this.height,
			preload: this.videoPreloadMethod ? this.videoPreloadMethod : (this.enableVideoAnnotation ? 'auto' : 'metadata'),
			width: parseInt(this.width),
			controlBar: {
				customPlaybackRateMenu: {},
				playbackRateMenuButton: false,
				children: [
					"playToggle",
					"customPlaybackRateMenu",
					"volumePanel",
					"customControlSpacer",
					"currentTimeDisplay",
					"timeDivider",
					"durationDisplay",
					"customControlSpacer",
					"progressControl",
					"PictureInPictureToggle",
					"fullscreenToggle"
				]
			},
			plugins: {
				vjsdownload: {
					controlId: downloadControlId,
					beforeElement: 'ResolutionSwitcher',
					textControl: 'Download',
					name: 'downloadButton',
					isDownloading: this.mtmFileDownloadService.isFileInDownload(downloadControlId),
					downloadCallback: (controlId: any, quality: any) => {
						let file = JSON.parse(JSON.stringify(this.file));
						if (quality) {
							file.displayName = this.file.displayName.split('.').slice(0, -1).join('.') + '.mp4';
							file.fileName = this.file.name.split('.').slice(0, -1).join('.') + '.mp4';
						}
						const button = document.getElementById(controlId);
						button.classList.add('downloading');
						onDownloadStart.emit();
						/*
						this.fileDownloadInfo = new MTMFileDownloadModel(
							downloadControlId,
							this.file,
							(downloadId: any) => {
								const downloadButton = document.getElementById(downloadId);
								downloadButton.classList.remove('downloading');
							},
							(downloadId: any) => {
								const downloadButton = document.getElementById(downloadId);
								downloadButton.classList.add('downloading');
							},
							this.getDownloadParameters(quality)
						);
						this.mtmFileDownloadService.startDownloadFile(this.fileDownloadInfo);
						*/
						const downloadParams = this.getDownloadParameters(quality);
						let url: any;
						if (this.file) {
							url = (quality ? file.signedURL : this.file.signedURL) + (downloadParams || '');
						}
						const downloadInfo = MTMFileDownload.fromFileRef(quality ? file : this.file);
						downloadInfo.id = downloadControlId;
						downloadInfo.url = url;
						this.transferService.downloadFile(downloadInfo);
					},
					mediaQuality: this.item.transcodeMetadata?.filter(metadata => !ignore_qualities.includes(metadata.quality)) || [],
					originalMedia: { "resolution": videoMetaData.width + "x" + videoMetaData.height, "codec": videoMetaData.codecName }
				},
				videoJsResolutionSwitcher: {
					default: 'second-high',
					dynamicLabel: true,
				}
			},
			playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
		};
		if (this.miniPlayer) {
			this.videoOptions.plugins.threeDotMenu = true;
		}
		if (this.enableSubtitle && this.subtitle) {
			this.videoOptions.plugins.subtitle = {
				name: 'subtitleButton',
				file: this.subtitle.signedURL,
				caption: this.getDisplay(this.subtitle.displayName || this.subtitle.name),
				downloadCallback: () => {
					var id = UUID.UUID();
					onDownloadStart.emit();
					/*
					this.fileDownloadInfo = new MTMFileDownloadModel(
						id,
						this.subtitle,
						(id: any) => { },
						(id: any) => { },
					);
					this.mtmFileDownloadService.startDownloadFile(this.fileDownloadInfo);
					*/
					const downloadInfo = MTMFileDownload.fromFileRef(this.subtitle);
					downloadInfo.id = id;
					this.transferService.downloadFile(downloadInfo);
				}
			};
		}
	}

	getDisplay(name: any) {
		if (!name) {
			return '';
		}
		let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
		let nameArr = name.split('_');
		let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
		let savedName = '';
		if (isTime) {
			savedName = _.slice(nameArr, 0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_');
		} else {
			let nameSplit = name.split('.');
			savedName = _.slice(nameSplit, 0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
		}
		return savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
	}

	createPlaybackRateComponent() {
		let playbackRateMenu = videojs.getComponent('PlaybackRateMenuButton');
		let CustomPlaybackRateMenu = videojs.extend(playbackRateMenu, {
			constructor: function (player, options) {
				playbackRateMenu.apply(this, arguments);
				// Trigger menu display on menu click
				$(this.menu.contentEl_).on("click", () => {
					$(this.el_).toggleClass("vjs-menu-open");
				});
			},
			handleClick() {
				// Trigger menu display on menu button click
				$(this.el_).toggleClass("vjs-menu-open");
			}

		});
		videojs.registerComponent('customPlaybackRateMenu', CustomPlaybackRateMenu);
	}

	private getDownloadParameters(quality: any) {
		let params = [];
		if (this.file && this.file.sectionId) {
			params.push('sectionId=' + this.file.sectionId);
		}
		if (this.file && this.file.subSectionId) {
			params.push('subSectionId=' + this.file.subSectionId);
		}
		if (quality) {
			params.push('thumbnail=' + quality);
		}
		if (params.length > 0) {
			return '?' + params.join('&');
		}
		return null;
	}

	setVideoVRProjection() {
		this.player.mediainfo = this.player.mediainfo || {};
		this.player.mediainfo.projection = this.vrProjection || 'NONE';
		this.player.vr({ projection: this.vrProjection || 'AUTO' });
	}

	private init(forceInit?: boolean): void {
		if (this.item && this.item.status === 'uploading') {
			EmitterService.get('file-upload-done-' + this.item.id).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(() => {
				this.updatePreviewSize();
			});
			return;
		}
		if (this.item && ((this.item !== this.file) || forceInit)) {
			if (typeof this.item === 'string' || this.item instanceof String) {
				this.file = this.item;
				this.mediaType = 'url';
				if (this.youtube) {
					this.youtube.link = this.item;
					this.youtube.ngOnInit();
				}
			} else if (HelperService.isVideoFile(this.item)) {
				this.file = this.item;
				this.isVideoSourceSet = false;
				this.videoSource = this.sanitizer.bypassSecurityTrustUrl(this.file.signedURL);
				this.mediaType = 'video';
				// this.initializeVideoJs();
				this.checkValidOriginal();
				// this.videoValidCheckInterval = setInterval(this.checkValidOriginal.bind(this), 5000);
			} else if (HelperService.isAudioFile(this.item)) {
				this.file = this.item;
				this.audioImage = HelperService.getAudioImage();
				this.audioSource = this.sanitizer.bypassSecurityTrustUrl(this.file.signedURL);
				this.prepareWaveSurfer(this.file);
				this.initializeAudioAnnotations();
			} else if (HelperService.isImageFile(this.item)) {
				this.file = this.item;
				this.imgSrc = this.sanitizer.bypassSecurityTrustStyle('url("' + this.file.signedURL + '")');
				this.initializeImageAnnotations();
			} else if (this.item.contentType == 'application/pdf') {
				this.mediaType = 'pdf';
				this.file = this.item;
				this.file.previewSRC = this.file.signedURL;
				this.initializeComments("pdf");
			} else if (HelperService.isOfficeDoc(this.item)) {
				this.mediaType = 'officeDoc';
				this.file = this.item;
				this.file.previewSRC = this.file.signedURL + "?thumbnail=_PDF";
				this.checkOfficeTranscodingStatus();
			} else if (HelperService.isSubtitleFile(this.item)) {
				// this.mediaType = 'subtitle';
				this.file = this.item;
				this.initializeComments("subtitle");
			} else {
				this.file = this.item;
				this.file.publicLink = this.file.signedURL;
				this.initializeComments("download-link");
				// this.mediaType = 'download-link';
			}
		}
	}

	private checkOfficeTranscodingStatus() {
		if (!HelperService.isOfficeDoc(this.item)) {
			return;
		}

		if (!this.file.transcodeMetadata?.length) {
			this.officeFileTranscodingStatus = 'in-progress';
		} else {
			const pdfMetadata = this.file.transcodeMetadata.find(m => m.codec == 'pdf');
			if (!pdfMetadata) {
				this.officeFileTranscodingStatus = 'in-progress';
			} else {
				switch (pdfMetadata.resolution) {
					case 'true':
						this.officeFileTranscodingStatus = 'success';
						break;
					case 'false':
						this.officeFileTranscodingStatus = 'failed';
						break;
					default:
						this.officeFileTranscodingStatus = 'in-progress';			//this.officeFileTranscodingStatus = 'in-progress';
						break;
				}
			}
		}

		if (this.officeFileTranscodingStatus == 'in-progress') {
			setTimeout(() => {
				this.checkOfficeTranscodingStatus();
			}, 5000);
		}
	}

	private checkValidOriginal() {
		setTimeout(() => {
			let metadata = this.file.metadata;
			if (!metadata || JSON.parse(metadata).length == 0) {
				this.isError = true;
				return;
			}
			let thumbnailUrl = this.file.signedURL + (this.directPreview ? '' : "?thumbnail=_SMALL");
			let img = new Image();
			img.onload = () => {
				if (this.videoContainer) $(this.videoContainer.nativeElement).removeClass("hidden-container");
				if (!this.portfolioPreview && this.videoTools) $(this.videoTools.nativeElement).removeClass("hidden-container")
				if (this.loadingVideoContainer) $(this.loadingVideoContainer.nativeElement).addClass("hidden-container");
				this.initializeVideoJs();
			}
			img.onerror = () => {
				if (this.file && this.file.name) {
					if (this.checkMp4(this.file.name)) {
						if (this.videoContainer) $(this.videoContainer.nativeElement).removeClass("hidden-container");
						if (!this.portfolioPreview && this.videoTools) $(this.videoTools.nativeElement).removeClass("hidden-container")
						if (this.loadingVideoContainer) $(this.loadingVideoContainer.nativeElement).addClass("hidden-container");
						this.initializeVideoJs();
					}
				} else {
					if (this.videoContainer) $(this.videoContainer.nativeElement).addClass("hidden-container");
					if (!this.portfolioPreview && this.videoTools) $(this.videoTools.nativeElement).addClass("hidden-container")
					if (this.loadingVideoContainer) $(this.loadingVideoContainer.nativeElement).removeClass("hidden-container");
					// fetch(this.file.signedURL + "?thumbnail=_TRANSCODED", {
					// 	headers: {
					// 		"Authorization": "Bearer " + localStorage.getItem("token")
					// 	}
					// });
					if (this.file && this.file.signedURL) {
						fetch(this.file.signedURL + "?thumbnail=_TRANSCODED", {
							headers: {
								"Authorization": "Bearer " + localStorage.getItem("token")
							}
						})
					}
					this.videoTypeCheckInterval = setTimeout(this.checkValidOriginal.bind(this), 5000);
				}
			}
			img.src = thumbnailUrl
		}, 100);
	}

	private initializeAudioAnnotations() {
		if (this.enableAudioAnnotation) {
			if (this.isBusy) return;
			this.isBusy = true;
			this.getAnnotations().pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data) => {
					this.annotations = data;
					this.mediaType = "audio";
					this.isBusy = false;
					this.userInfo = this.authService.getAuthUser();
				}
			)
		}
		else {
			this.mediaType = "audio";
		}
	}

	private initializeImageAnnotations() {
		if (this.enableImageAnnotation) {
			if (this.isBusy) return;
			this.isBusy = true;
			this.getAnnotations().pipe(
				takeUntil(this.ngUnsubscribe)
			)
				.subscribe(
					(data) => {
						this.annotations = data;
						this.isBusy = false;
						this.mediaType = 'image';
						this.userInfo = this.authService.getAuthUser();
					},
					(err) => {
						this.isBusy = false;
					}
				);
		}
		else {
			this.mediaType = "image";
		}

	}

	private initializeComments(mediaType) {
		if (this.enableGeneralComments) {
			if (this.isBusy) return;
			this.isBusy = true;
			this.getAnnotations().pipe(
				takeUntil(this.ngUnsubscribe)
			)
				.subscribe(
					(data) => {
						this.annotations = data;
						this.isBusy = false;
						this.userInfo = this.authService.getAuthUser();
						this.mediaType = mediaType;
					},
					(err) => {
						this.isBusy = false;
					}
				);
		}
	}

	private initializeVideoJs() {
		if (this.enableVideoAnnotation && this.mediaType === "video") {
			if (this.isBusy) return;
			this.isBusy = true;
			this.getAnnotations().pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data) => {
					this.annotations = data;
					setTimeout(() => {
						if (this.videoPlayer) {
							videojs.resetFormatTime();
							this.setDefaultVideoOptions();
							this.player = videojs(this.videoPlayer.nativeElement, this.videoOptions, () => {
								// this.videoTypeCheckInterval = setInterval(this.checkTranscodingFinished.bind(this), 5000);
								this.setVideoSource();
								this.setVideoVRProjection();
								this.setVideoAnnotationConfig();
								this.player.on('loadeddata', () => {
									this.isVideoPlayerLoaded = true;
									this.player.hasStarted(true);
								});
								this.player.one('loadeddata', () => {
									let customSpacer = this.player.controlBar.getChild("customControlSpacer").el();
									$(customSpacer).css("min-width", customSpacer.offsetWidth);
								});
								this.player.on('fullscreenchange', () => {
									let customSpacer = this.player.controlBar.getChild("customControlSpacer").el();
									$(customSpacer).css("min-width", "");
									setTimeout(() => {
										$(customSpacer).css("min-width", customSpacer.offsetWidth);
									}, 500);
								});
								this.player.on('pause', () => {
									onVideoPaused.emit({
										act: 'onVideoPaused',
										player: this.player,
										annotationPlugin: this.annotationPlugin,
										projectId: this.projectId,
										sectionId: this.sectionId,
										subSectionId: this.subsectionId,
										typeId: this.typeId,
										itemId: this.item.id
									});
									onVideoPlayerInitialized.emit({
										act: 'videoPlayerInitialized',
									});
								});
								this.player.on('play', () => {
									onVideoPlay.emit({
										act: 'onVideoPlay',
										player: this.player,
										annotationPlugin: this.annotationPlugin,
										projectId: this.projectId,
										sectionId: this.sectionId,
										subSectionId: this.subsectionId,
										typeId: this.typeId,
										itemId: this.item.id
									});
								});
								this.player.on('error', () => {
									onVideoPlayerErrorLoaded.emit();
								});
								onVideoPlayerInitialized.emit({
									act: 'videoPlayerInitialized',
									player: this.player,
									annotationPlugin: this.annotationPlugin,
									projectId: this.projectId,
									sectionId: this.sectionId,
									subSectionId: this.subsectionId,
									typeId: this.typeId,
									itemId: this.item.id
								});
								this.player.el().classList.add('vjs-has-started');
								this.isBusy = false;
							});
							this.player.on("ready", () => {
								$(document).on("click", (e) => {
									if (!$(e.target).hasClass("vjs-vjsdownload")) {
										$('.vjs-vjsdownload.vjs-control.vjs-button').removeClass("vjs-menu-open");
									}
									if (!$(e.target).hasClass("vjs-playback-rate")) {
										$('.vjs-playback-rate').removeClass("vjs-menu-open");
									}
									if ($(e.target).parent(".vjs-resolution-button").length == 0) {
										$('.vjs-resolution-button').removeClass("vjs-menu-open");
									}
								});
								if (this.customPaddingTop) {
									this.player.el().style.setProperty('padding-top', this.customPaddingTop, 'important');
								}

							});
						} else {
							this.isBusy = false;
						}
					}, 0);
				},
				(err) => {
					this.isBusy = false;
				}
			);
		} else {
			setTimeout(() => {
				if (this.videoPlayer) {
					this.setDefaultVideoOptions();
					this.player = videojs(this.videoPlayer.nativeElement, this.videoOptions, () => {
						this.setVideoSource();
						this.setVideoVRProjection();
						this.player.on('loadeddata', () => {
							this.isVideoPlayerLoaded = true;
							if (this.portfolioPreview) {
								$(this.player.controlBar.resolutionSwitcher).hide();
							}
							this.player.currentTime(0);
						});
						this.player.on('fullscreenchange', () => {

							if (this.currentJsonStyleSubtitle && this.currentFontSubtitle) {
								this.attachSubtitleStyle(this.currentJsonStyleSubtitle, this.currentFontSubtitle);
							}
						});
						this.player.on("ready", () => {
							if (this.customPaddingTop) {
								this.player.el().style.setProperty('padding-top', this.customPaddingTop, 'important');
							}
						});
						this.setVideoSubtitles();
					});
				}
				this.isBusy = false;
			}, 0);
		}
	}

	setVideoSubtitles() {
		if (!this.player || !this.enableSubtitle) {
			return;
		}
		let subtitles = this.player.textTracks();
		if (!this.videoSubtitle) {
			if (subtitles.length) {
				this.player.removeRemoteTextTrack(subtitles[0]);
			}
		} else {
			this.player.removeRemoteTextTrack(subtitles[0]);
			setTimeout(() => {
				const srtRegex = /(.*\n)?(\d\d:\d\d:\d\d),(\d\d\d --> \d\d:\d\d:\d\d),(\d\d\d)/g,
					vttText = 'WEBVTT\n\n' + this.videoSubtitle.content.replace(srtRegex, '$1$2.$3.$4'),
					vttBlob = new Blob([vttText], { type: 'text/vtt' }),
					blobURL = URL.createObjectURL(vttBlob);
				this.player.addRemoteTextTrack({
					src: blobURL,
					mode: this.videoSubtitle.mode,
					srclang: this.videoSubtitle.srclang,
					kind: 'captions'
				}, true);
			}, 0);
		}
	}

	setVideoAnnotationConfig() {
		this.item.metadata = this.item.metadata || '[]';
		this.item.participants = this.item.participants || this.projectParticipants;
		let user = this.authService.getAuthUser();
		this.annotationPlugin = this.player.annotationComments({
			annotationsObjects: this.annotations || [],
			bindArrowKeys: true,
			meta: {
				user_id: user.username,
				user_name: user.fullName
			},
			internalCommenting: true,
			showControls: true,
			showMarkerTooltips: true,
			startInAnnotationMode: true,
			showFullScreen: false,
			item: this.item,
			file: this.file,
			metadata: this.item.metadata || []
		});
		this.initializeAnnotationEvents();
	}

	setAudioAnnotationConfig(args) {
		this.isAudioPluginReady = true;
		this.annotationPlugin = args.annotationPlugin;
		this.initializeAudioAnnotationsEvents();
		this.initializeAnnotationEvents();
	}

	setImageAnnotationConfig(args) {
		this.isImagePluginReady = true;
		this.annotationPlugin = args.annotationPlugin;
		this.initializeAnnotationEvents();
	}

	setCommentPluginConfig(args) {
		this.isCommentPluginReady = true;
		this.annotationPlugin = args.annotationPlugin;
		this.initializeAnnotationEvents();
	}

	initializeAudioAnnotationsEvents() {
		if (this.mediaType === "audio" && this.isAudioPluginReady && this.annotationPlugin) {
			this.annotationPlugin.registerListener('annotationHoveredIn', (event: any) => {
				onAnnotationHoveredIn.emit({
					act: 'onAnnotationHoveredIn',
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id,
					data: event.detail
				});
			});

			this.annotationPlugin.registerListener('annotationHoveredOut', (event: any) => {
				onAnnotationHoveredOut.emit({
					act: 'onAnnotationHoveredOut',
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id,
					data: event.detail
				});
			});

			this.annotationPlugin.registerListener('annotationClickedIn', (event: any) => {
				onAnnotationClickedIn.emit({
					act: 'onAnnotationClickedIn',
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id,
					data: event.detail
				});
			});

			this.annotationPlugin.registerListener('annotationClickedOut', (event: any) => {
				onAnnotationClickedOut.emit({
					act: 'onAnnotationClickedOut',
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id,
					data: event.detail
				});
			});
		}
	}

	initializeAnnotationEvents() {
		this.annotationPlugin.registerListener('addingAnnotationDataChanged', (event: any) => {
			onAddingAnnotationDataChanged.emit({
				act: 'onAddingAnnotationDataChanged',
				data: event.detail,
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id
			});
		});

		// Change time format event from the plugin
		this.annotationPlugin.registerListener('timeFormatChanged', (event: any) => {
			onTimeFormatChangePlugin.emit({
				act: 'timeFormatChanged',
				format: event.detail.format
			});
		});

		this.annotationPlugin.registerListener('annotationHoverOpened', (event: any) => {
			onAnnotationHoverOpened.emit({
				annotation: event.detail.annotation
			});
		});

		this.annotationPlugin.registerListener('annotationHoverClosed', (event: any) => {
			onAnnotationHoverClosed.emit({
				annotation: event.detail.annotation
			});
		});

		this.annotationPlugin.registerListener('annotationClickedOpened', (event: any) => {
			onAnnotationClickOpened.emit({
				annotation: event.detail.annotation
			});
		});

		this.annotationPlugin.registerListener('annotationClickedClosed', (event: any) => {
			onAnnotationClickClosed.emit({
				annotation: event.detail.annotation
			});
		});

		this.annotationPlugin.registerListener("onAnnotationEdited", (event: any) => {
			let newAnnotation = event.detail.annotation;
			let oldAnnotation = _.find(this.annotations, { id: newAnnotation.id }) as any;
			if (oldAnnotation.shape !== newAnnotation.shape || oldAnnotation.range != newAnnotation.range) {
				this.saveAnnotation(newAnnotation);
				this.annotations = event.detail.allAnnotations;
			}
		});

		this.annotationPlugin.registerListener("annotationCreated", (event: any) => {
			this.saveAnnotation(event.detail.newAnnotation);
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener("annotationDeleted", (event: any) => {
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener("annotationEdited", (event: any) => {
			this.saveAnnotation(event.detail.annotation);
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener("commentCreated", (event: any) => {
			this.saveAnnotation(event.detail.annotation);
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener("commentDeleted", (event: any) => {
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener("commentEdited", (event: any) => {
			this.saveAnnotation(event.detail.annotation);
			this.annotations = event.detail.allAnnotations;
		});

		this.annotationPlugin.registerListener('enteredAddingAnnotation', (event: any) => {
			onAddingAnnotation.emit({
				act: 'addingAnnotation',
				event: event
			});
		});

		this.annotationPlugin.registerListener('exitingAddingAnnotation', (event: any) => {
			onCancelAddingAnnotation.emit({
				act: 'cancelAddingAnnotation',
				event: event
			});
		});

		// Image specific listeners

		this.annotationPlugin.registerListener('imageZoomChanged', (event: any) => {
			onImageZoomChanged.emit({
				act: 'imageZoomChanged',
				zoom: event.detail.zoom
			});
		});
	}

	saveAnnotation(entity: any) {
		console.log('entity', entity);
		this.annotationService
			.addNewAnnotation(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, entity, this.projectParticipants).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data) => {
					onAnnotationsStateChanged.emit({
						args: 'annotationStateChanged',
						data: this.annotations,
						projectId: this.projectId,
						sectionId: this.sectionId,
						subSectionId: this.subsectionId,
						typeId: this.typeId,
						itemId: this.item.id
					});
					this.isBusy = false;
				},
				(err) => {
					console.error('err', err);
					this.isBusy = false;
				}
			);
	}

	private getVideoType() {
		let fileType: string;

		if (this.file.publicLink && this.isFileExtension(this.file.publicLink, 'mp4')
			|| (this.file.name && this.isFileExtension(this.file.name, 'mp4')) || this.file.publicLink && this.isFileExtension(this.file.publicLink, 'mkv') || (this.file.name && this.isFileExtension(this.file.name, 'mkv')) || this.file.publicLink && this.isFileExtension(this.file.publicLink, 'mov') || (this.file.name && this.isFileExtension(this.file.name, 'mov')) || this.file.publicLink && this.isFileExtension(this.file.publicLink, 'avi') || (this.file.name && this.isFileExtension(this.file.name, 'avi'))) {
			fileType = 'video/mp4';
		}
		else if (this.file.publicLink && this.isFileExtension(this.file.publicLink, 'webm') || (this.file.name && this.isFileExtension(this.file.name, 'webm'))) {
			fileType = 'video/webm';
		}
		else if (this.file.publicLink && this.isFileExtension(this.file.publicLink, 'flv') || (this.file.name && this.isFileExtension(this.file.name, 'flv'))) {
			fileType = 'video/x-flv';
		}

		return fileType;
	}

	private setVideoSource() {
		let source = this.sanitizer.bypassSecurityTrustUrl(this.file.signedURL) as any;
		let sourceSafe = source.changingThisBreaksApplicationSecurity
		let type = this.getVideoType();
		let transcodedVideos: any;
		if (this.file) {
			transcodedVideos = this.file.transcodeMetadata?.filter(item => (item.codec !== "jpeg" && item.codec !== "jpg")) || [];
		}
		let availableResolutions = transcodedVideos.map(a => a.quality.substr(1));
		let resolutionArray = [];
		let thumbnailUrl: any;
		if (this.file && this.file.signedURL) {
			thumbnailUrl = this.file.signedURL + (this.directPreview ? '' : "?thumbnail=_SMALL");
		}

		if (HelperService.isImageExists(thumbnailUrl) && transcodedVideos.length > 0) {
			availableResolutions.forEach(resolution => {
				// For all the downgraded resolutions
				if (resolution !== 'SMALL' && resolution !== 'TINY') {
					let resOptions = {
						src: sourceSafe + (this.directPreview ? '' : "?thumbnail=_" + resolution),
						type: type,
						label: resolution + "p",
						res: resolution
					};
					resolutionArray.push(resOptions);
				}
			});
			this.player.updateSrc(resolutionArray);
		}
		// If transoding isn't finished, view the source file
		else {
			if (!this.isVideoSourceSet) {
				let resOptions = {
					src: sourceSafe + (this.directPreview ? '' : '?thumbnail=_TRANSCODED'), // Automatically redirects to original if transcoding underway
					type: type,
					label: "Source"
				};
				resolutionArray.push(resOptions);
				this.player.updateSrc(resolutionArray);
				this.isVideoSourceSet = true;
			}
			this.videoSourceCheckInterval = setTimeout(this.setVideoSource.bind(this), 5000);
		}
	}

	private checkMp4(filename) {
		return (filename && this.isFileExtension(filename, 'mp4')) ? true : false;
	}

	private prepareWaveSurfer(file: any) {
		setTimeout(() => {
			if (this.mtmAudioPlayer) {
				this.mtmAudioPlayer.nativeElement.src = this.file.signedURL;
				this.mtmAudioPlayer.nativeElement.load();
				let token = localStorage.getItem('token');
				let height;
				if (this.portfolioPreview) height = 70
				else if (this.reorderPreview) height = 100
				else height = 200
				this.isAudioPlayerLoaded = false;
				this.wavesurfer = WaveSurfer.create({
					container: document.getElementById(this.uuid),
					waveColor: '#909091',
					progressColor: '#fe3b00',
					barWidth: 1,
					cursorColor: "transparent",
					height: height,
					xhr: {
						requestHeaders: [
							{ key: "Authorization", value: "Bearer " + token }
						]
					}
				});
				this.wavesurfer.on('ready', (e: any) => {
					console.log('audio player ready');
					this.isAudioPlayerLoaded = true;
				});
				this.wavesurfer.load(file.signedURL);
				$(this.mtmAudioPlayer.nativeElement).on('timeupdate', (e: any) => {
					this.wavesurfer.setCurrentTime(e.target.currentTime);
				});

			}
		}, 1500);
	}

	private isFileExtension(filename: string, extension: string) {
		return HelperService.isFileExtension(filename, extension);
	}

	getFileTypeIcon(item) {
		return "assets/img/icons" + item.contentType.substr(item.contentType.lastIndexOf("/")) + ".svg";
	}

	private formatSizeUnits(bytes) {
		if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; }
		else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; }
		else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; }
		else if (bytes > 1) { bytes = bytes + ' bytes'; }
		else if (bytes == 1) { bytes = bytes + ' byte'; }
		else { bytes = '0 byte'; }
		return bytes;
	}

	showImageInNewTab() {
		if (this.isClickableImage)
			window.open(this.file.signedURL);
	}

	playAsset() {
		if (this.isError) {
			return;
		}

		if (this.mediaType === 'video' && this.player) {
			this.player.play();
		}
		else if (this.mediaType == 'audio' && this.mtmAudioPlayer) {
			this.mtmAudioPlayer.nativeElement.play();
		}

	}

	pauseAsset() {
		if (this.isError) {
			return;
		}

		if (this.mediaType === 'video' && this.player) {
			this.player.pause();
		} else if (this.mediaType === 'audio' && this.mtmAudioPlayer) {
			this.mtmAudioPlayer.nativeElement.pause();
		}
	}

	resetAssetPlay() {
		if (this.isError) {
			return;
		}

		if (this.mediaType === 'video' && this.player) {
			this.player.currentTime(0);
		} else if (this.mediaType === 'audio' && this.mtmAudioPlayer) {
			this.mtmAudioPlayer.nativeElement.currentTime = 0;
		}
	}

	get isMediaPlayerLoaded(): boolean {
		switch (this.mediaType) {
			case 'video':
				return this.isVideoPlayerLoaded;
			case 'audio':
				return this.isAudioPlayerLoaded;
			//TODO: add other media types loading check	
			default:
				return true;
		}
	}
}
