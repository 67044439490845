import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { Observable } from 'rxjs';

@Injectable()
export class VerificationGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		if (route.queryParams.token && route.queryParams.email) {
			let registerData = {
				auth: {
					token: route.queryParams.token
				},
				user: {
					email: route.queryParams.email,
					phone: route.queryParams.phone
				}
			};
			localStorage.setItem('register_data', JSON.stringify(registerData));
			this.route.navigate(['/verification'], { queryParams: {} });
		} else {
			let registerData = JSON.parse(localStorage.getItem('register_data'));
			if (HelperService.isObjectEmpty(registerData)) {
				this.route.navigate(['/entry']);
			} else {
				return true;
			}
		}
	}

}
