import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";
import { UrlShortenerService } from "app/shared/services/url-shortener.service";
import { Subject } from "rxjs";
import { ShortenedUrlInfo } from "app/shared/interfaces";
import { HelperService } from "../services/helper.service";

export const canActivateShortener: CanActivateFn = (route, state) => {
  const shortenerService = inject(UrlShortenerService);
  const router = inject(Router);
  const slug = route.params['slug'];

  if (!slug) {
    return false;
  }

  const subject = new Subject<boolean | UrlTree>();
  
  shortenerService.getShortenedUrlByBackhalf(slug).subscribe({
    next: (shortenedInfo: ShortenedUrlInfo) => {
      if (shortenedInfo) {
        const relativeUrl = shortenedInfo.redirectionUrl.replace(HelperService.getCurrentOrigin()+ '/', '');
        const urlTree = router.parseUrl(relativeUrl);
        subject.next(urlTree);
      } else {
        subject.next(router.parseUrl('/404'));
      }
      subject.complete();
    },
    error: (err) => {
      subject.next(router.parseUrl('/404'));
      subject.complete();
    }
  });

  return subject.asObservable();
}
