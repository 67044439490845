import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { UnseenTotal } from "../interfaces";
import { Subject, Subscription } from "rxjs";
import {
	MtmWebSocketService, WS_SUBSCRIBE_TOPIC_TYPES, wsListenerUNSEEN_NOTIFICATION_UPDATED
} from "./mtm-websocket.service";
import { AuthService } from "./auth.service";
import { MtmStompService } from "./mtm-stomp.service";

export type UnseenNotificationHandler = (data: Record<string, UnseenTotal>) => void;

@Injectable({
	providedIn: 'root'
})
export class UnseenNotifierService implements OnDestroy {
	private unseenChange: Subject<Record<string, UnseenTotal>> = new Subject<Record<string, UnseenTotal>>();
	private isWsStarted = false;
	private subscriptionId: string | null = null;


	constructor(private authService: AuthService,
		private stompService: MtmStompService) {
	}

	private initialize() {
		if (this.isWsStarted)
			return;

		const subscriptionId = this.stompService.subscribeToService();
		const userName = this.authService.getAuthUserName();

		this.stompService.subscribeToListener(WS_SUBSCRIBE_TOPIC_TYPES.UNSEEN_NOTIFICATION_UPDATED, subscriptionId,
			[userName], (data: Record<string, UnseenTotal>) => {
				this.unseenChange.next(data);
			}, wsListenerUNSEEN_NOTIFICATION_UPDATED);

		this.subscriptionId = subscriptionId;

		console.log('subscribed to unseen notifier');
	}

	unsubscribe(subscriptionId: string) {
		this.stompService.unsubscribeToService(subscriptionId);
	}

	subscribe(fn: UnseenNotificationHandler): Subscription {
		const sub = this.unseenChange.subscribe(fn);

		if (!this.isWsStarted) {
			this.initialize();
			this.isWsStarted = true;
		}

		return sub;
	}

	/*
	private initWS() {
		if (this.isWsStarted)
			return;

		this.wsService.initConnectWS();

		const userName = this.authService.getAuthUserName();

		this.wsService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.UNSEEN_NOTIFICATION_UPDATED,
			[userName]);

		///TODO: find ws issue, seems to be unreliable without set timeout
		this.wsService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.UNSEEN_NOTIFICATION_UPDATED,
			[userName]);

		this.wsSubscription = wsListenerUNSEEN_NOTIFICATION_UPDATED.subscribe(data => {
			this.unseenChange.emit(data);
		});
	}
	 */

	ngOnDestroy() {
		/*
		if (this.wsSubscription)
			this.wsSubscription.unsubscribe();

		this.wsService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.UNSEEN_NOTIFICATION_UPDATED,
			[this.authService.getAuthUserName()]);
		 */
		this.unseenChange.complete();

		if (this.subscriptionId) {
			this.stompService.unsubscribeToService(this.subscriptionId)
			console.log('unsubscribe from unseen notifier');
		}
	}

}
