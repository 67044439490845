<div class="modal-body pl-5 pr-5 pt-4 pb-4">
	<div class="d-flex align-items-center justify-content-center" *ngIf="audioService.isLoading">
		Loading...
	</div>
	<div class="video-incoming-popup d-flex" *ngIf="!audioService.isLoading">
		<div class="d-flex flex-fill flex-column align-items-center justify-content-center">
			<div class="d-flex align-items-center justify-content-center mb-2">
				<div *ngIf="conversation?.type==='GROUP' || conversation?.type === 'EVENT'">
					<mtm-group-chat-picture [conversation]="conversation"></mtm-group-chat-picture>
				</div>
				<div *ngIf="conversation?.type==='ONE_TO_ONE'">
					<mtm-user-profile-picture [user]="caller" [username]="caller.username" [height]="48" [width]="48"
						[fontSize]="'18px'">
					</mtm-user-profile-picture>
				</div>
				<i class="fa fa-video-camera icon-btn p-1 ml-1"></i>
			</div>
			<div class="user mb-4" *ngIf="conversation?.type === 'GROUP' || conversation?.type === 'EVENT'">
				{{conversation?.title}} is calling on the project - {{ project.name }}...
			</div>
			<div class="user mb-4" *ngIf="conversation?.type === 'ONE_TO_ONE'">
				{{caller.fullName}} is calling on the project - {{ project.name }}...
			</div>
			<div class="buttons m-0 p-0 d-flex align-items-center justify-content-center">
				<a class="btn btn-success btn-block decline-button mr-3 d-flex align-items-center justify-content-center m-0 pl-4 pr-4"
					(click)="onAcceptCall()">
					<i class="fa fa-phone mr-2"></i>
					<span class="button-nav-label">Accept</span>
				</a>
				<a class="btn btn-danger btn-block d-flex align-items-center justify-content-center m-0 pl-4 pr-4"
					(click)="onRejectCall()">
					<i class="fa fa-phone mr-2 decline"></i>
					<span class="button-nav-label">Decline</span>
				</a>
			</div>
		</div>
	</div>
</div>