import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionSelectPlanGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService, private subscriptionService: SubscriptionService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const authUser = this.auth.getAuthUser();
		if (!authUser) {
			this.route.navigate(['/entry/login'], {
				queryParams: {
					returnUrl: window.location.pathname
				}
			});
		} else {
			return true;
		}
	}

}
