import {Component, Input} from "@angular/core";

@Component({
  selector: 'mtm-subtitles-icon',
  templateUrl: './subtitles-icon.component.html'
})
export class SubtitlesIconComponent {
  @Input() svgWidth: string = "25px";

  constructor() {
  }
}
