<div class="container proposal-step-container">
	<div class="row">
		<div class="col-sm-12">
			<div class="card-header">
				<h6 class="font-weight-bold">6. Project Details</h6>
			</div>

			<div class="card-block">
				<div class="col-8">
					<div class="row">
						<p style="font-weight: bold">Production Owner</p>
						<br>
					</div>
					<div class="row">
						<p>Who is the Production Owner of this Project?</p>
					</div>
					<div class="row">
						<div class="col-6">
							<label class="form-group row has-float-label">
								<select #selectManager class="dropdown-toggle mb-2"
									(change)="onSelectManager(selectManager.value)">
									<option *ngFor="let productionOwner of productionOwners"
										[selected]="productionOwner.username == selectedManager.username"
										[value]="productionOwner.username">
										{{productionOwner.fullName}}
									</option>
								</select>
							</label>
						</div>
					</div>
					<div class="row">
						<p style="font-weight: bold">Project Description </p>
					</div>
					<div class="row">
						<p>
							Explain your project in a sentence or two - only this production company see this
							description
						</p>

					</div>

					<div class="row">
						<label class="form-group has-float-label">
							<textarea class="form-control text-area" placeholder=" " rows="10" cols="49"
								[(ngModel)]="description"></textarea>
							<span>Description</span>
						</label>
					</div>




				</div>

				<hr>
				<!-- <div class="row">

                        <div class="col text-right">
                            <button class="btn btn-danger btn-lg" (click)="goToTeam()" [disabled]="sending">
                                <span>Go to Team & User Permissions</span>
                            </button>
                        </div>
                    </div> -->

				<div class="row">
					<div class="col text-right">
						<button class="btn btn-danger btn-lg" (click)="goToTeam()">
							<span>Go to Team & User Permissions</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>