import { TaskPriority } from "../components/task/task-status.const";

export interface Task {
	campaignId?: string,
	projectId?: string,
	projectSectionId?: string,
	projectSectionName?: string,
	projectSubSectionId?: string,
	projectSubSectionName?: string,
	topicId?: string,
	optionId?: string,
	id?: string,
	versionNumber?: number,
	title?: string,
	description?: string,
	assetsVersions?: { assetId: string, versionNumber: number, projectId: string, projectSectionId: string, projectSubSectionId: string, topicId: string, optionId?: string }[],
	priority?: any,
	status?: string,
	creationDate?: any,
	startDate?: any;
	dueDate?: any,
	creator?: string,
	assignee?: string,
	collaborators?: string[],
	listPosition?: number;
	updatedAt?:any;
	section?: string;
}

export interface TasksSearchParams {
	campaignId: string;
	workspaceIds?: string;
	milestoneIds?: string;
	projectId?: string;
	sectionId?: string;
	sectionNames?: string;
	subsectionId?: string;
	subSectionNames?: string;
	taskId?: string;
	topicId?: string;
	optionId?: string;
	assignees?: string;
	from?: string;
	to?: string;
	page?: number;
	limit?: number;
	sortBy?: string;
	collaborators?: string[];
	creator?: string[];
	statusSet?: string[];
	dateRangeType?: string;
}

export interface TasksSearchResult {
	data: Task[];
	hasNext: boolean;
	pagingState: number;
}

export interface MilestoneTask {
	campaignId?: string,
	projectId?: string,
	projectSectionId?: string,
	projectSectionName?: string,
	projectSubSectionId?: string,
	projectSubSectionName?: string,
	topicId?: string,
	optionId?: string,
	id?: string,
	versionNumber?: number,
	title?: string,
	description?: string,
	assetsVersions?: { assetId: string, versionNumber: number, projectId: string, projectSectionId: string, projectSubSectionId: string, topicId: string, optionId?: string }[],
	priority?: string,
	status?: string,
	creationDate?: any,
	startDate?: any;
	dueDate?: any,
	creator?: string,
	assignee?: string,
	collaborators?: string[],
	milestoneId?: string;
	workspaceId?: string;
}
export const TaskUpdatedEvent = 'task.updated';
export const milestoneTaskUpdatedEvent = 'milestonTask.updated';
