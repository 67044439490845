import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignupDetailComponent } from "./components/detail/detail.component";

export const routes: Routes = [{
	path: '',
	component: SignupDetailComponent
}];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SignupRoutingModule { }
