import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api.service";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class PaymentInvoiceService {

	constructor(private apiService: ApiService) { }

	getPaymentInvoices() {
		return this.apiService.httpGet('/api/paymentInvoice/invoices');
	}

	getUpcomingPaymentInvoices() {
		return this.apiService.httpGet('/api/paymentInvoice/upcoming');
	}
}
