import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { NotificationService } from 'app/shared/services/notification.service';
import { LoadingBase } from 'app/shared/bases/loading.base';
import { Authority } from "../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Project } from "../../../shared/interfaces/project";

@Component({
	templateUrl: './archived-projects.component.html',
	styleUrls: ['./archived-projects.component.scss']
})
export class ArchivedProjectsComponent extends LoadingBase implements OnInit, OnDestroy {
	@ViewChild('loadingRef', { static: true }) public loadingRef: LoadingBase;
	user: any = {};
	archivedProjects: any[] = [];
	pendingProjects: any[] = [];
	authority = Authority;
	ngUnsubscribe = new Subject();

	constructor(private router: Router,
		private breadcrumb: BreadcrumbService,
		public auth: AuthService,
		public service: ProjectService,
		private notificationService: NotificationService) {
		super();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.user = this.auth.getAuthUser();
		this.loadProjects();
	}

	ngAfterViewInit() {
		this.initLoadingParameters(undefined, undefined, ['col-12']);
	}

	inProgress() {
		this.router.navigate(['projects']);
	}

	projectInvites() {
		this.router.navigate(['projects', 'project-invitations']);
	}

	projectProposals() {
		this.router.navigate(['projects', 'project-proposals']);
	}

	private loadProjects(): void {

		this.showLoading();
		this.service.getArchivedProjects().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {


			if (!Array.isArray(data))
				return;

			let projects = [];
			data.forEach((project: any) => {
				projects.push(project);
				if (project.avatarUrl)
					project.avatarUrl = project.avatarUrl + '?' + (new Date).getTime();
			});

			this.archivedProjects = projects;

			this.showInfoForArrayData(this.archivedProjects, 'You don’t have any archived project');

		}, (err: any) => this.showLoadingError());

	}

	deletedProject(project: Project) {
		if (!project) {
			return;
		}
		this.loadProjects();
	}
}
