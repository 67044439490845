import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from "ng5-breadcrumb";
import { onLeaveFromProject, onLoadProject, ProjectService } from "app/shared/services/project.service";
import { HelperService } from "app/shared/services/helper.service";
import { NotificationService } from "app/shared/services/notification.service";

import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "../../../shared/services/datepicker-adapter";
import { AuthService } from 'app/shared/services/auth.service';
import { Authority, PermissionService } from "../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';
import { isMissionComplete, missionCompletionInterface } from 'app/shared/services/annotation.service';

@Component({
	selector: 'mtm-new-project',
	templateUrl: './new-project.component.html',
	styleUrls: ['./new-project.component.scss'],
	providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class NewProjectComponent implements OnInit, OnDestroy, AfterViewInit {

	private status: string = 'New';
	projectId: string = null;
	project: any = null;
	index = 0;
	user: any;
	private userAccessed: boolean = false;
	ngUnsubscribe = new Subject();
	totalSections: number = 7;
	links: any[] = [
		{
			url: 'company',
			title: "companyDetails",
			text: "companyText",
			subText: '',
			authority: Authority.E,
			globalScope: true,
			requiresProject: false
		},
		{
			url: 'stages',
			title: "projectStages",
			text: "projectStageText",
			subText: "projectStageSubText",
			authority: Authority.U,
			globalScope: false,
			requiresProject: true
		},
		{
			url: 'users-permissions',
			title: "userPermissions",
			text: "userPermissionText",
			subText: "userPermissionSubText",
			authority: Authority.U,
			globalScope: false,
			requiresProject: true,
			id:'trainerUserPermission' //for trainer
		},
		{
			url: 'assets',
			title: "teamFiles",
			ext: "teamFilesText",
			subText: "teamFilesSubText",
			authority: Authority.U,
			globalScope: false,
			requiresProject: true
		},
		// {
		// 	url: 'notification-setting',
		// 	title: "Notifications",
		// 	text: "User Notification Setting",
		// 	subText: '',
		// 	authority: Authority.E,
		// 	globalScope: false
		// },
	];

	constructor(public service: ProjectService, private route: ActivatedRoute, private router: Router, public auth: AuthService,
		private breadcrumb: BreadcrumbService, private notificationService: NotificationService,
		public permissionService: PermissionService, private transferService: DataTransferBetweenComponentsService,
		private themeService: ThemeService,public stService: SelfTrainerService,) {
		// super();
		this.breadcrumb.addFriendlyNameForRoute('/projects', 'My Projects');
		this.breadcrumb.addFriendlyNameForRoute('/projects/new', 'New Project');
		this.breadcrumb.hideRoute('/projects/new/notification-setting');
		this.breadcrumb.hideRoute('/projects/new/company');
		this.breadcrumb.hideRouteRegex('/projects/new/details/*');
		this.breadcrumb.hideRoute('/projects/edit');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/notification-setting');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/company');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/details');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/stages');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/users-permissions');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/assets');
		this.breadcrumb.hideRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/assets');
		this.breadcrumb.hideRouteRegex('/projects/new/company*');
		this.breadcrumb.addCallbackForRouteRegex('/projects/edit/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*',
			(id) => id === this.service.project.id ? this.service.project.name : 'New Project');
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(project) => {
					this.project = project;
					this.totalSections = this.service.sections.length;
					if (this.totalSections == 0) this.totalSections = 7;
					if (this.service.projectOnloaded && !this.userAccessed) {
						this.setUserAccess();
					}
				}
			)

		this.route.params.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params) => {
			if (HelperService.isUUID(params['projectId'])) {
				this.projectId = params['projectId'];
				this.service.prepareProjectInit(this.projectId);
				this.status = 'Edit';
			}
		});

		this.route.url.subscribe(url => {
			if (this.service.project && this.service.project.id)
				this.project = this.service.project;
			this.totalSections = this.service.sections.length;
			if (this.totalSections == 0) this.totalSections = 7;
		});

		this.transferService.ngOnChangesBreadcrumbComponent();
	}

	setUserAccess() {
		if (this.project && this.user) {
			if (this.permissionService.hasAuthority(Authority.Z, null)) {
				return;
			}

			if (this.permissionService.hasAuthority(Authority.P, null)) {
				return;
			}

			this.links = _.filter(this.links, link => this.permissionService.hasAuthority(link.authority,
				link.globalScope ? null : this.project.id));
			this.userAccessed = true;
			this.router.navigate(['/projects', 'edit', this.project.id, this.links[0].url]);
		}
	}

	ngOnInit() {
		this.user = this.auth.getAuthUser();
		// for trainer
		this.approveVersionTrainer();
		/*
		EmitterService.get('project.saved').subscribe((project)=>{
			this.project = this.service.project;
			this.totalSections = this.service.sections.length;
		});
		*/
	}

	ngOnDestroy() {
		onLeaveFromProject.emit();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngAfterViewInit(): void {
		this.themeService.updateContent({
			backgroundColor: '',
			rightPane: null,
			backgroundImage: null,
			header: null
		});
	}

	/**
	 * Check if a step given is complete
	 *
	 * @param i
	 */
	private isComplete(i: number) {
		let index: number;
		this.links.map((link: any) => {
			if (this.isActive(link.url)) {
				index = this.links.indexOf(link);
				this.index = index;
			}
		});
		return i < index;
	}

	/**
	 * Check if an url given is active
	 *
	 * @param url
	 */
	private isActive(url: string): boolean {
		if (this.service.project.id)
			return this.router.isActive('/projects/edit/' + this.service.project.id + '/' + url, false);
		else
			return this.router.isActive('/projects/new/' + url, false);
	}


	/**
	 * Archive project
	 */
	private onArchiveProject() {
		this.notificationService.open({
			title: 'Archive Project',
			description: `Are you sure you want to archive this project?`,
			confirmBtn: 'Yes, archive',
			cancelBtn: 'Cancel'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			// Only if user clicked on Yes in previous popup
			if (confirm) {

				// Delete or archive project
				this.service.deleteOrArchiveProject(this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					() => {
						this.router.navigate(['/projects']);
					},
					(err: any) => {
						this.notificationService.open({
							title: 'Archive Project',
							description: 'There was an error trying to archive this project.\nPlease, try again.',
							confirmBtn: 'Accept'
						});
					})
			}
		});

		return false;
	}

	// for trainer
	async approveVersionTrainer() {
		isMissionComplete.subscribe((data: missionCompletionInterface) => {
			if (data.isComplete) {
				switch (data.name ) {
					case 'switchToUserPermission':
						if (data.elemenetId) {
							this.stService.hideTrainerModalAndStartTask();
						}
						break;
					default:
						break;
				}
			}
		});
	}

	// for trainer
	trainerUserPermission(){
		this.stService.hideTrainerModalAndStartTask()
	}
}
