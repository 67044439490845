import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'app/shared/services/helper.service';

@Pipe({
	name: 'searchMTMDropdownPipe'
})
export class PipeDropdownSearch implements PipeTransform {
	constructor(private helperService: HelperService) {}
	transform(value: any, arg?: any): any {
		return (value || [{ label: '' }]).filter((item: any) => HelperService.getNormalizedName((item || {}).label || '').toLowerCase().indexOf(HelperService.getNormalizedName(arg).toLowerCase()) > -1);
	}
}
