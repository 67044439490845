import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";

@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

	constructor(private route: Router, public auth: AuthService) { }

	/**
	 * Validate if user is not logged in. If user is logged in, then redirect to Main layout
	 * @param route
	 * @param state
	 * @returns {boolean}
	 */
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.auth.getAuthUser() !== null && this.auth.getAuthToken() !== null && !this.auth.isTokenExpired()) {
			if (state.url === "/entry/marketplace/build-request/ONE_SHOT/ADVERTISER") {
				this.auth.logout();
				return true;
			}
			this.route.navigate(['/projects']);
			return false;
		}
		if (!state.url.startsWith("/entry/auth")) {
			this.auth.logout();
		}
		return true;
	}
}
