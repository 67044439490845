import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import {
  SignedURL_FU_Base,
  WithSignedURLUploadInfo,
  WithUploadBaseDependencies
} from 'app/shared/bases/signed-url-file-upload.base';
import { AuthService } from 'app/shared/services/auth.service';
import { MovieTranslationService } from 'app/shared/services/movie-translation.service';
import { ProjectService } from 'app/shared/services/project.service';
import { listenerFileUploadCompleted } from 'app/shared/services/signed-url';
import { newSubtitleAdded, SubtitleGenerationService, subtitleThumbnailAdded, subtitleGenerated } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROOT_FOLDER, SUBTITLE_TYPE } from '../../subtitle.const';
import { FileTransferService } from '../../../../../shared/services/signed-url/file-transfer.service';

@Component({
	selector: 'mtm-subtitle-import',
	template: '<div></div>'
})
export class SubtitleImportComponent extends SignedURL_FU_Base implements OnInit, AfterViewInit, OnDestroy {
	@Input() importData: any;

	subtitle: any;
	items: any = [];

	selectedMasters: any;
	importMaster: any;

	ngUnsubscribe = new Subject();
	projectId: any;
	authUser: any;

	constructor(
		private service: SubtitleGenerationService,
		private movieTranslationService: MovieTranslationService,
		private projectService: ProjectService,
		authService: AuthService,
		transferService: FileTransferService,
	) {
    /*
		super(
			transferService,
			() => { return `/api/projects/${this.importData.projectId}/sections/${this.importData.sectionId}/subsections/${this.importData.subSectionId}/items/${this.items[this.items.length - 1]}/` },
			() => { return 'Subtitle Generation' },
			() => { return this.subtitle },
			() => { return this.importData.projectId },
			() => { return [" ", this.subtitle.name.replace(/[^a-zA-Z0-9 ]/g, "") || this.subtitle.id] }
		);
     */
    super(
      WithUploadBaseDependencies(authService,transferService),
      WithSignedURLUploadInfo({
        uploadPathGetter: ()=> `/api/projects/${this.importData.projectId}/sections/${this.importData.sectionId}/subsections/${this.importData.subSectionId}/items/${this.items[this.items.length - 1]}/`,
        labelGetter: ()=> 'Subtitle Generation',
        entityGetter: ()=> this.subtitle,
        projectIdGetter: ()=> this.importData.projectId,
        postUploadParamsGetter: ()=> [" ", this.subtitle.name.replace(/[^a-zA-Z0-9 ]/g, "") || this.subtitle.id]
      })
    );

		listenerFileUploadCompleted.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(this.startImport.bind(this));
	}

	ngOnInit(): void {
		this.projectId = this.projectService.project.id;
		this.authUser = this.authService.getAuthUser();
	}

	ngAfterViewInit(): void {
		this.selectedMasters = this.importData.selectedMasters;
		this.startImport();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	generateSubtitle(cb) {
		this.service.getSubtitleGenerationById(this.importData.projectId, this.importData.sectionId, this.importData.subSectionId, this.subtitle.id, this.subtitle.directoryId)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(subtitle => {
				this.subtitle = subtitle;
				subtitleThumbnailAdded.emit(subtitle);
				if (this.subtitle.type === SUBTITLE_TYPE.ITEM) {
					this.service.getSignedUrlInfo(this.importData.projectId, this.subtitle.editingFileId)
						.pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe((res: any) => {
							const { projectSubSectionId, directoryId, id } = this.subtitle;
							this.movieTranslationService.createMovieSubtitle(this.importData.sectionId, projectSubSectionId, directoryId, id, this.subtitle.editingFileId, this.subtitle.files[0].displayName, res.second, this.projectId, this.authUser.username)
								.pipe(
									takeUntil(this.ngUnsubscribe)
								).subscribe(res => {
									cb();
								});
						});
				}
			})
	}

	startImport(file?) {
		if (file && this.importData.subSectionId !== file.dbFileObject.subSectionId) {
			return;
		}
		if (this.items.length) {
			this.items.pop();
			if (this.importMaster) {
				this.generateSubtitle(() => {
					this.importMaster = void 0;
					if (this.items.length) {
						this.startReferenceUpload(this.subtitle.files);
						//this.startUploadFiles(false, false, true, this.subtitle.files);
					} else {
						this.startImport();
					}
				})
			} else {
				this.startImport();
			}
		} else {
			if (this.selectedMasters && !this.selectedMasters.length) {
				subtitleGenerated.emit({ import: true });
			} else {
				this.importMaster = this.selectedMasters.pop();
				this.service.saveSubtitleGenerations(this.importData.projectId, this.importData.sectionId, this.importData.subSectionId, {
					name: this.importMaster.items[0].displayName,
					editingFileId: this.importMaster.items[0].id,
					directoryId: this.importMaster.subtitle.directoryId || this.importData.directory.id || ROOT_FOLDER,
					type: SUBTITLE_TYPE.ITEM,
					language: this.importMaster.subtitle.language || 'en-US'
				}).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((res: any) => {
					this.subtitle = res;
					newSubtitleAdded.emit(res);
					// if (this.subtitle.directoryId && this.subtitle.directoryId && this.subtitle.directoryId !== ROOT_FOLDER) {
						// this.items.push(this.subtitle.directoryId);
					// }
					// This revised code checks if this.subtitle.directoryId is not equal to ROOT_FOLDER and only executes the subsequent code .
					// if the condition is true. This simplifies the condition and eliminates the duplication.
					if (this.subtitle.directoryId !== ROOT_FOLDER) {
						this.items.push(this.subtitle.directoryId);
					}
					this.items.push(this.subtitle.id);
					this.startReferenceUpload(this.importMaster.items);
					//this.startUploadFiles(false, false, true, this.importMaster.items);
				}, () => { });
			}
		}
	}
}
