export const SELECTORS = {
	'en_us_TERMSandCONDITIONS': 'en_us_TERMSandCONDITIONS',
	'en_us_TERMSofSALES': 'en_us_TERMSofSALES',
	'en_us_PRIVACYPOLICY': 'en_us_PRIVACYPOLICY',
	'en_us_TERMSofSERVICEPRODUCER': 'en_us_TERMSofSERVICEPRODUCER',
	'fr_TERMSandCONDITIONS': 'fr_TERMSandCONDITIONS',
	'fr_TERMSofSALES': 'fr_TERMSofSALES',
	'fr_PRIVACYPOLICY': 'fr_PRIVACYPOLICY',
	'fr_TERMSofSERVICEPRODUCER': 'fr_TERMSofSERVICEPRODUCER',
	CONTRATPRODUCTION: 'Contratdeproduction'
}
