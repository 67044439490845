import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { ApiService } from "app/shared/services/api.service";
import { HelperService } from "app/shared/services/helper.service";
import { NotificationService } from "app/shared/services/notification.service";
import { OverlayService } from "app/shared/services/overlayService";
import { environment } from "environments/environment";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

@Injectable()
export class VerificationService {
	baseUrl: string;
	validation: any = {};
	constructor(
		private http: Http,
		private notificationService: NotificationService,
		private overlayService: OverlayService,
		private router: Router
	) {
		this.baseUrl = environment.api.baseUrl;
	}

	accountVerification(username: string, code: string, type: string) {
		let headers = HelperService.getRegistrationHeaders();
		return this.http.post(`${this.baseUrl}/api/users/${username}/verification/${code}/${type}`, null, { headers: headers, withCredentials: true }).pipe(
			catchError(err => {
				return this.handleError(err)
			}));
	}

	reSendEmailVerificationCode(username: any) {
		let headers = HelperService.getRegistrationHeaders();
		return this.http.get(`${this.baseUrl}/api/users/${username}/verification/email`, { headers: headers, withCredentials: true })
			.pipe(catchError(err => {
				return this.handleError(err)
			}));
	}

	reSendPhoneVerificationCode(username: any) {
		let headers = HelperService.getRegistrationHeaders();
		return this.http.get(`${this.baseUrl}/api/users/${username}/verification`, { headers: headers, withCredentials: true })
		.pipe(catchError(err => {
				return this.handleError(err)
			}));
	}
	
	handleError(errorData: any) {
		if (errorData.status === 403 || errorData.status === 401) {
		  if (errorData.error.errorCode === 'NOT_PROJECT_MEMBER') {
			this.overlayService.showError('You don\'t have access to view this page', 'Access Denied');
		  } else {
			this.notificationService.open({
			  title: 'Session Expired',
			  notificationType: 'success',
			  centerHeader: true,
			  notificationInfo: 'Your session has expired. Please login to continue.',
			  confirmBtn: 'Login'
			}).subscribe(confirm => {
			  this.logout();
			});
		  }
		}
		return throwError(errorData.error);
	  }
	  

	logout() {
		this.http.post(`${this.baseUrl}/api/auth/logout`, null).subscribe(
			(data: any) => {
				console.log('logout success');
			},
			(err: any) => {
				console.log('logout error', err);
			}, () => {
				HelperService.deleteAuthCookie();
			}
		);
		let lang = localStorage.getItem('auth_user_language');
		localStorage.clear();
		sessionStorage.clear();
		if (lang) {
			localStorage.setItem('auth_user_language', lang)
		}
		this.router.navigateByUrl('/entry');
	}
}
