<div class="container">
	<div class="col">
		<div class="row">
			<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
		</div>
		<div class="row text-center justify-content-center">
			<h3>Complete Personal Info</h3>
		</div>
		<div class="row justify-content-center mt-4">
			<div class="card px-5 py-4 mt-1">
				<form class="verification-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
					<p class="h5 mb-3">To continue on MTM, please fill this form.</p>

					<!-- First name -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.firstName.invalid && submitted}">
							<input class="form-control" type="text" formControlName="firstName" [placeholder]="'firstName' | translate"
								[ngClass]="{'form-control-danger': formGroup.controls.firstName.invalid && submitted}">
							
							<span *ngIf="!(submitted && formGroup.controls.firstName.invalid)">{{'firstName' | translate }}</span>
							<span *ngIf="submitted && formGroup.controls.firstName.invalid" class='text-danger'>Enter a
								valid first name</span>
						</label>
					</div>
					<!-- / First name -->

					<!-- Last name -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.lastName.invalid && submitted}">
							<input class="form-control" type="text" formControlName="lastName" [placeholder]="'lastName' | translate"
								[ngClass]="{'form-control-danger': formGroup.controls.lastName.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.lastName.invalid)">{{'lastName' | translate }}</span>
							<span *ngIf="submitted && formGroup.controls.lastName.invalid" class='text-danger'>Enter a
								valid last name</span>
						</label>
					</div>
					<!-- / Last name -->

					<!-- Company -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.company_name.invalid && submitted}">
							<input class="form-control" type="text" formControlName="company_name" placeholder=" "
								readonly
								[ngClass]="{'form-control-danger': formGroup.controls.company_name.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.company_name.invalid)">{{'Company' | translate}}</span>
							<span *ngIf="submitted && formGroup.controls.company_name.invalid" class='text-danger'>Enter
								a valid company</span>
						</label>
					</div>
					<!-- / Company -->

					<!-- Job Title -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.job_title.invalid && submitted}">
							<input class="form-control" type="text" formControlName="job_title" [placeholder]="'jobTitle' | translate"
								[ngClass]="{'form-control-danger': formGroup.controls.job_title.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.job_title.invalid)">{{'jobTitle' | translate }}</span>
							<span *ngIf="submitted && formGroup.controls.job_title.invalid" class='text-danger'>Enter a
								valid job title</span>
						</label>
					</div>
					<!-- / Job Title -->

					<!-- Email -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.email.invalid && submitted}">
							<input class="form-control" type="text" formControlName="email" [placeholder]="'Email' | translate"
								[ngClass]="{'form-control-danger': formGroup.controls.email.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.email.invalid)">Email address</span>
							<span *ngIf="submitted && formGroup.controls.email.invalid" class='text-danger'>Enter a
								valid email address</span>
						</label>
					</div>
					<!-- / Email -->

					<!-- Phone -->
					<div class="row">
						<div class="form-group w-100"
							[ngClass]="{'has-danger': submitted && formGroup.controls.phone.invalid }">
							<selector-mtm-tel-input [cardForm]=formGroup [cardFormKey]="'phone'" #phoneInput>
							</selector-mtm-tel-input>
							<!-- <input class="form-control" type="tel" id="phone" formControlName="phone"
								[ngClass]="{'form-control-danger': submitted && (!isValidPhone() || formGroup.controls.phone.invalid)}"> -->
						</div>
					</div>
					<!-- / Phone -->

					<!-- Password -->
					<div class="row">
						<label class="form-group has-float-label mb-2"
							[ngClass]="{'has-danger': formGroup.controls.password.invalid && submitted}">
							<input class="form-control" type="password" formControlName="password" placeholder=" " passwordToggle
								[ngClass]="{'form-control-danger': formGroup.controls.password.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.password.invalid)">Password</span>
							<span *ngIf="submitted && formGroup.controls.password.invalid" class="text-danger">Enter a
								valid password</span>
						</label>
						<mtm-password-strength-meter class="w-100" [passwordToCheck]="formGroup.controls.password.value" (passwordStrength)="onPasswordStrengthChanged($event)"></mtm-password-strength-meter>
					</div>
					<!-- / Password -->
					<div class="row">
						<mtm-password-checker [companyId]="companyId" class="w-100" [showValidation]="formGroup.controls.password.dirty" [password]="formGroup.controls.password.value" (passwordValidated)="passwordValidated($event)"></mtm-password-checker>
					</div>

					<!-- Repeat password -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.rePassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="rePassword" placeholder=" " passwordToggle
								[ngClass]="{'form-control-danger': formGroup.controls.rePassword.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.rePassword.invalid)">Repeat password</span>
							<span *ngIf="submitted && formGroup.controls.rePassword.invalid" class="text-danger">Both
								passwords should match</span>
						</label>
					</div>
					<div class="row">
						<mtm-terms-conditions-check [companyType]="companyType" #mtmTermsConditionCheck
							style="max-width: 450px; display: inline-block">
						</mtm-terms-conditions-check>
					</div>
					<div class="row justify-content-between my-2 text-center">
						<button type="submit" [disabled]=" !mtmTermsConditionCheck.isTermsCondition"
							class="btn btn-lg btn-primary btn-block">Continue</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>