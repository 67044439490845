import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	templateUrl: './notification-modal.component.html',
	styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

	title: string = null;
	notificationType: string = null;
	subtitle: string = null;
	description: string = null;
	description2: string = null;
	description3: string = null;
	description4: string = null;
	question: string = null;
	question2: string = null;
	notificationInfo: string = null;
	note: string = null;
	paymentStatus: string = null;
	paymentMade: string = null;
	amount: string = null;
	currency: string = null;
	confirmBtn: string = null;
	isCloseButtonHidden: boolean;
	cancelBtn: string = null;
	cssClass: string;
	totalMTM: string = null;
	paymentMethod: string = null;
	downpaymentAmount: string = null;
	icon: any;
	centerHeader: boolean = false;
	noHeaderBorder: boolean = false;
	noteInfo: any;
	iconSize: any = 24;
	descriptionMeta: any;
	descriptionMeta2: any;
	descriptionMeta3: any;
	subDescription: any;
	subDescription2: any;
	subDescription3: any;
	closeModalHandlerResult: any;
	hideButton: any;
	title2: any;

	reverseButton: boolean = false;

	constructor(private activeModal: NgbActiveModal, private route: Router) { }

	ngOnInit() { }


	setParams(data: any) {
		if (data.title)
			this.title = data.title;

		if (data.subtitle)
			this.subtitle = data.subtitle;

		if (data.title2)
			this.title2 = data.title2;

		if (data.description)
			this.description = data.description;

		if (data.description2)
			this.description2 = data.description2;

		if (data.description3)
			this.description3 = data.description3;

		if (data.description4)
			this.description4 = data.description4;

		if (data.note)
			this.note = data.note;

		if (data.paymentMade)
			this.paymentMade = data.paymentMade;

		if (data.amount)
			this.amount = data.amount;

		if (data.currency)
			this.currency = data.currency;

		if (data.paymentStatus)
			this.paymentStatus = data.paymentStatus;

		if (data.downpaymentAmount)
			this.downpaymentAmount = data.downpaymentAmount;

		if (data.totalMTM)
			this.totalMTM = data.totalMTM;

		if (data.paymentMethod)
			this.paymentMethod = data.paymentMethod

		if (data.isCloseButtonHidden)
			this.isCloseButtonHidden = data.isCloseButtonHidden;

		if (data.confirmBtn)
			this.confirmBtn = data.confirmBtn;

		if (data.notificationInfo)
			this.notificationInfo = data.notificationInfo;

		if (data.question)
			this.question = data.question;

		if (data.question2)
			this.question2 = data.question2;

		if (data.cancelBtn)
			this.cancelBtn = data.cancelBtn;

		if (data.icon) {
			this.icon = data.icon;
		}

		if (data.centerHeader) {
			this.centerHeader = true;
		}

		if (data.notificationType) {
			this.notificationType = data.notificationType;
		}

		if (data.noteInfo) {
			this.noteInfo = data.noteInfo;
		}

		if (data.iconSize) {
			this.iconSize = data.iconSize;
		}

		if (data.noHeaderBorder) {
			this.noHeaderBorder = data.noHeaderBorder;
		}

		if (data.descriptionMeta) {
			this.descriptionMeta = data.descriptionMeta;
		}

		if (data.closeModalHandlerResult) {
			this.closeModalHandlerResult = data.closeModalHandlerResult;
		}

		if (data.hideButton) {
			this.hideButton = data.hideButton;
		}

		if (data.subDescription) {
			this.subDescription = data.subDescription;
		}

		if (data.subDescription2) {
			this.subDescription2 = data.subDescription2;
		}

		if (data.subDescription3) {
			this.subDescription3 = data.subDescription3;
		}

		if (data.reverseButton) {
			this.reverseButton = data.reverseButton;
		}

		this.cssClass = data.cssClass ? data.cssClass : 'default';
	}


	onConfirmModal() {
		if (this.title === 'User Blocked') {
			this.activeModal.close({ reason: 'OK' } && this.route.navigate(['/projects']));
		} else {
			this.activeModal.close(this.closeModalHandlerResult ? { reason: 'OK' } : true);
		}
	}


	onCancelModal(reason: string) {
		this.activeModal.close(this.closeModalHandlerResult ? { reason } : false);
	}
}
