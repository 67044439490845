import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SubscriptionUpgradeProduceComponent } from "./pages/produce/produce.component";
import { SubscriptionUpgradeComparePlanComponent } from "./pages/compare-plan/compare-plan.component";
import { SubscriptionUpgradeSelectPlanComponent } from "./pages/select-plan/select-plan.component";
import { SubscriptionUpgradeConfirmPlanComponent } from "./pages/confirm-plan/confirm-plan.component";
import { SubscriptionUpgradePaymentComponent } from "./pages/payment/payment.component";
import { UpgradePlanGuard } from "../core/upgrade-plan.guard";

export const routes: Routes = [{
	path: '',
	redirectTo: 'select-plan',
	pathMatch: 'full'
}, {
	path: 'select-plan',
	component: SubscriptionUpgradeSelectPlanComponent,
}, {
	path: 'compare-plan',
	component: SubscriptionUpgradeComparePlanComponent,
	canActivate: [UpgradePlanGuard]
}, {
	path: 'confirm-plan',
	component: SubscriptionUpgradeConfirmPlanComponent,
	canActivate: [UpgradePlanGuard]
}, {
	path: 'payment',
	component: SubscriptionUpgradePaymentComponent,
	canActivate: [UpgradePlanGuard]
}, {
	path: 'produce',
	component: SubscriptionUpgradeProduceComponent,
	canActivate: [UpgradePlanGuard]
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SubscriptionUpgradeRoutingModule {
}
