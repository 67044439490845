<div class="modal-header">
	<h4 class="modal-title">Want to find out more about MTM?</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.close(responseMsg)">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form class="signup-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()" [hidden]="responseMsg">
		<div class="container">
			<div class="row">
				<div class="col mx-2">
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': signupForm.controls.firstName.invalid && submitted}">
						<input class="form-control" type="text" formControlName="firstName" placeholder=" "
							[ngClass]="{'form-control-danger': signupForm.controls.firstName.invalid && submitted}">
						<span *ngIf="!(submitted && formErrors.firstName)">First name</span>
						<span class="text-danger"
							*ngIf="submitted && formErrors.firstName">{{formErrors.firstName}}</span>
					</label>
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': signupForm.controls.lastName.invalid && submitted}">
						<input class="form-control" type="text" formControlName="lastName" placeholder=" "
							[ngClass]="{'form-control-danger': signupForm.controls.lastName.invalid && submitted}">
						<span *ngIf="!(submitted && formErrors.lastName)">Last name</span>
						<span class="text-danger"
							*ngIf="submitted && formErrors.lastName">{{formErrors.lastName}}</span>
					</label>
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': signupForm.controls.company.invalid && submitted}">
						<input class="form-control" type="text" formControlName="company" placeholder=" "
							[ngClass]="{'form-control-danger': signupForm.controls.company.invalid && submitted}">
						<span *ngIf="!(submitted && formErrors.company)">Company name</span>
						<span class="text-danger" *ngIf="submitted && formErrors.company">{{formErrors.company}}</span>
					</label>
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': signupForm.controls.email.invalid && submitted}">
						<input class="form-control" type="text" formControlName="email" placeholder=" "
							[ngClass]="{'form-control-danger': signupForm.controls.email.invalid && submitted}">
						<span *ngIf="!(submitted && formErrors.email)">Email address</span>
						<span class="text-danger" *ngIf="submitted && formErrors.email">{{formErrors.email}}</span>
					</label>
					<div class="row">
						<div class="form-group w-100"
							[ngClass]="{'has-danger': submitted && signupForm.controls.phone.invalid}">
							<selector-mtm-tel-input [cardForm]=signupForm [cardFormKey]="'phone'">
							</selector-mtm-tel-input>
							<!-- <input class="form-control" type="tel" id="phone" formControlName="phone" [ngClass]="{'form-control-danger': submitted && (!isValidPhone() || signupForm.controls.phone.invalid)}"> -->
						</div>
					</div>
				</div>
				<div class="col mx-2">
					<label class="form-group row has-float-label"
						[ngClass]="{'has-danger': signupForm.controls.message.invalid && submitted}">
						<textarea class="form-control" placeholder=" " formControlName="message" rows="10"></textarea>
						<span *ngIf="!(submitted && formErrors.message)">Message - optional</span>
						<span class="text-danger" *ngIf="submitted && formErrors.message">{{formErrors.message}}</span>
					</label>
					<div class="row">
						<button type="submit" class="btn btn-lg btn-green btn-block">
							<h6 class="mb-0">
								Request invitation to discover MTM
							</h6>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="row mt-2 response-msg" *ngIf="responseMsg">
		<div class="col text-center">
			<h4>
				<pre class="mb-4">{{ responseMsg }}</pre>
			</h4>
			<button class="btn btn-primary btn-lg" (click)="activeModal.close(responseMsg)">Continue</button>
		</div>
	</div>
</div>