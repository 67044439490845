<div class="header-container">
	<div class="px-4 pb-4 row justify-content-md-center text-center ">
		<div class="header-wrapper">
			<div class="image-wrapper">

				<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=true
					[isRedirectImageLoadError]=false [imageURL]="avatarUrl ? avatarUrl : ''"
					bottomHtmlText="'Click here to upload workspace logo'" (files)="fileChangeListener($event)"
					[style.maxWidth]="'90px'" [style.maxHeight]="'90px'" [style.minWidth]="'90px'"
					[style.minHeight]="'90px;'" #avatarUploader>
				</mtm-upload-file>
				<div class="edit-avatar-icon" (click)="editAvatar()">
					<div class="fa fa-pencil"></div>
				</div>

			</div>

			<form [formGroup]="headerForm" (ngSubmit)="save()" class="workspace-form">

				<input type="text" class="mb-4 name" placeholder="{{'workspace_workspaceName' | translate}}"
					formControlName="name">

			</form>

			<div class="d-flex">
				<div class="buttons w-100 position-relative">
					<button class="cancel" (click)="cancel()">{{'cancel'|translate}}</button>

					<button class="save" (click)="save()">{{'saveChanges'|translate}}</button>

					<button class="remove"
						(click)="deleteWorkspace()">{{'workspace_edit_deleteWorkspace'|translate}}</button>

					<div class="position-absolute d-flex align-items-center  text-uppercase ml-auto cover-buttons">
						<button type="button" class="edit-cover-icon" (click)="editCover()">
							{{'workspace_edit_editCover'| translate}}
							<span><i class="fa fa-pencil"></i></span>
						</button>

						<button type="button" class="edit-cover-icon ml-2" (click)="removeCover()" *ngIf="coverUrl">
							{{'workspace_edit_removeCover'| translate}}
							<span><i class="fa fa-times"></i></span>
						</button>
					</div>
				</div>
			</div>

			<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [hidden]="true" #coverUploader
				(files)="fileChangeListener($event, 'cover')"></mtm-upload-file>


		</div>


	</div>


</div>