export interface EditUpdateDto {
	id?: string;
	title: string;
	description?: string;
	dateSubmitted?: string;
	approved?: boolean;
	files?: any[];
	typeOfEdit: string;
	batch: boolean;
}

export interface EditUpdateParams {
	edit: EditUpdateDto;
	projectId: string;
	sectionId: string;
	subsectionId: string;
	topic?: string;
	topicId: string;
}

export interface BatchEditParams {
	projectId: string;
	sectionId: string;
	subsectionId: string;
	items: BatchEditDto[];
	//TODO: need to remove in backend API
	topic?: string;
	topicId: string;
}

export interface BatchEditDto {
	projectId: string;
	projectSubSectionId: string;
	title: string;
	typeOfEdit: string;
	description?: string;
}

export enum VersionStatus {
	NoStatus = 'NO_STATUS_YET',
	OnHold = 'ON_HOLD',
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
	Neutral = 'NEUTRAL',
}
