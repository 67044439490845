import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimelineService } from 'app/shared/services/timeline.service';
import { Subject } from 'rxjs';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { AuthService } from 'app/shared/services/auth.service';
import { UserTourService } from 'app/shared/services/user-tour.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';

export enum createBtnOptions {
  PROJECT = 'Project',
  CAMPAIGN = 'Campaign',
  DRIVE_CAMPAIGN = 'Drive Campaign',
  WORKSPACE = 'Workspace'
}

@Component({
  selector: 'mtm-analytics-drivecampaign-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit , OnDestroy{

  images = {
    analytics: '../../../assets/img/project-v2/analytics.png',
    campaignDriveDashboard: '../../../assets/img/project-v2/new-project.png',
    dashboard: '../../../assets/img/project-v2/workspace-img.png',
    timeline: '../../../assets/img/project-v2/timeline-header.png',
    ysl: '../../../assets/img/project-v2/ysl-dashboard.png',
    taskproject: '../../../assets/img/project-v2/task-project-header.png',
    scenario2: '../../../assets/img/project-v2/scenario2Dashboard.png',
    scenario4ysl: '../../../assets/img/project-v2/scenario4yslheader.png'

  }
  // headerImage: string = '../../../assets/img/project-v2/black.png';
  headerImage: string
  isDashboard: boolean = true;
  title: string = '';
  subTitle: string = ''
  timelineId: string;
  ngUnsubscribe = new Subject();
  workspaceId: string;
  upperCase: boolean = false;
  isAnalytics: boolean = false;
  authUser: any;
  isHr: boolean = false;
  isYsl: boolean = false;
  isNotificationView: boolean = false;
  isTaskProject: boolean = false;
  isRecap: boolean = false
  isGeneral: boolean = false;
  cacheBuster: string = new Date().getTime().toString();
  isWorkspaceDashboard: boolean = false;
  searchString: string;
  headerTitle: string = '';
  headerLabel: string = '';
  isArchived: boolean = false;
  isFavorites: boolean = false;
  isAll: boolean = true;
  isDriveCampaignComponet: boolean = false;
  isCampaignComponent: boolean = false;
  isProjectComponent: boolean = false;
  isSharedComponent: boolean = false;
  showSideNav: boolean = false;
  isWorkspaceActive: boolean = false;
  isCreateActive: boolean = false;
  createBtnRouts = Object.values(createBtnOptions);
  modal: NgbModalRef;
  canCreateProject: boolean = false;
  showCampaign: boolean ;
  showDriveCampaign: boolean;

  constructor(
    private timelineService: TimelineService,
    public router: Router,
    private modalService: NgbModal,
    private projectV2Service: ProjectV2ServiceService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private userTourService: UserTourService,
    public stService: SelfTrainerService,
  ) { }


  ngOnInit() {
    // this.isWorkspaceDashboard = false;
    // // this.showSideNav = false;
    this.canCreateProject = ((this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P,null));
    const authUser = this.authService.getAuthUser();
    this.showCampaign = authUser.campaign;
    this.showDriveCampaign = authUser.drivecampaign;
    this,this.timelineService.resetTabsEmmiter.subscribe(data =>{
      this.allItems();
    })
    let image = this.timelineService.getComponentDetail();
    this.timelineService.isDriveNav.subscribe(data => {
      this.showSideNav = data;
    });
    this.timelineService.workspaceDashboardEmitter.subscribe(data => {
      this.isWorkspaceDashboard = data;
      this.isWorkspaceActive = true;
      this.isSharedComponent = false;
      this.isProjectComponent = false;
      this.isCampaignComponent = false;
      this.isDriveCampaignComponet = false;
    });
    // remove header title if  change view
    this.headerTitle = '';
    this.timelineService.imageEventEmitter.subscribe((data: any) => {
      this.headerImage = data.url + `?v=${this.cacheBuster}`; //here we are add ?v=${this.cacheBuster}  asquery params for latest image cacheBuster is current time
      this.title = data.userName;
      this.subTitle = data.title;
      this.isTaskProject = data.isTaskProject;
      this.isDashboard = data.isDashboard;
      this.isGeneral = data.isGeneral;
      this.isRecap = data.isRecap;
      this.headerTitle = data.headerTitle;
      if (this.headerTitle) {
        switch (this.headerTitle) {
          case 'Drive Campaigns':
            this.isDriveCampaignComponet = true;
            this.isCampaignComponent = false;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_driveCampaigns';
            break;
          case 'Campaigns':
            this.isCampaignComponent = true;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_campaigns';
            break;
          case 'Projects':
            this.isProjectComponent = true;
            this.isWorkspaceActive = false;
            this.isSharedComponent = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'projects';
            break;
          case 'Shared':
            this.isSharedComponent = true;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_shared';
            break;
          case 'Workspaces':
            this.isWorkspaceDashboard = true;
            this.isWorkspaceActive = true;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.headerLabel = 'workspaces';
            break;
          default:
            break;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.userTourService.destroyTour();
  }

  searchItem() {
    this.timelineService.searchEventEmitter.emit(this.searchString);
  }

  allItems() {
    this.isArchived = false;
    this.isFavorites = false;
    this.isAll = true;
    this.timelineService.emitIsArchived(false);
    this.timelineService.emitIsFavorites(false);
    this.timelineService.emitIsAll(true);
  }

  favoriteItems() {
    this.isArchived = false;
    this.isFavorites = true;
    this.isAll = false;
    this.timelineService.emitIsAll(false);
    this.timelineService.emitIsArchived(false);
    this.timelineService.emitIsFavorites(true);
  }

  getArchiveProjects() {
    this.isArchived = true;
    this.isFavorites = false;
    this.isAll = false;
    this.timelineService.emitIsAll(false);
    this.timelineService.emitIsFavorites(false);
    this.timelineService.emitIsArchived(true);
  }

  setHeaderTitle(title: string,componentName: string) {
    this.headerTitle = title;
    this.timelineService.componentName.next({
      componentName: componentName,
      bannerUrl: ''
    });
  }

  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  createNew() {
    this.isCreateActive = !this.isCreateActive;
  }

  createNewItem(event: string) {
    this.isCreateActive = !this.isCreateActive;
    switch (event) {
      case createBtnOptions.PROJECT:
        this.router.navigate(['/projects/new/company'])
        break;
      case createBtnOptions.CAMPAIGN:
        this.modal = this.modalService.open(CreateCampaignComponent, { size: 'lg' });
        this.modal.result.then(value => {
        })
        break;
      case createBtnOptions.DRIVE_CAMPAIGN:
        this.projectV2Service.driveCoordinatorMilestone = undefined;
        this.projectV2Service.timelineId = null;
        this.projectV2Service.updateFormData({}, {})
        this.router.navigate([`workspaces/campaigndrive/${this.workspaceId}/general`]);
        this.timelineService.isDriveNav.emit(false);
        break;
      case createBtnOptions.WORKSPACE:
        this.router.navigate(['/workspace/new']);
        break;
      default:
        break;
    }
  }
}
