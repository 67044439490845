import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubscriptionService } from "../../../services/subscription.service";
import { BILLING_TYPE, PLANS } from "../../../models/const";
import { PlanInfo } from "../../../models/plan";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";

@Component({
	selector: 'mtm-upgrade-compare-plan',
	templateUrl: './compare-plan.component.html',
	styleUrls: ['./compare-plan.component.scss']
})
export class SubscriptionUpgradeComparePlanComponent implements OnInit, OnDestroy {
	newPlan: PlanInfo;
	prevPlan: PlanInfo;
	newPlanCode: string = PLANS.STANDARD;
	prevPlanCode: string = PLANS.BUSINESS;
	plans: PlanInfo[] = [];
	private ngUnsubscribe = new Subject();
	prevPeriod: string = BILLING_TYPE.MONTHLY;
	currentPeriod: string = BILLING_TYPE.MONTHLY;
	currentSubscription: any;

	constructor(public subscriptionService: SubscriptionService, private authService: AuthService, private router: Router) {
		this.currentSubscription = this.authService.getAuthUserSubscription();
	}

	ngOnInit(): void {

		if (!this.subscriptionService.upgradeData.previousPlan || !this.subscriptionService.upgradeData.plan) {
			this.router.navigate(['/subscription/upgrade']);
			return;
		}

		if (this.subscriptionService.upgradeData.plan)
			this.newPlanCode = this.subscriptionService.upgradeData.plan.code;
		if (this.subscriptionService.upgradeData.previousPlan)
			this.prevPlanCode = this.subscriptionService.upgradeData.previousPlan.code;
		this.prevPeriod = this.subscriptionService.upgradeData.previousBillingType || BILLING_TYPE.MONTHLY;
		this.currentPeriod = this.subscriptionService.upgradeData.billingType || BILLING_TYPE.MONTHLY;
		this.initPlan();
	}

	initPlan() {
		this.prevPlan = { ... this.subscriptionService.upgradeData.previousPlan };
		this.newPlan = { ... this.subscriptionService.upgradeData.plan };
		if (this.newPlan.code === PLANS.FREE) {
			this.currentPeriod = BILLING_TYPE.MONTHLY;
		}

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}
}
