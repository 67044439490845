export const PLANS = {
	FREE: 'FREE_PLAN',
	STANDARD: 'STANDARD_PLAN',
	BUSINESS: 'BUSINESS_PLAN',
	ENTERPRISE: 'ENTERPRISE_PLAN'
}

export const BILLING_TYPE = {
	MONTHLY: 'monthlyPrice',
	QUARTERLY: 'quarterlyPrice',
	SEMI_ANNUAL: 'biannualPrice',
	ANNUAL: 'annualPrice'
}

export const SUBSCRIPTION_LIMIT = {
	ACTIVE_STORAGE: 'activeStorage',
	ARCHIVAL_STORAGE: 'archivalStorage',
	NUMBER_OF_FILES: 'numberOfFiles',
	LICENSE_USERS: 'users'
}

export function getBillingPeriodMultiplier(value: string): number {
	switch (value) {
		case BILLING_TYPE.QUARTERLY:
			return 3;
		case BILLING_TYPE.SEMI_ANNUAL:
			return 6;
		case BILLING_TYPE.ANNUAL:
			return 12;
		default:
			return 1;
	}
}