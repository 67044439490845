import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { Router } from "@angular/router";
import * as moment from 'moment';
import 'moment-timezone';
import { LoadingBase } from 'app/shared/bases/loading.base';
import { Authority, PermissionService } from "../../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
	selector: 'mtm-project-assets',
	templateUrl: './project-assets.component.html',
	styleUrls: ['./project-assets.component.scss']
})

export class ProjectAssetsComponent extends LoadingBase implements OnInit, OnDestroy {
	hrFiles: any;
	ngUnsubscribe = new Subject();
	activeRequest: any = 0;

	constructor(public service: ProjectService, public permissionService: PermissionService,
		private router: Router) {
		super();
		onLoadProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.loadFiles();
		})
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	async getFiles(projectId: any, sectionId: any) {
		let response = await this.service.getFilesBySectionId(projectId, sectionId);
		this.activeRequest--;
		return _.get((response as any).children, '[0].children', []);
	}

	ngOnInit() {
		if (this.service.project.id) {
			// Check if user has PM access
			if (!this.permissionService.hasAuthority(Authority.U)) {
				this.router.navigate(['projects', this.service.project.id]);
			}
			this.loadFiles();
		}
	}

	ngAfterViewInit() {
		this.initLoadingParameters(undefined, undefined, ['w-100']);
	}

	loadFiles() {
		let sections = _.uniqBy(this.service.sections, 'id') || [];
		this.hrFiles = {
			id: this.service.project.projectId,
			name: this.service.project.name,
			files: [],
			children: []
		}
		setTimeout(async () => {
			for (let i = 0; i < sections.length; i++) {
				if (sections[i].id) {
					this.activeRequest++;
					this.hrFiles.children.push({
						id: sections[i].id,
						name: sections[i].name,
						files: [],
						children: await this.getFiles(this.service.project.id, sections[i].id)
					});
				}
			}
		}, 0);
	}

	/**
	 * When save project, redirect to project dashboard page
	 */
	onSaveProject(): void {
		this.router.navigate(['projects/' + this.service.project.id + '/dashboard']);
	}


	getUploadedDate(time: number): Date {
		return moment(time).toDate();
	}

	returnToProject() {
		this.router.navigate(['projects', this.service.project.id]);
	}
}
