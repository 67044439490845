import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'hr-preview-subcmp',
	templateUrl: './file-preview.component.html',
	styleUrls: ['./file-preview.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HRPreviewFile {
	@Input() file: any;
	@Input() height: any;

	constructor() { }


	ngOnInit() {

	}

	ngOnChanges() {
		if (!this.file.id) {
			this.file.contentType = this.file.type;
		}
	}
}
