import { Injectable } from "@angular/core";
import { UntypedFormGroup, AbstractControl } from "@angular/forms";

@Injectable({
	providedIn: 'root'
})
export class ErrorMessageService {

	private validationMessages = {
		'required': 'Required error',
		'minlength': 'Min length error',
		'maxlength': 'Max length error',
	};


	/**
	 * Return list of form errors associated with the form group
	 * @param form
	 * @returns {{}}
	 */
	static getFormErrors(form: UntypedFormGroup) {

		let formErrors = {};

		// Build form error array based on controls
		for (const control in form.controls)
			formErrors[control] = null;

		for (const field in formErrors) {
			const control = form.get(field);
			formErrors[field] = '';

			// Get error for each field
			if (control && !control.valid)
				formErrors[field] = this.getErrorByKey(control.errors);
		}
		return formErrors;
	}


	/**
	 * Return customized string error based on error key and params
	 * @param errors
	 * @returns {any}
	 */
	static getErrorByKey(errors: any): string {

		if (errors) {
			if (errors.required)
				return 'Required error';
			else if (errors.minlength)
				return 'Min length error (required: ' + errors.minlength.requiredLength + ')';
			else if (errors.maxlength)
				return 'Max length error (required: ' + errors.maxlength.requiredLength + ')';
			else
				return 'Error';
		}

		return null;
	}

}
