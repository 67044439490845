import * as _ from 'lodash';
import { Component, Input } from '@angular/core';
import { TransferStatus, MTMCustomFile } from 'app/shared/services/signed-url';
import { FileTransferService } from '../../../services/signed-url/file-transfer.service';
import { Router } from '@angular/router';

@Component({
	selector: 'selector-file-upload-item',
	templateUrl: './file-upload-item.component.html',
	styleUrls: ['./file-upload-item.component.scss', '../common/common.scss']
})
export class FileUploadItem  {
	@Input() file: MTMCustomFile;

	isInfoContainerVisiblity: boolean = false;
	isFileInfo: boolean = false;
	isFileRemove: boolean = false;

	UPLOAD_STATUS_CODES = TransferStatus;
	wasInactive: boolean = false;
	resumableStatusCodes = [TransferStatus.PAUSED, TransferStatus.ERROR, TransferStatus.INACTIVE];

	constructor(private transferService: FileTransferService, private router: Router) {

	}

	onMake(file: MTMCustomFile) {
		switch (file.uploadStatusCode) {
			case TransferStatus.SENDING:
				file.cancelUpload(file);
				break;
			case TransferStatus.ERROR:
				file.onRefreshUploading(file);
				break;
			case TransferStatus.PAUSED:
				//will use for resumable uploading
				break;
			default:
				break;
		}
	}

	infoContainerVisiblity(status) {
		this.isInfoContainerVisiblity = status;
	}

	setFileInfoStatus(status) {
		if (this.isFileRemove)
			return;
		this.infoContainerVisiblity(status);
		this.isFileInfo = status;
		this.isFileRemove = false;
	}

	getFileTypeIcon(fileType) {
		return "assets/img/icons" + fileType.substr(fileType.lastIndexOf("/")) + ".svg";
	}

	onMouseEnterInfo() {
		this.setFileInfoStatus(true);
	}

	onMouseLeaveInfo() {
		this.setFileInfoStatus(false);
	}

	setIsFileRemove() {
		this.isFileRemove = !this.isFileRemove;
		this.isFileInfo = false;
		this.infoContainerVisiblity(this.isFileRemove);
	}

	onRemoveFileFromList() {
		this.setIsFileRemove();
	}

	stopEvent(e) {
		e.preventDefault();
		e.stopPropagation()
	}

	onCancelRemove(e) {
		this.stopEvent(e);
		this.setIsFileRemove();
	}

	onRemoveFromList(e) {
		this.stopEvent(e);
		//this.file.removeFromUploadingFiles(this.file);
		this.transferService.cancelUpload(this.file).subscribe(() => {
			//TODO: check usage
			this.setIsFileRemove();
		});
	}

	pauseUpload(file: MTMCustomFile) {
		this.transferService.pauseUpload(file);
	}

	resumeUpload(file: MTMCustomFile) {
		this.transferService.resumeUpload(file);
	}

	goToSource(){
		if(! this.file?.parentUrl){
			return;
		}

		this.router.navigate([this.file.parentUrl]);
	}
}
