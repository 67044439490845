import { EventEmitter, HostListener, Injectable, Output, Renderer2, RendererFactory2 } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Observable, Observer, Subject, catchError, map, of } from 'rxjs';
import Shepherd from 'shepherd.js';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

export interface StepButton {
  class?: string;
  text?: Button;
  isLoaded?: boolean;
  action?: () => void;
  actionOption?: ActionOption;
  secondary?: boolean;
  disabled?: boolean;
  type: string
}

export const defaultStepOptions = {
  classes: "shepherd-theme-arrows custom-default-class",
  scrollTo: false,
  cancelIcon: {
    enabled: false
  }
};

export interface TourSteps {
  id: string;
  attachTo: {
    element: string,
    on: Shepherd.Step.PopperPlacement
  },
  class: string;
  title?: string;
  text: string;
  showModal?: boolean;
  button?: StepButton[];
  highlightClass?: string;
}

type ActionOption = 'next' | 'complete' | 'skip' | 'exit' | 'back';
type Button = 'NEXT' | 'COMPLETED' | 'SKIP' | 'EXIT' | 'DONE' | 'GO';

@Injectable({
  providedIn: 'root'
})

export class UserTourService {
  public buttonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tourCompleted: EventEmitter<void> = new EventEmitter<void>();
  public isTourStart: EventEmitter<boolean> = new EventEmitter<boolean>();
  public getElementId: EventEmitter<string> = new EventEmitter<string>();
  public helperData: EventEmitter<object> = new EventEmitter<object>();

  private clickSubject: Subject<number> = new Subject<number>();
  private modalOverlayElement: HTMLElement | null = null;
  private clickCount: number = 0;
  StepOptions: Shepherd.Step.StepOptions
  builtInButtons = {
    cancel: {
      classes: "custom-exit-button cancel-button",
      text: "SKIP",
      type: "cancel",
    },
    next: {
      classes: "next-button",
      text: "Next",
      type: "next",
    },
    back: {
      classes: "back-button",
      secondary: true,
      text: "Back",
      type: "back",
    }
  }

  private renderer: Renderer2;
  private shepherd: Shepherd.Tour;
  private tourSteps: TourSteps[] = [];

  userTourActivity: any[] = [];
  opacityValue: number;
  tourID: any;

  constructor(
    private readonly shepherdService: ShepherdService,
    private rendererFactory: RendererFactory2,
    private http: HttpClient) {
    this.shepherd = new Shepherd.Tour({
      defaultStepOptions: {
        classes: 'shepherd-theme-arrows',
        scrollTo: true
      },
      useModalOverlay: true,
    });
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  trackUserTourActivity(id: string, isComplete: boolean) {
    console.log('id', id)
    let tourActivity = {
      id: id,
      completed: isComplete
    }
    this.userTourActivity.push(tourActivity);
    console.log('this.userTourActivity', this.userTourActivity)
  }

  getUserTourActivity() {
    return this.userTourActivity;
  }

  loadTourSteps(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/userTourSteps.json');
  }

  loadHelper(): Observable<any> {
    return this.http.get<any>(`assets/json/userTourHelper.json`);
  }

  public initiate(tourIDs: string[]) {
    this.tourID = tourIDs;
    this.initializeTour(tourIDs).subscribe(
      ({ steps, completedSteps }) => {
        // Check if userTourActivity is empty or undefined
        if (completedSteps.length === 0) {
          // Add both tour IDs if userTourActivity is empty or undefined
          tourIDs.forEach(id => {
            this.startTourById(id);
          });
        } else {
          // Add tours only if they are not already completed
          tourIDs.forEach(id => {
            // Check if there is a completed step with the specified tour ID
            const isTourCompleted = completedSteps.some(step => step.id === id && step.completed);
            this.shepherd.steps.map(steps => {
              this.shepherd.removeStep(completedSteps.find(st => st.id === id)?.id)
            })
            if (!isTourCompleted) {
              // Start the tour if it's not already completed
              this.startTourById(id);
            } else {
              console.log(`Tour '${id}' is already completed.`);
            }
          });
        }
        console.log('Tour initialized successfully.');
      },
      error => {
        console.error('Error initializing tour:', error);
      }
    );
  }

  private initializeTour(tourIds: string[]): Observable<{ steps: any[], completedSteps: any[] }> {
    return new Observable((observer: Observer<{ steps: any[], completedSteps: any[] }>) => {
      this.loadTourSteps().subscribe(
        (steps: any[]) => {
          let completedSteps = [];
          if (steps.find(data => tourIds.some(tour => data.id === tour))) {
            console.log('find steps', steps.find(data => tourIds.some(tour => data.id === tour)))
            if (this.userTourActivity.length > 0) {
              steps = steps.filter(st => this.userTourActivity.some(activity => activity.id !== st.id));
              completedSteps = this.userTourActivity.map(activity => activity);
            } else {
              completedSteps = [];
              // steps = steps.filter(st => tourIds.some(id => st.id === id) );
              console.log('steps', steps)
              this.tourSteps = steps;
              console.log('this.tourSteps', this.tourSteps)
            }
            // Get completed steps from userTourActivity
            // if (this.userTourActivity.length > 0) {
            //   completedSteps = this.userTourActivity.map(activity => activity);
            // } else {
            //   completedSteps = [];
            // }
            // Emit both loaded steps and completed steps as an object
            observer.next({ steps, completedSteps });
            observer.complete();
          } else {
            console.log('steps not found')
          }
        },
        error => {
          console.error('Error loading tour steps: ', error);
          observer.error(error);
        }
      );
    });
  }

  private startTourById(id: string): void {
    console.log('id', id)
    // if (this.userTourActivity.length > 0) {
    //   console.log('userTourActivity this.tourSteps ', this.tourSteps)
    //   this.tourSteps = this.tourSteps.map(st => this.userTourActivity.find(activity => activity.id !== st.id));
    // }

    if (!this.shepherdService.isActive) {
      const defaultSteps = {
        classes: 'shepherd-theme-arrows',
        scrollTo: true,
        cancelIcon: {
          enabled: true
        },
      };
      this.shepherdService.defaultStepOptions = defaultSteps;
      console.log('this.tourSteps', this.tourSteps)
      const step = this.tourSteps.find(s => s.id === id);
      console.log('all steps', step)
      if (step) {
        const element = document.querySelector(step.attachTo.element);
        if (element) {
          // this.setModalOverlayOpacity(0);
          const stepOptions: Shepherd.Step.StepOptions = {
            id: step.id,
            arrow: false,
            attachTo: step.attachTo,
            scrollTo: {
              behavior:'auto',
              block:'nearest',
              inline: 'nearest'
            },
            // classes: step.class,
            beforeShowPromise: () => {
              return new Promise<void>((resolve) => {
                setTimeout(() => {
                  window.scroll(0, 0);
                  resolve(); // Resolve the promise
                }, 100);
              });
            },
            buttons: [],
            // buttons: step?.button.map(btn => ({
            //   classes: btn.class,
            //   text: btn.text,
            //   action: () => {
            //     switch (btn.type) {
            //       case 'next':
            //         console.log('next item called')
            //         this.trackUserTourActivity(step.id, true);
            //         this.clickCount = 0;
            //         this.shepherd.next();
            //         break;
            //       case 'complete':
            //         console.log('complete item called')
            //         this.trackUserTourActivity(step.id, true)
            //         const currentStep = this.shepherd.getCurrentStep();
            //         this.clickCount = 0;
            //         currentStep.complete();
            //         break;
            //       case 'exit':
            //         console.log('complete item called')
            //         this.trackUserTourActivity(step.id, true)
            //         this.shepherd.complete();
            //         break;
            //       case 'back':
            //         this.shepherd.back();
            //         break;
            //       default:
            //         break;
            //     }
            //   },
            // })),
            canClickTarget: true,

          };
          this.shepherd.addStep(stepOptions);
          this.shepherd.start();
          // Hide the modal overlay by setting opacity to 0
          // Delayed hiding of the modal overlay
          setTimeout(() => {
            const modalOverlay = document.querySelector('.shepherd-modal-is-visible.shepherd-modal-overlay-container') as HTMLElement;
            if (modalOverlay) {
              modalOverlay.style.opacity = '0';
            }
            // set helper text
            this.loadHelper().subscribe({
              next: (helperData) => {
                let data = helperData.find(item => item.id === this.shepherd.getCurrentStep().id);
                if (data) {
                  this.helperData.emit(data);
                }
              },
              error: (err) => { console.error('helper notFound', err) }
            });
          }, 100);

        } else {
          console.error(`Element with selector '${step.attachTo.element}' not found for step '${id}'.`);
        }
      } else {
        console.error(`Step with ID '${id}' not found.`);
      }
    }
  }

  // destoye tour
  public destroyTour(tourId?: string) {
    this.clickCount = 0;
    this.removeTargetFocus(this.shepherd.getCurrentStep());
    if (this.shepherd.steps) {
      this.shepherd.steps = [];
      if (this.shepherd.getCurrentStep()) {
        const overlayElement = document.querySelector('.shepherd-modal-overlay-container');
        if (overlayElement) {
          overlayElement.remove(); // Clean up Shepherd's SVG overlay
        }
        const currentStep = this.shepherd.getCurrentStep();
        currentStep.destroy();
      }
    }
  }

  // complete tour 
  public completeTour(tourId?: string) {
    this.clickCount = 0;
    this.removeTargetFocus(this.shepherd.getCurrentStep());
    if (this.shepherd.getCurrentStep()) {
      const overlayElement = document.querySelector('.shepherd-modal-overlay-container');
      if (overlayElement) {
        overlayElement.remove(); // Clean up Shepherd's SVG overlay
      }
      const currentStep = this.shepherd.getCurrentStep();
      currentStep.complete();
    }
  }

  private getClickEventId() {
    this.getElementId.subscribe({
      next: (id) => {
        console.log('id', id)
      },
      error: (err) => {
        console.error('err', err);
      }
    })
  }

  // Method to increment the click count and emit the updated count
  public incrementClickCount(): void {

    this.clickCount++;
    let currentstep = this.shepherd.getCurrentStep();
    console.log('this.clickCount', this.clickCount);
    if (this.clickCount > 0 && currentstep) {
      this.setModalOverlayOpacity(0.5);
      this.setActiveElementStyle(currentstep, '#F1333B', 0.5);
      this.setModalOverlayColor('#F1333B');
    } else {
      this.setModalOverlayOpacity(0);
      this.setActiveElementStyle(currentstep, '#fff', 0);
      this.setModalOverlayColor('transparent');
    }
    if (this.clickCount === 3 && currentstep) {
      this.loadHelper().subscribe({
        next: (helperData) => {
          let data = helperData.find(item => item.id === currentstep.id);
          if (data) {
            this.helperData.emit(data.error);
          }
        },
        error: (err) => { console.error('helper notFound', err) }
      });
      this.setActiveElementStyle(currentstep, '#fff', 1);
      try {
        let filteredStep: any
        setTimeout(() => {
          if (this.tourSteps) {
            filteredStep = this.tourSteps.find(st => st.id === currentstep.id)
            this.shepherd.getCurrentStep().updateStepOptions({
              title: filteredStep.title,
              text: filteredStep.text,
              classes: filteredStep.class + 'highlight-border',
              attachTo: filteredStep.attachTo,
              arrow: false,
              modalOverlayOpeningPadding: 20
            });
            this.applyShepherdTargetStyles('3px', '#e2a60f');
          }
        }, 1000);

      } catch (error) {
        console.log('error', error)

      }
      // })
    }
    this.clickSubject.next(this.clickCount);
  }

  // Function to apply custom styles to .shepherd-target elements
  private applyShepherdTargetStyles(borderWidth: string, borderColor: string): void {
    const shepherdTargets = document.querySelectorAll('.shepherd-target');

    shepherdTargets.forEach(target => {
      this.renderer.setStyle(target, 'border', `${borderWidth} solid ${borderColor}`);
    });
  }

  //set modal overlay opacity 
  private setModalOverlayOpacity(opacity: number): void {
    const overlayElement = document.querySelector('.shepherd-modal-is-visible.shepherd-modal-overlay-container');
    if (overlayElement) {
      if (this.clickCount >= 3) {
        this.renderer.setStyle(overlayElement, 'opacity', '0.5')
      } else {
        this.renderer.setStyle(overlayElement, 'opacity', opacity.toString())
      }
    }
  }

  // set focused modal style
  private setActiveElementStyle(currentStep: any, color, opacity) {
    if (this.shepherd.isActive() && currentStep) {
      const highlihtedElement = document.querySelector(currentStep.options.attachTo.element);
      if (highlihtedElement ) {
        this.renderer.setStyle(highlihtedElement, 'opacity', opacity.toString())
        if (currentStep.id === 'postProductionBtnTour' || currentStep.id === 'playerBtnTour') {
          this.renderer.setStyle(highlihtedElement, 'background', 'transparent')
        } else {
          this.renderer.setStyle(highlihtedElement, 'background', color)
        }
        setTimeout(() => {
          this.renderer.setStyle(highlihtedElement, 'opacity', '1')
          if (currentStep.id === 'postProductionBtnTour' || currentStep.id === 'playerBtnTour') {
            this.renderer.setStyle(highlihtedElement, 'background', 'transparent')
          } else {
            this.renderer.setStyle(highlihtedElement, 'background', '#fff')
          }
        }, 500);
      }
    }
  }

  // set modal overlay svg style
  private setModalOverlayColor(color: string): void {
    const pathElement = document.querySelector('.shepherd-modal-is-visible.shepherd-modal-overlay-container path') as SVGPathElement;
    if (pathElement) {
      pathElement.setAttribute('fill', color);
      setTimeout(() => {
        if (this.clickCount >= 3) {
          pathElement.setAttribute('fill', '#000');
        } else {
          pathElement.setAttribute('fill', 'transparent');
        }
      }, 500);
    }
  }

  // update target element style
  private removeTargetFocus(currentStep): void {
    if (this.shepherd.isActive() && currentStep) {
      const targetElement = document.querySelector(currentStep.options.attachTo.element);
      if (targetElement) {
        this.renderer.setStyle(targetElement, 'border', 'none')
      }
    }
  }

  // Method to get the observable that emits the click count
  public getClickCountObservable(): Observable<number> {
    return this.clickSubject.asObservable();
  }

}
