import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBase } from "../../../../shared/bases/loading.base";


@Component({
	templateUrl: './project-proposal-NDA.component.html',
	styleUrls: ['./project-proposal-NDA.scss']
})
export class ProjectProposalNDAModalComponent extends LoadingBase implements OnInit {


	projectId: any;
	projectName: any;
	companyName: any;
	brandName: any;
	ndaApproved: any;
	isTermsCondition: boolean;
	startDate;
	constructor(private router: Router,
		private route: ActivatedRoute,
		public activeModal: NgbActiveModal) {
		super();


	}

	ngOnInit() {

	}

	accept() {
		this.activeModal.close('ACCEPTED');
	}

	onCloseModal() {
		this.activeModal.close('CLOSED');
	}

}
