import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgClickOutsideDirective } from 'ng-click-outside2';;


import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";

/* ----------- * DIRECTIVES * ----------- */



/* ----------- * GUARDS * ----------- */



/* ----------- * PIPES * ----------- */

/* ----------- * PAGES * ----------- */
import { LoginComponent } from "app/login/login.component";
import { MaintenanceComponent } from "../../maintenance/maintenance.component";
import { AuthRedirectComponent } from "app/login/auth-redirect/auth-redirect.component";
import { ResetPasswordComponent } from 'app/reset-password/reset-password.component';
import { RequestInvitationComponent } from "app/login/request-invitation/request-invitation.component";
import { ProfileInfoComponent } from "app/on-boarding/profile-info/profile-info.component";
import { PhoneVerificationComponent } from "app/on-boarding/phone-verification/phone-verification.component";
import { TermsAndConditionsComponent } from "../../on-boarding/terms-and-conditions/terms-and-conditions.component";
import { ProfileAvatarComponent } from "app/on-boarding/profile-avatar/profile-avatar.component";
import { RateClientComponent } from 'app/on-boarding/rate-client/rate-client.component';
import { SubscriptionComponent } from "app/layouts/subscription/subscription.component";
import { BuildRequestComponent } from "app/layouts/build-request/build-request.component";

/* ----------- * COMPONENTS * ----------- */
import { LoginWithEmailComponent } from 'app/login/login-with-email/login-with-email.component';
import { ChangePhoneModalComponent } from 'app/on-boarding/phone-verification/change-phone-modal/change-phone-modal.component';

/* ----------- * MODAL_COMPONENTS * ----------- */
import { BuildPlanPopupComponent } from "app/shared/components/build-plan-popup/build-plan-popup.component";
import { BuildPlanCircleComponent } from "app/layouts/build-plan-circle/build-plan-circle.component";
import { SignupFormComponent } from "app/login/signup-form/signup-form.component";
import { SubscriptionConfirmComponent } from "app/shared/components/subscription-confirm/subscription-confirm.component";
import { ContactForEnterpriseProposalComponent } from "app/shared/components/contact-for-enterprise-proposal/contact-for-enterprise-proposal.component";

/* ----------- * SERVICES * ----------- */
// import { SubscriptionService } from "app/shared/services/subscription.service";

/* ----------- * ROUTE * ----------- */
import { EntryRoutingModule } from './entry-routes';
import { BetweenProjectModuleAndOutherModuleSharedModule } from '../sharedModule-BetweenProjectModuleAndOutherModule';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { LanguagePopupComponent } from "../../shared/components/language-popup/language-popup.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LoginFormComponent } from "../../login/login-form/login-form.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgxFileDropModule } from "ngx-file-drop";
import { OneShotGuard } from "app/shared/guards/one-shot.guard";
import { PaymentInvoiceService } from "app/account/manages/pages/billing/services/payment-invoice.service";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import { PlatformEventService } from "app/shared/services/platform-events.service";

@NgModule({
	declarations: [
		LoginComponent,
		MaintenanceComponent,
		AuthRedirectComponent,
		ResetPasswordComponent,
		RequestInvitationComponent,
		ProfileInfoComponent,
		PhoneVerificationComponent,
		TermsAndConditionsComponent,
		ProfileAvatarComponent,
		RateClientComponent,
		SubscriptionComponent,
		BuildRequestComponent,
		BuildPlanPopupComponent,
		BuildPlanCircleComponent,
		LanguagePopupComponent,
		SignupFormComponent,
		SubscriptionConfirmComponent,
		LoginWithEmailComponent,
		ChangePhoneModalComponent,
		ContactForEnterpriseProposalComponent
	],
	imports: [
		HttpClientModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		NgClickOutsideDirective,
		NgbModule,
		// Ng2AutoCompleteModule,
		AutocompleteLibModule,
		EntryRoutingModule,
		BetweenProjectModuleAndOutherModuleSharedModule,
		// SharedModule,
		PdfViewerModule,
		SharedModule,
		NgxFileDropModule
	],
	// entryComponents: [
	//     BuildPlanPopupComponent,
	//     LanguagePopupComponent,
	//     RecoveryPasswordComponent,
	//     SignupFormComponent,
	//     SubscriptionConfirmComponent,
	//     LoginWithEmailComponent,
	//     ChangePhoneModalComponent,
	//     ContactForEnterpriseProposalComponent,
	//     LoginFormComponent
	// ],
	providers: [
		SubscriptionService,
		PlatformEventService,
		OneShotGuard,
		PaymentInvoiceService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class EntryModule {
}
