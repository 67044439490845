import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from 'app/shared/interfaces';
import { AuthService } from 'app/shared/services/auth.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { UserTourService } from 'app/shared/services/user-tour.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';

import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mtm-usertourprogress',
  templateUrl: './usertourprogress.component.html',
  styleUrls: ['./usertourprogress.component.scss']
})
export class UsertourprogressComponent implements OnInit, AfterViewInit {
  isTourStart: boolean = true;
  ngUnsubscribe = new Subject();
  themeOptions: ThemeOptions | null = null;
  helperData: any;
  authUser: any;

  constructor(public userTourService: UserTourService,
    private themeService: ThemeService,
    public authService: AuthService,
    public stService: SelfTrainerService,
  ) {
    this.authUser = this.authService.getAuthUser();
  }

  ngOnInit(): void {
    this.themeService.contentChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(update => {
        setTimeout(() => {
          this.themeOptions = update;
        }, 100);
      });
    this.getHelperData();
  }

  ngAfterViewInit(): void {

  }

  getPermission(){
    let canAccess = true;
    if (this.authUser.globalRole === 'COMPANY_PRINCIPAL') {
      canAccess = false;
    }
    if ( this.authUser.globalRole === 'ADMIN') {
      canAccess = false;
    }
    return canAccess;
  }

  private getHelperData() {
    this.userTourService.helperData.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.helperData = data;
        console.log('this.helperData', this.helperData)
      },
      error: (err) => {
        console.error('helper data not found', err)
      }
    })
  }

  @HostListener('document:click', ['$event'])
  clickedOut(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const modalWindow = document.querySelector('.modal.fade.show');
  
    if (this.stService.startSelfTrainer && clickedElement && !this.stService.isMissionPaused) {
      if (clickedElement.id === 'main-overlay' || (modalWindow && modalWindow.contains(clickedElement))) {
        this.stService.clickCount++;
        console.log("click Count: ", this.stService.clickCount)
        this.stService.updateUsedAttempt();
        switch (this.stService.clickCount) {
          case 3:
            this.stService.activateHint();
            break;
  
          default:
            this.stService.showDangerOverlay = true;
            this.stService.updateUsedAttempt();
            setTimeout(() => {
              this.stService.showDangerOverlay = false;
            }, 500);
            break;
        }
      }
    }
  
    if (clickedElement && !this.stService.isMissionPaused) {
      const elementId = clickedElement.id;
      if (elementId) {
        this.userTourService.getElementId.emit(elementId)
      } else {
        this.userTourService.getElementId.emit(undefined)
      }
      console.log('Clicked Element:', clickedElement);
      console.log('Element ID:', elementId);
    }
  }
  
  getMainOverlayBgClass() {
    switch (this.stService.clickCount) {
      case 0:
        return 'white-overlay';

      case 1:
        return 'white-overlay';

      case 2:
        return 'white-overlay';

      default:
        return 'gray-overlay';
    }
  }
  
  playPausebtn() {
    this.stService.playPause.emit(true)
  }
}
