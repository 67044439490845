import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { folderCreated } from 'app/layouts/ai-reframing/cores/ai-framing.events';
import { AIReframingProjectService } from 'app/layouts/ai-reframing/services/ai-reframing-project.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'mtm-ai-framing-folder-builder',
    template: '<div></div>'
})
export class AiFramingFolderBuilderContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() guid: any;
    @Input() folder: any;

    ngUnsubscribe = new Subject();

    constructor(private aIReframingProjectService: AIReframingProjectService) { }

    ngOnInit(): void {
        this.aIReframingProjectService.createProject(this.folder)
            .subscribe(res => {
                folderCreated.emit({
                    guid: this.guid,
                    folder: res
                });
            })  
    }

    ngAfterViewInit(): void { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);
        this.ngUnsubscribe.complete();
    }
}
