
import { map, debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';


import { AuthService } from './../../../shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { ConversationService } from './../../../shared/services/conversation.service';
import { UserService } from './../../../shared/services/user.service';
import { JitsiService } from "../../..//shared/services/jitsi.service";
import { NotificationService } from "app/shared/services/notification.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { onLoadProject, ProjectService } from "../../../shared/services/project.service";
import { BroadcastService } from "../../../shared/services/broadcast.service";
import DOMPurify from 'dompurify';

@Component({
	templateUrl: './video-chat-invite.component.html',
	styleUrls: ['./video-chat-invite.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideoChatInviteComponent implements OnInit, OnDestroy {

	projectId: string;
	id: string;
	roomId: any;
	conversation: any;
	openNewPage: boolean = true;
	project: any;

	authUser: any = null;
	users: any[] = [];
	userInput: string = '';
	invitedUsers = {};
	invitedKeys: string[] = [];
	disableBtn: boolean = false;
	formGroup: UntypedFormGroup;
	createType: string = 'video';
	submitted: boolean = false;
	invitedUseData: any = [];
	ngUnsubscribe = new Subject();
	sessionId: number = HelperService.genRandomNumber(1, 100000);
	usersSelected: any[] = [];

	constructor(private authService: AuthService, private jitsiService: JitsiService, private sanitizer: DomSanitizer,
		public userService: UserService, public activeModal: NgbActiveModal, private conversationService: ConversationService,
		private notificationService: NotificationService, private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute,
		private projectService: ProjectService,
		private broadcastService: BroadcastService,) {
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		if (this.projectService.projectOnloaded) {
			this.init(this.projectService.project);
		}
		else {
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe({
				next: (project) => {
					this.init(project)
				}
			})
		}
		this.authUser = this.authService.getAuthUser();
		this.projectService.getProjectUsers(this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(users => {
			let data = _.filter(users, (user: any) => user.globalRole !== 'COMPANY_OCCASIONAL');
			data.forEach(user => {
				this.users.push(user);
			});
		});
		// If ONE_TO_ONE conversation, then add automatically to other user (not logged in user)
		// to the invited list of users
		if (this.conversation) {
			if (this.conversation.type == 'ONE_TO_ONE') {
				this.conversation.participants.forEach((user: any) => {
					if (user.username != this.authService.getAuthUserName()) {
						this.onSelectInvitedUser(user, false);
					}
				})
			}
		}
		// If no conversation or ONE_TO_ONE, then create form group to handles group name
		if (!this.conversation || (this.conversation.type == 'ONE_TO_ONE')) {
			// Build FormGroup
			this.formGroup = this.fb.group({
				name: ['', [Validators.required, Validators.minLength(2)]]
			});
		}
	}

	delayExecutions(milliSeconds: any) {
		return new Promise(resolve => {
			setTimeout(() => { resolve('') }, milliSeconds);
		})
	}

	private init(project) {
		if (!this.projectId) {
			return;
		}
		this.project = project;
		sessionStorage.setItem('projectId', this.projectId);
	}

	// Get conversations of this project
	private getProjectAllConversations() {
		this.conversationService.getConversations(this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			EmitterService.get('project.messages').emit({ project: this.project, conversations: data });
		});
	}

	selectedInvitersChange($event: any) {
		this.onSelectInvitedUser($event.selectedData);
	}

	/**
	 * Select one user from list or just enter any external email
	 * @param user
	 */
	private onSelectInvitedUser(user, canRemove: boolean = true) {
		// Well, "user" can be a user object or just a string (email value)
		if (user) {
			// Invite a current user
			if (user.username && !this.invitedUsers[user.username]) {
				this.invitedUsers[user.username] = {
					avatarUrl: user.avatarUrl,
					fullName: user.fullName,
					inviteByEmail: false,
					canRemove: canRemove
				};
			}
			// Invite by email
			else if (!HelperService.isEmail((user || '').trim())) {
				return false;
			}
			else if (!this.invitedUsers[user]) {
				this.invitedUsers[user] = {
					avatarUrl: null,
					fullName: user,
					inviteByEmail: true,
					canRemove: canRemove
				}
			}
		}
		this.invitedKeys = Object.keys(this.invitedUsers);
		this.userInput = '';
		return false;
	}

	// check url is valid or not sanitize url
	isValidURL(url) {
		try {
			new URL(url);
			return true;
		} catch (error) {
			return false;
		}
	}
	/**
	 * Create new group conversation and close
	 */
	private onCreateGroupCallAndClose() {
		this.submitted = true;
		if (this.formGroup.invalid) {
			return;
		}
		this.disableBtn = true;
		// If user enter some email on input but didnt press enter,
		// then that email was not added yet to invitedUsers list - just send invitation by email
		if (this.userInput && !(typeof this.userInput === 'object') && HelperService.isEmail(this.userInput.trim())) {
			this.invitedKeys.push(this.userInput.trim());
		}
		// Now this.invitedKeys has the full list of email address (usernames) to start new group
		// Create new group conversation with that list of users and open a new video call page
		this.conversationService
			.createGroupConversation(this.projectId, this.invitedKeys, this.formGroup.controls['name'].value)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.activeModal.close();
					this.authService.setJitsiToken(data.jitsiToken);
					// Create video
					if (this.createType == 'video') {
						const videoCallUrl = HelperService.getVideoCallUrl(this.projectId, data.id, data.videoCallId, true);
						// Refresh page with new conversation
						if (this.openNewPage) {
							HelperService.openNewPopup(videoCallUrl);
						} else {
							let safeURL = DOMPurify.sanitize(videoCallUrl);
							window.location.href = encodeURI(safeURL);
						}
					}
					// Create chat popup
					else {
						EmitterService.get('textchat.open').emit({ conversation: data });
						this.getProjectAllConversations();
						this.broadcastService.send({ action: 'init', sessionId: this.sessionId });
					}
				},
				(err: any) => {
					let description = ''
					if (err && (err.errorCode == 'CONVERSATION_TITLE_EXISTS_UNDER_PROJECT')) {
						description = `Already exists a conversation with ${this.formGroup.controls['name'].value} name under this project.\nPlease, try another name.`;
					}
					// Display create video error
					if (this.createType == 'video') {
						this.notificationService.open({
							title: 'Create Video Call',
							description: (description) ? description : `Error trying to create a new video call.\n${err.errorCode}\nPlease, try again.`,
							confirmBtn: 'Accept'
						});
					}
					// Display create chat popup error
					else {
						this.notificationService.open({
							title: 'Create Chat Group',
							description: (description) ? description : `Error trying to create a new group chat.\n${err.errorCode}\nPlease, try again.`,
							confirmBtn: 'Accept'
						});
					}
					this.disableBtn = false;
				})
	}

	/**
	 * Send invitations to group conversation and close popup
	 */
	private onInviteAndClose() {
		// If user enter some email on input but didnt press enter,
		// then that email was not added yet to invitedUsers list - just send invitation by email
		if (this.userInput && !(typeof this.userInput === 'object') && HelperService.isEmail(this.userInput.trim())) {
			this.conversationService
				.addUsersToGroupChat(this.projectId, this.id, [this.userInput])
				.subscribe(
					(data: any) => {
						// Ping users
						this.conversationService
							.sendVideoCallPing(this.projectId, this.id, [this.userInput])
							.subscribe(
								(data: any) => {
								},
								(err: any) => {
								}
							);
					},
					(err: any) => {
						console.log(err);
					}
				)
		}

		if (this.invitedKeys) {
			this.invitedKeys.forEach((username: string) => {
				// If external user, need to invite by email
				if (!this.invitedUsers[username].invitedByEmail) {
					this.conversationService
						.addUsersToGroupChat(this.projectId, this.id, [username])
						.subscribe(
							(data: any) => {
								// Ping users
								this.conversationService
									.sendVideoCallPing(this.projectId, this.id, [username])
									.subscribe(
										(data: any) => {
										},
										(err: any) => {
										}
									);
							},
							(err: any) => {
								console.log(err);
							}
						);
				}
			});
		}
		this.activeModal.close();
	}

	/**
	 * Close popup
	 */
	private onClose() {
		this.activeModal.close();
	}

	/**
	 * Remove invited user
	 * @param username
	 */
	private onRemoveInvitedUser(username) {
		if (this.invitedUsers && this.invitedUsers[username]) {
			this.invitedUseData = _.filter(this.invitedUsers, (usr: any) => usr.email !== username);
			delete this.invitedUsers[username];
			this.invitedKeys = Object.keys(this.invitedUsers);
		}
	}

	/**
	 * Search users to invite
	 */
	private searchUsers = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			map(term => term === '' ? []
				: this.users.filter(v => new RegExp(term, 'gi').test(v.fullName)).slice(0, 10)),);


	/**
	 * Format user added to list
	 */
	private formatterUsers = (x: { firstName: string }) => '';


}
