import { ICellRendererAngularComp } from "ag-grid-angular";
import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: './action-cell.component.html',
})
export class ActionCellComponent implements ICellRendererAngularComp, OnInit {
  params: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  deleteTeam() {
    this.params.context.componentParent.deleteTeam(this.params.data);
  }
}
