import {Pipe, PipeTransform} from "@angular/core";
import {HelperService} from "../services/helper.service";

@Pipe({
  name: "cacheBusterImage"
})
export class CacheBusterImagePipe implements PipeTransform {
  transform(value: string, version: string = ''): string {
    if(! value || HelperService.isBase64Image(value)) {
      return  value;
    }

    version = version || new Date().getTime().toString();

    if(value.indexOf('?') > -1) {
      return value + '&v=' + version;
    } else {
      return value + '?v=' + version;
    }
  }
}
