import { Injectable } from "@angular/core";
import { MTMCustomFile, TransferType } from ".";
import { EntityFiles } from "./file.uploader";
import { listenerFileUploadCompleted, listenerMonitoredFileChanged } from "./listeners";
import { TransferStatus } from "./transfer-status";

@Injectable({
  providedIn: 'root'
})
export class TransferMonitorService {
  fileStates: EntityFiles[] = [];

  constructor() {
    listenerFileUploadCompleted.subscribe((file: MTMCustomFile) => this.updateFileState(file));
  }

  monitor(args: EntityFiles): MTMCustomFile[] {
    const state = this.fileStates.find(f => f.origin == args.origin);

    if (!state) {
      this.fileStates.push(args);
      return args.newFiles;
    }
    state.currentFiles = args.currentFiles;
    state.entity = args.entity;
    return state.newFiles;
  }

  sendFileToMonitor(origin: string, entity: any, file: MTMCustomFile): MTMCustomFile[] {
    const state = this.fileStates.find(f => f.origin == origin);

    if (!state) {
      const newFiles = [file];
      this.fileStates.push({
        origin,
        entity,
        currentFiles: [],
        newFiles: [file]
      });
      return newFiles;
    }

    state.newFiles.push(file);
    return state.newFiles;
  }

  removeFileFromUploadMonitor(origin: string, file: MTMCustomFile) {
    const state = this.fileStates.find(f => f.origin == origin);
    if (!state) {
      return;
    }
    const index = state.newFiles.findIndex(f => f == file);
    if (index > -1) {
      state.newFiles.splice(index, 1);
    }
  }

  unMonitor(origin: string) {
    const state = this.fileStates.find(f => f.origin == origin);

    if (!state) {
      return;
    }

    const expectedStates = [TransferStatus.COMPLETED, TransferStatus.CANCELED, TransferStatus.ERROR];
    const isUnfinished = state.newFiles.some(f => !expectedStates.includes(f.uploadStatusCode));

    if (!isUnfinished) {
      return;
    }

    let currentIndex = this.fileStates.findIndex(f => f == state);
    this.fileStates.splice(currentIndex, 1);
  }

  private updateFileState(file: MTMCustomFile) {
    const signedTypes: string[] = [TransferType.ResumableSignedURL, TransferType.SignedURL];

    if (!signedTypes.includes(file.transferType)) {
      return;
    }

    const state = this.fileStates.find(f => f.origin == file.preUploadUrl);
    if (!state) {
      return;
    }

    const newIndex = state.newFiles.findIndex(f => f.dbFileObject.id == file.dbFileObject.id);
    if (newIndex > -1) {
      state.newFiles.splice(newIndex, 1);
    }

    const filesIndex = state.currentFiles.findIndex(f => f.id == file.dbFileObject.id);
    if (filesIndex == -1) {
      state.currentFiles.unshift(file.dbFileObject);
    }

    listenerMonitoredFileChanged.emit(state);
  }
}
