import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MTMFileDownloadModel } from "./mtm-file-download.model";

export let onDownloadStart: EventEmitter<any> = new EventEmitter<any>();
export let onDownloadComplete: EventEmitter<any> = new EventEmitter<any>();

@Injectable()
export class MTMFileDownloadService {
	downloadList: MTMFileDownloadModel[] = [];

	constructor() { }

	addFileToDownload(fileToDownload: MTMFileDownloadModel) {
		this.downloadList.push(fileToDownload);
	}

	startDownloadFile(downloadFile: MTMFileDownloadModel) {
		this.downloadList.push(downloadFile);
		downloadFile.startDownload();
	}

	getFileDownloadInfoById(id: any) {
		let file = _.find(this.downloadList, (file: MTMFileDownloadModel) => file.id === id) as MTMFileDownloadModel;
		return file.getDownloadInfo();
	}

	getAllDownloadFile() {
		return this.downloadList;
	}

	getTotalDownloadInProgress() {
		return _.filter(this.downloadList, (downloadFile) => {
			return downloadFile.state === 'downloading';
		});
	}

	isFileInDownload(downloadId: any) {
		return _.find(this.downloadList, (downloadFile) => {
			return downloadFile.id === downloadId && (downloadFile.state === 'downloading' || downloadFile.state === 'start');
		});
	}

	removeFromListByIndex(index: any) {
		this.downloadList.splice(index, 1);
	}
}
