import { NgModule } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { EditWorkspaceComponent } from "./components/edit-workspace/edit-workspace.component";
import { WorkspaceRoutingModule } from "./workspace.route";
import { WorkspaceEditHeaderComponent } from "./components/edit-workspace/header/header.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../sub-modules/SharedModule";
import { CreateWorkspaceComponent } from "./components/create-workspace/create-workspace.component";
import {
    EditWorkspacePermissionsComponent
} from "./components/edit-workspace/workspace-permissions/workspace-permissions.component";
import { MtmChatModule } from "app/sub-modules/mtm-chat-module/mtm-chat.module";
import { NewWorkspaceUserComponent } from "./components/edit-workspace/new-user/new-workspace-user.component";
import { WorkspaceProjectsComponent } from "./components/edit-workspace/workspace-projects/workspace-projects.component";
import { ProjectsModule } from "app/sub-modules/projects-module/projects-module";
import { WorkspaceCreationGuard } from "./guards/workspace-creation.guard";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { UserPermissionTableModule } from "./components/edit-workspace/workspace-permissions/table/user-permission-table.module";
import { WorkspaceProjectParticipantTableModule } from "./components/edit-workspace/workspace-projects/table/workspace-project-participant-table.module";
import { AgGridModule } from "ag-grid-angular";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UserAndTeamsComponent } from './components/edit-workspace/user-and-teams/user-and-teams.component';
import { AddUserComponent } from './components/edit-workspace/user-and-teams/add-user/add-user.component';
import { CreateGroupComponent } from './components/edit-workspace/user-and-teams/create-group/create-group.component';
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
    declarations: [
        CreateWorkspaceComponent,
        EditWorkspaceComponent,
        WorkspaceEditHeaderComponent,
        EditWorkspacePermissionsComponent,
        NewWorkspaceUserComponent,
        WorkspaceProjectsComponent,
        UserAndTeamsComponent,
        AddUserComponent,
        CreateGroupComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        WorkspaceRoutingModule,
        SharedModule,
        MtmChatModule,
        // Ng2AutoCompleteModule,
        AutocompleteLibModule,
        ProjectsModule,
        AgGridModule,
        UserPermissionTableModule,
        NgbModule,
        WorkspaceProjectParticipantTableModule,
        NgSelectModule
    ],
    exports: [],
    providers: [
        AuthService,
        WorkspaceCreationGuard,
        NgbActiveModal
    ]
})
export class WorkspaceModule { }
