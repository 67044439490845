import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export interface Mission {
  id: string;
  sequence: number;
  exerciseTitle: string;
  instruction: string;
  failMessage: string;
  attemptExceedMessage: string;
  hint: string;
  completionMessage: string;
  modalPosition: string;
  targetElementId: string;
  elementBackground: string;
  scrollTo: any;
  pathUrl: string;
  nextPathUrl: string;
  totalAttempt: number;
  usedAttempt: number;
  completionPoint: number;
  nextMissionDelay: number;
  inProgress: boolean;
  completed: boolean;
  numberOfAttempt?: 0;
}

export interface ScenarioInterface  {
  id: string
  scenarioName: string;
  description: string;
  totalPoint: number;
  missions: Mission[];
}

@Injectable({
  providedIn: 'root'
})

export class SelfTrainerService {
  public playPause: EventEmitter<boolean> = new EventEmitter<boolean>();
  public setUserEmail: EventEmitter<string>= new EventEmitter<string>();

  startSelfTrainer: boolean = false;
  allMissionsCommpleted: boolean = false;
  showSelfTrainerModal: boolean = false;
  showDangerOverlay: boolean = false;
  isUserPerformingTask: boolean = false;
  showCompletionMessage: boolean = false;
  isLoading: boolean = false;
  selfTrainerStepData: object = {};
  scenario: any;
  missions: any = [];
  activeMission: Mission = null;
  totalPoints: number = 0;
  scoredPoints: number = 0;
  clickCount: number = 0;
  usedAttempt: number = 0;
  targetElementRect: any = null;
  mainOverlayPosition: string = 'fixed';
  completedMissions:Mission[] =[];
  completedScenarioMissions: ScenarioInterface ;
  isMissionPaused: boolean = false;
  missionOnHold: Mission = null;
	authUser: any;
  userEmail: string;
  brandId: string;
  ngUnsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.authUser = this.authService.getAuthUser();
    if (this.authUser) {
      this.userEmail = this.authUser.email;
    }

    // get user email from auto login 
    if (!this.authUser) {
      this.setUserEmail.subscribe({
        next: (email) => {
          console.log('auth user email', email)
          this.userEmail = email;
          if (this.userEmail) {
            this.getCurrentUserActivity().subscribe((scenario: ScenarioInterface) => {
              console.log('cureent user scenario', scenario)
              this.scenario = scenario[0]
              console.log('this.scenario', this.scenario)
              this.missions = this.scenario.missions;
              this.completedScenarioMissions = this.scenario;
              // this.completedScenarioMissions.missions = [];
              
              this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
              // this.missions[0].pathUrl = '/workspaces'// work around till mission edit api not fix
              console.log('this.missions', this.missions)
              console.log('current user missions', this.missions)
              this.totalPoints = this.missions.length * 100;
            });
          }
        },
        error: () => {
          console.error('User email not found')
        }
      })
    }
    // get scenario from json
    // this.getScenario().subscribe((item: any) => {
    //   this.scenario = item[0];
    //   this.missions = this.scenario.missions;
    //   console.log('this.missions.length', this.missions.length)
    //   this.completedScenarioMissions = this.scenario;
    //   this.completedScenarioMissions['scenarioId'] = this.scenario.id;
    
    //   this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
    //   console.log('this.missions', this.missions)
    //   this.totalPoints = this.missions.length * 100;
    // });

    // get AllScenario  from api
    // this.getAllScenarioById('a28cc90c-d66b-40d6-a2b9-bc921602ad53').subscribe((scenario:ScenarioInterface)=>{
    //   console.log('scenario', scenario)
    //   this.scenario = scenario;
    //   console.log('this.scenario', this.scenario)
    //   this.missions = this.scenario.missions;
    //   this.completedScenarioMissions = this.scenario;
    //   this.completedScenarioMissions.missions = [];
    
    //   this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
    //   console.log('this.missions', this.missions)
    //   this.totalPoints = this.missions.length * 100;
    // });
    
    // // get current user scenario and missions
    if (this.authUser) {
      this.getCurrentUserActivity().subscribe((scenario: ScenarioInterface) => {
        this.scenario = scenario[0]
        console.log('this.scenario', this.scenario)
        this.missions = this.scenario.missions;
        console.log('this.missions', this.missions)
        this.completedScenarioMissions = this.scenario;
        // this.completedScenarioMissions.missions = [];
        
        this.missions = this.missions.sort((a, b) => a.sequence - b.sequence);
        // this.missions[0].pathUrl = '/workspaces'// work around till mission edit api not fix
    
        this.totalPoints = this.missions.length * 100;
      });
    }

    this.waitForAction();
    
  }

  /**
   * @returns current user scenarios 
   */
  getCurrentUserScenarios(username: string): Observable<ScenarioInterface> {
    return this.apiService.httpGet(`/api/gamifications/user-activities/${username}/current`).pipe(
        map((response: any) => {
            return response as ScenarioInterface;
        })
    );
}


  /**
   * 
   * @returns all mission from json file
   */
  getScenario(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/scenarios.json');
  }

  /**
   * 
   * @returns all scenarios using scenario id
   */
  getAllScenarioById(scenarioId: string): Observable<ScenarioInterface> {
    scenarioId = 'a28cc90c-d66b-40d6-a2b9-bc921602ad53';
    return this.apiService.httpGet(`/api/gamifications/scenarios/${scenarioId}`).pipe(
      map((response: any) => {
        // Assuming the response contains the mission object
        return response as ScenarioInterface;
      })
    );
  }

  /**
   * 
   * @returns all scenarios based comapny
   */
  getScenarioByCompanyId(companyId: string): Observable<any> {
    return this.apiService.httpGet(`/api/gamifications/scenarios/${companyId}/comapny`)
  }

  /**
   * 
   * @returns current user gamification activity 
   */
  getCurrentUserActivity():Observable<ScenarioInterface>{
    return this.apiService.httpGet(`/api/gamifications/user-activities/${this.userEmail}/current`).pipe(
      map((response: any) => {
        // Assuming the response contains the mission object
        return response as ScenarioInterface;
      })
    );
  }

  /**
   * 
   * @returns all missions using scenario id 
   */
  getAllMissionsByScenarioId(scenarioId: string):Observable<Mission>{
    return this.apiService.httpGet(`/api/gamifications/missions/${scenarioId}/scenarios`).pipe(
      map((response: any) => {
        return response as Mission;
      })
    );
  }

  /**
   * 
   * create or update mission using scenarioid
   */
  createOrUpdateMissionByScenarioId(scenarioId: string, missions: Mission): Observable<any> {
    return this.apiService.httpPost(`/api/gamifications/missions/${scenarioId}`, missions);
  }

  /**
   * 
   * delete any mission using mission id and scenario id
   */
  deleteMissionById(missionId: string, scenarioId: string): Observable<any> {
    return this.apiService.httpDelete(`/api/gamifications/missions/${missionId}/scenario/${scenarioId}`);
  }
  
  /**
   * 
   * @returns update mission which is completed by user 
   */
  updatesUserActivity(completedMission) {
    console.log('completedMission', completedMission)
    return this.apiService.httpPost('/api/gamifications/user-activities',completedMission);
  }

  /**
   * @returns current user data for training
   * */
  trainerLogin(useremail: string) {
    console.log('useremail', useremail)
    const userDetail = {
      "username": useremail
    }
    return this.apiService.httpPost(`/api/auth/login/training`, userDetail);
  }

  activateTargetElement() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    // if (targetElement) {
      this.scrollToTargetElement();
      targetElement.style.position = "relative";
      targetElement.style.zIndex = "99999";
    // }
  }

  deactivateTargetElement() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    if (targetElement) {
      this.scrollToTargetElement();
      targetElement.style.zIndex = "9";
    } else {
      console.warn('target element already deactivated')
    }
  }

  activateHint() {
    const targetElement = document.getElementById(this.activeMission.targetElementId);
    if (this.activeMission.elementBackground) {
      targetElement.style.backgroundColor = this.activeMission.elementBackground;
    }
    this.scrollToTargetElement();
    const elementRect = targetElement.getBoundingClientRect();
    this.selfTrainerStepData = {
      id: this.activeMission.id,
      text: this.activeMission.hint,
      position: this.activeMission.modalPosition,
      rect: elementRect,
    }
    this.showSelfTrainerModal = true;
  }

  scrollToTargetElement() {
      const targetElement = document.getElementById(this.activeMission.targetElementId);
      // if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: this.activeMission.scrollTo,
        });
        // }
  }

  startSelfTrainerMission() {
     if (!this.activeMission) {
       this.activeMission = this.missions[0];
       const url = this.activeMission.pathUrl;
      this.router.navigate([`${url}`]);
   }
    this.activateTargetElement();
    this.isLoading = false;
    this.showCompletionMessage = false;
    this.isUserPerformingTask = false;
    this.startSelfTrainer = true;
  }

  getHelpText() {
    switch (this.clickCount) {
      case 0:
      case 1:
      case 2:
        if (this.showDangerOverlay) {
          return this.activeMission?.failMessage;
        } else {
          return this.activeMission?.instruction;
        }

      case 3:
        if (this.showSelfTrainerModal) {
          return this.activeMission?.attemptExceedMessage;
        } else {
          return this.activeMission?.instruction;
        }

      default:
        if (this.showDangerOverlay) {
          return this.activeMission?.failMessage;
        } else if (this.showSelfTrainerModal) {
          return this.activeMission?.attemptExceedMessage;
        } else {
          return this.activeMission?.instruction;
        }
    }
  }

  updateUsedAttempt() {
    if (this.usedAttempt < 3) {
      this.usedAttempt++;
    }
  }

  hideTrainerModalAndStartTask() {
    console.log('this.activeMission', this.activeMission)
    if (this.activeMission) {
      this.updatesUserMissionActivity(this.activeMission);
      // this.updatesUserActivity(this.activeMission);
      this.showDangerOverlay = false;
      this.showSelfTrainerModal = false;
      this.isUserPerformingTask = true;
      this.isLoading = false;
      this.clickCount = 0;
      // this.isMissionPaused = false;
      this.deactivateTargetElement();
      this.startNextMission();
    }
  }

  /**
     * 
     * @returns update mission which is completed by user 
     */
  updatesUserMissionActivity(mission) {
    this.brandId = localStorage.getItem('brandId')
    console.log('this.brandId', this.brandId)
    this.completedScenarioMissions['brandId'] = this.brandId; 

    // remove unused data for update user activity
    delete this.completedScenarioMissions['status'];
    delete this.completedScenarioMissions['timestamp'];
    delete this.completedScenarioMissions['username'];
    delete this.completedScenarioMissions['description'];
    delete this.completedScenarioMissions['totalPoint'];
    delete this.completedScenarioMissions['scenarioName'];
    console.log('mission', mission)
    const completedMissions =  this.completedScenarioMissions;
    this.scoredPoints += mission.completionPoint
    completedMissions.missions.forEach(x => {
      if(x.id === mission.id) {
        x.inProgress = false;
        x.completed = true;
        console.log('x', x)
        // this.completedMissions.push(x);
      } else {
        // console.error('mission not found')
        return false;
      }
    });
    // this.completedMissions.push(mission);
    // console.log('this.completedMissions', this.completedMissions)
    // this.completedScenarioMissions['id'] = mission.id;
    // this.completedScenarioMissions.missions = []
    // this.completedScenarioMissions.missions.push(mission);
    // this.completedMissions
    // const foundItem = this.completedMissions.find(item => item.id === mission.id);
    // console.log('foundItem', foundItem)
    // if (!foundItem) {
    //   mission.inProgress = false;
    //   mission.completed = true;
    //   this.scoredPoints += mission.completionPoint
    //   this.completedMissions.push(mission);
    //   // this.completedScenarioMissions['id'] = mission.id;
    //   this.completedScenarioMissions.missions = []
    //   this.completedScenarioMissions.missions.push(mission);

    //   } else {
    //     mission.inProgress = false;
    //     mission.completed = true;
    //     this.scoredPoints += mission.completionPoint
    //     this.completedMissions.push(mission);  
    //     // this.completedScenarioMissions['id'] = mission.id;
    //     this.completedScenarioMissions.missions = []
    //     this.completedScenarioMissions.missions.push(mission);
    //   }
      // const completedMissions =  this.completedScenarioMissions;
      console.log('completedMissions', this.completedMissions)
      console.log('inside updatesUserMissionActivity', this.completedScenarioMissions)
      // this.updatesUserActivity(this.completedScenarioMissions);
      this.updatesUserActivity(completedMissions).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next:(data)=>{
          console.log('data', data)
        },
        error:(err)=> {
          console.error('err', err)
        }
      })
  }

  showCompletionMessageModal() {
    this.isLoading = false;
    this.showCompletionMessage = true;
  }

  handleCompletionModalNextBtn() {
    // console.log("next button function called!");
    this.showCompletionMessage = false;
    this.isLoading = true;
    const nextPageUrl = this.activeMission?.nextPathUrl;
    // console.log('nextPageUrl', nextPageUrl)
    this.router.navigate([`${nextPageUrl}`]);
    this.hideTrainerModalAndStartTask();
  }

  startNextMission() {
    console.log('activeMission in start mission: ', this.activeMission);
    if (this.activeMission && !this.isMissionPaused) {
      this.isLoading = true;
      const currentMissionId = this.activeMission.id;
      const missionCompleted = this.completedScenarioMissions.missions.find(mission => {
      if (mission.id === this.activeMission.id && mission.completed ) {
          return mission;
        }
      });
        let nextMission :any;
        const nextMissionOnHold = this.missions.find((item: any)=> item.sequence === missionCompleted.sequence+1)
        console.log('nextMissionOnHold', nextMissionOnHold)
        const currentMissionSequence = this.activeMission.sequence;
        const nextMissionSequence = currentMissionSequence + 1;
         nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
        console.log("nextMission: ", nextMission);
        this.missionOnHold = nextMission;
      

      // if (!this.isMissionPaused) {
      if (nextMission) {
        setTimeout(() => {
          this.activeMission = nextMission;
          this.startSelfTrainerMission();
        }, nextMission.nextMissionDelay);
      } else {
        // console.log("No mission left!!");
        this.allMissionsCommpleted = true;
        this.isLoading = true;
        this.activeMission = null;
        setTimeout(() => {
          this.isLoading = false;
          this.showCompletionMessage = true;
        }, 3000);
      }
    } else {
      this.activeMission = null;
      this.isLoading = false;
    }
    // }
  }

  handleStartAgainBtn() {
    this.showCompletionMessage = false;
    this.isLoading = false;
    this.showDangerOverlay = false;
    this.showSelfTrainerModal = false;
    this.isUserPerformingTask = false;

    // reset all values
    this.totalPoints = 0;
    this.scoredPoints = 0;
    this.clickCount = 0;
    this.usedAttempt = 0;

    // restart trainer
    const url = this.missions[0].pathUrl;
    this.router.navigate([`${url}`]);
    this.isLoading = true;
    setTimeout(() => {
      this.startSelfTrainerMission();
    }, 5000);
  }

  waitForDomUpdation() {
    this.isLoading = true;
  }

  // waiting for user operation once click on next function will trigger
  waitForAction(){
    this.playPause.subscribe(data => {
      console.log('playpausedata', data)
      this.isMissionPaused = false;
      if (data) {
        this.activeMission = this.missionOnHold;
        this.hideTrainerModalAndStartTask();
      } 
    })
  }

  playNextMission() {
    this.isMissionPaused = false;
    if (this.missionOnHold) {
      console.log('this.missionOnHold', this.missionOnHold)
      this.activeMission = this.missionOnHold;
      console.log('this.activeMission', this.activeMission)
    }
        // this.hideTrainerModalAndStartTask();

    // console.log('this.completedMissions', this.completedMissions);
    // console.log('this.missions', this.missions);
    // const currentMissionSequence = this.missionOnHold.sequence;
    // const nextMissionSequence = currentMissionSequence + 1;
    // const nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
    // this.activeMission = nextMission;
    // console.log('playNextMissionnextMission', nextMission)
  }
  updateActiveMissionForCreateTask(){
     // console.log('this.missions', this.missions);
     this.isMissionPaused = false;
     if (this.missionOnHold) {
       const currentMissionSequence = this.missionOnHold.sequence;
       const nextMissionSequence = currentMissionSequence + 1;
       const nextMission = this.missions.find((item: any) => item.sequence === nextMissionSequence);
       this.activeMission = nextMission;
       console.log('updateActiveMissionForCreateTask', this.activeMission)
      }
    }
}
