<div class="text-center mt-3">
	<div class="row text-left">
		<div class="col-md-1" style="max-width: 1.33333%;">
			<input style="transform: scale(1.25);" #saveUsername [(ngModel)]="isTermsCondition"
				(ngModelChanges)="onModelChanged()" type="checkbox" data-toggle="toggle" class="mr-3"
				[ngModelOptions]="{standalone: true}">
		</div>
		<div class="col-md-11">
			{{ 'registerReadAndAgree1' | translate }}
			<a href="#" (click)="onRedirectToTCP(this.getLocale()	 +'_TERMSandCONDITIONS');false;"
				style="color:#e4442a">
				{{ 'registerTermsAndConditions' | translate }}</a>
			{{ 'registerReadAndAgree2' | translate }}
			<a *ngIf="companyType == 'ADVERTISER'" href="#"
				(click)="onRedirectToTCP(this.getLocale() +'_TERMSofSALES');false;" style="color:#e4442a">
				{{ 'registerTermsOfService' | translate }}</a>
			<a *ngIf="companyType == 'PRODUCER'" href="#"
				(click)="onRedirectToTCP(this.getLocale() +'_TERMSofSERVICEPRODUCER');false;" style="color:#e4442a">
				{{ 'registerTermsOfService' | translate }}</a>
			{{ 'registerReadAndAgree3' | translate }}
			<a href="#" (click)="onRedirectToTCP(this.getLocale() +'_PRIVACYPOLICY');false;" style="color:#e4442a">
				{{ 'registerPrivacyPolicy' | translate }}</a>
			.
		</div>


	</div>
</div>