<div class="file-modal-component">
	<div class="modal-header pl-0 pr-0 pb-4 pt-4 ml-5 mr-5">
		<div class="d-flex align-items-center">
			<ul class="d-flex align-items-center m-0">
				<li class="d-inline-block mr-3">
					<a class="nav-link p-0 pointer" [class.view-selected]="viewMode=='GRID'"
						(click)="selectViewChange($event, 'GRID')">
						<img type="image/svg+xml" height="24" width="24"
							src="/assets/img/{{viewMode=='GRID' ? 'grid-view' : 'unselected-grid-view-icon'}}.svg"
							class="grid-icon">
					</a>
				</li>
				<li class="d-inline-block">
					<a class="nav-link p-0 pointer" [class.view-selected]="viewMode=='LIST'"
						(click)="selectViewChange($event, 'LIST')">
						<img type="image/svg+xml" height="24" width="24"
							src="/assets/img/{{viewMode=='LIST' ? 'selected-list-view-icon' : 'list-view'}}.svg"
							class="list-icon">
					</a>
				</li>
			</ul>
			<div class="d-flex flex-column ml-4">
				<div [style.font-size]="'18px'" class="_700">Create Archive</div>
				<div [style.font-size]="'14px'" class="c-mtm-purple">Select folders and subfolders you want to download
					(zip)</div>
			</div>
		</div>
		<div class="ml-auto" *ngIf="folderView.hierarcicalMenu.length > 1">
			<div class="d-flex align-items-center folder-location-info">
				<i title="back" class="mtm-icon-back pointer" (click)="folderView.backFolder()"></i>
				<span class="_800">{{folderView.hierarcicalMenu.length ?
					folderView.hierarcicalMenu[folderView.hierarcicalMenu.length - 1].name : ''}}</span>
			</div>
		</div>
	</div>
	<div class="d-block">
		<slctr-hrfiles-cmp [hrModel]="files" [isSearchDisplay]="false" [viewMode]="viewMode" [canDownloadFiles]="true"
			#folderView>
		</slctr-hrfiles-cmp>
	</div>
	<div class="modal-footer pl-0 pr-0 pb-4 pt-4 ml-5 mr-5">
		<div class="d-flex align-items-center">
			<div>

			</div>
			<div class="ml-auto">
				<button (click)="closeModal()"
					class="btn mtm-button b-purple-default c-mtm-purple _700 c-full-white text-uppercase mr-3">Cancel</button>
				<button [disabled]="!selectedFiles()"
					class="btn mtm-button b-mtm-green c-full-white _700 text-uppercase" (click)="createArchive()">Create
					Archive</button>
			</div>
		</div>
	</div>
</div>