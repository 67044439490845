<svg [attr.width]="boxSize" [attr.height]="boxSize">
  <defs>
    <pattern id="image" patternUnits="userSpaceOnUse" [attr.height]="boxSize" [attr.width]="boxSize">
      <image x="0" y="0" [attr.height]="boxSize" [attr.width]="boxSize" [attr.xlink:href]="img"></image>
    </pattern>
  </defs>
  <circle [attr.cx]="cx" [attr.cy]="cy" [attr.r]="radius" [attr.fill]="backgroundColor" />
  <path [attr.d]="circleM+ circleL+ circleA+ '0 ' + arcSweep + circleEnd + 'Z'" [attr.fill]="color" />
  <circle [attr.cx]="cx" [attr.cy]="cy" [attr.r]="innerRadius" fill="url(#image)" [attr.stroke-width]="2"
    [attr.stroke]="'whitesmoke'" [attr.stroke-linecap]="round" />
  <circle [attr.cx]="cx" [attr.cy]="cy" [attr.r]="innerRadius" [attr.fill]="innerFill" [attr.opacity]=".8"
    [attr.stroke-width]="2" [attr.stroke]="'whitesmoke'" [attr.stroke-linecap]="round" />
  <text [attr.x]="fontX" [attr.y]="fontY" [attr.text-anchor]="textAnchor" [attr.fill]="fontColor"
    [attr.font-size]="fontSize" [attr.font-weight]="600" [attr.font-family]="fontFamily">{{circleText}}</text>
</svg>