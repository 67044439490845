import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mtm-grid-skeleton',
  templateUrl: './grid-skeleton.component.html',
  styleUrls: ['./grid-skeleton.component.scss']
})
export class GridSkeletonComponent implements OnInit {
  @Input() widthSizeInPixels;
  @Input() HeightSizeInPixels;
  @Input() gridCount;
  @Input() borderRadius = 0;
  @Input() repeat: number;
  @Input() apperance: string = '';
  @Input() color: string;
  @Input() animation: string = '2s';
  // Create an array based on the 'repeat' input value
  get repeatArray(): number[] {
    if (!this.repeat) {
      this.repeat = 1
    }
    return new Array(this.repeat);
  }  constructor() { }

  ngOnInit(): void {
    // console.log('widthSizeInPixels', this.widthSizeInPixels)
    // console.log('HeightSizeInPixels', this.HeightSizeInPixels)
  }

}
