<div class="card"  *ngIf="rsvp != 'no'" [ngClass]="{'rsvp-maybe': (rsvp == 'maybe')}" (click)="onOpenProject(project)"
	(mouseenter)="cardHover = true" (mouseleave)="cardHover = false">
	<div class="row row-img">
		<div class="card-img" *ngIf="!project.avatarUrl"></div>
		<img class="card-img" *ngIf="project.avatarUrl" [src]="project.avatarUrl">
		<div class="card-img badge-container" style="position: relative">
			<span *ngIf="!isArchived && rsvp == 'yes'
			&& (project.projectType!='ONE_SHOT' || (project.projectType == 'ONE_SHOT' && ( project.entranceFeePaid == true || subscription?.planType == 'ENTERPRISE')))"
				hasUserAccess [projectId]="project.id" [authority]="authority.U" class="badge edit-button"
				(click)="edit()" [title]="'settingsSmall' | translate"><i class="fa fa-cog fa-lg text-danger" aria-hidden="true"></i></span>
			<span *ngIf="(isManager || isAdminUser) && (!isArchived && rsvp == 'yes' && !isFromFreePlan
			&& (project.projectType!='ONE_SHOT' || (project.projectType == 'ONE_SHOT' && ( project.entranceFeePaid == true || subscription?.planType == 'ENTERPRISE'))))"
				class="badge archive-button" [title]="'archive' | translate"
				(click)="archive();$event.stopPropagation()" ><i class="fa fa-archive fa-lg text-danger"
					aria-hidden="true"></i></span>
			<span *ngIf="(isManager || isAdminUser) && (isArchived || isFromFreePlan)" [title]="'delete' | translate"
				class="badge archive-button" (click)="$event.stopPropagation(); delete();"><i class="fa fa-trash fa-lg text-danger"
					aria-hidden="true"></i></span>
			<span *ngIf="(isManager || isAdminUser) && isArchived" class="badge undo-button" [title]="'restore' | translate"
				(click)="$event.stopPropagation(); restoreArchive();"><i class="fa fa-undo fa-lg text-danger"
					aria-hidden="true"></i></span>
			<div *ngIf="!project.isFirstSeen"
				style="position: absolute; top: -10px; right: -20px; background-color: red; padding: 10px;border-radius: 12px">
				<span style="color:white;">New</span>
			</div>
		</div>
	</div>
	<div class="card-block p-3 info-container">
		<div class="trapezoid"></div>
		<h5 class="font-weight-bold break-word">{{ project.name }}</h5>
		<div class="media">
			<div class="d-flex flex-column" [style.gap]="'0.5rem'">
				<div class="rsvp-info">
					<div class="d-flex align-self-center flag-container" *ngIf="rsvp == 'yes'">
						<i class="fa fa-flag fa-lg text-warning" aria-hidden="true"></i>
					</div>
					<div class="media-body ml-3" *ngIf="rsvp == 'yes'">
						<h6 class="text-uppercase text-success">{{'start' | translate}} {{project.startDate | mtmDate}}</h6>
						<h6 class="text-uppercase text-danger">{{'due' | translate}} {{project.displayDueDate | mtmDate}}
						</h6>
					</div>
					<div class="media-body ml-3" *ngIf="rsvp == 'maybe'">
						<button class="btn btn-danger" (click)="onProjectInvitation(true); $event.stopPropagation()">
							<h6 class="mb-0">
								Accept Invitation
							</h6>
						</button>
						<button class="btn btn-outline-secondary"
							(click)="onProjectInvitation(false); $event.stopPropagation()">Reject</button>
					</div>
				</div>
				<div class="top-content d-flex align-items-center" (click)="$event.stopImmediatePropagation()">
					<div ngbDropdown class="d-flex align-items-center" [autoClose]="true" #dropdownProjectStatus>
						<button (click)="$event.stopImmediatePropagation()" type="button"
							class="d-flex align-items-center btn project-status-dropdown" ngbDropdownToggle>
							<div class="d-flex align-items-center project-status {{project.status}}">
								<div class="project-union-icon-container">
									<i class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[project.status]}}"></i>
								</div>
								<span class="mr-2">{{'task_'+project.status|translate}}</span>
								<i class="fa fa-chevron-down ml-auto pointer"></i>
							</div>
						</button>
						<div class="project-dropdown-menu" ngbDropdownMenu>
							<div *ngIf="project.status==projectStatus.Completed" (click)="$event.stopImmediatePropagation();updateProjectStatus(projectStatus.InProgress)"
								class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
								<div class="d-flex align-items-center project-status-display IN_PROGRESS">
									<i class="project-status-circle-color mr-2"></i>
									<span>{{'task_IN_PROGRESS'|translate}}</span>
								</div>
							</div>
							<div *ngIf="project.status==projectStatus.InProgress||project.status==projectStatus.UpcomingExpiry||project.status==projectStatus.Delayed" (click)="$event.stopImmediatePropagation();updateProjectStatus(projectStatus.Completed)"
								class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
								<div class="d-flex align-items-center project-status-display COMPLETED">
									<i class="project-status-circle-color mr-2"></i>
									<span>{{'task_COMPLETED'|translate}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ml-auto unseen-list" *ngIf="additionalData">
				<span class="unread" *ngIf="additionalData.unreadCount">{{additionalData.unreadCount | mtmRoundedNumber}}</span>
				<span class="unseen" *ngIf="additionalData.unseenCount"
					(click)="onNotificationSearch($event)">{{additionalData.unseenCount | mtmRoundedNumber}}</span>
			</div>
		</div>
	</div>
	<div class="card-hover" [hidden]="!cardHover">
		<div class="text-center">
			<i class="fa fa-eye fa-3x" aria-hidden="true"></i>
			<h6 class="text-uppercase mt-2">{{'previewProject' | translate }}</h6>
		</div>
	</div>
</div>