import { ImageComp } from "../lib/image-component";
import { Annotation } from "./annotation";

export class AnnotationState extends ImageComp {

    _enabled: boolean;
    _annotations: Annotation[];
    _activeAnnotation: any;
    currentActive: any;
    hoverActive: boolean;
    hoveredAnnotation: Annotation;
    clickActive: boolean;
    clickedAnnotation: Annotation;

    constructor(image: Object) {
        super(image);
        this.initAPI(this, 'AnnotationState');
        this.currentActive = new Set();

        this.hoverActive = false;
        this.clickActive = false;
        this.hoveredAnnotation = null;
        this.clickedAnnotation = null;

        this.enableAnnotationHover();
        this.enableAnnotationClick();
    }

    // sets _enabled and closes or opens annotation as needed
    set enabled(shouldBeEnabled) {
        this._enabled = shouldBeEnabled;
        if (!shouldBeEnabled) this.closeAllAnnotations();
        if (shouldBeEnabled) {
            this.openAllAnnotations();
        }
    }

    get enabled() {
        return this._enabled;
    }

    // Sets _annotations w/Annotation objects from input array
    set annotations(annotationsData) {
        let _this2 = this;
        this._annotations = annotationsData.map(a => new Annotation(a, _this2.plugin));
    }

    get annotations() {
        return this._annotations;
    }

    set activeAnnotation(annotation) {
        this._activeAnnotation = annotation;
    }

    // Get current active annotation or a null object with .close()
    get activeAnnotation() {
        return this._activeAnnotation || { close: () => { } };
    }

    // Serialize data
    get data() {
        return this._annotations.map(a => a.data);
    }

    openAllAnnotations() {
        const _this2 = this;
        this.annotations.forEach(annotation => {
            _this2.openAnnotation(annotation, false);
        });
    }

    closeAllAnnotations() {
        const _this2 = this
        this.annotations.forEach(annotation => {
            _this2.closeAnnotation(annotation);
        });
    }

    openAnnotation(annotation: Annotation, clickOpen: boolean) {
        if (!this.plugin.active) this.plugin.toggleAnnotationMode();
        let annotationIndex = this.annotations.indexOf(annotation);
        if (!this.currentActive.has(annotationIndex)) {
            annotation.open();
            this.currentActive.add(annotationIndex);
            if (clickOpen) annotation.clickOpen();
        }
        // this.activeAnnotation = annotation;
    }

    closeAnnotation(annotation: Annotation) {
        annotation.close();
        let annotationIndex = this.annotations.indexOf(annotation);
        this.currentActive.delete(annotationIndex);
    }

    destroyAnnotationById(id) {
        let annotation = this.findAnnotation(id);
        if (annotation) annotation.teardown();
    }

    removeAnnotation(annotation: Annotation) {
        let id = annotation.id;

        let i = this._annotations.indexOf(annotation);
        this._annotations.splice(i, 1);
        this.stateChanged();
        this.plugin.fire('annotationDeleted', { id: id, allAnnotations: this.data });
    }

    editAnnotation(annotation: Annotation) {
        let annotIndex = this.annotations.indexOf(annotation);
        if (annotation) {
            this.plugin.controls.editingStart(this.annotations[annotIndex]);
        }
    }

    saveEditedAnnotation(annotationId, isWSCall=false) {
        if (this.plugin.controls.uiState.editing) {
            let annotation = this.findAnnotation(annotationId);
            if (annotation) annotation.updateEditedAnnotation(isWSCall);
            this.plugin.controls.cancelAddNew();
        }
    }

    updateAnnotationById(annotation) {
        let annotationIndex = this.annotations.findIndex((obj => obj.id === annotation.id));
        this.annotations[annotationIndex].updateAnnotation(annotation);
        if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
            this.plugin.controls.cancelAddNew();
            this.closeAnnotation(this.annotations[annotationIndex]);
            this.openAnnotation(this.annotations[annotationIndex], false);
        }

    }

    clearActive() {
        this.activeAnnotation.close(false);
        this._activeAnnotation = null;
    }

    findComment(id) {
        var _ref;

        function _toConsumableArray(arr) {
            if (Array.isArray(arr)) {
                for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                    arr2[i] = arr[i];
                } return arr2;
            } else {
                return Array.from(arr);
            }
        }

        let comments = this.annotations.map(function (a) {
            return a.commentList.comments;
        });
        comments = (_ref = []).concat.apply(_ref, _toConsumableArray(comments)); // flatten 2d array
        return comments.find(function (c) {
            return c.id == id;
        });
    }

    // Returns annotation object given ID
    findAnnotation(id) {
        return this.annotations.find(a => a.id === id);
    }

    createAndAddAnnotation(data) {
        this.plugin.controls.uiState.adding && this.plugin.controls.cancelAddNew();
        console.log(`annotation state data`, data);
        const annotation = Annotation.newFromData(
            data.shape,
            data.commentStr || '',
            data.parentId || null,
            data.resolution,
            this.plugin,
            data.id,
            data.privateConditionKey
        )
        this.addNewAnnotation(annotation);
    }

    newAnnotationUpdate(annotationData) {
        const newAnnotation = new Annotation(annotationData, this.plugin);
        this._annotations.push(newAnnotation);
        // if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
        //     this.plugin.controls.cancelAddNew();
        //     this.openAllAnnotations();
        // }
        // console.log("test")
        this.openAnnotation(newAnnotation, false);
      }

    // Add a new annotation
    addNewAnnotation(annotation) {
        this._annotations.push(annotation);
        this.openAllAnnotations();
        this.stateChanged();
        let data = {"newAnnotation": annotation, "allAnnotations": this.data}
        this.plugin.fire('annotationCreated', data);
        // this.plugin.fire('addedNewAnnotation', { 'annotation': annotation.data });
    }

    hoverInAnnotation(id) {
        const annotation = this.findAnnotation(id);
        annotation.hoverOpen();
        this.hoveredAnnotation = annotation;
        this.plugin.primordial.activeAnnotation = {
            annotation: annotation.data,
            type: 'hover',
            username: annotation.commentList.comments[0].meta.user_id
        };
    }

    hoverOutAnnotation() {
        if (this.hoveredAnnotation) {
            this.hoveredAnnotation.hoverClose();
            this.hoveredAnnotation = null;
            this.plugin.primordial.activeAnnotation = null;
        }

    }

    clickInAnnotation(id) {
        const annotation = this.findAnnotation(id);

        // Closing annot if clicked on when already active
        if (this.clickedAnnotation && this.clickedAnnotation.id == annotation.id) {
            this.clickOutAnnotation();
        }
        // Close if clicking on new object after already clicking on another
        else if (this.clickedAnnotation) {
            this.clickOutAnnotation();
        }
        else {
            this.disableAnnotationHover();
            annotation.clickOpen();
            this.clickedAnnotation = annotation;
            this.plugin.primordial.activeAnnotation = {
                annotation: annotation.data,
                type: 'click',
                username: annotation.commentList.comments[0].meta.user_id
            };
        }
    }

    clickOutAnnotation() {
        if (!this.clickedAnnotation) return;
        this.clickedAnnotation.clickClose();
        this.clickedAnnotation = null;
        this.clickActive = false;
        this.disableMouseEvents();
        this.enableAnnotationHover();
        this.enableAnnotationClick();
        this.plugin.enableImageMove();
        this.plugin.controls.selectableShape.bindEvents();
        this.plugin.primordial.activeAnnotation = null;
    }

    disableMouseEvents() {
        this.plugin.canvasFabric.off('mouse:down');
		this.plugin.canvasFabric.off('mouse:move');
		this.plugin.canvasFabric.off('mouse:up');
    }

    enableAnnotationHover() {
        if (this.hoverActive) return;
        const _this2 = this;
        const canvasFabric = _this2.plugin.canvasFabric;
        _this2.hoverActive = true;

        // Event for doing function on hover object
        canvasFabric.on('mouse:move', function (e) {
            let pointer = canvasFabric.getPointer(e.e);
            let isInside = _this2.isInsideImage(pointer);
            if (e.target && isInside && !_this2.hoveredAnnotation) {

                const id = e.target.id;
                _this2.hoverInAnnotation(id);

            }
            else if (!isInside) {
                _this2.hoverOutAnnotation();
            }
        });

        // Event for reverting to normal when hovering out of object
        canvasFabric.on('mouse:out', function (e) {
            _this2.hoverOutAnnotation();
        });
    }

    isInsideImage(pointer) {
        const canvasFabric = this.plugin.canvasFabric;
        if (!canvasFabric.backgroundImage) {
            return;
        }
        let isInside = false;
        const imageCoords = canvasFabric.backgroundImage.calcACoords();

        let width = Math.abs(imageCoords.bl.x - imageCoords.br.x);
        let height = Math.abs(imageCoords.bl.y - imageCoords.tl.y);

        if (pointer.x < 0 || pointer.x > width || pointer.y < 0 || pointer.y > height) {
            return false;
        }
        else {
            return true;
        }


    }

    disableAnnotationHover() {
        if (!this.hoverActive) return;
        const _this2 = this;
        const canvasFabric = _this2.plugin.canvasFabric;
        _this2.hoverActive = false;
        if (_this2.hoveredAnnotation) {
            _this2.hoveredAnnotation.hoverClose();
            _this2.hoveredAnnotation = null;
            _this2.plugin.primordial.activeAnnotation = null;
        }

        canvasFabric.off('mouse:move');
        canvasFabric.off('mouse:out');
    }

    enableAnnotationClick() {
        if (this.clickActive) return;
        const _this2 = this;
        const canvasFabric = this.plugin.canvasFabric;
        this.clickActive = true;

        //Event for clicking on object
        canvasFabric.on('mouse:down', function (e) {
            let pointer = canvasFabric.getPointer(e.e);
            let isInside = _this2.isInsideImage(pointer);
            if (e.target && isInside) {
                const id = e.target.id;
                _this2.clickInAnnotation(id);
            }
            else {
                if (_this2.clickedAnnotation) {
                    _this2.clickOutAnnotation();
                }
            }
        });
    }

    disableAnnotationClick() {
        if (!this.clickActive) return;
        let _this2 = this;
        const canvasFabric = this.plugin.canvasFabric;
        this.clickActive = false;
        canvasFabric.off('mouse:down');
        if (_this2.clickedAnnotation) {
            _this2.clickedAnnotation.clickClose();
            _this2.clickedAnnotation = null;
        }



    }

    stateChanged() {
        this.plugin.fire('onStateChanged', this.data);
    }

    // Reset internal state properties
    resetData() {
        this.annotations = [];
        this.activeAnnotation = null;
        this.enabled = false;
    }

    teardown() {
        this.annotations.forEach(annotation => {
            annotation.teardown(false);
        });
        this.disableAnnotationHover();
        this.disableAnnotationClick();
        this.resetData();
        super.teardown();
        return null;
    }
}
