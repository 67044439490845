import {ICellRendererAngularComp} from "ag-grid-angular";
import {Component} from "@angular/core";

@Component({
  templateUrl: './date-cell-renderer.component.html',
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  data: any;

  constructor() { }

  agInit(params: any): void {
    this.data = params.value;
    this.params = params;
  }

  refresh(params: any): boolean {
    this.data = params.value;
    return false;
  }
}
