
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmitterService } from "./emitter.service";
import { AuthService } from "./auth.service";
import { BroadcastService } from "./broadcast.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class VersionCheckService {

	private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

	constructor(private http: HttpClient, private authService: AuthService, private broadcastService: BroadcastService, private router: Router) {
		console.log("Initial Hash: ", this.currentHash)
	}

	/**
	 * Checks in every set frequency the version of frontend application
	 * @param url
	 * @param {number} frequency - in milliseconds, defaults to 2 minutes
	 */
	public initVersionCheck(frequency = 1000 * 60 * 2) {
		setInterval(() => {
			this.checkVersion(environment.versionCheckURL);
		}, frequency);
		setTimeout(() => {
			this.checkVersion(environment.versionCheckURL);
		}, 1000);
	}
	/**
	 * Will do the call and check if the hash has changed or not
	 * @param url
	 */
	private checkVersion(url) {

		if (this.authService.getAuthUser(false) == null && this.authService.getAuthToken() == null)
			return;

		// timestamp these requests to invalidate caches
		this.http.get(url + '?t=' + new Date().getTime()).pipe(
			first())
			.subscribe(
				(response: any) => {
					const hash = response.hash;
					const hashChanged = this.hasHashChanged(this.currentHash, hash);

					console.log('Current hash: ', this.currentHash);
					console.log('Response hash: ', hash);
					console.log('Hash changed', hashChanged);

					if (hashChanged) {
						console.log("Need to log out");
						this.authService.logout();
						EmitterService.get('user.logout').emit();
						this.broadcastService.send({ action: 'user.logout' });
						window.location.reload();
					}
					this.currentHash = hash;
				},
				(err) => {
					console.error(err, 'Could not get version');
				}
			);
	}

	/**
	 * Checks if hash has changed.
	 * This file has the JS hash, if it is a different one than in the version.json
	 * we are dealing with version change
	 * @param currentHash
	 * @param newHash
	 * @returns {boolean}
	 */
	private hasHashChanged(currentHash, newHash) {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}

		return currentHash !== newHash;
	}
}
