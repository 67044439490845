import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DriveCampaignColor, DriveCampaignStatus } from 'app/layouts/my-projects/project.const';
import { TaskColor, TaskStatus } from 'app/shared/components/task/task-status.const';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthService } from 'app/shared/services/auth.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectService } from 'app/shared/services/project.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { UserTourService } from 'app/shared/services/user-tour.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { Subject, takeUntil } from 'rxjs';

export enum projectTypeOptions {
  // All_PROJECTS = 'All Projects',
  STAND_ALONE_PROJECTS = 'Stand Alone Projects',
  // SHARED_PROJECTS = 'Shared Projects',
  DRIVE_CAMPAIGN = 'Drive Campaign Projects',
  CAMPAIGN = 'Campaign Projects',
  // POERCELL_CAMPAIGN = 'Powercell Campaign',
  // REPLASTY_DRIVE_CAMPAIGN = 'Replasty Drive Campaign',
}

@Component({
  selector: 'mtm-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss', '../campaign-drive/campaign-drive.component.scss']
})

export class ProjectsComponent implements OnInit, OnDestroy {

  authUser: any;
  projectSearch: string;
  calendarViewMode = 'Latest';
  workspaces: any[] = [];
  workspaceId: string;
  selectedProjectType: string;
  canCreateProject: boolean = false;
  canEditStatus: boolean = false;
  isError: boolean = false;
  isLoaded: boolean = false;
  projectType = Object.values(projectTypeOptions);
  isStandAlone: boolean = false;
  isArchiveProjects: boolean = false;
  ngUnsubscribe = new Subject();
  taskColor = TaskColor;
  taskStatus = TaskStatus;
  projectColor = DriveCampaignColor;
  projectStatus = DriveCampaignStatus;
  projects: any[] = [];
  backupProjects: any[] = [];
  favprojects: any[] = [];
  favbackupProjects: any[] = [];
  archiveBackupProjects: any[] = [];
  archiveProjects: any[] = [];
  isAll: boolean = true;
  isFav: boolean = false;
  isArchived: boolean = false;
  selectedWorkspace: any = {
    name: 'All Workspaces',
    id: null
  };

  constructor(
    private authService: AuthService,
    public timelineService: TimelineService,
    private workspaceService: WorkspaceService,
    private permissionService: PermissionService,
    private projectService: ProjectService,
    private router: Router,
    private analyticService: AnalyticsService,
    private notificationService: NotificationService,
    private overlayService: OverlayService,
    private translate: TranslatePipe,
    private cryptoService: CryptoService,
    private route: ActivatedRoute,
    private userTourService: UserTourService,
    private stService: SelfTrainerService,
  ) { }

  ngOnInit(): void {
    // resetTab
    this.timelineService.resetTabsEmmiter.emit(true);

    this.authUser = this.authService.getAuthUser();

    this.timelineService.isDriveNav.emit(true);
    const brand = this.authService.getMyBrand();
    this.timelineService.componentName.next({
      componentName: 'projectsComponent',
      bannerUrl: brand.banner
    });
    this.canCreateProject = (((this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P,null));
    this.canEditStatus = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S));

    // set default stand alone project
    this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS;
    if (this.selectedProjectType == projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.isStandAlone = true;
      this.getStandAloneProjects();
    }

    // trigger tabs
    this.getTabsInputs();

    // get brand workspaces
    this.getWorkspaces();
  }

  ngOnDestroy(): void {
    this.userTourService.destroyTour();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    // this.timelineService.isDriveNav.emit(false);
  }

  searchProject() {
    if (this.projectSearch) {
      this.projects = this.backupProjects.filter(campaign => {
        let isMatched = true;
        if (this.projectSearch) {
          if (!campaign.name) {
            isMatched = false;
          } else {
            isMatched = campaign.name.toLowerCase().indexOf(this.projectSearch.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.projects = this.backupProjects;
          }
        }
        return isMatched;
      })
    } else {
      this.projects = this.backupProjects;
    }
  }

  selectCalendarMode(mode) {
    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        break;
      default:
        this.calendarViewMode = 'Latest';
        break;
    }
    console.log('this.calendarViewMode', this.calendarViewMode)
  }

  markAsFavorite(project) {
    if (this.selectedProjectType === projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.analyticService.markAsFavorite(project, 'STANDALONE_PROJECT').pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (res: any) => {
          console.log('res', res)
          this.projects = this.projects.map(project => ({
            ...project,
            isFavorite: res.items.includes(project.id)
          }));
        },
        error: (err) => {
          console.error(err);
          project.isFavorite = false;
        },
        complete: () => {
        }
      });
    }
  }

  removeFavorite(project) {
    this.analyticService.removeAsFavorite(project, 'STANDALONE_PROJECT').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // this.projects = this.projects.map(project => ({
        //   ...project,
        //   isFavorite: res.items.includes(project.id)
        // }));
        this.getStandAloneProjects();
        // this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
      }
    });
  }

  getFavoriteProjects() {
    this.projects = [];
    this.backupProjects = [];
    this.isError = false;
    this.isLoaded = false;
    this.analyticService.getFavoriteItem('STANDALONE_PROJECT').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        if (this.isFav) {
          this.projects = res;
          this.backupProjects = res;
          this.projects.forEach(project => {
            project.isFavorite = true;
          });
          this.backupProjects.forEach(project => {
            project.isFavorite = true;
          });
          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.isLoaded = true;
        } else if (this.isAll) {
          this.favprojects.forEach(project => {
            const foundproject = res.find(w => w.id === project.id);
            project.isFavorite = foundproject !== undefined;
          });
          this.favbackupProjects.forEach(project => {
            const foundproject = res.find(w => w.id === project.id);
            project.isFavorite = foundproject !== undefined;
          });
          // remove duplicate items
          this.projects = this.favprojects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupProjects = this.favbackupProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.isLoaded = true;
        } else if (this.isArchived) {
          this.archiveProjects.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.archiveBackupProjects.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.isLoaded = true;
          // remove duplicate items
          this.projects = this.archiveProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupProjects = this.archiveBackupProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        }
        this.isLoaded = true;
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getTabsInputs() {
    this.timelineService.isAll.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = true;
          this.isFav = false;
          this.isArchived = false
          if (this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS) {
            this.getStandAloneProjects();
          }
          this.timelineService.emitIsAll(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isFavorites.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = true;
          this.isArchived = false
          this.getFavoriteProjects();
          this.timelineService.emitIsFavorites(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isArchived.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = false;
          this.isArchived = true
          if (this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS) {
            this.getStandAloneArchiveProjects();
          }
          this.timelineService.emitIsArchived(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getStandAloneProjects() {
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;

    this.projectService.getProjectsUser(this.authUser.email).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.projects = data;
        this.backupProjects = data;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favbackupProjects = this.backupProjects;
        this.favprojects = this.projects;
        this.isLoaded = true;
        this.getFavoriteProjects();
        setTimeout(() => {
          const tourIDs = ['projectCardTour'];
          // this.userTourService.initiate(tourIDs);
        }, 1000);
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {

      }
    })
  }

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  changeProjectType(event) {
    this.selectedProjectType = event;
    switch (event) {
      case projectTypeOptions.STAND_ALONE_PROJECTS:
        this.isStandAlone = true;
        this.getStandAloneProjects();
        break;
      // case projectTypeOptions.All_PROJECTS:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      case projectTypeOptions.CAMPAIGN:
        this.isStandAlone = false;
        this.projects = [];
        this.getCamapignAndDriveCampaign();
        break;
      // case projectTypeOptions.POERCELL_CAMPAIGN:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      case projectTypeOptions.DRIVE_CAMPAIGN:
        this.isStandAlone = false;
        this.projects = [];
        this.getCamapignAndDriveCampaign();
        break;
      // case projectTypeOptions.REPLASTY_DRIVE_CAMPAIGN:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      // case projectTypeOptions.SHARED_PROJECTS:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      default:
        break;
    }
  }

  createProject() {
    this.router.navigate(['/projects/new/company']);
  }

  editProject(project) {
    console.log('project', project)
    let projectId = project.id;
    this.router.navigate([`/projects/edit/${projectId}/company`]);
  }

  deleteProject(project) {
    console.log('project', project)
  }

  changeProjectStatus(item, status) {
    // this.dropdownProjectStatus.close();
    item.status = status;
    // this.projectV2Service.updateDriveCampaign(item)
    //   .subscribe(res => {
    //   });
  }

  getStandAloneArchiveProjects() {
    // this.isArchiveProjects = true;
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;
    this.projectService.getArchivedProjects().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data: any) => {
        if (!Array.isArray(data))
          return;

        let projects = [];
        data.forEach((project: any) => {
          projects.push(project);
          if (project.avatarUrl)
            project.avatarUrl = project.avatarUrl + '?' + (new Date).getTime();
        });

        this.projects = projects;
        this.backupProjects = projects;
        this.isLoaded = true;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.archiveProjects = this.projects;
        this.archiveBackupProjects = this.backupProjects;
        // this.projects = this.projects.filter(project => project.milestoneId === this.driveCampaignId);
        this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      }
      // this.showInfoForArrayData(this.archivedProjects, 'You don’t have any archived project');
    });
  }

  archiveRestoreProject(project) {
    if (!project.deleted) {
      project.deleted = !project.deleted;
      this.projectService.deleteProject(project.id, project.deleted).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (res) => {
          project.deleted = !project.deleted;
          this.projects = this.projects.filter(p => p.id != project.id);
          // Optionally update other properties in your project if needed
        },
        error: (err) => {
          this.notificationService.open({
            title: 'Warning',
            description: err.message,
            confirmBtn: 'Ok'
          });
        }
      })
    } else {
      this.projectService.restoreArchive(project.id).subscribe({
        next: (result: any) => {
          this.projects = this.projects.filter(project => project.id != result.id);
        },
        error: (err: unknown) => {
          this.overlayService.showError(this.translate.transform('overlayErrorSave'), 'Error');
        }
      });
    }
  }

  getWorkspaces() {
    this.workspaces = [];
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
            this.workspaces.sort((d1, d2) => d1.name.localeCompare(d2.name));
            if (this.workspaceId) {
              let workspace = this.workspaces.find(w => w.id === this.workspaceId);

              if (workspace) {
                this.selectedWorkspace = workspace;
              } else {
                console.warn('Workspace not found with id:', this.workspaceId);
              }
            }
          })
        }, error: (err) => {
          console.error('err', err)
        }
      });
  }

  changeWorkspace(workpsace) {
    if (workpsace === 'all_workspaces') {
      this.selectedWorkspace = {
        name: 'All Workspaces'
      }
      this.workspaceId = '';
      const navigationExtras: NavigationExtras = {
        queryParams: {}
      };

      this.router.navigate(['/workspaces/projects'], navigationExtras);
    } else {
      this.selectedWorkspace = workpsace;
      if (workpsace.id || this.workspaceId) {
        let wid = this.cryptoService.encryptString(workpsace.id);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            wid: wid,  // Use the encrypted workspaceId
          },
          queryParamsHandling: 'merge',  // Preserve existing queryParams
        });
      }
      this.workspaceId = workpsace.id;
    }
    if (this.selectedProjectType == projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.isStandAlone = true;
      this.getStandAloneProjects();
    }
  }

  getCamapignAndDriveCampaign() {
    let isDriveCampaign: any;
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;
    if (this.selectedProjectType === projectTypeOptions.CAMPAIGN) {
        isDriveCampaign = false;
    } else if (this.selectedProjectType === projectTypeOptions.DRIVE_CAMPAIGN) {
      isDriveCampaign = true;
      console.log('isDriveCampaign', isDriveCampaign)
    }

    this.analyticService.getProjectsUser(this.authUser.email,isDriveCampaign).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.projects = data;
        this.backupProjects = data;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favbackupProjects = this.backupProjects;
        this.favprojects = this.projects;
        this.isLoaded = true;
        this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {

      }
    })
  }

  redirectToDashboard(project){
    // for self trainer
    this.stService.hideTrainerModalAndStartTask();
    // this.userTourService.trackUserTourActivity('projectCardTour',true);
    // this.userTourService.completeTour('projectCardTour');

    console.log('project', project)
    let projectId = project.id;
    this.router.navigate([`/projects/${projectId}/dashboard`]);
  }
}
