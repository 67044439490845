import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
	providedIn: 'root'
})
export class DateHelperService {

	// static getDateToAPI(date: Date):

	static START_OF_DAY: string = 'start_of_day';
	static END_OF_DAY: string = 'end_of_day';


	/**
	 * Returns a Date object based on datepicker selection
	 * So, convert from `{ day: 6, month: 1, year: 2017 }` to `Thu Jun 01 2017 00:00:00 GMT-0300`
	 * @param date
	 * @param time If START_OF_DAY, set hour to 0 (start of day). If END_OF_DAY, set hour at 23.59 (end of day)
	 * @return Date
	 */
	static fromDatePickerToDate(date: any, time: string = null): Date {
		if (date == null) {
			return null;
		}
		let d = new Date(date.year, date.month - 1, date.day);

		let utcOffset = moment().utcOffset() / 60;
		if ((time == null) || (time == DateHelperService.START_OF_DAY))
			d = moment(d).hour(0).minute(0).seconds(0).utcOffset(utcOffset).toDate();
		else
			d = moment(d).hour(23).minute(59).seconds(59).utcOffset(utcOffset).toDate();

		return d;
	}


	/**
	 * Return date formatted to be sent to API. It is based on Date object
	 * From date `` to `getFromDateToApi to string `2017-06-01T03:00:42`
	 * @param date
	 * @return string
	 */
	static fromDateToApi(date: Date, time: string = null): string {
		if (date)
			return DateHelperService.fromDateToUTCString(date, time);
		else
			return null;
	}


	/**
	 * Convert Date object to ISO string used in API (2017-04-01T00:00:00Z)
	 * @param date
	 * @param time
	 */
	static fromDateToUTCString(date: Date, time: string = null): string {
		// 2017-04-01T00:00:00Z
		let year = date.getFullYear();
		let month: any = date.getMonth() + 1;
		if (month < 10)
			month = '0' + month;
		let day: any = date.getDate();
		if (day < 10)
			day = '0' + day;

		let formatted = '';
		if ((time == null) || (time == DateHelperService.START_OF_DAY))
			formatted = `${year}-${month}-${day}T00:00:00Z`;
		else
			formatted = `${year}-${month}-${day}T23:59:59Z`;

		return formatted;
	}


	/**
	 * Return Date object from timestamp
	 * @param timestamp
	 */
	static fromTimestampToDate(timestamp: any, utcOffset: number = 0): Date {

		if (timestamp) {
			let m = moment.tz(timestamp, 'UTC')
			if (utcOffset > 0) {
				m.add(utcOffset, "hours");
			}
			let days = m.date();
			let months = m.month();
			let years = m.year();
			let hours = m.hours();
			let minutes = m.minutes();
			let seconds = m.seconds();

			return (new Date(years, months, days, hours, minutes, seconds));
		}

		return null;
	}


	/**
	 * Convert from Date object to datepicker format (json)
	 * @param date
	 * @return json
	 */
	static fromDateToDatepicker(date: Date): any {
		if (date)
			return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
		return null;
	}


	/**
	 * Format timezone names from momentjs
	 * @param tzName
	 * @return string
	 */
	static formatTimezoneName(tzName: string) {

		let tzNameFilterArray = new Array<string>();

		let tzNameArray = tzName.split('');
		tzNameArray.forEach(c => {
			if (c == '/')
				c = ' / ';
			else if (c == '_')
				c = ' ';
			tzNameFilterArray.push(c);
		})

		return tzNameFilterArray.join('');
	}


	/**
	 * Convert time (hour, min, sec) to seconds
	 * @param hour
	 * @param min
	 * @param sec
	 * @return number
	 */
	static fromTimeToSeconds(hours: string | number, minutes: string | number, seconds: string | number): number {

		hours = +hours;
		minutes = +minutes;
		seconds = +seconds;

		return (seconds + (minutes * 60) + (hours * 3600));
	}


	/**
	 * From seconds to time (hour, min, sec)
	 * @param sec
	 * @return number
	 */
	static fromSecondsToTime(durationInSeconds: string | number): any {

		let time = {
			hours: 0,
			minutes: 0,
			seconds: 0
		}

		if ("undefined" === typeof durationInSeconds)
			return time;

		else {

			// Convert string to number (if needed)
			durationInSeconds = +durationInSeconds;

			// Convert to hours, minutes and seconds
			let hours: number = Math.floor(durationInSeconds / 3600);
			var minutes: number = Math.floor((durationInSeconds - (hours * 3600)) / 60);
			var seconds = durationInSeconds - (hours * 3600) - (minutes * 60);

			time.hours = hours;
			time.minutes = minutes;
			time.seconds = seconds;

			return time;
		}
	}


	/**
	 * Check if value is a valid date
	 * @param value
	 */
	static isValidDate(value: any): boolean {
		let valid = true;
		if (Object.prototype.toString.call(value) === "[object Date]") {

			// It is a date
			if (isNaN(value.getTime())) // d.valueOf() could also work
				valid = false;
			else
				valid = true;
		}
		else
			valid = false;
		return valid;
	}


	static addMinutesToDate(date: Date, minutes: number) {
		return new Date(date.getTime() + minutes * 60000);
	}

	static beutifyDateObject(date: any) {
		return `${date.month}/${date.day}/${date.year}`;
	}

	static startOfDay(date: any): any {
		return new Date(date).setHours(0, 0, 0, 0);
	}

	static endOfDay(date: any): any {
		return new Date(date).setHours(23, 59, 59, 999);
	}

	static localToTimezoneToUtc(date: { year: any, month: any, day: any }, time: { hour: any, minute: any }, timezone: any) {
		return moment.tz(`${date.year}-${date.month}-${date.day} ${time.hour}:${time.minute}`, timezone).utc().format();
	}

}
