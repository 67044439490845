import { Injectable } from "@angular/core";
@Injectable({
	providedIn: 'root'
})
export class DataTransferBetweenComponentsService {
	public activeFileUploadPage: boolean = false;

	public breadcrumbComponent: any;
	public ngOnChangesBreadcrumbComponent() {
		if (this.breadcrumbComponent)
			this.breadcrumbComponent.ngOnChanges();
	}

}
