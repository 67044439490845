import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
// import { l } from "@angular/core/src/render3";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { CompanyRolesAndPermissionComponent } from "app/shared/components/company-roles-and-permissions/company-roles-and-permissions.component";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { EmitterService } from "app/shared/services/emitter.service";
import { HelperService } from "app/shared/services/helper.service";
import { OverlayService } from "app/shared/services/overlayService";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { activeLanguageUpdated } from "app/shared/services/translate.service";
import { UserService } from "app/shared/services/user.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { UserWorkspaceRole } from "app/workspace/models/workspace.model";
import { Subject } from "rxjs";
import { forkJoin, zip } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../../../../shared/services/auth.service";
import { NewWorkspaceUserComponent } from "../new-user/new-workspace-user.component";
import { UserPermissionTableHeader } from "./table/header/user-permission-table-header.component";
import { userPermissionColumnsDef } from "./table/user-permission-table.coldef";

@Component({
	selector: 'mtm-edit-workspace-permissions',
	templateUrl: './workspace-permissions.component.html',
	styleUrls: ['./workspace-permissions.component.scss']
})
export class EditWorkspacePermissionsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(AgGridAngular) userPermissionTable!: AgGridAngular;
	private _workspaceId: string = '';
	private _workspace: any = null;
	private workspaces: any[] = null;
	private allRoles: any[] = [];
	private availableRoles: any[] = [];
	private companyUsers: any[];
	private rolesToExclude: Set<string> = new Set();
	private newModalRef: NgbModalRef;
	private ngUnsubscribe = new Subject();
	users: UserWorkspaceRole[] = [];
	filteredUsers: UserWorkspaceRole[] = [];
	firstNameFilter: string = '';
	lastNameFilter: string = '';
	emailFilter: string = '';
	sortedColumn: string = '';
	sortAscending: boolean = true;

	rowFiltered: boolean = false;

	activationStatuses = [
		{ code: 'ACTIVE_COMPLETED', label: 'Active Completed' },
		{ code: 'PENDING_INPROGRESS', label: 'Pending In Progress' }
	];


	get workspaceId(): string {
		return this._workspaceId;
	}

	@Input()
	set workspaceId(value: string) {
		this._workspaceId = value;
		this.loadWorkspace();
	}


	private getRoleOrder(code: string): number {
		switch (code) {
			case 'COMPANY_PRINCIPAL':
				return 1;
			case 'COMPANY_REGULAR':
				return 3;
			default:
				return 2;
		}
	}

	@Input()
	set roles(value: any[]) {
		this.allRoles = value;
		this.initRoleSet();
		let isAdminUser = this.permissionService.hasAuthority(Authority.Z, null);
		let rolesToShow = value.filter(r => (r.code.startsWith('COMPANY') && !this.rolesToExclude.has(r.code)) ||  (isAdminUser && r.code === 'ADMIN'));
		rolesToShow.sort((a: any, b: any) => (this.getRoleOrder(a.code) < this.getRoleOrder(b.code) ? -1 : 1));
		this.availableRoles = rolesToShow;
		this.initUsers();
	}

	defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		headerValueGetter: this.getHeaderText.bind(this),
	};

	userPermissionColumnsDef = userPermissionColumnsDef;
	tableContext: any;
	public components: { [p: string]: any } = { agColumnHeader: UserPermissionTableHeader, };

	selectedRoles: any = [];

	constructor(private authService: AuthService,
		private workspaceService: WorkspaceService,
		private permissionService: PermissionService,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private translatePipe: TranslatePipe,
		private userService: UserService) {
		this.tableContext = {
			componentParent: this
		};
	}

	private loadWorkspace() {
		const authUser = this.authService.getAuthUser();
		this.workspaceService.getWorkspaces(authUser.company.id)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(workspaces => {
				this.workspaces = workspaces;
				this._workspace = workspaces.find(w => w.id == this.workspaceId);
				this.initUsers();
			});

	}

	private initRoleSet() {
		if (this.rolesToExclude.size > 0)
			return;

		if (this.rolesToExclude.size == 0) {
			if (!this.permissionService.hasAuthority(Authority.S, null))
				this.rolesToExclude.add('COMPANY_PRINCIPAL');

			this.rolesToExclude.add('COMPANY_OCCASIONAL');
		}
		const authUser = this.authService.getAuthUser();
		this.rolesToExclude.add(authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PRODUCTION_OWNER' : 'COMPANY_PROJECT_OWNER');
	}

	ngOnInit() {
		this.initRoleSet();
		const authUser = this.authService.getAuthUser();
		this.userService.getUsersByCompanyId(authUser.company.id)
			.subscribe(data => {
				this.companyUsers = data;
				this.initUsers();
			});

		activeLanguageUpdated
			.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				setTimeout(() => this.userPermissionTable?.api?.refreshHeader(), 100);
			});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.userPermissionTable?.api?.refreshHeader();
		}, 100);
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	isRoleChangable(code: string, username: string): { shouldChange: boolean, canChange: boolean } {
		const shouldChange = !code || code.startsWith('PROJECT') || code.startsWith('PRODUCTION')
			|| code == 'COMPANY_OCCASIONAL';
		let canChange = shouldChange || (code.startsWith('COMPANY') && code != 'COMPANY_PRINCIPAL');
		if (canChange) {
			//check if user is part of multiple company workspaces
			//if yes then cant
			const involvedWorkspaces = this.workspaces.filter(w => Array.isArray(w.usernames) && w.usernames.indexOf(username) > -1);
			if (involvedWorkspaces.length > 1) {
				canChange = false;
			}
		}

		return {
			shouldChange,
			canChange
		};
	}

	private initUsers() {
		if (!this._workspace && !this.allRoles)
			return;

		this.users = [];
		if (this._workspace && this._workspace.usernames && this.companyUsers) {
			this._workspace.usernames.forEach(u => {
				const user = this.companyUsers.find(cu => cu.username == u);
				if (user != null) {
					const userRole = this.allRoles.find(r => r.code == user.globalRole);
					let roleLabel = userRole ? userRole.label : '';
					let roleCode = user.globalRole;
					const { shouldChange, canChange } = this.isRoleChangable(roleCode, user.username);
					if (shouldChange) {
						roleCode = '';
						roleLabel = '';
					}
					const statusInfo = this.activationStatuses.find(s => s.code == user.activationStatus);
					const userData = {
						user,
						fullName: user.fullName ? user.fullName.trim() : '',
						username: user.username,
						jobTitle: user.jobTitle,
						roleLabel: roleLabel,
						role: roleCode,
						canChange,
						status: statusInfo,
						firstName: user.firstName,
						lastName: user.lastName

					};
					this.users.push(userData);
				}
			});
			this.filterUsers();
		}
	}

	removeUser(user) {
		if (!user || user.code == 'COMPANY_PRINCIPAL')
			return;
		const index = this.users.findIndex(u => u.username == user.username);
		if (index == -1)
			return;
		const authUser = this.authService.getAuthUser();
		this.workspaceService.removeUserFromWorkspace(authUser.company.id, this._workspace.id, user.username)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				() => {
					this.users.splice(index, 1);
					this.filterUsers();
				},
				() => {
					this.overlayService.showError(this.translatePipe.transform('workspace_removeUserFailed'), 'Error');
				}
			);

	}

	showUserModal() {
		this.newModalRef = this.modalService.open(NewWorkspaceUserComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		const instance = this.newModalRef.componentInstance;
		instance.workspaceRoles = this.availableRoles.filter(r => r.code != 'ADMIN');
		instance.projectRoles = this.allRoles.filter(r => r.code.startsWith('PROJECT') || r.code.startsWith('PRODUCTION'));
		instance.companyUsers = this.companyUsers;
		instance.currentUsers = [...this.users];
		instance.workspaces = this.workspaces;
		this.newModalRef.result.then(result => {
			this.addNewResult(result);
		});
	}

	addNewResult(result: any) {
		const { username, role } = result;

		const currentUser = this.users.find(u => u.username == username);
		if (currentUser != null)
			return;

		let selectedRole = this.allRoles.find(a => a.code == role);
		if (!selectedRole)
			return;

		let roleLabel = selectedRole.label;
		let newUser: UserWorkspaceRole = null;

		const companyUser = this.companyUsers.find(u => u.username == result.username);
		if (companyUser) {
			//if existing company user,
			//if has global role and role isnt starting with PROJECT and PRODUCTION then change role
			//else keep his global role
			let newRole = role;
			let currentRole = companyUser.globalRole;
			let { canChange } = this.isRoleChangable(currentRole, companyUser.username);
			if (!canChange) {
				newRole = currentRole;
				selectedRole = this.allRoles.find(a => a.code == newRole);
				if (!selectedRole)
					return;
				roleLabel = selectedRole.label;
			}
			const statusInfo = this.activationStatuses.find(s => s.code == companyUser.activationStatus);

			newUser = {
				user: companyUser,
				fullName: companyUser.fullName,
				username,
				jobTitle: companyUser.jobTitle,
				roleLabel,
				role: newRole,
				canChange,
				status: statusInfo,
				firstName: companyUser.firstName,
				lastName: companyUser.lastName
			};
		} else {
			const statusInfo = this.activationStatuses.find(s => s.code == 'PENDING_INPROGRESS');
			newUser = {
				fullName: '',
				username,
				jobTitle: '',
				roleLabel,
				role,
				canChange: true,
				status: statusInfo,
				firstName: '',
				lastName: '',
			};
		}

		const authUser = this.authService.getAuthUser();
		this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
			newUser.username, newUser.role, result.defaultProjectRoles || [])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.users.push(newUser);
				this.filterUsers();
			}, () => {
				this.overlayService.showError(this.translatePipe.transform('workspace_addUserFailed'), 'Error');
			});
	}

	updateRole(user) {
		if (user.role == '')
			return;

		console.log('user', user);
		const authUser = this.authService.getAuthUser();
		this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
			user.username, user.role, [])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				() => {
					console.log(`update ${user.role} success`);
				},
				() => {
					this.overlayService.showError(this.translatePipe.transform('workspace_updateRoleFailed'), 'Error');
				});
	}

	save() {
		const authUser = this.authService.getAuthUser();
		const workspaceData = {
			companyId: authUser.company.id,
			name: this._workspace.name,
			userNames: this.users.map(u => u.username),
			projectIds: this._workspace.projectIds,
			id: this._workspace.id
		};

		const $obs = [];
		this.users.forEach(user => {
			$obs.push(this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
				user.username, user.role, []));
		});
		forkJoin([
			...$obs
		]).pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
			});
	}

	showPermissionDetailsInfo() {
		this.modalService.open(CompanyRolesAndPermissionComponent, { backdrop: true, windowClass: 'company-roles-and-permission-modal' });
	}

	sortColumn(columnName) {
		if (this.sortedColumn != columnName) {
			this.sortAscending = true;
			this.sortedColumn = columnName;
		} else {
			this.sortAscending = !this.sortAscending;
		}
		this.filterUsers();
	}

	filterUsers() {
		this.filteredUsers = [...this.users];
		this.rowFiltered = !!(this.emailFilter || this.firstNameFilter || this.lastNameFilter || this.selectedRoles?.roles?.length);
		if (this.emailFilter || this.firstNameFilter || this.lastNameFilter || this.selectedRoles?.roles?.length) {
			this.filteredUsers = this.filteredUsers.filter(user => {
				let isMatched = true;

				if (this.emailFilter && isMatched) {
					if (!user.username) {
						isMatched = false;
					} else {
						isMatched = user.username.toLowerCase().indexOf(this.emailFilter.toLowerCase()) > -1;
					}
				}

				if (this.firstNameFilter && isMatched) {
					if (!user.firstName) {
						isMatched = false;
					} else {
						isMatched = HelperService.getNormalizedName(user.firstName).indexOf(HelperService.getNormalizedName(this.firstNameFilter)) > -1;
					}
				}

				if (this.lastNameFilter && isMatched) {
					if (!user.lastName) {
						isMatched = false;
					} else {
						isMatched = HelperService.getNormalizedName(user.lastName).indexOf(HelperService.getNormalizedName(this.lastNameFilter)) > -1;
					}
				}

				if (this.selectedRoles.roles?.length && isMatched) {
					if (!user.role) {
						isMatched = false;
					} else {
						isMatched = this.selectedRoles.roles.find(r => r == user.role);
					}
				}

				return isMatched;
			})
		}

		if (this.sortedColumn) {
			this.filteredUsers.sort((a, b) => {
				const multiplier = this.sortAscending ? 1 : -1;
				return (a[this.sortedColumn] || '').localeCompare((b[this.sortedColumn] || '')) * multiplier;
			});
		}
	}

	autoSizeColumnWidth() {
		this.userPermissionTable.columnApi.autoSizeAllColumns();
	}

	getHeaderText(params: any) {
		let headerText = '';

		switch (params.colDef.field) {
			case 'fullName':
				headerText = 'user';
				break;
			case 'username':
				headerText = 'email';
				break;
			case 'firstName':
				headerText = 'firstName';
				break;
			case 'lastName':
				headerText = 'lastName';
				break;
			case 'roleLabel':
				headerText = 'role';
				break;
			case 'action':
				headerText = 'action';
				break
		}

		return this.translatePipe.transform(headerText);
	}
}
