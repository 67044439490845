import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { AuthService } from './../../../shared/services/auth.service';
import { RegistrationService } from './../../../shared/services/registration.service';
import { NotificationService } from './../../../shared/services/notification.service';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VerificationService } from 'app/verification/shareds/services/verification.service';
import { Router } from '@angular/router';

@Component({
	selector: 'mtm-change-phone-modal',
	templateUrl: './change-phone-modal.component.html',
	styleUrls: ['./change-phone-modal.component.scss']
})
export class ChangePhoneModalComponent implements OnInit, OnDestroy {

	changePhoneForm: UntypedFormGroup;
	submitted: boolean = false;
	phoneValue: string;
	ngUnsubscribe = new Subject();
	activeModal: any;

	constructor(private fb: UntypedFormBuilder, private authService: AuthService, private _activeModal: NgbActiveModal,
		private router: Router, private notificationService: NotificationService) {
		this.activeModal = _activeModal;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.loadFormGroup();
	}

	/**
	   * Submit login form
	   */
	onSubmit() {
		this.submitted = true;
		if (this.changePhoneForm.valid) {
			let alternatePhone = this.changePhoneForm.controls.phone.value;
			this.authService.resendVerificationCodeToAlternate(alternatePhone).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((data: any) => {
				this.activeModal.close({ alternatePhoneSent: true, alternatePhone: alternatePhone });
			}, (err: any) => {
				if (err.status === 401) {
					this.notificationService.open({
						title: 'Session Expired',
						notificationType: 'success',
						centerHeader: true,
						notificationInfo: 'Your session has expired. Please login to continue.',
						confirmBtn: 'Login'
					}).subscribe(confirm => {
						this.activeModal.close({ error: true });
						let lang = localStorage.getItem('auth_user_language');
						localStorage.clear();
						sessionStorage.clear();
						if (lang) {
							localStorage.setItem('auth_user_language', lang)
						}
						this.router.navigateByUrl('/entry').then(() => window.location.reload());
					});
				}
			});
		}

	}

	/**
	   * Load form group
	   */
	private loadFormGroup() {
		this.changePhoneForm = this.fb.group({
			phone: [this.phoneValue, [Validators.required]]
		});
	}

}
