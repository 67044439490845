import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'toTime',
    pure: false
})
export class ToTimePipe implements PipeTransform {
    transform(value: number): any {
        const minutes = Math.floor((value / 1000) / 60), seconds = (value / 1000) % 60;
        return `${minutes < 10 ? `0${minutes.toFixed()}` : minutes.toFixed()}:${seconds < 10 ? `0${seconds.toFixed()}` : seconds.toFixed()}`;
    }
}
