import { EventEmitter, Injectable, Output } from "@angular/core";
import { ThemeOptions } from "../interfaces";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	@Output() contentChange: Subject<ThemeOptions> = new Subject<ThemeOptions>();

	updateContent(update: ThemeOptions) {
		this.contentChange.next(update);
	}


}
