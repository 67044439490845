import * as _ from 'lodash';
import { Directive, HostListener, ElementRef, Input, OnChanges } from '@angular/core';
import { MtmCurrencyPipe } from "app/shared/pipes/mtm-currency.pipe";
import { TranslatePipe } from "../pipes/translate.pipe";
import { UserService } from '../services/user.service';

@Directive({
	selector: '[mtmFormatCurrency]'
})
export class MtmCurrencyDirective implements OnChanges {

	private el: any;
	private loaded: boolean = false;
	@Input('mtmFormatCurrency') originalValue: any;
	@Input() currencySymbol: any;
	ranges = ["MINIMAL", "SMALL", "MEDIUM", "NORMAL", "BIG", "HUGE", "ENTERPRISE"];

	constructor(
		private elementRef: ElementRef,
		private currencyPipe: MtmCurrencyPipe,
		private translatePipe: TranslatePipe,
		private userService: UserService
	) {
		this.el = this.elementRef.nativeElement;
	}

	ngOnInit() {
		this.el.value = this.formatValue(this.originalValue);
		this.originalValue = this.el.value;
	}



	private formatValue(value) {
		let enumValueIdx = this.getEnumValueIdx(value);
		if (enumValueIdx >= 0) {
			return this.translatePipe.transform("budgetRange" + this.ranges[enumValueIdx] + (this.currencySymbol ? '_' + this.currencySymbol : ''));
		} else {
			let numberValue = Number((value || '').toString().replace(/[^0-9\.-]+/g, ""));
			return (this.currencySymbol === 'EUR' ? '€' : '$') + (numberValue || 0);
		}
	}

	public getEnumValueIdx(value) {
		let idx = this.ranges.indexOf(value);
		if (idx < 0) {
			for (let i = 0; i < this.ranges.length; i++) {
				let currencies = this.userService.getCurrencies();
				for (let j = 0; j < currencies.length; j++) {
					if (this.translatePipe.transform("budgetRange" + this.ranges[i] + (currencies[j] ? '_' + currencies[j].id : '')) === value) {
						idx = i;
					}
				}
			}
		}
		return idx;
	}

	ngOnChanges(changes) {
		if (this.isFocus) {
			return;
		}
		if (!this.loaded && this.originalValue) {
			this.loaded = true;
		}
		this.el.value = this.formatValue(this.originalValue);
		this.originalValue = this.el.value;
	}

	isFocus: boolean = false;

	@HostListener("focus", ["$event.target.value"])
	onFocus(value) {
		if (this.originalValue) {
			let rangeIdx = this.getEnumValueIdx(this.originalValue);
			if (rangeIdx < 0) {
				let numberValue = Number((value || '').toString().replace(/[^0-9\.-]+/g, ""));
				this.el.value = numberValue;
				this.originalValue = numberValue;
			}
		}
		this.isFocus = true;
	}

	@HostListener("blur", ["$event.target.value"])
	onBlur(value) {
		if (this.originalValue) {
			this.el.value = this.formatValue(this.originalValue);
			this.originalValue = this.el.value;
		}
		this.isFocus = false;
	}

}
