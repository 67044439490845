import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { ApiService } from "./api.service";

/**
 * This class manages stripe specific payment methods
 */
@Injectable()
export class PaymentMethodService {
    constructor(private apiService: ApiService) {

    }

    public getUserPaymentMethods() {
        return this.apiService.httpGet(`/api/paymentMethod/all`);
    }

    public addPaymentMethod(paymentId: string) {
        const url = `/api/paymentMethod/save?paymentMethodId=${paymentId}`;
        return this.apiService.httpPost(url, {});
    }

    public setPaymentAsDefault(paymentId: string) {
        const url = `/api/paymentMethod/attach?paymentMethodId=${paymentId}`;
        return this.apiService.httpPost(url, {});
    }

    public removePaymentMethod(paymentId: string) {
        const url = `/api/paymentMethod/detach?paymentMethodId=${paymentId}`;
        return this.apiService.httpDelete(url);
    }

    public getDetail(paymentId: string) {
        const url = `/api/paymentMethod/getDetails/{paymentId}`;
        return this.apiService.httpGet(url);
    }

    public getDefaultPayment() {
        const url = `/api/paymentMethod/default`;
        return this.apiService.httpGet(url);
    }

    public getPublishableKey(currency: any) {
        if (currency.toLowerCase() === 'usd') {
            return environment.stripe.publishableKeyUs;
        } else {
            return environment.stripe.publishableKey;
        }
    }
}