import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { CommonService } from "app/shared/services/common.service";
import { SharedModule } from "app/sub-modules/SharedModule";
import { SubscriptionRegisterComponent } from "./layout/register.component";
import { SubscriptionRegisterConfirmPlanComponent } from "./pages/confirm-plan/confirm-plan.component";
import { SubscriptionRegisterPaymentComponent } from "./pages/payment/payment.component";
import { SubscriptionRegisterProduceComponent } from "./pages/produce/produce.component";
import { SubscriptionRegisterSelectPlanComponent } from "./pages/select-plan/select-plan.component";
import { SubscriptionRegisterRoutingModule } from "./register.route";

@NgModule({
    declarations: [
        SubscriptionRegisterComponent,
        SubscriptionRegisterConfirmPlanComponent,
        SubscriptionRegisterPaymentComponent,
        SubscriptionRegisterProduceComponent,
        SubscriptionRegisterSelectPlanComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
        SubscriptionRegisterRoutingModule
    ],
    exports: [],
    providers: [
        AuthService,
        CommonService
    ]
})
export class SubscriptionRegisterModule { }
