const setProfilePreviewItem = (element, profilePreviewImage, isImage, isAudio) => {
	element.profilePreviewImage = profilePreviewImage;
	element.isImageProfilePreviewImage = isImage;
	element.isAudio = isAudio;
	if (element.updatePreviewImage) element.updatePreviewImage();
}

let videoPlayPromise: any;

const setProfileAndImagesProb = async (elementRef, elementFiles, type = null) => {
	if (elementRef.profilePreviewImage)
		return;

	elementFiles = [...elementFiles].sort((x, y) => {
		return y.addedTime - x.addedTime
	});

	if (type === "audio") {
		setProfilePreviewItem(elementRef, "../../../assets/img/misc/audioON.svg", false, true);
	}

	let index = elementFiles.findIndex(k => k.contentType && k.contentType.indexOf('image') !== -1);
	if (index >= 0) {
		setProfilePreviewItem(elementRef, elementFiles[index].signedURL, true, false);
		return;
	}

	index = elementFiles.findIndex(k => k.contentType && k.contentType.indexOf('video') !== -1);
	if (index < 0)
		return;
}

export default setProfileAndImagesProb;
