import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionPlan } from "../../../layouts/build-plan-circle/build-plan-circle.component";
import { NotificationService } from "../../services/notification.service";

@Component({
	selector: 'mtm-subscription-confirm',
	templateUrl: './subscription-confirm.component.html',
	styleUrls: ['./subscription-confirm.component.scss']
})
export class SubscriptionConfirmComponent implements OnInit, OnDestroy {

	public additionalDiscount: number;
	public totalToBePaid: string;
	public prompt: string;
	public subtitle: string;
	public toBePaidPrompt: string;
	public subscriptionPlan: SubscriptionPlan;
	ngUnsubscribe = new Subject();
	istermsAgreed;

	constructor(public activeModal: NgbActiveModal,
		private notificationService: NotificationService,) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() { }

	setParams(data: any) {
		if (data) {
			if (data.subscriptionPlan) {
				this.subscriptionPlan = data.subscriptionPlan;
			}
			if (data.additionalDiscount > 0) {
				this.additionalDiscount = data.additionalDiscount;
				this.prompt = `Please review the details of your plan`;
				this.subtitle = `MY PLAN`;
				this.toBePaidPrompt = `Total to be paid`;
				this.totalToBePaid = this.subscriptionPlan.cost.toFixed(2);
			} else if (data.additionalDiscount == -1) {
				this.prompt = `Here are the details of the features included`;
				this.subtitle = `BUILD MY OWN PLAN`;
				this.toBePaidPrompt = `Total to be paid per month`;
				this.totalToBePaid = (this.subscriptionPlan.cost / this.subscriptionPlan.duration).toFixed(2);
			}
		}
	}

	dismissModal() {
		this.activeModal.close(true)
	}
	navigateLoginPage() {
		this.notificationService.open({
			size: 'lg',
			title: 'MTM',
			description: `It should call backend to save a company with generated UUID and set its plan type to Subscribed and payment status to NEED_PAYMENT, should navigate the login screen after clicking the subscribe button. When user login or register it should present pay subscription modal in first page navigated.
       We need to pass company id that saved before to check subscription payment status.`,
			confirmBtn: 'I UNDERSTAND'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {

			// this.router.navigate(['/build-request',type, this.selectedCustomerType]);
			// this.isAdvertiser = true;
		});
	}

}
