import { EmitterService } from './emitter.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from "./api.service";
import { AuthService } from './auth.service';
import { HelperService } from "./helper.service";
import { SignedUrlService } from 'app/shared/services/signedurl.service';

@Injectable({
	providedIn: 'root'
})
export class UploadService {

	public progressObserver: Subscription;
	public progressEvent: EventEmitter<any> = new EventEmitter();
	public progress: number = 0;

	constructor(private api: ApiService, public auth: AuthService) { }

	/**
	 * Makes a POST request and updates the progress value
	 */
	makeFileRequest(url: string, params: string[], file: File | FileList | File[], fileKeyParam?: any, bodyParams?: any): Observable<any> {
		return new Observable(observer => {

			this.progress = 0;
			let formData: FormData = new FormData(),
				xhr: XMLHttpRequest = new XMLHttpRequest();

			if (file instanceof FileList || Array.isArray(file)) {
				for (let i = 0; i < file.length; i++)
					formData.append(fileKeyParam || 'files', file[i], file[i].name);
			} else {
				formData.append(fileKeyParam || 'file', file, file.name);
			}
			if (bodyParams) {
				if (Object.keys(bodyParams).length) {
					Object.keys(bodyParams).forEach(key => {
						formData.append(key, bodyParams[key]);
					})
				}
			}

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200 || xhr.status === 201) {
						let res = JSON.parse(xhr.response);
						observer.next(res);
						observer.complete();
					} else {
						observer.error(xhr.response);
					}
				}
			};

			xhr.upload.onprogress = (event) => {
				this.progress = Math.round(event.loaded / event.total * 100);
				this.progressEvent.emit(this.progress);
				observer.next(this.progress);
			};

			xhr.upload.onerror = err => {
				observer.error(this.progress);
			}

			xhr.open('POST', url, true);
			HelperService.getHttpHeaders().forEach((values, name) => {
				if (name !== 'Content-Type')
					xhr.setRequestHeader(name, values[0]);
			});
			xhr.send(formData);
		});
	}

	/**
	 * Convert base64/URLEncoded data component to raw binary data held in a string
	 */
	public dataURItoBlob(dataURI: string, _type: string = 'image/jpeg'): Blob {
		var byteString = atob(dataURI.split(',')[1]);
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);

		for (var i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ab], { type: _type });
	}

	/**
	 * Upload image file
	 */
	public uploadImage(dataURI: string, url?: string): Observable<any> {
		let subject = new Subject<any>();
		try {
			let user;
			if (user = this.auth.getAuthUser()) {

				let type = dataURI.substring(dataURI.indexOf(':') + 1, dataURI.indexOf(';'));
				let extension = type.substr(type.indexOf('/') + 1);
				let blobImage: any = this.dataURItoBlob(dataURI, type);
				blobImage.lastModifiedDate = new Date();
				blobImage.name = HelperService.genRandomNumber(1, 1000000) + '.' + extension;
				let fileImage = <File>blobImage;

				//  /api/users/{userId}/avatar
				let endpoint = url || (this.api.baseUrl + '/api/users/' + user.username + '/avatar');
				this.progressObserver = this.makeFileRequest(endpoint, [], fileImage).subscribe((data) => {
					subject.next(data);
				}, err => {
					subject.error(null);
				});
			} else
				subject.error(null);
		} catch (error) {
			console.log('normal file upload error')
			subject.error(null);
		}

		return subject.asObservable();
	}


	/**
	 * Upload multiple files
	 *
	 * @param files
	 * @param url
	 */
	public uploadFiles(files: File | FileList | any[], url, externalUrl?, keyParam?: any, bodyParams?: any): Observable<any> {
		return this.makeFileRequest(externalUrl ? url : this.api.baseUrl + url, [], files, keyParam, bodyParams);
	}
}
