import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AuthService } from "../../../shared/services/auth.service";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { take, takeUntil } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { UserService } from "../../../shared/services/user.service";
import { ProjectService } from "app/shared/services/project.service";
import { zip } from "rxjs";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { DataTransferBetweenComponentsService } from "app/shared/services/data-transfer-between-components.service";
import { BreadcrumbService } from "ng5-breadcrumb";
import { EmitterService } from "app/shared/services/emitter.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ThemeService } from "app/shared/services/theme.service";
import { HelperService } from "app/shared/services/helper.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { ActivityLogService } from "app/shared/services/activity-log.service";
import { ActivityLogLevels } from "app/shared/interfaces";

@Component({
	selector: 'mtm-edit-workspace',
	templateUrl: './edit-workspace.component.html',
	styleUrls: ['./edit-workspace.component.scss']
})
export class EditWorkspaceComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
	workspace: any = {};
	private ngUnsubscribe = new Subject();
	workspaceId: string;
	tabList: string[] = ['workspace_edit_workspacePermissions', 'workspace_edit_projectPermissions', 'workspace_edit_usersAndTeams'];
	tabIndex: number = 0;
	companyUsers: any[] = [];
	roles: any[] = [];
	authUser: any = {};

	constructor(private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private activityLogService: ActivityLogService,
		private permissionService: PermissionService,
		private workspaceService: WorkspaceService,
		private userService: UserService,
		private projectService: ProjectService,
		private breadcrumbService: BreadcrumbService,
		private themeService: ThemeService,
		private translatePipe: TranslatePipe,
		private transferService: DataTransferBetweenComponentsService) {
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();

		if (!this.authUser) {
			this.router.navigate(['/entry']);
		}

		this.route.params.subscribe(
			params => {
				this.workspaceId = params['workspaceId'];
				this.workspace = {};
				this.companyUsers = [];
				this.roles = [];
				this.initWorkspace();
			}
		)
	}

	ngAfterViewInit(): void {
		this.themeService.updateContent({
			backgroundColor: '',
			rightPane: null,
			backgroundImage: null,
			header: this.headerContent
		});
	}

	reloadUsers() {
		const authUser = this.authService.getAuthUser();
		this.userService.getUsersByCompanyId(authUser.company.id)
			.subscribe(data => {
				this.companyUsers = data;
			});

	}

	configureBreadcrumb() {
		this.breadcrumbService.addCallbackForRoute('/workspace', () => this.translatePipe.transform('workspace'));
		this.breadcrumbService.hideRouteRegex('workspace/edit/([0-9a-z-]+)?$');

		this.breadcrumbService.addCallbackForRouteRegex(
			'workspace/edit$',
			() => {
				let workspaceName = this.workspace.name;
				return `${workspaceName}`;
			}
		)

		this.transferService.ngOnChangesBreadcrumbComponent();
	}


	initWorkspace() {
		const authUser = this.authService.getAuthUser();

		zip(
			this.workspaceService.getWorkspaceById(authUser.company.id, this.workspaceId, true),
			this.userService.getUsersByCompanyId(authUser.company.id),
			this.projectService.getSortedRoles()
		).pipe(take(1))
			.subscribe(data => {
				const workspace = data[0];
				if (workspace) {
					this.workspace = workspace;
					this.workspaceService.setActiveWorkspace(this.workspace);
					this.checkUserAccess();
					this.configureBreadcrumb();
					this.activityLogService.activityLogAccess.next({
						hasAccess: true,
						level: ActivityLogLevels.WORKSPACE,
						workspaceId: this.workspaceId,
					});
				}
				if (data[1])
					this.companyUsers = data[1];
				if (data[2])
					this.roles = data[2].map(r => {
						return { code: r.id, label: r.name };
					});
			});
	}

	//check the user here instead of in guard, as need to call service
	private checkUserAccess() {
		if (!this.workspace) {
			this.router.navigate(['/entry']);
			return;
		}

		//admin & company principal can access
		if (this.permissionService.hasAuthority(Authority.S, null)) {
			return;
		}

		//only workspace owner of that workspace can access
		const allowedRoles = ['COMPANY_PROJECT_OWNER', 'COMPANY_PRODUCTION_OWNER'];
		let canAccess = false;
		if (allowedRoles.indexOf(this.authUser.globalRole) > -1 && this.workspace.usernames) {
			if (this.workspace.usernames.indexOf(this.authUser.username) > -1)
				canAccess = true;
		}

		if (!canAccess) {
			this.router.navigate(['/entry']);
			return;
		}
	}

	ngOnDestroy() {
		this.activityLogService.activityLogAccess.next({
			hasAccess: false,
		});
		this.themeService.updateContent({
			backgroundColor: '',
			rightPane: null,
			backgroundImage: null,
			header: null
		});
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
	}

	toggleTab(index: number) {
		this.tabIndex = index;
	}


}
