import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { PaymentService } from "../../shared/services/payment.service";

@Component({
	templateUrl: 'failure.component.html',
	styleUrls: ['./failure.component.scss']
})
export class FailureComponent implements OnInit {

	constructor(private router: Router, private paymentService: PaymentService) {
	}

	ngOnInit() {
		let companyId = this.router.parseUrl(this.router.url).queryParams['companyId'];
		this.checkConnectedAccountVerification(companyId, true);
	}

	checkConnectedAccountVerification(companyId: any, sendEmail: boolean) {
		this.paymentService.checkConnectedAccountVerification(companyId, sendEmail).subscribe(verificationStatus => {
			console.log("verificationStatus: ", verificationStatus);
		})
	}
}

