import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api.service";
import { Subject } from "rxjs";
import { SignupDetail } from "../models/signup.model";

@Injectable()
export class SignupService {

	constructor(private api: ApiService) { }

	signup(data: SignupDetail) {
		return this.api.httpPost('/api/registration/subscription', data);
	}
}
