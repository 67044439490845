import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentDTO } from "../credit-card-details.model";
import { PaymentService } from "../../shared/services/payment.service";
import { OverlayService } from "../../shared/services/overlayService";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompletePaymentModalComponent } from "../complete-payment-modal/complete-payment-modal.component";
import { AuthService } from "../../shared/services/auth.service";
import { CompanyService } from "../../shared/services/company.service";
import { UUID } from "angular2-uuid";
import { ProjectService } from "../../shared/services/project.service";
import { CreditCardDetailsModalComponent } from "../credit-card-details/credit-card-details-modal.component";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	templateUrl: './payment-details-modal.component.html',
	styleUrls: ['./payment-details-modal.component.scss']
})
export class PaymentDetailsModalComponent implements OnInit, OnDestroy {

	projectCount: number = 0;
	projectType: string;
	amount: string;
	name: string;
	paymentDTO = new PaymentDTO();
	checked: boolean = false;
	paymentId: string;
	payerID: string;
	user: object;
	company: any;
	projectId: UUID;
	paymentType: any;
	project: any;
	ngUnsubscribe = new Subject();
	modalCreditCardDetailsModalComponent: any;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private paymentService: PaymentService,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private authService: AuthService,
		private projectService: ProjectService,
		private companyService: CompanyService,
		public activeModal: NgbActiveModal) {
	}

	ngOnInit() {

		this.companyService.checkPaidOneShotCount(this.authService.getAuthUser().company.id).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(paidCount => {
			this.projectCount = paidCount;

			if (this.projectCount == 0)
				this.amount = '399';
			// else if (this.projectCount == 1)
			// 	this.amount = '199';
			else
				this.amount = '0';
		});

		this.name = this.authService.getAuthUser().firstName;
	}

	ngOnDestroy() {
		if (this.modalCreditCardDetailsModalComponent)
			this.modalCreditCardDetailsModalComponent.close();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	navigatePayment() {
		this.modalCreditCardDetailsModalComponent = this.modalService.open(CreditCardDetailsModalComponent, {
			size: 'lg',
			windowClass: 'company-matched-blur-color',
			backdrop: false
		});
		this.modalCreditCardDetailsModalComponent.componentInstance.projectId = this.projectId;
		this.modalCreditCardDetailsModalComponent.componentInstance.projectType = this.projectType;
		this.modalCreditCardDetailsModalComponent.componentInstance.paymentType = this.paymentType;
		this.modalCreditCardDetailsModalComponent.componentInstance.onPaymentSuccessfully = () => {
			this.activeModal.close(true);
		}
	}

	async continueProject() {
		try {
			await this.paymentService.changePaymentStatus(this.projectId, true).toPromise();
			this.updateLocalStorage();
			await this.projectService.navigateCompanyToProfileSuggestionAfterProjectInit(this.projectId.toString(), this.router).toPromise();
			this.activeModal.close(true);
		} catch (error) { }
	}

	/**
	 * this method is implemented for PaymentGuard.
	 */

	updateLocalStorage() {
		this.projectService.getProject(this.projectId.toString()).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(project => {
			project.entranceFeePaid = true;
			this.projectService.saveProjectsInCache(project);
		});
	}

	onCancelModal() {
		this.activeModal.close(false);
		this.router.navigate(['projects']);
	}

}
