import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { Authority, PermissionService } from "../services/permissions.service";
import { ProjectService } from "../services/project.service";

@Directive({
	selector: '[hasUserAccess]'
})
export class HasUserAccessDirective {
	@Input() authority: Authority = null;
	@Input() projectId: string;
	@Input() phase: string;
	@Input() section: string;
	@Input() subSection: string;

	constructor(public el: ElementRef, public renderer: Renderer2,
		public route: ActivatedRoute, private authService: AuthService,
		public permissionService: PermissionService,
		private projectService: ProjectService) { }

	ngOnInit() {
		if (!this.hasAuthority()) {
			this.hideElement();
		}
	}

	private hasAuthority(): boolean {
		let projectId = this.projectId ? this.projectId : this.permissionService.isGlobalAuthority(this.authority) ? "" : this.projectService.project.id;
		
		if(this.authority) {

			if(this.permissionService.isGlobalAuthority(this.authority)) {
				return this.permissionService.hasAuthority(this.authority, null);
			}

			return this.permissionService.hasAuthority(this.authority, projectId, this.phase, this.section, this.subSection);
		}

		return false;
	}

	/**
	 * Display element
	 */
	private hideElement() {
		if (this.el.nativeElement.parentElement) {
			this.el.nativeElement.parentElement.removeChild(this.el.nativeElement)
		}
		this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
	}


}
