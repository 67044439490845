import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Router } from "@angular/router";

@Component({
  selector: 'project-name-cell',
  templateUrl: './project-name-cell.component.html'
})
export class ProjectNameCellComponent implements ICellRendererAngularComp {
  project: any;

  constructor(private router: Router) {
  }

  agInit(params: ICellRendererParams): void {
    this.project = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.project = params.data;
    return true;
  }

  redirectToProject() {
    this.router.navigate(['/projects', this.project.id, 'dashboard'])
  }
}
