<div class="d-block">
	<div class="d-block" [style.position]="'relative'">
		<div class="d-flex align-items-center justify-content-center">
			<loading-data #loadingRef></loading-data>
		</div>
		<div class="row">
			<div class="col-6 company-card" *ngFor="let selectedData of data; let i=index">
				<div class="row mt-5">
					<div class="col-2">
						<div *ngIf="selectedData.avatarUrl" [style.background-image]="'url('+selectedData.avatarUrl+')'"
							class="company-logo rounded "></div>
						<div *ngIf="!selectedData.avatarUrl" [style.background-image]="'url('+noneImg+')'"
							class="company-logo rounded "></div>
					</div>
					<div class="col-10 d-flex align-items-center">
						<div class="d-flex flex-fill flex-column">
							<div class="company-name mb-1">
								<h5 class="font-weight-bold m-0">{{selectedData.clientCompanyName}}</h5>
							</div>
							<div class="company-sector-sub-sector-name">
								<span class="m-0">{{getClientSectorSubSectorName(selectedData)}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-8">
					<div class="col">
						<p class="card-text card-t" style="padding-bottom:30px">
							{{selectedData.clientCompanyName}} invites you to pitch for the production of its new video
							and
							would be very happy to have you onboard.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<p class="card-b" style="margin-bottom: 3px">SCRIPT</p>
						<p class="card-ts">{{selectedData.scriptValue}}</p>
						<p class="card-b" style="margin-bottom: 3px">PURPOSE</p>
						<p class="card-ts">{{selectedData.purpose}}</p>
						<p class="card-b" style="margin-bottom: 3px">FORMAT</p>
						<p class="card-ts">{{selectedData.format}}</p>
						<p class="card-b" style="margin-bottom: 3px">TECHNIQUE</p>
						<p class="card-ts">{{selectedData.technology}}</p>
						<p class="card-b" style="margin-bottom: 3px">STYLE</p>
						<p class="card-ts">{{selectedData.genre}}</p>
						<p class="card-b" style="margin-bottom: 3px">CHANNELS</p>
						<p class="card-ts">{{selectedData.exportChannels || 'N/A'}}</p>
					</div>
					<div class="col-6">
						<p class="card-b" style="margin-bottom: 3px">GLOBAL/LOCAL</p>
						<p class="card-ts">{{selectedData.location}}</p>
						<p class="card-b" style="margin-bottom: 3px">PREFERRED START DATE</p>
						<p class="card-ts">{{selectedData.startDate | mtmDate}}</p>
						<p class="card-b" style="margin-bottom: 3px">PREFERRED DUE DATE</p>
						<p class="card-ts">{{selectedData.displayDueDate | mtmDate}}</p>
						<p class="card-b" style="margin-bottom: 3px">BUDGET</p>
						<p class="card-ts">
							{{
							selectedData.budgetRange == "SPECIFIC"
							? (selectedData.budget + " " + (selectedData.currency === 'EUR' ? 'EUR' : 'USD'))
							: ("budgetRange" + selectedData.budgetRange + '_' + (selectedData.currency === 'EUR' ? 'EUR'
							: 'USD')) | translate
							}}</p>
						<p class="card-b " id="gstar" style="margin-bottom: 3px">GLOBAL RATING</p>
						<ng-template #t let-fill="fill">
							<span class="star" [class.full]="fill === 100">
								<span class="half" [style.width.%]="fill"><em
										class="fa fa-star text-warning"></em></span><em
									class="fa fa-star-o text-warning cursor"></em>
							</span>
						</ng-template>
						<ngb-rating [max]="5" [starTemplate]="t" [(rate)]="selectedData.globalRating" [readonly]="true">
						</ngb-rating>
						<p class="card-b companyNameLink" style="color: #e6431e; font-size:0.60rem"
							(click)="viewProfileModal(selectedData.projectId, selectedData.clientCompanyId, selectedData)">
							SEE CRITERIAS</p>
						<p class="card-b" style="margin-bottom: 3px">PROJECT DESCRIPTION</p>
						<p class="card-ts">
							<mtm-project-description [value]="selectedData.description"></mtm-project-description>
						</p>
					</div>
				</div>
				<div class="row my-3" *ngIf="viewType=='IN_PROGRESS'">
					<div class="col">
						<button class="btn btn-decline btn-md btn-block inv"
							(click)="declineInvitation(selectedData,i)">
							<span>
								<i class="fa fa-times fa-lg text-danger mr-10" aria-hidden="true"></i>Decline
								Invite</span>
						</button>
					</div>
					<div class="col">
						<button class="btn btn-accept btn-md btn-block inv" (click)="proposal(selectedData)">
							<span>
								<i class="fa fa-check fa-lg text-success mr-10" aria-hidden="true"></i>Accept
								Invite</span>
						</button>
					</div>
				</div>
				<div class="row my-3" *ngIf="viewType!='IN_PROGRESS'">
					<div class="col-12 text-center">
						<button class="btn btn-decline btn-md btn-block inv" (click)="openProposal(selectedData)">
							<span>
								<i class="fa fa-gears fa-lg text-danger mr-10" aria-hidden="true"></i>
								Open Bidding Process
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>