import { Injector } from '@angular/core';

import { MtmWebSocketService, WS_SUBSCRIBE_TOPIC_TYPES, wsListenerPROPOSAL_CONVERSATIONS } from "../../../shared/services/mtm-websocket.service";
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { AuthService } from 'app/shared/services/auth.service';

export default class ProposalConversationBase {
	//protected webSocketService: MtmWebSocketService;
	private wsListenerSubscribeCallbaclFunc;
	wsId: string;

	constructor(injector: Injector, private stompService: MtmStompService, private authService: AuthService) {
		this.wsId = this.stompService.subscribeToService();
		//this.webSocketService = injector.get(MtmWebSocketService);
		// if (this.authUser) {
		// 	this.stompService.subscribeToListener('PROPOSAL_CONVERSATION', this.wsId, [this.authUser.username],
		// 		(data: any) => {
		// 			if (this.wsListenerSubscribeCallbaclFunc)
		// 				this.wsListenerSubscribeCallbaclFunc(data);
		// 		}, wsListenerPROPOSAL_CONVERSATIONS);
		// }
	}

	setFncReceiveMessageFromWS(wsListenerSubscribeCallbaclFunc) {
		this.wsListenerSubscribeCallbaclFunc = wsListenerSubscribeCallbaclFunc;
	}

	subscribeWS_ProposalConversation(proposalId) {
		this.stompService.subscribeToListener('PROPOSAL_CONVERSATION', this.wsId, [proposalId],
			(data: any) => {
				if (this.wsListenerSubscribeCallbaclFunc)
					this.wsListenerSubscribeCallbaclFunc(data);
			}, wsListenerPROPOSAL_CONVERSATIONS);
		// this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_CONVERSATIONS, [proposalId]);
		// setTimeout(() => {
		// 	this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_CONVERSATIONS, [proposalId]);
		// }, 350);
	}

	unsubscribes() {
		//this.webSocketService.unsubscribeWSTopicSubscribeWithSUBSCRIBE_TOPIC_TYPE(WS_SUBSCRIBE_TOPIC_TYPES.PROPOSAL_CONVERSATIONS);
		this.stompService.unsubscribeToService(this.wsId);
	}
}
