import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'company-roles-and-permission',
	templateUrl: './company-roles-and-permissions.component.html',
	styleUrls: ['./company-roles-and-permissions.component.scss']
})
export class CompanyRolesAndPermissionComponent implements OnInit {

	constructor(private activeModal: NgbActiveModal) { }

	ngOnInit() { }

	close() {
		this.activeModal.close();
	}

}
