import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class CreatePartnerSelectionService {

	private prefix: string = '/api/commons/one-shot/creative-partner-selection/';

	constructor(private api: ApiService) { }

	/**
	 * Retrieve Content Options
	 */
	public getContentOptions(): Observable<any> {
		return this.api.httpGet(this.prefix + 'content/all');
	}

	/**
	 * Retrieve Content Options
	 */
	public getProfileOptions(): Observable<any> {
		return this.api.httpGet(this.prefix + 'creativeprofile/all');
	}

	/**
	 * Retrieve options by name
	 *
	 * @param name
	 */
	public getOptionsByName(name: string, page?: string): Observable<any> {
		if (!page)
			page = 'content';

		return this.api.httpGet(this.prefix + page + '/' + name);
	}
}
