<div class="container proposal-step-container">
	<div class="card">
		<div class="card-header font-weight-bold">
			4.a. Please enter the details for your payment (To be discussed with the brand if necessary)
		</div>
		<form *ngIf="formGroup" [formGroup]="formGroup">
			<div>
				<div class="row proposalDetails">
					<div class="col">
						<label class="form-group row has-float-label" [ngClass]="{'has-danger': (formGroup.controls['totalBudget'].hasError('reguired') ||
																						formGroup.controls['totalBudget'].hasError('min') ||
																						formGroup.controls['totalBudget'].hasError('pattern')) &&
																						formGroup.controls['totalBudget'].touched,
																						'div-disabled': proposalAccepted}">
							<input class="form-control" (focus)="onAmountFocused($event)" (blur)="onAmountBlur($event)"
								type="{{totalBudgetFocused ? 'number'  : 'text'}}" formControlName="totalBudget"
								placeholder=" " [ngClass]="{'form-control-danger': (formGroup.controls['totalBudget'].hasError('reguired') ||
																											formGroup.controls['totalBudget'].hasError('min') ||
																										formGroup.controls['totalBudget'].hasError('pattern')) &&
																										formGroup.controls['totalBudget'].touched,
																										'div-disabled': proposalAccepted}" />
							<span>{{'proposalTotalBudget' | translate}}</span>
						</label>
					</div>
					<div class="col">
						<label class="form-group row has-float-label" [ngClass]="{'has-danger': (formGroup.controls['downpaymentRate'].hasError('reguired') ||
																							formGroup.controls['downpaymentRate'].hasError('min')) &&
																							formGroup.controls['downpaymentRate'].touched,
																							'div-disabled': proposalAccepted}">
							<input class="form-control" type="number" formControlName="downpaymentRate" placeholder=" "
								[ngClass]="{'form-control-danger': (formGroup.controls['downpaymentRate'].hasError('reguired') ||
																										formGroup.controls['downpaymentRate'].hasError('min')) &&
																										formGroup.controls['downpaymentRate'].touched,
																										'div-disabled': proposalAccepted}" />
							<span [ngClass]="{'text-danger': (formGroup.controls['downpaymentRate'].hasError('min'))}">
								Downpayment Rate &nbsp;(<strong>%</strong>) {{
								(formGroup.controls['downpaymentRate'].hasError('min')) ? ' must be minimum 10' : ''
								}}</span>
							<br>
						</label>
						<span class="text-danger"></span>
					</div>
					<div class="col">
						<label class="form-group row has-float-label"
							[ngClass]="{'has-danger': formGroup.controls['downpaymentRate'].hasError('reguired'),'div-disabled': proposalAccepted}">
							<select class="dropdown-toggle" formControlName="currency" id="currencyId">
								<option value="{{ currency.id }}" *ngFor="let currency of currencies">{{currency.label}}
								</option>
							</select>
							<span>Select Currency</span>
						</label>
					</div>
				</div>
				<div class="row proposalDetails">
					<div class="col">
						<div class="input-group" [ngClass]="{'div-disabled': proposalAccepted}">
							<span class="has-float-label-datepicker">Downpayment Deadline<b class="star">*</b></span>
							<input [ngClass]="{'has-danger': (formGroup.controls['downpaymentDeadline'].hasError('reguired') ||
																										formGroup.controls['downpaymentDeadline'].hasError('min')) &&
																										formGroup.controls['downpaymentDeadline'].touched}" class="form-control date-picker"
								formControlName="downpaymentDeadline" ngbDatepicker
								[maxDate]="formGroup.controls['endDate'].value" #start1="ngbDatepicker"
								placeholder="Select Date" firstDayOfWeek="1" (click)="start1.toggle()">
							<div class="input-group-addon" (click)="start1.toggle()" [mtmDatePickerDirective]=start1>
								<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="input-group" [ngClass]="{'div-disabled': proposalAccepted}">
							<span class="has-float-label-datepicker">Due Date</span>
							<input [ngClass]="{'has-danger': (formGroup.controls['downpaymentDeadline'].hasError('reguired') ||
																										formGroup.controls['downpaymentDeadline'].hasError('min')) &&
																										formGroup.controls['downpaymentDeadline'].touched}" class="form-control date-picker"
								formControlName="endDate" ngbDatepicker #start2="ngbDatepicker"
								[minDate]="formGroup.controls['downpaymentDeadline'].value"
								[ngModelOptions]="{standalone: true}" (dateSelect)="onChangedDueDate($event)"
								placeholder="Select Date" firstDayOfWeek="1" (click)="start2.toggle()">
							<div class="input-group-addon" (click)="start2.toggle()" [mtmDatePickerDirective]=start2>
								<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="input-group" [ngClass]="{'div-disabled': proposalAccepted}">
							<span class="has-float-label-datepicker">Final Payment Deadline (24 hours before due
								date)</span>
							<input class="form-control date-picker" [(ngModel)]="finalPaymentDeadline" ngbDatepicker
								placeholder="{{ finalPaymentDeadline | mtmDate}}" [ngModelOptions]="{standalone: true}"
								disabled>
						</div>
					</div>
				</div>
				<div class="row proposalDetails">
					<label class="col checkbox-container">
						<input type="checkbox" [(ngModel)]="firstCondition" [ngModelOptions]="{standalone: true}"
							[attr.disabled]="contractApprovedByProducer ? true : null" />
						I acknowledge that depending on the client’s bank, it might take between 5 to 14 business days
						to receive the payment through SEPA direct debit.
						Hence, while entering the downpayment deadline, I have taken into account that the downpayment
						will be released to my bank account 5 days after the money has been received from the client.
						<span class="checkmark"></span>
					</label>
				</div>
				<div class="row proposalDetails">
					<label class="col checkbox-container">
						<input type="checkbox" [(ngModel)]="secondCondition" [ngModelOptions]="{standalone: true}"
							[attr.disabled]="contractApprovedByProducer ? true : null" />
						I understand and accept to pay MTM fee(5% of the agreed project budget) simultaneously with the
						downpayment.
						As a result I will include the 5% minimum downpayment of the full project budget in order to
						have the finder’s-fee processed from the downpayment.
						<span class="checkmark"></span>
					</label>
				</div>
				<div class="row proposalDetails">
					<label class="col checkbox-container">
						<input type="checkbox" [(ngModel)]="thirdCondition" [ngModelOptions]="{standalone: true}"
							[attr.disabled]="contractApprovedByProducer ? true : null" />
						I know and I accept that I will receive the final payment 30 days after the brand has paid and
						only if there are no disputes.
						<span class="checkmark"></span>
					</label>
				</div>
				<div class="row proposalDetails">
					<label class="col checkbox-container">
						<input #isTermsValid type="checkbox" [(ngModel)]="contractApprovedByProducer"
							[attr.disabled]="checkedAllOtherTerms() ? null : true" [ngModelOptions]="{standalone: true}"
							[checked]="proposalAccepted || contractApprovedByProducer" />
						I agree to the <a href="#" (click)="onRedirectToTCP('CONTRATPRODUCTION');false;"
							style="color:#e4442a"> Terms &
							conditions</a> of the contract with “{{clientCompanyName}}“
						<span class="checkmark"></span>
					</label>
					<div class="col-3 saveChanges">
						<button type="button" [disabled]="!isTermsValid.checked || proposalAccepted"
							(click)="saveProposal()" class="btn btn-lg btn-orange">
							Save Proposal
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
	<br>
	<br>
	<div class="card">
		<div class="card-header font-weight-bold">
			4.b. Upload Proposals
		</div>
		<div class="card p-3 mt-3">
			<div class="card-block">
				<div class="row align-items-center justify-content-between team-files-topbar px-3"
					[ngClass]="{'div-disabled': proposalAccepted && this.proposal && this.proposal.status == 'DECLINED'}">
					<div class="d-flex align-items-center">
						<i class="fa fa-search text-muted" aria-hidden="true"></i>
						<label class="form-group w-100 has-float-label m-0">
							<input class="form-control" type="text" [(ngModel)]="queryString" id="search"
								placeholder=" ">
							<span>Search files...</span>
						</label>
					</div>
					<div class="nav">
						<mtm-upload-file hidden (files)="getFilesFromUploader($event)" #mtmUploadFile>
						</mtm-upload-file>
						<a href="#" class="nav-link text-danger" (click)="isProposalSaved(mtmUploadFile);false"
							style="font-size: small">
							<i class="fa fa-cloud-upload fa-fw" aria-hidden="true"></i>
							<span style="font-weight:600"> Upload Files</span>
						</a>
						<a href="#" class="nav-link" [class.view-selected]="view !== views['list']"
							(click)="changeView('list');false"><img type="image/svg+xml" height="16" width="16"
								src="/assets/img/icons/list.svg" class="list-icon"></a>
						<a href="#" class="nav-link" [class.view-selected]="view !== views['grid']"
							(click)="changeView('grid');false"><img type="image/svg+xml" height="16" width="16"
								src="/assets/img/icons/grid.svg" class="grid-icon"></a>
					</div>
				</div>
			</div>
			<mtm-team-files-grid *ngIf="view === views['grid']" [projectId]="invitationProjectId" [files]="files"
				[queryString]="queryString"></mtm-team-files-grid>
			<mtm-team-files-list *ngIf="view === views['list']" [projectId]="invitationProjectId" [files]="files"
				[isDisableDownload]="proposalAccepted" [queryString]="queryString"></mtm-team-files-list>
			<mtm-upload-file *ngIf="view === views['upload']" (files)="getFilesFromUploader($event)"></mtm-upload-file>
		</div>
	</div>
</div>