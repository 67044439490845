import { Component, OnInit, OnDestroy } from '@angular/core';
import { folderAdded, folderCreated } from 'app/layouts/ai-reframing/cores/ai-framing.events';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mtm-ai-framing-folder-builder-container',
    template: '<div class="folder-builder-container"><mtm-ai-framing-folder-builder [guid]="info.guid" [folder]="info.data" *ngFor="let info of folderData;trackBy: trackByFn"></mtm-ai-framing-folder-builder></div>',
    styles: [`.folder-builder-container{display:none;}`]
})
export class AiFramingFolderBuilderComponent implements OnInit, OnDestroy {
    folderData: any = [];
    ngUnsubscribe = new Subject();

    constructor() {
        folderAdded.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
            this.folderData.push({
                guid: this.guid(),
                data
            });
        });

        folderCreated.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(data => {
            this.folderData = this.folderData.filter(s => s.guid !== data.guid);
        })
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void { }

    guid() {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    trackByFn(index, item) {
        return item.id;
    }

}