<div class="row pl-5 bg-primary my-projects-header">
	<div class="media my-5">
		<mtm-user-profile-picture [forceLoadProfile]="true" [user]="user" [username]="user.username" [height]="50"
			[width]="50">
		</mtm-user-profile-picture>
		<div class="media-body align-self-center ml-3 text-white">
			<h4 class="media-heading">{{'dashboardHi' | translate}} {{ user.firstName }}</h4>
			<p>{{'dashboardWelcome' | translate}}</p>
		</div>
	</div>
</div>
<div class="row justify-content-between projects-toolbar py-2">
	<div class="row w-100">
		<div class="col-sm-4 text-left">
		</div>

		<div class="col-sm-5 text-center">
		</div>

		<div class="col-sm-3 text-right">
			<div class="btn-group" role="group">
				<div ngbDropdown class="d-inline-block">
					<button class="btn" ngbDropdownToggle>
						<i class="fa fa-flag fa-fw text-primary" aria-hidden="true"></i>
						<span>Rate Production Companies </span>
						<i class="fa fa-angle-down fa-fw" aria-hidden="true"></i>
					</button>
					<div class="dropdown-menu">
						<button class="dropdown-item" (click)="inProgress()">{{'ongoingProjects' | translate}}</button>
						<button hasUserAccess *ngIf="this.user.company.companyType == 'PRODUCER'"
							[authority]="authority.I" class="dropdown-item" (click)="projectInvites()">Project
							Invitations</button>
						<button hasUserAccess *ngIf="this.user.company.companyType == 'PRODUCER'"
							[authority]="authority.B" class="dropdown-item" (click)="projectProposals()">Project
							Proposals</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="card">
	<div class="card-header">
		<div class="row">
			<div class="col">
				<h5 class="my-2" *ngIf="prodCompanies.length > 0">Please rate {{prodCompanyName}} on following criteria:
				</h5>
				<h5 class="my-2" *ngIf="prodCompanies.length == 0 ">There is no company to rate !</h5>
			</div>
		</div>
	</div>
	<div class="card-block p-0" *ngIf="prodCompanies.length > 0">
		<div class="row pr-15">
			<div class="col-auto proposals-list">
				<ul class="list-group">
					<li (click)="selectProposal(i)" class="list-group-item" [class.active]="i === indexSelected"
						*ngFor="let prodCompany of prodCompanies; let i = index">
						<div class="rounded-circle company-avatar"
							[style.background-image]="'url(' + prodCompany.avatarUrl + ')'"></div>
					</li>
				</ul>
			</div>
			<div class="col comments-col">
				<div class="comment-container">
					<div class="comments">
						<div class="col mt-30" [formGroup]="prodCompRateForm" (ngSubmit)="onSubmit()">
							<div class="row">
								<div class="col-2">
									<p class="rateText">On Brief</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star w-100 " [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="brief" [readonly]="false">
									</ngb-rating>
								</div>
								<div class="col">
									<div class="form-group">
										<input class="form-control" type="text" [placeholder]="onBriefPlaceholder"
											formControlName="onBriefFeedBack">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2">
									<p class="rateText">On Budget</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star w-100 " [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="budget" [readonly]="false">
									</ngb-rating>
								</div>
								<div class="col">
									<div class="form-group">
										<input class="form-control" type="text" [placeholder]="onBudgetPlaceholder"
											formControlName="onBudgetFeedBack">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2">
									<p class="rateText">Out Of The Box</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star w-100 " [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="outOfBox" [readonly]="false">
									</ngb-rating>
								</div>
								<div class="col">
									<div class="form-group">
										<input class="form-control" type="text" [placeholder]="outOfTheBoxPlaceholder"
											formControlName="outOfTheBoxFeedBack">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2">
									<p class="rateText">Good Listener</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star w-100 " [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="goodListener" [readonly]="false">
									</ngb-rating>
								</div>
								<div class="col">
									<div class="form-group">
										<input class="form-control" type="text" [placeholder]="goodListenerPlaceholder"
											formControlName="goodListenerFeedBack">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2">
									<p class="rateText">On Time</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star w-100 " [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="time" [readonly]="false">
									</ngb-rating>
								</div>
								<div class="col">
									<div class="form-group">
										<input class="form-control" type="text" [placeholder]="onTimePlaceholder"
											formControlName="onTimeFeedBack">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2" id="gstar">
									<p class="rateText">Global Rating</p>
								</div>
								<div class="col-md-2" [style.min-width]="'170px'">
									<ng-template #t let-fill="fill">
										<span class="star" [style.display]="'block'" [class.full]="fill === 100">
											<span class="half" [style.width.%]="fill"><em
													class="fa fa-star text-warning"></em></span><em
												class="fa fa-star-o text-warning cursor"></em>
										</span>
									</ng-template>
									<ngb-rating [max]="5" [starTemplate]="t" (ngModelChange)="modelChanged()"
										[(rate)]="global" [readonly]="true">
									</ngb-rating>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<p class="review">Reviews</p>
									<div class="form-group">
										<textarea class="form-control " [placeholder]="reviewPlaceholder"
											formControlName="review" rows="5"></textarea>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-2">
								</div>
								<div class="col-md-2">
								</div>
							</div>
						</div>
						<label class="container">
							<input type="checkbox" checked="checked" [(ngModel)]="checkValue">
							<span class="checkmark">{{agreementText}}</span>
						</label>
						<div class="col-md-3 text-right">
							<button class="btn btn-lg btn-danger w-100" [style.margin-top]="'45px'" type="button"
								[disabled]="sending || !checkValue " (click)="onSubmit()"
								[ngClass]="{'submitted' : isSubmitted }">
								<span>Submit</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>