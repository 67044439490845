import { BillingInfo, InvoiceDetail } from "../../register/models/subscription-register.model";

export class SubscriptionUpgrade {
	plan: any;
	previousPlan: any;
	plansInfo: any;
	billingType: string;
	previousBillingType: string;
	billingInfo: BillingInfo;
	invoice: InvoiceDetail
	isDowngrade: boolean;
	planConfirmed: boolean;
	paymentDone: boolean;
	accountStatus: string;

	constructor() {
		this.billingInfo = new BillingInfo();
		this.invoice = new InvoiceDetail();
		this.isDowngrade = false;
	}
}

