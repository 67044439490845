import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { onLoadProject, ProjectService } from "./project.service";
import { BatchEditParams, EditUpdateDto, EditUpdateParams } from "../interfaces/edit.interface";
import { GetVersionFilesParams } from "../interfaces";
import { takeUntil } from 'rxjs/operators';
import { SubsectionService } from './subsection.service';

@Injectable({
	providedIn: 'root'
})
export class EditingService {
	ngUnsubscribe = new Subject();
	files: any = {};
	private changedVersion = new BehaviorSubject({ changedVersionFiles: {}, versionelementId: null, versionChanged: false });
	//private changedVersion = new Subject();
	getSelectedVersion = this.changedVersion.asObservable();

	selectedVersion(versionFiles: any, keys: any, elementId: number, isVersionChanged: boolean) {
		this.subsectionService.getVersionDetailById(keys.project, keys.section, keys.subsection, keys.optionid, versionFiles.versionNumber)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				this.files = { ...result.files };
				if (result) {
					if (this.files) {
						this.changedVersion.next({ changedVersionFiles: this.files, versionelementId: elementId, versionChanged: isVersionChanged })
					}
				}
			})
	}

	projectId: string;
	sectionId: string;
	subsectionId: string;
	public prefix_url: string;

	public selectedTypeOfEdit: any;

	private approvedEditingList: any[] = [];
	private editingList: any[] = [];

	public typeOfEdits: any[] = [
		{ label: 'Full Edit', value: 'FULL_EDIT' },
		{ label: '1st Part', value: '1ST_PART' },
		{ label: '2nd Part', value: '2ND_PART' },
		{ label: '3rd Part', value: '3RD_PART' },
		{ label: '4th Part', value: '4TH_PART' },
		{ label: 'Extended Version', value: 'EXTENDED_VERSION' },
		{ label: 'Short Version', value: 'SHORT_VERSION' }
	];

	private tempFiles: any[] = null;

	private fileAddOrEdit = new BehaviorSubject(false);
	fileAddedOrEdited = this.fileAddOrEdit.asObservable();

	constructor(private api: ApiService, private auth: AuthService, private serviceProject: ProjectService, private subsectionService: SubsectionService) { }

	editOrAddFile(message: boolean) {
		this.fileAddOrEdit.next(message)
	}

	public setApprovedEditingList(approvedEditingList: any[]) {
		this.approvedEditingList = approvedEditingList;
	}
	public getApprovedEditingList() {
		return this.approvedEditingList;
	}
	public setEditingList(editingList: any[]) {
		this.editingList = editingList;
	}
	public getEditingList() {
		return this.editingList;
	}

	public getFullEditings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/editings`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	/**
	 * @deprecated The method should not be used
	 */
	public getEditings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	public renameEditing(projectId: string, sectionId: string, subsectionId: string, editingId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings/${editingId}?newName=${newName}`, {});
	}

	/**
	 * @deprecated The method should not be used
	 */
	public getApprovedEditType(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/approved/editings`);
	}

	/**
	 * @deprecated The method should not be used
	 */
	public saveEditing(projectId: string, sectionId: string, subsectionId: string, editing: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/editings`, editing);
	}


	/**
	 * @deprecated The method should not be used
	 */
	public deleteEditing(projectId: string, sectionId: string, subsectionId: string, editingId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/editings/${editingId}`);
	}


	public getEditingsForTypeOfEdit(projectId: string, sectionId: string, subsectionId: string, typeOfEdit: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/editings/typeofEdit/${typeOfEdit}`);
	}

	/**
	 * @deprecated The method should not be used
	 */
	public getEditing(projectId: string, sectionId: string, subsectionId: string, editingId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/editings/${editingId}`);
	}

	public getPostProdApprovedEditings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/approved-editings`)
	}

	public getEditingsWithoutSubSectionId(projectId: string, sectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/editings`);
	}

	public getSubsectionIdOfEditing(projectId): Observable<any> {
		return new Observable(observe => {

			let findSubSectionId = () => {
				try {
					let sectionIndex = this.serviceProject.sections.findIndex(k => k.section == "POST_PRODUCTION");
					if (sectionIndex < 0) {
						observe.error();
						return;
					}

					let index = this.serviceProject.sections[sectionIndex].projectSubSections.findIndex(k => k.subSection == "EDITING");
					if (index < 0) {
						observe.error();
						return;
					}

					observe.next(this.serviceProject.sections[sectionIndex].projectSubSections[index]);
					observe.complete();
				} catch (err) {
					console.log(err);
					observe.error();
				}
			}

			try {
				if (this.serviceProject.projectOnloaded)
					findSubSectionId();
				else {
					onLoadProject.subscribe(() => {
						findSubSectionId();
					});
				}
			} catch (error) {
				observe.error();
			}
		});
	}

	public sortListByVersioning(array: any[]) {
		// sort by versioning
		array.sort((a, b) => { return a.versioning - b.versioning });
		//search for any approved if there is put them on the top of the list
		let backup = array.filter(k => k.approved == true);
		if (backup) {
			backup.forEach(b => {
				array.splice(array.indexOf(b), 1);
				array.unshift(b);
			});
		}
		//sort approveds by versiyoning
		array.sort((a, b) => {
			if (a.approved && b.approved)
				return a.versioning - b.versioning;
		});
		return array;
	}

	public storeTempFiles(data: any[]) {
		this.tempFiles = data;
	}

	public retrieveTempFiles(): any[] {
		const files = this.tempFiles;
		this.tempFiles = [];
		return files;
	}

	public getVersionedEdits(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings`;
		return this.api.httpGet(url);
	}

	public getVersionedEdit(projectId: string, sectionId: string, subsectionId: string, editingId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings/${editingId}`;
		return this.api.httpGet(url);
	}

	public getSimpleEdit(projectId: string, sectionId: string, subsectionId: string, editingId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings-simple/${editingId}`;
		return this.api.httpGet(url);
	}

	public deleteVersionedEdit(projectId: string, sectionId: string, subsectionId: string, editingId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings/${editingId}`;
		return this.api.httpDelete(url);
	}


	public saveVersionedEdit(args: EditUpdateParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-editings`;
		const urlParams = [];
		if (args.topic) {
			urlParams.push('topic=' + encodeURIComponent(args.topic));
		}
		if (args.topicId) {
			urlParams.push('topicId=' + args.topicId);
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
		return this.api.httpPost(url, args.edit);
	}


	public batchSaveVersionedEdits(args: BatchEditParams): Observable<any> {
		///TODO: remove topic & topic id requirements from API
		let topic = encodeURIComponent(args.topic);
		let topicId = encodeURIComponent(args.topicId);
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/` +
			`v-editings-batch?topic=${topic}&topicId=${topicId}`;
		return this.api.httpPost(url, args.items);
	}

	public deleteEditFile(projectId: string, sectionId: string, subsectionId: string, editId: string, fileId: string): Observable<any> {
		const deleteUrl = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/` +
			`${editId}/files/${fileId}`;
		return this.api.httpDelete(deleteUrl);
	}

	public deleteBatchFiles(projectId: string, sectionId: string, subsectionId: string, editId: string, fileIds: string[] = []): Observable<any> {
		let deleteUrl = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-editings-batch` +
			`?id=${editId}`;
		fileIds.forEach(id => {
			deleteUrl += `&ids=${id}`;
		});
		return this.api.httpDelete(deleteUrl);
	}

	public reorderEditFiles(projectId: string, sectionId: string, subsectionId: string, editId: string, fileIds: string[]): Observable<any> {
		const reorderUrl = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/` +
			`${editId}/files`;
		return this.api.httpPut(reorderUrl, fileIds);
	}
}

