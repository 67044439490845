<ng-template #headerContent>
    <div class="header-container">
        <div class="px-4 pb-4 row justify-content-md-center text-center ">
            <div class="header-wrapper">
                <div class="">
                    <h6 class="title">Looking for a video?</h6>
                    <p class="title-description">Enter what you want to find in a video whether it's in the image or on
                        a voice quote... we find it!</p>
                </div>
                <form [formGroup]="headerForm" class="d-flex justify-content-center">
                    <div class="row">
                        <div class="col">
                            <div class="form-group " id="search-video">
                                <ng-autocomplete [data]="videoList" (inputChanged)='inputValueChanged($event)'
                                    (selected)="selectedItem($event)" [searchKeyword]="keyword"
                                    placeholder="Type something..." [itemTemplate]="itemTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplate let-item>
                                    <a class="listitems" [innerHTML]="item.fileName"></a>
                                </ng-template>
                            </div>
                            <!-- <input type="text" class="mb-4 name" placeholder="Type something..." formControlName="name"> -->
                        </div>
                        <div class="col search-icon-div" (click)="searchVideo()">
                            <img width="34" class="search-icon" type="image/svg+xml"
                                src="../.././../../assets//img/icons/search-icon.svg">
                        </div>
                    </div>
                </form>
                <form class="d-flex justify-content-center">
                    <div class="row" [style.width]="'40%'">
                        <div class="col align-items-center justify-content-center p-0 ml-1">
                            <label class="mtm-checkbox mtm-checkbox-green m-0">
                                <input class="form-check-input" type="checkbox">
                                <span class="checkmark"></span>
                                <span class="checkbox-text">Image</span>
                            </label>
                        </div>
                        <div class="col align-items-center justify-content-center p-0">
                            <label class="mtm-checkbox mtm-checkbox-green m-0">
                                <input class="form-check-input" type="checkbox">
                                <span class="checkmark"></span>
                                <span class="checkbox-text">Text</span>
                            </label>
                        </div>
                        <div class="col align-items-center justify-content-center p-0">
                            <label class="mtm-checkbox mtm-checkbox-green m-0">
                                <input class="form-check-input" type="checkbox">
                                <span class="checkmark"></span>
                                <span class="checkbox-text">Audio</span>
                            </label>
                        </div>
                        <div class="col align-items-center justify-content-center p-0 dropdown-position">
                            <select name="" id="" class="select-video">
                                <option value=""></option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<div class="content-wrapper">
    <div class="row mt-2">
        <img (click)="viewChanged(true)" class="grid-view"
            [src]="isViewChanged ? '../../../../assets/img/icons/gray-list.svg' : '../../../../assets/img/icons/black-grid.svg'"
            alt="">
        <img (click)="viewChanged(true)" class=" ml-2 list-view"
            [src]="isViewChanged ? '../../../../assets/img/icons/black-grid.svg' : '../../../../assets/img/icons/gray-list.svg'"
            alt="">
        <span class="ml-3 search-result-text text-uppercase" *ngIf="itemListLength">{{itemListLength}} results</span>
        <span class="ml-3 search-result-text text-uppercase" *ngIf="!itemListLength">no result found</span>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-4" *ngFor="let video of videoList">
            <div class="card video-block">
                <div class="card-img">
                    <img class="image-block" [src]="video.image" alt="">
                </div>
                <div class="card-block">
                    <div class="ml-2 mt-2 d-flex video-length-block">
                        <span class="ml-1 video-length-text"> 00:00:00 </span>
                    </div>
                    <div class="ml-2 mt-1 d-flex">
                        <span class="brand-name-text"> brandname
                            <img class="mr-1 ml-1" src="../../../../assets/img/icons/angle-right-arow.svg">
                            section name
                        </span>
                    </div>
                    <div class="ml-2 mt-1 d-flex file-name-block">
                        <span class="file-name-text"> {{video.fileName}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>