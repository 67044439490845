import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";

@Directive({
  selector: '[passwordToggle]'
})
export class PasswordToggleDirective implements OnInit, OnDestroy {
  iconEl: any = null;

  constructor(private el: ElementRef) {

  }

  get passwordEl() {
    return this.el.nativeElement;
  }

  ngOnInit(): void {
    const i = document.createElement('i');
    i.classList.add('fa', 'fa-eye', 'position-absolute');
    i.style.right = '10px';
    i.style.cursor = 'pointer';
    i.style.color = this.passwordEl.style.color;
    this.iconEl = i;

    this.passwordEl.after(i);
    $(this.iconEl).on('click', () => {
      this.togglePassword();
    });
    i.style.top = ((this.passwordEl.offsetHeight - i.offsetHeight) / 2) + 'px';
  }

  ngOnDestroy(): void {
    if (this.iconEl) {
      $(this.iconEl).off('click');
      this.iconEl.remove();
    }
  }

  togglePassword() {
    const newType = this.passwordEl.getAttribute('type') == 'password' ? 'text' : 'password';
    this.passwordEl.setAttribute('type', newType);
    this.iconEl.classList.toggle('fa-eye-slash');
  }
}
