import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

/* ----------- * DIRECTIVES * ----------- */


/* ----------- * GUARDS * ----------- */



/* ----------- * PIPES * ----------- */



/* ----------- * PAGES * ----------- */


/* ----------- * COMPONENTS * ----------- */
import { DetailsComponent } from "app/layouts/prod-partner/details/details.component";
import { SchedulePartnerSelectionComponent } from "app/layouts/prod-partner/schedule-partner-selection/schedule-partner-selection.component";
import { CompanyProfilesComponent } from "app/layouts/prod-partner/company-profiles/company-profiles.component";

/* ----------- * MODAL_COMPONENTS * ----------- */


/* ----------- * SERVICES * ----------- */


/* ----------- * ROUTE * ----------- */

import { SharedModule } from 'app/sub-modules/SharedModule';

@NgModule({
    declarations: [
        DetailsComponent,
        SchedulePartnerSelectionComponent,
        CompanyProfilesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
    ],
    providers: [],
    exports: [
        DetailsComponent,
        SchedulePartnerSelectionComponent,
        CompanyProfilesComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class BetweenProjectModuleAndOutherModuleSharedModule {
}
