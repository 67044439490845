import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
// import { NGValidators } from "ng-validators";

import { ProductionTeamService } from 'app/shared/services/proposal-services/productionteam.service';
import { ProposalTeamUserModel } from '../proposal-user.model';
import { ProjectService } from '../../../../../shared/services/project.service';

@Component({
	selector: 'mtm-project-proposal-new-user',
	templateUrl: './project-proposal-new-user.component.html',
	styleUrls: ['./project-proposal-new-user.component.scss']
})
export class ProjectProposalNewUserComponent implements OnInit {

	@Input() allCompanyUsers: any[] = [];
	@Input() allRoles: any[] = [];


	addUserForm: UntypedFormGroup;
	user: ProposalTeamUserModel = new ProposalTeamUserModel();

	submitted: boolean = false;
	sending: boolean = false;

	constructor(public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private serviceProductionTeam: ProductionTeamService,
		private serviceProject: ProjectService) {
		this.loadFormGroup();
	}

	ngOnInit() {

	}

	/**
	 * Load form group
	 */
	private loadFormGroup() {
		// Build FormGroup
		this.addUserForm = this.fb.group({
			username: [this.user.username ? this.user.username : null, [Validators.required, Validators.email]],
			jobTitle: [this.user.jobTitle ? this.user.jobTitle : null],
			userRole: [this.user.userRole ? this.user.userRole : "", [Validators.required]],
		});
	}


	/**
	 * Upload data on submit
	 */
	onSubmit(): void {
		if (this.addUserForm.invalid)
			return;

		else {
			this.submitted = true;
			this.user.setNewUserJobTitle(this.addUserForm.get('username').value,
				this.addUserForm.get('jobTitle').value,
				this.addUserForm.get('userRole').value,
				this.serviceProductionTeam.getCompanyId(),
				this.serviceProductionTeam.getCompanyInfo().name
			);
		}
		// if(inCompany.length){
		// 	this.activeModal.close(this.user);

		this.activeModal.close(this.user);
	}



	/**
	 * Check if form field is valid
	 * @param field
	 */
	isValid(field: string): boolean {
		return this.addUserForm.get(field).invalid && this.submitted;
	}
}
