import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { Observable } from "rxjs";
import { PaymentDTO } from "../../payment/credit-card-details.model";
import { UUID } from "angular2-uuid";

@Injectable({
	providedIn: 'root'
})
export class PaymentService {

	constructor(private api: ApiService) {
	}

	public changePaymentStatus(projectId, status): Observable<any> {
		return this.api.httpPut(`/api/payment/${projectId}/status/${status}`, {})
	}

	public createPayment(paymentDTO: PaymentDTO): Observable<any> {
		return this.api.httpPost(`/api/payment/${paymentDTO.projectId}/payment`, paymentDTO);
	}
	public getSourceCustomer(projectId: UUID, sourceEmail: string, paymentKind: string): Observable<any> {
		return this.api.httpGet(`/api/payment/${projectId}/sourceCustomer/${sourceEmail}/${paymentKind}`);
	}

	public createhostedStripeBoardLink(productionCompanyId, tokenId): Observable<any> {
		return this.api.httpGet(`/api/payment/createhostedStripeBoardLink/${productionCompanyId}/${tokenId}`);
	}

	public getCompanyExternalAccounts(companyId): Observable<any> {
		return this.api.httpGet(`/api/payment/connectedaccountsbankordebitcard/${companyId}`);
	}

	public checkConnectedAccountVerification(companyId, sendMail): Observable<any> {
		return this.api.httpGet(`/api/payment/checkConnectedAccountVerification/${companyId}?&sendMail=${sendMail}`);
	}

	public setCompanyExternalAccounts(companyId: string, tokenId: string) {
		return this.api.httpPost(`/api/payment/connectedaccountsbankordebitcard/${companyId}`, tokenId);
	}
}
