import { Directive, ElementRef } from '@angular/core';


@Directive({ selector: '[directiveCommentMinButtons]' })
export class CommentMinButtonsDirective {
	isHovered: boolean = false;
	constructor(
		public elementRef: ElementRef
	) { }

	ngOnInit() {
		this.elementRef.nativeElement.style.visibility = 'hidden';
		this.init();
	}

	private init() {
		try {
			this.elementRef.nativeElement.parentElement.onmouseenter = (e) => {
				this.isHovered = true;
				setTimeout(() => {
					if (this.isHovered) this.elementRef.nativeElement.style.visibility = 'visible';
				}, 300);
			}
			this.elementRef.nativeElement.parentElement.onmouseleave = (e) => {
				this.isHovered = false;
				this.elementRef.nativeElement.style.visibility = 'hidden';
			}
		} catch (error) {
			console.error('Element References Error');
		}
	}
}
