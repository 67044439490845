import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtm-ai-reframing-icon',
  templateUrl: './ai-reframing-icon.component.html'
})
export class AIReframingIconComponent implements OnInit {
  @Input() svgWidth: string = "25px";

  constructor() { }

  ngOnInit() { }

}
