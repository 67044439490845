import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from "app/shared/services/helper.service";
import { PopupMediaPlayerComponent } from 'app/shared/components/popup-media-player/popup-media-player.component';
import { UploadService } from 'app/shared/services/upload.service';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SignedUrlService {

	constructor(private modalService: NgbModal,
		private apiService: ApiService,
		private uploader: UploadService,
		private http: Http) { }

	public getSubSectionFileImageSignedUrl(s: any, l: any): void {
		s.img = l.signedURL;
		if (!s.img)
			s.img = HelperService.getAvatarNoImage();
	}


	public playerSignedUrl(file: any, windowClass?: any): void {
		const modalRef = this.modalService.open(PopupMediaPlayerComponent, { size: 'lg', windowClass: windowClass });
		modalRef.componentInstance.file = file;
	}

	public downloadSignedUrl(file: any, opts: any, onloadstart?: any, onprogress?: any, onloadend?: any, onerror?: any): void {
		let token = localStorage.getItem('token');
		download(file.signedURL, file.name, opts, onloadstart, onprogress, onloadend, onerror, [
			{ name: "Authorization", value: "Bearer " + token },
			{ name: "X-Download", value: "true" }
		]);
	}
}
