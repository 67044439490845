import {
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { HIERARCHICALFILESMODEL } from '../common/hierarchical-files.model';

import { setDOMSanitizer } from '../common/functions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileModalComponent } from '../file-modal/file-modal.component';
import { ProjectService } from 'app/shared/services/project.service';
import { MTMFileDownloadModel } from '../../mtm-file-download/mtm-file-download.model';
import moment from 'moment';
import { environment } from 'environments/environment';
import { HelperService } from 'app/shared/services/helper.service';
import { UUID } from 'angular2-uuid';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { CommonFileInfo, DirectoryChangeInfo, HierarchicalFileInfo, MoveFileInfo } from "app/shared/interfaces";
import { DirectoryService } from "../../../services/directory.service";
import { FileTransferService, projectKeyFileFolderAdded, projectKeyFileFolderRemoved } from "../../../services/signed-url";
import { FolderComponent } from 'app/layouts/post-prod/subtitles/folder/folder.component';
import { ROOT_FOLDER } from "../../../../layouts/post-prod/subtitles/subtitle.const";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FilePreviewModalComponent } from "../file-preview-modal/file-preview-modal.component";
import { MTMFileDownload } from 'app/shared/services/signed-url/mtm-file-download';


@Component({
  selector: 'slctr-hrfiles-cmp',
  templateUrl: './hierarchical-files.component.html',
  styleUrls: ['./hierarchical-files.component.scss']
})
export class HierarchicalFilesComponent implements OnInit, OnChanges {
  @Input() hrModel: any;

  @Input() skipCalculation: boolean = false;
  @Input() isDisplayTrashBtn: boolean = true;
  @Input() isSearchDisplay: boolean = true;
  @Input() isMenuDisplay: boolean = false;
  @Input() isSmallDisplay: boolean = false;
  @Input() viewMode: string = 'LIST';
  @Input() label: string = '';
  @Input() stillLoading: boolean;
  @Input() selectable: boolean = false;
  @Input() disableAction: boolean = false;
  @Input() selectedFiles: any = [];
  @Input() showArchiveButton: boolean = false;
  @Input() canDownloadFiles: boolean = false;
  @Input() showRecentUploadedAssets: boolean = false;
  @Input() folders: any = [];
  @Input() draggingEnabled: boolean = false; //at the moment dragging is disabled, due to the scrolling issue while dragging
  @Input() draggingKey: string = '';
  @Input() showNumberOfFiles: boolean = false;
  @Input() localFile: boolean = false;
  @Input() cacheBuster: number = 0;
  @Input() showUploadedBy: boolean = false;

  @Output() selectedFilesChange: EventEmitter<any> = new EventEmitter();

  /* ------- * * ------- */

  @Output() directoryChange: EventEmitter<DirectoryChangeInfo> = new EventEmitter<DirectoryChangeInfo>();
  @Output() folderClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() filesLoading: EventEmitter<CommonFileInfo> = new EventEmitter<CommonFileInfo>();
  @Output() createDirectoryClick: EventEmitter<CommonFileInfo> = new EventEmitter<CommonFileInfo>();
  @Output() deleteDirectoryClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFileClick: EventEmitter<CommonFileInfo> = new EventEmitter<CommonFileInfo>();
  @Output() fileIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileNameClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDrag: EventEmitter<MoveFileInfo> = new EventEmitter<MoveFileInfo>();
  @Output() fileMovePrompt: EventEmitter<any> = new EventEmitter<any>();

  @Output() onTrash: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTrashRF: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTrashLF: EventEmitter<any> = new EventEmitter<any>();


  /* ------- * * ------- */

  @ViewChild('hierarchicalFilesDisplayContainer', { static: true }) hierarchicalFilesDisplayContainer: ElementRef;

  /* ------- * * ------- */

  public hierarcicalMenu: any[] = [];
  currentRoot: any = new HIERARCHICALFILESMODEL();
  fileSearchName: string;
  slideValueMax: number = 100;
  slideValueMin: number = 5;
  slideValue: number = 100;
  maxWidthForPreviewMode: number = 21;
  diffWidthForPreviewMode: number = 12;
  widthForPreviewMode: number = 144;
  heightForPreviewMode: number = 115;
  hidden: boolean = true;

  downloadId: any;
  archivingFilesInProgress: boolean = false;
  recentUploadedAssets: any = [];
  isHasAccess: boolean = false;
  interval = null;
  isAllAssetsSelected: boolean = false;

  sortBy: any = 'name';
  dir: any = 'desc';

  private ngUnsubscribe = new Subject();

  constructor(
    private sane: DomSanitizer,
    private projectService: ProjectService,
    private directoryService: DirectoryService,
    private modalService: NgbModal,
    private transferService: FileTransferService,
    public permissionService: PermissionService
  ) {
    setDOMSanitizer(this.sane);
  }

  ngOnInit() {
    this.isHasAccess = this.permissionService.hasAuthority(Authority.U, this.projectService.project.id);
    this.init();

    this.calcMaxWidthForPreviewMode();

    window.onresize = () => {
      this.calcMaxWidthForPreviewMode();
    };

    this.removeInterval();

    if (!this.skipCalculation) {
      this.interval = setInterval(() => {
        if (!this.hrModel)
          return;

        this.prepareTree(this.hrModel);
      }, 2555);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const keysToIgnore = ['stillLoading', 'showUploadedBy'];

    const remainingKeys = Object.keys(changes).filter(key => !keysToIgnore.includes(key));


    if(remainingKeys.length === 0) {
      return;
    }
    
    if (!changes.viewMode || changes.viewMode.firstChange || changes.cacheBuster) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.removeInterval();
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  getFolderDisplayName(displayName) {
    const nameArr = displayName.split('/').filter(n => !!n);
    return decodeURI(nameArr[nameArr.length - 1]);
  }

  private removeInterval() {
    if (this.interval)
      clearInterval(this.interval);
  }

  private init() {
    this.hidden = this.isSearchDisplay;

    this.hrModel = this.hrModel ? this.hrModel : new HIERARCHICALFILESMODEL();
    this.hrModel.files = this.hrModel.files ? this.hrModel.files : [];
    this.hrModel.children = this.hrModel.children ? this.hrModel.children : [];

    this.hierarcicalMenu = [];
    this.nextFolder(this.hrModel);
    if (!this.skipCalculation) {
      this.prepareTree(this.hrModel);
    }

  }

  prepareTree(root): any {
    if (root == null) {
      return { sum: 0, lastUpdated: 0 };
    }
    root.size = 0;
    let sum = root.files.length, lastUpdatedFile = Math.max(root.files.map((f: any) => f.updated)) || 0;
    if (root.files && root.files.length) {
      this.recentUploadedCheck(root.files);
      root.size = root.files.reduce((acc, file) => {
        acc += file.size;
        return acc;
      }, root.size);
    }
    if (!root.children) {
      root.children = [];
    }
    root.children.map(k => {
      if (this.selectable) {
        if (this.selectedFiles.length) {
          k.selected = this.selectedFiles.find(file => file.id === k.id);
        }
      }
      const info = this.prepareTree(k);
      sum += info.sum;
      root.size += info.size;
      lastUpdatedFile = info.lastUpdated > lastUpdatedFile ? info.lastUpdated : lastUpdatedFile;
    });

    root.displayLengthOfFiles = sum;
    root.lastUpdated = lastUpdatedFile;
    return { sum: root.displayLengthOfFiles, lastUpdated: root.lastUpdated, size: root.size, createdBy: root.createdBy };
  }

  formatSizeUnits(bytes: any) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes == 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 byte';
    }
    return bytes;
  }

  recentUploadedCheck(files) {
    for (let i = 0; i < files.length; i++) {
      const isInRecentUploaded = this.recentUploadedAssets.find(f => f.files[0].id === files[i].id);
      if (!isInRecentUploaded) {
        this.recentUploadedAssets.push({ files: [files[i]], title: files[i].displayName });
      }
    }
    this.recentUploadedAssets = this.recentUploadedAssets.sort((a: any, b: any) => b.files[0].updated - a.files[0].updated).slice(0, 4);
  }

  private getCurrentRoot() {
    if (this.hierarcicalMenu.length <= 0)
      return new HIERARCHICALFILESMODEL();

    return this.hierarcicalMenu[this.hierarcicalMenu.length - 1];
  }

  selectViewChange(event, selectedViewMode: string) {
    event.preventDefault();
    this.viewMode = selectedViewMode;
  }

  nextFolder(child) {
    if (child == null) {
      return;
    }

    this.hierarcicalMenu.push(child);
    this.currentRoot = child;
    if (!child.lazyFiles) {
      this.sortData();
    } else {
      this.filesLoading.emit({
        currentFolder: child,
        path: this.hierarcicalMenu
      });
    }

    this.directoryChange.emit({
      currentFolder: child,
      path: this.hierarcicalMenu
    });
  }

  getParentFolder(): any{
    if(this.hierarcicalMenu.length <= 1) {
      return null;
    }

     const slice =  this.hierarcicalMenu.slice(this.hierarcicalMenu.length - 2, this.hierarcicalMenu.length - 1);
     return slice[slice.length - 1];
  }

  preFolder(index) {
    this.hierarcicalMenu.splice(index + 1, this.hierarcicalMenu.length - 1);
    this.currentRoot = this.hierarcicalMenu[this.hierarcicalMenu.length - 1];
    if (!(<any>this.currentRoot).lazyFiles) {
      this.sortData();
    } else {
      this.filesLoading.emit({
        currentFolder: this.currentRoot as HierarchicalFileInfo,
        path: this.hierarcicalMenu
      });
    }

    this.directoryChange.emit({
      currentFolder:  this.currentRoot,
      path: this.hierarcicalMenu
    });
  }

  public backFolder() {
    this.preFolder(this.hierarcicalMenu.length - 2)
  }

  private hide() {
    this.hidden = false;
  }

  private searchFiles() {
    if (this.fileSearchName == null || this.fileSearchName == '') {
      this.currentRoot = this.getCurrentRoot();
      this.sortData();
    } else {
      let response = new HIERARCHICALFILESMODEL();
      response.name = this.getCurrentRoot().name;
      this.searchFilesInTree(this.fileSearchName.toLowerCase(), this.getCurrentRoot(), response);
      this.currentRoot = response;
      this.sortData();
    }
  }

  private searchFilesInTree(key: string, root: HIERARCHICALFILESMODEL, responseFiles: HIERARCHICALFILESMODEL) {
    if (!root || root == null)
      return;

    root.files.filter(k => k.displayName.toLocaleLowerCase().indexOf(key) >= 0).map(k => {
      responseFiles.files.push(k);
    });

    root.children.forEach(k => this.searchFilesInTree(key, k, responseFiles));
  }

  private calcMaxWidthForPreviewMode() {
    let containerWidth = this.hierarchicalFilesDisplayContainer.nativeElement.clientWidth;
    this.maxWidthForPreviewMode = (containerWidth - 15) / 3.0;
    this.diffWidthForPreviewMode = this.maxWidthForPreviewMode - this.maxWidthForPreviewMode / 4.0;
    this.changeSlideValue();
  }

  changeSlideValue(direction: string = null) {
    if (direction == 'ZOOM-OUT')
      this.slideValue -= 10;
    if (direction == 'ZOOM-IN')
      this.slideValue += 10;

    if (this.slideValue > this.slideValueMax)
      this.slideValue = this.slideValueMax;
    if (this.slideValue < this.slideValueMin)
      this.slideValue = this.slideValueMin;

    let k = ((this.slideValueMax + this.slideValueMin) - (this.slideValue)) / this.slideValueMax;
    this.widthForPreviewMode = this.maxWidthForPreviewMode - (this.diffWidthForPreviewMode * k);
    this.heightForPreviewMode = this.widthForPreviewMode * 7.1 / 9;
  }

  onTrashFile(file) {
    if (!this.localFile) {
      this.currentRoot.files = this.currentRoot.files.filter(f => f.id !== file.id);
    }
    this.onTrash.emit(file);
    if (file.id)
      this.onTrashRF.emit(file);
    else
      this.onTrashLF.emit(file);
  }

  onFileSelected(e, file) {
    e.preventDefault();
    if (this.selectable) {
      file.selected = !file.selected;
      if (file.selected) {
        this.selectedFiles.push(file);
      } else {
        this.selectedFiles = this.selectedFiles.filter((fl: any) => fl.selected);
      }
      this.selectedFilesChange.emit(this.selectedFiles);
    }
  }

  openFolderModal(e) {
    if (this.archivingFilesInProgress) {
      return;
    }
    const modal = this.modalService.open(FileModalComponent, {
      size: 'lg',
      windowClass: 'file-modal-component',
      backdrop: 'static'
    });
    modal.componentInstance.files = this.hrModel;
    modal.result.then((sectionIds: any[]) => {
      let ids = sectionIds;

      if (ids.length) {
        this.downloadId = UUID.UUID();
        this.archivingFilesInProgress = true;
        /*
        const fileDownloader = new MTMFileDownloadModel('files-download' + this.downloadId, {
          name: this.projectService.project.name + '-assets-' + moment().format('MMDDYYYY'),
          signedURL: environment.api.baseUrl + '/api/projects/' + this.projectService.project.id + '/files-tree/zip?sectionIds=' + ids.join(','),
          contentType: 'application/zip'
        }, (res) => {
          this.archivingFilesInProgress = false;
        });
        fileDownloader.startDownload();
        */
        const downloadInfo = new MTMFileDownload();
        downloadInfo.id = `files-download${this.downloadId}`;
        downloadInfo.name = `${this.projectService.project.name}-assets-${moment().format('MMDDYYYY')}`;
        downloadInfo.url = environment.api.baseUrl + '/api/projects/' + this.projectService.project.id + '/files-tree/zip?sectionIds=' + ids.join(',');
        downloadInfo.contentType = 'application/zip';
        downloadInfo.isSilent = true;
        downloadInfo.isResumable = false;
        downloadInfo.done$.subscribe(() => {
          this.archivingFilesInProgress = false;
        });
        this.transferService.downloadFile(downloadInfo);
      }
      this.hrModel.children.map(f => f.selected = false);
    })
  }

  selectAsset() {
    this.isAllAssetsSelected = this.hrModel.children.filter(f => f.selected).length === this.hrModel.children.length;
  }

  selectAllAssets(e) {
    this.hrModel.children.map(f => f.selected = this.isAllAssetsSelected);
  }

  sortData(sortBy?: string) {
    if (sortBy) {
      if (this.sortBy === sortBy) {
        this.dir = this.dir === 'asc' ? 'desc' : 'asc';
      } else {
        this.dir = 'desc';
      }
      this.sortBy = sortBy;
    }
    if (this.sortBy === 'name') {
      if (this.currentRoot.children.length) {
        this.currentRoot.children.sort(
          (a, b) => this.dir === 'asc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
        );
      }
      if (this.currentRoot.files.length) {
        this.currentRoot.files.sort(
          (a, b) => this.dir === 'asc' ? b.displayName?.localeCompare(a.displayName) : a.displayName?.localeCompare(b.displayName)
        );
      }
    } else if (this.sortBy === 'content') {
      if (this.currentRoot.children.length) {
        this.currentRoot.children.sort(
          (a: any, b: any) => this.dir === 'asc' ? a.displayLengthOfFiles - b.displayLengthOfFiles : b.displayLengthOfFiles - a.displayLengthOfFiles
        );
      }
    } else if (this.sortBy === 'displayLengthOfFiles') {
      if (this.currentRoot.children.length) {
        this.currentRoot.children.sort(
          (a: any, b: any) => this.dir === 'asc' ? b.displayLengthOfFiles - a.displayLengthOfFiles : a.displayLengthOfFiles - b.displayLengthOfFiles
        );
      }
      if (this.currentRoot.files.length) {
        this.currentRoot.files.sort(
          (a, b) => this.dir === 'asc' ? b.updated - a.updated : a.updated - b.updated
        );
      }
    } else if (this.sortBy === 'lastUpdate') {
      if (this.currentRoot.children.length) {
        this.currentRoot.children.sort(
          (a: any, b: any) => this.dir === 'asc' ? b.lastUpdated - a.lastUpdated : a.lastUpdated - b.lastUpdated
        );
      }
      if (this.currentRoot.files.length) {
        this.currentRoot.files.sort(
          (a, b) => this.dir === 'asc' ? b.updated - a.updated : a.updated - b.updated
        );
      }
    } else if (this.sortBy === 'size') {
      if (this.currentRoot.children.length) {
        this.currentRoot.children.sort(
          (a: any, b: any) => this.dir === 'asc' ? b.size - a.size : a.size - b.size
        );
      }
      if (this.currentRoot.files.length) {
        this.currentRoot.files.sort(
          (a: any, b: any) => this.dir === 'asc' ? b.size - a.size : a.size - b.size
        );
      }
    }
  }

  openDirectory(folder) {
    this.folderClick.emit(folder);
  }

  createDirectory() {
    this.createDirectoryClick.emit({
      currentFolder: this.currentRoot,
      path: this.hierarcicalMenu
    });
  }

  uploadFile() {
    this.uploadFileClick.emit({
      currentFolder: this.currentRoot,
      path: this.hierarcicalMenu
    });
  }

  deleteDirectory(directory: any) {
    this.deleteDirectoryClick.emit({
      directory,
      currentFolder: this.currentRoot,
      path: this.hierarcicalMenu
    });
  }

  handleListNameClick(file: any) {
    this.fileNameClick.emit(file);
  }

  handleListIconClick(file: any) {
    this.fileIconClick.emit(file);
  }

  handleFileMovePrompt(file: any) {
    this.fileMovePrompt.emit({
      file,
      currentFolder: this.currentRoot,
      path: this.hierarcicalMenu
    });
  }

  handleDrag(event: any, file: any) {
    if (!file) {
      return;
    }
    event.dataTransfer.setData(`${this.draggingKey}_fileId`, file.id);
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  handleDrop(event: any, directory: any) {
    event.preventDefault();
    let fileId = event.dataTransfer.getData(`${this.draggingKey}_fileId`);
    if (!fileId) {
      return;
    }
    const file = this.currentRoot.files.find(f => f.id === fileId);
    if (!file) {
      return;
    }
    this.currentRoot.files = this.currentRoot.files.filter(f => f.id !== fileId);
    this.fileDrag.emit({
      file,
      targetDirectoryId: directory.id,
      currentFolder: this.currentRoot,
      path: this.hierarcicalMenu
    });
  }
}
