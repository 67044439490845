import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "../services/translate.service";
@Pipe({
	name: 'translate',
	pure: false
})
export class TranslatePipe implements PipeTransform {
	constructor(private translate: TranslateService) { }

	transform(key: any, ...args): any {
		let translation = this.translate.data[key] || key;
		if (typeof translation === "undefined") {
			// console.error("Missing translation for key: " + key);
			return key;
		}
		if (args.length > 0 && typeof args[0] == 'object') {
			Object.keys(args[0]).forEach(k => {
				translation = translation.replaceAll("%" + k.toUpperCase() + "%", args[0][k])
			})
		}
		return translation
	}

	translateRole(role: string) {
		return this.transform(`role${role}`);
	}

	translateSectionStatus(status: string) {
		return this.transform(`sectionStatus_${status}`);
	}
}
