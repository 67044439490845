<div class="card card-position pb-5">
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-12 d-flex justify-content-center align-items-center dashboard-background-color"
      [ngClass]="isDashboard ? 'dashboard-background': isAnalytics ? 'analytics': 'default-background' ">
      <div class="w-100 text-center" [ngClass]="{ 'no-cover-img': !headerImage }">
        <img *ngIf="headerImage" class="w-100" [ngClass]="isTaskProject ? 'task-project-block' : 'dashboard-image-block'"
          [ngStyle]="{'height': isGeneral || isRecap ? '235px':''}" [src]="headerImage" />
      </div>
      <div class="row text-position" *ngIf="title && subTitle">
        <div class="col">
          <div> <span class="company-title">{{title}}</span></div>
          <div class="mt-1"> <span class="company-header-title"
              [ngClass]="{'text-uppercase': upperCase}">{{subTitle}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showSideNav" class="col-12">
      <div class="row">
        <div class="col pl-5" [style.max-width]="'255px'" [style.min-width]="'255px'" *ngIf="showSideNav">
          <div class="sidebar-card ">
            <div class="col my-3 pt-3">
              <div *ngIf="canCreateProject" class="sidebar-btn d-flex align-items-center" [style.background]="'#000'" (click)="createNew()"
                [ngClass]="{'active-create-btn' : isCreateActive }">
                <div class="circle-div ml-1">
                  <i [style.color]="'#fff'" class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <span [style.color]="'#fff'" class="sidebar-btn-text text-uppercase f-14 _700 ml-2 ">{{'loreal_dashboard_createNew' | translate}}</span>
              </div>
              <div class="create-btn-dropdown pointer" *ngIf="isCreateActive">
                <div class="col create-btn-text" (click)="createNewItem(item)" *ngFor="let item of createBtnRouts">
                  {{item}}
                </div>
              </div>
            </div>
            <div class="col my-3">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Workspaces','workspaceComponent')"
                [routerLink]="'/workspaces'"
                [ngStyle]="{ 'background-color': isActive('/workspaces') || isWorkspaceActive ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/workspace-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'workspaces' | translate }}</span>
              </div>
            </div>
            <div class="col my-3" *ngIf="showDriveCampaign">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Drive Campaigns','campaignDriveComponent')"
                [routerLink]="'/workspaces/campaign-drive'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/campaign-drive') || isDriveCampaignComponet ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/drive-campaign-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'loreal_driveCampaigns' | translate }}</span>
              </div>
            </div>
            <div class="col my-3" *ngIf="showCampaign">
              <div class="sidebar-btn d-flex align-items-center" (click)="setHeaderTitle('Campaigns','campaignsComponent')"
                [routerLink]="'/workspaces/campaigns'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/campaigns') || isCampaignComponent ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/campaign-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'loreal_campaigns' | translate}}</span>
              </div>
            </div>
            <div class="col my-3">
              <div id="trainerProjectMenuButton" (click)="stService.hideTrainerModalAndStartTask()" class="sidebar-btn d-flex align-items-center" [routerLink]="'/workspaces/projects'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/projects') || isProjectComponent ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/project-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'projects' | translate }}</span>
              </div>
            </div>
            <div class="col my-3">
              <div class="sidebar-btn d-flex align-items-center"
                [routerLink]="'/workspaces/shared'"
                [ngStyle]="{ 'background-color': isActive('/workspaces/shared') || isSharedComponent ? '#F96416' : '' }">
                <img width="16" height="16" class="ml-1 mr-2" src="../../../assets/svg/shared-svg.svg" alt="">
                <span class="sidebar-btn-text text-capitalize">{{'loreal_shared' | translate}}</span>
              </div>
            </div>

          </div>
        </div>
        <div class="col">
          <div *ngIf="showSideNav">
            <!-- <div *ngIf="isWorkspaceDashboard" class="row border-bottom mt-3">
                <div class="col my-3">
                  <span class="f-17 _700">Workspaces</span>
                </div>
                <div class="col my-3 text-right">
                  <input [(ngModel)]="searchString" class="search-box pl-2 pr-4" (ngModelChange)="searchItem()"
                    type="text" placeholder="Search..." />
                  <i class="fa fa-search search-icon" aria-hidden="true"></i>
                </div>
              </div> -->
            <div class="row border-bottom mt-3">
              <div class="col d-flex align-items-center mt-2">
                <span class="f-17 _700 text-capitalize">{{ headerLabel | translate}}</span>
              </div>
              <div class="col d-flex justify-content-end text-right">
                <div class="tabs mt-2">
                  <div class="nav-tabs">
                    <div class="nav-item" (click)="allItems()">
                      <a routerLinkActive="active"
                        [ngClass]="{'active-tab': isAll, 'nav-link': !isAll, '_700': isAll, '_400': !isAll}"
                        class="pointer text-uppercase f-14">{{'All' | translate }}</a>
                    </div>
                    <div class="nav-item" (click)="favoriteItems()">
                      <a routerLinkActive="active"
                        [ngClass]="{'active-tab': isFavorites, 'nav-link': !isFavorites, '_700': isFavorites, '_400': !isFavorites}"
                        class="pointer text-uppercase f-14">{{'favorites'| translate }}</a>
                    </div>
                    <div *ngIf="!isWorkspaceDashboard" class="nav-item" (click)="getArchiveProjects()">
                      <a routerLinkActive="active"
                        [ngClass]="{'active-tab': isArchived, 'nav-link': !isArchived, '_700': isArchived, '_400': !isArchived}"
                        class="pointer text-uppercase f-14">{{'archived' | translate }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!showSideNav">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
