<div class="mtm-file-download-upload-preview d-flex align-items-center">
	<div class="mtm-file-download-upload-preview-icon">
		<img type="image/svg+xml" [src]="getFileTypeIcon(file?.contentType)" onError="this.src='assets/img/icons/file.svg'">
	</div>
	<div class="mtm-file-download-upload-preview-meta flex-fill d-flex flex-column">
		<div class="d-flex align-items-center pt-1">
			<div class="filename mr-auto flex-fill pt-1 pb-1" fileNameDisplay [fileName]="file?.displayName || file?.name">
			</div>
			<div class="progress-in-text ml-auto pt-1 pb-1">
				{{file?.percentage}}%
			</div>
		</div>
		<div class="d-flex align-items-center additional-labels mb-1" *ngIf="file.size">
			<span class="d-inline-block file-size">{{file.size | mtmFileSize }}</span>
			<span class="d-inline-block" *ngIf="file.startDate">{{file.startDate | mtmDateTime }}</span>
		</div>
		<div class="mtm-file-download-upload-preview-progress mt-2">
			<div class="progress-background" [style.width]="(file?.percentage || 0) + '%'"></div>
		</div>
	</div>
	<div class="mtm-file-download-upload-preview-nav d-flex align-items-center">
		<div class="mtm-file-download-upload-preview-pause-icon pl-3"
			(click)="$event.stopPropagation(); pauseDownload()"
			*ngIf="file.status == DownloadStatus.DOWNLOADING" role="button">
			<i class="fa fa-pause-circle-o" aria-hidden="true"></i>
		</div>
		<div class="mtm-file-download-upload-preview-resume-icon" *ngIf="resumableStatusCodes.includes(file.status)" 
		(click)="$event.stopPropagation(); resumeDownload()"
			role="button">
			<i class="fa fa-play-circle-o" aria-hidden="true"></i>
		</div>
		<div class="mtm-file-download-upload-preview-close-icon pl-3"
			(click)="$event.stopImmediatePropagation();removeDownloadedFile()">
			<i class="fa fa-times-circle"></i>
		</div>
	</div>
</div>