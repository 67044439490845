<div class="container" *ngIf="successful">
	<div class="col">
		<div class="row mt-5">

			<div class="col">

				<div class="row justify-content-center">

				</div>
			</div>


			<div class="col">
				<div class="row justify-content-center">
					<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
				</div>
				<div class="row ">
					<p class="success-text mt-5 text-center">
						Connected Account Creation <span *ngIf="completed" class="success-span">Successful</span><span
							*ngIf="!completed" class="pending-span">Pending</span>
					</p>
				</div>
				<div class="row justify-content-center">
					<i *ngIf="completed" class="fa fa-check-circle icon-size success-span" aria-hidden="true"></i>
					<i *ngIf="!completed" class="fa fa-clock-o icon-size pending-span" aria-hidden="true"></i>
				</div>
			</div>

			<div class="col">

			</div>
		</div>



	</div>
</div>