export interface NewWorkspaceInfo {
	name: string;
	owners: WorkspaceOwnerInfo[];
	imageUrl: string;
}

export interface WorkspaceOwnerInfo {
	name: string;
	email: string;
	avatarUrl: string;
	jobTitle: string;
	role: string;
	user?: any;
	status: string;
}

export function CreateNewWorkspaceInfo(): NewWorkspaceInfo {
	return {
		name: '',
		owners: [],
		imageUrl: ''
	}
}

export interface UserWorkspaceRole {
	user?: any;
	fullName: string;
	username: string;
	jobTitle: string;
	role: string;
	roleLabel: string;
	canChange: boolean;
	status?: any
	firstName?: string;
	lastName?: string;
}


