import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";
import { EditWorkspacePermissionsComponent } from "../../../workspace-permissions.component";

@Component({
    selector: 'action-cell',
    templateUrl: './action-cell.component.html',
    styleUrls: ['./action-cell.component.scss']
})
export class ActionCellComponent implements ICellRendererAngularComp, OnInit {
    params: any;

    constructor() { }

    ngOnInit(): void {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }
}

