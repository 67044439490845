import * as _ from 'lodash';
import { Component, OnInit } from "@angular/core";
import { MTMFileDownloadModel } from "../mtm-file-download.model";
import { MTMFileDownloadService } from "../mtm-file-download.service";
import { FileTransferService } from "../../../services/signed-url";
import { MTMFileDownload } from "../../../services/signed-url/mtm-file-download";

@Component({
	selector: 'mtm-file-download-list',
	templateUrl: './mtm-file-download-list.component.html',
	styleUrls: ['./mtm-file-download-list.component.scss']
})
export class MTMFileDownloadList implements OnInit {

	//downloadFileList: MTMFileDownloadModel[];
	downloadList: MTMFileDownload[];

	constructor(private fileDownloadService: MTMFileDownloadService,
		private transferService: FileTransferService) { }

	ngOnInit() {
		//this.downloadFileList = this.fileDownloadService.getAllDownloadFile();
		this.downloadList = this.transferService.filesToDownload;
	}


	removeDownloadedFile(file: MTMFileDownload) {
		//this.fileDownloadService.removeFromListByIndex(index);
		this.transferService.cancelDownload(file);
	}

}
