import {
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { VideoRecordingService } from 'app/shared/services/video-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'video-recorder-modal',
    templateUrl: './video-recorder-modal.component.html',
    styleUrls: ['./video-recorder-modal.component.scss']
})
export class VideoRecorderModalComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('videoElement', { static: true }) videoElement: any;

    video: any;
    isPlaying = false;
    displayControls = true;
    isVideoRecording = false;
    isRecordPause = false;
    videoRecordedTime;
    videoBlobUrl;
    videoBlob;
    videoName;
    videoStream: MediaStream;
    videoConf = { video: { facingMode: "user", width: 320 }, audio: true }

    minutes: number = 0;
    seconds: number = 0;
    timeInterval: any;

    constructor(
        private ref: ChangeDetectorRef,
        private videoRecordingService: VideoRecordingService,
        private sanitizer: DomSanitizer,
        private activeModal: NgbActiveModal
    ) {
        this.videoRecordingService.recordingFailed()
            .subscribe(() => {
                this.isVideoRecording = false;
                this.ref.detectChanges();
            });

        this.videoRecordingService.getRecordedTime()
            .subscribe((time) => {
                this.videoRecordedTime = time;
                this.ref.detectChanges();
            });

        this.videoRecordingService.getStream()
            .subscribe((stream) => {
                this.videoStream = stream;
                this.ref.detectChanges();
            });

        this.videoRecordingService.getRecordedBlob()
            .subscribe((data) => {
                this.videoBlob = data.blob;
                this.videoName = data.title;
                this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data.url);
                this.ref.detectChanges();
            });
    }

    ngOnInit(): void {
        this.video = this.videoElement.nativeElement;
        this.previewVideo();
    }

    ngOnChanges(changes: SimpleChanges): void { }

    ngOnDestroy(): void { }

    previewVideo() {
        this.videoRecordingService.showVideo(this.videoConf)
            .then(stream => {
                this.video.srcObject = stream;
                this.video.play();
            })
    }

    startVideoRecording() {
        this.videoRecordingService.stopPreview();
        if (!this.isVideoRecording) {
            this.video.controls = false;
            this.isVideoRecording = true;
            this.videoRecordingService.startRecording(this.videoConf)
                .then(stream => {
                    this.video.srcObject = stream;
                    this.video.play();
                })
                .catch(function (err) {
                    console.log(err.name + ": " + err.message);
                });
        }
        this.timeInterval = setInterval(() => {
            if (this.isRecordPause) {
                return;
            }
            this.seconds++;
            if (this.seconds == 60) {
                this.seconds = 0;
                this.minutes++;
            }
        }, 1000);
    }

    clearTimeInterval() {
        this.minutes = 0;
        this.seconds = 0;
        clearInterval(this.timeInterval);
    }

    pauseVideoRecording() {
        this.isRecordPause = true;
        this.videoRecordingService.pauseRecording();
    }

    resumeVideoRecording() {
        this.isRecordPause = false;
        this.videoRecordingService.resumeRecording();
    }

    abortVideoRecording() {
        if (this.isVideoRecording) {
            this.isVideoRecording = false;
            this.videoRecordingService.abortRecording();
            this.video.controls = false;
        }
        this.clearTimeInterval();
    }

    stopVideoRecording() {
        if (this.isVideoRecording) {
            this.videoRecordingService.stopRecording();
            this.video.srcObject = this.videoBlobUrl;
            this.isVideoRecording = false;
            this.video.controls = true;
        }
        this.clearTimeInterval();
    }

    clearVideoRecordedData() {
        this.videoBlobUrl = null;
        this.video.srcObject = null;
        this.video.controls = false;
        this.ref.detectChanges();
        this.previewVideo();
    }

    downloadVideoRecordedData() {
        this.activeModal.close(new File([new Blob([this.videoBlob])], this.videoName, { type: "video/mp4", lastModified: new Date().getTime() }));
    }

    closeModal() {
        this.videoRecordingService.stopStreamer();
        this.activeModal.dismiss();
    }
}