import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { Project } from "app/shared/interfaces/project";
import { NotificationService } from 'app/shared/services/notification.service';
import { Authority, PermissionService } from "../../../shared/services/permissions.service";

@Component({
	templateUrl: './project-invitation.component.html',
	styleUrls: ['./project-invitation.component.scss']
})
export class ProjectInvitationComponent implements OnInit {

	user: any = {};
	projects: any[] = [];
	authority = Authority;

	constructor(private router: Router,
		private breadcrumb: BreadcrumbService,
		public auth: AuthService) { }

	ngOnInit() {
		this.user = this.auth.getAuthUser();
	}

	inProgress() {
		this.router.navigate(['projects']);
	}

	projectProposals() {
		this.router.navigate(['projects', 'project-proposals']);
	}

	archivedProjects() {
		this.router.navigate(['projects', 'archived-projects']);
	}
}
