<div class="modal-header">
    <h4 class="modal-title">{{'projectType_title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row" *ngIf="isAdmin && !selectProjectType">
        <div class="col-6 p-4 text-center">
            <button class="btn mb-2" (click)="createAdminProject()">{{'projectType_admin_project' | translate }}</button>
            <div class="desc">
                {{'projectType_admin_project_description' | translate }}
            </div>
        </div>
        <div class="col-6 p-4 text-center">
            <button class="btn mb-2" (click)="switchAdminProjectSelectionMode()">{{'projectType_selection' | translate
                }}</button>
            <div class="desc">
                {{'projectType_selection_description' | translate }}
            </div>
        </div>
    </div>
    <div *ngIf="!isAdmin || selectProjectType" class="row">
        <div class="col-6 p-4 text-center">
            <button class="btn mb-2" (click)="select('one-shot')">{{'projectType_marketplace' | translate }}</button>
            <div class="desc">
                {{'projectType_marketplace_description' | translate }}
            </div>
        </div>
        <div class="col-6 p-4 text-center">
            <button class="btn mb-2" (click)="select(isAdmin ? 'adminSubscription' : 'subscription')">{{'projectType_subscription' | translate
                }}</button>
            <div class="desc">
                {{'projectType_subscription_description' | translate }}
            </div>
        </div>
    </div>
</div>