<div class="cardContainer">
	<div class="cardDetailsContainer" [ngStyle]="{'width': paymentType=='ENTRANCE_FEE' ? '550px' : '1000px'}">
		<div class="modal-header modalpading pt-1 pb-0">
			<div class="column w-100" *ngIf="isInfo || proposalAccepted">
				<h4 class="projectHeader">{{'proposalInfoTitle' | translate}} {{prodCompanyName}}</h4>
			</div>
			<div class="column w-100" *ngIf="!isInfo && !proposalAccepted">
				<h4 class="projectHeader">{{'proposalAcceptTitle' | translate}} {{prodCompanyName}}</h4>
			</div>
			<div class="column">
				<button type="button" class="close text-black p-1 pt-2" aria-label="Close" (click)="onCloseModal()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<br>
		<div class="row" style="margin-top: -15px;">
			<div class="col-7">
				<div class="cardDetails" style="margin-right: 50px; margin-top: 20px">
					<div class="subtitle">{{'paymentDetails' | translate}} "{{prodCompanyName}}"</div>
					<div class="subtitle" style="font-size: 13px;">({{'paymentToBeReviewed' | translate}}
						"{{prodCompanyName}}" {{'paymentIfNecessary' | translate}})</div>
					<div class="paymentDetails">
						<hr>
						<div>
							<p>{{'paymentTotalBudget' | translate}}</p>
							<p class="blackend">{{proposalSelected?.budget | mtmCurrency}}
								{{proposalSelected?.currency}}</p>
						</div>
						<hr>
						<div>
							<p class="childtitle">{{'downpaymentDue' | translate}} ({{downpaymentRate}}%)</p>
							<p class="end">{{downpayment | mtmCurrency}} {{proposalSelected?.currency}}</p>
						</div>
						<hr>
						<div>
							<p class="childtitle">{{'downpaymentDeadLine' | translate}}</p>
							<p class="end">
								{{ downpaymentDeadline | mtmDate}}</p>
						</div>
						<hr>
						<div>
							<p class="childtitle">{{'paymentDueDate' | translate}}</p>
							<p class="end">
								{{ dueDateDeadline | mtmDate}}</p>
						</div>
						<hr>
						<div>
							<p class="childtitle">{{'finalPaymentDeadline' | translate}}</p>
							<p class="end">
								{{ finalPaymentDeadline | mtmDate}}</p>
						</div>
						<hr>
						<div>
							<p>{{'downpaymentToPay' | translate}}</p>
							<p class="blackend">{{downpayment | mtmCurrency}} {{proposalSelected?.currency}}</p>
						</div>
						<hr>
					</div>
					<!--<p>-->
					<!--<b>Click here</b> to know how much are you saving with MTM vs Traditional systems.-->
					<!--</p>-->
					<p class="lblFont" [innerHtml]="'downpaymentText' | translate "></p>
				</div>

			</div>
			<div class="col-5">
				<div class="cardDetails" style="margin-right: 50px; margin-top: 20px">
					<div class="subtitle">{{'paymentReminder' | translate}}</div>
					<div class="paymentDetails">
						<!--hr-->
						<div class="pb-1 border-bottom">
							<p>
								<span *ngIf="!isScript">{{'mtmCommision' | translate}}</span>
								<span *ngIf="isScript">{{'mtmCommisionWithout' | translate}}</span>
							</p>
							<p class="blackend">
								<span>{{biddingAmount | mtmCurrency}}</span>

								{{proposalSelected?.currency}}
							</p>
						</div>

						<span *ngFor="let consultant of consultantOption" class="w-100">
							<div *ngIf="consultant && consultant.selected && consultant.biddingAmount"
								class="pb-1 border-bottom">
								<p class="childtitle">{{consultant.desc}}</p>
								<p class="end">{{consultant.biddingAmount | mtmCurrency}} {{proposalSelected?.currency}}
								</p>
							</div>
						</span>
						<div>
							<p>{{'mtmFee' | translate}}</p>
							<p class="blackend">{{showTotal | mtmCurrency}} {{proposalSelected?.currency}}</p>
						</div>
						<hr>
						<label class="chkLabel" [hidden]="proposalAccepted">
							<div *ngIf="isConsultantHelp">
								<div class="chkBox">
									<input type="checkbox" checked="checked" [disabled]="isInfo || proposalAccepted"
										[(ngModel)]="wantProd" (click)="changeTotal(!wantProd)">
								</div>
								<div>
									<span class="checkmark">{{'downpaymentCheckbox' | translate}}</span>
								</div>
							</div>
						</label>
					</div>


					<div class="lblFont">
						<p>{{'dueSameTime' | translate}}</p>
					</div>
				</div>
			</div>
		</div>



		<label class="col checkbox-container">
			<input #isTermsValid type="checkbox" [disabled]="proposalAccepted || isInfo" [checked]="proposalAccepted" />

			{{'downpaymentReadText' | translate}} <a href="#" (click)="onRedirectToTCP('CONTRATPRODUCTION');false;"
				style="color:#e4442a"> {{'downpaymentReadTextRed' | translate}}</a>
			“{{prodCompanyName}}“
			<span class="checkmark"></span>
		</label>
		<p *ngIf="proposalSelected?.currency === 'EUR'">{{'VATNote' | translate}}</p>
		<div style="text-align: center">
			<button
				*ngIf="(!isComingFromMailorNot && project.downPaymentPaid && project.finalPaymentPaid) || (!isInfo  && !proposalAccepted)"
				class="btn btn-payment " [disabled]="!isTermsValid.checked || isInfo" (click)="accept()">
				<div *ngIf="proposalSelected?.status == 'ACCEPTED' && isInfo">{{'Accepted' | translate}}</div>
				<div *ngIf="proposalSelected?.status == 'DECLINED' && isInfo">{{'Declined' | translate}}</div>
				<div *ngIf="!isInfo && !proposalAccepted">{{'accept' | translate}}</div>
			</button>
			<button
				*ngIf="proposalSelected?.status != 'DECLINED' && proposalAccepted && (isComingFromMailorNot || !project.downPaymentPaid || !project.finalPaymentPaid)"
				[disabled]="isInfo && project.downPaymentPaid && project.finalPaymentPaid" class="btn btn-payment "
				(click)="accept()">
				{{'goToPayment' | translate}}
			</button>
		</div>
		<loading-data #loadingRef></loading-data>
	</div>
</div>