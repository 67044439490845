import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
// import { t } from '@angular/core/src/render3';
import { DomSanitizer } from "@angular/platform-browser";

import { HelperService } from "app/shared/services/helper.service";

@Component({
	selector: 'selector-newupload-files-preview-thumpnail-component',
	templateUrl: './newupload-files-preview-thumpnail.component.html',
	styleUrls: ['./newupload-files-preview-thumpnail.component.scss']
})
export class NewUploadFilesPreviewThumpnailComponent implements OnInit, OnChanges {
	@ViewChild('mtmVideoPlayer', { static: false }) mtmVideoPlayer: ElementRef;
	@ViewChild('previewImage', { static: false }) previewImage: ElementRef;
	@ViewChild('mtmAudioPlayer', { static: true }) mtmAudioPlayer: ElementRef = null;
	@ViewChild('mtmEmbedVideo', { static: true }) mtmEmbedVideo: ElementRef;

	@Input() item: any = null;

	@Input() subTitleFile: boolean = false;

	@Input() fix: boolean = false;
	@Input() fillContainer: boolean = false;
	@Input() mode: string = 'view';

	player: any;
	videoOptions: any;

	mediaType: string = null;

	constructor(private sanitizer: DomSanitizer) {
	}

	ngOnInit() {
		this.mediaType = null;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			if (changes.item) {
				this.mediaType = void 0;
				this.disposePlayerIfExist();
				setTimeout(() => {
					this.init();
				}, 0);
			}
		}
	}

	ngOnDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	}

	disposePlayerIfExist() {
		if (this.player) {
			this.player.dispose();
			this.player = void 0;
		}
	}

	private init() {
		if (this.item) {
			if (typeof this.item === 'string' || this.item instanceof String) {
				this.mediaType = 'URL';
			} else if (HelperService.isVideoFile(this.item.file))  {
				this.mediaType = 'VIDEO';
				this.initializeVideoJs();
			} else if (HelperService.isAudioFileByExtension(this.item.fileType)) {
				this.mediaType = 'AUDIO';
				setTimeout(() => {
					this.mtmAudioPlayer.nativeElement.src = this.item.localPlayPath;
					this.mtmAudioPlayer.nativeElement.load();
				}, 2500)
			} else if ((this.item.file && HelperService.isImageFile(this.item.file)) || (!this.item.file && HelperService.isImageFileByExtension(this.item.fileType))) {
				this.mediaType = 'IMAGE';
				this.setImageUrl(this.item.localPlayPath);
			} else {
				this.mediaType = 'IMAGE';
				this.setImageUrl('assets/img/icons/file.svg');
			}
		} else {
			this.mediaType = 'IMAGE';
			this.setImageUrl('assets/img/icons/file.svg');
		}
	}

	private initializeVideoJs() {
		setTimeout(() => {
			if (this.mtmVideoPlayer) {
				this.player = videojs(this.mtmVideoPlayer.nativeElement, {
					autoplay: false,
					fluid: true,
					preload: 'auto',
				}, () => {
					this.setVideoSource();
				});
			}
		}, 0);
	}

	private setVideoSource() {
		let source = this.sanitizer.bypassSecurityTrustUrl(this.item.localPlayPath) as any;
		let type = this.getVideoType();
		if (source && type) {
			this.player.src([{ src: source.changingThisBreaksApplicationSecurity, type: type }]);
		}
	}

	private getVideoType() {
		let fileType: string;
		if (this.item.publicLink && this.isFileExtension(this.item.publicLink, 'mp4') || (this.item.name && this.isFileExtension(this.item.name, 'mp4'))) {
			fileType = 'video/mp4';
		}
		else if (this.item.publicLink && this.isFileExtension(this.item.publicLink, 'mkv') || (this.item.name && this.isFileExtension(this.item.name, 'mkv'))) {
			fileType = 'video/mp4';
		}
		else if (this.item.publicLink && this.isFileExtension(this.item.publicLink, 'mov') || (this.item.name && this.isFileExtension(this.item.name, 'mov'))) {
			fileType = 'video/mp4';
		}
		else if (this.item.publicLink && this.isFileExtension(this.item.publicLink, 'webm') || (this.item.name && this.isFileExtension(this.item.name, 'webm'))) {
			fileType = 'video/webm';
		}
		else if (this.item.publicLink && this.isFileExtension(this.item.publicLink, 'flv') || (this.item.name && this.isFileExtension(this.item.name, 'flv'))) {
			fileType = 'video/x-flv';
		}
		return fileType;
	}

	private setImageUrl(url) {
		setTimeout(() => {
			this.previewImage.nativeElement.src = url;
		}, 500);
	}

	private isFileExtension(filename, extension) {
		return HelperService.isFileExtension(filename, extension);
	}
}
