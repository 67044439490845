import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from "app/shared/services/project.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../../../../shared/services/user.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	selector: 'mtm-reinvite-user-project',
	templateUrl: './reinvite-user-project.component.html'
})
export class ReinviteUserProjectComponent implements OnInit, OnDestroy {

	public user: any;
	public projeID;
	ngUnsubscribe = new Subject();

	constructor(public userService: UserService, public service: ProjectService, public activeModal: NgbActiveModal) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
	}

	mthdReInvite(username, roles): void {
		this.service.mthdReInvite(this.projeID, username).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.service.project.users = res.users;
		},
			(err: any) => {
				console.log(err);
			}
		);

		this.activeModal.close(true);
	}

}
