import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ProjectService } from "../services/project.service";
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentGuard implements CanActivate {

	project: any;
	projectId: any;


	constructor(private route: Router,
		private projectService: ProjectService, 
		private authService: AuthService) {
	}

	/**
	 * Validate if user pay Enterence Fee If not, redirect to projects page
	 * @param route
	 * @param state
	 * @returns {boolean}
	 */
	public canActivate(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot):
		Observable<boolean> | Promise<boolean> | boolean {
		return new Promise<boolean>(async (resolve, reject) => {
			this.projectId = route.parent.params["projectId"];

			this.project = this.projectService.getProjectFromCache(this.projectId);
			if (!this.project) {
				this.projectService.getProject(this.projectId).subscribe(async (project) => {
					this.project = project;
					if (this.checkProject()) {
						resolve(true);
						await this.projectService.navigateCompanyToProfileSuggestionAfterProjectInit(this.projectId, this.route).toPromise();
						return;
					}
					resolve(true);
				}, () => {
					this.route.navigate(['/projects']);
					resolve(false);
				});
			} else {
				if (this.checkProject()) {
					resolve(true);
					await this.projectService.navigateCompanyToProfileSuggestionAfterProjectInit(this.projectId, this.route).toPromise();
					return;
				}
				resolve(true);
			}
		})
	}

	checkProject(): boolean {
		const subscription = this.authService.getAuthUserSubscription();

		return this.project.entranceFeePaid == false && subscription?.planType != 'ENTERPRISE' && this.project.projectType == 'ONE_SHOT' ;
	}
}
