import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { SubscriptionService } from "../../../services/subscription.service";
import {
	CostItem,
	PlanAddOn,
	PlanAddOnTypes,
	PlanInfo
} from "../../../models/plan";
import { Subject } from "rxjs";
import { BILLING_TYPE, PLANS, getBillingPeriodMultiplier } from "../../../models/const";
import { SubscriptionPlanService } from "../../../services/plan.service";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { NotificationService } from "app/shared/services/notification.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";

@Component({
	selector: 'mtm-subscription-register-confirm-plan',
	templateUrl: './confirm-plan.component.html',
	styleUrls: ['./confirm-plan.component.scss']
})
export class SubscriptionRegisterConfirmPlanComponent implements OnInit, OnChanges, OnDestroy {

	subscriptionService: SubscriptionService;
	private ngUnsubscribe = new Subject();
	plans: PlanInfo[] = [];
	addons: any[];
	planName: string = PLANS.STANDARD;
	currentPlan: PlanInfo = null;
	userAddOn: PlanAddOn = null;
	activeStorageAddOn: PlanAddOn = null;
	archivalStorageAddOn: PlanAddOn = null;
	planCost: CostItem = null;
	totalPlanCost: number = 0;
	selectedPeriod: string = BILLING_TYPE.MONTHLY;
	userCost: CostItem = null;
	activeStorageCost: CostItem = null;
	archivalStorageCost: CostItem = null;
	userCount: number = 0;
	activeStorageCount: number = 0;
	archivalStorageCount: number = 0;
	totalUserCost: number = 0;
	totalActiveStorageCost: number = 0;
	totalArchivalStorageCost: number = 0;
	totalUserCostPerMonth: number = 0;
	totalActiveStorageCostPerMonth: number = 0;
	totalArchivalStorageCostPerMonth: number = 0;
	totalAddOnCost: number = 0;
	totalPayment: number = 0;
	isFree: boolean = false;

	authUser: any;

	constructor(
		private route: Router,
		private _subscriptionService: SubscriptionService,
		private planService: SubscriptionPlanService,
		private authService: AuthService,
		private notificationService: NotificationService,
		private translatePipe: TranslatePipe,
	) {
		this.subscriptionService = _subscriptionService;
	}

	ngOnInit() {
		this.subscriptionService.planSelected = true;
		this.subscriptionService.registrationState = 3;
		if (this.subscriptionService.registerData) {
			if (this.subscriptionService.registerData.plan)
				this.planName = this.subscriptionService.registerData.plan;
			if (this.subscriptionService.registerData.billingType)
				this.selectedPeriod = this.subscriptionService.registerData.billingType;
		}
		this.isFree = this.planName == PLANS.FREE;
		this.authUser = this.authService.getAuthUserSettings() || {};
		if (!this.subscriptionService.plans.length) {
			this.planService.getPlans()
				.subscribe((res) => {
					this.subscriptionService.plans = res.plans.sort((a: any, b: any) => { return a.order - b.order });
					this.plans = this.subscriptionService.plans;
					this.addons = res.addon;
					this.initPlan();
				}, err => console.error(err));
		} else {
			this.plans = this.subscriptionService.plans;
			this.addons = this.subscriptionService.addons;
			this.initPlan();
		}
	}

	initPlan() {
		this.currentPlan = this.plans.find(p => p.code == this.planName);
		this.selectedPeriod = this.subscriptionService.registerData.billingType;
		this.planCost = this.currentPlan.basePrice[this.selectedPeriod];
		this.userAddOn = this.currentPlan.basePrice[this.selectedPeriod];
		this.activeStorageAddOn = this.addons.find(addon => addon.code === 'ACTIVE_STORAGE_PLAN');
		this.archivalStorageAddOn = this.addons.find(addon => addon.code === 'ARCHIVE_STORAGE_PLAN');
		this.activeStorageCost = this.activeStorageAddOn.basePrice[this.selectedPeriod] || this.currentPlan.activeStorage;
		this.archivalStorageCost = this.archivalStorageAddOn.basePrice[this.selectedPeriod] || this.currentPlan.archivalStorage;
		this.planCost = this.currentPlan.basePrice[this.selectedPeriod] || this.currentPlan.basePrice[BILLING_TYPE.MONTHLY];
		this.userCost = this.currentPlan.basePrice[this.selectedPeriod] || this.currentPlan.basePrice[BILLING_TYPE.MONTHLY];
		this.changePeriod(this.selectedPeriod);
	}

	changePeriod(period: string) {
		this.selectedPeriod = period;
		this.planCost = this.currentPlan.basePrice[this.selectedPeriod] || this.currentPlan.basePrice[BILLING_TYPE.MONTHLY];
		this.userCost = this.currentPlan.basePrice[this.selectedPeriod] || this.currentPlan.basePrice[BILLING_TYPE.MONTHLY];
		this.activeStorageCost = this.activeStorageAddOn.basePrice[this.selectedPeriod] || this.currentPlan.activeStorage;
		this.archivalStorageCost = this.archivalStorageAddOn.basePrice[this.selectedPeriod] || this.currentPlan.archivalStorage;
		this.updateTotalCost(PlanAddOnTypes.Users);
		this.updateTotalCost(PlanAddOnTypes.ActiveStorage);
		this.updateTotalCost(PlanAddOnTypes.ArchivalStorage);
	}

	get AddOnTypes(): typeof PlanAddOnTypes {
		return PlanAddOnTypes;
	}

	get BillingType() {
		return BILLING_TYPE;
	}

	increment(type: PlanAddOnTypes) {
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount < this.currentPlan.maxUsers - this.currentPlan.minUsers) {
					this.userCount++;
				}
				break;
			case PlanAddOnTypes.ActiveStorage:
				this.activeStorageCount++;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				this.archivalStorageCount++;
				break;
		}
		this.updateTotalCost(type);
	}

	decrement(type: PlanAddOnTypes) {
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount > 0)
					this.userCount--;
				break;
			case PlanAddOnTypes.ActiveStorage:
				if (this.activeStorageCount > 0)
					this.activeStorageCount--;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				if (this.archivalStorageCount > 0)
					this.archivalStorageCount--;
				break;
		}
		this.updateTotalCost(type);
	}

	resetField(action: () => void) {
		setTimeout(() => action(), 100);
	}

	updateTotalCost(type: PlanAddOnTypes) {
		const multiplier = getBillingPeriodMultiplier(this.selectedPeriod);
		switch (type) {
			case PlanAddOnTypes.Users:
				if (this.userCount < 0) {
					this.resetField(() => { this.userCount = 0; this.updateTotalCost(PlanAddOnTypes.Users); });
				}
				if (this.userCount > (this.currentPlan.maxUsers - this.currentPlan.minUsers)) {
					this.resetField(() => { this.userCount = this.currentPlan.maxUsers - this.currentPlan.minUsers; this.updateTotalCost(PlanAddOnTypes.Users); });
				}

				this.totalUserCostPerMonth = this.userCount * this.userCost.perMonthValue;
				this.totalUserCost = this.totalUserCostPerMonth * multiplier;
				break;
			case PlanAddOnTypes.ActiveStorage:
				if (this.activeStorageCount < 0) {
					this.resetField(() => { this.activeStorageCount = 0; this.updateTotalCost(PlanAddOnTypes.ActiveStorage); });
				}

				this.totalActiveStorageCostPerMonth = this.activeStorageCount * this.activeStorageCost.perMonthValue;
				this.totalActiveStorageCost = this.totalActiveStorageCostPerMonth * multiplier;
				break;
			case PlanAddOnTypes.ArchivalStorage:
				if (this.archivalStorageCount < 0) {
					this.resetField(() => { this.archivalStorageCount = 0; this.updateTotalCost(PlanAddOnTypes.ArchivalStorage); });
				}

				this.totalArchivalStorageCostPerMonth = this.archivalStorageCount * this.archivalStorageCost.perMonthValue;
				this.totalArchivalStorageCost = this.totalArchivalStorageCostPerMonth * multiplier;
				break;
		}
		this.totalPlanCost = this.planCost.value * this.currentPlan.minUsers;
		this.totalAddOnCost = this.totalUserCost + this.totalActiveStorageCost + this.totalArchivalStorageCost;
		this.totalPayment = this.totalPlanCost + this.totalAddOnCost;
		this.updateData();
	}

	updateData() {
		const model = this.subscriptionService.registerData;
		model.billingType = this.selectedPeriod;
		model.billingInfo.extraUserCount = this.userCount;
		model.billingInfo.extraActiveStorageCount = this.activeStorageCount;
		model.billingInfo.extraArchivalStorageCount = this.archivalStorageCount;
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnChanges(changes: SimpleChanges) { }

	validate(): boolean {
		if (this.userCount < 0 || this.userCount > (this.currentPlan.maxUsers - this.currentPlan.minUsers)
			|| this.activeStorageCount < 0 || this.archivalStorageCount < 0) {
			return false;
		}

		return true;
	}

	goToNextPage() {
		if (!this.validate()) {
			this.notificationService.open({
				title: this.translatePipe.transform('error'),
				description: this.translatePipe.transform('subscription_register_confirm_plan_verifyAddOn'),
				confirmBtn: this.translatePipe.transform('accept')
			});
			return;
		}

		const plan = this.subscriptionService.registerData.plan;
		this.subscriptionService.planConfirmed = true;
		if (plan === PLANS.FREE) {
			this.subscriptionService.subscribeFreePlan()
				.subscribe((res: any) => {
					if (res.subscription === 'ok') {
						this.subscriptionService.paymentSuccessful = true;
						this.route.navigate(['/subscription/register/produce']);
					}
				}, err => {
					console.log(err);
				});
		} else {
			this.route.navigate(['/subscription/register/payment']);
		}
	}

}
