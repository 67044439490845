import {
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	Output
} from "@angular/core";

@Directive({ selector: "[mtmDatePickerDirective]" })
export class MTMDatePickerDirective {
	@Input() mtmDatePickerDirective: any;
	@Output() blur = new EventEmitter<any>();

	private isMouseLeave = true;

	constructor(public elementRef: ElementRef) { }

	ngOnInit() {
		this.init();
	}

	private setToIsMouseLeave(status) {
		this.isMouseLeave = status;
	}
	private init() {
		window.addEventListener('click', () => setTimeout(() => this.process(), 100));
		this.elementRef.nativeElement.onclick = () => this.setToIsMouseLeave(false);
		this.elementRef.nativeElement.parentElement.onmouseover = () => this.setToIsMouseLeave(false);
		this.elementRef.nativeElement.parentElement.onmouseleave = () => this.setToIsMouseLeave(true);
	}

	private getStateOfNgbDatePicker() {
		let status = { state: false }
		this.findNgbDatePicker(this.elementRef.nativeElement.parentElement, status);
		return status.state;
	}

	private process() {
		if (this.isMouseLeave && this.getStateOfNgbDatePicker()) {
			this.mtmDatePickerDirective.close();
		} else {
			if (!this.isMouseLeave) {
				this.blur.emit();
			}
		}
	}

	private findNgbDatePicker(parent, status) {
		if (parent.nodeName == "NGB-DATEPICKER")
			status.state = true;

		for (let index = 0; index < parent.children.length; index++)
			this.findNgbDatePicker(parent.children[index], status);
	}
}
