<div class="cardContainer">
	<div class="cardDetailsContainer" style="height: 750px;" [ngStyle]="{'width': '1000px'}">
		<div class="modal-header modalpading pt-1 pb-0">
			<div class="column w-100">
				<h4 class="projectHeader">BEFORE VIEWING OR DOWNLOADING THE BRIEF/DOCUMENTS,
					PLEASE READ & ACCEPT THE FOLLOWING LEGALLY BINDING NON-DISCLOSURE AGREEMENT</h4>
			</div>
			<div class="column">
				<button type="button" class="close text-black p-1 pt-2" aria-label="Close" (click)="onCloseModal()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<br>
		<div class="form-control" formControlName="review" rows="5"
			style="overflow-x: auto;height: calc(36.5em + 1.5rem + 2px);">
			<meta content="text/html; charset=UTF-8" http-equiv="content-type">
			<style type="text/css">
				.lst-kix_xwjj8fm7iy25-3>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-3
				}

				ol.lst-kix_xwjj8fm7iy25-5.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-5 0
				}

				.lst-kix_xwjj8fm7iy25-0>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-0
				}

				.lst-kix_xwjj8fm7iy25-6>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-6
				}

				.lst-kix_xwjj8fm7iy25-0>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-0, decimal) ". "
				}

				ol.lst-kix_xwjj8fm7iy25-0.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-0 0
				}

				ol.lst-kix_xwjj8fm7iy25-3.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-3 0
				}

				ol.lst-kix_xwjj8fm7iy25-8.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-8 0
				}

				.lst-kix_xwjj8fm7iy25-6>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-6, decimal) ". "
				}

				.lst-kix_xwjj8fm7iy25-8>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-8
				}

				.lst-kix_xwjj8fm7iy25-7>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-7, lower-latin) ". "
				}

				.lst-kix_xwjj8fm7iy25-8>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-8, lower-roman) ". "
				}

				.lst-kix_xwjj8fm7iy25-2>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-2
				}

				ol.lst-kix_xwjj8fm7iy25-1.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-1 0
				}

				.lst-kix_xwjj8fm7iy25-5>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-5
				}

				ol.lst-kix_xwjj8fm7iy25-6.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-6 0
				}

				.lst-kix_xwjj8fm7iy25-4>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-4
				}

				.lst-kix_xwjj8fm7iy25-1>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-1
				}

				ol.lst-kix_xwjj8fm7iy25-4.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-4 0
				}

				ol.lst-kix_xwjj8fm7iy25-3 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-4 {
					list-style-type: none
				}

				.lst-kix_xwjj8fm7iy25-5>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-5, lower-roman) ". "
				}

				ol.lst-kix_xwjj8fm7iy25-1 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-2 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-2.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-2 0
				}

				.lst-kix_xwjj8fm7iy25-4>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-4, lower-latin) ". "
				}

				ol.lst-kix_xwjj8fm7iy25-0 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-7.start {
					counter-reset: lst-ctn-kix_xwjj8fm7iy25-7 0
				}

				.lst-kix_xwjj8fm7iy25-7>li {
					counter-increment: lst-ctn-kix_xwjj8fm7iy25-7
				}

				.lst-kix_xwjj8fm7iy25-2>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-2, lower-roman) ". "
				}

				.lst-kix_xwjj8fm7iy25-1>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-1, lower-latin) ". "
				}

				.lst-kix_xwjj8fm7iy25-3>li:before {
					content: "" counter(lst-ctn-kix_xwjj8fm7iy25-3, decimal) ". "
				}

				ol.lst-kix_xwjj8fm7iy25-7 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-8 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-5 {
					list-style-type: none
				}

				ol.lst-kix_xwjj8fm7iy25-6 {
					list-style-type: none
				}

				ol {
					margin: 0;
					padding: 0
				}

				table td,
				table th {
					padding: 0
				}

				.c0 {
					color: #000000;
					font-weight: 400;
					text-decoration: none;
					vertical-align: baseline;
					font-size: 11pt;
					font-family: "Times New Roman";
					font-style: normal
				}

				.c13 {
					padding-top: 0pt;
					padding-bottom: 0pt;
					line-height: 1.15;
					orphans: 2;
					widows: 2;
					text-align: center
				}

				.c5 {
					padding-top: 0pt;
					padding-bottom: 0pt;
					line-height: 1.15;
					orphans: 2;
					widows: 2;
					text-align: justify
				}

				.c4 {
					color: #000000;
					text-decoration: none;
					vertical-align: baseline;
					font-size: 20pt;
					font-style: normal
				}

				.c3 {
					color: #000000;
					text-decoration: none;
					vertical-align: baseline;
					font-size: 11pt;
					font-style: normal
				}

				.c9 {
					background-color: #ffffff;
					max-width: 451.4pt;
					padding: 35.4pt 72pt 0pt 72pt
				}

				.c10 {
					-webkit-text-decoration-skip: none;
					text-decoration: underline;
					text-decoration-skip-ink: none
				}

				.c11 {
					background-color: #ffffff;
					color: #212121
				}

				.c2 {
					font-weight: 400;
					font-family: "Times New Roman"
				}

				.c1 {
					font-weight: 700;
					font-family: "Times New Roman"
				}

				.c8 {
					height: 11pt
				}

				.c12 {
					font-size: 20pt
				}

				.c6 {
					font-style: italic
				}

				.c7 {
					font-size: 13pt
				}

				.title {
					padding-top: 0pt;
					color: #000000;
					font-size: 26pt;
					padding-bottom: 3pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				.subtitle {
					padding-top: 0pt;
					color: #666666;
					font-size: 15pt;
					padding-bottom: 16pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				li {
					color: #000000;
					font-size: 11pt;
					font-family: "Arial"
				}

				p {
					margin: 0;
					color: #000000;
					font-size: 11pt;
					font-family: "Arial"
				}

				h1 {
					padding-top: 20pt;
					color: #000000;
					font-size: 20pt;
					padding-bottom: 6pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				h2 {
					padding-top: 18pt;
					color: #000000;
					font-size: 16pt;
					padding-bottom: 6pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				h3 {
					padding-top: 16pt;
					color: #434343;
					font-size: 14pt;
					padding-bottom: 4pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				h4 {
					padding-top: 14pt;
					color: #666666;
					font-size: 12pt;
					padding-bottom: 4pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				h5 {
					padding-top: 12pt;
					color: #666666;
					font-size: 11pt;
					padding-bottom: 4pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					orphans: 2;
					widows: 2;
					text-align: left
				}

				h6 {
					padding-top: 12pt;
					color: #666666;
					font-size: 11pt;
					padding-bottom: 4pt;
					font-family: "Arial";
					line-height: 1.15;
					page-break-after: avoid;
					font-style: italic;
					orphans: 2;
					widows: 2;
					text-align: left
				}
			</style>
			<p class="c13"><span class="c10 c1 c12">NON-DISCLOSURE AGREEMENT</span></p>
			<p class="c5"><span class="c2"><br><br>This Non-Disclosure Agreement (&ldquo;Agreement&rdquo;) is made as of
					&ldquo;</span><span class="c2 c6">{{ startDate | mtmDateTime}}</span><span
					class="c2">&rdquo; </span><span class="c2 c6"></span><span class="c2">&nbsp;between </span><span
					class="c2 c6">&ldquo;{{this.brandName}}&ldquo;</span><span class="c2 c6 c7">&nbsp;</span><span
					class="c2">and </span><span class="c2 c6">&ldquo;{{this.companyName}}&ldquo;</span><span class="c2">
					for the project named &ldquo;</span><span class="c2 c6">{{this.projectName}}</span><span
					class="c0">&rdquo; on Master The Monster.</span></p>
			<p class="c5 c8"><span class="c0"></span></p>
			<p class="c5"><span class="c1">RECITATIONS </span></p>
			<p class="c5"><span class="c0">Both parties are conducting or intend to conduct discussions to explore and
					evaluate a potential business relationship or transaction. In connection therewith, each party may
					disclose to the other certain confidential and proprietary information. Accordingly, prior to such
					disclosure, the parties wish to enter into an agreement to protect such information.</span>
			</p>
			<p class="c5 c8"><span class="c0"></span></p>
			<p class="c5"><span class="c1 c3">1. Confidential Information</span></p>
			<p class="c5"><span class="c0">&ldquo;Confidential Information&rdquo; means any or all of the documents and
					information, regardless of their nature, whether strategic, financial, commercial, technical or
					creatives not publicly disclosed, in which one of the parties could have taken notice, in any
					format, including oral or during meetings.</span>
			</p>
			<p class="c5 c8"><span class="c0"></span></p>
			<p class="c5"><span class="c3 c1">2. Obligations of the producer</span></p>
			<p class="c5"><span class="c2">The producer </span><span class="c2">commit, on the one hand not to disclose
					or communicate to a third party any confidential information, and on the other hand, not to use any
					confidential information in another purpose than to refer to the Bidding Process and / or to the
					project on the collaborative tool of the MTM platform. Producer also </span><span
					class="c2">acknowledges that the provisions included in this agreement establish a performance of
					obligation. As a result, non-compliance, for any reason whatsoever, any of these provisions would
					expose them to an action for damages.</span>
			</p>
			<p class="c5 c8"><span class="c0"></span></p>
			<p class="c5"><span class="c3 c1">3. Property</span></p>
			<p class="c5"><span class="c2">Confidential information, regardless of their format and nature, given by the
					brand stays its exclusive property. The producer commit to return to the brand all documents, of
					whatever nature and whatever their form, communicated and not to keep any copy or
					reproduction.</span>
			</p>
			<p class="c5 c8"><span class="c0"></span></p>
			<p class="c5"><span class="c3 c1">4. Contract Period </span></p>
			<p class="c5"><span class="c2">This agreement comes into effect starting from the day of its signatory by
					the producer. &nbsp;The obligations defined by the present article will stay in effect as long as
					the confidential information </span><span class="c2 c11">will not have fallen into the public domain
					or until there is a mutual agreement between the producer and the brand it is working with, &nbsp;to
					disclose the confidential information before.</span>
			</p>
			<p class="c5 c8"><span class="c3 c1"></span></p>
			<p class="c5"><span class="c1">5. Governing Law</span></p>
			<p class="c5"><span class="c2">This Agreement shall be governed by &nbsp;French law (without regard to its
					conflict of law principles).</span>
			</p>
		</div>

		<div class="text-center mt-3">
			<label class="w-100 text-left" style="color: #e44a27">
				<input style="transform: scale(1.25);" [(ngModel)]="isTermsCondition" type="checkbox"
					data-toggle="toggle" class="mr-3" [ngModelOptions]="{standalone: true}">

				I have read and I accept the above terms & conditions.

			</label>
		</div>


		<div style="text-align: center">
			<button class="btn btn-payment " [disabled]="!isTermsCondition" (click)="accept()">
				Accept
			</button>
		</div>
		<loading-data #loadingRef></loading-data>
	</div>
</div>