import * as _ from 'lodash';
import { AfterViewInit, Component, DoCheck, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BroadcastService } from 'app/shared/services/broadcast.service';
import { EmitterService } from '../../shared/services/emitter.service';
import { AuthService, User } from "../../shared/services/auth.service";
import { NotificationService } from '../../shared/services/notification.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VideoChatInviteComponent } from "app/layouts/video-chat/video-chat-invite/video-chat-invite.component";
import {
	onLeaveFromProject,
	onLoadProject,
	onPermissionsAppliedToProject,
	ProjectService
} from "app/shared/services/project.service";

import { Authority, PermissionService } from "../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ProjectImageUpdatedEvent, UnseenTotal } from "../../shared/interfaces";
import { UnseenNotifierService } from "../../shared/services/unseen-notifier.service";
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { ActiveWorkspaceUpdatedEvent, ProjectWorkspaceUpdatedEvent, WorkspaceDeleted, WorkspaceUpdatedEvent } from 'app/shared/interfaces/workspace.interface';
import { CompanyService } from 'app/shared/services/company.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { ShepherdService } from 'angular-shepherd';
import { UserTourService } from 'app/shared/services/user-tour.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';
import { isMissionComplete } from 'app/shared/services/annotation.service';
import { KanbanProjectService } from 'app/kanban/project/state/project/project.service';


@Component({
	selector: 'mtm-menubar',
	templateUrl: './menubar.component.html',
	styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
	@ViewChild('manageAccountMenu') manageAccountMenu: ElementRef;
	@ViewChild('userMenu') userMenu: ElementRef;

	authUser: User;
	activeUserMenu: boolean = false;
	isUserHover: boolean = false;
	activeProjectsMenu: boolean = false;
	activeMainOptionsMenu: boolean = false;
	projects: any[] = null;
	isVideoRoomCreating: boolean = false;
	project = null;
	displayAvatar: boolean = true;
	stateFilesUploading: boolean = false;
	displayCompanyProfileMenuItem: boolean = false;
	authority = Authority;
	initState: any;
	ngUnsubscribe = new Subject();
	userInfo: any;
	hasUnseenNotifications = false;
	unseenWsSubscription: Subscription;
	workspaces: any[] = [];
	myWorkspaces: any[] = [];
	otherWorkspaces: any[] = [];
	activeWorkspace: any = null;
	showAllWorkspaces: boolean = false;
	publicReviewLink: boolean = false;
	subscription: any;
	isProductionCompany: boolean = false;
	inMarketplaceWorkspace: boolean = false;
	hasWorkspaceSettings: Record<string, boolean> = {};
	displayName: string;
	activeManageCompanyMenu: boolean = false;
	cacheBuster: string;
	projectCacheBuster: string;
	marketplaceAvatar: string;
	isReviewLinkUser: boolean = false;
	isPaidSubscriber: boolean = false;
	canCreateWorkspace: boolean = false;
	hasMarketplaceAccess: boolean = false;
	isSelectedWorkspace: boolean = false;
	hasSubtitlesAccess: boolean = false;
	subtitleUrlPath: any[] = [];
	accordionState: any = {
		'other': false,
		'my': true
	};
	selectedWorkspaceId: string;
	isLoreal: boolean = false;
	hasLtgPermission: boolean = false;

	constructor(private authService: AuthService,
		private router: Router,
		private notificationService: NotificationService,
		private broadcastService: BroadcastService,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private projectService: ProjectService,
		private permissionService: PermissionService,
		private unseenNotifier: UnseenNotifierService,
		private workspaceService: WorkspaceService,
		private companyService: CompanyService,
		private cryptoService: CryptoService,
		private analyticService: AnalyticsService,
		private timelineService: TimelineService,
		private shepherdService: ShepherdService,
		private userTourService: UserTourService,
        public stService: SelfTrainerService,
		private kanbanService:KanbanProjectService,
	) {
		// super();

		this.publicReviewLink = this.route.snapshot.data.publicReviewLink;
		this.isPaidSubscriber = this.authService.hasPaidSubscription();

		onLeaveFromProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			() => {
				this.project = {};
				this.adjustActiveWorkspace();
				this.showAllWorkspaces = false;
			}
		)
	}

	ngDoCheck(): void {
		if (!this.manageAccountMenu || !this.userMenu) {
			return;
		}
		const gap = this.userMenu.nativeElement.getBoundingClientRect().height - this.manageAccountMenu.nativeElement.getBoundingClientRect().height;
		if (gap >= 0) {
			this.manageAccountMenu.nativeElement.style.top = '0';
		} else {
			this.manageAccountMenu.nativeElement.style.top = gap + 'px';
		}
	}

	ngOnDestroy(): void {
		if (this.unseenWsSubscription)
			this.unseenWsSubscription.unsubscribe();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	setUserAccess() {
		if (this.project && this.authUser) {
			if (this.permissionService.hasAuthority(Authority.Z, null)) {
				return;
			}
			let participants = this.project.participants;
			this.userInfo = _.find(participants, { username: this.authUser.username }) as any;
			if (this.userInfo) {
				this.initState = this.permissionService.hasAuthority(Authority.U) ? 'company' : 'stages';
			}
		}
	}

	isElieAccount: boolean = false;
	ngOnInit() {
		this.hasLtgPermission = JSON.parse(localStorage.getItem('hasLtgPermission'));
		const self = this;
		this.workspaceService.currentStatus.subscribe(val => this.isSelectedWorkspace = val);
		this.workspaceService.workspaceId.subscribe((result: any) => {
			this.selectedWorkspaceId = result.id;
		});
		this.cacheBuster = (+ new Date()).toString();
		this.projectCacheBuster = (+ new Date()).toString();
		onLoadProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this.project = data;
			this.setUserAccess();
			this.adjustActiveWorkspace();
			this.showAllWorkspaces = false;
			this.isElieAccount = this.authUser.email == environment.aiReframingUserAccount.username;
		})

		onPermissionsAppliedToProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.checkSubtitlesAccess();
		});

		// Subscribe to user update data
		EmitterService.get('authUser.update').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((user: any) => {
			this.authUser = this.authService.getAuthUser();
			if (this.authUser) {
				this.displayName = (<any>this.authUser).fullName ? (<any>this.authUser).fullName : this.authUser.firstName;
				this.setUserAccess();
				this.isReviewLinkUser = this.authUser.globalRole == 'COMPANY_OCCASIONAL';
			}
		})

		// Subscribe to change avatar user
		EmitterService.get('authUser.changeAvatar').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this.displayAvatar = false;
			setTimeout(() => {
				this.displayAvatar = true;
			}, 200);
		})

		EmitterService.get(WorkspaceUpdatedEvent).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((workspaceToFlush) => {
			this.initWorkspaces(workspaceToFlush);
		});

		EmitterService.get('authUser.updateCompany').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.authUser = this.authService.getAuthUser();
			if (this.myWorkspaces) {
				this.myWorkspaces.forEach(workspace => {
					workspace.company.name = this.authUser.company.name;
				})
			}
		});

		EmitterService.get(ProjectWorkspaceUpdatedEvent).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			if (this.projectService.project)
				this.project = this.projectService.project;
			this.cacheBuster = (+ new Date()).toString();
			this.initWorkspaces();
		});

		EmitterService.get(ProjectImageUpdatedEvent).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.projectCacheBuster = (+ new Date()).toString();
		});

		EmitterService.get(ActiveWorkspaceUpdatedEvent).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(updates => {
			self.activeWorkspace = updates.workspace;
			self.inMarketplaceWorkspace = updates.inMarketplace;
		});

		EmitterService.get(WorkspaceDeleted).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.cacheBuster = (+ new Date()).toString();
			this.initWorkspaces();
		});


		this.authUser = this.authService.getAuthUser() || {};
		this.displayName = (<any>this.authUser).fullName ? (<any>this.authUser).fullName : this.authUser.firstName;
		this.isReviewLinkUser = this.authUser.globalRole == 'COMPANY_OCCASIONAL';
		if (this.authUser.company && this.authUser.company.companyType == 'PRODUCER') {
			this.isProductionCompany = true;
		}

		// Fetch projects from API
		this.loadProjects();
		this.initWorkspaces();
		this.canCreateWorkspace = (this.permissionService.hasAuthority(Authority.Z, null) || this.isPaidSubscriber) && this.permissionService.hasAuthority(Authority.S, null);
		this.checkSubtitlesAccess();
		if (this.hasLtgPermission) {
			this.isLoreal = true;
		}
	}

	ngAfterViewInit() {
		this.initUnseenNotification();
		this.initWebSocketUnseenNotification();
	}

	private initWebSocketUnseenNotification() {
		this.unseenWsSubscription = this.unseenNotifier
			.subscribe(data => {
				this.handleUnseenNotificationUpdate(data);
			});
	}

	private initWorkspaces(workspaceToFlush = null) {
		if (!this.authUser || !this.authUser.company)
			return;

		const allowedRoles = ['ADMIN', 'COMPANY_PRINCIPAL'];
		let projectRoles: any = localStorage.getItem('auth_user_project_roles');
		if (projectRoles) {
			projectRoles = JSON.parse(projectRoles);
		}
		this.hasWorkspaceSettings = {};
		const username = this.authUser.username;
		const availableWorkspaces = [];

		const $sources = [this.workspaceService.getWorkspaces(this.authUser.company.id),
		this.projectService.getProjects(), this.analyticService.getBrands()];

		//get company avatar for marketplace avatar
		if (this.isProductionCompany)
			$sources.push(this.companyService.getCompanyById(this.authUser.company.id));

		forkJoin([...$sources]).pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				let workspaceResult = result[0];
				this.workspaceService.setCachedProjects(result[1]);

				// filter brand workspaces if LGT permission is true
				let brandsWorkspace: any = result[2];
				if (this.hasLtgPermission && brandsWorkspace && brandsWorkspace.length) {
					brandsWorkspace = result[2][0].workspaces;
					workspaceResult = workspaceResult.filter(function (o1) {
						return brandsWorkspace.some(function (o2) {
							return o1.id === o2.id;
						});
					});
				}

				this.populateCompanyWorkspace({
					workspaceResult,
					allowedRoles,
					projectRoles,
					username,
					availableWorkspaces
				});

				this.workspaces = availableWorkspaces.sort((a, b) => a.name.localeCompare(b.name));
				this.workspaces.forEach(workspace => {
					this.hasWorkspaceSettings[workspace.id] = false;
					workspace.canEdit = false;
					workspace.company = {
						name: this.authUser.company.name
					};
					workspace.cacheBuster = this.cacheBuster;

					//admin / company principal can access settings
					if (allowedRoles.indexOf(this.authUser.globalRole) > -1) {
						this.hasWorkspaceSettings[workspace.id] = true;
						workspace.canEdit = true;
						return;
					}

					//workspace users which has owner role can access settings
					if (workspace.usernames.indexOf(username) > - 1) {
						const ownerRole = this.authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';
						if (this.authUser.globalRole == ownerRole) {
							this.hasWorkspaceSettings[workspace.id] = true;
							workspace.canEdit = true;
							return;
						}
					}
				});

				//refresh workspace image
				if (workspaceToFlush && workspaceToFlush.id) {
					const updatedWorkspace = this.workspaces.find(w => w.id == workspaceToFlush.id);
					if (updatedWorkspace)
						updatedWorkspace.cacheBuster = (+ new Date()).toString();
				}
				if (this.hasLtgPermission && brandsWorkspace && brandsWorkspace.length === 0) {
					this.myWorkspaces = [];
				} else {
					this.myWorkspaces = [...this.workspaces];
				}
				this.adjustActiveWorkspace();
				this.populateOtherWorkspaces(result[1]);

				//assign marketplace avatar with company avatar
				if (this.isProductionCompany && result.length == 3 && result[2].avatarUrl) {
					this.marketplaceAvatar = result[2].avatarUrl;
				}

				if (this.isProductionCompany && result.length == 3) {
					this.hasMarketplaceAccess = result[2].marketplaceAccess;
					this.displayCompanyProfileMenuItem = this.hasMarketplaceAccess;
				} else {
					this.hasMarketplaceAccess = false;
					this.displayCompanyProfileMenuItem = false;
				}
			});

	}

	private populateCompanyWorkspace(params: any) {
		const { workspaceResult, availableWorkspaces, allowedRoles, username, projectRoles } = params;

		workspaceResult.forEach(workspace => {
			if (this.authUser.globalRole) {
				if (allowedRoles.indexOf(this.authUser.globalRole) > -1) {
					availableWorkspaces.push(workspace);
					return;
				}
			}

			//check if user has workspace role
			if (workspace.usernames.indexOf(username) > -1) {
				availableWorkspaces.push(workspace);
				return;
			}

			if (!workspace.projectIds || !projectRoles)
				return;

			//check if user has project access
			let hasProjectAccess = false;
			for (let i = 0; i < workspace.projectIds.length; i++) {
				let projectId = workspace.projectIds[i];
				if (projectId in projectRoles) {
					if (projectRoles[projectId].length) {
						hasProjectAccess = true;
						break;
					}
				}
			}

			if (hasProjectAccess) {
				availableWorkspaces.push(workspace);
			}
		});
	}

	private populateOtherWorkspaces(projectResult: any) {
		const idSet = new Set<string>();
		const currentCompanyId = this.authUser.company.id;
		let otherWorkspaces = [];
		const otherCompanies: any = {};


		//one shot project will be populated inside marketplace workspace, not other workspaces
		if (this.isProductionCompany)
			projectResult = projectResult.filter(p => p.projectType == 'SUBSCRIPTION' || p.projectType == null);

		projectResult.filter(p => p.company && p.company.id != currentCompanyId).forEach(p => {
			idSet.add(p.workspaceId);
			const companyId = p.company.id;

			if (!otherCompanies[companyId]) {
				otherCompanies[companyId] = {
					name: p.company.name
				};
			}
		});


		if (idSet.size == 0) {
			this.workspaceService.setCachedWorkspaces(this.workspaces);
			return;
		}

		//guard check
		Array.from(idSet).forEach(id => {
			const existing = this.workspaces.find(w => w.id == id);
			if (existing)
				idSet.delete(id);
		});

		this.workspaceService.getWorkspacesByIds(currentCompanyId, Array.from(idSet))
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((workspaces: any[]) => {
				if (workspaces.length == 0)
					return;

				otherWorkspaces.push(...workspaces);
				otherWorkspaces.forEach(workspace => {
					this.hasWorkspaceSettings[workspace.id] = false;
					workspace.canEdit = false;
					const company = otherCompanies[workspace.companyId];
					workspace.company = {
						name: company ? company.name : ''
					};
				});
				otherWorkspaces = otherWorkspaces.sort((a, b) => {
					let result = a.company.name.localeCompare(b.company.name);
					if (result != 0)
						return result;
					return a.name.localeCompare(b.name);
				});
				this.otherWorkspaces = otherWorkspaces;
				this.workspaces.unshift(...otherWorkspaces);
				this.adjustActiveWorkspace();

				this.workspaceService.setCachedWorkspaces(this.workspaces);
			});
	}

	private adjustActiveWorkspace() {
		if (this.project && this.project.workspaceId) {
			const activeWorkspace = this.workspaces.find(w => w.id == this.project.workspaceId);
			if (activeWorkspace) {
				this.activeWorkspace = activeWorkspace;
				const currentWorkspace = this.workspaceService.getActiveWorkspace();
				if (!currentWorkspace || (currentWorkspace.id != this.project.workspaceId || currentWorkspace.companyId != this.project.company.id))
					this.workspaceService.setActiveWorkspace(this.activeWorkspace);
				this.showAllWorkspaces = false;
				this.inMarketplaceWorkspace = false;
			} else if (this.isProductionCompany) {
				if (this.project.company && this.project.company.id != this.authUser.company.id) {
					this.inMarketplaceWorkspace = true;
					this.showAllWorkspaces = false;
					this.workspaceService.setInMarketplace(true);
				}
			}
		}
	}

	initUnseenNotification() {
		let currentProject = '';
		let isInit = false;

		this.route.url.subscribe(urls => {
			if (urls.length == 0)
				return;

			let recheck = false;

			if (urls[0].path == 'projects') {
				const { projectId = '' } = this.route.snapshot.params;
				if (currentProject != projectId) {
					recheck = true;
				}
				currentProject = projectId;
			}

			if (!recheck && isInit)
				return;

			this.hasUnseenNotifications = false;
			if (this.permissionService.hasAuthority(Authority.N, null)) {
				const obs$ = currentProject ? this.notificationService.getUnseenProjectNotificationsCount(currentProject) :
					this.notificationService.getAllUnseenNotificationsFromAPI();
				obs$.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe({
						next: (data: any) => {
							this.hasUnseenNotifications = data.unseenCount > 0;
						}
					})
			}
		});
	}

	//update notification marker based on web socket result
	private handleUnseenNotificationUpdate(data: Record<string, UnseenTotal>) {
		if (!data)
			return;

		const { projectId = '' } = this.route.snapshot.params;
		let total = 0;
		for (let key in data) {
			if (projectId && key == projectId)
				continue;
			const { unseenCount = 0, unreadCount = 0 } = data[key];
			total += unreadCount + unseenCount;
		}
		this.hasUnseenNotifications = total > 0;
	}

	onLogout() {

		// Call service to remove token from BE and clean localStorage
		this.authService.logout();

		// Emit event and redirect to Login page
		EmitterService.get('user.logout').emit();
		this.broadcastService.send({ action: 'user.logout' });
		this.router.navigate(['/entry']);

		return false;
	}


	private loadProjects() {
		this.projects = [
			{ switchProject: true }
		]
	}


	onSwitchProject(project: any) {

		// TODO Switch project when API is ready
		return false;
	}


	onOpenMainOptions(): void {
		this.activeMainOptionsMenu = !this.activeMainOptionsMenu;
		this.activeUserMenu = !this.activeUserMenu;
		this.activeProjectsMenu = !this.activeProjectsMenu;
	}


	getFullNameUser(): string {
		return (this.authUser.firstName + ' ' + this.authUser.lastName);
	}


	userHover(): void {
		this.activeUserMenu = true;
		this.isUserHover = true;
	}


	userLeave(): void {
		this.isUserHover = false;
		setTimeout(() => {
			this.invalidateUserMenu();
		}, 300);
	}

	private manageCompanyHover() {
		this.activeManageCompanyMenu = true;
		this.activeUserMenu = true;
	}

	private manageCompanyMenuHover() {
		this.activeManageCompanyMenu = true;
		this.activeUserMenu = true;
	}

	private manageCompanyMenuLeave() {
		this.activeManageCompanyMenu = false;
		this.activeUserMenu = false;
	}

	private manageCompanyLeave() {
		//this.activeManageCompanyMenu = false;
	}

	private invalidateUserMenu(): void {
		if (!this.isUserHover) {
			this.activeUserMenu = false;
			this.activeManageCompanyMenu = false;
		}
	}


	projectsHover(): void {
		this.activeProjectsMenu = true;
	}


	projectsLeave(): void {
		this.activeProjectsMenu = false;
	}


	onStartVideoCall(event) {
		// Open modal
		let videoChatInviteModalRef = this.modalService.open(VideoChatInviteComponent, {
			size: 'lg',
			windowClass: 'mtm-xl'
		});
		videoChatInviteModalRef.result.then((result) => {
		}, (reason) => {
		});

		videoChatInviteModalRef.componentInstance.projectId = this.projectService.project.id;

		return false;
	}

	isProductionOrProjectStaff() {
		if (this.userInfo && this.userInfo.roles) {
			if (this.userInfo.roles.length === 1) {
				return this.userInfo.roles.includes('PROJECT_STAFF') || this.userInfo.roles.includes('PRODUCTION_STAFF');
			} else if (this.userInfo.roles.length === 2) {
				return this.userInfo.roles.includes('PROJECT_STAFF') && this.userInfo.roles.includes('PRODUCTION_STAFF');
			}
		}
		return false;
	}

	backToWorkspaces() {
		this.stService.hideTrainerModalAndStartTask();
		this.showAllWorkspaces = true;
	}

	returnToProject() {
		this.showAllWorkspaces = false;
	}

	setWorkspace(workspace: any) {
		this.project = {};
		this.showAllWorkspaces = false;
		this.workspaceService.setActiveWorkspace(workspace);
		localStorage.setItem('workspace', workspace.id);
		localStorage.setItem('workspaceName', workspace.name);
		this.workspaceService.changeStatus(true);
		// this.router.navigate(['/projects']);
		if (this.hasLtgPermission) {
			this.isLoreal = true;
			this.router.navigate(['/workspaces']);
		} else {
			this.router.navigate(['/projects']);
		}
	}

	setMarketplaceWorkspace() {
		this.workspaceService.setInMarketplace(true);
		this.showAllWorkspaces = false;
		this.router.navigate(['/projects']);
	}

	openWorkspaceList() {
		this.stService.hideTrainerModalAndStartTask();
		this.project = {};
		this.showAllWorkspaces = true;
		this.timelineService.selectedWorkspace.next(null);
		this.workspaceService.unsetActiveWorkspace();
		// this.userTourService.tourCompleted.pipe(
		// 	takeUntil(this.ngUnsubscribe)
		//   ).subscribe((e) => {
		// 	console.log('e', e)
		// 	this.shepherdService.complete();
		//   });
		if (this.hasLtgPermission) {
			this.isLoreal = true;
			this.router.navigate(['/workspaces']);
		} else {
			this.router.navigate(['/projects']);
		}
	}

	openDriveCampaign(workspace) {
		this.timelineService.componentName.next({
			componentName: 'campaignDriveComponent',
			bannerUrl: workspace.coverUrl
		});
		this.timelineService.selectedWorkspace.next(workspace);
		this.workspaceService.setActiveWorkspace(workspace);
	}

	homePage() {
		if (this.publicReviewLink) {
			return;
		}
		let redirectUrl: string;
		if (this.hasLtgPermission) {
			this.isLoreal = true;
			redirectUrl = '/workspaces';
		} else {
			redirectUrl = '/projects';
		}
		// let redirectUrl = '/projects';
		if (this.project && this.project.id && !this.hasLtgPermission) {
			redirectUrl = '/projects' + `/${this.project.id}/dashboard`;
		}
		if (this.project && this.project.id && this.hasLtgPermission) {
			redirectUrl = '/workspaces';
		}
		this.router.navigate([redirectUrl]);
	}

	//checks if project contains subtitles subsection
	checkSubtitlesAccess() {
		const sections = this.projectService.project?.sections;

		if (!sections) {
			this.subtitleUrlPath = [];
			this.hasSubtitlesAccess = false;
			return;
		}

		const postProd = sections.find(section => section.section === 'POST_PRODUCTION');

		if (!postProd || !postProd.subsections) {
			this.subtitleUrlPath = [];
			this.hasSubtitlesAccess = false;
			return;
		}

		const subSection = postProd.subsections.find(sub => sub.subSection === 'SUBTITLES');

		if (!subSection) {
			this.subtitleUrlPath = [];
			this.hasSubtitlesAccess = false;
			return;
		}

		if (!this.permissionService.isReadAuthority(this.projectService.project.id, subSection.id)) {
			this.subtitleUrlPath = [];
			this.hasSubtitlesAccess = false;
			return;
		}

		this.subtitleUrlPath = ['/projects', this.projectService.project.id, postProd.id, subSection.id, 'grid'];
		this.hasSubtitlesAccess = true;
	}

	goToSubtitles() {
		if (!this.subtitleUrlPath.length) {
			return;
		}

		this.router.navigate(this.subtitleUrlPath);
	}

	goToAIReframingUI() {
		this.router.navigate(['./projects', this.projectService.project.id, 'ai-reframing']);
	}

	toggleAccordion(id: string) {
		this.accordionState[id] = !this.accordionState[id];
	}

	setAnalytics() {
		if (this.shepherdService.tourObject) {
			this.shepherdService.complete();
		}

		let workspaceId = localStorage.getItem('workspaceV2Id');
		console.log('workspaceId', workspaceId)
		this.router.navigate(['/workspaces/analytics'], {
			queryParams: {
				wid: workspaceId
			}
		});
	}
	
	openGamification(){
		this.userTourService.isTourStart.emit(true)
		this.router.navigate(['/workspaces/gamification/dashboard']);
	}

	setProjectId(projectId: string){
		console.log('projectId', projectId)
		this.kanbanService.setProjectId.next(projectId)
		this.router.navigate(['/kanban/board'],{queryParams:{
			projectId: projectId
		}});
	}

	// for trainer
	trainerProjectSetting(){
		this.stService.hideTrainerModalAndStartTask();
		// isMissionComplete.emit({ isComplete: true, name: 'switchToUserPermission',elemenetId:'trainerProjectSetting'});
	}

	// for trainer
	trainerDashboard(){
     this.stService.hideTrainerModalAndStartTask();
	}
}
