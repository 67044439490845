<div class="card" id="section-0">
	<div class="card-block">
		<h6 class="font-weight-bold">{{'haveScript' | translate}}</h6>
		<div class="row my-3">
			<div class="col mt-2">
				<span class="badge badge-option w-100" [class.badge-option-selected]="haveScript"
					(click)="haveScript = true">{{'yesScript' | translate}}</span>
			</div>
			<div class="col mt-2">
				<span class="badge badge-option w-100" [class.badge-option-selected]="!haveScript"
					(click)="haveScript = false">{{'noScript' | translate}}</span>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="!isOneShotProject">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(0)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="card mt-3" id="section-1" >
	<div class="card-block">
		<h6 class="font-weight-bold">{{'purpose' | translate}}</h6>
		<p class="card-text">{{'purposeSpecify' | translate}}</p>
		<p class="cart-text options text-center">
			<span *ngFor="let p of purpose" class="badge badge-option" [class.badge-option-selected]="p.selected"
				(click)="toggleOption(p)">{{ p.title | translate }}</span>
		</p>
	</div>
	<div class="card-footer" *ngIf="!isOneShotProject">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(1)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="card mt-3" id="section-2">
	<div class="card-block">
		<h6 class="font-weight-bold">{{'format' | translate}}</h6>
		<p class="card-text">{{'formatSubTitle' | translate}}</p>
		<div class="row options justify-content-center" [class.danger-border]="checkArray('format')">
			<div class="toogle-option" *ngFor="let f of format">
				<div class="badge-option text-center" [class.badge-option-selected]="f.selected"
					(click)="toggleOption(f)">{{ f.title | translate}}</div>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="!isOneShotProject">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(2)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="card mt-3" id="section-3">
	<div class="card-block">
		<h6 class="font-weight-bold">{{'technique' | translate}}</h6>
		<p class="card-text">{{'techniqueSubTitle' | translate}}</p>
		<div class="row options justify-content-center" [class.danger-border]="checkArray('technology')">
			<div class="toogle-option" *ngFor="let t of technology">
				<div class="badge-option text-center" [class.badge-option-selected]="t.selected"
					(click)="toggleOption(t)">{{ t.title | translate}}</div>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="!isOneShotProject">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(3)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="card mt-3" id="section-4">
	<div class="card-block">
		<h6 class="font-weight-bold">{{'genre' | translate}}</h6>
		<p class="card-text">{{'genreSubTitle' | translate}}</p>
		<div class="row options justify-content-center" [class.danger-border]="checkArray('style')">
			<div class="toogle-option" *ngFor="let s of style">
				<div class="badge-option text-center" [class.badge-option-selected]="s.selected"
					(click)="toggleOption(s)">{{ s.title | translate}}</div>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="!isOneShotProject">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(4)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="card mt-3" id="section-5">
	<div class="card-block">
		<h6 class="font-weight-bold mb-2">{{'targetChannels' | translate}}</h6>
		<p class="card-text m-0">{{'yourIdea' | translate}}</p>
		<p class="card-text mb-3">{{'yourIdeaChange' | translate}}</p>
		<div class="row">
			<div class="col-sm-3 d-flex align-items-center justify-content-center target-channel-icons-container mb-4"
				*ngFor="let exportChannel of exportChannels">
				<div (click)="exportChannel.selected=!exportChannel.selected"
					[ngClass]="{ 'selected': exportChannel.selected }"
					class="d-flex flex-column align-items-center justify-content-center target-channel-icon p-2 w-100  pointer">
					<img class="mb-3" width="50"
						[src]="'assets/img/icons/target-channel-icons/' + exportChannel.title.toLowerCase() + '.svg'">
					<span>{{getPlacementLabel(exportChannel.title)}}</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="continue(5)">{{'continue' |
					translate}}</button>
			</div>
		</div>
		<!-- <div class="row m-0">
			<div class="another-channel-input col-sm-12 d-flex align-items-center pr-0 pl-0">
				<span class="d-inline mr-1 ml-3">I use another channel:</span>
				<div class="flex-fill">
					<input class="form-control w-100 p-2" placeholder="Enter one or more names separated by comma..." />
				</div>
			</div>
		</div> -->
	</div>
</div>
<div class="card mt-3" id="section-6">
	<div class="card-block">
		<div class="row mrl-2">
			<div class="col-5">
				<h6 class="font-weight-bold">{{'sector' | translate}}</h6>
				<p class="card-text">{{'sectorSpecify' | translate}}</p>
				<label class="form-group row has-float-label">
					<mtm-dropdown [title]="'Please select a sector'" [emptyListInfoText]="'Loading...Please wait.'"
						[items]=companySectors [itemValueAccessor]="'id'" [itemLabelAccessor]="'name'"
						[isMultipleSelect]=false class="w-100" [refObject]="this"
						(onChangeValue)="onSelectSector($event)" [objectVariableAccessor]="'clientSector'">
					</mtm-dropdown>
				</label>
			</div>
			<div class="col-1"></div>
			<div class="col-5">
				<h6 class="font-weight-bold">{{'subsector' | translate}}</h6>
				<p class="card-text">{{'subSectorSpecify' | translate}}</p>
				<label class="form-group row has-float-label">
					<mtm-dropdown [title]="'Please select a sub sector'" [emptyListInfoText]="'Loading...Please wait.'"
						[items]=companySubSectors [itemValueAccessor]="'id'" [itemLabelAccessor]="'name'"
						[isMultipleSelect]=false class="w-100" [refObject]="this"
						[objectVariableAccessor]="'clientSubSector'">
					</mtm-dropdown>
				</label>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-lg btn-danger" (click)="onSubmit(true)">{{'continue' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>