import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SubscriptionGuard } from "./core/subscription.guard";
import { SubscriptionRegisterComponent } from "./register/layout/register.component";
import { SubscriptionUpgradeComponent } from "./upgrade/layout/upgrade.component";

export const routes: Routes = [{
	path: '',
	redirectTo: 'register',
	pathMatch: 'full',
}, {
	path: 'register',
	component: SubscriptionRegisterComponent,
	canActivate: [SubscriptionGuard],
	loadChildren: () => import('./register/register.module').then(m => m.SubscriptionRegisterModule)
},

{
	path: 'upgrade',
	component: SubscriptionUpgradeComponent,
	loadChildren: () => import('./upgrade/upgrade.module').then(m => m.SubscriptionUpgradeModule)
}

];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SubscriptionRoutingModule { }
