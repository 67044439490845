import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtm-team-files-icon',
  templateUrl: './team-files-icon.component.html',
  styleUrls: ['./team-files-icon.component.scss']
})
export class TeamFilesIconComponent implements OnInit {
  @Input() svgWidth: string = "25px";

  constructor() { }

  ngOnInit() {
  }

}
