import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'mtm-preview-all-asset',
    styleUrls: ['./preview-all-asset.component.scss'],
    templateUrl: './preview-all-asset.component.html'
})
export class PreviewAllAssetComponent implements OnInit, OnDestroy {
    @Input() asset: any;
    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        console.log("assets", this.asset);

    }
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.

    }
}
