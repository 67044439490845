<div class="row py-3">
	<div class="col-4">
		<h6 class="font-weight-bold">Name</h6>
	</div>
	<div class="col-4">
		<h6 class="font-weight-bold">Last modified</h6>
	</div>
	<div class="col-4">
		<h6 class="font-weight-bold">Actions</h6>
	</div>
</div>

<div class="row files-list-item align-items-center" *ngFor="let f of files | FilterPipe: queryString">
	<div class="col-4">
		<div class="media">
			<img *ngIf="!f.folder" [src]="f.type" height="32" width="32" class="file-icon mr-1" />
			<img *ngIf="f.folder" src="/assets/img/icons/blue_folder.svg" height="32" width="32"
				class="folder-icon mr-1" />
			<div class="media-body align-self-center ml-2">
				<h6 class="media-heading m-0" fileNameDisplay [fileName]="f?.displayName"></h6>
				<span class="font-weight-bold text-muted" *ngIf="f.folder">{{ f.files }} Files</span>
				<span class="font-weight-bold text-muted" *ngIf="f.size">{{ f.displaySize }}</span>
			</div>
		</div>
	</div>
	<div class="col-4">
		<h6 class="text-muted m-0">{{ f.uploadedDate | mtmDate }}</h6>
	</div>
	<div class="col-4">
		<div class="d-flex actions">
			<a [ngClass]="{'div-disabled': isDisableDownload}" href="#" class="text-danger mr-3"
				(click)="onDeleteFile(f)">
				<i class="fa fa-trash fa-fw" aria-hidden="true"></i>
				<span class="text-uppercase font-weight-bold">Delete</span>
			</a>
			<mtm-file-download-control [file]="f" [displayType]="'icon'" [iconSize]="24"
				[uniqueControlId]="'list-download-' + f.projectId + '-' + f.sectionId + '-' + f.subSectionId + '-' + f.id">
			</mtm-file-download-control>
			<a class="text-danger preview ml-3" (click)="openPreviewModal(previewModal)">
				<i class="fa fa-eye fa-fw" aria-hidden="true"></i>
			</a>
		</div>
		<ng-template #previewModal let-modal>
			<div class=" d-flex actions modal-header">
				<h4 class="modal-title" id="modal-basic-title">{{f?.displayName}}</h4>
				<button type="button" class="btn btn-sm btn-outline-danger" aria-label="Close"
					(click)="modal.dismiss('Cross click')">X</button>
			</div>
			<div class="modal-body ">
				<div class="w-100 modal-height">
					<mtm-media-player
						[uniqueControlId]="'list-download-' + f.projectId + '-' + f.sectionId + '-' + f.subSectionId + '-' + f.id"
						class="w-100 h-100" [mode]="'view'" [width]="'100%'" [height]="'100%'" [item]="f">
					</mtm-media-player>
				</div>
			</div>
		</ng-template>
	</div>
</div>