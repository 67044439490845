import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { environment } from "environments/environment";
import { SubscriptionService } from "../../../services/subscription.service";

@Component({
	selector: 'mtm-register-produce',
	templateUrl: './produce.component.html',
	styleUrls: ['./produce.component.scss']
})
export class SubscriptionRegisterProduceComponent implements OnInit, OnChanges, AfterViewInit {

	plan: string;
	redirectToDashboard: boolean = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private subscriptionService: SubscriptionService
	) { }

	ngOnInit() {
		this.subscriptionService.registrationState = 5;
	}

	ngOnChanges(changes: SimpleChanges) { }

	ngAfterViewInit() { }

	returnToDashboard() {
		this.redirectToDashboard = true;
		setTimeout(() => {
			this.subscriptionService.getCurrentPlan().subscribe(res => {
				this.authService.setAuthUserSubscription(res);
				this.redirectToDashboard = false;
				this.subscriptionService.init();
				this.router.navigate(['/projects']);
			})
		}, 5000);
	}

}
