import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import { OverlayService } from "app/shared/services/overlayService";
import { PaymentMethodService } from "app/shared/services/payment-method.service";
import { environment } from "environments/environment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
    styleUrls: ['./payment-method-modal.component.scss'],
    templateUrl: './payment-method-modal.component.html'
})
export class PaymentMethodModalComponent implements OnInit, OnDestroy {
    @Input() billingEmail: string;
    @Input() billingName: string;
    @Input() method: any;

    paymentType: string = 'credit-card';
    private ccStripe: any;
    private ccElements: any;
    private sepaStripe: any;
    private sepaElements: any;
    private ngUnsubscribe = new Subject();

    creditCard: any;
    creditCardExpiry: any;
    creditCardCvcNumber: any;
    creditCardPostal: any;
    cardNumberValid: boolean = false;
    cardExpiryValid: boolean = false;
    cardCvcValid: boolean = false;
    postalCodeValid: boolean = false;

    sepaIban: any;
    sepaIbanValid: boolean = false;
    acceptSepa: boolean = false;

    isBusy: boolean = false;
    euroCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];

    @ViewChild('creditCardNumber') creditCardNumber: ElementRef;
    @ViewChild('expiry') expiry: ElementRef;
    @ViewChild('cvcNumber') cvcNumber: ElementRef;
    @ViewChild('zipCode') zipCode: ElementRef;
    @ViewChild('iban') iban: ElementRef;

    constructor(private activeModal: NgbActiveModal,
        private authService: AuthService,
        private methodService: PaymentMethodService,
        private overlayService: OverlayService,
        private companyService: CompanyService,
        private translatePipe: TranslatePipe) {

    }

    ngOnInit(): void {
        this.initStripe();
    }

    private initStripe() {
        this.companyService.getCompanyById(this.authService.getAuthUser().companyId)
            .subscribe(res => {
                const currency = this.getCompanyCurrency(res);
                this.initStripeKey(currency);
                if (this.method) {
                    this.paymentType = this.method.type === 'card' ? 'credit-card' : 'sepa';
                }
                this.initStripeElements();
            });
    }

    private initStripeKey(currency: any) {
        const publishableKey = this.methodService.getPublishableKey(currency);
        this.ccStripe = Stripe(publishableKey);
        this.ccElements = this.ccStripe.elements();
        this.sepaStripe = Stripe(publishableKey);
        this.sepaElements = this.sepaStripe.elements();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    initStripeElements() {
        //credit card elements
        this.creditCard = this.ccElements.create('cardNumber');
        this.creditCard.mount(this.creditCardNumber.nativeElement);
        this.creditCard.addEventListener('change', (e) => {
            this.cardNumberValid = e.complete;
        });

        this.creditCardExpiry = this.ccElements.create('cardExpiry');
        this.creditCardExpiry.mount(this.expiry.nativeElement);
        this.creditCardExpiry.addEventListener('change', (e) => {
            this.cardExpiryValid = e.complete;
        });

        this.creditCardCvcNumber = this.ccElements.create('cardCvc');
        this.creditCardCvcNumber.mount(this.cvcNumber.nativeElement);
        this.creditCardCvcNumber.addEventListener('change', (e) => {
            this.cardCvcValid = e.complete;
        });

        this.creditCardPostal = this.ccElements.create('postalCode');
        this.creditCardPostal.mount(this.zipCode.nativeElement);
        this.creditCardPostal.addEventListener('change', (e) => {
            this.postalCodeValid = e.complete;
        });

        //sepa elements
        this.sepaIban = this.sepaElements.create('iban', {
            supportedCountries: ['SEPA'],
        });
        this.sepaIban.mount(this.iban.nativeElement);
        this.sepaIban.addEventListener('change', (e) => {
            this.sepaIbanValid = e.complete;
        });
    }

    confirm() {
        if (this.method) {
            this.isBusy = true;
            this.methodService.removePaymentMethod(this.method.id)
                .subscribe(res => {
                    this.addPayment();
                }, err => {
                    this.isBusy = false;
                    this.overlayService.showError(JSON.stringify(err));
                });
        } else {
            this.addPayment();
        }
    }

    addPayment() {
        if (this.paymentType == 'credit-card') {
            if (!this.cardNumberValid || !this.cardExpiryValid ||
                !this.cardCvcValid || !this.postalCodeValid)
                return;

            this.isBusy = true;
            this.ccStripe.createPaymentMethod({
                type: 'card',
                card: this.creditCard,
                billing_details: {
                    email: this.billingEmail
                }
            }).then(method => {
                this.processPayment(method);
            });
        }
        else {
            if (!this.sepaIbanValid || !this.acceptSepa) {
                return;
            }
            this.isBusy = true;
            this.sepaStripe.createPaymentMethod({
                type: 'sepa_debit',
                sepa_debit: this.sepaIban,
                billing_details: {
                    email: this.billingEmail,
                    name: this.billingName
                }
            }).then(method => {
                this.processPayment(method);
            });
        }
    }

    private processPayment(result: any) {
        if (!result.paymentMethod)
            return;
        this.isBusy = true;
        this.methodService.addPaymentMethod(result.paymentMethod.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isBusy = false;
                this.activeModal.close(result.paymentMethod);
                this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
            }, err => {
                this.isBusy = false;
                this.overlayService.showError(JSON.stringify(err));
            });

    }

    cancel() {
        this.activeModal.dismiss();
    }


    getCompanyCurrency(company) {
        return this.euroCountries.indexOf(company.billingAddress.country) > -1 ? 'EUR' : 'USD';
    }

}