import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectService, onLoadProject } from '../services/project.service';

@Injectable({
    providedIn: 'root'
})
export class GuardProjectSubSectionEdit implements CanActivate {

    constructor(private route: Router,
        private permissionService: PermissionService) { }

    /**
     * Validate if user is logged in. If not, redirect to Login page
     * @param route
     * @param state
     * @returns {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let projectId = route.parent.params['projectId'];
        let subsectionId = route.params['subsectionId'];

        if (this.permissionService.isWritingAuthority(projectId, subsectionId) == false) {

            if (this.permissionService.hasAuthority(Authority.R, projectId)) {
                this.permissionService.navigateSubSectionPreview(projectId, route.params["sectionId"], subsectionId);
            } else {
                this.permissionService.navigateToWorkspaceDashboard();
            }

            return false;
        }

        return true;
    }
}
