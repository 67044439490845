<div class="container proposal-step-container">
	<div class="row">
		<div class="col-sm-12">
			<div class="card-header">
				<h6 class="font-weight-bold">Production Brief</h6>
			</div>
			<div class="card-block">
				<div class="row">
					<div class="col-4">
						<div *ngIf="files.length>0" class="w-100">
							<p class="text-black font-weight-bold m-0">Briefs</p>
							<div class="col border-top border-bottom mt-1 mb-1 pt-1 pb-1">
								<div class="row justify-content-between" *ngFor="let file of files">
									<div class="col-md-2">
										<img *ngIf="!file.folder" [src]="file.type"
											onError="this.src='/assets/img/icons/file.svg'" height="56" width="46"
											class="file-icon mr-1" />
										<img *ngIf="file.folder" src="/assets/img/icons/blue_folder.svg" height="36"
											width="36" class="folder-icon mr-1" />
									</div>
									<div class="col-md-7 mrt-5">
										<h6 class="font-weight-bold wb-b " fileNameDisplay
											[fileName]="file?.displayName"></h6>
										<p class="font-weight-bold" *ngIf="file.folder">{{ file.files }} Files</p>
										<p *ngIf="file.size">{{ file.displaySize }}</p>
									</div>
									<div class="col-md-3">
										<mtm-file-download-control [file]="file" [displayType]="'icon'" [iconSize]="24"
											[uniqueControlId]="'project-proposal-brief-' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
										</mtm-file-download-control>
										<a (click)="onSwitchShowFile(file);false" href="#"
											class="align-self-center text-danger fileActionButton">
											<i *ngIf="!file.isShow" class="fa fa-eye fa-lg mt-10"
												aria-hidden="true"></i>
											<i *ngIf="file.isShow" class="fa fa-eye-slash fa-lg" aria-hidden="true"></i>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="filesB.length>0" class="w-100 mt-3">
							<p class="text-black font-weight-bold m-0">Documents</p>
							<div class="col  border-top border-bottom mt-1 mb-1 pt-1 pb-1">
								<div class="row	 justify-content-between" *ngFor="let file of filesB">
									<div class="col-md-2">
										<img *ngIf="!file.folder" [src]="file.type"
											onError="this.src='/assets/img/icons/file.svg'" height="56" width="46"
											class="file-icon mr-1" />
										<img *ngIf="file.folder" src="/assets/img/icons/blue_folder.svg" height="36"
											width="36" class="folder-icon mr-1" />
									</div>
									<div class="col-md-7 mrt-5">
										<h6 class="font-weight-bold wb-b text-truncate">{{ file.displayName }}</h6>
										<p class="font-weight-bold" *ngIf="file.folder">{{ file.files }} Files</p>
										<p *ngIf="file.size">{{ file.displaySize }}</p>
									</div>
									<div class="col-md-3">
										<mtm-file-download-control [file]="file" [displayType]="'icon'" [iconSize]="24"
											[uniqueControlId]="'project-proposal-brief-' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
										</mtm-file-download-control>
										<a (click)="onSwitchShowFile(file);false" href="#"
											class="align-self-center text-danger fileActionButton">
											<i *ngIf="!file.isShow" class="fa fa-eye fa-lg mt-10"
												aria-hidden="true"></i>
											<i *ngIf="file.isShow" class="fa fa-eye-slash fa-lg" aria-hidden="true"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col">
						<div *ngIf="selectedFile == null && isSettingDuration"
							class="w-100 h-100 d-flex justify-content-center align-items-center">
							<i class="fa fa-3x fa-spinner fa-pulse text-danger"></i>
						</div>
						<div *ngIf="selectedFile != null" class="row pdf-row">
							<mtm-media-player class="w-100 h-100" [mode]="'view'" [width]="'100%'" [height]="'100%'"
								[item]="selectedFile">
							</mtm-media-player>
						</div>
					</div>
				</div>

				<div class="row">
				</div>

				<div class="row mt-3">
					<div class="col text-right">
						<button class="btn btn-danger btn-lg" (click)="goToTeam()"><span>Go to
								Proposal Team</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>