import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/shared/services/auth.service";
import { MTMFileDownloadModel } from "../mtm-file-download.model";
import { MTMFileDownloadService, onDownloadStart } from "../mtm-file-download.service";
import { FileTransferService, TransferStatus } from "app/shared/services/signed-url";
import { MTMFileDownload } from "app/shared/services/signed-url/mtm-file-download";

@Component({
	selector: 'mtm-file-download-control',
	templateUrl: './mtm-file-download-control.component.html',
	styleUrls: ['./mtm-file-download-control.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MTMFileDownloadControl implements OnInit {
	@Input() file: any;
	@Input() displayType: any = 'compact';
	@Input() uniqueControlId: any;
	@Input() iconSize: number = 32;
	@Input() showUploadTime: boolean = false;
	@Input() lightIcon: boolean = false;
	@Input() iconColor: string = 'red';
	@Input() iconUrl: string;
	@Input() fileDownload?: boolean = true;
	authUserSettings: any;
	dateFormat: 'MMMM dd, yyyy';

	transferStatus = TransferStatus;

	fileDownloadInfo: MTMFileDownload;

	constructor(
		private elementRef: ElementRef,
		private mtmFileDownloadService: MTMFileDownloadService,
		private authService: AuthService,
		private transferService: FileTransferService
	) {
		this.authUserSettings = this.authService.getAuthUserSettings();
		if (this.authUserSettings.dateFormat) {
			this.dateFormat = this.authUserSettings.dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');
		}
	}

	ngOnInit(): void {
		this.elementRef.nativeElement.setAttribute('id', this.uniqueControlId);
	}

	downloadFile() {
		if (this.fileDownload) {
			onDownloadStart.emit();
			//this.fileDownloadInfo = new MTMFileDownloadModel(this.uniqueControlId, this.file);
			this.fileDownloadInfo = MTMFileDownload.fromFileRef(this.file);
			this.fileDownloadInfo.id = this.uniqueControlId;
			this.fileDownloadInfo.url = this.file.signedURL;
			this.transferService.downloadFile(this.fileDownloadInfo);
			//this.mtmFileDownloadService.startDownloadFile(this.fileDownloadInfo);
		}
	}
}
