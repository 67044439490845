import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';

@Component({
  selector: 'mtm-timeline-full-view',
  templateUrl: './timeline-full-view.component.html',
  styleUrls: ['./timeline-full-view.component.scss', '../timeline-style.scss']
})
export class TimelineFullViewComponent implements OnInit {

  @Input() timeLineData: any = [];
  timelines: any = [];
  @Input() isDownload?:boolean =false ;
  fullView = "Full View";
  timelineDropdown: any = ['View all'];
  mileStonePopupVisible: boolean = false;
  currentMilestone: object = null;
  timeLineMilestoneData: any = [];
  colors = {
    'CAMPAIGN': '#000000',
    'PRODUCT': '#1E43A1',
    'MAGELLAN': '#1E43A1',
    'COLLECTOR / PRODUCT': '#086289',
    'GIFT': '#923065',
    'SET': '#502B9D'
  }

  isFullView: boolean = true;
  popupExtraMargin: number = 158;
  milestonePopupMargin: number = 0;

  constructor(private router: Router,
    private timelineService:ProjectV2ServiceService) {
  }

  ngOnInit(): void {
    this.handleTimeline();
  }

  // handle timeline data
  async handleTimeline() {
    // get destructured timeline data
    this.timelines =  await this.timelineService.handleTimelineData(this.timeLineData);

    // filter milestones
    this.timeLineMilestoneData = await this.timelineService.filterMilestonesFromTimeline(this.timelines);
  }

  //toggle full timeline view
  viewFullTimeline() {
    this.isFullView = !this.isFullView;
    this.timelineService.toggleVisibility(this.isFullView);
  }

  // show popup
  showMilestonePopup(milestone) {
    const index = this.timeLineMilestoneData.indexOf(milestone);
    this.milestonePopupMargin = (1240/this.timeLineMilestoneData.length) * index;
    this.currentMilestone = milestone;
  }

  // hide popup
  hideMilestonePopup(){
    if(this.currentMilestone){
      this.currentMilestone = null;
    }
  }
}

