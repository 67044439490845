import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { HelperService } from './helper.service';

export interface IAllowedOperation {
	permission: string;
	phase: string;
	role: string;
	global: boolean;
	section: string;
	subSection: string;
	projectId: string;
	projectSectionId: string;
	projectSubSectionId: string;
}

export enum Authority {
	A, //Access to calendar (create, view & modify events) - not global scope
	B, //Unused, to be removed - not global scope
	C, //Comment - global scope
	E, //Access to Company profie (Preferences, exp, portfolio,...)  - global scope
	F, //Access user profile (Is logged in, preferences, settings...)  - global scope
	H, //Access video calls. Create group conversations & video calls. Access to 1-1 chats - not global scope
	I, //Access project invitations - global scope
	N, //Notifications - global scope
	P, //Create Regular Projects - global scope
	Q, //Create One-shot projects (marketplace projects) through website - global scope
	R, //Preview content - non global scope
	S, //Add/remove Workspace - global scope
	U, //Edit Project details. Modify Project stages. Add/remove users inside project - not global scope
	V, //Validate - not global scope
	W, //Add / upload / delete content - not global scope
	X, //Archive a project - global scope
	Z  //Administration - global scope
}

@Injectable({
	providedIn: 'root'
})
export class PermissionService {

	private projectId: string = '';
	private projectOnloaded: boolean = false;


	constructor(
		private authService: AuthService,
		private notificationService: NotificationService,
		private router: Router,
		private location: Location
	) { }

	public navigateToBackURL() {
		if (this.projectOnloaded)
			this.location.back();
		else
			this.navigateProjectDashboard(this.projectId);
	}

	public navigateSubSectionEdit(projectId, sectionId, subSectionId) {
		this.router.navigate(['projects', projectId, sectionId, subSectionId, 'grid']);
	}

	public navigateSubSectionPreview(projectId, sectionId, subSectionId) {
		this.router.navigate(['projects', projectId, sectionId, subSectionId, 'preview']);
	}

	public navigateProjectDashboard(projectId) {
		this.router.navigate(['projects', projectId, 'dashboard']);
	}

	public navigateToWorkspaceDashboard() {
		this.router.navigate(['projects']);
	}

	showAssetMissingPopup() {
		this.notificationService
			.open({
				title: "Not found",
				description: `The asset referring to the notification has been deleted.`,
				confirmBtn: "OK"
			}, true).subscribe((confirm: any) => { });

	}

	public checkPermissionAndNavigateFromPreviewToGrid(projectId, sectionId, subSectionId, list, notification?: any) {
		if ((!list || !Array.isArray(list) || list.length <= 0) && this.isWritingAuthority(projectId, subSectionId)) {
			if (!HelperService.isObjectEmpty(notification)) {
				this.showAssetMissingPopup();
			}
			this.navigateSubSectionEdit(projectId, sectionId, subSectionId);
		}
	}

	public getProjectPermissionsFromLocalStorage(projectId) {
		this.projectId = projectId;
		this.projectOnloaded = false;
	}

	public prepareSectionAndSubSections(section): void {
		try {
			this.projectOnloaded = true;
			this.authService.getAllowedOperations().forEach(operation => {
				section.subsections.forEach(ss => {
					if (operation.phase == section.phase && operation.section == section.section && operation.subSection == ss.subSection) {
						ss.isWritable = operation.permission.indexOf(Authority[Authority.W]) >= 0;
						ss.isCommentable = operation.permission.indexOf(Authority[Authority.C]) >= 0;
						ss.isReadable = operation.permission.indexOf(Authority[Authority.R]) >= 0;
					}
				});
			});
			let ss = section.subsections.filter(k => !k.isReadable);
			ss.forEach(element => {
				let index = section.subsections.findIndex(k => k.id == element.id);
				if (index >= 0)
					section.subsections.splice(index, 1);
			});
			//warning: isSectionVisibility true means not visible
			section.isSectionVisibility = section.subsections.length <= 0 || this.isSectionVisibility(this.projectId, section.subsections);
		} catch (error) {
			section.isSectionVisibility = true;
		}
	}

	private getAllowedOperation(projectId, projectSubSectionId): IAllowedOperation {
		let allowedOperations = this.authService.getAllowedOperations();
		if (allowedOperations.length == 0)
			return null;

		return allowedOperations.find(k => k.projectId == projectId && k.projectSubSectionId == projectSubSectionId);
	}

	public isWritingAuthority(projectId, projectSubSectionId): boolean {
		let allowedOperation = this.getAllowedOperation(projectId, projectSubSectionId);
		return allowedOperation ? allowedOperation.permission.indexOf(Authority[Authority.W]) >= 0 : false;
	}

	public isReadAuthority(projectId, projectSubSectionId): boolean {
		let allowedOperation = this.getAllowedOperation(projectId, projectSubSectionId);
		return allowedOperation ? allowedOperation.permission.indexOf(Authority[Authority.R]) >= 0 : false;
	}

	public isCommentableAuthority(projectId, projectSubSectionId): boolean {
		let allowedOperation = this.getAllowedOperation(projectId, projectSubSectionId);
		return allowedOperation ? allowedOperation.permission.indexOf(Authority[Authority.C]) >= 0 : false;
	}

	public isSectionVisibility(projectId, subsections: any[]): boolean {
		try {
			let counter = 0;
			for (let index = 0; index < subsections.length; index++)
				if (!subsections[index].isReadable)
					counter++;

			if (counter >= subsections.length)
				return true;
			else
				return false;
		} catch (error) {
			return true;
		}
	}

	public hasAuthority(authority: Authority, projectId: string = this.projectId, phase: string = null, section: string = null, subSection: string = null): boolean {
		let isGlobalPermission = this.isGlobalAuthority(authority);
		let isGlobalScope = projectId == null || projectId == "";
		let filteredOperations = this.authService.getAllowedOperations()
			.filter(op =>
				(op.projectId == projectId || op.global && isGlobalPermission && isGlobalScope) &&
				(phase == null || op.phase == phase) &&
				(section == null || op.section == section) &&
				(subSection == null || op.subSection == subSection)
			);
		return !!filteredOperations.find(op => op.permission.indexOf(Authority[authority]) > 0);
	}

	public isGlobalAuthority(authority: Authority) {
		return [Authority.B, Authority.E, Authority.I, Authority.F, Authority.N, Authority.P, Authority.Q, Authority.S, Authority.X, Authority.Z].indexOf(authority) >= 0;
	}
}
