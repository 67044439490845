import { ICellRendererAngularComp } from "ag-grid-angular";
import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ProjectsOwnershipTransferComponent
} from "app/shared/components/projects-ownership-transfer/projects-ownership-transfer.component";
import { ProjectService } from "app/shared/services/project.service";
import { OwnershipTransferTypes } from "app/shared/interfaces";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { AuthService } from "app/shared/services/auth.service";
import { WorkspaceService } from "app/shared/services/workspace.service";

@Component({
  templateUrl: './transfer-project-rights-cell.component.html',
  styleUrls: ['./transfer-project-rights-cell.component.scss']
})
export class TransferWorkspaceProjectsRightsCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  canTransfer: boolean = false;
  canTransferManager: boolean = false;

  constructor(private modalService: NgbModal, private projectService: ProjectService,
    private permissionService: PermissionService,
    private workspaceService: WorkspaceService,
    private authService: AuthService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.checkOwnership()
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.checkOwnership();
    return true;
  }

  private isWorkspaceOwner() {
    const authUser = this.authService.getAuthUser();
    const activeWorkspace = this.workspaceService.getActiveWorkspace();
    const ownerRole = authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';
    return activeWorkspace.usernames?.includes(authUser.username) && authUser.globalRole == ownerRole;
  }

  checkOwnership() {
    let canTransfer: boolean = false;
    let canTransferManager: boolean = false;
    const currentUser = this.params.data;
    const authUserName = this.authService.getAuthUserName();

    if (this.projectService.project?.manager === currentUser.username) {
      if (this.permissionService.hasAuthority(Authority.S, null) || this.isWorkspaceOwner() || authUserName == currentUser.username) {
        canTransferManager = true;
      }
    } else if (this.params.context.componentParent?.participants?.length) {
      const participant = this.params.context.componentParent?.participants?.find(p => p.username === currentUser.username);
      if (participant && participant.roles?.includes('PROJECT_OWNER')) {
        canTransfer = true;
      }
    }

    this.canTransfer = canTransfer;
    this.canTransferManager = canTransferManager;
  }

  transfer() {
    const modalRef = this.modalService.open(ProjectsOwnershipTransferComponent,
      { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.sourceUser = this.params.data;
    modalRef.componentInstance.projects = [this.params.context.componentParent.selectedProject];
    modalRef.componentInstance.transferType = OwnershipTransferTypes.PROJECT;
    modalRef.componentInstance.isProjectManagerTransfer = this.canTransferManager;
    modalRef.result.then((result: boolean) => {
      if (!result) {
        return;
      }

      this.params.context.componentParent.loadParticipants(this.params.context.componentParent.selectedProject);
    });
  }
}

