<div class="modal-header pt-1 pb-1">
	<h4 class="modal-title" id="modal-basic-title"></h4>
	<button type="button" class="close" aria-label="Close" (click)="closeModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
		<h5 class="text-center">{{'Add New User'|translate}}</h5>
		<div class="row mt-4">
			<div class="col">
				<label [style.position]="'relative'" [style.z-index]="'2'" [style.width]="'100%'"
					[ngClass]="{'has-danger': isValid('username')}">
					<mtm-inviter-dropdown class="flex-fill mr-1" [data]="companyUsers" [(value)]="invitedUsers"
						[internalUsersOnly]="true" (selectedInvitersChange)="selectedInvitersChange($event)"
						[iconSize]="24" [userInfo]="'nameOnly'" [inputPlaceholder]="'nameOrEmail' | translate"
						[singleInput]="true" [asClassicDropdown]="true">
					</mtm-inviter-dropdown>
					<input [hidden]="true" class="form-control" type="text" formControlName="username" placeholder=" "
						[ngClass]="{'form-control-danger': isValid('username')}">
					<span *ngIf="isValid('username')" [ngClass]="{'text-danger': isValid('username')}">Email address{{
						(isValid('username')) ? ' is
						required' : '' }}</span>
				</label>
				<label class="w-100" [style.position]="'relative'" [style.z-index]="'1'">
					<mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select Roles'" [emptyListInfoText]="''"
						[dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
						[isDangerBorderForFormInValid]=submitted [items]=formRoles [itemValueAccessor]="'id'"
						[itemLabelAccessor]="'name'" [isMultipleSelect]=true [form]=addUserForm
						[formControlAccessor]="'selectedRoles'">
					</mtm-dropdown>
					<span *ngIf="addUserForm.controls['selectedRoles'].value.length<=0 && submitted"
						class="text-danger">{{'roleRequired'|translate}}</span>
				</label>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col">
				<button class="btn btn-outline-secondary btn-lg btn-block" type="button" (click)="closeModal()">
					<span>{{'Cancel'|translate}}</span>
				</button>
			</div>
			<div class="col">
				<button class="btn btn-danger btn-lg btn-block" type="submit" [disabled]="!addUserForm.valid">
					<span>{{'Invite'|translate}}</span>
				</button>
			</div>
		</div>
	</form>
</div>