<div class="modal-header">
    <div class="modal-title">{{'workspace_edit_newWorkspaceUser' | translate }}</div>
</div>
<div class="modal-body">
    <form [formGroup]="addForm">
        <h5 class="text-center mb-4">{{'workspace_edit_addNewUser' | translate }}</h5>

        <div class="row-mt-4">
            <div class="col">
                <div class="mb-2" [class.invalid]="nameChanged && !addForm.controls['username'].valid">
                    <mtm-inviter-dropdown class="flex-fill mr-1" [data]="companyUsers" [(value)]="invitedUsers"
                        [internalUsersOnly]="true" (selectedInvitersChange)="nameChange($event)" [iconSize]="24"
                        [userInfo]="'nameOnly'" [inputPlaceholder]="'nameOrEmail' | translate" [singleInput]="true"
                        [asClassicDropdown]="true">
                    </mtm-inviter-dropdown>
                    <input [hidden]="true" class="form-control" type="text" formControlName="username">

                    <div class="error-msg" *ngIf="addForm.controls['username'].errors?.duplicate">
                        {{addForm.controls.username.errors.duplicate.message | translate }}
                    </div>
                </div>

                <div class="mb-3">
                    <input type="text" readonly class="p-2 role-label" *ngIf="isReadOnlyRole" [value]="roleLabel"
                        title="{{'workspace_roleChangeNotPossible' | translate}}" />
                    <select class="form-control p-2 role" formControlName="role" (change)="validate()"
                        *ngIf="! isReadOnlyRole">
                        <option value="">{{'workspace_edit_selectRole' | translate }}</option>
                        <option *ngFor="let role of workspaceRoles" value="{{role.code}}">{{role.label}}</option>
                    </select>
                </div>

                <div class="mb-3" *ngIf="isNewUser">
                    <ng-select [items]="projectRoles" formControlName="defaultProjectRoles" [multiple]="true"
                        class="project-roles" bindValue="code" bindLabel="label" [searchable]="false"
                        [placeholder]="'Default Project Roles'">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <button class="btn btn-outline-secondary btn-lg btn-block" type="button" (click)="cancel()">
                    <span>{{'cancel'| translate}}</span>
                </button>
            </div>
            <div class="col">
                <button class="btn btn-danger btn-lg btn-block" [disabled]="!addForm.valid" type="submit"
                    (click)="save()">
                    <span>{{'add'| translate}}</span>
                </button>
            </div>
        </div>
    </form>
</div>