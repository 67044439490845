import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "./auth.service";
import * as moment from "moment";
import { MtmDatePipe } from "../pipes/mtm-date.pipe";
import { TranslateService } from "./translate.service";
// import {el} from "@angular/platform-browser/testing/src/browser_util";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date";

@Injectable({
	providedIn: 'root'
})
export class CustomAdapter extends NgbDateAdapter<string> {

	readonly DELIMITER = '-';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			let date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10)
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
	}
}



@Injectable({
	providedIn: 'root'
})
export class CustomDateParserFormatter extends NgbDateParserFormatter {

	constructor(private authService: AuthService, private translateService: TranslateService) {
		super();
		this.userDateFormat = this.authService.getUserSettings('dateFormat') || 'YYYY-MM-DD';
		this.lang = this.translateService.getLang().toString();
	}

	readonly DELIMITER = '-';
	private lang: string;
	private readonly userDateFormat: string;

	parse(value: string): NgbDateStruct | null {
		let newMoment = moment(value, this.userDateFormat, this.lang);
		if (newMoment.isValid()) {
			let result = {
				day: newMoment.date(),
				month: newMoment.month() + 1,
				year: newMoment.year()
			};
			return result;
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		let ngbObj = JSON.parse(JSON.stringify(date));
		let newMoment = moment();
		if (ngbObj) {
			ngbObj.month--;
			newMoment.month(ngbObj.month);
			newMoment.date(ngbObj.day);
			newMoment.year(ngbObj.year);
			if (newMoment.isValid()) {
				let result = newMoment.locale(this.lang).format(this.userDateFormat);
				return result;
			}
		}
		return '';
	}
}

