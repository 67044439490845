import { UserService } from 'app/shared/services/user.service';
import { AuthService } from 'app/shared/services/auth.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from "@angular/common";

@Pipe({
	name: 'mtmCurrency'
})
export class MtmCurrencyPipe implements PipeTransform {

	constructor(private authService: AuthService, public userService: UserService) {

	}

	transform(value: any, fraction: any = null): string {
		// Get number format settings
		// 'us' = 1,500.75
		// 'int' = 1.500,75
		let numberFormatSettings = this.authService.getUserSettings('numberFormat');
		let locale = 'en-US';
		if (numberFormatSettings) {
			if (numberFormatSettings == 'us')
				locale = 'en-US';
			else if (numberFormatSettings == 'fr-CA')
				locale = 'fr-CA'
			else if (numberFormatSettings == 'int')
				locale = 'es-ES';
		}

		// Get currency
		let currencyId = this.authService.getUserSettings('currency');
		let currencies = this.userService.getCurrencies();
		let foundCurrency = currencies[0];
		currencies.forEach((cur: any) => {
			if (cur.id == currencyId) {
				foundCurrency = cur;
			}
		});

		const options: any = { style: 'currency', currency: locale === 'en-US' ? 'USD' : 'EUR' };
		if (numberFormatSettings == 'fr-CA')
			options.minimumFractionDigits = 0;

		if (fraction != null) {
			options.minimumFractionDigits =  parseInt(fraction, 10);
		}


		let result = new Intl.NumberFormat(locale, options).format(value);
		if (result === 'NaN')
			return value;
		else
			return locale === 'en-US' ? result.slice(1, result.length).trim() : result.slice(0, -1).trim();
	}

}
