<div class="mtm-filereorder-full-container">
	<div class="mtm-container">
		<div class="mtm-file-reorder-header">
			<div class="col w-100 text-right">
				<button type="button" class="close" aria-label="Close" (click)="serviceFileReorder.closeModal()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<div class="mtm-file-reorder-body" (mouseover)="disableTrue($event)">
			<div [ngClass]="{'progress-visibility':!reorderRequestStatus}" class="progress">
				<div class="progress-bar progress-bar-striped bg-danger progress-bar-animated" role="progressbar"
					style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<div class="d-flex flex-wrap" [dragula]="'files-reorder-modal'" [dragulaModel]="backupElementList"
				#tilesContainer>
				<div class="col-xs-3 media-container" [style.width]="tileWidth + 'px'"
					[style.height]="tileHeight + 'px'" *ngFor="let file of backupElementList"
					id="{{file.fileReorderId}}">
					<mtm-media-player [style.cursor]="'move'" [reorderPreview]="true" [item]="file"
						[miniPlayer]="true"></mtm-media-player>
					<span class="badge move-button">
						<i class="fa fa-arrows fa-lg text-danger" aria-hidden="true"></i>
					</span>
					<span class="badge edit-button" (click)="deleteFile(file)">
						<i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i>
					</span>
				</div>
			</div>
		</div>
		<div class="slider-cont" (mouseover)="disableFalse($event)">
			<div class="w-75">
				<ng5-slider [(value)]="slideValue" [options]="{ floor: 5, step:5, ceil: 100 }"
					(valueChange)="onChange($event)"></ng5-slider>
			</div>
		</div>
	</div>
</div>