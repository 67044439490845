import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api.service";


@Injectable()
export class PlatformEventService {

	constructor(private apiService: ApiService) { }

	getPlatformEventInfo() {
		return this.apiService.httpGet(`/api/platformEvents`);
	}

	createPlatformEventInfo(event: any) {
		return this.apiService.httpPost(`/api/platformEvents`, event);
	}

	editPlatformEventInfo(event: any) {
		return this.apiService.httpPut(`/api/platformEvents/${event.id}`, event);
	}

	deletePlatformEventInfo(event: any) {
		return this.apiService.httpDelete(`/api/platformEvents/${event.id}`);
	}
}
