export enum ProjectStatus {
	ToDo = 'TO_COME',
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	BEWARE = 'BEWARE',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	Delayed = 'DELAYED',
	ARCHIVED = 'ARCHIVED'
}

export enum ProjectColor {
	TO_COME = 'purple',
	IN_PROGRESS = 'blue',
	COMPLETED = 'green',
	BEWARE = 'yellow',
	UPCOMING_EXPIRY = 'yellow',
	DELAYED = 'orange',
	ARCHIVED = 'gray'
}

export enum DriveCampaignStatus {
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	Delayed = 'DELAYED'
}

export enum DriveCampaignColor {
	IN_PROGRESS = 'gray',
	COMPLETED = 'green',
	UPCOMING_EXPIRY = 'orange',
	DELAYED = 'orange'
}