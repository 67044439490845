import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import {
    SignedURL_FU_Base,
    WithSignedURLUploadInfo,
    WithUploadBaseDependencies
} from 'app/shared/bases/signed-url-file-upload.base';
import { AuthService } from 'app/shared/services/auth.service';
import { MovieTranslationService } from 'app/shared/services/movie-translation.service';
import { ProjectService } from 'app/shared/services/project.service';
import { FileTransferService, listenerFileUploadCompleted } from 'app/shared/services/signed-url';
import { newSubtitleAdded, SubtitleGenerationService, subtitleThumbnailAdded, subtitleGenerated } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EMPTY_ID } from '../../task/task-status.const';
import { HelperService } from 'app/shared/services/helper.service';
import { commentFileTranscriptionGenerated, commentFileUploaded, commentFileUploading } from 'app/shared/services/comments.service';

@Component({
    selector: 'mtm-comment-file-transcription-generator',
    template: '<div></div>'
})
export class CommentFileTranscriptionGenerator extends SignedURL_FU_Base implements OnInit, AfterViewInit, OnDestroy {
    @Input() guid: any;
    @Input() projectId: any;
    @Input() sectionId: any;
    @Input() subSectionId: any;
    @Input() comment: any;
    @Input() files: any;
    @Input() annotationId: any;

    subtitle: any;
    items: any = [];
    ngUnsubscribe = new Subject();
    authUser: any;

    constructor(
        transferService: FileTransferService,
        private service: SubtitleGenerationService,
        private movieTranslationService: MovieTranslationService,
        private projectService: ProjectService,
        authService: AuthService,
    ) {
        super(
            WithUploadBaseDependencies(authService, transferService),
            WithSignedURLUploadInfo({
                uploadPathGetter: () => `/api/mediaAnnotations/${this.projectId}/${this.sectionId}/${this.subSectionId}/${this.annotationId}/${this.comment.id}/`,
                labelGetter: () => 'comment',
                entityGetter: () => this.comment,
                projectIdGetter: () => this.projectId,
                resumablePreUploadSlug: 'preUploadFilesResumable',
                postUploadAttributes: () => { return { comments: {} } },
                hideProgress: true
            })
        )
        listenerFileUploadCompleted.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(this.commentFileUploaded.bind(this));
    }

    ngOnInit(): void {
        if (!this.files?.length) {
            commentFileTranscriptionGenerated.emit({ guid: this.guid });
        } else {
            this.authUser = this.authService.getAuthUser();
            this.prepareFilesForChatUpload(this.files);
            this.startUpload();
            commentFileUploading.emit({ comment: this.comment, files: this.files });
        }
    }

    ngAfterViewInit(): void { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    commentFileUploaded(data) {
        const isAudioFile = HelperService.isAudioFile(data.dbFileObject) || HelperService.isVideoFile(data.dbFileObject);
        commentFileUploaded.emit({ comment: this.comment, file: data });
        if (!isAudioFile) {
            return;
        }
        if (data?.entity?.id == this.comment.id) {
            this.service.saveSubtitleGenerations(this.projectId, this.sectionId, this.subSectionId, {
                id: data.dbFileObject.id,
                language: "detect",
                directoryId: EMPTY_ID,
                name: data.dbFileObject.displayName
            }).pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe((res: any) => {
                this.service.getSignedUrlInfo(this.projectId, data.dbFileObject.id)
                    .pipe(
                        takeUntil(this.ngUnsubscribe)
                    ).subscribe((info: any) => {
                        this.movieTranslationService
                            .createMovieSubtitle(this.sectionId, this.subSectionId, EMPTY_ID, res.id, data.dbFileObject.id, data.dbFileObject.displayName, info.second, this.projectId, this.authService.getAuthUserName())
                            .pipe(
                                takeUntil(this.ngUnsubscribe)
                            ).subscribe(transcriptionData => {
                                commentFileTranscriptionGenerated.emit({ guid: this.guid, transcriptionData });
                            });
                    });
            });
        }
    }
}
