<ng-template #headerContent>
	<section class="dashboard-header">
		<div class="d-flex align-items-center user-row">
			<mtm-user-profile-picture [forceLoadProfile]="true" [user]="user" [username]="user.username" [height]="50"
				[width]="50" [squareIcon]="true">
			</mtm-user-profile-picture>
			<div class="ml-3">
				<h4 class="media-heading">{{'dashboardWelcomeText' | translate}} {{ user.firstName }}</h4>
				<div class="welcome-text">{{'dashboardHiText' | translate}}</div>
			</div>

			<ng-container *ngIf="activeWorkspace">
				<div ngbDropdown class="ml-auto mt-2 workspace-picker" container="body">
					<div class="d-flex align-items-center p-2 workspace-picker-active">
						<mtm-item-icon [name]="activeWorkspace.name" [imageUrl]="activeWorkspace.avatarUrl"
							[width]="'24px'" [generatedTextColor]="true" [height]="'24px'">
						</mtm-item-icon>
						<span class="d-inline-block ml-2 text-uppercase workspace-name">{{activeWorkspace.name}}</span>
						<span class="d-inline-block ml-2 workspace-settings" *ngIf="hasWorkspaceSettings"
							(click)="goToWorkspaceSettings()">
							<i class="fa fa-sliders"></i>
						</span>
						<span class="d-flex align-items-center justify-content-center ml-2 workspace-picker-toggle"
							ngbDropdownToggle><i class="fa fa-caret-down"></i></span>
					</div>

					<div ngbDropdownMenu aria-labelledby="workspace-picker" class="workspace-picker-menu">
						<a class="dropdown-item" *ngFor="let workspace of sortedWorkspaces"
							(click)="changeWorkspace(workspace)">
							<div class="d-flex align-items-center">
								<mtm-item-icon [name]="workspace.name" [imageUrl]="workspace.avatarUrl" [width]="'24px'"
									[generatedTextColor]="true" [height]="'24px'">
								</mtm-item-icon>
								<span
									class="d-inline-block ml-2 text-uppercase workspace-name">{{workspace.name}}</span>
							</div>
						</a>
					</div>
				</div>
			</ng-container>
		</div>

		<ng-container *ngIf="dashboardMode == dashboardModes.WorkspaceList">
			<div class="row justify-content-between p-3 filter-container workspace-list-bar">
				<div class="d-flex align-items-center filter-wrapper">
					<h2 class="mr-3 workspaces-title c-mtm-full-white">{{'dashboard_workspacesDashboard' | translate}}</h2>
					<input type="text" [(ngModel)]="workspaceFilter"
						placeholder="{{'dashboard_workspaceFilterText' | translate}}" />
				</div>
				<div class="text-right ml-auto">
					<label for="projectFilter" class="mr-2">{{'dashboard_projectFilterText'| translate }}</label>
					<input type="text" id="projectFilter" [(ngModel)]="projectFilter"
						placeholder="{{'search' | translate }}" />
				</div>
			</div>
		</ng-container>

		<div class="row filter-container" *ngIf="activeWorkspace || inMarketplaceWorkspace">
			<div>
				<div class="card tab-container projects-tab-container">
					<div class="nav-tabs">
						<div class="nav-item" *ngFor="let tab of availableTabs;">
							<a (click)="toggleTab(tab.value)" [class.active]="tab.value==selectedTab"
								class="nav-link">{{tab.label | translate}}</a>
						</div>
					</div>
				</div>
			</div>

			<div class="ml-auto px-0 position-relative d-flex align-items-center justify-content-end">
				<ng-container *ngIf="selectedTab != 'marketplaceBidding'">
					<div class="status-filter-wrapper" *ngIf="viewMode == ViewMode.Grid && selectedTab != 'archived'">
						<ng-select [items]="projectStatusList" [multiple]="true"
						bindLabel="label" bindValue="value" [selectableGroup]="true"  [closeOnSelect]="false"
						[(ngModel)]="statusFilter"  [placeholder]="'task_selectStatus' | translate" > 
							<ng-template ng-multi-label-tmp>
							<span style="display:block" class="ng-placeholder"> {{statusFilter.length}} {{'itemSelected' | translate}} </span>
						  </ng-template>
							<ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
								<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{task_selectStatus' | translate}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
								<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.label | translate}}
							</ng-template>
						</ng-select>
					</div>

					<div class="position-relative search-box" *ngIf="viewMode == ViewMode.Grid">
						<label class="form-group row mx-0 has-float-label w-100">
							<input class="search-input filter-search-input" [(ngModel)]="projectName" type="search"
								placeholder="{{'search' | translate }}">
							<!--<span>Filter...</span>-->

						</label>
						<i class="fa fa-search search-icon" aria-hidden="true"></i>
					</div>

					<div class="d-inline-block ml-3 grid-view-toggle" [class.active]="viewMode == ViewMode.Grid"
						role="button" (click)="switchViewMode(ViewMode.Grid)"></div>
					<div class="d-inline-block ml-2 list-view-toggle" [class.active]="viewMode == ViewMode.List"
						role="button" (click)="switchViewMode(ViewMode.List)"> </div>

					<div ngbDropdown class="d-inline-block sort-dropdown" *ngIf="viewMode == ViewMode.Grid">
						<div class="toggle" ngbDropdownToggle role="button">
							<i class="fa" aria-hidden="true" [ngClass]="{
							  'fa-sort-alpha-asc': projectSorting?.btn == ProjectSortCriteria.ProjectName && projectSorting?.sortDirection,
							  'fa-sort-alpha-desc': projectSorting?.btn == ProjectSortCriteria.ProjectName && !projectSorting?.sortDirection,
								'fa-sort-numeric-asc': projectSorting?.btn == ProjectSortCriteria.StartDate && projectSorting?.sortDirection,
								'fa-sort-numeric-desc': projectSorting?.btn == ProjectSortCriteria.StartDate && !projectSorting?.sortDirection,
								'fa-sort-amount-asc': projectSorting?.btn == ProjectSortCriteria.DueDate && projectSorting?.sortDirection,
								'fa-sort-amount-desc': projectSorting?.btn == ProjectSortCriteria.DueDate && !projectSorting?.sortDirection
								}"></i>
						</div>
						<div ngbDropdownMenu>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.ProjectName, true)">
								<i class="fa fa-sort-alpha-asc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByProjectNameAsc' | translate }}</span>
							</div>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.ProjectName, false)">
								<i class="fa fa-sort-alpha-desc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByProjectNameDesc' | translate }}</span>
							</div>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.StartDate, true)">
								<i class="fa fa-sort-numeric-asc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByStartDateAsc' | translate }}</span>
							</div>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.StartDate, false)">
								<i class="fa fa-sort-numeric-desc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByStartDateDesc' | translate }}</span>
							</div>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.DueDate, true)">
								<i class="fa fa-sort-amount-asc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByDueDateAsc' | translate }}</span>
							</div>
							<div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.DueDate, false)">
								<i class="fa fa-sort-amount-desc mr-2" aria-hidden="true"></i>
								<span>{{'dashboard_sortByDueDateDesc' | translate }}</span>
							</div>
						</div>
					</div>

				</ng-container>

				<div class="w-100 filter-box" *ngIf="selectedTab == 'marketplaceBidding'">
					<label class="form-group row has-float-label m-0 w-100">
						<select [(ngModel)]="proposalStatusFilter" class="d-block p-1 w-100">
							<option value="ongoing">{{'dashboard_ongoingProposals' | translate}}</option>
							<option value="accepted">{{'dashboard_acceptedProposals' | translate}}</option>
							<option value="declined">{{'dashboard_rejectedProposals' | translate}}</option>
						</select>
					</label>
				</div>
			</div>
		</div>
	</section>
</ng-template>

<div class="col mt-3 project-container">
	<div class="d-block row projects-container" [style.position]="'relative'">

		<div class="d-flex align-items-center justify-content-center" [class.is_hidden]="selectedTab == 'invitation'
			|| selectedTab == 'marketplaceBidding'">
			<loading-data #loadingRef></loading-data>
		</div>

		<ng-container *ngIf="dashboardMode == dashboardModes.WorkspaceList">
			<workspace-list [projectFilter]="projectFilter" [workspaceFilter]="workspaceFilter" class="d-block"
				style="margin-top: 15px;"></workspace-list>
		</ng-container>

		<ng-container *ngIf="dashboardMode == dashboardModes.ProjectList">
			<div class="row"
				*ngIf="(activeWorkspace || inMarketplaceWorkspace) &&
			(selectedTab == 'ongoing' ||( selectedTab == 'archived' && !inMarketplaceWorkspace ) || (selectedTab == 'bidding' && !inMarketplaceWorkspace))">


				<div *ngIf="viewMode == ViewMode.Grid &&  !loadingRef.isLoading" class="pl-0 pr-0 col col-sm-12">

					<div *ngIf="selectedTab != 'archived'" class="row" [dragula]="'project-elements'"
						[dragulaModel]="backupElementList">
						<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4 float-left" *ngFor="let mergedProject of backupElementList | filterSearchProjects:[projectName,companyName,startDatePre,startDateLast,dueDatePre,dueDateLast,airDatePre,airDateLast,budgetDownLimit,budgetUpLimit,tag, statusFilter] |
							 ProjectSorting:[projectSorting]" id="{{mergedProject.localId}}">
							<button class="btn btn-new-project"
								*ngIf="selectedTab != 'archived' && mergedProject && !mergedProject.localId && !loadingRef.isLoading && !inMarketplaceWorkspace"
								(click)="newProject()">
								<i class="fa fa-plus fa-5x" aria-hidden="true"></i>
								<h6 class="text-uppercase mt-3">{{'newProject' | translate}}</h6>
							</button>
							<project-thumbnail
								*ngIf="mergedProject && mergedProject.id && !mergedProject.isPending && mergedProject.localId"
								[project]="mergedProject" [parentLoadingDataElement]=loadingRef [subscription]="subscription"
								[additionalData]="additionalData[mergedProject.id]" [canUpdateStatus]=true [isFromFreePlan]="mergedProject.isFree"
								(deleteEmit)="deletedProject($event)" (projectStatusUpdate)="updateProjectStatus($event)">
							</project-thumbnail>
							<!-- <project-thumbnail
								*ngIf="mergedProject && mergedProject.id && mergedProject.isPending && mergedProject.localId"
								[project]="mergedProject"   [rsvp]="'maybe'" [parentLoadingDataElement]=loadingRef [canUpdateStatus]=true
								[subscription]="subscription"
								[additionalData]="additionalData[mergedProject.id]" (projectStatusUpdate)="updateProjectStatus($event)"
								(deleteEmit)="deletedProject($event)" (rejectEmit)="rejectedProject($event)" (projectStatusUpdate)="updateProjectStatus($event)">
							</project-thumbnail> -->
						</div>
					</div>
				</div>


				<div *ngIf="viewMode==ViewMode.List &&  !loadingRef.isLoading" class="col col-sm-12 p-0">
					<!--
          <selector-project-thumbnail-list
						*ngFor="let mergedProject of backupElementList | filterSearchProjects:[projectName,companyName,startDatePre,startDateLast,dueDatePre,dueDateLast,airDatePre,airDateLast,budgetDownLimit,budgetUpLimit,tag] | ProjectSorting:[projectSorting]"
						[project]="mergedProject" [rsvp]=" mergedProject.isPending ? 'maybe' : 'yes'"
						[parentLoadingDataElement]=loadingRef (deleteEmit)="deletedProject($event)"
						(rejectEmit)="rejectedProject($event)">
					</selector-project-thumbnail-list>
					-->

					<div class="text-left mb-3" *ngIf="selectedTab != 'archived'">
						<button class="btn d-flex align-items-center justify-content-center btn-list-new"
							(click)="newProject()" *ngIf="canCreateProject">
							<i class="fa fa-plus-circle" aria-hidden="true"></i>
							<span class="ml-2 text-uppercase">{{'newProject' | translate}}</span>
						</button>
					</div>


					<mtm-project-list-view *ngIf="elementList.length && selectedTab != 'archived'" [projectList]="elementList |
							 ProjectSorting:[projectSorting]" [projectName]="projectName"
							 (projectDeleted)="populateData()" (projectArchived)="populateData()" (projectRestored)="populateData()"
							 ></mtm-project-list-view>


					<mtm-project-list-view *ngIf="archivedProjects.length && selectedTab == 'archived'"
                                 [projectName]="projectName"
                                 [projectList]="archivedProjects"
								 (projectDeleted)="populateData()" (projectArchived)="populateData()" (projectRestored)="populateData()"
								 ></mtm-project-list-view>
				</div>

			</div>
			<div *ngIf="selectedTab == 'archived' && viewMode == ViewMode.Grid" class="d-flex flex-wrap">
				<div class="col-xs-12 col-sm-6 col-md-4 mt-4 float-left col-lg-3"
					*ngFor="let archivedProject of archivedProjects | archivedProjectFilter:[projectName,companyName,startDatePre,startDateLast,dueDatePre,dueDateLast,airDatePre,airDateLast,budgetDownLimit,budgetUpLimit,tag] | ProjectSorting:[projectSorting]"
					[class.d-none]= "archivedProject.displayDueDate == 0" [class.d-block]="archivedProject.displayDueDate != 0">
					<project-thumbnail [isArchived]="true" [project]="archivedProject" [subscription]="subscription"
						(deleteEmit)="deletedProject($event)" (projectRestored)="restoreArchive($event)"></project-thumbnail>
				</div>
			</div>

			<!-- marketplace invitation -->
			<div class="mt-3" *ngIf="inMarketplaceWorkspace && selectedTab == 'invitation'">
				<invitations [viewType]="'IN_PROGRESS'" [isInv]="true" style="min-width:100%"></invitations>
			</div>

			<!-- marketplace bidding -->
			<div class="mt-3" *ngIf="inMarketplaceWorkspace && selectedTab == 'marketplaceBidding'">
				<invitations [viewType]="'OTHER'" [isInv]="false" style="min-width:100%"
					[proposalStatusFilter]="proposalStatusFilter"></invitations>
			</div>
		</ng-container>
	</div>
</div>
