import { EventEmitter, Injectable } from "@angular/core";

export let folderAdded: EventEmitter<any> = new EventEmitter<any>();
export let folderCreated: EventEmitter<any> = new EventEmitter<any>();

export let movieAdded: EventEmitter<any> = new EventEmitter<any>();
export let movieCreated: EventEmitter<any> = new EventEmitter<any>();

export let movieVersionSelected: EventEmitter<any> = new EventEmitter<any>();
export let movieVersionDeleted: EventEmitter<any> = new EventEmitter<any>();

export let aiReframingDemoAccountReady: EventEmitter<any> = new EventEmitter<any>();