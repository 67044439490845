import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, DoCheck, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from 'app/shared/services/helper.service';
import { AuthService } from 'app/shared/services/auth.service';
import { EmitterService } from './../shared/services/emitter.service';
import { PushNotificationComponent } from './../shared/components/push-notification/push-notification.component';
import { NotificationService } from './../shared/services/notification.service';
import { BroadcastService } from "app/shared/services/broadcast.service";
import { BreadcrumbService } from 'ng5-breadcrumb';
// import { JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from "@auth0/angular-jwt";

import { DateHelperService } from 'app/shared/services/date-helper.service';
import { UserService } from 'app/shared/services/user.service';
import { GoogleAnalyticsEventsService } from './../shared/services/google-analytics-events.service';
import { OverlayService } from "./../shared/services/overlayService";
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { ProposalModalComponent } from "../shared/components/proposal-modal/proposal-modal-component";
import { FileReorderService } from 'app/shared/services/files-reorder.service';
import { ProjectService, onCheckFinalPayment } from "../shared/services/project.service";
import { CreditCardDetailsModalComponent } from "../payment/credit-card-details/credit-card-details-modal.component";
import * as $ from 'jquery';
import {
  MtmWebSocketService,
  listenerWSConnectionChange,
  WS_STATUS_CONNECTING,
  WS_STATUS_CONNECTED,
  WS_STATUS_CLOSED,
  WS_SEND_DESTINATION_TYPES,
  wsListenerCONVERSATION,
  wsListenerAUTH_USER_POPUP_NOTIFY,
  wsListenerWORKSPACE_DELETE
} from 'app/shared/services/mtm-websocket.service';
import { TranslateService } from "../shared/services/translate.service";
import { DesktopNotificationService } from "app/shared/services/desktop-notification.service";
import { ConversationService } from "app/shared/services/conversation.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { AdminService } from 'app/admin/services/admin.service';
import { ThemeService } from "../shared/services/theme.service";
import {
  ActiveWorkspaceUpdatedEvent,
  ComapanyCoverUpdatedEvent,
  CrispDisplayUpdatedEvent,
  ThemeOptions,
  WorkspaceCoverUpdatedEvent,
  WorkspaceDeleted
} from "../shared/interfaces";
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { listenerFileDownloadCompleted } from 'app/shared/services/signed-url';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { BannerTypes } from "../subscriptions/shared/components/subscription-banner/subscription-banner.component";
import { SecurityPolicyService } from 'app/shared/services/security-policy.service';
import { OnboardingProgressComponent } from 'app/shared/components/onboarding-progress/onboarding-progress.component';

@Component({
  selector: 'mtm-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('mainLayoutContainer', { static: true }) mainLayoutContainer: ElementRef;
  @ViewChild(PushNotificationComponent) pushNotification: PushNotificationComponent;
  @ViewChild('breadcrumbComponent', { static: true }) breadcrumbComponent: ElementRef;

  sessionId: number = HelperService.genRandomNumber(1, 100000);
  loading: boolean = false;
  rightbarCollapsed: boolean = false;
  modalRef: NgbModalRef = null;
  isModalOpen: boolean = false;
  isVideoCallModalOpen: boolean = false;
  project: any = null;
  projectId: any = null;
  duration: number = 0;
  proposal: any[] = [];
  private intervalWSProjectActiveUser: any;

  public hasDebt: boolean = false;
  private backendStatus = null;
  private intervalVerfyToken: any;
  private items = [{ id: 1, label: 'bir' }, { id: 2, label: 'iki' }, { id: 3, label: 'üç' }];
  publicReviewLink: boolean = false;
  publicPage: boolean = false;
  ngUnsubscribe = new Subject();
  authUser: any;
  subscription: any;
  themeOptions: ThemeOptions | null = null;
  workspaceCover: string = '';
  workspaceId: any;
  workspaceName: any;
  wsId: string;
  companyCover: string = '';
  bannerTypes = BannerTypes;

  lastAccessedWorkspace: string;
  logoutTimeout: any;
  logoutTime: any;

  constructor(private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private webSocketService: MtmWebSocketService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private broadcastService: BroadcastService,
    public userService: UserService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private overlayService: OverlayService,
    private serviceDataTransferBetweenComponents: DataTransferBetweenComponentsService,
    public serviceFileReorder: FileReorderService,
    private projectService: ProjectService,
    private conversationService: ConversationService,
    private desktopNotificationService: DesktopNotificationService,
    private translateService: TranslateService,
    private workspaceService: WorkspaceService,
    private adminService: AdminService,
    private themeService: ThemeService,
    private elementRef: ElementRef,
    private conversation: ConversationService,
    public translatePipe: TranslatePipe,
    private stompService: MtmStompService,
    private securityPolicyService: SecurityPolicyService,
    private timelineService: TimelineService) {
    this.wsId = this.stompService.subscribeToService();

    EmitterService.get('user.logout').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.projectService.leaveFromProject();
      this.workspaceService.unsetActiveWorkspace();
      this.workspaceService.clearWorkspaceCache();
      this.webSocketService.disconnectWS();
      $crisp.push(["do", "session:reset"]);
    });

    EmitterService.get('SECURITY_POLICY:AUTO_LOGOUT').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(time => {
      if (!this.authUser) {
        return;
      }
      this.logoutTime = time;
      this.resetTimer();
    });

    this.publicReviewLink = this.route.snapshot.data.publicReviewLink;
    this.publicPage = this.route.snapshot.data.publicPage;
    // this.getActiveWorkSpace();
    this.workspaceService.workspaceId.subscribe((result: any) => {
      this.workspaceId = result.id;
      this.workspaceName = result.name;
    });

    this.initBreadcrumb();
    this.buildVerifyTokenInterval();
    this.webSocketService.initConnectWS();
    // this.addSubscribe('STATUS_CHANGE', listenerWSConnectionChange, (status) => this.changesConnection(status));
    // this.addSubscribe('CONVERSATION', wsListenerAUTH_USER_NOTIFY, (message) => this.processMessage(message));
    // this.addSubscribe('ONLOADED_PROJECT', onCheckFinalPayment, () => this.onLoadedProjectForPayment(), () => this.onLoadedProjectForPayment());
    
    listenerWSConnectionChange.pipe(
			takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (status) => this.changesConnection(status)
    )

		onCheckFinalPayment.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			() => this.onLoadedProjectForPayment(),
			() => this.onLoadedProjectForPayment()
		);

    this.buildWSProjectActiveUserInterval();
    this.authUser = this.authService.getAuthUser();
    this.subscription = this.authService.getAuthUserSubscription();
    if (this.authUser) {

      this.userService.getUserProfileSettings().subscribe((profile: any) => {
        $crisp.push(["set", "user:email", [_.get(this.authUser, 'username', '-')]]);
        $crisp.push(["set", "session:data", [[
          ["email", _.get(this.authUser, 'username', '-')]
        ]]]);

        if (!profile.crispChatEnable) {
          $crisp.push(['do', 'chat:hide']);
        }
      });
      this.stompService.subscribeToListener('CONVERSATION', this.wsId, [this.authUser.username],
        (args: any) => this.processMessage(args), wsListenerCONVERSATION);
      this.stompService.subscribeToListener('USER_POPUP_NOTIFY', this.wsId, [this.authUser.username],
        (args: any) => this.popupNotificationAdded(args), wsListenerAUTH_USER_POPUP_NOTIFY);
      this.stompService.subscribeToListener('WORKSPACE_DELETE', this.wsId, [this.authUser.username],
        (args: any) => this.workspaceDeleted(args), wsListenerWORKSPACE_DELETE);
    }

    EmitterService.get(CrispDisplayUpdatedEvent).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((enabled: boolean) => {
      if (enabled) {
        $crisp.push(['do', 'chat:show']);
      } else {
        $crisp.push(['do', 'chat:hide']);
      }
    });


    listenerFileDownloadCompleted.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (result) => {
        const downloadControl = document.getElementById(result.id);
        downloadControl.classList.remove('downloading');
      },
      () => {

      })

    if (this.authUser) {
      this.stompService.subscribeToListener('CONVERSATION', this.wsId, [this.authUser.username],
        (args: any) => this.processMessage(args), wsListenerCONVERSATION);
    }
  }

  ngDoCheck(): void {
    if (this.elementRef) {
      let breadcrumbs = this.elementRef.nativeElement.getElementsByClassName('breadcrumb-item');
      for (let i = 0; i < breadcrumbs.length; i++) {
        this.translatePipe.transform(breadcrumbs[i].innerText)
        if (HelperService.isObjectEmpty(breadcrumbs[i].innerText)) {
          breadcrumbs[i].style.display = 'none';
        }
      }
    }
  }

  workspaceDeleted(args) {
    EmitterService.get(WorkspaceDeleted).emit(args);
  }

  getSelectedLanguage(lang: any) {
    let selectedLanguage = localStorage.getItem('auth_user_language') || 'en_us';
    return lang === selectedLanguage;
  }

  popupNotificationAdded(message) {
    EmitterService.get('popup.notification').emit(message);
  }

  private initBreadcrumb() {
    if (this.elementRef) {
      let breadcrumbs = this.elementRef.nativeElement.getElementsByClassName('breadcrumb-item');
      for (let i = 0; i < breadcrumbs.length; i++) {
        this.translatePipe.transform(breadcrumbs[i].innerText);
      }
    }
    this.breadcrumbService.addCallbackForRouteRegex('^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/calendar', (id) => {
      return this.translatePipe.transform('Calendar');
    });

    this.breadcrumbService.addCallbackForRouteRegex('^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/teamfiles', (id) => {
      return this.translatePipe.transform('Files');
    });

    this.breadcrumbService.addFriendlyNameForRoute('/account/company', 'Company Profile');

    this.breadcrumbService.addCallbackForRouteRegex(`^/account/${this.workspaceId}`, (id) => {
      return this.workspaceName;
    });

    this.breadcrumbService.addCallbackForRouteRegex(`^/account/${this.workspaceId}/notifications-settings`, (id) => {
      return this.translatePipe.transform('notification_settings');
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/campaigndrive`, (id) => {
      return id == 'campaigndrive' ? 'Drive Campaign' : (id == 'general' ? '' : (id == 'recap' ? 'Recap' : this.timelineService.lastSelectedDriveCampaign?.name || (id.startsWith('timeline-settings') ? 'Settings' : '') || 'Drive Campaign'));
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/campaigndrive/dashboard`, (id) => {
      return this.timelineService.lastSelectedDriveCampaign?.name || 'Dashboard';
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/campaigndrive/dashboard/task-project`, (id) => {
      return this.timelineService.lastSelectedMilestone?.name || 'Projects & Tasks';
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/analytics/all-milestones`, (id) => {
      return '';
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/analytics/calendar-view`, (id) => {
      return '';
    });

    this.breadcrumbService.addCallbackForRouteRegex(`workspaces/analytics/main-milestones`, (id) => {
      return '';
    });

    this.breadcrumbService.addCallbackForRouteRegex(`/workspaces/campaign-drive`, (id) => {
      return this.timelineService.workspaceName || '';
    });

    this.breadcrumbService.hideRoute(`^/account/${this.workspaceId}`)
    this.breadcrumbService.hideRoute(`^/account/${this.workspaceId}/notifications-settings`)
    this.breadcrumbService.hideRoute('/account');
    this.breadcrumbService.hideRoute('/projects');
    this.breadcrumbService.hideRouteRegex(`^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/(?!edit)[a-zA-Z0-9]*$`);
    this.breadcrumbService.hideRouteRegex(`^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/[a-zA-Z0-9]*/[a-zA-Z0-9]*/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*$`);
  }

  private changesConnection(status) {
    this.backendStatus = status;

    switch (this.backendStatus) {
      case WS_STATUS_CLOSED:
        this.googleAnalyticsEventsService.emitEvent('Recover',
          'Backend.down',
          (this.authService.getAuthUser())
            ? this.authService.getAuthUserName()
            : '');
        break;
      case WS_STATUS_CONNECTING:
        break;
      case WS_STATUS_CONNECTED:
        this.googleAnalyticsEventsService.emitEvent('Recover',
          'Backend.up',
          (this.authService.getAuthUser())
            ? this.authService.getAuthUserName()
            : '');
        break;
    }
  }

  private buildVerifyTokenInterval() {
    this.breakVerifyTokenInterval();

    this.intervalVerfyToken = setInterval(() => this.verifyAuthToken(), 1000);
  }

  private breakVerifyTokenInterval() {
    if (this.intervalVerfyToken)
      clearInterval(this.intervalVerfyToken);
  }

  private verifyAuthToken() {
    if (this.backendStatus != WS_STATUS_CONNECTED && !localStorage.getItem('token'))
      return;

    let jwtHelper: JwtHelperService = new JwtHelperService();
    let token = localStorage.getItem('token');

    // Check if token is going to expire
    const now = new Date();

    // Check if token is going to expire in next 60 min
    // If that is the case, then request for new token
    // If got new token succesfully - force refresh page
    // If error trying to get new token - display error message and redirect to Login page
    if (token && DateHelperService.addMinutesToDate(now, 60) > jwtHelper.getTokenExpirationDate(token)) {

      this.userService.getToken().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (data: any) => {
          this.authService.changeToken(data.token);

          // GA: Event token is expired
          this.googleAnalyticsEventsService.emitEvent('Recover',
            'Token.expired',
            (this.authService.getAuthUser())
              ? this.authService.getAuthUserName()
              : '');
        },
        (err: any) => {
          this.authService.logout();
          window.location.href = '/entry';
        }
      )
    }
  }

  ngOnInit() {
    this.serviceDataTransferBetweenComponents.breadcrumbComponent = this.breadcrumbComponent;
    // this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.CONVERSATION, [this.authService.getAuthUserName()]);
    // setTimeout(() => {
    //   this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.CONVERSATION, [this.authService.getAuthUserName()]);
    // }, 350);

    // Listen for localStorage changes and fire message_receive method
    $(window).on('storage', this, this.broadcastService.receive);

    // Play blank sound to enable this page play background sounds
    this.playBlankSound();
    this.checkWorkspaceCover();
    this.setCompanySecurityConfig();

    EmitterService.get(ActiveWorkspaceUpdatedEvent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.checkWorkspaceCover();
      });

    EmitterService.get(WorkspaceCoverUpdatedEvent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(cover => {
        if (cover) {
          this.workspaceCover = `url(${HelperService.getCachebusterUrl(cover)})`;
        } else {
          this.workspaceCover = '';
        }
      });

    EmitterService.get(ComapanyCoverUpdatedEvent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(cover => {
        if (cover) {
          this.companyCover = `url(${HelperService.getCachebusterUrl(cover)})`;
        } else {
          this.companyCover = '';
        }
      });

    this.themeService.contentChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(update => {
        setTimeout(() => {
          this.themeOptions = update;
        }, 100);
      });
  }

  ngOnDestroy() {
    this.stompService.unsubscribeToService(this.wsId);
    this.breakWSProjectActiveUserInterval();
    this.breakVerifyTokenInterval();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // Get conversations of this project
  private getProjectAllConversations() {

    console.log('this.projectId', this.projectId)
    this.conversationService.getConversations(this.projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      EmitterService.get('project.messages').emit({ project: this.project, conversations: data });
    });
  }

  setCompanySecurityConfig() {
    const { companyId } = this.authUser;
    if (!companyId) {
      return;
    }
    this.securityPolicyService.getSecurityConfig(companyId)
      .subscribe((res) => {
        const autoLogoutConfig = res.find(c => c.configKey === 'AUTO_LOGOUT');
        if (autoLogoutConfig?.configValue !== '0') {
          this.logoutTime = parseInt(autoLogoutConfig?.configValue || '15');
          if (this.authUser) {
            this.setAutoLogout();
          }
        }
      })
  }

  logout() {
    this.authService.logout();
    EmitterService.get('user.logout').emit();
    this.broadcastService.send({ action: 'user.logout' });
    this.router.navigate(['/entry']);
  }

  resetTimer() {
    clearTimeout(this.logoutTimeout);
    if (this.logoutTime) {
      this.logoutTimeout = setTimeout(this.logout.bind(this), this.logoutTime * 60000);
    }
  }

  setAutoLogout() {
    document.addEventListener('mousedown', this.resetTimer.bind(this), true);
    document.addEventListener('mousemove', this.resetTimer.bind(this), true);
    document.addEventListener('keypress', this.resetTimer.bind(this), true);
    document.addEventListener('scroll', this.resetTimer.bind(this), true);
    document.addEventListener('touchstart', this.resetTimer.bind(this), true);
  }


  /**
   * Process message from web socket
   * @param message
   */
  processMessage(message: any) {
    if (!message.object) message.object = JSON.parse(message.objectJson)
    if (message.code === 'CHANGED_COMPANY_ROLE' && message.username === this.authService.getAuthUserName()) {
      this.notificationService.open({
        title: this.translatePipe.transform('roleChanged_title'),
        description: this.translatePipe.transform('roleChanged_relogin'),
        confirmBtn: this.translatePipe.transform('accept')
      }).subscribe(() => {
        this.authService.logout();
        window.location.href = '/entry';
      });
      return;
    }

    if (message.code == 'CONVERSATION_MESSAGE_SENT') {
      this.conversation.changeMessage(true);
    } else {
      this.conversation.changeMessage(false);
    }
    if (_.get(message, 'projectId', 0) !== _.get(this.project, 'id', 1)) {
      switch (message.code) {
        case 'CONVERSATION_CALL':
          this.desktopNotificationService.queueVideoChatIncoming(message.projectId, message);
          break;
      }
    } else {
      // Add notification in list
      let notify = !(message.code == 'CONVERSATION_MESSAGE_SEEN' || message.code == 'CONVERSATION_MESSAGE_SENT' || message.code == 'CONVERSATION_CALL');
      if (notify) {
        this.notificationService.addNewNotification(message);
      }

      switch (message.code) {
        // case 'FILE_UPLOADED':
        // case 'FILE_DELETED':
        //   let notificationDisplay = this.notificationService.getMessage(message.code, message.object);
        //this.overlayService.showSuccessWithTimeout(!message.object.sectionId ? notificationDisplay.label : notificationDisplay.label + " File uploaded.");
        // break;

        case 'CONVERSATION_MESSAGE_SENT':
          // Queue desktop notification (if logged in user is not the one who send the message)
          if (message.object.sender != this.authService.getAuthUserName())
            this.desktopNotificationService.queueTextMessage(this.projectId,
              {
                id: message.object.id,
                title: message.object.sender,
                body: (message.object.message)
                  ? message.object.message
                  : 'has attached a file',
                sound: { type: 'beep', loop: false }
              });
          EmitterService.get('new_message').emit(message.object);
          break;

        case 'CONVERSATION_CALL':
          this.desktopNotificationService.queueVideoChatIncoming(message.projectId, message);
          break;

        case 'CONVERSATION_CREATED':
          this.getProjectAllConversations();
          break;

        case 'PROPOSAL_ACCEPTED':
          this.modalRef = this.modalService.open(ProposalModalComponent, { size: 'lg' });
          break;
      }
    }
  }

  /**
   * Redirect to home page
   */
  homePage() {
    if (this.publicReviewLink) {
      return;
    }
    let redirectUrl = '/projects';
    if (this.project && this.project.id)
      redirectUrl = redirectUrl + `/${this.projectId}/dashboard`;

    this.router.navigate([redirectUrl]);
  }

  /**
   * Play blank sound. This is a Chrome hack to allow play background sounds (like video call incoming)
   * even page is not active
   */
  private playBlankSound() {
    var audio = new Audio("assets/sound/beep.mp3");
    audio.volume = 1;
  }

  openPaymentModal() {
    this.projectService.getAllProjectProposals(this.projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(proposals => {
      let modal = this.modalService.open(CreditCardDetailsModalComponent, {
        size: 'lg',
        windowClass: 'company-matched-blur-color',
        backdrop: false
      });
      modal.componentInstance.projectId = this.projectId;
      modal.componentInstance.projectType = this.project.projectType;
      if (this.project.downPaymentPaid == false) {
        modal.componentInstance.paymentType = 'DOWNPAYMENT';
        this.projectService.getAllProjectProposals(this.projectId).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(proposal => {
          if (proposal.status == 'ACCEPTED')
            modal.componentInstance.selectedProposal = proposal;
        });
        modal.componentInstance.totalMTM = 3242;

      } else
        modal.componentInstance.paymentType = 'FINAL_PAYMENT';
      proposals.forEach(proposal => {
        if (proposal.status == 'ACCEPTED')
          modal.componentInstance.selectedProposal = proposal;
      });

      modal.result.then(res => {
        this.projectService.prepareProjectInit(this.projectId);
      })
    })

  }

  onLoadedProjectForPayment() {
    const project = this.projectService.project;
    this.projectId = this.route.snapshot.params['projectId'];
    this.project = project;

    this.hasDebt = this.projectId && this.projectId != '' && project.prodCompanyId && project.downPaymentPaid == true && project.finalPaymentPaid == false && this.authService.getAuthUser(false).companyId == this.project.company.id;
  }

  changeLang(lang: any) {
    this.translateService.use(lang);
    let registerData = JSON.parse(localStorage.getItem('register_data'));
    this.authService.setAuthLanguage(lang);
    if (registerData) {
      return;
    }
    this.userService.changeUserProfileSettingLang(lang).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(userProfileSettings => {
    });
    this.notificationService.getAllUnseenNotifications();
  }

  private breakWSProjectActiveUserInterval() {
    if (!this.intervalWSProjectActiveUser)
      return;

    clearInterval(this.intervalWSProjectActiveUser);
  }

  private buildWSProjectActiveUserInterval() {
    this.breakWSProjectActiveUserInterval();
    this.sendUserActiveInProject();
    this.intervalWSProjectActiveUser = setInterval(() => this.sendUserActiveInProject(), 1000);
  }

  private sendUserActiveInProject() {
    this.webSocketService.sendToWS(WS_SEND_DESTINATION_TYPES.ONLINE_USER, [], undefined, { "user": this.authService.getAuthUserName() });
    if (this.projectId) {
      this.webSocketService.sendToWS(WS_SEND_DESTINATION_TYPES.ACTIVE_PROJECT, [this.projectId]);
    }
  }

  private checkWorkspaceCover() {
    const activeWorkspace = this.workspaceService.getActiveWorkspace();
    if (!activeWorkspace) {
      this.workspaceCover = '';
      return;
    }

    this.workspaceService.getWorkspacesByIds(activeWorkspace.companyId, [activeWorkspace.id])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((workspaces: any[]) => {
        if (workspaces.length > 0) {
          this.workspaceService.setActiveWorkspaceCoverAndAvatar({
            avatar: workspaces[0].avatarUrl,
            cover: workspaces[0].coverUrl
          })
          if (workspaces[0].coverUrl) {
            const cover = HelperService.getCachebusterUrl(workspaces[0].coverUrl);
            this.workspaceCover = `url(${cover})`;
          } else {
            this.workspaceCover = '';
          }
        }
      });
  }

  backToAdmin() {
    this.adminService.switchToAdmin();
  }

  onBehalf() {
    return !!localStorage.getItem('admin_token');
  }

  openTourProgressBar(){
    this.modalRef = this.modalService.open(OnboardingProgressComponent, { windowClass: 'progress-modal',size: 'lg' });
  }
}
