<div class="permission-actions text-center d-flex flex-column align-items-center" [style.line-height]="1.35">
  <a *ngIf="(isAdmin || isManager) &&  params.data.status == 'COMPLETED' && (params.data.projectType!='ONE_SHOT' || (params.data.projectType == 'ONE_SHOT' && params.data.entranceFeePaid == true))"
    class="m-0 d-block user-action reinvite pointer text-uppercase" [style.color]="'#e6431e'"
    [ngClass]="{ 'mt-2': !isAdmin }"
    (click)="params.context.parentComponent.archiveProject(params.data);$event.stopPropagation()">
    <i class="fa fa-archive fa-md text-danger mr-1"></i>
    {{'archive' | translate}}
  </a>
  <a *ngIf="(isAdmin || isManager) && params.data.status == 'ARCHIVED'" [style.color]="'#e6431e'" [ngClass]="{ 'mt-2': !isAdmin }"
  (click)="$event.stopPropagation(); params.context.parentComponent.restoreProject(params.data);"
  class="d-block m-0 user-action delete pointer text-uppercase">
  <i class="fa fa-trash fa-md text-danger mr-1"></i>
  {{'restore' | translate}}
</a>
  <a *ngIf="(isAdmin || isManager) && params.data.status == 'ARCHIVED'" [style.color]="'#e6431e'" [ngClass]="{ 'mt-2': !isAdmin }"
    (click)="params.context.parentComponent.deleteProject(params.data);$event.stopPropagation()"
    class="d-block m-0 user-action delete pointer text-uppercase">
    <i class="fa fa-trash fa-md text-danger mr-1"></i>
    {{'delete' | translate}}
  </a>
  <a *ngIf="isAdmin && params.data.projectType!='ONE_SHOT' || (params.data.projectType == 'ONE_SHOT' && params.data.entranceFeePaid == true)"
    [style.color]="'#e6431e'" (click)="params.context.parentComponent.editProject(params.data)"
    class="d-block mb-0 user-action delete pointer text-uppercase">
    <i class="fa fa-cog fa-lg text-danger" aria-hidden="true"></i>
    {{'settings' | translate}}
  </a>
</div>