<div class="container" [style.max-width]="'1239px'" [ngStyle]="{'display': isNotificationView || productionView ? 'none' :'block' }"  *ngIf="!isNotificationView || !productionView" [style.min-width]="'1239px'" style="margin-top: -117px; position: relative;">
	<h4 class="text-left mt-3 pl-3 c-full-white" *ngIf="!project">{{status | translate}}</h4>
	<h4 class="text-left mt-3 pl-3 c-full-white" *ngIf="project">{{project.name}}</h4>
	<h5 class="text-left mt-3 pl-3 text-uppercase c-full-white">{{links[index]?.text || '' | translate }}</h5>
	<h6 class="text-left mt-3 pl-3">{{links[index]?.subText || '' | translate:{'TOTALSECTIONS':totalSections} }}</h6>
	<p class="text-left pl-3">
	</p>
	<ul class="list-inline steps">
		<li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active"
			*ngFor="let link of links; let i = index" [ngClass]="{'step-complete': isComplete(i)}">
			<a href="#" [routerLink]="link.url">
				<i *ngIf="isComplete(i)" class="fa fa-check-circle" aria-hidden="true"></i>
				<div *ngIf="!isComplete(i)" class="step-number">{{i + 1}}</div>
				<span class="text-uppercase">{{link.title || '' | translate}}</span>
			</a>
		</li>
		<div *ngIf="this.isTimeLineId" class="reset-btn-div">
			<button class="reset-btn _600 f-12" (click)="resetForms()">{{'generateNewTimeLine' | translate}}</button>
		</div>
	</ul>
</div>
<router-outlet></router-outlet>
