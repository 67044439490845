<div class="password-checker-component">
    <div *ngIf="showValidation">
        <div *ngIf="passwordValidations?.PASSWORD_LENGTH?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_LENGTH.valid">
                <label>&#9745; Password length valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_LENGTH.valid">
                <label>Minimum password length is {{passwordValidations.PASSWORD_LENGTH.value}}</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.NUMBER.valid">
                <label>&#9745; Password number requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.NUMBER.valid">
                <label>Password number requirement is invalid</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.SYMBOL.valid">
                <label>&#9745; Password symbol requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.SYMBOL.valid">
                <label>Password symbol requirement is invalid</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.CASES.valid">
                <label>&#9745; Password case requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.CASES.valid">
                <label>Password case requirement is invalid</label>
            </div>
        </div>
    </div>

    <div class="password-generator-section">
        <div *ngIf="!!samplePassword" class="d-flex align-items-center sample-password flex-fill py-2 w-100">
            <label class="w-100 d-flex flex-column">
                <span class="mb-1">Generate random password:</span>
                <div class="d-flex align-items-center info">
                    <span class="d-block w-100 p-2 mr-3 sample-password-label"
                        [style.line-break]="'anywhere'">{{samplePassword}}
                    </span>
                    <i class="fa fa-copy pointer mr-3" (click)="copyPassword($event)" [style.font-size]="'1rem'"
                        [attr.title]="'Password copied &#10003;'"></i>
                    <i class="fa fa-refresh pointer" [style.font-size]="'1rem'"
                        (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></i>
                </div>
            </label>
        </div>

        <div class=" group-item" *ngIf="passwordGeneratorModel">
            <div class="group-content security-policy">
                <div class="form-group d-flex align-items-center" [ngClass]="{'min-same-floor': minLength === 128}">
                    <label class="text-nowrap flex-fill" for="passwordLength">Password Length</label>
                    <ng5-slider [(ngModel)]="passwordGeneratorModel.length.value"
                        [options]="{ floor: minLength || 0, step:1, ceil: 128 }"
                        (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ng5-slider>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="numbers">Numbers</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.numbers.enable"
                            [(ngModel)]="passwordGeneratorModel.numbers.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="specialCharacters">Special Characters</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.symbols.enable"
                            [(ngModel)]="passwordGeneratorModel.symbols.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="cases">Upper Case / Lower Case mix</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.cases.enable"
                            [(ngModel)]="passwordGeneratorModel.cases.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>