import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { AuthService, User } from './auth.service';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { UUID } from "angular2-uuid";

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    constructor(private authService: AuthService, private apiService: ApiService) {

    }


    /**
     * Invite user to the system
     * @param firstName
     * @param lastName
     * @param email
     */
    inviteUser(user, authUser, role: string = ''): Observable<any> {
        let subject = new Subject<any>();

        let body: any = {
            email: (user && user.email) ? user.email : '',
            firstName: (user && user.firstName) ? user.firstName : '',
            fullName: (user && user.firstName && user.lastName) ? user.firstName + ' ' + user.lastName : '',
            lastName: (user && user.lastName) ? user.lastName : '',
            phone: (user && user.phone) ? user.phone : '',
            company: (user && user.company) ? user.company : '',
            termsAndConditionsAgree: true,
            username: (user && user.email) ? user.email : null,
            verified: true,
            id: (user && user.id) ? user.id : null,
            projectId: (user && user.projectId) ? user.projectId : null,
            inviter: (authUser) ? authUser : ''
        };

        if (user.companyId)
            body.companyId = user.companyId;

        if (user.defaultProjectRoles) {
            body.defaultProjectRoles = user.defaultProjectRoles;
        }

        let registrationUrl = body.projectId ? `/api/registration/project/${body.projectId}` : `/api/registration`
        if (role)
            registrationUrl += `?roleType=${role}`;
        else
            registrationUrl += '?roleType=COMPANY_REGULAR';
        this.apiService.httpPost(registrationUrl, body).subscribe(
            (data: any) => {
                subject.next(data);
            },
            (err: any) => {
                subject.error(err);
            });

        return subject.asObservable();
    }

    inviteUserToTeam(user, authUser, role: string = ''): Observable<any> {
        let subject = new Subject<any>();

        let body: any = {
            email: user.email,
            company: user.company,
            termsAndConditionsAgree: true,
            username: user.email,
            inviter: authUser,
            verified: true,
            campaign: user.campaign,
            drivecampaign: user.drivecampaign
        };

        if (user.defaultProjectRoles) {
            body.defaultProjectRoles = user.defaultProjectRoles;
        }

        let registrationUrl = `/api/registration/${user.company.id}/teams`;
        if (role) {
            registrationUrl += `?roleType=${role}`;
        }
        else {
            registrationUrl += '?roleType=COMPANY_REGULAR';
        }
        this.apiService.httpPost(registrationUrl, body).subscribe(
            (data: any) => {
                subject.next(data);
            },
            (err: any) => {
                subject.error(err);
            });

        return subject.asObservable();
    }


    /**
     * Return listing of users
     * @param page
     * @param limit
     * @returns {Observable<User[]>}
     */
    getInvitationRequests(page: number = 1, limit: number = 10): Observable<any[]> {
        let subject = new Subject<any[]>();

        this.apiService.httpGet('/api/registration/invitations')
            .subscribe((data: any) => {

                let invitations = new Array<any>();
                data.forEach((inv: any) => {
                    inv.avatarUrl = HelperService.getAvatarNoImage();
                    invitations.push(inv);
                });

                subject.next(invitations);
            });

        return subject.asObservable();
    }


    /**
     * Delete user from system
     * @param id
     */
    deleteInvitation(inv: any): Observable<any> {
        let subject = new Subject<any>();

        this.apiService.httpDelete('/api/registration/invitations/' + inv.email)
            .subscribe((data: any) => {
                subject.next(data);
            });

        return subject.asObservable();
    }


    /**
     * Update user
     * @param firstName
     * @param lastName
     * @param company
     * @param email
     * @param phone
     * @param password
     * @param rePassword
     */
    update(userId: any, firstName: string, lastName: string, company: string, jobTitle: string, email: string, phone: string,
        password: string, rePassword: string): Observable<any> {

        let subject = new Subject<any>();

        let body = {
            userId: userId,
            email: email,
            username: email,
            firstName: firstName,
            lastName: lastName,
            company: company,
            jobTitle: jobTitle,
            phone: phone,
            password: password,
            rePassword: rePassword,
            message: null,
            termsAndConditionsAgree: true,
        };

        this.apiService.httpPut('/api/registration', body).subscribe(
            (data: any) => {

                this.authService.setAuthUser(data.user);
                subject.next(data);
            },
            (err: any) => {
                subject.error(err);
            });

        return subject.asObservable();
    }


    /**
     * Request invitation
     * @param firstName
     * @param lastName
     * @param company
     * @param email
     * @param phone
     * @param message
     * @returns {Observable<any>}
     */
    requestInvitation(firstName: string, lastName: string, company: string, email: string, phone: string, message: string) {

        let subject = new Subject<any>();

        // Build body
        let body = {
            firstName: firstName,
            lastName: lastName,
            company: company,
            email: email,
            phone: phone,
            message: message
        };

        this.apiService.httpPost('/api/registration/invitations', body)
            .subscribe(
                (data: any) => {
                    subject.next(undefined);
                },
                (err: any) => {
                    console.error(err);
                    subject.error(err);
                });

        return subject.asObservable();
    }


    /**
     * Check if invitation code is valid
     * @param code
     */
    isInvitationCodeValid(code: string) {
        let subject = new Subject<any>();

        this.apiService.httpGet('/api/registration/' + code).subscribe(
            (data: any) => {
                subject.next(data);
            },
            (err: any) => {
                if (err.errorCode == "USER_ALREADY_DELETED")
                    err.errorCode = err.message;
                subject.error(err);
            }
        );

        return subject.asObservable();
    }

    /**
     * Registration
     * @param user
     * @param projectId
     * @param companyType
     * @returns {Observable<any>}
     */
    saveRegistration(user: User, projectId?, projectType?, companyType?, companyUrl?, companyTaxId?, roleType?, countryCode?): Observable<any> {

        let subject = new Subject<any>();
        let url = '/api/registration';
        if (projectType && companyType) {
            url = `/api/registration/${projectType}/${companyType}?companyUrl=${companyUrl}&companyTaxId=${companyTaxId}`;
            if (roleType) {
                url += `&roleType=${roleType}`;
            }
            if (countryCode) {
                url += `&countryCode=${countryCode}`;
            }
        }
        if (projectId && projectType && companyType) {
            url = `/api/registration/${projectId}/${projectType}/${companyType}?companyUrl=${companyUrl}&companyTaxId=${companyTaxId}`;
            if (roleType) {
                url += `&roleType=${roleType}`;
            }
            if (countryCode) {
                url += `&countryCode=${countryCode}`;
            }
        }

        // url += '/' + projectId;

        this.apiService.httpPost(url, user)
            .subscribe(
                (data: any) => {
                    subject.next(data);
                },
                (err: any) => {
                    console.error('Registration error', err);
                    subject.error(err);
                });

        return subject.asObservable();
    }

    /**
     * Resend Registration email
     * @param user
     * @returns {Observable<any>}
     */
    resendReqistrationEmail(user: User): Observable<any> {
        let subject = new Subject<any>();
        let url = '/api/registration/resendEmail';
        this.apiService.httpPost(url, user)
            .subscribe(
                (data: any) => {
                    subject.next(data);
                },
                (err: any) => {
                    console.error('Resending email error', err);
                    subject.error(err);
                });

        return subject.asObservable();
    }

    checkEmail(email): Observable<any> {
        return this.apiService.httpPost(`/api/registration/checkEmail/${email}`, {});
    }
}
