import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { Project } from "app/shared/interfaces/project";
import { NotificationService } from 'app/shared/services/notification.service';
import { Authority } from "../../../shared/services/permissions.service";

@Component({
	templateUrl: './project-proposals.component.html',
	styleUrls: ['./project-proposals.component.scss']
})
export class ProjectProposalsComponent implements OnInit {

	user: any = {};
	private projects: any[] = [];
	private pendingProjects: any[] = [];
	authority = Authority

	constructor(private router: Router,
		private breadcrumb: BreadcrumbService,
		public auth: AuthService) { }

	ngOnInit() {
		this.user = this.auth.getAuthUser();
	}

	inProgress() {
		this.router.navigate(['projects']);
	}

	projectInvites() {
		this.router.navigate(['projects', 'project-invitations']);
	}

	archivedProjects() {
		this.router.navigate(['projects', 'archived-projects']);
	}
}
