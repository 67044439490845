<ng-container *ngIf="! projectFilter">


    <h2 class="text-center d-block font-weight-bold mb-4" group-title>{{ 'workspace_myWorkspaces' | translate }}</h2>

    <div class="d-flex flex-wrap workspace-list">

        <div class="mt-4 workspace-item new-workspace" *ngIf="canCreateWorkspace">
            <div class="d-flex h-100 flex-column align-items-center justify-content-center text-center inner"
                (click)="newWorkspace()">
                <i class="fa fa-plus fa-5x" aria-hidden="true"></i>
                <h6 class="text-uppercase mt-3">{{'dashboard_newWorkspace' | translate}}</h6>
            </div>
        </div>

        <div class="mt-4 workspace-item" *ngIf="marketplaceIncluded">
            <div class="inner" (click)="setMarketplace()">
                <div class="d-flex justify-content-center align-items-center thumb">
                    <mtm-item-icon [name]="'Marketplace'" [imageUrl]="marketplaceAvatar" [width]="'55px'"
                        [generatedTextColor]="true" [height]="'55px'" [cacheBuster]="cacheBuster"
                        [generatedBackground]="'transparent'" [containerBackground]="'#2e2e53'"
                        [containerHeight]="'118px'" [containerWidth]="'100%'" [isFullImage]="true" [isSquare]="true"
                        [fontSize]="'1.5rem'" [fontBold]="true"></mtm-item-icon>
                </div>
                <div class="p-3 info">
                    <div class="font-weight-bold workspace-name">{{marketplaceName}}</div>
                </div>
            </div>
        </div>

        <div class="mt-4 workspace-item" *ngFor="let workspace of myWorkspaces">
            <div class="inner" (click)="setActiveWorkspace(workspace)" [class.hasImage]="workspace.avatarUrl">
                <div class="d-flex justify-content-center align-items-center thumb">
                    <mtm-item-icon [name]="workspace.name" [imageUrl]="workspace.avatarUrl" [width]="'55px'"
                        [generatedTextColor]="true" [height]="'55px'" [cacheBuster]="cacheBuster"
                        [generatedBackground]="'transparent'" [containerBackground]="'#2e2e53'"
                        [containerHeight]="'118px'" [containerWidth]="'100%'" [isFullImage]="true" [isSquare]="true"
                        [fontSize]="'1.5rem'" [fontBold]="true"></mtm-item-icon>
                    <span class="badge edit-button" *ngIf="workspace.canEdit" (click)="goToSettings(workspace)"
                    [title]="'settingsSmall' | translate">
                        <i class="fa fa-cog fa-lg text-danger" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="p-3 info">
                    <div class="text-uppercase font-weight-bold company-name" *ngIf="workspace.company"
                        title="{{workspace?.company?.name}}">{{workspace?.company?.name}}</div>
                    <div class="font-weight-bold workspace-name" title="{{workspace.name}}">{{workspace.name}}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="myWorkspaces.length == 0 && !marketplaceIncluded" class="text-center na-message">
        {{'workspace_noWorkspaceAvailable' | translate}}</div>

    <h2 class="text-center d-block font-weight-bold my-4" group-title>{{ 'workspace_otherWorkspaces' | translate }}</h2>

    <div class="d-flex flex-wrap workspace-list">

        <div class="mt-4 workspace-item" *ngFor="let workspace of otherWorkspaces">
            <div class="inner" (click)="setActiveWorkspace(workspace)" [class.hasImage]="workspace.avatarUrl">
                <div class="d-flex justify-content-center align-items-center thumb">
                    <mtm-item-icon [name]="workspace.name" [imageUrl]="workspace.avatarUrl" [width]="'55px'"
                        [generatedTextColor]="true" [height]="'55px'" [cacheBuster]="cacheBuster"
                        [generatedBackground]="'transparent'" [containerBackground]="'#2e2e53'"
                        [containerHeight]="'118px'" [containerWidth]="'100%'" [isFullImage]="true" [isSquare]="true"
                        [fontSize]="'1.5rem'" [fontBold]="true"></mtm-item-icon>
                    <span class="badge edit-button" *ngIf="workspace.canEdit" (click)="goToSettings(workspace)">
                        <i class="fa fa-cog fa-lg text-danger" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="p-3 info">
                    <div class="text-uppercase font-weight-bold company-name" *ngIf="workspace.company"
                        title="{{workspace?.company?.name}}">{{workspace?.company?.name}}</div>
                    <div class="font-weight-bold workspace-name" title="{{workspace.name}}">{{workspace.name}}</div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="otherWorkspaces.length == 0" class="text-center na-message">{{'workspace_noWorkspaceAvailable' |
        translate}}</div>

</ng-container>
<ng-container *ngIf="projectFilter">
    <div class="d-flex flex-wrap project-list">
        <div class="mt-4 project-item" *ngFor="let project of filteredProjects">
            <project-thumbnail [project]="project" [canUpdateStatus]=true (projectStatusUpdate)="updateProjectStatus($event)"
            [isFromFreePlan]="project.isFree"></project-thumbnail>
        </div>
    </div>
</ng-container>