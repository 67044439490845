export class ProposalTeamUserModel {
    id: string;
    companyId: string;
    companyName: string;
    username: string;
    jobTitle: string;
    userRole: string;
    comment: boolean = false;
    owner: boolean = false;
    read: boolean = false;
    recommend: boolean = false;
    write: boolean = false;

    public setNewUser(userName, userRole, companyId?, companyName?) {
        this.companyId = companyId;
        this.companyName = companyName;
        this.username = userName;
        this.userRole = userRole;
    }

    public setNewUserJobTitle(userName, jobTitle, userRole, companyId?, companyName?) {
        this.companyId = companyId;
        this.companyName = companyName;
        this.username = userName;
        this.jobTitle = jobTitle;
        this.userRole = userRole;
    }

}
