import {Injectable} from "@angular/core";
import {RxStompConfig, RxStomp} from "@stomp/rx-stomp";
import { UUID } from "angular2-uuid";
import { Subscription } from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {prepareTopic, WS_SUBSCRIBE_TOPICS, WS_SUBSCRIBE_TOPIC_TYPES} from "./mtm-websocket.service";

export interface TopicSubscription {
  topic: string;
  listener: Subscription;
  topicParams: any[];
  }
@Injectable({
  providedIn: 'root'
})
export class MtmStompService {
  stomp: RxStomp;
  subscriptions: any = {};
  subscriptionCount: any = {};
  records: Record<string, TopicSubscription[]>
  

  constructor(private authService: AuthService) {
    this.stomp = new RxStomp();
    this.setupConfig();
    this.stomp.activate();
    this.records = {};
  }

  private setupConfig() {
		let urlObject = new URL(environment.websocket.chatBaseUrl);
		let scheme = urlObject.protocol == "https:" ? 'wss://' : 'ws://';
		let socketUrl = scheme + urlObject.host + urlObject.pathname + "/websocket"
		const config: RxStompConfig = {
			brokerURL:socketUrl,
			// Headers
			connectHeaders: {
			  'Authorization': 'Bearer ' + this.authService.getAuthToken()
			},
			heartbeatIncoming: 60000, // Typical value 0 - disabled
			heartbeatOutgoing: 60000, // Typical value 20000 - every 20 seconds
			reconnectDelay: 500,
			// debug: (msg: string): void => {
			//   console.log(new Date(), msg);
			// },
		}
    this.stomp.configure(config);
  }

  subscribeToService() {
    let id = UUID.UUID();
    this.records[id] = [];

    return id;
  }

  subscribeToListener(topic: string, id: string, topicParams: any[], handler: any, topicEvent: any) {
    this.subscribeToTopic(WS_SUBSCRIBE_TOPIC_TYPES[topic], topicParams);
    let listener = topicEvent.subscribe((data) => {
      handler(JSON.parse(data.body || '{}'))
  });

    this.records[id].push({"topic": topic, "listener": listener, "topicParams": topicParams});
  }

  unsubscribeToService(id: string) {
    if (id in this.records) {
      this.records[id].forEach(listener => {
        this.unsubscribeTopic(WS_SUBSCRIBE_TOPIC_TYPES[listener.topic], listener.topicParams);
        listener.listener.unsubscribe();
      });
      delete this.records[id];
    }
  }

  subscribeToTopic(topic: string, params: any[]) {
    const {topicRef, wsListener} = WS_SUBSCRIBE_TOPICS[topic];
    const topicName = prepareTopic(topicRef, params);
    if (this.subscriptions[topicName]) {
      this.subscriptionCount[topicName]++;
      return;
    }
    this.subscriptions[topicName] = this.stomp.watch(topicName)
      .subscribe((message) => {
        wsListener.emit(message);
      });
    this.subscriptionCount[topicName] = 1;
  }

  unsubscribeTopic(topic: string, params: any[]) {
    const {topicRef, wsListener} = WS_SUBSCRIBE_TOPICS[topic];
    const topicName = prepareTopic(topicRef, params);
    if (!this.subscriptions[topicName]) {
      return;
    }
    this.subscriptionCount[topicName]--;
    if (this.subscriptionCount[topicName] === 0) {
      this.subscriptions[topicName].unsubscribe();
      delete this.subscriptions[topicName];
      delete this.subscriptionCount[topicName];
    }
  }

  unsubscribeAll() {
    for (const topicName in this.subscriptions) {
      this.subscriptions[topicName].unsubscribe();
    }
    this.subscriptions = {};
    this.subscriptionCount = {};
  }
}
