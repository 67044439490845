import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import 'moment-timezone';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompanyProfilePortfolioComponent } from "app/layouts/prod-partner/company-profiles/company-profile-portfolio/company-profile-portfolio.component";
import { ProdPartnerCompanyProfilesService } from 'app/shared/services/production-partner-services/company-profiles.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { ProposalInvitationService } from "../../../shared/services/proposal-invitation.service";
import { ProjectService } from "../../../shared/services/project.service";
import { ProdPartnerService } from "../../../shared/services/prod-partner.service";
import { CreatePartnerSelectionService } from 'app/shared/services/create-partner-selection.service';
import { CompanyService } from '../../../shared/services/company.service';
import { OverlayService } from "../../../shared/services/overlayService";
import { RegistrationService } from "../../../shared/services/registration.service";
import { AuthService } from "../../../shared/services/auth.service";
import { SignedUrlService } from "../../../shared/services/signedurl.service";
import { LoadingBase } from 'app/shared/bases/loading.base';
import { InviteCompanyProjectDetailModalComponent } from "./invite-company-project-detail/invite-company-project-detail-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentDetailsModalComponent } from "../../../payment/payment-details/payment-details-modal.component";
import { EmitterService } from "../../../shared/services/emitter.service";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { Authority, PermissionService } from "../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { SubscriptionService } from 'app/subscriptions/services/subscription.service';

@Component({
	selector: 'mtm-company-profiles',
	templateUrl: './company-profiles.component.html',
	styleUrls: ['./company-profiles.component.scss']
})

export class CompanyProfilesComponent extends LoadingBase implements OnInit {
	@Input() projectId: string;
	@Input() sectionId: string;
	@Input() subsectionId: string;
	@Input() isOneShotProject: boolean;
	@Output() onGetStartedClick = new EventEmitter<any>();
	@Output() onContentClick = new EventEmitter<any>();
	@ViewChild('loadingRef', { static: true }) public loadingRef: LoadingBase;

	routeSubscribe: any;
	companies: any[] = [];
	oneShotCompaniesDumbArray: any[] = [];
	step = 1;
	invited = [];
	status: any;
	clientId: any;
	clientName: any;
	clientAvatarUrl: any;
	clientCompany: any;
	allFormats: any[] = [];
	findable: any = true;
	register: boolean = false;
	authUser: any = null;
	companySelected: any;
	findCompanies: any[] = [];
	companySelectedList: any[] = [];
	isDisabledInviteButtonForAllCompany: boolean = false;
	isHiddenInviteButton: boolean = false;
	isShow: boolean = false;
	showContact: boolean = false;
	showMorePage: number = 1;
	selectedSuggestionField: any[] = [];
	contactInfo = {
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		company: '',
		additionalInfo: ''
	}
	ngUnsubscribe = new Subject();

	suggestCompanyFilter: any[] = [
		{ id: 0, label: "Overall rating", selectStatus: false, value: "OVERALL_RATING" },
		{ id: 1, label: "On Brief", selectStatus: false, value: "ON_BRIEF" },
		{ id: 2, label: "Out of the Box", selectStatus: false, value: "OUT_OF_THE_BOX" },
		{ id: 3, label: "On Time", selectStatus: false, value: "ON_TIME" },
		{ id: 4, label: "On Budget", selectStatus: false, value: "ON_BUDGET" },
		{ id: 5, label: "Good Listener", selectStatus: false, value: "GOOD_LISTENER" }
	];

	isLoading: boolean = false;
	modalPaymentDetailsModalComponent: any;

	constructor(private modalService: NgbModal,
		private serviceProdPartnerCompanyProfiles: ProdPartnerCompanyProfilesService,
		private serviceProdPartner: ProdPartnerService,
		private serviceProject: ProjectService,
		private proposalInvitationService: ProposalInvitationService,
		private serviceNotification: NotificationService,
		private serviceCreatePartnerSelection: CreatePartnerSelectionService,
		private registrationService: RegistrationService,
		private authService: AuthService,
		private companyService: CompanyService,
		private overlayService: OverlayService,
		private signedUrlService: SignedUrlService,
		private router: Router,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private subscriptionService: SubscriptionService,
		public permissionService: PermissionService,
		private workspaceService: WorkspaceService,
		private translatePipe: TranslatePipe) {
		super();
	}

	ngOnInit() {
		if (this.projectId)
			this.init();
		else
			this.routeSubscribe = this.route.parent.parent.params.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(params => {
				this.projectId = params['projectId'];
				this.init();
			});
	}


	ngOnDestroy() {
		setTimeout(() => {
			if (this.routeSubscribe)
				this.routeSubscribe.unsubscribe();
			if (this.modalPaymentDetailsModalComponent)
				this.modalPaymentDetailsModalComponent.close(true);
		}, 500)
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private init() {
		const project = this.serviceProject.project;
		const subscription = this.authService.getAuthUserSubscription();
		this.authUser = this.authService.getAuthUser();

		if (project?.projectType == 'ONE_SHOT') {
			const isEntranceFeePaid = project.entranceFeePaid;

			if (!isEntranceFeePaid) {
				if (subscription?.planType != 'ENTERPRISE') {
					this.showPaymentModal();
				} else {
					this.subscriptionService.getSubscriptionInfo()
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe((subscriptionInfo: any) => {
							if (subscriptionInfo?.subscription.endDate < (+ new Date()) / 1000) {
								this.showPaymentModal();
							} else {
								this.isOneShotProject = false;
								this.modalPaymentDetailsModalComponent.dismiss();
								this.getCompaniesProfiles();
							}
						});
				}
			}
		}

		if (!this.isOneShotProject) {

			this.selectDefaultOption();
			this.getAllFormats();
		}
		this.getCompaniesProfiles();
	}

	private showPaymentModal() {
		const project = this.serviceProject.project;
		if (project.projectType && project.projectType == 'ONE_SHOT' && project.entranceFeePaid == false) {
			this.isOneShotProject = true;

			this.modalPaymentDetailsModalComponent = this.modalService.open(PaymentDetailsModalComponent, { size: 'lg', windowClass: 'company-matched-blur-color', backdrop: false });
			this.modalPaymentDetailsModalComponent.componentInstance.projectId = this.projectId;
			this.modalPaymentDetailsModalComponent.componentInstance.projectType = 'ONE_SHOT';
			this.modalPaymentDetailsModalComponent.componentInstance.paymentType = 'ENTRANCE_FEE';
			const gotoProject = () => { this.router.navigate(['projects']) }
			this.modalPaymentDetailsModalComponent.result.then(
				res => {
					if (!res)
						gotoProject()
					else {
						this.isOneShotProject = false;
						this.modalPaymentDetailsModalComponent.dismiss();
						this.getCompaniesProfiles();
					}
				},
				err => { gotoProject() })

		}
	}

	selectDefaultOption() {
		this.suggestCompanyFilter[0].selectStatus = true;
	}

	submitContactInfo() {
		this.serviceProdPartner.sendEmailToMTMAdmin(this.projectId, this.contactInfo).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.serviceNotification.open({
				size: 'lg',
				title: '',
				description: `Thank you for leaving us your details. We'll get back to you in the next 24 hours.`,
				confirmBtn: 'Close'
			}).subscribe(() => {
				this.router.navigate(['entry/login']);
			}, error1 => {

			})
		}, error1 => {
			console.error('error', error1)
		})

	}

	navigateContent() {
		this.onContentClick.emit('true');
	}
	navigateContentInProject() {
		EmitterService.get("getContentSection").emit("CONTENT");
	}
	private getAllFormats() {
		this.serviceCreatePartnerSelection.getOptionsByName('Format').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((options: any[]) => {
			this.allFormats = options;
		}, err => console.error(err));
	}

	getCompanies() {
		return this.companies.slice(0, 6 * this.showMorePage);
	}

	showMore() {
		this.showMorePage++;
	}

	private getThumbnail(url: string, thumb: string): string {
		if (url.indexOf("?") >= 0) {
			return url + "&thumbnail=" + thumb;
		}
		return url + "?thumbnail=" + thumb;
	}

	private getCompaniesProfiles() {
		this.loadingRef.showLoading();
		this.isLoading = true;
		this.companies = [];
		this.showMorePage = 1;
		this.oneShotCompaniesDumbArray = [];
		this.serviceProdPartnerCompanyProfiles.getCompanyProfiles(this.projectId, this.isOneShotProject, this.selectedSuggestionField).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data) => {
				if (data && Array.isArray(data)) {
					data.map(cmp => {
						const images = cmp.files.filter(f => f.contentType.indexOf('image') >= 0).sort((f1, f2) => {
							return f1.timeCreated - f2.timeCreated
						}).slice(0, 3);
						cmp.avatarUrlBeautify = cmp.avatarUrl ? cmp.avatarUrl.split("'").join("\\'") : cmp.avatarUrl;
						cmp.tileImageONE = images.length >= 1 ? this.getThumbnail(images[0].signedURL, "_SMALL") : 'assets/img/samples/cover1.jpg';
						cmp.tileImageTWO = images.length >= 2 ? this.getThumbnail(images[1].signedURL, "_SMALL") : 'assets/img/samples/cover2.jpg';
						cmp.tileImageTHREE = images.length >= 3 ? this.getThumbnail(images[2].signedURL, "_SMALL") : 'assets/img/samples/cover3.jpg';
					})
				}

				if (!this.isOneShotProject) {
					if (!data || !Array.isArray(data) || data.length <= 0) {
						this.showContact = true;
					} else {
						this.companies = data;
						this.loadingRef.showInfoForArrayData(this.companies, 'Company not available');
						this.showContact = false;
						this.companies.forEach(company => {
							company.files.forEach(f => {
								this.signedUrlService.getSubSectionFileImageSignedUrl(company, f);
							});

						});
						this.getInvitedCompanies();
					}
				}
				else {
					if (data > 0) {
						this.oneShotCompaniesDumbArray = new Array(data);
						if (!this.router.url.includes('oneShot')) {
							this.presentEntranceFeeModal(data);
						}
					}
					else {
						this.showContact = true;
					}
				}
				this.hideLoading();
				this.isLoading = false;
			}, err => {
				this.loadingRef.showLoadingError();
			});
	}

	private presentEntranceFeeModal(matchedCompanyNumber) {
		if (this.serviceProject && this.serviceProject.project && this.serviceProject.project.id)
			return;

		sessionStorage.setItem('fromOneShotProjectId', this.projectId);
		this.serviceNotification.open({
			size: 'lg',
			backdrop: 'static',
			isCloseButtonHidden: true,
			subtitle: this.translatePipe.transform('discoverSubtitle'),
			description: this.translatePipe.transform('discoverDescription', { count: matchedCompanyNumber }),
			description2: this.translatePipe.transform('discoverDescription3'),
			description3: this.translatePipe.transform('discoverDescription2'),
			description4: this.translatePipe.transform('discoverDescription4'),
			confirmBtn: this.translatePipe.transform('discoverConfirmBtn')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.onGetStartedClick.emit(true);
		});
	}

	private getInvitedCompanies() {
		this.isHiddenInviteButton = true;
		if (this.permissionService.hasAuthority(Authority.R, this.projectId)) {
			this.proposalInvitationService.getProjectInvitations(this.projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((invitationStatus: any[]) => {
				this.isHiddenInviteButton = false;
				this.setInviteStatusToCompanies(invitationStatus);
			})
		}
	}

	private setInviteStatusToCompanies(invitedCompanies: any[]) {
		this.companies.map(k => {
			let invitedCompany = invitedCompanies.find(l => l.productionCompanyId == k.id);
			k.inviteStatus = invitedCompany ? invitedCompany.status : null;
		});
		this.calcDisabledInviteButtonForAllCompany();
	}

	private calcDisabledInviteButtonForAllCompany() {
		this.isHiddenInviteButton = !this.permissionService.hasAuthority(Authority.W, this.projectId);
		this.isDisabledInviteButtonForAllCompany = this.isHiddenInviteButton || this.companies.filter(k => k.inviteStatus == "IN_PROGRESS" || k.inviteStatus == "ACCEPTED").length >= 3;
	}

	/**
	 * Invite company
	 *
	 * @param company
	 */
	invite(company): void {
		if (this.invited.length < 3) {
			this.invited.push(company);
		}
	}

	/**
	 * Open new entry modal
	 */
	viewProfileModal(company, selector: 'TEXT' | 'COMPANY_PROJECT_DETAILS' = 'TEXT'): void {
		let project = this.serviceProject.project;
		this.companyService.getCompanyById(project.company.id).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.clientCompany = res;
			if (project.projectType == 'ONE_SHOT' && this.checkOneShotProject(project, company)) {
				this.openInviteCompanyProjectDetailModal(selector, company);
			} else {
				this.serviceProdPartner.getProdPartnerContent(project.id).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(prodPartnerContent => {
					let modal = this.modalService.open(CompanyProfilePortfolioComponent, { size: 'lg' });
					modal.componentInstance.modalRef = modal;
					modal.componentInstance.allFormats = this.allFormats;
					modal.componentInstance.prodPartnerContent = prodPartnerContent;
					modal.componentInstance.companyInfo = company;
					modal.result.then((v) => void 0, (v) => void 0);
				});
			}
		});
	}

	private onInvite(company, selector: 'TEXT' | 'COMPANY_PROJECT_DETAILS' = 'TEXT'): void {
		if (this.isHiddenInviteButton) {
			return;
		}
		let project = this.serviceProject.project;
		let companyId = project.company.id;
		this.clientName = project.company.name;
		this.clientId = companyId;
		this.companyService.getCompanyById(companyId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.clientCompany = res;
			if (project.projectType == 'ONE_SHOT' && this.checkOneShotProject(project, company)) {
				this.openInviteCompanyProjectDetailModal(selector, company);
			} else {
				this.notificationService.open({
					title: this.translatePipe.transform('confirmation'),
					description: this.translatePipe.transform('inviteCompany', {
						COMPANY_NAME: company.name
					}),
					confirmBtn: this.translatePipe.transform('yes'),
					cancelBtn: this.translatePipe.transform('no'),
					windowClass: 'invite-to-pitch-confirmation-modal',
					centerHeader: true
				}).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(confirm => {
					if (confirm) {
						this.clientAvatarUrl = this.clientCompany.avatarUrl;
						this.createInvite(company);
					}
				});
			}
		})
	}

	private openInviteCompanyProjectDetailModal(selector: any, company: any) {
		let modal = this.modalService.open(InviteCompanyProjectDetailModalComponent, {
			size: 'lg',
			backdrop: false,
			windowClass: 'company-project-detail-modal-company-profiles'
		});
		if (selector == 'TEXT') {
			modal.componentInstance.onSubmitForTEXT = () => {
				modal.close();
				this.onInvite(company, 'COMPANY_PROJECT_DETAILS');
			}
		}
		modal.componentInstance.selector = selector;
	}

	private checkOneShotProject(project: any, company: any) {
		if (project.name && project.manager && project.description &&
			project.currency && project.budget &&
			project.startDate != null && project.airDate != null && project.plannedEndDate != null) {
			return false;
		}
		return true;
	}


	private createInvite(company) {
		let DTO = {
			projectId: this.projectId,
			productionCompanyId: company.id,
			clientCompanyId: this.clientId,
			clientCompanyName: this.clientName,
			status: "IN_PROGRESS",
			avatarUrl: this.clientAvatarUrl
		};

		this.proposalInvitationService.saveInvitedCompany(DTO).subscribe(
			(data: any) => {
				this.status = data.status;
				this.companies.find(k => k.id == data.productionCompanyId).inviteStatus = "IN_PROGRESS";
				this.calcDisabledInviteButtonForAllCompany();
				this.overlayService.showSuccess(this.translatePipe.transform('overlayInvitationSuccess'), 'Success');
			}, err => {
				this.overlayService.showError(this.translatePipe.transform('overlayMoreInvite'), 'Error');
			});
	}

	changeSuggestedCompanyShowStatus(filter: any) {
		if (filter.id == 0) {
			return;
		}
		if (filter.selectStatus == false) {
			filter.selectStatus = true;
			this.selectedSuggestionField.push(filter.value);
		} else {
			filter.selectStatus = false;
			let index = this.selectedSuggestionField.indexOf(filter.value);
			this.selectedSuggestionField.splice(index, 1);
		}
		let companyIdList: any[] = [];
		this.companies.forEach(company => {
			companyIdList.push(company.id);
		});
		this.getCompaniesProfiles();
	}

}
