<div>
    <div class="role" *ngIf="!params.data.canChange">
        {{params.data.roleLabel}}
    </div>
    <div class="select-role" *ngIf="params.data.canChange">
        <select [(ngModel)]="params.data.role" (ngModelChange)="params.context.componentParent.updateRole(params.data)"
            class="role-select">
            <option value="">{{'Select Role'|translate}}</option>
            <option *ngFor="let role of params.context.componentParent.availableRoles" value="{{role?.code}}">
                {{role.label|translate}}</option>
        </select>
    </div>
</div>