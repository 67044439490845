import * as moment from "moment";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ProjectService } from "./project.service";
import { WorkspaceService } from "./workspace.service";
import { AuthService } from "./auth.service";
import { EmitterService } from "./emitter.service";
import { ActiveWorkspaceUpdatedEvent } from "../interfaces/workspace.interface";

@Injectable()
export class ClickableNotificationService {
	constructor(
		private router: Router,
		private authService: AuthService,
		private projectService: ProjectService,
		private workspaceService: WorkspaceService
	) { }

	private goToProposalQaComment(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		const section = projectSections.find((section: any) => section.section === 'PRODUCTION_BRIEF');
		const subSection = section.subsections.find((subsection: any) => subsection.subSection === 'Q&A');
		this.router.navigate([
			'projects',
			projectId,
			section.id,
			subSection.id,
			'preview'
		]);
	}

	private goToInvitationFirstAccepted(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		const section = projectSections.find((section: any) => section.section === 'PRODUCTION_BRIEF');
		const subSection = section.subsections.find((subsection: any) => subsection.name === 'UPLOAD_BRIEF');
		this.router.navigate(['projects', projectId, section.id, subSection.id, 'preview']);
	}

	private goToInvitationDeclined(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		const section = projectSections.find((section: any) => section.section === 'PRODUCTION_PARTNER_SELECTION');
		const subSection = section.subsections.find((subsection: any) => subsection.name === 'COMPANY_PROFILE_SUGGESTIONS');
		this.router.navigate(['projects', projectId, section.id, subSection.id, 'preview']);
	}

	private goToProposalDiscussion(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		const section = projectSections.find((section: any) => section.section === 'PRODUCTION_COMPANY_PROPOSALS');
		const subSection = section.subsections.find((subsection: any) => subsection.name === 'PROPOSALS_&_DISCUSSION');
		this.router.navigate(['projects', projectId, section.id, subSection.id, 'preview']);
	}

	private goToProposalAccepted(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		this.router.navigate(['projects', projectId, 'dashboard']);
	}

	private goToProjectInvitation(projectSections: any, notification: any) {
		this.workspaceService.setInMarketplace(true);
		this.router.navigate(['/projects']);
	}

	private goToProjectDashboard(projectSections: any, notification: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		this.router.navigate(['/projects', projectId, 'dashboard']);
	}

	private goToProjectDashboardWithChatOpened(projectSections: any, notification: any, conversation: any) {
		const projectId = notification?.projectId || notification?.object?.projectId;
		this.router.navigate(['/projects', projectId, 'dashboard'], {
			queryParams: conversation
		});
	}

	private goToProjectCalendar(projectSections: any, notification: any, noPopup?: boolean) {
		const { projectId, eventId } = notification.object;
		this.router.navigate([
			'/projects',
			projectId,
			'calendar'
		], {
			queryParams: {
				eventId: noPopup ? void 0 : eventId
			}
		});
	}

	private goToSubSection(projectSections: any, notification: any) {
		const data = this.getProjectSectionSubSection(projectSections, notification);
		const { projectId, sectionId, subSectionId } = data;
		if (!projectId || !sectionId || !subSectionId) {
			return;
		}
		let queryParams: any = {};
		if (notification.object && notification.object.fileName) {
			queryParams.fileName = notification.object.fileName.split('/').pop()
		}

		if (notification.object?.versionNumber) {
			queryParams.version = notification.object.versionNumber;
		}
		if (notification.object?.fileId) {
			queryParams.fileId = notification.object.fileId;
		}

		if(notification.object?.fileVotes){
			try{
				const votes = JSON.parse(notification.object.fileVotes);
				queryParams.fileId = Object.keys(votes)[0];
			}catch(e){
			}
		}

		if(notification?.object?.privateConditionKey){
			queryParams.privateConditionKey = notification.object.privateConditionKey;
		}
		
		this.router.navigate([
			'projects',
			projectId,
			sectionId,
			subSectionId,
			'preview'
		], {
			fragment: notification.object.optionId || notification.object.typeId || notification.object.itemId,
			queryParams: {
				...queryParams,
				type: notification.category,
				notificationCode: notification.code,
				commentId: notification.object.commentId,
				random: moment().unix()
			}
		});
	}

	getProjectSectionSubSection(projectSections, notification) {
		const section = projectSections.find(s => s.name === notification.object?.sectionName);
		const subSection = section?.subsections?.find(ss => ss.name === notification.object?.subSectionName);
		return {
			projectId: notification?.object?.projectId,
			sectionId: notification?.object?.sectionId || section?.id,
			subSectionId: notification?.object?.subSectionId || subSection?.id
		}
	}

	goArchiveProjectPage(projectSections: any, notification: any) {
		const { projectId } = notification.object;
		if (projectId) {
			this.projectService.getProjectById(projectId)
				.subscribe((res) => {
					const authUser = this.authService.getAuthUser();
					const { workspaceId } = res, { companyId } = authUser;
					this.workspaceService.getWorkspacesByIds(companyId, [workspaceId])
						.subscribe((res) => {
							if (!res.length) {
								return;
							}
							this.router.navigate(['projects'])
								.then(() => {
									setTimeout(() => {
										EmitterService.get(ActiveWorkspaceUpdatedEvent).emit({
											workspace: res[0],
											inMarketplace: false,
											isArchive: true
										});
									}, 0);
								});
						})
				});
		}
	}

	goToTaskPage(notification) {
		this.router.navigate([
			'tasks'
		], {
			queryParams: {
				projectId: notification.object.projectId,
				taskId: notification.foreignId
			}
		});
	}

	gotoNotificationSource(projectSections: any, notification: any) {
		switch (notification.code) {
			case 'PROJECT_ARCHIVED':
				this.goArchiveProjectPage(projectSections, notification);
				break;
			case 'PROJECT_USER_INVITED':
				this.goToProjectDashboard(projectSections, notification);
				break;
			case 'PROPOSAL_QA_COMMENT':
				this.goToProposalQaComment(projectSections, notification);
				break;
			case 'INVITATION_FIRST_ACCEPTED':
				this.goToInvitationFirstAccepted(projectSections, notification);
				break;
			case 'INVITATION_DECLINED':
				this.goToInvitationDeclined(projectSections, notification);
				break;
			case 'PROPOSAL_UPLOADED':
			case 'PROPOSAL_DISCUSSION_COMMENT':
				this.goToProposalDiscussion(projectSections, notification);
				break;
			case 'PROPOSAL_ACCEPTED':
				this.goToProposalAccepted(projectSections, notification);
				break;
			case 'PROJECT_INVITATION':
				this.goToProjectInvitation(projectSections, notification);
				break;
			case 'PROJECT_UPDATED':
				this.goToProjectDashboard(projectSections, notification);
				break;
			case 'EVENT_CREATED':
			case 'EVENT_USER_ADDED':
				this.goToProjectCalendar(projectSections, notification);
				break;
			case 'EVENT_USER_REMOVED':
				this.goToProjectCalendar(projectSections, notification, true);
				break;
			case 'PROJECT_DATE':
				this.goToProjectDashboard(projectSections, notification);
				break;
			case 'CONVERSATION_CREATED':
			case 'CONVERSATION_MESSAGE_SENT':
			case 'CONVERSATION_USER_ADDED':
				this.goToProjectDashboardWithChatOpened(projectSections, notification, { type: notification.object.conversationType.toLowerCase(), username: notification.object.sender, conversationId: notification.object.conversationId });
				break;
			case 'TASK_CREATED':
			case 'TASK_USER_ADDED':
			case 'TASK_DELETED':
			case 'TASK_USER_REMOVED':
			case 'TASK_STATUS_UPDATED':
			case 'TASK_UPDATED':
			case 'TASK_STATUS_OVERDUE':
			case 'TASK_STATUS_BEWARE':
			case 'TASK_STATUS_UPCOMING_EXPIRY':
				this.goToTaskPage(notification);
				break;
			default:
				!notification.object.sectionName || !notification.object.subSectionName ? this.goToProjectDashboard(projectSections, notification) : this.goToSubSection(projectSections, notification);
				break;
		}
	}
}