import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from 'app/shared/services/overlayService';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Item, Category } from 'app/shared/interfaces';
import { DummyDataService } from 'app/shared/services/dummy-data.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { AuthService } from 'app/shared/services/auth.service';
import { DateHelperService } from 'app/shared/services/date-helper.service';

export enum reasonCategoryEnum {
  PRODUCT_DELAYED = 'Product delayed',
  PACKAGING_DELAYED = 'Packaging delayed',
  EGERIE_AVAILABILITY = 'Egerie availability',
  EGERIE_SELECTION = 'Egerie selection',
  PHOTOGRAPHERS_AVAILABILITY = 'Photographers availability',
  DIRECTOR_AVAILABILITY = 'Director availability',
  BIG_IDEA_GENERATION = 'Big idea generation',
  HERO_CAMPAIGN_GENERATION = 'Hero campaign generation'
}

@Component({
  selector: 'mtm-production-timelines',
  templateUrl: './production-timelines.component.html',
  styleUrls: ['./production-timelines.component.scss', '../timeline-style.scss'],
  providers: [DatePipe],
})

export class ProductionTimelinesComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  reasonCategory = Object.values(reasonCategoryEnum);
  backgroundcolor = '#264083'
  ExpectedMad: string;
  timline: any;
  selectedTimeLine: any = [];
  selectedDate: any;
  selecteRealDate: any;
  selecteMadDate: any;
  isChangeReasonDropdownOpen: boolean = false;
  selectedReasonCategory: string = 'select Reason';
  timelineData: any;
  timelineProduct: any[] = [];
  negociationIndex: number;
  allTimeLines: boolean = true;
  subscription: Subscription;
  timelinDetails: any = [];
  ngUnsubscribe = new Subject();
  showTimeline: boolean = true;
  showCloseBtn: boolean = false;
  showProductBlock: boolean = true;
  showCampiagnBlock: boolean = true;
  showMagellanBlock: boolean = true;
  campaignTimeline = [];
  productTimeline = [];
  MegellanTimeline = [];
  expectedMadDate: any;
  changedMadDate: any;
  colors = {
    campaign: '#00000',
    product: '#502B9D',
    magellan: '#1E43A1',
    collector: '#086289',
    gift: '#923065'
  }
  selectedtimeline: any;
  workspaceId: string;
  driveCampaignId: string;
  expectedMad: string;
  url: any;
  @Output() timelineDataUpdated: EventEmitter<any> = new EventEmitter<any>();
  canEditMad: boolean = false;
  canEditMilestoneDate: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private ProjectV2ServiceService: ProjectV2ServiceService,
    private timelineService: TimelineService,
    private overlayService: OverlayService,
    private loarealDummyDataService: DummyDataService,
    private route: ActivatedRoute,
    private authUserService: AuthService,
    private datePipe: DatePipe,
    private cryptoService: CryptoService,
    private breadcrumbService: BreadcrumbService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.url = event.url
      }
    });
  }

  ngOnInit(): void {
    // set header
    const authUser = this.authUserService.getAuthUser();
    this.canEditMad = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authUserService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.U));
    this.canEditMilestoneDate = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authUserService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.U));
    // this.timelineService.componentName.next('timelineSettingComponent');

    this.loadFormGroup();
    this.route.queryParams.subscribe(query => {
      if (query && query.wid && query.dc) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
        this.driveCampaignId = this.cryptoService.decryptUsingAES256(query.dc);
      }
    });

    if (this.workspaceId && this.driveCampaignId) {
      this.getTimeLines(this.workspaceId, this.driveCampaignId);
    }

  }

  prepareBreadcrumbs(): void {
    if (this.url) {
      const route = this.url.split('?')[0];
      const searchParams = new URLSearchParams(this.url.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }
      this.breadcrumbService.hideRoute(route);
      const newRoute = `${route}?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      const newRouteName = this.timelineData.name;
      this.breadcrumbService.addFriendlyNameForRoute(newRoute, newRouteName);
    }
  }

  async getTimeLines(workspaceId: string, driveCampaignId: string): Promise<void> {
    try {
      let data = await this.timelineService.getTimeLine(workspaceId, driveCampaignId);
      this.selectedtimeline = data;
      this.timelineData = data;
      this.timelineService.componentName.next({
        componentName: 'timelineSettingComponent',
        bannerUrl: data.banner
      });
      // Assuming you have a Date object or a valid date string
      const date = new Date(data.expectedMad);
      const formattedDate = this.datePipe.transform(date, 'dd.MM.yyyy');
      this.expectedMad = formattedDate;
      this.formGroup.patchValue(data);
    } catch (error) {
      this.router.navigate(['workspaces']); // if there is no filtered projects then redirect to workspace dashboard
      console.error('Error retrieving timeline:', error);
    }
    if (this.timelineData) {
      this.prepareBreadcrumbs();
      this.timelinDetails = await this.ProjectV2ServiceService.handleTimelineData(this.timelineData);
      this.timelinDetails.forEach(timelineItem => {
        timelineItem.items.forEach(milestone => {
          if (milestone.negotiatedDate) {
            milestone.isNegociated = true;
          } else {
            milestone.isNegociated = false;
          }
        });
      });
      // default open first three block
      // Filter timelinDetails to include only items with items.length > 0
      const filteredTimelinDetails = this.timelinDetails.filter(item => item.items && item.items.length > 0);

      // Iterate over the first three filtered items and set showProductionToggleIcons to true
      filteredTimelinDetails.slice(0, 3).forEach((item, index) => {
        item.showProductionToggleIcons = true;
      });

      // Iterate over the original timelinDetails and set showProductionToggleIcons to false for other items
      this.timelinDetails.forEach(item => {
        if (!filteredTimelinDetails.includes(item)) {
          item.showProductionToggleIcons = false;
        }
      });
    } else {
      const route = new URL(this.url).pathname;
      this.breadcrumbService.hideRoute(route);
    }
  }

  async handleTimeline() {
    this.timelinDetails = await this.ProjectV2ServiceService.handleTimelineData(this.timelineData);
    this.timelinDetails.forEach(element => {
      element.showProductionToggleIcons = false;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  loadFormGroup() {
    this.formGroup = this.fb.group({
      negociatedDate: [''],
      realDate: [''],
      changedMad: [''],
      reasonCategory: [''],
      description: [''],
    });
  }



  negociatedDateChanged(inputElement: HTMLInputElement, selectedmilestone: any) {
    const inputValue = inputElement.value; // Get the value from the input element

    if (inputValue) {
      try {
        const parsedDate = new Date(inputValue);
        if (!isNaN(parsedDate.getTime())) {
          const formattedDate = parsedDate.toISOString();
          this.timelinDetails.forEach(timeline => {
            timeline.items.forEach(milestone => {
              if (milestone.id === selectedmilestone.id && milestone.name === selectedmilestone.name) {
                milestone.negotiatedDate = formattedDate;
              }
            });
          });
          this.timelineData.timelines = JSON.stringify(this.timelinDetails);
        } else {
          console.error('Invalid date format');
        }
      } catch (error) {
        console.error('Error parsing date:', error);
      }
    }
  }

  getFormatedDate(negociatedDate) {
    let date = new Date(negociatedDate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return dt + '.' + month + '.' + year;
  }

  realDateChanged(realDate: any, selectedmilestone) {
    const formattedDate = new Date(realDate.year, realDate.month - 1, realDate.day).toISOString();
    this.timelinDetails.forEach(timeline => {
      timeline.items.forEach(milestone => {
        if (milestone.id === selectedmilestone.id && milestone.name === selectedmilestone.name) {
          milestone.actualDate = formattedDate;
        }
      });
    });
    this.timelineData.timelines = JSON.stringify(this.timelinDetails)
  }

  selectTimeLine(event, timeline) {
    const filteredValue = this.selectedTimeLine.filter(value => {
      return value.name == timeline.name;
    })
    if (this.selectedTimeLine.length && filteredValue.length > 0) {
      this.selectedTimeLine.splice(this.selectedTimeLine.indexOf(filteredValue[0]), 1)
    } else {
      this.selectedTimeLine.push(timeline);
    }
  }

  timelineNegociation(milestone, isNegociated) {
    this.timelinDetails.forEach(timeline => {
      timeline.items.forEach(m => {
        if (m.id == milestone.id) {
          m.isNegociated = !m.isNegociated;
        }
      });
    });
  }

  onContinue() {
    this.onSubmit();
    this.router.navigate([
      "workspaces/campaigndrive",
      "users-permissions"
    ]);
  }

  changeReasonDropDown(value) {
    this.isChangeReasonDropdownOpen = !value;
  }

  getReasonCategory(dropdown, event) {
    this.isChangeReasonDropdownOpen = !dropdown;
    this.selectedReasonCategory = event.target.value;
  }

  madDateChanged(expectedMadDate: any) {
    if (expectedMadDate) {
      const formattedDate = new Date(expectedMadDate.year, expectedMadDate.month - 1, expectedMadDate.day).toISOString();
      this.timelineData.expectedMad = formattedDate;
    }
  }

  async onApply(): Promise<any> {
    if (this.timelineData.id) {
      try {
        this.timelineData.description = this.formGroup.value.description;
        if (this.selectedReasonCategory != 'select Reason') {
          this.timelineData['reason'] = this.selectedReasonCategory;
        }
        const result = await this.ProjectV2ServiceService.createTimeline(this.timelineData).toPromise();
        this.timelineData = result;
        this.timelineDataUpdated.emit(this.timelineData);
        this.getTimeLines(result.workspaceId, result.id);
        this.changeDetectorRef.markForCheck();
        this.overlayService.showSuccess('Date updated successfully.', 'Success');
      } catch (error) {
        this.overlayService.showError('Unable to update date.', 'Error');
      }
    }
  }

  selectedTimelines(selectedTimeLineItem) {
    if (!this.campaignTimeline.some(obj => obj.name === selectedTimeLineItem.name)) {
      this.campaignTimeline.push(selectedTimeLineItem);
    } else {
      this.campaignTimeline = this.campaignTimeline.filter(item => item.name !== selectedTimeLineItem.name);
    }
  }

  onSubmit() {
    let data = this.formGroup.value;
  }

  toggleBlockDiv() {
    this.showTimeline = false;
    this.showCloseBtn = true;
  }

  toggleProductBlock() {
    this.showProductBlock = !this.showProductBlock;
  }

  toggleCampiagnBlock() {
    this.showCampiagnBlock = !this.showCampiagnBlock;
  }

  toggleMagellanBlockBlock() {
    this.showMagellanBlock = !this.showMagellanBlock;
  }

  campiangTimelineScroll() {
    const scrollContainer = document.querySelector('.scroll-container') as HTMLElement;
    scrollContainer.addEventListener('wheel', (event) => {
      event.preventDefault();
      scrollContainer.scrollLeft += event.deltaY;
    });
  }

  timelineScrollOnWheel() {
    const scrollContainer = document.querySelector('.timeline-scroll') as HTMLElement;
    scrollContainer.addEventListener('wheel', (event) => {
      event.preventDefault();
      scrollContainer.scrollLeft += event.deltaY;
    });
  }

  timelineScrollOnClick() {
    const scrollContainer = document.getElementById("scroll-container");
    const scrollLeftButton = document.getElementById("scroll-left-button");
    const scrollRightButton = document.getElementById("scroll-right-button");
    scrollLeftButton.addEventListener("click", () => {
      scrollContainer.scrollLeft -= 10;
    });

    scrollRightButton.addEventListener("click", () => {
      scrollContainer.scrollLeft += 10;
    });
  }

  campiangTimelineDraggerScroll() {
    const scrollContainer = document.querySelector('.campiang-scroll-container') as HTMLElement;
    scrollContainer.addEventListener('wheel', (event) => {
      event.preventDefault();
      scrollContainer.scrollLeft += event.deltaY;
    });
  }

  previewTimeline() {
    this.ProjectV2ServiceService.updateSelectedDriveCampaign(this.workspaceId, this.driveCampaignId);
  }

  toggleBlock(timeline: any) {
    timeline.showProductionToggleIcons = !timeline.showProductionToggleIcons;
  }

  returnToProject() {
    const path = `workspaces/campaigndrive/dashboard`;
    this.router.navigate([path], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
        dc: this.cryptoService.encryptString(this.driveCampaignId),
      }
    });
  }
}
