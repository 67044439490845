import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'mtm-contact-us-component',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class SubscriptionRegisterContactUsComponent implements OnInit, OnChanges, OnDestroy {
	constructor(private activeModal: NgbActiveModal) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy() { }

	chatWithUs() {
		$crisp.push(["do", "chat:open"]);
		this.closeModal();
	}

	closeModal() {
		this.activeModal.close();
	}

}
