<div class="tel-input-container" [ngClass]="{'anError':isAnError}">
    <div class="btn-group custom-drop-down">
        <a (click)="setValueIsVisibleDropdown(true)"
            class="btn btn-primary d-flex align-items-center justify-content-center dropdown-toggle custom-drop-down-button h-100">
            <span class="label label-default">
                <span #flag></span>
            </span>
        </a>
        <ul class="dropdown-menu w-100 mt-5" style="min-width: 275px;" role="menu"
            [class.itemListMenu]="isVisibleDropdown==true">
            <div *ngIf="isLoadingDropdown" class="w-100 text-center d-table"><i
                    class="fa fa-spinner fa-spin text-danger" style="font-size:24px"></i></div>
            <div *ngIf="isVisibleDropdown" class="w-100 h-100" (clickOutside)="setValueIsVisibleDropdown(false)">
                <label class="form-group w-100 has-float-label mb-1">
                    <input class="form-control search-value" [(ngModel)]="searchValue" type="text" placeholder="">
                    <span>Search</span>
                </label>
                <div class="w-100 h-100">
                    <li *ngFor="let countryCode of listCountryCodes | filterTelCountry:searchValue">
                        <a class="dropdown-item  item-max-height" href="#"
                            (click)="onSelectedCountry(countryCode);false">
                            <span class="label label-default">
                                <span class="flag-icon" [ngClass]="countryCode.flagClass"></span>
                            </span>
                            {{countryCode.countryName}}
                        </a>
                    </li>
                </div>
            </div>
        </ul>
    </div>
    <label class="form-group has-float-label tel-input m-0">
        <input [(ngModel)]="phoneNumber" (ngModelChange)="modelChangeForPhoneInput($event)" (focus)="elementFocus()" 
            (blur)="blur()" [placeholder]="phoneFormatInternationalForPlaceholder"
            maxlength="16" class="form-control phone-input">
            
            <span
            class="w-100 text-truncate phoneFormatInternationalForPlaceholder">{{phoneFormatInternationalForPlaceholder}}</span>
        <!--<span class="w-100 text-truncate phoneFormatInternationalForPlaceholder mobile-number-validation">*Mobile Number</span>-->
    </label>
</div>