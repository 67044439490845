import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { MtmChatModule } from 'app/sub-modules/mtm-chat-module/mtm-chat.module';
import { ProjectTeamTableHeader } from './header/project-team-table-header.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { OverrideRolesCellComponent } from "./renderer/override-roles/override-roles-cell.component";
import { ActionCellComponent } from "./renderer/action/action-cell.component";

@NgModule({
    declarations: [
        ProjectTeamTableHeader,
        OverrideRolesCellComponent,
        ActionCellComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MtmChatModule,
        NgSelectModule
    ],
    exports: [
        ProjectTeamTableHeader,
        OverrideRolesCellComponent,
        ActionCellComponent
    ]
})
export class ProjectTeamTableModule { }
