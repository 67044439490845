import { CommentPlugin } from "../lib/comment-plugin";
import { utils } from "../lib/utils";
import { CommentList } from "./commentList";
import { Comment } from "./comment";
import {AnnotationPrivateCondition} from "../../../../interfaces";

export class Annotation extends CommentPlugin {
    id: string;
	shape: any;
	resolution: any;
	range: any;
	comments: Comment[];
	isOpen: boolean;
	commentList: CommentList;
  	privateConditionKey: AnnotationPrivateCondition = null;

    constructor(data: any, plugin: any) {
        super(plugin);
        this.id = data.id || utils.guid();
        this.resolution = null;
        this.shape = null;
        this.range = data.range || { start: null, end: null };
        this.privateConditionKey = data.privateConditionKey;
        this.buildComments(data);
    }

    buildComments(data) {
		this.commentList = new CommentList({ comments: data.comments, annotation: this }, this.plugin);
		this.comments = this.commentList.comments;
	}

    get data() {
		return {
			id: this.id,
			shape: this.shape,
			comments: this.commentList.data,
			resolution: this.resolution,
			range: this.range,
      		privateConditionKey: this.privateConditionKey
		}
	}

    updateAnnotation(data, isWSCall=false) {
        // this.plugin.fire("updateComment", {
		// 	annotationId: this.id,
		// 	commentId: this.commentList.comments[0].id,
		// 	body: this.plugin.primordial.annotationComment
		// });
		this.buildComments(data);
        // this.plugin.annotationState.stateChanged();
    }

    teardown(removeFromCollection = true) {
		if (this.commentList) this.commentList.teardown(removeFromCollection);
		if (removeFromCollection) this.plugin.annotationState.removeAnnotation(this);
	}

	static newFromData(commentStr, parentId, plugin, id = null, range=null, privateConditionKey=null) {
		var comment = Comment.dataObj(commentStr, parentId, plugin);
		var data = {
			id: id,
			comments: [comment],
			range: range,
			privateConditionKey: privateConditionKey
		};
		return new Annotation(data, plugin);

	}

}

