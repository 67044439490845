import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { AuthService } from "app/shared/services/auth.service";
import { NotificationService } from "app/shared/services/notification.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ProjectService } from "app/shared/services/project.service";


@Component({
    styleUrls: ['./project-types-modal.component.scss'],
    templateUrl: './project-types-modal.component.html'
})
export class ProjectTypesModalComponent implements OnInit, OnDestroy {

	authority = Authority;
    isAdmin: boolean = false;
    selectProjectType: boolean = false;

    constructor(private activeModal: NgbActiveModal,
        private projectService: ProjectService,
        private notificationService: NotificationService,
        private authService: AuthService,
		private permissionService: PermissionService,
        private translatePipe: TranslatePipe,
        private router: Router) {

    }

    ngOnInit(): void {
        if (this.permissionService.hasAuthority(this.authority.Z, null)) {
            this.isAdmin = true;
        }
    }

    ngOnDestroy(): void {

    }

    select(type: string) {
        if (this.projectService.projectOnloaded) {
            this.projectService.leaveFromProject();
        }

        if ((type == 'subscription' || type == 'adminSubscription') && !this.authService.hasActiveSubscription()) {
            this.showSubscribePrompt();
            return;
        }

        this.activeModal.close(type);
    }

    cancel() {
        this.activeModal.dismiss();
    }

    private showSubscribePrompt() {
        const body = $(document.getElementsByTagName('body'));
        body.addClass('modal-blur-background');
        this.notificationService.open({
            title: this.translatePipe.transform('billing_overview_label_accessDenied'),
            notificationType: 'success',
            centerHeader: true,
            question: this.translatePipe.transform('billing_overview_label_lock'),
            confirmBtn: this.translatePipe.transform('billing_overview_label_subscribeNow'),
            cancelBtn: this.translatePipe.transform('billing_overview_label_returnToDashboard')
        }).subscribe(confirm => {
            body.removeClass('modal-blur-background');
            this.activeModal.close(null);
            if (confirm) {
                this.router.navigate(['/subscription/register']);
            } else {
                this.router.navigate(['/projects']);
            }
        });
    }

    switchAdminProjectSelectionMode() {
        this.selectProjectType = !this.selectProjectType;
    }

    createAdminProject() {
        this.activeModal.close('admin');
    }
}