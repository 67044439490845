import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentMethodModalComponent } from "app/shared/components/payment-method-modal/payment-method-modal.component";
import { AuthService } from "app/shared/services/auth.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { OverlayService } from "app/shared/services/overlayService";
import { PaymentMethodService } from "app/shared/services/payment-method.service";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: 'mtm-plan-renewal-failed',
	templateUrl: './plan-renewal-failed.component.html',
	styleUrls: ['./plan-renewal-failed.component.scss']
})
export class PlanRenewalFailedComponent implements OnInit, OnChanges, OnDestroy {
	@Input() isTodayPaymentFailed: boolean;

	private ngUnsubscribe = new Subject();
	methods: any = [];
	availableMethods: string[] = ['card', 'sepa_debit'];
	flagInfos: any;
	subscription: any;
	isAfterPaymentFailed: boolean = false;
	defaultPayment: any;
	isBusy: boolean = false;

	constructor(
		private authService: AuthService,
		private paymentMethodService: PaymentMethodService,
		private modalService: NgbModal,
		private subscriptionService: SubscriptionService,
		private activeModal: NgbActiveModal,
		private overlayService: OverlayService,
		private router: Router
	) {
		this.subscription = this.authService.getAuthUserSubscription();
		this.flagInfos = this.authService.getSubscriptionCompanyFlagInfos();
		const planRenewalDate = moment(this.flagInfos.planRenewalDate * 1000);
		const dayDiff = planRenewalDate.diff(moment(), 'days');
		this.isAfterPaymentFailed = dayDiff < 0;
	}

	ngOnInit() {
		if (this.isTodayPaymentFailed) {
			this.getDefaultPayment();
			this.loadPaymentMethods();
		}
	}

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy() { }

	getDefaultPayment() {
		this.paymentMethodService.getDefaultPayment()
			.subscribe((res: any) => {
				this.defaultPayment = res.default;
			}, (err) => {
				this.overlayService.showError(JSON.stringify(err), 'Error');
			});
	}

	loadPaymentMethods() {
		this.paymentMethodService.getUserPaymentMethods()
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((methods: any) => {
				this.handleLoadedPaymentMethods(methods);
			});
	}

	addPaymentMethod() {
		const modalRef = this.modalService.open(PaymentMethodModalComponent, {
			size: 'sm',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.billingEmail = this.subscriptionService.upgradeData.invoice.billingCcEmail;
		let billingName = this.subscriptionService.upgradeData.invoice.firstName;
		if (this.subscriptionService.upgradeData.invoice.lastName)
			billingName += ' ' + this.subscriptionService.upgradeData.invoice.lastName;
		modalRef.componentInstance.billingName = billingName;
		modalRef.result.then(() => {
			this.loadPaymentMethods();
		});
	}

	handleLoadedPaymentMethods(methods: any) {
		this.methods = [];
		let cardCounter = 0, sepaCounter = 0;
		if (methods) {
			methods.forEach(method => {
				if (this.availableMethods.indexOf(method.type) == -1)
					return;
				const info: any = {
					type: method.type,
					id: method.id
				};
				if (method.type == 'card') {
					cardCounter++;
					info.label = `Card ${cardCounter}`;
					info.card = {
						brand: method.card.brand,
						last4: method.card.last4,
						expMonth: method.card.expMonth,
						expYear: method.card.expYear
					};
				}
				if (method.type == 'sepa_debit') {
					sepaCounter++;
					info.label = `Sepa debit ${sepaCounter}`;
					info.sepa = {
						last4: method.sepaDebit.last4,
						country: method.sepaDebit.country,
						bankCode: method.sepaDebit.bankCode
					};
				}

				this.methods.push(info);
			});
		}
	}

	setDefaultPayment(defaultPaymentId: string) {
		this.isBusy = true;
		this.paymentMethodService.setPaymentAsDefault(defaultPaymentId)
			.subscribe((res: any) => {
				this.defaultPayment = defaultPaymentId;
				this.isBusy = false;
			}, (err) => {
				this.isBusy = false;
				this.overlayService.showError(JSON.stringify(err), 'Error');
			});
	}

	retryPayment() {
		this.subscriptionService.retryPayment()
			.subscribe((res) => {
				this.subscriptionService
					.getSubscriptionFlagsInfo(this.authService.getAuthUserName())
					.subscribe((data) => {
						this.authService.setSubscriptionCompanyFlagInfos(data);
						EmitterService.get('subscriptionFlagInfosUpdated').emit();
					});
				this.overlayService.showSuccess('Payment Success', 'Success');
			}, (err) => {
				this.overlayService.showError(JSON.stringify(err), 'Error');
			})
	}

	managePaymentMethod() {
		this.router.navigate(['/account/manage/billing/payment-settings']);
		this.activeModal.close();
	}

	closeModal() {
		this.activeModal.close();
	}

}
