import { Injectable } from '@angular/core';
import { DateHelperService } from 'app/shared/services/date-helper.service';

@Injectable({
	providedIn: 'root'
})
export class CheckProjectDates {
	static checkProjectStartDateEndDate = (startDate, endDate): boolean => {
		if (!startDate || startDate == '' ||
			!endDate || endDate == '')
			return false;

		let timestampStartDate = DateHelperService.fromDatePickerToDate(startDate);
		let timestampEndDate = DateHelperService.fromDatePickerToDate(endDate);

		return (timestampStartDate < timestampEndDate);
	}
	static checkProjectEndDateAirDate = (endDate, airDate): boolean => {
		if (!endDate || endDate == '' ||
			!airDate || airDate == '')
			return false;

		let timestampEndDate = DateHelperService.fromDatePickerToDate(endDate);
		let timestampAirDate = DateHelperService.fromDatePickerToDate(airDate);
		return (timestampEndDate <= timestampAirDate);
	}
}
