import { Injectable } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ApiService } from "app/shared/services/api.service";
import { ProjectService } from 'app/shared/services/project.service';

import FileUpload from './file-upload';
import { MTMCustomFile } from './index';
import { AuthService } from '../auth.service';
import { OverlayService } from '../overlayService';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { UploadService } from '../upload.service';


@Injectable({
	providedIn: 'root'
})
export class SURLFileUploadService {
	public listFilesUploadStruct: { authUserName: String, projectName: string, id: string, label: string, card: any, fileUploadObject: FileUpload, isDeleted: boolean }[] = [];

	constructor(private serviceApi: ApiService,
		private serviceProject: ProjectService,
		private serviceAuth: AuthService,
		private translatePipe: TranslatePipe,
		private overlayService: OverlayService,
		private uploadService: UploadService,

		private ngbModal: NgbModal) { }

	public syncBetweenPageAndUploadingFiles(files: any[], preUploadURL: string) {
		let index = this.listFilesUploadStruct.findIndex(k => k.id == preUploadURL);
		if (index < 0)
			return [];

		this.listFilesUploadStruct[index].fileUploadObject.files = files;
		return this.listFilesUploadStruct[index].fileUploadObject.newFiles;
	}

	public uploadNewFiles(files: any[], newFiles: MTMCustomFile[], preUploadURL: string, postUploadURL: string, label: string, card: any, projectId: any, silentUpload = false, asReference = false, refFiles: any = [], parentId?: any, nativeUploadMethod?: boolean) {
		let index = this.listFilesUploadStruct.findIndex(k => k.id == preUploadURL);
		if (index >= 0 && !asReference && !nativeUploadMethod) {
			this.listFilesUploadStruct[index].fileUploadObject.newFiles = newFiles;
			this.listFilesUploadStruct[index].fileUploadObject.files = files;
			this.listFilesUploadStruct[index].fileUploadObject.startFileUpload(silentUpload, parentId, nativeUploadMethod);
		} else {
			let item = new FileUpload(
				this.translatePipe,
				this.overlayService,
				this.ngbModal,
				this.serviceApi,
				files,
				newFiles,
				preUploadURL,
				postUploadURL,
				asReference,
				refFiles,
				this.uploadService
			);
			if (!asReference) {
				this.listFilesUploadStruct.push({
					authUserName: this.serviceAuth.getAuthUserName(),
					projectName: projectId ? this.serviceProject.project.name : null,
					id: preUploadURL,
					label: label ? label : 'NOT SET CONTENT',
					card: card,
					fileUploadObject: item,
					isDeleted: false
				});
			}
			item.startFileUpload(silentUpload, parentId, nativeUploadMethod);
		}
	}
}
