<div class="row">
    <div class="col-6 my-3">
        <input [(ngModel)]="sharedSearch" class="search-box pl-2 pr-4" (ngModelChange)="search()" type="text"
            placeholder="{{'search' |  translate}}" />
        <i class="fa fa-search search-icon" aria-hidden="true"></i>
    </div>
    <div class="col-6 my-3 ">
        <div class="row justify-content-end align-items-center pr-5">
            <div class="calendar-block d-flex mr-5">
                <div class="d-flex align-items-center">
                    <div #monthYearDropdown class="d-flex align-items-center calendar-dropdown ml-auto" ngbDropdown>
                        <div class="dropdown-toggle mt-auto d-flex align-items-center p-0 pointer" ngbDropdownToggle>
                            <div class="_400 ml-2 mr-3">{{calendarViewMode | translate}}
                            </div>
                            <div class="calendar-icon-div pointer ml-auto">
                                <img src="../../../../assets/img/project-v2/calendar.svg" alt="">
                            </div>
                        </div>
                        <div class="calendar-dropdown-menu w-100" ngbDropdownMenu>
                            <div ngbDropdownItem (click)="selectCalendarMode('latest')">{{'Latest' |  translate}}</div>
                            <div ngbDropdownItem (click)="selectCalendarMode('week')">{{'Week' |  translate}}</div>
                            <div ngbDropdownItem (click)="selectCalendarMode('3_months')">{{'3 Months' |  translate}}</div>
                            <div ngbDropdownItem (click)="selectCalendarMode('6_months')">{{'6 Months' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center item-filter" container="body" ngbDropdown>
                <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis pointer f-10"
                  ngbDropdownToggle>
                  {{ shouldShowEllipsis(selectedType) ? (selectedType | slice:0:12) + '...' : selectedType }}
                </div>
                <div class="" [style.max-width]="'169px'" [style.min-width]="'169px'" ngbDropdownMenu>
                  <div class="_1-line-text-ellipsis pointer f-12" (click)="changeProjectType(sharedtype)" *ngFor="let sharedtype of sharedType"
                    ngbDropdownItem [ngbTooltip]="shouldShowEllipsis(sharedtype) ? sharedtype : null">
                    {{sharedtype |  translate}}
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col d-flex ">
        <span class="f-14 c-full-black _400"> {{'no_shared_project' | translate}} </span>
    </div>
</div>