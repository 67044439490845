import { Directive, ViewChild } from "@angular/core";

const status = {
	LOADING: 'LOADING',
	LOADED: 'LOADED',
	ERROR: 'ERROR'
}
@Directive()
export class LoadingBase {
	@ViewChild('loadingRef', { static: true }) public loadingRef: any;
	public loadingStatus: string;

	constructor() {
	}

	private setStatus(status) {
		this.loadingStatus = status;
	}

	public initLoadingParameters(loadingText = 'Loading...', errorText = 'An error while trying to retrieve data', globalClass: string[] = []) {
		this.loadingRef.initParameters(loadingText, errorText, globalClass);
	}

	public showLoading(loadingText?) {
		this.setStatus(status.LOADING);
		this.loadingRef.showLoading(loadingText);
	}

	public showLoadingError(errorText?) {
		this.setStatus(status.ERROR);
		this.loadingRef.showError(errorText)
	}

	public showLoadingInfo(infoText) {
		this.loadingRef.showInfo(infoText);
	}

	public hideLoading() {
		this.setStatus(status.LOADED);
		this.loadingRef.hide()
	}

	public showInfoForArrayData(data, infoText) {
		!Array.isArray(data) || data.length <= 0 ? this.showLoadingInfo(infoText) : this.hideLoading();
	}
}
