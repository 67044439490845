export class UserNotificationsModel {
  editingWeb: boolean = false;
  platformNotificationsWeb: boolean = false;
  projectCommunicationWeb: boolean = false;
  projectScheduleWeb: boolean = false;
  teamBuildingWeb: boolean = false;
  validationsWeb: boolean = false;
  editingMobile: boolean = false;
  platformNotificationsMobile: boolean = false;
  projectCommunicationMobile: boolean = false;
  projectScheduleMobile: boolean = false;
  teamBuildingMobile: boolean = false;
  validationsMobile: boolean = false;
  editingEmail: boolean = false;
  platformNotificationsEmail: boolean = false;
  projectCommunicationEmail: boolean = false;
  projectScheduleEmail: boolean = false;
  teamBuildingEmail: boolean = false;
  validationsEmail: boolean = false;
  notificationSummaryEmail: boolean = false;
  notificationSummaryDuration: string = "HOUR_2";
  projectNotificationsEnabled: boolean = false;
  seeOwnActions: boolean = false;
  taskWeb: boolean = false;
  taskEmail: boolean = false;
  taskMobile: boolean = false;
}

export class PlatformNotificationModel {
  platformNotificationsWeb: boolean = false;
  platformNotificationsMobile: boolean = false;
  platformNotificationsEmail: boolean = false;
}
