import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from "../../shared/services/notification.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginFormComponent } from "../../login/login-form/login-form.component";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { Subject } from 'rxjs';
import { WorkspaceService } from 'app/shared/services/workspace.service';

@Component({
	selector: 'mtm-build-request',
	templateUrl: './build-request.component.html',
	styleUrls: ['./build-request.component.scss']
})
export class BuildRequestComponent implements OnInit, OnDestroy {

	stepName: string = 'content';
	projectId: string;
	index: number = 0;
	links = [{
		name: this.translatePipe.transform('CONTENT'),
		isComplete: false,
		isActive: true,
		stepName: 'content'
	}, {
		name: this.translatePipe.transform('SCHEDULE_&_PRICING'),
		isComplete: false,
		isActive: false,
		stepName: 'scheduleAndPricing'
	}, {
		name: this.translatePipe.transform('COMPANY_PROFILE_SUGGESTIONS'),
		isComplete: false,
		isActive: false,
		stepName: 'companyProfileSuggestions'
	},];
	projectType: string;
	companyType: string;
	ngUnsubscribe = new Subject();

	constructor(private serviceNotification: NotificationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private workspaceService: WorkspaceService,
		private translatePipe: TranslatePipe) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.projectType = this.activatedRoute.snapshot.params['projectType'];
		this.companyType = this.activatedRoute.snapshot.params['companyType'];
	}

	onProjectIdChange(projectId: any) {
		this.projectId = projectId;
		this._continue()

	}

	onScheduleAndPriceSave(isSaved) {
		this._continue();
		console.log('isScheduleAndPriceSave', isSaved);
	}

	onGetStartedClick(isClicked) {
		this.selectStep(2);
		this.links[this.index].isComplete = true;
		const activeWorkspace = this.workspaceService.getActiveWorkspace();
		//if workspace -> create one shot
		if (activeWorkspace) {
			return;
		}
		let modal = this.modalService.open(LoginFormComponent, { size: 'lg', backdrop: 'static', keyboard: false, windowClass: "company-matched-blur-color" });
		modal.componentInstance.projectId = this.projectId;
		modal.componentInstance.projectType = this.projectType;
		modal.componentInstance.companyType = this.companyType;
		modal.componentInstance.fromOneShot = true;
		modal.componentInstance.modalREF = modal;

		modal.componentInstance.callbackRegistrationSuccess = () => {
			this.serviceNotification.open({
				size: 'lg',
				backdrop: 'static',
				isCloseButtonHidden: true,
				subtitle: this.translatePipe.transform('Information'),
				description: this.translatePipe.transform('subscription_register_detail_registered'),
				description3: this.translatePipe.transform('subscription_register_invitation_emailCheck'),
				confirmBtn: this.translatePipe.transform('subscription_register_detail_continue'),
			}).subscribe(() => this.closeRegisterModalAndRedirectToLogin(modal), err => this.closeRegisterModalAndRedirectToLogin(modal));
		}
		//this.navigateLoginPage();
	}


	private closeRegisterModalAndRedirectToLogin(modal) {
		modal.close();
		this.navigateLoginPage();
	}


	onContentClick() {
		this.selectStep(0);
	}

	selectStep(index: number) {
		this.index = index;
		this.links[index].isActive = true;
		this.stepName = this.links[index].stepName
		window.scrollTo(0, 0)

		// }
	}

	_continue() {
		if (this.index >= 0 && this.index < 2) {
			this.links[this.index].isComplete = true;
			this.selectStep(this.index + 1);
		}
	}

	private navigateLoginPage() { //PROJECT TYPE AND COMPANY TYPE WILL BE ADDED
		this.router.navigate(['entry/login/fromOneShot', this.projectId, this.projectType, this.companyType, true], { skipLocationChange: true });
	}

}
