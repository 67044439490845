<div class="p-2" #roleCell>
    <div *ngIf="params.data.memberOf =='PROJECT' || params.context.componentParent.isAdmin">
        <mtm-dropdown [isDisplaySearchBox]="false"
            [isDisabled]="!params.context.componentParent.isAuthUserIsClientSide && !params.context.componentParent.isAdmin"
            [title]="'Select'" [emptyListInfoText]="'Role is required'" [dotItemSelectedText]="'role selected'"
            [isDangerBorder]="!params.data?.selectedRoles?.length" [dotItemsSelectedText]="'roles selected'"
            [items]=params.context.componentParent.roles [itemValueAccessor]="'id'" [itemLabelAccessor]="'name'"
            [isMultipleSelect]=true [refObject]=params.data [preventEmptyValue]="true"
            [customClass]="params.context.componentParent.users.length/2 > params.data.index ? 'dropdown-bottom' : 'dropdown-top'"
            [objectVariableAccessor]="'selectedRoles'" (open)="dropdownOpen($event)"
            (onChangeValue)="params.context.componentParent.onChange($event,params.data)">
        </mtm-dropdown>
    </div>
    <div *ngIf="params.data.memberOf == 'PRODUCTION' && !params.context.componentParent.isAdmin">
        <mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select'" [emptyListInfoText]="'Role is required'"
            [dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
            [items]=params.context.componentParent.productionRoles [itemValueAccessor]="'id'"
            [customClass]="params.context.componentParent.users.length/2 > params.data.index ? 'dropdown-bottom' : 'dropdown-top'"
            [itemLabelAccessor]="'name'" [isMultipleSelect]=true [refObject]=params.data
            [objectVariableAccessor]="'selectedRoles'" [preventEmptyValue]="true" (open)="dropdownOpen($event)"
            (onChangeValue)="params.context.componentParent.onChange($event,params.data)">
        </mtm-dropdown>
    </div>
    <div
        *ngIf="!params.context.componentParent.isAdmin && !params.data.memberOf && params.context.componentParent.authUserHaveAValidationRight">
        <mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select'" [emptyListInfoText]="'Role is required'"
            [dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
            [customClass]="params.context.componentParent.users.length/2 > params.data.index ? 'dropdown-bottom' : 'dropdown-top'"
            [items]=params.context.componentParent.roles [itemValueAccessor]="'id'" [itemLabelAccessor]="'name'"
            [isMultipleSelect]=true [refObject]=params.data [objectVariableAccessor]="'selectedRoles'"
            (open)="dropdownOpen($event)" [preventEmptyValue]="true"
            (onChangeValue)="params.context.componentParent.onChange($event,params.data)">
        </mtm-dropdown>
    </div>
</div>