import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ShepherdService } from 'angular-shepherd';
import { UUID } from 'angular2-uuid';
import { DriveCampaignColor, DriveCampaignStatus, ProjectColor, ProjectStatus } from 'app/layouts/my-projects/project.const';
import { ActiveWorkspaceUpdatedEvent } from 'app/shared/interfaces';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { isMissionComplete } from 'app/shared/services/annotation.service';
import { AuthService } from 'app/shared/services/auth.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { HelperService } from 'app/shared/services/helper.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { ProjectService, onLoadProject } from 'app/shared/services/project.service';
import { SelfTrainerService } from 'app/shared/services/self-trainer.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import {  UserTourService } from 'app/shared/services/user-tour.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import _ from 'lodash';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mtm-campaign-drive',
  templateUrl: './campaign-drive.component.html',
  styleUrls: ['./campaign-drive.component.scss']
})
export class CampaignDriveComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDropdown, { static: false }) dropdownProjectStatus: NgbDropdown;
  @Output() domLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  defaultimage = '../../../assets/img/project-v2/hr/skin-care-3.png';
  worskspaceSearch: string;
  ngUnsubscribe = new Subject();
  isLoaded: boolean = false;
  backupDriveCampaigns = [];
  DriveCampaigns = [];
  favbackupDriveCampaigns = [];
  favDriveCampaigns = [];
  archiveDriveCampaigns = [];
  archiveBackupDriveCampaigns = [];
  timelines: any = [];
  authUser: any;
  isHrUser: boolean = false;
  isYslUser: boolean = false;
  userType: string;
  workspaceId: string;
  url: any;
  projectColor = DriveCampaignColor;
  projectStatus = DriveCampaignStatus;
  initialName: any;
  nameColor: any;
  usersList: any = {};
  usersLoaded: boolean = false;
  blobPicture: any;
  username: any;
  driveStatus: any[] = [{
    value: 'IN_PROGRESS',
    label: 'In Progress'
  }, {
    value: 'COMPLETED',
    label: 'Completed'
  }, {
    value: 'DELAYED',
    label: 'Delayed'
  }];
  categoryTags: string[] = [];
  brandId: string;
  canCreateDriveCampaign: boolean = false;
  canEditStatus: boolean = false;
  workspaceName: string;
  banner: any;
  isError: boolean = false;
  cacheBuster: string = new Date().getTime().toString();
  isActive: boolean = true;
  searchDriveCampaign: string;
  selectedItem: string;
  workspaces: any[] = [];
  selectedWorkspace: any = {
    name: 'All Workspaces',
    id: null
  };

  selectedDateFilter: any = {
    name: 'Latest',
    id: null
  };

  calendarViewMode = 'Latest';
  isAll: boolean = true;
  isFav: boolean = false;
  isArchived: boolean = false;
  hasNext: boolean = false;
  page: number = 1;
  isInit: boolean = false;
  pagingState: string;
  userTourActivity: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectV2Service: ProjectV2ServiceService,
    private translatePipe: TranslatePipe,
    private analyticService: AnalyticsService,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    public timelineService: TimelineService,
    private permissionService: PermissionService,
    public projectService: ProjectService,
    private shepherdService: ShepherdService,
    private userTourService: UserTourService,
    private workspaceService: WorkspaceService,
    private overlayService: OverlayService,
    private stService: SelfTrainerService
  ) {
    this.timelineService.lastSelectedDriveCampaign = null;
    this.timelineService.isDriveNav.emit(true);
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.url = event.url
      }
    });
    this.nameColor = this.stringToHslColor(UUID.UUID() + " " + UUID.UUID());

    // Subscribe to change avatar user
    EmitterService.get('authUser.changeAvatar').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data) => {
      if (data && data.externalUser) {
        this.username = data.user.email;
        setTimeout(() => {
          this.reloadProfilePicture();
        }, 350);
      } else if (data && data.email === this.username) {
        setTimeout(() => {
          this.reloadProfilePicture();
        }, 350);
      }
    })
  }

  ngOnInit(): void {
    // resetTab
    this.timelineService.resetTabsEmmiter.emit(true);

    this.authUser = this.authService.getAuthUser();
    this.workspaceName = this.timelineService.workspaceName;
    // for dashboard image
    this.canCreateDriveCampaign = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.P));
    this.canEditStatus = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S));
    // this.timelineService.componentName.next({
    //   componentName: 'campaignDriveComponent',
    //   bannerUrl: ''
    // });
    // EmitterService.get(ActiveWorkspaceUpdatedEvent)
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe(data => {
    //     if (data && data.workspace) {
    //       this.timelineService.workspaceBannerEmitter.emit(data.workspace.coverUrl);
    //       this.timelineService.componentName.next({
    //         componentName: 'campaignDriveComponent',
    //         bannerUrl: data.workspace.coverUrl
    //       });
    //     }
    //   })

    this.brandId = localStorage.getItem('brandId')
    this.nameColor = this.stringToHslColor(this.username);
    this.route.queryParams.subscribe(query => {
      if (query.wid) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid)
      }
    });
    this.getWorkspaceDriveCampaign();
    if (this.DriveCampaigns) {
      this.prepareBreadcrumbs();
    } else {
      const route = new URL(this.url).pathname;
      this.breadcrumbService.hideRoute(route);
    }
    this.setProfilePicture(this.authUser)
    this.getSideNavWorkspace();

    // get workspace
    this.getWorkspaces();
    // this.timelineService.componentName.next({
    //   componentName: 'campaignDriveComponent',
    //   bannerUrl: ''
    // });
    this.isInit = true;
    // trigger tabs
    this.getTabsInputs();
    this.userTourActivity = this.userTourService.getUserTourActivity();
    console.log('this.userTourActivity', this.userTourActivity)
  }

  ngAfterViewInit(): void {

    // const userSvg = '../../../assets/svg/demoUser.svg'
    // this.domLoaded.subscribe((loaded: boolean) => {
    //   console.log('loaded', loaded)
    //   if (loaded) {

    //     const steps: TourStep[] = [
    //       {
    //         id: "createDriveCampaignBtnTour",
    //         element: "#campaigndrivebtn",
    //         class: "campaign-drive-btn ml-2 center-header multiple-btn",
    //         placement: "left",
    //         title:'Create Drive Campaign',
    //         // title: `<div style="display: flex; align-items: center;"><img src="${userSvg}" alt="User Icon" width="50" height="50" style="margin-right: 10px;">Create Drive Campaign</div>`,
    //         text: "Click on the below button to create a new Drive campaign",
    //         showModal: true,
    //         button: [{
    //           action: () => {
    //             this.skip();
    //           },
    //           class: "custom-exit-button",
    //           text: "SKIP",
    //           isLoaded: true,
    //           actionOption: 'exit',
    //           secondary: false,
    //           disabled: this.isLoaded
    //         },
    //         {
    //           action: () => {
    //             this.customFunction();
    //           },
    //           class: "custom-next-button ",
    //           text: "NEXT",
    //           isLoaded: true,
    //           actionOption: 'next',
    //           secondary: false,
    //           disabled: this.isLoaded
    //         },
    //         ]

    //       },
    //       {
    //         id: "driveCampaignCard",
    //         element: "#drivecampaingcard",
    //         class: "drive-campaing-card mt-2 single-btn center-header",
    //         placement: "left",
    //         title: "View Drive Campaing",
    //         text: "Click on the below button to view created drive campaing",
    //         showModal: true,
    //         button: [{
    //           action: () => {
    //             this.tourComplete();
    //           },
    //           class: "custom-next-button",
    //           text: "NEXT",
    //           isLoaded: true,
    //           actionOption: 'next',
    //           secondary: false,
    //           disabled: this.isLoaded
    //         }],
    //       },
    //       {
    //         id: "projectDashboard",
    //         element: "#projectDashboardview",
    //         class: "project-dash-btn ml-2 center-header single-btn",
    //         placement: "right",
    //         title: "View Project Dashboard",
    //         text: `<span class="f-14">Click on icon <img src="../../../assets/img/project-v2/black-analytics.svg" alt="User Icon" width="25" height="25""> to view project dashboard</span>`,
    //         showModal: true,
    //         button: [{
    //           action: () => {
    //             this.tourComplete();
    //           },
    //           class: "custom-next-button",
    //           text: "DONE",
    //           isLoaded: true,
    //           actionOption: 'exit',
    //           secondary: false,
    //           disabled: this.isLoaded
    //         }],
    //       },
    //     ];
    //     this.userTourService.createTour(steps);
    //   }
    // })
  }

  ngOnDestroy(): void {
    this.userTourService.destroyTour();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  tourComplete() {
    console.warn('tour complete');
    localStorage.setItem('driveCampaign-tour-id', JSON.stringify(true));
  }

  customFunction() {
    // this.getBrands();
    console.warn('next called');
    localStorage.setItem('tour-id', 'createDriveCampaignBtnTour');
  }

  skip() {
    console.warn('skip called');
    localStorage.setItem('tour-id', 'createDriveCampaignBtnTour');
  }

  getSideNavWorkspace() {
    this.timelineService.selectedWorkspace.subscribe((wk: any) => {
      if (wk && wk.id) {
        this.timelineService.componentName.next({
          componentName: 'campaignDriveComponent',
          bannerUrl: wk.coverUrl
        });
        this.workspaceId = wk.id;
        this.getWorkspaceDriveCampaign();
      }
    })
  }

  // ngOnDestroy(): void {
  //   this.ngUnsubscribe.next(undefined);
  //   this.ngUnsubscribe.complete();
  // }

  prepareBreadcrumbs(): void {
    if (this.url) {
      const route = this.url.split('?')[0];
      const searchParams = new URLSearchParams(this.url.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }
      this.breadcrumbService.hideRoute(route);
      const newRoute = `${route}?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      const newRouteName = this.timelineService.workspaceName;
      this.breadcrumbService.addFriendlyNameForRoute(newRoute, newRouteName);
    }
  }

  getTimelines() {
    this.projectV2Service.getTimelines().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
      this.timelines = result;
    });
  }

  getWorkspaceDriveCampaign() {
    this.isLoaded = false;
    this.isError = false;
    this.DriveCampaigns = [];
    this.timelineService.workspaceId = this.workspaceId;
    if (!this.workspaceId) {
      this.workspaceId = ''
    }
    let archived: boolean = false;
    let limit = 15;
    if (this.isAll) {
      archived = false
    } else if (this.isArchived) {
      archived = true
    }
    this.projectV2Service.getDriveCampaign(this.workspaceId, archived).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (result: any) => {
        this.isLoaded = true;
        if (this.workspaceId) {
          this.pagingState = result.pagingState;
          this.hasNext = result.hasNext;
          this.DriveCampaigns = result.data;
          this.backupDriveCampaigns = result.data;
        } else {
          this.page = result.pagingState + 1;
          this.hasNext = result.hasNext;
          this.DriveCampaigns = result.data;
          this.backupDriveCampaigns = result.data;
        }
        // Get category or tags
        this.DriveCampaigns.forEach(drives => {
          drives['isFavorite'] = false;
          if (drives.logo) {
            drives.logo = drives.logo + `?v=${this.cacheBuster}`
          }
          if (drives.name) {
            this.categoryTags.push(drives.name);
          }
        });
        // Sort data based on alphabetical order
        this.DriveCampaigns.sort((d1, d2) => d1.name.localeCompare(d2.name));
        this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favDriveCampaigns = this.DriveCampaigns;
        this.favbackupDriveCampaigns = this.backupDriveCampaigns;
        if (archived) {
          this.archiveBackupDriveCampaigns = this.backupDriveCampaigns;
          this.archiveDriveCampaigns = this.DriveCampaigns;
        }
        this.isInit = false;
        this.getFavoriteDriveCampaigns();
        const tourIDs = ['createDriveCampaignBtnTour', 'driveCampaignCard'];
        setTimeout(() => {
          this.domLoaded.emit(this.isLoaded)
          // this.userTourService.initiate(tourIDs)
        }, 1000);
       },
      error: (error) => { // Corrected syntax for the error handling block
        console.error('error', error)
        this.isError = true;
        this.isLoaded = false;
      }
    })
  }

  searchDriveCampaigns() {
    if (this.searchDriveCampaign) {
      this.DriveCampaigns = this.backupDriveCampaigns.filter(item => {
        let isMatched = true;
        if (this.searchDriveCampaign) {
          if (!item.name) {
            isMatched = false;
          } else {
            isMatched = item.name.toLowerCase().indexOf(this.searchDriveCampaign.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.DriveCampaigns = this.backupDriveCampaigns;
          }
        }
        return isMatched;
      })
    } else {
      this.DriveCampaigns = this.backupDriveCampaigns;
    }
  }

  createCampaignDrive() {
    // reset driveCoordinatorMilestone

    this.userTourService.trackUserTourActivity('createDriveCampaignBtnTour',true);
    this.projectV2Service.driveCoordinatorMilestone = undefined;
    this.projectV2Service.timelineId = null;
    this.projectV2Service.updateFormData({}, {})
    this.router.navigate([`workspaces/campaigndrive/${this.workspaceId}/general`]);
    this.timelineService.isDriveNav.emit(false);
    this.userTourService.completeTour();
  }

  redirectToDashboard(drive) {
    this.trainerMilestoneRedirect();
    this.timelineService.componentName.next({
      componentName: 'milestoneComponent',
      bannerUrl: drive.banner
    })
    if (!this.authService.hasPaidSubscription()) {
      return;
    }
    this.timelineService.lastSelectedDriveCampaign = drive;
    this.projectV2Service.driveCoordinatorMilestone = undefined;
    const path = `workspaces/campaigndrive/dashboard`;
    this.router.navigate([path], {
      queryParams: {
        wid: this.cryptoService.encryptString(drive.workspaceId),
        dc: this.cryptoService.encryptString(drive.id),
      }
    });
      //reset stored milestone
    this.timelineService.updateMilestone({});
    // this.stService.waitForDomUpdation();
    // isMissionComplete.emit({ isComplete: true, name: 'switchToMilestone' ,elemenetId:'trainerHeroShootMilestone'});
    // this.userTourService.trackUserTourActivity('driveCampaignCard',true);

    // this.userTourService.completeTour()
  }

  // for trainer
  trainerMilestoneRedirect(){
    console.log('trainer function called')
    // this.stService.waitForDomUpdation();
    isMissionComplete.emit({ isComplete: true, name: 'switchToMilestone' ,elemenetId:'trainerHeroShootMilestone'});
  }

  editCampaignDrive(drive) {
    this.projectV2Service.updateSelectedDriveCampaign(this.workspaceId, drive.id);
  }

  // set profile picture as a name of company if image is not available
  private setProfilePicture(currentUser) {
    let fullName = currentUser.fullName ? _.get(currentUser, 'fullName', '') : _.get(currentUser, 'firstName', '') + ' ' + _.get(currentUser, 'lastName', '');
    let nameArr = fullName.toLowerCase().split(' ');
    if (nameArr.length > 0 && !HelperService.isObjectEmpty(nameArr[0][0])) {
      this.initialName = nameArr[0][0];
      if (nameArr.length > 1 && !HelperService.isObjectEmpty(nameArr[1][0])) {
        this.initialName += nameArr[1][0];
      } else {
        this.initialName += nameArr[0][1];
      }
      this.nameColor = this.stringToHslColor(this.initialName);
      this.blobPicture = _.get(currentUser, 'blobPicture') ? void 0 : currentUser.blobPicture;
    }
  }

  reloadProfilePicture() {
    this.usersLoaded = false;
    this.usersList[this.username] = void 0;
  }

  stringToHslColor(initial: any = []) {
    let hash = 0;
    if (!initial) {
      return;
    }
    for (let i = 0; i < initial.length; i++) {
      hash = initial.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', 75%, 62%)';
  }

  changeDriveCampaignStatus(item, status) {
    this.dropdownProjectStatus.close();
    item.status = status;
    this.projectV2Service.updateDriveCampaign(item)
      .subscribe(res => {
      });
  }

  deleteDriveCampaign(item) {
    this.notificationService.open({
      title: this.translatePipe.transform('confirmation'),
      notificationType: 'danger',
      centerHeader: true,
      question: this.translatePipe.transform('deleteDriveCampaignConfirm'),
      question2: this.translatePipe.transform('deleteDriveCampaignConfirmQuestion'),
      confirmBtn: this.translatePipe.transform('confirm'),
      cancelBtn: this.translatePipe.transform('cancel')
    }).subscribe(confirm => {
      if (confirm) {
        this.projectV2Service.deleteDriveCampaign(item.workspaceId, item.id)
          .subscribe(res => {
            this.DriveCampaigns = this.DriveCampaigns.filter(d => d.id != item.id);
          });
      }
    });
  }

  redirectToCampaign() {
    this.router.navigate(['workspaces/campaign'], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
      }
    });
  }
  search() {

  }

  changeWorkspace(workpsace) {
    if (workpsace === 'all_workspaces') {
      this.selectedWorkspace = {
        name: 'All Workspaces'
      }
      this.workspaceId = '';
      const navigationExtras: NavigationExtras = {
        queryParams: {}
      };

      const brand = this.authService.getMyBrand();
      this.timelineService.componentName.next({
        componentName: 'campaignDriveComponent',
        bannerUrl: brand.banner
      });

      this.router.navigate(['/workspaces/campaign-drive'], navigationExtras);
    } else {
      this.selectedWorkspace = workpsace;
      if (workpsace.id || this.workspaceId) {
        let wid = this.cryptoService.encryptString(workpsace.id);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            wid: wid,  // Use the encrypted workspaceId
          },
          queryParamsHandling: 'merge',  // Preserve existing queryParams
        });
      }
      this.workspaceId = workpsace.id;

      this.timelineService.componentName.next({
        componentName: 'campaignDriveComponent',
        bannerUrl: this.selectedWorkspace.coverUrl
      });
    }
    this.getWorkspaceDriveCampaign();
  }

  changeDate(event) {
    this.selectedDateFilter = event;
  }

  editBanner() {
  }

  removeBanner() {
  }

  getBrands() {
    this.isError = false;
    this.isLoaded = false;
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
          });
          this.isLoaded = true;
        },
        error: (err) => {
          this.isError = true;
          console.error('An error occurred:', err);
        },
        complete: () => {
          console.info('complete')
        }
      })
  }

  getWorkspaces() {
    this.workspaces = [];
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
            this.workspaces.sort((d1, d2) => d1.name.localeCompare(d2.name));
            if (this.workspaceId) {
              let workspace = this.workspaces.find(w => w.id === this.workspaceId);

              if (workspace) {
                this.selectedWorkspace = workspace;
              } else {
                console.warn('Workspace not found with id:', this.workspaceId);
              }
            }
            if (this.workspaceId) {
              const selectedWorkspace = this.workspaces.find(w => w.id == this.workspaceId);
              this.changeWorkspace(selectedWorkspace);
            } else {
              const brand = this.authService.getMyBrand();
              this.timelineService.componentName.next({
                componentName: 'campaignDriveComponent',
                bannerUrl: brand.banner
              });
            }
          })
        }, error: (err) => {
          console.error('err', err)
        }
      });
  }

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  markAsFavorite(driveCampaign) {
    this.analyticService.markAsFavorite(driveCampaign, 'DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        this.DriveCampaigns = this.DriveCampaigns.map(dc => ({
          ...dc,
          isFavorite: res.items.includes(dc.id)
        }));
      },
      error: (err) => {
        driveCampaign.isFavorite = false;
        console.error(err)
      },
      complete: () => {

      }
    });
  }

  removeFavorite(driveCampaign) {
    this.analyticService.removeAsFavorite(driveCampaign, 'DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // this.DriveCampaigns = this.DriveCampaigns.map(dc => ({
        //   ...dc,
        //   isFavorite: res.items.includes(dc.id)
        // }));
        this.getWorkspaceDriveCampaign();
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
      }
    });
  }

  selectCalendarMode(mode) {
    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        break;
      default:
        this.calendarViewMode = 'Latest';
        break;
    }
  }

  getFavoriteDriveCampaigns() {
    this.isError = false;
    this.isLoaded = false;
    this.DriveCampaigns = [];
    this.backupDriveCampaigns = [];
    this.analyticService.getFavoriteItem('DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        if (this.isFav) {
          this.DriveCampaigns = res;
          this.backupDriveCampaigns = res;
          this.DriveCampaigns.forEach(project => {
            project.isFavorite = true;
          });
          this.backupDriveCampaigns.forEach(project => {
            project.isFavorite = true;
          });
          this.isLoaded = true;
        } else if (this.isAll) {
          this.favDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.favbackupDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.isLoaded = true;
          // remove duplicate items
          this.DriveCampaigns = this.favDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupDriveCampaigns = this.favbackupDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.DriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        } else if (this.isArchived) {
          this.archiveDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.archiveBackupDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.isLoaded = true;
          // remove duplicate items
          this.DriveCampaigns = this.archiveDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupDriveCampaigns = this.archiveBackupDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.DriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        }
        this.isLoaded = true;
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getTabsInputs() {
    this.timelineService.isAll.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = true;
          this.isFav = false;
          this.isArchived = false;
          this.timelineService.emitIsAll(false);
          this.getWorkspaceDriveCampaign();
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isFavorites.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = true;
          this.isArchived = false;
          this.getFavoriteDriveCampaigns();
          this.timelineService.emitIsFavorites(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isArchived.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = false;
          this.isArchived = true;
          this.getWorkspaceDriveCampaign();
          this.timelineService.emitIsArchived(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  archiveDriveCampaign(item) {
    if (!this.workspaceId) {
      this.workspaceId = item.workspaceId;
    }
    item.archived = !item.archived;
    this.projectV2Service.updateDriveCampaign(item).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.DriveCampaigns = this.DriveCampaigns.filter(drive => drive.id != item.id);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  loadMore(check: boolean) {
    if (!check || !this.hasNext) {
      return;
    }

    this.getWorkspaceDriveCampaign();
  }
}
