<div class="videojs-annotation-control-bar-list d-flex flex-column"
	[ngClass]="{ 'annotation-on-preview': activeAnnotation?.id, 'video-container': isVideo }">
	<div class="annotation-comment-modal" #annotationCommentModal
		[ngClass]="{ 'comment-show': activeAnnotation?.id, 'annotation-toggled': annotationActiveToggled && activeAnnotation?.id }"
		[ngStyle]="{ top: (activeAnnotation?.id ? modalPosition?.top : 0) + 'px', left: (activeAnnotation?.id ? modalPosition?.left : 0) + 'px' }">
		<div class="comment-preview-container" *ngIf="activeAnnotation?.id">
			<mtm-comment #commentsComponent [projectId]="projectId" [sectionId]="sectionId"
				[subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
				[projectParticipants]="projectParticipants" [previewAnnotation]="activeAnnotation"
				[annotationPlugin]="annotationPlugin" [isPlayerReady]="isPlayerReady" [player]="player">
			</mtm-comment>
			<div #annotationConcat class="annotation-concat-toggled"
				[ngClass]="{ 'concat-show': activeAnnotation?.id, 'annotation-toggled': annotationActiveToggled && activeAnnotation?.id, 'audio-row': isAudio }"
				[ngStyle]="{ 'background-color': activeAnnotation?.id ? activeAnnotation?.color : 'transparent', bottom: (activeAnnotation?.id ? concatPosition?.bottom : 0) + 'px', height: (activeAnnotation?.id ? concatPosition?.height : 0) + 'px', left: (activeAnnotation?.id ? concatPosition?.left : 0) + 'px' }">
			</div>
		</div>
	</div>
	<div class="annotations-bar-list-container d-block"
		[ngClass]="{ 'annotation-mode-active': isAnnotationActive || isPlaying, 'audio-row': isAudio }">
		<div *ngFor="let row of annotationIn2DMatrixDataModel | slice: 0:1;let i=index;"
			class="d-block matrix-row hidden-row">
			<div *ngFor="let annotation of row;let j=index;">
				<div class="annotation-rows d-block annotation-time-range-bar" *ngIf="!annotation.range.isGeneralComment"
				[ngClass]="{ 'annotation-active': activeAnnotation?.id === annotation?.id, 'annotation-fade': activeAnnotation?.id && activeAnnotation?.id !== annotation?.id && annotationActiveToggled, 'annotation-toggled': annotationActiveToggled && activeAnnotation?.id === annotation.id, 'annotation-single': annotation?.range?.end - annotation?.range?.start <= 0.5 }"
				(click)="annotationBarClick(annotation, true)" (mouseenter)="onTimeBarMouseEnter(annotation, true)"
				(mouseleave)="onTimeBarMouseLeave(annotation, true)"
				[ngStyle]="{ width: getAnnotationWidth(annotation) + '%', left: getAnnotationLeft(annotation) + '%', 'z-index': getAnnotationZIndex(annotation) }">
			</div>
			</div>
		</div>
	</div>
	<div class="annotations-bar-list-container-scroller d-block"
		[ngClass]="{ 'annotation-mode-active': isAnnotationActive || isPlaying }">
		<div *ngFor="let row of annotationIn2DMatrixDataModel | slice: 0:(isShowMoreAnnotations ? annotationIn2DMatrixDataModel.length : 4);let i=index;"
			class="d-block matrix-row">
			<div *ngFor="let annotation of row;let j=index;">
				<div class="annotation-rows d-block annotation-time-range-bar" *ngIf="!annotation.range.isGeneralComment"
				[ngClass]="{ 'annotation-active': activeAnnotation?.id === annotation?.id, 'annotation-disabled': isAudio && activeAnnotation && activeAnnotation.id !== annotation.id, 'annotation-fade': (activeAnnotation?.id && activeAnnotation?.id !== annotation?.id && annotationActiveToggled), 'annotation-toggled': annotationActiveToggled && activeAnnotation?.id === annotation?.id, 'annotation-single': annotation?.range?.end - annotation?.range?.start <= 0.5 }"
				(click)="annotationBarClick(annotation, true)" (mouseenter)="onTimeBarMouseEnter(annotation, true)"
				(mouseleave)="onTimeBarMouseLeave(annotation, true)"
				[ngStyle]="{ width: getAnnotationWidth(annotation) + '%', left: getAnnotationLeft(annotation) + '%', 'z-index': getAnnotationZIndex(annotation) }">
			</div>
			</div>
		</div>
	</div>
	<!-- <div *ngIf="annotationIn2DMatrixDataModel.length > 4"
		class="more-annotations d-flex align-items-center justify-content-center p-1 mt-auto">
		<span class="d-inline-block" (click)="showMoreAnnotations()">
			{{ !isShowMoreAnnotations ? 'SHOW MORE ANNOTATIONS' : 'SHOW LESS' }}
		</span>
	</div> -->
</div>