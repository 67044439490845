<div class="verification-component">
	<div class="component-head d-flex flex-column" [style.max-width]="'680px'"
		[style.margin]="'1.5rem auto 1.5rem auto'">
		<div [style.text-transform]="'uppercase'" [style.color]="'#F4A143'"
			class="component-title d-flex align-items-center justify-content-center text-center">
			<span [style.color]="'#000'" [style.font-size]="'1.5rem'" [style.text-transform]="'uppercase'" class="_600">
				{{'verification_title'|translate}}
			</span>
		</div>
	</div>
	<form [style.max-width]="'680px'" [style.background-color]="'#ffffff'"
		class="verification-form d-flex flex-column m-auto p-3" novalidate>
		<div class="d-flex flex-column verification-form email-input">
			<div [style.text-transform]="'uppercase'" [style.color]="'#000'"
				class="component-title d-flex flex-column align-items-center justify-content-center text-center">
				<span class="_600" [style.font-size]="'1.25rem'">
					{{'verification_confirm_email'|translate}}
				</span>
			</div>
			<div [style.color]="'#000'"
				class="component-title d-flex flex-column align-items-center justify-content-center text-center">
				<span class="_600">
					{{'verification_email_sent_info'|translate:{ email: registerData.user.email } }}
				</span>
			</div>
			<div class="d-flex flex-column p-3" [ngClass]="{ 'input-touched': emailVerificationTouched }"
				[class.has-danger]="validation?.error?.email && submitted">
				<div class="d-flex align-items-center flex-nowrap m-auto" [style.max-width]="'360px'">
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[0]"
							#emailCode1 (focus)="emailCodeBoxPress()" (paste)="emailCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'emailCode1', 'emailCode2', null, 'emailVerificationCode[0]')"
							(keypress)="moveToElement($event, 'emailCode1', 'emailCode2', null, 'emailVerificationCode[0]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[1]"
							#emailCode2 (focus)="emailCodeBoxPress()" (paste)="emailCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'emailCode2', 'emailCode3', 'emailCode1', 'emailVerificationCode[1]')"
							(keypress)="moveToElement($event, 'emailCode2', 'emailCode3', 'emailCode1', 'emailVerificationCode[1]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[2]"
							#emailCode3 (focus)="emailCodeBoxPress()" (paste)="emailCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'emailCode3', 'emailCode4', 'emailCode2', 'emailVerificationCode[2]')"
							(keypress)="moveToElement($event, 'emailCode3', 'emailCode4', 'emailCode2', 'emailVerificationCode[2]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[3]"
							#emailCode4 (focus)="emailCodeBoxPress()" (paste)="emailCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'emailCode4', 'emailCode5', 'emailCode3', 'emailVerificationCode[3]')"
							(keypress)="moveToElement($event, 'emailCode4', 'emailCode5', 'emailCode3', 'emailVerificationCode[3]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<!-- <div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[4]"
							#emailCode5 (focus)="emailCodeBoxPress()"
							(keydown.backspace)="moveToElement($event, 'emailCode5', 'emailCode6', 'emailCode4')"
							(keypress)="moveToElement($event, 'emailCode5', 'emailCode6', 'emailCode4', 'emailVerificationCode[4]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="emailVerificationCode[5]"
							#emailCode6 (focus)="emailCodeBoxPress()"
							(keydown.backspace)="moveToElement($event, 'emailCode6', null, 'emailCode5')"
							(keypress)="moveToElement($event, 'emailCode5', 'emailCode6', 'emailCode4', 'emailVerificationCode[5]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div> -->
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.email?.INCORRECT_VERIFICATION_CODE && submitted">
					<span>{{validation.error.email.INCORRECT_VERIFICATION_CODE | translate}}</span>
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.email?.INVALID && submitted">
					<span>{{validation.error.email.INVALID | translate}}</span>
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.email?.EMPTY && submitted">
					<span>{{validation.error.email.EMPTY | translate}}</span>
				</div>
			</div>
			<div class="d-flex align-items-center m-auto">
				<span *ngIf="!resendEmailAgainCounter">{{'verification_did_not_get_email'|translate}} <a
						class="pointer resend-nav c-mtm-orange"
						(click)="resendEmail()">{{'verification_resent_email'|translate}}</a></span>
				<span *ngIf="resendEmailAgainCounter">
					{{'verification_email_resent_counter'|translate: { emailSent: emailResent ? 'Email sent. ' : '',
					counter: resendEmailAgainCounter > 9 ? resendEmailAgainCounter : '0' +
					resendEmailAgainCounter } }}
				</span>
			</div>
		</div>
		<div class="d-flex flex-column verification-form mobile-input mb-4">
			<div [style.text-transform]="'uppercase'" [style.color]="'#000'"
				class="component-title d-flex align-items-center justify-content-center text-center pt-3">
				<span [style.text-transform]="'uppercase'" class="_600" [style.font-size]="'1.25rem'">
					{{'verification_confirm_number'|translate}}
				</span>
			</div>
			<div [style.color]="'#000'"
				class="component-title d-flex flex-column align-items-center justify-content-center text-center">
				<span class="_600">
					{{'verification_phone_sent_info'|translate:{ phone: phoneView(alternatePhone ? alternatePhone :
					registerData?.user?.phone) } }}
				</span>
				<a class="mt-2 pointer c-mtm-orange"
					(click)="useAnotherMobileNumber()">{{'verification_use_different_number'|translate}}</a>
			</div>
			<div class="d-flex flex-column p-3" [ngClass]="{ 'input-touched': phoneVerificationTouched }"
				[class.has-danger]="validation?.error?.phone && submitted">
				<div class="d-flex align-items-center flex-nowrap m-auto" [style.max-width]="'360px'">
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="phoneVerificationCode[0]"
							#phoneCode1 (focus)="phoneCodeBoxPress()" (paste)="phoneCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'phoneCode1', 'phoneCode2', null, 'phoneVerificationCode[0]')"
							(keypress)="moveToElement($event, 'phoneCode1', 'phoneCode2', null, 'phoneVerificationCode[0]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="phoneVerificationCode[1]"
							#phoneCode2 (focus)="phoneCodeBoxPress()" (paste)="phoneCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'phoneCode2', 'phoneCode3', 'phoneCode1', 'phoneVerificationCode[1]')"
							(keypress)="moveToElement($event, 'phoneCode2', 'phoneCode3', 'phoneCode1', 'phoneVerificationCode[1]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="phoneVerificationCode[2]"
							#phoneCode3 (focus)="phoneCodeBoxPress()" (paste)="phoneCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'phoneCode3', 'phoneCode4', 'phoneCode2', 'phoneVerificationCode[2]')"
							(keypress)="moveToElement($event, 'phoneCode3', 'phoneCode4', 'phoneCode2', 'phoneVerificationCode[2]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
					<div class="d-flex p-1">
						<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="phoneVerificationCode[3]"
							#phoneCode4 (focus)="phoneCodeBoxPress()" (paste)="phoneCodePaste($event)"
							(keydown.backspace)="moveToElement($event, 'phoneCode4', null, 'phoneCode3', 'phoneVerificationCode[3]')"
							(keypress)="moveToElement($event, 'phoneCode4', null, 'phoneCode3', 'phoneVerificationCode[3]')"
							class="form-control char-textbox" type="text" maxlength="1">
					</div>
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.phone?.INCORRECT_VERIFICATION_CODE && submitted">
					<span>{{validation.error.phone.INCORRECT_VERIFICATION_CODE | translate}}</span>
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.phone?.INVALID && submitted">
					<span>{{validation.error.phone.INVALID | translate}}</span>
				</div>
				<div class="ml-auto mr-auto" *ngIf="validation?.error?.phone?.EMPTY && submitted">
					<span>{{validation.error.phone.EMPTY | translate}}</span>
				</div>
			</div>
			<div class="d-flex align-items-center m-auto">
				<span *ngIf="!resendPhoneAgainCounter">{{'verification_did_not_get_code'|translate}} <a
						class="pointer resend-nav c-mtm-orange"
						(click)="resendPhone()">{{'verification_resent_code'|translate}}</a></span>
				<span *ngIf="resendPhoneAgainCounter">
					{{'verification_phone_resent_counter'|translate: {codeSent: phoneResent ? 'Code sent. ' : '',
					counter: resendPhoneAgainCounter > 9 ? resendPhoneAgainCounter : '0' +
					resendPhoneAgainCounter } }}
				</span>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-center flex-column verification-form-navigation">
			<div class="d-inline-flex">
				<a (click)="submit()" class="btn mtm-button mtm-main-button" [style.color]="'#FFFFFF'"
					[style.text-transform]="'uppercase'">
					<span *ngIf="!isLoading">{{'subscription_register_detail_continue'|translate}}</span>
					<span class="d-flex align-items-center" *ngIf="isLoading">
						<span class="mr-2">Loading</span>
						<i [style.font-size]="'18px'" class="fa fa-spinner fa-pulse"></i>
					</span>
				</a>
			</div>
		</div>
	</form>
</div>