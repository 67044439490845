import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { DirectoryCreationParams } from "../interfaces";

@Injectable({
	providedIn: 'root'
})
export class DirectoryService {

	constructor(private api: ApiService) { }

	createDirectory(params: DirectoryCreationParams) {
		const data = {
			directory: params.directory,
			parentId: params.parentId
		};
		return this.api.httpPost(`/api/projects/${params.projectId}/storage/directories?isKPF=${params.isKPF}`, data);
	}

	renameFolder(projectId: any, id: any, newName: any) {
		return this.api.httpPut(`/api/projects/${projectId}/storage/${id}/rename/${newName}`, {});
	}

	deleteDirectory(projectId: any, id: any) {
		return this.api.httpDelete(`/api/projects/${projectId}/storage/${id}`);
	}

	deleteFile(projectId: any, id: string) {
		return this.api.httpDelete(`/api/projects/${projectId}/storage/${id}`);
	}

	moveFile(projectId: string, fileId: string, directoryId: string) {
		return this.api.httpPost(`/api/projects/${projectId}/storage/${fileId}/to/${directoryId}`, {});
	}
}
