<div class="container2">
  <div class="container">

    <div class="row justify-content-center">
      <h3 style="color:white; padding:0 0 5px 0; font-weight: bold">Review Your Plan</h3>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="row justify-content-center">
          <mtm-build-plan-circle [isReadOnly]="true" [existingPlan]="subscriptionService.subscriptionPlan"
            (onApply)="onApplyNowClick($event)"></mtm-build-plan-circle>
        </div>


      </div>
      <div class="col-4">
        <div class="centered">
          <div class="row justify-content-center">


            <ul class="features">
              <div class="feature-title">
                Features
              </div>
              <li>Personalised subscription plan</li>
              <li>Unlimited Projects on MTM Marketplace</li>
              <li>Full access to MTM Collaborative platform</li>
              <li>Automated Asset Managements System</li>
              <li>IM Chat & Group Chat</li>
              <li>Video Conferencing</li>
              <li>Screen Sharing</li>

              <li>Feedback System (Comments & Approval buttons)</li>
              <li>User & Permission roles</li>
            </ul>


          </div>
          <div class="row justify-content-center">
            <div class="discount">
              YOU ARE SAVING XX€ AS DISCOUNT ON YOUR PAYMENT
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6">
              <div class="row justify-content-center">
                <button class="btn btn-build" (click)="openPaymentModal(1)">PAY IN ONE-GO</button>
              </div>
              <div class="row justify-content-center note">(Get Additional discount if you pay all at once)</div>

            </div>
            <div class="col-6">
              <button class="btn btn-build" (click)="openPaymentModal(2)">PAY MONTHLY</button>
            </div>
          </div>


        </div>

      </div>
    </div>


  </div>
</div>