<div class="d-flex flex-column text-center pointer" *ngIf="!compactView">
	<div class="d-flex align-items-center folder-info">
		<img height="34" width="46" [src]="file.typeIcon" class="file-icon file"
			onError="this.src='assets/img/icons/file.svg'" (click)="handleIconClick()" />
		<h6 class="nameFileFolder _600 c-mtm-full-black text-left" (click)="handleNameClick()">
			<mtm-asset-name [file]="file" [nameAttribute]="'printName'" [allowFilenameEdit]="true" [projectId]="projectId"></mtm-asset-name>
		</h6>
	</div>
	<div class="d-flex align-items-center folder-meta">
		<span class="c-mtm-purple _500">{{file.displaySize}}</span>
		<div *ngIf="file.displayTime" class="d-flex align-items-center">
			<i [style.font-size]="'6px'" class="c-mtm-purple fa fa-circle"
				[ngClass]="{'mx-2': !tightDateMargin, 'mx-1': tightDateMargin}"></i>
			<span class="c-mtm-purple _500">{{file.displayTime | mtmDateTime}}</span>
		</div>
	</div>
</div>
<div class="compact-view file-display d-flex align-items p-1" *ngIf="compactView">
	<div class="d-flex align-items-center file-icon">
		<img *ngIf="!uploading" height="34" width="46" [src]="file.typeIcon" class="file-icon file"
			onError="this.src='assets/img/icons/file.svg'" (click)="handleIconClick()" />
		<mtm-download-upload-progress-icon class="mr-2 d-flex align-items-center" [style.height]="'36px'"
			*ngIf="uploading" [isOnLoading]="true" [widthInPixel]="28" [iconColor]="'red'">
		</mtm-download-upload-progress-icon>
	</div>
	<div class="d-flex flex-column flex-fill">
		<label class="file-name _600 text-left c-mtm-full-black m-0">
			<mtm-asset-name [file]="file" [nameAttribute]="'printName'" [allowFilenameEdit]="true" [projectId]="projectId"></mtm-asset-name>
		</label>
		<span class="file-size text-left c-mtm-purple _500">{{file.displaySize}}</span>
	</div>
	<div *ngIf="!hideDeleteButton" class="d-flex align-items-center">
		<div class="delete-icon pointer mr-2 ml-2" (click)="deleteFile()"></div>
	</div>
</div>
<div [title]="textTranscription" *ngIf="enableAudioTranscription && isAudioFile && textTranscription"
	class="text-transcription">{{textTranscription}}</div>