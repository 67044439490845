<div class="company-roles-and-permissions">
	<div class="company-roles-and-permissions-header d-flex flex-column">
		<span class="_600">{{'company_role_and_permission_title'|translate}}</span>
		<span class="sub-title">{{'company_role_and_permission_sub_title'|translate}}</span>
		<i class="fa fa-times close-button pointer" (click)="close()"></i>
	</div>
	<div class="company-roles-and-permissions-body d-flex flex-column">
		<table class="mt-4">
			<thead>
				<tr>
					<th></th>
					<th class="role-name text-center">{{'company_role_and_permission_accountOwner'|translate}}</th>
					<th class="role-name text-center">{{'company_role_and_permission_workspaceOwner'|translate}}</th>
					<th class="role-name text-center no-border-right">
						{{'company_role_and_permission_regularUser'|translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="role-section">
					<td class="_600">{{'company_role_and_permission_companyAndBillingDetails'|translate}}</td>
					<td></td>
					<td></td>
					<td class="no-border-right"></td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_manageCompanyAndBillingDetails'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr class="role-section">
					<td class="_600">{{'company_role_and_permission_companySubscription'|translate}}</td>
					<td></td>
					<td></td>
					<td class="no-border-right"></td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_manageSubscription'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_manageStorage'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_accessBillingHistory'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_managePaymentMethod'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr class="role-section">
					<td class="_600">{{'company_role_and_permission_companyUsers'|translate}}</td>
					<td></td>
					<td></td>
					<td class="no-border-right"></td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_manageTeamMembers'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr class="role-section">
					<td class="_600">{{'company_role_and_permission_companyWorkspace'|translate}}</td>
					<td></td>
					<td></td>
					<td class="no-border-right"></td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_createCompany'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_manageWorkspace'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_createProject'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="false">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>{{'company_role_and_permission_accessToInviteProject'|translate}}</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td>
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
					<td class="no-border-right">
						<div class="d-flex justify-content-center">
							<label class="mtm-checkbox mtm-checkbox-green m-0">
								<input class="form-check-input" type="checkbox" [checked]="true">
								<span class="checkmark"></span>
							</label>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>