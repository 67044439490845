<div class="d-flex align-items-center">
    <mtm-user-profile-picture [user]="params.data" [username]="params.data.username" [height]="32" [width]="32">
    </mtm-user-profile-picture>
    <div class="media-body align-self-center ml-2">
        <h6 class="media-heading m-0">{{ params.data.fullName }}</h6>
        <!--
        <h6 class="media-heading m-0 invitation" *ngIf="params.context.componentParent.reInviteStyleUserControl(params.data)==true">
            {{'invitationSent' | translate}}</h6>
        -->
        <span class="pending-activation" *ngIf="params.data.activationStatus == 'PENDING_INPROGRESS'">(Pending activation)</span>
    </div>
</div>