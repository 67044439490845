export class InvoiceDetail {
	firstName: string;
	lastName: string;
	companyName: string;
	address: string;
	city: string;
	zipCode: string;
	country: string;
	state: string;
	taxIdNumber: string;
	billingCcEmail: string;
}

export class CreditCardInfo {
	number: string;
	expiry: string;
	cvcNumber: string;
	zipCode: string;
}

export class RegisterPaymentDetail {
	invoice: InvoiceDetail;
	creditCard: CreditCardInfo;
	constructor() {
		this.invoice = new InvoiceDetail();
		this.creditCard = new CreditCardInfo();
	}
}

export class BillingInfo {
	extraUserCount: number = 0;
	extraActiveStorageCount: number = 0;
	extraArchivalStorageCount: number = 0;
}

export class SubscriptionRegister {
	plan: any;
	billingType: any;
	payment: RegisterPaymentDetail;
	billingInfo: BillingInfo;

	constructor() {
		this.payment = new RegisterPaymentDetail();
		this.billingInfo = new BillingInfo();
	}
}

export class SubscriptionProduct {
	productId: string;
	quantity: number;
}

export class SubscriptionDTO {
	planProductId: string;
	addOns: SubscriptionProduct[];
	payment: RegisterPaymentDetail;
	billingInfo: BillingInfo;

	constructor() {
		this.payment = new RegisterPaymentDetail();
		this.billingInfo = new BillingInfo();
		this.addOns = [];
	}
}

