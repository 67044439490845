import * as _ from 'lodash';
import { Directive, DoCheck, ElementRef, Input } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Directive({ selector: '[fileNameDisplay]' })
export class FileNameDisplayDirective implements DoCheck {
	@Input() fileName: string;

	isBusy: boolean = false;

	constructor(public elementRef: ElementRef) { }

	ngDoCheck(): void {
		this.elementRef.nativeElement.innerHTML = this.getDisplay(this.fileName);
	}

	getDisplay(name: any) {
		if (!name) {
			return '';
		}
		let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
		let nameArr = name.split('_');
		let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
		let savedName = '';
		if (isTime) {
			savedName = nameArr.length ? _.slice(nameArr, 0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_') : name.split('.')[0];
		} else {
			let nameSplit = name.split('.');
			savedName = _.slice(nameSplit, 0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
		}
		return savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
	}
}
