<div class="mtm-file-download-upload-preview d-flex">
	<div class="mtm-file-download-upload-preview-icon">
		<img type="image/svg+xml" [src]="getFileTypeIcon(file?.type || file?.fileType)" onError="this.src='assets/img/icons/file.svg'">
	</div>
	<div class="mtm-file-download-upload-preview-meta flex-fill d-flex flex-column justify-content-center pr-2">
		<div class="d-flex align-items-center mb-1">
			<div class="filename mr-auto flex-fill" fileNameDisplay [fileName]="file?.fileName" [title]="file?.fileName"
				[class.error]="file.uploadStatusCode == UPLOAD_STATUS_CODES.ERROR" (click)="goToSource()"></div>
			<div class="progress-in-text ml-auto">{{file?.progress | number :'1.0-0'}}%</div>
		</div>
		<div class="d-flex align-items-center additional-labels mb-1" *ngIf="file.fileSize">
			<span class="d-inline-block file-size">{{file.fileSize | mtmFileSize }}</span>
			<span class="d-inline-block" *ngIf="file.createTime">{{file.createTime | mtmDateTime }}</span>
		</div>
		<div class="mtm-file-download-upload-preview-progress mt-2">
			<div class="progress-background" [style.width]="(file.progress || 0) + '%'"></div>
		</div>
	</div>
	<div class="mtm-file-download-upload-preview-nav d-flex align-items-center">
		<div class="mtm-file-download-upload-preview-pause-icon pl-3"
			(click)="$event.stopPropagation(); pauseUpload(file)"
			*ngIf="file.isResumable && file.uploadStatusCode == UPLOAD_STATUS_CODES.SENDING" role="button">
			<i class="fa fa-pause-circle-o" aria-hidden="true"></i>
		</div>
		<div class="mtm-file-download-upload-preview-resume-icon" *ngIf="file.isResumable && 
	resumableStatusCodes.includes(file.uploadStatusCode)" (click)="$event.stopPropagation(); resumeUpload(file)"
			role="button">
			<i class="fa fa-play-circle-o" aria-hidden="true"></i>
		</div>
		<div class="mtm-file-download-upload-preview-close-icon pl-3"
			(click)="$event.stopImmediatePropagation();onRemoveFromList($event)">
			<i class="fa fa-times-circle"></i>
		</div>
	</div>
</div>