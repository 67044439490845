import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { MtmChatModule } from 'app/sub-modules/mtm-chat-module/mtm-chat.module';
import { ActionCellComponent } from "./renderer/action/action-cell.component";
import { UserCellComponent } from "./renderer/user/user-cell.component";
import { RoleCellComponent } from "./renderer/role/role-cell.component";
import { DecisionMakerCellComponent } from "./renderer/decision-maker/decision-maker-cell.component";
import { EmailCellComponent } from "./renderer/email/email-cell.component";
import { ProjectPermissionTableHeader } from "./header/project-permission-table-header.component";
import { TransferProjectsRightsCellComponent } from "./renderer/transfer/transfer-project-rights-cell.component";

@NgModule({
    declarations: [
        ActionCellComponent,
        UserCellComponent,
        RoleCellComponent,
        DecisionMakerCellComponent,
        EmailCellComponent,
        ProjectPermissionTableHeader,
        TransferProjectsRightsCellComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MtmChatModule
    ],
    exports: [
        ActionCellComponent,
        UserCellComponent,
        RoleCellComponent,
        DecisionMakerCellComponent,
        EmailCellComponent,
        ProjectPermissionTableHeader,
        TransferProjectsRightsCellComponent
    ]
})
export class ProjectPermissionTableModule { }
