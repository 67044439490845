import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FileReorderService {
	public projectId = "";
	public sectionId = "";
	public subSectionId = "";

	public item: any = {};

	public files: any[] = [];

	/* ------- * * ------- */

	public isFileReorderShowState: boolean = false;

	public deleteFileOverrideMethod: any;

	/* ------- * * ------- */

	public removedEmitFileForReorderModal: EventEmitter<any> = new EventEmitter<any>();

	/* ------- * * ------- */

	private init() {
		this.projectId = "";
		this.sectionId = "";
		this.subSectionId = "";
		this.item = {};
		this.files = [];
		this.deleteFileOverrideMethod = null;
	}

	public setReorderItems(projectId = "", sectionId = "", subSectionId = "", item: any, files: any[] = [], deleteFileOverrideMethod: any) {
		this.init();

		this.projectId = projectId;
		this.sectionId = sectionId;
		this.subSectionId = subSectionId;
		this.item = item;
		this.files = files;
		this.deleteFileOverrideMethod = deleteFileOverrideMethod;

		this.setLocalFileReorderId();
		this.showModal();
	}

	private setLocalFileReorderId() {
		let index = 1;
		this.files.forEach(file => file.fileReorderId = index++);
	}

	public showModal() {
		this.isFileReorderShowState = true;
	}

	public closeModal() {
		this.isFileReorderShowState = false;
	}
}
