import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { SignupRoutingModule } from "./signup.route";
import { SignupDetailComponent } from "./components/detail/detail.component";
import { SignupGuard } from "./shareds/core/signup.guard";
import { SignupService } from "./shareds/services/signup.service";
import { SharedModule } from "app/sub-modules/SharedModule";

@NgModule({
    declarations: [
        SignupDetailComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
        SignupRoutingModule
    ],
    exports: [],
    providers: [
        AuthService,
        SignupService,
        SignupGuard
    ]
})
export class SignupModule { }