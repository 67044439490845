<div class="item">
<div class="row justify-content-center">
    <div class="col p-1" [style.max-width]="'85px'" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]">
            <ngx-skeleton-loader count="1" animation="progress-dark"
                [theme]="{ 'border-radius': '0', height: '30px', width:'83px' ,margin:'1px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress-dark"
                [theme]="{ 'border-radius': '0', height: '60px', width:'83px' ,margin:'1px'}"></ngx-skeleton-loader>
    </div>
</div>

</div>