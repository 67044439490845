import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { ApiService } from "./api.service";
import { Team, TeamByIdParams } from "app/shared/interfaces/team.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(private apiService: ApiService) {

  }

  saveTeam(team: Team) {
    const endpoint = '/api/teams';
    if (team.id) {
      return this.apiService.httpPut(endpoint, team);
    } else {
      return this.apiService.httpPost(endpoint, team);
    }
  }

  getTeamsByWorkspaceId(companyId: string, brandId: string, workspaceId: string) {
    const endpoint = `/api/teams/${companyId}/${brandId}/${workspaceId}`;
    return this.apiService.httpGet(endpoint);
  }

  getTeamByProjectId(companyId, brandId, workspaceId, projectId) {
    const endpoint = `/api/teams/${companyId}/${brandId}/${workspaceId}/${projectId}`;
    return this.apiService.httpGet(endpoint);
  }

  getTeamById(params: TeamByIdParams) {
    const endpoint = `/api/teams/${params.companyId}/${params.brandId}/${params.workspaceId}/${params.projectId}/${params.id}`;
    return this.apiService.httpGet(endpoint);
  }

  deleteTeam(params: TeamByIdParams) {
    const endpoint = `/api/teams/${params.companyId}/${params.brandId}/${params.workspaceId}/${params.projectId}/${params.id}`;
    return this.apiService.httpDelete(endpoint);
  }

  addMember(team: Team) {
    const endpoint = `/api/teams/add-members`;
    return this.apiService.httpPut(endpoint, team);
  }

  removeMember(team: Team) {
    const endpoint = `/api/teams/remove-members`;
    return this.apiService.httpPut(endpoint, team);
  }

}
