<div class="plan-renewal-failed-component p-4">
	<i class="fa fa-times" (click)="closeModal()" [style.position]="'absolute'" [style.top]="'1rem'"
		[style.right]="'1rem'"></i>
	<div class="plan-renewal-failed-head mb-4">
		<div class="c-mtm-brand t-uppercase _700 text-center">Plan Renewal Failed</div>
	</div>
	<div class="plan-renewal-failed-body d-flex flex-column">
		<div class="plan-renewal-failed-info ml-auto mr-auto" *ngIf="isAfterPaymentFailed" [style.max-width]="'460px'">
			<div class="text-center mb-4">
				Unfortunately we could not process the payment for the renewal of your subcription plan. Please select
				or add a new payment method, we will process your payment again in the next 2-3 days.
			</div>
		</div>
		<div class="plan-renewal-failed-info ml-auto mr-auto" *ngIf="!isAfterPaymentFailed" [style.max-width]="'460px'">
			<div class="text-center mb-4">
				Unfortunately we could not process the payment for the renewal of your subscription plan. Your account
				will be locked until your payment is processed.
			</div>
			<div class="text-center mb-4">
				Please select or add a new payment method and retry your payment to continue using MTM.
			</div>
		</div>
		<div class="plan-renewal-failed-payment-method pl-4 pr-4 pt-3" *ngIf="isAfterPaymentFailed">
			<div class="text-center">
				<button (click)="managePaymentMethod()"
					class="btn mtm-button b-mtm-green t-uppercase c-full-white _600 pt-2 pb-2 pl-3 pr-3">
					Manage payment method
				</button>
			</div>
		</div>
		<div class="plan-renewal-failed-payment-method pl-4 pr-4 pt-3" *ngIf="!isAfterPaymentFailed">
			<div class="d-block mb-3">
				<div class="c-mtm-brand t-uppercase _700">Payment Methods</div>
			</div>
			<div class="d-block">
				<div class="payment-method-list d-flex flex-wrap mb-3">
					<ng-container *ngFor="let method of methods">
						<div class="flex-fill d-flex mr-4 method-wrapper pb-3"
							*ngIf="availableMethods.indexOf(method.type) > -1">
							<div class="mr-1">
								<input [checked]="defaultPayment === method.id" type="radio"
									(change)="setDefaultPayment(method.id)" name="paymentMethodId_{{method.id}}"
									value="{{ method.id }}">
							</div>
							<div class="d-flex flex-column">
								<div class="card-info" *ngIf="method.type == 'card'">
									<div class="name">{{method.label }} ( XXXX {{ method.card.last4 }} )</div>
									<div class="font-weight-bold text-capitalize brand">{{method.card.brand}}</div>
								</div>
								<div class="sepa-info" *ngIf="method.type == 'sepa_debit'">
									<div class="name">{{method.label }} ( XXXX {{ method.sepa.last4 }} )</div>
									<div class="font-weight-bold text-capitalize country">{{method.sepa.country}}</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="d-block mb-4">
					<button class="btn d-inline c-mtm-purple mtm-btn b-mtm-grey _600 pt-2 pb-2 pl-3 pr-3 t-uppercase"
						(click)="addPaymentMethod()">
						<span>+</span>
						<span class="t-uppercase">Add a new payment method</span>
					</button>
				</div>
				<div class="text-center">
					<button [disabled]="isBusy || !defaultPayment"
						class="btn mtm-button b-mtm-green t-uppercase c-full-white _600 pt-2 pb-2 pl-3 pr-3"
						(click)="retryPayment()">
						Retry Payment
					</button>
				</div>
			</div>
		</div>
	</div>
</div>