import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { ProjectService } from "app/shared/services/project.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ActivityLogAccessArgs, ActivityLogService } from "app/shared/services/activity-log.service";
import { Subject, takeUntil } from "rxjs";
import { ActivityLogLevels } from "app/shared/interfaces";

@Component({
  selector: 'selector-activity-log',
  templateUrl: './activity-log-button.component.html',
  styleUrls: ['./activity-log-button.component.scss']
})
export class ActivityLogButton implements OnInit, OnDestroy {

  todays: any[] = [];
  past: any[] = [];
  accessInfo: ActivityLogAccessArgs;
  ngUnsubscribe = new Subject<boolean>();

  constructor(private router: Router,
    private projectService: ProjectService,
    private activityLogService: ActivityLogService,
    public permissionService: PermissionService) {
  }

  ngOnInit() {
    this.activityLogService.activityLogAccess
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((accessInfo: ActivityLogAccessArgs) => {
        this.accessInfo = accessInfo;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  isVisited() {
    if (this.router.url == `/projects/${this.projectService.project.id}/activity`) {
      return "activitylog-btn-visited"
    }
    return ""
  }

  isLogAvailable() {
    return this.projectService.project && this.projectService.project.id &&
      this.permissionService.hasAuthority(Authority.U, this.projectService.project.id)
  }

  goToActivityLog() {
    if (!this.accessInfo.hasAccess) {
      return;
    }

    switch (this.accessInfo.level) {
      case ActivityLogLevels.COMPANY:
        this.router.navigate(['/account/company-activity']);
        break;
      case ActivityLogLevels.WORKSPACE:
        this.router.navigate(['/account/company-activity/workspace', this.accessInfo.workspaceId]);
        break;
      case ActivityLogLevels.PROJECT:
        this.router.navigate(['projects', this.projectService.project.id, 'activity']);
        break;
      case ActivityLogLevels.CAMPAIGN:
        this.router.navigate(['/account/company-activity/campaign', this.accessInfo.workspaceId, this.accessInfo.campaignId]);
        break;
      case ActivityLogLevels.DRIVE_CAMPAIGN:
        this.router.navigate(['/account/company-activity/drive-campaign', this.accessInfo.workspaceId, this.accessInfo.campaignId]);
        break;
    }
  }
}
