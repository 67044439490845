import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { UploadService } from './upload.service';

@Injectable({
	providedIn: 'root'
})
export class ConversationService {
	private messageSource = new BehaviorSubject(false);
	currentMessage = this.messageSource.asObservable();
	private notificationMessageSource = new BehaviorSubject(false)
	notificationServiceMessage = this.notificationMessageSource.asObservable();

	constructor(private authService: AuthService, private apiService: ApiService, private uploadService: UploadService) { }

	changeMessage(message: boolean) {
		this.messageSource.next(message)
	}

	notificationMessage(message: boolean) {
		this.notificationMessageSource.next(message)
	}
	/**
	 * Get conversation info
	 * @param projectId
	 * @param id
	 */
	getConversation(projectId: string, id: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}`;
		return this.apiService.httpGet(endpoint);
	}

	/**
	 * Return list of active conversations of the user
	 * @param projectId
	 */
	getConversations(projectId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/users`;
		return this.apiService.httpGet(endpoint);
	}

	/**
	 * Get or create a one to one conversation
	 * @param projectId
	 * @param username  Username to chat with
	 */
	createOneToOneConversation(projectId: string, username: string): Observable<any> {
		let body = {
			fromUser: this.authService.getAuthUserName(),
			projectId: projectId,
			toUser: username
		}
		let endpoint = `/api/projects/${projectId}/conversations/one-to-one`;
		return this.apiService.httpPost(endpoint, body);
	}

	/**
	 * Create a new group chat
	 * @param projectId
	 * @param username
	 * @param users
	 * @param name
	 */
	createGroupConversation(projectId: string, users: string[], name: string = null): Observable<any> {
		if (!name) {
			name = 'Group ' + HelperService.genRandomNumber(1, 1000);
		}
		let endpoint = `/api/projects/${projectId}/conversations`;
		return this.apiService.httpPost(endpoint, {
			anyoneCanJoin: false,
			users: users,
			title: name,
			projectId: projectId
		});
	}

	/**
	 * Get conversations from one to one
	 * @param projectId
	 * @param username
	 */
	getOneToOneConversations(projectId: string, username: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/users/${username}`;
		return this.apiService.httpGet(endpoint);
	}

	/**
	 * Add users to a current group chat
	 * @param projectId
	 * @param id
	 * @param users
	 */
	addUsersToGroupChat(projectId: string, id: string, users: string[]): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/participants`;
		return this.apiService.httpPost(endpoint, users);
	}

	/**
	 * Remove participants from conversation
	 * @param projectId
	 * @param id
	 * @param users
	 */
	removeParticipants(projectId: string, id: string, users: any[]): Observable<any> {
		return this.apiService.httpPut(`/api/projects/${projectId}/conversations/${id}/participants`, users);
	}

	/**
	 * Get messages
	 * @param projectId
	 * @param id
	 */
	getMessages(projectId: string, id: string, pagingState?: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/messages`;
		if (pagingState != null) {
			endpoint = endpoint + `?pagingState=${pagingState}`;
		}
		return this.apiService.httpGet(endpoint);
	}

	/**
	 * Save new message
	 * @param projectId
	 * @param id
	 * @param message
	 */
	saveMessage(projectId: string, id: string, message: any): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/messages`;
		return this.apiService.httpPost(endpoint, message.id ? message : { message: message });
	}

	deleteMessage(projectId: string, id: string, messageId: any): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/messages/${messageId}`;
		return this.apiService.httpDelete(endpoint);
	}

	/**
	 * Send push notification for new video call (one-to-one or group chat)
	 * @param projectId
	 * @param id
	 */
	sendVideoCallInvite(projectId: string, id: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/call`;
		return this.apiService.httpPost(endpoint, null);
	}

	/**
	 * Send individual push notification for a new video call
	 * @param projectId
	 * @param id
	 * @param username
	 */
	sendVideoCallPing(projectId: string, id: string, usernames: string[]): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/ping`;
		return this.apiService.httpPost(endpoint, usernames);
	}


	/**
	 * Reject video call invitation
	 * @param projectId
	 * @param id
	 * @param callerUsername
	 */
	rejectCall(projectId: string, id: string, callerUsername: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/reject/${callerUsername}`;
		return this.apiService.httpPost(endpoint, null);
	}

	/**
	 * Validate token (for external user invitation to a video call)
	 * @param projectId
	 * @param id
	 * @param token
	 */
	validateToken(projectId: string, id: string, token: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/tokens/${token}`;
		return this.apiService.httpGet(endpoint);
	}

	/**
	 * Update existing conversation
	 * @param projectId
	 * @param id
	 * @param data
	 */
	updateConversation(projectId: string, id: string, data: any): Observable<any> {
		return this.apiService.httpPut(`/api/projects/${projectId}/conversations/${id}`, data);
	}

	/**
	 * Archive conversation
	 * @param projectId
	 * @param id
	 */
	archiveConversation(projectId: string, id: string): Observable<any> {
		return this.apiService.httpPut(`/api/projects/${projectId}/conversations/${id}/archive`, null);
	}

	/**
	 * Delete conversation
	 * @param projectId
	 * @param id
	 */
	deleteConversation(projectId: string, id: string): Observable<any> {
		return this.apiService.httpDelete(`/api/projects/${projectId}/conversations/${id}`);
	}

	missedCall(projectId: string, id: string, callerUsername: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/conversations/${id}/missed/${callerUsername}`;
		return this.apiService.httpPost(endpoint, {});
	}

	setConversationMessageSeen(projectId: string, conversationId: any, messageId: any) {
		let endpoint = `/api/projects/${projectId}/conversations/${conversationId}/messages/${messageId}`;
		return this.apiService.httpPut(endpoint, {});
	}

	/*
	 * Mark all messages in that conversation as seen
	 * @param projectId
	 * @param conversationId
	 */
	markConversationAsSeen(projectId: string, conversationId: string): Observable<any> {
		return this.apiService.httpPut(`/api/projects/${projectId}/conversations/${conversationId}/messages`, {});
	}
}
