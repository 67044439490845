<div class="modal-body" style="padding: 2.0rem;">
	<h6 style="text-align:center">
		<b>Why have you declined this project?</b>
	</h6>
	<div class="row">
		<div class="col-12 mt-4">
			<mtm-dropdown [title]="'Select Decline Reason'" [emptyListInfoText]="''"
				[dotItemSelectedText]="'reason selected'" [dotItemsSelectedText]="'reasons selected'"
				[items]=declineReasons [itemValueAccessor]="'id'" [itemLabelAccessor]="'name'" [isMultipleSelect]=false
				[refObject]=this [objectVariableAccessor]="'declineReason'" (onChangeValue)="selectedReasons()">
			</mtm-dropdown>
		</div>

		<div class="col-12 mt-4">
			<label *ngIf="isOther" class="form-group has-float-label project-type">
				<input class="form-control" [(ngModel)]="declineValue" type="text" placeholder=" "
					(ngModelChange)="otherReasonChange()">
			</label>
		</div>

		<div class="col-12 mt-4">
			<button class="btn btn-block btn-danger" (click)="saveChanges()">Save Changes</button>
		</div>
	</div>
</div>