<ng-select [items]="projectStatusList" [multiple]="true"
						bindLabel="label" bindValue="value" [selectableGroup]="true"  [closeOnSelect]="false" [searchable]="false" [clearable]="true"
						[(ngModel)]="currentValues"  [placeholder]="'task_selectStatus' | translate" (ngModelChange)="onSelectionChange($event)"
            appendTo="body" class="project-list-status-selector"> 
  <ng-template ng-multi-label-tmp>
  <span style="display:block" class="ng-multi-label"> {{currentValues.length}} {{'itemSelected' | translate}} </span>
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> 
    <span class="ml-1 option-label" title="{{task_selectStatus' | translate}}">{{task_selectStatus' | translate}}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> 
    <span class="ml-1 option-label" title="{{item.label | translate}}">{{item.label | translate}}</span>
  </ng-template>
</ng-select>