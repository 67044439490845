export interface ActivitySearchParams {
  projectId: string;

  notifyByUsers?: string;

  subSections?: string;

  sections?: string;

  category?: string;

  codes?: string[];

  startDate?: number;

  endDate?: number;

  pagingState?: string;

  taskId?: string;

  limit?: number;

  downloadFormat?: string;
}

export enum ActivityLogLevels {
  COMPANY = 'COMPANY',
  BRAND = 'BRAND',
  CAMPAIGN = 'CAMPAIGN',
  DRIVE_CAMPAIGN = 'DRIVE_CAMPAIGN',
  WORKSPACE = 'WORKSPACE',
  PROJECT = 'PROJECT'
}

export interface GeneralLogParams {
  companyId: string;
  exportType: 'JSON' | 'CSV';
  level: ActivityLogLevels;
  brandIds?: string[];
  projectIds?: string[];
  workspaceIds?: string[];
  campaignIds?: string[];
  driveCampaign?: boolean;
}
