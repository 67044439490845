import { Injectable } from '@angular/core';
import { PaymentWarningModalComponent } from "../../payment/payment-warning-modal/payment-warning-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DateHelperService } from "./date-helper.service";

@Injectable({
	providedIn: 'root'
})
export class PaymentCheckService {
	private project: any;
	private allowedDayTime: number = 172800000;//48 Hour
	paymentWarningComponentModal: any;


	constructor(private modalService: NgbModal) {
	}
	/**
	 * Down Payment Date Check
	 */
	private downPaymentDateCheck() {
		if ((DateHelperService.endOfDay(this.project.downPaymentDeadline) + this.allowedDayTime) < new Date()) {
			this.paymentWarning("DOWNPAYMENT", true);
		}
		else {
			this.paymentWarning("DOWNPAYMENT", false);
			//	this.modalRef.componentInstance.initData(project);

		}
	}
	/**
	 * Down Payment Paid Check
	 */
	public downPaymentCheck(project: any) {
		this.project = project;
		if (project.entranceFeePaid &&
			!project.downPaymentPaid && DateHelperService.endOfDay(project.downPaymentDeadline) < new Date()) {
			this.downPaymentDateCheck();
		}
		else return;
	}
	/**
	 * Final Payment Paid Check
	 */
	public finalPaymentCheck(project: any) {
		this.project = project;
		if (project.entranceFeePaid && project.downPaymentPaid &&
			!project.finalPaymentPaid && (DateHelperService.endOfDay(project.finalPaymentDeadline) < new Date())) {
			this.finalPaymentDateCheck();
		}
		else return;
	}
	/**
	 * Final Payment Date Check
	 */
	private finalPaymentDateCheck() {
		if ((DateHelperService.endOfDay(this.project.finalPaymentDeadline) + (this.allowedDayTime / 2)) < new Date()) {
			this.paymentWarning("FINAL_PAYMENT", true);
		}
		else {
			this.paymentWarning("FINAL_PAYMENT", false);
			//	this.modalRef.componentInstance.initData(project);

		}
	}

	/**
	 * Payment Warning Component Open
	 */
	private paymentWarning(paymentType: string, endTimeStatus: boolean) {
		this.paymentWarningComponentModal = this.modalService.open(PaymentWarningModalComponent, { size: 'lg', windowClass: 'company-matched-blur-color', backdrop: false, keyboard: false });
		this.paymentWarningComponentModal.componentInstance.setParam(this.project, paymentType, endTimeStatus);
	}
}
