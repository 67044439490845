import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular'
import { IHeaderParams } from 'ag-grid-community'
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'project-team-table-header',
    templateUrl: 'project-team-table-header.component.html',
    styleUrls: ['./project-team-table-header.component.scss']
})
export class ProjectTeamTableHeader implements IHeaderAngularComp {
    colId: any;
    order: any = '';
    public params: IHeaderParams;

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.colId = params.column.getColId();
    }

    refresh(params: IHeaderParams<any>): boolean {
        return true;
    }

    onSortRequested(event: any) {
        if (this.params.column.isSortAscending()) {
            this.order = 'desc';
        } else if (this.params.column.isSortDescending()) {
            this.order = '';
        } else {
            this.order = 'asc';
        }
        this.params.setSort(this.order, event.shiftKey);
    }
}