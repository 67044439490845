<div class="limit-storage-popup-component">
	<div class="limit-storage-popup-head d-flex flex-column align-items-center justify-content-center">
		<span class="page-title c-mtm-brand _700 t-uppercase mb-2">{{'limitReached' | translate }}</span>
	</div>
	<div class="limit-storage-popup-body text-center">
		<label class="limit-storage-popup-body-title text-center m-0 c-mtm-dark-purple mb-1" *ngIf="limitText">
			{{limitText | translate}}
		</label>
		<div class="limit-storage-popup-body-control mt-2 mb-2 d-flex">
			<div class="limit-storage-popup-control d-flex align-items-center flex-fill m-auto">
				<div class="total-addon-storage flex-fill text-center c-mtm-brand">
					<span class="d-block p-1 _700">
						{{limitTotal}} {{limitMetric}}
					</span>
				</div>
			</div>
		</div>
		<div class="limit-storage-popup-body-info">
			<div class="d-flex flex-column">
				<span class="mb-3 c-mtm-dark-purple">
					{{'limitReached_allowedInPlan' | translate}}
				</span>
				<span class="mb-5 c-mtm-dark-purple">
					{{promptText | translate}}
				</span>
			</div>
		</div>
	</div>
	<div class="limit-storage-popup-button d-flex align-items-center justify-content-center">
		<div>
			<a class="btn b-mtm-grey c-mtm-dark-purple _600 pl-5 pr-5 pt-2 pb-2 mr-3" (click)="cancel()">Cancel</a>
		</div>
		<div *ngIf="authUser.globalRole === 'COMPANY_PRINCIPAL'">
			<a class="btn b-mtm-green c-full-white _600 pl-5 pr-5 pt-2 pb-2" (click)="confirm()">
				<span *ngIf="!isLoading">{{'subscription_warning_upgrade_now' | translate }}</span>
				<span class="d-flex align-items-center" *ngIf="isLoading">
					<span class="mr-2">Loading</span>
					<i [style.font-size]="'18px'" class="fa fa-spinner fa-pulse"></i>
				</span>
			</a>
		</div>
	</div>
</div>