import {Directive, HostListener, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {HelperService} from "../services/helper.service";
import {TranslatePipe} from "../pipes/translate.pipe";
import {OverlayService} from "../services/overlayService";
import {UrlShortenerService} from "../services/url-shortener.service";
import {result} from "lodash";
import {ShortenedUrlInfo} from "../interfaces";

@Directive({
  selector: '[shortenedUrl]'
})
export class ShortenedUrlDirective implements OnInit, OnChanges {
  @Input() sourceUrl: string;
  @Input() readOnlyLink: boolean = true;
  shortenedUrl: string = '';
  shortenedInfo: ShortenedUrlInfo | null = null;
  isPending: boolean = false;

  constructor(private urlShortenerService: UrlShortenerService,
              private overlayService: OverlayService,
              private translatePipe: TranslatePipe) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.linkSource) {
      this.shortenedInfo = null;
      this.shortenedUrl = '';
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if(! this.sourceUrl || this.isPending){
      return;
    }

    if(this.shortenedUrl){
     this.copyShortenedUrl();
      return;
    }

    this.isPending = true;
    this.urlShortenerService.getShortenedUrl(this.sourceUrl).subscribe({
      next: (result: ShortenedUrlInfo) =>{
        this.shortenedUrl = result.shortenedUrl;
        this.shortenedInfo = result;
        this.copyShortenedUrl();
        this.isPending = false;
      },
      error: (error: any) => {
        if (error.status == 404 || error.errorCode == 'NOT_FOUND') {
          this.createShortenedUrl();
        } else {
          this.copyOriginalUrl();
          console.error('Failed to create shortened url', error);
          this.isPending = false;
        }
      }
    });

  }

  private copyShortenedUrl(){
    HelperService.copyToClipboard(this.shortenedUrl);
    this.overlayService.showSuccess(this.translatePipe.transform('copiedToClipboard'));
  }

  private copyOriginalUrl(){
    HelperService.copyToClipboard(this.sourceUrl);
    this.overlayService.showSuccess(this.translatePipe.transform('copiedToClipboard'));
  }

  private createShortenedUrl() {
    const info: ShortenedUrlInfo = {
      backHalf: '',
      shortenedUrl: '',
      domain: HelperService.getCurrentOrigin(),
      redirectionUrl: this.sourceUrl,
      tags: [],
      title: ''
    };
    this.urlShortenerService.createShortenedUrl(info)
      .subscribe({
        next: (result: ShortenedUrlInfo) => {
          this.shortenedInfo = result;
          this.shortenedUrl = result.shortenedUrl;
          this.copyShortenedUrl();
          this.isPending = false;
        },
        error: (error: unknown) => {
          this.copyOriginalUrl();
          console.error('Failed to create shortened url', error);
          this.isPending = false;
        }
      })
  }

}
