<div class="payment-container">
	<form *ngIf="paymentForm" [formGroup]="paymentForm" class="payment-form d-flex flex-column position-relative">
		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_register_payment_invoiceDetail'|translate}}</h3>
		</div>
		<div class="d-flex flex-column register-form main-input">
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['firstName'].pristine && paymentForm?.controls['firstName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_firstName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_firstName'|translate}}"
						class="form-control" formControlName="firstName" />
					<div
						*ngIf="!paymentForm?.controls['firstName'].pristine && paymentForm?.controls['firstName'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['lastName'].pristine && paymentForm?.controls['lastName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_lastName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_lastName'|translate}}"
						class="form-control" formControlName="lastName" />
					<div
						*ngIf="!paymentForm?.controls['lastName'].pristine && paymentForm?.controls['lastName'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['companyName'].pristine && paymentForm?.controls['companyName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_companyName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_companyName'|translate}}"
						class="form-control" formControlName="companyName" />
					<div
						*ngIf="!paymentForm?.controls['companyName'].pristine && paymentForm?.controls['companyName'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['address'].pristine && paymentForm?.controls['address'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_address'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_address'|translate}}"
						class="form-control" formControlName="address" />
					<div
						*ngIf="!paymentForm?.controls['address'].pristine && paymentForm?.controls['address'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['city'].pristine && paymentForm?.controls['city'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_city'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_city'|translate}}"
						class="form-control" formControlName="city" />
					<div
						*ngIf="!paymentForm?.controls['city'].pristine && paymentForm?.controls['city'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['zipCode'].pristine && paymentForm?.controls['zipCode'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_zipCode'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_zipCode'|translate}}"
						class="form-control" formControlName="zipCode" />
					<div
						*ngIf="!paymentForm?.controls['zipCode'].pristine && paymentForm?.controls['zipCode'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['country'].pristine && paymentForm?.controls['country'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_country'|translate}}</label>
					<select class="form-control" formControlName="country" disabled="disabled">
						<option value="">{{'searchCountry' | translate}}</option>
						<option [value]="item.regionCode" *ngFor="let item of listCountryCodes">{{item.countryName}}
						</option>
					</select>
					<div
						*ngIf="!paymentForm?.controls['country'].pristine && paymentForm?.controls['country'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['state'].pristine && paymentForm?.controls['state'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label class="control-label mb-1 _600">{{'companyDetail_State' | translate }}</label>
					<input class="form-control" formControlName="state" *ngIf="paymentForm?.value?.country != 'US'">
					<select class="form-control" formControlName="state" *ngIf="paymentForm?.value?.country == 'US'">
						<option value="">{{'companyDetail_SelectState' | translate}}</option>
						<option [value]="item.name" *ngFor="let item of usaStates">{{item.name}}</option>
					</select>
					<div
						*ngIf="!paymentForm?.controls['state'].pristine && paymentForm?.controls['state'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['taxIdNumber'].pristine && paymentForm?.controls['taxIdNumber'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<div class="d-flex mb-1 align-items-center">
						<label
							class="control-label mb-0 mr-1 _600">{{'subscription_register_payment_invoiceDetail_taxIdNumber'|translate}}</label>
						<div class="info-icon tooltipped"
							[attr.data-title]="'subscription_register_payment_invoiceDetail_taxIdNumberExample' | translate">
						</div>
					</div>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_taxIdNumber'|translate}}"
						class="form-control" formControlName="taxIdNumber" />
					<div
						*ngIf="!paymentForm?.controls['taxIdNumber'].pristine && paymentForm?.controls['taxIdNumber'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_invalidTax'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['billingCcEmail'].pristine && paymentForm?.controls['billingCcEmail'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_billingCCEmail'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_billingCCEmail'|translate}}"
						class="form-control" formControlName="billingCcEmail" />
					<div class="d-flex flex-column"
						*ngIf="!paymentForm?.controls['billingCcEmail'].pristine && paymentForm?.controls['billingCcEmail'].status === 'INVALID'">
						<span *ngIf="paymentForm?.controls['billingCcEmail'].errors.required"
							class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
						<span *ngIf="paymentForm?.controls['billingCcEmail'].errors.email"
							class="required">{{'invalidEmail'|translate}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_upgrade_paymentMethod'|translate}}</h3>
		</div>

		<div class="control-label mb-3 _600">{{'subscription_upgrade_selectPaymentMethod'|translate}}</div>

		<div class="mb-3 d-flex method-list">
			<ng-container *ngFor="let method of methods; trackBy: trackByMethodId">
				<div class="d-flex mr-4 method-wrapper" *ngIf="availableMethods.indexOf(method.type) > -1">
					<input type="radio" name="paymentMethodId" value="{{ method.id }}"
						formControlName="paymentMethodId">
					<div class="card-info" *ngIf="method.type == 'card'">
						<div class="name">{{method.label }} ( XXXX {{ method.card.last4 }} )</div>
						<div class="font-weight-bold text-capitalize brand">{{method.card.brand}}</div>
					</div>
					<div class="sepa-info" *ngIf="method.type == 'sepa_debit'">
						<div class="name">{{method.label }} ( XXXX {{ method.sepa.last4 }} )</div>
						<div class="font-weight-bold text-capitalize country">{{method.sepa.country}}</div>
					</div>
				</div>
			</ng-container>
		</div>

		<button class="btn d-inline btn-dark btn-payment" (click)="addPaymentMethod()">
			<span>+</span>
			<span>Add a new payment method</span>
		</button>




		<div class="text-center mt-5 payment-navigation mt-3">
			<div class="d-inline-flex" [ngClass]="{ 'disabled': !paymentForm?.valid }">
				<a (click)="planConfimation()" class="btn mtm-button mtm-main-button" [style.color]="'#FFFFFF'"
					[style.text-transform]="'uppercase'">
					<span *ngIf="!paymentProcessing" class="mr-2">{{ 'subscription_upgrade_payment_confirm' |
						translate}}</span>
					<span *ngIf="paymentProcessing" class="d-flex align-items-center">
						<span class="mr-2">{{ 'subscription_upgrade_payment_processing_payment' | translate}}</span>
						<i [style.font-size]="'18px'" class="fa fa-spinner fa-pulse"></i>
					</span>
				</a>
			</div>
		</div>
	</form>
</div>