import { Component } from '@angular/core';

@Component({
  selector: 'mtm-onboarding-progress',
  templateUrl: './onboarding-progress.component.html',
  styleUrls: ['./onboarding-progress.component.scss']
})
export class OnboardingProgressComponent {

  certificationData =[
    {
      category:'gold',
      title:'Gold Certification',
      area:'Mastering the Basics: Get Started',
      date:'May 1, 2023',
      status:'Earned',
      totalStages:5,
      completedStages:5,
      stageStatus:'Fabulous'
    },
    {
      category:'halfcompleted',
      title:'',
      area:'Designing and Producing Creative Assets',
      date:'May 1, 2023',
      status:'Completed',
      totalStages:10,
      completedStages:5,
      stageStatus:'Correct'
    },
    {
      category:'Medalsbronez',
      title:'Bronze',
      area:'Team Collaboration: Streamlining Workflows',
      date:'May 1, 2023',
      status:'Earned',
      totalStages:5,
      completedStages:5,
      stageStatus:'Fabulous'
    },
    // {
    //   category:'gold',
    //   title:'Gold Certification',
    //   area:'Mastering the Basics: Get Started',
    //   date:'May 1, 2023',
    //   status:'Earned',
    //   totalStages:5,
    //   completedStages:5,
    //   stageStatus:'Fabulous'
    // }
  ]
}
