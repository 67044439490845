import { AudioComp } from "../lib/audio-component";
import { Annotation } from "./annotation";
import { Comment } from "./comment";

export class CommentList extends AudioComp {
    annotation: Annotation;
    comments: any;

    constructor(data: any, plugin: Object) {
        super(plugin);
        this.annotation = data.annotation;
        this.comments = data.comments.map(commentData => {
            commentData.commentList = this;
            return new Comment(commentData, plugin);
        });
        this.sortComments();
    }

    createCommentUpdate(commentData) {
        const body = commentData.body;
        const parentId = commentData.parentId;
        const comment = Comment.newFromData(body, parentId || this.comments[0].id, this, this.plugin, commentData);
        this.comments.push(comment);
        this.sortComments();

    }

    createComment(body, parentId, commentData=null, isWSCall=false) {
        var comment = Comment.newFromData(body, parentId || this.comments[0].id, this, this.plugin, commentData);
        this.comments.push(comment);
        this.sortComments();
        this.plugin.annotationState.stateChanged();
        if (!isWSCall) this.plugin.fire("commentCreated", {annotation: this.annotation, allAnnotations: this.plugin.annotationState.data});
    }

    destroyComment(event) {
        if (this.comments.length == 1) {
            this.annotation.teardown();
        } else {
            var commentId = this.findCommentId(event);
            var comment = this.comments.find(function (c) {
                return c.id == commentId;
            });
            var i = this.comments.indexOf(comment);
            this.comments.splice(i, 1);
            if (!event.detail.isWSCall) this.plugin.fire("commentDeleted", {annotation: this.annotation, allAnnotations: this.plugin.annotationState.data});
        }
    }

    editComment(event, comment) {
        var i = this.comments.indexOf(comment);
        this.comments[i].meta.updatetime = Comment.commentUpdateTime();
        this.comments[i].body = event.detail.body;
        this.comments[i].files = event.detail.files;
        this.plugin.annotationState.stateChanged();
        if (!event.detail.isWSCall) this.plugin.fire("commentEdited", {annotation: this.annotation, allAnnotations: this.plugin.annotationState.data});
    }

    findCommentId(event) {
        var id = typeof event.detail.id === 'undefined' ? $(event.target).closest('.vac-comment').data('id') : event.detail.id;
        return id;
    }

    sortComments() {
        this.comments.sort(function (a, b) {
            return a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0;
        });
    }

    teardown(destroyComments = true) {
        if (destroyComments) this.comments = [];

    }

    get data() {
        return this.comments.map(function (c) {
            return c.data;
        });
    }
}