import { Component, OnDestroy, OnInit } from "@angular/core";
import { Params, ActivatedRoute, Router } from "@angular/router";
import { AuthService } from './../../shared/services/auth.service';
import { RegistrationService } from './../../shared/services/registration.service';
import { environment } from "../../../environments/environment";
import { NotificationService } from './../../shared/services/notification.service';
import { ProjectService } from "../../shared/services/project.service";
import { isNullOrUndefined } from "util";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import DOMPurify from "dompurify";

@Component({
	template: `
		<div style="margin: 10px">Processing ...</div>
	`
})
export class AuthRedirectComponent implements OnInit, OnDestroy {

	authToken: string;
	ngUnsubscribe = new Subject();

	constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService,
		private registrationService: RegistrationService, private notificationService: NotificationService, private projectService: ProjectService) {
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		this.activatedRoute.params.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params: Params) => {

			if (params['service']) {

				if (params['service'] == 'facebook' || params['service'] == 'google' || params['service'] == 'linkedin' || params['service'] == 'azure') {

					let redirectUrl = environment.api.baseUrl + '/oauth2/authorization/' + params['service'];

					// If facebook, add scope=email
					if (params['service'] == 'facebook')
						redirectUrl += '?scope=email';

					// If google, add scope=profile
					else if (params['service'] == 'google')
						redirectUrl += '?scope=profile+email';

					// If redirectUrl defined, just redirect
					window.location.href = redirectUrl;

				} else if (params['service'] == 'process') { // Process server redirect

					let regCode = this.router.parseUrl(this.router.url).queryParams['registration'];
					let passwordCode = this.router.parseUrl(this.router.url).queryParams['password'];

					// If registration code received (so, process invitation sent by email)

					if (regCode) {

						// Check if code is valid
						this.registrationService.isInvitationCodeValid(regCode).pipe(
							takeUntil(this.ngUnsubscribe)
						)
							.subscribe((data: any) => {

								// If user status is in progress yet
								if (data.activationStatus == 'PENDING_INPROGRESS') {

									// If projectId attached in url (it means should accept project invitation)
									// save in sessionStorage to then use to automatically approves invitation
									this.activatedRoute.queryParams.pipe(
										takeUntil(this.ngUnsubscribe)
									).subscribe((params: Params) => {
										let projectId = params['projectId'];
										if (projectId)
											sessionStorage.setItem('projectId', projectId);
									});

									let user = {
										id: data.id,
										username: data.username,
										firstName: data.firstName,
										lastName: data.lastName,
										company_name: (data.company && data.company.name) ? data.company.name : null,
										company_type: (data.company && data.company.companyType) ? data.company.companyType : null,
										company_id: (data.company && data.company.id) ? data.company.id : null,
										email: data.email,
										phone: data.phone
									}
									this.router.navigate(['/entry/on-boarding/profile-info'], { queryParams: user });
								} else
									this.router.navigate(['/entry']);

							}, (err: any) => {

								// Display notification
								this.notificationService.open({
									title: 'Authentication',
									description: `${err.errorCode}`,
									confirmBtn: 'Accept'
								});

							})

					} else if (passwordCode) { // If password receive, then process reset password scenario

						// Verify that token is valid
						this.authService.verifyUserPasswordToken(passwordCode).pipe(
							takeUntil(this.ngUnsubscribe)
						)
							.subscribe(
								(data: any) => {
									// Redirect to Reset Password page with user object as param
									this.router.navigate(['entry/user-password/reset'], { queryParams: data });
								},
								(err: any) => {

									// Display notification
									this.notificationService.open({
										title: 'Authentication',
										description: `Error:\n${err.errorCode}`,
										confirmBtn: 'Accept'
									}).pipe(
										takeUntil(this.ngUnsubscribe)
									).subscribe(() => {

										// Navigate to profile avatar page
										this.router.navigate(['/entry']);

									})

								});
					} else {

						// Read token
						this.authToken = this.router.parseUrl(this.router.url).queryParams['token'];

						// If token is NOT_AVAILABLE, it means that user was not able to login with social network
						// Read user data params (if any) and redirect to request-invitation page
						if ((!this.authToken) || (this.authToken == 'NOT_AVAILABLE')) {

							let firstName = (this.router.parseUrl(this.router.url).queryParams['firstName'])
								? this.router.parseUrl(this.router.url).queryParams['firstName']
								: null;
							let lastName = (this.router.parseUrl(this.router.url).queryParams['lastName'])
								? this.router.parseUrl(this.router.url).queryParams['lastName']
								: null;
							let email = (this.router.parseUrl(this.router.url).queryParams['email'])
								? this.router.parseUrl(this.router.url).queryParams['email']
								: null;

							let params = {
								firstName: firstName,
								lastName: lastName,
								email: email
							}

							// Navigate to Request Invitation page
							this.router.navigate(['/entry/request-invitation'], { queryParams: params });

						} else { // Otherwise, login user and redirect to main layout
							let username = this.router.parseUrl(this.router.url).queryParams['username'];

							if (!isNullOrUndefined(sessionStorage.getItem('fromOneShotProjectId'))) {
								this.authService.loginWithTokenForOneShot(this.authToken, username, sessionStorage.getItem('fromOneShotProjectId')).pipe(
									takeUntil(this.ngUnsubscribe)
								).subscribe((data: any) => {
									this.navigateToReturnUrlOrDashboards();
								});
							} else {
								this.authService.loginWithToken(this.authToken, username).pipe(
									takeUntil(this.ngUnsubscribe)
								)
									.subscribe((data: any) => {
										this.navigateToReturnUrlOrDashboards();
									});
							}

						}

					}

				} else
					this.router.navigate(['/entry']);
			} else
				this.router.navigate(['/entry']);

		});
	}

	private navigateToReturnUrlOrDashboards() {
		if (sessionStorage.getItem('returnUrl')) {
			let returnUrl = sessionStorage.getItem('returnUrl');
			sessionStorage.removeItem('returnUrl');
			let safeURL = DOMPurify.sanitize(returnUrl);
			this.router.navigateByUrl(encodeURI(safeURL));
		} else
			this.router.navigate(['/projects']);
	}
	isValidURL(url) {
		try {
			new URL(url);
			return true;
		} catch (error) {
			return false;
		}
	}
}
