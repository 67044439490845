
import { forkJoin as observableForkJoin, empty as observableEmpty, Observable } from 'rxjs';
import { ProjectService } from 'app/shared/services/project.service';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class EventService {
    public subject = new BehaviorSubject('');

    constructor(private api: ApiService, public auth: AuthService, private projectService: ProjectService) { }

    public getSectionList(): any[] {
        return [
            { label: this.projectService.getSectionName('CREATIVE_PARTNER_SELECTION'), value: 'CREATIVE_PARTNER_SELECTION' },
            { label: this.projectService.getSectionName('CREATIVE_BRIEF'), value: 'CREATIVE_BRIEF' },
            { label: this.projectService.getSectionName('CREATIVE_WORK'), value: 'CREATIVE_WORK' },
            { label: this.projectService.getSectionName('PRODUCTION_PARTNER_SELECTION'), value: 'PRODUCTION_PARTNER_SELECTION' },
            { label: this.projectService.getSectionName('PRODUCTION_BRIEF'), value: 'PRODUCTION_BRIEF' },
            { label: this.projectService.getSectionName('PRE_PRODUCTION'), value: 'PRE_PRODUCTION' },
            { label: this.projectService.getSectionName('SHOOTING'), value: 'SHOOTING' },
            { label: this.projectService.getSectionName('POST_PRODUCTION'), value: 'POST_PRODUCTION' },
            { label: this.projectService.getSectionName('SELF_FIX_EDIT'), value: 'SELF_FIX_EDIT' },
            { label: this.projectService.getSectionName('DIFFUSION'), value: 'DIFFUSION' }
        ];
    }

    /**
     * Retrieve all events for this user.
     */
    public getAllEvents(projectId: string): Observable<Response> {
        return this.api.httpGet('/api/projects/' + projectId + '/events/users/' + this.auth.getAuthUser().username);
    }

    public getAllEventsAndSections(projectId: string, startOfMonth, endOfMonth) {
        if (!projectId) {
            return observableEmpty();
        }
        return observableForkJoin([
            this.api.httpGet('/api/projects/' + projectId + '/events/users/' + this.auth.getAuthUser().username),
            this.api.httpGet('/api/projects/' + projectId + '/sections'),
            this.api.httpGet('/api/projects/' + projectId + '/subsections'),
            this.api.httpGet('/api/tasks?campaignId=00000000-0000-0000-0000-000000000000&projectIds=' + projectId + '&page=1&size=999&from=' + startOfMonth + '&to=' + endOfMonth)
        ]);
    }

    public getAllEvent(projectId: string) {
        return this.api.httpGet('/api/projects/' + projectId + '/events/users/' + this.auth.getAuthUser().username);
    }

    public getEventsByDate(projectId: string, startDate: string | number, endDate: string | number): Observable<Response> {
        return this.api.httpGet('/api/projects/' + projectId + '/events/users/' + this.auth.getAuthUser().username + '/' + startDate + '/' + endDate);
    }

    public getOwnerEvents(projectId: string): Observable<Response> {
        return this.api.httpGet('/api/projects/' + projectId + '/events/users/' + this.auth.getAuthUser().username + '/owner');
    }

    public getProjectEvents(projectId: string): Observable<Response> {
        return this.api.httpGet('/api/projects/' + projectId + '/events');
    }

    /**
     * Retrieve event by id.
     *
     * @param id
     */
    public getEvent(id: string, projectId: string): Observable<Response> {
        return this.api.httpGet('/api/projects/' + projectId + '/events/' + id);
    }

    /**
     * Delete event by id.
     *
     * @param id
     */
    public deleteEvent(id, projectId: string): Observable<Response> {
        return this.api.httpDelete('/api/projects/' + projectId + '/events/' + id);
    }

    /**
     * Create an event.
     *
     * @param event
     */
    public createEvent(event: any, projectId: string): Observable<Response> {
        return this.api.httpPost('/api/projects/' + projectId + '/events', event);
    }

    /**
     * Associate a conversation to an event.
     *
     * @param event
     */
    public associateEventConversation(event: any, projectId: string): Observable<Response> {
        return this.api.httpPut('/api/projects/' + projectId + '/events/' + event.id + '/conversation', event);
    }

    /**
     * Retrieve color by Section
     *
     * @param section
     */
    public getColor(section) {

        let primary, secondary, text;

        switch (section) {
            case 'CREATIVE_PARTNER_SELECTION':
                primary = '#3f9e88'
                secondary = '#fff'
                text = primary
                break;
            case 'CREATIVE_BRIEF':
                primary = '#4dbd89'
                secondary = '#fff'
                text = primary
                break;
            case 'CREATIVE_WORK':
                primary = '#8dc870'
                secondary = '#fff'
                text = primary
                break;
            case 'PRODUCTION_PARTNER_SELECTION':
                primary = '#bdd05c'
                secondary = '#fff'
                text = primary
                break;
            case 'PRODUCTION_BRIEF':
                primary = '#f7db3d'
                secondary = '#fff'
                text = primary
                break;
            case 'PRODUCTION_COMPANY_PROPOSALS':
                primary = '#f9cd24'
                secondary = '#fff'
                text = primary
                break;
            case 'PRE_PRODUCTION':
                primary = '#f7be39'
                secondary = '#fff'
                text = primary
                break;
            case 'SHOOTING':
                primary = '#f8a036'
                secondary = '#fff'
                text = primary
                break;
            case 'POST_PRODUCTION':
            case 'POST_PRODUCTION':
                primary = '#ff7d2d'
                secondary = '#fff'
                text = primary
                break;
            case 'SELF_FIX_EDIT':
            case 'SELF_FIX_EDIT':
                primary = '#ff5e2f'
                secondary = '#fff'
                text = primary
                break;
            case 'DIFFUSION':
                primary = '#ff3c00'
                secondary = '#fff'
                text = primary
                break;
            default:
                primary = '#fff'
                secondary = '#1E90FF'
                text = secondary
                break;
        }

        return { primary, secondary, text };
    }

    getTaskColor(task, sections) {
        if (!task.assetsVersions) {
            task.assetsVersions = [];
        }
        let primary: string, secondary: string, text: string;
        let assetsVersionsSections = [];
        for (let i = 0; i < task.assetsVersions.length; i++) {
            const isExists = assetsVersionsSections.find(s => s === task.assetsVersions[i].projectSectionId);
            if (!isExists && task.assetsVersions[i].projectSectionId) {
                assetsVersionsSections.push(task.assetsVersions[i].projectSectionId);
            }
        }
        let selectedSection = '';
        if (assetsVersionsSections?.length === 1) {
            selectedSection = assetsVersionsSections[0] || task.projectSectionId;
        }
        const section = sections.find(s => s.id == selectedSection);
        switch (section?.section) {
            case 'PRE_PRODUCTION':
                primary = 'rgb(248, 160, 54)'
                secondary = '#fff'
                text = secondary
                break;
            case 'SHOOTING':
                primary = 'rgb(255, 125, 45)'
                secondary = '#fff'
                text = secondary
                break;
            case 'POST_PRODUCTION':
            case 'POST_PRODUCTION':
                primary = 'rgb(255, 94, 47)'
                secondary = '#fff'
                text = secondary
                break;
            default:
                primary = 'gray'
                secondary = '#efefef'
                text = secondary
                break;
        }
        return { primary, secondary, text };
    }

    updateEvent(event: any, projectId: string): Observable<Response> {
        return this.api.httpPut('/api/projects/' + projectId + '/events/' + event.id, event);
    }
}
