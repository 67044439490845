import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { AdminService } from "../../admin/services/admin.service";
import { onCheckFinalPayment, ProjectService } from "../../shared/services/project.service";
import { takeUntil } from "rxjs/operators";
import { CreditCardDetailsModalComponent } from "../../payment/credit-card-details/credit-card-details-modal.component";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../shared/services/user.service";
import { TranslateService } from "../../shared/services/translate.service";
import { NotificationService, contentLanguageUpdated } from "../../shared/services/notification.service";
import { ActivityLogAccessArgs, ActivityLogService } from "../../shared/services/activity-log.service";

@Component({
	selector: 'top-nav',
	templateUrl: './top-nav.component.html',
	styleUrls: ['./top-nav.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TopNavComponent implements OnInit, OnDestroy {
	publicReviewLink: boolean = false;
	publicPage: boolean = false;
	authUser: any;
	hasDebt: boolean = false;
	ngUnsubscribe = new Subject();
	project: any;
	projectId: string = null;
	selectedLanguage: string = 'en_US';
	selectedFlag: string = 'flag-icon-gb';
	isPending: boolean = false;
	hasActivityLogAccess: boolean = false;

	constructor(
		private adminService: AdminService,
		private authService: AuthService,
		private projectService: ProjectService,
		private userService: UserService,
		private translateService: TranslateService,
		private notificationService: NotificationService,
		private activityLogService: ActivityLogService,
		private modalService: NgbModal,
		private route: ActivatedRoute) {

	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		this.publicReviewLink = this.route.snapshot.data.publicReviewLink;
		this.publicPage = this.route.snapshot.data.publicPage;
		this.selectedLanguage = localStorage.getItem('auth_user_language') || 'en_us';
		this.adjustSelectedFlag();
		onCheckFinalPayment.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			() => this.checkProjectPayment(),
			() => this.checkProjectPayment()
		);
		contentLanguageUpdated.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((language: string) => {
			this.changeLang(language, false);
		});
		this.activityLogService.activityLogAccess
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((args: ActivityLogAccessArgs) => {
				this.hasActivityLogAccess = args.hasAccess;
			});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	backToAdmin() {
		this.adminService.switchToAdmin();
	}

	onBehalf() {
		return !!localStorage.getItem('admin_token');
	}

	checkProjectPayment() {
		const project = this.projectService.project;
		const projectId = this.route.snapshot.params['projectId'];
		this.project = project;
		this.projectId = projectId;

		this.hasDebt = project && projectId && project.prodCompanyId && project.downPaymentPaid == true
			&& project.finalPaymentPaid == false && this.authService.getAuthUser(false).companyId == project.company.id;
	}

	openPaymentModal() {
		this.projectService.getAllProjectProposals(this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposals => {
			let modal = this.modalService.open(CreditCardDetailsModalComponent, { size: 'lg', windowClass: 'company-matched-blur-color', backdrop: false });
			modal.componentInstance.projectId = this.projectId;
			modal.componentInstance.projectType = this.project.projectType;
			if (this.project.downPaymentPaid == false) {
				modal.componentInstance.paymentType = 'DOWNPAYMENT';
				this.projectService.getAllProjectProposals(this.projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(proposal => {
					if (proposal.status == 'ACCEPTED')
						modal.componentInstance.selectedProposal = proposal;
				});
				modal.componentInstance.totalMTM = 3242;

			}
			else
				modal.componentInstance.paymentType = 'FINAL_PAYMENT';
			proposals.forEach(proposal => {
				if (proposal.status == 'ACCEPTED')
					modal.componentInstance.selectedProposal = proposal;
			});

			modal.result.then(res => { this.projectService.prepareProjectInit(this.projectId); })
		})

	}

	changeLang(lang: string, updateSettings: boolean = true) {
		if(this.isPending){
			return;
		}

		this.isPending = true;
		this.selectedLanguage = lang;
		this.adjustSelectedFlag();
		this.translateService.use(lang).then(() => {
			let registerData = JSON.parse(localStorage.getItem('register_data'));
			if (registerData) {
				this.isPending = false;
				return;
			}
			if(updateSettings){
				this.authService.setAuthLanguage(lang);
			}
			this.notificationService.getAllUnseenNotifications();
			this.isPending = false;
		}).catch(() => {
			this.isPending = false;
		});	
	}

	adjustSelectedFlag() {
		let flagClass = '';
		switch (this.selectedLanguage.toLowerCase()) {
			case 'fr':
				flagClass = 'flag-icon-fr';
				break;
			case 'es':
				flagClass = 'flag-icon-es';
				break;
			case 'pt':
				flagClass = 'flag-icon-pt';
				break;
			case 'zh':
				flagClass = 'flag-icon-cn';
				break;
			default:
				flagClass = 'flag-icon-us';
				break;
		}
		this.selectedFlag = flagClass;
	}

}
