import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralComponent } from './general/general.component';
import { IndexComponent } from './index/index.component';
import { ProductionTimelinesComponent } from './production-timelines/production-timelines.component';
import { UsersPermissionsComponent } from './users-permissions/users-permissions.component';
import { RecapComponent } from './recap/recap.component';
const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: 'general',
        component: GeneralComponent
      },
      {
        path: ':workspaceId/general',
        component: GeneralComponent
      },
      {
        path: 'timeline-setting',
        component: ProductionTimelinesComponent
      },
      {
        path: 'users-permissions',
        component: UsersPermissionsComponent
      },
      {
        path: ':workspaceId/recap',
        component: RecapComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimelineGeneratorRoutingModule { }
