import { Component, OnInit, Input } from '@angular/core';
import { SafePipe } from "app/shared/pipes/safe.pipe";


@Component({
	selector: 'embed-video',
	templateUrl: './embed-video.component.html',
	styleUrls: ['./embed-video.component.scss'],
	providers: [SafePipe]
})
export class EmbedVideoComponent implements OnInit {

	@Input() width: string = '100%';
	@Input() height: string = '100%';
	@Input() link: string;
	@Input() fix: boolean = false;

	private vid: string;
	sourceType: string;
	source: string;

	constructor() {
	}

	ngOnInit() {
		// YouTube
		var videoid = this.link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
		if (videoid != null) {
			let vid = videoid[1];
			this.sourceType = 'youtube';
			this.source = 'https://www.youtube.com/embed/' + vid;
		} else {
			// Vimeo
			var vimeoid = this.link.match(/(videos|video|channels|\.com)\/([\d]+)/);
			if (vimeoid != null) {
				let vid = vimeoid[2];
				this.sourceType = 'vimeo';
				this.source = 'https://player.vimeo.com/video/' + vid;
			}
			else {
				this.sourceType = 'url';
			}
		}
	}
}
