<div class="card">
    <div class="card-header mt-4 p-0">
        <h6 class="information-title">{{'generalInformations' | translate}}</h6>
    </div>
    <div class="card-block">
        <form [formGroup]="generalInfoForm">
            <!-- drive camapaign banner -->
            <div class="row mb-2">
                <div class="col p-0" id="newProjectBrandFileUpload">
                    <mtm-upload-file class="d-inline-block" [preDefinedFilter]="['IMAGE_FILTER']"
                        [isRoundedCircle]=false [isRedirectImageLoadError]=false buttonTitle="drive_campaign_banner"
                        [imageURL]="banner | cacheBusterImage: cacheBuster" [disabled]="false" [isProjectV2Brand]="true"
                        (files)="brandFileChangeListener($event)" [style.maxWidth]="'100%'"
                        [style.maxHeight]="'60px !important'" [style.minWidth]="'100%'"
                        [style.minHeight]="'60px !important'">
                    </mtm-upload-file>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col file-dropzone" id="newProjectFileUpload">
                            <mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=false
                                [disabled]="false" [isRedirectImageLoadError]=false [isProjectV2]="true"
                                [imageURL]="driveCampaignImage | cacheBusterImage: cacheBuster"
                                (files)="fileChangeListener($event)">
                            </mtm-upload-file>
                        </div>
                        <div class="col">
                            <div class="d-flex ">
                                <div class="">
                                    <span class="form-title"> {{'projectCompanyProjectName' | translate}} </span>
                                    <input [ngClass]="{'has-danger': isValidGeneralForm('name')}" class="mt-1 project-name form-control" formControlName="name" type="text">
                                </div>
                                <div class="workspace-dropdown">
                                    <span class="form-title">{{'WorkspaceAxe' | translate}}</span>
                                    <select class="select-workspace mt-1" formControlName="workspace"
                                        (change)="changeWorkspace()"   [ngClass]="{'has-danger': isValidGeneralForm('workspace')}">
                                        <option [value]="'Select Workspace'">{{'selectWorkspace' | translate}}</option>
                                        <option *ngFor="let workspace of workspaces" [value]="workspace.name">{{
                                            workspace.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-4">
                                <span class="form-title"> {{'description' | translate}} </span>
                                <input class="mt-1 form-control description" [ngClass]="{'has-danger': isValidGeneralForm('description')}" formControlName="description" type="text">
                            </div>
                            <div class="mt-4">
                                <span class="form-title"> {{'projectLeaders' | translate}} </span>

                                <div class="row">
                                    <div *ngIf="!isParticipantsLoaded"
                                        class="mt-1 ml-3 d-flex justify-content-start align-items-center project-users form-control">
                                        <div class="d-flex loading-dots ">
                                            <div class="loading-dots--dot"></div>
                                            <div class="loading-dots--dot"></div>
                                            <div class="loading-dots--dot"></div>
                                        </div>
                                    </div>
                                    <div *ngIf="isParticipantsLoaded" class="col" formControlName="projectLeaders">
                                        <div class="mt-3">
                                            <div class="form-field col-section mr-2"
                                                [ngClass]="{ 'has-error': !brandOwners?.length }" >
                                                <div class="d-flex align-items-center collaborators-dropdown"
                                                    ngbDropdown [ngClass]="{'has-danger': isValidGeneralForm('projectLeaders')}"  >
                                                    <div class=" mt-auto d-flex align-items-center p-1 w-100"
                                                        ngbDropdownToggle>
                                                        <div class="flex-fill mr-1">
                                                            <span *ngIf="!brandOwners?.length" class="c-mtm-full-black">
                                                                {{'SelectProjectLeader' | translate}}</span>
                                                            <div *ngIf="brandOwners?.length" class="task-collaborators">
                                                                <div
                                                                    class="task-collaborator-wrapper d-flex align-items-center">
                                                                    <div class="collaborator-avatar mr-1"
                                                                        *ngFor="let owner of existingLeaders">
                                                                        <img class="participant-img"
                                                                            *ngIf="owner.avatarUrl"
                                                                            [src]="owner.avatarUrl" alt="">
                                                                        <span *ngIf="!owner.avatarUrl"
                                                                            class="c-full-white participant-img text-uppercase "
                                                                            [style.backgroundColor]="timelineService.getColorFromName(owner.fullName)">
                                                                            {{owner.fullName | slice:0:2}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <i class="fa fa-chevron-down ml-auto pointer mr-1"></i>
                                                    </div>
                                                    <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                                                        <div class="p-1 w-100">
                                                            <input type="text" placeholder="Search..."
                                                                class="search-text"
                                                                (keyup)="searchParticipants($event)" />
                                                        </div>
                                                        <div *ngFor="let participant of participants"
                                                            class="d-flex align-items-center pointer"
                                                            (click)="ownerSelected($event, participant)"
                                                            ngbDropdownItem>
                                                            <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                                                                [ngClass]="{ 'selected': participant.selected }">
                                                                <i class="fa fa-check"></i>
                                                            </div>
                                                            <img class="participant-img" *ngIf="participant.avatarUrl"
                                                                [src]="participant.avatarUrl" alt="">
                                                            <span *ngIf="!participant.avatarUrl"
                                                                class="c-full-white participant-img text-uppercase"
                                                                [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                                                                {{participant.fullName | slice:0:2}}
                                                            </span>
                                                            <span class="ml-2 _600">{{participant.fullName}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <input [disabled]="!canEditOwner" class="mt-1 project-users form-control" formControlName="projectLeaders" type="text"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <form [formGroup]="criteriaForm">
        <div class="container p-0" style="max-width: 1239px; min-width: 1239px;">
            <div class="d-flex">
                <div class="col-6">
                    <h6 class="information-title">{{'WhatsYourTypeOfProject' | translate}}</h6>
                </div>
                <div *ngIf="isProjectSelected" class="col-6 d-flex flex-wrap align-content-end flex-column-reverse"
                    (click)="resetCriteriaForm(); changeProjectType()">
                    <button class="text-uppercase change-project-type">
                        <img class="mr-1" src=".../../../../../assets/img/project-v2/fa-chevron-left.svg" />
                        {{'ChangeTypeOfProject' | translate}}
                    </button>
                </div>
            </div>
            <div *ngIf="!isProjectSelected" class="row mt-4 d-flex justify-content-between align-items-center"
                [ngClass]="{'campaign-select-error': isValid('Project Type')}">
                <div class="col ml-3 p-0 campaign-image-section d-flex align-items-center justify-content-center"
                    (click)="selectProjectType(productCampaign); removeObjectFromCriteriaForm(['Hallmarks Xmas', 'China Specific', 'Collector', 'Collector Complexity', 'Set', 'Set DMI Produced', 'Coffret Type', 'Gift'])">
                    <span class="project-type-title">{{'productCampaign' | translate}}</span>
                </div>
                <div class="p-3">
                    <span class="d-flex justify-content-center align-items-center _500 partition-title"> or </span>
                </div>
                <div class="col mr-3 p-0 hallmark-image-section d-flex align-items-center justify-content-center"
                    (click)="selectProjectType(hallmarks); removeObjectFromCriteriaForm(['Product Launch', 'Product Launch Category', 'Complexity', '360 Campaign', 'Campaign Type'])">
                    <span class="project-type-title">{{'hallmarks' | translate}}</span>
                </div>
            </div>
            <div *ngIf="isProjectSelected && isProductCampaign" class="mt-3">
                <div class="p-0 productCampaign-project-selected d-flex align-items-center justify-content-center">
                    <span class="project-type-title">{{'productCampaign' | translate}}
                        <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                    </span>
                </div>
                <div class="row w-100 mt-3 ml-3">
                    <div class="row w-100 mt-3 align-items-center">
                        <div class="col-2">
                            <span class="seleted-project-title">{{'isItaProductLaunch' | translate}}</span>
                        </div>
                        <div class="col-3 pl-0">
                            <div class="pl-3 justify-content-evenly">
                                <input class="custom-radio-btn" [ngClass]="{'has-danger': isValid('Product Launch')}"
                                    formControlName="Product Launch" type="radio" value="Yes"
                                    (click)="addObjectInCriteriaForm(['Product Launch Category', 'Complexity', '360 Campaign']);updateCampaignCategory(true)">
                                <label class="custom-radio-label _700 text-uppercase">{{'Yes' | translate}}</label>
                                <input class="custom-radio-btn" [ngClass]="{'has-danger': isValid('Product Launch')}"
                                    formControlName="Product Launch" type="radio" value="No"
                                    (click)="removeObjectFromCriteriaForm(['Product Launch Category', 'Complexity', '360 Campaign', 'Gift']);updateCampaignCategory(false)">
                                <label class="custom-radio-label _700 text-uppercase">{{'No' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Launch'].value == 'Yes'">
                    <div class="row w-100 mt-3 align-items-center" *ngIf="cf['Product Launch'].value == 'Yes'">
                        <div class="col-2">
                            <span class="seleted-project-title">{{'ProductCategory' | translate}}</span>
                        </div>
                        <div class="col-3">
                            <div class="col p-0" style="z-index: 9999999 !important;">
                                <div class="select-wrapper">
                                    <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                        <div [ngClass]="{'has-danger': isValid('Product Launch Category')}"
                                            class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                            <div class="flex-fill mr-1">
                                                <span *ngIf="!selectedProductCategory && !cf['Product Launch Category']?.value"
                                                    class="c-mtm-full-black">{{'selectedProductCategory' | translate
                                                    }}</span>
                                                <div *ngIf="selectedProductCategory || cf['Product Launch Category']?.value " class="task-collaborators">
                                                    <div class="task-collaborator-wrapper d-flex align-items-center">
                                                        <div class="collaborator-avatar d-flex">
                                                            <span
                                                                class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">{{(selectedProductCategory ? selectedProductCategory  : cf['Product Launch Category']?.value
                                                                )| translate }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                        </div>
                                        <div class=" w-100" ngbDropdownMenu>
                                            <div *ngFor="let category of productCategory"
                                                class="d-flex align-items-center pointer"
                                                (click)="getProductCategory(category)" ngbDropdownItem>
                                                <span class="ml-2 _400">{{ category.name | translate }}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Launch'].value == 'Yes'">
                    <div class="row w-100 mt-3 align-items-center" *ngIf="cf['Product Launch'].value == 'Yes'">
                        <div class="col-2">
                            <span class="seleted-project-title">{{'complexity' | translate}}</span>
                        </div>
                        <div class="col-3">
                            <div class="col p-0">
                                <div class="select-wrapper">
                                    <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                        <div [ngClass]="{'has-danger': isValid('Complexity')}"
                                            class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                            <div class="flex-fill mr-1">
                                                <span *ngIf="!selectedComplexityCategory && !cf['Complexity']?.value"
                                                    class="c-mtm-full-black">{{'selectedComplexityCategory' | translate
                                                    }}</span>
                                                <div *ngIf="selectedComplexityCategory || cf['Complexity']?.value" class="task-collaborators">
                                                    <div class="task-collaborator-wrapper d-flex align-items-center">
                                                        <div class="collaborator-avatar d-flex">
                                                            <span
                                                                class="c-mtm-full-black ml-2 _400 _1-line-text-ellipsis mr-1">{{(selectedComplexityCategory ? selectedComplexityCategory : cf['Complexity']?.value)
                                                                | translate }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                        </div>
                                        <div class="w-100" ngbDropdownMenu>
                                            <div *ngFor="let category of categoryComplexity"
                                                class="d-flex align-items-center pointer"
                                                (click)="getComplexity(category)" ngbDropdownItem>
                                                <span class="ml-2 _400">{{ category.name | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-4 mr-5" *ngIf="cf['Product Launch'].value == 'Yes'">
                    <div class="row w-100 mt-3 align-items-center" *ngIf="cf['Product Launch'].value == 'Yes'">
                        <div class="col-2">
                            <span class="seleted-project-title">{{'IsItA360Campaign' | translate}}</span>
                        </div>
                        <div class="col-3 pl-0">
                            <div class="pl-3 justify-content-evenly">
                                <input class="custom-radio-btn" formControlName="360 Campaign" type="radio" value="Yes"
                                    (click)="addObjectInCriteriaForm(['Campaign Type', 'POSM', 'POSM DMI Produced']);updateCampaignCategory(true)">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'has-danger': isValid('360 Campaign')}">{{'Yes' | translate}}</label>
                                <input class="custom-radio-btn" formControlName="360 Campaign" type="radio" value="No"
                                    (click)="removeObjectFromCriteriaForm(['Campaign Type', 'POSM', 'POSM DMI Produced']);updateCampaignCategory(false)">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'has-danger': isValid('360 Campaign')}">{{'No' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-4 mr-5"
                        *ngIf="(cf['360 Campaign'] && cf['360 Campaign'].value == 'Yes') || cf['Product Launch'].value == 'No'">
                    <div class="row w-100 mt-3 align-items-center"
                        *ngIf="(cf['360 Campaign'] && cf['360 Campaign'].value == 'Yes') || cf['Product Launch'].value == 'No'">
                        <div class="col-2">
                            <span class="seleted-project-title">
                                <img class="mr-2" src="../../../assets/img/selection-grid-edit/questionmarkicon.svg"
                                    [ngbTooltip]="campaignTooltip" alt="">
                                {{'CampaignType' | translate}}</span>
                        </div>
                        <ng-template #campaignTooltip>
                            <div [style.minWidth]="'175px'" class="p-1">
                                <div class="text-left">{{'CampaignTypeTooltip' | translate}}</div>
                            </div>
                        </ng-template>
                        <div class="col-2">
                            <div class="col p-0">
                                <div class="select-wrapper">
                                    <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                        <div [ngClass]="{'has-danger': isValid('Campaign Type')}"
                                            class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                            <div class="flex-fill mr-1">
                                                <span *ngIf="!selectedLCampaignTypeCategory && !cf['Campaign Type'].value"
                                                    class="c-mtm-full-black">{{'selectedLCampaignTypeCategory' |
                                                    translate }}</span>
                                                <div *ngIf="selectedLCampaignTypeCategory || cf['Campaign Type'].value" class="task-collaborators">
                                                    <div class="task-collaborator-wrapper d-flex align-items-center">
                                                        <div class="collaborator-avatar d-flex ">
                                                            <span
                                                                class="c-mtm-full-black _400">{{(selectedLCampaignTypeCategory ? selectedLCampaignTypeCategory : cf['Campaign Type'].value) | translate }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                        </div>
                                        <div class="w-100" ngbDropdownMenu>
                                            <div *ngFor="let category of campaignTypeCategory"
                                                class="d-flex align-items-center pointer"
                                                (click)="getCampaignType(category)" ngbDropdownItem>
                                                <span class="ml-2 _400">{{ category.name | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2" *ngIf="cf['POSM'] ">
                            <span class="seleted-project-title">{{'posm' | translate}}</span>
                        </div>
                        <div class="col-2" *ngIf="cf['POSM']">
                            <div class="row justify-content-evenly">
                                <input class="custom-radio-btn" formControlName="POSM" type="radio" value="Yes"
                                    (click)="addObjectInCriteriaForm(['POSM DMI Produced'])">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'text-danger': isValid('POSM')}">{{'Yes' | translate}}</label>
                                <input class="custom-radio-btn" [ngClass]="{'has-danger': isValid('POSM')}"
                                    formControlName="POSM" type="radio" value="No"
                                    (click)="removeObjectFromCriteriaForm(['POSM DMI Produced'])">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'text-danger': isValid('POSM')}">{{'No' | translate}}</label>
                            </div>
                        </div>
                        <div *ngIf="cf['POSM'] && cf['POSM'].value == 'Yes'" class="col-2">
                            <span class="seleted-project-title">{{'dmiProduced' | translate}}</span>
                        </div>
                        <div *ngIf="cf['POSM'] && cf['POSM'].value == 'Yes'" class="col-2">
                            <div class="row justify-content-evenly">
                                <input class="custom-radio-btn" formControlName="POSM DMI Produced" type="radio"
                                    value="Yes">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'text-danger': isValid('POSM DMI Produced')}">{{'Yes' |
                                    translate}}</label>
                                <input class="custom-radio-btn" formControlName="POSM DMI Produced" type="radio"
                                    value="No">
                                <label class="custom-radio-label _700 text-uppercase"
                                    [ngClass]="{'text-danger': isValid('POSM DMI Produced')}">{{'No' |
                                    translate}}</label>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line mt-4 mr-5"
                        *ngIf="cf['360 Campaign'] && cf['360 Campaign'].value == 'No'">
                    <div class="row w-100 mt-3 align-items-center"
                        *ngIf="cf['360 Campaign'] && cf['360 Campaign'].value == 'No'">
                        <div class="col">
                            <span>{{'reducedTimeline' | translate}}.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isProjectSelected && !isProductCampaign " class="mt-3">
                <div class="p-0 animation-project-selected d-flex align-items-center justify-content-center">
                    <span class="project-type-title">{{'hallmarks' | translate}}
                        <img class="ml-1" src="../../../assets/img/project-v2/right-arrow.svg" alt="">
                    </span>
                </div>
                <div class="row w-100 mt-3 ml-3 align-items-center">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'isItChinaSpecific' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="China Specific" type="radio" value="Yes"
                                (click)="removeObjectFromCriteriaForm(['Hallmarks Xmas'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('China Specific')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="China Specific" type="radio" value="No"
                                (click)="addObjectInCriteriaForm(['Hallmarks Xmas'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('China Specific')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                </div>
                <hr class="horizontal-line mt-3" *ngIf="cf['China Specific'].value == 'No'">
                <div class="row w-100 mt-3 ml-3 align-items-center" *ngIf="cf['China Specific'].value == 'No'">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'isItForXmas' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="Hallmarks Xmas" type="radio" value="Yes">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Hallmarks Xmas')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="Hallmarks Xmas" type="radio" value="No">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Hallmarks Xmas')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                </div>
                <hr class="horizontal-line mt-3">
                <div class="row w-100 mt-3 ml-3 align-items-center">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'collector' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="Collector" type="radio" value="Yes"
                                (click)="addObjectInCriteriaForm(['Collector Complexity'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Collector')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="Collector" type="radio" value="No"
                                (click)="removeObjectFromCriteriaForm(['Collector Complexity'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Collector')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="cf['Collector'].value=='Yes'">
                        <span class="seleted-project-title">{{'complexity' | translate}}</span>
                    </div>
                    <div class="col-2 pl-0" *ngIf="cf['Collector'].value=='Yes'">
                        <div class="select-wrapper mr-3">
                            <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                <div [ngClass]="{'has-danger': isValid('Collector Complexity')}"
                                    class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                    <div class="flex-fill mr-1">
                                        <span *ngIf="!hallmarkSelectedComplexityCategory && !cf['Collector Complexity'].value"
                                            class="c-mtm-full-black">{{'hallmarkSelectedComplexityCategory' | translate }}</span>
                                        <div *ngIf="hallmarkSelectedComplexityCategory || cf['Collector Complexity'].value" class="task-collaborators">
                                            <div class="task-collaborator-wrapper d-flex align-items-center">
                                                <div class="collaborator-avatar d-flex">
                                                    <span class="c-mtm-full-black _400">{{(hallmarkSelectedComplexityCategory ? hallmarkSelectedComplexityCategory : cf['Collector Complexity'].value
                                                        )| translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                </div>
                                <div class="w-100" ngbDropdownMenu>
                                    <div *ngFor="let category of hallmarksComplexicity"
                                        class="d-flex align-items-center pointer"
                                        (click)="getHallmarkComplexity(category)" ngbDropdownItem>
                                        <span class="ml-2 _400">{{ category.name | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <select class="dropdown-toggle section-filter" formControlName="Collector Complexity"
                                (change)="getHallmarkComplexity(isHallmarkComplexityDropdownOpen,$event)"
                                [ngClass]="{'has-danger': isValid('Collector Complexity')}">
                                <option class="option-hover" value="">{{'hallmarkSelectedComplexityCategory' |
                                    translate}}
                                </option>
                                <option class="option-hover" *ngFor="let category of hallmarksComplexicity"
                                    [value]="category.name">
                                    {{ category.name }}</option>
                            </select> -->
                        </div>
                    </div>
                </div>
                <hr class="horizontal-line mt-3">
                <div class="row w-100 mt-3 ml-3 align-items-center">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'set' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="Set" type="radio" value="Yes"
                                (click)="addObjectInCriteriaForm(['Set DMI Produced', 'Coffret Type', 'Gift'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Set')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="Set" type="radio" value="No"
                                (click)="removeObjectFromCriteriaForm(['Set DMI Produced', 'Coffret Type','Gift'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Set')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="cf['Set'].value=='Yes'" class="col-2">
                        <span class="seleted-project-title">{{'dmiProduced' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div *ngIf="cf['Set'].value=='Yes'" class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="Set DMI Produced" type="radio" value="Yes"
                                (click)="addObjectInCriteriaForm(['Coffret Type','Gift'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Set DMI Produced')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="Set DMI Produced" type="radio" value="No"
                                (click)="removeObjectFromCriteriaForm(['Coffret Type','Gift'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Set DMI Produced')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                </div>
                <hr class="horizontal-line mt-3"
                    *ngIf="cf['Set'].value=='Yes' && cf['Set DMI Produced'] && cf['Set DMI Produced'].value=='Yes'">
                <div class="row w-100 mt-3 ml-3 align-items-center"
                    *ngIf="cf['Set'].value=='Yes' && cf['Set DMI Produced'] && cf['Set DMI Produced'].value=='Yes'">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'setType' | translate}}</span>
                    </div>
                    <div class="col-2 pl-0">
                        <div class="select-wrapper mr-3">
                            <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
                                <div [ngClass]="{'has-danger': isValid('Coffret Type')}"
                                    class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                                    <div class="flex-fill mr-1">
                                        <span *ngIf="!selectedCoffretType && !cf['Coffret Type'].value"
                                            class="c-mtm-full-black">{{'selectedCoffretType' | translate}}</span>
                                        <div *ngIf="selectedCoffretType || cf['Coffret Type'].value" class="task-collaborators">
                                            <div class="task-collaborator-wrapper d-flex align-items-center">
                                                <div class="collaborator-avatar d-flex">
                                                    <span class="c-mtm-full-black _400">{{(selectedCoffretType ? selectedCoffretType  : cf['Coffret Type'].value )| translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa fa-chevron-down ml-auto pointer mr-1 c-mtm-full-black"></i>
                                </div>
                                <div class="w-100" ngbDropdownMenu>
                                    <div *ngFor="let category of typeOfCoffret"
                                        class="d-flex align-items-center pointer"
                                        (click)="getCoffretType(category)" ngbDropdownItem>
                                        <span class="ml-2 _400">{{ category.name | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <select class="dropdown-toggle section-filter" formControlName="Coffret Type"
                                (change)="getCoffretType(isCoffretTypeDropdownOpen,$event)"
                                [ngClass]="{'has-danger': isValid('Coffret Type')}">
                                <option class="option-hover" value="">{{'selectedCoffretType' | translate}}</option>
                                <option class="option-hover" *ngFor="let category of typeOfCoffret"
                                    [value]="category.name">{{
                                    category.name | translate}}</option>
                            </select> -->
                        </div>
                    </div>
                    <div class="col-2">
                        <span class="seleted-project-title">{{'gift' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="Gift" type="radio" value="Yes">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Gift')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="Gift" type="radio" value="No">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('Gift')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                </div>
                <hr class="horizontal-line mt-3">
                <div class="row w-100 mt-3 ml-3 align-items-center">
                    <div class="col-2">
                        <span class="seleted-project-title">{{'posm' | translate}}</span>
                    </div>
                    <div class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="POSM" type="radio" value="Yes"
                                (click)="addObjectInCriteriaForm(['POSM DMI Produced'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('POSM')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="POSM" type="radio" value="No"
                                (click)="removeObjectFromCriteriaForm(['POSM DMI Produced'])">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('POSM')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="cf['POSM'].value == 'Yes'" class="col-2">
                        <span class="seleted-project-title">{{'dmiProduced' | translate}}</span>
                    </div>
                    <div *ngIf="cf['POSM'].value == 'Yes'" class="col-2">
                        <div class="row justify-content-evenly">
                            <input class="custom-radio-btn" formControlName="POSM DMI Produced" type="radio"
                                value="Yes">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('POSM DMI Produced')}">{{'Yes' | translate}}</label>
                            <input class="custom-radio-btn" formControlName="POSM DMI Produced" type="radio" value="No">
                            <label class="custom-radio-label _700 text-uppercase"
                                [ngClass]="{'text-danger': isValid('POSM DMI Produced')}">{{'No' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="horizontal-line mt-3">
        <div class="row mt-3">
            <div class="row w-100 ml-3 align-items-center">
                <div class="col-2 p-0">
                    <span class="information-title">{{'whatsYourExpectedMAD' | translate}}</span>
                </div>
                <div class="col-3 pl-0">
                    <div class="input-group h-100 mt-1" [ngClass]="{'has-danger': isValid('expectedDate')}">
                        <input class="form-control h-100 date-picker" formControlName="expectedDate" ngbDatepicker
                            #date="ngbDatepicker" firstDayOfWeek="1" (click)="date.toggle()"
                            [(ngModel)]="expectedMadDate" (ngModelChange)="filterDateChanged()">
                        <div class="input-group-addon d-flex flex-column justify-content-center"
                            (click)="date.toggle()">
                            <img src="'../../../../../assets/img/square-calendar.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <span class="information-title">{{'defineThePriorityLevel' | translate}}</span>
                </div>
                <div class="col-2 prioritylevel-btn" (click)="getOtherPriority()" formControlName="priority"  
                [ngClass]="{'has-danger': isValid('priority')}" 
                    [ngClass]="isOtherPriorityLevel ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="mr-4 _400 f-12">{{'other' | translate}}</div>
                        <div class="">
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isOtherPriorityLevel ? 'green-star': 'black-star'"></i>
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isOtherPriorityLevel ? 'gray-star' : 'gray-star'"></i>
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isOtherPriorityLevel ? 'gray-star' : 'gray-star'"></i>
                        </div>
                    </div>
                </div>
                <!-- priority level -->
                <div class="col-2 prioritylevel-btn ml-4" (click)="getCriticalPriority()" formControlName="priority" 
                [ngClass]="{'has-danger': isValid('priority')}" 
                    [ngClass]="isCriticalPriorityLevel ? 'active-prioritylevel-btn': 'prioritylevel-btn'">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="mr-4 _400 f-12">{{'critical' | translate}}</div>
                        <div class="">
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isCriticalPriorityLevel ? 'green-star' : 'black-star'"></i>
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isCriticalPriorityLevel ? 'green-star' : 'black-star'"></i>
                            <i class="fa fa-star ml-1" aria-hidden="true"
                                [ngClass]="isCriticalPriorityLevel ? 'green-star' : 'black-star'"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="horizontal-line mt-3">
        <div class="row my-3">
            <div class="container m-0 p-0" [style.max-width]="'1290px'" [style.min-width]="'1290px'">
                <div class="row">
                    <div class="col-sm ml-3" *ngIf="this.projectV2ServiceService.timelineId">
                        <button (click)="returnToProject()" class="bg-white return-to-project-button" type="button">
                            <span class="_700 f-12 text-uppercase">{{'returnToProjects' | translate}}</span>
                        </button>
                    </div>
                    <div class="col-sm p-0 mr-5 d-flex justify-content-end">
                        <button class="next-button" type="button" (click)="onContinue()">
                            <span class="_700 f-12 text-light text-uppercase">{{'next' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>