<div class="container p-0" [ngClass]="{'w-1340': isProposal}" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
	<div class="container mtm-dropdown sorting">
		<select class="selected-value" (ngModelChange)="sortSubSection($event)" [(ngModel)]="selectedSubsection">
			<option [value]=""> Sort Subsection </option>
			<option [value]="sortingValue[0]">{{sortingValue[0]}}</option>
			<option [value]="sortingValue[1]">{{sortingValue[1]}}</option>
			<option [value]="sortingValue[2]">{{sortingValue[2]}}</option>
			<option [value]="sortingValue[3]">{{sortingValue[3]}}</option>
		</select>
	</div>
	<div class="row main-nav-top no-gutters">
		<div class="col text-left pl-1">
			<span class="text-uppercase font-weight-bold">{{service.project.startDate | mtmDate}}</span>
		</div>
		<div class="col text-right pr-1">
			<span class="text-uppercase font-weight-bold">{{service.project.plannedEndDate | mtmDate}}</span>
		</div>
	</div>
	<div class="progress line-h-unset">
		<div class="progress-bar" oneShotDownPayment [project]="project" [index]="i" [section]="section"
			[ngClass]="{'inactive': expand && sectionSelected && sectionSelected !== section}"
			*ngFor="let section of service.sections; let i = index"
			[style.width]="section.width ? section.width + '%' : '20%'" [style.background-color]="service.colors[i]"
			[class.unclear]="!barClarity[i]" (click)="selectSection(section, i)">
			<span class="text-uppercase">{{section.name | translate}}</span>
		</div>
	</div>
	<div class="row m-0 pt-4 sections-container" [style.background-color]="service.colors[position]"
		*ngIf="sectionSelected" [class.unclear]="!barClarity[position]">
		<ul class="nav w-100 stages-created" [dragula]="'subsections'" [dragulaModel]="subsections" (dragulaModelChange)="subsections = $event">
			<li class="nav-item subsection-button" *ngFor="let subsection of subsections | cleaner"
				(click)="edit(subsection)" id="{{ subsection.id }}">
				<button *ngIf="isDeletableSubsection(subsection)" class="btn btn-danger btn-delete-subsection"
					(click)="delete(subsection.id);$event.stopPropagation()"><i class="fa fa-trash fa-lg"
						aria-hidden="true"></i></button>
				<div class="d-flex align-items-center h-100" *ngIf="!subsection.id">
					<i class="fa fa-spinner fa-pulse fa-fw fa-2x" aria-hidden="true"></i>
				</div>
				<div class="col p-0" *ngIf="subsection.id">
					<span class="dates" [ngClass]="{'badge': !hasDate(subsection)}" *ngIf="hasDate(subsection)">
						<i class="fa fa-calendar fa-fw" aria-hidden="true"></i>
						<small class="font-weight-bold">
							<span *ngIf="subsection.startDate && subsection.endDate">{{subsection.startDate | mtmDate}}
								- {{subsection.endDate | mtmDate}}</span>
						</small>
					</span>
					<p class="m-0" [ngClass]="{'section-without-dates': !hasDate(subsection)}">
						<span class="text-uppercase">{{ subsection.name | translate}}</span>
					</p>
				</div>
			</li>
			<li class="nav-item drop-here" *ngIf="draggables.length">
				<div class="nav-content">
					<p class="m-0">
						<span class="text-uppercase font-weight-bold">{{'dropHere' | translate}}</span>
					</p>
				</div>
			</li>
		</ul>
	</div>
	<div class="d-block mt-4">
		<span>{{ 'projectTextInfo' | translate }}</span>
	</div>
	<div class="d-block drag-and-drop" *ngIf="sectionSelected">
		<ul class="nav w-100 stages-draggables" [dragula]="'subsections'" [dragulaModel]="draggables"
			*ngIf="sectionSelected.section!='SHOOTING'">
			<li class="nav-item subsection-draggable" id="{{ draggable.subSection }}"
				*ngFor="let draggable of draggables | cleaner" [style.background-color]="service.colors[position]">
				<div class="nav-content">
					<p class="m-0 w-100 text-center">
						<span class="text-uppercase font-weight-bold">{{ draggable.name | translate }}</span>
					</p>
				</div>
			</li>
		</ul>
		<p class="m-0 w-100 text-center" *ngIf="sectionSelected.section=='SHOOTING'">
			<span class="text-uppercase font-weight-bold">{{'pleaseNote' | translate}}</span>
			<span>{{'shootingNote' | translate}}</span><br /><br />
		</p>
	</div>
	<div class="d-flex mx-0 my-4 justify-content-between">
		<button *ngIf="!isProposal && service.project.id" class="btn btn-lg btn-danger mr-2" type="button"
			(click)="returnToProject()">
			<span>{{'returnProject' | translate}}</span>
		</button>
		<button type="button" style="margin-right: 0;margin-left: auto;" class="btn btn-lg btn-danger"
			(click)="continue()">{{'project_goToPermissions' | translate}}</button>
	</div>
</div>