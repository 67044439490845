
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { HelperService } from "./helper.service";

@Injectable({
	providedIn: 'root'
})
export class CurrencyService {
	constructor(private http: Http) { }
	getMyCountryDetail(): Observable<Response> {
		return this.http.get('https://ip-geo-location.p.rapidapi.com/ip/check', {
			headers: HelperService.getHttpRapidAPIHeaders()
		}).pipe(map((data: Response) => data.json()))
	}

	getHttpHeaders(): Headers {
		let headers = new Headers();
		headers.append('x-rapidapi-key', '962a261d3amshe957a1acab414eep13b360jsn7f9fb9b79935');
		headers.append('x-rapidapi-host', 'ip-geo-location.p.rapidapi.com');
		return headers;
	}

}
