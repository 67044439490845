export const TransferStatus = {
	NEW: 'NEW',
	WAITING: 'WAITING',
	SENDING: 'SENDING',
	UPLOADED: 'UPLOADED',
	ERROR: 'ERROR',
	COMPLETED: 'COMPLETED',
	PAUSED: 'PAUSED',
	CANCELED: 'CANCELED',
	INACTIVE: 'INACTIVE', //state after refresh
	DOWNLOADING: 'DOWNLOADING'
}
