import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Pipe({
    name: 'FilterUser'
})
export class FilterUser implements PipeTransform {
    transform(value: any, ...args: any) {
        let result = value;
        Object
            .keys(args)
            .forEach((k, i, v) => {
                if (i == 0) {
                    result = result.filter(u => HelperService.getNormalizedName(u.fullName).toLowerCase().trim().indexOf(HelperService.getNormalizedName(args[i])) >= 0);
                } else if (i == 1) {
                    result = result.filter(u => HelperService.getNormalizedName(u.username).toLowerCase().trim().indexOf(HelperService.getNormalizedName(args[i])) >= 0);
                } else if (i == 2) {
                    if (args[i].length) {
                        result = result.filter(u => u.roles.find(r => args[i].includes(r)));
                    }
                } else if (i == 3) {
                    if (args[i] !== '') {
                        result = result.filter(u => {
                            return u.selectOdm === args[i];
                        });
                    }
                }
            });
        return result;
    }
}
