import { UUID } from "angular2-uuid";

export class PaymentDTO {
    companyId: UUID;
    projectId: UUID;
    companyName;
    cardNumber?: string;
    cardType?: string;
    expireMonth?: number;
    expireYear?: number;
    firstName: string;
    lastName: string;
    cvv?: string;
    cardId?: string;
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    zipCode?: string;
    projectType: string;
    paymentType: string;
    stripeToken: string;
    ibanNo?: string;
    proposalId: UUID;
    sourceId?: string;
    paymentKind?: string;
    email?: string;
    paymentIntentId: string;
    clientSecret: string;
    currency?: string;

    constructor() {

    }
}
