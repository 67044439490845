<div class="container" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
	<h4 class="text-left mt-3 pl-3" *ngIf="!project">{{ status }} Project</h4>
	<h4 class="text-left mt-3 pl-3" *ngIf="project">{{project.name}}</h4>
	<h5 class="text-left mt-3 pl-3">{{links[index]?.text || '' | translate }}</h5>
	<h6 class="text-left mt-3 pl-3">{{links[index]?.subText || '' | translate:{'TOTALSECTIONS':totalSections} }}</h6>
	<p class="text-left pl-3">
	</p>
	<ul class="list-inline steps" style="display: block;">
		<li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active"
			*ngFor="let link of links; let i = index" id="{{link?.id}}" [ngClass]="{'step-complete': isComplete(i)}" >
			<a [routerLink]="link.url" class="project-link" [class.disabled]="link.requiresProject && ! projectId" (click)="trainerUserPermission()">
				<i *ngIf="isComplete(i)" class="fa fa-check-circle" aria-hidden="true"></i>
				<div *ngIf="!isComplete(i)" class="step-number">{{i + 1}}</div>
				<span class="text-uppercase">{{link.title || '' | translate}}</span>
			</a>
		</li>
	</ul>
</div>
<router-outlet></router-outlet>