import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "app/shared/services/user.service";
import { NotificationService } from './../../../../shared/services/notification.service';
import { ProjectProposalNewUserComponent } from './project-proposal-new-user/project-proposal-new-user.component';
import { ProductionTeamService } from 'app/shared/services/proposal-services/productionteam.service';
import { ProposalTeamUserModel } from './proposal-user.model';
import { PermissionsInfoModal } from '../../permissions-info-modal/permissions-info-modal.component';
import { Observable, Subject } from "rxjs";
import { ProjectService } from "../../../../shared/services/project.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from "../../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'mtm-project-proposal-team',
	templateUrl: './project-proposal-team.component.html',
	styleUrls: ['./project-proposal-team.component.scss']
})
export class ProjectProposalTeamComponent implements OnInit, OnDestroy {
	@Input() isUserPermissions: boolean = false;

	user: any = {};
	private invitationProjectId;
	proposalId;

	private jops: any[] = [];
	users: ProposalTeamUserModel[] = [];
	private usersBackup: ProposalTeamUserModel[] = [];
	private allRoles: any[] = [];
	private allCompanyUsers: any[] = [];
	decision: boolean = true;
	authority = Authority;
	ngUnsubscribe = new Subject();

	constructor(private serviceUser: UserService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		public auth: AuthService,
		private modalService: NgbModal,
		private notificationService: NotificationService,
		public userService: UserService,
		private projectService: ProjectService,
		private serviceProductionTeam: ProductionTeamService,
		private serviceOverlay: OverlayService,
		public permissionService: PermissionService) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.user = this.auth.getAuthUser();
		this.invitationProjectId = this.activatedRoute.parent.snapshot.params['projectId'];
		this.proposalId = this.activatedRoute.parent.snapshot.params['proposalId'];

		this.jops = this.serviceUser.jops;

		this.getAllRoles();
		// this.getProposalTeamUsers();
	}

	private getAllRoles() {
		this.serviceProductionTeam.getAllRoles().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			data => {
				let sort = ['PRODUCTION_OWNER', 'PRODUCTION_MANAGER', 'PRODUCTION_SUPERVISOR', 'PRODUCTION_SENIOR_STAFF', 'PRODUCTION_STAFF'];
				let newArr = [];
				sort.forEach(code => {
					let foundItem = data.find(k => k.code == code);
					foundItem ? newArr.push(foundItem) : null;
				});
				this.allRoles = newArr;
				this.getProposalTeamUsers();
			});
	}



	private getProposalTeamUsers() {
		this.serviceProductionTeam.getProposalTeamUsersForProjectId(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				this.users = res;
				this.users.forEach(user => {
					this.setUserAvatar(user);
					this.setUserName(user);
					this.setUserRoleText(user);
					this.setJobTitle(user);
				})
			});
	}

	private setJobTitle(user: any) {
		this.userService.getUserByUsername(user.username).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(userData) => {
				user.jobTitle = userData.jobTitle;
			}
		)
	}


	private setUserRoleText(user) {
		let index = this.allRoles.findIndex(k => k.code == user.roles);
		if (index >= 0) {
			user.roleText = this.allRoles[index].label;
			user.userRole = this.allRoles[index].code
		}
	}
	private setUserAvatar(user) {
		user.avatarUrl = user.avatarUrl ? user.avatarUrl : '../../../assets/img/misc/avatar-no-image.png';
	}
	private setUserName(user) {
		let index = this.allCompanyUsers.findIndex(p => p.userName == user.userName);
		if (index >= 0)
			user.userName = this.allCompanyUsers[index].firstName + ' ' + this.allCompanyUsers[index].lastName;
	}

	private isUpdating(user): boolean {
		return user.isUpdating;
	}

	addNewUser() {
		let modal = this.modalService.open(ProjectProposalNewUserComponent, { size: 'lg' });
		modal.componentInstance.allCompanyUsers = this.allCompanyUsers;
		if ((this.decision == false && this.getDecisionLenght() < 1) || this.decision == true)
			modal.componentInstance.allRoles = JSON.parse(JSON.stringify(this.allRoles));
		else
			modal.componentInstance.allRoles = JSON.parse(JSON.stringify(this.allRoles.filter(k => k.code.indexOf('OWNER') < 0)));

		modal.result.then(
			(result: any) => {
				if (!result)
					return;

				let filtered = this.users.filter(u => u.username == result.username);
				// It means user already exists in the list

				if (filtered.length > 0)
					this.notificationService.open({
						title: 'User Invitation',
						description: `This user is already in the list!`,
						confirmBtn: 'OK'
					});
				else {
					result.projectId = this.invitationProjectId;
					this.saveUser(result);
				}
			},
			(err: any) => {
				console.error(err);
			});

		return modal;
	}

	private saveUser(result: any) {

		let saveUser: any;
		saveUser = {
			"username": result.username, "jobTitle": result.jobTitle, "projectId": this.projectService.proposal.projectId,
			"companyId": this.projectService.proposal.prodCompanyId,
			"userRole": result.userRole
		};

		this.serviceProductionTeam.saveTeamUser(saveUser).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				this.getProposalTeamUsers();
				this.serviceProductionTeam.setUserToProposalUsers(this.invitationProjectId, this.proposalId, res);
			});
	}

	oneDecisionMaker(user: ProposalTeamUserModel) {
		// let recommend = this.users.filter(u => u.recommend == true);
		// if (recommend.length <= 1)
		// 	return;

		// this.users.filter(u => u.userName != user.userName).map(u => u.recommend = false);
		// this.users.filter(u => u.userName != user.userName)
		// 	.forEach(u => {
		// 		this.serviceProductionTeam.saveTeamUser(u).subscribe(res => {
		// 			console.log('success1')
		// 		});
		// 	});

	}

	onDeleteUser(user: ProposalTeamUserModel) {
		this.notificationService.open({
			title: 'Delete user',
			description: `Are you sure you want to delete this user?\n${user.username} from project\n`,
			confirmBtn: 'Yes, delete',
			cancelBtn: 'Cancel'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			if (!confirm)
				return;

			this.serviceProductionTeam.removeTeamUser(user.username, this.invitationProjectId, this.proposalId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(res) => {
					let index = this.users.findIndex(k => k.username == user.username);
					if (index >= 0)
						this.users.splice(index, 1);

					this.serviceProductionTeam.removeUserFromProposal(this.invitationProjectId, this.proposalId, user);
				});
		});
	}

	onOpenPermissionInfo() {
		let modal = this.modalService.open(PermissionsInfoModal, { size: 'lg' });
		modal.componentInstance.CLIENTorPROD = 'PRODUCTION';
	}

	//ROUTE METHODS
	goToQASession() {
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'qaSession']);
	}
	goToProjectStages() {
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'projectStages']);
	}

	private addUser(username, data): Observable<any> {
		return this.projectService.addUser(this.invitationProjectId, username, data);
	}

	private setRoles(username, data): Observable<any> {
		return this.projectService.setRoles(this.invitationProjectId, username, data);
	}

	private isDeleteButtonAvailable(user) {
		if (this.auth.getAuthUserName() == user.username) {
			return false;
		}
		return user.roles.indexOf("PRODUCTION_OWNER") < 0 || this.users.filter((u) => u.userRole === "PRODUCTION_OWNER").length > 1
	}

	private initDecision() {
		this.decision = this.getDecisionStatus();
	}

	changeDecision(status: boolean) {
		let state = this.getDecisionStatus();
		if (status == false)
			if (state)
				this.showDecisionErrorMessage();
			else
				this.decision = status;

		if (status == true)
			this.decision = status;
	}

	private showDecisionErrorMessage() {
		this.serviceOverlay.showError("Have more than one selected 'owner' role", 'Recommendation');
	}

	private getDecisionStatus(): boolean {
		return this.getDecisionLenght() > 1;
	}

	private getDecisionLenght(): number {
		return this.users.filter(k => k.userRole.indexOf('OWNER') >= 0).length
	}
}
