import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { BreadcrumbService } from "ng5-breadcrumb";

@Component({
	selector: 'mtm-search-video',
	templateUrl: './search-video.component.html',
	styleUrls: ['./search-video.component.scss']
})
export class SearchVideoComponent implements OnInit {
	@ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
	headerForm: UntypedFormGroup = null;
	isViewChanged: boolean = false;
	searchedValue: string;
	demoimg = '../../../../assets/img/demo-img.jpg'
	keyword = 'fileName';
	tempVideos = [];
	itemListLength: number;
	videoList = [
		{ fileName: 'File Name Lorem ipsum 1', image: this.demoimg },
		{ fileName: 'File Name Lorem ipsum 1', image: this.demoimg },
		{ fileName: 'File Name Lorem ipsum 2', image: this.demoimg },
		{ fileName: 'File Name Lorem ipsum 3', image: this.demoimg },
		{ fileName: 'File Name Lorem ipsum 4', image: this.demoimg },
		{ fileName: 'File Name Lorem ipsum 5', image: this.demoimg }
	]

	constructor(
		private themeService: ThemeService,
		private breadcrumbService: BreadcrumbService,
		private transferService: DataTransferBetweenComponentsService) { }

	ngOnInit(): void {
		this.breadcrumbService.hideRoute('^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/search-video');
		this.breadcrumbService.addFriendlyNameForRoute(`^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/search-video`, `Video Search`);
		this.breadcrumbService.addCallbackForRouteRegex(
			`^/projects/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*/search-video`,
			() => {
				return `Video Search`;
			}
		);

		this.transferService.ngOnChangesBreadcrumbComponent();
		this.tempVideos = this.videoList;
		this.itemListLength = this.videoList.length;
	}

	ngAfterViewInit() {
		this.themeService.updateContent({
			backgroundColor: '',
			rightPane: null,
			backgroundImage: null,
			header: this.headerContent
		});
	}

	searchVideo() {
		if (this.searchedValue.length) {
			var filteredData = this.videoList.filter(creature => {
				return creature.fileName == this.searchedValue
			});
			this.videoList = []
			this.videoList.push(...filteredData);
			this.itemListLength = this.videoList.length;
		} else {
			this.videoList = this.tempVideos;
		}
	}

	inputValueChanged(value) {
		this.searchedValue = value;
		if (value.length == 0) {
			this.videoList = this.tempVideos
		}
	}

	viewChanged(value) {
		this.isViewChanged = value;
		this.isViewChanged = !this.isViewChanged;
	}

	selectedItem(value) {
		this.searchedValue = value.fileName;
	}
}
