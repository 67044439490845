import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DragulaModule } from 'ng2-dragula';
import { TagInputModule } from 'ngx-chips';
import { MomentModule } from 'angular2-moment';
import { LinkyModule } from 'angular-linky';
import { Ng5SliderModule } from 'ng5-slider';


/* ----------- * DIRECTIVES * ----------- */


/* ----------- * GUARDS * ----------- */



/* ----------- * PIPES * ----------- */



/* ----------- * PAGES * ----------- */


/* ----------- * COMPONENTS * ----------- */
import { ToggleSectionsComponent } from 'app/shared/components/toggle-section/toggle-section.component';
import { SubsectionGridComponent } from 'app/shared/components/subsection-grid/subsection-grid.component';
import { ImageSliderComponent } from "app/shared/components/slider/slider.component";
import { ReviewComponent } from 'app/shared/components/review/review.component';
import { ApprovedEditTabComponentForPostProd } from 'app/layouts/post-prod/approved-edit-tab-component/approved-edit-tab.component';
import { UserPopoverComponent } from "app/shared/components/user-popover/user-popover.component";
import { ItemPopoverComponent } from "app/shared/components/item-popover/item-popover.component";
import { SubsectionRightFileControl } from 'app/shared/components/subsection-rigth-file-control/subsection-right-file-control.component';
import { ColorImageDropDownComponent } from 'app/shared/components/dropdown-color-image/color-image-dropdown.component';




/* ----------- * MODAL_COMPONENTS * ----------- */
import { DashboardCommentComponent } from "app/layouts/my-projects/dashboard/dashboard-comment/dashboard-comment.component";
import { NewEventModalComponent } from 'app/layouts/calendar/new-event-modal/new-event-modal.component';
import { EventDetailsComponent } from 'app/layouts/calendar/event-details/event-details.component';

/* ----------- * SERVICES * ----------- */
// import { ToggleSectionService } from 'app/shared/services/toogle-sections.service';
// import { VoteService } from 'app/shared/services/vote.service';
// import { CommentsService } from "app/shared/services/comments.service";


/* ----------- * ROUTE * ----------- */
import { SharedModule } from 'app/sub-modules/SharedModule';
// import { AnnotationService } from "app/shared/services/annotation.service";
import { CommentDatePipe } from "app/shared/components/comment/pipe/comment-date-pipe.pipe";
import { CompanyProfilePortfolioComponent } from "app/layouts/prod-partner/company-profiles/company-profile-portfolio/company-profile-portfolio.component";
import { NgxLoadingModule } from "ngx-loading";
import { MtmChatModule } from "../mtm-chat-module/mtm-chat.module";

import { SubsectionVersionListComponent } from "app/shared/components/subsection-version-list/subsection-version-list.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { SubsectionUploadGridComponent } from "app/shared/components/subsection-upload-grid/subsection-upload-grid.component";
import { VersionsComparerComponent } from "app/shared/components/versions-comparer/versions-comparer.component";
import {
	SubsectionVersionedAssetsComponent
} from "app/shared/components/subsection-versioned-assets/subsection-versioned-assets.component";
import { BatchApprovalComponent } from "app/shared/components/batch-approval/batch-approval.component";
import { SubsectionUploaderComponent } from "../../shared/components/subsection-uploader/subsection-uploader.component";
import { SubtitleElementComponent } from "app/layouts/post-prod/subtitles/subtitles-grid/subtitle-element/subtitle-element.component";
import { SubtitleTimeEditorComponent } from "app/layouts/post-prod/subtitles/subtitles-create/subtitle-time-editor/subtitle-time-editor.component";
import { ColorControlComponent } from "app/layouts/post-prod/subtitles/subtitles-create/color-control/color-control.component";
import { SubtitleStyleControlComponent } from "app/layouts/post-prod/subtitles/subtitles-create/subtitle-style-control/subtitle-style-control.component";
import { ColorPickerModule } from "ngx-color-picker";
import {
	SubsectionInsightComponent
} from "../../layouts/my-projects/dashboard/subsection-insight/subsection-insight.component";
import { SliderHeaderComponent } from "../../shared/components/slider/slider-header/slider-header.component";
import { BatchUploadOptionComponent } from "../../shared/components/batch-upload-option/batch-upload-option.component";
import { UiSwitchModule } from "ngx-ui-switch";
import {BatchUploadEditComponent} from "../../shared/components/batch-upload-edit/batch-upload-edit.component";
import {BatchFeedbackComponent} from "../../shared/components/batch-feedback/batch-feedback.component";
import {
  SectionDateStatusEditorComponent
} from "../../shared/components/section-date-status-editor/section-date-status-editor.component";
import {SectionStatusComponent} from "../../shared/components/section-status/section-status.component";

@NgModule({
	declarations: [
		DashboardCommentComponent,
		NewEventModalComponent,
		EventDetailsComponent,
		ToggleSectionsComponent,
		SubsectionGridComponent,
		SubsectionUploadGridComponent,
		ImageSliderComponent,
		SliderHeaderComponent,
		VersionsComparerComponent,
		ReviewComponent,
		UserPopoverComponent,
		ItemPopoverComponent,
		SubsectionRightFileControl,
		SubsectionUploaderComponent,
		SubsectionVersionedAssetsComponent,
		SubsectionVersionListComponent,
		BatchApprovalComponent,
		ColorImageDropDownComponent,
		ApprovedEditTabComponentForPostProd,
		ApprovedEditTabComponentForPostProd,
		CompanyProfilePortfolioComponent,
		SubtitleElementComponent,
		SubtitleTimeEditorComponent,
		SubtitleStyleControlComponent,
		ColorControlComponent,
		SubsectionInsightComponent,
		BatchUploadOptionComponent,
    BatchUploadEditComponent,
    BatchFeedbackComponent,
    SectionStatusComponent,
    SectionDateStatusEditorComponent
	],
	imports: [
		HttpClientModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgClickOutsideDirective,
		NgbModule,
		DragulaModule,
		TagInputModule,
		LinkyModule,
		SharedModule,
		MomentModule,
		NgxLoadingModule,
		AutocompleteLibModule,
		MtmChatModule,
		Ng5SliderModule,
		ColorPickerModule,
		UiSwitchModule
	],
	providers: [
		CommentDatePipe
	],
	exports: [
		DashboardCommentComponent,
		NewEventModalComponent,
		EventDetailsComponent,
		ToggleSectionsComponent,
		SubsectionGridComponent,
		SubsectionUploadGridComponent,
		ImageSliderComponent,
		SliderHeaderComponent,
		VersionsComparerComponent,
		ReviewComponent,
		UserPopoverComponent,
		ItemPopoverComponent,
		SubsectionRightFileControl,
		SubsectionUploaderComponent,
		SubsectionVersionedAssetsComponent,
		SubsectionVersionListComponent,
		BatchApprovalComponent,
		ColorImageDropDownComponent,
		ApprovedEditTabComponentForPostProd,
		ApprovedEditTabComponentForPostProd,
		CompanyProfilePortfolioComponent,
		SubtitleElementComponent,
		SubtitleTimeEditorComponent,
		SubtitleStyleControlComponent,
		ColorControlComponent,
		SubsectionInsightComponent,
		BatchUploadOptionComponent,
    BatchUploadEditComponent,
    BatchFeedbackComponent,
    SectionStatusComponent,
    SectionDateStatusEditorComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ProjectSharedModule {
}
