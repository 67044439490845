import { Component, OnInit, OnDestroy } from '@angular/core';
import { movieAdded, movieCreated } from 'app/layouts/ai-reframing/cores/ai-framing.events';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mtm-ai-framing-movie-builder-container',
    template: '<div class="movie-builder-container"><mtm-ai-framing-movie-builder [guid]="info.guid" [folderId]="info.folder" [movie]="info.data" *ngFor="let info of movieData;trackBy: trackByFn"></mtm-ai-framing-movie-builder></div>',
    styles: [`.movie-builder-container{display:none;}`]
})
export class AiFramingMovieBuilderContainerComponent implements OnInit, OnDestroy {
    movieData: any = [];
    ngUnsubscribe = new Subject();

    constructor() {
        movieAdded.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
            this.movieData.push({
                guid: this.guid(),
                folder: data.folder,
                data: data.movie
            });
        });

        movieCreated.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(data => {
            this.movieData = this.movieData.filter(s => s.guid !== data.guid);
        })
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void { }

    guid() {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    trackByFn(index, item) {
        return item.id;
    }

}