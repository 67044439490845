
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpCancelService } from "../services/http-cancel.service";
import {IS_UNCANCELABLE_REQUEST} from "../models";

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
	constructor(private httpCancelService: HttpCancelService) {

	}

	intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if(req.context.get(IS_UNCANCELABLE_REQUEST)) {
      return next.handle(req);
    }

		return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
	}
}
