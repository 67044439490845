import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { PLANS } from '../models/const';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionProduceGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService, private subscriptionService: SubscriptionService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		if (this.subscriptionService.paymentSuccessful || this.subscriptionService.registerData.plan === PLANS.FREE && this.subscriptionService.planConfirmed) {
			return true;
		} else {
			this.route.navigate(['/subscription/register']);
		}
	}

}
