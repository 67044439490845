import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { NotificationViewMode } from "app/shared/interfaces";

@Component({
    selector: 'mtm-toaster',
    templateUrl: './mtm-toaster.component.html',
    styleUrls: ['./mtm-toaster.component.scss']
})
export class MTMToasterComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() notification: any;
    @Input() projectId: any;
    NotificationViewType = NotificationViewMode;

    notifications: any = [];

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.notification?.currentValue) {
            this.notifications.push(changes.notification.currentValue);
        }
    }

    ngAfterViewInit() { }

    ngOnDestroy() { }

    removeNotification(e) {
        this.notifications = this.notifications.filter(n => n.time != e.time);
    }
}
