import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateHelperService } from 'app/shared/services/date-helper.service';

import { getIconURL, formatSizeUnits, setDOMSanitizer } from '../common/functions';
import { HelperService } from 'app/shared/services/helper.service';
import { MovieTranslationService } from 'app/shared/services/movie-translation.service';
import { EMPTY_ID } from '../../task/task-status.const';

@Component({
  selector: 'hr-grid-subcmp',
  templateUrl: './file-grid.component.html',
  styleUrls: ['./file-grid.component.scss']
})
export class HRGridFile {
  @Input() file: any;
  @Input() tightDateMargin: boolean = false;
  @Input() compactView: boolean = false;
  @Input() hideDeleteButton: boolean = false;
  @Input() enableAudioTranscription: boolean = false;
  @Input() subSectionId: any;
  @Input() forTranscript: boolean = false;
  @Input() uploading: boolean = false;
  @Input() projectId: string;
  @Input() canEditFilename: boolean = false;
  @Output() nameClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCallback: EventEmitter<any> = new EventEmitter<any>();

  textTranscription: string;
  isAudioFile: boolean;
  timeoutHandler: any;

  constructor(private sane: DomSanitizer, private movieTranslationService: MovieTranslationService) {
    setDOMSanitizer(this.sane);
  }


  ngOnInit() {
    this.isAudioFile = HelperService.isAudioFile(this.file) || HelperService.isVideoFile(this.file);
    if (this.enableAudioTranscription && this.isAudioFile) {
      this.getAudioTranscription();
    }
    this.file.displayTime = DateHelperService.fromTimestampToDate(this.file.timeCreated);
    if (!this.file.id) {
      this.file.displaySize = this.formatSizeUnits(this.file.size);
      this.file.displayName = this.truncate(this.file.displayName);
    }
  }

  ngOnChanges(changes) {
    if (!this.file.id)
      this.file.contentType = this.file.type;
    this.file.printName = this.file.displayName || this.file.name;
    this.file.typeIcon = getIconURL(this.file);
    this.file.displaySize = formatSizeUnits(this.file.size);
    this.file.displayTime = DateHelperService.fromTimestampToDate(this.file.timeCreated);
  }

  checkNeedToReFetchStatus() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    this.timeoutHandler = setTimeout(this.getAudioTranscription.bind(this), 10000);
  }

  getAudioTranscription() {
    this.movieTranslationService.getSubtitle(this.subSectionId, EMPTY_ID, this.file.id)
      .subscribe((res) => {
        if (res.subtitles?.length) {
          this.textTranscription = this.getTranscription(res.subtitles[0]);
        } else {
          this.checkNeedToReFetchStatus();
        }
      })
  }

  getTranscription(subtitle) {
    if (subtitle.status != 'completed') {
      this.checkNeedToReFetchStatus();
      return '';
    }
    if (!subtitle.subtitleJson) {
      subtitle.subtitleJson = [];
    }
    return subtitle.subtitleJson.reduce((acc, curr, index) => {
      acc += curr.content;
      return acc;
    }, '');
  }

  handleNameClick() {
    if (this.canEditFilename) {
      return;
    }
    this.nameClick.emit(this.file);
  }

  handleIconClick() {
    this.iconClick.emit(this.file);
  }

  deleteFile() {
    this.deleteCallback.emit(this.file);
  }

  private formatSizeUnits(bytes) {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; }
    else if (bytes > 1) { bytes = bytes + ' bytes'; }
    else if (bytes == 1) { bytes = bytes + ' byte'; }
    else { bytes = '0 byte'; }
    return bytes;
  }
  private truncate(fileName) {
    if (fileName?.length > 20)
      return fileName.substring(0, 20) + '...' + fileName.substring(fileName.length - 4, fileName.length);
    return fileName;
  }
}
