<div class="permission-actions text-center">
    <a class="mb-3 d-block user-action reinvite" *ngIf="params.data.status == 'PENDING_INPROGRESS'"
        [class.tooltipped]="!params.context.componentParent.canEditProject"
        [attr.data-title]="'workspace_edit_cantReinviteUser' | translate"
        (click)="params.context.componentParent.invite(params.data)">{{'reInvite' | translate}}</a>

    <a (click)="params.context.componentParent.removeUser(params.data)" class="d-block mb-2 user-action delete"
        [class.tooltipped]="!params.context.componentParent.canEditProject"
        [attr.data-title]="'workspace_edit_cantRemoveUser' | translate"><i class="fa fa-trash-o" aria-hidden="true"></i>
        {{'workspace_edit_removeFromProject' | translate}}</a>
</div>