import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { SubscriptionService } from "../../shared/services/subscription.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'mtm-build-plan-circle',
    templateUrl: './build-plan-circle.component.html',
    styleUrls: ['./build-plan-circle.component.scss']
})
export class BuildPlanCircleComponent implements OnInit {

    @Output() onApply = new EventEmitter<any>();
    @Input() isReadOnly: boolean;
    @Input() existingPlan: SubscriptionPlan;

    public plan: SubscriptionPlan = {
        duration: 12,
        users: 0,
        projects: 0,
        guests: 0,
        storage: 0,
        cost: 0,
    };

    public currency: string = '€';

    public userCoef: number = 50;
    public projectCoef: number = 70;
    public guestCoef: number = 50;
    public storageCoef: number = 5;

    constructor(private router: Router,
        private subscriptionService: SubscriptionService) {

    }

    ngOnInit() {
        this.checkIsReadOnlyAndHideButtons()

        if (this.existingPlan) {
            this.plan = this.existingPlan;
        }


    }

    checkIsReadOnlyAndHideButtons() {
        if (this.isReadOnly) {
            document.getElementById('increase-user').hidden = true;
            document.getElementById('increase-project').hidden = true;
            document.getElementById('increase-guest').hidden = true;
            document.getElementById('increase-storage').hidden = true;
            document.getElementById('decrease-user').hidden = true;
            document.getElementById('decrease-project').hidden = true;
            document.getElementById('decrease-guest').hidden = true;
            document.getElementById('decrease-storage').hidden = true;
            document.getElementById('increase-duration').hidden = true;
            document.getElementById('decrease-duration').hidden = true;
            document.getElementById('apply-now').hidden = true;
        }
    }

    applyNow() {
        this.subscriptionService.subscriptionPlan = this.plan;
        this.onApply.emit(true);
        this.router.navigate(['entry', 'subscription']);
    }

    increase(option) {

        if (option == 1)
            this.plan.users++;
        else if (option == 2)
            this.plan.projects++;
        else if (option == 3)
            this.plan.guests++;
        else if (option == 4)
            this.plan.storage += 50;
        else if (option == 5)
            this.plan.duration++;


        this.calculateTotalCost()

    }

    decrease(option) {

        if (option == 1 && this.plan.users > 0)
            this.plan.users--;
        else if (option == 2 && this.plan.projects > 0)
            this.plan.projects--;
        else if (option == 3 && this.plan.guests > 0)
            this.plan.guests--;
        else if (option == 4 && this.plan.storage > 0)
            this.plan.storage -= 50;
        else if (option == 5 && this.plan.duration > 0)
            this.plan.duration--;

        this.calculateTotalCost()

    }

    calculateTotalCost() {
        this.plan.cost = (this.userCoef * this.plan.users) +
            (this.projectCoef * this.plan.projects) +
            (this.guestCoef * this.plan.guests) +
            (this.storageCoef * this.plan.storage);
    }

}
export interface SubscriptionPlan {
    duration?: number,
    users?: number,
    projects?: number,
    guests?: number,
    storage?: number,
    cost?: number,

}
