import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PaymentDTO } from "../credit-card-details.model";
import { PaymentService } from "../../shared/services/payment.service";
import { OverlayService } from "../../shared/services/overlayService";
import { LoadingBase } from "../../shared/bases/loading.base";
import { CompletePaymentModalComponent } from "../complete-payment-modal/complete-payment-modal.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../shared/services/auth.service";
import { UUID } from "angular2-uuid";
import { ProjectService } from "../../shared/services/project.service";
import { CompanyService } from "../../shared/services/company.service";
import { environment } from "../../../environments/environment";
import { NotificationService } from "../../shared/services/notification.service";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	templateUrl: './credit-card-details-modal.component.html',
	styleUrls: ['./credit-card-details-modal.component.scss']
})
export class CreditCardDetailsModalComponent extends LoadingBase implements OnInit, OnDestroy, AfterViewInit {

	formGroup: UntypedFormGroup = null;
	paymentDTO = new PaymentDTO();
	currentYear: number;
	currentMonth: number;
	invalidExpireMonth: boolean = false;
	invalidExpireYear: boolean = false;
	projectType: string;
	paymentType: string;
	selectedProposal: any;
	projectId: UUID;
	project: any = {};
	downpayment: string;
	finalpayment: number;
	biddingFee: string;
	total: string;
	prodCompanyName: string;
	stripeCreditCard: any;
	stripeSepa: any;
	isDownpayment: boolean = false;
	isSepaDebit: boolean = false;
	sourceCustomer: any = {};
	isCompleteButtonClickable: boolean = true;
	isCardPaymentAllowed: boolean = true;
	ngUnsubscribe = new Subject();

	@Input() totalMTM: any;
	@Input() mtmFee: any;
	@Input() onPaymentSuccessfully: Function;
	@ViewChild('cardElement') cardElement: ElementRef;
	@ViewChild('ibanElement') ibanElement: ElementRef;
	@ViewChild('loadingRef') public loadingRef: LoadingBase;

	cardElementForm: any;
	ibanElementForm: any;
	elementsCreditCard: any;
	elementsSepa: any;
	sepaConfirmation: boolean = false;
	cardElementValid: boolean = false;
	ibanElementValid: boolean = false;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private paymentService: PaymentService,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private authService: AuthService,
		private projectService: ProjectService,
		public activeModal: NgbActiveModal,
		private companyService: CompanyService,
		private serviceNotification: NotificationService,
		private translatePipe: TranslatePipe) {
		super();
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.stripeCreditCard = (<any>window).Stripe;
		this.stripeSepa = (<any>window).Stripe;
		// this.stripe = Stripe(environment.stripe.publishableKey);
		let date = new Date();
		this.currentYear = date.getFullYear();
		this.currentMonth = date.getMonth() + 1;

		if (this.paymentType != 'ENTRANCE_FEE') {
			this.projectService.getProject(this.projectId.toString()).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(project => {
				this.project = project;
				if (this.project.entranceFeePaid == true && this.project.downPaymentPaid == true) {
					this.downpayment = ((Number.parseFloat(this.project.budget) * this.project.downPaymentRate) / 100).toFixed(2);
				} else {
					this.downpayment = ((Number.parseFloat(this.selectedProposal.budget) * this.selectedProposal.downpaymentRate) / 100).toFixed(2);
				}
				if (this.downpayment.endsWith('.00'))
					this.downpayment = this.downpayment.substring(0, this.downpayment.length - 3);
				this.biddingFee = ((project.budget * 5) / 100).toFixed(2);
				if (this.biddingFee.endsWith('.00'))
					this.biddingFee = this.biddingFee.substring(0, this.biddingFee.length - 3);
				this.total = (Number(this.downpayment) + Number(this.biddingFee)).toFixed(2);
				this.finalpayment = Number(project.budget) - Number(this.downpayment);
				if (this.paymentType == 'DOWNPAYMENT') {
					this.isDownpayment = true;
					this.companyService.getCompanyById(project.prodCompanyId).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(company => {
						this.prodCompanyName = company.name;
					})
				}

				let checkTheCostForCreditCardOption;
				this.isDownpayment ? checkTheCostForCreditCardOption = parseFloat(this.totalMTM) :
					checkTheCostForCreditCardOption = parseFloat(this.finalpayment.toString())
				this.isCardPaymentAllowed = checkTheCostForCreditCardOption < 10000;
				if (!this.isCardPaymentAllowed)
					this.selectSepaDebit()
			}, error => {
				console.error(error);
			})
		}
		this.fillPaymentDTO();
		this.paymentService.getSourceCustomer(this.paymentDTO.projectId, this.authService.getAuthUser().email, this.isSepaDebit ? "sepa_debit" : "card").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res: any) => {
			this.setSourceCustomer(res);
		})
		this.createPaymentForm();
	}

	ngAfterViewInit() {
		this.stripeCreditCard = Stripe(environment.stripe.publishableKey);
		this.stripeSepa = Stripe(environment.stripe.publishableKey);
		this.elementsCreditCard = this.stripeCreditCard.elements();
		this.elementsSepa = this.stripeSepa.elements();
		this.createStripeCardForm();
		this.initLoadingParameters('', '', ['w-100']);
	}

	createStripeCardForm() {
		if (!this.cardElementForm) {
			this.cardElementForm = this.elementsCreditCard.create('card',
				{
					style: {
						base: {
							iconColor: '#666EE8',
							color: '#31325F',
							lineHeight: '40px',
							fontWeight: 300,
							fontFamily: 'Helvetica Neue',
							fontSize: '15px',

							'::placeholder': {
								color: '#CFD7E0',
							},
						},
					}
				});
		}
		//if(this.isCardPaymentAllowed)
		this.cardElementForm.mount(this.cardElement.nativeElement);
		this.cardElementForm.addEventListener('change', (event) => {
			this.cardElementValid = !!event.complete;
		});
	}


	createStripeIbanForm() {
		let style = {
			base: {
				iconColor: '#666EE8',
				color: '#31325F',
				lineHeight: '40px',
				fontWeight: 300,
				fontFamily: 'Helvetica Neue',
				fontSize: '15px',

				'::placeholder': {
					color: '#CFD7E0',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
				':-webkit-autofill': {
					color: '#fa755a',
				},
			}
		};

		if (!this.ibanElementForm) {
			this.ibanElementForm = this.elementsSepa.create('iban', {
				style: style,
				supportedCountries: ['SEPA'],
			});
		}

		this.ibanElementForm.mount(this.ibanElement.nativeElement);
		this.ibanElementForm.addEventListener('change', (event) => {
			this.ibanElementValid = !!event.complete;
		});

	}
	// checkConditions() {
	// 	return (group: FormGroup): { [key: string]: any } => {
	// 		if (this.paymentDTO.expireMonth && (this.paymentDTO.expireMonth > 12 || ((this.currentYear == this.paymentDTO.expireYear) && (this.currentMonth > this.paymentDTO.expireMonth)))) {
	// 			this.invalidExpireMonth = true;
	// 			return {
	// 				expireMonthError: true
	// 			};
	// 		}
	// 		else
	// 			this.invalidExpireMonth = false;
	//
	// 		if (this.paymentDTO.expireYear && (this.currentYear > this.paymentDTO.expireYear)) {
	// 			this.invalidExpireYear = true;
	// 			return {
	// 				expireYearError: true
	// 			};
	// 		}
	// 		else
	// 			this.invalidExpireYear = false;
	// 	}
	// }

	skipPayment() {
		this.activeModal.close();
	}

	fillPaymentDTO() {
		let company = this.authService.getAuthUser().company;
		this.paymentDTO.companyId = company.id;
		this.paymentDTO.companyName = company.name;
		this.paymentDTO.projectId = this.projectId;
		this.paymentDTO.projectType = this.projectType;
		this.paymentDTO.paymentType = this.paymentType;
		this.paymentDTO.email = this.authService.getAuthUser().email;
		if (this.selectedProposal)
			this.paymentDTO.proposalId = this.selectedProposal.id;
	}
	setTrueIsCompleteButtonClickable() {
		this.isCompleteButtonClickable = true;
	}
	completePayment() {
		//this.stripe.setPublishableKey(environment.stripe.publishableKey);
		this.isCompleteButtonClickable = false;
		setTimeout(() => {
			this.setTrueIsCompleteButtonClickable();
		}, 10000);
		const that = this;
		this.paymentService.getSourceCustomer(this.paymentDTO.projectId, this.authService.getAuthUser().email, this.isSepaDebit ? "sepa_debit" : "card").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res: any) => {
			this.setSourceCustomer(res);
			let paymentHandler = function (result) {
				if (result.error) {
					// Inform the customer that there was an error.
					that.overlayService.showError(result.error.message, 'FAILURE');
				} else {
					that.paymentDTO.sourceId = result.source.id;
					that.paymentDTO.paymentKind = result.source.type;
					that.paymentDTO.email = result.source.owner.email;
					that.paymentDTO.clientSecret = result.source.client_secret;
					let modal = that.modalService.open(CompletePaymentModalComponent, {
						size: 'lg',
						windowClass: 'company-matched-blur-color',
						backdrop: false
					});
					modal.componentInstance.completePayment(that.paymentDTO).pipe(
						takeUntil(that.ngUnsubscribe)
					).subscribe(() => {
						that.call_onPaymentSuccessfully();
						that.successPaymentModal(that.isSepaDebit);
						if (that.paymentType == 'ENTRANCE_FEE') {
							that.project.entranceFeePaid = true;
							that.updateLocalStorage();
						}
					}, err => {
						that.overlayService.showError(err.message, "FAILURE");
					});
				}
			};
			if (!that.sourceCustomer.sourceId) {
				let ownerInfo
				if (!that.isSepaDebit) {
					that.paymentDTO.paymentKind = "card";
					ownerInfo = {
						owner: {
							name: that.paymentDTO.firstName + " " + that.paymentDTO.lastName,
							address: {
								line1: that.paymentDTO.address,
								city: that.paymentDTO.city,
								postal_code: that.paymentDTO.zipCode,
								country: that.paymentDTO.country,
							},
							email: that.authService.getAuthUser().email
						},
					};
					that.stripeCreditCard.createSource(that.cardElementForm, ownerInfo)
						.then(paymentHandler);
				} else {
					that.paymentDTO.paymentKind = "sepa_debit";
					ownerInfo = {
						type: 'sepa_debit',
						currency: that.selectedProposal ? that.selectedProposal.currency : that.project.currency,
						owner: {
							name: that.paymentDTO.firstName + " " + that.paymentDTO.lastName,
							email: that.authService.getAuthUser().email
						},
						mandate: {
							// Automatically send a mandate notification email to your customer
							// once the source is charged.
							notification_method: 'email',
						}
					}
					that.stripeSepa.createSource(that.ibanElementForm, ownerInfo)
						.then(paymentHandler);
				}
			} else {
				let sourceData = {
					id: that.sourceCustomer.sourceId,
					client_secret: that.sourceCustomer.clientSecret,
				}
				that.stripeSepa.retrieveSource(sourceData)
					.then(paymentHandler);
			}
		});
	}

	successPaymentModal(sepaDebit?) {
		let desc1 = '';
		let desc3 = '';
		let paymentMade = '';
		let amount = '';
		let currency = '';
		let paymentStatus = '';
		let downpaymentAmount = '';
		let totalMTM = '';
		let paymentMethod = '';
		if (!sepaDebit) {
			paymentMethod = 'Credit Card';
			//desc3 = "We have successfully received your";
			if (this.paymentType == 'ENTRANCE_FEE') {
				desc1 = this.translatePipe.transform('entranceDialog');
			} else if (this.paymentType == 'DOWNPAYMENT') {
				paymentStatus = 'Payment done';
				paymentMade = 'Downpayment and MTM Fees';
				downpaymentAmount = parseInt(this.downpayment).toFixed(2);
				totalMTM = this.mtmFee;
				currency = this.paymentDTO.currency;
			}
			else if (this.paymentType == 'FINAL_PAYMENT') {
				desc3 = '';
				paymentStatus = 'Payment done';
				paymentMade = 'Final Payment';
				amount = this.finalpayment.toFixed(2);
				currency = this.paymentDTO.currency;
			}

		}
		else {
			paymentMethod = 'SEPA DD';
			if (this.paymentType == 'ENTRANCE_FEE')
				desc1 = this.translatePipe.transform('entranceDialog');
			else if (this.paymentType == 'DOWNPAYMENT') {
				paymentStatus = 'Payment in progress';
				paymentMade = 'Downpayment and MTM Fees';
				downpaymentAmount = parseInt(this.downpayment).toFixed(2);
				totalMTM = this.mtmFee;
				currency = this.paymentDTO.currency;
			}
			else if (this.paymentType == 'FINAL_PAYMENT') {
				desc3 = '';
				paymentStatus = 'Payment in progress';
				paymentMade = 'Final Payment';
				amount = this.finalpayment.toFixed(2);
				currency = this.paymentDTO.currency;
			}
			//desc3 = "We have received your payment request. Payment status will be send as a notification ";
		}


		this.serviceNotification.open({
			size: 'lg',
			backdrop: 'static',
			isCloseButtonHidden: true,
			subtitle: 'Information: ',
			description: `${desc3}  ${desc1}`,
			description3: this.paymentType == 'ENTRANCE_FEE' ? this.translatePipe.transform('entranceDialogDiscover') : '',
			paymentStatus: paymentStatus,
			paymentMade: paymentMade,
			amount: amount,
			currency: currency,
			downpaymentAmount: downpaymentAmount,
			totalMTM: totalMTM,
			paymentMethod: paymentMethod,
			confirmBtn: this.paymentType == 'ENTRANCE_FEE' ? this.translatePipe.transform('entranceDialogButton') : 'OK',
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => this.closeRegisterModalAndRedirectToLogin(), err => this.closeRegisterModalAndRedirectToLogin());
	}

	private async closeRegisterModalAndRedirectToLogin() {
		this.call_onPaymentSuccessfully();
		this.activeModal.close(true);
		if (this.paymentType == 'ENTRANCE_FEE')
			await this.projectService.navigateCompanyToProfileSuggestionAfterProjectInit(this.projectId.toString(), this.router).toPromise();
		else
			this.projectService.prepareProjectInit(this.projectId.toString());
	}

	call_onPaymentSuccessfully() {
		if (this.onPaymentSuccessfully)
			this.onPaymentSuccessfully();
	}

	/**
	 * This method is implemented for PaymentGuard.
	 */
	updateLocalStorage() {
		this.projectService.saveProjectsInCache(this.project);
	}

	createPaymentForm() {
		this.formGroup = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			city: ['', Validators.required],
			country: ['', Validators.required],
			address: ['', Validators.required],
			state: [''],
			zipCode: [''],
		});
		if (!this.isSepaDebit) {
			setTimeout(() => {
				this.createStripeCardForm();
			}, 100)
		}

		else {
			setTimeout(() => {
				this.createStripeIbanForm();
			}, 100)
		}
	}

	sepaConfirmationM() {
		this.sepaConfirmation = !!this.sepaConfirmation;
	}

	selectCreditCard() {
		if (this.isCardPaymentAllowed) {
			this.isSepaDebit = false;
			this.paymentService.getSourceCustomer(this.paymentDTO.projectId, this.authService.getAuthUser().email, "card").pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((res: any) => {
				this.setSourceCustomer(res);
				this.createPaymentForm();
			})
		}
	}

	selectSepaDebit() {
		this.isSepaDebit = true;
		this.paymentService.getSourceCustomer(this.paymentDTO.projectId, this.authService.getAuthUser().email, "sepa_debit").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res: any) => {
			this.setSourceCustomer(res);
			this.createPaymentForm();
		})
	}

	onCloseModal() {
		this.skipPayment();
	}

	private setSourceCustomer(res: any) {
		this.sourceCustomer = res;
		this.paymentDTO.clientSecret = res.clientSecret;
	}
}
