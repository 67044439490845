
import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChildren, ViewEncapsulation, DoCheck, OnDestroy, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from '../../../../shared/services/project.service';
import { ActivatedRoute } from "@angular/router";
import { CompanyService } from '../../../../shared/services/company.service';
import { ProposalInvitationService } from 'app/shared/services/proposal-invitation.service';
import { onCompanyUpdated, ProdPartnerCompanyProfilesService } from 'app/shared/services/production-partner-services/company-profiles.service';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { MTMFileDownloadService, onDownloadStart } from 'app/shared/components/mtm-file-download/mtm-file-download.service';
import { MTMFileDownloadModel } from 'app/shared/components/mtm-file-download/mtm-file-download.model';
import {
	SignedURL_FU_Base,
	WithSignedURLUploadInfo,
	WithUploadBaseDependencies
} from 'app/shared/bases/signed-url-file-upload.base';
import { FilePreviewModalComponent } from 'app/shared/components/hierarchical-files/file-preview-modal/file-preview-modal.component';
import { FileTransferService } from '../../../../shared/services/signed-url/file-transfer.service';
import { MTMFileDownload } from 'app/shared/services/signed-url/mtm-file-download';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
	selector: 'mtm-company-profile-portfolio',
	templateUrl: './company-profile-portfolio.component.html',
	styleUrls: ['./company-profile-portfolio.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class CompanyProfilePortfolioComponent implements OnInit, DoCheck, OnDestroy {
	@ViewChildren("reviewsRef") reviewRef;
	@Input() allFormats: any[] = [];
	@Input() companyInfo: any = {};
	@Input() prodPartnerContent: any = {};
	@Input() isClient: boolean = true;
	@Input() projectId: any;
	@Input() clientId: any;
	public modalRef: NgbModalRef;
	brief: number;
	budget: number;
	outOfBox: number;
	goodListener: number;
	time: number;
	global: number;
	reviews: any[] = [];
	toggleSection: any = true;
	toggleViewArray: any[] = [];
	loadingFilms = false;
	isFilmsEmpty = false;
	isDirectorsEmpty = false;
	isPortfolioEmpty = false;
	ngUnsubscribe = new Subject();
	isHavePrefix = false;
	noneImg = "./assets/img/samples/No_Image_Available.png";
	itemLoaded = 5;
	isWorkLoaded = false;
	isTagsVisible: boolean = true;
	fileDownloadInfo: MTMFileDownloadModel;
	baseSignedURL_FU_A: SignedURL_FU_Base = new SignedURL_FU_Base(
		WithUploadBaseDependencies(this.authService, this.transferService),
		WithSignedURLUploadInfo({
			uploadPathGetter: () => this.projectId
		})
	)
	constructor(
		private elementRef: ElementRef,
		public activeModal: NgbActiveModal,
		private projectService: ProjectService,
		private activeRouter: ActivatedRoute,
		private proposalInvitationService: ProposalInvitationService,
		private companyService: CompanyService,
		private authService: AuthService,
		private transferService: FileTransferService,
		private serviceProdPartnerCompanyProfiles: ProdPartnerCompanyProfilesService,
		private mtmFileDownloadService: MTMFileDownloadService,
		private modalService: NgbModal
	) {
		onCompanyUpdated.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(args) => {
				this.onCompanyUpdatedHandler(args)
			}
		)
	}

	ngOnInit() {
		this.getProjectAvatarForProductionSide();
		this.prepareToggleView();
		if (this.companyInfo.companyUrl) {
			if (this.companyInfo.companyUrl.includes('http')) {
				this.isHavePrefix = true;
			}
		}
		this.bypassSocialMediaPrefix(this.companyInfo);
		if (this.isClient) {
			this.getProductionCompanyAvgRates();
		}
		else {
			this.getClientCompanyAvgRates();
		}
		this.getWorksAndDirectorForEachCompany(this.companyInfo);
	}

	downloadItem(event) {
		onDownloadStart.emit();
		/*
		this.fileDownloadInfo = new MTMFileDownloadModel(event.id, event.file);
		this.mtmFileDownloadService.startDownloadFile(this.fileDownloadInfo);
		*/
		const downloadInfo = MTMFileDownload.fromFileRef(event.file);
		downloadInfo.id = event.id;
		this.transferService.downloadFile(downloadInfo);
	}

	previewFile(file) {
		const modal = this.modalService.open(FilePreviewModalComponent, { windowClass: 'media-player-preview' });
		if (!this.toggleSection) {
			modal.componentInstance.fileType = 'image';
		}
		modal.componentInstance.file = file;
	}

	ngDoCheck(): void {
		this.isWorkLoaded = this.companyInfo.isWorkLoaded;
	}

	ngOnChanges(changes: any) {
		this.prepareToggleView();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
		setTimeout(() => {
			let mediaElements = this.elementRef.nativeElement.querySelectorAll('[src]');
			_.map(mediaElements, (element) => {
				if (element.nodeName === 'VIDEO') {
					element.pause();
					element.removeAttribute('src');
					element.load();
				} else {
					element.removeAttribute('src');
				}
			});
		}, 350);
	}

	private prepareCompanyDirectorFilmsMedia(objects: any[]) {
		objects.forEach(object => {
			object.videoFiles = [];
			if (this.isMediaFile(object)) {
				object.videoFiles.push(object);
			}
		});
	}

	private prepareCompanyMediaInfoForDirectorsAndWorks(objects: any[]) {
		objects.forEach(object => {
			object.videoFiles = [];
			object.files.forEach(k => {
				if (this.isMediaFile(k))
					object.videoFiles.push(k);
			})
		});
	}

	private computeWeight(element) {
		element.formatNames = [];
		if (element.category) {
			element.category.forEach(cat => {
				let format = this.allFormats.find(k => k.id == cat);
				if (format && format.item)
					element.formatNames.push(format.item);
			});
		}
		if (element.formatNames.length == 0 && element.format) {
			element.formatNames = element.format;
		}

		return element.formatNames
			.map(c => (this.prodPartnerContent.format || '').indexOf(c))
			.reduce((r, i) => i >= 0 ? r * (this.prodPartnerContent.format.length - i + 1) : r, 1);
	}

	private async getWorksAndDirectorForEachCompany(company: any) {
		company.isWorkLoaded = false;
		let self = this;
		this.serviceProdPartnerCompanyProfiles
			.getWorks(company.id)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(works => {
				if (!Array.isArray(works)) {
					company.isWorkLoaded = true;
					return;
				} else {
					company.works = works;
					this.prepareCompanyMediaInfoForDirectorsAndWorks(company.works);
					company.isWorkLoaded = true;
					//company.works.sort((w1, w2) => self.computeWeight(w2) - self.computeWeight(w1));
					company.works.sort((w1, w2) => w2.uploadedTime - w1.uploadedTime);
					onCompanyUpdated.emit({
						type: 'work',
						company: company
					});
				}
			});
		company.isExperienceLoaded = false;
		this.companyService.getExperiencesForClientSide(company.id)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(experiences => {
				if (!Array.isArray(experiences)) {
					company.isExperienceLoaded = false;
					return;
				} else {
					company.experiences = experiences;
					company.experiences.sort((e1, e2) => e2.startDate - e1.startDate);
					company.experiences.forEach(experience => {
						self.computeWeight(experience);
						experience.startDate = DateHelperService.fromDateToUTCString(DateHelperService.fromTimestampToDate(experience.startDate));
					});
					company.isExperienceLoaded = true;

					onCompanyUpdated.emit({
						type: 'experience',
						company: company
					});
				}
			});
		company.isAwardLoaded = false;
		this.companyService.getAwardsForClientSide(company.id)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(awards => {
				if (!Array.isArray(awards)) {
					company.isAwardLoaded = true;
					return;
				} else {
					company.awards = awards;
					company.awards.forEach(award => {
						self.computeWeight(award);
						award.date = DateHelperService.fromDateToUTCString(DateHelperService.fromTimestampToDate(award.date));
					});
					company.isAwardLoaded = true;
					company.awards.sort((a1, a2) => Date.parse(a2.date) - Date.parse(a1.date));
					onCompanyUpdated.emit({
						type: 'award',
						company: company
					});
				}
			});
		company.isDirectorLoaded = false;
		this.serviceProdPartnerCompanyProfiles.getAllCompanyDirectors(company.id)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(directors => {
				company.directors = directors;
				company.directors.forEach(director => {
					this.companyService.getDirectorFilmsForClient(company.id, director.id)
						.pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(filmData => {
							if (filmData.length > 0) {
								director.films = filmData;
								director.films.forEach(film => {
									this.companyService.getFilmFilesForClient(company.id, film.id)
										.pipe(
											takeUntil(this.ngUnsubscribe)
										).subscribe(filmFilesData => {
											film = filmFilesData;
											this.prepareCompanyDirectorFilmsMedia(filmFilesData);
										});
								});
							}
						});
				});
				company.isDirectorLoaded = true;
				onCompanyUpdated.emit({
					type: 'director',
					company: company
				});
			});
	}


	onSelectToggle(toggleSection) {
		this.toggleSection = toggleSection;
		this.prepareToggleView();
	}

	onCompanyUpdatedHandler(args: any) {
		if (args.company.id == this.companyInfo.id) {
			if (args.type === 'work') {
				this.isWorkLoaded = args.company.isWorkLoaded;
				this.companyInfo.works = args.company.works;
				this.prepareViewForPortfolio();
			} else if (args.type === 'award') {
				this.companyInfo.awards = args.company.awards;
			} else if (args.type === 'director') {
				this.companyInfo.directors = args.company.directors;
			} else if (args.type === 'experience') {
				this.companyInfo.experiences = args.company.experiences;
			}
		}
		this.prepareToggleView();
	}

	prepareToggleView() {
		this.toggleViewArray = [];
		this.isFilmsEmpty = false;
		this.isPortfolioEmpty = false;
		this.isDirectorsEmpty = false;
		if (this.toggleSection) {
			this.prepareViewForPortfolio();
		}
		else {
			this.prepareViewForDirector();
		}
	}
	goToDirectorsFilms(id) {
		this.toggleViewArray = [];
		this.toggleSection = 2;
		this.prepareDirectorFilmVideos(id);
	}

	private prepareDirectorFilmVideos(filmId) {
		this.loadingFilms = true;
		this.companyInfo.directors.filter(d => d.id == filmId).forEach(director => {
			this.companyService.getDirectorFilmsForClient(this.companyInfo.id, director.id)
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(filmData => {
					if (filmData.length > 0) {
						filmData.forEach(film => {
							this.companyService.getFilmFilesForClient(this.companyInfo.id, film.id)
								.subscribe(filmFilesData => {
									director.films.forEach(mFilm => {
										if (film.id == mFilm.id) {
											film.videoFiles = filmFilesData;
										}
									});
									if (director.films) {
										if (director.films.length > 0) {
											this.loadingFilms = false;
											director.films.forEach(mFilm => {
												if (film.videoFiles.length > 0 && film.id == mFilm.id) {
													this.pushIntoToggleViewArray(film.title, "Video", film.videoFiles, film.tags, "Films");
												}
											});
											if (director.films.length == 0)
												this.isFilmsEmpty = true;
										}
									}
									else {
										this.loadingFilms = false;
										this.isFilmsEmpty = true;
									}
								});
						});
					}
					else {
						this.loadingFilms = false;
						this.isFilmsEmpty = true;
					}
				});
		})
	}

	private isMediaFile(file) {
		return (file && file.contentType && (HelperService.isVideoFile(file) || HelperService.isImageFile(file)
			|| HelperService.isAudioFile(file)))
		// return (!file || !file.contentType || file.contentType.indexOf('video') < 0) ? false : true;

	}

	private prepareViewForPortfolio() {
		if (this.companyInfo.works) {
			this.isPortfolioEmpty = !this.companyInfo.works.length;
			this.companyInfo.works.forEach(work => {
				let format = (work.formatNames || []).join(", ");
				this.pushIntoToggleViewArray(work.title, format, work.files, work.tags, "Portfolio");
				this.isPortfolioEmpty = !this.toggleViewArray.length;
			});
		}
		else {
			this.isPortfolioEmpty = true;
		}
	}

	private prepareViewForDirector() {
		if (this.companyInfo.directors) {
			if (this.companyInfo.directors.length == 0) {
				this.isDirectorsEmpty = true;
			}
			this.companyInfo.directors.forEach(director => {
				if (director.directorsFilms) {
					this.pushIntoToggleViewArrayDirectors(
						director.id,
						director.name + ' ' + director.surName,
						director.directorsFilms.length + ' Films',
						director.coverImage
					);
				}
			});
		}
	}

	private pushIntoToggleViewArrayDirectors(id, title, desc, directorCoverImage) {
		this.toggleViewArray.push({
			id: id,
			title: title,
			desc: desc,
			file: directorCoverImage
		});
		this.toggleViewArray = _.uniqBy(this.toggleViewArray, 'id');
		this.toggleViewArray = this.toggleViewArray.slice(0, 10);
	}

	private pushIntoToggleViewArray(title, desc, videoFiles: any[], tags: any[], itemType?,) {
		videoFiles.forEach(k => {
			k.displayName = k.displayName.substring(0, k.displayName.length - 4)
			if (this.isMediaFile(k)) {
				this.toggleViewArray.push({
					title: title,
					desc: desc,
					file: k,
					tag: tags
				});
			}
		});
	}

	getProjectAvatarForProductionSide() {
		if (!this.isClient)
			this.projectService.getProject(this.projectId)
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(project => {
					this.companyInfo.projectAvatarUrl = project.avatarUrl;
				});
	}

	private getProductionCompanyAvgRates() {
		this.reviews = [];
		this.proposalInvitationService.getProductionCompanyAvgRate(this.companyInfo.id)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(avgRate => {
				this.brief = avgRate.onBriefAvg !== "NaN" ? avgRate.onBriefAvg : 0;
				this.budget = avgRate.onBudgetAvg != "NaN" ? this.budget = avgRate.onBudgetAvg :  0;
				this.outOfBox = avgRate.outOfTheBoxAvg != "NaN" ? this.outOfBox = avgRate.outOfTheBoxAvg : 0;
				this.time = avgRate.onTimeAvg != "NaN" ? this.time = avgRate.onTimeAvg : 0;
				this.goodListener =  avgRate.goodListenerAvg != "NaN" ? this.goodListener = avgRate.goodListenerAvg : 0;
				this.global =  avgRate.globalRatingAvg != "NaN" ? this.global = avgRate.globalRatingAvg : 0;
				this.proposalInvitationService.getAllProductionCompanyRate(this.companyInfo.id)
					.pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(prodCompanyRates => {
						if (prodCompanyRates.length > 0) {
							prodCompanyRates.forEach(p => {
								if (p.review.length <= 150) {
									this.reviews.push({
										'review': p.review,
										'reviewSlice': p.review.slice(0, 150),
										'userAvatar': p.userAvatar,
										'userCompanyAvatar': p.userCompanyAvatar,
										'userCompanyName': p.userCompanyName,
										'userName': p.userName,
										'userProjectName': p.userProjectName,
										'time': p.time
									});
								}
								else {
									this.reviews.push({
										'review': p.review,
										'reviewSlice': p.review.slice(0, 150) + '...',
										'userAvatar': p.userAvatar,
										'userCompanyAvatar': p.userCompanyAvatar,
										'userCompanyName': p.userCompanyName,
										'userName': p.userName,
										'userProjectName': p.userProjectName,
										'time': p.time
									});
								}
							});
						}
					})
			});
	}

	private getClientCompanyAvgRates() {
		this.reviews = [];
		this.proposalInvitationService.getClientCompanyAvgRate(this.clientId)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(avgRate => {
				this.brief = avgRate.clarityOfTheBriefAvg != "NaN" ? this.brief = avgRate.clarityOfTheBriefAvg : 0;
				this.budget = avgRate.opennessToIdeasAvg != "NaN" ? this.budget = avgRate.opennessToIdeasAvg : 0;
				this.outOfBox = avgRate.goodListenerAvg != "NaN" ? this.outOfBox = avgRate.goodListenerAvg : 0;
				this.time = avgRate.goodPlayerAvg != "NaN" ? this.time = avgRate.goodPlayerAvg : 0;
				this.goodListener =  avgRate.feedBackQualitySpeedAvg != "NaN" ? this.goodListener = avgRate.feedBackQualitySpeedAvg : 0;
				this.global = avgRate.globalRatingAvg != "NaN" ? this.global = avgRate.globalRatingAvg : 0;
				this.proposalInvitationService.getClientReviewsCompanyRate(this.clientId)
					.pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(clientCompanyRates => {
						if (clientCompanyRates.length > 0) {
							clientCompanyRates.forEach(c => {
								if (c.review.length <= 150) {
									this.reviews.push({
										'review': c.review,
										'reviewSlice': c.review.slice(0, 150),
										'userAvatar': c.userAvatar,
										'userCompanyAvatar': c.userCompanyAvatar,
										'userCompanyName': c.userCompanyName,
										'userName': c.userName,
										'userProjectName': c.userProjectName,
										'time': c.time
									});
								}
								else {
									this.reviews.push({
										'review': c.review,
										'reviewSlice': c.review.slice(0, 150) + '...',
										'userAvatar': c.userAvatar,
										'userCompanyAvatar': c.userCompanyAvatar,
										'userCompanyName': c.userCompanyName,
										'userName': c.userName,
										'userProjectName': c.userProjectName,
										'time': c.time
									});
								}
							});
						}
					})
			});

	}
	private showAllReview(review, index) {
		this.reviewRef.toArray()[index].nativeElement.lastElementChild.innerHTML = '<p class="text-muted">' + review.review + '</p>';
	}

	showMoreReviews() {
		this.itemLoaded += 5;
	}

	bypassSocialMediaPrefix(companyInfo) {
		if (this.companyInfo.facebookUrl && !this.companyInfo.facebookUrl.includes('http'))
			this.companyInfo.facebookPrefix = '//';
		else
			this.companyInfo.facebookPrefix = '';
		if (this.companyInfo.instagramUrl && !this.companyInfo.instagramUrl.includes('http'))
			this.companyInfo.instagramPrefix = '//';
		else
			this.companyInfo.instagramPrefix = '';
		if (this.companyInfo.linkedinUrl && !this.companyInfo.linkedinUrl.includes('http'))
			this.companyInfo.linkedinPrefix = '//';
		else
			this.companyInfo.linkedinPrefix = '';
	}

	modalCloseClick() {
		this.modalRef.close();
	}

	tagsVisible() {
		this.isTagsVisible = !this.isTagsVisible;
	}
}
