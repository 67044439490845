import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs'

import { ApiService } from '../api.service';
import { CompanyService } from 'app/shared/services/company.service';
import { CommonService } from 'app/shared/services/common.service';
import { ProjectService } from "../project.service";

export let onCompanyUpdated: EventEmitter<any> = new EventEmitter<any>();

@Injectable({
	providedIn: 'root'
})
export class ProdPartnerCompanyProfilesService {
	constructor(private serviceApi: ApiService,
		private serviceCompany: CompanyService,
		private serviceProject: ProjectService,
		private serviceCommon: CommonService) { }

	getCompanyProfiles(projectId, isOneShot?: boolean, sorting?: string[]): Observable<any> {
		let sortingFilter = sorting && sorting.length > 0 ? '?ranking=' + sorting.filter(s => s != "").join(",") : '';
		return this.serviceApi.httpGet(`/api/projects/${projectId}/prodPartner/${isOneShot ? 'one-shot/' : ''}filter${sortingFilter}`);
	}

	public getWorks(companyId: string): Observable<any> {
		return this.serviceApi.httpGet(`/api/companies/${companyId}/portfolio/works`);
	}

	public getAllCompanyDirectors(companyId: string): Observable<any> {
		return this.serviceApi.httpGet(`/api/companies/${companyId}/portfolio/companyDirectors`);
	}

	public getDirectorFiles(companyId: string, directorId: string): Observable<any> {
		return this.serviceCompany.getDirectorFilesForClient(companyId, directorId);
	}

	public getCompanyTypes() {
		return this.serviceCommon.getProjectTypes();
	}
}
