<div class="register-component">
	<div class="d-flex flex-column align-items-center justify-content-center" [style.color]="'#03041F'">
		<div class="d-flex align-items-center">
			<div class="d-inline-flex">
				<img src="../../../../../assets/img/rocket.svg" height="50">
			</div>
		</div>
		<div class="d-flex align-items-center">
			<span [style.font-size]="'2rem'"
				[style.font-weight]="'bold'">{{'subscription_register_produce_confirmation'|translate}}</span>
		</div>
		<div class="d-flex flex-column align-items-center justify-content-center">
			<span>{{'subscription_register_produce_thankYou'|translate}}</span>
		</div>
		<div class="d-block mt-4">
			<button (click)="returnToDashboard()"
				class="btn mtm-button b-mtm-green t-uppercase c-full-white _600 pt-2 pb-2 pl-3 pr-3">
				{{ (redirectToDashboard ? 'subscription_produce_redirect_to_dashboard' :
				'subscription_produce_return_to_dashboard') | translate}}
			</button>
		</div>
	</div>
</div>