<div class="modal-body">
    <h2 class="text-center font-weight-bold mb-4">Add Payment Method</h2>

    <div class="form-group">
        <label for="paymentType">Payment Type</label>
        <select class="form-control" id="paymentType" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}">
            <option value="credit-card">Credit Card</option>
            <option value="sepa">SEPA</option>
        </select>
    </div>

    <div [class.hidden]="paymentType!='credit-card'">
        <div class="form-group">
            <div class="d-flex align-items-center">
                <label class="m-0">{{'subscription_register_payment_creditCardInformation_number'|translate}}</label>
                <span class="ml-2 _600" *ngIf="method">{{'XXXX ' + method?.card?.last4}}</span>
            </div>
            <div class="form-control" #creditCardNumber></div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <label for="">{{'subscription_register_payment_creditCardInformation_expiry'|translate}}</label>
                <div class="form-control" #expiry></div>
            </div>
            <div class="col-6 form-group">
                <label for="">{{'subscription_register_payment_creditCardInformation_cvcNumber'|translate}}</label>
                <div class="form-control" #cvcNumber></div>
            </div>
        </div>
        <div class="form-group">
            <label>{{'subscription_register_payment_creditCardInformation_zipCode'|translate}}</label>
            <div class="form-control" #zipCode></div>
        </div>
    </div>

    <div [class.hidden]="paymentType!='sepa'">
        <div class="form-group">
            <div class="d-flex align-items-center">
                <label class="m-0" for="">IBAN</label>
                <span class=" ml-2 _600" *ngIf="method">{{'XXXX ' + method?.sepaDebit?.last4}}</span>
            </div>
            <div class="form-control" #iban></div>
        </div>

        <div class="form-group">
            <input type="checkbox" name="acceptSepa" [(ngModel)]="acceptSepa" [ngModelOptions]="{standalone: true}">
            {{'sepaAcceptText' | translate}}
        </div>

    </div>

    <div class="secure"><img class="secure-icon"
            src="/assets/img/subscription/secure-icon.png">{{'subscription_register_payment_encrypted'|translate}}
    </div>

    <div class="buttons">
        <a class="confirm d-flex align-items-center" (click)="confirm()">
            <span>{{'finalPaymentBoxConfirm'|translate}}</span>
            <i *ngIf="isBusy" [style.font-size]="'18px'" class="ml-2 fa fa-spinner fa-pulse"></i>
        </a>
        <a class="cancel" (click)="cancel()">{{'cancel'|translate}}</a>
    </div>

</div>