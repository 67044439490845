import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { NotificationService } from "app/shared/services/notification.service";
import { PlatformEventService } from "app/shared/services/platform-events.service";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import * as moment from "moment";
import { Subject, forkJoin } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { PlanRenewalFailedComponent } from "./plan-renewal-failed/plan-renewal-failed.component";
import { MaintenanceUpdatedEvent } from "app/shared/models";

const SUBSCRIPTION_BANNER = {
	PLAN_CANCELLATION: 'PLAN_CANCELLATION',
	PLAN_DOWNGRADE: 'PLAN_DOWNGRADE',
	PLAN_RENEWAL_FAILED: 'PLAN_RENEWAL_FAILED',
	APPROACHING_PLAN_LIMITS_LICENSE: 'APPROACHING_PLAN_LIMITS_LICENSE',
	APPROACHING_PLAN_LIMITS_ACTIVE: 'APPROACHING_PLAN_LIMITS_ACTIVE',
	APPROACHING_PLAN_LIMITS_ARCHIVE: 'APPROACHING_PLAN_LIMITS_ARCHIVE',
	ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED'
}

const PLATFORM_BANNER = {
	SCHEDULED_MAINTENANCE: 'SCHEDULED_MAINTENANCE'
}

export enum BannerTypes {
	MaintenanceOnly = 'MaintenanceOnly',
	All = 'All'
};

@Component({
	selector: 'mtm-banner',
	templateUrl: './subscription-banner.component.html',
	styleUrls: ['./subscription-banner.component.scss']
})
export class SubscriptionBannerComponent implements OnInit, OnChanges, OnDestroy {
	@Input() bannerType = BannerTypes.MaintenanceOnly;
	subscription: any;
	authUser: any;
	banner: any;
	flagInfos: any = {};
	platformEvents: any = [];
	scheduledMaintenance: any = null;
	ngUnsubscribe = new Subject();
	bannerTypes = BannerTypes;

	constructor(
		private authService: AuthService,
		private subscriptionService: SubscriptionService,
		private platformEventService: PlatformEventService,
		private notificationService: NotificationService,
		private modalService: NgbModal,
		private router: Router
	) {

	}

	private isMaintenanceOnly() {
		return this.bannerType === BannerTypes.MaintenanceOnly;
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		this.subscription = this.authService.getAuthUserSubscription();
		this.flagInfos = this.authService.getSubscriptionCompanyFlagInfos();

		EmitterService.get(MaintenanceUpdatedEvent)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: () => {
					this.banner = null;
					this.scheduledMaintenance = null;
					this.loadData();
				}
			});

		EmitterService.get('subscriptionFlagInfosUpdated').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			this.banner = void 0;
			this.subscription = this.authService.getAuthUserSubscription();
			this.flagInfos = this.authService.getSubscriptionCompanyFlagInfos();
			if (this.flagInfos && this.flagInfos.bannerHide || !this.subscription) {
				return;
			}
			this.checkBanner();
		})

		this.loadData();
	}

	private loadData() {
		if (!this.subscription && !this.isMaintenanceOnly()) {
			return;
		}

		const requests$ = [
			this.platformEventService.getPlatformEventInfo(),
		];

		const isLoggedIn = this.authUser?.username;
		if (isLoggedIn) {
			requests$.push(this.subscriptionService.getSubscriptionFlagsInfo(this.authService.getAuthUserName()));
		};

		forkJoin(requests$).pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((res: any) => {
				this.platformEvents = [...res[0]]
				this.authService.setPlatformEvents(this.platformEvents);
				if (isLoggedIn) {
					this.flagInfos = { ...this.flagInfos, ...res[1] };
					this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
					if (this.flagInfos && this.flagInfos.bannerHide || (!this.isMaintenanceOnly() && !this.subscription)) {
						return;
					}
				}
				this.checkBanner();
			});
	}

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	checkBanner() {
		if (this.platformEvents.length) {
			for (let i = 0; i < this.platformEvents.length; i++) {
				const event = this.platformEvents[i];
				if (event.code === 'SCHEDULED_DEPLOYMENT') {
					const today = moment().utc(), startTime = moment(event.startTime).utc(), endTime = moment(event.endTime).utc();
					if (endTime > today) {
						const scheduledInfo = JSON.parse(event.objectJson);
						this.banner = PLATFORM_BANNER.SCHEDULED_MAINTENANCE;
						this.scheduledMaintenance = {};
						this.scheduledMaintenance.startTime = `${scheduledInfo.startDate.year}-${scheduledInfo.startDate.month}-${scheduledInfo.startDate.day} ${scheduledInfo.startTime.hour}:${scheduledInfo.startTime.minute}`;
						this.scheduledMaintenance.endTime = `${scheduledInfo.endDate.year}-${scheduledInfo.endDate.month}-${scheduledInfo.endDate.day} ${scheduledInfo.endTime.hour}:${scheduledInfo.endTime.minute}`;
						this.scheduledMaintenance.timezone = scheduledInfo.timezone;
					}
				}
			}
		} else {
			this.banner = void 0;
		}

		if (!this.banner && !this.isMaintenanceOnly()) {
			if (this.flagInfos.planRenewalStatus === 'FAILED') {
				this.banner = SUBSCRIPTION_BANNER.PLAN_RENEWAL_FAILED;
			} else if (this.flagInfos.activeLicenceLimitApproached) {
				this.banner = SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_LICENSE;
				this.approachingLimitItem = 'license';
			} else if (this.flagInfos.activeStorageLimitApproached) {
				this.banner = SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_ACTIVE;
				this.approachingLimitItem = 'active storage';
			} else if (this.flagInfos.archivalStorageLimitApproached) {
				this.banner = SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_ARCHIVE;
				this.approachingLimitItem = 'archive storage';
			} else if (this.flagInfos.isPlanCancelled) {
				this.banner = SUBSCRIPTION_BANNER.PLAN_CANCELLATION;
			}
		}
	}

	showBannerModal() {
		if (this.banner && this.flagInfos) {
			this.showModal();
		}
	}

	closeBanner() {
		this.flagInfos.bannerHide = true;
		this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
	}

	learnMoreClick() {
		this.showBannerModal();
	}

	showModal() {
		if (this.banner === SUBSCRIPTION_BANNER.PLAN_DOWNGRADE) {
			this.showPlanDowngradeModal();
		} else if (this.banner == SUBSCRIPTION_BANNER.PLAN_CANCELLATION) {
			const endDate = moment(this.subscription.endDate * 1000);
			const dayDiff = endDate.diff(moment(), 'days');
			if (dayDiff === 14 || dayDiff === 7 || dayDiff === 3 || dayDiff === 1) {
				this.showPlanCancellationModal();
			} else {
				this.banner = void 0;
			}
		} else if (this.banner == SUBSCRIPTION_BANNER.PLAN_RENEWAL_FAILED) {
			const modal = this.modalService.open(PlanRenewalFailedComponent, { backdrop: 'static', windowClass: 'plan-renewal-failed-component' });
			modal.componentInstance.isTodayPaymentFailed = true;
			modal.result.then((res) => {
				this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
			});
		} else if (this.banner == SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_LICENSE) {
			this.showApproachingPlanLimitModal('license');
		} else if (this.banner == SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_ACTIVE) {
			this.showApproachingPlanLimitModal('active');
		} else if (this.banner == SUBSCRIPTION_BANNER.APPROACHING_PLAN_LIMITS_ARCHIVE) {
			this.showApproachingPlanLimitModal('archive');
		}
	}

	showPlanDowngradeModal() {
		let message = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN'
			? 'Your current usage exceeds the limits of the selected downgrade plan. Please adjust excess users and storage according to the limits of your selected downgrade plan before it comes into effect, or your account will be locked until you subscribe again to the original plan or higher, for a minimum period of 1 month.'
			: 'Your current usage exceeds the limits of the selected downgrade plan. Please contact the account owner to adjust excess users and storage according to the limits of your selected downgrade plan before it comes into effect, or your account will be locked for a minimum period of 1 month, until subscribed again to the original plan or higher.';
		let confirmBtn = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN' ? 'Manage Account' : void 0;

		this.notificationService.open({
			title: 'PLAN DOWNGRADE',
			notificationType: 'success',
			centerHeader: true,
			hideButton: !confirmBtn,
			notificationInfo: message,
			confirmBtn: confirmBtn
		}).subscribe(confirm => {
			if (confirm) {
				this.router.navigate(['account/manage/billing']);
			}
			this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
		});
	}

	showPlanCancellationModal() {
		const { timeFormat = '12hs' } = this.authService.getAuthUserSettings();
		let formattedTime = 'hh:mm a';
		if (timeFormat === '24hs') {
			formattedTime = 'HH:mm';
		}
		let dateFormat = this.authService.getUserSettings('dateFormat');
		if (!dateFormat)
			dateFormat = 'YYYY-MM-DD';
		let message = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN'
			? `You\'ve cancelled your MTM subscription which will end on ${moment(this.subscription.endDate * 1000).format(dateFormat)} at ${moment(this.subscription.endDate * 1000).format(formattedTime)} Timezone. Once your subscription ends your account will be locked. If you wish to reverse this cancellation, click on see plans below and compare what plan works best for you.`
			: `You\'ve cancelled your MTM subscription which will end on ${moment(this.subscription.endDate * 1000).format(dateFormat)} at ${moment(this.subscription.endDate * 1000).format(formattedTime)} Timezone. Once your subscription ends your account will be locked. If you wish to reverse this cancellation, please contact the account owner.`;
		let confirmBtn = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN' ? 'See Plans' : void 0;

		this.notificationService.open({
			title: 'PLAN CANCELLATION',
			notificationType: 'success',
			centerHeader: true,
			hideButton: !confirmBtn,
			notificationInfo: message,
			confirmBtn: confirmBtn
		}).subscribe(confirm => {
			if (confirm) {
				this.router.navigate(['subscription/upgrade']);
			}
			this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
		});
	}

	approachingLimitItem: any;
	showApproachingPlanLimitModal(item: any) {
		let message = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN'
			? `The limits of your current ${this.approachingLimitItem} plan is almost exhausted. You can add more ${this.approachingLimitItem} or  delete your ${this.approachingLimitItem} to save space in manage storage. Once your limits have been breached, you will need to upgrade your plan. `
			: `The limits set by your current ${this.approachingLimitItem} plan are almost exhausted. If you wish to increase your limits, contact the account owner to upgrade your plan.`;
		let confirmBtn = this.authUser.globalRole === 'COMPANY_PRINCIPAL' || this.authUser.globalRole === 'ADMIN' ? 'Manage Account' : void 0;

		this.notificationService.open({
			title: 'Approaching Plan Limits',
			notificationType: 'success',
			centerHeader: true,
			hideButton: !confirmBtn,
			notificationInfo: message,
			confirmBtn: confirmBtn
		}).subscribe(confirm => {
			if (confirm) {
				this.router.navigate(['account/manage/billing']);
			}
			this.authService.setSubscriptionCompanyFlagInfos(this.flagInfos);
		});
	}
}
