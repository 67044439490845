<div class="container">
	<div class="col">
		<div class="row mt-5">

			<div class="col">

				<div class="row justify-content-center">

				</div>
			</div>


			<div class="col">
				<div class="row justify-content-center">
					<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
				</div>
				<div class="row">
					<p class="fail-text mt-5 text-center">
						Connected Account Creation <span class="fail-span">Failed</span>
					</p>
				</div>
				<div class="row justify-content-center">
					<i class="fa fa-exclamation-circle icon-size fail-span" aria-hidden="true"></i>
				</div>
			</div>

			<div class="col">

			</div>
		</div>



	</div>
</div>