export enum AiFramingStatus {
    QUEUED = 'queued',
    PROCESSING = 'processing',
    READY = 'ready',
    ERROR = 'error'
}

export interface Movie {
    id: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    stream_url: string;
    cropping_analysis_status: any;
    audio_analysis_status: any;
    conversion_status: any;
    splitting_status: any;
    visual_analysis_status: any;
    face_analysis_status: any;
    language_name: string;
    size: any;
    duration: number;
    thumbnail_url: string;
    height: number;
    width: number
}

export interface MovieCreateParams {
    title: string;
    file: any;
    description?: string;
}

export interface MovieUpdateParams {
    title: string;
    description?: string;
}

export interface MovieDetail {
    title: string;
    description: string;
    id: string;
    created_at: string;
    download_url: string;
    updated_at: string;
    stream_url: string;
    cropping_analysis_status: any;
    audio_analysis_status: any;
    conversion_status: any;
    splitting_status: any;
    visual_analysis_status: any;
    face_analysis_status: any;
    language_name: string;
    size: any;
    duration: number;
    versions: Array<any>;
    height: number;
    width: number
}

export interface Version {
    aspect_ratio: string;
    strategy: any;
    id: string;
    created_at: string;
    updated_at: string;
    status: any;
    movie_id: string;
    stream_url: string;
}

export interface VersionCreate {
    aspect_ratio: string;
    strategy: string;
}