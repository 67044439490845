import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PasswordGenerator {
	dictionary: Array<String>;

	generatePassword(options: any) {
		if (options.lowercase === false && options.uppercase === false && options.numbers === false && options.symbols === false) {
			return "...";
		}

		this.dictionary = [].concat(
			options.lowercase ? "abcdefghijklmnopqrstuvwxyz".split("") : [],
			options.uppercase ? "ABCDEFGHIJKLMNOPWRSTUVWXYZ".split("") : [],
			options.numbers ? "0123456789".split("") : [],
			options.symbols ? "!@#$%^&*-_=+\\|:;',.\<>/?~".split("") : []
		);
		if (!this.dictionary.length) {
			return "...";
		}
		let newPassword = "";
		for (let i = 0; i < options.length; i++) {
			newPassword += this.dictionary[Math.floor(Math.random() * this.dictionary.length)];
		}
		return newPassword;
	}
}
