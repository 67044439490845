<ng-template #headerContent>
	<mtm-workspace-edit-header [workspace]="workspace"></mtm-workspace-edit-header>
</ng-template>


<div class="my-4 detail-container">
	<div class="card tab-container">
		<div class="nav-tabs">
			<div class="nav-item" *ngFor="let tab of  tabList;let i=index">
				<a (click)="toggleTab(i)" [class.active]="i==tabIndex" class="nav-link">{{tab | translate}}</a>
			</div>
		</div>
	</div>

	<mtm-edit-workspace-permissions *ngIf="tabIndex==0" [workspaceId]="workspace.id"
		[roles]="roles"></mtm-edit-workspace-permissions>

	<mtm-workspace-projects *ngIf="tabIndex == 1" [workspace]="workspace" [companyUsers]="companyUsers"
		[roles]="roles"></mtm-workspace-projects>
		<mtm-user-and-teams *ngIf="tabIndex == 2" [workspace]="workspace" [companyUsers]="companyUsers"
		[roles]="roles"></mtm-user-and-teams>
</div>