import { Injectable } from "@angular/core";
import { AIFramingServiceBase } from "./ai-framing.base.service";
import { AiFramingStatus, MovieCreateParams, MovieDetail } from "../cores/models/movie";
import { Movie } from "../cores/models/movie"

const MOVIE_PATH = '/v4/api/movies';

@Injectable({
    providedIn: 'root'
})
export class AIReframingMovieService {

    token: string;

    constructor(private apiService: AIFramingServiceBase) { }

    getMovies(skip: number = 0, limit: number = 10, q?: string) {
        let url = `${MOVIE_PATH}/`;
        let params = '';
        if (q) {
            params += params + `?q=${q}`;
        }
        if (skip) {
            if (params) {
                params += '&';
            }
            params += params + `skip=${skip}`;
        }
        if (limit) {
            if (params) {
                params += '&';
            }
            params += params + `&limit=${limit}`;
        }
        return this.apiService.httpGet(`${url}${params}`);
    }

    createMovie(movieData: MovieCreateParams) {
        const data = new FormData();
        data.append('title', movieData.title);
        data.append('description', movieData.description);
        data.append('file', movieData.file);
        return this.apiService.httpPost(`${MOVIE_PATH}/`, data, true);
    }

    getMovieById(movieId: string) {
        const url = `${MOVIE_PATH}/${movieId}`;
        return this.apiService.httpGet(url);
    }

    updateMovie(movieId: string, movieData: any) {
        const url = `${MOVIE_PATH}/${movieId}`;
        return this.apiService.httpPatch(url, movieData);
    }

    deleteMovie(movieId) {
        const url = `${MOVIE_PATH}/${movieId}`;
        return this.apiService.httpDelete(url);
    }

    createMovieVersion(movieId, aspect_ratio, strategy) {
        const url = `${MOVIE_PATH}/${movieId}/versions`;
        return this.apiService.httpPost(url, { aspect_ratio, strategy });
    }

    deleteMovieVersion(movieId: string, versionId: string) {
        const url = `${MOVIE_PATH}/${movieId}/versions/${versionId}`;
        return this.apiService.httpDelete(url);
    }

    isMovieReady(movie: MovieDetail) {
        return (movie.conversion_status == AiFramingStatus.READY) && (movie.cropping_analysis_status == AiFramingStatus.READY) && (movie.splitting_status == AiFramingStatus.READY);
    }

    isMovieError(movie: MovieDetail) {
        return movie.conversion_status == AiFramingStatus.ERROR || movie.cropping_analysis_status == AiFramingStatus.ERROR || movie.splitting_status == AiFramingStatus.ERROR;
    }

    isMovieQueued(movie: MovieDetail) {
        return movie.conversion_status == AiFramingStatus.QUEUED || movie.cropping_analysis_status == AiFramingStatus.QUEUED || movie.splitting_status == AiFramingStatus.QUEUED;
    }
}