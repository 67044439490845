<div class="workspace-permissions">
	<div class="d-flex justify-content-between permission-header">
		<button type="button" class="btn add-user" (click)="showUserModal()">
			<i class="fa fa-plus-circle" aria-hidden="true"></i>
			{{'workspace_edit_addNewUser'|translate}}
		</button>
		<button (click)="showPermissionDetailsInfo()" class="btn add-user">
			<i class="fa fa-users fa-fw"></i>
			{{'permissionDetails' | translate}}
		</button>
	</div>
	<div class="permission-list table-responsive p-3">
		<div *ngIf="rowFiltered" class="d-flex justify-content-end">
			<label class="p-2">Total: {{filteredUsers.length}} records</label>
		</div>
		<ag-grid-angular #userPermissionTable [rowData]="filteredUsers" [debounceVerticalScrollbar]="true"
			[headerHeight]="72" [rowHeight]="80" [defaultColDef]="defaultColDef" class="ag-theme-alpine"
			[columnDefs]="userPermissionColumnsDef" [context]="tableContext" [components]="components"
			[domLayout]="'autoHeight'" [enableCellTextSelection]="true" [ensureDomOrder]="true">
		</ag-grid-angular>
	</div>
	<div class="d-flex justify-content-between permission-footer">
		<button type="button" class="btn add-user" (click)="showUserModal()">
			<i class="fa fa-plus-circle" aria-hidden="true"></i>
			{{'workspace_edit_addNewUser'|translate}}
		</button>
	</div>
</div>