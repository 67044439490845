import { TransferProgress } from "./transfer-progress";
import { Subject, Subscription } from "rxjs";
import { DownloadRecord } from "./idx-schema";
import {TransferStatus} from "./transfer-status";

export class MTMFileDownload {
  id: string;
  name: string;
  owner: string;
  contentType: string;
  downloadParameters: any;
  displayName: string;
  url: string;
  status: string = TransferStatus.WAITING;
  size: number;
  percentage: number = 0;
  buffer: Uint8Array = new Uint8Array(0)
  startDate: number = +new Date();
  startIndex: number = 0;
  retryCount: number = 0;
  maxRetry: number = 3;
  isResumable: boolean = true;
  isSilent: boolean = false;

  progress$: Subject<TransferProgress> = new Subject<TransferProgress>();
  done$: Subject<MTMFileDownload> = new Subject<MTMFileDownload>();
  currentRequest: Subscription = null;

  toDownloadRecord(): DownloadRecord {
    return {
      id: this.id,
      name: this.name,
      owner: this.owner,
      url: this.url,
      contentType: this.contentType,
      status: this.status,
      startDate: this.startDate,
      percentage: this.percentage,
      size: this.size,
      displayName: this.displayName
    };
  }

  static fromDownloadRecord(record: DownloadRecord): MTMFileDownload {
    const download = new MTMFileDownload();
    download.id = record.id;
    download.name = record.name;
    download.owner = record.owner;
    download.url = record.url;
    download.contentType = record.contentType;
    download.status = record.status;
    download.startDate = record.startDate;
    download.percentage = record.percentage;
    download.size = record.size;
    download.displayName = record.displayName;
    return download;
  }

  static fromFileRef(file: any): MTMFileDownload{
    const downloadItem = new MTMFileDownload();
		downloadItem.name = file.name;
		downloadItem.url = file.signedURL;
		downloadItem.contentType = file.contentType;
		downloadItem.size = file.size;
		downloadItem.displayName = file.displayName;
    return downloadItem;
  }
}
