<div class="card">
    <div class="row">
        <div class="col-2 d-flex align-items-center justify-content-center">
            <div class="circle-div">
                <div class="number-circle">
                    <span class="_700 f-16 level-number ">2</span>
                </div>
            </div>
        </div>
        <div class="col p-0">
            <span class="f-15 c-mtm-full-black">Level 2</span>
            <br> <span class="f-10 _700" [style.color]="'#0000004d'">500 Points to next level</span>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col">
            <div class="progress-container d-flex">
                <progress class="progress-colored" id="file" max="100" value="40"></progress>
                <div class="progress-label d-flex justify-content-center align-items-center ">
                    <div class="d-flex justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 9" fill="none">
                            <path opacity="0.6" d="M5.19545 7.42802L7.33177 8.6448C7.723 8.86779 8.20174 8.53815 8.09878 8.12124L7.53253 5.83312L9.42176 4.29154C9.76666 4.01037 9.58134 3.47712 9.12834 3.44319L6.64197 3.24443L5.66904 1.08234C5.49402 0.689675 4.89688 0.689675 4.72185 1.08234L3.74892 3.23958L1.26256 3.43834C0.809552 3.47227 0.624233 4.00552 0.969133 4.28669L2.85836 5.82827L2.29211 8.1164C2.18915 8.5333 2.66789 8.86295 3.05912 8.63995L5.19545 7.42802Z" fill="white"/>
                        </svg>
                    </div>
                    <div class="ml-1 d-flex justify-content-center align-items-center">
                        <span class="f-11 _600" [style.color]="'#685613'">
                            5000 
                        </span>
                        <span class="f-11 _600"  [style.color]="'#685613'" [style.opacity]="'0.8'">
                            / 6000
                        </span>
                    </div>
                </div>
                <div class="prev-level">
                    <div class="prev-level-circle">
                        <span class="f-11 _600" [style.color]="'#825C24'">2</span>
                    </div>
                </div>
                <div class="next-level">
                    <div class="next-level-circle">
                        <span class="f-11 _600" [style.color]="'#825C24'">3</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col d-flex justify-content-between">
            <div>
                <span class="f-14 _400">Certifications</span>
            </div>
            <div class="pointer">
                <span class="f-10 _600">SEE ALL</span>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col certificate-card-gap">
            <div class="certificate-card d-flex justify-content-center align-items-center flex-wrap">
                <div>
                    <img src="../../../../assets/svg/certification/gold-certificate.svg" alt="">
                </div>
                <div class="d-flex text-center">
                    <span class="f-11 _600">Comment & Annotate</span>
                </div>
                <div class="gold-certificate-btn d-flex justify-content-center align-items-center pointer">
                    <span  class="f-9 _600" [style.color]="'#E8AE1A'">Gold Certified  </span>
                </div>
            </div>
            <div class="certificate-card d-flex justify-content-center align-items-center flex-wrap">
                <div>
                    <img src="../../../../assets/svg/certification/bronze-certificate.svg" alt="">
                </div>
                <div class="d-flex text-center">
                    <span class="f-11 _600">Tasks & Events</span>
                </div>
                <div class="bronze-certificate-btn d-flex justify-content-center align-items-center pointer">
                    <span class="f-9 _600" [style.color]="'#A59E8E'">Bronze Certified  </span>
                </div>
            </div>
            <div class="certificate-card d-flex justify-content-center align-items-center flex-wrap">
                <div>
                    <img src="../../../../assets/svg/certification/silver-certificate.svg" alt="">
                </div>
                <div class="d-flex text-center">
                    <span class="f-11 _600">Asset’s Project Creation</span>
                </div>
                <div class="silver-certificate-btn d-flex justify-content-center align-items-center pointer">
                    <span class="f-9 _600" [style.color]="'#778390'">Silver Certified </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-5 history-card">
        <div class="row my-2 certification-history" *ngFor="let data of certificationData;">
            <div class="col-2 d-flex align-items-center justify-content-center">
                <div class="">
                    <img *ngIf="data.category === 'Medalsgold'" src="../../../../assets/svg/certification/Medalsgold.svg" alt="">
                    <img *ngIf="data.category === 'Medalsbronez'" src="../../../../assets/svg/certification/Medalsbronez.svg" alt="">
                    <img *ngIf="data.category === 'Medalssilver'" src="../../../../assets/svg/certification/Medalssilver.svg" alt="">
                    <img *ngIf="data.category === 'halfcompleted'" src="../../../../assets/svg/certification/complete90percent.svg" alt="">
                    <img *ngIf="data.category === 'completed'" src="../../../../assets/svg/certification/completed.svg" alt="">
                    <img *ngIf="data.category === 'gold'" src="../../../../assets/svg/certification/gold-certificate.svg" alt="">
                </div>
            </div>
            <div class="col p-0">
                <span class="f-15 c-mtm-full-black">{{data.status}} <b>{{data.title}} </b> <span *ngIf="data.title"> in </span> <b>{{data.area}}</b></span>
                <br> <span class="f-10 _700" [style.color]="'#0000004d'">{{data.date}} • {{data.completedStages}}/{{data.totalStages}} {{data.stageStatus}}</span>
            </div>
        </div>
    </div>
</div>