import { Component, HostBinding, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { IHeaderParams } from "ag-grid-community";
import { Subject } from "rxjs";

@Component({
  selector: 'project-list-table-header',
  templateUrl: './project-list-table-header.component.html',
  styleUrls: ['./project-list-table-header.component.scss']
})
export class ProjectListTableHeaderComponent implements IHeaderAngularComp, OnInit, OnDestroy {
  @HostBinding('class') classes = 'w-100 h-100 d-flex align-items-center py-2';
  colId: any;
  params: IHeaderParams;
  searchValue: any;
  order: any = '';


  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.colId = params.column.getColId();
  }

  refresh(params: IHeaderParams<any>): boolean {
    return true;
  }

  onSortRequested(event: any) {
    if (this.params.column.isSortAscending()) {
      this.order = 'desc';
    } else if (this.params.column.isSortDescending()) {
      this.order = '';
    } else {
      this.order = 'asc';
    }
    this.params.setSort(this.order, event.shiftKey);
  }
}
