import * as _ from 'lodash';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { HelperService } from 'app/shared/services/helper.service';
import { getIconURL, formatSizeUnits, setDOMSanitizer } from '../common/functions';
import { ApiService } from 'app/shared/services/api.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from 'app/shared/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectoryService } from 'app/shared/services/directory.service';
import moment from 'moment';
import { OverlayService } from 'app/shared/services/overlayService';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { ProjectService } from 'app/shared/services/project.service';

@Component({
	selector: 'hr-list-subcmp',
	templateUrl: './file-list.component.html',
	styleUrls: ['./file-list.component.scss']
})
export class HRListFile {
	@Input() file: any;
	@Input() isSmallDisplay: boolean = false;
	@Input() showNumberOfFiles: boolean = false;
	@Input() allowFilenameEdit: boolean = false;
	@Input() showUploadedBy: boolean = false;
	@Output() nameClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() iconClick: EventEmitter<any> = new EventEmitter<any>();

	clicked: boolean = false;
	projectId: string;
	sectionId: string;
	subsectionId: string;
	itemId: string;
	fileId: string;
	formGroup: UntypedFormGroup = null;
	newfileName: any;
	fileExtension: string;
	role: number;
	editMode: boolean = false;
	fileName: string;
	ngUnsubscribe = new Subject();

	constructor(
		private sane: DomSanitizer,
		private apiService: ApiService,
		private fb: UntypedFormBuilder,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private directoryService: DirectoryService,
		private notificationService: NotificationService) {
		setDOMSanitizer(this.sane);
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.init();
		this.loadFormGroup()
	}

	ngAfterViewInit() {
		this.init();
	}

	init() {
		if (!this.file.id)
			this.file.contentType = this.file.type;
		this.file.printName = this.file.displayName || this.file.name || this.file.title;
		if (!this.file.files) {
			this.file.file ? this.file.files = [this.file.file] : [];
		}
		if (!this.file.id && this.file.files.length > 0) {
			this.file.typeIcon = getIconURL(this.file.files[0]);
		}
		if (this.file.id) {
			this.file.typeIcon = getIconURL(this.file);
		}
		this.file.displaySize = formatSizeUnits(this.file.size || this.file.files[0].size);
		this.file.displayTime = DateHelperService.fromTimestampToDate(this.file.timeCreated || this.file.files[0].updated);
	}

	private loadFormGroup() {
		this.formGroup = this.fb.group({
			displayName: [(this.newfileName && this.newfileName.title) ? this.newfileName.displayName : '', [Validators.required]]
		});
	}

	getDisplay(name: any) {
		if (!name) {
			return '';
		}
		let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
		let nameArr = name.split('_');
		let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
		let savedName = '';
		if (isTime) {
			savedName = nameArr.length ? _.slice(nameArr, 0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_') : name.split('.')[0];
		} else {
			let nameSplit = name.split('.');
			savedName = _.slice(nameSplit, 0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
		}
		let fileDisplayName = savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
		this.newfileName = fileDisplayName.split('.');
		this.fileExtension = '.' + this.newfileName[1];
		return fileDisplayName;
	}

	editFileName(element) {
		this.role = element.participants.map(val => val.roles.length)
		this.projectId = element.projectId
		this.sectionId = element.id;
		this.subsectionId = element.id;
		this.itemId = element.id
		this.fileId = element.id;
		this.clicked = true;
	}

	close() {
		this.clicked = false;
	}

	save() {
		let newname = this.formGroup.value.displayName + this.fileExtension;
		if (this.role != 0) {
			this.apiService.updateDisplayName(this.projectId, this.sectionId, this.subsectionId, this.itemId, this.fileId, newname).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				data => {
				});
		} else {
			this.notificationService.open({
				title: 'User permission',
				description: `You don't have permission to update file name`,
				confirmBtn: 'OK'
			});

		}
	}

	handleNameClick() {
		console.log('ok');
		if (this.allowFilenameEdit) {
			this.editMode = !this.editMode;
			this.fileName = this.getDisplay(this.file.displayName).split('.').slice(0, -1).join('.');
		} else {
			this.nameClick.emit(this.file);
		}
	}

	handleIconClick() {
		this.iconClick.emit(this.file);
	}

	editModeClick(e) {
	}

	saveName(e) {
		this.editMode = false;
		const newFilename = this.fileName + '_' + moment().unix() + this.fileExtension;
		this.file.displayName = this.file.name = this.file.title = this.file.printName = newFilename;
		this.directoryService.renameFolder(this.file.projectId, this.file.id, newFilename)
			.subscribe(
				(res) => {
					this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
				}, err => {
					this.overlayService.showError(err.message, 'Error');
				})
	}

	getUserInfo(file, email) {
		if (!file) {
			return '';
		}
		const user = file.participants.find(user => user.username === email);
		return user?.fullName || '';
	}
}
