import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SignupFormComponent } from './signup-form/signup-form.component';
import { NotificationService } from "../shared/services/notification.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
	BuildPlanPopupComponent
} from "../shared/components/build-plan-popup/build-plan-popup.component";
import { EmitterService } from "../shared/services/emitter.service";
import { environment } from "./../../environments/environment";
import { LanguagePopupComponent } from "../shared/components/language-popup/language-popup.component";
import { TranslateService } from "../shared/services/translate.service";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { BannerTypes } from "../subscriptions/shared/components/subscription-banner/subscription-banner.component";

@Component({
	selector: 'mtm-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	// entryComponents: [BuildPlanPopupComponent]
})
export class LoginComponent implements OnInit, OnDestroy {

	isCustomerTypeSelected: boolean;
	isRECAPTCHAChecked: boolean = true;
	projectId: string;
	redirectLoginFormOneShot: boolean;
	selectedCustomerType: string;
	projectType: string;
	companyType: string;
	currentLanguage: string = 'en_us';
	public planText: string = 'I am an advertiser and I want to take back control on my content in hybrid system';
	ngUnsubscribe = new Subject();
	bannerTypes = BannerTypes;
	trainingMode: boolean;
	trainerUserName: string;

	constructor(private modalService: NgbModal,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private authService: AuthService,
		public userService: UserService) {
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.getRoutesParams();
		//this.openLanguagePopup();
		EmitterService.get('detectNewRoutes').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.getRoutesParams();
		});

		// for self trainer login
		this.activatedRoute.queryParams.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((params: Params) => {
			if (params.trainer) {
				this.trainingMode = true;
				console.log('this.trainingMode', this.trainingMode);
			} else {
				this.trainingMode = false;
			}
		
			if (params.u) {
				// Directly decode the email parameter
				const decodedEmail = decodeURIComponent(params.u);
				console.log('decodedEmail', decodedEmail);
				this.trainerUserName = decodedEmail;
			}
		});
		
		this.currentLanguage = this.authService.getAuthLanguage();
	}

	/**
	 * Open Signup form modal
	 */
	onOpenSignupModal() {
		const modalRef = this.modalService.open(SignupFormComponent, { size: 'lg' });
	}

	openLanguagePopup() {
		let modal = this.modalService.open(LanguagePopupComponent, { size: 'lg', backdrop: 'static' });
	}

	notifyUser(type: string) {
		if (type == 'ONE_SHOT') {
			this.notificationService.open({
				size: 'lg',
				title: 'MTM',
				description: `You want to produce a video on our marketplace. Great!!! Please build your request and see how easy it is!`,
				confirmBtn: 'GET STARTED'
			}).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(() => {
				this.router.navigate(['/entry/marketplace/build-request', type, this.selectedCustomerType]);
				// this.isAdvertiser = true;
			});
		}
		else if (type == 'SUBSCRIPTION') {
			/*
			let buildPlanModal = this.modalService.open(BuildPlanPopupComponent, { size: 'lg', backdrop: 'static', windowClass: 'subscription-plan-modal-content' });
			buildPlanModal.componentInstance.customerType = this.selectedCustomerType;
			buildPlanModal.componentInstance.projectType = type;
			*/
			window.location.href = 'https://mtm.video/fr/workspace/pricing/';
		}

	}

	selectCustomerType(customerType) {
		this.isCustomerTypeSelected = true;
		this.selectedCustomerType = customerType;
		// this.isRECAPTCHAChecked = true;
	}

	resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response ${captchaResponse}:`);
		if (captchaResponse) {
			this.isRECAPTCHAChecked = true;
		}
	}

	closeModal() {
		this.isCustomerTypeSelected = false;
	}

	checkEnvironment() {
		return environment.isProductionSide;
	}

	getRoutesParams() {
		this.projectId = this.activatedRoute.snapshot.params['projectId'];
		this.projectType = this.activatedRoute.snapshot.params['projectType'];
		this.companyType = this.activatedRoute.snapshot.params['companyType'];
		this.redirectLoginFormOneShot = this.activatedRoute.snapshot.params['redirectLoginFormOneShot'];
	}
	changeLanguage(language: any) {
		this.translateService.use(language);
		this.authService.setAuthLanguage(language);
		this.currentLanguage = language;
		this.userService.changeUserProfileSettingLang(language).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(userProfileSettings => { });
	}
}

