<div class="company-table-header-column w-100" #roleCell>
    <div class="d-flex align-items-center justify-content-center" (click)="onSortRequested($event)">
        <div class="header-label mb-1">{{ params.displayName|translate }}</div>
        <div class="d-flex align-items-center order-label ml-2" *ngIf="params.enableSorting">
            <i class="fa"
                [ngClass]="{ 'fa-sort-asc': order==='asc', 'fa-sort-desc': order==='desc', 'fa-sort':!order }"></i>
        </div>
    </div>
    <div class="search-element-container">
        <div *ngIf="colId==='fullName'">
            <input [(ngModel)]="params.context.componentParent.localFilterParams.fullName" class="form-control" type="text"
                placeholder="{{'Type name'|translate}}...">
        </div>
        <div *ngIf="colId==='email'">
            <input [(ngModel)]="params.context.componentParent.localFilterParams.email" class="form-control" type="text"
                placeholder="{{'Type email'|translate}}...">
        </div>
        <div *ngIf="colId==='role'">
            <mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select'" [emptyListInfoText]="'Select roles...'"
                [dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
                [items]=params.context.componentParent.allRoles [itemValueAccessor]="'code'" [itemLabelAccessor]="'label'"
                [isMultipleSelect]=true [refObject]=params.context.componentParent.localFilterParams
                [hideSelectedItem]="true" [objectVariableAccessor]="'roles'" (open)="dropdownOpen($event)">
            </mtm-dropdown>
        </div>
        <div *ngIf="colId==='decisionMaker'">
            <select class="form-control" [(ngModel)]="params.context.componentParent.localFilterParams.decisionMaker">
                <option [ngValue]="''">{{'Select option'|translate}}...</option>
                <option [ngValue]="true">{{'Yes'|translate}}</option>
                <option [ngValue]="false">{{'No'|translate}}</option>
            </select>
        </div>
    </div>
</div>