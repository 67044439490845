<div class="component-container subscription-register-component pl-3 pr-3 pt-3 pb-5 m-auto">
  <div class="component-head d-flex flex-column">
    <div [style.text-transform]="'uppercase'" [style.color]="'#F4A143'"
      class="component-title d-flex align-items-center justify-content-center pt-3">
      <span class="_600">{{'subscription_register_plans_title'|translate}}</span>
    </div>
    <div [style.color]="'#03041F'" [style.font-size]="'1.25rem'"
      class="component-sub-title d-flex align-items-center justify-content-center p-3">
      <span class="_600">{{'subscription_register_plans_subscribe_now'|translate}}</span>
    </div>
  </div>
  <div class="component-body d-flex flex-column" [style.font-size]="'13px'">
    <div class="register-step-tab flex-fill d-flex align-items-center justify-content-center flex-nowrap"
      [style.font-weight]="'600'">
      <div class="tab-container p-3 d-flex align-items-center justify-content-center flex-fill"
        [style.background-color]="'#419E89'" [style.width]="'20%'" [style.border-top-left-radius]="'4px'">
        <a [routerLink]="['./select-plan']" [style.color]="'#FFFFFF'" [style.text-transform]="'uppercase'">
          <div class="d-flex align-items-center">
            <span class="mr-2">1.</span>
            <span class="d-inline-block">{{'subscription_register_menu_selectPlan'|translate}}</span>
          </div>
        </a>
      </div>
      <div class="tab-container p-3 d-flex align-items-center justify-content-center flex-fill"
        [style.background-color]="'#F2DB4D'" [style.width]="'20%'" [class.disabled]="!registerService?.planSelected">
        <a [routerLink]="['./confirm-plan']" [style.color]="'#FFFFFF'" [style.text-transform]="'uppercase'">
          <div class="d-flex align-items-center">
            <span class="mr-2">2.</span>
            <span class="d-inline-block">{{'subscription_register_menu_confirmPlan'|translate}}</span>
          </div>
        </a>
      </div>
      <div class="tab-container p-3 d-flex align-items-center justify-content-center flex-fill"
        [style.background-color]="'#F4A143'" [style.width]="'20%'" [class.disabled]="!registerService?.planConfirmed"
        *ngIf="registerService?.registerData?.plan !== 'FREE_PLAN'">
        <a [routerLink]="['./payment']" [style.color]="'#FFFFFF'" [style.text-transform]="'uppercase'">
          <div class="d-flex align-items-center">
            <span class="mr-2">3.</span>
            <span class="d-inline-block">{{'subscription_register_menu_payment'|translate}}</span>
          </div>
        </a>
      </div>
      <div class="tab-container p-3 d-flex align-items-center justify-content-center flex-fill"
        [class.disabled]="!registerService?.paymentSuccessful || !registerService?.planConfirmed"
        [style.background-color]="'#FF3C00'" [style.width]="'20%'" [style.border-top-right-radius]="'4px'">
        <a [routerLink]="['./produce']" [style.color]="'#FFFFFF'" [style.text-transform]="'uppercase'">
          <div class="d-flex align-items-center">
            <span class="mr-2">{{registerService?.registerData?.plan === 'FREE_PLAN' ? '3.' : '4.'}}</span>
            <span class="d-inline-block">{{'subscription_register_menu_produce'|translate}}</span>
          </div>
        </a>
      </div>
    </div>
    <div class="content-container flex-fill" [style.background-color]="'#FFFFFF'">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>