import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";
import { Observable } from 'rxjs';
import { ShortenedUrlInfo } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class UrlShortenerService {
    constructor(private authService: AuthService, private apiService: ApiService) { }

    getShortenedUrl(url: string): Observable<any> {
        return this.apiService.httpGet(`/api/url-managers?url=${encodeURIComponent(url)}`);
    }

    getShortenedUrlByBackhalf(backHalf: string): Observable<any> {
        return this.apiService.httpGet(`/api/url-managers/${backHalf}`);
    }

    createShortenedUrl(data: ShortenedUrlInfo): Observable<any> {
        return this.apiService.httpPost(`/api/url-managers`, data);
    }

    editShortenedUrl(data: ShortenedUrlInfo): Observable<any> {
        return this.apiService.httpPut(`/api/url-managers/${data.id}`, data);
    }

    saveShortenedUrl(data: ShortenedUrlInfo): Observable<any> {
        if (data.id) {
            return this.editShortenedUrl(data);
        } else {
            return this.createShortenedUrl(data);
        }
    }
}


