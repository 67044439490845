import * as _ from 'lodash';
import { OnInit, Component, Input, Output, OnDestroy, ViewEncapsulation } from "@angular/core";
import { EventEmitter } from '@angular/core';
import { OverlayService } from 'app/shared/services/overlayService';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
	selector: 'mtm-inviter-dropdown',
	templateUrl: './mtm-inviter-dropdown.component.html',
	styleUrls: ['./mtm-inviter-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MtmInviterDropdownComponent implements OnInit, OnDestroy {
	@Input() data: any[] = [];
	@Input() iconSize: any = 32;
	@Input() internalUsersOnly: boolean = false;
	@Input() userInfo: any;
	@Input() inputPlaceholder: any;
	@Input() hideModelPreview: boolean = false;
	@Input() componentId: any;
	@Input() forceLoadProfile: boolean = false;
	@Input() singleInput: boolean = false;
	@Input() asClassicDropdown: boolean = false;
	@Output() selectedInvitersChange: EventEmitter<any> = new EventEmitter();

	modelValue: any = [];
	@Input()
	get value() {
		return this.modelValue || [];
	};
	@Output() valueChange: EventEmitter<any> = new EventEmitter();
	set value(val) {
		this.modelValue = val;
		this.valueChange.emit(this.modelValue);
	}
	isOpen: boolean = false;
	emailValue: any = '';
	suggestedUsers: any[] = [];

	constructor(
		private overlayService: OverlayService,
		private authService: AuthService) {
		let component = this;
		window.addEventListener('click', (e) => {
			let dropdownComponent = $(e.target).closest('.mtm-inviter-dropdown') || [];
			if (!dropdownComponent.length && component.suggestedUsers.length) {
				component.suggestedUsers = [];
				component.emailValue = '';
			}
		});
	}

	ngOnInit() { }

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	ngOnDestroy(): void {
		let component = this;
		window.removeEventListener('click', (e) => {
			let dropdownComponent = $(e.target).closest('.mtm-inviter-dropdown') || [];
			if (!dropdownComponent.length && component.suggestedUsers.length) {
				component.suggestedUsers = [];
				component.emailValue = '';
			}
		});
	}


	suggestUsers(e) {
		this.data.map(u => {
			u.fullName = u.fullName.trim();
			return u;
		})
		if (e.target.value) {
			this.suggestedUsers = _.filter(this.data,
				(data) => (_.get(data, 'email', '').toLowerCase().includes(e.target.value.toLowerCase()) || _.get(data, 'fullName', '').toLowerCase().includes(e.target.value.toLowerCase()))
					&& !_.find(this.value, (user) => user.email === data.email)
					&& (data.globalRole !== 'COMPANY_OCCASIONAL'
						|| ((data.roles && data.roles[0] !== 'COMPANY_OCCASIONAL') && data.roles.length === 1)
					)
					&& data.email !== this.authService.getAuthUser().email
			);
		} else {
			this.suggestedUsers = [];
		}
	}

	suggestedUserSelected(user) {
		this.value = this.singleInput ? [user.email] : [...this.value, ...[user]];
		this.emailValue = !this.asClassicDropdown ? '' : user.email;
		this.suggestedUsers = [];
		this.modelValue = this.value;
		this.selectedInvitersChange.emit({ value: this.value, selectedData: user, componentId: this.componentId });
	}

	removeUser(user) {
		this.value = _.filter(this.value, (data) => {
			return data.email !== user.email;
		});
		this.modelValue = this.value;
		this.selectedInvitersChange.emit({ value: this.value, removedData: user, componentId: this.componentId });
	}

	selectEmail(email, doNotShowOverlay?: boolean) {
		let emailAlreadyAdded = _.find(this.value, (usr) => usr.email === email);
		if (emailAlreadyAdded) {
			this.overlayService.showError('This email has already invited', 'Email Invited');
			return;
		}
		if (!this.validateEmail(email)) {
			if (this.asClassicDropdown) {
				this.modelValue = this.value;
				this.selectedInvitersChange.emit({ value: this.value, newData: email, invalid: true, componentId: this.componentId });
			}
			if (!doNotShowOverlay) {
				this.overlayService.showError('Please use a valid email address', 'Invalid Email');
			}
			return;
		}
		let anonimUser = {
			type: 'anonim',
			email: email
		}
		this.value = this.singleInput ? [email] : [...this.value, ...[anonimUser]];
		if (!this.asClassicDropdown) {
			this.emailValue = '';
			this.suggestedUsers = [];
		}
		this.modelValue = this.value;
		this.selectedInvitersChange.emit({ value: this.value, newData: email, componentId: this.componentId });
	}

	validateEmail(email: any) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(email).toLowerCase());
	}
}
