<div class="row">
  <div class="col-6 my-3">
    <input [(ngModel)]="searchDriveCampaign" class="search-box pl-2 pr-4" (ngModelChange)="searchDriveCampaigns()"
      type="text" placeholder="{{'search' | translate}}" />
    <i class="fa fa-search search-icon" aria-hidden="true"></i>
  </div>
  <div class="col-6 my-3 ">
    <div class="row justify-content-end align-items-center pr-5">
      <div class="calendar-block d-flex mr-5">
        <div class="d-flex align-items-center">
          <div #monthYearDropdown class="d-flex align-items-center calendar-dropdown ml-auto" ngbDropdown>
            <div class="dropdown-toggle mt-auto d-flex align-items-center p-0 pointer" ngbDropdownToggle>
              <div class="_400 ml-2 mr-3">{{calendarViewMode | translate}}
              </div>
              <div class="calendar-icon-div pointer ml-auto">
                <img src="../../../../assets/img/project-v2/calendar.svg" alt="">
              </div>
            </div>
            <div class="calendar-dropdown-menu w-100" ngbDropdownMenu>
              <div ngbDropdownItem (click)="selectCalendarMode('latest')">{{'latest' | translate }}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('week')">{{'week' | translate}}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('3_months')">{{'calendarView_3_months' | translate}}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('6_months')">{{'calendarView_6_months' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center item-filter" container="body" ngbDropdown>
        <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis f-10 pointer"
          ngbDropdownToggle>
          {{ shouldShowEllipsis(selectedWorkspace.name) ? (selectedWorkspace.name | slice:0:12) + '...' :
          selectedWorkspace.name }}
        </div>
        <div class="" [style.max-width]="'169px'" [style.min-width]="'169px'" ngbDropdownMenu>
          <div class="_1-line-text-ellipsis f-12 pointer" (click)="changeWorkspace('all_workspaces')" ngbDropdownItem>
            All Workspaces
          </div>

          <div class="_1-line-text-ellipsis f-12 pointer" (click)="changeWorkspace(workspace)"
            *ngFor="let workspace of workspaces" ngbDropdownItem
            [ngbTooltip]="shouldShowEllipsis(workspace.name) ? workspace.name : null">
            {{workspace.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vh-100 item-scroller">
  <div class="row mt-4">
    <div *ngIf="canCreateDriveCampaign" [style.max-width]="'200px'" [style.min-width]="'200px'" class="col d-flex mb-4">
      <button id="campaigndrivebtn" class="campaign-drive-btn w-100" (click)="createCampaignDrive()">
        <div class="circle-div">
          <i class="fa fa-plus d-flex" aria-hidden="true"></i>
        </div>
        <h6 class="_700 f-10 mt-3 line-height" [style.color]="'#898C98'">{{'creatAnewDriveCampaign' | translate}}</h6>
      </button>
    </div>
    <div *ngIf="!isLoaded && !isError" class="skeleton">
      <mtm-grid-skeleton [borderRadius]="6" [gridCount]="5" [widthSizeInPixels]="180"
        [HeightSizeInPixels]="235"></mtm-grid-skeleton>
    </div>
    <div class="d-flex justify-content-center align-items-center w-75" *ngIf="isError">
      <span class="_600 f-12 ">{{'loreal_dashboard_somethingWentWrong' | translate}}</span>
    </div>
    <div [style.max-width]="'200px'" [style.min-width]="'200px'" class="col d-flex mb-4 pointer"
      *ngFor="let item of DriveCampaigns" (click)="$event.stopImmediatePropagation();redirectToDashboard(item)"  id="trainerOpenDriveCamapign">
      <div class="drive-campaign-card" >
        <div class="card-img" (click)="$event.stopImmediatePropagation();trainerMilestoneRedirect()">
          <img *ngIf="item.logo" class="image-style" [src]="item.logo" alt="">
          <!-- <img *ngIf="item.logo" class="image-style" [src]="item.logo + `?v=${cacheBuster}`" alt=""> -->
          <span *ngIf="!item.logo" class="c-full-white profile-text text-uppercase h-100 w-100"
            [style.backgroundColor]="timelineService.getColorFromName(item.name)">
            {{item.name | slice:0:2}}
          </span>
          <span class="badge badge-edit-button">
            <i (click)="$event.stopImmediatePropagation();markAsFavorite(item)" *ngIf="!item.isFavorite"
              class="fa fa-star-o" aria-hidden="true"></i>
            <i (click)="$event.stopImmediatePropagation();removeFavorite(item)" *ngIf="item.isFavorite"
              class="fa fa-star" [style.color]="'#ffca4c'" aria-hidden="true"></i>
          </span>
        </div>
        <div class="card-block d-flex"  >
          <div class="col pl-1">
            <div class="row">
              <div class="col">
                <span class="f-10 _700 brand-name" [style.color]="'#898C98'">{{workspaceName}}</span>
              </div>
            </div>
            <div class="row" [style.height]="'45px'">
              <div class="col">
                <span class="f-15 _700 c-full-white">{{item.name | titlecase}}</span>
              </div>
            </div>
            <div class="row flag-position">
              <div class="col-12">
                <span class="f-10 _700 c-full-white text-uppercase"> mad {{item.expectedMad | date:'dd.MM.YYYY'}}</span>
              </div>
              <!-- <div class="col-12" (click)="$event.stopImmediatePropagation()">
              <select class="pointer milestone-filter pl-1 campaign-drive-select  f-9 _500" [(ngModel)]="item.status"
                (ngModelChange)="changeDriveCampaignStatus(item)"
                [ngClass]="item.driveStatus== 'In Progress' ? '#fff' : '#000'" [style.background]="item.flagColor">
                <option class="option-hover f-9 _500 " *ngFor="let status of driveStatus" [ngValue]="status.value">
                  {{status.label}}
                </option>
              </select>
            </div> -->
              <div class="top-content d-flex align-items-center ml-3" (click)="$event.stopImmediatePropagation()">
                <div ngbDropdown class="d-flex align-items-center" [autoClose]="true" #dropdownProjectStatus>
                  <button [disabled]="!canEditStatus" (click)="$event.stopImmediatePropagation()" type="button"
                    class="d-flex align-items-center btn project-status-dropdown" ngbDropdownToggle>
                    <div class="d-flex align-items-center project-status {{item.status}}">
                      <div class="project-union-icon-container">
                        <i class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[item.status]}}"></i>
                      </div>
                      <span class="mr-2">{{'task_'+item.status|translate}}</span>
                      <i class="fa fa-chevron-down ml-auto pointer"></i>
                    </div>
                  </button>
                  <div class="project-dropdown-menu" ngbDropdownMenu>
                    <div
                      *ngIf="!item.status||item.status==projectStatus.Completed||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                      (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.InProgress)"
                      class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                      <div class="d-flex align-items-center project-status-display IN_PROGRESS">
                        <i class="project-status-circle-color mr-2"></i>
                        <span>{{'task_IN_PROGRESS'|translate}}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                      (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Completed)"
                      class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                      <div class="d-flex align-items-center project-status-display COMPLETED">
                        <i class="project-status-circle-color mr-2"></i>
                        <span>{{'task_COMPLETED'|translate}}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Completed"
                      (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Delayed)"
                      class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                      <div class="d-flex align-items-center project-status-display DELAYED">
                        <i class="project-status-circle-color mr-2"></i>
                        <span>{{'task_DELAYED'|translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>                                                                                                                                                                                                                                                                                                                                                                                                                           
              </div>
            </div>
          </div>
          <div class="pointer" [style.height]="'20px'" style="position: relative; top: -5px;"
            (click)="$event.stopImmediatePropagation()">
            <div *ngIf="canCreateDriveCampaign" ngbDropdown class="dropdown" container="body" placement="bottom-right">
              <button ngbDropdownToggle class="edit-button">
                <i class="fa fa-ellipsis-v fa-lg" [style.color]="'#898D98'" aria-hidden="true"></i>
              </button>
              <!-- <div ngbDropdownMenu class="dropdown-menu-left dropdown-menu-campaign" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item pointer" (click)="editCampaignDrive(item)">
                Edit
              </a>
              <a class="dropdown-item pointer" (click)="deleteDriveCampaign(item)">
                Delete
              </a>
            </div> -->
              <div ngbDropdownMenu class="dropdown-menu-left arrow_box" aria-labelledby="dropdownMenuLink">
                <!-- <a class="dropdown-item pointer" (click)="editWorkspace(item)">
                Edit
              </a> -->
                <div class="col options hover-text">
                  <div class="col pointer p-0 d-flex align-items-center my-3 " (click)="editCampaignDrive(item)">
                    <i class="_500 fa fa-pencil fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                    <span class="ml-2 f-10 _700"> {{'settings' | translate}}</span>
                  </div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="markAsFavorite(item)">
                    <i *ngIf="!item.isFavorite" class="_500 fa fa-star-o fa-lg" [style.color]="'#181E39'"
                      aria-hidden="true"></i>
                    <i *ngIf="item.isFavorite" class="_500 fa fa-star fa-lg " [style.color]="'#ffca4c'"
                      aria-hidden="true"></i>
                    <span *ngIf="!item.isFavorite" class="ml-2 f-10 _700 text-uppercase"> {{ 'markAsFavorite' | translate }}</span>
                    <span *ngIf="item.isFavorite" class="ml-2 f-10 _700 text-uppercase"> {{ 'removeFavorite' | translate }}</span>
                  </div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="archiveDriveCampaign(item)">
                    <img [style.color]="'#181E39'" width="15" height="12" src="../../../assets/svg/archive-svg.svg"
                      alt="">
                    <span *ngIf="!item.archived" class="ml-2 f-10 _700 text-uppercase"> {{ 'archive' | translate}}</span>
                    <span *ngIf="item.archived" class="ml-2 f-10 _700 text-uppercase"> {{ 'unArchive' | translate}}</span>
                  </div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="deleteDriveCampaign(item)">
                    <i class="_500 fa fa-trash-o fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                    <span class="ml-2 f-10 _700"> {{'deleteReviewLink' | translate}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isInit" class="w-100" style="height: 30px;" #resultObserver observeThis
    (visibilityChange)="loadMore($event)">
  </div>
</div>