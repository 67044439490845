import { SELECTORS } from '../../terms-conditions/selectors';

export const onRedirectToTCP_FNC = (selector: string, params: any[] = []): boolean => {
	try {
		let routeParams = params.toString().replace(/,/g, '/');

		window.open(`${window.location.origin}/mtm-tcp/view${SELECTORS[selector] ? '/' + selector : ''}${routeParams.length <= 0 ? '' : '/' + routeParams}`, '_blank')
	} catch (error) {

	}
	return false;
}
