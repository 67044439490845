import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { SubscriptionService } from "../../../services/subscription.service";
import * as moment from 'moment';

@Component({
	selector: 'mtm-upgrade-produce',
	templateUrl: './produce.component.html',
	styleUrls: ['./produce.component.scss']
})
export class SubscriptionUpgradeProduceComponent implements OnInit, AfterViewInit {
	isDowngrade: boolean = false;
	newPlanStr: string = '';
	billingDate: string = '';
	billingTime: string = '';
	redirectToDashboard: boolean;

	constructor(
		private subscriptionService: SubscriptionService,
		private authService: AuthService,
		private router: Router
	) { }

	ngOnInit() {
		this.newPlanStr = `subscription_register_select_plan_${this.subscriptionService.upgradeData.plan.code}`;
		this.isDowngrade = this.subscriptionService.upgradeData.isDowngrade;

		if (this.isDowngrade) {
			this.getBillingDate();
		}
	}

	ngAfterViewInit(): void {
	}

	getBillingDate() {
        const { timeFormat = '12hs' } = this.authService.getAuthUserSettings();
        let formattedTime = 'hh:mm a';
        if (timeFormat === '24hs') {
            formattedTime = 'HH:mm';
        }
        let dateFormat = this.authService.getUserSettings('dateFormat');
        if (!dateFormat)
            dateFormat = 'YYYY-MM-DD';
		const subscription = this.authService.getAuthUserSubscription();
		const timestamp = moment.unix(subscription.endDate);
		this.billingDate = timestamp.format(dateFormat);
		this.billingTime = timestamp.format(formattedTime);
	}

	returnToDashboard() {
		this.redirectToDashboard = true;
		setTimeout(() => {
			this.subscriptionService.getCurrentPlan().subscribe(res => {
				this.authService.setAuthUserSubscription(res);
				this.redirectToDashboard = false;
				this.subscriptionService.init();
				this.router.navigate(['/projects']);
			})
		}, 5000);
	}

}
