import * as _ from 'lodash';
import { EventEmitter, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Subject, Observable, of } from "rxjs";
import { AuthService } from "./auth.service";
import { HelperService } from "./helper.service";
import { map, tap, share, finalize } from 'rxjs/operators';

export let commentFileAdd: EventEmitter<any> = new EventEmitter<any>();
export let commentFileTranscriptionGenerated: EventEmitter<any> = new EventEmitter<any>();
export let commentFileUploading: EventEmitter<any> = new EventEmitter<any>();
export let commentFileUploaded: EventEmitter<any> = new EventEmitter<any>();

@Injectable({
	providedIn: 'root'
})
export class Comment {
	id: string;
	replies?: any[];
	body: string;
	datetime: any;
	updatetime: any;
	username: any;
	parentId: any;
	files: any;
	annotation?: {
		id: any,
		color: any,
		colorOpacity: any,
		isCommentInTimeRange: any,
		range: {
			start: any,
			end: any
		},
		rangeInTimeString: {
			start: any,
			end: any
		},
		secondRangeString: {
			start: any,
			end: any
		},
		shape: any
	}
}

@Injectable({
	providedIn: 'root'
})
export class CommentsService {

	private cache: any = {};
	private cachedObservable: any = {};

	constructor(private apiService: ApiService, private authService: AuthService) { }

	/**
	 * Get comments by section
	 * @param projectId
	 * @param sectionId
	 */
	getCommentsBySection(projectId: string, sectionId: string, cacheRequest?: boolean): Observable<any> {
		return this.getComments(projectId, sectionId, null, null, cacheRequest);
	}

	getCommentsOfProject(projectId: string, cacheRequest?: boolean): Observable<any> {
		return this.getComments(projectId, null, null, null, cacheRequest);
	}


	/**
	 * Get comments by section and subsection
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	getCommentsBySectionAndSubsection(projectId: string, sectionId: string, subsectionId: string, cacheRequest?: boolean): Observable<any> {
		return this.getComments(projectId, sectionId, subsectionId, null, cacheRequest);
	}


	/**
	 * Get comments by section, subsection and typeId
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param typeId
	 */
	getCommentsBySectionAndSubsectionAndTypeId(projectId: string, sectionId: string, subsectionId: string, typeId: string, cacheRequest?: boolean): Observable<any> {
		return this.getComments(projectId, sectionId, subsectionId, typeId, cacheRequest);
	}

	sleep(milliSecond: any) {
		return new Promise(resolve => setTimeout(resolve, milliSecond));
	}

	async delay(milliSecond: any) {
		await this.sleep(milliSecond);
		for (let i = 0; i < 5; i++) {
			if (i === 3)
				await this.sleep(milliSecond);
		}
	}

	/**
	 * Get comments
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param typeId
	 */
	private getComments(projectId: string, sectionId: string = null, subsectionId: string = null, typeId: string = null, cacheRequest?: boolean) {
		let endpoint = `/api/comments/${projectId}`;
		let observable: Observable<any>;
		if (sectionId) {
			endpoint += `/${sectionId}`;
		}
		if (subsectionId) {
			endpoint += `/${subsectionId}`;
		}
		if (typeId) {
			endpoint += `/${typeId}`;
		}
		if (cacheRequest) {
			if (this.cache[projectId + sectionId + subsectionId + typeId]) {
				observable = of(this.cache[projectId + sectionId + subsectionId + typeId]);
			} else if (this.cachedObservable[projectId + sectionId + subsectionId + typeId]) {
				observable = this.cachedObservable[projectId + sectionId + subsectionId + typeId];
			} else {
				this.delay(1000);
				this.cachedObservable[projectId + sectionId + subsectionId + typeId] = this.apiService.httpGet(endpoint)
					.pipe(
						tap(res => this.cache[projectId + sectionId + subsectionId + typeId] = res),
						share(),
						map((data: any) => {
							let comments = new Array<any>();
							if (data) {
								data.forEach((c: any) => {
									c.avatarUrl = HelperService.getAvatarNoImage();
									c.isDelete = (c.username == this.authService.getAuthUserName());
									comments.push(c);
								})
							}
							comments.reverse();
							return comments;
						}),
						finalize(() => {
							this.cachedObservable[projectId + sectionId + subsectionId + typeId] = void 0;
							this.cache[projectId + sectionId + subsectionId + typeId] = void 0;
						})
					);
				observable = this.cachedObservable[projectId + sectionId + subsectionId + typeId];
			}
		} else {
			observable = this.apiService.httpGet(endpoint)
				.pipe(
					map((data: any) => {
						let comments = new Array<any>();
						if (data) {
							data.forEach((c: any) => {
								c.avatarUrl = HelperService.getAvatarNoImage();
								c.isDelete = (c.username == this.authService.getAuthUserName());
								comments.push(c);
							})
						}
						comments.reverse();
						return comments;
					})
				);
		}
		return observable;
	}

	/**
	 * Post a new comment
	 * @param message
	 * @param type
	 * @param typeId
	 */
	postComment(message: string, projectId: string, sectionId: string, subsectionId: string, typeId: string, commentId: any, time?: any, parentId?: string, isReply?: boolean, isEdit?: boolean, files?: any) {
		let subject = new Subject<any>();
		let body: any = {
			files: files,
			message: message,
			projectId: projectId,
			sectionId: sectionId,
			subSectionId: subsectionId,
			typeId: typeId,
			username: this.authService.getAuthUserName()
		};
		if (isReply) {
			body.parentId = commentId;
		} else {
			if (isEdit) {
				body.parentId = parentId;
				body.time = time;
			}
		}

		this.apiService.httpPost(`/api/comments/${projectId}/${sectionId}/${subsectionId}/${typeId}`, body).subscribe(
			(data: any) => {
				if (data) {
					data.avatarUrl = HelperService.getAvatarNoImage();
					data.canDelete = true;
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	/**
	 * Delete comment
	 * @param comment
	 */
	deleteComment(comment: any): Observable<any> {
		let authUser = this.authService.getAuthUserName();

		let endpoint = encodeURI(`/api/comments/${comment.projectId}/${comment.sectionId}/${comment.subSectionId}/${comment.typeId}/${comment.time}/${authUser}`);

		return this.apiService.httpDelete(endpoint);
	}

	mergeComment(generalComments: any[] = [], annotations: any[] = []) {
		let comments = new Array<Comment>();
		for (let i = 0; i < generalComments.length; i++) {
			comments.push({
				id: generalComments[i].id,
				replies: _.map(generalComments[i].replies, (value) => {
					return {
						id: value.id,
						body: value.message,
						datetime: value.time,
						updatetime: value.updatetime || value.time,
						username: value.username,
						parentId: value.parentId
					}
				}),
				files: generalComments[i].files,
				body: generalComments[i].message,
				datetime: generalComments[i].time,
				updatetime: generalComments[i].updatetime || generalComments[i].time,
				username: generalComments[i].username,
				parentId: generalComments[i].parentId
			});
		}
		for (let j = 0; j < annotations.length; j++) {
			let annotation = {
				id: annotations[j].id,
				color: annotations[j].color,
				colorOpacity: annotations[j].colorOpacity,
				isCommentInTimeRange: annotations[j].isCommentInTimeRange,
				range: {
					start: annotations[j].range.start,
					end: annotations[j].range.end,
					isGeneralComment: annotations[j].range.isGeneralComment || false
				},
				rangeInTimeString: {
					start: annotations[j].rangeInTimeString.start,
					end: annotations[j].rangeInTimeString.end
				},
				secondRangeString: {
					start: annotations[j].secondRangeString.start,
					end: annotations[j].secondRangeString.end
				},
				shape: annotations[j].shape
			};
			comments.push({
				id: annotations[j].comments[0].id,
				replies: _.map(
					_.takeRight(annotations[j].comments, annotations[j].comments.length - 1),
					(value: any) => {
						return {
							id: value.id,
							files: value.files,
							body: value.body,
							datetime: value.meta.datetime,
							updatetime: value.meta.updatetime || value.meta.datetime,
							username: value.meta.user_id,
							parentId: value.parentId,
							annotation: annotation
						}
					}
				),
				files: annotations[j].comments[0].files,
				body: annotations[j].comments[0].body,
				datetime: annotations[j].comments[0].meta.datetime,
				updatetime: annotations[j].comments[0].meta.updatetime || annotations[j].comments[0].meta.datetime,
				username: annotations[j].comments[0].meta.user_id,
				annotation: annotation,
				parentId: annotations[j].comments[0].parentId
			});
		}
		comments.sort((commentA: Comment, commentB: Comment) => {
			let commentAMax = Math.max(...[commentA.datetime, ...commentA.replies.map((reply: any) => reply.datetime)]);
			let commentBMax = Math.max(...[commentB.datetime, ...commentB.replies.map((reply: any) => reply.datetime)]);
			return commentBMax - commentAMax;
		});
		return comments;
	}
}
