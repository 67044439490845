import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/shared/services/api.service';
import { NotificationService } from "./notification.service";
import { ActivityLogLevels, ActivitySearchParams, GeneralLogParams } from "../interfaces";

export type ActivityLogAccessArgs = {
	hasAccess: boolean;
	level?: ActivityLogLevels;
	workspaceId?: string;
	campaignId?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ActivityLogService {
	/* ----------- ///////////// ----------- */

	previousNotifs = [];
	activityLogAccess: BehaviorSubject<ActivityLogAccessArgs> = new BehaviorSubject<ActivityLogAccessArgs>({
		hasAccess: false,
		level: null
	});

	constructor(private modalService: NgbModal,
		private apiService: ApiService,
		private notificationService: NotificationService) {
	}


	getStages(): Observable<any> {
		return this.apiService.httpGet('/api/notifications/stages');
	}

	getActivityLogs(params: ActivitySearchParams): Observable<any> {
		let subject = new Subject<any>();
		let url = `/api/projects/${params.projectId}/activity`;
		if (params.pagingState == "") {
			this.previousNotifs = []
		}

		const urlParams: string[] = [];

		if (params.notifyByUsers)
			urlParams.push(`notifyByUsers=${params.notifyByUsers}`)

		if (params.sections)
			urlParams.push(`sections=${params.sections}`);

		if (params.subSections)
			urlParams.push(`subSections=${params.subSections}`);

		if (params.pagingState && params.pagingState !== '')
			urlParams.push(`pagingState=${params.pagingState}`);

		if (params.startDate)
			urlParams.push(`startDate=${params.startDate}`);

		if (params.endDate)
			urlParams.push(`endDate=${params.endDate}`);

		if (params.category && params.category !== '')
			urlParams.push(`category=${params.category}`);

		if (params.codes.length)
			urlParams.push(`codes=${params.codes.join(",")}`);

		if (params.taskId) {
			urlParams.push(`taskId=${params.taskId}`);
		}

		if (params.limit) {
			urlParams.push(`limit=${params.limit}`);
		}

		url += '?' + urlParams.join('&');


		if (url.indexOf('?') == -1) {
			let queryIndex = url.indexOf('&');
			if (queryIndex != -1)
				url = url.substring(0, queryIndex) + '?' + url.substring(queryIndex + 1, url.length);
		}

		this.apiService.httpGet(url)
			.subscribe(
				(result: any) => {
					result.data.forEach((item: any) => {
						this.notificationService.prepareNotification(item);
					});
					result.data.sort((a: any, b: any) => a.time > b.time ? -1 : 1);
					subject.next(result);
				},
				(err: any) => {
					subject.error(err);
				}
			);
		return subject.asObservable();
	}

	downloadActivityLogs(params: ActivitySearchParams): Observable<any> {
		const urlParams: string[] = [];

		if (params.startDate)
			urlParams.push(`startDate=${params.startDate}`);

		if (params.endDate)
			urlParams.push(`endDate=${params.endDate}`);

		if (params.category && params.category !== '')
			urlParams.push(`category=${params.category}`);

		if (params.codes.length)
			urlParams.push(`codes=${params.codes.join(",")}`);

		if (params.downloadFormat) {
			urlParams.push(`type=${params.downloadFormat}`);
		}

		let url = `/api/projects/${params.projectId}/activity/download`;
		if (urlParams.length) {
			url += '?' + urlParams.join('&');
		}

		return this.apiService.httpGet(url, false, {
			'Accept': 'application/octet-stream',
			'Content-Type': ''
		}, {
			blobResponse: true
		});
	}

	downloadGeneralLog(params: GeneralLogParams) {
		const url = '/api/projects/activity/download';

		return this.apiService.newPost(url, params, {
			headers: {
			 	'Accept': '',
			},
			responseType: 'blob'
		});
	}
}
