import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class SignupGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const authUser = this.auth.getAuthUser();
		if (!route.queryParams.email && !authUser) {
			this.route.navigate(['/entry']);
		} else if (this.auth.getAuthUser()) {
			this.route.navigate(['/subscription/register/select-plan']);
		} else {
			return true;
		}
	}

}
