import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";

import { NotificationService } from 'app/shared/services/notification.service';
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { UnseenNotifierService } from "../../../shared/services/unseen-notifier.service";
import { ActiveWorkspaceUpdatedEvent, AllNotificationsReadEvent, UnseenTotal } from "../../../shared/interfaces";
import { Observable, Subject, Subscription } from "rxjs";
import { EmitterService } from "../../../shared/services/emitter.service";
import { OverlayService } from 'app/shared/services/overlayService';
import { ConversationService } from 'app/shared/services/conversation.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthService } from 'app/shared/services/auth.service';
import { UserTourService } from 'app/shared/services/user-tour.service';
import { ShepherdService } from 'angular-shepherd';

@Component({
	selector: 'selector-notification-dropdown',
	templateUrl: './notification-dropdown.component.html',
	styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdown implements AfterViewInit, OnInit, OnDestroy {
	private notifierSub: Subscription = null;
	private projectSelectedSub: Subscription = null;

	status;
	todays: any[] = [];
	past: any[] = [];
	unseenTotal: number = 0;
	localProjectId: string;
	newmessage: boolean = false;
	latestMessage: string = '';
	newChatMessage: boolean = false;
	isnotificationServiceMessage: boolean = false;
	isButtoneVisible: boolean = false;
	toastNotification: any;
	ngUnsubscribe = new Subject();
	isHrUser: boolean = false;
	isYslUser: boolean = false;
	userType: string;
	totalNotifications: number;
	currentUser: any;
	constructor(private router: Router,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private unseenNotifier: UnseenNotifierService,
		private permissionService: PermissionService,
		private overlayService: OverlayService,
		private conversationService: ConversationService,
		private workspaceService: WorkspaceService,
		private analyticService: AnalyticsService,
		private authService: AuthService,
		private userTourService: UserTourService,
		private shepherdService: ShepherdService) {
		this.currentUser = this.authService.getAuthUser();
	}

	ngOnInit() {
        // this.userTourService.tourCompleted.pipe(
		// 	takeUntil(this.ngUnsubscribe)
		//   ).subscribe(() => {
		//   });
		//check user type is
		//special case is notifications page
		//here we have to load based on selected project in dropdown
		if (window.location.pathname == '/account/notifications') {
			if ('project' in this.route.snapshot.queryParams) {
				this.localProjectId = this.route.snapshot.queryParams.project;
			}
		}
		//this.initUnseenTotal();
		this.getUnseenNotificationsCount();

		EmitterService.get(ActiveWorkspaceUpdatedEvent).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => {
			this.getUnseenNotificationsCount();
		});

		EmitterService.get(AllNotificationsReadEvent)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.unseenTotal = 0;
				this.getUnseenNotificationsCount();
			});

		EmitterService.get('popup.notification')
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(res => {
				this.toastNotification = this.notificationService.prepareNotification(res);
			})
	}

	getCurrentProjectId() {
		//edge case for notifications page
		if (window.location.pathname == '/account/notifications')
			return this.localProjectId;

		let { projectId = '' } = this.route.snapshot.params;
		if (projectId)
			return projectId;

		//this is used when inside edit project
		({ projectId = '' } = this.route.firstChild.snapshot.params);
		if (projectId)
			return projectId;

		return '';
	}

	private getUnseenNotificationsCount(callback: () => void | null = null) {
		this.status = "LOADING";
		let obs$: Observable<any> = null;

		const projectId = this.getCurrentProjectId();
		const activeWorkspace = this.workspaceService.getActiveWorkspace();

		if (projectId) {
			obs$ = this.notificationService.getUnseenProjectNotificationsCount(projectId);
		} else if (activeWorkspace?.id) {
			obs$ = this.notificationService.getUnseenWorkspaceNotificationsCount(activeWorkspace.id);
		} else {
			obs$ = this.notificationService.getAllUnseenNotificationsFromAPI();
		}

		obs$.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (data: UnseenTotal) => {
					this.unseenTotal = data.unseenCount;
					//this.handleUnseenUpdate(data);
					this.status = 'LOADED';
					this.getLatestNotifications();
					if (callback) {
						callback();
					}
				}
			}

			)
	}

	getLatestNotifications() {
		let projectId = this.getCurrentProjectId();
		this.status = "LOADING";
		this.notificationService.getNotifications(projectId, '', [], '', '', '', 0, 0,
			'')
			.subscribe({
				next: (result: any) => {
					if (result.data?.length > 0) {
						this.handleNotificationsUpdate(result.data);
					}
					this.status = 'LOADED';
				}, error: (err) => {
					this.status = 'ERROR';
				}
			});
	}

	ngAfterViewInit() {
		this.notifierSub = this.unseenNotifier.subscribe(data => {
			const oldTotal = this.unseenTotal;
			//this.handleUnseenUpdate(data);
			this.getUnseenNotificationsCount(() => {
				if (this.unseenTotal != oldTotal) {
					this.newmessage = true;
					this.conversationService.currentMessage.subscribe(val => this.newChatMessage = val);
					this.conversationService.notificationServiceMessage.subscribe(val => this.isnotificationServiceMessage = val);
					//this.getLatestNotifications();
				} else {
					this.newmessage = false;
				}
			});
		});

		this.projectSelectedSub = EmitterService.get('localProject.selected').subscribe(projectId => {
			this.localProjectId = projectId;
			this.getUnseenNotificationsCount();
		});
	}

	ngOnDestroy() {
		if (this.notifierSub)
			this.notifierSub.unsubscribe();

		if (this.projectSelectedSub)
			this.projectSelectedSub.unsubscribe();

		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	isVisited() {
		if (this.router.url == `/account/notifications`) {
			return "notification-dropdown-visited"
		}
		return ""
	}

	goToNotification() {
		sessionStorage.setItem('last_visited_url', this.router.url);
		const projectId = this.getCurrentProjectId();
		const params = {};
		if (projectId) {
			params['project'] = projectId;
		}
		this.router.navigate(['account', 'notifications'], { queryParams: params });
	}

	/*
	  private handleUnseenUpdate(data: any) {
		  if (!data)
			  return;
  
		  const projectId = this.getCurrentProjectId();
		  let total = 0;
		  for (let key in data) {
			  if (projectId && key != projectId)
				  continue;
			  const { unseenCount = 0 } = data[key];
			  total += unseenCount;
		  }
		  this.unseenTotal = total;
	  }
  
	 */

	private handleNotificationsUpdate(items) {
		// check loreal user notification
		if (this.isHrUser || this.isYslUser) {
			this.totalNotifications = items.length;
			console.log('this.totalNotifications', this.totalNotifications)
		}

		if (!items)
			return;

		const todayItems = items.filter(i => i.isToday && i.notificationDisplay && i.notificationDisplay.label);
		const pastItems = items.filter(i => !i.isToday && i.notificationDisplay && i.notificationDisplay.label);
		this.todays = todayItems.slice(0, 4);
		this.past.length = 0;

		if (this.todays.length < 4) {
			this.past = pastItems.slice(0, 4 - this.todays.length);
		}

		this.notificationService.changeMessage(this.todays.length > 0 ?
			this.todays[0].notificationDisplay?.label : (this.past.length ? this.past[0].notificationDisplay?.label : ''));
		if (this.isnotificationServiceMessage && !this.newChatMessage) {
			this.notificationService.currentMessage.subscribe(message => this.latestMessage = message);
			//this.overlayService.showSuccessWithTimeout(this.latestMessage);
		} else {
			this.newmessage = false;
		}
	}

	private initUnseenTotal() {
		if (!this.permissionService.hasAuthority(Authority.N, null))
			return;

		this.notificationService.getAllUnseenNotificationsFromAPI().subscribe(
			(data: UnseenTotal) => {
				this.unseenTotal = data.unseenCount;
			}
		);
	}

	buzzerOff() {
		this.shepherdService.complete();
        // this.userTourService.tourCompleted.pipe(
		// 	takeUntil(this.ngUnsubscribe)
		//   ).subscribe(() => {
		// });
		// this.shepherdService.complete();
		this.workspaceService.currentStatus.subscribe(val => this.isButtoneVisible = val)
		this.newmessage = false;
	}

	markNotificationsAsRead() {
		const projectId = this.getCurrentProjectId();
		this.notificationService.markNotificationsAsSeen(projectId)
			.subscribe(() => {
				EmitterService.get(AllNotificationsReadEvent).emit({ projectId });
			});
	}


}
