import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from "../../shared/services/subscription.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubscriptionConfirmComponent } from "../../shared/components/subscription-confirm/subscription-confirm.component";
import { SubscriptionPlan } from "../build-plan-circle/build-plan-circle.component";

@Component({
    selector: 'mtm-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

    private additionalDiscount: number = 15;
    private subscriptionPlan: SubscriptionPlan;
    constructor(public subscriptionService: SubscriptionService,
        private modalService: NgbModal, public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.subscriptionPlan = this.subscriptionService.subscriptionPlan;
    }

    openPaymentModal(option: number) {

        let data;
        if (option == 1) {
            data = {
                additionalDiscount: this.additionalDiscount,
                subscriptionPlan: this.subscriptionPlan
            };
        }
        else if (option == 2) {
            data = {
                additionalDiscount: -1,
                subscriptionPlan: this.subscriptionPlan
            };
        }
        let paymentModal = this.modalService.open(SubscriptionConfirmComponent, {
            size: 'lg',
            backdrop: 'static',
            windowClass: 'subscription-confirm-modal'

        });
        paymentModal.componentInstance.setParams(data);
    }
    onApplyNowClick($event) {
        this.activeModal.close(true);
    }

}
