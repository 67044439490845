<div class="card vh-100">
    <div class="row mt-3 ml-1 mr-1">
        <div class="col d-flex justify-content-center align-items-center p-0">
            <button (click)="addNewUser()"
                class="d-flex justify-content-center align-items-center add-new-user f-14 _700">
                <div class="circle-div">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <span class="ml-1" [style.color]="'#5F5F89'">{{'Add New User' | translate }}</span>
            </button>
        </div>
        <div class="col d-flex justify-content-center align-items-center p-o">
            <button (click)="createTeam()"
                class="d-flex justify-content-center align-items-center add-new-user f-14 _700">
                <div class="circle-div">
                    <div>
                        <div>
                            <img class="d-flex justify-content-center align-items-center" width="16px" height="12px"
                                src="../../../../../assets/svg/user-group.svg" alt="">
                        </div>
                        <div>
                            <i class="fa fa-plus d-flex justify-content-center align-items-center"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <span class="ml-1" [style.color]="'#5F5F89'"> {{'team_createTeam' | translate }}</span>
            </button>
        </div>
        <div class="col d-flex justify-content-center align-items-center p-0">
            <button class="d-flex justify-content-center align-items-center add-new-user f-14 _700" disabled>
                <div class="circle-div">

                    <img width="22px" height="12px" src="../../../../../assets/svg/user-group.svg" alt="">
                    <!-- <i class="fa fa-plus" aria-hidden="true"></i> -->
                </div>
                <span class="ml-1" [style.color]="'#5F5F89'">{{'team_importTeam' | translate }}</span>
            </button>
        </div>
    </div>
    <!-- <div class="mt-3 d-flex align-items-center">
        <div class="ml-3 mr-3">
            <select name="" id="" class="view" [(ngModel)]="viewMode">
                <option [value]="'userView'">Users view</option>
                <option [value]="'teamView'">Team view</option>
            </select>
        </div>
        <div class="view" *ngIf="isUserView">
            <select class="view" name="teamSelect" id="teamSelect" (change)="onTeamSelect($event)">
                <option value="null">Select Team</option>
                <option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</option>
            </select>
        </div>
        <div class="view ml-3" *ngIf="!isUserView">
            <select class="view" name="teamSelect" id="teamSelect" (change)="onTeamSelect($event)">
              <option value="null">Select Team</option>
              <option class="f-12 _700" value="Users view">Users view</option>
              <option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</option>
            </select>
          </div>
    </div> -->
    <!-- user view -->
    <!-- <div class="row ml-1" *ngIf="isUserView">
        <div class="col" [style.max-width]="'220px'" [style.min-width]="'220px'"><i [style.color]="'#898C98'"
                class="fa fa-caret-down pointer mr-1" aria-hidden="true"></i><span class="f-14 _700"
                [style.color]="'#5F5F89'">Name</span></div>
        <div class="col" [style.max-width]="'250px'" [style.min-width]="'250px'"><i [style.color]="'#898C98'"
                class="fa fa-caret-down pointer mr-1" aria-hidden="true"></i><span class="f-14 _700"
                [style.color]="'#5F5F89'">Email</span></div>
        <div class="col" [style.max-width]="'120px'" [style.min-width]="'120px'"><i [style.color]="'#898C98'"
                class="fa fa-caret-down pointer mr-1" aria-hidden="true"></i><span class="f-14 _700"
                [style.color]="'#5F5F89'">Job</span></div>
        <div class="col" [style.max-width]="'200px'" [style.min-width]="'200px'"><i [style.color]="'#898C98'"
                class="fa fa-caret-down pointer mr-1" aria-hidden="true"></i><span class="f-14 _700"
                [style.color]="'#5F5F89'">Role</span></div>
        <div class="col" [style.max-width]="'150px'" [style.min-width]="'150px'"><i [style.color]="'#898C98'"
                class="fa fa-caret-down pointer mr-1" aria-hidden="true"></i><span class="f-14 _700"
                [style.color]="'#5F5F89'">Team</span></div>
        <div class="col p-0 d-flex align-items-center justify-content-center" [style.max-width]="'100px'"
            [style.min-width]="'100px'"><i [style.color]="'#898C98'" class="fa fa-caret-down pointer mr-1"
                aria-hidden="true"></i><span class="f-14 _700" [style.color]="'#5F5F89'">Decision Maker</span></div>
        <div class="col"  [style.max-width]="'180px'" [style.min-width]="'180px'"><i [style.color]="'#898C98'" class="fa fa-caret-down pointer mr-1"
                aria-hidden="true"></i><span class="f-14 _700" [style.color]="'#5F5F89'">Activate</span></div>
    </div> -->
    <!-- <div *ngIf="isUserView" class="w-100 horizontal-line"></div>
    <div *ngIf="isUserView" class="scroller" [style.height]="'650px'">
        <div class="row ml-1" [style.min-height]="'55px'" *ngFor="let user of users;let i=index">
            <div class="col d-flex align-items-center" [style.max-width]="'220px'" [style.min-width]="'220px'">
                <img *ngIf="user.avatarUrl" class="d-flex justify-content-center align-items-center user-img"
                    [src]="user.avatarUrl" alt="">
                <span *ngIf="!user.avatarUrl"
                    class="d-flex justify-content-center align-items-center user-img text-uppercase _700"
                    [style.backgroundColor]="timelineService.getColorFromName(user.fullName)">
                    {{user.fullName | slice:0:2}}
                </span>
                <span
                    class="d-flex justify-content-center align-items-center ml-2 f-14 _700">{{user.fullName}}</span>
            </div>
            <div class="col d-flex align-items-center" [style.max-width]="'250px'" [style.min-width]="'250px'"><span
                    class="f-12 _400">{{user.email}}</span></div>
            <div class="col d-flex align-items-center" [style.max-width]="'120px'" [style.min-width]="'120px'"><span
                    class="f-12 _400">{{user.jobTitle}}</span></div>
            <div class="col d-flex align-items-center" [style.max-width]="'200px'" [style.min-width]="'200px'">
                <select class="view" name="teamSelect" id="roleSelect" (change)="changeRole($event)">
                    <option value="null">Select Role</option>
                    <option *ngFor="let role of UserRole" [value]="user.gloablRole">{{user.globalRole }}</option>
                </select>
            </div>
            <div class="col d-flex align-items-center" [style.max-width]="'150px'" [style.min-width]="'150px'"><span
                class="f-12 _400">{{user.teamName}}</span></div>
            <div class="col p-0 d-flex align-items-center" [style.max-width]="'100px'" [style.min-width]="'100px'">
                <div class="checkbox-div">
                    <label class="mtm-checkbox mtm-checkbox-green m-0">
                        <input (click)="toggleCheckbox()" class="form-check-input" type="checkbox"
                            [checked]="isChecked">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="col d-flex align-items-center" [style.max-width]="'180px'" [style.min-width]="'180px'">
                <div class="d-flex" [style.max-width]="'35px'" [style.min-width]="'35px'">
                    <div class="checkbox-div">
                        <label class="mtm-checkbox mtm-checkbox-green m-0">
                            <input (click)="toggleActivation(user)" class="form-check-input" type="checkbox"
                                [checked]="user.isActivated">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col" [style.max-width]="'130px'" [style.min-width]="'130px'">
                    <div class="row f-10 _400">Last Seen:</div>
                    <div class="row f-10 _400">{{user.lastSeen| date :'medium'}}</div>
                </div>
                <div class="col">
                    <i (click)="removeUser(user)" class="fa fa-trash pointer" [style.color]="'#E45933'"
                        aria-hidden="true"></i>
                </div>
            </div>
            <div *ngIf="i >= 0" class="w-100 horizontal-line" [style.backgroundColor]="'#5F5F89'"
                [style.max-width]="'98.6%'"></div>
        </div>
    </div> -->

    <!-- team view -->
    <div class="teams-col d-flex flex-column mt-3">
        <div class="p-2" style="flex:1;">
            <ag-grid-angular [rowData]="teams" [debounceVerticalScrollbar]="true" [rowHeight]="54"
                [defaultColDef]="defaultColDef" class="ag-theme-alpine" [headerHeight]="54"
                [columnDefs]="teamColDefs" [enableCellTextSelection]="true" [ensureDomOrder]="true"
                style="width: 100%; height: 100%;" (rowClicked)="editTeam($event)" [context]="teamTableContext"
                [components]="teamComponents">
            </ag-grid-angular>
        </div>
    </div>
</div>