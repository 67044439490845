import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'role-cell',
    templateUrl: './role-cell.component.html',
    styleUrls: ['./role-cell.component.scss']
})
export class RoleCellComponent implements ICellRendererAngularComp, OnInit {
    @ViewChild('roleCell', { static: false }) roleCell: ElementRef;
    params: any;

    constructor() { }

    ngOnInit(): void { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }

    dropdownOpen(event) {
        if (event.isOpen) {
            let row = this.roleCell.nativeElement.closest('.ag-row');
            row.classList.add("row-open");
        } else {
            let row = this.roleCell.nativeElement.closest('.ag-row');
            row.classList.remove("row-open");
        }
    }
}

