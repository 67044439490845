import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentService } from "../shared/services/payment.service";


@Component({
	templateUrl: 'success.component.html',
	styleUrls: ['./success.component.scss'],

})
export class SuccessComponent implements OnInit {

	successful: boolean = false
	completed: boolean = false

	constructor(private router: Router, private paymentService: PaymentService) {
	}

	ngOnInit() {
		let companyId = this.router.parseUrl(this.router.url).queryParams['companyId'];
		this.checkConnectedAccountVerification(companyId, true);
		console.log("companyId:", companyId);
	}

	checkConnectedAccountVerification(companyId: any, sendEmail: boolean) {
		this.paymentService.checkConnectedAccountVerification(companyId, sendEmail).subscribe(verificationStatus => {
			console.log("verificationStatus: ", verificationStatus);
			this.successful = true
			this.completed = verificationStatus === "SUCCESS"
		})
	}
}
