import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { arrayRemove, arrayUpsert, setLoading } from '@datorama/akita';
import { JComment } from '../../../interface/comment';
import { JProject } from '../../../interface/project';
import { DateUtil } from '../../../project/utils/date';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { ProjectStore } from './project.store';
import { Task, TasksSearchParams, TasksSearchResult } from 'app/shared/interfaces';
import { ApiService } from 'app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class KanbanProjectService {
  baseUrl: string;
  public getUpdatedTask: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setNextTask = new BehaviorSubject({isScroll:false,status:'',count:0})
  getNextTask = this.setNextTask.asObservable();
  public setProjectId = new BehaviorSubject<any>('');

  public selectedTasks = new BehaviorSubject<string[]>([]);
  public selectedTeams = new BehaviorSubject<string[]>([]);
  public selectedDate = new BehaviorSubject<string>('');
//   public setView = new BehaviorSubject({kanban: true,calendar:false})
//   getView = this.setView.asObservable();
private viewSubject = new BehaviorSubject<{ kanban: boolean, calendar: boolean,taskList:boolean }>({ kanban: true, calendar: false  ,taskList: false});

setView(view: { kanban: boolean, calendar: boolean ,taskList:boolean}) {
  this.viewSubject.next(view);
}

getView() {
  return this.viewSubject.asObservable();
}
//   Combine BehaviorSubjects and map to return only id for brands, workspaces, and projects
  public selectedTeamsAndTasks: Observable<{ tasks: string[] | string; teams: string[] | string; date:string | string}> = combineLatest([
    this.selectedTasks.asObservable(),
    this.selectedTeams.asObservable(),
	this.selectedDate.asObservable()
  ]).pipe(
    map(([tasks, teams, date]) => ({
      tasks: tasks || null,
      teams: teams || null,
	  date: date || null
    }))
  );

  constructor(
    private _http: HttpClient, 
    private _store: ProjectStore,
    private apiService: ApiService) {
    this.baseUrl = '/assets/data/project.json';
  }

  setLoading(isLoading: boolean) {
    this._store.setLoading(isLoading);
  }

	searchTasks(params: TasksSearchParams){
		let endpoint = `/api/tasks?`;
		const args = [];
		if (params.campaignId) {
			args.push(`campaignId=${params.campaignId}`);
		}
		if (params.workspaceIds) {
			args.push(`workspaceIds=${params.workspaceIds}`);
		}
		if (params.milestoneIds) {
			args.push(`milestoneIds=${params.milestoneIds}`);
		}
		if (params.projectId) {
			args.push(`projectIds=${params.projectId}`)
		}
		if (params.sectionId) {
			args.push(`projectSectionIds=${params.sectionId}`);
		}
		if (params.subsectionId) {
			args.push(`projectSubSectionIds=${params.subsectionId}`);
		}
		if (params.sectionNames?.length) {
			args.push(`projectSectionNames=${params.sectionNames}`);
		}
		if (params.subSectionNames?.length) {
			args.push(`projectSubSectionNames=${params.subSectionNames}`);
		}
		if (params.taskId) {
			args.push(`id=${params.taskId}`)
		}
		if (params.topicId) {
			args.push(`topicId=${params.topicId}`);
		}
		if (params.optionId) {
			args.push(`optionId=${params.optionId}`);
		}
		if (params.assignees) {
			// let assignee = encodeURIComponent(params.assignees);
			args.push(`assignees=${params.assignees}`);
		}
		if (params.creator) {
			args.push(`creators=${params.creator}`)
		}
		if (params.collaborators) {
			args.push(`collaborators=${params.collaborators}`)
		}

		if(!!params.statusSet?.length) {
			args.push(`statusSet=${params.statusSet}`)
		}

		if (params.page) {
			args.push(`page=${params.page}`);
		}
		if (params.limit) {
			args.push(`limit=${params.limit}`);
		}
		if (params.from) {
			let from = encodeURIComponent(params.from);
			args.push(`from=${from}`);
		}
		if (params.to) {
			let to = encodeURIComponent(params.to);
			args.push(`to=${to}`);
		}
		if (params.sortBy) {
			args.push(`sortBy=${params.sortBy}`);
		}
		if (params.dateRangeType) {
			args.push(`dateRangeType=${params.dateRangeType}`);
		}
		endpoint += args.join('&');
    return this.apiService.taskhttpGet<TasksSearchResult>(endpoint);
	}

  updateProject(project: Partial<JProject>) {
    this._store.update((state) => ({
      ...state,
      ...project
    }));
  }

  updateIssue(issue: Task) {
    issue.updatedAt = DateUtil.getNow();
    this._store.update((state) => {
      const data = arrayUpsert(state.data, issue.id, issue);
      return {
        ...state,
        data
      };
    });
  }

  deleteIssue(issueId: string) {
    this._store.update((state) => {
      const issues = arrayRemove(state.issues, issueId);
      return {
        ...state,
        issues
      };
    });
  }

  updateIssueComment(issueId: string, comment: JComment) {
    const allIssues = this._store.getValue().issues;
    const issue = allIssues.find((x) => x.id === issueId);
    if (!issue) {
      return;
    }

    // const comments = arrayUpsert(issue.comments ?? [], comment.id, comment);
    // this.updateIssue({
    //   ...issue,
    //   comments
    // });
  }
}
