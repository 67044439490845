<div class="d-flex align-items-center modal-header pl-3 pr-3 pt-2 pb-2">
	<h5 class="modal-title">
		<b fileNameDisplay [fileName]="file?.displayName || file?.name"></b>
	</h5>
	<button id="login-with-email-close-btn" type="button" class="close" aria-label="Close"
		(click)="activeModal.close('')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="container mb-3 mt-3">
	<mtm-media-player [item]="file" class="w-100 h-100" [mode]="'view'" [width]="'100%'" [height]="'100%'"
		[autoPlayVideo]="true">
	</mtm-media-player>
</div>