import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { ProposalFileService } from '../../../../shared/services/proposal-services/proposalfile.service';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as moment from 'moment';
import 'moment-timezone';
import { SignedUrlService } from 'app/shared/services/signedurl.service';
import { Observable, Subject } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { takeUntil } from 'rxjs/operators';
import DOMPurify from 'dompurify';



@Component({
	selector: 'mtm-project-proposal-brief',
	templateUrl: './project-proposal-brief.component.html',
	styleUrls: ['./project-proposal-brief.component.scss']
})
export class ProjectProposalBriefComponent implements OnInit, OnDestroy {
	files: any[] = [];
	filesB: any[] = [];
	proposalId;
	invitationProjectId;
	sourceIdA = "c157e98d-c973-441f-8e42-1986e821b310";
	sourceIdB = "e52d7b9c-3d8e-488d-be54-610be927436f";
	selectedFile: any = null;
	isSettingDuration: boolean = false;
	ngUnsubscribe = new Subject();

	constructor(public service: ProjectService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private proposalFileService: ProposalFileService,
		private sane: DomSanitizer,
		private signedUrlService: SignedUrlService) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}


	ngOnInit() {
		this.proposalId = this.activatedRoute.parent.snapshot.params['proposalId'];
		this.invitationProjectId = this.activatedRoute.parent.snapshot.params['projectId'];
		this.getFiles();
	}

	goToTeam() {
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'team']);
	}

	getFiles() {
		this.getRequireParamsForFiles().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			let sectionId = res.sectionId;
			let subSectionId = res.subSectionId;

			this.getFilesFromAPI(sectionId, subSectionId, this.sourceIdA).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(files => this.files = files);
			this.getFilesFromAPI(sectionId, subSectionId, this.sourceIdB).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(files => this.filesB = files);
		})
	}

	getRequireParamsForFiles(): Observable<any> {
		return new Observable(observer => {
			this.service.getSectionIdBySectionName(this.invitationProjectId, "PRODUCTION_BRIEF").pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(sectionId => {
				this.service.getSubSectionIdByName(this.invitationProjectId, sectionId, "UPLOAD_BRIEF").pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(subSectionId => {
					observer.next({
						sectionId: sectionId,
						subSectionId: subSectionId
					});
					observer.complete();
				}, () => observer.error());
			}, () => observer.error());
		})
	}

	private getFilesFromAPI(sectionId, subSectionId, sourceId): Observable<any> {
		return new Observable(observer => {
			this.proposalFileService.getProposalBriefFiles(this.invitationProjectId, sectionId, subSectionId, sourceId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				files => {
					if (!files || !Array.isArray(files)) {
						observer.error();
						return;
					}

					files.map((f) => {
						f.uploadedDate = this.getUploadedDate(f.timeCreated);
						f.type = this.getIconUrl(f);
						f.displaySize = this.formatSizeUnits(f.size);
						f.isShow = false;
						f.isPDF = f.contentType.indexOf('pdf') >= 0;
						let safeSignedURL = DOMPurify.sanitize(f.signedURL);
						f.safeSignedURL = this.sane.bypassSecurityTrustResourceUrl(safeSignedURL);
						f.isMedia = HelperService.isMediaFile(f);
					});

					observer.next(files);
					observer.complete();
				}, err => observer.error()
			);
		})
	}

	onSwitchShowFile(file): boolean {
		this.files.forEach(item => item.id != file.id ? item.isShow = false : null);
		this.filesB.forEach(item => item.id != file.id ? item.isShow = false : null);

		if (!this.selectedFile || file.isShow == false) {
			file.isShow = true;
			this.selectedFile = null;
			this.isSettingDuration = true;
			setTimeout(() => {
				this.selectedFile = Object.assign({}, {}, { ...file });
				this.isSettingDuration = false;
			}, 3579);
		} else if (this.selectedFile && this.selectedFile.id == file.id) {
			file.isShow = false;
			this.selectedFile = null;
			this.isSettingDuration = false;
		}

		return false;
	}

	private getIconUrl(file: any) {
		if (file.contentType)
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/' + file.contentType.substr(file.contentType.indexOf('/') + 1) + '.svg'));
		else
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/' + file.displayName.substr(file.displayName.lastIndexOf('.') + 1, 3) + '.svg'));
	}

	private formatSizeUnits(bytes) {
		if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; }
		else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; }
		else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; }
		else if (bytes > 1) { bytes = bytes + ' bytes'; }
		else if (bytes == 1) { bytes = bytes + ' byte'; }
		else { bytes = '0 byte'; }
		return bytes;
	}
	private getUploadedDate(time: number): Date {
		return moment(time).toDate();
	}

	downloadFile(file) {
		this.signedUrlService.downloadSignedUrl(file, {
			control: '.media-downloader-control'
		});
	}
}
