import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserTour } from '../interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsertourComponent } from 'app/useronboardingtool/usertour/usertour.component';

@Injectable({
  providedIn: 'root'
})

export class UserTourToolService {

  // Set initial value for BehaviorSubject
  private initialUserTour: UserTour = {
    title: '',
    subTitle: '',
    isButton: false,
    buttonTitle: '',
    headerIcon: '',
    selector : '',
    highlightClass: '',
    placement: ''
  };

  // Create BehaviorSubject with initial value
  private setTourData = new BehaviorSubject<UserTour>(this.initialUserTour);

  // Expose observable for subscribers
  public getTourData: Observable<UserTour> = this.setTourData.asObservable();

  constructor(
    private modalService: NgbModal
  ) { }

  // Method to update timeline data
  public updateTimelineData(data: UserTour): void {
    if (data) {
      this.setTourData.next(data);
      let element: HTMLElement | null = null;

      // Check if selector is an ID or a class
      if (data.selector.startsWith('#')) {
        // If selector starts with '#', it's an ID
        element = document.getElementById(data.selector.substring(1));
      } else {
        // Otherwise, assume it's a class
        element = document.querySelector(data.selector) as HTMLElement;
      }
      console.log('element', element)
     

      // const element = document.querySelector(data.selector) as HTMLElement;
      let modal = this.modalService.open(UsertourComponent, { backdrop: false, windowClass: 'modal-placement', keyboard: false });
      modal.componentInstance.data = data;
      modal.componentInstance.highlightedElement = element;

// modal position 
  // Adjust the position based on the placement
  const rect = element.getBoundingClientRect();
  const top = rect.top + window.scrollY;
  const left = rect.left + window.scrollX;
  // Adjust the position based on the placement
  let modalTop = top;
  let modalLeft = left;
  
  // switch (data.placement) {
  //   case 'top':
  //     modalTop -= modal.componentInstance.elementRef.nativeElement.offsetHeight + 10; // Adjust 10 pixels to provide some spacing
  //     break;
  //   case 'bottom':
  //     modalTop += rect.height + 10; // Adjust 10 pixels to provide some spacing
  //     break;
  //   case 'left':
  //     modalLeft -= modal.componentInstance.elementRef.nativeElement.offsetWidth + 10; // Adjust 10 pixels to provide some spacing
  //     break;
  //   case 'right':
  //     modalLeft += rect.width + 10; // Adjust 10 pixels to provide some spacing
  //     break;
  //   default:
  //     // Default to 'right' placement
  //     modalLeft += rect.width + 10; // Adjust 10 pixels to provide some spacing
  //     break;
  // }

  modal.componentInstance.topPosition = modalTop;
  modal.componentInstance.leftPosition = modalLeft;
  modal.componentInstance.placement = data.placement;

 
    } else {
      console.error(`Element with selector '${data.selector}' not found`);
    }
  }
}
