<div class="mtm-inviter-dropdown mtm-inviter-dropdown-component d-flex flex-column"
	[ngClass]="{ 'user-exists': modelValue?.length }">
	<div class="d-flex align-items-center flex-wrap p-1 selected-users-container"
		*ngIf="modelValue?.length && !hideModelPreview && !asClassicDropdown">
		<div class="selected-users pl-2 pr-2 pt-1 pb-1 mr-1 mb-1 d-flex align-items-center"
			*ngFor="let user of modelValue; trackBy user?.email">
			<div class="d-inline-flex align-items-center pointer">
				<mtm-user-profile-picture *ngIf="user.type !== 'anonim'" class="d-inline-flex mr-2 pointer"
					[user]="user" [username]="user?.email || user" [height]="32" [width]="32"
					[forceLoadProfile]="forceLoadProfile">
				</mtm-user-profile-picture>
				<div class="d-flex flex-column pointer">
					<label class="m-0 _600 pointer">{{ user?.fullName || user?.email }}</label>
				</div>
			</div>
			<i class="ml-2 fa fa-times pointer" (click)="removeUser(user)"></i>
		</div>
	</div>
	<div class="d-inline-block" *ngIf="singleInput">
		<div *ngIf="asClassicDropdown">
			<input [(ngModel)]="emailValue" (keyup)="suggestUsers($event)" class="d-block form-control search-box p-2"
				placeholder="{{inputPlaceholder || ('addPeople' | translate) }}"
				(blur)="selectEmail(emailValue, true)" />
		</div>
		<div *ngIf="!asClassicDropdown">
			<input *ngIf="!modelValue?.length" [(ngModel)]="emailValue" (keyup)="suggestUsers($event)"
				class="d-block form-control search-box p-2"
				placeholder="{{inputPlaceholder || ('addPeople' | translate) }}" />
		</div>
	</div>
	<div class="d-inline-block" *ngIf="!singleInput">
		<input [(ngModel)]="emailValue" (keyup)="suggestUsers($event)" class="d-block form-control search-box p-2"
			placeholder="{{inputPlaceholder || ('addPeople' | translate) }}" />
	</div>
	<div class="invite-suggestions-container">
		<div *ngIf="suggestedUsers?.length && emailValue" class="invite-suggestions">
			<div class="p-2" *ngFor="let user of suggestedUsers; trackBy user?.email pointer"
				(click)="suggestedUserSelected(user)">
				<div class="d-flex align-items-center pointer">
					<mtm-user-profile-picture class="d-inline-flex mr-2 pointer" [username]="user.email"
						[height]="iconSize" [user]="user" [width]="iconSize" [forceLoadProfile]="forceLoadProfile">
					</mtm-user-profile-picture>
					<div class="d-flex align-items-center pointer">
						<div class="d-flex flex-column mr-2">
							<label class="m-0 _600 pointer name" *ngIf="user.fullName" [title]="user.fullName">{{ user.fullName}}</label>
							<span class="email" [title]="user.email">{{user.email}}</span>
							<span class="status text-uppercase">{{user.activationStatus|translate}}</span>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!suggestedUsers?.length && emailValue && !internalUsersOnly" class="invite-suggestions">
			<div class="p-2 pointer d-flex align-items-center" (click)="selectEmail(emailValue)">
				<i class="fa fa-envelope mr-2"></i>
				<span class="d-block">{{emailValue}}</span>
			</div>
		</div>
	</div>
</div>