import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SubscriptionService } from "../../../services/subscription.service";
import { of, Subject } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentMethodService } from "app/shared/services/payment-method.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentMethodModalComponent } from "app/shared/components/payment-method-modal/payment-method-modal.component";
import { CompanyService } from "app/shared/services/company.service";
import { AuthService } from "app/shared/services/auth.service";
import { forkJoin } from "rxjs";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { UpgradePlanStateChanged } from "../../models/upgrade-tab.model";
import { EmitterService } from "app/shared/services/emitter.service";
import { ValidatorService } from "app/shared/services/validator.service";
import { CountryCodes } from "app/shared/components/mtm-tel-input/models/country-codes.model";
import { NotificationService } from "app/shared/services/notification.service";
import { USAStates } from "app/shared/models/usa-states.model";

@Component({
	selector: 'mtm-subscription-upgrade-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class SubscriptionUpgradePaymentComponent implements OnInit, OnDestroy {
	paymentForm: UntypedFormGroup;
	methods: any[] = [];
	availableMethods: string[] = ['card', 'sepa_debit'];
	private ngUnsubscribe = new Subject();
	private company: any;
	isTaxIdValid: boolean = true;
	listCountryCodes: any[] = new CountryCodes().allCountries;
	subscriptionInfo: any;
	modelOpen: boolean = true;
	trialEnded: boolean = true;
	subscription: any;
	isFreePlan: boolean = true;
	private usaStates: any[] = new USAStates().states;
	accountStatus: string;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private modalService: NgbModal,
		private subscriptionService: SubscriptionService,
		private paymentMethodService: PaymentMethodService,
		private companyService: CompanyService,
		private overlayService: OverlayService,
		private validatorService: ValidatorService,
		private translatePipe: TranslatePipe,
		private notificationService: NotificationService,
	) {
		this.getSubscriptionInfo();
	}

	ngOnInit() {
		this.buildForm();
		this.loadInitialData();

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private loadInitialData() {
		const authUser = this.authService.getAuthUser();
		forkJoin(
			this.companyService
				.getCompanyById(authUser.company.id)
				.pipe(
					map((res) => res),
					catchError(e => of({ error: true }))
				),
			this.paymentMethodService
				.getUserPaymentMethods()
				.pipe(
					map((res) => res),
					catchError(e => of({ error: true }))
				)
		).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this.company = data[0];
			if (!data[0].error) {
				this.handleLoadedCompanyData(data[0]);
			}
			if (!data[1].error) {
				this.handleLoadedPaymentMethods(data[1]);
			}
			this.buildForm(true);
		});
	}

	buildForm(withData: boolean = false) {
		this.paymentForm = this.fb.group({
			firstName: [
				this.subscriptionService.upgradeData.invoice.firstName,
				[Validators.required]
			],
			lastName: [
				this.subscriptionService.upgradeData.invoice.lastName,
				[Validators.required]
			],
			companyName: [
				this.subscriptionService.upgradeData.invoice.companyName,
				[Validators.required]
			],
			address: [
				this.subscriptionService.upgradeData.invoice.address,
				[Validators.required]
			],
			city: [
				this.subscriptionService.upgradeData.invoice.city,
				[Validators.required]
			],
			zipCode: [
				this.subscriptionService.upgradeData.invoice.zipCode,
				[Validators.required]
			],
			country: [
				this.subscriptionService.upgradeData.invoice.country,
				[Validators.required]
			],
			state: [
				this.subscriptionService.upgradeData.invoice.state,
				[Validators.required]
			],
			taxIdNumber: [
				this.subscriptionService.upgradeData.invoice.taxIdNumber,
				[Validators.required]
			],
			billingCcEmail: [
				this.subscriptionService.upgradeData.invoice.billingCcEmail,
				[Validators.required, Validators.email]
			],
			paymentMethodId: [
				'', [Validators.required]
			]
		})

		if (withData) {
			this.paymentForm.valueChanges.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				res => {
					this.subscriptionService.upgradeData.invoice = Object.assign(this.subscriptionService.upgradeData.invoice,
						{
							firstName: res.firstName,
							lastName: res.lastName,
							companyName: res.companyName,
							address: res.address,
							city: res.city,
							zipCode: res.zipCode,
							country: res.country,
							state: res.state,
							taxIdNumber: res.taxIdNumber,
							billingCcEmail: res.billingCcEmail
						}
					);
				},
				err => {
					this.overlayService.showError(this.translatePipe.transform('overlayErrorOccurred'), 'Error');
				}
			);
		}
	}

	private loadPaymentMethods() {
		this.paymentMethodService.getUserPaymentMethods()
			.pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe((methods: any) => {
				this.handleLoadedPaymentMethods(methods);
			});
	}

	private handleLoadedCompanyData(company: any) {
		let invoice = this.subscriptionService.upgradeData.invoice;
		let { billingAddress, billingDetails } = company;
		if (billingAddress) {
			invoice.address = billingAddress.address;
			invoice.city = billingAddress.city;
			invoice.zipCode = billingAddress.postalCode;
			invoice.country = billingAddress.country;
			invoice.state = billingAddress.state;
		}
		invoice.taxIdNumber = company.taxId;
		if (billingDetails) {
			invoice.companyName = billingDetails.companyName;
			if (billingDetails.accountRepName) {
				const names = billingDetails.accountRepName.split(' ');
				if (names.length > 0)
					invoice.firstName = names[0];
				if (names.length > 1) {
					names.shift();
					invoice.lastName = names.join(' ');
				}
			}
		}
		invoice.billingCcEmail = company.billingEmail;
	}

	private handleLoadedPaymentMethods(methods: any) {
		this.methods = [];
		let cardCounter = 0, sepaCounter = 0;
		if (methods) {
			methods.forEach(method => {
				if (this.availableMethods.indexOf(method.type) == -1)
					return;
				const info: any = {
					type: method.type,
					id: method.id
				};
				if (method.type == 'card') {
					cardCounter++;
					info.label = `Card ${cardCounter}`;
					info.card = {
						brand: method.card.brand,
						last4: method.card.last4,
						expMonth: method.card.expMonth,
						expYear: method.card.expYear
					};
				}
				if (method.type == 'sepa_debit') {
					sepaCounter++;
					info.label = `Sepa debit ${sepaCounter}`;
					info.sepa = {
						last4: method.sepaDebit.last4,
						country: method.sepaDebit.country,
						bankCode: method.sepaDebit.bankCode
					};
				}

				this.methods.push(info);
			});
		}
	}

	addPaymentMethod() {
		if (this.paymentForm.controls.billingCcEmail.status === 'INVALID') {
			this.overlayService.showError(this.translatePipe.transform('invalidBillingCcEmail'), 'Error');
		} else {
			const modalRef = this.modalService.open(PaymentMethodModalComponent, {
				size: 'sm',
				backdrop: 'static',
				keyboard: false
			});
			modalRef.componentInstance.billingEmail = this.subscriptionService.upgradeData.invoice.billingCcEmail;
			let billingName = this.subscriptionService.upgradeData.invoice.firstName;
			if (this.subscriptionService.upgradeData.invoice.lastName)
				billingName += ' ' + this.subscriptionService.upgradeData.invoice.lastName;
			modalRef.componentInstance.billingName = billingName;
			modalRef.result.then(() => {
				this.loadPaymentMethods();
			});
		}
	}

	private prepareCompanyForUpdate() {
		const invoiceDetail = this.subscriptionService.upgradeData.invoice;
		this.company.billingAddress = {
			...this.company.billingAddress,
			address: invoiceDetail.address,
			city: invoiceDetail.city,
			postalCode: invoiceDetail.zipCode,
			country: invoiceDetail.country,
			state: invoiceDetail.state
		};
		this.company.taxId = invoiceDetail.taxIdNumber;
		this.company.billingDetails = {
			...this.company.billingDetails,
			accountRepName: invoiceDetail.firstName + ' ' + invoiceDetail.lastName,
			companyName: invoiceDetail.companyName
		}
		this.company.billingEmail = invoiceDetail.billingCcEmail;
	}

	private getPlanUpdates(): any[] {
		const updates = [];
		const upgradeData = this.subscriptionService.upgradeData;
		const currentPlan = upgradeData.plan;
		const billingInfo = upgradeData.billingInfo;
		updates.push({
			priceId: currentPlan.basePrice[upgradeData.billingType].priceId,
			quantity: currentPlan.minUsers + billingInfo.extraUserCount
		});
		if (billingInfo.extraActiveStorageCount) {
			const addOn = upgradeData.plansInfo.addon.find(addon => addon.code === 'ACTIVE_STORAGE_PLAN');
			updates.push({
				priceId: addOn.basePrice[upgradeData.billingType].priceId,
				quantity: billingInfo.extraActiveStorageCount
			});
		}
		if (billingInfo.extraArchivalStorageCount) {
			const addOn = upgradeData.plansInfo.addon.find(addon => addon.code === 'ARCHIVE_STORAGE_PLAN');
			updates.push({
				priceId: addOn.basePrice[upgradeData.billingType].priceId,
				quantity: billingInfo.extraArchivalStorageCount
			});
		}
		return updates;
	}

	paymentProcessing: boolean = false;
	submitData() {
		if (!this.paymentForm.valid) {
			this.overlayService.showError(this.translatePipe.transform('overlayFillMandatory'), "Error");
			return;
		}

		this.paymentProcessing = true;
		// protecting from opengin confirmation modal again when payment is processing
		if (this.paymentProcessing === true) {
			this.modelOpen = false;
		}
		this.prepareCompanyForUpdate();
		const updates = this.getPlanUpdates();
		const actions = [this.companyService.updateCompany(this.company.id, this.company)];

		if (this.subscriptionService.upgradeData.previousPlan.code == 'FREE_PLAN' || this.subscriptionService.upgradeData.accountStatus != 'ACTIVE') {
			actions.push(this.subscriptionService.subscriptionWithAddonCreate(this.paymentForm.value.paymentMethodId, updates));
		} else {
			let isNewInterval = (this.subscriptionService.upgradeData.previousBillingType != this.subscriptionService.upgradeData.billingType)
				|| this.subscriptionService.upgradeData.previousPlan.code == 'FREE_PLAN';
			actions.push(this.subscriptionService.update(updates, isNewInterval));
		}

		//sets payment id as default
		actions.push(this.paymentMethodService.setPaymentAsDefault(this.paymentForm.value.paymentMethodId));

		forkJoin(...actions)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				this.subscriptionService.upgradeData.paymentDone = true;
				EmitterService.get(UpgradePlanStateChanged).emit({ paymentDone: true });
				this.paymentProcessing = false;
				this.router.navigate(['../produce'], { relativeTo: this.route });
			}, err => {
				this.paymentProcessing = false;
				switch (err.errorCode) {
					case 'INVALID_TAX_INFORMATION':
						this.overlayService.showError(this.translatePipe.transform('companyDetail_IncorrectTaxId'), 'Error');
						break;
					default:
						this.overlayService.showError(this.translatePipe.transform('overlayErrorOccurred'), 'Error');
						break;
				}
			})

	}

	trackByMethodId(index: number, item: any) {
		return item.id;
	}

	checkMethod() {
		this.paymentMethodService.removePaymentMethod(this.paymentForm.value.paymentMethodId)
			.subscribe(() => {
				this.loadPaymentMethods();
			}, err => {
				console.log(err);
			})
	}

	//currently we skip tax id validation, because of MTM-3452
	/*
	validateTaxId() {
		const taxIdNumber: string = this.paymentForm.value.taxIdNumber;
		const country: string = this.paymentForm.value.country;

		if (!taxIdNumber) {
			return;
		}

		this.validatorService.checkTaxIdWithName(this.company.name, country, taxIdNumber)
			.subscribe(() => {
				this.isTaxIdValid = true;
			}, error => {
				if (error) {
					this.isTaxIdValid = false;
					this.overlayService.showError(this.translatePipe.transform('companyDetail_IncorrectTaxId'), 'Error');
				}
			});

	}
	*/

	/**
	 * trial info
	 */
	getSubscriptionInfo() {
		this.subscriptionService.getSubscriptionInfo()
			.subscribe((res: any) => {
				this.subscriptionInfo = res;
				this.trialEnded = res.trialEnded;
				this.accountStatus = res.subscription.accountStatus;
				if (this.trialEnded === undefined) {
					this.trialEnded = true;
				} else {
					this.trialEnded = false;
				}
			})
	}

	/**
	 * notification modal will open for plan confirmation
	*/
	planConfimation() {
		//TODO HERE : when select same plan after
		if (this.accountStatus === 'ACTIVE' && this.subscriptionService.upgradeData.previousPlan.name === this.subscriptionService.upgradeData.plan.name) {
			this.submitData()
		}
		//when user select same plan after unsubscribe
		else if (this.subscriptionService.upgradeData.previousPlan.name === this.subscriptionService.upgradeData.plan.name
		) {
			this.submitData()
		}
		// when user upgrade plan
		else if (
			this.subscriptionService.upgradeData.previousPlan.name === 'Free Plan' ||
			this.subscriptionService.upgradeData.plan.name === 'Enterprise Plan' ||
			this.subscriptionService.upgradeData.plan.name === 'Business Plan'
		) {
			if (this.modelOpen === true) {
				this.notificationService.open({
					title: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_title'),
					notificationType: 'success',
					centerHeader: true,
					notificationInfo: this.translatePipe.transform('subscription_confirm_upgrade_adjustWarning'),
					question2: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_continueQuestion'),
					confirmBtn: this.translatePipe.transform('confirm'),
					cancelBtn: this.translatePipe.transform('cancel'),
					reverseButton: true
				}).subscribe(confirm => {
					if (confirm === true) {
						this.submitData()
					}
				});
			}
		}
		//when user downgrade plan trialplan is active
		else if (this.trialEnded === false) {
			this.notificationService.open({
				title: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_title'),
				notificationType: 'success',
				centerHeader: true,
				notificationInfo: this.translatePipe.transform('subscription_confirm_downgrade_to_paid_plan_adjustWarning'),
				question2: this.translatePipe.transform('subscription_confirm_upgrade_and_downgrade_continueQuestion'),
				confirmBtn: this.translatePipe.transform('confirm'),
				cancelBtn: this.translatePipe.transform('cancel'),
				reverseButton: true
			}).subscribe(confirm => {
				if (confirm === true) {
					this.submitData();
				}
			});
		} else {
			this.submitData();
		}
	}
}
