<!--header buttons-->
<div class="card" style="border: unset; min-width: 1239px; max-width: 1239px;">
	<div class="row">
		<div *ngIf="!isCompanyProfiles" class="col d-flex" style="margin-bottom: 2rem;">
			<button *ngIf="service.project.id" class="btn btn-lg border-dark btn-light mr-2" type="button"
				(click)="returnToProject()">
				<span>{{'returnToProject' | translate}}</span>
			</button>
			
			<button class="btn btn-lg btn-dark float-right" type="button" (click)="onEdit()"
				*ngIf="isReadMode" [disabled]="!project?.name">
				<span>{{'edit' | translate}}</span>
			</button>

			<button class="btn btn-lg btn-danger float-right" type="button" (click)="onSaveAndContinue(true)"
				[disabled]="sending" *ngIf="isNew && !isReadMode">
				<span>{{'saveAndContinue' | translate}}</span>
			</button>

			<ng-container *ngIf="!isNew && ! isReadMode">
				<button *ngIf="service.project.id" class="btn btn-lg border-dark btn-light mr-2" type="button"
					(click)="onCancelChanges()">
					<span>{{'cancel' | translate}}</span>
				</button>

				<button class="btn btn-lg btn-danger float-right" type="button" (click)="onSaveChanges()"
					[disabled]="sending">
					<span>{{'saveChanges' | translate }}</span>
				</button>
			</ng-container>
		</div>
	</div>
</div>

<div class="card" [style.max-width]="'1239px'" [style.min-width]="'1239px'" [formGroup]="projectCompanyForm"
	(ngSubmit)="onSubmit()">

	<!---COMPANY--->
	<div class="card-header">
		<h6 class="font-weight-bold">1. {{'projectCompanyCompanyDetailsHeader' | translate}}</h6>
	</div>
	<div class="card-block">
		<div class="col mt-3 mb-3">
			<div class="row justify-content-center mb-2">
				<h6>{{'projectCompanyCompanyLogo' | translate}}</h6>
			</div>
			<div class="row justify-content-center mx-auto mt-4">
				<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=true [disabled]="true"
					[isRedirectImageLoadError]=false
					[imageURL]="profilesInfo.companyProfileImage | cacheBusterImage: imageVersion"
					(files)="fileChangeListener($event,'COMPANY')"
					style="max-width: 200px;max-height: 200px;min-width: 200px;min-height: 200px;">
				</mtm-upload-file>
			</div>
		</div>
		<div class="col mt-5 justify-content-start">
			<h6 class="font-weight-bold mb-3">{{'projectCompanyCompanyName' | translate}}</h6>
			<div class="w-100"
				[ngClass]="{'has-danger': submitted && (this.projectCompanyForm?.controls?.companyName?.invalid || this.projectCompanyForm?.controls?.companyType?.invalid)}">
				<div *ngIf="!(isAdminUser && !adminProjectSelect) || !isNew" class="d-block">
					<input disabled class="form-control w-100" type="text" value="{{companyName}}" />
				</div>
				<div *ngIf="isAdminUser && !adminProjectSelect && isNew" class="d-block">
					<mtm-dropdown [form]="projectCompanyForm" [items]="allCompanies" [itemValueAccessor]="'name'"
						[itemLabelAccessor]="'name'" [isMultipleSelect]="false" [refObject]=this
						[formControlAccessor]="'companyName'" (onChangeValue)="sourceResult($event)">
					</mtm-dropdown>
				</div>
			</div>
			<div class="d-block" *ngIf="!this.projectCompanyForm?.controls?.companyName.invalid">
				<h6 class="font-weight-bold mb-2 mt-3">Workspace</h6>
				<div class="mb-3">
					<input disabled class="form-control w-100" type="text" *ngIf="!canEditWorkspace || isReadMode"
						[ngModelOptions]="{standalone: true}" [(ngModel)]="workspaceName" />

					<mtm-dropdown [form]="projectCompanyForm" *ngIf="canEditWorkspace && !isReadMode" [items]="companyWorkspaces"
						[itemValueAccessor]="'id'" [itemLabelAccessor]="'name'" [isMultipleSelect]="false"
						[refObject]=this [formControlAccessor]="'workspaceId'"
						[isDangerBorder]="isValid('workspaceId')">
					</mtm-dropdown>
				</div>
			</div>
		</div>
	</div>
	<!---PROJECT--->
	<div class="card-header">
		<h6 class="font-weight-bold">2. {{'projectCompanyProjectDetailsHeader' | translate}}</h6>
	</div>
	<div class="card-block">
		<div class="col my-3">
			<h6 class="font-weight-bold">{{'projectCompanyProjectType'|translate}}</h6>

			<ng-container>
				<div class="project-type-label">{{ getProjectTypeLabel(projectType) }}</div>
			</ng-container>
		</div>

		<div class="col mt-3 mb-3">
			<h6 class="font-weight-bold">{{'projectCompanyProjectName' | translate}}</h6>
			<label class="form-group has-float-label" [ngClass]="{'has-danger': isValid('projectName')}">
				<input (blur)="autoSavedFormValue()" class="form-control" type="text" placeholder=" "
					formControlName="projectName">
				<span>Project Name</span>
			</label>
		</div>
		
		<div class="col mt-5">
			<div class="row justify-content-center mb-2">
				<h6>{{'projectCompanyProjectLogo' | translate}}</h6>
			</div>
			<div class="row justify-content-center mx-auto mt-4">
				<div class="square">
					<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [isRoundedCircle]=false
						[isRedirectImageLoadError]=false [imageURL]=profilesInfo.projectProfileImage
						(files)="fileChangeListener($event, 'PROJECT')" [disabled]="isReadMode">
					</mtm-upload-file>
				</div>
			</div>
		</div>

		<div class="col mt-5">
			<h6 class="font-weight-bold mb-2 mt-3">{{'projectCompanyProjectDesc' | translate}}</h6>
			<h6 class="mb-3">{{'projectCompanyProjectDescSubtitle' | translate}}</h6>
			
			<label class="form-group has-float-label" [ngClass]="{'has-danger': isValid('description')}">
				<textarea (blur)="autoSavedFormValue()" class="form-control description" placeholder=" " rows="10"
					formControlName="description" [disabled]="isReadMode"></textarea>
				<span>{{'description' | translate}}</span>
			</label>
			
			<p class="text-muted mb-1">{{'projectCompanyProjectTypeDesc' | translate}}</p>
			<select (blur)="autoSavedFormValue()" class="dropdown-toggle mb-2" #typeSelect formControlName="videoGenre"
				[ngClass]="{'has-danger': isValid('videoGenre')}">
				<option value="{{ type.value }}" *ngFor="let type of types">{{type.label}}</option>
			</select>
			
			<label *ngIf="projectCompanyForm.value.videoGenre == 'OTHER'"
				class="form-group has-float-label project-type"
				[ngClass]="{'has-danger': submitted && projectCompanyForm.value.videoGenre == 'OTHER' && (!projectCompanyForm.value.videoGenreOther || projectCompanyForm.value.videoGenreOther=='')}">
				<input (blur)="autoSavedFormValue()" class="form-control" formControlName="videoGenreOther" type="text"
					placeholder=" ">
				<span>{{'projectCompanyProjectType' | translate }}</span>
			</label>

			<p class="text-muted mt-3 mb-1">{{'projectCompanyProjectTagDescription' | translate}}</p>
			<div class="mb-3">
				<ng-container *ngIf="! isReadMode">
					<tag-input theme='foundation-theme' [modelAsStrings]="true"
						(onAdd)="autoSavedFormValue()" (onRemove)="autoSavedFormValue()" [formControlName]="'tags'"
						placeholder="{{'projectCompanyProjectTagPlaceholder' | translate}}" [addOnBlur]="true"
						[separatorKeyCodes]="[13, 32]"
						secondaryPlaceholder="{{'projectCompanyProjectTagPlaceholder' | translate}}"
						[ngClass]="{'has-danger': isValid('tags')}">
					</tag-input>
					<span [ngClass]="{'text-danger': isValid('tags')}">{{ (isValid('tags')) ? ' Tags is required ' : ''
						}}</span>
				</ng-container>
				<div *ngIf="isReadMode && projectCompanyForm?.value?.tags" class="tag-list d-flex flex-wrap">
					<span *ngFor="let tag of projectCompanyForm?.value?.tags" 
					class="d-inline-block px-3 py-2 mr-2 bg-secondary text-white rounded">{{tag}}</span>
				</div>
			</div>

			<p class="text-muted mt-3 mb-1">{{'projectCompanyProjectWhoWillManage' | translate}}</p>
			<div class="mb-3" [ngClass]="{'has-danger': isValid('manager')}">
				<select (blur)="autoSavedFormValue()" class="dropdown-toggle" formControlName="manager"
					[ngClass]="{'has-danger': isValid('manager')}" *ngIf="isNew">
					<option [value]="u.username" *ngFor="let u of users">{{u.fullName}}</option>
				</select>
				<div *ngIf="! isNew" class="d-inline-flex align-items-center">
					<span class="ml-2">{{project?.manager}}</span>
				</div>
			</div>

			<div class="row my-2" [ngClass]="{'has-danger': isValid('validationMode')}">
				<div class="col-8">
				</div>
				<div class="col-4">
					<p class="m-0">{{'scheduleVideoLength' | translate}}</p>
				</div>
			</div>
			
			<div class="row">
				<div class="col-4" [ngClass]="{'has-danger': isValid('currency')}">
					<select class="dropdown-toggle mb-2" formControlName="currency" id="currencyId"
						[ngClass]="{'div-disabled': proposalAccepted}">
						<option value="{{ currency.id }}" *ngFor="let currency of currencies">{{currency.label}}
						</option>
					</select>
				</div>
				<div class="col">
					<label class="form-group has-float-label d-flex flex-row"
						[ngClass]="{'div-disabled': proposalAccepted, 'has-danger-dd': isValid('budget')}">
						<input class="form-control budget-input_spin" (ngModelChange)="onChangeProjectBudget()"
							[mtmFormatCurrency]=projectCompanyForm.value.budget [currencySymbol]="currentCurrency"
							formControlName="budget" type="text" #txtBudget>
						<span>Budget</span>
						<div class="btn-group" [ngClass]="{'div-disabled': proposalAccepted}">
							<button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
								aria-haspopup="true" aria-expanded="false" (click)="switchBudgetDropdown()" *ngIf="!isReadMode">
							</button>
							<div *ngIf="isOpenBudgetDropdown" class="dropdown-menu dropdown-menu-right"
								[class.d-block]="isOpenBudgetDropdown" (clickOutside)="switchBudgetDropdown()">
								<button *ngFor="let item of budgets" class="dropdown-item pl-1" type="button"
									(click)="onSelectedBudgetFromList(item) ">
									<span style="width:14px;display: inline-block;" class="mr-1">
										<i [class.d-none]="projectCompanyForm.value.budget != item.key"
											aria-hidden="true" class="fa fa-check ml-1 mr-1 text-success"></i>
									</span>
									{{'budgetRange'+ item.key + '_' + currentCurrency | translate}}
								</button>
							</div>
						</div>
					</label>
				</div>
				<div class="col-4">
					<div class="row">
						<div class="col">
							<div class="row">
								<label class="form-group has-float-label form-group-video-length">
									<input (blur)="autoSavedFormValue()" class="form-control" type="text"
										placeholder="{{somePlaceholder}}" formControlName="hoursVideoLength">
									<span>hh</span>
								</label>
							</div>
						</div>
						<div class="col">
							<div class="row">
								<label class="form-group has-float-label form-group-video-length">
									<input (blur)="autoSavedFormValue()" class="form-control" type="text"
										placeholder="{{somePlaceholder}}" formControlName="minutesVideoLength">
									<span>mm</span>
								</label>
							</div>
						</div>
						<div class="col mr-3">
							<div class="row">
								<label class="form-group has-float-label form-group-video-length">
									<input (blur)="autoSavedFormValue()" class="form-control" type="text"
										placeholder="{{somePlaceholder}}" formControlName="secondsVideoLength">
									<span>ss</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" [class.mb-3]="!isValidDate('airDate')">
				<div class="col">
					<div class="form-group mb-1" [ngClass]="{'has-danger': isValidDate('startDate')}">
						<div class="input-group" (click)="start.toggle()">
							<span *ngIf="isDateDefined(projectCompanyForm.controls['startDate'].value)"
								class="has-float-label-datepicker">{{'startDate' | translate}}</span>
							<input (ngModelChange)="autoSavedFormValue()" class="form-control date-picker"
								formControlName="startDate" [container]="'body'"
								[maxDate]="subSectionMinDate==null?projectCompanyForm.controls['airDate'].value:subSectionMinDate"
								ngbDatepicker #start="ngbDatepicker" placeholder="Start Date" firstDayOfWeek="1">
							<div class="input-group-addon" [mtmDatePickerDirective]=start *ngIf="! isReadMode">
								<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="form-group mb-1" [ngClass]="{'has-danger': isValidDate('plannedEndDate')}">
						<div class="input-group" [ngClass]="{'div-disabled': proposalAccepted}" (click)="due.toggle()">
							<span *ngIf="isDateDefined(projectCompanyForm.controls['plannedEndDate'].value)"
								class="has-float-label-datepicker"> {{'dueDate' | translate}}</span>
							<input (ngModelChange)="autoSavedFormValue()" class="form-control date-picker"
								formControlName="plannedEndDate" [container]="'body'"
								[maxDate]="projectCompanyForm.controls['airDate'].value" ngbDatepicker
								#due="ngbDatepicker" placeholder="Due Date" firstDayOfWeek="1">
							<div class="input-group-addon" [mtmDatePickerDirective]=due *ngIf="! isReadMode">
								<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="form-group mb-1" [ngClass]="{'has-danger': isValidDate('airDate')}">
						<div class="input-group" (click)="air.toggle()">
							<span *ngIf="isDateDefined(projectCompanyForm.controls['airDate'].value)"
								class="has-float-label-datepicker">{{'airDate' | translate}}</span>
							<input class="form-control date-picker" (ngModelChange)="autoSavedFormValue()" [container]="'body'"
								[minDate]="projectCompanyForm.controls['startDate'].value" formControlName="airDate"
								ngbDatepicker #air="ngbDatepicker" placeholder="Air Date" firstDayOfWeek="1">
							<div class="input-group-addon" [mtmDatePickerDirective]=air *ngIf="! isReadMode">
								<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<mtm-dropdown [title]="'selectMainLanguage' | translate" [emptyListInfoText]="''"
						[dotItemSelectedText]="'main language selected'"
						[dotItemsSelectedText]="'main languages selected'" [items]="allLanguages"
						[itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'" [isMultipleSelect]=true
						[form]=projectCompanyForm [formControlAccessor]="'languages'"
						(onChangeValue)="autoSavedFormValue()" [isDisabled]="isReadMode">
					</mtm-dropdown>
				</div>
				<div class="col-4">
					<mtm-dropdown [title]="'selectSubtitleLanguage' | translate" [emptyListInfoText]="''"
						[dotItemSelectedText]="'subtitle languages selected'"
						[dotItemsSelectedText]="'subtitle languages selected'" [items]="allLanguages"
						[itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'" [isMultipleSelect]=true
						[form]=projectCompanyForm [formControlAccessor]="'subTitleLanguages'"
						(onChangeValue)="autoSavedFormValue()" [isDisabled]="isReadMode">
					</mtm-dropdown>
				</div>
				<div class="col-4">
					<mtm-dropdown [title]="'selectDubbingLanguage' | translate" [emptyListInfoText]="''"
						[dotItemSelectedText]="'dubbing language selected'"
						[dotItemsSelectedText]="'dubbing languages selected'" [items]="allLanguages"
						[itemValueAccessor]="'abbreviation'" [itemLabelAccessor]="'name'" [isMultipleSelect]=true
						[form]=projectCompanyForm [formControlAccessor]="'dubbingLanguages'"
						(onChangeValue)="autoSavedFormValue()" [isDisabled]="isReadMode">
					</mtm-dropdown>
				</div>
			</div>
		</div>
	</div>
</div>
<!--FOOTER_BUTTONS-->
<div class="card" style="border: unset; min-width: 1239px; max-width: 1239px;">
	<div class="row">
		<div *ngIf="!isCompanyProfiles" class="col d-flex my-4">
			<button *ngIf="service.project.id" class="btn btn-lg border-dark btn-light mr-2" type="button"
				(click)="returnToProject()">
				<span>{{'returnToProject' | translate}}</span>
			</button>

			<button class="btn btn-lg btn-dark float-right" type="button" (click)="onEdit()"
				*ngIf="isReadMode">
				<span>{{'edit' | translate}}</span>
			</button>
			
			<button class="btn btn-lg btn-danger float-right" type="button" (click)="onSaveAndContinue(true)"
				[disabled]="sending" *ngIf="isNew && ! isReadMode">
				<span>{{'saveAndContinue' | translate }}</span>
			</button>

			<ng-container *ngIf="!isNew && ! isReadMode">
				<button *ngIf="service.project.id" class="btn btn-lg border-dark btn-light mr-2" type="button"
					(click)="onCancelChanges()">
					<span>{{'cancel' | translate}}</span>
				</button>

				<button class="btn btn-lg btn-danger float-right" type="button" (click)="onSaveChanges()"
					[disabled]="sending">
					<span>{{'saveChanges' | translate }}</span>
				</button>
			</ng-container>
		</div>
	</div>
</div>