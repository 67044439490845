import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PlatformNotificationModel, UserNotificationsModel } from 'app/account/notifications-settings/user-notifications.model';
import { ErrorMessageService } from 'app/shared/services/error-message.service';
import { UserService } from 'app/shared/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'app/shared/services/project.service';

@Component({
  selector: 'mtm-project-notification-setting',
  templateUrl: './project-notification-setting.component.html',
  styleUrls: ['./project-notification-setting.component.scss']
})
export class ProjectNotificationSettingComponent implements OnInit {
  formGroup: UntypedFormGroup;
  isPerProjectSettingsSelected: boolean = false;
  userNotification: UserNotificationsModel = new UserNotificationsModel();
  platformNotification: PlatformNotificationModel = new PlatformNotificationModel();
  perProjectSettingData: any[] = [];
  workspaceId: any;
  workspaceName: string;
  ngUnsubscribe = new Subject();
  backupProjectList: any[] = [];
  enList: string[] = ['en', 'en-us', 'en_us', 'en_uk', 'en-uk'];
  formErrors: any = null;
  languages = null;
  startWeekOns = null;
  dateFormats = null;
  timeFormats = null;
  timeDisplays = null;
  numberFormats = null;
  currencies = null;
  timezones = null;
  projectName: string = '';
  projectId: string;
  isAllNotificationsSelected: boolean = false;
  isAllPlatformNotificationsSelected: boolean = false;
  isAllEmailNotificationsSelected: boolean = false;
  isAllSMSNotificationsSelected: boolean = false;
  isApiCallInProgress : boolean = false;

  notificationIntervals = [
    {
      text: 'NotificationSummaryIntervals_15Minutes',
      value: 'MINUTES_15'
    },
    {
      text: 'NotificationSummaryIntervals_30Minutes',
      value: 'MINUTES_30'
    },
    {
      text: 'NotificationSummaryIntervals_45Minutes',
      value: 'MINUTES_45'
    },
    {
      text: 'NotificationSummaryIntervals_1Hour',
      value: 'HOUR_1'
    },
    {
      text: 'NotificationSummaryIntervals_2Hours',
      value: 'HOUR_2'
    },
    {
      text: 'NotificationSummaryIntervals_3Hours',
      value: 'HOUR_3'
    },
    {
      text: 'NotificationSummaryIntervals_6Hours',
      value: 'HOUR_6'
    },
    {
      text: 'NotificationSummaryIntervals_12Hours',
      value: 'HOUR_12'
    },
    {
      text: 'NotificationSummaryIntervals_1Day',
      value: 'DAYS_1'
    }
  ];
  constructor(private fb: UntypedFormBuilder,
    public userService: UserService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router) { }

  ngOnInit(): void {
    let fetchProjectId = this.router.url.split('/')
    this.projectId = fetchProjectId[fetchProjectId.length - 2]
    if (this.projectId) {
      this.getWorkSpaceId();
    }
    this.loadFormGroup();
  }

  getWorkSpaceId() {
    this.projectService.getSingleProject(this.projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.workspaceId = data.workspaceId;
      this.getPerProjectSettings();
    });
  }

  private loadFormGroup(data: any = null) {
    // Build FormGroup
    this.formGroup = this.fb.group({
      id: [(data && data.id) ? data.id : null],
      language: [(data && data.language) ? ((this.enList.indexOf(data.language) > -1) ? 'en' : data.language) : null, [Validators.required]],
      startWeekOn: [(data && data.startWeekOn) ? data.startWeekOn : null, [Validators.required]],
      dateFormat: [(data && data.dateFormat) ? data.dateFormat : null, [Validators.required]],
      timeFormat: [(data && data.timeFormat) ? data.timeFormat : null, [Validators.required]],
      timeDisplay: [(data && data.timeDisplay) ? data.timeDisplay : null, [Validators.required]],
      numberFormat: [(data && data.numberFormat) ? data.numberFormat : null, [Validators.required]],
      currency: [(data && data.currency) ? data.currency : null, [Validators.required]],
      timeZone: [(data && data.timeZone) ? data.timeZone : null, [Validators.required]],
      muteConversations: [(data && data.muteConversations != null) ? data.muteConversations : false, [Validators.required]],
      generalNotifications: [(data && data.generalNotifications != null) ? data.generalNotifications : null, [Validators.required]]
    });

    // Subscribe to changes to get validation errors
    this.formGroup.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => this.onValueChanged(data));

    // Just run an empty validation
    this.onValueChanged();
  }

  private onValueChanged(data?) {
    if (this.formGroup)
      this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);
  }

  getPerProjectSettings() {
    this.userService.getUserNotificationsSettings(this.workspaceId, this.projectId).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.userNotification = data;
      this.workspaceName = data.workspacename;
      this.projectName = data.projectName;
      this.backupProjectList = data;
      this.isAllNotificationsSelected = this.isAllSelected();
      this.isAllPlatformNotificationsSelected = this.isAllPlatformSelected();
      this.isAllEmailNotificationsSelected = this.isAllEmailSelected();
      this.isAllSMSNotificationsSelected = this.isAllSMSSelected();
    });
  }

  projectNotificationsSettings(event: any) {
    this.isPerProjectSettingsSelected = event;
  }

  changeNotificationsSettings(userNotificationSettings: any) {
    if (this.workspaceId  && this.projectId ) {
      // Check if the API call is already in progress
      if (!this.isApiCallInProgress) {
        this.isApiCallInProgress = true; // Set a flag to indicate that the API call is in progress
        userNotificationSettings.workspaceId = this.workspaceId;
        userNotificationSettings.projectId = this.projectId;
        this.userService.updateUserNotificationsSettings(this.workspaceId, this.projectId, userNotificationSettings).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (res) => {
            console.log('res', res)
            // Handle the API response
            this.isAllNotificationsSelected = this.isAllSelected();
            this.isAllPlatformNotificationsSelected = this.isAllPlatformSelected();
            this.isAllEmailNotificationsSelected = this.isAllEmailSelected();
            this.isAllSMSNotificationsSelected = this.isAllSMSSelected();
          },
          error: (e) => {
            // Handle API errors
            console.error(e);
          },
          complete: () => {
            // This block is executed when the API call is complete
            console.info('complete');
            this.isApiCallInProgress = false; // Reset the flag
          }
        });
      }
    } else {
      // Handle the case where workspaceId or projectId is null or undefined
      console.error('workspaceId or projectId is null or undefined');
    }
  }
  

  returnToProject() {
    this.router.navigate(['projects', this.projectId]);
  }

  toggleNotifications() {
    this.userNotification.notificationSummaryEmail = this.isAllNotificationsSelected;
    this.userNotification.validationsWeb = this.isAllNotificationsSelected;
    this.userNotification.validationsEmail = this.isAllNotificationsSelected;
    this.userNotification.validationsMobile = this.isAllNotificationsSelected;
    this.userNotification.editingWeb = this.isAllNotificationsSelected;
    this.userNotification.editingEmail = this.isAllNotificationsSelected;
    this.userNotification.editingMobile = this.isAllNotificationsSelected;
    this.userNotification.projectCommunicationWeb = this.isAllNotificationsSelected;
    this.userNotification.projectCommunicationEmail = this.isAllNotificationsSelected;
    this.userNotification.projectCommunicationMobile = this.isAllNotificationsSelected;
    this.userNotification.projectScheduleWeb = this.isAllNotificationsSelected;
    this.userNotification.projectScheduleEmail = this.isAllNotificationsSelected;
    this.userNotification.projectScheduleMobile = this.isAllNotificationsSelected;
    this.userNotification.teamBuildingWeb = this.isAllNotificationsSelected;
    this.userNotification.teamBuildingEmail = this.isAllNotificationsSelected;
    this.userNotification.teamBuildingMobile = this.isAllNotificationsSelected;
    this.userNotification.seeOwnActions = this.isAllNotificationsSelected;
    this.userNotification.taskEmail = this.isAllNotificationsSelected;
    this.userNotification.taskMobile = this.isAllNotificationsSelected;
    this.userNotification.taskWeb = this.isAllNotificationsSelected;
    this.changeNotificationsSettings(this.userNotification);
  }

  togglePlatformNotifications() {
    this.userNotification.validationsWeb = this.isAllPlatformNotificationsSelected;
    this.userNotification.editingWeb = this.isAllPlatformNotificationsSelected;
    this.userNotification.projectCommunicationWeb = this.isAllPlatformNotificationsSelected;
    this.userNotification.taskWeb = this.isAllPlatformNotificationsSelected;
    this.userNotification.projectScheduleWeb = this.isAllPlatformNotificationsSelected;
    this.userNotification.teamBuildingWeb = this.isAllPlatformNotificationsSelected;
    this.changeNotificationsSettings(this.userNotification);
  }

  toggleEmailNotifications() {
    this.userNotification.validationsEmail = this.isAllEmailNotificationsSelected;
    this.userNotification.editingEmail = this.isAllEmailNotificationsSelected;
    this.userNotification.projectCommunicationEmail = this.isAllEmailNotificationsSelected;
    this.userNotification.taskEmail = this.isAllEmailNotificationsSelected;
    this.userNotification.projectScheduleEmail = this.isAllEmailNotificationsSelected;
    this.userNotification.teamBuildingEmail = this.isAllEmailNotificationsSelected;
    this.changeNotificationsSettings(this.userNotification);
  }

  toggleSMSNotifications() {
    this.userNotification.validationsMobile = this.isAllSMSNotificationsSelected;
    this.userNotification.editingMobile = this.isAllSMSNotificationsSelected;
    this.userNotification.projectCommunicationMobile = this.isAllSMSNotificationsSelected;
    this.userNotification.taskMobile = this.isAllSMSNotificationsSelected;
    this.userNotification.projectScheduleMobile = this.isAllSMSNotificationsSelected;
    this.userNotification.teamBuildingMobile = this.isAllSMSNotificationsSelected;
    this.changeNotificationsSettings(this.userNotification);
  }

  isAllSelected() {
    return this.userNotification.notificationSummaryEmail
      && this.userNotification.validationsWeb
      && this.userNotification.validationsEmail
      && this.userNotification.validationsMobile
      && this.userNotification.editingWeb
      && this.userNotification.editingEmail
      && this.userNotification.editingMobile
      && this.userNotification.projectCommunicationWeb
      && this.userNotification.projectCommunicationEmail
      && this.userNotification.projectCommunicationMobile
      && this.userNotification.projectScheduleWeb
      && this.userNotification.projectScheduleEmail
      && this.userNotification.projectScheduleMobile
      && this.userNotification.teamBuildingWeb
      && this.userNotification.teamBuildingEmail
      && this.userNotification.teamBuildingMobile
      && this.userNotification.seeOwnActions
      && this.userNotification.taskEmail
      && this.userNotification.taskMobile
      && this.userNotification.taskWeb;
  }

  isAllPlatformSelected() {
    return this.userNotification.validationsWeb
      && this.userNotification.editingWeb
      && this.userNotification.projectCommunicationWeb
      && this.userNotification.projectScheduleWeb
      && this.userNotification.taskWeb
      && this.userNotification.teamBuildingWeb;
  }

  isAllEmailSelected() {
    return this.userNotification.validationsEmail
      && this.userNotification.editingEmail
      && this.userNotification.projectCommunicationEmail
      && this.userNotification.taskEmail
      && this.userNotification.projectScheduleEmail
      && this.userNotification.teamBuildingEmail;
  }

  isAllSMSSelected() {
    return this.userNotification.validationsMobile
      && this.userNotification.editingMobile
      && this.userNotification.projectCommunicationMobile
      && this.userNotification.taskMobile
      && this.userNotification.projectScheduleMobile
      && this.userNotification.teamBuildingMobile;
  }
}
