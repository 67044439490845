import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import * as moment from 'moment';
import 'moment/min/locales';
import 'moment-timezone';
import { authUserSettings } from '../interfaces';

@Pipe({
  name: 'mtmTimePipe'
})

export class MtmTimePipe implements PipeTransform {
  private timeFormat: string
  authUserSettings: authUserSettings;

  constructor(private authService: AuthService) {
    this.timeFormat = '12hs'
    this.authUserSettings = this.authService.getAuthUserSettings();
  }

  transform(value: any): string {
    if (this.authUserSettings?.timeFormat != this.timeFormat) {
      let today: Date = new Date(value)
      const formattedTime = moment(today).format('HH:mm');
      return formattedTime
    } else {
      let today: Date = new Date(value)
      const formattedTime = moment(today).format('hh:mm a');
      return formattedTime
    }
  }

}
