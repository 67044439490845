import { AudioComp } from "../lib/audio-component";
import { Annotation } from "./annotation";
import { DraggableMarker } from "./draggableMarker";

export class Controls extends AudioComp {

    uiState: any;
    marker: DraggableMarker;

    constructor(plugin: Object) {
        super(plugin);
        this.initAPI(this, 'Controls');

        this.uiState = { adding: false }
        this.bindTimeChangeHotkeys();
    }

    startAddNew() {
        if (!this.plugin.active) this.plugin.toggleAnnotationMode();
        if (this.uiState.adding) return;
        const _this2 = this;
        this.uiState.adding = true;

        this.plugin.annotationState.clickOutAnnotation();
        this.pause();

        let currentTime = this.currentTime();

        let range = {
            start: Math.round(currentTime * 10) / 10,
            end: Math.round(currentTime * 10) / 10
        }

        this.marker = new DraggableMarker(this.plugin, range);

        this.plugin.primordial.annotatingMode = true;
        this.plugin.primordial.activeTimeMode = 'start';
        this.plugin.fire('enteredAddingAnnotation');
    }

    cancelAddNew(isGeneralComment = false) {
        if (!this.uiState.adding && !isGeneralComment) return;
        let _this2 = this;
        this.plugin.firstTouch = false;
        this.uiState.adding = false;

        this.plugin.annotationState.closeAllAnnotations();

        // redrawing all annotations
        this.plugin.annotationState.openAllAnnotations();

        if (this.marker) this.marker.teardown();
        this.marker = null;

        this.plugin.primordial.annotatingMode = false;
        this.plugin.primordial.activeTimeMode = null;
        this.plugin.fire('exitingAddingAnnotation');
    }

    bindTimeChangeHotkeys() {
        let _this2 = this;
        $(document).on('keydown', e => {
            if ((e.target as any).classList.contains('mtm-text-editor-element')) return;
            if (e.key === "ArrowRight") {
                _this2.moveTimeRight();
            }
            else if (e.key === "ArrowLeft") {
                _this2.moveTimeLeft();
            }
        });
    }

    moveTimeRight() {
        let annotatingMode = this.plugin.primordial.annotatingMode;
        let activeTimeFormat = this.plugin.primordial.activeTimeFormat;
        let activeTimeMode = this.plugin.primordial.activeTimeMode;
        if (!annotatingMode) {
            if (activeTimeFormat === 'seconds') {
                this.setCurrentTime(this.currentTime() + 1);
            }
            else if (activeTimeFormat === 'milliseconds') {
                this.setCurrentTime(this.currentTime() + 0.1);
            }
        }
        else if (annotatingMode && this.marker) {
            if (activeTimeMode === 'start') {
                if (activeTimeFormat === 'seconds') {
                    this.marker.updateStart(+1);
                }
                else if (activeTimeFormat === 'milliseconds') {
                    this.marker.updateStart(+0.1);
                }
            }
            else if (activeTimeMode === 'end') {
                if (activeTimeFormat === 'seconds') {
                    this.marker.updateEnd(+1);
                }
                else if (activeTimeFormat === 'milliseconds') {
                    this.marker.updateStart(+0.1);
                }
            }
        }
    }

    moveTimeLeft() {
        let annotatingMode = this.plugin.primordial.annotatingMode;
        let activeTimeFormat = this.plugin.primordial.activeTimeFormat;
        let activeTimeMode = this.plugin.primordial.activeTimeMode;
        if (!annotatingMode) {
            if (activeTimeFormat === 'seconds') {
                this.setCurrentTime(this.currentTime() - 1);
            }
            else if (activeTimeFormat === 'milliseconds') {
                this.setCurrentTime(this.currentTime() - 0.1);
            }
        }
        else if (annotatingMode && this.marker) {
            if (activeTimeMode === 'start') {
                if (activeTimeFormat === 'seconds') {
                    this.marker.updateStart(-1);
                }
                else if (activeTimeFormat === 'milliseconds') {
                    this.marker.updateStart(-0.1);
                }
            }
            else if (activeTimeMode === 'end') {
                if (activeTimeFormat === 'seconds') {
                    this.marker.updateEnd(-1);
                }
                else if (activeTimeFormat === 'milliseconds') {
                    this.marker.updateStart(-0.1);
                }
            }
        }
    }

    timeUpdate() {
        if (this.marker) this.marker.timeUpdateChange();
        this.plugin.annotationState.timeUpdate();
    }

    playUpdate() {
        if (this.marker) this.marker.onPlay();
    }

    breakUpdate() {
        if (this.marker) this.marker.onBreak();
    }

    saveNew() {
        let range = this.marker.range;
        const annotation = Annotation.newFromData(
            this.plugin.primordial.annotationComment,
            null,
            range,
            this.plugin,
            null,
            this.plugin.primordial.privateCondition,
        );
        console.log('audio annotation plugin', this.plugin);
        this.plugin.annotationState.addNewAnnotation(annotation);
        this.cancelAddNew();
    }

    saveNewGeneralComment() {
        const range = {
            start: null,
            end: null,
            isGeneralComment: true
        }
        const annotation = Annotation.newFromData(
            this.plugin.primordial.annotationComment,
            null,
            range,
            this.plugin
        );
        this.plugin.annotationState.addNewAnnotation(annotation);
        this.cancelAddNew(true);
    }

    startRangeUpdate(act) {
        let activeTimeFormat = this.plugin.primordial.activeTimeFormat;
        if (this.uiState.adding && this.marker) {
            let secondChange = ((act === 'add') ? +1 : (act === 'min') ? -1 : null);
            let millisecondChange = ((act === 'add') ? +0.1 : (act === 'min') ? -0.1 : null);
            if (activeTimeFormat === 'seconds') {
                this.marker.updateStart(secondChange);
            }
            else if (activeTimeFormat === 'milliseconds') {
                this.marker.updateStart(millisecondChange);
            }
        }
    }

    endRangeUpdate(act) {
        let activeTimeFormat = this.plugin.primordial.activeTimeFormat;
        if (this.uiState.adding && this.marker) {
            let secondChange = ((act === 'add') ? +1 : (act === 'min') ? -1 : null);
            let millisecondChange = ((act === 'add') ? +0.1 : (act === 'min') ? -0.1 : null);
            if (activeTimeFormat === 'seconds') {
                this.marker.updateEnd(secondChange);
            }
            else if (activeTimeFormat === 'milliseconds') {
                this.marker.updateEnd(millisecondChange);
            }
        }
    }

    teardown() {
        this.cancelAddNew();
        $(document).off('keydown');
        super.teardown();

        return null;
    }
}
