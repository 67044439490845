<div class="projects-container">
    <div class="row ml-0 mr-0">
        <div class="col-4 col-lg-2 p-0 d-flex flex-column project-container">
            <div class="new-project d-flex align-items-center" *ngIf="canCreateProject">
                <button class="btn btn-new mr-auto" (click)="createProject()">
                    <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                    <span>{{'workspace_edit_createNewProject'|translate}}</span>
                </button>

                <div ngbDropdown class="d-inline-block sort-dropdown ml-auto">
                    <div class="toggle mr-2" ngbDropdownToggle role="button">
                        <i class="fa" aria-hidden="true" [ngClass]="{
                          'fa-sort-alpha-asc': projectSorting?.btn == ProjectSortCriteria.ProjectName && projectSorting?.sortDirection,
                          'fa-sort-alpha-desc': projectSorting?.btn == ProjectSortCriteria.ProjectName && !projectSorting?.sortDirection,
                            'fa-sort-numeric-asc': projectSorting?.btn == ProjectSortCriteria.StartDate && projectSorting?.sortDirection,
                            'fa-sort-numeric-desc': projectSorting?.btn == ProjectSortCriteria.StartDate && !projectSorting?.sortDirection,
                            'fa-sort-amount-asc': projectSorting?.btn == ProjectSortCriteria.DueDate && projectSorting?.sortDirection,
                            'fa-sort-amount-desc': projectSorting?.btn == ProjectSortCriteria.DueDate && !projectSorting?.sortDirection
                            }"></i>
                    </div>
                    <div ngbDropdownMenu>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.ProjectName, true)">
                            <i class="fa fa-sort-alpha-asc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByProjectNameAsc' | translate }}</span>
                        </div>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.ProjectName, false)">
                            <i class="fa fa-sort-alpha-desc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByProjectNameDesc' | translate }}</span>
                        </div>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.StartDate, true)">
                            <i class="fa fa-sort-numeric-asc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByStartDateAsc' | translate }}</span>
                        </div>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.StartDate, false)">
                            <i class="fa fa-sort-numeric-desc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByStartDateDesc' | translate }}</span>
                        </div>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.DueDate, true)">
                            <i class="fa fa-sort-amount-asc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByDueDateAsc' | translate }}</span>
                        </div>
                        <div ngbDropdownItem (click)="updateProjectSorting(ProjectSortCriteria.DueDate, false)">
                            <i class="fa fa-sort-amount-desc mr-2" aria-hidden="true"></i>
                            <span>{{'dashboard_sortByDueDateDesc' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-fill projects">
                <div class="d-flex p-3 align-items-center justify-content-center project-item"
                    *ngFor="let project of projects | ProjectSorting:[projectSorting]" [class.active]="project == selectedProject"
                    (click)="selectProjectItem(project)">
                    <div class="mr-2">
                        <mtm-item-icon [name]="project.name" [imageUrl]="project.avatarUrl" [width]="'30px'"
                            [height]="'30px'" [generatedTextColor]="true">
                        </mtm-item-icon>
                    </div>
                    <div class="flex-fill name">
                        {{project.name}}
                    </div>
                </div>
            </div>
            <div class="new-project" *ngIf="canCreateProject">
                <button class="btn btn-new" (click)="createProject()">
                    <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                    <span>{{'workspace_edit_createNewProject'|translate}}</span>
                </button>
            </div>
        </div>
        <div class="col-8 col-lg-10 d-flex flex-column user-container">
            <div class="d-flex align-items-center my-3 buttons">
                <button type="button" class="btn add-user" *ngIf="selectedProject" (click)="showAddModal()"
                    [disabled]="!canEditProject" [attr.data-title]="'workspace_edit_cantAddUser' | translate"
                    [class.tooltipped]="!canEditProject">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    {{'workspace_edit_addNewUser'|translate}}
                </button>

                <button type="button" class="btn ml-3 go-project" *ngIf="selectedProject" (click)="goToProject()">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    {{'workspace_edit_goToProject'|translate}} "{{selectedProject.name}}"
                </button>

                <button type="button" (click)="openPermissionDetail()" class="btn add-user ml-auto">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                    {{'permissionDetails' | translate}}
                </button>
            </div>
            <div>
                <div *ngIf="!!(localFilterParams?.fullName || localFilterParams?.email || localFilterParams?.roles?.length)" class="d-flex justify-content-end">
                    <label class="p-2">Total: {{(participants | FilterUser: localFilterParams?.fullName : localFilterParams?.email : localFilterParams?.roles).length}} records</label>
                </div>
                <ag-grid-angular #workspaceProjectParticipantsTable
                    [rowData]="participants | FilterUser: localFilterParams?.fullName : localFilterParams?.email : localFilterParams?.roles : localFilterParams?.decisionMaker"
                    [debounceVerticalScrollbar]="true" [rowHeight]="120" [defaultColDef]="defaultColDef"
                    class="ag-theme-alpine" [columnDefs]="workspaceProjectParticipantColumnsDef" [headerHeight]="72"
                    [domLayout]="'autoHeight'" (gridReady)="onGridReady($event)" [context]="tableContext"
                    [enableCellTextSelection]="true" [ensureDomOrder]="true" [components]="components">
                </ag-grid-angular>
            </div>

            <div class=" my-3 buttons">
                <button type="button" class="btn add-user" *ngIf="selectedProject" (click)="showAddModal()"
                    [disabled]="!canEditProject" [attr.data-title]="'workspace_edit_cantAddUser' | translate"
                    [class.tooltipped]="!canEditProject">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    {{'workspace_edit_addNewUser'|translate}}
                </button>

                <button type="button" class="btn ml-3 go-project" *ngIf="selectedProject" (click)="goToProject()">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    {{'workspace_edit_goToProject'|translate}} "{{selectedProject.name}}"
                </button>
            </div>
        </div>
    </div>
</div>