<div class="row row-modal">
  <div class="col-8 img-cropper-container">
    <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [imageFile]="imageFile"
      [maintainAspectRatio]="false" [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1 / 1"
      [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
      [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
      #cropper></image-cropper>
    <!-- <img-cropper class="cropper" [image]="data" [settings]="cropperSettings"></img-cropper> -->
  </div>
  <div class="col-4">
    <div class="logo-container row-img d-flex align-items-center justify-content-center p-3">
      <img *ngIf="data.image && !isCreateProject" class="img-boundary img-uploaded-circle" [src]="data.image">
      <img *ngIf="data.image && isCreateProject " class="img-square img-boundary img-uploaded" [src]="data.image">
    </div>
    <div class="d-block">
      <p class="crop-label">Crop your profile image</p>
      <p class="text-muted">Please crop your image using the<br>cropping tool to the left. When<br>you're happy
        with your selection,<br>click
        the save button.</p>
      <button type="button" class="btn btn-lg btn-danger" (click)="upload()">Save</button>
    </div>
  </div>

  <div>

  </div>
</div>
<!-- <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" /> -->