import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "../../services/translate.service";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: 'language-popup-component',
	templateUrl: './language-popup.component.html',
	styleUrls: ['./language-popup.component.scss']
})

export class LanguagePopupComponent implements OnInit {

	constructor(private router: Router, public activeModal: NgbActiveModal,
		private translateService: TranslateService, private authService: AuthService) {

	}
	ngOnInit() {
	}

	changeLanguage(language: any) {
		this.translateService.use(language);
		this.authService.setAuthLanguage(language);
		this.activeModal.close();
	}
}
