import { AnnotationPrivateCondition } from "./annotation.interface";

export interface UITask {
  id?: string;
  name: string;
  running?: boolean;
  commands: UICommand[];
  deadline: number;
}

export interface UICommand {
  id: string;
  params: any;
}

export const SliderInitialized = 'slider.initialized';
export const SliderSelectVersion = 'slider.selectVersion';
export const SliderSelectFile = 'slider.selectFile';
export const TextEditorSelectPrivateCondition = 'textEditor.selectPrivateCondition';
export const SliderSelectAsset = 'slider.selectAsset';
export const SliderScrollToAsset = 'slider.scrollToAsset';
export const CommentHighlight = 'comment.highlight';

export type TaskQueueProps = {
  resolve: (value: boolean) => void
}

export type TextEditorSelectPrivateConditionProps = {
  privateCondition: AnnotationPrivateCondition ;
} & TaskQueueProps;
