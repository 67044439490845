import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';
import { Project } from "app/shared/interfaces/project";
// import * as moment from 'moment';
import 'moment-timezone';
import { Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { NotificationService } from "app/shared/services/notification.service";
import { AuthService } from '../../../shared/services/auth.service';
import { BreadcrumbService, BreadcrumbComponent } from "ng5-breadcrumb";
import { DateHelperService } from "../../../shared/services/date-helper.service";
import { PaymentService } from "../../../shared/services/payment.service";

import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Authority, PermissionService } from "../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { UserService } from 'app/shared/services/user.service';
import { LimitReachedPopupComponent } from 'app/subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component';
import { SUBSCRIPTION_LIMIT } from 'app/subscriptions/models/const';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { ProjectColor, ProjectStatus } from 'app/layouts/my-projects/project.const';
import { ProjectCompletionDateComponent } from '../project-completion-date/project-completion-date.component';
import { OverlayService } from 'app/shared/services/overlayService';


@Component({
	selector: 'project-thumbnail',
	templateUrl: './thumbnail.component.html',
	styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit, OnDestroy {
	@ViewChild(NgbDropdown, { static: false }) dropdownProjectStatus: NgbDropdown;
	@Input('project') project: Project;
	@Input() rsvp: string = 'yes';
	@Input() isArchived: boolean = false;
	@Input() additionalData: any;
	@Input() canUpdateStatus: boolean = false;
	@Input() subscription: any;
	@Input() isFromFreePlan: boolean = false;

	@Input() parentLoadingDataElement: any;

	@Output() deleteEmit: EventEmitter<any> = new EventEmitter<any>();
	@Output() rejectEmit: EventEmitter<any> = new EventEmitter<any>();
	@Output() projectRestored: EventEmitter<any> = new EventEmitter<any>();
	@Output() projectStatusUpdate: EventEmitter<any> = new EventEmitter<any>();

	projectColor = ProjectColor;
	projectStatus = ProjectStatus;
	/**
	 * Date of today
	 */
	private today: Date = new Date();
	private state: string;
	private positionColor: number;
	private isClickedBadgeButton: boolean = false;
	private users: any;
	ngUnsubscribe = new Subject();

	authority = Authority;
	currentuser: any;
	userBlocked: boolean = false;
	userName: any = '';
	authUser: any;
	isAdminUser: any;
	isManager: boolean;

	constructor(private router: Router,
		public service: ProjectService,
		private paymentService: PaymentService,
		private notificationService: NotificationService,
		private authService: AuthService,
		private breadCrumb: BreadcrumbService,
		private translate: TranslatePipe,
		private modalService: NgbModal,
		private permissionService: PermissionService,
		private userService: UserService,
		private overlayService: OverlayService,
		private workspaceService: WorkspaceService) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		this.isAdminUser = this.permissionService.hasAuthority(Authority.Z, null);
		this.isManager = this.authUser.email == this.project.manager;
		let due = DateHelperService.fromTimestampToDate(this.project.plannedEndDate);
		this.project.displayDueDate = new Date(due).getTime();
		this.checkUserAccess();
	}

	/**
	 * Check if the phase of category is today
	 */
	private isToday(section: any): boolean {
		return (section.startDate <= this.today)
			&& (section.endDate >= this.today);
	}

	/**
	 * Edit project
	 */
	edit(): void {
		this.clickedBadgeButton();
		this.router.navigate(['/projects/edit', this.project.id]);
	}

	/**
	 * Open project page
	 */
	private async onOpenProject(project) {
		this.service.updateFirstSeenByProjectIdAfterCheck(project);
		if (this.rsvp == 'yes' && !this.isClickedBadgeButton && !this.userBlocked) {
			let url = '/projects/' + project.id + '/dashboard';
			this.router.navigate([url]);
			this.workspaceService.changeStatus(false);
		} else {
			this.notificationService.open({
				title: this.translate.transform('userBlocked'),
				description: this.translate.transform('userBlocked_Text'),
				confirmBtn: this.translate.transform('ok')
			})
		}
	}

	//redirects to notifications page
	onNotificationSearch(event: MouseEvent) {
		event.stopPropagation();
		if (!this.project)
			return;

		this.router.navigate(['account/notifications'], { queryParams: { project: this.project.id } })
	}

	/**
	 * Handles if user accept or reject project invitation
	 * @param accept
	 */
	private onProjectInvitation(accept: boolean = true) {

		if (accept) { // Accept invitation

			this.service.acceptInvitation(this.project.id).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.rsvp = 'yes';
				},
				(err: any) => {
					this.notificationService.open({
						title: 'Project invitation',
						description: `There was an error. Please, try again."`,
						confirmBtn: 'Accept',
					})
				});

		} else { // Reject invitation

			// Display double check confirmation
			this.notificationService.open({
				title: 'Project invitation',
				description: `Are you sure you do not wish to join this project?`,
				confirmBtn: 'Yes, reject',
				cancelBtn: 'Cancel'
			}).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((confirm: any) => {

				// Reject project invitation
				if (confirm) {
					this.service.rejectInvitation(this.project.id).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						(data: any) => {
							this.rsvp = 'no';
							this.rejectEmit.emit(this.project);
						});
				}
			});
		}
	}

	archive() {
		const isAdmin = this.permissionService.hasAuthority(Authority.Z, null);

		if (!this.authService.hasPaidSubscription() && !isAdmin) {
			let modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
			modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE;
			return;
		}

		let projectId = this.project.id;
		this.clickedBadgeButton();
		this.notificationService.open({
			title: 'Archiving...',
			description: `Are you sure you want to archive this project?\n${this.project.name}\n`,
			confirmBtn: 'Yes, please',
			cancelBtn: 'Cancel'
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			if (confirm) {
				let company: any = this.project.company;
				this.service.getProductionOwnerForRating(projectId, company.id).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(productionOwner => {
					if (productionOwner && productionOwner.username) {
						//console.log(this.serviceAuth.getAuthUser());
						this.router.navigate(['projects', 'rate-production-company', projectId], { queryParams: { action: 'delete' } });
						this.deleteEmit.emit(this.project);
						this.router.navigate(['projects', 'rate-production-company', projectId], { queryParams: { action: 'delete' } });
						this.breadCrumb.hideRouteRegex(`/projects/rate-production-company/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*$`);
					}
					else {
						this.service.deleteProject(this.project.id).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(
							res => { this.deleteEmit.emit(this.project); },
							err => {
								this.notificationService.open({
									title: 'Warning',
									description: err.message,
									confirmBtn: 'Ok'
								});
							});
					}

				})
			}

			this.returnIsClickedBadgeButton();
		});
	}

	delete() {
		this.clickedBadgeButton();
		this.notificationService.open({
			title: this.translate.transform('projectDeleteTitle'),
			description: `${this.translate.transform('projectDeleteConfirm', { project_name: this.project.name })}`,
			confirmBtn: this.translate.transform('yes'),
			cancelBtn: this.translate.transform('cancel')
		}).subscribe((confirm: any) => {
			if (confirm) {
				this.service.deleteProject(this.project.id, false).subscribe({
					next: res => { this.deleteEmit.emit(this.project); },
					error: err => {
						this.notificationService.open({
							title: 'Warning',
							description: err.message,
							confirmBtn: 'Ok'
						});
					}
				});
			}

			this.returnIsClickedBadgeButton();
		})
	}

	private clickedBadgeButton() {
		this.isClickedBadgeButton = true;
	}

	private returnIsClickedBadgeButton() {
		this.isClickedBadgeButton = false;
	}

	private checkUserAccess() {
		if (!this.project) {
			return;
		}

		const userName = this.authService.getAuthUserName();
		const participant = (<any>this.project)?.participants?.find(u => u.username === userName);
		this.userBlocked = participant?.blocked;
	}

	updateProjectStatus(status) {
		if (!this.canUpdateStatus || !(this.isAdminUser || this.isManager)) {
			this.dropdownProjectStatus.close();
			return;
		}
		if (!(this.authUser.globalRole == 'ADMIN' || this.authUser.globalRole=='COMPANY_PRINCIPAL')) {
			this.dropdownProjectStatus.close();
			return;
		}
		this.dropdownProjectStatus.close();
		if (status == this.projectStatus.Completed) {
			const modalRef = this.modalService.open(ProjectCompletionDateComponent, { size: 'sm' });
			modalRef.componentInstance.setParams({ project: this.project });
			modalRef.result.then((data: any) => {
				if (!data) {
					return;
				}
				this.project.status = status;
				this.projectStatusUpdate.emit({ projectId: this.project.id, status, completionDate: data.completionDate });
			}, reason => {
				console.log(reason);
			});
		} else {
			this.project.status = status;
			this.projectStatusUpdate.emit({ projectId: this.project.id, status });
		}
	}

	restoreArchive(){
		this.service.restoreArchive(this.project.id).subscribe({
			next: (result: any) => { 
				this.projectRestored.emit(this.project);
			},
			error: (err: unknown) => {
				this.overlayService.showError(this.translate.transform('overlayErrorSave'), 'Error');
			}
		});
	}
}
