import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { CachedWorkspaceProjectsUpdatedEvent, CachedWorkspacesUpdatedEvent } from "app/shared/interfaces/workspace.interface";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { HelperService } from "app/shared/services/helper.service";
import { OverlayService } from "app/shared/services/overlayService";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ProjectService } from "app/shared/services/project.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'workspace-list',
    templateUrl: './workspace-list.component.html',
    styleUrls: ['./workspace-list.component.scss']
})
export class WorkspaceListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() projectFilter: string = '';
    @Input() workspaceFilter: string = '';
    authUser: any = null;
    ngUnsubscribe = new Subject();
    isProductionCompany: boolean = false;
    workspaces: any[] = [];
    myWorkspaces: any[] = [];
    otherWorkspaces: any[] = [];
    cacheBuster: string = '';
    filteredWorkspaces: any = [];
    projects: any[] = [];
    filteredProjects: any[] = [];
    marketplaceAvatar: string = '';
    marketplaceIncluded: boolean = false;
    marketplaceName: string = 'MTM Marketplace';
    canCreateWorkspace: boolean = false;
    subscription: any = null;

    constructor(
        private authService: AuthService,
        private workspaceService: WorkspaceService,
        private companyService: CompanyService,
        private permissionService: PermissionService,
        private projectService: ProjectService,
        private overlayService: OverlayService,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.authUser = this.authService.getAuthUser();
        this.isProductionCompany = this.authUser.company.companyType == 'PRODUCER';
        this.cacheBuster = (+ new Date()).toString();
        this.workspaces = this.workspaceService.getCachedWorkspaces();
        this.projects = this.workspaceService.getCachedProjects();
        this.filteredWorkspaces = this.workspaces;
        this.filteredProjects = this.projects;
        this.subscription = this.authService.getAuthUserSubscription();

        this.canCreateWorkspace = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) ||
            (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S));

        this.popularWorkspaceGroups();

        EmitterService.get(CachedWorkspacesUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe((workspaces) => {
            this.workspaces = workspaces;
            this.filteredWorkspaces = this.workspaces;
            this.popularWorkspaceGroups();
        });
        EmitterService.get(CachedWorkspaceProjectsUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe((projects) => {
            this.projects = projects;
            this.filteredProjects = projects;
        });

        if (this.isProductionCompany) {
            this.companyService.getCompanyById(this.authUser.company.id).pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe((result) => {
                this.marketplaceAvatar = result.avatarUrl;
                this.marketplaceIncluded = !!result.marketplaceAccess;
            });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectFilter && changes.projectFilter.currentValue) {
            this.filterProjects();
        } else if (changes.workspaceFilter && changes.workspaceFilter.currentValue) {
            this.marketplaceIncluded = this.isProductionCompany && this.marketplaceName.toLocaleLowerCase().indexOf(changes.workspaceFilter.currentValue) > -1;
            this.filterWorkspaces();
            this.popularWorkspaceGroups();

        } else {
            this.filteredWorkspaces = [...this.workspaces];
            this.marketplaceIncluded = this.isProductionCompany;
            this.popularWorkspaceGroups();
        }

    }

    private filterProjects() {
        if (!this.projects || this.projects.length == 0)
            return;

        const filtered = this.projects.filter(p => p.name &&
            HelperService.getNormalizedName(p.name).indexOf(HelperService.getNormalizedName(this.projectFilter)) > -1);
        filtered.sort((p1, p2) => (p1.name || '').localeCompare(p2.name || ''));
        this.filteredProjects = filtered;

        filtered.forEach((project: any) => {
            project.isFree = project?.company?.id == this.subscription?.companyId && this.subscription?.planType == 'FREE';
        });
    }

    private filterWorkspaces() {
        if (!this.workspaces || this.workspaces.length == 0)
            return;
        this.filteredWorkspaces = this.workspaces.filter(w => (w.name && HelperService.getNormalizedName(w.name).indexOf(HelperService.getNormalizedName(this.workspaceFilter)) > -1)
            || (w.company && w.company.name && HelperService.getNormalizedName(w.company.name).indexOf(HelperService.getNormalizedName(this.workspaceFilter)) > -1));
    }


    private popularWorkspaceGroups() {
        if (!this.filteredWorkspaces) {
            this.myWorkspaces = [];
            this.otherWorkspaces = [];
            return;
        }

        this.myWorkspaces = this.filteredWorkspaces.filter(w => w.companyId == this.authUser.company.id);
        this.otherWorkspaces = this.filteredWorkspaces.filter(w => w.companyId != this.authUser.company.id);
    }

    setActiveWorkspace(workspace: any) {
        this.workspaceService.setActiveWorkspace(workspace);
        localStorage.setItem('workspace',workspace.id);
		localStorage.setItem('workspaceName',workspace.name);
        this.workspaceService.changeStatus(true);
        this.workspaceService.changeStatus(true);
    }

    setMarketplace() {
        this.workspaceService.setInMarketplace(true);
    }

    goToSettings(workspace) {
        this.router.navigate([`/workspace/edit/${workspace.id}`]);
    }

    newWorkspace() {
        this.router.navigate(['/workspace/new']);
    }

	updateProjectStatus(e) {
		let project = this.filteredProjects.find(p => p.id == e.projectId), args: any = { projectId: project.id };
		args.status = e.status;
		if (e.completionDate) {
			args.completionDate = moment(e.completionDate).format('DD-MM-yyyy');
		}
		this.projectService.updateProjectStatus(args)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				project.status = e.status;
				project.completionDate = e.completionDate;
			}, err => {
				this.overlayService.showError(JSON.stringify(err));
			});
	}
}
