import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from 'app/shared/services/project.service';
import { SubtitleGenerationService } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'file-preview-modal-component',
	styleUrls: ['./file-preview-modal.component.scss'],
	templateUrl: './file-preview-modal.component.html'
})
export class FilePreviewModalComponent implements OnInit {

	isUrl: boolean = false;
	ngUnsubscribe = new Subject();

	constructor(private projectService: ProjectService, private service: SubtitleGenerationService) { }
	private _fileWidth: number = 0;
	private _fileHeight: number = 0;
	private _file: any;
	private _directPreview: boolean = false;
	private playerPaddingTop: string | null = null;

	get file(): any {
		return this._file;
	}

	//this is for specific handling if metadata is not available
	@Input() fileType: string;

	@Input()
	set file(value: any) {
		this._file = value;

		let width = 0;
		let height = 0;
		if (value.metadata) {
			try {
				const metadata = JSON.parse(value.metadata)[0];
				if (metadata) {
					width = metadata.width;
					height = metadata.height;
				}
			} catch (e) {

			}
		} else if (value.transcodeMetadata?.length) {
			try {
				const { resolution } = value.transcodeMetadata[0];
				let [w, h] = resolution.split('x');
				width = parseInt(w, 10);
				height = parseInt(h, 10);
			} catch (e) {

			}
		} else if (typeof value == 'string' && this.fileType == 'image') {
			const img = new Image();
			img.onload = () => {
				this.fileWidth = img.width;
				this.fileHeight = img.height;
			}
			img.src = value;
		}

		if (width && height) {
			this.fileWidth = width;
			this.fileHeight = height;
		} else if (value.contentType == 'application/pdf') {
			this.fileWidth = window.innerWidth * 0.9;
			this.fileHeight = window.innerHeight * 0.9;
		}
	}

	get fileWidth(): number {
		return this._fileWidth;
	}

	@Input()
	set fileWidth(value: number) {
		this._fileWidth = value;
		this.calculateDisplay();
	}

	get fileHeight(): number {
		return this._fileHeight;
	}

	@Input()
	set directPreview (value: boolean) {
		this._directPreview = value;
		this.calculateDisplay();
	}

	get directPreview(): boolean {
		return this._directPreview;
	}

	@Input()
	set fileHeight(value: number) {
		this._fileHeight = value;
		this.calculateDisplay();
	}

	displayWidth: number = 0;
	displayHeight: number = 0;

	ngOnInit() {
		if (typeof this.file == 'object' && this.file) {
			if (!this.file.signedURL && this.file.id) {
				this.service
					.getSignedUrlInfo(this.projectService.project.id, this.file.id)
					.pipe(
						takeUntil(this.ngUnsubscribe)
					)
					.subscribe((res: any) => {
						this.isUrl = !this.isUrl;
						this.file.signedURL = res.second;
					})
			} else {
				this.isUrl = !this.isUrl;
			}
		}
	}


	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	calculateDisplay() {
		if (!this.fileWidth || !this.fileHeight) {
			return;
		}

		const ratio = this.fileWidth / this.fileHeight;
		const availableWidth = window.innerWidth * 0.9;
		const availableHeight = window.innerHeight * 0.9;

		if (this.fileWidth <= availableWidth && this.fileHeight <= availableHeight) {
			this.displayWidth = this.fileWidth;
			this.displayHeight = this.fileHeight;
			this.calculatePaddingTop();
			return;
		}

		if (ratio > 1) {
			this.displayWidth = availableWidth;
			this.displayHeight = this.displayWidth / ratio;

			if (this.displayHeight > availableHeight) {
				this.displayHeight = availableHeight;
				this.displayWidth = this.displayHeight * ratio;
			}
		} else {
			this.displayHeight = availableHeight;
			this.displayWidth = this.displayHeight * ratio;
		}

		this.calculatePaddingTop();
	}

	//this applies to video player only as video player has default padding top which needs to be overriden
	private calculatePaddingTop() {
		const playerPadding = this.displayHeight / this.displayWidth * 100;
		this.playerPaddingTop = `${playerPadding}%`;
	}

}
