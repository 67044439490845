import { Component, OnInit, Input, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'mtm-popup-media-player',
	templateUrl: './popup-media-player.component.html',
	styleUrls: ['./popup-media-player.component.scss']
})
export class PopupMediaPlayerComponent implements OnInit {

	@Input() file; any;

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit() {
		console.log(this.file);
	}
}
