<div class="cardContainer">
	<div class="cardDetailsContainer" style="width: 500px; height: 150px">
		<div class="modal-header modalpading pt-1 pb-0">
			<div class="column w-100">
				<h4 class="projectHeader">{{'chooseLanguage' | translate}}</h4>
			</div>
		</div>
		<br>
		<div class="row" style="margin-top: -15px;">
			<div class="col-6">
				<div class="cardDetails" style="margin-right: 50px; margin-top: 20px">
					<button class="btn btn-small" (click)="changeLanguage('en_us')">English</button>
				</div>

			</div>
			<div class="col-6">
				<div class="cardDetails" style="margin-right: 50px; margin-top: 20px">
					<button class="btn btn-small" (click)="changeLanguage('fr')">French</button>
				</div>
			</div>
		</div>
	</div>
</div>