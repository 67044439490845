import { Pipe, PipeTransform } from '@angular/core';

export enum ProjectSortingCriteria {
    ProjectName = 'PROJECTNAME',
    CompanyName = 'COMPANYNAME',
    StartDate = 'STARTDATE',
    DueDate = 'DUEDATE'
}

@Pipe({
    name: 'ProjectSorting'
})
export class ProjectSortingPipe implements PipeTransform {
    transform(items: any[], args: any[]): any[] {
        if (args.length <= 0 || !args[0])
            return items;

        let filterList = JSON.parse(JSON.stringify(items));

        let indexAddButton = filterList.findIndex(k => k?.localId == null);
        if (indexAddButton >= 0)
            filterList.splice(indexAddButton, 1);

        switch (args[0].btn) {
            case ProjectSortingCriteria.ProjectName: filterList.sort((a, b) => this.filterFunc(a.name.toLowerCase(), b.name.toLowerCase()));
                break;
            case ProjectSortingCriteria.CompanyName: filterList.sort((a, b) => this.filterFunc(a.company.name.toLowerCase(), b.company.name.toLowerCase()));
                break;
            case ProjectSortingCriteria.StartDate: filterList.sort((a, b) => this.filterFunc(a.startDate, b.startDate));
                break;
            case ProjectSortingCriteria.DueDate: filterList.sort((a, b) => this.filterFunc(a.plannedEndDate, b.plannedEndDate));
                break;
        }

        if (args[0].sortDirection)
            filterList.reverse();

        if (indexAddButton >= 0) {
            let list = [{ localId: null }];
            filterList.map(k => list.push(k));
            return list;
        }

        return filterList;
    }

    filterFunc = (itemA, itemB): number => {
        if (itemA < itemB)
            return 1;
        else if (itemA > itemB)
            return -1;
        else
            return 0;
    }
}
