import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DateHelperService } from "../services/date-helper.service";
import * as moment from 'moment';
import { HelperService } from '../services/helper.service';
import { AuthService } from '../services/auth.service';

@Pipe({
    name: 'archivedProjectFilter'
})

export class archivedProjectFilterPipe implements PipeTransform {
    //     transform(items: any[], searchText: string): any[] {
    //         if (!items) return [];
    //         if (!searchText) return items;
    //         return items.filter(item => {
    //             return String(item.name).toLowerCase().includes(searchText.toLowerCase());
    //         });
    //     }
    // }

    private startDatePre: any;
    private startDateLast: any;
    private dueDatePre: any;
    private dueDateLast: any;
    private airDatePre: any;
    private airDateLast: any;
    private budgetDownLimit: any;
    private budgetUpLimit: any;
    constructor(private authService: AuthService) {}
    transform(items: any[], args: any[]): any[] {
        if (args[enmFilterParameterIndex.enmStartDatePre])
            this.startDatePre = this.getTimesmap(args[enmFilterParameterIndex.enmStartDatePre]);
        else
            this.startDatePre = null;

        if (args[enmFilterParameterIndex.enmStartDateLast])
            this.startDateLast = this.getTimesmap(args[enmFilterParameterIndex.enmStartDateLast]);
        else
            this.startDateLast = null;


        if (args[enmFilterParameterIndex.enmDueDatePre])
            this.dueDatePre = this.getTimesmap(args[enmFilterParameterIndex.enmDueDatePre]);
        else
            this.dueDatePre = null;

        if (args[enmFilterParameterIndex.enmDueDateLast])
            this.dueDateLast = this.getTimesmap(args[enmFilterParameterIndex.enmDueDateLast]);
        else
            this.dueDateLast = null;


        if (args[enmFilterParameterIndex.enmAirDatePre])
            this.airDatePre = this.getTimesmap(args[enmFilterParameterIndex.enmAirDatePre]);
        else
            this.airDatePre = null;

        if (args[enmFilterParameterIndex.enmAirDateLast])
            this.airDateLast = this.getTimesmap(args[enmFilterParameterIndex.enmAirDateLast]);
        else
            this.airDateLast = null;

        this.budgetDownLimit = args[enmFilterParameterIndex.enmBudgetDownLimit];
        this.budgetUpLimit = args[enmFilterParameterIndex.enmBudgetUpLimit];
        let filtered = items.filter(item =>
            HelperService.getNormalizedName(item.name).indexOf(HelperService.getNormalizedName(args[enmFilterParameterIndex.enmName])) !== -1 &&
            HelperService.getNormalizedName(item.company.name).indexOf(HelperService.getNormalizedName(args[enmFilterParameterIndex.enmCompanyName])) !== -1 &&
            this.controlDate(item, this.startDatePre, this.startDateLast) &&
            this.controlDate(item, this.dueDatePre, this.dueDateLast) &&
            this.controlDate(item, this.airDatePre, this.airDateLast) &&
            this.controlBudget(item) &&
            this.controlTag(item.tags, args)

        );

        const firstArr = items?.length && items[0] ? [items[0]]: [];

        return [...firstArr, ...filtered];
    }
    private controlDate(item: any, datePre: any, dateLast: any): boolean {
        if (!datePre && !dateLast)
            return true;
        else if (datePre && dateLast) {
            if (parseInt(item.startDate) > datePre && parseInt(item.startDate) < dateLast)
                return true;
            else
                return false;
        } else if (datePre) {
            if (parseInt(item.startDate) > datePre)
                return true;
            else
                return false;
        } else if (dateLast) {
            if (parseInt(item.startDate) < dateLast)
                return true;
            else
                return false;
        } else
            return true;
    }

    private controlBudget(item: any): boolean {
        if (!this.budgetDownLimit && !this.budgetUpLimit)
            return true;
        else if (this.budgetDownLimit && this.budgetUpLimit) {
            if (parseInt(item.budget) >= this.budgetDownLimit && parseInt(item.budget) <= this.budgetUpLimit)
                return true;
            else
                return false;
        } else if (this.budgetDownLimit) {
            if (parseInt(item.budget) >= this.budgetDownLimit)
                return true;
            else
                return false;
        } else if (this.budgetUpLimit) {
            if (parseInt(item.budget) <= this.budgetUpLimit)
                return true;
            else
                return false;
        } else
            return true;
    }

    private getTimesmap(date: any) {
        if (!date)
            return "";
        const { timeFormat = '12hs' } = this.authService.getAuthUserSettings();
        let formattedTime = 'hh:mm a';
        if (timeFormat === '24hs') {
            formattedTime = 'HH:mm';
        }
		let dateFormat = this.authService.getUserSettings('dateFormat');
		if (!dateFormat)
			dateFormat = 'YYYY-MM-DD';
        return new Date(
            moment(new Date(), formattedTime).year(date.year).month(date.month - 1).date(date.day).utcOffset(0).format(dateFormat)
        ).getTime();
    }

    private controlTag(tags, args) {
        if (tags) {
            return tags.map(i => i.toLowerCase()).some((i, v, a) => {
                return i.indexOf(args[enmFilterParameterIndex.enmTag].toLowerCase()) !== -1 || args[enmFilterParameterIndex.enmTag].toLowerCase() == "";
            })
        }
        else if (!tags && args[enmFilterParameterIndex.enmTag].toLowerCase() !== "") {
            return false;
        }
        else {
            return true;
        }
    }
}


export enum enmFilterParameterIndex {
    enmName = 0,
    enmCompanyName = 1,
    enmStartDatePre,
    enmStartDateLast,
    enmDueDatePre,
    enmDueDateLast,
    enmAirDatePre,
    enmAirDateLast,
    enmBudgetDownLimit,
    enmBudgetUpLimit,
    enmTag
}
