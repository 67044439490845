<div class="user-permission-table-header-column" #roleCell>
    <div class="d-flex align-items-center justify-content-center" (click)="onSortRequested($event)">
        <div class="header-label mb-1">{{ params.displayName }}</div>
        <div class="d-flex align-items-center order-label ml-2" *ngIf="params.enableSorting">
            <i class="fa"
                [ngClass]="{ 'fa-sort-asc': order==='asc', 'fa-sort-desc': order==='desc', 'fa-sort':!order }"></i>
        </div>
    </div>
    <div class="search-element-container">
        <div *ngIf="colId==='username'">
            <input [(ngModel)]="params.context.componentParent.emailFilter" class="form-control p-1 filter"
                (ngModelChange)="params.context.componentParent.filterUsers()" autocomplete="off" type="text"
                placeholder="{{'username' | translate }}">
        </div>
        <div *ngIf="colId==='firstName'">
            <input [(ngModel)]="params.context.componentParent.firstNameFilter" class="form-control p-1 filter"
                (ngModelChange)="params.context.componentParent.filterUsers()" autocomplete="off" type="text"
                placeholder="{{'firstName' | translate }}">
        </div>
        <div *ngIf="colId==='lastName'">
            <input [(ngModel)]="params.context.componentParent.lastNameFilter" class="form-control p-1 filter"
                (ngModelChange)="params.context.componentParent.filterUsers()" autocomplete="off" type="text"
                placeholder="{{'lastName' | translate }}">
        </div>
        <div *ngIf="colId==='roleLabel'">
            <mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select'" [emptyListInfoText]="'Select roles...'"
                [dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
                [items]=params.context.componentParent.availableRoles [itemValueAccessor]="'code'" [itemLabelAccessor]="'label'"
                [isMultipleSelect]=true [refObject]=params.context.componentParent.selectedRoles (onChangeValue)="params.context.componentParent.filterUsers()"
                [hideSelectedItem]="true" [objectVariableAccessor]="'roles'" (open)="dropdownOpen($event)">
            </mtm-dropdown>
        </div>
    </div>
</div>