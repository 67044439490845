import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const subscription = this.auth.getAuthUserSubscription();
		if (!HelperService.isObjectEmpty(subscription) && subscription.planType) {
			this.route.navigate(['subscription/upgrade']);
		} else {
			return true;
		}
	}

}
