import * as _ from 'lodash';
import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import Emitter from 'quill/core/emitter';
import { ProjectService } from "app/shared/services/project.service";
import { EventEmitter } from '@angular/core';
import { EmitterService } from 'app/shared/services/emitter.service';
import { HelperService } from 'app/shared/services/helper.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommentEditFocusEvent, CommentReplyFocusEvent } from "app/shared/models";
import Quill from 'quill';
import QuillEmoji from 'quill-emoji';
import Mention from 'quill-mention';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoRecorderModalComponent } from './video-recorder-modal/video-recorder-modal.component';
import { AudioRecorderModalComponent } from './audio-recorder-modal/audio-recorder-modal.component';
import { TimelineService } from 'app/shared/services/timeline.service';
import { AnnotationPrivateCondition } from "../../../shared/interfaces";
import { AnnotationService } from 'app/shared/services/annotation.service';

Quill.register('modules/emoji-shortname', QuillEmoji.ShortNameEmoji);
Quill.register('modules/mention', Mention);

@Component({
  selector: 'mtm-text-editor',
  templateUrl: './mtm-text-editor.component.html',
  styleUrls: ['./mtm-text-editor.component.scss']
})
export class MtmTextEditor implements OnInit, DoCheck, OnChanges, OnDestroy {
  @ViewChild('editor', { static: false }) editor: any;
  @ViewChild('mtmUploadFileExplorer', { static: true }) mtmUploadFileExplorer: any;

  @Input() conversationId: any;
  @Input() projectId: any;
  @Input() sectionId: any;
  @Input() subSectionId: any;
  @Input() commentId: any;
  @Input() placeholder: any = 'writeComment';
  @Input() isPreview: boolean = false;
  @Input() showToolbar: boolean = false;
  @Input() participants: any = [];
  @Input() conversations: any = [];
  @Input() dropUp: boolean = true;
  @Input() elementId: any = this.guid();
  @Input() backgroundColor: any = 'transparent';
  @Input() maxHeight: any = '53px';
  @Input() mtmUploadFile: any;
  @Input() newLineOnEnter: boolean;
  @Input() graySmile: boolean;
  @Input() expandable: boolean = false;
  @Input() isAnnotationEditor: boolean = false;
  @Input() isTaskComment: boolean = false;
  @Input() userAvatar: any;
  @Input() relatedCommentId: string = '';  //tie editor to comment
  @Input() quillActive: boolean;
  @Input() showBottomToolbar: boolean = false;
  @Input() hideQuillToolbar: boolean = false;
  @Input() files: any = [];
  @Input() videoRecordFiles: any = [];
  @Input() audioRecordFiles: any = [];
  @Input() supportPrivateComment: boolean = false;
  @Input() privateCondition: AnnotationPrivateCondition = null;
  @Output() privateConditionChange: EventEmitter<AnnotationPrivateCondition> = new EventEmitter<AnnotationPrivateCondition>();

  privateConditionSelect: HTMLSelectElement| null = null;
  attachmentsValue: any;

  @Input()
  get attachments() {
    return this.attachmentsValue;
  }

  @Output() attachmentsChange = new EventEmitter();

  set attachments(val) {
    this.attachmentsValue = val;
    this.attachmentsChange.emit(this.attachmentsValue);
  }

  isEmojiPickerVisible: boolean = false;
  modelValue: any;

  @Input()
  get value() {
    return this.modelValue;
  };

  @Output() valueChange = new EventEmitter();

  set value(val) {
    this.modelValue = val;
    this.valueChange.emit(this.modelValue);
  };

  @Output() enterKeydownPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() enterKeyPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFileCallback: EventEmitter<any> = new EventEmitter();
  @Output() deleteFileCallback: EventEmitter<any> = new EventEmitter();

  setPrivateCondition(value: AnnotationPrivateCondition) {
    this.privateCondition = value;
    this.privateConditionChange.emit(value);
  }

  project: any;
  mentionConfig: any;
  isMentionDataOpened: boolean = false;
  lastTextAreaSelection = {
    start: 0,
    end: 0
  };
  taggedGroups: any = [];
  mentionedUsers: any = [];
  isEmojiIconListOpen: boolean = false;

  quillModule: any;
  quillEditorInstance: any;

  ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private elementRef: ElementRef,
    private projectService: ProjectService,
    private modalService: NgbModal,
    private timelineService: TimelineService,
    private annotationService: AnnotationService,
  ) {

  }

  ngOnInit() {
    this.initQuillModule();
    EmitterService.get(CommentReplyFocusEvent).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const { parentId: parentCommentId } = data;
        if (parentCommentId === this.relatedCommentId) {
          setTimeout(() => {
            if (this.quillActive) {
              this.editor.elementRef.nativeElement.focus();
            } else {
              this.editor.nativeElement.focus();
            }
          }, 0);
        }
      });
    EmitterService.get(CommentEditFocusEvent).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const { commentId } = data;
        if (commentId === this.relatedCommentId) {
          setTimeout(() => {
            if (this.quillActive) {
              this.editor.elementRef.nativeElement.focus();
            } else {
              this.editor.nativeElement.focus();
            }
          }, 0);
        }
      });

      this.annotationService.privateConditionChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((privateCondition: AnnotationPrivateCondition) => {
        if(this.privateCondition != privateCondition) {
          this.privateCondition = privateCondition;
          if(this.privateConditionSelect){
            this.privateConditionSelect.value = this.privateCondition || '';
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    ;
    this.ngUnsubscribe.complete();
    this.privateConditionSelect = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.quillActive) {
      if (changes.value) {
        if (changes.value.firstChange) {
          this.project = this.projectService.project;
          this.mentionConfig = {
            mentions: this.getMentionData()
          };
          if (this.editor) {
            this.editor.nativeElement.innerHTML = this.parseToReadOnlyChatMessage(_.get(changes.value, 'currentValue', ''));
          }
        } else if (!changes.value.currentValue) {
          if (this.editor) {
            this.editor.nativeElement.innerHTML = this.parseToReadOnlyChatMessage(_.get(changes.value, 'currentValue', ''));
          }
        }
      }
      if (changes.conversations && changes.conversations.currentValue) {
        this.project = {
          ...this.project || {}, ...{
            conversations: this.conversations
          }
        };
        this.mentionConfig = {
          mentions: this.getMentionData()
        };
      }
      if (changes.participants && changes.participants.currentValue) {
        this.project = {
          ...this.project || {}, ...{
            participants: this.participants
          }
        };
        this.mentionConfig = {
          mentions: this.getMentionData()
        };
      }
    }
  }

  ngDoCheck(): void {
    let container = $(this.elementRef.nativeElement);
    let mentionMenuHidden = container.find('.mention-menu[hidden]');
    let mentionMenus = container.find('.mention-menu li');
    this.isMentionDataOpened = (mentionMenus.length > 0) && !mentionMenuHidden.length;
  }

  getSelectedText() {
    let txt;
    if (window.getSelection) {
      txt = window.getSelection();
    } else if (window.document.getSelection) {
      txt = window.document.getSelection();
    } else if ((window.document as any).selection) {
      txt = (window.document as any).selection.createRange().text;
    }
    return txt;
  }

  initQuillModule() {
    let __this = this;
    this.quillModule = {
      toolbar: this.hideQuillToolbar ? false : {
        container: [
          ['bold', 'italic', 'underline'],
          ['link'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['blockquote', 'code-block'],
          ['emoji']
        ],
        handlers: {
          link: (value) => {
            if (value) {
              const editorInstance = this.quillEditorInstance;
              let range = editorInstance.selection;
              if (range.savedRange == null || range.savedRange.length === 0) return;
              let preview = __this.getSelectedText();
              const tooltip = editorInstance.theme.tooltip;
              tooltip.save = function () {
                let val = tooltip.textbox.value;
                if (val.indexOf('https') === -1 && val.indexOf('http') === -1) {
                  val = 'https://' + val;
                }
                this.quill.format('link', val, Emitter.sources.USER);
              };
              tooltip.edit("link", preview);
            }
          }
        }
      },
      'emoji-toolbar': true,
      keyboard: {
        bindings: {
          linebreak: {
            key: 13,
            shiftKey: false,
            handler: function () {
              __this.enterKeyPress.emit();
            }
          }
        }
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        source: (searchTerm, renderList, mentionChar) => {
          let values;
          if (mentionChar === "@") {
            values = _.filter(_.map(_.filter(__this.projectService.project.participants, (usr) => _.get(usr, 'roles[0]', '') !== 'COMPANY_OCCASIONAL'), (participant) => {
              return {
                id: participant.username,
                value: participant.fullName,
                type: 'one_on_one'
              };
            }), (data) => !HelperService.isObjectEmpty(data.value.trim()));
          } else {
            values = _.map(_.filter(__this.projectService.project.conversations, (conversation) => conversation.type !== 'ONE_TO_ONE'), (conversation) => {
              return {
                id: conversation.id,
                value: conversation.title,
                type: 'group',
                users: conversation.users
              };
            });
            ;
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~HelperService.getNormalizedName(values[i].value).toLowerCase().indexOf(HelperService.getNormalizedName(searchTerm).toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        }
      }
    }
  }

  onEditorInitialized(editor: any) {
    this.quillEditorInstance = editor;

    if (this.supportPrivateComment) {
      this.injectCommentVisibilityDropdown();
    }
  }

  private injectCommentVisibilityDropdown() {
    const select = document.createElement('select');
    const publicOption = new Option('Public', '');
    publicOption.setAttribute('title', 'All project members');
    select.appendChild(publicOption);
    const domainOption = new Option('Private', 'DOMAIN');
    domainOption.setAttribute('title', 'Company members only');
    select.appendChild(domainOption);
    const companyOption = new Option('Private Extended', 'COMPANY');
    companyOption.setAttribute('title', 'Company members and Affiliates');
    select.appendChild(companyOption);
    select.classList.add('d-inline-block', 'comment-visibility');


    switch (this.privateCondition) {
      case 'COMPANY':
      case 'DOMAIN':
        select.value = this.privateCondition;
        break;
      default:
        select.value = '';
        break;
    }

    const selectRef = select as any;
    selectRef.toolbar = this.quillEditorInstance.getModule('toolbar')
    selectRef.toolbarEl = selectRef.toolbar.container
    selectRef.toolbarEl.insertBefore(select, selectRef.toolbarEl.firstChild);
    this.privateConditionSelect = select;

    $(select).on('change', (event) => {
      const value = $(select).val().toString();
      if(value == this.privateCondition) {
        console.log('change triggered but value is same');
        return;
      }
      if (value) {
        this.setPrivateCondition(<AnnotationPrivateCondition>value);
      } else {
        this.setPrivateCondition(null);
      }
    }).on('click', (event) => {
      event.stopPropagation();
    });
  }

  onKeydownEnterPress($event: any) {
    if (this.newLineOnEnter) {
      return true;
    }
    if (this.isMentionDataOpened) {
      return;
    }
    $event.preventDefault();
    this.editor.nativeElement.innerHTML = '';
    this.enterKeyPress.emit($event);
  }

  getMentionData() {
    return [{
      items: this.generateUserListData(),
      dropUp: this.dropUp,
      labelKey: 'label',
      data: this.generateUserListData(),
      triggerChar: "@",
      allowSpace: false,
			mentionFilter: (query: any) => {
				const allData = this.generateUserListData();
				return allData.filter(d => HelperService.getNormalizedName(d.value).indexOf(HelperService.getNormalizedName(query)) > -1);
			},
      mentionSelect: (args: any) => {
        this.isMentionDataOpened = false;
        return '@{{' + args.value + ':' + args.label + '}}';
      }
    }, {
      items: this.generateConversationListData(),
      dropUp: this.dropUp,
      labelKey: 'label',
      data: this.generateConversationListData(),
      triggerChar: "#",
      allowSpace: false,
			mentionFilter: (query: any) => {
				const allData = this.generateConversationListData();
				return allData.filter(d => HelperService.getNormalizedName(d.value).indexOf(HelperService.getNormalizedName(query)) > -1);
			},
      mentionSelect: (args: any) => {
        this.isMentionDataOpened = false;
        return '#{{' + args.value + ':' + args.label + '}}';
      }
    }]
  }


  generateUserListData() {
    return _.filter(_.map(_.filter(this.project.participants, (usr) => _.get(usr, 'roles[0]', '') !== 'COMPANY_OCCASIONAL'), (participant) => {
      return {
        value: participant.username,
        label: participant.fullName,
        type: 'one_on_one'
      };
    }), (data) => !HelperService.isObjectEmpty(data.label.trim()));
  }

  generateConversationList() {
    let conversations = _.filter(this.project.conversations, (conversation) => conversation.type !== 'ONE_TO_ONE');
    return _.map(conversations, (conversation) => {
      return conversation.title;
    });
  }

  generateConversationListData() {
    let conversations = _.filter(this.project.conversations, (conversation) => conversation.type !== 'ONE_TO_ONE');
    return _.map(conversations, (conversation) => {
      return {
        value: conversation.id,
        label: conversation.title,
        type: 'group',
        users: conversation.users
      };
    });
  }

  onMentionOpened() {
    this.isMentionDataOpened = true;
  }

  onMentionClosed() {
    this.isMentionDataOpened = false;
    this.parseMentionChatMessage();
    this.parseGroupChatMessage();
  }

  parseMentionChatMessage() {
    let mentioned = this.editor.nativeElement.innerHTML.match(/(\@{{.*?\}})/g) || [];
    if (!mentioned.length) {
      return;
    }
    for (let i = 0; i < mentioned.length; i++) {
      let data = (mentioned[i].replace(/[{()}]/g, '')).replace('@', '').split(':');
      let userEmail = data[0];
      let userLabel = data[1];
      let selection = saveSelection()(this.editor.nativeElement);
      let mentionsConfigData = _.find(this.mentionConfig.mentions[0].items, (data) => data.value === userEmail);
      selection = {
        start: selection.start - ((userEmail.length + userLabel.length + 3) - mentionsConfigData.label.length),
        end: selection.start - ((userEmail.length + userLabel.length + 3) - mentionsConfigData.label.length)
      };
      ((data, config) => {
        if (!config) {
          return;
        }
        setTimeout(() => {
          this.editor.nativeElement.innerHTML =
            this.editor.nativeElement.innerHTML.replace(
              data,
              `\uFEFF<span class="mention" data-denotation-char="@" data-id="${config.value}" data-value="${config.label}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${config.label}</span></span>\uFEFF`
            );
          setTimeout(() => {
            restoreSelection()(this.editor.nativeElement, selection);
            this.saveCaretPosition();
          }, 0);
        }, 0);
      })(mentioned[i], mentionsConfigData);
    }
  }

  parseGroupChatMessage() {
    let mentioned = this.editor.nativeElement.innerHTML.match(/(\#{{.*?\}})/g) || [];
    if (!mentioned.length) {
      return;
    }
    for (let i = 0; i < mentioned.length; i++) {
      let data = (mentioned[i].replace(/[{()}]/g, '')).replace('#', '').split(':');
      let groupId = data[0];
      let groupLabel = data[1];
      let selection = saveSelection()(this.editor.nativeElement);
      let mentionsConfigData = _.find(this.mentionConfig.mentions[1].items, (data) => data.value === groupId);
      selection = {
        start: selection.start - ((groupId.length + groupLabel.length + 3) - mentionsConfigData.label.length),
        end: selection.start - ((groupId.length + groupLabel.length + 3) - mentionsConfigData.label.length)
      };
      ((data, config) => {
        if (!config) {
          return;
        }
        setTimeout(() => {
          this.editor.nativeElement.innerHTML =
            this.editor.nativeElement.innerHTML.replace(
              data,
              `\uFEFF<span class="mention" data-denotation-char="#" data-id="${config.value}" data-value="${config.label}"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span>${config.label}</span></span>\uFEFF`
            );
          setTimeout(() => {
            restoreSelection()(this.editor.nativeElement, selection);
            this.saveCaretPosition();
          }, 0);
        }, 0);
      })(mentioned[i], mentionsConfigData);
    }
  }

  // getMessageRawContent(message: any) {
  // 	let tempEl = document.createElement('div');
  // 	tempEl.innerHTML = message;
  // 	let mentionedUsers = tempEl.getElementsByClassName('mentioned-user');
  // 	for (var i = 0; i < mentionedUsers.length; i++) {
  // 		mentionedUsers[i].innerHTML = `@{{${mentionedUsers[i].getAttribute('data-id')}:${mentionedUsers[i].getAttribute('data-label')}}}`
  // 	}
  // 	let taggingGroups = tempEl.getElementsByClassName('mentioned-group');
  // 	for (var i = 0; i < taggingGroups.length; i++) {
  // 		taggingGroups[i].innerHTML = `#{{${taggingGroups[i].getAttribute('data-id')}:${taggingGroups[i].getAttribute('data-label')}}}`
  // 	}
  // 	return message;
  // }

  parseToReadOnlyChatMessage(message: string) {
    if (!message) {
      return '';
    }
    let mentioned = message.match(/(\@{{.*?\}})/g) || [];
    if (mentioned.length) {
      for (let i = 0; i < mentioned.length; i++) {
        let data = (mentioned[i].replace(/[{()}]/g, '')).replace('@', '').split(':');
        let userEmail = data[0];
        let userLabel = data[1];
        let elementClass = _.find(this.mentionConfig.mentions[0].items, (data) => data.value === userEmail) ? 'mentioned-user' : 'mentioned-user user-not-visible';
        message =
          message.replace(
            mentioned[i],
            `\uFEFF<span data-id="${userEmail}" data-label="${userLabel}" contenteditable="false" class="${elementClass} c-mtm-brand">@${userLabel}</span>\uFEFF`
          );
      }
    }
    let tagging = message.match(/(\#{{.*?\}})/g) || [];
    if (tagging.length) {
      for (let i = 0; i < tagging.length; i++) {
        let data = (tagging[i].replace(/[{()}]/g, '')).replace('#', '').split(':');
        let groupId = data[0];
        let groupLabel = data[1];
        let elementClass = _.find(this.mentionConfig.mentions[1].items, (data) => data.value === groupId) ? 'mentioned-group' : 'mentioned-group group-not-visible';
        message =
          message.replace(
            tagging[i],
            `\uFEFF<span data-id="${groupId}" data-label="${groupLabel}" contenteditable="false" class="${elementClass} c-mtm-brand">#${groupLabel}</span>\uFEFF`
          );
      }
    }
    this.bindElementClick();
    return message;
  }

  bindElementClick() {
    setTimeout(() => {
      this.taggedGroups = $(this.elementRef.nativeElement).find('.mentioned-group');
      for (let i = 0; i < this.taggedGroups.length; i++) {
        this.taggedGroups[i].addEventListener('click', this.taggedGroupClickHandler)
      }
    });
    setTimeout(() => {
      this.mentionedUsers = $(this.elementRef.nativeElement).find('.mentioned-user');
      for (let i = 0; i < this.mentionedUsers.length; i++) {
        this.mentionedUsers[i].addEventListener('click', this.mentionedUserClickHandler);
      }
    });
  }

  mentionedUserClickHandler(e) {
    e.stopImmediatePropagation();
    EmitterService.get('OPEN_CHAT').emit({
      type: 'one_on_one',
      username: $(e.target).data('id')
    });
  }

  taggedGroupClickHandler(e) {
    e.stopImmediatePropagation();
    EmitterService.get('OPEN_CHAT').emit({
      type: 'group',
      groupId: $(e.target).data('id')
    });
  }

  saveCaretPosition() {
    // this.timelineService.taskDescription.next(this.editor.nativeElement.innerHTML);
    var target = document.createTextNode("\u0001");
    document.getSelection().getRangeAt(0).insertNode(target);
    let position = this.editor.nativeElement.innerHTML.indexOf("\u0001");
    target.parentNode.removeChild(target);
    this.lastTextAreaSelection = {
      start: position,
      end: position
    }
    // this.value = this.editor.nativeElement.innerHTML;
    //this.getMessageRawContent(this.editor.nativeElement.innerHTML);
  }

  setDescription() {
    this.timelineService.taskDescription.next(this.editor.nativeElement.innerHTML);
  }

  appendContentToEditor(content: any) {
    if (!this.editor.nativeElement.innerHTML) {
      this.editor.nativeElement.innerHTML = content;
      this.lastTextAreaSelection.start = this.lastTextAreaSelection.end = content.length;
    } else {
      let previousContent = this.editor.nativeElement.innerHTML.substring(0, this.lastTextAreaSelection.start);
      let previousContentText = $('<div>').html(previousContent || '').text();
      this.editor.nativeElement.innerHTML = previousContent + content;
      this.lastTextAreaSelection.start = this.lastTextAreaSelection.end = previousContentText.length + content.length;
    }
    restoreSelection()(this.editor.nativeElement, this.lastTextAreaSelection);
    this.saveCaretPosition();
  }

  guid() {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  addEmoji($event) {
    this.editor.nativeElement.innerHTML = `${this.editor.nativeElement.innerHTML}${$event.emoji.native}`;
    this.value = this.editor.nativeElement.innerHTML;
    this.appendContentToEditor($event.emoji.native);
    this.isEmojiPickerVisible = false;
  }

  fileIconClick() {
    this.uploadFileCallback.emit();
  }

  openFileExplorer() {
    const element = this.mtmUploadFileExplorer.fileInput.nativeElement;
    element.click();
  }

  fileChangeListener(file) {
    this.uploadFileCallback.emit(file);
  }

  openCameraPreviewPopup(e) {
    let modal = this.modalService.open(VideoRecorderModalComponent, {
      windowClass: 'video-recorder-modal',
      backdrop: 'static',
      keyboard: false
    });
    modal.result.then(file => {
      if (file) {
        file.method = 'videoRecord';
        this.fileChangeListener({ files: [file] });
      }
    });
  }

  openAudioPopup(e) {
    let modal = this.modalService.open(AudioRecorderModalComponent, {
      windowClass: 'audio-recorder-modal',
      backdrop: 'static',
      keyboard: false
    });
    modal.result.then(file => {
      if (file) {
        file.method = 'audioRecord';
        this.fileChangeListener({ files: [file] });
      }
    });
  }

  deleteFile(e, file) {
    this.deleteFileCallback.emit(file);
  }
}
