export class CountryCodes {
    public allCountries = [
        { countryName: "Afghanistan (‫افغانستان‬‎)", regionCode: "AF", internationalCode: "93" },
        { countryName: "Albania (Shqipëri)", regionCode: "AL", internationalCode: "355" },
        { countryName: "Algeria (‫الجزائر‬‎)", regionCode: "DZ", internationalCode: "213" },
        { countryName: "American Samoa", regionCode: "AS", internationalCode: "1684" },
        { countryName: "Andorra", regionCode: "AD", internationalCode: "376" },
        { countryName: "Angola", regionCode: "AO", internationalCode: "244" },
        { countryName: "Anguilla", regionCode: "AI", internationalCode: "1264" },
        { countryName: "Antigua and Barbuda", regionCode: "AG", internationalCode: "1268" },
        { countryName: "Argentina", regionCode: "AR", internationalCode: "54" },
        { countryName: "Armenia (Հայաստան)", regionCode: "AM", internationalCode: "374" },
        { countryName: "Aruba", regionCode: "AW", internationalCode: "297" },
        { countryName: "Australia", regionCode: "AU", internationalCode: "61" },
        { countryName: "Austria (Österreich)", regionCode: "AT", internationalCode: "43" },
        { countryName: "Azerbaijan (Azərbaycan)", regionCode: "AZ", internationalCode: "994" },
        { countryName: "Bahamas", regionCode: "BS", internationalCode: "1242" },
        { countryName: "Bahrain (‫البحرين‬‎)", regionCode: "BH", internationalCode: "973" },
        { countryName: "Bangladesh (বাংলাদেশ)", regionCode: "BD", internationalCode: "880" },
        { countryName: "Barbados", regionCode: "BB", internationalCode: "1246" },
        { countryName: "Belarus (Беларусь)", regionCode: "BY", internationalCode: "375" },
        { countryName: "Belgium (België)", regionCode: "BE", internationalCode: "32" },
        { countryName: "Belize", regionCode: "BZ", internationalCode: "501" },
        { countryName: "Benin (Bénin)", regionCode: "BJ", internationalCode: "229" },
        { countryName: "Bermuda", regionCode: "BM", internationalCode: "1441" },
        { countryName: "Bhutan (འབྲུག)", regionCode: "BT", internationalCode: "975" },
        { countryName: "Bolivia", regionCode: "BO", internationalCode: "591" },
        { countryName: "Bosnia and Herzegovina (Босна и Херцеговина)", regionCode: "BA", internationalCode: "387" },
        { countryName: "Botswana", regionCode: "BW", internationalCode: "267" },
        { countryName: "Brazil (Brasil)", regionCode: "BR", internationalCode: "55" },
        { countryName: "British Indian Ocean Territory", regionCode: "IO", internationalCode: "246" },
        { countryName: "British Virgin Islands", regionCode: "VG", internationalCode: "1284" },
        { countryName: "Brunei", regionCode: "BN", internationalCode: "673" },
        { countryName: "Bulgaria (България)", regionCode: "BG", internationalCode: "359" },
        { countryName: "Burkina Faso", regionCode: "BF", internationalCode: "226" },
        { countryName: "Burundi (Uburundi)", regionCode: "BI", internationalCode: "257" },
        { countryName: "Cambodia (កម្ពុជា)", regionCode: "KH", internationalCode: "855" },
        { countryName: "Cameroon (Cameroun)", regionCode: "CM", internationalCode: "237" },
        { countryName: "Canada", regionCode: "CA", internationalCode: "1" },
        { countryName: "Cape Verde (Kabu Verdi)", regionCode: "CV", internationalCode: "238" },
        { countryName: "Caribbean Netherlands", regionCode: "BQ", internationalCode: "599" },
        { countryName: "Cayman Islands", regionCode: "KY", internationalCode: "1345" },
        { countryName: "Central African Republic (République centrafricaine)", regionCode: "CF", internationalCode: "236" },
        { countryName: "Chad (Tchad)", regionCode: "TD", internationalCode: "235" },
        { countryName: "Chile", regionCode: "CL", internationalCode: "56" },
        { countryName: "China (中国)", regionCode: "CN", internationalCode: "86" },
        { countryName: "Christmas Island", regionCode: "CX", internationalCode: "61" },
        { countryName: "Cocos (Keeling) Islands", regionCode: "CC", internationalCode: "61" },
        { countryName: "Colombia", regionCode: "CO", internationalCode: "57" },
        { countryName: "Comoros (‫جزر القمر‬‎)", regionCode: "KM", internationalCode: "269" },
        { countryName: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", regionCode: "CD", internationalCode: "243" },
        { countryName: "Congo (Republic) (Congo-Brazzaville)", regionCode: "CG", internationalCode: "242" },
        { countryName: "Cook Islands", regionCode: "CK", internationalCode: "682" },
        { countryName: "Costa Rica", regionCode: "CR", internationalCode: "506" },
        { countryName: "Côte d’Ivoire", regionCode: "CI", internationalCode: "225" },
        { countryName: "Croatia (Hrvatska)", regionCode: "HR", internationalCode: "385" },
        { countryName: "Cuba", regionCode: "CU", internationalCode: "53" },
        { countryName: "Curaçao", regionCode: "CW", internationalCode: "599" },
        { countryName: "Cyprus (Κύπρος)", regionCode: "CY", internationalCode: "357" },
        { countryName: "Czech Republic (Česká republika)", regionCode: "CZ", internationalCode: "420" },
        { countryName: "Denmark (Danmark)", regionCode: "DK", internationalCode: "45" },
        { countryName: "Djibouti", regionCode: "DJ", internationalCode: "253" },
        { countryName: "Dominica", regionCode: "DM", internationalCode: "1767" },
        { countryName: "Dominican Republic (República Dominicana)", regionCode: "DO", internationalCode: "1" },
        { countryName: "Ecuador", regionCode: "EC", internationalCode: "593" },
        { countryName: "Egypt (‫مصر‬‎)", regionCode: "EG", internationalCode: "20" },
        { countryName: "El Salvador", regionCode: "SV", internationalCode: "503" },
        { countryName: "Equatorial Guinea (Guinea Ecuatorial)", regionCode: "GQ", internationalCode: "240" },
        { countryName: "Eritrea", regionCode: "ER", internationalCode: "291" },
        { countryName: "Estonia (Eesti)", regionCode: "EE", internationalCode: "372" },
        { countryName: "Ethiopia", regionCode: "ET", internationalCode: "251" },
        { countryName: "Falkland Islands (Islas Malvinas)", regionCode: "FK", internationalCode: "500" },
        { countryName: "Faroe Islands (Føroyar)", regionCode: "FO", internationalCode: "298" },
        { countryName: "Fiji", regionCode: "FJ", internationalCode: "679" },
        { countryName: "Finland (Suomi)", regionCode: "FI", internationalCode: "358" },
        { countryName: "France", regionCode: "FR", internationalCode: "33" },
        { countryName: "French Guiana (Guyane française)", regionCode: "GF", internationalCode: "594" },
        { countryName: "French Polynesia (Polynésie française)", regionCode: "PF", internationalCode: "689" },
        { countryName: "Gabon", regionCode: "GA", internationalCode: "241" },
        { countryName: "Gambia", regionCode: "GM", internationalCode: "220" },
        { countryName: "Georgia (საქართველო)", regionCode: "GE", internationalCode: "995" },
        { countryName: "Germany (Deutschland)", regionCode: "DE", internationalCode: "49" },
        { countryName: "Ghana (Gaana)", regionCode: "GH", internationalCode: "233" },
        { countryName: "Gibraltar", regionCode: "GI", internationalCode: "350" },
        { countryName: "Greece (Ελλάδα)", regionCode: "GR", internationalCode: "30" },
        { countryName: "Greenland (Kalaallit Nunaat)", regionCode: "GL", internationalCode: "299" },
        { countryName: "Grenada", regionCode: "GD", internationalCode: "1473" },
        { countryName: "Guadeloupe", regionCode: "GP", internationalCode: "590" },
        { countryName: "Guam", regionCode: "GU", internationalCode: "1671" },
        { countryName: "Guatemala", regionCode: "GT", internationalCode: "502" },
        { countryName: "Guernsey", regionCode: "GG", internationalCode: "44" },
        { countryName: "Guinea (Guinée)", regionCode: "GN", internationalCode: "224" },
        { countryName: "Guinea-Bissau (Guiné Bissau)", regionCode: "GW", internationalCode: "245" },
        { countryName: "Guyana", regionCode: "GY", internationalCode: "592" },
        { countryName: "Haiti", regionCode: "HT", internationalCode: "509" },
        { countryName: "Honduras", regionCode: "HN", internationalCode: "504" },
        { countryName: "Hong Kong (香港)", regionCode: "HK", internationalCode: "852" },
        { countryName: "Hungary (Magyarország)", regionCode: "HU", internationalCode: "36" },
        { countryName: "Iceland (Ísland)", regionCode: "IS", internationalCode: "354" },
        { countryName: "India (भारत)", regionCode: "IN", internationalCode: "91" },
        { countryName: "Indonesia", regionCode: "ID", internationalCode: "62" },
        { countryName: "Iran (‫ایران‬‎)", regionCode: "IR", internationalCode: "98" },
        { countryName: "Iraq (‫العراق‬‎)", regionCode: "IQ", internationalCode: "964" },
        { countryName: "Ireland", regionCode: "IE", internationalCode: "353" },
        { countryName: "Isle of Man", regionCode: "IM", internationalCode: "44" },
        { countryName: "Israel (‫ישראל‬‎)", regionCode: "IL", internationalCode: "972" },
        { countryName: "Italy (Italia)", regionCode: "IT", internationalCode: "39" },
        { countryName: "Jamaica", regionCode: "JM", internationalCode: "1876" },
        { countryName: "Japan (日本)", regionCode: "JP", internationalCode: "81" },
        { countryName: "Jersey", regionCode: "JE", internationalCode: "44" },
        { countryName: "Jordan (‫الأردن‬‎)", regionCode: "JO", internationalCode: "962" },
        { countryName: "Kazakhstan (Казахстан)", regionCode: "KZ", internationalCode: "7" },
        { countryName: "Kenya", regionCode: "KE", internationalCode: "254" },
        { countryName: "Kiribati", regionCode: "KI", internationalCode: "686" },
        { countryName: "Kosovo", regionCode: "KX", internationalCode: "383" },
        { countryName: "Kuwait (‫الكويت‬‎)", regionCode: "KW", internationalCode: "965" },
        { countryName: "Kyrgyzstan (Кыргызстан)", regionCode: "KG", internationalCode: "996" },
        { countryName: "Laos (ລາວ)", regionCode: "LA", internationalCode: "856" },
        { countryName: "Latvia (Latvija)", regionCode: "LV", internationalCode: "371" },
        { countryName: "Lebanon (‫لبنان‬‎)", regionCode: "LB", internationalCode: "961" },
        { countryName: "Lesotho", regionCode: "LS", internationalCode: "266" },
        { countryName: "Liberia", regionCode: "LR", internationalCode: "231" },
        { countryName: "Libya (‫ليبيا‬‎)", regionCode: "LY", internationalCode: "218" },
        { countryName: "Liechtenstein", regionCode: "LI", internationalCode: "423" },
        { countryName: "Lithuania (Lietuva)", regionCode: "LT", internationalCode: "370" },
        { countryName: "Luxembourg", regionCode: "LU", internationalCode: "352" },
        { countryName: "Macau (澳門)", regionCode: "MO", internationalCode: "853" },
        { countryName: "Macedonia (FYROM) (Македонија)", regionCode: "MK", internationalCode: "389" },
        { countryName: "Madagascar (Madagasikara)", regionCode: "MG", internationalCode: "261" },
        { countryName: "Malawi", regionCode: "MW", internationalCode: "265" },
        { countryName: "Malaysia", regionCode: "MY", internationalCode: "60" },
        { countryName: "Maldives", regionCode: "MV", internationalCode: "960" },
        { countryName: "Mali", regionCode: "ML", internationalCode: "223" },
        { countryName: "Malta", regionCode: "MT", internationalCode: "356" },
        { countryName: "Marshall Islands", regionCode: "MH", internationalCode: "692" },
        { countryName: "Martinique", regionCode: "MQ", internationalCode: "596" },
        { countryName: "Mauritania (‫موريتانيا‬‎)", regionCode: "MR", internationalCode: "222" },
        { countryName: "Mauritius (Moris)", regionCode: "MU", internationalCode: "230" },
        { countryName: "Mayotte", regionCode: "YT", internationalCode: "262" },
        { countryName: "Mexico (México)", regionCode: "MX", internationalCode: "52" },
        { countryName: "Micronesia", regionCode: "FM", internationalCode: "691" },
        { countryName: "Moldova (Republica Moldova)", regionCode: "MD", internationalCode: "373" },
        { countryName: "Monaco", regionCode: "MC", internationalCode: "377" },
        { countryName: "Mongolia (Монгол)", regionCode: "MN", internationalCode: "976" },
        { countryName: "Montenegro (Crna Gora)", regionCode: "ME", internationalCode: "382" },
        { countryName: "Montserrat", regionCode: "MS", internationalCode: "1664" },
        { countryName: "Morocco (‫المغرب‬‎)", regionCode: "MA", internationalCode: "212" },
        { countryName: "Mozambique (Moçambique)", regionCode: "MZ", internationalCode: "258" },
        { countryName: "Myanmar (Burma) (မြန်မာ)", regionCode: "MM", internationalCode: "95" },
        { countryName: "Namibia (Namibië)", regionCode: "NA", internationalCode: "264" },
        { countryName: "Nauru", regionCode: "NR", internationalCode: "674" },
        { countryName: "Nepal (नेपाल)", regionCode: "NP", internationalCode: "977" },
        { countryName: "Netherlands (Nederland)", regionCode: "NL", internationalCode: "31" },
        { countryName: "New Caledonia (Nouvelle-Calédonie)", regionCode: "NC", internationalCode: "687" },
        { countryName: "New Zealand", regionCode: "NZ", internationalCode: "64" },
        { countryName: "Nicaragua", regionCode: "NI", internationalCode: "505" },
        { countryName: "Niger (Nijar)", regionCode: "NE", internationalCode: "227" },
        { countryName: "Nigeria", regionCode: "NG", internationalCode: "234" },
        { countryName: "Niue", regionCode: "NU", internationalCode: "683" },
        { countryName: "Norfolk Island", regionCode: "NF", internationalCode: "672" },
        { countryName: "North Korea (조선 민주주의 인민 공화국)", regionCode: "KP", internationalCode: "850" },
        { countryName: "Northern Mariana Islands", regionCode: "MP", internationalCode: "1670" },
        { countryName: "Norway (Norge)", regionCode: "NO", internationalCode: "47" },
        { countryName: "Oman (‫عُمان‬‎)", regionCode: "OM", internationalCode: "968" },
        { countryName: "Pakistan (‫پاکستان‬‎)", regionCode: "PK", internationalCode: "92" },
        { countryName: "Palau", regionCode: "PW", internationalCode: "680" },
        { countryName: "Palestine (‫فلسطين‬‎)", regionCode: "PS", internationalCode: "970" },
        { countryName: "Panama (Panamá)", regionCode: "PA", internationalCode: "507" },
        { countryName: "Papua New Guinea", regionCode: "PG", internationalCode: "675" },
        { countryName: "Paraguay", regionCode: "PY", internationalCode: "595" },
        { countryName: "Peru (Perú)", regionCode: "PE", internationalCode: "51" },
        { countryName: "Philippines", regionCode: "PH", internationalCode: "63" },
        { countryName: "Poland (Polska)", regionCode: "PL", internationalCode: "48" },
        { countryName: "Portugal", regionCode: "PT", internationalCode: "351" },
        { countryName: "Puerto Rico", regionCode: "PR", internationalCode: "1" },
        { countryName: "Qatar (‫قطر‬‎)", regionCode: "QA", internationalCode: "974" },
        { countryName: "Réunion (La Réunion)", regionCode: "RE", internationalCode: "262" },
        { countryName: "Romania (România)", regionCode: "RO", internationalCode: "40" },
        { countryName: "Russia (Россия)", regionCode: "RU", internationalCode: "7" },
        { countryName: "Rwanda", regionCode: "RW", internationalCode: "250" },
        { countryName: "Saint Barthélemy (Saint-Barthélemy)", regionCode: "BL", internationalCode: "590" },
        { countryName: "Saint Helena", regionCode: "SH", internationalCode: "290" },
        { countryName: "Saint Kitts and Nevis", regionCode: "KN", internationalCode: "1869" },
        { countryName: "Saint Lucia", regionCode: "LC", internationalCode: "1758" },
        { countryName: "Saint Martin (Saint-Martin (partie française))", regionCode: "MF", internationalCode: "590" },
        { countryName: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", regionCode: "PM", internationalCode: "508" },
        { countryName: "Saint Vincent and the Grenadines", regionCode: "VC", internationalCode: "1784" },
        { countryName: "Samoa", regionCode: "WS", internationalCode: "685" },
        { countryName: "San Marino", regionCode: "SM", internationalCode: "378" },
        { countryName: "São Tomé and Príncipe (São Tomé e Príncipe)", regionCode: "ST", internationalCode: "239" },
        { countryName: "Saudi Arabia (‫المملكة العربية السعودية‬‎)", regionCode: "SA", internationalCode: "966" },
        { countryName: "Senegal (Sénégal)", regionCode: "SN", internationalCode: "221" },
        { countryName: "Serbia (Србија)", regionCode: "RS", internationalCode: "381" },
        { countryName: "Seychelles", regionCode: "SC", internationalCode: "248" },
        { countryName: "Sierra Leone", regionCode: "SL", internationalCode: "232" },
        { countryName: "Singapore", regionCode: "SG", internationalCode: "65" },
        { countryName: "Sint Maarten", regionCode: "SX", internationalCode: "1721" },
        { countryName: "Slovakia (Slovensko)", regionCode: "SK", internationalCode: "421" },
        { countryName: "Slovenia (Slovenija)", regionCode: "SI", internationalCode: "386" },
        { countryName: "Solomon Islands", regionCode: "SB", internationalCode: "677" },
        { countryName: "Somalia (Soomaaliya)", regionCode: "SO", internationalCode: "252" },
        { countryName: "South Africa", regionCode: "ZA", internationalCode: "27" },
        { countryName: "South Korea (대한민국)", regionCode: "KR", internationalCode: "82" },
        { countryName: "South Sudan (‫جنوب السودان‬‎)", regionCode: "SS", internationalCode: "211" },
        { countryName: "Spain (España)", regionCode: "ES", internationalCode: "34" },
        { countryName: "Sri Lanka (ශ්‍රී ලංකාව)", regionCode: "LK", internationalCode: "94" },
        { countryName: "Sudan (‫السودان‬‎)", regionCode: "SD", internationalCode: "249" },
        { countryName: "Suriname", regionCode: "SR", internationalCode: "597" },
        { countryName: "Svalbard and Jan Mayen", regionCode: "SJ", internationalCode: "47" },
        { countryName: "Swaziland", regionCode: "SZ", internationalCode: "268" },
        { countryName: "Sweden (Sverige)", regionCode: "SE", internationalCode: "46" },
        { countryName: "Switzerland (Schweiz)", regionCode: "CH", internationalCode: "41" },
        { countryName: "Syria (‫سوريا‬‎)", regionCode: "SY", internationalCode: "963" },
        { countryName: "Taiwan (台灣)", regionCode: "TW", internationalCode: "886" },
        { countryName: "Tajikistan", regionCode: "TJ", internationalCode: "992" },
        { countryName: "Tanzania", regionCode: "TZ", internationalCode: "255" },
        { countryName: "Thailand (ไทย)", regionCode: "TH", internationalCode: "66" },
        { countryName: "Timor-Leste", regionCode: "TL", internationalCode: "670" },
        { countryName: "Togo", regionCode: "TG", internationalCode: "228" },
        { countryName: "Tokelau", regionCode: "TK", internationalCode: "690" },
        { countryName: "Tonga", regionCode: "TO", internationalCode: "676" },
        { countryName: "Trinidad and Tobago", regionCode: "TT", internationalCode: "1868" },
        { countryName: "Tunisia (‫تونس‬‎)", regionCode: "TN", internationalCode: "216" },
        { countryName: "Turkey (Türkiye)", regionCode: "TR", internationalCode: "90" },
        { countryName: "Turkmenistan", regionCode: "tm", internationalCode: "993" },
        { countryName: "Turks and Caicos Islands", regionCode: "TC", internationalCode: "1649" },
        { countryName: "Tuvalu", regionCode: "TV", internationalCode: "688" },
        { countryName: "U.S. Virgin Islands", regionCode: "VI", internationalCode: "1340" },
        { countryName: "Uganda", regionCode: "UG", internationalCode: "256" },
        { countryName: "Ukraine (Україна)", regionCode: "UA", internationalCode: "380" },
        { countryName: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", regionCode: "AE", internationalCode: "971" },
        { countryName: "United Kingdom", regionCode: "GB", internationalCode: "44" },
        { countryName: "United States", regionCode: "US", internationalCode: "1" },
        { countryName: "Uruguay", regionCode: "UY", internationalCode: "598" },
        { countryName: "Uzbekistan (Oʻzbekiston)", regionCode: "UZ", internationalCode: "998" },
        { countryName: "Vanuatu", regionCode: "VU", internationalCode: "678" },
        { countryName: "Vatican City (Città del Vaticano)", regionCode: "VA", internationalCode: "39" },
        { countryName: "Venezuela", regionCode: "VE", internationalCode: "58" },
        { countryName: "Vietnam (Việt Nam)", regionCode: "VN", internationalCode: "84" },
        { countryName: "Wallis and Futuna", regionCode: "WF", internationalCode: "681" },
        { countryName: "Western Sahara (‫الصحراء الغربية‬‎)", regionCode: "EH", internationalCode: "212" },
        { countryName: "Yemen (‫اليمن‬‎)", regionCode: "YE", internationalCode: "967" },
        { countryName: "Zambia", regionCode: "ZM", internationalCode: "260" },
        { countryName: "Zimbabwe", regionCode: "ZW", internationalCode: "263" },
        { countryName: "Åland Islands", regionCode: "AX", internationalCode: "358" }
    ];
}