export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';

export enum TaskStatus {
	ToDo = 'TO_COME',
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETED',
	BEWARE = 'BEWARE',
	UpcomingExpiry = 'UPCOMING_EXPIRY',
	Delayed = 'DELAYED'
}

export enum TaskColor {
	TO_COME = 'purple',
	IN_PROGRESS = 'blue',
	COMPLETED = 'green',
	BEWARE = 'yellow',
	UPCOMING_EXPIRY = 'yellow',
	DELAYED = 'orange'
}

export enum TaskPriority {
	Highest = 'highest',
	High = 'high',
	Medium = 'medium',
	Low = 'low',
	Lowest = 'lowest'
}