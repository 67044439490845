<div class="card" [class.with-banner]="campaignBanner">
  <div class="d-flex justify-content-center align-items-center">
    <span class="header-title d-flex justify-content-center align-items-center text-uppercase"> create campaign </span>
  </div>
  <hr class="ml-3 mr-3 mt-0" />
  <form class="pl-3 pr-3" [formGroup]="campaignForm">
    <div class="row m-0" *ngIf="showWorkspaceDropdown">
      <div class="col d-flex justify-content-end mr-3">
        <select class="item-filter" name="" id="" (change)="changeWorkspace($event)">
          <option [value]="workspace.id"  class="workspace-options _1-line-text-ellipsis" *ngFor="let workspace of workspaces" ngbDropdownItem [ngbTooltip]="shouldShowEllipsis(workspace.name) ? workspace.name : null">  {{workspace.name}}</option>
        </select>
        <!-- <div class="d-flex align-items-center item-filter" container="body" ngbDropdown>
          <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis" ngbDropdownToggle>
            {{ shouldShowEllipsis(selectedWorkspace.name) ? (selectedWorkspace.name | slice:0:12) + '...' : selectedWorkspace.name }}
          </div>
          <div class="" ngbDropdownMenu>
            <div class="_1-line-text-ellipsis" (click)="changeWorkspace(workspace)" *ngFor="let workspace of workspaces"
              ngbDropdownItem [ngbTooltip]="shouldShowEllipsis(workspace.name) ? workspace.name : null">
              {{workspace.name}}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row m-0">
      <div class="col d-flex">
        <div class="mt-1">
          <label class="f-12 _400 w-100" for="campaignName">Campaign Name</label>
          <input type="text" id="campaignName" class="campaign-name-input " formControlName="campaignName">
        </div>
        <div class="col">
          <div class="mt-4 d-flex align-items-center ">
            <img *ngIf="campaignLogo" class="brand-img" [src]="campaignLogo" alt="">
            <span *ngIf="!campaignLogo" class="d-flex align-items-center brand-img text-uppercase _700 mr-2 "
              [style.backgroundColor]="timelineService.getColorFromName(campaignName)">
              {{campaignName | slice:0:2}}
            </span>
            <input type="file" (change)="campaignFileChangeListener($event)" style="display: none;" #fileInput>
            <button class="ml-3 upload-logo-btn d-flex align-items-center" (click)="fileInput.click()">
              <div class="upload-btn-circle">
                <img width="11px" height="11px" src="../../../../assets/svg/upload-file.svg" alt="">
              </div>
              <span class="ml-2 f-12 _700 text-uppercase c-mtm-full-white">Upload Logo</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col mr-3">
        <div class="mt-4">
          <div *ngIf="campaignBanner" class="position-relative mb-2">
            <img class="brand-banner" [src]="campaignBanner" alt="">
            <!-- 
                            <span class="delete-banner" (click)="removeBanner()">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </span>
                            -->
          </div>
          <input type="file" (change)="bannerFileChangeListener($event)" style="display: none;" #bannerInput>
          <button class="upload-banner-btn d-flex align-items-center float-right" (click)="bannerInput.click()">
            <div class="upload-btn-circle">
              <img width="11px" height="11px" src="../../../../assets/svg/upload-file.svg" alt="">
            </div>
            <span class="ml-2 f-12 _700 text-uppercase c-mtm-full-white">Upload Banner</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col">
        <div class="mt-3">
          <div class="form-field col-section mr-2" [ngClass]="{ 'has-error': !campaignLeaders?.length }">
            <label class="f-12 _400 w-100">Leaders</label>
            <div class="d-flex align-items-center collaborators-dropdown" ngbDropdown>
              <div class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                <div class="flex-fill mr-1">
                  <div *ngIf="campaignLeaders?.length" class="task-collaborators">
                    <div class="task-collaborator-wrapper d-flex align-items-center">
                      <div class="collaborator-avatar mr-1" *ngFor="let leader of existingleaders">
                        <img class="participant-img" *ngIf="leader.avatarUrl" [src]="leader.avatarUrl" alt="">
                        <span *ngIf="!leader.avatarUrl" class="c-full-white participant-img text-uppercase "
                          [style.backgroundColor]="timelineService.getColorFromName(leader.fullName)">
                          {{leader.fullName | slice:0:2}}
                        </span>
                        <!-- <div class="crown-container" *ngIf="owner.username == campaignData.leaders">
                                                    <i class="mtm-icon mtm-icon-crown-red"></i>
                                                </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <i class="fa fa-chevron-down ml-auto pointer mr-1"></i>
              </div>
              <div class="task-priority-dropdown-menu w-100" ngbDropdownMenu>
                <div class="p-1 w-100">
                  <input type="text" placeholder="Search..." class="search-text" (keyup)="searchParticipants($event)" />
                </div>
                <div *ngFor="let participant of participants"
                  class="task-dropdown-item d-flex align-items-center pointer"
                  (click)="leaderselected($event, participant)" ngbDropdownItem>
                  <div class="d-flex align-items-center collaborator-selected-flag mr-2"
                    [ngClass]="{ 'selected': participant.selected }">
                    <i class="fa fa-check"></i>
                  </div>
                  <img class="participant-img" *ngIf="participant.avatarUrl" [src]="participant.avatarUrl" alt="">
                  <span *ngIf="!participant.avatarUrl" class="c-full-white participant-img text-uppercase"
                    [style.backgroundColor]="timelineService.getColorFromName(participant.fullName)">
                    {{participant.fullName | slice:0:2}}
                  </span>
                  <span class="ml-2 _600">{{participant.fullName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col mt-3">
        <label class="f-12 _400 w-100" for="status">Status</label>
        <select name="status" id="status" class="form-control status-dropdown" formControlName="status"
          (change)="changeStatus($event)" required>
          <option value="null">Select Status</option>
          <!-- Use *ngFor to loop through statusOptions array and generate options -->
          <option *ngFor="let option of statusOptions" [value]="option.value">{{ option.name }}</option>
        </select>

        <!-- <div class="top-content d-flex align-items-center ml-3" (click)="$event.stopImmediatePropagation()">
                    <div ngbDropdown class="d-flex align-items-center" [autoClose]="true" #dropdownCampaignStatus>
                      <button [disabled]="!canEditStatus" (click)="$event.stopImmediatePropagation()" type="button"
                        class="d-flex align-items-center btn project-status-dropdown" ngbDropdownToggle>
                        <div class="d-flex align-items-center project-status {{item.status}}">
                          <div class="project-union-icon-container">
                            <i class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[item.status]}}"></i>
                          </div>
                          <span class="mr-2">{{'task_'+item.status|translate}}</span>
                          <i class="fa fa-chevron-down ml-auto pointer"></i>
                        </div>
                      </button>
                      <div class="project-dropdown-menu" ngbDropdownMenu>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.Completed||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.InProgress)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display IN_PROGRESS">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_IN_PROGRESS'|translate}}</span>
                          </div>
                        </div>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Delayed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Completed)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display COMPLETED">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_COMPLETED'|translate}}</span>
                          </div>
                        </div>
                        <div
                          *ngIf="!item.status||item.status==projectStatus.InProgress||item.status==projectStatus.UpcomingExpiry||item.status==projectStatus.Completed"
                          (click)="$event.stopImmediatePropagation();changeDriveCampaignStatus(item,projectStatus.Delayed)"
                          class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                          <div class="d-flex align-items-center project-status-display DELAYED">
                            <i class="project-status-circle-color mr-2"></i>
                            <span>{{'task_DELAYED'|translate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
        <!-- <div class="Collaborator-btn pointer"><i class="fa fa-plus" aria-hidden="true"></i></div> -->
      </div>
    </div>
    <div class="row m-0">
      <div class="d-flex col mt-3 justify-content-between">
        <div class="form-field col-section mr-2"
          [ngClass]="{ 'has-error': fieldErrors.startDate && !campaignData.startDate }">
          <label class="f-12 _400 w-100">{{ 'task_startDate' | translate }}</label>
          <div class="due-date-control d-flex align-items-center">
            <input name="startDate" class="form-control" placeholder="{{ 'task_startDate' | translate }}" ngbDatepicker
              #dpStartDate="ngbDatepicker" formControlName="startDate" firstDayOfWeek="1"
              (click)="dpStartDate.toggle()" />
            <i class="mtm-icon mtm-icon-calendar-black" (click)="dpStartDate.toggle()"></i>
          </div>
        </div>

        <div class="form-field col-section ml-2"
          [ngClass]="{ 'has-error': fieldErrors.dueDate && !campaignData?.dueDate }">
          <label class="f-12 _400 w-100">{{ 'endDate' | translate }}</label>
          <div class="due-date-control d-flex align-items-center">
            <input name="endDate" class="form-control" placeholder="{{ 'endDate' | translate }}" ngbDatepicker
              #dpEndDate="ngbDatepicker" formControlName="endDate" firstDayOfWeek="1" (click)="dpEndDate.toggle()" />
            <i class="mtm-icon mtm-icon-calendar-black" (click)="dpEndDate.toggle()"></i>
          </div>
        </div>
      </div>

    </div>
    <div class="row m-0">
      <div class="col mt-3">
        <label class="f-12 _400 w-100" for="assignPriority">Priority</label>
        <select id="assignPriority" class="assign-team" formControlName="assignPriority">
          <option value="High">High</option>
          <option value="critical">Critical</option>
        </select>
      </div>
    </div>
    <div class="row p-3">
      <div class="col mt-3" *ngIf="campaignData && campaignData.id">
        <button (click)="deleteCampaign(campaignData)" class="delete-btn text-uppercase f-13 _700" type="button">delete
          campaign</button>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="col mt-3" [ngClass]="{'pl-0' : !campaignData}">
          <button class="cancel-btn text-uppercase f-13 _700" (click)="closeModal()" type="button">cancel</button>
        </div>
        <!-- <div class="d-flex justify-content-end col mt-3" [ngClass]="{'d-flex justify-content-end' : !campaignData}"> -->
        <div class="d-flex justify-content-end col mt-3">
          <button (click)="onSubmit()" type="submit" class="save-btn text-uppercase f-13 _700">
            save
          </button>
        </div>
      </div>

    </div>
  </form>
</div>