<ul class="d-flex align-items-center badgebtn-container m-0">
	<li class="preview-button mt-1 mr-1">
		<span class="key-project-media-download" (click)="previewFile(file)">
			<i class="fa fa-eye" aria-hidden="true"></i>
		</span>
	</li>
	<li class="download-media-file mt-1 mr-1" *ngIf="file.id">
		<mtm-file-download-control [file]="fileData" [displayType]="'icon'" [iconSize]="24"
			[uniqueControlId]="'badge-button-' + fileData.projectId + '-' + fileData.sectionId + '-' + fileData.subSectionId + '-' + file.id">
		</mtm-file-download-control>
	</li>
	<li class="move-button mt-1 mr-1" *ngIf="moveButtonShown">
		<span (click)="moveFile(file)">
			<i class="fa fa-arrows" aria-hidden="true"></i>
		</span>
	</li>
	<li class="trash-button mt-1 mr-1" *ngIf="(isDisplayTrashBtn && file.id) || localFile">
		<span (click)="onTrash(file)">
			<i class="fa fa-trash" aria-hidden="true"></i>
		</span>
	</li>
</ul>