import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { WorkspaceService } from "../services/workspace.service";

@Injectable()
export class OneShotGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const authUser = this.authService.getAuthUser();

        //if not logged in then allow it
        if (!authUser || this.authService.getAuthToken() == null || this.authService.isTokenExpired()) {
            this.authService.logout();
            return true;
        }

        //if logged in user, but not ADVERTISER or want to access producer one shot, then redirects it
        if (state.url.toLowerCase() != `/entry/marketplace/build-request/one_shot/advertiser` || authUser.company.companyType != 'ADVERTISER') {
            this.router.navigate(['/projects']);
            return false;
        }

        //allow ADVERTISER user
        return true;
    }

}