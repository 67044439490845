import { StringPair } from "./angle-types.model";

export const LightingTypes: StringPair[] = [
	{ label: 'dawn', value: 'DAWN' },
	{ label: 'sunrise', value: 'SUNRISE' },
	{ label: 'morning', value: 'MORNING' },
	{ label: 'noon', value: 'NOON' },
	{ label: 'afternoon', value: 'AFTERNOON' },
	{ label: 'evening', value: 'EVENING' },
	{ label: 'sunset', value: 'SUNSET' },
	{ label: 'dusk', value: 'DUSK' },
	{ label: 'night', value: 'NIGHT' }
];
