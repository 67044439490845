import { Component, OnInit, OnDestroy } from '@angular/core';
import { addSubtitleStart, importSubtitleStart, subtitleGenerated } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'mtm-subtitle-uploader-container',
	templateUrl: './subtitle-uploader-container.component.html',
	styleUrls: ['./subtitle-uploader-container.component.scss']
})
export class SubtitleUploaderContainerComponent implements OnInit, OnDestroy {
	subtitleData: any = [];
	importData: any;
	ngUnsubscribe = new Subject();

	constructor() {
		addSubtitleStart.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			this.subtitleData.push({
				guid: this.guid(),
				data
			});
		});

		subtitleGenerated.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			if (data.import) {
				this.importData = void 0;
			} else {
				this.subtitleData = this.subtitleData.filter(s => s.guid !== data.guid);
			}
		});

		importSubtitleStart.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			this.importData = data;
		});
	}


	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void { }

	guid() {
		let s4 = () => {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	trackByFn(index, item) {
		return item.guid;
	}

}