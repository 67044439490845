import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { PaymentService } from "../../shared/services/payment.service";
import { OverlayService } from "../../shared/services/overlayService";
import { LoadingBase } from "../../shared/bases/loading.base";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../shared/services/auth.service";
import { ProjectService, } from "../../shared/services/project.service";
import { CompanyService } from "../../shared/services/company.service";
import { ProdPartnerService } from "../../shared/services/prod-partner.service";
import { DateHelperService } from "../../shared/services/date-helper.service";
import { onRedirectToTCP_FNC } from "../../shared/base-functions/terms-conditions.base-function";
import { FinalpaymentService } from "../../shared/services/finalpayment.service";
import { DownpaymentService } from "../../shared/services/downpayment.service";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	templateUrl: './downpayment-info-modal.component.html',
	styleUrls: ['./downpayment-info-modal.component.scss']
})
export class DownPaymentInfoModalComponent extends LoadingBase implements OnInit, OnDestroy {

	formGroup: UntypedFormGroup = null;
	percentage: any;
	projectType: string;
	paymentType: string;
	selectedProposal: any = {};
	project: any = {};
	downpayment: string;
	downpaymentRate: string;
	total: string;
	checkValue: any;
	wantProd: any = false;
	prodCompanyName: string = '';
	downpaymentDeadline: any = {};
	finalPaymentDeadline: any = {};
	dueDateDeadline: any = {};
	proposalContractApprovedDate: any;
	isConsultantHelp: boolean = true;
	contractApprovedByAdvertiser: boolean = true;
	proposalAccepted: boolean = false;
	isComingFromMailorNot: boolean = false;
	isScript: boolean = false;
	isInfo: boolean = false;

	donthelp: any;
	creative: any;
	bidding: any;
	production: any;
	biddingAmount: any;
	totalToPay: any = 0.00;
	showTotal: any;
	ngUnsubscribe = new Subject();

	consultantOption = [];

	@Input() projectId;
	@Input() proposalSelected: any = {};
	@Input() componentInstance;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private paymentService: PaymentService,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private authService: AuthService,
		private projectService: ProjectService,
		public activeModal: NgbActiveModal,
		private companyService: CompanyService,
		private prodpartnerService: ProdPartnerService,
		private fpService: FinalpaymentService,
		private dpService: DownpaymentService,
		private translatePipe: TranslatePipe) {
		super();

		this.consultantOption.push({
			title: translatePipe.transform("creative"),
			desc: translatePipe.transform("creativeAssistance"),
			percent: 2,
			selected: false,
			label: "CREATIVE_BRIEF",
			biddingAmount: 1.30,
		})
		this.consultantOption.push({
			title: translatePipe.transform("bidding"),
			desc: translatePipe.transform("biddingAssistance"),
			percent: 1,
			selected: false,
			label: "BIDDING",
			biddingAmount: 0.65,
		})
		this.consultantOption.push({
			title: translatePipe.transform("production"),
			selected: false,
			desc: translatePipe.transform("productionAssistance"),
			percent: 1,
			label: "PRODUCTION",
			biddingAmount: 0.65,
		})
		this.consultantOption.push({
			title: translatePipe.transform("dontHelp"),
			selected: false,
			label: "DONTHELP",
			percent: null,
			biddingAmount: null
		})
		this.consultantOption.push({
			title: null,
			selected: false,
			label: null,
			percent: null,
			biddingAmount: null
		})

	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.proposalSelected = this.proposalSelected ? this.proposalSelected : {};

		this.projectService.getProject(this.projectId.toString()).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(async (project) => {
			this.project = project;
			try {
				const res1 = await this.prodpartnerService.getProdPartnerContent(project.id).toPromise();
				this.isScript = res1.script;

				const s = await this.prodpartnerService.getProdPartnerScheduleAndPricing(project.id).toPromise();
				this.biddingAmount = ((Number(this.proposalSelected.budget) * 10) / 100).toFixed(2);
				this.setValuesOfConsultant(this.consultantOption, s.consultantValues);

				this.isConsultantHelp = Array.isArray(s.consultantValues) &&
					s.consultantValues.find(k => k == 'PRODUCTION')
					? true : false;
			} catch (err) {

			}

			this.downpaymentRate = this.proposalSelected.downpaymentRate;
			this.downpayment = ((Number(this.proposalSelected.budget) * this.proposalSelected.downpaymentRate) / 100).toFixed(2);
			this.downpaymentDeadline = DateHelperService.fromTimestampToDate(this.proposalSelected.downpaymentDeadline);
			this.finalPaymentDeadline = DateHelperService.fromTimestampToDate(this.proposalSelected.finalPaymentDeadline);
			this.proposalContractApprovedDate = new Date().getTime();
			this.contractApprovedByAdvertiser = this.proposalSelected.contractApprovedByAdvertiser;
			this.dueDateDeadline = new Date(this.proposalSelected.endDate);
			this.companyService.getCompanyById(this.proposalSelected.prodCompanyId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(company => {
				this.prodCompanyName = company.name;
			})
		});

		if (this.proposalAccepted == false)
			this.checkProposalAccepted();

	}

	private setValuesOfConsultant(consultantKey: any[] = [], consultantList: any[] = []) {
		if (consultantList) {
			consultantList.map(k => {
				let obj = consultantKey.find(l => l.label == k);
				obj.selected = true;
			});

			this.totalToPay = 0.0;

			this.consultantOption.filter(k => k.selected && k.biddingAmount).forEach(consultant => {
				consultant.biddingAmount = Number(this.biddingAmount / 2 * consultant.percent).toFixed(2);
				this.totalToPay = Number(this.totalToPay) + Number(consultant.biddingAmount);
			});
			//Mtm fee
			this.totalToPay = this.totalToPay + Number(this.biddingAmount);
		} else {
			this.totalToPay = Number(this.biddingAmount);
		}

		this.showTotal = Number.parseFloat(this.totalToPay.toFixed(2)).toFixed(2);
	}

	accept() {
		let data: any = {};
		data.totalMtm = (Number(this.showTotal) + Number(this.downpayment)).toFixed(2);
		data.mtmFee = this.showTotal;
		data.isNeedProductionProcess = !this.wantProd;
		data.isConsultantHelp = this.isConsultantHelp;
		if (this.proposalAccepted && !this.project.downPaymentPaid) {
			this.activeModal.close("GOTOPAYMENT");
			this.dpService.openDownpaymentModalCreditCard(this.proposalSelected, data.totalMtm, data.mtmFee, this.projectId, this.projectService.project.projectType)
		} else if (this.proposalAccepted && !this.project.finalPaymentPaid) {
			this.activeModal.close("GOTOPAYMENT");
			this.fpService.openFinalpaymentModals(this.proposalSelected.id)
		} else {
			this.activeModal.close(data);
		}
	}

	changeTotal(currentValue) {
		if (currentValue) {
			this.totalToPay = (Number(this.totalToPay) - Number(this.biddingAmount / 2));
			this.consultantOption[2].biddingAmount = 0.0;
		}
		else {
			this.totalToPay = (Number(this.totalToPay) + Number(this.biddingAmount / 2));
			this.consultantOption[2].biddingAmount = Number(this.biddingAmount / 2);
		}

		this.showTotal = Number.parseFloat(this.totalToPay.toFixed(2)).toFixed(2);
	}

	onCloseModal() {
		this.activeModal.close('CLOSED');
	}

	onRedirectToTCP(selector: string): boolean {
		return onRedirectToTCP_FNC(selector, [this.projectId, this.proposalSelected.id]);
	}

	private checkProposalAccepted() {
		this.projectService.checkProposalAccepted(this.projectId.toString()).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.proposalAccepted = res;
		});
	}


}
