import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SubscriptionPlanService {


	constructor(
		private api: ApiService,
		private authService: AuthService,
		private companyService: CompanyService) { }

	getPlans() {
		let subject = new Subject<any>();
		this.companyService.getCompanyById(this.authService.getAuthUser().companyId)
			.subscribe(company => {
				this.api.httpGet(`/api/subscription/plans/${this.getCompanyBillingCurrency(company)}`).pipe(
					map((data: any) => {
						for (let i = 0; i < data.plans.length; i++) {
							data.plans[i].features = _.map(data.plans[i].features, (feature) => _.trim(feature));
						}
						return data;
					})
				).subscribe(res => {
					subject.next(res);
					subject.complete();
				}, err => {
					subject.error(err);
				});
			}, err => {
				subject.error(err);
			});
		return subject.asObservable();
	}

	getCompanyBillingCurrency(company: any) {
		const euroCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
		const currency = company.currency || (euroCountries.indexOf(company.billingAddress.country) > -1 ? 'EUR' : 'USD');
		return currency.toUpperCase();
	}
}
