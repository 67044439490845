
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'mtm-subscription-confirm-downgrade',
	templateUrl: './confirm-downgrade.component.html',
	styleUrls: ['./confirm-downgrade.component.scss']
})
export class SubscriptionConfirmDowngradeComponent {
	constructor(private router: Router,
		private activeModal: NgbActiveModal) {

	}

	continue() {
		this.activeModal.close(true);
	}

	cancel() {
		this.router.navigate(['/subscription/upgrade/select-plan']);
		this.activeModal.dismiss();
	}
}
