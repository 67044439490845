import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserTourService } from 'app/shared/services/user-tour.service';

import {UserTourToolService} from 'app/shared/services/user-tour-tool.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserTour } from 'app/shared/interfaces';

@Component({
  selector: 'mtm-usertour',
  templateUrl: './usertour.component.html',
  styleUrls: ['./usertour.component.scss']
})
export class UsertourComponent implements OnInit ,AfterViewInit, OnDestroy{

  @Input() data: UserTour
  constructor(
    private UserTourToolService: UserTourToolService,
    private modalService: NgbModal
  ) {}
  @Input() message: string = 'hello';
  // @ViewChild('highlightedElement') highlightedElement: ElementRef;
  @Input() highlightedElement: HTMLElement | null;
  @Input() topPosition: number;
  @Input() leftPosition: number;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right'; // Add this input property

  ngAfterViewInit(): void {
   
  }

  ngOnInit(): void {
    console.log(this.data)
    console.log('this.topPosition',this.topPosition)
    console.log('leftPosition',this.leftPosition)
    console.log('this.placement ',this.placement )
    // const modal = this.modalService.open(MapWorkpsaceComponent, { windowClass: 'lg', backdrop: 'static', keyboard: false });
    // modal.componentInstance.brand = brand;
    setTimeout(() => {
      if (this.data && this.highlightedElement) {
        // console.log('this.highlightedElement.nativeElement', this.highlightedElement.nativeElement)
        console.log('this.highlightedElement', this.highlightedElement)
        this.highlightedElement.classList.add(this.data.highlightClass,'default');
      }
    }, 1000);
    
  }
  ngOnDestroy(): void {
    
  }
}
