<div class="p-2" #roleCell>
    <div class="role-wrapper" [attr.data-title]="'workspace_edit_cantEditRole' | translate"
        [class.tooltipped]="!params.context.componentParent.canEditProject">
        <mtm-dropdown [isDisplaySearchBox]="false" [title]="'Select'"
            [isDisabled]="! params.context.componentParent.canEditProject" [emptyListInfoText]="'Role is required'"
            [dotItemSelectedText]="'role selected'" [dotItemsSelectedText]="'roles selected'"
            [items]=params.context.componentParent.projectRoles [itemValueAccessor]="'code'"
            [itemLabelAccessor]="'label'" [isMultipleSelect]=true [refObject]=params.data
            [objectVariableAccessor]="'roles'" [preventEmptyValue]="true" (open)="dropdownOpen($event)"
            [customClass]="params.context.componentParent.participants.length/2 > params.data.index ? 'dropdown-bottom' : 'dropdown-top'"
            (onChangeValue)="params.context.componentParent.onRoleChange($event,params.data)">
        </mtm-dropdown>
    </div>
</div>