import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MentionModule } from 'angular-mentions';
import { GroupChatPicture } from "app/shared/components/mtm-profile-picture/mtm-group-chat-picture/mtm-group-chat-picture.component";
import { UserProfilePicture } from "app/shared/components/mtm-profile-picture/mtm-user-profile-picture/mtm-user-profile-picture.component";
import { SafeHtmlPipe } from "app/shared/pipes/safe-html.pipe";
import { SafeUrlPipe } from "app/shared/pipes/safe-resource-url.pipe";
import { AudioMicVisualizer } from "./mtm-text-editor/audio-recorder-modal/audio-mic-visualizer/audio-mic-visualizer.component";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from "@angular/forms";

@NgModule({
	declarations: [
		UserProfilePicture,
		GroupChatPicture,
		SafeHtmlPipe,
		SafeUrlPipe,
		AudioMicVisualizer
	],
	providers: [
	],
	imports: [
		CommonModule,
		FormsModule,
		MentionModule,
		PickerModule,
		QuillModule
	],
	exports: [
		UserProfilePicture,
		GroupChatPicture,
		SafeHtmlPipe,
		SafeUrlPipe,
		AudioMicVisualizer
	]
})
export class MtmChatModule { }

