import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangePhoneModalComponent } from "app/on-boarding/phone-verification/change-phone-modal/change-phone-modal.component";
import { AuthService } from "app/shared/services/auth.service";
import { HelperService } from "app/shared/services/helper.service";
import { NotificationService } from "app/shared/services/notification.service";
import { OverlayService } from "app/shared/services/overlayService";
import { VerificationService } from "app/verification/shareds/services/verification.service";
import * as _ from "lodash";

@Component({
	selector: 'mtm-verification',
	templateUrl: './verification.component.html',
	styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('emailCode1') emailCode1: ElementRef;
	@ViewChild('emailCode2') emailCode2: ElementRef;
	@ViewChild('emailCode3') emailCode3: ElementRef;
	@ViewChild('emailCode4') emailCode4: ElementRef;
	// @ViewChild('emailCode5') emailCode5: ElementRef;
	// @ViewChild('emailCode6') emailCode6: ElementRef;

	@ViewChild('phoneCode1') phoneCode1: ElementRef;
	@ViewChild('phoneCode2') phoneCode2: ElementRef;
	@ViewChild('phoneCode3') phoneCode3: ElementRef;
	@ViewChild('phoneCode4') phoneCode4: ElementRef;

	registerData: any;
	verifyForm: UntypedFormGroup;
	isLoading: boolean = false;
	emailVerificationCode: any[] = ['1', '2', '3', '4'];
	emailVerificationTouched: boolean = false;
	phoneVerificationCode: any[] = ['1', '2', '3', '4'];
	phoneVerificationTouched: boolean = false;
	validation: any = {};
	submitted: boolean = false;
	resendPhoneAgainCounter: number = 0;
	phoneResent: boolean = false;
	resendEmailAgainCounter: number = 0;
	emailResent: boolean = false;
	lastTwoDigits: string;
	alternatePhone: string = '';

	constructor(
		private authService: AuthService,
		private router: Router,
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private verificationService: VerificationService,
		private overlayService: OverlayService,
		private fb: UntypedFormBuilder
	) {
		this.registerData = JSON.parse(localStorage.getItem('register_data'));
		this.buildForm();
	}

	ngOnInit() {
		setTimeout(() => {
			this.notificationService.open({
				title: 'Session Expired',
				notificationType: 'success',
				centerHeader: true,
				notificationInfo: 'Your session has expired. Please login to continue.',
				confirmBtn: 'Login'
			}).subscribe(confirm => {
				this.verificationService.logout();
			});
		}, 1000 * 60 * 10);
	}

	ngAfterViewInit() {
		this.emailResendCounter();
		this.phoneResendCounter();
	}

	ngOnChanges(changes: SimpleChanges) { }

	buildForm() {
		this.verifyForm = this.fb.group({});
		this.verifyForm.valueChanges.subscribe(
			(res) => {
			}, (err) => {
				console.error(err);
			});
	}

	resendEmail() {
		this.verificationService.reSendEmailVerificationCode(this.registerData.user.email)
			.subscribe((res) => {
				this.emailResent = true;
				this.emailResendCounter();
			}, (err) => {
				console.error(err);
			});
	}

	emailResendCounter() {
		this.resendEmailAgainCounter = 60;
		let emailInterval = setInterval(() => {
			this.resendEmailAgainCounter--;
			if (!this.resendEmailAgainCounter) {
				clearInterval(emailInterval);
			}
		}, 1000);
	}

	resendPhone() {
		this.verificationService.reSendPhoneVerificationCode(this.registerData.user.email)
			.subscribe((res) => {
				this.phoneResent = true;
				this.phoneResendCounter();
			}, (err) => {
				console.error(err);
			});
	}

	phoneResendCounter() {
		this.resendPhoneAgainCounter = 60;
		let phoneInterval = setInterval(() => {
			this.resendPhoneAgainCounter--;
			if (!this.resendPhoneAgainCounter) {
				clearInterval(phoneInterval);
			}
		}, 1000);
	}

	submit() {
		this.submitted = true;
		this.isLoading = true;
		this.validation = {};
		if (!this.emailVerificationTouched || !this.phoneVerificationTouched) {
			if (!this.emailVerificationTouched) {
				this.validation.error = {
					email: { EMPTY: 'verification_required_input' }
				};
			}
			if (!this.phoneVerificationTouched) {
				this.validation.error = {
					...this.validation.error,
					phone: { EMPTY: 'verification_required_input' }
				};
			}
			this.isLoading = false;
		} else {
			let emailCode = this.emailVerificationCode.reduce((acc, curr) => acc + curr, '');
			let phoneCode = this.phoneVerificationCode.reduce((acc, curr) => acc + curr, '');
			if (emailCode.length < 3) {
				this.validation.error = { email: { INVALID: 'verification_invalid_code' } };
			}
			if (phoneCode.length < 3) {
				this.validation.error = {
					...this.validation.error,
					phone: { INVALID: 'verification_invalid_code' }
				};
			}
			if (!HelperService.isObjectEmpty(this.validation)) {
				this.isLoading = false;
			} else {
				this.verifyEmailAndPhone(emailCode, phoneCode);
			}
		}
	}

	private verifyEmailAndPhone(emailCode: any, phoneCode: any) {
		this.verificationService
			.accountVerification(this.registerData.user.email, emailCode, 'EMAIL')
			.subscribe((emailResult: any) => {
				this.verificationService
					.accountVerification(this.registerData.user.email, phoneCode, 'SMS')
					.subscribe((phoneResult: any) => {
						const data = phoneResult.json();
						localStorage.clear();
						sessionStorage.clear();
						this.authService.setAuthUser(data.user);
						this.authService.setAuthToken(data.auth.token).subscribe(() => {
							this.authService.setAuthSession();
							if (!localStorage.getItem('auth_user_language')) {
								this.authService.setAuthLanguage("en_us");
							}
							if (data.userProfileSettings) {
								this.authService.setAuthUserSettings(data.userProfileSettings);
							}
							this.isLoading = false;
							this.router.navigate(['../subscription/register']);
						});
					}, err => {
						if (err) {
							if (err.errorCode === 'INCORRECT_VERIFICATION_CODE') {
								this.validation.error = {
									...this.validation.error,
									phone: { [err.errorCode]: err.message }
								};
							}
						}
						this.isLoading = false;
					})
			}, (err) => {
				if (err) {
					if (err.errorCode === 'INCORRECT_VERIFICATION_CODE') {
						this.validation.error = {
							...this.validation.error,
							email: { [err.errorCode]: err.message }
						};
					}
				}
				this.isLoading = false;
			});

	}

	moveToElement(e, currentElement, nextElement, previousElement, model) {
		let _currentElement = this[currentElement] as ElementRef;
		let _nextElement = this[nextElement] as ElementRef;
		let _previousElement = this[previousElement] as ElementRef;
		setTimeout(() => {
			if (e.keyCode >= 48 && e.keyCode <= 57) {
				_.set(this, model, e.key);
			}
			if (e.keyCode === 8) {
				if (_previousElement) {
					_previousElement.nativeElement.focus();
				}
			} else {
				if (_nextElement) {
					_nextElement.nativeElement.focus();
				}
			}
		}, 0);
	}

	emailCodeBoxPress() {
		if (!this.emailVerificationTouched) {
			this.clearEmailVerificationCodeModel();
			this.emailVerificationTouched = true;
			this.emailCode1.nativeElement.focus();
		}
		this.submitted = false;
		return true;
	}

	phoneCodeBoxPress() {
		if (!this.phoneVerificationTouched) {
			this.clearPhoneVerificationCodeModel();
			this.phoneVerificationTouched = true;
			this.phoneCode1.nativeElement.focus();
		}
		this.submitted = false;
		return true;
	}

	clearEmailVerificationCodeModel() {
		this.emailVerificationCode = ['', '', '', '', '', ''];
	}

	clearPhoneVerificationCodeModel() {
		this.phoneVerificationCode = ['', '', '', ''];
	}

	phoneView(phone: string) {
		return HelperService.isObjectEmpty(phone) ? '' : phone.substring(0, 3) + 'xxxxxxx' + phone.substring(phone.length - 2, phone.length);
	}

	useAnotherMobileNumber() {
		let modal = this.modalService.open(ChangePhoneModalComponent, { size: 'lg' });

		modal.result.then((data) => {
			if (data.alternatePhoneSent) {
				this.alternatePhone = data.alternatePhone;
				this.lastTwoDigits = data.alternatePhone.slice(-2);
				this.notificationService.open({
					title: 'Verification code sent!',
					centerHeader: true,
					confirmBtn: 'Continue'
				})
			}
		}, (reason) => {
			console.log('ERROR', reason);
		});
	}

	emailCodePaste(e: any) {
		let clipboardData = e.clipboardData || (window as any).clipboardData;
		let pastedText = clipboardData.getData('text');
		this.emailVerificationCode = pastedText.split('');
		const focusElement = 'emailCode' + pastedText.length;
		<ElementRef>this[focusElement].nativeElement.focus();
	}

	phoneCodePaste(e: any) {
		let clipboardData = e.clipboardData || (window as any).clipboardData;
		let pastedText = clipboardData.getData('text');
		this.phoneVerificationCode = pastedText.split('');
		const focusElement = 'phoneCode' + pastedText.length;
		<ElementRef>this[focusElement].nativeElement.focus();
	}

}
