<div class="modal-body modal-zoom">
	<div class="w-100 text-right mb-3">
		<i class="fa fa-close close-btn" (click)="activeModal.close()"></i>
	</div>
	<div class="container container-inner" style="overflow-x: auto;">
		<h3 class="mt-2 mb-2">{{'permissionDetails' | translate}}</h3>
		<h6 class="mt-2 mb-2" *ngIf="CLIENTorPROD == 'PRODUCTION'">
			{{'permissionModalTitle' | translate}}
		</h6>
		<h6 *ngIf="CLIENTorPROD == 'CLIENT'">
			{{'permissionModalTitle' | translate}}
		</h6>
		<table>
			<tbody>
				<tr *ngFor="let permisson of viewList; let i = index" [ngClass]="{'bordered': permisson.isEndOfBrand }">
					<th>
						<div class="d-flex align-items-center" [style.white-space]="'nowrap'">
							<span class="mr-1">{{permisson.label | translate}}</span>
							<span class="span-text-color"
								*ngIf="permisson.brandTeam && CLIENTorPROD == 'CLIENT'">*</span>
							<span class="span-text-color"
								*ngIf="!permisson.brandTeam && CLIENTorPROD == 'CLIENT'">**</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center">
							<input type="checkbox" [checked]="permisson.weight>=1" [disabled]="true">
							<label class="custom-control-description" [style.white-space]="'nowrap'">{{'informed' |
								translate}}</label>
						</div>
					</th>
					<th>
						<input type="checkbox" [checked]="permisson.weight>=2" [disabled]="true">
						<label class="custom-control-description" [style.white-space]="'nowrap'">{{'addContent' |
							translate}}</label>
					</th>
					<th>
						<input type="checkbox" [checked]="permisson.weight>=3" [disabled]="true">
						<label class="custom-control-description" [style.white-space]="'nowrap'">{{'comment' |
							translate}}</label>
					</th>
					<th>
						<input type="checkbox" [checked]="permisson.weight>=4" [disabled]="true">
						<label class="custom-control-description"
							*ngIf="permisson.brandTeam && CLIENTorPROD == 'CLIENT'"
							[style.white-space]="'nowrap'">{{'validate' | translate}}</label>
						<label class="custom-control-description"
							*ngIf="!permisson.brandTeam && CLIENTorPROD == 'CLIENT'"
							[style.white-space]="'nowrap'">{{'recommend' | translate}}</label>
						<label class="custom-control-description"
							*ngIf="!permisson.brandTeam && CLIENTorPROD == 'PRODUCTION'"
							[style.white-space]="'nowrap'">{{'recommend' |
							translate}}</label>

					</th>
					<th>
						<input type="checkbox" [checked]="permisson.weight>=5" [disabled]="true">
						<label class="custom-control-description" [style.white-space]="'nowrap'">{{'own' |
							translate}}</label>
					</th>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="row" style="margin-top: 15px" *ngIf="CLIENTorPROD == 'CLIENT'">
		<div class="col">
			<p class="text-muted text-center">
				<span class="span-text-color">*</span>
				{{'brandMember' | translate}}
			</p>
		</div>
		<div class="col">
			<p class="text-muted">
				<span class="span-text-color">**</span>
				{{'prodMember' | translate}}
			</p>
		</div>
	</div>

</div>