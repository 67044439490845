import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DragulaModule } from 'ng2-dragula';
import { TagInputModule } from 'ngx-chips';
// import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

/* ----------- * GUARDS * ----------- */
import { ProposalProjectLinksGuard } from 'app/shared/guards/proposal-project-links.guard';

/* ----------- * PAGES * ----------- */
import { MyProjectsComponent } from 'app/layouts/my-projects/my-projects.component';
import { ProjectInvitationComponent } from "app/layouts/my-projects/project-invites/project-invitation.component";
import { ArchivedProjectsComponent } from "app/layouts/my-projects/archived-projects/archived-projects.component";
import { ProjectProposalsComponent } from "app/layouts/my-projects/project-proposals/project-proposals.component";
import { RateProdCompComponent } from "app/layouts/my-projects/rate-prod-comp/rate-prod-comp.component";
import { NewProjectComponent } from 'app/layouts/my-projects/new-project/new-project.component';
import { ProjectStagesComponent } from 'app/layouts/my-projects/new-project/project-stages/project-stages.component';
import { ProjectPermissionsComponent } from 'app/layouts/my-projects/new-project/project-permissions/project-permissions.component';
import { ProjectAssetsComponent } from 'app/layouts/my-projects/new-project/project-assets/project-assets.component';
import { ProjectProposalComponent } from 'app/layouts/my-projects/project-proposal/project-proposal.component';
import { ProjectProposalBriefComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-brief/project-proposal-brief.component';
import { ProjectProposalTeamComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-team/project-proposal-team.component';
import { ProjectProposalQaSessionComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-qaSession/project-proposal-qaSession.component';
import { ProjectProposalUploadProposalComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-uploadProposal/project-proposal-uploadProposal.component';
import { ProjectProposalDiscussionComponent } from 'app/layouts/my-projects/project-proposal/project-proposal-discussion/project-proposal-discussion.component';
import { ProjectProposalProjectDetails } from "app/layouts/my-projects/project-proposal/project-proposal-projectDetails/project-proposal-projectDetails.component";
import { ProjectProposalUserPermissions } from "app/layouts/my-projects/project-proposal/project-proposal-userPermissions/project-proposal-userPermissions.component";
import { ProjectProposalStages } from "app/layouts/my-projects/project-proposal/project-proposal-stages/project-proposal-stages.component";

/* ----------- * COMPONENTS * ----------- */
import { ThumbnailComponent } from 'app/layouts/my-projects/thumbnail/thumbnail.component';
import { ProjectThumbnailListComponent } from 'app/layouts/my-projects/thumbnail-list/thumbnail-list.component';
import { InvitationComponent } from 'app/layouts/my-projects/project-invites/invitation/invitation.component';
import { TeamFilesListComponent } from "app/layouts/team-files/list/list.component";
import { TeamFilesGridComponent } from "app/layouts/team-files/grid/grid.component";
import { ProjectSortingComponent } from 'app/layouts/my-projects/project-sorting/project-sorting.component';

/* ----------- * MODAL_COMPONENTS * ----------- */
import { EditSectionComponent } from "app/layouts/my-projects/new-project/project-stages/edit-section/edit-section.component";
import { NewUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/new-user-project/new-user-project.component";
import { DeleteUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/delete-user-project/delete-user-project.component";
import { BlockUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/block-user-project/block-user-project.component";
import { ReinviteUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/reinvite-user-project/reinvite-user-project.component";
import { PermissionsInfoModal } from 'app/layouts/my-projects/permissions-info-modal/permissions-info-modal.component';
import { ProjectProposalNewUserComponent } from "app/layouts/my-projects/project-proposal/project-proposal-team/project-proposal-new-user/project-proposal-new-user.component";
import { DeclineProposalPopupComponent } from "app/layouts/my-projects/project-invites/decline-proposal-popup/decline-proposal-popup.component";

/* ----------- * SERVICES * ----------- */
// import { ProdPartnerService } from "app/shared/services/prod-partner.service";
// import { ProductionTeamService } from 'app/shared/services/proposal-services/productionteam.service';

/* ----------- * ROUTE * ----------- */
import { ProjectsRoutingModule } from './projects-routes';
import { SharedModule } from 'app/sub-modules/SharedModule';
// import { AnnotationService } from "app/shared/services/annotation.service";
import { ProjectSharedModule } from "../project-modules/project-SharedModule";
import { MtmChatModule } from "../mtm-chat-module/mtm-chat.module";
import { ProposalsComponent } from "app/layouts/proposals/proposals.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { WorkspaceListComponent } from "app/layouts/my-projects/workspace-list/workspace-list.component";

import { UiSwitchModule } from 'ngx-ui-switch';
import { ProjectNotificationSettingComponent } from 'app/layouts/my-projects/new-project/project-notification-setting/project-notification-setting.component';
import { SearchVideoComponent } from 'app/layouts/my-projects/search-video/search-video.component';
import { ProjectPermissionTableModule } from 'app/layouts/my-projects/new-project/project-permissions/table/project-permission-table.module';
import { ProjectTeamTableModule } from 'app/layouts/my-projects/new-project/project-permissions/team-table/project-team-table.module';
import { AgGridModule } from "ag-grid-angular";
import { ProjectListViewComponent } from "../../layouts/my-projects/project-list-view/project-list-view.component";
import {
	ProjectNameCellComponent
} from "../../layouts/my-projects/project-list-view/table/renderer/project-name-cell/project-name-cell.component";
import {
	ProjectOwnerCellComponent
} from "../../layouts/my-projects/project-list-view/table/renderer/project-owner-cell/project-owner-cell.component";
import {
	ProjectBudgetCellComponent
} from "../../layouts/my-projects/project-list-view/table/renderer/project-budget-cell/project-budget-cell.component";
import {
	ProjectActionsCellComponent
} from "../../layouts/my-projects/project-list-view/table/renderer/project-action-cell/project-action-cell.component";
import {
	ProjectListTableHeaderComponent
} from "../../layouts/my-projects/project-list-view/table/header/project-list-table-header.component";
import { ProjectCompletionDateComponent } from "../../layouts/my-projects/project-completion-date/project-completion-date.component";
import {
	ProjectStatusCellComponent
} from "../../layouts/my-projects/project-list-view/table/renderer/project-status-cell/project-status-cell.component";
import {
	ProjectStatusFilterComponent
} from "../../layouts/my-projects/project-list-view/table/filter/project-status-filter/project-status-filter.component";
import {
	ProjectStatusFloatingFilterComponent
} from "../../layouts/my-projects/project-list-view/table/filter/project-status-floating-filter/project-status-floating-filter.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
	declarations: [
		MyProjectsComponent,
		ProjectInvitationComponent,
		ArchivedProjectsComponent,
		ProjectProposalsComponent,
		RateProdCompComponent,
		NewProjectComponent,
		ProjectStagesComponent,
		ProjectPermissionsComponent,
		ProjectAssetsComponent,
		ProjectProposalComponent,
		ProjectProposalTeamComponent,
		ProjectProposalQaSessionComponent,
		ProjectProposalUploadProposalComponent,
		ProjectProposalDiscussionComponent,
		ProjectProposalProjectDetails,
		ProjectProposalUserPermissions,
		ProjectProposalStages,
		ThumbnailComponent,
		ProjectThumbnailListComponent,
		WorkspaceListComponent,
		ProjectListViewComponent,
		ProjectListTableHeaderComponent,
		ProjectNameCellComponent,
		ProjectOwnerCellComponent,
		ProjectBudgetCellComponent,
		ProjectStatusCellComponent,
		ProjectStatusFilterComponent,
		ProjectStatusFloatingFilterComponent,
		ProjectActionsCellComponent,
		InvitationComponent,
		ProjectProposalBriefComponent,
		TeamFilesListComponent,
		TeamFilesGridComponent,
		ProjectPermissionsComponent,
		ProjectSortingComponent,
		EditSectionComponent,
		NewUserProjectComponent,
		DeleteUserProjectComponent,
		BlockUserProjectComponent,
		ReinviteUserProjectComponent,
		PermissionsInfoModal,
		ProjectProposalNewUserComponent,
		DeclineProposalPopupComponent,
		ProposalsComponent,
		ProjectNotificationSettingComponent,
		SearchVideoComponent,
		ProjectCompletionDateComponent
	],
	imports: [
		HttpClientModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgClickOutsideDirective,
		NgbModule,
		DragulaModule,
		TagInputModule,
		// Ng2AutoCompleteModule,
		AutocompleteLibModule,
		// SharedModule,
		ProjectsRoutingModule,
		ProjectSharedModule,
		MtmChatModule,
		SharedModule,
		UiSwitchModule,
		ProjectPermissionTableModule,
		ProjectTeamTableModule,
		AgGridModule,
		NgSelectModule
	],
	// entryComponents: [
	// 	EditSectionComponent,
	// 	NewUserProjectComponent,
	// 	DeleteUserProjectComponent,
	// 	BlockUserProjectComponent,
	// 	ReinviteUserProjectComponent,
	// 	PermissionsInfoModal,
	// 	ProjectProposalNewUserComponent,
	// 	DeclineProposalPopupComponent
	// ],
	providers: [
		// ProdPartnerService,
		// ProductionTeamService,
		// AnnotationService,
		ProposalProjectLinksGuard,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ProjectsModule {
}
