<div class="row">
  <div class="col-6 my-3">
    <input [(ngModel)]="projectSearch" class="search-box pl-2 pr-4" (ngModelChange)="searchProject()" type="text"
      placeholder="{{'search' | translate}}" />
    <i class="fa fa-search search-icon" aria-hidden="true"></i>
  </div>
  <div class="col-6 my-3 ">
    <div class="row justify-content-end align-items-center pr-5">
      <!-- calendar dropdown -->
      <div class="calendar-block d-flex mr-3">
        <div class="d-flex align-items-center">
          <div #monthYearDropdown class="d-flex align-items-center calendar-dropdown ml-auto" ngbDropdown>
            <div class="dropdown-toggle mt-auto d-flex align-items-center p-0 pointer" ngbDropdownToggle>
              <div class="_400 ml-2 mr-3">{{calendarViewMode | translate}}
              </div>
              <div class="calendar-icon-div pointer ml-auto">
                <img src="../../../../assets/img/project-v2/calendar.svg" alt="">
              </div>
            </div>
            <div class="calendar-dropdown-menu w-100" ngbDropdownMenu>
              <div ngbDropdownItem (click)="selectCalendarMode('latest')">{{'Latest' | translate}}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('week')">{{'Week' | translate}}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('3_months')">{{'3 Months' | translate}}</div>
              <div ngbDropdownItem (click)="selectCalendarMode('6_months')">{{'6 Months' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- workspace dropdown -->
      <div class="d-flex align-items-center item-filter mr-3" container="body" ngbDropdown>
        <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis f-10 pointer"
          ngbDropdownToggle>
          {{ shouldShowEllipsis(selectedWorkspace.name) ? (selectedWorkspace.name | slice:0:12) + '...' :
          selectedWorkspace.name | translate }}
        </div>
        <div class="" [style.max-width]="'169px'" [style.min-width]="'169px'" ngbDropdownMenu>
          <div class="_1-line-text-ellipsis f-12 pointer" (click)="changeWorkspace('all_workspaces')" ngbDropdownItem>
            All Workspaces
          </div>
          <div class="_1-line-text-ellipsis f-12 pointer" (click)="changeWorkspace(workspace)"
            *ngFor="let workspace of workspaces" ngbDropdownItem
            [ngbTooltip]="shouldShowEllipsis(workspace.name) ? workspace.name : null">
            {{workspace.name}}
          </div>
        </div>
      </div>
      <!-- project dropdown -->
      <div class="d-flex align-items-center item-filter" container="body" ngbDropdown>
        <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis pointer f-10"
          ngbDropdownToggle>
          {{ shouldShowEllipsis(selectedProjectType) ? (selectedProjectType | translate | slice:0:12) + '...' : selectedProjectType |  translate }}
        </div>
        <div class="" [style.max-width]="'169px'" [style.min-width]="'169px'" ngbDropdownMenu>
          <div class="_1-line-text-ellipsis pointer f-12" (click)="changeProjectType(projecttype)" *ngFor="let projecttype of projectType"
            ngbDropdownItem [ngbTooltip]="shouldShowEllipsis(projecttype) ? projecttype : null">
            {{projecttype | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div *ngIf="canCreateProject && isStandAlone" [style.max-width]="'200px'" [style.min-width]="'200px'" class="col d-flex mb-4 pointer" >
    <button class="campaign-drive-btn w-100" (click)="createProject()">
      <div class="circle-div">
        <i class="fa fa-plus d-flex" aria-hidden="true"></i>
      </div>
      <h6 class="_700 f-10 mt-3 line-height" [style.color]="'#898C98'">{{'createNewProject' | translate}}</h6>
    </button>
  </div>
  <div *ngIf="!isLoaded && !isError" class="skeleton">
    <mtm-grid-skeleton [borderRadius]="6" [gridCount]="5" [widthSizeInPixels]="180"
      [HeightSizeInPixels]="235"></mtm-grid-skeleton>
  </div>
  <div class="d-flex justify-content-center align-items-center w-75" *ngIf="isError">
    <span class="_600 f-12 ">{{'loreal_dashboard_somethingWentWrong' |  translate}}</span>
  </div>
  <div class="col d-flex mb-4 pointer" [style.max-width]="'200px'" [style.min-width]="'200px'" *ngFor="let item of projects" (click)="redirectToDashboard(item)">
    <div class="project-card" id="trainerProjectCard">
      <div class="card-img">
        <img *ngIf="item.avatarUrl" class="image-style" [src]="item.avatarUrl + '?v=' + cacheBuster" alt="">
        <span *ngIf="!item.avatarUrl" class="c-full-white profile-text text-uppercase h-100 w-100"
          [style.backgroundColor]="timelineService.getColorFromName(item.name)">
          {{item.name | slice:0:2}}
        </span>
        <span class="badge badge-edit-button" >
          <i (click)="$event.stopImmediatePropagation();markAsFavorite(item)" *ngIf="!item.isFavorite" class="fa fa-star-o" aria-hidden="true"></i>
          <i (click)="$event.stopImmediatePropagation();removeFavorite(item)" *ngIf="item.isFavorite" class="fa fa-star" [style.color]="'#ffca4c'" aria-hidden="true"></i>
      </span>
      </div>
      <div class="card-block d-flex">
        <div class="col">
          <div class="row">
            <div class="col">
              <span class="f-10 _700 brand-name" [style.color]="'#898C98'">{{workspaceName}}</span>
            </div>
          </div>
          <div class="row" [style.height]="'45px'">
            <div class="col">
              <span class="f-15 _700 c-full-black" [ngbTooltip]="shouldShowEllipsis(item.name) ? item.name : null">
                {{ shouldShowEllipsis(item.name) ? (item.name | slice:0:12) + '...' : item.name }}</span>
            </div>
            <div class="pointer" [style.height]="'20px'" style="position: relative; top: -5px;"
            (click)="$event.stopImmediatePropagation()">
            <div  ngbDropdown class="dropdown" container="body" placement="bottom-right">
              <button ngbDropdownToggle class="edit-button">
                <i class="fa fa-ellipsis-v fa-lg" [style.color]="'#898D98'" aria-hidden="true"></i>
              </button>
              <div ngbDropdownMenu class="dropdown-menu-left arrow_box" aria-labelledby="dropdownMenuLink">
                <div class="col options hover-text">
                  <div class="col pointer p-0 d-flex align-items-center my-3 " (click)="editProject(item)">
                    <i class="_500 fa fa-pencil fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                    <span class="ml-2 f-10 _700"> SETTINGS</span>
                  </div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="markAsFavorite(item)">
                    <i *ngIf="!item.isFavorite" class="_500 fa fa-star-o fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                    <i *ngIf="item.isFavorite" class="_500 fa fa-star fa-lg " [style.color]="'#ffca4c'" aria-hidden="true"></i>
                    <span class="ml-2 f-10 _700"> {{ !item.isFavorite ? 'MARK AS FAVORITE' : 'REMOVE FAVORITE' }} </span></div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="archiveRestoreProject(item)">
                    <img [style.color]="'#181E39'" width="15" height="12" src="../../../assets/svg/archive-svg.svg" alt="">
                    <span class="ml-2 f-10 _700"> {{!item.deleted ? 'ARCHIVE' : 'UNARCHIVE'}} </span></div>
                  <div class="col pointer p-0 d-flex align-items-center my-3" (click)="deleteProject(item)">
                    <i class="_500 fa fa-trash-o fa-lg" [style.color]="'#181E39'" aria-hidden="true"></i>
                    <span class="ml-2 f-10 _700"> DELETE </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="row date-section">
            <div class="col-12 pr-0">
              <span class="d-flex f-9 _700 c-full-black text-uppercase my-1">Start: <span class="ml-1">{{item.startDate | date:'dd.MM.YYYY'}}</span></span>
              <span class="d-flex f-9 _700 c-full-black text-uppercase my-1">end: <span class="ml-1">{{item.endDate | date:'dd.MM.YYYY'}}</span></span>
            </div>
            <div class="col-12 pr-0" (click)="$event.stopImmediatePropagation()">
              <div ngbDropdown class="d-flex align-items-center" [autoClose]="true" #dropdowntaskStatus>
                <button [disabled]="!canEditStatus" (click)="$event.stopImmediatePropagation()" type="button"
                  class="d-flex align-items-center btn project-status-dropdown" ngbDropdownToggle>
                  <div class="d-flex align-items-center project-status {{item.status}}">
                    <div class="project-union-icon-container">
                      <i class="project-union-icon mtm-icon mtm-icon-union-{{taskColor[item.status]}}"></i>
                    </div>
                    <span class="mr-2">{{'task_'+item.status|translate}}</span>
                    <i class="fa fa-chevron-down ml-auto pointer"></i>
                  </div>
                </button>
                <div class="project-dropdown-menu" ngbDropdownMenu>
                  <div
                    *ngIf="!item.status||item.status==taskStatus.Completed||item.status==taskStatus.UpcomingExpiry||item.status==taskStatus.Delayed"
                    (click)="$event.stopImmediatePropagation();changeProjectStatus(item,taskStatus.InProgress)"
                    class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                    <div class="d-flex align-items-center project-status-display IN_PROGRESS">
                      <i class="project-status-circle-color mr-2"></i>
                      <span>{{'task_IN_PROGRESS'|translate}}</span>
                    </div>
                  </div>
                  <div
                    *ngIf="!item.status||item.status==taskStatus.InProgress||item.status==taskStatus.UpcomingExpiry||item.status==taskStatus.Delayed"
                    (click)="$event.stopImmediatePropagation();changeProjectStatus(item,taskStatus.Completed)"
                    class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                    <div class="d-flex align-items-center project-status-display COMPLETED">
                      <i class="project-status-circle-color mr-2"></i>
                      <span>{{'task_COMPLETED'|translate}}</span>
                    </div>
                  </div>
                  <div
                    *ngIf="!item.status||item.status==taskStatus.InProgress||item.status==taskStatus.UpcomingExpiry||item.status==taskStatus.Completed"
                    (click)="$event.stopImmediatePropagation();changeProjectStatus(item,taskStatus.Delayed)"
                    class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                    <div class="d-flex align-items-center project-status-display DELAYED">
                      <i class="project-status-circle-color mr-2"></i>
                      <span>{{'task_DELAYED'|translate}}</span>
                    </div>
                  </div>
                  <!-- <div
                  *ngIf="!item.status||item.status==taskStatus.ToDo||item.status==taskStatus.UpcomingExpiry||item.status==taskStatus.Completed"
                  (click)="$event.stopImmediatePropagation();changeProjectStatus(item,taskStatus.ToDo)"
                  class="project-dropdown-item pt-1 pb-1 pl-2 pr-2">
                  <div class="d-flex align-items-center project-status-display TO_COME">
                    <i class="project-status-circle-color mr-2"></i>
                    <span>{{'task_TO_COME'|translate}}</span>
                  </div>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
