import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

declare var window: any;


@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    constructor(private toastrService: ToastrService
    ) {

    }

    private toasts: any;

    showSuccess(msg?, title?) {
        this.toastrService.success(msg, title);
    }

    showSuccessWithTimeout(msg?): Promise<any> {
        return new Promise<any>((resolve) => {
            this.toastrService.success((msg));
            setTimeout(() => resolve(true), 1000);
        });

    }

    showError(error?, title?): ActiveToast<any> {
        return this.toastrService.error(error, title);
    }

    showWarning(warning?, title?): ActiveToast<any> {
        return this.toastrService.warning(warning, title);
    }

    showInfo(info?, title?): ActiveToast<any> {
        return this.toastrService.info(info, title);
    }

    clear() {
        this.toastrService.clear();
    }
}
