import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmitterService } from "../services/emitter.service";
import { HelperService } from "../services/helper.service";


@Directive({ selector: '[assetPreviewDirective]' })
export class AssetPreviewDirective implements OnInit, OnChanges, OnDestroy {
    @Input() assetPreviewDirective;
    @Input() assetPreviewThumbnailPosition;
    @Input() showFolderIfEmpty;

    timeInterval: any;
    retryTime: number = 5000; // In ms
    ngUnsubscribe = new Subject();
    childEl: any = null;

    constructor(
        public elementRef: ElementRef,
        private renderer: Renderer2) { }

    ngOnInit() {
        this.init();

        if (typeof this.assetPreviewDirective == 'object') {
            this.assetPreviewDirective.updatePreviewImage = () => {
                this.init();
            }
        }
    }

    ngOnChanges() {
        setTimeout(() => {
            this.init();
        }, 0);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
        clearTimeout(this.timeInterval);
    }

    private init() {
        let type = this.assetPreviewDirective.contentType || this.assetPreviewDirective.type;
        this.clearPreviousState();
        if (!type) {
            if (this.assetPreviewDirective.status === 'uploading') {
                EmitterService.get('file-upload-done-' + this.assetPreviewDirective.data[0].id).pipe(
                    takeUntil(this.ngUnsubscribe)
                ).subscribe((file) => {
                    this.assetPreviewDirective = file;
                    this.init();
                });
                this.showImage(null, this.elementRef, false);
            }
        } else {
            let itemType = type.split('/')[0];
            /* let transcodeMetadata = this.assetPreviewDirective.transcodeMetadata || []; */
            if (itemType === "image") {
                let thumbnailUrl;
                let thumbnailExtension
                if (this.assetPreviewThumbnailPosition === 'vertical') {
                    thumbnailExtension = "?thumbnail=_SMALL_209_241";
                }
                else if (this.assetPreviewThumbnailPosition === 'horizontal') {
                    thumbnailExtension = "?thumbnail=_SMALL_211_121"
                }
                else {
                    thumbnailExtension = "?thumbnail=_SMALL"
                }
                thumbnailUrl = (this.assetPreviewDirective.signedURL || this.assetPreviewDirective.previewImage) + thumbnailExtension;
                let img = new Image();
                img.onload = () => {
                    this.elementRef.nativeElement.style.backgroundImage = "url(" + thumbnailUrl + ")";
                }
                img.onerror = () => {
                    let thumbnailUrlSource = (this.assetPreviewDirective.signedURL || this.assetPreviewDirective.previewImage);
                    this.elementRef.nativeElement.style.backgroundImage = "url(" + thumbnailUrlSource + ")";
                }
                img.src = thumbnailUrl;
            }

            else if (itemType === "video") {
                let thumbnailUrl;
                // if (this.assetPreviewDirective.transcodeMetadata.filter(item => item.quality === "_SMALL").length != 0) {
                //     thumbnailUrl = this.assetPreviewDirective.signedURL + "?thumbnail=_SMALL";
                // }

                let thumbnailExtension
                if (this.assetPreviewThumbnailPosition === 'vertical') {
                    thumbnailExtension = "?thumbnail=_SMALL_209_241";
                }
                else if (this.assetPreviewThumbnailPosition === 'horizontal') {
                    thumbnailExtension = "?thumbnail=_SMALL_211_121"
                }
                else {
                    thumbnailExtension = "?thumbnail=_SMALL"
                }

                thumbnailUrl = (this.assetPreviewDirective.signedURL || this.assetPreviewDirective.previewImage) + thumbnailExtension;
                // this.checkFileExists(this.assetPreviewDirective.signedURL, thumbnailUrl)
                this.showImage(thumbnailUrl, this.elementRef);
                // this.timeInterval = setInterval(this.showImage, 3000, thumbnailUrl, this.elementRef);
            }

            else if (itemType === "audio") {
                // this.elementRef.nativeElement.style.backgroundImage = "url(../../../assets/img/misc/audioON.svg)";
                let previewImage = "../../../assets/img/misc/audioON.svg";
                let imageEl = document.createElement('img');
                this.elementRef.nativeElement.classList.add("audio-image");
                imageEl.src = previewImage;
                imageEl.setAttribute('width', '50%');
                this.elementRef.nativeElement.appendChild(imageEl);
                this.childEl = imageEl;
            }
            else if (type == 'application/pdf') {
                let imageUrl = "/assets/img/icons/pdf.svg";
                this.previewImage(imageUrl);
            }
            else if(type == 'application/vnd.ms-powerpoint' || type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                let imageUrl = "/assets/img/icons/ppt.svg";
                this.previewImage(imageUrl);
            }
            else if(type == 'application/msword' || type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                let imageUrl = "/assets/img/icons/doc.svg";
                this.previewImage(imageUrl);
            }
            else if(type == 'application/vnd.ms-excel' || type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                let imageUrl = "/assets/img/icons/xls.svg";
                this.previewImage(imageUrl);
            }
            else if(HelperService.isOfficeMimeType(type)) {
                let imageUrl = "/assets/img/icons/file.svg";
                this.previewImage(imageUrl);
            }
            else {
                let imageUrl = "/assets/img/icons/file.svg";
                this.previewImage(imageUrl);
            }
        }
    }

    private clearPreviousState() {
        if (this.childEl) {
            this.elementRef.nativeElement.removeChild(this.childEl);
        }

        const classesToRemove = ['audio-image', 'file-image'];
        classesToRemove.forEach(className => {
            this.elementRef.nativeElement.classList.remove(className);
        });

        this.elementRef.nativeElement.style.backgroundImage = '';
        this.childEl = null;
        $(this.elementRef.nativeElement).empty();
    }

    private previewImage(imageUrl) {
        let imageEl = document.createElement('img');
        this.elementRef.nativeElement.classList.add("file-image");
        imageEl.src = imageUrl;
        let index = this.assetPreviewDirective.index;
        let thumbLength = this.assetPreviewDirective.thumbLength;
        let imgWidth = (index == 2 || index == 3) ? '30%' : '50%';
        if (!this.assetPreviewDirective.isEditSection) {
            imgWidth = '50%';
        }
        else if (index == 2 || index == 3) {
            imgWidth = '30%';
        }
        else if (index == 1 && thumbLength > 1) {
            imgWidth = '30%';
        }
        else if (thumbLength == 1) {
            imgWidth = '40%';
        }
        else {
            imgWidth = '50%';
        }
        imageEl.setAttribute('width', imgWidth);
        imageEl.classList.add('image-file');
        imageEl.setAttribute('title', thumbLength);
        this.elementRef.nativeElement.appendChild(imageEl);
        this.childEl = imageEl;
    }

    private showImage(thumbnailUrl, elementRef, retry = true) {
        let img = new Image();
        img.onload = () => {
            if ($(elementRef.nativeElement).find('i').length > 0) {
                $(elementRef.nativeElement).find('i')[0].remove();
            }
            elementRef.nativeElement.style.backgroundImage = "url(" + thumbnailUrl + ")";
        }
        img.onerror = () => {
            let spinner = document.createElement("i");
            spinner.className = this.showFolderIfEmpty ? "fa fa-folder fa-4x" : "fa fa-spinner fa-pulse fa-2x";
            spinner.style.color = this.showFolderIfEmpty ? 'rgba(255,255,255,0.2)' : "#E4442A";
            if ($(elementRef.nativeElement).find('i').length == 0) {
                elementRef.nativeElement.className += " d-flex align-items-center justify-content-center";
                elementRef.nativeElement.appendChild(spinner);
            }
            if (retry && document.body.contains(elementRef.nativeElement)) {
                this.timeInterval = setTimeout(() => {
                    this.showImage(thumbnailUrl, elementRef)
                }, this.retryTime);
                if (this.retryTime < 40000) this.retryTime *= 2;
            }
        }
        img.src = thumbnailUrl;

    }

    private checkFileExists(url, thumbnailUrl) {
        fetch(url, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        })
            .then(response => {
                if (response.ok) {
                    this.showImage(thumbnailUrl, this.elementRef);
                }
                else {
                    let imageUrl = "../../../assets/img/icons/error.png";
                    this.previewImage(imageUrl)
                }
            })
            .catch((error) => {
                let imageUrl = "../../../assets/img/icons/error.png";
                this.previewImage(imageUrl)
            });
    }


}
