import { ColDef } from "ag-grid-community";
import { EmailCellComponent } from "app/layouts/my-projects/new-project/project-permissions/table/renderer/email/email-cell.component";
import { ActionCellComponent } from "./renderer/action/action-cell.component";
import { DecisionMakerCellComponent } from "./renderer/decision-maker/decision-maker-cell.component";
import { RoleCellComponent } from "./renderer/role/role-cell.component";
import { UserCellComponent } from "./renderer/user/user-cell.component";
import { TransferWorkspaceProjectsRightsCellComponent } from "./renderer/transfer/transfer-project-rights-cell.component";

export const workspaceProjectParticipantColumnsDef: ColDef[] = [
    {
        field: 'action',
        headerName: 'Action',
        minWidth: 280,
        sortable: false,
        cellRenderer: ActionCellComponent
    },
    {
        field: 'fullName',
        headerName: 'User',
        sortable: true,
        minWidth: 320,
        flex: 1,
        cellRenderer: UserCellComponent
    },
    {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        autoHeight: true,
        minWidth: 240,
        flex: 1,
        cellRenderer: EmailCellComponent
    },
    {
        field: 'role',
        headerName: 'Role',
        sortable: false,
        autoHeight: true,
        minWidth: 350,
        flex: 1,
        cellRenderer: RoleCellComponent
    },
    {
        field: 'rights',
        headerName: 'Ownership',
        sortable: false,
        autoHeight: true,
        minWidth: 140,
        flex: 1,
        cellRenderer: TransferWorkspaceProjectsRightsCellComponent
    },
    {
        field: 'decisionMaker',
        headerName: 'Decision Maker',
        sortable: false,
        suppressMenu: false,
        autoHeight: true,
        minWidth: 280,
        flex: 1,
        cellRenderer: DecisionMakerCellComponent
    }
]