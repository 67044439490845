import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { NGValidators } from "ng-validators";
import { ErrorMessageService } from "../../shared/services/error-message.service";
import { AuthService } from "../../shared/services/auth.service";
import { SignupFormComponent } from './../signup-form/signup-form.component';
import { RegistrationService } from './../../shared/services/registration.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	templateUrl: 'request-invitation.component.html',
	styleUrls: ['request-invitation.component.scss']
})
export class RequestInvitationComponent implements OnInit, OnDestroy {

	firstName: string = null;
	lastName: string = null;
	email: string = null;
	signupForm: UntypedFormGroup;
	submitted: boolean = false;
	success: boolean = false;        // if true, display success page
	formErrors: any = null;
	ngUnsubscribe = new Subject();

	constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService, private modalService: NgbModal, private registrationService: RegistrationService) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}


	ngOnInit() {

		// Read params
		this.route.queryParams.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(params => {
			this.firstName = params['firstName'];
			this.lastName = params['lastName'];
			this.email = params['email'];

			// Load form group
			this.loadFormGroup();
		});

	}


	/**
	 * Open Signup form modal
	 */
	onOpenSignupModal() {
		let modalRef = this.modalService.open(SignupFormComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
		modalRef.result.then((msg) => {
			if (msg)
				this.router.navigate(['/entry']);
		}).catch((err) => {
			console.log(err);
		});
	}


	/**
	 * Submit form
	 */
	onSubmit() {
		this.submitted = true;

		if (this.signupForm.valid) {

			// Call API to request invitation
			this.registrationService.requestInvitation(this.signupForm.controls['firstName'].value,
				this.signupForm.controls['lastName'].value,
				this.signupForm.controls['company'].value,
				this.signupForm.controls['email'].value,
				this.signupForm.controls['phone'].value,
				this.signupForm.controls['message'].value).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data: any) => {

					/*
					// Get authUser and check if verified flag is true or not
					let authUser = this.authService.getAuthUser();
					if (!authUser.verified)
						this.router.navigate(['/verification']);
					else
						this.router.navigate(['/upload']);
					*/

					this.success = true;
				});
		}
	}


	/**
	 * Load form
	 */
	private loadFormGroup() {

		// Build FormGroup
		this.signupForm = this.fb.group({
			firstName: [this.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			lastName: [this.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			company: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			email: [this.email, [Validators.required, Validators.email]],
			phone: ['', [Validators.required, Validators.pattern(/^\+[1-9][0-9]{3,14}$/)]],
			message: ['', [Validators.required]]
		});

		// Subscribe to changes to get validation errors
		this.signupForm.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.signupForm)
			this.formErrors = ErrorMessageService.getFormErrors(this.signupForm);
	}

}
