import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from 'rxjs';
import { AuthService } from "../../../shared/services/auth.service";
import { ImageCropperComponent } from "app/shared/components/image-cropper/component/image-cropper.component";
import { Dimensions, ImageCroppedEvent, ImageTransform } from "app/shared/components/image-cropper/interfaces";

/**
 * Override draw method of ImageCropper
 */
// ImageCropper.prototype.draw = function (ctx) {
// 	var bounds = this.getBounds();
// 	if (this.srcImage) {
// 		ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
// 		var sourceAspect = this.srcImage.height / this.srcImage.width;
// 		var canvasAspect = this.canvasHeight / this.canvasWidth;
// 		var w = this.canvasWidth;
// 		var h = this.canvasHeight;
// 		if (canvasAspect > sourceAspect) {
// 			w = this.canvasWidth;
// 			h = this.canvasWidth * sourceAspect;
// 		}
// 		else {
// 			h = this.canvasHeight;
// 			w = this.canvasHeight / sourceAspect;
// 		}
// 		this.ratioW = w / this.srcImage.width;
// 		this.ratioH = h / this.srcImage.height;
// 		if (canvasAspect < sourceAspect) {
// 			this.drawImageIOSFix(ctx, this.srcImage, 0, 0, this.srcImage.width, this.srcImage.height, this.buffer.width / 2 - w / 2, 0, w, h);
// 		}
// 		else {
// 			this.drawImageIOSFix(ctx, this.srcImage, 0, 0, this.srcImage.width, this.srcImage.height, 0, this.buffer.height / 2 - h / 2, w, h);
// 		}

// 		//Cover.drawImageProp(ctx, this.srcImage, 0, 0, this.canvasWidth, this.canvasHeight, .5, .5);

// 		this.buffer.getContext("2d").drawImage(this.canvas, 0, 0, this.canvasWidth, this.canvasHeight);
// 		ctx.lineWidth = this.cropperSettings.cropperDrawSettings.strokeWidth;
// 		ctx.strokeStyle = this.cropperSettings.cropperDrawSettings.strokeColor;
// 		if (!this.cropperSettings.rounded) {
// 			ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
// 			ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
// 			ctx.drawImage(this.buffer, bounds.left, bounds.top, Math.max(bounds.width, 1), Math.max(bounds.height, 1), bounds.left, bounds.top, bounds.width, bounds.height);
// 			ctx.strokeRect(bounds.left, bounds.top, bounds.width, bounds.height);
// 		} else {
// 			ctx.save();
// 			ctx.fillStyle = "rgba(64, 64, 64, 0.5)";
// 			ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

// 			ctx.beginPath();
// 			ctx.arc(bounds.left + bounds.width / 2, bounds.top + bounds.height / 2, bounds.width / 2, 0, Math.PI * 2, true);
// 			ctx.closePath();
// 			ctx.clip();

// 			ctx.drawImage(this.buffer, bounds.left, bounds.top, Math.max(bounds.width, 1), Math.max(bounds.height, 1), bounds.left, bounds.top, bounds.width, bounds.height);

// 			ctx.beginPath();
// 			ctx.arc(bounds.left + bounds.width / 2, bounds.top + bounds.height / 2, bounds.width / 2, 0, Math.PI * 2, true);
// 			ctx.clip();
// 			ctx.lineWidth = 4;
// 			ctx.strokeStyle = '#49b092';
// 			ctx.stroke();
// 			ctx.closePath();
// 			ctx.restore();
// 		}
// 		var marker = void 0;
// 		for (var i = 0; i < this.markers.length; i++) {
// 			marker = this.markers[i];
// 			marker.draw(ctx);
// 		}
// 		this.center.draw(ctx);
// 	}
// 	else {
// 		ctx.fillStyle = "rgba(192,192,192,1)";
// 		ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
// 	}
// }

@Component({
	templateUrl: './upload-modal.component.html',
	styleUrls: ['./upload-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UploadModalComponent implements OnInit {

	data: any;
	//cropperSettings: CropperSettings;
	uploadObserver: BehaviorSubject<any> = new BehaviorSubject(false);
	croppedWidth: number;
	croppedHeight: number;
	imageFile: any;
	@Input() isCreateProject: boolean = false;
	@ViewChild(ImageCropperComponent) cropper!: ImageCropperComponent;
	// @ViewChild( ImageCropperComponent ) cropper!: ImageCropperComponent;

	constructor(public activeModal: NgbActiveModal, private authService: AuthService) { }

	ngOnInit() {

	}
	setCrop(isProjectImage) {
		if (isProjectImage) {
			// this.cropperSettings = new CropperSettings();
			// this.cropperSettings.keepAspect = true;
			// this.cropperSettings.width = 594;
			// this.cropperSettings.height = 248;
			// this.cropperSettings.canvasWidth = 500;
			// this.cropperSettings.canvasHeight = 450;
			// this.cropperSettings.minWidth = 10;
			// this.cropperSettings.minHeight = 10;
			// this.cropperSettings.rounded = false;
			// this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
			// this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
			// this.cropperSettings.noFileInput = true;
			// this.cropperSettings.preserveSize = true;
			this.data = {};
		}
		else {
			// this.cropperSettings = new CropperSettings();
			// this.cropperSettings.width = 400;
			// this.cropperSettings.height = 400;
			// this.cropperSettings.keepAspect = false;
			// this.cropperSettings.croppedWidth = 128;
			// this.cropperSettings.croppedHeight = 128;
			// this.cropperSettings.canvasWidth = 500;
			// this.cropperSettings.canvasHeight = 450;
			// this.cropperSettings.minWidth = 10;
			// this.cropperSettings.minHeight = 10;
			// this.cropperSettings.rounded = false;
			// this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
			// this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
			// this.cropperSettings.noFileInput = true;
			// this.cropperSettings.preserveSize = true;
			this.data = {};
		}
	}


	// ****************************************************************************************************************

	/**
	 * Upload image/thumbnail selectedd
	 */
	// cropped(bounds:Bounds) {
	//     this.croppedHeight =bounds.bottom-bounds.top;
	//     this.croppedWidth = bounds.right-bounds.left;
	//   }
	// fileChangeListener($event, cropperComp: IC) {
	// 	this.cropper = cropperComp;
	// 	var image: any = new Image();
	// 	var file: File = $event.target.files[0];
	// }

	upload() {
		this.activeModal.close();
		this.isCreateProject;
		this.uploadObserver.next(this.data.image);
	}

	//start
	imageChangedEvent: any = '';
	croppedImage: any = '';
	canvasRotation = 0;
	rotation = 0;
	scale = 1;
	showCropper = false;
	containWithinAspectRatio = false;
	transform: ImageTransform = {};

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.data.image = this.croppedImage;
		// console.log(event, base64ToFile(event.base64));
	}

	imageLoaded() {
		this.showCropper = true;
		// console.log('Image loaded');
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		sourceImageDimensions.width = 1000;
		sourceImageDimensions.height = 1000;

		// console.log('Cropper ready', sourceImageDimensions);
	}

	loadImageFailed() {
		// console.log('Load failed');
	}

}
