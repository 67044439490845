<div class="upgrade-component">
	<div class="d-flex flex-column align-items-center justify-content-center" [style.color]="'#03041F'">
		<div class="text-center">
			<img width="30" class="mb-4 d-block mx-auto" src="/assets/img/rocket.svg">

			<h2 class="mb-4">{{'subscription_upgrade_produce_confirmation'|translate}}</h2>
		</div>
		<div class="d-flex flex-column align-items-center justify-content-center info">
			<ng-container *ngIf="isDowngrade">
				<div>{{ 'subscription_upgrade_produce_downgraded_message' | translate }} <span class="new-plan">{{
						newPlanStr | translate}} plan</span></div>
				<div>{{ 'subscription_upgrade_produce_downgraded_period' | translate }}</div>
				<div *ngIf="billingDate">{{ 'subscription_upgrade_produce_downgraded_on' | translate }} {{ billingDate
					}}
					{{ 'subscription_upgrade_produce_downgraded_at' | translate}} {{ billingTime }} </div>
			</ng-container>

			<ng-container *ngIf="! isDowngrade">
				<div>{{ 'subscription_upgrade_produce_upgraded_message' | translate }} <span class="new-plan">{{
						newPlanStr | translate }} plan</span></div>
				<div>{{ 'subscription_upgrade_produce_upgraded_period' | translate }}</div>
			</ng-container>
		</div>
		<div class="d-block mt-4">
			<button (click)="returnToDashboard()"
				class="btn mtm-button b-mtm-green t-uppercase c-full-white _600 pt-2 pb-2 pl-3 pr-3">
				{{ (redirectToDashboard ? 'subscription_produce_redirect_to_dashboard' :
				'subscription_produce_return_to_dashboard') | translate}}
			</button>
		</div>
	</div>
</div>