import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SUBSCRIPTION_LIMIT } from "app/subscriptions/models/const";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import { LimitReachedPopupComponent } from "app/subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component";
import { Observable, of } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionCheckService {

    constructor(
        private authService: AuthService,
        private subscriptionService: SubscriptionService,
        private modalService: NgbModal
    ) {

    }

    checkProjectAccess(project: any): Observable<boolean> {
        let modalRef: NgbModalRef = null;

        if (!project) {
            return of(false);
        }

        if (project.projectType != 'SUBSCRIPTION') {
            return of(true);
        }

        //free user cant access archived project
        if (this.authService.hasFreeSubscription() && project.deleted) {
            modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal', backdrop: 'static', keyboard: false });
            modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE;
            return of(false);
        }

        const $obs = new Observable<boolean>(subscriber => {
            this.subscriptionService.getSubscriptionInfo()
                .subscribe((result: any) => {
                    if (result.licenseUsageExceeded) {
                        modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal', backdrop: 'static', keyboard: false });
                        modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.LICENSE_USERS;
                        subscriber.next(false);
                        subscriber.complete();
                        return;
                    }

                    if (result.activeUsageExceeded) {
                        if (this.authService.hasFreeSubscription() || !project.deleted) {
                            modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal', backdrop: 'static', keyboard: false });
                            modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ACTIVE_STORAGE;
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }
                    }

                    if (result.archiveUsageExceeded) {
                        //if archived project or free subscriber, then show popup
                        if (this.authService.hasFreeSubscription() || project.deleted) {
                            modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal', backdrop: 'static', keyboard: false });
                            modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE;
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }
                    }

                    //TODO: use correct property
                    if (result.numberOfFilesExceeded) {
                        modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal', backdrop: 'static', keyboard: false });
                        modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.NUMBER_OF_FILES;
                        subscriber.next(false);
                        subscriber.complete();
                        return;
                    }

                    subscriber.next(true);
                    subscriber.complete();
                }, () => {
                    subscriber.next(false);
                    subscriber.complete();
                });
        });

        return $obs;

    }

}