import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UploadModalComponent } from "./upload-modal/upload-modal.component";
import { UploadService } from '../../shared/services/upload.service';
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectService } from 'app/shared/services/project.service';
import { Authority, PermissionService } from "../../shared/services/permissions.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
// import { Ng2FileDropAcceptedFile } from "ng2-file-drop";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';


@Component({
	templateUrl: './profile-avatar.component.html',
	styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit, OnDestroy {

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;
	@ViewChild('uploadProgress', { static: true }) uploadProgress: ElementRef;
	uploadForm: UntypedFormGroup;
	submitted: boolean = false;
	modal: NgbModalRef;
	uploading: boolean = false;
	thumbnail: any;
	currentProfileImage: any;
	isDragFile: boolean = false;
	user: any;
	ngUnsubscribe = new Subject();
	filesArray: File[] = [];

	constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, public upService: UploadService,
		private router: Router, private authService: AuthService, private projectService: ProjectService,
		public permissionService: PermissionService) {
		// Load form group
		this.loadFormGroup();
	}

	ngOnInit() {
		this.user = this.authService.getAuthUser();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	/**
	 * Submit login form
	 */
	onSubmit() {
		this.submitted = true;

		if (this.uploadForm.valid)
			console.log(this.uploadForm.value);
	}

	/**
	 * Trigger click on input file
	 */
	upload(): boolean {
		this.fileInput.nativeElement.click();
		return false;
	}

	/**
	 * Used to send image to second cropper
	 * @param $event
	 */
	fileChangeListener($event): void {
		if ($event.target.files.length) {

			// Open crop image modal
			this.openModal();
			this.modal.componentInstance.imageChangedEvent = $event;
			setTimeout(() => {
				this.fileInput.nativeElement.value = '';
			}, 500);
			let file: File = $event.target.files[0];
			let fileReader: FileReader = new FileReader();

			let setImageOnModal = this.setImageOnModal;
			setImageOnModal(this, file);
			fileReader.readAsDataURL(file);
		}
	}
	private sleep = (ms: number) => {
		return new Promise(resolve => setTimeout(resolve, ms))
	};

	private async getFiles(draggedFiles: any) {

		for await (const droppedFile of draggedFiles) {

			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file(async (file: File) => {
					this.filesArray.push(file)
				});
			}
		}
	}
	private setFileOnModal(files) {
		this.openModal();
		this.modal.componentInstance.imageFile = files[0];
		let that = this;

		// Load the image in
		let fileReader = new FileReader();
		fileReader.onload = () => {

			// Set and show the image
			this.currentProfileImage = fileReader.result;

			// Set image on modal
			this.setImageOnModal(that, this.currentProfileImage);
		};

		// Read in the file
		fileReader.readAsDataURL(files[0]);
		// fileReader.readAsDataURL(acceptedFile.file(this.currentProfileImage));

	}
	/**
	 * File being dragged has been dropped and is valid
	 * @param acceptedFile
	 */
	async dragFileAccepted(acceptedFile: FileSystemFileEntry) {

		// Open crop image modal
		await this.getFiles(acceptedFile);
		await this.sleep(200);
		this.setFileOnModal(this.filesArray)
		this.filesArray = [];
	}

	/**
	 * Load form group
	 */
	private loadFormGroup() {
		this.uploadForm = this.fb.group({});
	}

	/**
	 * Open crop modal
	 */
	private openModal() {
		this.modal = this.modalService.open(UploadModalComponent, { size: 'lg' });
		this.modal.componentInstance.setCrop(false);
	}

	/**
	 * Set image in the crop modal
	 * Also, subscribe to the event when crop image is saved and display on main page
	 * @param that
	 * @param imageBase64
	 */
	private setImageOnModal(that: any, imageBase64: any) {
		let image: any = new Image();
		// image.src = imageBase64;
		let c = that.modal.componentInstance.cropper;
		// c.setImage(image);

		// Subscribe to modal event
		that.modal.componentInstance.uploadObserver.pipe(
			takeUntil(that.ngUnsubscribe)
		).subscribe((result) => {
			if (result) {
				that.uploading = true;
				that.thumbnail = result;
				that.upService.uploadImage(result).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(data: any) => {

						// Replace avatarUrl image in logged in user (localStorage)
						if (data.avatarUrl) {
							let authUser = that.authService.getAuthUser();
							authUser.avatarUrl = data.avatarUrl;
							that.authService.setAuthUser(authUser);
						}

					}
				)
			}
		});
	}

	continue() {

		if (this.user.company.companyType == 'PRODUCER' && this.permissionService.hasAuthority(Authority.E, null))
			this.router.navigate(['/account/company/experience']);
		else {
			// If projectId, it means user should accept invitation (automatically)
			// and being redirected to this project page
			let projectId = sessionStorage.getItem('projectId');
			if (projectId) {
				this.projectService.acceptInvitation(projectId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(data: any) => {
						this.router.navigate(['/projects/' + projectId + '/dashboard']);
					},
					(err: any) => {
						this.router.navigate(['/projects']);
					}
				)
			}
			else
				this.router.navigate(['/projects']);
		}

		return false;
	}

}
