<div class="register-component">
	<form *ngIf="paymentForm" [formGroup]="paymentForm" class="payment-form d-flex flex-column position-relative">
		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_register_payment_invoiceDetail'|translate}}</h3>
		</div>
		<div class="d-flex flex-column register-form main-input">
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['firstName'].pristine && paymentForm?.controls['firstName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_firstName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_firstName'|translate}}"
						class="form-control" formControlName="firstName" />
					<div
						*ngIf="!paymentForm?.controls['firstName'].pristine && paymentForm?.controls['firstName'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['lastName'].pristine && paymentForm?.controls['lastName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_lastName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_lastName'|translate}}"
						class="form-control" formControlName="lastName" />
					<div
						*ngIf="!paymentForm?.controls['lastName'].pristine && paymentForm?.controls['lastName'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['companyName'].pristine && paymentForm?.controls['companyName'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_companyName'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_companyName'|translate}}"
						class="form-control" formControlName="companyName" />
					<div
						*ngIf="!paymentForm?.controls['companyName'].pristine && paymentForm?.controls['companyName'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['address'].pristine && paymentForm?.controls['address'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_address'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_address'|translate}}"
						class="form-control" formControlName="address" />
					<div
						*ngIf="!paymentForm?.controls['address'].pristine && paymentForm?.controls['address'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['city'].pristine && paymentForm?.controls['city'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_city'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_city'|translate}}"
						class="form-control" formControlName="city" />
					<div
						*ngIf="!paymentForm?.controls['city'].pristine && paymentForm?.controls['city'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['zipCode'].pristine && paymentForm?.controls['zipCode'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_zipCode'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_zipCode'|translate}}"
						class="form-control" formControlName="zipCode" />
					<div
						*ngIf="!paymentForm?.controls['zipCode'].pristine && paymentForm?.controls['zipCode'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['country'].pristine && paymentForm?.controls['country'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_country'|translate}}</label>
					<select class="form-control" formControlName="country" disabled="disabled">
						<option value="">{{'searchCountry' | translate}}</option>
						<option [value]="item.regionCode" *ngFor="let item of listCountryCodes">{{item.countryName}}
						</option>
					</select>
					<div
						*ngIf="!paymentForm?.controls['country'].pristine && paymentForm?.controls['country'].status === 'INVALID'">
						<span>{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['state'].pristine && paymentForm?.controls['state'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label class="control-label mb-1 _600">{{'companyDetail_State' | translate }}</label>
					<input class="form-control" formControlName="state" *ngIf="paymentForm?.value?.country != 'US'">
					<select class="form-control" formControlName="state" *ngIf="paymentForm?.value?.country == 'US'">
						<option value="">{{'companyDetail_SelectState' | translate}}</option>
						<option [value]="item.name" *ngFor="let item of usaStates">{{item.name}}</option>
					</select>
					<div
						*ngIf="!paymentForm?.controls['state'].pristine && paymentForm?.controls['state'].status === 'INVALID'">
						<span class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!paymentForm?.controls['taxIdNumber'].pristine && paymentForm?.controls['taxIdNumber'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<div class="d-flex mb-1 align-items-center">
						<label
							class="control-label mb-0 mr-1 _600">{{'subscription_register_payment_invoiceDetail_taxIdNumber'|translate}}</label>
						<div class="info-icon tooltipped"
							[attr.data-title]="'subscription_register_payment_invoiceDetail_taxIdNumberExample' | translate">
						</div>
					</div>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_taxIdNumber'|translate}}"
						class="form-control" formControlName="taxIdNumber" />
					<div
						*ngIf="!paymentForm?.controls['taxIdNumber'].pristine && paymentForm?.controls['taxIdNumber'].status === 'INVALID'">
						<span>{{'subscription_register_payment_invalidTax'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!paymentForm?.controls['billingCcEmail'].pristine && paymentForm?.controls['billingCcEmail'].status === 'INVALID'"
					class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
					<label
						class="control-label mb-1 _600">{{'subscription_register_payment_invoiceDetail_billingCCEmail'|translate}}</label>
					<input placeholder="{{'subscription_register_payment_invoiceDetail_billingCCEmail'|translate}}"
						class="form-control" formControlName="billingCcEmail" />
					<div class="d-flex flex-column"
						*ngIf="!paymentForm?.controls['billingCcEmail'].pristine && paymentForm?.controls['billingCcEmail'].status === 'INVALID'">
						<span *ngIf="paymentForm?.controls['billingCcEmail'].errors.required"
							class="required">{{'subscription_register_payment_requiredField'|translate}}</span>
						<span *ngIf="paymentForm?.controls['billingCcEmail'].errors.email"
							class="required">{{'invalidEmail'|translate}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_register_payment_creditCardInformation'|translate}}</h3>
		</div>
		<div class="d-flex flex-wrap register-form main-input">
			<div class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
				<label
					class="control-label mb-1 _600">{{'subscription_register_payment_creditCardInformation_number'|translate}}</label>
				<div class="form-control" #creditCardNumber></div>
			</div>
			<div class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'16%'">
				<label
					class="control-label mb-1 _600">{{'subscription_register_payment_creditCardInformation_expiry'|translate}}</label>
				<div class="form-control" #expiry></div>
			</div>
			<div class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'17%'">
				<label
					class="control-label mb-1 _600">{{'subscription_register_payment_creditCardInformation_cvcNumber'|translate}}</label>
				<div class="form-control" #cvcNumber></div>
			</div>
			<div class="payment-inputs d-flex flex-column p-2 mb-2" [style.width]="'33.33%'">
				<label
					class="control-label mb-1 _600">{{'subscription_register_payment_creditCardInformation_zipCode'|translate}}</label>
				<div class="form-control" #zipCode></div>
			</div>
		</div>
		<div class="mt-3 text-center info">
			<div class="mb-2 d-flex align-items-center justify-content-center trial">
				<span class="free-days">{{'subscription_register_payment_7daysFree'|translate}}</span>
				<span>{{'subscription_register_payment_cancelTime'|translate}}</span>
				<div *ngIf="!isFree" class="info-icon tooltipped _600"
					[attr.data-title]="'subscription_register_payment_info'|translate"></div>
			</div>
			<div class="secure"><img class="secure-icon"
					src="/assets/img/subscription/secure-icon.png">{{'subscription_register_payment_encrypted'|translate}}
			</div>
		</div>
		<div class="text-center mt-5 register-form-navigation mt-3">
			<div class="d-inline-flex" [ngClass]="{ 'disabled': !paymentForm?.valid || !(cardNumberValid && cardExpiryValid && cardCvcValid && postalCodeValid
			) }">
				<a class="btn mtm-button mtm-main-button" (click)="submitData()" [style.color]="'#FFFFFF'"
					[style.text-transform]="'uppercase'">
					<span *ngIf="!isLoading">{{'subscription_register_payment_freeTrial'|translate}}</span>
					<span class="d-flex align-items-center" *ngIf="isLoading">
						<span class="mr-2">{{'subscription_register_payment_processing_trial'|translate}}</span>
						<i [style.font-size]="'18px'" class="fa fa-spinner fa-pulse"></i>
					</span>
				</a>
			</div>
		</div>
	</form>
</div>