import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "app/shared/services/project.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'mtm-delete-user-project',
	templateUrl: './delete-user-project.component.html',
	styleUrls: ['./delete-user-project.component.scss']
})
export class DeleteUserProjectComponent implements OnInit, OnDestroy {

	public users: any[] = [];
	public userNameList: any[] = [];
	ngUnsubscribe = new Subject();
	public projectId: string = '';

	constructor(public service: ProjectService, public activeModal: NgbActiveModal) { }
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.userNameList = this.users.map(x => x.username);

	}

	delete(): void {
		let projectId = this.projectId ? this.projectId : this.service.project.id;
		this.service.deleteUsers(projectId, this.userNameList).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res: any) => {
			this.service.project.users = res.users;
			this.activeModal.close(res);
		}, (err: any) => {
			this.activeModal.close(err);
		});
	}

}
