<div class="col p-0" *ngIf="timelineData">
    <div class="row d-flex align-items-center">
        <!-- <button class="setting-btn" *ngIf="!isDownloadComponentActive"><img
                src=".../../../../../assets/img/project-v2/setting-icon.svg" /></button> -->
        <span class="" [ngClass]="!isDownloadComponentActive ? 'f-22 _500 ml-2': 'f-22 _500'">{{'summary' | translate}}</span>
    </div>
    <div class="row" [style.max-height]="'80x'" [style.min-height]="'67px'">
        <div class="col-3 border-top border-right p-0">
            <div><span class="f-12 _700">{{'Project Name' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.name}}</span></div>
        </div>
        <div class="col-3 border-top border-right">
            <div><span class="f-12 _700">{{'WorkspaceAxe' | translate}}</span></div>
            <div><span class="f-18 _400">{{workspaceName}}</span></div>
        </div>
        <div class="col-6 border-top">
            <div><span class="f-12 _700">{{'Description' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.description}}</span></div>
        </div>
    </div>
    <div class="row" [style.max-height]="'80x'" [style.min-height]="'67px'">
        <div class="col-3 border-bottom border-top border-top border-right p-0">
            <div><span class="f-12 _700">{{'projectLeader' | translate}}</span></div>
            <div class="d-flex justify-content-start align-items-center" >
                <!-- <img *ngIf="timelineData && timeLineData?.user" class="user-img mr-3" [src]="timeLineData?.user" alt="">
                <span class="f-18 _400">{{timelineData.projectLeaders}}</span> -->
                <div class="row ml-1 d-flex">
                    <div class="user-avatar" *ngFor="let user of timelineData?.projectLeaders; let idx=index;"
                        [style.z-index]="idx+1" [style.left]="((-1)*(idx*8))+'px'">
                        <mtm-user-profile-picture placement="left" container="body"
                            [ngbTooltip]="selectedParticipantTooltip" [username]="user" [height]="24"
                            [width]="24">
                        </mtm-user-profile-picture>
                        <ng-template #selectedParticipantTooltip>
                            <div class="user-tooltip d-flex flex-column align-items-start p-1">
                                <h6 class="user-name m-0">{{getUserInfo(user)}}</h6>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

        </div>
        <div class="col border-bottom border-top border-right">
            <div><span class="f-12 _700">{{'typeofProject' | translate}}</span></div>
            <div><span class="f-18 _400">{{summaryDetail['Project Type'] | translate}}</span></div>
        </div>
        <div class="col border-bottom border-top border-right">
            <div><span class="f-12 _700">{{'priorityLevel' | translate}}</span></div>
            <div>
                <div *ngIf="priority == 'OTHER'">
                    <span class="f-18 _400 ">{{ priority.charAt(0).toUpperCase() +
                        priority.slice(1).toLowerCase() | translate }}</span>
                    <i class="fa fa-star ml-2 rating-start" aria-hidden="true"></i>
                </div>
                <div *ngIf="priority !== 'OTHER'">
                    <span class="f-18 _400">{{ priority.charAt(0).toUpperCase() +
                        priority.slice(1).toLowerCase()  | translate}}</span>
                    <i class="fa fa-star ml-2 rating-start" aria-hidden="true"></i>
                    <i class="fa fa-star ml-1 rating-start" aria-hidden="true"></i>
                    <i class="fa fa-star ml-1 rating-start" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="col border-bottom border-top">
            <div><span class="f-12 _700 text-uppercase">{{'mad' | translate}}</span></div>
            <div><span class="f-18 _400">{{timelineData.expectedMad | date:'dd.MM.YYYY'}}</span></div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="summaryDetail['Project Type'] == 'Hallmarks'">
        <div class="d-flex align-items-center" *ngIf="summaryDetail['Hallmarks Xmas']">
            <div>
                <span class="f-12 _400">{{'xmas' | translate}}</span>
            </div>
            <div class="ml-3 ">
                <span class="f-12 _700">{{summaryDetail["Hallmarks Xmas"] | translate}}</span>
            </div>
        </div>
        <div class="d-flex align-items-center ml-3">
            <div>
                <span class="f-12 _400">{{'chinaSpecific' | translate}}</span>
            </div>
            <div class="ml-3">
                <span class="f-12 _700">{{summaryDetail["China Specific"] | translate}}</span>
            </div>
        </div>
        <div class="d-flex align-items-center ml-3">
            <div>
                <span class="f-12 _400">{{'posm' | translate}}</span>
            </div>
            <div class="ml-3">
                <span class="f-12 _700">{{summaryDetail["POSM"] | translate}}</span>
            </div>
        </div>
    </div>
    <div class="row border-bottom ">
        <div class="d-flex  mt-3 mb-3" *ngIf="summaryDetail['Project Type'] == 'Hallmarks'"  [ngClass]="{'row flex-wrap gap':isDownloadComponentActive}">
            <div class="col summary-criteria text-nowrap" [style.max-width]="'fit-content'" [ngClass]="{'ml-3': isDownloadComponentActive}"
                [style.background-color]="colors['COLLECTOR / PRODUCT']">
                <div class="d-flex align-items-center">
                    <div>
                        <span class="f-12 _400">{{'collector' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-12 _700">{{summaryDetail["Collector"] | translate}}</span>
                    </div>
                    <div class="d-flex align-items-center ml-3"
                        *ngIf="summaryDetail['Collector Complexity']">
                        <div class="d-flex align-items-center ml-3">
                            <div>
                                <span class="f-12 _400">{{'complexity' | translate}}</span>
                            </div>
                            <div class="ml-3">
                                <span class="f-12 _700">{{summaryDetail["Collector Complexity"] | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="summaryDetail['Set DMI Produced']" class="ml-3 col summary-criteria text-nowrap" [style.max-width]="'fit-content'"
                [style.background-color]="colors['SET']">
                <div class="d-flex align-items-center">
                    <div>
                        <span class="f-12 _400">{{'set' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-12 _700">{{summaryDetail["Set"] | translate}}</span>
                    </div>
                    <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Set DMI Produced']">
                        <div>
                            <span class="f-12 _400">{{'dmiProduced' | translate}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="f-12 _700">{{summaryDetail["Set DMI Produced"] | translate}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Coffret Type']">
                        <div>
                            <span class="f-12 _400">{{'Type' | translate}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="f-12 _700">{{summaryDetail["Coffret Type"] | translate}}</span>
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Gift']">
                        <div>
                            <span class="f-12 _400">Gift</span>
                        </div>
                        <div class="ml-3">
                            <span class="f-12 _700">{{summaryDetail["Gift"]}}</span>
                        </div>
                    </div> -->
                </div>
            </div>
            <div *ngIf="summaryDetail['Gift'] || summaryDetail['POSM DMI Produced']" class="ml-3 col summary-criteria text-nowrap" [style.max-width]="'fit-content'"
                [style.background-color]="colors['GIFT']">
                <div class="d-flex align-items-center">
                    <!-- <div>
                        <span class="f-12 _400">POSM</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-12 _700">{{summaryDetail["POSM"]}}</span>
                    </div> -->
                    <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Gift']">
                        <div>
                            <span class="f-12 _400">{{'gift' | translate}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="f-12 _700">{{summaryDetail["Gift"] | translate}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['POSM DMI Produced']">
                        <div class="d-flex align-items-center ml-3">
                            <div>
                                <span class="f-12 _400">{{'dmiProduced' | translate}}</span>
                            </div>
                            <div class="ml-3">
                                <span class="f-12 _700">{{summaryDetail["POSM DMI Produced"] | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row border-bottom">
        <div class="d-flex mb-3 mt-3" *ngIf="summaryDetail['Project Type'] == 'Product Campaign'" [ngClass]="{'row flex-wrap gap ':isDownloadComponentActive}">
            <div class="col summary-criteria text-nowrap" [style.max-width]="'fit-content'" [ngClass]="{'ml-3': isDownloadComponentActive}"
                [style.background-color]="colors['COLLECTOR / PRODUCT']">
                <div class="d-flex align-items-center">
                    <div>
                        <span class="f-10 _400">{{'productLaunch' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-10 _700">{{summaryDetail["Product Launch"] | translate}}</span>
                    </div>
                    <div class="d-flex align-items-center ml-3"
                        *ngIf="summaryDetail['Product Launch Category']">
                        <div>
                            <span class="f-10 _400">{{'launchCategory' | translate}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="f-10 _700">{{summaryDetail["Product Launch Category"] | translate}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Complexity']">
                      <div>
                          <span class="f-10 _400">{{'complexity' | translate}}</span>
                      </div>
                      <div class="ml-3">
                          <span class="f-10 _700">{{summaryDetail["Complexity"] | translate}}</span>
                      </div>
                    </div>
                </div>
            </div>
            <div *ngIf="summaryDetail['360 Campaign'] || summaryDetail['Campaign Type']" class="ml-3 col summary-criteria text-nowrap" [style.max-width]="'fit-content'"
                [style.background-color]="colors['CAMPAIGN']">
                <div class="d-flex align-items-center" *ngIf="summaryDetail['360 Campaign']">
                    <div>
                        <span class="f-10 _400">{{'campaign360' | translate }}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-10 _700">{{summaryDetail["360 Campaign"] | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['Campaign Type']">
                    <div>
                        <span class="f-10 _400">{{'CampaignType' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-10 _700">{{summaryDetail["Campaign Type"] | translate}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="summaryDetail['POSM DMI Produced'] || summaryDetail['POSM']" class="ml-3 col summary-criteria text-nowrap" [style.max-width]="'fit-content'"
                [style.background-color]="colors['CAMPAIGN']">
                <div class="d-flex align-items-center" *ngIf="summaryDetail['POSM']">
                    <div>
                        <span class="f-12 _400">{{'posm' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-12 _700">{{summaryDetail["POSM"] | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center ml-3" *ngIf="summaryDetail['POSM DMI Produced']">
                    <div>
                        <span class="f-12 _400">{{'dmiProduced' | translate}}</span>
                    </div>
                    <div class="ml-3">
                        <span class="f-12 _700">{{summaryDetail["POSM DMI Produced" | translate]}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row d-flex align-items-center mt-3">
        <span class="f-12 _400">Collector 3T/4T • Coffret DMI Produced Type Premium • POSM DMI
            Produced</span>
    </div>
</div>
<div class="vertical-line"></div>
<div class="col">
    <div class="row d-flex align-items-center">
        <button class="setting-btn"><img
                src=".../../../../../assets/img/project-v2/setting-icon.svg" /></button>
        <span class="f-22 _500 ml-2">MAD</span>
    </div>
    <div class="row d-flex align-items-center mt-3">
        <span class="f-12 _700">{{expectedMad}}</span>
        <span class="f-12 _400 ml-4">{{ priority.charAt(0).toUpperCase() + priority.slice(1).toLowerCase() }}</span>
        <div class="ml-4" *ngIf="priority == 'OTHER'">
            <i class="fa fa-star rating-start" aria-hidden="true"></i>
        </div>
        <div class="ml-4" *ngIf="priority !== 'OTHER'">
            <i class="fa fa-star rating-start" aria-hidden="true"></i>
            <i class="fa fa-star ml-1 rating-start" aria-hidden="true"></i>
            <i class="fa fa-star ml-1 rating-start" aria-hidden="true"></i>
        </div>
    </div>
</div> -->
</div>
