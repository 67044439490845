import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OverlayService } from "../../shared/services/overlayService";
import { PaymentService } from "../../shared/services/payment.service";
import { LoadingBase } from "../../shared/bases/loading.base";
import { PaymentDTO } from "../credit-card-details.model";
import { Observable, Subject } from "rxjs";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'complete-payment-modal',
	templateUrl: './complete-payment-modal.component.html',
	styleUrls: ['./complete-payment-modal.component.scss']
})
export class CompletePaymentModalComponent extends LoadingBase implements OnInit, OnDestroy {
	ngUnsubscribe = new Subject();
	constructor(public activeModal: NgbActiveModal,
		private paymentService: PaymentService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe) {
		super();
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.initLoadingParameters('Processing payment, please wait!', '', ['w-100']);
	}

	completePayment(paymentDTO: PaymentDTO) {
		return new Observable(observer => {
			this.showLoading();
			this.paymentService.createPayment(paymentDTO).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(() => {
				this.hideLoading();

				this.closeModal();
				observer.next(undefined);
				observer.complete();
			}, error => {
				this.hideLoading();
				this.closeModal();
				this.overlayService.showError(this.translatePipe.transform('overlayPaymentError') + error.message);
				observer.error(this.translatePipe.transform('overlayPaymentError') + error.message);
			});
		});
	}

	closeModal() {
		this.activeModal.close();
	}
}
