<div class="contact-us-component d-flex flex-column p-4">
	<div class="contact-us-head d-flex align-items-center justify-content-center mb-3">
		<span class="_bold" [style.font-size]="'1rem'">Contact Us</span>
	</div>
	<div class="contact-us-body d-flex align-items-center flex-nowrap justify-content-center" [style.width]="'540px'">
		<a href="mailto:contact@mtm.video"
			class="d-flex align-items-center flex-column flex-fill content-element pointer p-1" [style.width]="'33.33%'"
			(click)="closeModal()">
			<div class="d-flex align-items-center justify-content-center content-icon m-auto"
				[style.font-size]="'2rem'">
				<i class="fa fa-envelope-o" aria-hidden="true"></i>
			</div>
			<div class="d-flex mt-3 w-100">
				<span class="d-flex w-100 justify-content-center flex-nowrap content-icon-label">
					Send us a message
				</span>
			</div>
		</a>
		<a href="https://calendly.com/masterthemonster/demo" target="_blank"
			class="d-flex align-items-center flex-column flex-fill content-element pointer p-1" [style.width]="'33.33%'"
			(click)="closeModal()">
			<div class="d-flex align-items-center justify-content-center content-icon m-auto"
				[style.font-size]="'2rem'">
				<i class="fa fa-calendar" aria-hidden="true"></i>
			</div>
			<div class="d-flex mt-3 w-100">
				<span class="d-flex w-100 justify-content-center flex-nowrap content-icon-label">Schedule a call</span>
			</div>
		</a>
		<div class="d-flex align-items-center flex-column flex-fill content-element pointer p-1"
			[style.width]="'33.33%'" (click)="chatWithUs()">
			<div class="d-flex align-items-center justify-content-center content-icon m-auto"
				[style.font-size]="'2rem'">
				<i class="fa fa-commenting-o" aria-hidden="true"></i>
			</div>
			<div class="d-flex mt-3 w-100">
				<span class="d-flex w-100 justify-content-center flex-nowrap content-icon-label">Chat with us</span>
			</div>
		</div>
	</div>
</div>