import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";

@Directive({
	selector: '[observeThis]'
})
export class ObserveThisDirective implements OnInit, AfterViewInit, OnDestroy{
	@Input() threshold: number = 0.99;
	@Output() visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	private observer: IntersectionObserver | null = null;


	constructor(private elementRef: ElementRef) {
	}

	ngOnInit() {
		this.observer = new IntersectionObserver((entries, observer)=>
				this.checkIntersection(entries, observer)
			, {
			threshold: this.threshold,
			rootMargin: `0px`,
		});
	}

	ngOnDestroy() {
		if (!this.observer) {
			return;
		}
		this.observer.disconnect();
		this.observer = null;
	}

	ngAfterViewInit() {
		if(! this.observer){
			return;
		}
		this.observer.observe(this.elementRef.nativeElement);
	}

	checkIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver){
		entries.forEach(entry => {
			const { intersectionRatio } = entry;
			if (intersectionRatio == 1) {
				this.visibilityChange.emit(true);
			} else {
				this.visibilityChange.emit(false);
			}
		});
	}
}
