import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AudioService {

	isLoading: boolean = true;
	audio: any = null;
	audioPlayPromise: any;

	constructor() { }

	/**
	 * Play ring when new call is received
	 */
	public playRing() {
		this.audio = new Audio();
		this.audio.src = "assets/sound/audio-ring.mp3?t=" + new Date().getTime();
		this.audio.load();
		let loopCount = 0;
		this.audio.addEventListener('ended', function () {
			loopCount++;
			if (loopCount < 3) {
				this.currentTime = 0;
				this.audioPlayPromise = this.play();
			}
		}, false);
		this.audioPlayPromise = this.audio.play();
	}


	/**
	 * Pause audio ring
	 */
	public pauseRing() {
		if (this.audioPlayPromise) {
			this.audioPlayPromise
				.then(() => {
					if (this.audio && !this.audio.paused) {
						this.audio.pause();
					}
				}).catch((err: any) => {
					console.error(err);
				});
		}
	}


	/**
	 * Pause audio ring
	 */
	public setIsLoading(value: boolean) {
		this.isLoading = value;
	}


}
