<div class="register-component mb-5">
	<div class="component-head d-flex flex-column" [style.max-width]="'680px'"
		[style.margin]="'1.5rem auto 1.5rem auto'">
		<div [style.text-transform]="'uppercase'" [style.color]="'#F4A143'"
			class="component-title d-flex align-items-center justify-content-center text-center">
			<span [style.color]="'#000'" [style.font-size]="'1.5rem'" [style.text-transform]="'uppercase'" class="_600">
				{{'signup_page_title'|translate}}
			</span>
		</div>
	</div>
	<form [style.background-color]="'#ffffff'" [formGroup]="detailForm"
		class="register-form main-input d-flex flex-column m-auto p-3" novalidate>
		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_register_detail_personalDetails'|translate}}</h3>
		</div>
		<div class="d-flex flex-column mb-4">
			<div class="d-flex">
				<div [class.has-danger]="!detailForm.controls['firstName'].pristine && detailForm.controls['firstName'].status === 'INVALID'"
					class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
					[style.width]="'33.33%'">
					<label
						class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_firstName'|translate}}</label>
					<input placeholder="{{'subscription_register_detail_firstName'|translate}}" class="form-control"
						formControlName="firstName" />
					<div
						*ngIf="!detailForm?.controls['firstName'].pristine && detailForm?.controls['firstName'].status === 'INVALID'">
						<span>{{'subscription_register_detail_requiredField'|translate}}</span>
					</div>
				</div>
				<div [class.has-danger]="!detailForm.controls['lastName'].pristine && detailForm.controls['lastName'].status === 'INVALID'"
					class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
					[style.width]="'33.33%'">
					<label
						class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_lastName'|translate}}</label>
					<input placeholder="{{'subscription_register_detail_lastName'|translate}}" class="form-control"
						formControlName="lastName" />
					<div
						*ngIf="!detailForm?.controls['lastName'].pristine && detailForm?.controls['lastName'].status === 'INVALID'">
						<span>{{'subscription_register_detail_requiredField'|translate}}</span>
					</div>
				</div>
				<div class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
					[style.width]="'33.33%'"
					[class.has-danger]="detailForm.controls['email'].status === 'INVALID' || validation?.error?.email">
					<label
						class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_companyEmail'|translate}}</label>
					<input placeholder="{{'subscription_register_detail_companyEmail'|translate}}" class="form-control"
						formControlName="email" />
					<div *ngIf="detailForm?.controls['email'].status === 'INVALID'">
						<span>{{'subscription_register_detail_requiredField'|translate}}</span>
					</div>
					<div *ngIf="validation?.error?.email?.EMAIL_EXISTS">
						<span>{{validation.error.email.EMAIL_EXISTS}}</span>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div [class.has-danger]="!detailForm.controls['phone'].pristine && detailForm.controls['phone'].status === 'INVALID' || validation?.error?.phone"
					class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
					[style.width]="'33.33%'" (keydown.backspace)="onPhonePress()" (keypress)="onPhonePress()">
					<label
						class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_mobileNumber'|translate}}</label>
					<!-- <input autocomplete="off" placeholder="{{'subscription_register_detail_mobileNumber'|translate}}"
						class="form-control" formControlName="phone" /> -->
					<selector-mtm-tel-input [cardForm]="detailForm" [cardFormKey]="'phone'"></selector-mtm-tel-input>
					<div
						*ngIf="!detailForm?.controls['phone'].pristine && detailForm?.controls['phone'].status === 'INVALID'">
						<span>{{'subscription_register_detail_requiredField'|translate}}</span>
					</div>
					<div *ngIf="validation?.error?.phone?.PHONE_EXISTS">
						<span>{{validation.error.phone.PHONE_EXISTS}}</span>
					</div>
					<div *ngIf="validation?.error?.phone?.NOT_VALID_PHONE_ERROR">
						<span>{{validation.error.phone.NOT_VALID_PHONE_ERROR}}</span>
					</div>
				</div>
				<div [class.has-danger]="!detailForm.controls['password'].pristine && detailForm.controls['password'].status === 'INVALID'"
					class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
					[style.width]="'33.33%'">
					<label
						class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_password'|translate}}</label>
					<div class="d-flex align-items-center" [style.position]="'relative'">
						<input autocomplete="new-password"
							placeholder="{{'subscription_register_detail_password'|translate}}" class="form-control"
							formControlName="password" type="{{inputTextType}}" />
						<i [style.position]="'absolute'" [style.right]="'0'"
							class="fa fa-eye pr-2 pl-0 pt-2 pb-2 pointer" (click)="changePasswordDisplayType()"></i>
					</div>
					<div
						*ngIf="!detailForm?.controls['password'].pristine && detailForm?.controls['password'].status === 'INVALID'">
						<span>{{'subscription_register_detail_requiredField'|translate}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-3 mb-2 subtitle">
			<h3>{{'subscription_register_detail_companyDetails'|translate}}</h3>
		</div>
		<div class="d-flex">
			<div [class.has-danger]="!detailForm.controls['companyName'].pristine && detailForm.controls['companyName'].status === 'INVALID' || validation?.error?.company"
				class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
				[style.width]="'33.33%'">
				<label
					class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_companyName'|translate}}</label>
				<input placeholder="{{'subscription_register_detail_companyName'|translate}}" class="form-control"
					formControlName="companyName" />
				<div
					*ngIf="!detailForm?.controls['companyName'].pristine && detailForm?.controls['companyName'].status === 'INVALID'">
					<span>{{'subscription_register_detail_requiredField'|translate}}</span>
				</div>
				<div *ngIf="validation?.error?.company?.COMPANY_NAME_REGISTERED">
					<span>{{validation.error.company.COMPANY_NAME_REGISTERED}}</span>
				</div>
			</div>
			<div class="register-inputs d-flex flex-column p-2 mb-2"
				[class.has-danger]="!detailForm.controls['companyType'].pristine && detailForm.controls['companyType'].status === 'INVALID'"
				[style.max-width]="'33.33%'" [style.width]="'33.33%'">
				<label
					class="t-uppercase control-label mb-1 _600">{{'subscription_register_detail_companyType'|translate}}</label>
				<select class="form-control" formControlName="companyType">
					<option [ngValue]="null" selected>
						{{'subscription_register_detail_companyType'|translate}}...
					</option>
					<option [ngValue]="'ADVERTISER'">Brand</option>
					<option [ngValue]="'PRODUCER'">Production Company</option>
				</select>
				<div
					*ngIf="!detailForm?.controls['companyType'].pristine && detailForm?.controls['companyType'].status === 'INVALID'">
					<span>{{'subscription_register_detail_requiredField'|translate}}</span>
				</div>
			</div>
			<div [class.has-danger]="!detailForm.controls['country'].pristine && detailForm.controls['country'].status === 'INVALID'"
				class="register-inputs d-flex flex-column p-2 mb-2o" [style.max-width]="'33.33%'"
				[style.width]="'33.33%'">
				<label class="t-uppercase control-label mb-1 _600">{{'billingCountry' | translate}}</label>
				<select class="form-control" formControlName="country">
					<option value="">{{'searchCountry' | translate}}</option>
					<option [value]="item.regionCode" *ngFor="let item of listCountryCodes">{{item.countryName}}
					</option>
				</select>
				<div
					*ngIf="!detailForm?.controls['country'].pristine && detailForm?.controls['country'].status === 'INVALID'">
					<span>{{'subscription_register_detail_requiredField'|translate}}</span>
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div class="register-inputs d-flex flex-column p-2 mb-2" [style.max-width]="'33.33%'"
				[style.width]="'33.33%'">
				<label class="t-uppercase control-label mb-1 _600">{{'billingCurrency'|translate}}</label>
				<div class="d-flex align-items-center" [style.position]="'relative'">
					<input autocomplete="new-password" placeholder="{{'billingCurrency'|translate}}"
						[ngModel]="currency" class="form-control" type="text" [disabled]="true"
						[ngModelOptions]="{standalone: true}" />
				</div>
				<div
					*ngIf="!detailForm?.controls['password'].pristine && detailForm?.controls['password'].status === 'INVALID'">
					<span>{{'subscription_register_detail_requiredField'|translate}}</span>
				</div>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-center footer-input mb-4 mt-3">
			<div [style.padding-left]="'1.875rem'" class="form-check" [style.font-size]="'13px'">
				<input [ngModelOptions]="{ standalone: true }" [(ngModel)]="isAgree" class="form-check-input"
					type="checkbox" value="" id="checkboxAgreement">
				<label class="form-check-label" for="checkboxAgreement">
					<span>{{'subscription_register_detail_signUp'|translate}}</span>
					<a href="https://mtm.video/terms-of-use" target="_blank"
						class="_600">{{'subscription_register_detail_terms'|translate}}</a>
					<span>{{'subscription_register_detail_and'|translate}}</span>
					<a href="https://mtm.video/privacy-policy" target="_blank"
						class="_600">{{'subscription_register_detail_policy'|translate}}</a>
				</label>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-center flex-column register-form-navigation">
			<div class="d-inline-flex" [ngClass]="{ 'disabled': !detailForm.valid && !isAgree }">
				<a (click)="submit()" class="btn mtm-button mtm-main-button" [style.color]="'#FFFFFF'"
					[style.text-transform]="'uppercase'">
					<span *ngIf="!isLoading">{{'subscription_register_detail_continue'|translate}}</span>
					<span class="d-flex align-items-center" *ngIf="isLoading">
						<span class="mr-2">Loading</span>
						<i [style.font-size]="'18px'" class="fa fa-spinner fa-pulse"></i>
					</span>
				</a>
			</div>
			<div class="d-inline-flex mt-2">
				<a class="c-mtm-brand pointer" [style.font-size]="'13px'" (click)="alreadyHaveAnAccount()">
					{{'subscription_register_detail_alreadyHaveAccount'|translate}}
				</a>
			</div>
		</div>
	</form>
</div>