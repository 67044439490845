import { IFilterAngularComp } from "ag-grid-angular";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import { Component } from "@angular/core";
import { ProjectStatus } from "../../../../project.const";
import { TranslatePipe } from "../../../../../../shared/pipes/translate.pipe";

@Component({
  templateUrl: './project-status-filter.component.html',
})
export class ProjectStatusFilterComponent implements IFilterAngularComp {
  params: IFilterParams;
  statusList: string[] = [];
  projectStatusList = [
    { label: `sectionStatus_${ProjectStatus.ToDo}`, value: ProjectStatus.ToDo },
    { label: `sectionStatus_${ProjectStatus.InProgress}`, value: ProjectStatus.InProgress },
    { label: `sectionStatus_${ProjectStatus.UpcomingExpiry}`, value: ProjectStatus.UpcomingExpiry },
    { label: `sectionStatus_${ProjectStatus.Completed}`, value: ProjectStatus.Completed },
    { label: `sectionStatus_${ProjectStatus.Delayed}`, value: ProjectStatus.Delayed },
  ]

  constructor(private translatePipe: TranslatePipe) {
    console.log('status filter constructor');
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    console.log('status filter aginit');
  }
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.statusList.length == 0) return true;

    return this.statusList.includes(params.data.status);
  }

  isFilterActive(): boolean {
    return true;
  }

  getModel(): any {
    return { value: this.statusList };
  }

  setModel(model: any): void {
    this.statusList = model.value;
  }

  updateFilter(values: string[]) {
    this.statusList = values;
    this.params.filterChangedCallback();
  }

  /*
  getModelAsString(model: any): string {
    if(this.statusList.length === 0){
      return '';
    }

    return this.statusList.map(status => this.translatePipe.transform('sectionStatus_'+status)).join(', ');
  }
   */
}
