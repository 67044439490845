import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'projectFilter'
})

export class projectFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        return items.filter(item => {
            return String(item).toLowerCase().includes(searchText.toLowerCase());
        });
    }
}
