import { IFloatingFilterAngularComp } from "ag-grid-angular";
import { Component } from "@angular/core";
import { IFloatingFilterParams } from "ag-grid-community";
import { ProjectStatus } from "../../../../project.const";

@Component({
  templateUrl: './project-status-floating-filter.component.html',
})
export class ProjectStatusFloatingFilterComponent implements IFloatingFilterAngularComp {
  params: IFloatingFilterParams;
  status: string;
  currentValues: string[] = [];
  projectStatusList = [
    { label: `sectionStatus_${ProjectStatus.ToDo}`, value: ProjectStatus.ToDo },
    { label: `sectionStatus_${ProjectStatus.InProgress}`, value: ProjectStatus.InProgress },
    { label: `sectionStatus_${ProjectStatus.UpcomingExpiry}`, value: ProjectStatus.UpcomingExpiry },
    { label: `sectionStatus_${ProjectStatus.Completed}`, value: ProjectStatus.Completed },
    { label: `sectionStatus_${ProjectStatus.Delayed}`, value: ProjectStatus.Delayed },
  ]

  agInit(params: IFloatingFilterParams<any>) {
    this.params = params;
  }

  onParentModelChanged(parentModel: any) {
    if (!parentModel) {
      this.currentValues = [];
    } else {
      this.currentValues = parentModel.value;
    }
  }

  onSelectionChange(values: string) {
    this.params.parentFilterInstance((instance: any) => {
      instance?.updateFilter(this.currentValues);
    });
  }
}
