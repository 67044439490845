import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ProjectService } from "app/shared/services/project.service";
import { FilterPipe } from "app/shared/pipes/search-file.pipe";
import { SignedUrlService } from "../../../shared/services/signedurl.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const onCheckFileList: EventEmitter<any> = new EventEmitter<any>();
@Component({
	selector: 'mtm-team-files-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [FilterPipe]
})
export class TeamFilesListComponent implements OnInit, OnDestroy {

	@Input() files: any[];
	@Input() projectId: string;
	@Input() queryString: string;
	@Input() isDisableDownload: boolean;
	ngUnsubscribe = new Subject();

	constructor(private projectService: ProjectService, private signedUrlService: SignedUrlService,
		private modalService: NgbModal) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() { }

	/**
	 * Download file
	 * @param file
	 */
	private onDownloadFile(file: any) {
		return false;
	}


	/**
	 * Delete file
	 * @param file
	 */
	private onDeleteFile(file: any) {
		this.projectService.deleteFile(file.id, this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(res) => {
				let index = this.files.findIndex(k => k.id == file.id);
				if (index >= 0)
					this.files.splice(index, 1);
				if (this.files.length == 0) {
					onCheckFileList.emit();
				}
			},
			(err) => {
			}
		);
		return false;
	}

	private downloadFile(file) {
		this.signedUrlService.downloadSignedUrl(file, {
			control: '.media-downloader-control'
		});
	}

	openPreviewModal(modal) {
		this.modalService.open(modal, { size: 'xl' });
	}

}
