import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from './../../shared/services/auth.service';
import { RegistrationService } from './../../shared/services/registration.service';
import { ProjectService } from "app/shared/services/project.service";
import { ProposalInvitationService } from "app/shared/services/proposal-invitation.service";
import { CompanyService } from "app/shared/services/company.service";
import { NotificationService } from '../../shared/services/notification.service';
import { OverlayService } from "../../shared/services/overlayService";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	templateUrl: './rate-client.component.html',
	styleUrls: ['./rate-client.component.scss']
})

export class RateClientComponent implements OnInit, OnDestroy {

	formGroup: UntypedFormGroup;
	clientCompRateForm: UntypedFormGroup;
	clientCompRate: any = {};
	brief: number = 0;
	budget: number = 0;
	outOfBox: number = 0;
	goodListener: number = 0;
	checkBoxValue: boolean = false;
	time: number = 0;
	global: number = 0;
	prodCompanies: any[] = [];
	prodCompany: any = {};
	isSubmitted: boolean = false;
	ratingToken: any;
	clientCompany = {};
	companyUrl: any;
	user: any = {};
	ngUnsubscribe = new Subject();
	noneImg = "./assets/img/samples/No_Image_Available.png";

	constructor(private fb: UntypedFormBuilder, private authService: AuthService, private route: ActivatedRoute,
		private registrationService: RegistrationService, private projectService: ProjectService, private router: Router,
		private proposalInvitationService: ProposalInvitationService, private activeRouter: ActivatedRoute,
		private notificationService: NotificationService, private companyService: CompanyService, private overlayService: OverlayService,
		private translatePipe: TranslatePipe) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		let ratingCode = this.router.parseUrl(this.router.url).queryParams['rating'];
		if (ratingCode) {
			this.projectService.isRatingCodeValid(ratingCode).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				this.clientCompRate.userName = res.userFullName;
				this.clientCompRate.userAvatar = res.userAvatar;
				this.clientCompRate.userCompanyName = res.userCompanyName;
				this.clientCompRate.userProjectName = res.projectName;
				this.clientCompRate.userCompanyAvatar = res.userCompanyAvatar;
				this.ratingToken = res;
				if (res.clientCompanyAvatar)
					this.clientCompRate.userCompanyAvatar = res.clientCompanyAvatar;
				else
					this.clientCompRate.userCompanyAvatar = this.noneImg;

			}, err => {
				this.notificationService.open({
					title: 'Tokent Error',
					description: `TOKEN NOT FOUND`,
					confirmBtn: 'Accept'
				}).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(() => {

					this.inProgress();

				})
			})
		}

		this.loadFormGroup();

	}

	inProgress() {
		this.router.navigate(['projects']);
	}


	private loadFormGroup() {
		this.clientCompRateForm = this.fb.group({
			clarityOfTheBriefFeedBack: [(this.clientCompRate && this.clientCompRate.clarityOfTheBriefFeedBack) ? this.clientCompRate.clarityOfTheBriefFeedBack : ''],
			feedBackQualitySpeedFeedBack: [(this.clientCompRate && this.clientCompRate.feedBackQualitySpeedFeedBack) ? this.clientCompRate.feedBackQualitySpeedFeedBack : ''],
			goodPlayerFeedBack: [(this.clientCompRate && this.clientCompRate.goodPlayerFeedBack) ? this.clientCompRate.goodPlayerFeedBack : ''],
			opennessToIdeasFeedBack: [(this.clientCompRate && this.clientCompRate.opennessToIdeasFeedBack) ? this.clientCompRate.opennessToIdeasFeedBack : ''],
			goodListenerFeedBack: [(this.clientCompRate && this.clientCompRate.goodListenerFeedBack) ? this.clientCompRate.goodListenerFeedBack : ''],
			review: [(this.clientCompRate && this.clientCompRate.review) ? this.clientCompRate.review : ''],
			clarityOfTheBrief: [(this.clientCompRate && this.clientCompRate.clarityOfTheBrief) ? this.clientCompRate.clarityOfTheBrief : ''],
			opennessToIdeas: [(this.clientCompRate && this.clientCompRate.opennessToIdeas) ? this.clientCompRate.opennessToIdeas : ''],
			feedBackQualitySpeed: [(this.clientCompRate && this.clientCompRate.feedBackQualitySpeed) ? this.clientCompRate.feedBackQualitySpeed : ''],
			goodPlayer: [(this.clientCompRate && this.clientCompRate.goodPlayer) ? this.clientCompRate.goodPlayer : ''],
			goodListener: [(this.clientCompRate && this.clientCompRate.goodListener) ? this.clientCompRate.goodListener : ''],
			globalRating: this.global,
			userAvatar: [(this.clientCompRate && this.clientCompRate.userAvatar) ? this.clientCompRate.userAvatar : ''],
			userCompanyAvatar: [(this.clientCompRate && this.clientCompRate.userCompanyAvatar) ? this.clientCompRate.userCompanyAvatar : ''],
			userCompanyName: [(this.clientCompRate && this.clientCompRate.userCompanyName) ? this.clientCompRate.userCompanyName : ''],
			userName: [(this.clientCompRate && this.clientCompRate.userName) ? this.clientCompRate.userName : ''],
			userProjectName: [(this.clientCompRate && this.clientCompRate.userProjectName) ? this.clientCompRate.userProjectName : '']

		});

		this.clientCompRate.clarityOfTheBrief = this.brief;
		this.clientCompRate.opennessToIdeas = this.budget;
		this.clientCompRate.feedBackQualitySpeed = this.outOfBox;
		this.clientCompRate.goodPlayer = this.time;
		this.clientCompRate.goodListener = this.goodListener;
		this.clientCompRate.globalRating = this.global;

	}

	onSubmit() {
		let deneme = this.clientCompRate;
		let form = this.clientCompRateForm.value;
		this.clientCompRate.review = this.clientCompRateForm.value.review;
		this.loadFormGroup();
		this.proposalInvitationService.saveClientCompanyRate(this.ratingToken.projectId, this.ratingToken.clientCompanyId, this.clientCompRateForm.value, this.ratingToken.username).pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(savedClientCompRate => {
				// savedClientCompRate.avatarUrl =  this.clientCompRate.avatarUrl;
				this.isSubmitted = true;
				this.overlayService.showSuccess(this.translatePipe.transform('overlayRatingSaved'), 'Success');
				this.inProgress();

			});
	}

	modelChanged() {

		this.isSubmitted = false;
		this.clientCompRate.clarityOfTheBrief = this.brief;
		this.clientCompRate.opennessToIdeas = this.budget;
		this.clientCompRate.feedBackQualitySpeed = this.outOfBox;
		this.clientCompRate.goodListener = this.goodListener;
		this.clientCompRate.goodPlayer = this.time;
		this.global = (+this.brief + +this.budget + +this.outOfBox + +this.goodListener + +this.time) / 5;
		this.clientCompRate.globalRating = this.global;
	}
}
