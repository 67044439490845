export class CommentPlugin {
    _plugin: any;

    constructor(plugin) {
        this._plugin = plugin;
    }

    get plugin() {
        return this._plugin.annotationComments();
    }

    // Register all events in the EventRegistry matching this className
    initAPI(obj, className) {
        this.plugin.eventDispatcher.registerListenersFor(obj, className);
    }

    // Nullify player reference so objects can be removed safely
    // All components should call super.teardown() within their teardown funcs
    teardown(destroy = false) {
        if (destroy) this._plugin = null;
    }
}