import { RouterModule, Routes } from "@angular/router";
import { EditWorkspaceComponent } from "./components/edit-workspace/edit-workspace.component";
import { NgModule } from "@angular/core";
import { CreateWorkspaceComponent } from "./components/create-workspace/create-workspace.component";
import { WorkspaceCreationGuard } from "./guards/workspace-creation.guard";
import { GeneralLogComponent } from "../layouts/activity-log/general-log/general-log.component";

export const routes: Routes = [{
	path: '',
	redirectTo: 'new',
	pathMatch: 'full'
}, {
	path: 'new',
	component: CreateWorkspaceComponent,
	canActivate: [WorkspaceCreationGuard]
}, {
	path: 'edit/:workspaceId',
	component: EditWorkspaceComponent
}]

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class WorkspaceRoutingModule {

}
