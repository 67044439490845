import { CommentPlugin } from "../lib/comment-plugin";
import { Annotation } from "./annotation";

export class AnnotationState extends CommentPlugin {
    _annotations: any;

    constructor(plugin: Object) {
        super(plugin);
        this.initAPI(this, 'AnnotationState');

    }

    // Sets _annotations w/Annotation objects from input array
    set annotations(annotationsData) {
        let _this2 = this;
        this._annotations = annotationsData.map(a => new Annotation(a, _this2.plugin));
    }

    get annotations() {
        return this._annotations;
    }

    // Serialize data
    get data() {
        return this._annotations.map(a => a.data);
    }

    destroyAnnotationById(id) {
        let annotation = this.findAnnotation(id);
        if (annotation) annotation.teardown();
    }

    removeAnnotation(annotation: Annotation) {
        let id = annotation.id;

        let i = this._annotations.indexOf(annotation);
        this._annotations.splice(i, 1);
        this.stateChanged();
        this.plugin.fire('annotationDeleted', { id: id, allAnnotations: this.data });
    }

    editAnnotation(annotation: Annotation) {
        let annotIndex = this.annotations.indexOf(annotation);
        if (annotation) {
            this.plugin.controls.editingStart(this.annotations[annotIndex]);
        }
    }

    saveEditedAnnotation(annotationId, isWSCall=false) {
        if (this.plugin.controls.uiState.editing) {
            let annotation = this.findAnnotation(annotationId);
            if (annotation) annotation.updateAnnotation(null, isWSCall);
            this.plugin.controls.cancelAddNew();
        }
    }

    newAnnotationUpdate(annotationData) {
        const newAnnotation = new Annotation(annotationData, this.plugin);
        this._annotations.push(newAnnotation);
        if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
            this.plugin.controls.cancelAddNew();
        }
      }

    updateAnnotationById(annotation) {
        let annotationIndex = this.annotations.findIndex((obj => obj.id === annotation.id));
        this.annotations[annotationIndex].updateAnnotation(annotation);
        if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
            this.plugin.controls.cancelAddNew();
        }
    }

    findComment(id) {
        var _ref;

        function _toConsumableArray(arr) {
            if (Array.isArray(arr)) {
                for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                    arr2[i] = arr[i];
                } return arr2;
            } else {
                return Array.from(arr);
            }
        }

        let comments = this.annotations.map(function (a) {
            return a.commentList.comments;
        });
        comments = (_ref = []).concat.apply(_ref, _toConsumableArray(comments)); // flatten 2d array
        return comments.find(function (c) {
            return c.id == id;
        });
    }

    // Returns annotation object given ID
    findAnnotation(id) {
        return this.annotations.find(a => a.id === id);
    }

    createAndAddAnnotation(data) {
        this.plugin.controls.uiState.adding && this.plugin.controls.cancelAddNew();

        const annotation = Annotation.newFromData(
            data.commentStr || '',
            data.parentId || null,
            this.plugin,
            data.id,
            data.range,
            data.privateConditionKey
        )
        this.addNewAnnotation(annotation);
    }

    // Add a new annotation
    addNewAnnotation(annotation) {
        this._annotations.push(annotation);
        this.stateChanged();
        let data = {"newAnnotation": annotation, "allAnnotations": this.data}
        this.plugin.fire('annotationCreated', data);
    }

    stateChanged() {
        this.plugin.fire('onStateChanged', this.data);
    }

    // Reset internal state properties
    resetData() {
        this.annotations = [];
    }

    teardown() {
        this.annotations.forEach(annotation => {
            annotation.teardown(false);
        });
        this.resetData();
        super.teardown();
        return null;
    }

}
