import { UploadService } from './upload.service';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StompService } from 'ng2-stomp-service';

@Injectable({
    providedIn: 'root'
})
export class SimpleChatService {
    constructor(private authService: AuthService,
        private apiService: ApiService) {

    }

    /**
     * Get history messages for that room
     * @param chatId
     */
    getHistoryGroupMessages(chatId): Observable<any> {
        let subject = new Subject<any>();

        this.apiService.httpGet('/topic/' + chatId + '/messages').subscribe(
            (data: any) => {

                // Reverse array to get at the end the most recent messages
                if (data)
                    data.reverse();

                subject.next(data);
            },
            (err: any) => {
                subject.error(err);
            }
        )

        return subject.asObservable();
    }


    /**
     * Get history messages of private conversation
     * @param chatId
     */
    getHistoryPrivateMessages(usernameTo: string): Observable<any> {
        let subject = new Subject<any>();

        let authUser = this.authService.getAuthUser();

        this.apiService.httpGet('/chat/' + authUser.username + '/' + usernameTo + '/messages').subscribe(
            (data: any) => {

                // Reverse array to get at the end the most recent messages
                if (data)
                    data.reverse();

                subject.next(data);
            },
            (err: any) => {
                subject.error(err);
            }
        )

        return subject.asObservable();
    }
}
