<div class="container proposal-step-container">
	<div class="row">
		<div class="col-sm-12">
			<div class="card-header">
				<h6 class="font-weight-bold" *ngIf="!isUserPermissions">2. Proposal Team</h6>
				<h6 class="font-weight-bold" *ngIf="isUserPermissions">7. Users & Permissions</h6>
			</div>

			<div class="card-block ">

				<div class="row ">
					<div class="col">
						<p class="text-black font-weight-bold m-0">Recommendation mode</p>
						<p class="card-text">How do you like your team members to recommend?</p>
						<div class="row mt-2 recommendation-row py-3">
							<div class="col">
								<div class="position" [ngClass]="{'position-active': decision}"
									(click)="changeDecision(true)">
									<i class="fa fa-users fa-2x" aria-hidden="true"></i>
									<h6 class="font-weight-bold">Unanimity</h6>
									<p [ngClass]="{'position-font': decision}">
										The team members with the permission to recommend should all greenlight on
										element for presentation to client
										The more members the more difficult the recommendation.
									</p>
								</div>
							</div>
							<div class="col">
								<div class="position" [ngClass]="{'position-active': !decision}"
									(click)="changeDecision(false)" style="height:100%">
									<i class="fa fa-user fa-2x" aria-hidden="true"></i>
									<h6 class="font-weight-bold">One Decision maker</h6>
									<p [ngClass]="{'position-font': !decision}">
										One Team member is the only one to recommend the elements presented to the
										client. Choose who by ticking the "recommend"
										box.
									</p>
								</div>
							</div>
							<div class="unauth-overlay tooltipped"
								*ngIf="!user || user.globalRole.startsWith('PRODUCTION')"
								[attr.data-title]="'NoChangeAllowed' | translate"></div>
						</div>
					</div>

				</div>



				<div class="row">
					<div class="col-12">
						<hr>
						<p class="card-text">Select the team members who you'd like to work on this project and set
							their permissions.</p>
						<br>
					</div>



					<div class="col-12 mtm-scroll-only-x">
						<table class="table table-striped table table-hover table-striped table-sortable">
							<tbody>
								<tr *ngFor="let user of users">
									<td class="userBasicInfoContainer pt-2" colspan="4">
										<label class="custom-control d-flex flex-row pl-0">
											<div class="media p-2">
												<img class="rounded-circle" [src]=user.avatarUrl alt="" width="32"
													height="32">
												<div class="media-body align-self-center ml-2">
													<h6 class="media-heading m-0">{{user.username}}</h6>
													<span
														class="font-weight-bold text-muted username">{{user.roleText}}</span>
												</div>
											</div>


										</label>
									</td>
									<td class="pt-3">
										<p class="custom-control-description">{{user.jobTitle}}</p>
									</td>
									<td>
										<select class="form-control role-dropdown" [(ngModel)]="user.userRole"
											(ngModelChange)="saveUser(user)"
											[disabled]="user.isUpdating || !permissionService.hasAuthority(authority.I, null)">
											<option value="{{ role.code }}" *ngFor="let role of allRoles">{{role.label}}
											</option>
										</select>
									</td>
									<td colspan="4">
										<mtm-button [label]="'PERMISSION DETAILS'" [icon]="'cog'" [color]="'red'"
											(click)="onOpenPermissionInfo()"></mtm-button>
									</td>
									<td colspan="4" *ngIf="isDeleteButtonAvailable(user)">
										<mtm-button [label]="'REMOVE FROM PROJECT'" [icon]="'trash'" [color]="'red'"
											[class.disabled]="user.isUpdating || !permissionService.hasAuthority(authority.I, null)"
											(click)="onDeleteUser(user)"></mtm-button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>



				<hr>
				<div class="row">
					<div class="col">
						<button class="btn btn-outline-secondary btn-lg" (click)="addNewUser()"
							[disabled]="!permissionService.hasAuthority(authority.I, null)">
							<i class="fa fa-lg fa-plus-circle" style="color:#e44a27" aria-hidden="true"></i>
							Add New User</button>
					</div>
					<div class="col text-right">
						<button class="btn btn-danger btn-lg" *ngIf="!isUserPermissions" (click)="goToQASession()">
							<span>Go to Q&A Session</span>
						</button>
						<button class="btn btn-danger btn-lg" *ngIf="isUserPermissions" (click)="goToProjectStages()">
							<span>{{'goProjectStages' | translate}}</span>
						</button>
					</div>
				</div>


			</div>
		</div>
	</div>
</div>