<div *ngIf="!quillActive" class="mtm-text-editor d-flex justify-content-between align-items-center"
	[ngClass]="{ 'expandable': expandable, 'justify-content-between': isAnnotationEditor, 'align-items-center': isAnnotationEditor || isTaskComment }">
	<ng-template #mentionList let-item="item">
		<div class="mention-list-preview d-flex align-items-center p-1">
			<div class="d-inline-block mr-1">
				<mtm-user-profile-picture *ngIf="item?.type==='one_on_one'" [username]="item?.value" [height]="28"
					[width]="28">
				</mtm-user-profile-picture>
				<mtm-group-chat-picture *ngIf="item?.type==='group'" [conversation]="item" [iconSize]="'14px'"
					[fontSize]="'8px'">
				</mtm-group-chat-picture>
			</div>
			<span class="mention-data-label p-1 _1-line-text-ellipsis">{{ item.label }}</span>
		</div>
	</ng-template>
	<a *ngIf="!graySmile && !(isAnnotationEditor || isTaskComment)" class="emojibtn"
		(click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</a>
	<div class="mtm-text-editor-nav"
		[ngClass]="{ 'd-block': isTaskComment, 'd-flex task-comment-icon align-items-center': isTaskComment }"
		*ngIf="graySmile">
		<a class="emojibtn mtm-icon d-inline-block mtm-icon-smile pointer mr-2"
			(click)="isEmojiPickerVisible = !isEmojiPickerVisible;"></a>
		<a *ngIf="!isTaskComment" class="mtm-icon mtm-icon-pin pointer" (click)="fileIconClick()"></a>
	</div>
	<emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)">
	</emoji-mart>
	<div class="d-flex w-100">
		<mtm-user-profile-picture *ngIf="userAvatar" [username]="userAvatar" [height]="28" [width]="28"
			[fontSize]="'10px'" [forceLoadProfile]="true" class="pt-2 pl-2">
		</mtm-user-profile-picture>
		<div id="{{elementId}}" class="flex-fill p-3 mtm-text-editor-element content-placeholder" contenteditable="true"
			(keydown.enter)="onKeydownEnterPress($event)" (keyup)="$event.stopPropagation();saveCaretPosition();setDescription();"
			(keypress)="saveCaretPosition();setDescription();" [style.maxHeight]="maxHeight" [style.minHeight]="maxHeight"
			[style.height]="'auto'" [style.backgroundColor]="backgroundColor" (keydown)="saveCaretPosition();setDescription();"
			[attr.placeholder]="placeholder | translate" [mentionListTemplate]="mentionList" (click)="saveCaretPosition();setDescription();"
			[mentionConfig]="mentionConfig" (opened)="onMentionOpened()" (closed)="onMentionClosed()" #editor [innerHTML]="value"></div>
	</div>
	<a *ngIf="!graySmile && (isAnnotationEditor || isTaskComment)" class="emojibtn emoji-usr"
		(click)="isEmojiPickerVisible = !isEmojiPickerVisible;">
		<img src="../../../../../assets/img/annotations/emoji.svg" alt="" height="24" width="22">
	</a>
</div>
<div *ngIf="quillActive" class="mtm-text-editor" [ngClass]="{ 'expandable': expandable }">
	<div class="w-100" [ngClass]="{ 'd-flex': userAvatar }">
		<mtm-user-profile-picture *ngIf="userAvatar" [username]="userAvatar" [height]="28" [width]="28"
			[fontSize]="'10px'" [forceLoadProfile]="true" class="pt-2 pl-2">
		</mtm-user-profile-picture>
		<quill-editor id="{{elementId}}" class="d-block w-100" [(ngModel)]="value"
			(onEditorCreated)="onEditorInitialized($event)" [placeholder]="placeholder | translate" [modules]="quillModule"
			#editor></quill-editor>
	</div>
</div>
<div [hidden]="!showBottomToolbar">
	<div *ngIf="attachments?.length" class="d-flex align-items-center text-center mt-2 flex-wrap"
		[style.margin-left]="'-8px'" [style.margin-right]="'-8px'">
		<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2" *ngFor="let attachment of attachments">
			<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="true"
				(onTrashFile)="deleteFile($event, attachment)"></hr-badgebtn-subcmp>
			<hr-grid-subcmp [file]="attachment" [compactView]="true" [hideDeleteButton]="true"
				class="w-100"></hr-grid-subcmp>
		</div>
	</div>
	<div class="file-upload-icon d-flex align-items-center mr-3">
		<div class="file-icon-wrapper">
			<i class="fa fa-plus p-2" [title]="'Add file(s)'" (click)="openFileExplorer()"></i>
			<span [title]="files.length + ' file(s)'" *ngIf="files?.length" class="file-display-thumbnail">
				<div ngbDropdown [autoClose]="true" class="file-dropdown-component d-flex align-items-center"
					container="body" #dropdownFiles>
					<button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
						<span class="text-uppercase">
							{{files?.length}}
						</span>
					</button>
					<div ngbDropdownMenu class="file-item-list">
						<div class="d-flex flex-column">
							<div class="d-flex text-center pointer file-content m-2" *ngFor="let file of files">
								<hr-badgebtn-subcmp [file]="file" [isDisplayTrashBtn]="true" [localFile]="true"
									(onTrashFile)="deleteFile($event, file)"></hr-badgebtn-subcmp>
								<hr-grid-subcmp [file]="file" [compactView]="true" [hideDeleteButton]="true"
									class="w-100"></hr-grid-subcmp>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
		<div class="file-icon-wrapper">
			<i class="fa fa-video-camera p-2" [title]="'Record a video'" (click)="openCameraPreviewPopup($event)"></i>
			<span [title]="videoRecordFiles.length + ' video(s) record'" *ngIf="videoRecordFiles?.length"
				class="file-display-thumbnail">
				<div ngbDropdown [autoClose]="true" class="video-file-dropdown-component d-flex align-items-center"
					container="body" #dropdownVideoFiles>
					<button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
						<span class="text-uppercase">
							{{videoRecordFiles?.length}}
						</span>
					</button>
					<div ngbDropdownMenu class="file-item-list">
						<div class="d-flex flex-column">
							<div class="d-flex text-center pointer file-content m-2"
								*ngFor="let video of videoRecordFiles">
								<hr-badgebtn-subcmp [file]="video" [isDisplayTrashBtn]="true" [localFile]="true"
									(onTrashFile)="deleteFile($event, video)"></hr-badgebtn-subcmp>
								<hr-grid-subcmp [file]="video" [compactView]="true" [hideDeleteButton]="true"
									class="w-100"></hr-grid-subcmp>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
		<div class="file-icon-wrapper">
			<i class="fa fa-microphone p-2" [title]="'Record an audio'" (click)="openAudioPopup($event)"></i>
			<span [title]="audioRecordFiles.length + ' audio(s) record'" *ngIf="audioRecordFiles?.length"
				class="file-display-thumbnail">
				<div ngbDropdown [autoClose]="true" class="audio-file-dropdown-component d-flex align-items-center"
					container="body" #dropdownAudioFiles>
					<button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
						<span class="text-uppercase">
							{{audioRecordFiles?.length}}
						</span>
					</button>
					<div ngbDropdownMenu class="file-item-list">
						<div class="d-flex flex-column">
							<div class="d-flex text-center pointer file-content m-2"
								*ngFor="let audio of audioRecordFiles">
								<hr-badgebtn-subcmp [file]="audio" [isDisplayTrashBtn]="true" [localFile]="true"
									(onTrashFile)="deleteFile($event, audio)"></hr-badgebtn-subcmp>
								<hr-grid-subcmp [file]="audio" [compactView]="true" [hideDeleteButton]="true"
									class="w-100"></hr-grid-subcmp>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
	</div>
	<div class="upload-file-container" [hidden]="true">
		<mtm-upload-file [isRoundedCircle]=false [isRedirectImageLoadError]=false (files)="fileChangeListener($event)"
			#mtmUploadFileExplorer>
		</mtm-upload-file>
	</div>
</div>