<div class="container proposal-step-container">
	<div class="row">
		<div class="col-sm-9">
			<div class="card-header">
				<h6 class="font-weight-bold">5. Proposal Discussion</h6>
			</div>
			<div class="card-block">
				<div class="row">
					<div class="col-12 messages-container" ngx-auto-scroll lock-y-offset="10" observe-attributes
						#commentContainer>
						<label *ngFor="let messageInfo of allMessages"
							class="custom-control message-container message-border">
							<div class="col">
								<div class="row"><span
										class="custom-control-description messageCard-time">{{messageInfo.time |
										date:'medium' }}</span>
								</div>
								<div class="row">
									<div class="col-md-1"><img class="rounded-circle" *ngIf="messageInfo.avatarUrl"
											[src]=messageInfo.avatarUrl width="36" height="36" /><img
											class="rounded-circle" *ngIf="!messageInfo.avatarUrl" width="36" height="36"
											src="../../../assets/img/misc/avatar-no-image.png"
											class="commentUserAvatarUrl">
									</div>
									<div class="col">
										<div class="row"><span class="custom-control-description"
												style="font-weight: 600;">{{messageInfo.fullName}}</span>
										</div>
										<div class="row" style="margin-top: -15px;">
											<p class="custom-control-description userCard-UserRole">
												<mtm-comment-preview [value]="messageInfo.message"
													[participants]="project?.participants"
													[conversations]="project?.conversations">
												</mtm-comment-preview>
												<!-- <span [innerHTML]="messageInfo.message | parseUrl"
													style="white-space: pre-line"></span> -->
											</p>

										</div>
									</div>
								</div>
							</div>
						</label>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col">
						<div class="w-100 d-flex flex-row">
							<div class="d-flex flex-fill">
								<mtm-text-editor (keydown.enter)="$event.preventDefault()" [(value)]="newComment"
									class="flex-fill" (keyup)="$event.stopPropagation()"
									[participants]="project?.participants" [conversations]="project?.conversations"
									(enterKeyPress)="onSendComment()" (click)="$event.stopPropagation()">
								</mtm-text-editor>
							</div>
							<span class="input-group-addon d-flex flex-column pl-3 pr-3 justify-content-center"
								(click)="onSendComment()">↵</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-3">
			<div class="card-block" style="max-height:424px">
				<div class="row">
					<div class="col text-center">
						<h6 class="font-weight-bold">Your Proposal</h6>
					</div>
				</div>
				<hr>
				<div class="row mtm-color-scroll" style="overflow-x:auto; max-height: 295px; margin-bottom:5px">
					<div class="col">
						<div class="row	" *ngFor="let file of uploadedFiles">
							<mtm-file-download-control [file]="file"
								[uniqueControlId]="'proposal-test-' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
							</mtm-file-download-control>
						</div>
						<hr>
					</div>
				</div>
				<div class="row">
					<div class="col text-center">
						<button class="btn btn-danger btn-md" (click)="goToUploadProposal()"><span>Re-upload
								Proposal</span>
						</button>
						<!-- [disabled]="sending" -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>