<div style="align-items: center; display: flex; flex-direction: column; background-color: #ececec;">
	<div class="paymentContainer">
		<div *ngIf="projectCount >= 0" class="row w-100 justify-content-end">
			<button type="button" class="close" aria-label="Close" (click)="onCancelModal()">
				<span aria-hidden="true">x</span>
			</button>
		</div>
		<div *ngIf="name && projectCount >= 0 " class="projectHeader text-uppercase">{{"dashboardHi" | translate}}
			{{name.toUpperCase()}},
		</div>
		<div class="projectHeader text-center">
			<span *ngIf="projectCount != 0">{{"oneShotFeaturesTitle0" | translate}} {{projectCount + 1}}
				<sup>{{projectCount + 1 | ordinal }}</sup></span>
			<span *ngIf="projectCount == 0">
				{{"oneShotFeaturesTitle1" | translate}}
			</span>
			<span *ngIf="projectCount!=0">
				{{"oneShotFeaturesTitleMarketplace" | translate}}
			</span>
		</div>
		<div class="projectHeader text-center">
			{{"oneShotFeaturesTitle2" | translate}}
		</div>
		<p>{{"oneShotFeaturesSubtitle" | translate}}</p>

		<div class="details">
			<div *ngIf="projectCount<1" class="fee">
				<div class="projectCount">
					{{projectCount+1}}<sup>{{projectCount+1 | ordinal }}</sup> {{"oneShotFeatureProject" | translate}}
				</div>
				<div *ngIf="projectCount == 0"
					style="display: flex; flex-direction: row; justify-content: space-between">
					<div class="entranceFeeDescription">{{"oneShotFeatureEntranceFee" | translate}}
					</div>
					<div style="margin-left: 15px; font-size: 25px; font-weight: bold" *ngIf="projectCount == 0">
						€{{amount}}*</div>
					<div style="margin-left: 15px; font-size: 25px; font-weight: bold" *ngIf="projectCount != 0">
						{{amount}}€</div>
				</div>
				<!--				<div *ngIf="projectCount == 1" style="display: flex; flex-direction: row; justify-content: space-between">-->
				<!--					<p class="entranceFeeDescription">{{"oneShotFeatureEntranceFee" | translate}}-->
				<!--						<span class="entranceFeeSubSpanOne">{{amount}} {{"oneShotFeaturesFirstProject1Discount5" | translate}}</span>-->
				<!--						<span class="entranceFeeSubSpanTwo">{{"oneShotFeatureProcess2" | translate}}</span>-->
				<!--					</p>-->

				<!--				</div>-->
				<div *ngIf="projectCount == 0">
					{{"oneShotFeatureProcess" | translate}}

				</div>
			</div>
			<div *ngIf="projectCount>=1" class="fee free">
				<div class="projectCount">
					{{projectCount+1}}<sup>{{projectCount+1 | ordinal }}</sup> {{"oneShotFeatureProject" | translate}}
				</div>
				<div style="display: flex; flex-direction: row; justify-content: space-between">
					<p class="entranceFeeDescription"> {{"oneShotFeaturesFirstProject2Discount2" | translate}}

					</p>

				</div>
			</div>

			<div style="color: #E6431E; text-align: center; margin: 5px; font-size: 15px; font-weight: bold">
				{{"oneShotFeature1" | translate}}
			</div>
			<div class="featureContainer">
				<div
					style="display: flex; flex-direction: column; flex: 8; border-right: 2px solid #E6431E; align-items: flex-start">
					<div>
						<p class="font-weight-bold"><i class="fa fa-chevron-right feature"></i>
							{{"oneShotFeature2" | translate}}</p>
					</div>
					<div>
						<p class="font-weight-bold">
							<i class="fa fa-chevron-right feature"></i>
							{{"oneShotFeature3" | translate}}
						</p>
					</div>
					<div>
						<p class="font-weight-bold">
							<i class="fa fa-chevron-right feature"></i>
							{{"oneShotFeature4" | translate}}
						</p>

					</div>
				</div>
				<div style="display: flex; flex-direction: column; flex: 7; align-items: flex-start; margin-left: 2%">
					<div>
						<p class="font-weight-bold">
							<i class="fa fa-chevron-right feature"></i>
							{{"oneShotFeature5" | translate}}
						</p>

					</div>
					<div>
						<p class="font-weight-bold">
							<i class="fa fa-chevron-right feature"></i>
							{{"oneShotFeature6" | translate}}
						</p>

					</div>
				</div>
			</div>
			<div style="margin: 10px 15px 0px 15px">
				<label>
					<input type="checkbox" [(ngModel)]="checked">
					<span class="checkmark">
						{{"oneShotIAccepted" | translate}}
					</span>
				</label>
			</div>
		</div>
		<div>
			<button *ngIf="projectCount<1" class="btn btn-payment" [disabled]="!checked" (click)="navigatePayment()">
				{{"oneShotFeaturesPaymentBtn" | translate}}
			</button>
			<button *ngIf="projectCount>=1" class="btn btn-payment" [disabled]="!checked" (click)="continueProject()">
				{{"continue" | translate}}
			</button>
		</div>
		<div>
			<p>{{"oneShotFeaturesBtnNote" | translate}}</p>
		</div>
		<div style="display: flex; flex-direction: row; align-items: stretch; justify-content: center; width: 100%"
			[ngStyle]="{'justify-content': 'center'}">
			<!--			<div *ngIf="projectCount==0" class="discount">-->
			<!--				<div class="projectCount" [innerHTML]="'oneShotFeaturesFirstProject1Discount1' | translate"></div>-->
			<!--				<div class="discountDescription">{{"oneShotFeaturesFirstProject1Discount2" | translate}}</div>-->
			<!--				<div class="discountDescription">{{"oneShotFeaturesFirstProject2Discount2" | translate}}</div>-->
			<!--				&lt;!&ndash;				<div class="discountDescription">Pay {{"oneShotFeaturesFirstProject1Discount3" | translate}}&ndash;&gt;-->
			<!--				&lt;!&ndash;					<span class="discountDescriptionBlack"> {{"oneShotFeaturesFirstProject1Discount4" | translate}}</span>&ndash;&gt;-->
			<!--				&lt;!&ndash;				</div>&ndash;&gt;-->
			<!--			</div>-->
			<div class="discount">
				<!--				<div class="projectCount"  [innerHTML]="'oneShotFeaturesFirstProject2Discount1' | translate"></div>-->
				<div class="projectCount" *ngIf="projectCount == 0">{{projectCount+2}}<sup>{{projectCount+2 |
						ordinal}}</sup> {{"oneShotFeatureProjects" | translate}} **</div>
				<div class="projectCount" *ngIf="projectCount != 0">{{projectCount+2}}<sup>{{projectCount+2 |
						ordinal}}</sup> {{"oneShotFeatureProjects" | translate}} *</div>
				<div class="discountDescription">{{"oneShotFeaturesFirstProject2Discount2" | translate}}</div>
			</div>
		</div>
		<div class="w-100">
			<small *ngIf="projectCount == 0">*{{"oneShotFeaturesEntranceFeeVAT" | translate}}</small>
			<br>
			<small *ngIf="projectCount == 0">**{{"oneShotFeatures12Months" | translate}}</small>
			<small *ngIf="projectCount != 0">*{{"oneShotFeatures12Months" | translate}}</small>
		</div>
	</div>
</div>