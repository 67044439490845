import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { EmitterService } from "app/shared/services/emitter.service";
import { BreadcrumbService } from "ng5-breadcrumb";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubscriptionService } from "../../services/subscription.service";
import { UpgradePlanStateChanged, UpgradeTab } from "../models/upgrade-tab.model";

@Component({
	selector: 'mtm-subscription-upgrade',
	templateUrl: './upgrade.component.html',
	styleUrls: ['./upgrade.component.scss']
})
export class SubscriptionUpgradeComponent implements OnInit, OnDestroy, AfterViewInit {
	constructor(public subscriptionService: SubscriptionService, private breadcrumbService: BreadcrumbService) { }

	tabList: UpgradeTab[] = [];
	private ngUnsubscribe = new Subject();


	ngOnInit() {
		this.populateTabs();

		EmitterService.get(UpgradePlanStateChanged)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.populateTabs();
			});
	}

	ngAfterViewInit(): void {
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/upgrade",
			"Change Plan"
		);
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/upgrade/compare-plan",
			"Compare Plan"
		);
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/upgrade/select-plan",
			"Select Plan"
		);
		this.breadcrumbService.addFriendlyNameForRoute(
			"/subscription/upgrade/confirm-plan",
			"Confirm Plan"
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private populateTabs() {
		this.tabList = [];
		let index = 0;
		this.tabList.push({
			index,
			url: './select-plan',
			label: 'subscription_register_menu_selectPlan',
			bgColor: '#419E89',
			disabled: false
		});
		if (this.subscriptionService.upgradeData.isDowngrade) {
			index++;
			this.tabList.push({
				index,
				url: './compare-plan',
				label: 'subscription_upgrade_menu_comparePlan',
				bgColor: '#BAD063',
				disabled: !this.subscriptionService.planSelected
			});
		}
		index++;
		this.tabList.push({
			index,
			url: './confirm-plan',
			label: 'subscription_register_menu_confirmPlan',
			bgColor: '#F2DB4D',
			disabled: !this.subscriptionService.planCompared
		});

		if (!this.subscriptionService.upgradeData.plan || this.subscriptionService.upgradeData.plan.code != 'FREE_PLAN') {
			index++;
			this.tabList.push({
				index,
				url: './payment',
				label: 'subscription_register_menu_payment',
				bgColor: '#F4A143',
				disabled: !this.subscriptionService.upgradeData.planConfirmed
			});
		}
		index++;
		this.tabList.push({
			index,
			url: './produce',
			label: 'subscription_register_menu_produce',
			bgColor: '#FF3C00',
			disabled: !this.subscriptionService.upgradeData.planConfirmed
				|| !this.subscriptionService.upgradeData.paymentDone
		});

	}

}
