import { Component, HostBinding } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { HelperService } from "app/shared/services/helper.service";

@Component({
  selector: 'project-budget-cell',
  templateUrl: './project-budget-cell.component.html'
})
export class ProjectBudgetCellComponent implements ICellRendererAngularComp {
  @HostBinding('class') classes = 'd-block w-100';
  project: any;
  budgetText: string;

  constructor(private router: Router) {
  }

  agInit(params: ICellRendererParams): void {
    this.project = params.data;
    this.budgetText = this.getBudgetText(this.project?.budgetRange, this.project?.currency, this.project?.budget);
  }

  refresh(params: ICellRendererParams): boolean {
    this.project = params.data;
    this.budgetText = this.getBudgetText(this.project?.budgetRange, this.project?.currency || 'EUR', this.project?.budget);
    return true;
  }

  private getBudgetText(budgetRange: string, currency: string, budget: string): string {
    if (!budgetRange) {
      return currency;
    }

    if (budgetRange == 'SPECIFIC') {
      return HelperService.getCurrencySymbol(currency) + ' ' + budget;
    }

    return 'budgetRange' + budgetRange + '_' + currency;
  }

}
