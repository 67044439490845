import { IHeaderAngularComp } from "ag-grid-angular";
import { IHeaderParams } from "ag-grid-community";
import { Component } from "@angular/core";

@Component({
  selector: 'workspace-team-table-header',
  templateUrl: './workspace-team-table-header.component.html',
  styleUrls: ['./workspace-team-table-header.component.scss']
})
export class WorkspaceTeamTableHeaderComponent implements IHeaderAngularComp {
  colId: any;
  order: any = '';
  public params: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.colId = params.column.getColId();
  }

  refresh(params: IHeaderParams<any>): boolean {
    this.params = params;
    this.colId = params.column.getColId();
    return true;
  }

  onSortRequested(event: any) {
    if (this.params.column.isSortAscending()) {
        this.order = 'desc';
    } else if (this.params.column.isSortDescending()) {
        this.order = '';
    } else {
        this.order = 'asc';
    }
    this.params.setSort(this.order, event.shiftKey);
}
}
