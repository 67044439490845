<ng-template #headerContent>
	<section class="dashboard-header">
		<div class="d-flex align-items-center user-row">
			<div class="">
				<h4 class="media-heading">Welcome to to you Self Trainer, Ready to play?</h4>
			</div>
		</div>

		<div class="row " *ngIf="activeGamification">
			<div class="ml-2">
				<div class="card tab-container projects-tab-container">
					<div class="nav-tabs d-flex justify-content-center align-items-center">
						<div class="nav-item" *ngFor="let tab of availableTabs;">
							<a (click)="toggleTab(tab.value)" [class.active]="tab.value==selectedTab"
								class="nav-link d-flex justify-content-center align-items-center">{{tab.label | translate}}</a>
						</div>
					</div>
				</div>
			</div>
		</div> 
	</section>
</ng-template>
<section class="dashboard-section">
	<div class="row" >
		<div class="col-3 dashboard-left-bar">
			<div class="row">
				<div class="col-12 d-flex align-items-center flex-row">
					<div>
						<img class="user-image mr-3" src="../../../assets/img/male-model.png" alt="">
					</div>
					<div>
						<p class="user-name mb-0">John Macdrive</p>
						<p class="user-role mb-0">Role Name</p>
						<p class="user-role mb-0">C&C</p>
					</div>
				</div>
				<div class="col-12 mt-3">
					<button class="play-now-button"> <i class="fa fa-play mr-2"></i> Play Now</button>
				</div>
				<div class="col-12 mt-3 d-flex align-items-center flex-row">
					<div class="w-50">
						<p class="rank">4<sup class="rank-sup">th</sup></p>
						<p class="rank-sub-heading">All Divisions</p>
					</div>
					<div class="w-50">
						<p class="rank">2<sup class="rank-sup">nd</sup></p>
						<p class="rank-sub-heading">My Division</p>
					</div>
				</div>
				<div class="col-12 mt-3">
					<div class="row justify-content-center">
						<div class="progress-container d-flex">
						  <ngb-progressbar type="success" [value]="(stService.scoredPoints / stService.totalPoints) * 100"
							class="w-100">
							<div class="w-100 ml-3 d-flex justify-content-start align-items-center" [style.gap]="'5px'">
							  <span><i class="fa fa-star c-mtm-full-black" aria-hidden="true"></i></span>
							  <span class="f-10 _600 c-mtm-full-black"> {{stService.scoredPoints}} pts <span class="f-10 _600"
								  [style.color]="'#685613'" [style.opacity]="'0.8'"> / {{stService.totalPoints}} pts </span> </span>
							</div>
						  </ngb-progressbar>
						</div>
					  </div>
					<!-- <ngb-progressbar class="mb-3" [showValue]="true" type="warning" [value]="25" /> -->
				</div>
				<div class="col-12 mt-4 d-flex align-items-center justify-content-between">
					<p class="achievement-heading mb-0">Achievements</p>
					<p class="view-history-title mb-0">View History</p>
				</div>
				<div class="col-12 px-4 certificate-overflow">
					<!-- ultimate certificate -->
					<div class="row">
						<div (click)="toggleultimateCertificate(ultimateCertificate)" class="col-12 mt-3 px-2 d-flex align-items-center justify-content-between certification-box"
						*ngFor="let ultimateCertificate of ultimatemtmcertification">
								<div class="d-flex align-items-center flex-row">
									<div>
										<div class="circular-progress mr-2">
										<span class="progress-value">{{ultimateCertificate.completionPercentage}}%</span>
									</div>
								</div>
								<div>
									<p class="certification-title mb-0">{{ultimateCertificate.title}}</p>
									<p class="certification-description mb-0">{{ultimateCertificate.description}}</p>
								</div>
							</div>
							<div>
								<p class="certification-title mb-0">{{ultimateCertificate.points}}</p>
							</div>
						</div>
						<!-- ultimatecertificate mission exercice -->
						<div class="row mt-3 justify-content-center" *ngIf="isUltimateCertificatesToggles">
							<div class="col-12 exercice d-flex justify-content-center align-items-center"  [style.max-width]="'425px'" *ngFor="let missionExercice of selectedMissionExercice ">
								<div class="col">
									<div class="col">
										<div>
											<span class="f-10 _600 c-mtm-full-white">{{missionExercice.topic}}</span>
										</div>
										<div>
											<span class="f-14 _600 c-mtm-full-white">{{missionExercice.excercice}}</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="d-flex justify-content-end align-items-center">
											<span class="f-10 _600" [style.color]="'#FFD770'">{{missionExercice.points}}</span>
										</div>
										<div *ngIf="missionExercice.bonusPoints">
											<span class="f-14 _600 " [style.color]="'#FF6B00'">BONUS : + {{missionExercice.bonusPoints}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- end -->

						<!-- other certificates -->
						<div class="col-12 mt-2 px-2 d-flex align-items-center justify-content-between achievement-box"
							*ngFor="let item of achievements">
							<div class="d-flex align-items-center flex-row">
								<div>
									<img class="mr-2" [src]=[this.getMedalImage(item.medal)] alt="medal">
								</div>
								<div>
									<p class="achievement-title mb-0">{{item.title}}</p>
									<p class="achievement-description mb-0">{{item.description}}</p>
								</div>
							</div>
							<div>
								<p class="mb-0">{{item.points}} pts</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-9 dashboard-right-section">
			<!-- division section -->
			<div class="row division-section-row ml-0">
				<div class="d-flex pointer">
					<span [ngClass]="{ 'activeDiv': isAllDivison, '_400': !isAllDivison, '_600': isAllDivison }"
						class="f-14 c-mtm-full-black" (click)="isAllDivison = true">All Division</span>
				</div>
				<div class="d-flex ml-3 pointer">
					<span [ngClass]="{ 'activeDiv': !isAllDivison, '_400': isAllDivison, '_600': !isAllDivison }"
						class="f-14 c-mtm-full-black" (click)="isAllDivison = false">My Division</span>
				</div>
			</div>
		<!-- all division -->
			<div class="row" *ngIf="isAllDivison">
				<div class="col-12 mt-3 px-4">
					<div class="row">
						<div class="col-12 mt-2 px-2 d-flex align-items-center justify-content-between achievement-box"
							*ngFor="let item of achievements">
							<div class="d-flex align-items-center flex-row">
								<div>
									<img class="mr-3" [src]=[this.getMedalImage(item.medal)] alt="medal">
								</div>
								<div>
									<p class="achievement-title mb-0">{{item.title}}</p>
									<p class="achievement-description mb-0">{{item.description}}</p>
								</div>
							</div>
							<div>
								<p class="mb-0">{{item.points}} pts</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- my division -->
			<div class="row" *ngIf="!isAllDivison">
				<div class="col-12 mt-3 px-4">
					<div class="row">
						<div class="col-12 mt-2 px-2 d-flex align-items-center justify-content-between mydivision-achievement-box"
							*ngFor="let item of mydivisionachievements;let i=index" >
							<div class="d-flex align-items-center flex-row">
								<div>
									<span class="user-index f-16 _400">{{++i}}</span>
								</div>
								<div class=" ml-4">
									<img width="52px" height="52px" class="mr-3" [src]=[this.getMedalImage(item.medal)] alt="medal">
								</div>
								<div class="row">
									<div class="col-12">
										<span class="user-role-name f-18 _400 mb-0">{{item.name}} - {{item.role}}</span>
									</div>
									<div class="col-12">
										<span class="user-comapny mb-0 f-16 _700">{{item.company}}</span>
									</div>
								</div>
							</div>
							<div>
								<p class="mb-0 user-points">{{item.points}} pts</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>