<!doctype html>
<html>

<head>
	<!-- Google Tag Manager -->
	<script>(function (w, d, s, l, i) {
			w[l] = w[l] || []; w[l].push({
				'gtm.start':
					new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
					'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-NXTDWP9');</script>
	<!-- End Google Tag Manager -->
	<meta charset="utf-8">
	<title>MTM</title>
	<base href="/">

	<meta name="viewport" content="width=device-width, initial-scale=1">
	<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
	<meta http-equiv="Pragma" content="no-cache">
	<meta http-equiv="Expires" content="0">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<link rel="icon" type="image/png" href="assets/img/favicon.png">
</head>

<body>
	<!-- Google Tag Manager (noscript) -->
	<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NXTDWP9" height="0" width="0"
			style="display:none;visibility:hidden"></iframe></noscript>
	<!-- End Google Tag Manager (noscript) -->
	<div id="index_animation" class="index-animation-container">
		<div id="maintenance" class="maintenance-container">
			<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="228" height="228"
				viewBox="0 0 127.000000 111.000000" preserveAspectRatio="xMidYMid meet">
				<g transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
					<defs>
						<linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" style="stop-color:#ea0303;stop-opacity:1" />
							<stop offset="100%" style="stop-color:#f75605;stop-opacity:1" />
						</linearGradient>
					</defs>
					<path fill="url(#grad1)" d="M595 1100 c-22 -5 -56 -9 -76 -9 -36 -1 -139 -37 -150 -53 -3 -5 -16
	-10 -28 -13 -31 -6 -66 -29 -122 -82 -34 -32 -45 -49 -42 -64 4 -13 1 -18 -8
	-14 -8 2 -21 -1 -30 -8 -21 -16 -90 -153 -81 -161 3 -4 1 -11 -6 -15 -36 -22
	-49 -280 -17 -356 7 -16 23 -58 36 -93 13 -34 30 -62 37 -62 7 0 10 -5 7 -11
	-8 -12 32 -73 80 -119 l35 -34 0 287 c0 281 0 287 19 277 11 -5 27 -7 37 -3
	13 5 16 3 11 -10 -4 -12 21 -33 104 -87 61 -40 119 -75 130 -77 10 -3 19 -9
	19 -13 0 -5 20 -22 44 -38 l44 -30 78 48 c88 56 124 87 124 108 0 9 6 12 16 8
	17 -6 76 24 145 73 23 17 44 29 46 27 3 -3 5 -131 5 -286 l0 -282 45 43 c24
	24 53 60 62 79 10 19 30 56 44 81 40 68 82 289 59 303 -5 3 -7 19 -4 35 4 30
	-20 119 -43 156 -6 11 -15 34 -19 50 -13 52 -55 115 -77 115 -12 0 -17 4 -13
	11 9 15 -72 96 -116 117 -19 9 -56 29 -81 44 -42 26 -102 44 -169 52 -14 2
	-43 5 -65 9 -22 3 -58 1 -80 -3z m350 -384 l-36 -65 -66 0 c-43 0 -62 4 -56
	10 15 15 188 126 191 123 2 -1 -13 -32 -33 -68z m-596 42 c20 -12 39 -26 42
	-31 3 -5 9 -8 13 -8 3 0 27 -14 52 -32 l46 -32 -63 -3 c-56 -3 -65 0 -81 20
	-10 13 -18 28 -18 33 0 6 -9 21 -20 35 -35 45 -26 51 29 18z" />
				</g>
			</svg>
			<div class="info-box">
				<h2 class="title">{{ 'maintenanceTitle' | translate }}</h2>
				<h3 class="subtitle">{{ 'maintenanceSubTitle' | translate }}</h3>
				<p class="info">
					{{ 'maintenanceInfo' | translate }}
				</p>
				<p class="subtitle support-info">
					{{ 'maintenanceSupport' | translate }}
				</p>
				<div class="support"><a href="mailto:support@mtm.video">support@mtm.video</a></div>
			</div>
		</div>
	</div>

	<script>
		(function (i, s, o, g, r, a, m) {
			i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
				(i[r].q = i[r].q || []).push(arguments)
			}, i[r].l = 1 * new Date(); a = s.createElement(o),
				m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
		})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
		ga('create', 'UA-103666960-1', 'auto');
	</script>

	<script>
		// Add safari-mac class in body if browser is Safari on Mac
		if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Mac') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
			document.body.className += "safari-mac";
		}
	</script>

</body>

</html>