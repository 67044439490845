import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ProposalInvitationService {

	constructor(private api: ApiService,
		public auth: AuthService) {
	}

	public saveInvitedCompany(invitedProdCompany: any): Observable<any> {
		return this.api.httpPost(`/api/proposal-invitations/project/${invitedProdCompany.projectId}`, invitedProdCompany)
	}

	public setInvitationStatus(invitationProject: any): Observable<any> {
		return this.api.httpPut(`/api/proposal-invitations/project/${invitationProject.projectId}`, invitationProject)
	}

	/*public declineInvitation(companyId, clientId, projectId, proposalInvitation: any): Observable<any> {
		return this.api.httpDelete(`/api/proposal-invitations`, proposalInvitation);
	}*/

	public declineInvitation(proposalInvitation, username: string): Observable<any> {
		return this.api.httpPost(`/api/proposal-invitations/user/${username}/decline-invitation`, proposalInvitation);
	}

	public getInvitedCompanies(): Observable<any> {
		return this.api.httpGet(`/api/proposal-invitations/user/${this.auth.getAuthUser().username}`)
	}

	public getInvitedCompaniesByUserProject(): Observable<any> {
		return this.api.httpGet(`/api/proposal-invitations/user/${this.auth.getAuthUser().username}/projects`)
	}

	public getInvitedCompaniesContentByUserProject(): Observable<any> {
		return this.api.httpGet(`/api/proposal-invitations/user/${this.auth.getAuthUser().username}/projects/prodPartner/content`)
	}

	public getProposals(): Observable<any> {
		return this.api.httpGet(`/api/proposals/proposalStatus/user/${this.auth.getAuthUser().username}`)
	}

	public getProposalsByUserProject(): Observable<any> {
		return this.api.httpGet(`/api/proposals/proposalStatus/user/${this.auth.getAuthUser().username}/projects`)
	}

	public getProposalsContentByUserProject(): Observable<any> {
		return this.api.httpGet(`/api/proposals/proposalStatus/user/${this.auth.getAuthUser().username}/projects/prodPartner/content`)
	}

	public getProposalByProjectId(projectId: string): Observable<any> {
		return this.api.httpGet(`/api/proposals/${projectId}/proposalStatus`)
	}

	public getInvitationCheck(): Observable<any> {
		return this.api.httpGet(`/api/proposal-invitations/user/${this.auth.getAuthUser().username}/count`)
	}

	public getProjectInvitations(projectId): Observable<any> {
		return this.api.httpGet(`/api/proposal-invitations/project/${projectId}`);
	}

	public saveProdCompanyRate(projectId, prodCompId, productionCompanyRate): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/productionCompanyRates/${prodCompId}`, productionCompanyRate);
	}

	public getAllProductionCompanyRate(prodCompId): Observable<any> {
		return this.api.httpGet(`/api/projects/${prodCompId}/productionCompanyRates`);
	}

	public getProductionCompanyRate(projectId, prodCompId): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/productionCompanyRates/${prodCompId}`);
	}

	public getProductionCompanyAvgRate(prodCompId): Observable<any> {
		return this.api.httpGet(`/api/projects/productionCompanyRates/${prodCompId}`);
	}

	public saveClientCompanyRate(projectId, clientCompId, clientCompanyRate, username): Observable<any> {
		return this.api.httpPost(`/api/ratingMail/${projectId}/clientCompanyRates/${clientCompId}/${username}`, clientCompanyRate);
	}

	public getClientCompanyAvgRate(clientCompId): Observable<any> {
		return this.api.httpGet(`/api/ratingMail/clientCompanyRates/${clientCompId}`);
	}
	public getClientReviewsCompanyRate(clientCompId): Observable<any> {
		return this.api.httpGet(`/api/ratingMail/clientCompanyRates/review/${clientCompId}`);
	}
}
