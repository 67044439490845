import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SecurityPolicyService {

    constructor(private api: ApiService, public auth: AuthService) { }

    public getSecurityConfig(companyId: string): Observable<any> {
        let endpoint = `/api/security-policies/${companyId}`;
        return this.api.httpGet(endpoint);
    }

    public createOrEditSecurityConfig(data: any): Observable<any> {
        let endpoint = `/api/security-policies`;
        return this.api.httpPost(endpoint, data);
    }

    public deleteSecurityConfig(companyId: string, configKey: string): Observable<any> {
        let endpoint = `/api/security-policies`;
        return this.api.httpDelete(endpoint, null, { companyId, configKey });
    }

    public platformConfig(category: string, configKey: string, configValue: any): Observable<any> {
        let endpoint = `/api/platform-config`;
        return this.api.httpPost(endpoint,  { category, configKey, configValue });
    }

    public getSecurityPolicyPlatformConfig(key?: any): Observable<any> {
        let endpoint = `/api/platform-config/SECURITY_POLICY`;
        return this.api.httpGet(endpoint);
    }
}
