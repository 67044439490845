import { Component } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'decision-maker-cell',
    templateUrl: './decision-maker-cell.component.html',
    styleUrls: ['./decision-maker-cell.component.scss']
})
export class DecisionMakerCellComponent implements ICellRendererAngularComp {
    params: any;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params.data;
        return true;
    }
}

