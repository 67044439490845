import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectService } from "app/shared/services/project.service";
import { NotificationService } from './../../../../shared/services/notification.service';
import { ProposalCommentService } from 'app/shared/services/proposal-services/comment.service';
import { CommentModel } from '../comment.model';
import ProposalConversationBase from '../proposal-conversation.base';
import { UserService } from "../../../../shared/services/user.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';

@Component({
	selector: 'mtm-project-proposal-qaSession',
	templateUrl: './project-proposal-qaSession.component.html',
	styleUrls: ['./project-proposal-qaSession.component.scss']
})
export class ProjectProposalQaSessionComponent extends ProposalConversationBase implements OnInit, OnDestroy {
	@ViewChild('commentContainer', { static: true }) commentContainer: ElementRef;
	invitationProjectId: string = '';
	proposalId: string = '';
	proposal: any = {};
	sectionId: string = "30dbc93a-2aca-5838-418c-5b529e3bbdf8";
	allMessages: any[] = [];
	authUser: any;
	files: any[] = [];
	newFiles: any[] = [];
	newComment: any;
	project: any;
	ngUnsubscribe = new Subject();

	constructor(
		public userService: UserService,
		public service: ProjectService,
		private router: Router,
		private activeRouter: ActivatedRoute,
		private modalService: NgbModal,
		private serviceApi: ApiService,
		private serviceAuth: AuthService,
		private notificationService: NotificationService,
		private serviceProposalComment: ProposalCommentService,
		private projectService: ProjectService,
		stompService: MtmStompService,
		injector: Injector) {
		super(injector, stompService, serviceAuth);
		this.setFncReceiveMessageFromWS(this.receivedMessageFromWS);
	}


	ngOnInit() {
		this.invitationProjectId = this.activeRouter.parent.snapshot.params['projectId'];
		this.proposalId = this.activeRouter.parent.snapshot.params['proposalId'];
		this.getProposal();

		this.subscribeWS_ProposalConversation(this.proposalId);

		this.authUser = this.serviceAuth.getAuthUser();
		this.getMessages();
		this.projectService.getProject(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((project) => {
			this.projectService.project = project;
			this.projectService.project.conversations = [];
			this.project = this.projectService.project;
		});
	}

	ngOnDestroy() {
		this.unsubscribes();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private getProposal() {
		this.service.getProposalByUsername(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			this.proposal = proposal;
		});
	}

	private getMessages() {
		this.serviceProposalComment.getAllQaComment(this.invitationProjectId, this.proposalId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data: any[]) => {
				if (!Array.isArray(data))
					return;
				let messages: CommentModel[] = data;
				messages.map((k: any) => {
					this.userService.findUserByUsername(k.username, false, true).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						res => {
							k.fullName = res.fullName;
							k.avatarUrl = res.avatarUrl;
							this.allMessages.push(k);
							this.allMessages.sort((a, b) => {
								return a.time - b.time;
							});
							setTimeout(() => {
								this.commentContainer.nativeElement.scrollTop = this.commentContainer.nativeElement.scrollHeight;
							}, 0);
						});
				});
			});
	}

	onSendComment() {
		if (this.isTextContentEmpty(this.newComment)) {
			return;
		}
		let comment = new CommentModel(this.invitationProjectId, this.proposalId, this.sectionId, this.serviceAuth.getAuthUser().username, this.newComment);
		this.serviceProposalComment
			.setQaComment(this.invitationProjectId, this.proposalId, comment).pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(
				(data: any) => {
					this.newComment = '';
				},
				err => {
					this.newComment = '';
				}
			);
	}

	private receivedMessageFromWS(data) {
		if (data.code != "PROPOSAL_MESSAGE_QA")
			return;

		let username = data.username;
		this.userService.findUserByUsername(username, false, true).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				this.allMessages.push({
					fullName: res.fullName,
					avatarUrl: res.avatarUrl,
					username: username,
					message: JSON.parse(data.objectJson).message,
					time: data.time
				});
				setTimeout(() => {
					this.commentContainer.nativeElement.scrollTop = this.commentContainer.nativeElement.scrollHeight;
				}, 0);
			});
	}

	private isTextContentEmpty(textContent): boolean {
		if (!textContent || textContent == '')
			return true;

		return false;
	}
	goToUploadProposal() {
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'uploadProposal']);
	}

}
