import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { ColDef } from "ag-grid-community";
import { HelperService } from "../../../shared/services/helper.service";
import { AgGridAngular } from "ag-grid-angular";
import { Router } from "@angular/router";
import { ProjectNameCellComponent } from "./table/renderer/project-name-cell/project-name-cell.component";
import { ProjectBudgetCellComponent } from "./table/renderer/project-budget-cell/project-budget-cell.component";
import { ProjectListTableHeaderComponent } from "./table/header/project-list-table-header.component";
import {
  DateCellRendererComponent
} from "../../../shared/components/grid/renderer/date-cell/date-cell-renderer.component";
import { ProjectOwnerCellComponent } from "./table/renderer/project-owner-cell/project-owner-cell.component";
import { ProjectActionsCellComponent } from "./table/renderer/project-action-cell/project-action-cell.component";
import { LimitReachedPopupComponent } from "app/subscriptions/shared/components/limit-reached-popup/limit-reached-popup.component";
import { SUBSCRIPTION_LIMIT } from "app/subscriptions/models/const";
import { takeUntil } from "rxjs/operators";
import { NotificationService } from "app/shared/services/notification.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { PaymentService } from "app/shared/services/payment.service";
import { PermissionService, Authority } from "app/shared/services/permissions.service";
import { ProjectService } from "app/shared/services/project.service";
import { UserService } from "app/shared/services/user.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { BreadcrumbService } from "ng5-breadcrumb";
import { Subject } from "rxjs";
import { ProjectStatusCellComponent } from "./table/renderer/project-status-cell/project-status-cell.component";
import { ProjectStatusFilterComponent } from "./table/filter/project-status-filter/project-status-filter.component";
import {
  ProjectStatusFloatingFilterComponent
} from "./table/filter/project-status-floating-filter/project-status-floating-filter.component";
import { Project, User } from "app/shared/interfaces";
import { OverlayService } from "app/shared/services/overlayService";

const defaultCellClass = 'h-100 d-flex align-items-center';

const projectColumnsDef: ColDef[] = [
  {
    field: 'name',
    headerName: 'Project Name',
    sortable: true,
    cellRenderer: ProjectNameCellComponent,
    cellClass: defaultCellClass,
    resizable: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    minWidth: 240,
    flex: 1,
    autoHeight: true
  }, {
    field: 'manager',
    headerName: 'Owner',
    cellRenderer: ProjectOwnerCellComponent,
    cellClass: defaultCellClass,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    minWidth: 193,
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: ProjectStatusCellComponent,
    sortable: true,
    resizable: true,
    filter: ProjectStatusFilterComponent,
    floatingFilter: true,
    floatingFilterComponent: ProjectStatusFloatingFilterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    minWidth: 220,
    suppressMenu: true,
    flex: 1,
    cellClass: 'd-flex h-100 align-items-center justify-content-center',
  },
  {
    field: 'creationDate',
    headerName: 'Created Date',
    cellRenderer: DateCellRendererComponent,
    sortable: true,
    cellClass: defaultCellClass,
    resizable: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    filterParams: {
      comparator: HelperService.compareAgGridDate,
      inRangeFloatingFilterDateFormat: 'DD/MM/YYYY',
    },
    minWidth: 193,
    flex: 1

  }, {
    field: 'startDate',
    headerName: 'Start Date',
    cellRenderer: DateCellRendererComponent,
    sortable: true,
    cellClass: defaultCellClass,
    resizable: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    flex: 1,
    filterParams: {
      comparator: HelperService.compareAgGridDate,
      inRangeFloatingFilterDateFormat: 'DD/MM/YYYY',
    },
    minWidth: 193
  }, {
    field: 'plannedEndDate',
    headerName: 'Due Date',
    cellRenderer: DateCellRendererComponent,
    sortable: true,
    cellClass: defaultCellClass,
    resizable: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    filterParams: {
      comparator: HelperService.compareAgGridDate,
      inRangeFloatingFilterDateFormat: 'DD/MM/YYYY',
    },
    minWidth: 193,
    flex: 1
  }, {
    field: 'airDate',
    headerName: 'Air Date',
    cellRenderer: DateCellRendererComponent,
    sortable: true,
    cellClass: defaultCellClass,
    resizable: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    filterParams: {
      comparator: HelperService.compareAgGridDate,
      inRangeFloatingFilterDateFormat: 'DD/MM/YYYY',
    },
    minWidth: 193,
    flex: 1
  }, {
    field: 'budget',
    headerName: 'Budget',
    cellClass: `${defaultCellClass} text-right`,
    resizable: true,
    filter: 'agNumberColumnFilter',
    floatingFilter: true,
    filterParams: {
      numberParser: val => val
    },
    cellRenderer: ProjectBudgetCellComponent,
  }, {
    field: 'action',
    headerName: 'Actions',
    cellRenderer: ProjectActionsCellComponent,
    cellClass: 'd-flex h-100 flex-column align-items-center justify-content-center',
  }
];

@Component({
  selector: 'mtm-project-list-view',
  templateUrl: './project-list-view.component.html',
  styleUrls: ['./project-list-view.component.scss']
})
export class ProjectListViewComponent implements OnInit, OnDestroy {
  @ViewChild('projectsTable') projectsTable!: AgGridAngular;
  @Input() projectList: any[] = [];
  @Input() projectName: string = '';

  @Output() projectArchived = new EventEmitter<Project>();
  @Output() projectRestored = new EventEmitter<Project>();
  @Output() projectDeleted = new EventEmitter<Project>();

  tableColumnsDef: ColDef[] = projectColumnsDef;
  components: { [p: string]: any } = { agColumnHeader: ProjectListTableHeaderComponent, };
  tableContext: any;
  ngUnsubscribe = new Subject();
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    autoHeight: true,
  };

  constructor(
    private router: Router,
    public service: ProjectService,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private breadCrumb: BreadcrumbService,
    private translate: TranslatePipe,
    private modalService: NgbModal,
    private overlayService: OverlayService,
    private permissionService: PermissionService) {
    this.tableContext = {
      parentComponent: this
    };
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  autoSizeColumnWidth() {
    this.projectsTable.columnApi.autoSizeAllColumns();
  }

  public editProject(project): void {
    this.router.navigate(['/projects/edit', project.id]);
  }

  public archiveProject(project) {
    const isAdmin = this.permissionService.hasAuthority(Authority.Z, null);

    if (!this.authService.hasPaidSubscription() && !isAdmin) {
      let modalRef = this.modalService.open(LimitReachedPopupComponent, { windowClass: 'limit-storage-modal' });
      modalRef.componentInstance.limitType = SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE;
      return;
    }

    let projectId = project.id;
    this.notificationService.open({
      title: 'Archiving...',
      description: `Are you sure you want to archive this project?\n${project.name}\n`,
      confirmBtn: 'Yes, please',
      cancelBtn: 'Cancel'
    }).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((confirm: any) => {
      if (confirm) {
        let company: any = project.company;
        this.service.getProductionOwnerForRating(projectId, company.id).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(productionOwner => {
          if (productionOwner && productionOwner.username) {
            this.router.navigate(['projects', 'rate-production-company', projectId], { queryParams: { action: 'delete' } });
            this.breadCrumb.hideRouteRegex(`/projects/rate-production-company/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*$`);
          }
          else {
            this.service.deleteProject(project.id).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: () => {
                this.projectArchived.emit(project);
               },
              error: err => {
                this.notificationService.open({
                  title: 'Warning',
                  description: err.message,
                  confirmBtn: 'Ok'
                });
              }
            })
          }
        })
      }
    });
  }

  public deleteProject(project) {
    this.notificationService.open({
      title: this.translate.transform('projectDeleteTitle'),
      description: `${this.translate.transform('projectDeleteConfirm', { project_name: project.name })}`,
      confirmBtn: this.translate.transform('yes'),
      cancelBtn: this.translate.transform('cancel')
    }).subscribe((confirm: any) => {
      if (confirm) {
        this.service.deleteProject(project.id, false).subscribe(
          res => {
            this.projectDeleted.emit(project);
           },
          err => {
            this.notificationService.open({
              title: 'Warning',
              description: err.message,
              confirmBtn: 'Ok'
            });
          });
      }
    })
  }

  restoreProject(project: Project) {
		this.service.restoreArchive(project.id).subscribe({
			next: (result: any) => { 
        this.projectRestored.emit(project);
			},
			error: (err: unknown) => {
				this.overlayService.showError(this.translate.transform('overlayErrorSave'), 'Error');
			}
		});
	}
}
