<div *ngIf="isDisplay" #container>
	<div class="capsul">
		<div class="content-area m-2">
			<div class="d-flex align-items-center justify-content-center flex-column">
				<div class="d-block">
					<i *ngIf="isLoading" class="fa fa-spinner fa-pulse" [ngStyle]="iconStyle"></i>
					<i *ngIf="isError" class="fa fa-exclamation-triangle" [ngStyle]="iconStyle"></i>
					<i *ngIf="isInfo" class="fa fa-info-circle" [ngStyle]="iconStyle"></i>
				</div>
				<div class="d-block">
					<p *ngIf="isLoading" class="text-secondar m-2" [ngStyle]="textStyle"> {{loadingText}}</p>
					<p *ngIf="isError" class="text-danger text-underline text-opacity" [ngStyle]="textStyle">
						{{errorText}}</p>
					<p *ngIf="isInfo" class="text-muted text-underline text-opacity" [ngStyle]="textStyle">{{infoText}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>