import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { Subject } from 'rxjs';

export enum sharedOptions {
  All_SHARED = 'All Shared Projects',
}

@Component({
  selector: 'mtm-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit, OnDestroy {
  sharedSearch: string;
  sharedType = Object.values(sharedOptions);
  selectedType: string;
  calendarViewMode: string = 'Latest';
  authUser: any;
  ngUnsubscribe = new Subject();

  constructor(
    public timelineService: TimelineService,
    private authService: AuthService
  ){}

  ngOnInit(): void {
    this.timelineService.resetTabsEmmiter.emit(true);

    this.authUser = this.authService.getAuthUser();

    this.timelineService.isDriveNav.emit(true);
    const brand = this.authService.getMyBrand();
    this.timelineService.componentName.next({
      componentName: 'sharedComponent',
      bannerUrl: brand.banner
    });
    this.selectedType = sharedOptions.All_SHARED;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
  search(){}

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  changeProjectType(type){
    console.log('type', type)

  }
  
  selectCalendarMode(mode) {
    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        break;
      default:
        this.calendarViewMode = 'Latest';
        break;
    }
    console.log('this.calendarViewMode', this.calendarViewMode)
  }
}
