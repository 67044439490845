import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { Observable } from "rxjs";
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class CompanyService {

	private director: any = {};

	constructor(private api: ApiService,
		private authService: AuthService) { }

	private getCompanyId() {
		try {
			return this.authService.getAuthUser().companyId;
		} catch (error) {
			return null;
		}
	}

	/**
	 * Create Company's award
	 *
	 * @param award
	 */
	public createAward(award: any): Observable<any> {
		return this.api.httpPost(`/api/companies/${this.getCompanyId()}/portfolio/awards`, award);
	}

	/**
	 * Create Company's experience
	 *
	 * @param exp
	 */
	public createExperience(exp: any): Observable<any> {
		return this.api.httpPost(`/api/companies/${this.getCompanyId()}/portfolio/experiences`, exp);
	}

	/**
	 * Create Company's work
	 *
	 * @param work
	 */
	public createWork(work: any): Observable<any> {
		return this.api.httpPost(`/api/companies/${this.getCompanyId()}/portfolio/works`, work);
	}

	public updateWork(workId, work): Observable<any> {
		return this.api.httpPut(`/api/companies/${this.getCompanyId()}/portfolio/works/${workId}`, work);
	}

	/**
	 * Delete company's work
	 *
	 * @param companyId
	 * @param workId
	 */
	public deleteWork(companyId: string, workId: string): Observable<any> {
		return this.api.httpDelete(`/api/companies/${companyId}/portfolio/works/${workId}`);
	}

	/**
	 * Retrieve list of company's works
	 *
	 * @param companyId
	 */
	public getWorks(companyId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/works`);
	}

	public getAllCompanies(): Observable<any> {
		return this.api.httpGet('/api/companies');
	}

	public getAllCompaniesWithSearchAndPagination(companyName?: any, hasSubscribeToAnyPlan?: any, planType?: any, onTrialPeriod?: any, companyType?: any, sector?: any, subSector?: any, country?: any, companyId?: string, pagingState?: any): Observable<any> {
		let queryParams = [];
		if (companyName) {
			queryParams.push(`companyName=${companyName}`);
		}
		if (typeof hasSubscribeToAnyPlan === 'boolean') {
			queryParams.push(`hasSubscribeToAnyPlan=${hasSubscribeToAnyPlan}`);
		}
		if (planType) {
			queryParams.push(`planType=${planType}`);
		}
		if (typeof onTrialPeriod === 'boolean') {
			queryParams.push(`onTrialPeriod=${onTrialPeriod}`);
		}
		if (companyType && companyType != 'BOTH') {
			queryParams.push(`companyType=${companyType}`);
		}
		if (sector) {
			queryParams.push(`sector=${sector}`);
		}
		if (subSector) {
			queryParams.push(`subSector=${subSector}`);
		}
		if (country) {
			queryParams.push(`country=${country}`);
		}
		if (companyId) {
			queryParams.push(`companyId=${companyId}`);
		}
		if (pagingState) {
			queryParams.push(`pagingState=${pagingState}`);
		}
		return this.api.httpGet(`/api/companies/search${queryParams.length ? `?${queryParams.join('&')}` : ''}`);
	}

	/**
	 * Retrieve company's work by ID
	 *
	 * @param companyId
	 * @param workId
	 */
	public getWork(workId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/works/work/${workId}`);
	}

	public getAward(awardId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/award/${awardId}`);
	}

	/**
	 * Retrieve list of work's files
	 *
	 * @param workId
	 */
	public getWorkFiles(workId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/works/${workId}/files`);
	}
	public getFilmFiles(filmId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/directorsFilms/${filmId}/files`);
	}
	public getFilmFilesForClient(companyId: String, filmId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/directorsFilms/${filmId}/files`);
	}
	public getDirectorFiles(directorId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors/${directorId}/files`);
	}
	public getDirectorFilesForClient(companyId: String, directorId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/companyDirectors/${directorId}/files`);
	}
	/**
	 * Retrieve URL for work upload files
	 *
	 * @param workId
	 * @param companyId
	 */
	public getUrlToUploadFiles(workId: string, companyId: string): string {
		return `/api/companies/${companyId}/portfolio/works/${workId}/files`;
	}



	/**
	 * Delete Work's file
	 *
	 * @param companyId
	 * @param workId
	 * @param fileId
	 */
	public deleteWorkFile(workId, fileId): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/works/${workId}/files/${encodeURIComponent(fileId)}`);
	}

	public deleteFilmFile(filmId, fileId): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/directorsFilms/${filmId}/files/${encodeURIComponent(fileId)}`);
	}
	/**
	 * Retrieve list of company's awards
	 *
	 * @param companyId
	 */
	public getAwards(): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/awards`);
	}
	public getAwardsForClientSide(companyId): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/awards`);
	}

	/**
	 * Delete companmy's award
	 *
	 * @param companyId
	 * @param awardId
	 */
	public deleteAward(awardId: string): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/awards/${awardId}`);
	}

	/**
	 * Update company award
	 *
	 * @param companyId
	 * @param award
	 */
	public updateAward(award: any): Observable<any> {
		return this.api.httpPut(`/api/companies/${this.getCompanyId()}/portfolio/awards/${award.id}`, award);
	}

	/**
	 * Retrieve list of company's experience
	 *
	 * @param companyId
	 */
	public getExperiences(): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/experiences`);
	}

	public getExperiencesForClientSide(companyId): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/experiences`);
	}
	/**
	 * Delete company's experience by ID
	 *
	 * @param companyId
	 * @param experienceId
	 */
	public deleteExperience(experienceId: string): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/experiences/${experienceId}`);
	}

	/**
	 * Update company's experience by ID
	 *
	 * @param companyId
	 * @param experience
	 */
	public updateExperience(experience: any): Observable<any> {
		return this.api.httpPut(`/api/companies/${this.getCompanyId()}/portfolio/experiences/${experience.id}`, experience);
	}

	/**
	 * Save company's preferences
	 *
	 * @param preferences
	 */
	public savePreferences(preferences: any): Observable<any> {
		return this.api.httpPost(``, preferences);
	}

	/**
	 * Retrieve all options
	 */
	public getAllOptions(): Observable<any> {
		return this.api.httpGet(`/api/commons/company/profile/all`);
	}

	public createCompany(company: any, createDefaultWorkspace: boolean = false): Observable<any> {
		const url = `/api/companies?defaultWorkspace=${createDefaultWorkspace}`;
		return this.api.httpPost(url, company)
	}

	public updateCompany(companyId, company: any, syncAddress: boolean = false, stripeToken: string = null): Observable<any> {
		let url = `/api/companies/${companyId}`;

		if (syncAddress) {
			url += `?syncStripeAddress=true`;
			if (stripeToken) {
				url += `&accountToken=${stripeToken}`;
			}
		}

		return this.api.httpPut(url, company)
	}

	public getCompanyById(companyId): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}`)
	}

	public checkPaidOneShotCount(companyId): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/checkPaidOneShotCount`)
	}

	public createCompanyDirector(director: any): Observable<any> {
		return this.api.httpPost(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors`, director);
	}
	public getAllCompanyDirectors(): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors`);
	}
	public getCompanyDirector(directorId): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors/${directorId}`);
	}
	public createDirectorsFilms(film: any): Observable<any> {
		return this.api.httpPost(`/api/companies/${this.getCompanyId()}/portfolio/directorsFilms`, film);
	}
	public getDirectorFilms(directorId): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors/${directorId}/directorFilms`);
	}
	public getDirectorFilmsForClient(companyId, directorId): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/portfolio/companyDirectors/${directorId}/directorFilms`);
	}
	public getDirectorFilm(filmId): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/portfolio/directorsFilms/${filmId}`);
	}
	public getUrlToUploadFilesFilm(filmId, companyId): string {
		return `/api/companies/${companyId}/portfolio/directorsFilms/${filmId}/files`;
	}
	public getUrlToUploadFilesDirector(directorId, companyId): string {
		return `/api/companies/${companyId}/portfolio/companyDirectors/${directorId}/files`;
	}
	public deleteFilm(filmId): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/directorsFilms/${filmId}`);
	}
	public deleteDirector(directorId): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/portfolio/companyDirectors/${directorId}`);
	}
	public filterCompany(companyName): Observable<any> {
		return this.api.httpGet(`/api/companies/nameStarts/${companyName}`);
	}

	public setDirector(director: any) {
		this.director = director;
	}

	public getDirector() {
		return this.director;
	}
	public getCompanyFiles(): Observable<any> {
		return this.api.httpGet(`/api/companies/${this.getCompanyId()}/files`);
	}
	public deleteCompanyFile(fileId): Observable<any> {
		return this.api.httpDelete(`/api/companies/${this.getCompanyId()}/files/${encodeURIComponent(fileId)}`);
	}
	public getCompanyUsers(includeBrandId: boolean = false): Observable<any> {
		const params = includeBrandId ? '?includeBrandId=true' : '';
		return this.api.httpGet(`/api/users/company/${this.getCompanyId()}${params}`);
	}

	public getCompanyProfileCompletion(companyId: string): Observable<any> {
		return this.api.httpGet(`/api/companies/${companyId}/profileCompletion`);
	}

	getCompanyFileStorage(companyId: any) {
		return this.api.httpGet(`/api/companies/${companyId}/files/storage/size`);
	}

	//this is only for admin
	updateCompanyAccess(companyId: string, access: any) {
		return this.api.httpPut(`/api/admin/company-access/${companyId}`, access);
	}

	forceDeleteCompanies(companyIds: string[]) {
		return this.api.httpDelete(`/api/admin/deleteCompanyForceMulti`, false, companyIds);
	}
}
