import { NotificationService } from "app/shared/services/notification.service";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UploadService } from "../../../../shared/services/upload.service";
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UploadModalComponent } from "app/on-boarding/profile-avatar/upload-modal/upload-modal.component";
import { ApiService } from "app/shared/services/api.service";
import { CommonService } from "../../../../shared/services/common.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { HelperService } from "../../../../shared/services/helper.service";
import { DateHelperService } from "../../../../shared/services/date-helper.service";
import { UserService } from "../../../../shared/services/user.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { ValidatorService } from "app/shared/services/validator.service";
import { MtmCurrencyPipe } from "app/shared/pipes/mtm-currency.pipe";
import { Observable, Subject, zip } from "rxjs";

import checkProjectDates from "app/shared/base-functions/check-project-dates.base.function";
import { TranslatePipe } from "../../../../shared/pipes/translate.pipe";
import { PaymentCheckService } from "../../../../shared/services/payment-check.service";
import { CustomDateParserFormatter } from "../../../../shared/services/datepicker-adapter";
import { CheckProjectDates } from "../../../../shared/services/check-project-dates";
import { OverlayService } from "../../../../shared/services/overlayService";
import { ProdPartnerService } from "../../../../shared/services/prod-partner.service";
import { Authority, PermissionService } from "../../../../shared/services/permissions.service";
import { take, takeUntil } from 'rxjs/operators';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { ProjectWorkspaceUpdatedEvent } from 'app/shared/interfaces/workspace.interface';
import { CompanyService } from 'app/shared/services/company.service';
import { SubscriptionService } from 'app/subscriptions/services/subscription.service';
import { SubscriptionCheckService } from 'app/shared/services/subscription-check.service';
import { ProjectImageUpdatedEvent } from 'app/shared/interfaces';


@Component({
	selector: "mtm-project-company",
	templateUrl: "./project-company.component.html",
	styleUrls: [
		"./project-company.component.scss",
		"./project.company.component.scss",
	],
	providers: [MtmCurrencyPipe, TranslatePipe, { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class ProjectCompanyComponent implements OnInit, OnDestroy {
	@Input() isCompanyProfiles: boolean = false;
	@ViewChild("txtBudget", { static: false }) txtBudget: ElementRef;
	@ViewChild("uploadProgress", { static: true }) uploadProgress: ElementRef;

	get currentCurrency(): string {
		return this.projectCompanyForm?.value?.currency || this.project?.currency;
	}

	modal: NgbModalRef;
	thumbnail: any;
	sending: boolean = false;
	company = null;
	companySectors = [];
	companySubSectors = [];
	projectCompanyForm: UntypedFormGroup;
	submitted: boolean = false;
	allCommonsSectors: any[] = [];
	currencies: any[];
	types = [];
	allCompaniesName: any[] = [];
	allCompanies: any[] = [];
	tags: any[] = [];
	proposalAccepted: boolean = false;
	somePlaceholder: string = "00";
	subSectionMaxDate = null;
	subSectionMinDate = null;
	users: any[] = [];
	allLanguages: any[] = [];
	private noCompanyImg = "./assets/img/samples/No_Image_Available.png";
	private content: any = {};
	project: any;
	ngUnsubscribe = new Subject();
	currencySymbol: any;
	originalProjectCurrency: string;
	companyName: any;
	ranges = ["MINIMAL", "SMALL", "MEDIUM", "NORMAL", "BIG", "HUGE", "ENTERPRISE"];
	isNew: boolean = false; //determine if this is new project or existing project
	isReadMode: boolean = false; //determine if this is edit mode or read only
	isAdminUser: boolean = false;
	projectType: string = 'SUBSCRIPTION';
	isProducerSubscriptionProject: boolean = false;
	canEditWorkspace: boolean = false;
	imageVersion: string = new Date().getTime().toString();
	adminProjectSelect: boolean = false;

	profilesInfo = {
		uploadedForCompany: false,
		companyProfileImage: null,
		uploadedForProject: false,
		projectProfileImage: null,
	};

	private authUser;

	private budgets: any[] = [
		{ key: "MINIMAL", value: 1 },
		{ key: "SMALL", value: 2 },
		{ key: "MEDIUM", value: 3 },
		{ key: "NORMAL", value: 4 },
		{ key: "BIG", value: 5 },
		{ key: "HUGE", value: 6 },
		{ key: "ENTERPRISE", value: 7 },
	];
	isOpenBudgetDropdown: boolean = false;
	private selectedBudget: string;
	private workspaceId: string = '';
	workspaceName: string;
	companyWorkspaces: any[] = [];
	ghostCompany: any = null;
	hasLtgPermission: boolean = false;

	constructor(
		private api: ApiService,
		private modalService: NgbModal,
		private router: Router,
		public upService: UploadService,
		public service: ProjectService,
		private authService: AuthService,
		public userService: UserService,
		private fb: UntypedFormBuilder,
		private commonService: CommonService,
		private prodPartnerService: ProdPartnerService,
		private notificationService: NotificationService,
		private currencyPipe: MtmCurrencyPipe,
		private translatePipe: TranslatePipe,
		private paymentCheckService: PaymentCheckService,
		private overlayService: OverlayService,
		private permissionsService: PermissionService,
		private route: ActivatedRoute,
		private subscriptionService: SubscriptionService,
		private workspaceService: WorkspaceService,
		private companyService: CompanyService,
		private subscriptionCheckService: SubscriptionCheckService
	) {
	}

	ngOnInit() {
		this.hasLtgPermission = JSON.parse(localStorage.getItem('hasLtgPermission'));
		this.adminProjectSelect = this.route.snapshot.queryParams.adminProjectSelect == 'true';
		this.authUser = this.authService.getAuthUser();
		this.isNew = this.isNewProject();
		//if this is existing project, initialize in read only
		this.isReadMode = !this.isNew;
		this.isAdminUser = this.isAdmin();
		this.canEditWorkspace = (this.isAdminUser && !this.adminProjectSelect) || (!this.isNew && this.permissionsService.hasAuthority(Authority.S, null) || this.hasLtgPermission)
		this.checkWorkspaceId();
		this.createFORM();
		this.loadInitialDatas();
		if (this.isNew) {
			this.checkSubscription();
		}

		if (this.service.projectOnloaded) {
			if (this.service.project)
				this.project = this.service.project;
			this.checkSubscription();
			this.retrieveExistingWorkspaceName();
			this.init();
			this.checkProposalStatusAndPayment(this.service.project.id);
		}
		else
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((project: any) => {
				if (project.id) {
					this.project = project;
					this.originalProjectCurrency = project.currency;
					this.checkSubscription();
					this.retrieveExistingWorkspaceName();
					this.init();
					this.checkProposalStatusAndPayment(project.id);
				}
			});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	onEdit() {
		this.isReadMode = false;
		this.adjustFormControls();
	}

	private adjustFormControls() {
		if (this.isReadMode) {
			HelperService.setFormFieldsEnabled(this.projectCompanyForm, false);
		} else {
			HelperService.setFormFieldsEnabled(this.projectCompanyForm, true);
		}
	}

	isAdmin(): boolean {
		return this.permissionsService.hasAuthority(Authority.Z, null);
	}

	private isNewProject() {
		return window.location.pathname == '/projects/new/company';
	}

	private checkWorkspaceId() {
		if (this.isAdminUser && !this.adminProjectSelect)
			return;

		const isNew = this.isNew;

		if (!isNew)
			return;

		if (!('workspaceId' in this.route.snapshot.queryParams) && !this.hasLtgPermission) {
			this.router.navigate(['/projects']);
			return;
		}

		this.workspaceId = this.route.snapshot.queryParams.workspaceId;

		//TODO : check if workspaceId is part of current company workspace
		//add additional checking to ensure workspaceId is valid and part of current user

		this.workspaceService.getWorkspaces(this.authUser.company.id)
			.subscribe(workspaces => {
				const existing = workspaces.find(w => w.id == this.workspaceId);
				if (!existing && !this.hasLtgPermission) {
					this.router.navigate(['/projects']);
					return;
				}
				if (existing && existing.name) {
					this.workspaceName = existing.name;
				}
			});
	}


	private retrieveExistingWorkspaceName() {
		if (this.project && this.project.company && this.project.workspaceId) {
			this.workspaceService.getWorkspaces(this.project.company.id)
				.subscribe(workspaces => {
					const existing = workspaces.find(w => w.id == this.project.workspaceId);
					if (!existing && !this.hasLtgPermission) {
						this.router.navigate(['/projects']);
						return;
					}
					if (existing && existing.name) {
						this.workspaceName = existing.name;
					}
				});
		}
	}

	private warnNonPayingSubscriber() {
		const isPrincipal = this.permissionsService.hasAuthority(Authority.S, null);

		let instruction = isPrincipal ?
			this.translatePipe.transform('subscription_warning_locked_non_paying_principal_instruction') :
			this.translatePipe.transform('subscription_warning_locked_non_paying_user_instruction')

		let options: any = {};

		if (isPrincipal) {
			options = {
				title: this.translatePipe.transform('subscription_warning_locked_title').toString().toLocaleUpperCase(),
				question2: this.translatePipe.transform('subscription_warning_locked_non_paying_description') + '<br/>' +
					instruction,
				confirmBtn: this.translatePipe.transform('subscription_warning_upgrade_now'),
				cancelBtn: this.translatePipe.transform('cancel'),
				notificationType: 'success'
			};
		} else {
			options = {
				title: this.translatePipe.transform('subscription_warning_locked_title').toString().toLocaleUpperCase(),
				description: this.translatePipe.transform('subscription_warning_locked_non_paying_description') + '<br/>' +
					instruction,
				confirmBtn: this.translatePipe.transform('ok'),
			};
		}

		this.notificationService.open(options).subscribe((result) => {
			if (!isPrincipal) {
				this.router.navigate(['/projects']);
			} else {
				if (result) {
					this.router.navigate(['/subscription/upgrade']);
				} else {
					this.router.navigate(['/projects']);
				}
			}
		});
	}

	private warnExceedUsageSubscriber() {
		const isPrincipal = this.permissionsService.hasAuthority(Authority.S, null);

		let instruction = isPrincipal ?
			this.translatePipe.transform('subscription_warning_locked_exceed_principal_instruction') :
			this.translatePipe.transform('subscription_warning_locked_exceed_user_instruction')

		let options: any = {};

		if (isPrincipal) {
			options = {
				title: this.translatePipe.transform('subscription_warning_locked_title').toString().toLocaleUpperCase(),
				question2: this.translatePipe.transform('subscription_warning_locked_exceed_description') + '<br/>' +
					instruction,
				confirmBtn: this.translatePipe.transform('subscription_warning_upgrade_now'),
				cancelBtn: this.translatePipe.transform('cancel'),
				notificationType: 'success'
			};
		} else {
			options = {
				title: this.translatePipe.transform('subscription_warning_locked_title').toString().toLocaleUpperCase(),
				description: this.translatePipe.transform('subscription_warning_locked_exceed_description') + '<br/>' +
					instruction,
				confirmBtn: this.translatePipe.transform('ok'),
			};
		}

		this.notificationService.open(options).subscribe((result) => {
			if (!isPrincipal) {
				this.router.navigate(['/projects']);
			} else {
				if (result) {
					this.router.navigate(['/subscription/upgrade']);
				} else {
					this.router.navigate(['/projects']);
				}
			}
		});
	}

	private checkSubscription() {
		if (!this.isNew) {
			if (!this.project || this.project.projectType != 'SUBSCRIPTION') {
				return;
			}
		}

		if (this.permissionsService.hasAuthority(Authority.Z, null)) {
			return;
		}

		if (!this.authService.hasActiveSubscription()) {
			this.warnNonPayingSubscriber();
			return;
		}

		let project = this.project;
		if (!project) {
			project = this.projectCompanyForm.value;
		}

		this.subscriptionCheckService.checkProjectAccess(project)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((result: boolean) => {
				//console.log(`has project access:${result}`);
			});
	}

	private loadInitialDatas() {
		this.currencies = this.userService.getCurrencies();
		this.types = this.commonService.getProjectTypes();
		this.loadUsers();
		this.setUpLanguages();

		if (this.isAdminUser && !this.adminProjectSelect) {
			this.getAllCompanies();
		}

	}

	private loadUsers() {
		this.users = Array<any>();
		let getUserByUsername = () => {
			this.userService
				.findUserByUsername(this.authService.getAuthUserName(), false, true).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data) => {
					if (!this.users.find(u => u.username == data.username)) {
						this.users.push({
							username: data.username,
							fullName: data.fullName,
							email: data.email,
						});
						this.users.sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''));
					}
				});
		};

		getUserByUsername();

		const currentUsername = this.authService.getAuthUserName();

		if (this.service.projectOnloaded) {
			this.service.getProject(this.service.project.id).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data) => {
					const participants = data.participants.filter(user => user.username != currentUsername);
					participants.forEach((user) => {
						if (user.roles.indexOf('PROJECT_OWNER') != -1) {
							this.userService
								.findUserByUsername(user.username, false, true).pipe(
									takeUntil(this.ngUnsubscribe)
								)
								.subscribe((response) => {
									if (
										response.activationStatus ==
										"ACTIVE_COMPLETED" && !this.users.some(u => u.username == user.username)
									) {
										this.users.push({
											username: user.username,
											fullName: user.fullName,
											email: user.email,
										});
										this.users.sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''));
										/*
										if (user.username === this.authService.getAuthUserName()) {
											this.projectCompanyForm.controls[
												"manager"
											].setValue(data.manager);
										}*/
									}
								});
						}
					});
				},
				(err) => getUserByUsername()
			);
		}

	}

	//this needs proper api
	private loadGhostCompany() {
		if (this.ghostCompany)
			return;
		this.companyService.getAllCompanies().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((companies) => {
			const ghostCompany = companies.find(c => c.name == 'MTM Ghost');
			if (ghostCompany)
				this.ghostCompany = ghostCompany;
		});
	}

	private checkProposalStatusAndPayment(projectId: string) {
		if (projectId) {
			this.service.checkProposalAccepted(projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				if (res == true) {
					this.service.getProject(projectId).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(data => {
						if (!data.downPaymentPaid)
							this.paymentCheckService.downPaymentCheck(data);
						if (data.downPaymentPaid && !data.finalPaymentPaid)
							this.paymentCheckService.finalPaymentCheck(data);
						else
							return;
					});
				} else
					return;
			});
		}
	}

	private setUpLanguages() {
		this.commonService.getLanguages().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this.allLanguages = data; // id: lang.abbreviation, name: lang.name
		});
	}

	private getSortResult(x: any, y: any): number {
		x = x.toLocaleLowerCase();
		y = y.toLocaleLowerCase();

		if (x < y) return -1;
		else if (x > y) return 1;
		else return 0;
	}

	private getAllCompanies() {
		this.service.getCompanies().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((companies) => {
			companies.forEach((c) => {
				this.allCompaniesName.push(c.name);
			});
			this.allCompanies = companies;
			this.allCompanies.sort((a, b) => a.name.localeCompare(b.name))
			// this.allCompanies.unshift({id: null, name: "	"});
		});
	}
	private init(): void {
		this.loadUsers();
		if (this.service.project.company) {
			this.service
				.getCompany(this.service.project.company.id).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data) => {
					this.service.project.company = data;
					this.companyName = data.name;
					if (data.avatarUrl) {
						this.profilesInfo.companyProfileImage = data.avatarUrl;
						this.refreshImageVersion();
					}
					else
						this.profilesInfo.companyProfileImage = this.noCompanyImg;

					if (this.service.project.avatarUrl)
						this.profilesInfo.projectProfileImage =
							this.service.project.avatarUrl +
							"?" +
							new Date().getTime();
					this.createFORM();
				});
		}

		if (!this.authUser.isAdmin && this.proposalAccepted == false) {
			this.checkProposalAccepted();
		}
		const output = [];
		if (this.service.sections) {
			Array.from(this.service.sections).forEach(section => {
				if (section.subsections) {
					section.subsections.forEach(subSection => {
						output.push(DateHelperService.fromTimestampToDate(subSection.endDate))
						output.push(DateHelperService.fromTimestampToDate(subSection.startDate))
					})
				}
			})
			const max = Math.max.apply(null, output.filter(val => val !== null));
			const min = Math.min.apply(null, output.filter(val => val !== null));
			if (max.length) {
				let tempMaxDate: Date = DateHelperService.fromTimestampToDate(Math.max.apply(null, output.filter(val => val !== null)));
				tempMaxDate.setDate(tempMaxDate.getDate() + 1);
				this.subSectionMaxDate = DateHelperService.fromDateToDatepicker(tempMaxDate);
			}
			if (min.length) {
				let tempMinDate: Date = DateHelperService.fromTimestampToDate(Math.min.apply(null, output.filter(val => val !== null)))
				tempMinDate.setDate(tempMinDate.getDate() + 1)
				this.subSectionMinDate = DateHelperService.fromDateToDatepicker(tempMinDate);
			}
		}
	}

	getContent(projectId: string) {
		this.content = {};
		if (projectId) {
			this.prodPartnerService.getProdPartnerContent(projectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(value => {
				this.content = value;
			})
		}
	}

	private createFORM() {
		let company = this.service.project.company
			? this.service.project.company
			: {};

		if (this.isNew && !(this.isAdminUser && !this.adminProjectSelect)) {
			const authCompany = this.authUser.company;
			company = {
				id: authCompany.id,
				companyType: authCompany.companyType,
				name: authCompany.name,
				sector: null,
				description: authCompany.description
			};
			this.companyName = company.name;
			this.service.project.company = company;
			this.projectType = 'SUBSCRIPTION';
			this.service
				.getCompany(authCompany.id).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data) => {
					company.avatarUrl = data.avatarUrl;
					if (data.avatarUrl) {
						this.profilesInfo.companyProfileImage = data.avatarUrl;
						this.refreshImageVersion();
					} else {
						this.profilesInfo.companyProfileImage = this.noCompanyImg;
					}


					if (this.service.project.avatarUrl)
						this.profilesInfo.projectProfileImage = this.service.project.avatarUrl + "?" + new Date().getTime();

					if (data.sector && data.subSector) {
						company.sector = data.sector;
						company.subsector = data.subSector;
					}

				});
			if (authCompany.companyType == 'PRODUCER') {
				this.isProducerSubscriptionProject = true;
			}
		}

		if (this.isNew && this.isAdminUser && !this.adminProjectSelect) {
			this.projectType = null;
		}

		if (!this.isNew) {
			this.projectType = this.service.project ? this.service.project.projectType : 'SUBSCRIPTION';
			if (this.canEditWorkspace) {
				this.populateCompanyWorkspaces(company.id);
			}

		}

		if (this.hasLtgPermission) {
			this.populateCompanyWorkspaces(company.id);
		}

		let project = this.service.project ? this.service.project : {};
		this.getContent(project.id);
		const { hours, minutes, seconds } = HelperService.fromSecondsToTime(
			this.service.project.videoLength
		);

		let budget = project.budgetRange ? this.translatePipe.transform("budgetRange" + project.budgetRange + (project.currency ? '_' + project.currency : '')) : undefined
		if (!isNaN(project.budget)) {
			budget = project.budget
		}

		if (!budget) {
			budget = this.translatePipe.transform("budgetRangeMINIMAL" + (project && project.currency ? '_' + project.currency : ''));
		}

		let range = Object.assign([], this.content.format);
		this.currencySymbol = project.currency || this.authService.getAuthUserSettings().currency;
		const groupOptions: any = {
			companyId: [company.id],
			companyType: [company.companyType],
			clientCompany: [{ value: company.name, disabled: !this.canEditCompany() }],
			projectName: [project.name, [Validators.required]],
			description: [project.description, [Validators.required]],
			videoGenre: [project.videoGenre ? project.videoGenre : (range[0] !== undefined && range !== undefined && range.length == 1 ? this.types.find(value => (value.label === range[0])).value : ''), [Validators.required]],
			videoGenreOther: [project.videoGenreOther],
			tags: [project.tags ? project.tags : []],
			manager: [project.manager, [Validators.required]],
			budget: [
				budget,
				[Validators.required],
			],
			validationMode: [
				project.validationMode ? project.validationMode : "ALL",
				[Validators.required],
			],
			currency: [project.currency || this.authService.getAuthUserSettings().currency, [Validators.required]],
			hoursVideoLength: [
				hours > 0 ? hours : "",
				[
					Validators.required,
					ValidatorService.isInteger,
					ValidatorService.isGreaterOrEqualsToZero,
				],
			],
			minutesVideoLength: [
				minutes > 0 ? minutes : "",
				[
					Validators.required,
					ValidatorService.isInteger,
					ValidatorService.isGreaterOrEqualsToZero,
				],
			],
			secondsVideoLength: [
				seconds > 0 ? seconds : "",
				[
					Validators.required,
					ValidatorService.isInteger,
					ValidatorService.isGreaterOrEqualsToZero,
				],
			],
			startDate: [
				DateHelperService.fromDateToDatepicker(
					this.service.project.startDate
				),
				[Validators.required],
			],
			plannedEndDate: [
				DateHelperService.fromDateToDatepicker(
					this.service.project.plannedEndDate
				),
				[Validators.required],
			],
			airDate: [
				DateHelperService.fromDateToDatepicker(
					this.service.project.airDate
				),
				[Validators.required],
			],
			languages: [project.languages ? project.languages : []],
			subTitleLanguages: [
				project.subTitleLanguages ? project.subTitleLanguages : [],
			],
			dubbingLanguages: [
				project.dubbingLanguages ? project.dubbingLanguages : [],
			]
		}

		if (this.isNew && this.isAdminUser && !this.adminProjectSelect) {
			groupOptions.companyName = [company.name, [Validators.required]];
			groupOptions.projectType = [null];
		} else {
			groupOptions.companyName = [company.name];
			groupOptions.projectType = [project.projectType ? project.projectType : this.projectType];
		}
		groupOptions.workspaceId = [project.workspaceId ? project.workspaceId : this.workspaceId, [Validators.required]];

		this.projectCompanyForm = this.fb.group(groupOptions);

		this.projectCompanyForm.get('currency').valueChanges.subscribe((val) => {
			if (this.project) {
				this.project.currency = val;
			}
			this.currencySymbol = val;
			this.autoSavedFormValue();
		});

		this.adjustFormControls();
	}

	isValid(field: string): boolean {
		return this.projectCompanyForm.get(field).invalid && this.submitted;
	}

	isValidDate(field: string): boolean {
		let result = checkProjectDates(
			this.projectCompanyForm.get("startDate").value,
			this.projectCompanyForm.get("plannedEndDate").value,
			this.projectCompanyForm.get("airDate").value
		);

		if (!result) {
			this.projectCompanyForm.controls["plannedEndDate"].setErrors({
				incorrect: true,
			});
			this.projectCompanyForm.controls["airDate"].setErrors({
				incorrect: true,
			});
		} else {
			this.projectCompanyForm.controls["plannedEndDate"].setErrors(null);
			this.projectCompanyForm.controls["airDate"].setErrors(null);
		}

		return (
			this.projectCompanyForm.get(field).invalid &&
			(this.submitted ||
				(this.service.project && this.service.project.id))
		);
	}

	sourceResult(clientCompanyName) {
		let index = this.allCompanies.findIndex(
			(k) => k.name.toLowerCase() === clientCompanyName.toLowerCase()
		);
		if (index >= 0) {
			let selectedCompany = this.allCompanies[index];
			this.projectCompanyForm.controls["companyId"].setValue(selectedCompany.id);
			this.projectCompanyForm.controls["companyType"].setValue(selectedCompany.companyType);

			setTimeout(() => {
				this.profilesInfo.companyProfileImage = selectedCompany.avatarUrl;
			}, 0);

			this.populateCompanyWorkspaces(selectedCompany.id);

			this.service.project.company = {
				id: selectedCompany.id,
				companyType: selectedCompany.companyType,
				name: selectedCompany.name,
				description: selectedCompany.description
			};

			//reload full company data upon selection change
			this.service
				.getCompany(selectedCompany.id).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data) => {
					this.service.project.company = data;
					this.companyName = data.name;
					if (data.avatarUrl)
						this.profilesInfo.companyProfileImage = data.avatarUrl;
					else
						this.profilesInfo.companyProfileImage = this.noCompanyImg;

					if (this.service.project.avatarUrl)
						this.profilesInfo.projectProfileImage =
							this.service.project.avatarUrl +
							"?" +
							new Date().getTime();
				});


		} else {
			this.projectCompanyForm.controls["companyId"].setValue(null);
		}
	}

	private populateCompanyWorkspaces(companyId) {
		this.companyWorkspaces = [];
		if (companyId) {
			this.workspaceService.getWorkspaces(companyId)
				.subscribe(workspaces => {
					if (workspaces) {
						this.companyWorkspaces = workspaces.sort((a, b) => a.name.localeCompare(b.name));
					} else {
						this.companyWorkspaces = [];
					}

				});
		}


	}

	/**
	 * Used to send image to second cropper
	 * @param $event
	 */
	fileChangeListener($event, isCompanyOrBranch): void {
		var files = [];
		if (!$event.event) {
			files = $event.files[0];
			if (files) {
				this.openModal();
				this.modal.componentInstance.imageFile = files;
				this.setProfileImage(files, isCompanyOrBranch);
			}
		}
		else {
			files = $event.event.target.files;
			if (files.length) {
				this.openModal();
				this.modal.componentInstance.imageChangedEvent = $event.event;
				this.setProfileImage(files[0], isCompanyOrBranch);
			}
		}


	}

	private setImageOnModal(
		that: any,
		imageBase64: any,
		isCompanyOrBranch: string
	) {
		let image: any = new Image();
		// image.src = imageBase64;
		let c = that.modal.componentInstance.cropper;
		// c.setImage(image);
		let res;

		// Subscribe to modal event
		that.modal.componentInstance.uploadObserver.pipe(
			takeUntil(that.ngUnsubscribe)
		).subscribe((result) => {
			if (result) {
				that.uploading = true;
				that.thumbnail = result;
				//res = result;
				if (isCompanyOrBranch == "COMPANY") {
					that.profilesInfo.companyProfileImage = result;
					that.autoSavedFormValue();
				} else {
					that.profilesInfo.projectProfileImage = result;
					//that.onSubmit(null, true);
				}
			}
		});
	}

	private openCropModal(isProject?) {
		this.modal = this.modalService.open(UploadModalComponent, {
			size: "lg",
		});
		this.modal.componentInstance.setCrop(isProject);
		if (isProject) {
			this.modal.componentInstance.isCreateProject = true;
		}
	}

	private setProfileImage(file, isCompanyOrBranch: string) {
		let that = this;
		let fileReader = new FileReader();
		let setImageOnModal = this.setImageOnModal;
		// this.openModal();
		fileReader.onload = () => {
			if (isCompanyOrBranch == "COMPANY") {
				this.profilesInfo.companyProfileImage = fileReader.result;
				this.profilesInfo.uploadedForCompany = true;
				// this.openCropModal(false);
			}
			if (isCompanyOrBranch == "PROJECT") {
				this.profilesInfo.projectProfileImage = fileReader.result;
				this.profilesInfo.uploadedForProject = true;
				// this.openCropModal(true);
			}
		};
		fileReader.onloadend = function (loadEvent: any) {
			if (isCompanyOrBranch == "COMPANY") {
				setImageOnModal(
					that,
					file,
					isCompanyOrBranch
				);
			}
			if (isCompanyOrBranch == "PROJECT") {
				setImageOnModal(
					that,
					file,
					isCompanyOrBranch
				);
			}

		};
		fileReader.readAsDataURL(file);
	}

	/**
	 * Open crop modal
	 */
	private openModal() {
		this.modal = this.modalService.open(UploadModalComponent, { size: 'lg' });
		this.modal.componentInstance.setCrop(false);
	}

	private returnToProject() {
		this.router.navigate(["projects", this.service.project.id]);
	}


	onSaveAndContinue(isSubmit) {
		setTimeout(() => {
			if (isSubmit) this.onSubmit();
		}, 350);
	}

	setBudget(formValue) {
		let enumValueIdx = this.getEnumValueIdx(formValue.budget);
		if (enumValueIdx >= 0) {
			formValue.budget = this.ranges[enumValueIdx];
		} else {
			formValue.budget = (formValue.budget || '').toString().replace(/[^0-9\.-]+/g, "");
		}
	}

	public onSubmit(onCompletedCallback?: Function, isAutoSaved?: boolean): void {
		if (!CheckProjectDates.checkProjectStartDateEndDate(this.projectCompanyForm.controls["startDate"].value, this.projectCompanyForm.controls["plannedEndDate"].value)) {
			if (!isAutoSaved) {
				this.overlayService.showError(this.translatePipe.transform("overlaySubsectionDateError"));
			}
			return;
		} else if (!CheckProjectDates.checkProjectEndDateAirDate(this.projectCompanyForm.controls["plannedEndDate"].value, this.projectCompanyForm.controls["airDate"].value)) {
			if (!isAutoSaved) {
				this.overlayService.showError(this.translatePipe.transform("overlaySubsectionDateError2"));
			}
			return;
		}


		if (this.sending)
			return;

		this.submitted = true;
		let canChangeCompany = this.canEditCompany();

		if (
			this.projectCompanyForm.value.type == "OTHER" &&
			(!this.projectCompanyForm.value.videoGenreOther ||
				this.projectCompanyForm.value.videoGenreOther == "")
		)
			return;

		this.setVideoLengthValues();
		if (this.projectCompanyForm.invalid && !isAutoSaved) {
			this.overlayService.showError(this.translatePipe.transform('mandatoryFieldRequired'), 'Error');
			return;
		}

		this.sending = true;
		let formValue: any = Object.assign({}, this.projectCompanyForm.value);

		formValue = !this.isAdminUser
			? {
				...formValue,
				companyName: this.projectCompanyForm.controls["companyName"]
					.value,
			}
			: formValue;

		if (canChangeCompany) {
			formValue.workspaceId = this.projectCompanyForm.controls['workspaceId'].value;
		}

		// create milestone project fetching drive campaign id  and milestone id
		if ('dcId' in this.route.snapshot.queryParams && 'mId' in this.route.snapshot.queryParams && 'isdc' in this.route.snapshot.queryParams) {
			formValue.campaignId = this.route.snapshot.queryParams.dcId;
			formValue.milestoneId = this.route.snapshot.queryParams.mId;
			formValue.isDriveCampaign = this.route.snapshot.queryParams.isdc;
		}
		this.checkValidBudget(formValue);

		const $observables = [];
		$observables.push(this.createProject(formValue));
		//this.setupProjectSaving($observables, formValue);

		//disable company data change for now
		// if (canChangeCompany) {
		// 	$observables.push(
		// 		this.createOrUpdateCompany(formValue)
		// 	);
		// }

		zip(...$observables).pipe(take(1))
			.subscribe({
				next: data => {
					let projectResult = data[0];
					let companyResult = null;
					if ($observables.length > 1)
						companyResult = data[1];
					this.handleProjectSaved(projectResult, companyResult, isAutoSaved, onCompletedCallback);
					if (companyResult) {
						this.handleCompanySaved(companyResult);
					}


				},
				error: () => {
					this.sending = false;
				}
			});
	}

	private setupProjectSaving($observables, formValue) {
		let dataToSave = Object.assign({}, formValue);

		if (this.isProducerSubscriptionProject) {
			dataToSave.prodCompanyId = formValue.companyId;
			dataToSave.companyId = this.ghostCompany.companyId;
			dataToSave.company = this.ghostCompany;
		}

		$observables.push(this.createProject(dataToSave));
	}

	private handleCompanySaved(companyResult: any) {
		let index = this.allCompaniesName.findIndex(
			(company) => company.name != companyResult.name
		);
		if (index < 0) {
			this.allCompaniesName.push(companyResult.name);
			this.allCompanies.push(companyResult);
		} else
			this.allCompanies[index] = {
				...this.allCompanies[index],
				...companyResult,
			};

		this.service.project.company = companyResult;
	}

	private handleProjectSaved(projectResult: any, companyResult: any, isAutoSaved: boolean, onCompletedCallback?: Function) {
		if (this.service.project.id)
			this.service.project = {
				...this.service.project,
				...projectResult,
			};
		else {
			this.service.project = projectResult;

			if (companyResult)
				this.service.project.company = companyResult;
		}
		this.userService
			.getUserByUsername(
				this.authService.getAuthUserName()
			).pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe({
				next: (data: any) => {
					this.sending = false;
					this.authService.setAuthUser(data);
					this.authService.updateAllowedOperations(this.service.project.id).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(() => {
						if (this.canEditWorkspace) {
							EmitterService.get(ProjectWorkspaceUpdatedEvent)
								.emit({});
						}
						!onCompletedCallback
							? this.onContinue(isAutoSaved)
							: onCompletedCallback();
					})
				},
				error: (err: any) => {
					this.sending = false;
					this.notificationService.open({
						title: "Project",
						description: `Error saving project:\n${err.message}\n`,
						confirmBtn: "Accept",
					});
					!onCompletedCallback
						? this.onContinue(isAutoSaved)
						: onCompletedCallback();
				}
			});

	}

	private createOrUpdateCompany(data: any): Observable<any> {
		return new Observable((observe) => {
			let company = {
				id: data.companyId,
				name: data.companyName,
				sector: data.sector,
				subSector: data.subSector,
				companyType: data.companyType
			};
			let project = {
				...this.service.project,
				name: data.projectName,
				description: data.description,
				videoGenreOther: data.videoGenreOther,
				tags: data.tags,
				manager: data.manager,
				videoGenre: data.videoGenre
			}

			if (data.companyName) {
				let index = this.allCompanies.findIndex(
					(k) => k.name.toLowerCase() == data.companyName.toLowerCase()
				);
				if (index >= 0) {
					company.name = this.allCompanies[index].name;
					company = {
						...this.allCompanies[index],
						...company,
					};
				}
			}

			if (!this.canEditCompany()) {
				observe.next(this.service.project.company)
			} else {
				this.service.createCompany(company).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(newCompany) => {
						if (!this.profilesInfo.uploadedForCompany) {
							observe.next(newCompany);
							return;
						}

						this.uploadAvatarImage(
							this.profilesInfo.companyProfileImage,
							"/api/companies/" + newCompany.id + "/avatar"
						).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(
							(resAvatarURL) => {
								newCompany.avatarUrl = resAvatarURL;
								observe.next(newCompany);
							},
							(err) => observe.next(newCompany)
						);
					},
					(err) => observe.error()
				);
			}
		});
	}

	canEditCompany() {
		if (!this.service.project.company) {
			return true;
		}

		if (this.permissionsService.hasAuthority(Authority.Z, null))
			return true;

		return this.authService.getAuthUser().companyId == this.service.project.company.id
			&& this.permissionsService.hasAuthority(Authority.E, null);
	}

	private createProject(data: any): Observable<any> {
		return new Observable((observer) => {
			data.startDate = DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(data.startDate)
			);
			data.plannedEndDate = DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(data.plannedEndDate),
				DateHelperService.END_OF_DAY
			);
			data.airDate = DateHelperService.fromDateToApi(
				DateHelperService.fromDatePickerToDate(data.airDate),
				DateHelperService.END_OF_DAY
			);

			data.videoLength = HelperService.fromTimeToSeconds(
				data.hoursVideoLength,
				data.minutesVideoLength,
				data.secondsVideoLength
			);

			data.name = data.projectName;

			data = {
				...this.service.project,
				...data,
			};


			this.service.saveProject(data).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(newProject) => {
					this.project = newProject;

					if (!this.profilesInfo.uploadedForProject) {
						observer.next(newProject);
						return;
					}

					EmitterService.get('project.saved').emit(newProject);
					this.originalProjectCurrency = newProject.currency;
					this.uploadAvatarImage(
						this.profilesInfo.projectProfileImage,
						"/api/projects/" + newProject.id + "/avatar"
					).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						(resAvatarURL) => {
							newProject.avatarUrl = resAvatarURL;
							EmitterService.get(ProjectImageUpdatedEvent).emit({
								projectId: newProject.id
							});
							observer.next(newProject);
						},
						(err) => observer.next(newProject)
					);
				},
				(err) => observer.error()
			);
		});
	}

	private uploadAvatarImage(image, partialURL): Observable<any> {
		return new Observable((observer) => {
			this.upService
				.uploadImage(image, this.api.baseUrl + partialURL).pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe(
					(resAvatar) => {
						if (!resAvatar.avatarUrl) return;

						observer.next(resAvatar.avatarUrl);
					},
					(err) => {
						observer.error();
					}
				);
		});
	}

	/**
	 * On continue
	 */
	private onContinue(isAutoSaved) {
		if (isAutoSaved) {
			return;
		}
		this.router.navigate([
			"projects",
			"edit",
			this.service.project.id,
			"stages",
		]);
	}

	onSelectSector(selectedSector: any) {
		this.companySubSectors = [];
		let filteredSubSector = this.allCommonsSectors.filter(
			(k) => k.industrycode == selectedSector
		);

		filteredSubSector.forEach((x) => {
			if (
				this.companySubSectors.findIndex(
					(k) => k.id == x.subindustrycode
				) < 0
			)
				this.companySubSectors.push({
					id: x.subindustrycode,
					name: x.subindustryname,
				});
		});

		if (
			this.companySubSectors.length > 0 &&
			this.companySubSectors.findIndex(
				(k) =>
					k.id == this.projectCompanyForm.controls["subSector"].value
			) < 0
		)
			this.projectCompanyForm.controls["subSector"].setValue(
				this.companySubSectors[0].id
			);

		this.companySubSectors.sort((a, b) => a.name.localeCompare(b.name));
	}

	getProjectTypeLabel(projectType) {
		if (!projectType)
			return 'Admin';

		switch (projectType) {
			case 'ONE_SHOT':
				return 'One Shot';
			default:
				return 'Subscription';
		}
	}

	isDateDefined(formDate: any): boolean {
		return !(
			formDate &&
			isNaN(formDate.day) &&
			isNaN(formDate.month) &&
			isNaN(formDate.year)
		);
	}

	public arrangeValidators(decisionChoice) {
		if (decisionChoice == "ONE") {
			this.service.project.participants.forEach((p) => {
				if (p.roles.includes("PROJECT_OWNER"))
					this.service.project.validators.push(p.username);
				else {
					let index = this.service.project.validators.findIndex(
						(v) => v == p.username
					);
					if (index > -1)
						this.service.project.validators.splice(index, 1);
				}
			});
		} else {
			this.service.project.participants.forEach((p) => {
				if (
					p.roles.includes("PROJECT_OWNER") ||
					p.roles.includes("PROJECT_MANAGER")
				) {
					this.service.project.validators.push(p.username);
				}
			});
		}
	}

	switchBudgetDropdown() {
		setTimeout(() => {
			this.isOpenBudgetDropdown = !this.isOpenBudgetDropdown;
		});
	}

	public getEnumValueIdx(value) {
		let idx = this.ranges.indexOf(value);
		if (idx < 0) {
			for (let i = 0; i < this.ranges.length; i++) {
				let currencies = this.userService.getCurrencies();
				for (let j = 0; j < currencies.length; j++) {
					const rangeValue = this.translatePipe.transform("budgetRange" + this.ranges[i] + (currencies[j] ? '_' + currencies[j].id : ''));
					if (rangeValue == value) {
						idx = i;
						break;
					}
				}
			}
		}
		return idx;
	}
	//try to prevent budget error in backend
	checkValidBudget(formValue) {
		//let formValue: any = Object.assign({}, this.projectCompanyForm.value);
		let enumValueIdx = this.getEnumValueIdx(formValue.budget);
		if (enumValueIdx >= 0) {
			formValue.budget = this.ranges[enumValueIdx];
			//formValue.budget = this.translatePipe.transform("budgetRange" + this.ranges[enumValueIdx] + (this.currencySymbol ? '_' + this.currencySymbol : ''));
		} else {
			let numberValue = Number((formValue.budget || '').toString().replace(/[^0-9\.-]+/g, ""));
			formValue.budget = numberValue;
			//formValue.budget = (this.currencySymbol === 'EUR' ? '€' : '$') + (numberValue || 0);
		}
	}

	onChangeProjectBudget() {
		const value = this.projectCompanyForm.controls["budget"][
			"_pendingValue"
		];
		const foundItem = this.budgets.find((k) => k.key == value);

		if (
			value != "" &&
			((!foundItem && !/^\d+$/.test(value)) ||
				value == "0" ||
				Number(value) == 0)
		) {
			this.projectCompanyForm.controls["budget"].setValue("");
		}
	}

	onSelectedBudgetFromList(item) {
		if (item) {
			this.selectedBudget = item;
			this.projectCompanyForm.controls["budget"].setValue(item.key);
			this.switchBudgetDropdown();
		}
	}

	private checkProposalAccepted() {
		this.service
			.checkProposalAccepted(this.service.project.id).pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe((res) => {
				this.proposalAccepted = res;
			});
	}

	private setVideoLengthValues() {
		if (this.projectCompanyForm.controls["hoursVideoLength"].value === "") {
			this.projectCompanyForm.controls["hoursVideoLength"].setValue(0);
		}
		if (
			this.projectCompanyForm.controls["minutesVideoLength"].value === ""
		) {
			this.projectCompanyForm.controls["minutesVideoLength"].setValue(0);
		}
		if (
			this.projectCompanyForm.controls["secondsVideoLength"].value === ""
		) {
			this.projectCompanyForm.controls["secondsVideoLength"].setValue(0);
		}
	}

	autoSavedFormValue() {
		if (!this.isNew) {
			return;
		}

		let formValue: any = Object.assign({}, this.projectCompanyForm.value);
		if (!formValue.companyName) return;

		if (!this.project) {
			this.project = {};
		}

		this.onSubmit(void 0, true);
	}

	onCancelChanges() {
		this.isReadMode = true;

		const { hours, minutes, seconds } = HelperService.fromSecondsToTime(
			this.service.project.videoLength
		);

		const project = this.project;
		this.currencySymbol = this.originalProjectCurrency;
		let budget = project.budgetRange;
		if (!isNaN(project.budget)) {
			budget = project.budget;
		}

		let range = Object.assign([], this.content.format);

		const patchValue: any = {
			projectName: project.name,
			description: project.description,
			currency: this.originalProjectCurrency,
			videoGenre: project.videoGenre ? project.videoGenre : (range[0] !== undefined && range !== undefined && range.length == 1 ? this.types.find(value => (value.label === range[0])).value : ''),
			videoGenreOther: project.videoGenreOther,
			tags: project.tags ? project.tags : [],
			budget: budget,
			hoursVideoLength: hours > 0 ? hours : "",
			minutesVideoLength: minutes > 0 ? minutes : "",
			secondsVideoLength: seconds > 0 ? seconds : "",
			startDate:
				DateHelperService.fromDateToDatepicker(
					this.service.project.startDate
				),
			plannedEndDate:
				DateHelperService.fromDateToDatepicker(
					this.service.project.plannedEndDate
				),
			airDate:
				DateHelperService.fromDateToDatepicker(
					this.service.project.airDate
				),
			languages: project.languages ? project.languages : [],
			subTitleLanguages:
				project.subTitleLanguages ? project.subTitleLanguages : [],
			dubbingLanguages: project.dubbingLanguages ? project.dubbingLanguages : []
		};
		this.projectCompanyForm.patchValue(patchValue,);
		this.profilesInfo.projectProfileImage = this.service.project.avatarUrl + "?" + new Date().getTime();
		//trigger blur event handler on MtmCurrencyDirective
		try {
			this.txtBudget.nativeElement.dispatchEvent(new Event('blur'));
		} catch (e) {

		}
		this.profilesInfo.uploadedForProject = false;
		this.adjustFormControls();

	}

	onSaveChanges() {
		this.onSubmit(() => {
			this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'));
		});
	}

	private refreshImageVersion() {
		this.imageVersion = new Date().getTime().toString();
	}
}
