import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { ApiService } from './api.service';
import { AnalyticsParams } from '../interfaces';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  authUser: any;
  isHrUser: boolean = false;
  isYslUser: boolean = false;
  private allSubjectsEmitted = false;
  selectedBrand: BehaviorSubject<any> = new BehaviorSubject(false);
  // // brands
  // public selectedBrands = new BehaviorSubject([]);
  // public selectedBrandsData = this.selectedBrands.asObservable();
  // //workspaces
  // public selectedWorkspaces = new BehaviorSubject([]);
  // public selectedWorkspacesData = this.selectedWorkspaces.asObservable();
  // // projects
  // public selectedProjects = new BehaviorSubject([]);
  // public selectedProjectsData = this.selectedProjects.asObservable();
  // // priorities
  // public selectedPriority = new BehaviorSubject([]);
  // public selectedPriorityData = this.selectedProjects.asObservable();
  public selectedBrands = new BehaviorSubject<any[]>([]);
  public selectedWorkspaces = new BehaviorSubject<any[]>([]);
  public selectedProjects = new BehaviorSubject<any[]>([]);
  public selectedPriority = new BehaviorSubject<any[]>([]);

  public selectedComponent = new BehaviorSubject<any>('');

  // Combine BehaviorSubjects and map to return only id for brands, workspaces, and projects
  public allData$: Observable<{ brands: any[] | any; workspaces: any[] | any; driveCamapigns: any[] | any; priority: any[]; }> = combineLatest([
    this.selectedBrands.asObservable(),
    this.selectedWorkspaces.asObservable(),
    this.selectedProjects.asObservable(),
    this.selectedPriority.asObservable()
  ]).pipe(
    map(([brands, workspaces, driveCamapigns, priority]) => ({
      brands: (Array.isArray(brands) ? brands.map((item: any) => (item && item.id) || null) : (brands && [(brands as any).id])) || null,
      workspaces: (Array.isArray(workspaces) ? workspaces.map((item: any) => (item && item.name) || null) : (workspaces && (workspaces as any).name)) || null,
      driveCamapigns: (Array.isArray(driveCamapigns) ? driveCamapigns.map((item: any) => (item && item.id) || null) : (driveCamapigns && (driveCamapigns as any).id)) || null,
      priority
    }))
  );

  constructor(
    private authService: AuthService,
    private apiService: ApiService
    ) { }

  // setBrands(brands: any) {
  //   console.log('selected brands', brands)
  //   this.selectedBrands.next(brands);
  // }

  // setWorkspaces(workspaces: any) {
  //   console.log('selected workspaces', workspaces)
  //   this.selectedWorkspaces.next(workspaces);
  // }

  // setProjects(projects: any) {
  //   this.selectedProjects.next(projects);
  // }

  // setPriorities(priority:any){
  //   this.selectedPriority.next(priority)
  // }
  // brandIds
  // workspaceNames (for Axe)
  // type (type of the Milestone)
  // projectsSummary (set to true, then it will include the project list).
  // get analytics
  getAnalytics(args?:AnalyticsParams,brandIds?: any, workspaces?: any) {
    let url = `/api/loreal-timeline/drive-campaigns`;
    const urlParams = [];
		if (args.projectsSummary) {
			urlParams.push('projectsSummary=' + encodeURIComponent(args.projectsSummary));
		}
		if (args.type) {
			urlParams.push('milestoneTypes=' + args.type);
		}
    if (workspaces) {
			urlParams.push('workspaceNames=' + workspaces);
		}
    if (brandIds) {
			urlParams.push('brandIds=' + brandIds);
		}
    if (args.workspaceNames) {
			urlParams.push('workspaceNames=' + args.workspaceNames);
		}
    if (args.brandIds) {
			urlParams.push('brandIds=' + args.brandIds);
		}
    if (args.driveCamapigns) {
			urlParams.push('campaignIds=' + args.driveCamapigns);
		}
    if (args.priorities) {
			urlParams.push('priorities=' + args.priorities);
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
    return this.apiService.httpGet(url);
  }

  createBrand(data){
    return this.apiService.httpPost('/api/brands',data);
  }
  
  getBrands(){
    return this.apiService.httpGet('/api/brands');
  }
  getBrandsByCompanyId(companyId){
    return this.apiService.httpGet(`/api/brands/${companyId}`);
  }
  getBrandsByCompany(){
    return this.apiService.httpGet(`/api/brands/${this.authUser.companyId}`);
  }

  getBrandsById(brandId:string){
    return this.apiService.httpGet(`/api/brands/${this.authUser.companyId}/${brandId}`);
  }

  getHeaderMilestone() {
    return this.apiService.httpGet(`/api/loreal-timeline/master-data/milestone-analytics-headers`);
  }

  createCustomnAlyticsVisualization(data) {
    return this.apiService.httpPost(`/api/loreal-timeline/drive-campaigns/custom-views`, data);
  }

  createCampaign(data){
    return this.apiService.httpPost(`/api/loreal-campaign`, data);
  }

  getCamapigns(workspaceId: string, archived: boolean = true): Observable<any>{
    let url =`/api/loreal-campaign/${workspaceId}`;
    let urlParams =[];
    if (archived || !archived) {
			urlParams.push(`isArchived=${archived}`);
		}
		url += '?' + urlParams.join('&');
    return this.apiService.httpGet(url);
    // return this.apiService.httpGet(`/api/loreal-campaign/${workspaceId}`);
  }

  getCampaignById(campaignId: string): Observable<any>{
    return this.apiService.httpGet(`/api/loreal-campaign/byId/${campaignId}`);
  }

  getAllCampaigns(archived: boolean = true){
    let url =`/api/loreal-campaign`;
    let urlParams =[];
    if (archived || !archived) {
			urlParams.push(`isArchived=${archived}`);
		}
		url += '?' + urlParams.join('&');
    return this.apiService.httpGet(url);
  }

  getFavoriteItem(type:string){
    let brandId = localStorage.getItem('brandId')
    return this.apiService.httpGet(`/api/brand-favorite?brandId=${brandId}&type=${type}`);
  }

  markAsFavorite(item :any, type: string) {
    let brandId = localStorage.getItem('brandId')
    let itemsId=[];
    if (item) {
      itemsId.push(item.id)
    }
    return this.apiService.httpPostWithoutBody(`/api/brand-favorite?brandId=${brandId}&items=${itemsId}&type=${type}`)
  }

  removeAsFavorite(item: any, type: string) {
    let brandId = localStorage.getItem('brandId')
    let itemsId=[];
    if (item) {
      itemsId.push(item.id)
    }
    return this.apiService.httpDelete(`/api/brand-favorite?brandId=${brandId}&items=${itemsId}&type=${type}`)
  }

  deleteCampaign(item){
    return this.apiService.httpPost(`/api/loreal-campaign/${item.workspaceId}/${item.id}`,item);
  }

  public getProjectsUser(user_name: any, isDriveCampaign: boolean = true): Observable<any> {
    console.log('isDriveCampaign', isDriveCampaign)
    let url =`/api/projects/users/${user_name}`;
    let urlParams =[];
    urlParams.push(`isDriveCampaign=${isDriveCampaign}`);
		url += '?' + urlParams.join('&');
    return this.apiService.httpGet(url);
	}

  mapWorkspace(brandId,companyId,workspaces){
    return this.apiService.httpPost(`/api/brands/${companyId}/${brandId}/add-workspaces`, workspaces);
  }

}
