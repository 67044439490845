import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { Authority, PermissionService } from "../services/permissions.service";

@Injectable({
    providedIn: 'root'
})
export class MtmAdminGuard implements CanActivate {

    constructor(private route: Router, private authService: AuthService, public permissionService: PermissionService) { }

    /**
     * Validate if user is ADMIN. If not, redirect to Projects page
     * @param route
     * @param state
     * @returns {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // If no logged in, redirect to /login
        if (!this.authService.getAuthUser()) {
            this.route.navigate(['/entry']);
            return false;
        }

        // Check if user is ADMIN
        if (this.permissionService.hasAuthority(Authority.Z, null)) {
            return true;
        } else {
            this.route.navigate(['/projects']);
            return false;
        }

    }

}
