import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SubscriptionConfirmPlanGuard implements CanActivate {

	constructor(private route: Router, private auth: AuthService, private subscriptionService: SubscriptionService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const selectedPlan = this.subscriptionService.plans.find(plan => plan.code === this.subscriptionService.registerData.plan);
		if (!selectedPlan) {
			this.route.navigate(['/subscription/register/select-plan']);
		} else {
			return true;
		}
	}

}
