import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "app/shared/services/auth.service";
import { SignupService } from "app/signup/shareds/services/signup.service";
import { ActivatedRoute, Router } from "@angular/router";
import { OverlayService } from "app/shared/services/overlayService";
import { CountryCodes } from "app/shared/components/mtm-tel-input/models/country-codes.model";
import { NotificationService } from "app/shared/services/notification.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { values } from "lodash";

@Component({
	selector: 'mtm-signup-detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss']
})
export class SignupDetailComponent implements OnInit, OnChanges, OnDestroy {
	detailForm: UntypedFormGroup;
	countries: any[];
	authUser: any;

	isLoading: boolean = false;

	validation: any = {};
	currency: any;
	inputTextType: string = 'password';
	ngUnsubscribe = new Subject();
	isAgree: boolean = false;

	euroCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
	listCountryCodes: any[] = new CountryCodes().allCountries;

	constructor(
		private authService: AuthService,
		private signupService: SignupService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private fb: UntypedFormBuilder
	) {
		this.authUser = this.authService.getAuthUser();
		this.buildForm();
	}

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	buildForm() {
		this.detailForm = this.fb.group({
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			email: [
				{
					value: this.route.snapshot.queryParams.email,
					disabled: true
				},
				[Validators.required]
			],
			companyName: ['', [Validators.required]],
			phone: ['', [Validators.required]],
			country: ['', [Validators.required]],
			password: ['', [Validators.required]],
			companyType: [null, [Validators.required]]
		});
		this.detailForm.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(values => {
			if (values.country) {
				this.currency = this.euroCountries.indexOf(this.detailForm.value.country) > -1 ? 'EUR' : 'USD';
			} else {
				this.currency = '';
			}
		});
	}

	submit() {
		this.notificationService.open({
			title: this.translatePipe.transform('confirmation'),
			notificationType: 'success',
			centerHeader: true,
			description: 'The following information is final and can not be changed after confirming: Company Type, Billing Country and Billing Currency.',
			subDescription: 'If you need to change some details, go back and select the correct country.',
			subDescription2: 'Or, restart the signup process for your MTM Subscription Plan, and this time choose the correct country.',
			subDescription3: 'After validating these choices, you cannot change your billing country and currency.',
			question2: 'Do you want to continue?',
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no'),
			reverseButton: true
		}).subscribe(confirm => {
			if (confirm) {
				this.isLoading = true;
				const currency = this.euroCountries.indexOf(this.detailForm.value.country) > -1 ? 'EUR' : 'USD';
				this.signupService.signup({ ...this.detailForm.getRawValue(), currency })
					.subscribe((res: any) => {
						localStorage.setItem('register_data', JSON.stringify(res));
						this.router.navigate(['../verification']);
					}, (err) => {
						if (err) {
							if (err.errorCode === 'EMAIL_EXISTS') {
								this.validation.error = { email: { [err.errorCode]: err.message } };
							} else if (err.errorCode === 'PHONE_EXISTS') {
								this.validation.error = { phone: { [err.errorCode]: err.message } };
							} else if (err.errorCode === 'NOT_VALID_PHONE_ERROR') {
								this.validation.error = { phone: { [err.errorCode]: err.message } };
							} else if (err.errorCode === 'COMPANY_NAME_REGISTERED') {
								this.validation.error = { company: { [err.errorCode]: err.message } };
							} else {
								this.validation.error = { other: { [err.errorCode]: err.message } };
								this.overlayService.showError(JSON.stringify(err));
							}
						}
						this.isLoading = false;
					});
			}
		}, (err) => {
			this.overlayService.showError(JSON.stringify(err));
		});

	}

	alreadyHaveAnAccount() {
		this.router.navigate(['/entry/login'], {
			queryParams: {
				returnUrl: window.location.pathname
			}
		});
	}

	changePasswordDisplayType() {
		this.inputTextType = this.inputTextType === 'text' ? 'password' : 'text';
	}

	onPhonePress() {
		if (this.validation && this.validation.error && this.validation.error.phone) {
			this.validation.error.phone = void 0;
		}
	}

	filterCountryCode(value: string): string {
		return this.listCountryCodes.find((country: any) => value.startsWith(country.regionCode)).regionCode;
	}
}
