import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/sub-modules/SharedModule';
import { UserPermissionTableHeader } from "./header/user-permission-table-header.component";
import { ActionCellComponent } from "./cell-renderer/action/action-cell.component";
import { UserCellComponent } from "./cell-renderer/user/user-cell.component";
import { RoleCellComponent } from "../table/cell-renderer/role/role-cell.component";
import { MtmChatModule } from 'app/sub-modules/mtm-chat-module/mtm-chat.module';

@NgModule({
    declarations: [
        UserPermissionTableHeader,
        ActionCellComponent,
        UserCellComponent,
        RoleCellComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MtmChatModule,
    ],
    exports: [
        UserPermissionTableHeader,
        ActionCellComponent,
        UserCellComponent,
        RoleCellComponent
    ]
})
export class UserPermissionTableModule { }
