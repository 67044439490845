import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: 'mtm-group-chat-picture',
	templateUrl: './mtm-group-chat-picture.component.html',
	styleUrls: ['./mtm-group-chat-picture.component.scss']
})
export class GroupChatPicture implements OnInit {
	@Input() conversation: any;
	@Input() showDetail: boolean = false;
	@Input() showHoverIcon: boolean = false;
	@Input() height: any = 32;
	@Input() width: any = 32;
	@Input() iconSize: any = '16px';
	@Input() fontSize: any = '8px';

	constructor() { }

	ngOnInit(): void { }
}
