import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { ProjectService } from "app/shared/services/project.service";

@Injectable({
    providedIn: 'root'
})
export class ProductionTeamService {
    proposal: { proposalId: string, projectId: string, users: any[] }[] = [];

    constructor(private serviceApi: ApiService,
        private serviceAuth: AuthService,
        private serviceProject: ProjectService) { }

    //FOR PERMISSION
    public initProposalTeamUsers(projectId, proposalId): Observable<any> {
        return new Observable(observe => {
            this.getProposalTeamUsersForProjectId(projectId).subscribe(
                res => {
                    this.setUsersProposals(projectId, proposalId, res);

                    observe.next(res);
                    observe.complete();
                },
                err => observe.error());
        });
    }

    private findIndexOfProposal(projectId, proposalId): number {
        let index = this.proposal.findIndex(k => k.projectId == projectId && k.proposalId == proposalId);
        if (index < 0)
            this.proposal.push({ proposalId: proposalId, projectId: projectId, users: [] });

        return this.proposal.length - 1;
    }

    private setUsersProposals(projectId, proposalId, users: any[]) {
        let index = this.findIndexOfProposal(projectId, proposalId);

        this.proposal[index].users = users;
    }

    public setUserToProposalUsers(projectId, proposalId, user: any) {
        let index = this.findIndexOfProposal(projectId, proposalId);
        this.proposal[index].users.push(user);
    }

    public removeUserFromProposal(projectId, proposalId, user) {
        let index = this.findIndexOfProposal(projectId, proposalId);
        let userIndex = this.proposal[index].users.findIndex(k => k.userName == user.userName);
        if (userIndex >= 0)
            this.proposal[index].users.splice(userIndex, 1);
    }

    //FOR

    public getCompanyInfo() {
        return this.serviceAuth.getAuthUser().company;
    }

    public getCompanyId() {
        return this.serviceAuth.getAuthUser().companyId;
    }



    public getAllRoles() {
        return this.serviceProject.getRoles();
    }

    public getCompanyUser(userEmail): Observable<any> {
        return this.serviceApi.httpGet(`/api/projects/users/${userEmail}`);
    }

    public saveTeamUser(data): Observable<any> {
        return this.serviceApi.httpPost(`/api/proposals/${data.projectId}/proposalTeam`, data);
    }

    public getProposalTeamUsersForProjectId(projectId): Observable<any> {
        return this.serviceApi.httpGet(`/api/proposals/${projectId}/proposalTeam/company/${this.serviceProject.proposal.prodCompanyId}`);
    }

    public removeTeamUser(username, projectId, proposalId): Observable<any> {
        return this.serviceApi.httpDelete(`/api/proposals/${projectId}/${proposalId}/proposalTeam/${username}`);
    }
}
