import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'mtm-button',
	template: `
		<button [disabled]="disabled" type="button" class="btn text-uppercase color-{{color}} d-flex flex-row align-items-center flex-nowrap">
			<i *ngIf="icon" class="fa fa-{{icon}} mr-3" aria-hidden="true"></i>
			<div class="label">{{label}}</div>
		</button>
	`,
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

	@Input() icon: string = null;
	@Input() label: string;
	@Input() color: string = 'default';
	@Input() disabled: boolean = false;
	@Output() click = new EventEmitter();


	constructor() {
	}


	ngOnInit() {

	}

}
