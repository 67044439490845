import {Injectable, SecurityContext} from "@angular/core";
import {DirectUploadRequest, FileUploader, UploadRequest} from "./file.uploader";
import {HttpClient, HttpContext, HttpEventType} from "@angular/common/http";
import {MTMCustomFile} from "./mtm-custom-file";
import {environment} from "../../../../environments/environment";
import {TransferStatus} from "./transfer-status";
import {HelperService} from "../helper.service";
import {listenerFileUploaded, listenerUploadStatusChanged} from "./listeners";
import {Observable, empty} from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { IS_UNCANCELABLE_REQUEST } from "app/shared/models";
import { UrlSanitizer } from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class DirectFileUploader implements FileUploader {
  constructor(private http: HttpClient,private sanitizer: DomSanitizer){

  }

  uploadFiles(request: UploadRequest) {
    const directRequest = request as DirectUploadRequest;
    if(! directRequest) {
      return;
    }
    request.files.forEach(file => {
      file.sessionUrl = environment.api.baseUrl + '/' + directRequest.uploadUrl;
      this.doDirectUpload(file);
    });
  }
  
  myEmptyObservable: Observable<any> = empty();
  cancelUpload(file: MTMCustomFile): Observable<any> {
    return this.myEmptyObservable;
  }

  pauseUpload(file: MTMCustomFile) {
  }

  resumeUpload(file: MTMCustomFile) {
  }

  //at the moment direct upload only uses multipart FormData
  doDirectUpload(file: MTMCustomFile) {
    const data = new FormData();
    data.append('file', file.file, file.fileName || 'mtm-file');

    file.uploadStatusCode = TransferStatus.SENDING;
    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name) {
        headers[name] = values[0];
      }
    });

    //let browser set boundary automatically in multipart/form-data
    if ('Content-Type' in headers) {
      delete headers['Content-Type'];
    }

    //warning: don't encode the url
    let safeurl = UrlSanitizer.sanitizeUrl(file.sessionUrl)

    this.http.post(safeurl, data, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: (response: any) => {
        switch (response.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(100 * response.loaded / response.total);
            break;
          case HttpEventType.Response:
            file.progress = 100;
            file.uploadStatusCode = TransferStatus.COMPLETED;
            file.setDbFileObject(response.body);
            listenerUploadStatusChanged.next(file);
            listenerFileUploaded.next(file);
            break;
        }
      },
      error: (error) => {
        file.uploadStatusCode = TransferStatus.ERROR;
        listenerUploadStatusChanged.next(file);
      }
    });
  }
}
