import { Injectable } from "@angular/core";
import { AIFramingServiceBase } from "./ai-framing.base.service";
import { AiReframingProject, ProjectCreateParams } from "../cores/models/project";

const PROJECT_PATH = '/v4/api/projects';

@Injectable({
    providedIn: 'root'
})
export class AIReframingProjectService {

    constructor(private apiService: AIFramingServiceBase) { }

    getProjects() {
        return this.apiService.httpGet(`${PROJECT_PATH}/`);
    }

    createProject(projectData: ProjectCreateParams) {
        return this.apiService.httpPost(`${PROJECT_PATH}/`, projectData);
    }

    getProjectById(projectId: string) {
        const url = `${PROJECT_PATH}/${projectId}`;
        return this.apiService.httpGet(url);
    }

    deleteProjectById(projectId: string) {
        const url = `${PROJECT_PATH}/${projectId}`;
        return this.apiService.httpDelete(url);
    }

    updateProject(projectId: string, projectData: AiReframingProject) {
        const url = `${PROJECT_PATH}/${projectId}`;
        return this.apiService.httpPatch(url, projectData);
    }

    createProjectMovies(projectId: string, moviesIds: string[]) {
        const url = `${PROJECT_PATH}/${projectId}/movies`;
        let params = '?';
        for (let i = 0; i < moviesIds.length; i++) {
            params += (!i ? `movie_ids=${moviesIds[i]}` : `&movie_ids=${moviesIds[i]}`);
        }
        return this.apiService.httpPost(`${url}${params}`, null);
    }

    deleteProjectMovieById(projectId: string, movieId: string) {
        const url = `${PROJECT_PATH}/${projectId}/movies/${movieId}`;
        return this.apiService.httpDelete(url);
    }

}