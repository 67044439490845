import * as moment from "moment";
import { CommentPlugin } from "../lib/comment-plugin";
import { utils } from "../lib/utils";

export class Comment extends CommentPlugin {
  commentList: any[];
  id: string;
  parentId: string;
  meta: any;
  body: string;
  timestamp: number;
  files: any[];

  constructor(data: any, image: Object) {
      super(image);
      this.id = data.id || utils.guid();
      this.parentId = data.parentId;
      this.meta = data.meta;
      this.body = data.body;
      this.files = data.files;
      this.timestamp = moment(data.meta.datetime).unix();
  }

  get data() {
    return {
    id: this.id,
    meta: this.meta,
    parentId: this.parentId,
    body: this.body,
    files: this.files
    }
  }

  static newFromData(body, parentId, commentList, plugin, commentData: any = null) {
    let data = this.dataObj(body, parentId, plugin, commentData);
    data["commentList"] = commentList;
    return new Comment(data, plugin.image);
  }

  static dataObj(body, parentId, plugin, commentData: any = null) {
    let datetime, updatetime, id, meta, creationDateTime, files;
    if (commentData) {
      datetime = commentData.meta.datetime;
      updatetime = commentData.meta.updatetime;
      const user_id = commentData.meta.user_id;
      const user_name = commentData.meta.user_name;
      id = commentData.id;
      files = commentData.files;
      meta = { "user_id": user_id, "user_name": user_name };

    }
    else {
      creationDateTime = moment.utc().valueOf();
      files = [];
    }
    return {
      meta: meta || {
        datetime: datetime || creationDateTime,
        updatetime: updatetime || creationDateTime,
        ...plugin.meta
      },
      id: id || utils.guid(),
      body: body,
      parentId: parentId,
      files: files
    };
  }

  static commentUpdateTime() {
    return moment.utc().valueOf();
  }

}