import {Pipe, PipeTransform} from "@angular/core";


@Pipe({ name: 'mtmRoundedNumber' })
export class RoundedNumberPipe implements PipeTransform {

  transform(value: number, limit: number = 10): string {
    return value > limit ? `${limit}+` : `${value}`;
  }
}

