import { AfterViewInit, Component, Input, NgModuleRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { DeleteUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/delete-user-project/delete-user-project.component";
import { NewUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/new-user-project/new-user-project.component";
import { ReinviteUserProjectComponent } from "app/layouts/my-projects/new-project/project-permissions/reinvite-user-project/reinvite-user-project.component";
import { PermissionsInfoModal } from "app/layouts/my-projects/permissions-info-modal/permissions-info-modal.component";
import { ProjectTypesModalComponent } from "app/shared/components/project-types-modal/project-types-modal.component";
import { ProjectSortingCriteria } from "app/shared/pipes/filter-sorting-project.pipe";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { AuthService } from "app/shared/services/auth.service";
import { NotificationService } from "app/shared/services/notification.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ProjectService } from "app/shared/services/project.service";
import { activeLanguageUpdated } from "app/shared/services/translate.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { UserProjectPermissionInfo } from "app/workspace/models/project.model";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { WorkspaceProjectPermissionTableHeader } from "./table/header/workspace-project-permission-table-header.component";
import { workspaceProjectParticipantColumnsDef } from "./table/workspace-project-participant-table.coldef";
import { CompanyService } from "app/shared/services/company.service";

@Component({
    selector: 'mtm-workspace-projects',
    templateUrl: './workspace-projects.component.html',
    styleUrls: ['./workspace-projects.component.scss']
})
export class WorkspaceProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(AgGridAngular) workspaceProjectParticipantsTable!: AgGridAngular;
    private ngUnsubscribe = new Subject();
    private _workspace: any = null;
    projects: any[] = [];
    selectedProject: any = null;
    projectUsers: any[] = null;
    public participants: UserProjectPermissionInfo[] = null;
    @Input() companyUsers: any[];
    projectRoles: any[] = [];
    isAdmin: boolean = false;
    productionRoles: any[] = [];
    isProductionSide: boolean = false;
    canCreateProject: boolean = false;
    canEditProject: boolean = false;
    allRoles: any = [];
	ProjectSortCriteria = ProjectSortingCriteria;
	projectSorting: any = { btn: ProjectSortingCriteria.ProjectName, sortDirection: true };
    company: any = null;
    hasMarketplaceAccess: boolean = false;
    rowFiltered: boolean = false;

    activationStatuses = [
        { code: 'ACTIVE_COMPLETED', label: 'Active Completed' },
        { code: 'PENDING_INPROGRESS', label: 'Pending In Progress' }
    ];

    get workspace(): any {
        return this._workspace;
    }

    @Input()
    set workspace(value: any) {
        this._workspace = value;
        this.projects = [];
        this.participants = [];
        this.selectedProject = null;
        this.projectUsers = [];
        this.loadProjects();
    }

    @Input()
    set roles(value: any[]) {
        this.allRoles = value;
        if (value) {
            this.projectRoles = value.filter(r => r.code.startsWith('PRODUCTION') || r.code.startsWith('PROJECT'));
            this.productionRoles = value.filter(r => r.code.startsWith('PRODUCTION'));
        }
    }

    defaultColDef: ColDef = {
        sortable: false,
        resizable: true,
        filter: false,
        headerValueGetter: this.getHeaderText.bind(this),
    };

    workspaceProjectParticipantColumnsDef = workspaceProjectParticipantColumnsDef;
    tableContext = {
        componentParent: this
    };
    public components: { [p: string]: any } = { agColumnHeader: WorkspaceProjectPermissionTableHeader, };
    localFilterParams: { fullName: string, email: string, roles: any[], decisionMaker: any } = { fullName: '', email: '', roles: [], decisionMaker: '' };
    authUserHaveAValidationRight: boolean;

    constructor(private authService: AuthService,
        private companyService: CompanyService,
        private router: Router,
        private workspaceService: WorkspaceService,
        private notificationService: NotificationService,
        private projectService: ProjectService,
        private permissionService: PermissionService,
        private translatePipe: TranslatePipe,
        private modalService: NgbModal) {
        this.tableContext = {
            componentParent: this
        };
    }

    ngOnInit(): void {
        if (this.permissionService.hasAuthority(Authority.Z, null))
            this.isAdmin = true;

        //disable new subscription project for now, for production company user
        const authUser = this.authService.getAuthUser();
        this.isProductionSide = authUser.company.companyType == 'PRODUCER';
        this.canCreateProject = true;

        activeLanguageUpdated
			.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				setTimeout(() => this.workspaceProjectParticipantsTable?.api?.refreshHeader(), 100);
			});
        this.companyService.getCompanyById(this.authService.getAuthUser().company.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: company => {
                    this.company = company;
                    this.hasMarketplaceAccess = company.companyType == 'PRODUCER' && !!company.marketplaceAccess;
                }
            });
    }


	ngAfterViewInit() {
		setTimeout(() => {
			this.workspaceProjectParticipantsTable?.api?.refreshHeader();
		}, 100);
	}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    loadProjects() {
        if (!this._workspace)
            return;

        this.projectService.getProjects()
            .pipe(take(1))
            .subscribe(projects => {
                if (!projects)
                    return;
                let filteredProjects = projects.filter(p => p.workspaceId == this._workspace.id);
                filteredProjects.forEach(p => p.localId = p.id); //to bypass localId check in ProjectSorting pipe
                filteredProjects.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1);
                this.projects = filteredProjects;
                if (this.projects && this.projects.length) {
                    this.selectProjectItem(this.projects[0]);
                }
            });
    }

    createProject() {
        if (!this._workspace)
            return;

        if (!this.permissionService.hasAuthority(Authority.P, null))
            return;

        if (this.projectService.projectOnloaded)
            this.projectService.leaveFromProject();

        if (this.permissionService.hasAuthority(Authority.Z, null)) {
            this.showProjectTypesPopup();
            return;
        } else if (this.isProductionSide) {
            this.createSubscriptionProject();
        } else {
            if(! this.hasMarketplaceAccess){
                this.createSubscriptionProject();
            } else {
                this.showProjectTypesPopup();
            }
        }
    }

    private createNewProjectForBrands() {
        const modalRef = this.modalService.open(ProjectTypesModalComponent, { size: 'sm' });
        modalRef.result.then((data: any) => {
            switch (data) {
                case 'one-shot':
                    this.router.navigate(['/entry/marketplace/build-request/ONE_SHOT/ADVERTISER']);
                    break;
                case 'subscription':
                    this.createSubscriptionProject();
                    break;
                default:
                    break;
            }
        });

    }

    private showProjectTypesPopup(){
		const modalRef = this.modalService.open(ProjectTypesModalComponent, { size: 'sm' });
		modalRef.result.then((data: any) => {
			switch (data) {
				case 'one-shot':
					this.router.navigate(['/entry/marketplace/build-request/ONE_SHOT/ADVERTISER']);
					break;
				case 'subscription':
					const params = { workspaceId: this._workspace.id };
					this.router.navigate(['/projects/new/company'], { queryParams: params });
					break;
				case 'adminSubscription':
					const adminParams = { workspaceId: this._workspace.id, adminProjectSelect: true };
					this.router.navigate(['/projects/new/company'], { queryParams: adminParams });
					break;
				default:
					this.router.navigate(['/projects/new/company']);
					break;
			}
		}, reason => {
		});
	}

    private createSubscriptionProject() {
        const params = { workspaceId: this._workspace.id };
        this.router.navigate(['/projects/new/company'], { queryParams: params });
    }

    selectProjectItem(project: any) {
        this.authUserHaveAValidationRight = this.permissionService.hasAuthority(Authority.V, project.id);
        this.selectedProject = project;
        this.selectedProject.airDate = new Date(this.selectedProject.airDate);
        this.selectedProject.startDate = new Date(this.selectedProject.startDate);
        this.selectedProject.plannedEndDate = new Date(this.selectedProject.plannedEndDate);
        this.selectedProject.validationMode = this.selectedProject.validators.length <= 1 ? "ONE" : "ALL";
        this.initDecision();
        this.loadParticipants(project);
        this.canEditProject = this.permissionService.hasAuthority(Authority.U, project.id);

    }

    public loadParticipants(project: any) {
        this.participants = [];
        this.projectUsers = [];
        this.projectService.getProjectParticipantWithUserActivationStatus(project.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                participants => {
                    this.participants = [];
                    this.projectUsers = participants;
                    participants.forEach((element: any, index) => {
                        const { username = '', fullName = '', roles = [], userActivationStatus = '' } = element;
                        const statusInfo = this.activationStatuses.find(s => s.code == userActivationStatus);
                        let statusLabel = '';
                        if (statusInfo)
                            statusLabel = statusInfo.label;

                        const user: any = {
                            username,
                            avatarUrl: '',
                            fullName: element.fullName ? element.fullName.trim() : '',
                            jobTitle: '',
                            status: userActivationStatus,
                            statusLabel,
                            roles
                        };

                        if (this.companyUsers) {
                            const existingUser = this.companyUsers.find(u => u.username == username);
                            if (existingUser) {
                                user.avatarUrl = existingUser.avatarUrl;
                                user.jobTitle = existingUser.jobTitle;
                                user.userData = existingUser;
                            }
                        }

                        user.index = index;
                        this.odmSelect(user);
                        user.selectedRoles = JSON.parse(JSON.stringify(user.roles));
                        this.participants.push(user);
                    });
                }
            )
    }

    private odmSelect(user: any) {
        user.showOdm = false;
        if (user.roles.includes("PROJECT_OWNER") || user.roles.includes("PROJECT_MANAGER")) {
            if (!this.selectedProject.validators) {
                user.selectOdm = true
            } else if (this.selectedProject.validators.includes(user.username))
                user.selectOdm = true;
            else
                user.selectOdm = false;
            user.showOdm = true;
        } else {
            if (this.selectedProject.validators.includes(user.username)) {
                user.selectOdm = true;
                user.showOdm = true;
            }
            else
                user.selectOdm = false;
        }

        if (this.participants.length > 0) {
            let index = this.participants.findIndex(u => u.username == user.username);
            if (index > -1) {
                this.participants[index].showOdm = user.showOdm;
                this.participants[index].selectOdm = user.selectOdm;
            }
        }
    }


    identifyParticipant(participant, index) {
        return participant.username;
    }

    onRoleChange(selectedRoles, user) {
        if (!this.selectedProject)
            return;

        this.projectService.setRoles(this.selectedProject.id, user.username, selectedRoles)
            .pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe(result => {

            })
    }

    showAddModal() {
        if (!this.selectedProject || !this.canEditProject)
            return;

        let modal = this.modalService.open(NewUserProjectComponent, { size: 'lg', backdrop: 'static', keyboard: false });
        if (this.isProductionSide)
            modal.componentInstance.roles = this.productionRoles.map(r => ({id: r.code, name: r.label}));
        else
            modal.componentInstance.roles = this.projectRoles.map(r => ({id: r.code, name: r.label}));
        modal.componentInstance.users = this.projectUsers;
        modal.componentInstance.decision = true;
        modal.componentInstance.project = this.selectedProject;
        modal.componentInstance.projectId = this.selectedProject.id;
        modal.result.then((result: any) => {
            if (result) {
                // It means user was found, so add to the list
                if (result.user) {
                    let filtered = this.companyUsers.filter(u => u.username == result.user.username);

                    if (filtered.length > 0) {
                        this.notificationService.open({
                            title: 'User Invitation',
                            description: `This user is already in the list!`,
                            confirmBtn: 'OK'
                        });
                    }
                }

                else if (result.invited) {
                    this.notificationService.open({
                        title: 'User Invitation',
                        description: `We haven't found a user with ${result.email} email address. We've sent an invite to join MTM.`,
                        confirmBtn: 'Accept'
                    });
                }
                this.loadParticipants(this.selectedProject);
            }
        }, (err: any) => {

        });
        return modal;
    }

    removeUser(user) {
        if (!this.canEditProject)
            return;

        const modalRef = this.modalService.open(DeleteUserProjectComponent, { size: 'lg' });
        modalRef.componentInstance.users = [(user.userData ? user.userData : user)];
        modalRef.componentInstance.projectId = this.selectedProject.id;
        modalRef.result.then(v => {
            if (v)
                this.loadParticipants(this.selectedProject);
        }, (err: any) => console.log(err));
    }

    invite(user) {
        if (!this.canEditProject)
            return;

        if (user.selectedRoles == 0)
            return;

        let modal = this.modalService.open(ReinviteUserProjectComponent, { size: 'lg' });
        modal.componentInstance.user = (user.userData ? user.userData : user);
        modal.componentInstance.projeID = this.selectedProject.id;
        modal.result.then(v => {
            if (v)
                this.loadParticipants(this.selectedProject);
        }, (err: any) => console.log(err));
        return modal;
    }

    openPermissionDetail() {
        let modal = this.modalService.open(PermissionsInfoModal, { size: 'lg' });
        modal.componentInstance.CLIENTorPROD = 'CLIENT';
    }

    goToProject() {
        if (!this.selectedProject) {
            return;
        }

        this.router.navigate(['/projects', this.selectedProject.id, 'dashboard']);
    }

    decision: boolean;

    private initDecision() {
        if (this.selectedProject.validationMode == "ALL") {
            this.decision = true;
        } else if (this.selectedProject.validationMode == "ONE") {
            this.decision = false;
        }
    }

    changeOdmDecision(user: any, e) {
        if (!this.authUserHaveAValidationRight) {
            return;
        }

        if (this.selectedProject.validators.includes(user.username)) {
            if (this.selectedProject.validators.length <= 1) {
                user.selectOdm = true;
                e.target.checked = true;
                this.projectService.saveProject(this.selectedProject);
                return;
            }
            let index = this.selectedProject.validators.findIndex(k => k == user.username);
            if (index >= 0) {
                user.selectOdm = false;
                e.target.checked = false;
                this.selectedProject.validators.splice(index, 1);
            }
        } else {
            if (user.selectedRoles.includes("PROJECT_OWNER") || user.selectedRoles.includes("PROJECT_MANAGER")) {
                user.selectOdm = true;
                e.target.checked = true;
                this.selectedProject.validators.push(user.username);
            } else {
                user.selectOdm = false;
                e.target.checked = false;
            }
        }
        this.selectedProject.validationMode = this.selectedProject.validators.length <= 1 ? "ONE" : "ALL";
        this.initDecision();
        this.projectService.saveProject(this.selectedProject);
    }

    onGridReady($event) { }

	autoSizeColumnWidth() {
		this.workspaceProjectParticipantsTable.columnApi.autoSizeAllColumns();
	}

	updateProjectSorting(criteria: string, ascending: boolean) {
		this.projectSorting = {
			btn: criteria,
			sortDirection: ascending
		};
	}

    getHeaderText(params: any) {
		let headerText = '';

		switch (params.colDef.field) {
			case 'fullName':
				headerText = 'user';
				break;
			case 'email':
				headerText = 'email';
				break;
			case 'role':
				headerText = 'role';
				break;
            case 'rights':
                headerText = 'Ownership';
                break;
			case 'decisionMaker':
				headerText = 'decisionMaker';
				break;
            case 'action':
				headerText = 'action';
				break
		}

		return this.translatePipe.transform(headerText);
	}
}
