import { Component, OnInit } from '@angular/core';

@Component({
	templateUrl: 'error-404.component.html',
	styleUrls: ['./error-404.component.scss']
})
export class Error404Component implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}
}

