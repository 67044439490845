<ng-template ngbModalContainer></ng-template>
<mtm-banner [bannerType]="bannerTypes.MaintenanceOnly"></mtm-banner>
<div class="container-fluid">

	<!-- Comment this or set ngIf to false after migration -->
	<!--
	<div class="top-banner" *ngIf="true">
		<span>{{'topBanner_maintenanceText' | translate: {'date':'2022-04-02','start':'09:00', 'end':'13:00'} }}</span>
	</div>
	-->
	<!-- loading with mtm logo if auto login -->
	<div *ngIf="trainingMode" id="index_animation" class="index-animation-container">
		<div class="index-text-logo">
			<strong>MTM</strong>
		</div>
		<div class="index-mtm">
			MASTER THE MONSTER
		</div>
		<div class="index-animation-lds-ellipsis">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
	<!-- loading end -->
	
	<div class="row forms">
		<div class="col white-side">
			<div *ngIf="!trainingMode" class="justify-content-center text-center logo-container">
				<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
			</div>

			<!-- div class="row align-top">
                Text
            </div -->

			<div *ngIf="!trainingMode" class="justify-content-center text-center mkt-content" [innerHTML]="'loginTitle' | translate ">
			</div>

			<div  *ngIf="!trainingMode" class="justify-content-center mt-4 request-invitation">
				<div class="customer-type-container text-center justify-content-center" *ngIf="!isCustomerTypeSelected">

					<button *ngIf="!checkEnvironment()" id="producer-btn" class="m-1 btn btn-lg btn-danger btn-signup"
						[disabled]="!isRECAPTCHAChecked" (click)="selectCustomerType('PRODUCER')">{{'login_producer' | translate}}
					</button>
					<button *ngIf="!checkEnvironment()" id="advertiser-btn" class="m-1 btn btn-lg btn-danger btn-signup"
						[disabled]="!isRECAPTCHAChecked" (click)="selectCustomerType('ADVERTISER')">{{'login_advertiser' | translate}}
					</button>

				</div>
				<div style="margin-top: -32px; transform:scale(0.7); display: flex; justify-content: center">
					<re-captcha *ngIf="!isRECAPTCHAChecked" (resolved)="resolved($event)"
						siteKey="6LcOWZgUAAAAAI4Gel-E5e_iXH-F2Z6yVu6IIkNt"></re-captcha>
				</div>

				<div class="one-shot-container text-center justify-content-center" *ngIf="isCustomerTypeSelected">
					<div class="close-button" (click)="closeModal()"><i class="fa fa-window-close"
							aria-hidden="true"></i></div>

					<div class="text-center mb-3 title">{{'entryPlanSelector_title'|translate}}</div>
					<button id="market-place-btn" class="m-1 btn btn-lg btn-danger btn-long-text"
						(click)="notifyUser('ONE_SHOT')">{{'entryPlanSelector_produce'|translate}}
					</button>
					<button id="subscription-btn" class="m-1 btn btn-lg btn-danger btn-long-text"
						(click)="notifyUser('SUBSCRIPTION')">
						{{'entryPlanSelector_collab'|translate}}
					</button>
				</div>
			</div>

		</div>
		<div class="col black-side" >
			<div class="row" *ngIf="!trainingMode">
				<div class="col black-side">
					<div class="row justify-content-center">
						<p class="select-lang">{{'chooseLanguage' | translate}}</p>
					</div>
					<div class="row justify-content-center">
						<button class="btn btn-light btn-xs mr-2" [class.active]="currentLanguage == 'en_us'" (click)="changeLanguage('en_us')">
							<i class="flag-icon flag-icon-us"></i>
						</button>
						<button class="btn btn-light btn-xs mr-2" [class.active]="currentLanguage == 'fr'" (click)="changeLanguage('fr')">
							<i class="flag-icon flag-icon-fr"></i>
						</button>
						<button class="btn btn-light btn-xs mr-2" [class.active]="currentLanguage == 'es'" (click)="changeLanguage('es')">
							<i class="flag-icon flag-icon-es"></i>
						</button>
						<button class="btn btn-light btn-xs mr-2" [class.active]="currentLanguage == 'pt'" (click)="changeLanguage('pt')">
							<i class="flag-icon flag-icon-pt"></i>
						</button>

						<button class="btn btn-light btn-xs" [class.active]="currentLanguage == 'zh'" (click)="changeLanguage('zh')">
							<i class="flag-icon flag-icon-cn"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col black-side">


					<div class="row justify-content-center">
						<mtm-login-form [projectId]="projectId" [projectType]="projectType" [companyType]="companyType"
							[redirectLoginFormOneShot]="redirectLoginFormOneShot" [isTrainer]="trainingMode" [trainerUserName]="trainerUserName">
						</mtm-login-form>
					</div>
				</div>

			</div>

		</div>
	</div>
</div>