<div class="row">
  <div class="col-6 my-3">
    <input [(ngModel)]="worskspaceSearch" class="search-box pl-2 pr-4" (ngModelChange)="searchWorkSpace()" type="text"
      [placeholder]="'search' | translate" />
    <i class="fa fa-search search-icon" aria-hidden="true"></i>
  </div>
  <div class="col-6 my-3 ">
    <div class="row justify-content-end align-items-center pr-5">
      <div class="calendar-block d-flex">
        <div class="d-flex align-items-center">
          <div #monthYearDropdown class="d-flex align-items-center calendar-dropdown ml-auto" ngbDropdown>
            <div class="dropdown-toggle mt-auto d-flex align-items-center p-0 pointer" ngbDropdownToggle>
              <div class="_400 ml-2 mr-3 text-capitalize">{{calendarViewLabel | translate}}
              </div>
              <div class="calendar-icon-div pointer ml-auto">
                <img src="../../../../assets/img/project-v2/calendar.svg" alt="">
              </div>
            </div>
            <div class="calendar-dropdown-menu w-100" ngbDropdownMenu>
              <div ngbDropdownItem class="text-capitalize" (click)="selectCalendarMode('latest')">{{'latest' | translate }}</div>
              <div ngbDropdownItem class="text-capitalize" (click)="selectCalendarMode('week')">{{'week' | translate}}</div>
              <div ngbDropdownItem class="text-capitalize" (click)="selectCalendarMode('3_months')">{{'loreal_dashboard_3months' | translate }}</div>
              <div ngbDropdownItem class="text-capitalize" (click)="selectCalendarMode('6_months')">{{'loreal_dashboard_6months' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center item-filter" container="body" ngbDropdown>
        <div class="d-flex justify-content-start pl-2 align-items-center mt-auto _1-line-text-ellipsis f-10 pointer" ngbDropdownToggle>
          {{ shouldShowEllipsis(selectedWorkspace.name) ? (selectedWorkspace.name | slice:0:12) + '...' : selectedWorkspace.name }}
        </div>
        <div class="" ngbDropdownMenu>
          <div class="_1-line-text-ellipsis f-10 pointer" (click)="changeWorkspace(workspace)" *ngFor="let workspace of workspaces"
            ngbDropdownItem [ngbTooltip]="shouldShowEllipsis(workspace.name) ? workspace.name : null">
            {{workspace.name}}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<div class="row mt-4">
  <div *ngIf="canCreateWorkspace" class="d-flex col mb-4" [style.max-width]="'200px'" [style.min-width]="'200px'">
    <button id="trainerCreateWorkspace" class="workspace-btn w-100 createworkspace-btn" (click)="createWorkspace()">
      <div class="circle-div">
        <i class="fa fa-plus d-flex" aria-hidden="true"></i>
      </div>
      <h6 class="_700 f-10 mt-3 line-height text-uppercase" [style.color]="'#898C98'">{{'loreal_dashboard_createNewWorkspace' | translate }}</h6>
    </button>
  </div>
  <div class="mb-4 workspace-skeleton" *ngIf="!isLoaded && !isError">
    <mtm-grid-skeleton [borderRadius]="6" [gridCount]="5" [widthSizeInPixels]="169"
      [HeightSizeInPixels]="230"></mtm-grid-skeleton>
  </div>
  <div class="d-flex justify-content-center align-items-center w-75" *ngIf="isError">
    <span class="_600 f-12 ">{{'loreal_dashboard_somethingWentWrong' | translate }}</span>
  </div>
  <div [style.max-width]="'200px'" [style.min-width]="'200px'" class="col mb-4 pointer" *ngFor="let item of workSpaces"
    (click)="createCampaignDrive(item)" id="trainerOpenWorkspace">
    <div class="workspace-card" id="trainerWorkspaceCard">
      <div class="card-img ">
        <img *ngIf="item.avatarUrl" class="image-style" [src]="item.avatarUrl" alt="">
        <span *ngIf="!item.avatarUrl" class="c-full-white workspace-name-text text-uppercase h-100 w-100"
          [style.backgroundColor]="timelineService.getColorFromName(item.name)">
          {{item.name | slice:0:2}}
        </span>
        <span class="badge badge-edit-button">
          <i (click)="$event.stopImmediatePropagation();markAsFavorite(item)" *ngIf="!item.isFavorite"
            class="fa fa-star-o" aria-hidden="true"></i>
          <i (click)="$event.stopImmediatePropagation();removeFavorite(item)" *ngIf="item.isFavorite" class="fa fa-star"
            [style.color]="'#ffca4c'" aria-hidden="true"></i>
        </span>
      </div>
      <div class="card-block d-flex">
        <div class="curve"></div>
        <div class="col pl-1">
          <div class="row" [style.max-width]="'180px'" [style.min-width]="'180px'">
            <div class="col">
              <span class="f-10 _700 company-name text-uppercase" [style.color]="'#898C98'">{{item.brandName }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="f-14 _700 c-full-white workspace-name"
                [ngbTooltip]="shouldShowEllipsis(item.name) ? item.name : null">
                {{ shouldShowEllipsis(item.name) ? (item.name | slice:0:12) + '...' : item.name }}
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-3">
              <div class="drive-campaign-circle">{{item.numberOfDriveCampaign}} </div>
            </div>
            <div>
              <span class="f-10 _400 c-full-white">Drive Campaigns</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-3">
              <div class="drive-campaign-circle">{{item.numberOfCampaign}} </div>
            </div>
            <div>
              <span class="f-10 _400 c-full-white">Campaigns</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-3">
              <div class="drive-campaign-circle">{{item.numberOfStandaloneProjects}} </div>
            </div>
            <div>
              <span class="f-10 _400 c-full-white">Stand alone projects</span>
            </div>
          </div>
        </div>
        <div class="pointer" [style.height]="'20px'" style="position: relative; top: -5px; right: 20px;"
          (click)="$event.stopImmediatePropagation()">
          <div *ngIf="canCreateWorkspace" ngbDropdown class="dropdown" container="body" placement="bottom-right">
            <button ngbDropdownToggle class="edit-button">
              <i class="fa fa-ellipsis-v fa-lg" [style.color]="'#898D98'" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-left arrow_box" aria-labelledby="dropdownMenuLink">
              <!-- <a class="dropdown-item pointer" (click)="editWorkspace(item)">
              Edit
            </a> -->
              <div class="col options hover-text">
                <div class="col pointer p-0 d-flex align-items-center my-3 " (click)="editWorkspace(item)">
                  <i [style.color]="'#181E39'" class="_500 fa fa-pencil fa-lg" aria-hidden="true"></i>
                  <span class="ml-2 f-10 _700"> SETTINGS</span>
                </div>
                <div class="col pointer p-0 d-flex align-items-center my-3" (click)="markAsFavorite(item)">
                  <i *ngIf="!item.isFavorite" class="_500 fa fa-star-o fa-lg" [style.color]="'#181E39'"
                    aria-hidden="true"></i>
                  <i *ngIf="item.isFavorite" class="_500 fa fa-star fa-lg " [style.color]="'#ffca4c'"
                    aria-hidden="true"></i>
                  <span class="ml-2 f-10 _700"> {{ !item.isFavorite ? 'MARK AS FAVORITE' : 'REMOVE FAVORITE' }}</span>
                </div>
                <!-- <div class="col pointer p-0 d-flex align-items-center my-3">
                <img [style.color]="'#181E39'"  width="15" height="12" src="../../../assets/svg/archive-svg.svg" alt="">
                <span class="ml-2 f-10 _700"> ARCHIVE</span></div> -->
                <div class="col pointer p-0 d-flex align-items-center my-3">
                  <i [style.color]="'#181E39'" class="_500 fa fa-trash-o fa-lg" aria-hidden="true"></i>
                  <span class="ml-2 f-10 _700"> DELETE </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
