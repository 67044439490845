<div class="modal-body">
  <h5 class="text-center" *ngFor="let user of users">Delete {{ user.fullName }}?</h5>
  <p class="text-muted mt-3 text-center">By deleting this person they will be permanently removed from the project.</p>
  <div class="row">
    <div class="col">
      <button class="btn btn-outline-secondary btn-lg btn-block"
        (click)="activeModal.close()"><span>Cancel</span></button>
    </div>
    <div class="col">
      <button class="btn btn-danger btn-lg btn-block" (click)="delete()"><span>Delete</span></button>
    </div>
  </div>
</div>