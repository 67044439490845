import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { CompanyService } from "app/shared/services/company.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { SUBSCRIPTION_LIMIT } from "app/subscriptions/models/const";
import { SubscriptionPlanService } from "app/subscriptions/services/plan.service";

@Component({
	templateUrl: "limit-reached-popup.component.html",
	styleUrls: ['./limit-reached-popup.component.scss']
})
export class LimitReachedPopupComponent implements OnInit {
	@Input() forceRedirect: boolean = true;

	_limitType: string;

	get limitType(): string {
		return this._limitType;
	}

	set limitType(value: string) {
		this._limitType = value;
		this.setText();
	}

	limitText: string;
	promptText: string;
	authUser: any;
	addonStorage: any;
	limitTotal: string;
	limitMetric: string;

	addonInfo: any;

	subscription: any;
	billingPeriodInMonth: any;
	billingPeriodInString: any;

	subscriptionInfo: any;
	upcomingPaymentInvoice: any;
	currentPlanDetail: any;
	activeStorageAddon: any;
	archivalStorageAddon: any;
	totalActiveStorage: any = 0;
	totalArchiveStorage: any = 0;
	totalLimitUsers: any = 0;

	companyUsers: any[] = [];

	isLoading: boolean = false;

	constructor(
		private companyService: CompanyService,
		private authService: AuthService,
		private permissionService: PermissionService,
		private subscriptionPlanService: SubscriptionPlanService,
		private activeModal: NgbActiveModal,
		private router: Router
	) {
		this.authUser = this.authService.getAuthUser();
		this.subscription = this.authService.getAuthUserSubscription();
		console.log('subscription lstorage');
		console.log(this.subscription);
		this.getCurrentPlanDetails();
	}

	ngOnInit(): void {

	}

	setText() {
		const isPrincipal = this.permissionService.hasAuthority(Authority.S, null);

		switch (this.limitType) {
			case SUBSCRIPTION_LIMIT.LICENSE_USERS:
				this.limitText = 'limitReached_totalUsers';
				this.promptText = isPrincipal ? 'limitReached_principalLicensePrompt' : 'limitReached_userLicensePrompt';
				this.limitMetric = 'users';
				this.limitTotal = this.subscription.totalLicense;
				break;
			case SUBSCRIPTION_LIMIT.ACTIVE_STORAGE:
				this.limitText = 'limitReached_activeStorage';
				this.promptText = isPrincipal ? 'limitReached_principalActiveStoragePrompt' : 'limitReached_userActiveStoragePrompt';
				this.limitMetric = 'GB';
				break;
			case SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE:
				this.limitText = 'limitReached_archivalStorage';
				this.promptText = isPrincipal ? 'limitReached_principalArchivalStoragePrompt' : 'limitReached_userArchivalStoragePrompt';
				this.limitMetric = 'GB';
				console.log(this.limitMetric);
				break;
			case SUBSCRIPTION_LIMIT.NUMBER_OF_FILES:
				this.limitText = 'limitReached_uploadsNumber';
				this.promptText = isPrincipal ? 'limitReached_principalUploadPrompt' : 'limitReached_userUploadPrompt';
				this.limitMetric = 'files';
				this.limitTotal = '10'; // need to populate from somewhere
				break;
		}
	}

	getCurrentPlanDetails() {
		this.subscriptionPlanService.getPlans()
			.subscribe(data => {
				this.activeStorageAddon = data.addon.find(a => a.code === 'ACTIVE_STORAGE_PLAN');
				this.archivalStorageAddon = data.addon.find(a => a.code === 'ARCHIVE_STORAGE_PLAN');
				this.currentPlanDetail = data.plans.find(plan => plan.code === this.subscription.planType + '_PLAN');
				this.totalActiveStorage = this.subscription.activeLicense * this.activeStorageAddon.activeStorage.value + this.currentPlanDetail.activeStorage.value;
				this.totalArchiveStorage = this.subscription.archiveLicense * this.archivalStorageAddon.archivalStorage.value + this.currentPlanDetail.archivalStorage.value;

				switch (this.limitType) {
					case SUBSCRIPTION_LIMIT.ACTIVE_STORAGE:
						this.limitTotal = this.totalActiveStorage;
						break;
					case SUBSCRIPTION_LIMIT.ARCHIVAL_STORAGE:
						this.limitTotal = this.totalArchiveStorage;
						break;
				}

				this.getCompanyUsers();
			});
	}

	getCompanyUsers() {
		this.companyService.getCompanyUsers()
			.subscribe((users: any) => {
				this.companyUsers = users;
				this.totalLimitUsers = this.subscription.totalLicense + this.currentPlanDetail.maxUsers - this.companyUsers.length;
			});
	}

	cancel() {
		if (this.forceRedirect) {
			this.router.navigate(['/projects']);
		}
		this.activeModal.close();
	}

	confirm() {
		this.router.navigate(['/subscription/upgrade']);
		this.activeModal.close();
	}

}