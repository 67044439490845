<loading-data #loadingRef></loading-data>
<div class="card mb-3" *ngIf="step === 1 && !isLoading">
	<div class="card-header font-weight-bold">{{'companyProfilesSmallTitle' | translate}}</div>
	<div class="card-block">
		<!-- TODO :- when filter api will be ready will implement this -->
		<!-- <p class="text-blod">{{'companyProfilesSubtitleOne' | translate}}</p>
		<div class="row">
			<div *ngFor="let filter of suggestCompanyFilter" class="col col-xs-2"
				(click)="changeSuggestedCompanyShowStatus(filter)">
				<input type="checkbox" [(ngModel)]="filter.selectStatus">
				<label class="custom-control-description">{{ filter.value | translate}}</label>
			</div>
		</div> -->
		<p class="card-text" *ngIf="companies.length > 0" [innerHTML]="'companyProfilesSubtitleTwo' | translate">
		</p>
		<div class="col" *ngIf="showContact">
			<div class="row" style="text-align: center; padding: 30px 100px">
				{{'companyProfilesNoProductionCompany' | translate}}
			</div>
			<div class="row justify-content-center">
				<div class="col-8">
					<div class="mt-3 row justify-content-center">
						<label class="form-group has-float-label">
							<input class="form-control contact-info" type="text" [(ngModel)]="contactInfo.firstName"
								placeholder=" ">
							<span>{{'firstName' | translate}}</span>
						</label>
					</div>
					<div class="mt-3 row justify-content-center">
						<label class="form-group has-float-label">
							<input class="form-control contact-info" type="text" [(ngModel)]="contactInfo.lastName"
								placeholder=" ">
							<span>{{'lastName' | translate}}</span>
						</label>
					</div>
					<div class="mt-3 row justify-content-center">
						<label class="form-group has-float-label">
							<input class="form-control contact-info" type="email" [(ngModel)]="contactInfo.email"
								placeholder=" ">
							<span>{{'email' | translate}}</span>
						</label>
					</div>
					<div class="mt-3 row justify-content-center">
						<label class="form-group has-float-label">
							<input class="form-control contact-info" type="tel" [(ngModel)]="contactInfo.mobile"
								placeholder=" ">
							<span>{{'mobileNo' | translate}}</span>
						</label>
					</div>
					<div class="mt-3 row justify-content-center">
						<label class="form-group has-float-label">
							<input class="form-control contact-info" type="text" [(ngModel)]="contactInfo.company"
								placeholder=" ">
							<span>{{'company' | translate}}</span>
						</label>
					</div>
					<div class="mt-3 row justify-content-center">
						<textarea class="form-control contact-info form-group has-float-label"
							placeholder="{{'additionalInformation' | translate}}" rows="5"
							[(ngModel)]="contactInfo.additionalInfo"></textarea>
					</div>
					<div class="mt-3 row justify-content-center">
						<button type="button" class="btn btn-lg btn-danger" style="width: 400px"
							(click)="submitContactInfo()">{{'submit' | translate}}
						</button>
					</div>
					<div class="mt-3 row justify-content-center">
						OR
					</div>
					<div class="mt-3 row justify-content-center">
						<button *ngIf="isOneShotProject" type="button" class="btn btn-lg btn-success"
							style="width: 400px" (click)="navigateContent()">{{'createRequestAgain' | translate}}
						</button>
						<button *ngIf="!isOneShotProject" type="button" class="btn btn-lg btn-success"
							style="width: 400px" (click)="navigateContentInProject()">{{'createRequestAgain' |
							translate}}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-4 company-card" *ngFor="let company of getCompanies(); trackBy: company?.id">
				<div class="row mt-5">
					<div class="col">
						<div *ngIf="company.avatarUrl" [style.background-image]="'url('+company.avatarUrlBeautify+')'"
							class="company-logo rounded mx-auto"></div>
						<div *ngIf="!company.avatarUrl"
							[style.background-image]="'url(assets/img/samples/No_Image_Available.png)'"
							class="company-logo rounded mx-auto"></div>
						<h5 class="text-center font-weight-bold my-3">{{company.name}}</h5>
					</div>
				</div>
				<div class="row">
					<div class="col-6 mt-3 ">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url('+company.tileImageONE+')'"></div>
					</div>
					<div class="col-6 mt-3">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url('+company.tileImageTWO+')'"></div>
					</div>
					<div class="col-6 mt-3 ">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url('+company.tileImageTHREE+')'"></div>
					</div>
					<div class="col-6 mt-3">
						<div class="view-portfolio rounded" (click)="viewProfileModal(company)">
							<h6 class="text-center">{{'companyProfilesViewProfiles' | translate}}</h6>
						</div>
					</div>
				</div>
				<div class="row my-3">
					<div class="col">
						<button *ngIf="!isHiddenInviteButton || company.inviteStatus"
							class="btn mtm-default-button btn-lg btn-block" (click)="onInvite(company)"
							[disabled]="isDisabledInviteButtonForAllCompany || company.inviteStatus"
							[ngClass]="{'btnInvitedAcceptedDeclined': company.inviteStatus && (company.inviteStatus == 'IN_PROGRESS' || company.inviteStatus == 'DECLINED' || company.inviteStatus == 'ACCEPTED')}">
							<span *ngIf="!company.inviteStatus && !isDisabledInviteButtonForAllCompany">
								{{'companyProfilesInviteToPitch' | translate}}
							</span>
							<span *ngIf="company.inviteStatus && company.inviteStatus == 'IN_PROGRESS'">Invited</span>
							<span *ngIf="company.inviteStatus && company.inviteStatus == 'DECLINED'">DECLINED</span>
							<span *ngIf="company.inviteStatus && company.inviteStatus == 'ACCEPTED'">ACCEPTED</span>
						</button>
					</div>
				</div>
			</div>
			<div class="col-4 company-card blur-filter" *ngFor="let company of oneShotCompaniesDumbArray">
				<div class="row mt-5">
					<div class="col">
						<div [style.background-image]="'url(assets/img/samples/logo-1.jpg)'"
							class="company-logo rounded mx-auto"></div>
						<h5 class="text-center font-weight-bold my-3">Company {{company}}</h5>
					</div>
				</div>
				<div class="row">
					<div class="col-6 mt-3 ">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url(assets/img/samples/cover1.jpg)'"></div>
					</div>
					<div class="col-6 mt-3">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url(assets/img/samples/cover2.jpg)'"></div>
					</div>
					<div class="col-6 mt-3 ">
						<div class="portofolio-img rounded img-p"
							[style.background-image]="'url(assets/img/samples/cover3.jpg)'"></div>
					</div>

					<div class="col-6 mt-3">
						<div class="view-portfolio rounded">
							<h6 class="text-center">{{'companyProfilesViewProfiles' | translate}}</h6>
						</div>
					</div>
				</div>
				<div class="row my-3">
					<div class="col">
						<button class="btn btn-danger btn-lg btn-block">
							{{'companyProfilesInviteToPitch' | translate}}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col" *ngIf="companies.length > showMorePage*6">
				<div class="row w-100">
					<div class="col col-4"></div>
					<div class="col col-4">
						<button class="btn btn-danger btn-lg btn-block btn-show-more" (click)="showMore()">
							{{'showMore' | translate}}
						</button>
					</div>
					<div class="col col-4"></div>
				</div>
			</div>
		</div>
	</div>
</div>