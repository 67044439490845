import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { BreadcrumbService } from "ng5-breadcrumb";
import { ProposalInvitationService } from "app/shared/services/proposal-invitation.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { CompanyService } from "../../../shared/services/company.service";
import { ProjectService } from "../../../shared/services/project.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { EmitterService } from "../../../shared/services/emitter.service";
import { OverlayService } from "../../../shared/services/overlayService";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { Authority } from "../../../shared/services/permissions.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: 'mtm-rate-prod',
	templateUrl: './rate-prod-comp.component.html',
	styleUrls: ['./rate-prod-comp.component.scss']
})

export class RateProdCompComponent implements OnInit, OnDestroy {

	user: any = {};
	projectId: any;
	delete: boolean;
	reviewPlaceholder: any;
	onBriefPlaceholder: any;
	onBudgetPlaceholder: any;
	outOfTheBoxPlaceholder: any;
	goodListenerPlaceholder: any;
	onTimePlaceholder: any;
	agreementText: any;
	agreement: any;
	checkValue: any = false;
	proposals: any[] = [
		{ company: { name: 'Pixar Studios', avatarUrl: 'assets/img/samples/pixar-logo.png' } },
		{ company: { avatarUrl: 'assets/img/samples/pixar-logo.png' } },
		{ company: { avatarUrl: 'assets/img/samples/pixar-logo.png' } }
	];
	indexSelected: number = 0;
	prodCompRateSelected: any;
	prodCompRateForm: UntypedFormGroup;
	prodCompRate: any;
	brief: number = 0;
	budget: number = 0;
	outOfBox: number = 0;
	goodListener: number = 0;
	time: number = 0;
	global: number = (this.brief + this.budget + this.outOfBox + this.goodListener + this.time) / 5;
	prodCompanies: any[] = [];
	prodCompany: any = {};
	isSubmitted: boolean = false;
	prodCompanyName = "";
	authority = Authority;
	ngUnsubscribe = new Subject();

	constructor(private router: Router, public auth: AuthService, private breadCrumb: BreadcrumbService,
		private proposalInvitationService: ProposalInvitationService, private activeRouter: ActivatedRoute,
		private fb: UntypedFormBuilder, private companyService: CompanyService, private projectService: ProjectService,
		private notificationService: NotificationService, private overlayService: OverlayService, private translatePipe: TranslatePipe
	) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		EmitterService.get("getCompanyName").pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(name => {
			this.prodCompanyName = name;
			this.breadCrumb.hideRoute(`/projects//projects/rate-production-company/${this.projectId}`);
			this.breadCrumb.addFriendlyNameForRoute(
				"/projects/rate-production-company",
				'Rate Production Company  >  ' + this.prodCompanyName
			);
			this.breadCrumb.hideRoute(`/projects//projects/rate-production-company/${this.projectId}`);
			this.breadCrumb.hideRouteRegex(`^/projects/rate-production-company/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*(.*)?$`);
		});
		this.user = this.auth.getAuthUser();
		this.breadCrumb.hideRouteRegex(`^/projects/rate-production-company/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*(.*)?$`);
		this.breadCrumb.hideRoute(`^/projects/rate-production-company/[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*\-[a-z0-9]*(.*)?$`);
		this.projectId = this.activeRouter.snapshot.params['projectId'];
		this.delete = this.activeRouter.snapshot.queryParams['action'] === "delete";
		this.saveProductionCompaniesRates();
		this.loadFormGroup();
	}
	inProgress() {
		this.router.navigate(['projects']);
	}


	private saveProductionCompaniesRates() {
		this.projectService.getAllProjectProposals(this.projectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(projectProposals => {
			projectProposals = projectProposals.filter(p => p.status == "ACCEPTED");
			projectProposals.forEach(prodComp => {
				this.companyService.getCompanyById(prodComp.prodCompanyId).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(prodCompanyData => {
					let clientCompanyAvatar = prodComp.avatarUrl;
					let projectName = prodComp.invitationProjectName;
					prodComp.avatarUrl = prodCompanyData.avatarUrl + '?' + (new Date).getTime();
					this.prodCompanyName = prodCompanyData.name;
					this.loadPlaceholders();
					EmitterService.get("getCompanyName").emit(this.prodCompanyName);
					this.proposalInvitationService.getProductionCompanyRate(this.projectId, prodComp.prodCompanyId).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(productionCompanyRateData => {
						productionCompanyRateData.avatarUrl = prodCompanyData.avatarUrl;
						productionCompanyRateData.userCompanyAvatar = clientCompanyAvatar;
						productionCompanyRateData.userCompanyName = this.user.company.name;
						productionCompanyRateData.userName = this.user.fullName;
						productionCompanyRateData.userAvatar = this.user.avatarUrl;
						productionCompanyRateData.userProjectName = projectName;
						productionCompanyRateData.prodCompId = prodComp.prodCompanyId;
						this.prodCompanies.push(productionCompanyRateData);
						this.prodCompRateSelected = this.prodCompanies[0];
						this.fillTheStars();
						this.loadFormGroup();
					}, (err: any) => {
						let prodCompanyDTO = {
							userProjectName: projectName,
							userAvatar: this.user.avatarUrl,
							userName: this.user.fullName,
							userCompanyName: this.user.company.name,
							userCompanyAvatar: clientCompanyAvatar,
							avatarUrl: prodCompanyData.avatarUrl + '?' + (new Date).getTime(),
							prodCompId: prodComp.prodCompanyId,
							onBrief: "",
							onBudget: "",
							outOfTheBox: "",
							onTime: "",
							goodListener: "",
							globalRating: "",
							id: null
						}
						this.prodCompanies.push(prodCompanyDTO);
						this.prodCompRateSelected = this.prodCompanies[0];
						this.loadFormGroup();
						this.fillTheStars();
					});
				});
			});
		})
	}

	private selectProposal(index: number): void {
		this.indexSelected = index;
		this.prodCompRateSelected = this.prodCompanies[index];
		this.loadFormGroup();
		this.fillTheStars();
	}
	private loadFormGroup() {
		this.prodCompRateForm = this.fb.group({
			onBriefFeedBack: [(this.prodCompRateSelected && this.prodCompRateSelected.onBriefFeedBack) ? this.prodCompRateSelected.onBriefFeedBack : ''],
			onBudgetFeedBack: [(this.prodCompRateSelected && this.prodCompRateSelected.onBudgetFeedBack) ? this.prodCompRateSelected.onBudgetFeedBack : ''],
			outOfTheBoxFeedBack: [(this.prodCompRateSelected && this.prodCompRateSelected.outOfTheBoxFeedBack) ? this.prodCompRateSelected.outOfTheBoxFeedBack : ''],
			onTimeFeedBack: [(this.prodCompRateSelected && this.prodCompRateSelected.onTimeFeedBack) ? this.prodCompRateSelected.onTimeFeedBack : ''],
			goodListenerFeedBack: [(this.prodCompRateSelected && this.prodCompRateSelected.goodListenerFeedBack) ? this.prodCompRateSelected.goodListenerFeedBack : ''],
			review: [(this.prodCompRateSelected && this.prodCompRateSelected.review) ? this.prodCompRateSelected.review : ''],
			onBrief: [(this.prodCompRateSelected && this.prodCompRateSelected.onBrief) ? this.prodCompRateSelected.onBrief : ''],
			onBudget: [(this.prodCompRateSelected && this.prodCompRateSelected.onBudget) ? this.prodCompRateSelected.onBudget : ''],
			outOfTheBox: [(this.prodCompRateSelected && this.prodCompRateSelected.outOfTheBox) ? this.prodCompRateSelected.outOfTheBox : ''],
			onTime: [(this.prodCompRateSelected && this.prodCompRateSelected.onTime) ? this.prodCompRateSelected.onTime : ''],
			goodListener: [(this.prodCompRateSelected && this.prodCompRateSelected.goodListener) ? this.prodCompRateSelected.goodListener : ''],
			globalRating: this.global,
			userCompanyName: [(this.prodCompRateSelected && this.prodCompRateSelected.userCompanyName) ? this.prodCompRateSelected.userCompanyName : ''],
			userProjectName: [(this.prodCompRateSelected && this.prodCompRateSelected.userProjectName) ? this.prodCompRateSelected.userProjectName : ''],
			userAvatar: [(this.prodCompRateSelected && this.prodCompRateSelected.userAvatar) ? this.prodCompRateSelected.userAvatar : ''],
			userCompanyAvatar: [(this.prodCompRateSelected && this.prodCompRateSelected.userCompanyAvatar) ? this.prodCompRateSelected.userCompanyAvatar : ''],
			userName: [(this.prodCompRateSelected && this.prodCompRateSelected.userName) ? this.prodCompRateSelected.userName : '']
		});
		if (this.prodCompRateSelected) {
			this.prodCompRateSelected.onBrief = this.brief;
			this.prodCompRateSelected.onBudget = this.budget;
			this.prodCompRateSelected.outOfTheBox = this.outOfBox;
			this.prodCompRateSelected.onTime = this.time;
			this.prodCompRateSelected.goodListener = this.goodListener;
			this.prodCompRateSelected.globalRating = this.global;
		}
	}

	onSubmit() {
		let form = this.prodCompRateForm.value;
		this.proposalInvitationService.saveProdCompanyRate(this.projectId, this.prodCompRateSelected.prodCompId, this.prodCompRateForm.value).pipe(
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(savedProdCompRate => {
				savedProdCompRate.avatarUrl = this.prodCompRateSelected.avatarUrl;
				this.prodCompanies[this.indexSelected] = savedProdCompRate;
				this.prodCompRateSelected = this.prodCompanies[this.indexSelected];
				this.isSubmitted = true;
				this.overlayService.showSuccess(this.translatePipe.transform('overlayRatingSaved'), 'Success');
				this.projectService.getProductionOwnerForRating(this.projectId, savedProdCompRate.id).pipe(
					takeUntil(this.ngUnsubscribe)
				)
					.subscribe(productionOwner => {
						if (productionOwner && productionOwner.username) {
							this.projectService.ratingMail(this.projectId, productionOwner)
						}
					});
				if (this.delete) {
					this.projectService.deleteProject(this.projectId).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						_ => this.router.navigate(['projects']),
						err => {
							this.notificationService.open({
								title: 'Warning',
								description: err.message,
								confirmBtn: 'Ok'
							});
						});
				} else {
					this.router.navigate(['projects']);
				}
			}, err => {
				this.overlayService.showError(this.translatePipe.transform('overlayErrorSave') + 'rate.' + this.translatePipe.transform('overlayTryAgain'), 'Error');
				console.log(err);
			});
	}

	fillTheStars() {
		this.brief = this.prodCompRateSelected.onBrief;
		this.budget = this.prodCompRateSelected.onBudget;
		this.outOfBox = this.prodCompRateSelected.outOfTheBox;
		this.goodListener = this.prodCompRateSelected.goodListener;
		this.time = this.prodCompRateSelected.onTime;
		this.global = this.prodCompRateSelected.globalRating;
	}
	modelChanged() {
		this.isSubmitted = false;
		this.prodCompRateSelected.onBrief = this.brief;
		this.prodCompRateSelected.onBudget = this.budget;
		this.prodCompRateSelected.outOfTheBox = this.outOfBox;
		this.prodCompRateSelected.goodListener = this.goodListener;
		this.prodCompRateSelected.onTime = this.time;
		this.global = (+this.brief + +this.budget + +this.outOfBox + +this.goodListener + +this.time) / 5;
		this.prodCompRateSelected.globalRating = this.global;


	}

	loadPlaceholders() {
		let text = "Please add additional comments & Feedback on the ability of " + this.prodCompanyName;
		this.reviewPlaceholder = "Feel free to tell us more & give us more insights anout your global experience with " + this.prodCompanyName;
		this.onBriefPlaceholder = text + " to respect the brief.";
		this.onBudgetPlaceholder = text + " to respect the budget.";
		this.outOfTheBoxPlaceholder = text + " to develop innovative & out of the box ideas.";
		this.goodListenerPlaceholder = text + " to respect schedule & deadlines.";
		this.onTimePlaceholder = text + " to listen to clients comments & feedbacks.";
		this.agreementText = "I agree to share my comments & reviews on the MTM Platform, if needed and agreed by " + this.user.company.name;
	}
}
