import { PLANS } from "./const";

export interface PlanInfo {
	planId: string;
	name: string;
	code: string;
	description: string;
	order: number;
	minUsers: number;
	maxUsers: number;
	maxProjects: number;
	activeStorage: StorageItem;
	archivalStorage: StorageItem;
	basePrice: PlanPrice;
	features: string[]
}

export interface PlanPrice {
	monthlyPrice: CostItem;
	quarterlyPrice: CostItem;
	biannualPrice: CostItem;
	annualPrice: CostItem;
}

export enum PlanAddOnTypes {
	Users = 'USERS',
	ActiveStorage = 'ACTIVE_STORAGE',
	ArchivalStorage = 'ARCHIVAL_STORAGE'
};

export interface PlanAddOn {
	productId: string
	name: string;
	description: string;
	type: PlanAddOnTypes,
	basePrice: PlanPrice;
	cost?: PlanPrice;
	value?: number;
	perMonthValue: number;
}

export interface CostItem {
	priceId: string;
	currency: string;
	value: number;
	perMonthValue: number;
}

export interface StorageItem {
	value: number;
	metric: string;
}

export const DummyPlans: PlanInfo[] = [
	{
		planId: 'FREE',
		name: 'FREE',
		code: PLANS.FREE,
		description: '',
		order: 1,
		minUsers: 1,
		maxUsers: 2,
		maxProjects: 1,
		activeStorage: {
			value: 5,
			metric: 'Gb'
		},
		archivalStorage: {
			value: 0,
			metric: ''
		},
		basePrice: {
			monthlyPrice: {
				priceId: 'price 1',
				currency: '€',
				value: 0,
				perMonthValue: 0
			},
			quarterlyPrice: {
				priceId: 'price 2',
				currency: '€',
				value: 0,
				perMonthValue: 0
			},
			biannualPrice: {
				priceId: 'price 3',
				currency: '€',
				value: 0,
				perMonthValue: 0
			},
			annualPrice: {
				priceId: 'price 4',
				currency: '€',
				value: 0,
				perMonthValue: 0
			}
		},
		features: []
	}, {
		planId: 'STANDARD',
		name: 'STANDARD',
		code: PLANS.STANDARD,
		description: '',
		order: 2,
		minUsers: 2,
		maxUsers: 10,
		maxProjects: 3,
		activeStorage: {
			value: 500,
			metric: 'Gb'
		},
		archivalStorage: {
			value: 1,
			metric: 'Tb'
		},
		basePrice: {
			monthlyPrice: {
				priceId: 'price 5',
				currency: '€',
				value: 99,
				perMonthValue: 0
			},
			quarterlyPrice: {
				priceId: 'price 6',
				currency: '€',
				value: 94,
				perMonthValue: 0
			},
			biannualPrice: {
				priceId: 'price 7',
				currency: '€',
				value: 89,
				perMonthValue: 0
			},
			annualPrice: {
				priceId: 'price 8',
				currency: '€',
				value: 79,
				perMonthValue: 0
			}
		},
		features: []
	}, {
		planId: 'BUSINESS',
		name: 'BUSINESS',
		code: PLANS.BUSINESS,
		description: '',
		order: 3,
		minUsers: 5,
		maxUsers: 20,
		maxProjects: 10,
		activeStorage: {
			value: 1,
			metric: 'Tb'
		},
		archivalStorage: {
			value: 2,
			metric: 'Tb'
		},
		basePrice: {
			monthlyPrice: {
				priceId: 'price 9',
				currency: '€',
				value: 169,
				perMonthValue: 0
			},
			quarterlyPrice: {
				priceId: 'price 10',
				currency: '€',
				value: 161,
				perMonthValue: 0
			},
			biannualPrice: {
				priceId: 'price 11',
				currency: '€',
				value: 152,
				perMonthValue: 0
			},
			annualPrice: {
				priceId: 'price 12',
				currency: '€',
				value: 135,
				perMonthValue: 0
			}
		},
		features: []
	}
];
