import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HierarchicalFilesComponent } from '../main/hierarchical-files.component';

@Component({
	selector: 'file-modal-component',
	templateUrl: './file-modal.component.html',
	styleUrls: ['./file-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileModalComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('folderView', { static: true }) folderView: HierarchicalFilesComponent;
	@Input() public files: any;

	viewMode: string = 'GRID';
	menu: any[] = [];

	constructor(private activeModal: NgbActiveModal) { }

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy(): void { }

	selectViewChange(event, selectedViewMode: string) {
		event.preventDefault();
		this.viewMode = selectedViewMode;
	}

	closeModal() {
		this.activeModal.close();
	}

	selectedFiles() {
		const selectedFolders = this.files.children.filter(c => c.selected).map(f => f.id);
		return selectedFolders.length;
	}

	createArchive() {
		const selectedFolders = this.files.children.filter(c => c.selected).map(f => f.id);
		if (selectedFolders.length) {
			this.activeModal.close(selectedFolders);
		}
	}
}
