import { Component, OnInit, Input } from '@angular/core';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Subject, Subscription } from 'rxjs';
import * as htmlToImage from 'html-to-image';
import { OverlayService } from 'app/shared/services/overlayService';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/shared/services/auth.service';
import { DummyDataService } from 'app/shared/services/dummy-data.service';
import { TimelineService } from 'app/shared/services/timeline.service';

@Component({
  selector: 'mtm-download-timeline',
  templateUrl: './download-timeline.component.html',
  styleUrls: ['./download-timeline.component.scss', '../recap.component.scss', '../../timeline-style.scss']
})
export class DownloadTimelineComponent implements OnInit {
  @Input() visualizations: any = [];
  @Input() productTimeline: any = [];
  @Input() campaignTimeline: any = [];
  @Input() isFullTimeline: boolean = false;
  @Input() timelineData: any;
  @Input() isBothSelected: boolean = false
  subscription: Subscription
  summaryDetail: any
  showLoader: boolean = false;
  colors = {
    'CAMPAIGN': '#000000',
    'PRODUCT': '#1E43A1',
    'MAGELLAN': '#1E43A1',
    'COLLECTOR / PRODUCT': '#086289',
    'GIFT': '#923065',
    'SET': '#502B9D'
  };

  generatedImages: any[] = [];
  pageIds: string[] = ['page1', 'page2']; // Array of page IDs
  items: any[] = [];
  ngUnsubscribe = new Subject();
  temimg = ['../../../../assets/img/project-v2/download-recap-header-img.png']
  headerImage = '.../../../../../assets/img/project-v2/recap-header.jpg';
  headerLogo = '.../../../../../assets/img/project-v2/comapnny-log.png';
  headerTitle = 'LANCÔME FRAGRANCE';
  headerSubtitle = 'L’Absolu Rouge';
  scenario4Header = '.../../../../../assets/img/project-v2/workspace-img.png';
  scenario4HeaderLogo = '.../../../../../assets/img/project-v2/helenarubinstein-icon.png';
  // ysl
  scenario3YslHeader = '.../../../../../assets/img/project-v2/ysl-dashboard.png';
  scenario3YslHeaderLogo = '.../../../../../assets/img/project-v2/ysl-icon.png';
  authUser: any;

  constructor(
    private projectV2Service: ProjectV2ServiceService,
    private overlayService: OverlayService,
    private authService: AuthService,
    private loarealDummyDataService: DummyDataService,
    public timelineService: TimelineService
  ) { }

  ngOnInit(): void {
    this.authUser = this.authUser = this.authService.getAuthUser();
    this.subscription = this.projectV2Service.currentVisibility.subscribe(result => { this.isFullTimeline = result });
    let criteria = JSON.parse(this.timelineData.criteria);
    this.summaryDetail = criteria;
    // this.downloadImages();
    this.headerImage = this.scenario3YslHeader;
    this.headerLogo = this.scenario3YslHeaderLogo;
    this.headerTitle = this.timelineService.workspaceName;
    this.headerSubtitle = this.timelineData.name;
    console.log('this.timelineData', this.timelineData)
  }

  async generateImage(id) {
    const element = document.getElementById(id);
    this.showLoader = true;
    await htmlToImage.toBlob(element).then((blob) => {
      if (blob) {
        navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]);
        this.showLoader = false;
        this.overlayService.showSuccess('Image copied successfully.', 'Success');
      } else {
        this.showLoader = false;
        this.overlayService.showError('Unable to copy image. Please try again.', 'Error');
      }
    }).catch((error) => {
      this.overlayService.showError('Unable to copy image. Please try again.', 'Error');
      this.showLoader = false;
    });
  }

  async downloadImages() {
    this.showLoader = true;
    this.generatedImages = [];
    await Promise.all(this.pageIds.map(async (elementId) => {
      const element = document.getElementById(elementId);
      try {
        const dataUrl = await htmlToImage.toPng(element);
        this.generatedImages.push({
          title: `Title ${elementId}`,
          base64Image: dataUrl.split('data:image/png;base64,')[1]
        });
      } catch (error) {
        console.error('Error generating image for', elementId, error);
      }
    }));

    this.generatedImages = this.generatedImages.sort((a, b) => a.title.localeCompare(b.title));
    if (this.generatedImages.length > 0) {
      this.downloadppt();
    }
  }

  generateFileFromDataURL(dataURL: string, filename: string): File {
    const arr = dataURL.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mimeType = mimeMatch ? mimeMatch[1] : '';
    const byteString = atob(arr[1]);

    let n = byteString.length;
    const uint8Array = new Uint8Array(n);

    while (n--) {
      uint8Array[n] = byteString.charCodeAt(n);
    }

    return new File([uint8Array], filename, { type: mimeType });
  }

  downloadppt() {
    if (this.generatedImages.length > 0) {
      this.projectV2Service.downloadPPT({ items: this.generatedImages })
        .subscribe(
          response => {
            const fileName = 'TimelineSummary.ppt';
            let blob = response as Blob;
            let a = document.createElement('a');
            a.download = fileName;
            a.href = window.URL.createObjectURL(blob);
            a.click();
            this.showLoader = false;
          }
        ), (err: any) => {
          this.showLoader = false;
          console.log('err', err)
          console.error('something went wrong can not generate ppt.');
        }
    } else {
      console.log('No images generated');
    }
  }
}
