import * as _ from 'lodash';
const MODAL_LOCAL_STORAGE_KEY = 'file.upload.modal.settings'

export default class FUMLocalSetting {
	private emailAuthUser: string;

	private localSettings = {};

	constructor() {
		this.emailAuthUser = _.get(JSON.parse(localStorage.getItem('auth_user')), 'email');
	}

	private getSettings() {
		this.localSettings = localStorage.getItem(MODAL_LOCAL_STORAGE_KEY);
		this.localSettings = this.localSettings && typeof this.localSettings == 'string' ? JSON.parse(this.localSettings) : {};

		return this.localSettings[this.emailAuthUser] || {};
	}

	private updateSetting(mySetting: Object) {
		let item = this.getSettings();
		item = {
			...item,
			...mySetting
		}

		this.localSettings[this.emailAuthUser] = item;
		localStorage.setItem(MODAL_LOCAL_STORAGE_KEY, JSON.stringify(this.localSettings));
	}

	public getPosition() {
		let init = 75;
		let item = this.getSettings();

		return {
			top: item.top ? item.top : init,
			left: item.left ? item.left : init
		}
	}

	public getInitStatus() {
		let item = this.getSettings();
		return item.latestStatus ? item.latestStatus : false;
	}

	public getBackgroundMode() {
		let item = this.getSettings();
		return item.isBackground ? item.isBackground : false;
	}

	public savePosition(top, left) {
		let item = {
			top: top,
			left: left
		};
		this.updateSetting(item);
	}

	public updateModalLatestStatus(latestStatus) {
		let item = {
			latestStatus: latestStatus
		}

		this.updateSetting(item);
	}

	public updateBackgroundMode(status) {
		let item = {
			isBackground: status
		}

		this.updateSetting(item);
	}
}
