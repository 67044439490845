import { onAnnotationClickClosed, onAnnotationClickOpened } from "app/shared/services/annotation.service";
import { AudioComp } from "../lib/audio-component";
import { Annotation } from "./annotation";

export class AnnotationState extends AudioComp {

    _enabled: boolean;
    _annotations: Annotation[];
    _activeAnnotation: any;
    currentActive: any;
    hoverActive: boolean;
    hoveredAnnotation: Annotation;
    clickActive: boolean;
    clickedAnnotation: Annotation;
    annotationTimeMap: {};
    animationFrameObject: any;

    constructor(plugin: Object) {
        super(plugin);
        this.initAPI(this, 'AnnotationState');
        this.currentActive = new Set();

        this.hoverActive = false;
        this.clickActive = false;
        this.hoveredAnnotation = null;
        this.clickedAnnotation = null;
    }

    // sets _enabled and closes or opens annotation as needed
    set enabled(shouldBeEnabled) {
        this._enabled = shouldBeEnabled;
        if (!shouldBeEnabled) {
            this.clickOutAnnotation();
            this.closeAllAnnotations();
        }
        if (shouldBeEnabled) {
            this.openAllAnnotations();
        }
    }

    get enabled() {
        return this._enabled;
    }

    // Sets _annotations w/Annoation objects from input array
    set annotations(annotationsData) {
        let _this2 = this;
        this._annotations = annotationsData.map(a => new Annotation(a, _this2.plugin));
        this.rebuildAnnotationTimeMap();
    }

    get annotations() {
        return this._annotations;
    }

    set activeAnnotation(annotation) {
        this._activeAnnotation = annotation;
    }

    // Get current active annotation or a null object with .close()
    get activeAnnotation() {
        return this._activeAnnotation || { close: () => { } };
    }

    // Serialize data
    get data() {
        return this._annotations.map(a => a.data);
    }

    openAllAnnotations() {
        const _this2 = this;
        this.annotations.forEach(annotation => {
            _this2.openAnnotation(annotation, false);
        });
    }

    closeAllAnnotations() {
        const _this2 = this
        this.annotations.forEach(annotation => {
            _this2.closeAnnotation(annotation);
        });
    }

    newAnnotationUpdate(annotationData) {
        const newAnnotation = new Annotation(annotationData, this.plugin);
        this._annotations.push(newAnnotation);
        if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
            this.plugin.controls.cancelAddNew();
            this.openAllAnnotations();
        }
    }
    
    updateAnnotationById(annotation) {
        let annotationIndex = this.annotations.findIndex((obj => obj.id === annotation.id));
        this.annotations[annotationIndex].updateAnnotation(annotation);
        if (!this.plugin.controls.uiState.adding && !this.plugin.controls.uiState.editing) {
            this.plugin.controls.cancelAddNew();
            this.closeAnnotation(this.annotations[annotationIndex]);
            this.openAnnotation(this.annotations[annotationIndex], false);
        }
    }

    openAnnotation(annotation: Annotation, clickOpen: boolean) {
        if (!this.plugin.active) this.plugin.toggleAnnotationMode();
        let annotationIndex = this.annotations.indexOf(annotation);
        if (!this.currentActive.has(annotationIndex)) {
            annotation.open();
            this.currentActive.add(annotationIndex);
            if (clickOpen) annotation.clickOpen();
        }
        // this.activeAnnotation = annotation;
    }

    closeAnnotation(annotation: Annotation) {
        annotation.close();
        let annotationIndex = this.annotations.indexOf(annotation);
        this.currentActive.delete(annotationIndex);
    }

    destroyAnnotationById(id) {
        let annotation = this.findAnnotation(id);
        if (annotation) annotation.teardown();
    }

    removeAnnotation(annotation: Annotation) {
        let id = annotation.id;
        this.clickOutAnnotation();
        let i = this._annotations.indexOf(annotation);
        this._annotations.splice(i, 1);
        this.stateChanged();
        this.plugin.fire('annotationDeleted', { id: id, allAnnotations: this.data });
    }

    clearActive() {
        this.activeAnnotation.close(false);
        this._activeAnnotation = null;
    }

    rebuildAnnotationTimeMap() {
        var _this2 = this;

        var timeMap = {};
        this.annotations.forEach(function (annotation) {
            annotation.secondsActive.forEach(function (second) {
                var val = timeMap[second] || [];
                val.push(_this2.annotations.indexOf(annotation));
                timeMap[second] = val;
            });
        });
        this.annotationTimeMap = timeMap;
    }

    findComment(id) {
        var _ref;

        function _toConsumableArray(arr) {
            if (Array.isArray(arr)) {
                for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                    arr2[i] = arr[i];
                } return arr2;
            } else {
                return Array.from(arr);
            }
        }

        let comments = this.annotations.map(function (a) {
            return a.commentList.comments;
        });
        comments = (_ref = []).concat.apply(_ref, _toConsumableArray(comments)); // flatten 2d array
        return comments.find(function (c) {
            return c.id == id;
        });
    }

    // Returns annotation object given ID
    findAnnotation(id) {
        return this.annotations.find(a => a.id === id);
    }

    createAndAddAnnotation(data) {
        this.plugin.controls.uiState.adding && this.plugin.controls.cancelAddNew();

        const annotation = Annotation.newFromData(
            data.commentStr || '',
            data.parentId || null,
            data.range,
            this.plugin,
            data.id
        )
        this.addNewAnnotation(annotation);
    }

    // Add a new annotation
    addNewAnnotation(annotation) {
        this._annotations.push(annotation);
        this.openAllAnnotations();
        this.stateChanged();
        // this.plugin.fire('addedNewAnnotation', { 'annotation': annotation.data });
        let data = {"newAnnotation": annotation, "allAnnotations": this.data}
        this.plugin.fire('annotationCreated', data);
    }

    hoverInAnnotation(id) {
        const annotation = this.findAnnotation(id);
        annotation.hoverOpen();
        this.hoveredAnnotation = annotation;
        this.plugin.primordial.activeAnnotation = {
            annotation: annotation.data,
            type: 'hover',
            username: annotation.commentList.comments[0].meta.user_id
        };
    }

    hoverOutAnnotation() {
        if (this.hoveredAnnotation) {
            this.hoveredAnnotation.hoverClose();
            this.hoveredAnnotation = null;
            this.plugin.primordial.activeAnnotation = null;
        }

    }

    clickInAnnotation(id) {
        const annotation = this.findAnnotation(id);

        // Closing annot if clicked on when already active
        // if (this.clickedAnnotation && this.clickedAnnotation.id == annotation.id ) {
        //     this.clickOutAnnotation();
        // }
        // Close if clicking on new object after already clicking on another
        if (this.clickedAnnotation) {
            this.clickOutAnnotation();
        }
        this.plugin.controls.cancelAddNew();
        if (!this.plugin.active) {
            this.plugin.toggleAnnotationMode();
        }
        annotation.clickOpen();
        this.clickedAnnotation = annotation;
        onAnnotationClickOpened.emit({
            annotation: this.clickedAnnotation.data
        });
        this.plugin.primordial.activeAnnotation = {
            annotation: annotation.data,
            type: 'click',
            username: annotation.commentList.comments[0].meta.user_id
        };
    }

    clickOutAnnotation() {
        if (!this.clickedAnnotation) return;
        this.clickedAnnotation.clickClose();
        onAnnotationClickClosed.emit({
            annotation: this.clickedAnnotation.data
        });
        this.clickedAnnotation = null;
        this.plugin.primordial.activeAnnotation = null;
    }


    activeAnnotationsForTime(time) {
        if (!this.annotations.length) return [];
        return this.annotationTimeMap[time] || [];
    }

    setLiveAnnotation() {
        let time = parseFloat(this.currentTime().toFixed(2));
        let activeAnnotations = this.activeAnnotationsForTime(time);

        this.boundTimeRange();

    }

    runSetLive() {
        let progressEl = document.getElementById("progress-navigation");
        let songProgressOverlay = document.getElementById("song-played-progress");
        let currentTime = this.currentTime();
        progressEl["value"] = currentTime;
        songProgressOverlay["value"] = currentTime;
        this.plugin.primordial.wavesurfer.setCurrentTime(currentTime);
        this.plugin.primordial.startTime = this.currentTime();
        this.plugin.primordial.endTime = this.currentTime();
        this.plugin.controls.playUpdate();
        this.setLiveAnnotation();
        this.animationFrameObject = requestAnimationFrame(() => this.runSetLive());
    }

    timeUpdate() {
        this.boundTimeRange();
    }

    boundTimeRange() {
        if (this.clickedAnnotation) {
            let annotation = this.clickedAnnotation;
            let currentTime = this.currentTime();
            if (currentTime < annotation.range.start) {
                this.setCurrentTime(annotation.range.start);
            }
            if (currentTime > annotation.range.end) {
                this.setCurrentTime(annotation.range.end);
                this.pause();
            }
        }
    }

    onAudioPlay() {
        this.plugin.primordial.isPlaying = true;
        // this.clickOutAnnotation();
        console.log('calling request');
        requestAnimationFrame(() => this.runSetLive());
        if (this.clickedAnnotation) {
            if (this.currentTime() >= this.clickedAnnotation.range.end - 0.1) {
                this.setCurrentTime(this.clickedAnnotation.range.start);
            }
        }
    }

    onAudioBreak() {
        this.plugin.primordial.isPlaying = false;
        this.plugin.controls.breakUpdate();
        cancelAnimationFrame(this.animationFrameObject);
    }

    stateChanged() {
        this.plugin.fire('onStateChanged', this.data);
    }

    // Reset internal state properties
    resetData() {
        this.annotations = [];
        this.activeAnnotation = null;
        this.enabled = false;
        this.annotationTimeMap = {};
    }

    teardown() {
        this.annotations.forEach(annotation => {
            annotation.teardown(false);
        });
        // this.disableAnnotationHover();
        // this.disableAnnotationClick();
        this.resetData();
        super.teardown();
        return null;
    }
}
