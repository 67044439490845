import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubscriptionPlanService } from "app/subscriptions/services/plan.service";
import { SubscriptionRegisterContactUsComponent } from "app/subscriptions/shared/components/contact-us/contact-us.component";
import { SubscriptionService } from "../../../services/subscription.service";
import { BILLING_TYPE } from "../../../models/const";
import { Router } from "@angular/router";
import { UpgradePlanStateChanged } from "../../models/upgrade-tab.model";
import { EmitterService } from "app/shared/services/emitter.service";
import { AuthService } from "app/shared/services/auth.service";
import * as moment from 'moment';
import { LoadingDataComponent } from "app/shared/components/loading-data/loading-data.component";
import { CompanyService } from "app/shared/services/company.service";
import { forkJoin } from "rxjs";

@Component({
  selector: 'mtm-subscription-upgrade-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SubscriptionUpgradeSelectPlanComponent implements OnInit {
  @ViewChild('loadingRef', { static: true }) public loadingRef: LoadingDataComponent;
  isLoading: boolean = false;
  plans: any[] = [];
  euroCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
  constructor(
    private modalService: NgbModal,
    private router: Router,
    public subscriptionService: SubscriptionService,
    private planService: SubscriptionPlanService,
    private authService: AuthService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.loadingRef.initParameters('', '', []);
    if (this.subscriptionService.upgradeData) {
      if (!this.subscriptionService.upgradeData.billingType)
        this.subscriptionService.upgradeData.billingType = BILLING_TYPE.MONTHLY;
    }
    this.isLoading = true;
    this.loadingRef.showLoading();
    this.subscriptionService.getCurrentPlan().subscribe(res => {
      this.companyService.getCompanyById(this.authService.getAuthUser().companyId)
        .subscribe(company => {
          this.subscriptionService.currency = this.planService.getCompanyBillingCurrency(company);
          forkJoin(
            this.planService.getPlans(),
            this.subscriptionService.getCurrentPlan(),
            this.subscriptionService.getSubscriptionInfo()
          ).subscribe(
            result => {
              this.subscriptionService.upgradeData.plansInfo = result[0];
              this.plans = result[0].plans;
              this.plans.sort((a: any, b: any) => { return a.order - b.order });
              let { planType, accountStatus } = result[1];
              let subscriptionInfo: any = result[2];
              let billingPeriodInMonth: number, billingPeriodInString: string;
              if (subscriptionInfo.interval === 'MONTHLY') {
                billingPeriodInString = BILLING_TYPE.MONTHLY;
                billingPeriodInMonth = 1;
              } else if (subscriptionInfo.interval === 'QUARTERLY') {
                billingPeriodInString = BILLING_TYPE.QUARTERLY;
                billingPeriodInMonth = 3;
              } else if (subscriptionInfo.interval === 'BIANNUAL') {
                billingPeriodInString = BILLING_TYPE.SEMI_ANNUAL;
                billingPeriodInMonth = 6;
              } else if (subscriptionInfo.interval === 'ANNUAL') {
                billingPeriodInString = BILLING_TYPE.ANNUAL;
                billingPeriodInMonth = 12;
              }
              this.subscriptionService.upgradeData.previousBillingType = billingPeriodInString;
              this.subscriptionService.upgradeData.accountStatus = accountStatus;
              planType = `${planType}_PLAN`;
              //if current user doesn't have any plan then redirect
              if (!planType) {
                this.router.navigate(['/projects']);
                return;
              }

              let oldPlan = this.plans.find(p => p.code == planType);
              if (!oldPlan) {
                console.log(`Plan ${planType} not found !`);
                this.router.navigate(['/projects']);
                return;
              }

              this.subscriptionService.upgradeData.previousPlan = oldPlan;
              this.chooseBillingType(billingPeriodInString);
              this.choosePlan(this.plans.find((plan) => plan.code === planType));
              this.isLoading = false;
              this.loadingRef.hide();
            },
            error => {
              this.isLoading = false;
              this.loadingRef.hide();
              this.router.navigate(['/projects']);
            }
          );
        })
    });
  }

  chooseBillingType(billingType: string) {
    this.subscriptionService.upgradeData.billingType = billingType;
  }

  choosePlan(plan: any) {
    this.subscriptionService.upgradeData.plan = plan;
    this.subscriptionService.upgradeData.isDowngrade = this.subscriptionService.upgradeData.previousPlan.order > this.subscriptionService.upgradeData.plan.order;
    EmitterService.get(UpgradePlanStateChanged).emit({ plan });
  }

  ngOnChanges(changes: SimpleChanges) { }

  openContactUsModal() {
    this.modalService.open(SubscriptionRegisterContactUsComponent);
  }

  proceed() {
    if (!this.subscriptionService.upgradeData.previousPlan || !this.subscriptionService.upgradeData.plan) {
      return;
    }
    this.subscriptionService.planSelected = true;
    if (this.subscriptionService.upgradeData.previousPlan.order > this.subscriptionService.upgradeData.plan.order) {
      EmitterService.get(UpgradePlanStateChanged).emit({ planSelected: true });
      this.router.navigate(['/subscription/upgrade/compare-plan']);
    } else {
      this.subscriptionService.planCompared = true;
      EmitterService.get(UpgradePlanStateChanged).emit({ planSelected: true, planCompared: true });
      this.router.navigate(['/subscription/upgrade/confirm-plan']);
    }

  }

  getCompanyCurrency(company) {
    return this.euroCountries.indexOf(company.billingAddress.country) > -1 ? 'EUR' : 'USD';
  }

}
