export interface NotificationResult {
	data: NotificationItem[];
	hasNext: boolean;
	pagingState: string;
}

export interface NotificationItem {
	category: string;
	code: string;
	date?: number;
	isToday: boolean;
	notificationDisplay: any;
	object: any;
	objectJson: string;
	projectId: string;
	seen: boolean;
	time: number;
	username: string;
	dateString?: string;
}

export enum NotificationViewMode {
	List = 1,
	RecentActivity = 2,
	Toast = 3
}

export const AllNotificationsReadEvent = 'notifications.allRead';