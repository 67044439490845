<div class="item-icon-container" [style.width]="wrapperWidth" [style.height]="wrapperHeight"
	[style.backgroundColor]="containerBackground">
	<div class="img-container" *ngIf="imageUrl" [class.square]="isSquare">
		<img src="{{ finalImageUrl }}" [style.width]="imageWidth" [style.height]="imageHeight"
			[ngStyle]="{'border-radius': borderRadius}" />
	</div>
	<div class="initial-container" *ngIf="!imageUrl && !generatedTextColor" [style.backgroundColor]="initialColor"
		[class.square]="isSquare">
		<span class="initial" [style.fontSize]="fontSize" [class.bold]="fontBold">
			{{initialName}}
		</span>
	</div>
	<div class="initial-container" *ngIf="!imageUrl && generatedTextColor" [style.color]="initialColor"
		[style.width]="imageWidth" [style.height]="imageHeight" [style.backgroundColor]="generatedBackground"
		[class.square]="isSquare" [style.margin]="'auto'" >
		<span class="initial" [style.fontSize]="fontSize" [class.bold]="fontBold">
			{{initialName}}
		</span>
	</div>
</div>