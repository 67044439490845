import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { catchError, map } from "rxjs/operators";
import { throwError as observableThrowError } from 'rxjs';
import { BearerResponse } from "../cores/models/bearer-response";

@Injectable({
    providedIn: 'root'
})
export class AIFramingServiceBase {

    protected credentials: BearerResponse;

    protected baseUrl: string;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.aIFramingBaseAPIUrl;
    }

    getFormUrlEncodedHeaders(): any {
        let headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/x-www-form-urlencoded');
        return headers;
    }

    getCredentials() {
        return this.credentials;
    }

    setCredentials(token) {
        this.credentials = token;
    }

    getHeaders(uploadFiles = false): any {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.credentials.access_token}`);
        if (!uploadFiles) {
            headers.set('Accept', 'application/json')
            headers.set('Content-Type', 'application/json')
        }
        return headers;
    }

    httpGet(urlPath) {
        const headers = this.getHeaders();
        return this.httpClient.get(`${this.baseUrl}${urlPath}`, { headers: headers, withCredentials: true });
    }

    httpFormUrlEncodedPost(urlPath, body) {
        const params = new URLSearchParams();
        Object.entries(body).forEach(([key, value]) => {
            params.set(key.toString(), value.toString());
        });
        let options = {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json'),
            withCredentials: true
        };
        return this.httpClient.post(this.baseUrl + urlPath, params.toString(), options).pipe(
            map(response => response),
            catchError(error => {
                console.log(error);
                return observableThrowError(error.json ? error.json() : error);
            }));
    }

    httpPost(urlPath, body, uploadFiles = false) {
        let headers: any = this.getHeaders(uploadFiles);
        return this.httpClient.post(this.baseUrl + urlPath, body, { headers: headers, withCredentials: true }).pipe(
            map(response => response),
            catchError(error => {
                return observableThrowError(error.json ? error.json() : error);
            }));
    }

    httpPut(urlPath, body) {
        let headers: any = this.getHeaders();
        return this.httpClient.put(this.baseUrl + urlPath, body, { headers: headers, withCredentials: true }).pipe(
            map(response => response),
            catchError(error => {
                return observableThrowError(error.json ? error.json() : error);
            }));
    }

    httpPatch(urlPath, body) {
        let headers: any = this.getHeaders();
        return this.httpClient.patch(this.baseUrl + urlPath, body, { headers: headers, withCredentials: true }).pipe(
            map(response => response),
            catchError(error => {
                return observableThrowError(error.json ? error.json() : error);
            }));
    }

    httpDelete(urlPath) {
        let headers: any = this.getHeaders();
        return this.httpClient.delete(this.baseUrl + urlPath, { headers: headers, withCredentials: true }).pipe(
            map(response => response),
            catchError(error => {
                return observableThrowError(error.json ? error.json() : error);
            }));
    }

    errorHandler() {

    }
}