<div class="custom-area">
    <div class="expanded">
        <div class="section-title">Production Partner</div>
        <div class="partner-container">
            <p class="text-left" style="color: white">{{'prodPartnerSpecify' | translate}}</p>
            <ul class="list-inline steps">
                <li class="list-inline-item" *ngFor="let link of links; let i = index"
                    [ngClass]="{'step-complete': link.isComplete || link.isActive, 'step-inactive':!link.isComplete && !link.isActive}">
                    <a (click)="selectStep(i)">
                        <i *ngIf="link.isComplete" class="fa fa-check-circle" aria-hidden="true"></i>
                        <div *ngIf="!link.isComplete" class="step-number">{{i + 1}}</div>
                        <span class="text-uppercase">{{ link.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">


            <div class="col">
                <mtm-details [hidden]="stepName != 'content'" (onProjectIdChange)="onProjectIdChange($event)"
                    [isOneShotProject]="true"></mtm-details>
                <mtm-schedule-partner-selection [hidden]="stepName != 'scheduleAndPricing'"
                    (onSubmitScheduleAndPrice)="onScheduleAndPriceSave($event)" [projectId]="projectId"
                    [isOneShotProject]="true"></mtm-schedule-partner-selection>
                <mtm-company-profiles (onGetStartedClick)="onGetStartedClick($event)"
                    (onContentClick)="onContentClick($event)" *ngIf="stepName == 'companyProfileSuggestions'"
                    [projectId]="projectId" [isOneShotProject]="true"></mtm-company-profiles>
            </div>
        </div>
    </div>
</div>