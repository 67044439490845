import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'user-cell',
    templateUrl: './user-cell.component.html',
    styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements ICellRendererAngularComp, OnInit {
    params: any;

    constructor() { }

    ngOnInit(): void { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params.data;
        return true;
    }
}

