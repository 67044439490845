import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "../services/translate.service";
import moment from "moment";

@Pipe({
	name: 'mtmDateTime',
	pure: false
})
export class MtmDateTimePipe implements PipeTransform {

	constructor(private authService: AuthService, private translateService: TranslateService) {
	}
	transform(value: any): string {
		const { dateFormat = 'YYYY-MM-DD', timeFormat = '12hs' } = this.authService.getAuthUserSettings() || {};
		const lang = this.translateService.getLang() as string;
		let formattedTime = 'hh:mm a';
		let newValue: string = value;
		let dateValue: any = value;

		if (!(value instanceof Date)) {
			let tmpDate = new Date(value);
			if (!tmpDate.getTime()) {
				return value;
			}
			dateValue = tmpDate;
		}

		if (dateValue instanceof Date) {
			if (timeFormat === '24hs') {
				formattedTime = 'HH:mm';
			}

			const dateTimeFormat = `${dateFormat} ${formattedTime}`;
			newValue = moment(value).locale(lang).format(dateTimeFormat);
		}

		return newValue;
	}
}
