import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  encrypted: any = "";
  decrypted: any = "";
  has: any = '';
  constructor() { }

  encryptString(text: string) {
    //encrypt
    let wordArray = CryptoJS.enc.Utf8.parse(text);
    let base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64;
  }

  decryptUsingAES256(encodedText: string) {
    //decrypt
    let parsedWordArray = CryptoJS.enc.Base64.parse(encodedText);
    let parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return parsedStr;
  }

}