export const PLUS_ONE_AREA_CODE = {
	"201": {
		"country": "US",
		"location": "NJ"
	},
	"202": {
		"country": "US",
		"location": "DC"
	},
	"203": {
		"country": "US",
		"location": "CT"
	},
	"204": {
		"country": "CA",
		"location": "MANITOBA"
	},
	"205": {
		"country": "US",
		"location": "AL"
	},
	"206": {
		"country": "US",
		"location": "WA"
	},
	"207": {
		"country": "US",
		"location": "ME"
	},
	"208": {
		"country": "US",
		"location": "ID"
	},
	"209": {
		"country": "US",
		"location": "CA"
	},
	"210": {
		"country": "US",
		"location": "TX"
	},
	"212": {
		"country": "US",
		"location": "NY"
	},
	"213": {
		"country": "US",
		"location": "CA"
	},
	"214": {
		"country": "US",
		"location": "TX"
	},
	"215": {
		"country": "US",
		"location": "PA"
	},
	"216": {
		"country": "US",
		"location": "OH"
	},
	"217": {
		"country": "US",
		"location": "IL"
	},
	"218": {
		"country": "US",
		"location": "MN"
	},
	"219": {
		"country": "US",
		"location": "IN"
	},
	"220": {
		"country": "US",
		"location": "OH"
	},
	"223": {
		"country": "US",
		"location": "PA"
	},
	"224": {
		"country": "US",
		"location": "IL"
	},
	"225": {
		"country": "US",
		"location": "LA"
	},
	"226": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"228": {
		"country": "US",
		"location": "MS"
	},
	"229": {
		"country": "US",
		"location": "GA"
	},
	"231": {
		"country": "US",
		"location": "MI"
	},
	"234": {
		"country": "US",
		"location": "OH"
	},
	"236": {
		"country": "CA",
		"location": "BRITISH COLUMBIA"
	},
	"239": {
		"country": "US",
		"location": "FL"
	},
	"240": {
		"country": "US",
		"location": "MD"
	},
	"242": {
		"country": "BS",
		"location": "BAHAMAS"
	},
	"246": {
		"country": "BB",
		"location": "BARBADOS"
	},
	"248": {
		"country": "US",
		"location": "MI"
	},
	"249": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"250": {
		"country": "CA",
		"location": "BRITISH COLUMBIA"
	},
	"251": {
		"country": "US",
		"location": "AL"
	},
	"252": {
		"country": "US",
		"location": "NC"
	},
	"253": {
		"country": "US",
		"location": "WA"
	},
	"254": {
		"country": "US",
		"location": "TX"
	},
	"256": {
		"country": "US",
		"location": "AL"
	},
	"260": {
		"country": "US",
		"location": "IN"
	},
	"262": {
		"country": "US",
		"location": "WI"
	},
	"264": {
		"country": "AI",
		"location": "ANGUILLA"
	},
	"267": {
		"country": "US",
		"location": "PA"
	},
	"268": {
		"country": "AG",
		"location": "ANTIGUA/BARBUDA"
	},
	"269": {
		"country": "US",
		"location": "MI"
	},
	"270": {
		"country": "US",
		"location": "KY"
	},
	"272": {
		"country": "US",
		"location": "PA"
	},
	"276": {
		"country": "US",
		"location": "VA"
	},
	"279": {
		"country": "US",
		"location": "CA"
	},
	"281": {
		"country": "US",
		"location": "TX"
	},
	"284": {
		"country": "IO",
		"location": "BRITISH VIRGIN ISLANDS"
	},
	"289": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"301": {
		"country": "US",
		"location": "MD"
	},
	"302": {
		"country": "US",
		"location": "DE"
	},
	"303": {
		"country": "US",
		"location": "CO"
	},
	"304": {
		"country": "US",
		"location": "WV"
	},
	"305": {
		"country": "US",
		"location": "FL"
	},
	"306": {
		"country": "CA",
		"location": "SASKATCHEWAN"
	},
	"307": {
		"country": "US",
		"location": "WY"
	},
	"308": {
		"country": "US",
		"location": "NE"
	},
	"309": {
		"country": "US",
		"location": "IL"
	},
	"310": {
		"country": "US",
		"location": "CA"
	},
	"312": {
		"country": "US",
		"location": "IL"
	},
	"313": {
		"country": "US",
		"location": "MI"
	},
	"314": {
		"country": "US",
		"location": "MO"
	},
	"315": {
		"country": "US",
		"location": "NY"
	},
	"316": {
		"country": "US",
		"location": "KS"
	},
	"317": {
		"country": "US",
		"location": "IN"
	},
	"318": {
		"country": "US",
		"location": "LA"
	},
	"319": {
		"country": "US",
		"location": "IA"
	},
	"320": {
		"country": "US",
		"location": "MN"
	},
	"321": {
		"country": "US",
		"location": "FL"
	},
	"323": {
		"country": "US",
		"location": "CA"
	},
	"325": {
		"country": "US",
		"location": "TX"
	},
	"326": {
		"country": "US",
		"location": "OH"
	},
	"330": {
		"country": "US",
		"location": "OH"
	},
	"331": {
		"country": "US",
		"location": "IL"
	},
	"332": {
		"country": "US",
		"location": "NY"
	},
	"334": {
		"country": "US",
		"location": "AL"
	},
	"336": {
		"country": "US",
		"location": "NC"
	},
	"337": {
		"country": "US",
		"location": "LA"
	},
	"339": {
		"country": "US",
		"location": "MA"
	},
	"340": {
		"country": "US",
		"location": "USVI"
	},
	"341": {
		"country": "US",
		"location": "CA"
	},
	"343": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"345": {
		"country": "KY",
		"location": "CAYMAN ISLANDS"
	},
	"346": {
		"country": "US",
		"location": "TX"
	},
	"347": {
		"country": "US",
		"location": "NY"
	},
	"351": {
		"country": "US",
		"location": "MA"
	},
	"352": {
		"country": "US",
		"location": "FL"
	},
	"360": {
		"country": "US",
		"location": "WA"
	},
	"361": {
		"country": "US",
		"location": "TX"
	},
	"364": {
		"country": "US",
		"location": "KY"
	},
	"365": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"367": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"368": {
		"country": "CA",
		"location": "ALBERTA"
	},
	"380": {
		"country": "US",
		"location": "OH"
	},
	"385": {
		"country": "US",
		"location": "UT"
	},
	"386": {
		"country": "US",
		"location": "FL"
	},
	"401": {
		"country": "US",
		"location": "RI"
	},
	"402": {
		"country": "US",
		"location": "NE"
	},
	"403": {
		"country": "CA",
		"location": "ALBERTA"
	},
	"404": {
		"country": "US",
		"location": "GA"
	},
	"405": {
		"country": "US",
		"location": "OK"
	},
	"406": {
		"country": "US",
		"location": "MT"
	},
	"407": {
		"country": "US",
		"location": "FL"
	},
	"408": {
		"country": "US",
		"location": "CA"
	},
	"409": {
		"country": "US",
		"location": "TX"
	},
	"410": {
		"country": "US",
		"location": "MD"
	},
	"412": {
		"country": "US",
		"location": "PA"
	},
	"413": {
		"country": "US",
		"location": "MA"
	},
	"414": {
		"country": "US",
		"location": "WI"
	},
	"415": {
		"country": "US",
		"location": "CA"
	},
	"416": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"417": {
		"country": "US",
		"location": "MO"
	},
	"418": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"419": {
		"country": "US",
		"location": "OH"
	},
	"423": {
		"country": "US",
		"location": "TN"
	},
	"424": {
		"country": "US",
		"location": "CA"
	},
	"425": {
		"country": "US",
		"location": "WA"
	},
	"430": {
		"country": "US",
		"location": "TX"
	},
	"431": {
		"country": "CA",
		"location": "MANITOBA"
	},
	"432": {
		"country": "US",
		"location": "TX"
	},
	"434": {
		"country": "US",
		"location": "VA"
	},
	"435": {
		"country": "US",
		"location": "UT"
	},
	"437": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"438": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"440": {
		"country": "US",
		"location": "OH"
	},
	"441": {
		"country": "BM",
		"location": "BERMUDA"
	},
	"442": {
		"country": "US",
		"location": "CA"
	},
	"443": {
		"country": "US",
		"location": "MD"
	},
	"445": {
		"country": "US",
		"location": "PA"
	},
	"447": {
		"country": "US",
		"location": "IL"
	},
	"448": {
		"country": "US",
		"location": "FL"
	},
	"450": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"458": {
		"country": "US",
		"location": "OR"
	},
	"463": {
		"country": "US",
		"location": "IN"
	},
	"464": {
		"country": "US",
		"location": "IL"
	},
	"469": {
		"country": "US",
		"location": "TX"
	},
	"470": {
		"country": "US",
		"location": "GA"
	},
	"473": {
		"country": "GD",
		"location": "GRENADA"
	},
	"474": {
		"country": "CA",
		"location": "SASKATCHEWAN"
	},
	"475": {
		"country": "US",
		"location": "CT"
	},
	"478": {
		"country": "US",
		"location": "GA"
	},
	"479": {
		"country": "US",
		"location": "AR"
	},
	"480": {
		"country": "US",
		"location": "AZ"
	},
	"484": {
		"country": "US",
		"location": "PA"
	},
	"501": {
		"country": "US",
		"location": "AR"
	},
	"502": {
		"country": "US",
		"location": "KY"
	},
	"503": {
		"country": "US",
		"location": "OR"
	},
	"504": {
		"country": "US",
		"location": "LA"
	},
	"505": {
		"country": "US",
		"location": "NM"
	},
	"506": {
		"country": "CA",
		"location": "NEW BRUNSWICK"
	},
	"507": {
		"country": "US",
		"location": "MN"
	},
	"508": {
		"country": "US",
		"location": "MA"
	},
	"509": {
		"country": "US",
		"location": "WA"
	},
	"510": {
		"country": "US",
		"location": "CA"
	},
	"512": {
		"country": "US",
		"location": "TX"
	},
	"513": {
		"country": "US",
		"location": "OH"
	},
	"514": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"515": {
		"country": "US",
		"location": "IA"
	},
	"516": {
		"country": "US",
		"location": "NY"
	},
	"517": {
		"country": "US",
		"location": "MI"
	},
	"518": {
		"country": "US",
		"location": "NY"
	},
	"519": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"520": {
		"country": "US",
		"location": "AZ"
	},
	"530": {
		"country": "US",
		"location": "CA"
	},
	"531": {
		"country": "US",
		"location": "NE"
	},
	"534": {
		"country": "US",
		"location": "WI"
	},
	"539": {
		"country": "US",
		"location": "OK"
	},
	"540": {
		"country": "US",
		"location": "VA"
	},
	"541": {
		"country": "US",
		"location": "OR"
	},
	"548": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"551": {
		"country": "US",
		"location": "NJ"
	},
	"557": {
		"country": "US",
		"location": "MO"
	},
	"559": {
		"country": "US",
		"location": "CA"
	},
	"561": {
		"country": "US",
		"location": "FL"
	},
	"562": {
		"country": "US",
		"location": "CA"
	},
	"563": {
		"country": "US",
		"location": "IA"
	},
	"564": {
		"country": "US",
		"location": "WA"
	},
	"567": {
		"country": "US",
		"location": "OH"
	},
	"570": {
		"country": "US",
		"location": "PA"
	},
	"571": {
		"country": "US",
		"location": "VA"
	},
	"572": {
		"country": "US",
		"location": "OK"
	},
	"573": {
		"country": "US",
		"location": "MO"
	},
	"574": {
		"country": "US",
		"location": "IN"
	},
	"575": {
		"country": "US",
		"location": "NM"
	},
	"579": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"580": {
		"country": "US",
		"location": "OK"
	},
	"581": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"582": {
		"country": "US",
		"location": "PA"
	},
	"585": {
		"country": "US",
		"location": "NY"
	},
	"586": {
		"country": "US",
		"location": "MI"
	},
	"587": {
		"country": "CA",
		"location": "ALBERTA"
	},
	"601": {
		"country": "US",
		"location": "MS"
	},
	"602": {
		"country": "US",
		"location": "AZ"
	},
	"603": {
		"country": "US",
		"location": "NH"
	},
	"604": {
		"country": "CA",
		"location": "BRITISH COLUMBIA"
	},
	"605": {
		"country": "US",
		"location": "SD"
	},
	"606": {
		"country": "US",
		"location": "KY"
	},
	"607": {
		"country": "US",
		"location": "NY"
	},
	"608": {
		"country": "US",
		"location": "WI"
	},
	"609": {
		"country": "US",
		"location": "NJ"
	},
	"610": {
		"country": "US",
		"location": "PA"
	},
	"612": {
		"country": "US",
		"location": "MN"
	},
	"613": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"614": {
		"country": "US",
		"location": "OH"
	},
	"615": {
		"country": "US",
		"location": "TN"
	},
	"616": {
		"country": "US",
		"location": "MI"
	},
	"617": {
		"country": "US",
		"location": "MA"
	},
	"618": {
		"country": "US",
		"location": "IL"
	},
	"619": {
		"country": "US",
		"location": "CA"
	},
	"620": {
		"country": "US",
		"location": "KS"
	},
	"623": {
		"country": "US",
		"location": "AZ"
	},
	"626": {
		"country": "US",
		"location": "CA"
	},
	"628": {
		"country": "US",
		"location": "CA"
	},
	"629": {
		"country": "US",
		"location": "TN"
	},
	"630": {
		"country": "US",
		"location": "IL"
	},
	"631": {
		"country": "US",
		"location": "NY"
	},
	"636": {
		"country": "US",
		"location": "MO"
	},
	"639": {
		"country": "CA",
		"location": "SASKATCHEWAN"
	},
	"640": {
		"country": "US",
		"location": "NJ"
	},
	"641": {
		"country": "US",
		"location": "IA"
	},
	"646": {
		"country": "US",
		"location": "NY"
	},
	"647": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"649": {
		"country": "TC",
		"location": "TURKS & CAICOS ISLANDS"
	},
	"650": {
		"country": "US",
		"location": "CA"
	},
	"651": {
		"country": "US",
		"location": "MN"
	},
	"656": {
		"country": "US",
		"location": "FL"
	},
	"657": {
		"country": "US",
		"location": "CA"
	},
	"658": {
		"country": "JM",
		"location": "JAMAICA"
	},
	"659": {
		"country": "US",
		"location": "AL"
	},
	"660": {
		"country": "US",
		"location": "MO"
	},
	"661": {
		"country": "US",
		"location": "CA"
	},
	"662": {
		"country": "US",
		"location": "MS"
	},
	"664": {
		"country": "MS",
		"location": "MONTSERRAT"
	},
	"667": {
		"country": "US",
		"location": "MD"
	},
	"669": {
		"country": "US",
		"location": "CA"
	},
	"670": {
		"country": "US",
		"location": "CNMI"
	},
	"671": {
		"country": "US",
		"location": "GU"
	},
	"672": {
		"country": "CA",
		"location": "BRITISH COLUMBIA"
	},
	"678": {
		"country": "US",
		"location": "GA"
	},
	"680": {
		"country": "US",
		"location": "NY"
	},
	"681": {
		"country": "US",
		"location": "WV"
	},
	"682": {
		"country": "US",
		"location": "TX"
	},
	"683": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"684": {
		"country": "US",
		"location": "AS"
	},
	"689": {
		"country": "US",
		"location": "FL"
	},
	"701": {
		"country": "US",
		"location": "ND"
	},
	"702": {
		"country": "US",
		"location": "NV"
	},
	"703": {
		"country": "US",
		"location": "VA"
	},
	"704": {
		"country": "US",
		"location": "NC"
	},
	"705": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"706": {
		"country": "US",
		"location": "GA"
	},
	"707": {
		"country": "US",
		"location": "CA"
	},
	"708": {
		"country": "US",
		"location": "IL"
	},
	"709": {
		"country": "CA",
		"location": "NEWFOUNDLAND AND LABRADOR"
	},
	"712": {
		"country": "US",
		"location": "IA"
	},
	"713": {
		"country": "US",
		"location": "TX"
	},
	"714": {
		"country": "US",
		"location": "CA"
	},
	"715": {
		"country": "US",
		"location": "WI"
	},
	"716": {
		"country": "US",
		"location": "NY"
	},
	"717": {
		"country": "US",
		"location": "PA"
	},
	"718": {
		"country": "US",
		"location": "NY"
	},
	"719": {
		"country": "US",
		"location": "CO"
	},
	"720": {
		"country": "US",
		"location": "CO"
	},
	"721": {
		"country": "SG",
		"location": "SINT MAARTEN"
	},
	"724": {
		"country": "US",
		"location": "PA"
	},
	"725": {
		"country": "US",
		"location": "NV"
	},
	"726": {
		"country": "US",
		"location": "TX"
	},
	"727": {
		"country": "US",
		"location": "FL"
	},
	"731": {
		"country": "US",
		"location": "TN"
	},
	"732": {
		"country": "US",
		"location": "NJ"
	},
	"734": {
		"country": "US",
		"location": "MI"
	},
	"737": {
		"country": "US",
		"location": "TX"
	},
	"740": {
		"country": "US",
		"location": "OH"
	},
	"742": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"743": {
		"country": "US",
		"location": "NC"
	},
	"747": {
		"country": "US",
		"location": "CA"
	},
	"753": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"754": {
		"country": "US",
		"location": "FL"
	},
	"757": {
		"country": "US",
		"location": "VA"
	},
	"758": {
		"country": "LK",
		"location": "ST. LUCIA"
	},
	"760": {
		"country": "US",
		"location": "CA"
	},
	"762": {
		"country": "US",
		"location": "GA"
	},
	"763": {
		"country": "US",
		"location": "MN"
	},
	"765": {
		"country": "US",
		"location": "IN"
	},
	"767": {
		"country": "DM",
		"location": "DOMINICA"
	},
	"769": {
		"country": "US",
		"location": "MS"
	},
	"770": {
		"country": "US",
		"location": "GA"
	},
	"771": {
		"country": "US",
		"location": "DC"
	},
	"772": {
		"country": "US",
		"location": "FL"
	},
	"773": {
		"country": "US",
		"location": "IL"
	},
	"774": {
		"country": "US",
		"location": "MA"
	},
	"775": {
		"country": "US",
		"location": "NV"
	},
	"778": {
		"country": "CA",
		"location": "BRITISH COLUMBIA"
	},
	"779": {
		"country": "US",
		"location": "IL"
	},
	"780": {
		"country": "CA",
		"location": "ALBERTA"
	},
	"781": {
		"country": "US",
		"location": "MA"
	},
	"782": {
		"country": "CA",
		"location": "NOVA SCOTIA - PRINCE EDWARD ISLAND"
	},
	"784": {
		"country": "LK",
		"location": "ST. VINCENT & GRENADINES"
	},
	"785": {
		"country": "US",
		"location": "KS"
	},
	"786": {
		"country": "US",
		"location": "FL"
	},
	"787": {
		"country": "US",
		"location": "PUERTO RICO"
	},
	"801": {
		"country": "US",
		"location": "UT"
	},
	"802": {
		"country": "US",
		"location": "VT"
	},
	"803": {
		"country": "US",
		"location": "SC"
	},
	"804": {
		"country": "US",
		"location": "VA"
	},
	"805": {
		"country": "US",
		"location": "CA"
	},
	"806": {
		"country": "US",
		"location": "TX"
	},
	"807": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"808": {
		"country": "US",
		"location": "HI"
	},
	"809": {
		"country": "DO",
		"location": "DOMINICAN REPUBLIC"
	},
	"810": {
		"country": "US",
		"location": "MI"
	},
	"812": {
		"country": "US",
		"location": "IN"
	},
	"813": {
		"country": "US",
		"location": "FL"
	},
	"814": {
		"country": "US",
		"location": "PA"
	},
	"815": {
		"country": "US",
		"location": "IL"
	},
	"816": {
		"country": "US",
		"location": "MO"
	},
	"817": {
		"country": "US",
		"location": "TX"
	},
	"818": {
		"country": "US",
		"location": "CA"
	},
	"819": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"820": {
		"country": "US",
		"location": "CA"
	},
	"825": {
		"country": "CA",
		"location": "ALBERTA"
	},
	"826": {
		"country": "US",
		"location": "VA"
	},
	"828": {
		"country": "US",
		"location": "NC"
	},
	"829": {
		"country": "DO",
		"location": "DOMINICAN REPUBLIC"
	},
	"830": {
		"country": "US",
		"location": "TX"
	},
	"831": {
		"country": "US",
		"location": "CA"
	},
	"832": {
		"country": "US",
		"location": "TX"
	},
	"835": {
		"country": "US",
		"location": "PA"
	},
	"838": {
		"country": "US",
		"location": "NY"
	},
	"839": {
		"country": "US",
		"location": "SC"
	},
	"840": {
		"country": "US",
		"location": "CA"
	},
	"843": {
		"country": "US",
		"location": "SC"
	},
	"845": {
		"country": "US",
		"location": "NY"
	},
	"847": {
		"country": "US",
		"location": "IL"
	},
	"848": {
		"country": "US",
		"location": "NJ"
	},
	"849": {
		"country": "DO",
		"location": "DOMINICAN REPUBLIC"
	},
	"850": {
		"country": "US",
		"location": "FL"
	},
	"854": {
		"country": "US",
		"location": "SC"
	},
	"856": {
		"country": "US",
		"location": "NJ"
	},
	"857": {
		"country": "US",
		"location": "MA"
	},
	"858": {
		"country": "US",
		"location": "CA"
	},
	"859": {
		"country": "US",
		"location": "KY"
	},
	"860": {
		"country": "US",
		"location": "CT"
	},
	"862": {
		"country": "US",
		"location": "NJ"
	},
	"863": {
		"country": "US",
		"location": "FL"
	},
	"864": {
		"country": "US",
		"location": "SC"
	},
	"865": {
		"country": "US",
		"location": "TN"
	},
	"867": {
		"country": "CA",
		"location": "YUKON-NW TERR. - NUNAVUT"
	},
	"868": {
		"country": "TO",
		"location": "TRINIDAD & TOBAGO"
	},
	"869": {
		"country": "LK",
		"location": "ST. KITTS & NEVIS"
	},
	"870": {
		"country": "US",
		"location": "AR"
	},
	"872": {
		"country": "US",
		"location": "IL"
	},
	"873": {
		"country": "CA",
		"location": "QUEBEC"
	},
	"876": {
		"country": "JM",
		"location": "JAMAICA"
	},
	"878": {
		"country": "US",
		"location": "PA"
	},
	"901": {
		"country": "US",
		"location": "TN"
	},
	"902": {
		"country": "CA",
		"location": "NOVA SCOTIA - PRINCE EDWARD ISLAND"
	},
	"903": {
		"country": "US",
		"location": "TX"
	},
	"904": {
		"country": "US",
		"location": "FL"
	},
	"905": {
		"country": "CA",
		"location": "ONTARIO"
	},
	"906": {
		"country": "US",
		"location": "MI"
	},
	"907": {
		"country": "US",
		"location": "AK"
	},
	"908": {
		"country": "US",
		"location": "NJ"
	},
	"909": {
		"country": "US",
		"location": "CA"
	},
	"910": {
		"country": "US",
		"location": "NC"
	},
	"912": {
		"country": "US",
		"location": "GA"
	},
	"913": {
		"country": "US",
		"location": "KS"
	},
	"914": {
		"country": "US",
		"location": "NY"
	},
	"915": {
		"country": "US",
		"location": "TX"
	},
	"916": {
		"country": "US",
		"location": "CA"
	},
	"917": {
		"country": "US",
		"location": "NY"
	},
	"918": {
		"country": "US",
		"location": "OK"
	},
	"919": {
		"country": "US",
		"location": "NC"
	},
	"920": {
		"country": "US",
		"location": "WI"
	},
	"925": {
		"country": "US",
		"location": "CA"
	},
	"928": {
		"country": "US",
		"location": "AZ"
	},
	"929": {
		"country": "US",
		"location": "NY"
	},
	"930": {
		"country": "US",
		"location": "IN"
	},
	"931": {
		"country": "US",
		"location": "TN"
	},
	"934": {
		"country": "US",
		"location": "NY"
	},
	"936": {
		"country": "US",
		"location": "TX"
	},
	"937": {
		"country": "US",
		"location": "OH"
	},
	"938": {
		"country": "US",
		"location": "AL"
	},
	"939": {
		"country": "US",
		"location": "PUERTO RICO"
	},
	"940": {
		"country": "US",
		"location": "TX"
	},
	"941": {
		"country": "US",
		"location": "FL"
	},
	"943": {
		"country": "US",
		"location": "GA"
	},
	"945": {
		"country": "US",
		"location": "TX"
	},
	"947": {
		"country": "US",
		"location": "MI"
	},
	"948": {
		"country": "US",
		"location": "VA"
	},
	"949": {
		"country": "US",
		"location": "CA"
	},
	"951": {
		"country": "US",
		"location": "CA"
	},
	"952": {
		"country": "US",
		"location": "MN"
	},
	"954": {
		"country": "US",
		"location": "FL"
	},
	"956": {
		"country": "US",
		"location": "TX"
	},
	"959": {
		"country": "US",
		"location": "CT"
	},
	"970": {
		"country": "US",
		"location": "CO"
	},
	"971": {
		"country": "US",
		"location": "OR"
	},
	"972": {
		"country": "US",
		"location": "TX"
	},
	"973": {
		"country": "US",
		"location": "NJ"
	},
	"978": {
		"country": "US",
		"location": "MA"
	},
	"979": {
		"country": "US",
		"location": "TX"
	},
	"980": {
		"country": "US",
		"location": "NC"
	},
	"983": {
		"country": "US",
		"location": "CO"
	},
	"984": {
		"country": "US",
		"location": "NC"
	},
	"985": {
		"country": "US",
		"location": "LA"
	},
	"986": {
		"country": "US",
		"location": "ID"
	},
	"989": {
		"country": "US",
		"location": "MI"
	}
}