import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { WorkspaceDashboardComponent } from './workspace-dashboard/workspace-dashboard.component';
import { CampaignDriveComponent } from './campaign-drive/campaign-drive.component';
import { StandAloneProjectsComponent } from './stand-alone-projects/stand-alone-projects.component';
import { ProjectsComponent } from './projects/projects.component';
import { SharedComponent } from './shared/shared.component';
import { UseronboardingtoolModule } from 'app/useronboardingtool/useronboardingtool.module';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: '',
        component: WorkspaceDashboardComponent
      },
      {
        path: 'campaign-drive',
        component: CampaignDriveComponent
      },
      {
        path: 'projects',
        component: ProjectsComponent
      },
      {
        path: 'shared',
        component: SharedComponent
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./campaigns/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: 'stand-alone-project',
        component: StandAloneProjectsComponent
      },
      {
        path: 'campaigndrive',
        loadChildren: () => import('./timeline-generator/timeline-generator.module').then(m => m.TimelineGeneratorModule)
      },
      {
        path: 'campaigndrive/dashboard',
        loadChildren: () => import('./campaign-drive-dashboard/campaign-drive-dashboard.module').then(m => m.CampaignDriveDashboardModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
      },
    ]
  },
  {
    path:'gamification',
    loadChildren: ()=> import('app/useronboardingtool/useronboardingtool.module').then(m => m.UseronboardingtoolModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkspaceV2RoutingModule { }
