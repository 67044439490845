import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private initialized: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  //for 3rd party scripts which needs to check application information
  loadScripts() {
    if (this.initialized) return;
    this.loadCrisp();
    this.initialized = true;
  }

  private loadCrisp() {
    const window = document.defaultView;
    (window as any).CRISP_WEBSITE_ID = "9927123f-86f6-442d-b635-f4e4dfa1dd06";
    let locale = localStorage.getItem('auth_user_language') || 'en';
    if (locale == 'en_us') { locale = 'en' }
    (window as any).CRISP_RUNTIME_CONFIG = {
      locale
    };
    const script = document.createElement('script');
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    this.document.body.appendChild(script);
  }
}
