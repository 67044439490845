import { ImageComp } from "../lib/image-component";

export class Shape extends ImageComp {

	shape: any;

	constructor(image: any, shape = null) {
		super(image);
		this.shape = shape;
	}

	openShape(id: string, resolution: any, excludeFromExport = true) {
		if (!this.shape) return;

		if (this.shape) {
			let canvasFabric = this.plugin.canvasFabric;
			let annotation_data = JSON.parse(this.shape)
			const scale = canvasFabric.getWidth() / resolution.width;

			if (!excludeFromExport) {
				canvasFabric.remove(...canvasFabric.getObjects());
			}

			fabric.util.enlivenObjects(annotation_data.objects, function (objects) {
				objects.forEach(function (o) {
					o.set('left', o.left * scale);
					o.set('top', o.top * scale);
					o.set('scaleX', o.scaleX * scale);
					o.set('scaleY', o.scaleY * scale);
					o.set('strokeWidth', o.strokeWidth / scale);
					o.set('id', id);
					o.set('selectable', false);
					o.set('hoverCursor', 'cursor');
					o.set('excludeFromExport', excludeFromExport);
					o.setCoords();
					canvasFabric.add(o);
				});
				canvasFabric.renderAll();
			});
		};

	}

	closeShape(id: string) {
		const canvasFabric = this.plugin.canvasFabric;
		canvasFabric.getObjects().forEach(function (o) {
			if (o.id == id) {
				canvasFabric.remove(o);
			}
		});
		canvasFabric.renderAll();
	}
}