<div class="confirm-plan-container">
	<form class="upgrade-form d-flex flex-column" novalidate *ngIf="currentPlan">
		<div class="confirm-plan-inner">
			<div class="text-center mb-3 selection">
				<div class="mb-2 label">{{'subscription_register_confirm_plan_youChoose'|translate}}</div>
				<div class="selected-plan">{{ currentPlan?.name }}</div>
			</div>

			<div class="text-center mb-5 billing-cycle" *ngIf="!isFree">
				<div class="mb-3 font-weight-bold label">
					{{'subscription_register_confirm_plan_chooseBillingCycle'|translate}}</div>

				<div class="mx-auto pricing-selector">
					<div class="selector"></div>
					<ul>
						<li [class.current]="selectedPeriod == BillingType.MONTHLY"
							(click)="changePeriod(BillingType.MONTHLY)">
							<div class="interval">{{'subscription_register_select_plan_1month'|translate}}</div>
						</li>
						<li [class.current]="selectedPeriod == BillingType.QUARTERLY"
							(click)="changePeriod(BillingType.QUARTERLY)">
							<div class="interval">{{'subscription_register_select_plan_3months'|translate}}</div>
							<div class="desc">{{'subscription_register_select_plan_save5%'|translate}}</div>
						</li>
						<li [class.current]="selectedPeriod == BillingType.SEMI_ANNUAL"
							(click)="changePeriod(BillingType.SEMI_ANNUAL)">
							<div class="interval">{{'subscription_register_select_plan_6months'|translate}}</div>
							<div class="desc">{{'subscription_register_select_plan_save10%'|translate}}</div>
						</li>
						<li [class.current]="selectedPeriod == BillingType.ANNUAL"
							(click)="changePeriod(BillingType.ANNUAL)">
							<div class="interval">{{'subscription_register_select_plan_1year'|translate}}</div>
							<div class="desc">{{'subscription_register_select_plan_save20%'|translate}}</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="d-flex mb-5 align-items-center justify-content-center plan-info">
				<div class="label included">{{'subscription_register_confirm_include'|translate}}</div>
				<div class="users">
					<div class="value">{{ currentPlan.minUsers }}</div>
					<div class="metric">{{'subscription_register_confirm_plan_user'|translate}}</div>
				</div>
				<div class="active-storage">
					<div class="text-uppercase value">{{ currentPlan.activeStorage.value + ' ' +
						currentPlan.activeStorage.metric }}</div>
					<div class="metric">{{'subscription_register_confirm_plan_activeStorage'|translate}}</div>
				</div>
				<div class="archival-storage">
					<div class="text-uppercase value">{{ currentPlan.archivalStorage.value + ' ' +
						currentPlan.archivalStorage.metric }}</div>
					<div class="metric">{{'subscription_register_confirm_plan_archivalStorage'|translate}}</div>
				</div>
				<div class="cost">
					<div class="label d-inline-block cost-label">{{'subscription_register_confirm_plan_cost'|translate}}:
					</div>
					<div class="d-inline-block value">{{subscriptionService.currency === 'USD' ? '$' : '€'}} {{
						totalPlanCost | mtmCurrency : 0 }}<span *ngIf="!isFree"> / {{ 'subscription_register_select_plan_' +
							selectedPeriod | translate }}</span></div>
				</div>
			</div>

			<div class="options" *ngIf="!isFree">
				<span class="caption">{{'subscription_register_confirm_plan_options'|translate}}</span>

				<div class="addons-container">

					<div class="mb-5 d-flex justify-content-center">
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_costPerUserPerMonth'|translate}}
								per
								{{ 'subscription_register_select_plan_month' | translate }}</div>
							<div class="value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{userCost?.perMonthValue || 0 | mtmCurrency : 0}}</div>
						</div>
						<div class="item">
							<div class="label label-center">
								{{'subscription_register_confirm_plan_additionalUsers'|translate}}
							</div>
							<div class="number-field">
								<button [disabled]="!userCount" class="minus"
									(click)="decrement(AddOnTypes.Users)">-</button>
								<input type="number" min="0" name="extra-users" [(ngModel)]="userCount"
									(ngModelChange)="updateTotalCost(AddOnTypes.Users)">
								<button [disabled]="userCount >= currentPlan.maxUsers - currentPlan.minUsers"
									class="plus" (click)="increment(AddOnTypes.Users)">+</button>
							</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_costPerMonth' | translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalUserCostPerMonth | mtmCurrency: 0}} / {{ 'subscription_register_select_plan_month' |
								translate }}</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_cost'|translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalUserCost | mtmCurrency: 0 }} / {{ 'subscription_register_select_plan_' +
								selectedPeriod | translate }}</div>
						</div>
					</div>

					<div class="mb-5 d-flex justify-content-center">
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_activeStorageCost'|translate}} per
								{{ 'subscription_register_select_plan_month' | translate }}</div>
							<div class="value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{activeStorageCost?.perMonthValue || 0 | mtmCurrency: 0}}</div>
						</div>
						<div class="item">
							<div class="label label-center">
								{{'subscription_register_confirm_plan_activeStorageCount'|translate}}</div>
							<div class="number-field">
								<button [disabled]="!activeStorageCount" class="minus"
									(click)="decrement(AddOnTypes.ActiveStorage)">-</button>
								<input type="number" min="0" name="extra-active-storage" [(ngModel)]="activeStorageCount"
									(ngModelChange)="updateTotalCost(AddOnTypes.ActiveStorage)">
								<button class="plus" (click)="increment(AddOnTypes.ActiveStorage)">+</button>
							</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_costPerMonth' | translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalActiveStorageCostPerMonth | mtmCurrency: 0}} / {{
								'subscription_register_select_plan_month' | translate }}</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_cost'|translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalActiveStorageCost | mtmCurrency: 0}} / {{ 'subscription_register_select_plan_' +
								selectedPeriod | translate }}</div>
						</div>
					</div>

					<div class="d-flex justify-content-center">
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_archivalStorageCost'|translate}}
								per {{ 'subscription_register_select_plan_month' | translate }}</div>
							<div class="value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{archivalStorageCost?.perMonthValue || 0 | mtmCurrency: 0 }}</div>
						</div>
						<div class="item">
							<div class="label label-center">
								{{'subscription_register_confirm_plan_archivalStorageCount'|translate}}</div>
							<div class="number-field">
								<button [disabled]="!archivalStorageCount" class="minus"
									(click)="decrement(AddOnTypes.ArchivalStorage)">-</button>
								<input type="number" min="0" name="extra-archival" [(ngModel)]="archivalStorageCount"
									(ngModelChange)="updateTotalCost(AddOnTypes.ArchivalStorage)">
								<button class="plus" (click)="increment(AddOnTypes.ArchivalStorage)">+</button>
							</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_costPerMonth' | translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalArchivalStorageCostPerMonth | mtmCurrency: 0 }} / {{
								'subscription_register_select_plan_month' | translate }}</div>
						</div>
						<div class="item">
							<div class="label">{{'subscription_register_confirm_plan_cost'|translate}}</div>
							<div class="total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
								{{totalArchivalStorageCost | mtmCurrency: 0 }} / {{ 'subscription_register_select_plan_' +
								selectedPeriod | translate }}</div>
						</div>
					</div>
				</div>

			</div>


			<div class="text-center font-weight-bold pt-4 total-options" *ngIf="!isFree">
				<span class="label">{{'subscription_register_confirm_plan_totalAddOnCost'|translate}}:</span>

				<span class="orange total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}} {{totalAddOnCost
					| mtmCurrency: 0 }} / {{ 'subscription_register_select_plan_' + selectedPeriod | translate }}</span>
			</div>


		</div>
		<div class="text-center pt-4 total-cost">
			<div class="font-weight-bold total-info">
				<span *ngIf="isFree"
					class="orange label">{{'subscription_register_confirm_plan_totalCost_trial'|translate}}</span>
				<span *ngIf="!isFree"
					class="orange label">{{'subscription_register_confirm_plan_totalCost'|translate}}</span>
				<span *ngIf="isFree" class="ml-2 orange total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
					{{totalPayment | mtmCurrency: 0 }}</span>
				<span *ngIf="!isFree" class="ml-2 orange total-value">{{subscriptionService.currency === 'USD' ? '$' : '€'}}
					{{totalPayment | mtmCurrency: 0 }} / {{'subscription_register_select_plan_' +
					selectedPeriod|translate}}</span>
				<div *ngIf="!isFree" class="info-icon tooltipped _600"
					[attr.data-title]="'confirm_plan_amount_info'|translate"></div>
			</div>

			<div *ngIf="!isFree" class="foot-note">
				<span>{{'subscription_register_confirm_plan_renew'|translate}}</span>
				<div class="info-icon tooltipped _600" [attr.data-title]="'confirm_plan_amount_cancel'|translate"></div>
			</div>

			<div class="d-flex align-items-center justify-content-center flex-column register-form-navigation mt-3">
				<div class="d-inline-flex">
					<a class="btn mtm-button mtm-main-button" [style.color]="'#FFFFFF'"
						[style.text-transform]="'uppercase'" (click)="proceed()">
						{{'continue'|translate}}
					</a>
				</div>
			</div>
		</div>

	</form>
</div>