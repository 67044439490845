import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/services/auth.service";
import { EntryModule } from "app/sub-modules/entry-module/entry-module";
import { SharedModule } from "app/sub-modules/SharedModule";
import { VerificationComponent } from "./components/verification/verification.component";
import { VerificationGuard } from "./shareds/core/verification.guard";
import { VerificationService } from "./shareds/services/verification.service";
import { VerificationRoutingModule } from "./verification.route";

@NgModule({
    declarations: [
        VerificationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
        VerificationRoutingModule,
        EntryModule
    ],
    exports: [],
    providers: [
        AuthService,
        VerificationService,
        VerificationGuard
    ]
})
export class VerificationModule { }