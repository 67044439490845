<div class="modal-body">
	<form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
		<h5 class="text-center">Add New User</h5>
		<div class="row mt-4">
			<div class="col">
				<p class="text-muted mb-1">E-Mail</p>
				<label class="form-group has-float-label" [ngClass]="{'has-danger': isValid('username')}">
					<input class="form-control" type="text" formControlName="username" placeholder=" "
						[ngClass]="{'form-control-danger': isValid('username')}">
					<span [ngClass]="{'text-danger': isValid('username')}">Email address{{ (isValid('username')) ? ' is
						required' : '' }}</span>
				</label>

				<p class="text-muted mb-1">Job Title</p>
				<label class="form-group  has-float-label">
					<input class="form-control" type="text" formControlName="jobTitle" placeholder="Job Title"
						[ngClass]="{'has-danger': isValid('jobTitle')}">
				</label>

				<p class="text-muted mb-1">Role</p>
				<select class="dropdown-toggle mb-2 form-control" formControlName="userRole"
					[ngClass]="{'has-danger': isValid('userRole')}">
					<option value="">Please select the role...</option>
					<option *ngFor="let role of allRoles" [ngValue]=role.code>{{ role.label }}</option>
				</select>
			</div>
			<br>
		</div>

		<hr>

		<div class="row">
			<div class="col">
				<button class="btn btn-outline-secondary btn-lg btn-block" type="button" (click)="activeModal.close()">
					<span>Cancel</span>
				</button>
			</div>
			<div class="col">
				<button class="btn btn-danger btn-lg btn-block" type="submit" [disabled]="!addUserForm.valid">
					<h6 class="mb-0">Add New User</h6>
				</button>
			</div>
		</div>
	</form>
</div>