<div class="image-drawing"
	[ngClass]="{ 'd-flex': !isExpanded, 'block': isExpanded, 'hidden': checkInvalidUser() }">
	<!-- <div *ngIf="!isExpanded" class="annotation-nav-total d-flex align-items-center justify-content-center"
		[ngClass]="{ 'player-ready': isPlayerReady }">
		{{totalAnnotations}}
	</div> -->
	<div class="image-drawing-container drawingTools d-flex" [ngClass]="{ 'expanded': isExpanded }">
		<!-- <div title="Toggle Annotation Toolbar" class="annotation-nav-toggle" (click)="createAnnotation()"
			[hidden]="isExpanded">
			<div class="annotation-nav-toggle-icon d-flex align-items-center justify-content-center">
				<svg _ngcontent-c82="" height="40" viewBox="0 0 40 40" width="24">
					<path _ngcontent-c82="" class="st0" d="M34.9,0c0.4,0,0.7,0.2,0.9,0.4l4.8,4.8c0.5,0.5,0.5,1.3,0,1.8l-0.9,0.9V32c0,2.3-1.9,4.2-4.2,4.2H18l-4.5,4.5
c-0.4,0.4-1,0.4-1.4,0l-4.5-4.5H4.2C1.9,36.2,0,34.3,0,32V5.4c0-2.3,1.9-4.2,4.2-4.2h28.9L34,0.4C34.2,0.2,34.5,0,34.8,0L34.9,0z
 M32.3,27.5h-25c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h25c0.5,0,1-0.4,1-1C33.3,28,32.9,27.5,32.3,27.5L32.3,27.5z M14.7,20.8H7.4
c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h7.4c0.5,0,1-0.4,1-1C15.7,21.2,15.3,20.8,14.7,20.8L14.7,20.8z M30.4,7.6l-8.6,8.6v3h3l8.6-8.6
L30.4,7.6z M14.7,14.1H7.4c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h7.4c0.5,0,1-0.4,1-1C15.7,14.5,15.3,14.1,14.7,14.1L14.7,14.1z
 M19.5,7.7H7.4c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h12.2c0.5,0,1-0.4,1-1C20.5,8.1,20.1,7.7,19.5,7.7L19.5,7.7z M34.9,3.1l-2.7,2.7
l3,3l2.7-2.7L34.9,3.1z M34.9,3.1"></path>
				</svg>
			</div>
		</div> -->
		<div class="pl-2 annotation-mode-toggle d-flex align-items-center" title>
			<ui-switch switchColor="#43CB9A" defaultBgColor="#FFFFFF" size="small" class="annotation-mode-switch" [(ngModel)]="annotationToolbarActive" (ngModelChange)="toggleAnnotationToolbarDisplay(annotationToolbarActive)"></ui-switch>
		</div>
		<div class="annotation-nav d-flex align-items-center flex-fill" [ngClass]="{'disabled': !annotationToolbarActive}">
			<!-- <div class="userAvatar pr-1">
				<img class="rounded-circle" [src]="authUser.avatarUrl" height="30px" width="30px" alt="">
			</div> -->
			<div class="annotation-nav-time-range time-range d-flex align-items-center">
				<div id="startTime" title="Annotation Start Time"
					class="time-display-start text-center d-flex align-items-center justify-content-center"
					[ngClass]="{'format-open': isTimeFormatOpen}">
					<i id="startTimeAdd" class="fa fa-caret-up" aria-hidden="true"
						(click)="onStartTimeRangeChange('add')"></i>
					<span [innerHTML]="getTimeDisplay(startTime, true)"></span>
					<i id="startTimeMin" class="fa fa-caret-down" aria-hidden="true"
						(click)="onStartTimeRangeChange('min')"></i>
				</div>
				<i class="fa fa-play mx-2" style="font-size: xx-small;color: #898C98"></i>
				<div id="endTime" title="Annotation End Time"
					class="time-display-end text-center d-flex align-items-center justify-content-center"
					[ngClass]="{'format-open': isTimeFormatOpen}">
					<i id="endTimeAdd" class="fa fa-caret-up" aria-hidden="true"
						(click)="onEndTimeRangeChange('add')"></i>
					<span [innerHTML]="getTimeDisplay(endTime, false)"></span>
					<i id="endTimeMin" class="fa fa-caret-down" aria-hidden="true"
						(click)="onEndTimeRangeChange('min')"></i>
				</div>
				<!-- <div id="changeFormat" class="change-time-format text-center d-inline-flex"
					(click)="isTimeFormatOpen = !isTimeFormatOpen" [ngClass]="{'format-open': isTimeFormatOpen}"
					title="Time Format">
					<div class="format-type-short px-1">
						<span [hidden]="activeTimeFormat === 'frames'">S</span>
						<span [hidden]="activeTimeFormat === 'standard'">F</span>
					</div>
					<div class="caret px-1" [ngClass]="{ 't-open': isTimeFormatOpen, 't-close': !isTimeFormatOpen }">
						<i class="fa fa-play" aria-hidden="true"></i>
					</div>
					<div class="format-types" [hidden]=!isTimeFormatOpen>
						<div class="std-format pt-1" [ngClass]="{'active': activeTimeFormat === 'standard'}"
							(click)="changeTimeFormat('standard')">
							<span>Seconds</span>
						</div>
						<div class="frame-format pt-1" [ngClass]="{'active': activeTimeFormat === 'frames'}"
							(click)="changeTimeFormat('frames')">
							<span>Frames</span>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div id="pallete" class="toolbarPallete hidden d-inline-flex align-items-center" [hidden]="!isPalleteShow" [ngClass]="{'disabled': !annotationToolbarActive}">
			<div class="colorComp">
				<!-- <span class="tooltiptext color" directiveCommentMinButtons>{{ 'selectColorAnnotation' |
					translate}}</span> -->
				<div id="cursorIcon"
					[ngClass]="{ 'active': activeShape==='cursor', 'disabledButton': !isEditActive && !isAnnotationActive }"
					class="toolbarOptions" (click)="toolbarClicked('cursor')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'cursorAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/select_2.svg" alt="" height="24" width="22">
				</div>
				<div class="tools-separator">
					<img src="../../../../../assets/img/annotations/separator.svg" alt="" height="24" width="22">
				</div>
				<div id="colorIcon" class="toolbarOptions d-flex align-items-center ml-auto"
					(click)="isColorOpen=!isColorOpen">
					<div class="annotation-color-picker"></div>
					<!-- <div class="selected-color"></div> -->
					<!-- <div class="caret ml-2" [ngClass]="{ 't-open': isColorOpen, 't-close': !isColorOpen }">
						<i class="fa fa-play" aria-hidden="true"></i>
					</div> -->
				</div>
				<!-- <div class="toolbarOptions separator"></div> -->
			</div>
			<div class="drawComp pr-2">
				<div id="pencilIcon" [ngClass]="{ 'active': activeShape==='pencil' }" class="toolbarOptions"
					(click)="toolbarClicked('pencil')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'freeDrawAnnotation' | translate}}</span>
					<img id="trainerPencilIcon" src="../../../../../assets/img/annotations/free_draw_2.svg" alt="" height="24" width="22">
				</div>
				<div id="circleIcon" [ngClass]="{ 'active': activeShape==='circle' }" class="toolbarOptions"
					(click)="toolbarClicked('circle')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'circleAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/circle_2.svg" alt="" height="22"
						width="22">
				</div>
				<div id="rectangleIcon" [ngClass]="{ 'active': activeShape==='rectangle' }" class="toolbarOptions"
					(click)="toolbarClicked('rectangle')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'rectangleAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/rectangle_2.svg" alt="" height="22" width="22">
				</div>
				<div id="lineIcon" [ngClass]="{ 'active': activeShape==='arrow' }" class="toolbarOptions"
					(click)="toolbarClicked('arrow')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'arrowAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/arrow_2.svg" alt="" height="24" width="22">
				</div>
				<div class="tools-separator">
					<img src="../../../../../assets/img/annotations/separator.svg" alt="" height="24" width="22">
				</div>
				<div id="deleteIcon" class="toolbarOptions disabledButton delete-icon"
					(click)="toolbarClicked('delete')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'deleteSelectedAnnotation' |
						translate}}</span>
					<img class="mb-1" src="../../../../../assets/img/annotations/delete_2.svg" alt="" height="19" width="19">
				</div>
				<div id="undoIcon" class="toolbarOptions disabledButton" (click)="toolbarClicked('undo')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'undoAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/undo_2.svg" alt="" height="25" width="25">
				</div>
				<div id="redoIcon" class="toolbarOptions disabledButton" (click)="toolbarClicked('redo')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'redoAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/undo_2.svg" alt="" height="25" width="25" style="transform: rotateY(180deg);">
				</div>
				<div id="clearIcon" class="toolbarOptions disabledButton" (click)="toolbarClicked('clear')">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'clearAnnotation' | translate}}</span>
					<img src="../../../../../assets/img/annotations/clear_2.svg" alt="" height="20" width="20">
				</div>
				<!-- <div class="toolbarOptions separator"></div> -->
				<!-- <div class="color-carot" [hidden]="!isColorOpen">
					<i class="fa fa-play" aria-hidden="true"></i>
				</div> -->
				<!-- <div class="annotation-color-picker hidden" (click)="toolbarClicked('color')"
					[ngClass]="{ 'color-picker-closed': !isColorOpen, 'color-picker-open': isColorOpen }">
				</div> -->
			</div>
		</div>
		<!-- <div title="Close Annotation Toolbar" class="slide-button d-flex align-items-center p-2"
			[ngClass]="{ 't-open': isPalleteShow, 't-close': !isPalleteShow }" (click)="createAnnotation()">
			<span class="tooltiptext" directiveCommentMinButtons>{{ 'closeToolbarAnnotation' | translate
				}}</span>
			<i class="fa fa-play" aria-hidden="true"></i>
		</div> -->
	</div>
	<div class="comment-container w-100">
		<div class="annotation-nav-comment d-flex flex-fill">
			<div class="annotation-text-area d-flex flex-fill align-items-center" [style.position]="'relative'"
				[style.top]="'0px'">
				<div class="editor-container d-flex flex-fill w-100">
					<mtm-text-editor [elementId]="'annotCommentArea'" (keydown.enter)="$event.preventDefault()"
						[(value)]="annotationComment" class="flex-fill" [dropUp]="false" [maxHeight]="'44px'"
						[files]="files" [videoRecordFiles]="videoRecordFiles" [audioRecordFiles]="audioRecordFiles"
						(keyup)="$event.stopPropagation()" (enterKeyPress)="saveAnnotation()" [placeholder]="getPlaceholderText()"
						[quillActive]="true" (uploadFileCallback)="uploadFileCallback($event)" [showBottomToolbar]="true" (deleteFileCallback)="onFileDeleted($event)"
						(click)="$event.stopPropagation();startAnnotationMode();" [backgroundColor]="'#FFFFFF'" [isAnnotationEditor]="true" [userAvatar]="authUser.username"
						[supportPrivateComment]="true" [(privateCondition)]="privateCondition" (privateConditionChange)="handlePrivateConditionChange($event)">
					</mtm-text-editor>
				</div>
			</div>
			<div class="toolbarOptions separator"></div>
			<div class="d-flex align-items-center annot-validation px-1">
				<div #clearModeIcon id="clearModeIcon" (click)="cleanUpFiles()" class="toolbarOptions cancel-button disabledButton pl-1 pr-1">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'cancelAnnotation' | translate
						}}</span>
					<img src="../../../../../assets/img/annotations/{{ isAnnotationActive || isEditActive ? 'cancel_on' : 'cancel_off' }}.svg"
						alt="" height="24">
				</div>
				<div [class.disabled]="isInValidInput()" (click)="$event.preventDefault();saveAnnotation()"
					class="save-button pt-2 pb-2 pl-1 pr-1"
					*ngIf="!isAnnotActiveUser() || !this.isAnnotationClicked || isEditActive ">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'saveAnnotation' | translate
						}}</span>
					<img src="../../../../../assets/img/annotations/annot_send.svg"
						alt="" height="24">
				</div>
				<div id="editModeIcon" class="toolbarOptions edit-button pl-1 pr-1"
					(click)="$event.preventDefault();editAnnotation()"
					[ngClass]="{'disabledButton': !isAnnotationClicked || isEditActive}"
					*ngIf="this.isAnnotationClicked && isAnnotActiveUser() && !isEditActive">
					<span class="tooltiptext" directiveCommentMinButtons>{{ 'editAnnotation' | translate
						}}</span>
					<img src="../../../../../assets/img/annotations/{{ !isAnnotationClicked || isEditActive ? 'edit_off' : 'edit_on' }}.svg"
						alt="" height="24">
				</div>
			</div>
		</div>
	</div>
</div>
