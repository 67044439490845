import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductionTeamService } from 'app/shared/services/proposal-services/productionteam.service';
import { Authority, PermissionService } from "../services/permissions.service";

@Injectable({
    providedIn: 'root'
})
export class ProposalProjectLinksGuard implements CanActivate {

    constructor(private route: Router,
        private serviceProductionTeam: ProductionTeamService,
        public permissionService: PermissionService,
        location: Location) { }

    /**
     * Validate if user is logged in. If not, redirect to Login page
     * @param route
     * @param state
     * @returns {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let invitaionProjectId = route.parent.params['projectId'];
        let proposalId = route.parent.params['proposalId'];

        if (!this.permissionService.hasAuthority(Authority.U, invitaionProjectId)) {
            this.route.navigate(['projects', invitaionProjectId, 'proposal', proposalId, 'brief']);
            return false;
        }

        return true;
    }
}
