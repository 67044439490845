<div class="d-flex align-items-center">
    <mtm-user-profile-picture forceLoadProfile="true" [user]="user" [username]="user.username"
        [height]="50" [width]="50"></mtm-user-profile-picture>
    <div class="ml-3">
        <div class="d-flex align-items-center status-wrapper">
            <div class="name-wrapper">
                <div class="name" *ngIf="user.fullName">{{user.fullName}}</div>
                <div class="username" *ngIf="!user.fullName">{{user.username}}</div>
            </div>
            <div class="ml-3 new-label" *ngIf="user?.status?.code == 'PENDING_INPROGRESS'">
                {{'newUser'|translate}}</div>
        </div>

        <div class="job">{{user.jobTitle}}</div>
        <div class="status" *ngIf="user?.status?.code == 'PENDING_INPROGRESS'">{{user.status.label}}
        </div>
    </div>
</div>