import * as _ from 'lodash';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommentDatePipe } from '../comment/pipe/comment-date-pipe.pipe';
import { EmitterService } from 'app/shared/services/emitter.service';
import { ProjectService } from 'app/shared/services/project.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { HelperService } from 'app/shared/services/helper.service';

@Component({
	selector: 'mtm-comment-preview',
	templateUrl: './comment-preview.component.html',
	styleUrls: ['./comment-preview.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CommentPreviewComponent implements OnInit, OnChanges, OnDestroy {
	@Input() value: string;
	@Input() isEdited: boolean = false;
	@Input() dateEdited: string;
	@Input() participants: any = [];
	@Input() conversations: any = [];
	@Input() textColor: any = 'inherit';
	@Input() isPreviewComment: boolean = false;
	@Input() fontSize: any = 'inherit';
	@Input() truncateText: boolean = false;
	@Input() isPreviewTitle: boolean = false;
	@Input() isAssetFilename: boolean = false;
	@Input() attachments: any;
	@Input() showFiles: any;
	@Input() enableAudioTranscription: boolean = false;
	@Input() subSectionId: any;
	@Input() showDeleteFile: boolean = false;

	@Output() deleteFileCallback: EventEmitter<any> = new EventEmitter();

	project: any;
	taggedGroups: any = [];
	mentionedUsers: any = [];
	mentionConfig: any;
	isMentionDataOpened: boolean = false;
	isTruncated: boolean = false;
	displayValue: string;
	qlMentionElements: any;
	isAudioFile = HelperService.isAudioFile;

	constructor(
		private elementRef: ElementRef,
		private datePipe: CommentDatePipe,
		private projectService: ProjectService,
		private translatePipe: TranslatePipe
	) { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.truncateText && changes.truncateText.currentValue) {
			this.isTruncated = this.truncateText;
		}
		if (changes.value && changes.value.firstChange) {
			this.project = this.projectService.project;
			this.mentionConfig = {
				mentions: this.getMentionData()
			};
			this.displayValue = this.getCommentBodyInPreview(this.value, this.isEdited);
			if (this.truncateText) {
				this.bindTruncateHandler();
			}
		} else if (changes.value && this.isPreviewTitle) {
			this.displayValue = this.getCommentBodyInPreview(this.value, this.isEdited);
		}
		if (changes.conversations && changes.conversations.currentValue) {
			this.project = {
				...this.project || {}, ...{
					conversations: this.conversations
				}
			};
			this.mentionConfig = {
				mentions: this.getMentionData()
			};
		}
		if (changes.participants && changes.participants.currentValue) {
			this.project = {
				...this.project || {}, ...{
					participants: this.participants
				}
			};
			this.mentionConfig = {
				mentions: this.getMentionData()
			};
		}
	}

	ngOnDestroy(): void {
		//** --- old element logic --- */
		this.taggedGroups = $(this.elementRef.nativeElement).find('.mentioned-group');
		for (let i = 0; i < this.taggedGroups.length; i++) {
			this.taggedGroups[i].removeEventListener('click', this.taggedGroupClickHandler)
		}
		this.mentionedUsers = $(this.elementRef.nativeElement).find('.mentioned-user');
		for (let i = 0; i < this.mentionedUsers.length; i++) {
			this.mentionedUsers[i].removeEventListener('click', this.mentionedUserClickHandler);
		}
		//** --- old element logic --- */
		this.qlMentionElements = $(this.elementRef.nativeElement).find('.mention');
		for (let i = 0; i < this.qlMentionElements.length; i++) {
			if (this.qlMentionElements[i].dataset?.denotationChar == '@' && this.qlMentionElements[i].dataset?.id) {
				this.qlMentionElements[i].removeEventListener('click', this.mentionedUserClickHandler);
			} else if (this.qlMentionElements[i].dataset?.denotationChar == '#' && this.qlMentionElements[i].dataset?.id) {
				this.qlMentionElements[i].removeEventListener('click', this.taggedGroupClickHandler)
			}
		}

	}

	bindTruncateHandler() {
		setTimeout(() => {
			let truncateButton = $(this.elementRef.nativeElement).find('.comment-view-nav');
			let __this = this;
			if (truncateButton && truncateButton.length > 0) {
				truncateButton[0].addEventListener('click', (e) => {
					e.stopImmediatePropagation();
					e.preventDefault();
					__this.isTruncated = !__this.isTruncated;
					__this.displayValue = __this.getCommentBodyInPreview(__this.value, __this.isEdited);
				});
			}
		}, 0);
	}

	getMentionData() {
		return [{
			items: this.generateUserListData(),
			dropUp: true,
			labelKey: 'label',
			triggerChar: "@",
			allowSpace: false,
			mentionFilter: (query: any) => {
				const allData = this.generateUserListData();
				return allData.filter(d => HelperService.getNormalizedName(d.value).indexOf(HelperService.getNormalizedName(query)) > -1);
			},
			mentionSelect: (args: any) => {
				this.isMentionDataOpened = false;
				return '@{{' + args.value + ':' + args.label + '}}';
			}
		}, {
			items: this.generateConversationListData(),
			dropUp: true,
			labelKey: 'label',
			triggerChar: "#",
			allowSpace: false,
			mentionFilter: (query: any) => {
				const allData = this.generateConversationListData();
				return allData.filter(d => HelperService.getNormalizedName(d.value).indexOf(HelperService.getNormalizedName(query)) > -1);
			},
			mentionSelect: (args: any) => {
				this.isMentionDataOpened = false;
				return '#{{' + args.value + ':' + args.label + '}}';
			}
		}]
	}

	generateUserListData() {
		return _.map(this.project.participants, (participant) => {
			return {
				value: participant.username,
				label: participant.fullName,
				type: 'one_on_one'
			};
		});
	}

	generateConversationList() {
		let conversations = _.filter(this.project.conversations, (conversation) => conversation.type !== 'ONE_TO_ONE');
		return _.map(conversations, (conversation) => {
			return conversation.title;
		});
	}

	generateConversationListData() {
		let conversations = _.filter(this.project.conversations, (conversation) => conversation.type !== 'ONE_TO_ONE');
		return _.map(conversations, (conversation) => {
			return {
				value: conversation.id,
				label: conversation.title,
				type: 'group',
				users: conversation.users
			};
		});
	}

	getCommentBodyInPreview(comment: string, isEdited?: boolean) {
		if (!comment) {
			return;
		}
		let el = document.createElement('div');
		el.innerHTML = comment;
		if (!el.innerText.trim()) {
			return null;
		}
		comment = comment.replace(/(?:\r\n|\r|\n)/g, '<br>');
		comment = this.parseToReadOnlyChatMessage(comment || '');
		if (isEdited) {
			if (!this.isTruncated) {
				comment = comment + `<span class="tooltipped comment-has-edited ml-1" title="${this.datePipe.transform(this.dateEdited)}">(edited)</span>`;
			} else {
				if (comment.length > 100) {
					comment = comment.substr(0, 100) + '<span>... <a class="comment-view-nav">' + this.translatePipe.transform('viewMore') + '</a></span>';
				} else {
					comment = comment + `<span class="tooltipped comment-has-edited ml-1" title="${this.datePipe.transform(this.dateEdited)}">(edited)</span>`;
				}
			}
		}
		return this.isAssetFilename ? this.getDisplay(comment) : comment;
	}

	getDisplay(name: any) {
		if (!name) {
			return '';
		}
		let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
		let nameArr = name.split('_');
		let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
		let savedName = '';
		if (isTime) {
			savedName = _.slice(nameArr, 0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_');
		} else {
			let nameSplit = name.split('.');
			savedName = _.slice(nameSplit, 0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
		}
		return savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
	}

	parseToReadOnlyChatMessage(message: string) {
		if (!message) {
			return '';
		}
		let mentioned = message.match(/(\@{{.*?\}})/g) || [];
		if (mentioned.length) {
			for (let i = 0; i < mentioned.length; i++) {
				let data = (mentioned[i].replace(/[{()}]/g, '')).replace('@', '').split(':');
				let userEmail = data[0];
				let userLabel = data[1];
				let elementClass = _.find(this.mentionConfig.mentions[0].items, (data) => data.value === userEmail) ? 'mentioned-user' : 'mentioned-user user-not-visible';
				message =
					message.replace(
						mentioned[i],
						`\uFEFF<span data-id="${userEmail}" data-label="${userLabel}" contenteditable="false" class="${elementClass} c-mtm-brand">@${userLabel}</span>\uFEFF`
					);
			}
		}
		let tagging = message.match(/(\#{{.*?\}})/g) || [];
		if (tagging.length) {
			for (let i = 0; i < tagging.length; i++) {
				let data = (tagging[i].replace(/[{()}]/g, '')).replace('#', '').split(':');
				let groupId = data[0];
				let groupLabel = data[1];
				let elementClass = _.find(this.mentionConfig.mentions[1].items, (data) => data.value === groupId) ? 'mentioned-group' : 'mentioned-group group-not-visible';
				message =
					message.replace(
						tagging[i],
						`\uFEFF<span data-id="${groupId}" data-label="${groupLabel}" contenteditable="false" class="${elementClass} c-mtm-brand">#${groupLabel}</span>\uFEFF`
					);
			}
		}
		this.bindElementClick();
		return message;
	}

	bindElementClick() {
		setTimeout(() => {
			//** --- old element logic --- */
			this.taggedGroups = $(this.elementRef.nativeElement).find('.mentioned-group');
			for (let i = 0; i < this.taggedGroups.length; i++) {
				this.taggedGroups[i].addEventListener('click', this.taggedGroupClickHandler)
			}
			this.mentionedUsers = $(this.elementRef.nativeElement).find('.mentioned-user');
			for (let i = 0; i < this.mentionedUsers.length; i++) {
				this.mentionedUsers[i].addEventListener('click', this.mentionedUserClickHandler);
			}
			//** --- old element logic --- */
			this.qlMentionElements = $(this.elementRef.nativeElement).find('.mention');
			for (let i = 0; i < this.qlMentionElements.length; i++) {
				if (this.qlMentionElements[i].dataset?.denotationChar == '@' && this.qlMentionElements[i].dataset?.id) {
					this.qlMentionElements[i].addEventListener('click', this.mentionedUserClickHandler);
				} else if (this.qlMentionElements[i].dataset?.denotationChar == '#' && this.qlMentionElements[i].dataset?.id) {
					this.qlMentionElements[i].addEventListener('click', this.taggedGroupClickHandler)
				}
			}
		}, 0);
	}

	mentionedUserClickHandler(e: any) {
		e.stopImmediatePropagation();
		EmitterService.get('OPEN_CHAT').emit({
			type: 'one_on_one',
			username: $(e.target).data('id')
		});
	}

	taggedGroupClickHandler(e) {
		e.stopImmediatePropagation();
		EmitterService.get('OPEN_CHAT').emit({
			type: 'group',
			groupId: $(e.target).data('id')
		});
	}

	deleteFile(e, attachment) {
		this.deleteFileCallback.emit(attachment);
	}
}
