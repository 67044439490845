import { AnnotationState } from "./components/annotationState";
import { Controls } from "./components/controls";
import { EventDispatcher } from "./lib/event-dispatcher";

export class CommentAnnotationPlugin {
    options: any;
    primordial: any;
    meta: { user_id: any; user_name: any; };
    eventDispatcher: EventDispatcher;
    _readyCallbacks: any[];
    annotationComments: () => this;
    annotationState: AnnotationState;
    controls: Controls;

    constructor(primordial: any, config: any) {
        window['VAC_DEBUG'] = false;

        this.options = config;
        this.meta = {
			user_id: config.userInfo.username,
			user_name: config.userInfo.fullName
		};

        this.primordial = primordial;
		this.eventDispatcher = new EventDispatcher();
		this.eventDispatcher.registerListenersFor(this, 'AnnotationComments');
		this._readyCallbacks = [];

		this.annotationComments = () => {
			return this;
		};
        this.postLoadDataConstructor();

    }
    postLoadDataConstructor() {
        this.annotationState = new AnnotationState(this);
        this.controls = new Controls(this);

        this.annotationState.annotations = this.options.annotationObjects;
        this.pluginReady();
    }

    getItem() {
		return this.options.item;
	}

    // A wrapper func to make it easier to use EventDispatcher from the client
	// Ex: plugin.fire(type, data);
	fire(type, data = {}) {
		this.eventDispatcher.fire(type, data);
	}

    	// A wrapper func to make it easier to listen to API events from the client
	registerListener(type, callback) {
		this.eventDispatcher.registerListener(type, callback, true);
	}

	// Mark plugin as ready and fire any pending callbacks
	pluginReady() {
		this.eventDispatcher.pluginReady = true;
		while (this._readyCallbacks.length) {
			this._readyCallbacks.pop()();
		}
		this.fire('pluginReady');
	}

	// Public function to register a callback for when plugin is ready
	onReady(callback) {
		if (this.eventDispatcher.pluginReady) {
			return callback();
		}
		this._readyCallbacks.push(callback);
	}

	disposeAll() {
		this.controls.teardown();
		this.annotationState.teardown();
		this.eventDispatcher.teardown();
	}

}