import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "app/shared/services/project.service";
import { Project } from "app/shared/interfaces/project";
import { NotificationService } from 'app/shared/services/notification.service';
import { DragulaService } from 'ng2-dragula';
import { ApiService } from '../../shared/services/api.service';
import { ProposalInvitationService } from "app/shared/services/proposal-invitation.service";
import { LoadingBase } from 'app/shared/bases/loading.base';
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "../../shared/services/datepicker-adapter";
import { Authority, PermissionService } from "../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { CachedWorkspacesUpdatedEvent, UnseenTotal } from "../../shared/interfaces";
import { UnseenNotifierService } from "../../shared/services/unseen-notifier.service";
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { ActiveWorkspaceUpdatedEvent } from 'app/shared/interfaces/workspace.interface';
import { ProjectTypesModalComponent } from 'app/shared/components/project-types-modal/project-types-modal.component';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { CompanyService } from 'app/shared/services/company.service';
import { HelperService } from 'app/shared/services/helper.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { ProjectSortingCriteria } from "app/shared/pipes/filter-sorting-project.pipe";
import moment from 'moment';
import { ProjectCompletionDateComponent } from './project-completion-date/project-completion-date.component';
import { OverlayService } from 'app/shared/services/overlayService';
import { ProjectStatus } from './project.const';

enum DashboardMode {
	WorkspaceList,
	ProjectList
}

enum DashboardViewMode {
	Grid = 'GRID',
	List = 'LIST'
}

@Component({
	selector: 'mtm-my-projects',
	templateUrl: './my-projects.component.html',
	styleUrls: ['./my-projects.component.scss'],
	providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})

export class MyProjectsComponent extends LoadingBase implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
	@ViewChild('loadingRef', { static: true }) public loadingRef: LoadingBase;
	user: any = {};
	projects: any[] = [];
	pendingProjects: any[] = [];

	projectName: string = "";
	companyName: string = "";
	tag: string = "";
	budgetDownLimit: any;
	budgetUpLimit: any;
	startDatePre: any;
	startDateLast: any;
	dueDatePre: any;
	dueDateLast: any;
	airDatePre: any;
	airDateLast: any;
	statusFilter: string[] = [];
	username: any;
	isBudgetControlVisibility: boolean = false;
	isDateControlVisibility: boolean = false;
	viewMode: string = 'GRID';
	additionalData: Record<string, any> = {};
	projectSorting: any = { btn: ProjectSortingCriteria.ProjectName, sortDirection: true };
	backupElementList: any[] = [];
	elementList: any[] = [];
	mergedProjectList: any[] = [];
	ordered: any[] = [];
	dragulaKey: string = 'project-elements';
	dropSubscribeProject;
	inProgressInvitation: any = 0;
	authority = Authority;
	isBusy: boolean = false;
	activeWorkspace: any = null;
	inMarketplaceWorkspace: boolean = false;
	isOtherWorkspace: boolean = false;
	isProductionCompany: boolean = false;
	ngUnsubscribe = new Subject();
	default;
	availableTabs: any[] = [];
	selectedTab: string = '';
	archivedProjects: any[] = [];
	biddingProjects: any[] = [];
	hasWorkspaceSettings: boolean = false;
	hasMarketplaceAccess: boolean = false;
	projectRoles: any = {};
	workspaceFilter: string = '';
	projectFilter: string = '';
	dashboardMode: DashboardMode = DashboardMode.WorkspaceList;
	dashboardModes: typeof DashboardMode = DashboardMode;
	proposalStatusFilter: string = '';
	isCompanyDetailFilled = true;
	workspaces: any[] = [];
	sortedWorkspaces: any[] = [];
	loadEnds$: Subject<any> = new Subject();
	ProjectSortCriteria = ProjectSortingCriteria;
	ViewMode = DashboardViewMode;
	canCreateProject: boolean = false;
	projectStatusList = [
		{ label: `sectionStatus_${ProjectStatus.ToDo}` , value: ProjectStatus.ToDo },
		{ label: `sectionStatus_${ProjectStatus.InProgress}`, value: ProjectStatus.InProgress },
		{ label: `sectionStatus_${ProjectStatus.UpcomingExpiry}`, value: ProjectStatus.UpcomingExpiry },
		{ label: `sectionStatus_${ProjectStatus.Completed}`, value: ProjectStatus.Completed },
		{ label: `sectionStatus_${ProjectStatus.Delayed}`, value: ProjectStatus.Delayed },
	]
	subscription: any = null;
	subs = new Subscription();
	unseenWsSubscription: Subscription;
	currentCompany: any = null;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		public auth: AuthService,
		public service: ProjectService,
		private notificationService: NotificationService,
		private dragulaService: DragulaService,
		private proposalInvitationService: ProposalInvitationService,
		private apiService: ApiService,
		private unseenNotifier: UnseenNotifierService,
		private workspaceService: WorkspaceService,
		private modalService: NgbModal,
		private permissionService: PermissionService,
		private themeService: ThemeService,
		private companyService: CompanyService,
		private overlayService: OverlayService,

		private translatePipe: TranslatePipe) {
		super();
	}

	ngOnInit() {
		this.initLoadingParameters('Loading...', undefined, ['w-100']);
		this.user = this.auth.getAuthUser();
		this.activeWorkspace = this.workspaceService.getActiveWorkspace();
		this.inMarketplaceWorkspace = this.workspaceService.getInMarketplace();
		this.isProductionCompany = this.user.company.companyType == 'PRODUCER'
		this.isOtherWorkspace = this.activeWorkspace && this.activeWorkspace.companyId != this.user.company.id;
		this.subscription = this.auth.getAuthUserSubscription();

		this.getMyCompany();
		this.checkWorkspaceSettings();

		if (this.activeWorkspace || this.inMarketplaceWorkspace) {
			this.dashboardMode = DashboardMode.ProjectList;
		}

		EmitterService.get(ActiveWorkspaceUpdatedEvent)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				const { workspace, inMarketplace } = data;
				this.activeWorkspace = workspace;
				this.inMarketplaceWorkspace = inMarketplace;
				this.dashboardMode = (workspace != null || this.inMarketplaceWorkspace) ? DashboardMode.ProjectList : DashboardMode.WorkspaceList;
				this.isOtherWorkspace = workspace && workspace.companyId != this.user.company.id;
				if (this.dashboardMode == DashboardMode.ProjectList) {
					this.checkWorkspaceSettings();
					this.initializeTabs();
					this.populateData();
				} else {
					this.projectFilter = '';
					this.hideLoading();
				}

				if (!this.inMarketplaceWorkspace && data.isArchive) {
					this.toggleTab('archived');
				}
			});

		EmitterService.get(CachedWorkspacesUpdatedEvent)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				this.workspaces = data;
				this.sortedWorkspaces = [...data].sort((a, b) => a.name.localeCompare(b.name));
			});

		this.dragulaService.createGroup(this.dragulaKey, {
			moves: (el: any, container: any, handle: any) => {
				if (el.className.indexOf('drop-here') !== -1)
					return false;

				if (this.projectName)
					return false;
				else
					return true;
			}
		});

		this.subs.add(this.dragulaService.drop(this.dragulaKey).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((value) => {
			setTimeout(() => this.dropFunc(value), 55);
		}));
		this.breadcrumb.addFriendlyNameForRoute('/projects', 'My Projects');
		this.username = this.auth.getAuthUserName();
	}

	ngAfterViewInit() {
		this.themeService.updateContent({
			backgroundColor: '',
			rightPane: null,
			backgroundImage: null,
			header: this.headerContent
		});
		this.initWSSubscription();
	}

	getMyCompany() {
		this.companyService.getCompanyById(this.auth.getAuthUser().companyId)
			.subscribe({
				next: company => {
					this.currentCompany = company;
					this.hasMarketplaceAccess = company.marketplaceAccess;
					if (this.user.globalRole === 'COMPANY_PRINCIPAL') {
						this.isCompanyDetailFilled = this.isCompanyDetailValid(company);
					}		
					this.initializeTabs();
					this.init();
					this.populateData();
					this.getInvitationCheck();
				}
			}
		);
	}

	isOnFreePlan() {
		return this.subscription?.planType == 'FREE';
	}

	isCompanyDetailValid(company: any) {
		return !HelperService.isObjectEmpty(company.details.companyName) &&
			!HelperService.isObjectEmpty(company.details.domainName) &&
			!HelperService.isObjectEmpty(company.details.accountRepName) &&
			!HelperService.isObjectEmpty(company.details.accountRepEmail) &&
			!HelperService.isObjectEmpty(company.details.accountRepPhone) &&
			!HelperService.isObjectEmpty(company.details.idNumber) &&
			!HelperService.isObjectEmpty(company.address.address) &&
			!HelperService.isObjectEmpty(company.address.city) &&
			!HelperService.isObjectEmpty(company.address.country) &&
			!HelperService.isObjectEmpty(company.address.postalCode) &&
			!HelperService.isObjectEmpty(company.address.state) &&
			!HelperService.isObjectEmpty(company.billingAddress.address) &&
			!HelperService.isObjectEmpty(company.billingAddress.city) &&
			!HelperService.isObjectEmpty(company.billingAddress.country) &&
			!HelperService.isObjectEmpty(company.billingAddress.postalCode) &&
			!HelperService.isObjectEmpty(company.billingAddress.state)
	}

	private initWSSubscription() {
		this.unseenWsSubscription = this.unseenNotifier.subscribe(data => {
			this.updateUnseenData(data);
		});
	}

	private initializeTabs() {
		const companyType = this.user.company.companyType;
		const isBrand = companyType == 'ADVERTISER';
		this.availableTabs = [];
		if (this.inMarketplaceWorkspace && this.permissionService.hasAuthority(Authority.I, null)) {
			this.availableTabs.push({
				label: 'dashboard_invitation',
				value: 'invitation',
			});
		}

		this.availableTabs.push({
			label: 'dashboard_ongoingProduction',
			value: 'ongoing'
		});

		if (this.inMarketplaceWorkspace) {
			this.availableTabs.push({
				label: 'dashboard_marketplaceBidding',
				value: 'marketplaceBidding',
			});
		}

		if (this.hasMarketplaceAccess && (isBrand || (this.isProductionCompany && this.isOtherWorkspace))) {
			this.availableTabs.push({
				label: 'dashboard_ongoingBidding',
				value: 'bidding',
			});
		}

		if (!this.inMarketplaceWorkspace) {
			this.availableTabs.push({
				label: 'archivedProjects',
				value: 'archived'
			});
		}

		if (this.availableTabs.length > 0)
			this.selectedTab = this.availableTabs[0].value;
	}

	changesProjectSorting(projectSorting) {
		this.projectSorting = projectSorting;
	}

	updateProjectSorting(criteria: string, ascending: boolean) {
		this.projectSorting = {
			btn: criteria,
			sortDirection: ascending
		};
	}


	private onFilterReset(): void {
		this.projectName = '';
		this.companyName = '';
		this.tag = '';
		this.budgetDownLimit = null;
		this.budgetUpLimit = null;
		this.startDatePre = null;
		this.startDateLast = null;
		this.dueDatePre = null;
		this.dueDateLast = null;
		this.airDatePre = null;
		this.airDateLast = null;
	}

	private newProject() {
		if (!this.permissionService.hasAuthority(this.authority.P, null) || !this.activeWorkspace)
			return;

		if (this.service.projectOnloaded)
			this.service.leaveFromProject();

		this.service.company = {};
		this.service.project = {};

		const isAdmin =	this.permissionService.hasAuthority(Authority.Z, null)

		// if (this.permissionService.hasAuthority(this.authority.Z, null)) {
		// 	this.router.navigate(['/projects/new/company']);
		// 	return;
		// }

		if(isAdmin) {
			this.showProjectTypesPopup();
		} else {
			if (this.isProductionCompany) {
				const params = { workspaceId: this.activeWorkspace.id };
				this.router.navigate(['/projects/new/company'], { queryParams: params });
			} else {
				if(!this.hasMarketplaceAccess){
					const params = { workspaceId: this.activeWorkspace.id };
					this.router.navigate(['/projects/new/company'], { queryParams: params });
				} else {
					this.showProjectTypesPopup();
				}
			}
		}
	}

	showProjectTypesPopup(){
		const modalRef = this.modalService.open(ProjectTypesModalComponent, { size: 'sm' });
		modalRef.result.then((data: any) => {
			switch (data) {
				case 'one-shot':
					this.router.navigate(['/entry/marketplace/build-request/ONE_SHOT/ADVERTISER']);
					break;
				case 'subscription':
					const params = { workspaceId: this.activeWorkspace.id };
					this.router.navigate(['/projects/new/company'], { queryParams: params });
					break;
				case 'adminSubscription':
					const adminParams = { workspaceId: this.activeWorkspace.id, adminProjectSelect: true };
					this.router.navigate(['/projects/new/company'], { queryParams: adminParams });
					break;
				default:
					this.router.navigate(['/projects/new/company']);
					break;
			}
		}, reason => {
		});
	}

	private populateData() {
		this.backupElementList = [];
		switch (this.selectedTab) {
			case 'archived':
				this.loadArchivedProjects();
				break;
			case 'bidding':
				this.loadProjects(true);
				break;
			case 'ongoing':
				this.loadProjects();
				break;
		}
	}


	private loadProjects(isBidding: boolean = false): void {
		if (!this.activeWorkspace && !this.inMarketplaceWorkspace)
			return;

		this.showLoading();
		this.loadEnds$.next(undefined);
		this.service.getProjects().pipe(
			takeUntil(this.loadEnds$)
		).subscribe((data: any) => {
			const emptyMessage = isBidding ? 'workspace_noBiddingProcess' : 'workspace_noOngoingProduction';

			if (!Array.isArray(data)) {
				this.showInfoForArrayData([], this.translatePipe.transform(emptyMessage));
				return;
			}

			let workspaceData = data;

			let idsToCheck: any[] = []

			if (this.isProductionCompany) {
				//if  marketplace workspace, then it doesnt filter by active workspace
				if (!this.inMarketplaceWorkspace)
					workspaceData = data.filter(p => p.workspaceId == this.activeWorkspace.id && p.projectType != 'ONE_SHOT');

				//marketplace workspace doesn't contain admin / subscription project
				if (this.inMarketplaceWorkspace) {
					workspaceData = workspaceData.filter(p => p.projectType == 'ONE_SHOT');
					idsToCheck = workspaceData.map(p => p.id);
				}

				if (this.isOtherWorkspace) {
					if (isBidding) {
						workspaceData = workspaceData.filter(p => p.projectType != 'SUBSCRIPTION');
					}
					idsToCheck = workspaceData.filter(p => !p.projectType || p.projectType == 'ONE_SHOT')
						.map(p => p.id);
				}

				//if it is own workspace then for production company no need to check proposal status
			} else {
				workspaceData = data.filter(p => p.workspaceId == this.activeWorkspace.id);

				//bidding project won't have subscription projects
				if (isBidding)
					workspaceData = workspaceData.filter(p => p.projectType != 'SUBSCRIPTION');

				idsToCheck = workspaceData.filter(p => !p.projectType || p.projectType == 'ONE_SHOT')
					.map(p => p.id);
			}


			//admin project (null type)	and one shot project needs to check its proposal status to determine its bidding status
			if (idsToCheck.length == 0) {
				this.innerLoadProjects(workspaceData, isBidding);
			}
			else {
				let filteredData = [];
				if (!isBidding) {
					filteredData = workspaceData.filter(p => p.projectType == 'SUBSCRIPTION');
				}

				this.service.checkProjectsProposalStatus(idsToCheck)
					.pipe(takeUntil(this.loadEnds$))
					.subscribe(checkResult => {

						Object.keys(checkResult).forEach(projectId => {
							let isAccepted = isBidding ? false : true;
							if (checkResult[projectId] == isAccepted) {
								filteredData.push(workspaceData.find(p => p.id == projectId));
							}
						});
						this.innerLoadProjects(filteredData, isBidding);
					});
			}
		}, (err: any) => this.showLoadingError());
	}

	innerLoadProjects(workspaceData, isBidding: boolean = false) {
		let projects = [];
		let pendingProjects = [];
		this.mergedProjectList = workspaceData;
		// this.mergedProjectList.map(p => p.isPending = false);
		workspaceData.forEach((project: any) => {
			this.additionalData[project.id] = {};
			if (this.service.isUserRsvp(project)) {
				projects.push(project);
			} else {
				pendingProjects.push(project);
				// this.mergedProjectList.filter(p => p.id == project.id)
				// 	.forEach(pp => {
				// 		pp.isPending = true;
				// 	})
			}
			project.isFree = project?.company?.id == this.subscription?.companyId && this.isOnFreePlan();
			if (project.avatarUrl) {
				project.avatarUrl = project.avatarUrl + '?' + (new Date).getTime();
			}
		});

		let oneShotCount = 0;
		projects.forEach(project => {
			if (project.projectType == 'ONE_SHOT' && project.entranceFeePaid == false && this.subscription?.planType != 'ENTERPRISE') {
				oneShotCount++;
			}
		});

		this.projects = projects;
		this.pendingProjects = pendingProjects;
		this.elementList = this.mergedProjectList;
		this.initUnseenData();
		const emptyMessage = isBidding ? 'workspace_noBiddingProcess' : 'workspace_noOngoingProduction';
		this.showInfoForArrayData(this.elementList, this.translatePipe.transform(emptyMessage));
		this.service.saveProjectsInCache(this.projects);
		this.ordered = projects;
		this.init();
		//legacy logic, deactivated by request
		// try {
		// 	const oneOfProject = projects[0];
		// 	if (oneShotCount == 1 && oneOfProject.isFirstSeen != 'TRUE') {
		// 		this.navigateCompanyToProfileSuggestionIFOneShotCountEqualsOne(oneOfProject.id);
		// 		this.service.updateFirstSeenByProjectIdAfterCheck(oneOfProject);
		// 	}
		// } catch (error) {
		// }
	}

	async navigateCompanyToProfileSuggestionIFOneShotCountEqualsOne(projectId) {
		try {
			await this.service.navigateCompanyToProfileSuggestionAfterProjectInit(projectId, this.router).toPromise();
		} catch (error) {

		}
	}

	loadArchivedProjects() {
		this.service.getArchivedProjects().pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {

			if (!Array.isArray(data))
				return;

			const workspaceData = data.filter(d => d.workspaceId == this.activeWorkspace.id);
			let projects = [];
			workspaceData.forEach((project: any) => {
				projects.push(project);
				if (project.avatarUrl)
					project.avatarUrl = project.avatarUrl + '?' + (new Date).getTime();
			});

			this.archivedProjects = projects;
			this.showInfoForArrayData(this.archivedProjects, this.translatePipe.transform('workspace_noArchivedProject'));

		}, (err: any) => this.showLoadingError());
	}

	restoreArchive(project: Project) {
		if(! project) {	
			return;
		}

		this.populateData();
	}

	deletedProject(project: Project) {
		if (!project) {
			return;
		}
		this.populateData();
	}

	rejectedProject(project: Project) {
		if (!project) {
			return;
		}
		this.populateData();
	}

	projectInvites() {
		this.router.navigate(['projects', 'project-invitations']);
	}

	projectProposals() {
		this.router.navigate(['projects', 'project-proposals']);
	}

	goToArchivedProjects() {
		this.router.navigate(['projects', 'archived-projects']);
	}

	ngOnDestroy() {
		if (this.unseenWsSubscription)
			this.unseenWsSubscription.unsubscribe();

		this.dragulaService.destroy(this.dragulaKey);
		// if (this.dropSubscribeProject) {
		// 	this.dropSubscribeProject.unsubscribe();
		// }
		this.subs.unsubscribe();

		this.loadEnds$.next(undefined);
		this.loadEnds$.complete();

		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	init() {
		//setTimeout(() => {
		this.backupElementList = [];
		this.canCreateProject = this.canAddProject();
		if (this.canCreateProject)
			this.backupElementList.push({ localId: null });
		let index = 1;
		this.elementList.forEach(x => {
			x.localId = index++;
			this.backupElementList.push(x);
		});
		this.backupElementList.sort((a: any, b: any) => b.order - a.order);
		//}, 100);

	}

	canAddProject(): boolean {
		//disable add new project if other company's workspace
		if (this.isOtherWorkspace || this.inMarketplaceWorkspace)
			return false;

		if (this.permissionService.hasAuthority(Authority.Z))
			return true;

		//if company principal, then allow it
		const exceptions = ['COMPANY_PRINCIPAL'];
		if (exceptions.indexOf(this.user.globalRole) > -1)
			return true;

		//if workspace owner, needs to check workspace users
		const ownerRoles = ['COMPANY_PROJECT_OWNER', 'COMPANY_PRODUCTION_OWNER'];
		if (ownerRoles.indexOf(this.user.globalRole) > -1 && this.activeWorkspace) {
			if (this.activeWorkspace.usernames && this.activeWorkspace.usernames.indexOf(this.user.username) > -1) {
				return true;
			}
		}

		return this.permissionService.hasAuthority(Authority.P, null);
	}

	dropFunc(dropValue: any) {
		if (this.isBusy) {
			return;
		}
		this.isBusy = true;
		let index = this.backupElementList.findIndex(k => k.localId == null);
		if (index > 0) {
			let newList = JSON.parse(JSON.stringify(this.backupElementList));
			let backup = newList[index];
			newList[index] = newList[0];
			newList[0] = backup;
			this.backupElementList = newList;
			this.backupElementList.sort((a: any, b: any) => b.order - a.order);
			this.isBusy = false;
		} else {
			index = this.backupElementList.findIndex(k => k.localId == dropValue.el.id);
			if (index < 0 || index > this.backupElementList.length) {
				return;
			}
			this.backupElementList[index].order = this.backupElementList.length - (((index - 1) % this.backupElementList.length) + 1);
			let p = this.ordered.filter(p => p.id == this.backupElementList[index].id).pop();
			p.order = (this.backupElementList.length - ((index - 1) % this.backupElementList.length) + 1);
			this.apiService.httpPost(`/api/projects/usersProjectOrder/order/${this.username}`, p).pipe(
				takeUntil(this.ngUnsubscribe)
			)
				.subscribe(
					(value) => {
						this.isBusy = false;
						this.backupElementList.sort((a: any, b: any) => b.order - a.order);
					}, err => {
						console.log('reorder error', err);
						this.isBusy = false;
					}
				);
		}
	}

	getInvitationCheck() {
		if (this.permissionService.hasAuthority(Authority.I, null)) {
			this.proposalInvitationService.getInvitationCheck()
				.subscribe(k => {
					this.inProgressInvitation = k
				});
		}
	}

	//loads initial unseen counts from web service
	private initUnseenData() {
		let obs$: Observable<any> | null = null;

		if (this.activeWorkspace?.id) {
			obs$ = this.notificationService.getUnseenProjectNotificationsCountInWorkspace(this.activeWorkspace.id);
		} else {
			const projectIdList = this.mergedProjectList.map(p => p.id);
			if (projectIdList.length) {
				obs$ = this.notificationService.getUnseenNotificationsCount(projectIdList);
			}
		}

		if (!obs$) {
			return;
		}

		obs$.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: data => this.updateUnseenData(data)
			});
	}

	//update the unseen models with data from either web socket / web service
	updateUnseenData(data: Record<string, UnseenTotal>) {
		if (!data)
			return;
		for (let key in data) {
			if (!(key in this.additionalData)) {
				this.additionalData[key] = {};
			}
			Object.assign(this.additionalData[key], data[key]);
			delete this.additionalData[key].notifications;
		}
	}

	toggleTab(value: string) {
		this.selectedTab = value;
		this.populateData();
	}

	//check if current user can access workspace settings
	checkWorkspaceSettings() {
		this.hasWorkspaceSettings = false;

		if (!this.activeWorkspace || this.isOtherWorkspace)
			return;

		if (this.permissionService.hasAuthority(Authority.S, null)) {
			this.hasWorkspaceSettings = true;
			return;
		}

		if (this.activeWorkspace.usernames.indexOf(this.user.username) > - 1) {
			const ownerRole = !this.isProductionCompany ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';

			if (this.user.globalRole == ownerRole) {
				this.hasWorkspaceSettings = true;
			}

		}
	}

	checkUserHasWorkspaceAccess(workspace: any): boolean {
		if (this.user.company.id != workspace.companyId)
			return false;

		if (this.permissionService.hasAuthority(Authority.S, null))
			return true;

		if (workspace.usernames.indexOf(this.user.username) > -1)
			return true;


		if (!workspace.projectIds || !this.projectRoles)
			return false;

		let hasProjectAccess = false;
		for (let i = 0; i < workspace.projectIds.length; i++) {
			let projectId = workspace.projectIds[i];
			if (projectId in this.projectRoles) {
				if (this.projectRoles[projectId].length) {
					hasProjectAccess = true;
					break;
				}
			}
		}

		if (hasProjectAccess)
			return true;

		return false;

	}

	//change current active workspace
	changeWorkspace(workspace: any) {
		console.log(workspace);
		this.workspaceService.setActiveWorkspace(workspace);
	}

	//redirects to active workspace settings
	goToWorkspaceSettings() {
		if (!this.hasWorkspaceSettings) {
			return;
		}

		this.router.navigate(['/workspace/edit', this.activeWorkspace.id]);
	}

	switchViewMode(viewMode: DashboardViewMode) {
		this.viewMode = viewMode;
	}

	updateProjectStatus(e) {
		let project = this.backupElementList.find(p => p.id == e.projectId), args: any = { projectId: project.id };
		args.status = e.status;
		if (e.completionDate) {
			args.completionDate = moment(e.completionDate).format('DD-MM-yyyy');
		}
		this.service.updateProjectStatus(args)
			.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				project.status = e.status;
				project.completionDate = e.completionDate;
			}, err => {
				this.overlayService.showError(JSON.stringify(err));
			});
	}

	updateProjectStatusFilter(values: string[]){
		this.statusFilter = values;
	}
}
