import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { Observable } from "rxjs";

@Injectable()
export class WorkspaceCreationGuard implements CanActivate {
    constructor(private permissionService: PermissionService,
        private authService: AuthService,
        private router: Router) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if ((!this.authService.hasPaidSubscription() && !this.permissionService.hasAuthority(Authority.Z, null)) || !this.permissionService.hasAuthority(Authority.S, null)) {
            this.router.navigate(['/entry']);
            return false;
        }

        return true;
    }
}