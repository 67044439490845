import { Component, OnInit, Inject, Injector, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
// import { PageScrollInstance, PageScrollService, PageScrollConfig } from "ng2-page-scroll/ng2-page-scroll";
import { PageScrollService } from 'ngx-page-scroll-core';
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { MainLayoutComponent } from "app/layouts/main-layout.component";
import { ProdPartnerService } from "../../../shared/services/prod-partner.service";
import { CommonService } from '../../../shared/services/common.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { Subject, combineLatest } from "rxjs";
import { ProjectService } from "../../../shared/services/project.service";
import { CreatePartnerSelectionService } from '../../../shared/services/create-partner-selection.service';
import { OverlayService } from "../../../shared/services/overlayService";
import { EmitterService } from "../../../shared/services/emitter.service";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'mtm-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {

	@Input() isOneShotProject: boolean;
	@Output() onProjectIdChange = new EventEmitter<any>()
	layout1: any;
	projectId: any;
	sectionId: any;
	subsectionId: any;
	contentId: any;
	format: any[] = [];
	technology: any[] = [];
	style: any[] = [];
	purpose: any[] = [{
		title: 'Corporate',
		selected: false
	}, {
		title: 'Brand',
		selected: false
	}, {
		title: 'Product',
		selected: false
	}, {
		title: 'Tactical',
		selected: false
	}];

	allCommonsSectors: any[] = [];
	companySubSectors: any[] = [];
	companySectors: any[] = [];
	clientSector: any = "";
	clientSubSector: any = "";
	haveScript: boolean = false;
	ngUnsubscribe = new Subject();
	isSend: boolean = false;

	exportChannels: any = [];

	constructor(
		private router: Router,
		private pageScroll: PageScrollService,
		private service: ProdPartnerService,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private projectService: ProjectService,
		private serviceNotification: NotificationService,
		private serviceCreatePartnerSelection: CreatePartnerSelectionService,
		private overlayService: OverlayService,
		private injector: Injector,
		private translatePipe: TranslatePipe,
		@Inject(DOCUMENT) private document: any
	) {

		// PageScrollConfig.defaultDuration = 500;

	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		if (!this.isOneShotProject)
			this.layout1 = <MainLayoutComponent>this.injector.get(MainLayoutComponent);

		combineLatest([
			this.serviceCreatePartnerSelection.getOptionsByName('Format'),
			this.serviceCreatePartnerSelection.getOptionsByName('Techs'),
			this.serviceCreatePartnerSelection.getOptionsByName('Style'),
			this.serviceCreatePartnerSelection.getOptionsByName('Channel', 'exports')
		]).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			res[0].forEach((op) => {
				this.format.push({
					id: op.id,
					title: op.item,
					selected: false
				});
			});
			res[1].forEach((op) => {
				this.technology.push({
					id: op.id,
					title: op.item,
					selected: false
				});
			});
			res[2].forEach((op) => {
				this.style.push({
					id: op.id,
					title: op.item,
					selected: false
				});
			});
			res[3].forEach((op) => {
				this.exportChannels.push({
					id: op.id,
					title: op.item,
					selected: false
				});
			});
			this.init();
		}, () => this.init());
	}

	private init() {
		if (!this.isOneShotProject) {
			this.route.parent.parent.params.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((params) => {
				this.projectId = params['projectId'];
			});

			this.route.params.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((params) => {
				if (params['sectionId'] && params['subsectionId']) {

					this.sectionId = params['sectionId'];
					this.subsectionId = params['subsectionId'];

					this.service.getProdPartnerContent(this.projectId).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						(s) => {
							if (s) {
								this.clientSector = s.clientSector == "111" ? "" : s.clientSector || "";
								this.onSelectSector(this.clientSector);
								this.clientSubSector = s.clientSubSector == "1111" ? "" : s.clientSubSector || "";
								this.setValuesOfContent(this.format, s.format);
								this.setValuesOfContent(this.style, s.genre);
								this.setValuesOfContent(this.purpose, s.purpose);
								this.setValuesOfContent(this.technology, s.technology);
								this.setValuesOfContent(this.exportChannels, s.exportChannels);
								this.haveScript = s.script;
								this.getAllCommonSectors(this.clientSector);
							}
						});
				}
			}).unsubscribe();
		}
		this.getAllCommonSectors();
	}

	/**
	 * Select option
	 * @param option
	 */
	private toggleOption(option) {
		option.selected = !option.selected;
	}

	public continue(step: number): void {
		this.onSubmit(false);
		step++;
		const sectionEl =	this.document.getElementById(`section-${step}`);
		if(sectionEl){
			sectionEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'  });
		}
	}

	private getValueOfContent(inputs: any[]) {
		let values: any[] = [];
		inputs.filter(k => k.selected == true).map(l => {
			values.push(l.title)
		});
		return values;
	}

	public onSubmit(navigate: boolean): void {
		this.isSend = true;

		let content = {
			projectId: this.projectId,
			clientSector: this.companySectors.find(s => s == this.clientSector || s.id == this.clientSector) ? this.clientSector : null,
			clientSubSector: this.companySubSectors.find(ss => ss == this.clientSubSector || ss.id == this.clientSubSector) ? this.clientSubSector : null,
			format: this.getValueOfContent(this.format),
			genre: this.getValueOfContent(this.style),
			purpose: this.getValueOfContent(this.purpose),
			technology: this.getValueOfContent(this.technology),
			script: this.haveScript,
			exportChannels: this.getValueOfContent(this.exportChannels)
		};

		if (content.format.length < 1 || content.technology.length < 1) {
			this.overlayService.showWarning(this.translatePipe.transform('overlayFillMandatory'), "Warning");
			return;
		}

		this.service.saveProdPartnerContent(this.projectId, content, this.isOneShotProject).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			res => {
				if (navigate && !this.isOneShotProject)
					this.nextPage();
				if (!this.projectId) {
					this.projectId = res.projectId;
				}
				this.onProjectIdChange.emit(this.projectId);
				EmitterService.get("scriptChange").emit(content.script);
			},
			err => {
				this.overlayService.showError(this.translatePipe.transform('overlayErrorSave') + 'your preferences.' + this.translatePipe.transform('overlayTryAgain'), 'Error');
				this.serviceNotification.open({
					title: '',
					description: this.translatePipe.transform('overlayErrorSave') + 'your preferences.' + this.translatePipe.transform('overlayTryAgain'),
					confirmBtn: 'Accept'
				});
			});
	}

	private setValuesOfContent(contentKey: any[] = [], contentList: any[] = []) {
		try {
			contentList.map(k => {
				let obj = contentKey.find(l => l.title == k);
				obj.selected = true;
			})
		} catch (error) {

		}
	}

	onSelectSector(selectedSector: any, get?) {
		this.companySubSectors = [];
		let filteredSubSector = this.allCommonsSectors.filter(k => k.industrycode == selectedSector);
		filteredSubSector.map(x => {
			if (this.companySubSectors.findIndex(k => k.id == x.subindustrycode) < 0) {
				this.companySubSectors.push({
					id: x.subindustrycode,
					name: x.subindustryname
				});
			}
		});
		this.companySubSectors.sort((a, b) => a.name.localeCompare(b.name));

	}

	private getAllCommonSectors(selected?) {
		this.commonService.getClientSector(this.isOneShotProject).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			res
				.sort((a, b) => a.subindustryname.localeCompare(b.subindustryname))
				.map((r) => {
					this.allCommonsSectors.push({
						id: r.id,
						subsectorcode: r.subsectorcode,
						subsectorname: r.subsectorname,
						sectorcode: r.sectorcode,
						sectorname: r.sectorname,
						industrycode: r.industrycode,
						industryname: r.industryname,
						subindustrycode: r.subindustrycode,
						subindustryname: r.subindustryname,

					});

					if (this.companySectors.findIndex(k => k.id == r.industrycode) < 0 && r.industrycode != "111") {
						this.companySectors.unshift({ id: r.industrycode, name: r.industryname });
						this.companySectors.sort((a, b) => a.name.localeCompare(b.name));
					}
				});


			this.onSelectSector(selected, true);
		});
	}

	private nextPage(): void {
		if (this.projectService.subsectionsSelected) {
			let current = false;
			this.projectService.subsectionsSelected.forEach((sub) => {
				if (current) {
					this.router.navigate(['projects', this.projectId, this.sectionId, sub.id, 'preview']);
					current = false;
				}
				if (sub.id === this.subsectionId)
					current = true;
			});
		}
	}

	checkArray(accessor): boolean {
		return this[accessor].filter(k => k.selected).length <= 0 && this.isSend ? true : false;
	}

	getPlacementLabel(placement: any) {
		return placement === 'Tv_advertising' ? 'Tv advertising' : placement;
	}

}
