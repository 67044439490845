import { Component, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { onLoadedProposal, ProjectService } from "app/shared/services/project.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ProposalFileService } from '../../../../shared/services/proposal-services/proposalfile.service';
import * as moment from 'moment';
import 'moment-timezone';
import { OverlayService } from "../../../../shared/services/overlayService";
import { ProposalCommentService } from "../../../../shared/services/proposal-services/comment.service";
import { AuthService } from "../../../../shared/services/auth.service";
import {
  SignedURL_FU_Base,
  WithSignedURLUploadInfo,
  WithUploadBaseDependencies
} from 'app/shared/bases/signed-url-file-upload.base';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateHelperService } from "../../../../shared/services/date-helper.service";
import { UserService } from "../../../../shared/services/user.service";
import { onRedirectToTCP_FNC } from '../../../../shared/base-functions/terms-conditions.base-function';
import { onCheckFileList } from "../../../team-files/list/list.component";
import { TranslatePipe } from "../../../../shared/pipes/translate.pipe";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "../../../../shared/services/datepicker-adapter";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileTransferService } from '../../../../shared/services/signed-url/file-transfer.service';
import DOMPurify from 'dompurify';

@Component({
	selector: 'mtm-project-proposal-uploadProposal',
	templateUrl: './project-proposal-uploadProposal.component.html',
	styleUrls: ['./project-proposal-uploadProposal.component.scss'],
	providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class ProjectProposalUploadProposalComponent extends SignedURL_FU_Base implements OnInit, OnDestroy {
	invitationProjectId: string;
	sectionId: string = "30dbc93a-2aca-5838-418c-5b529e3bbdf8";
	subsectionId: string = "05d52e21-d6dd-7dc0-d899-6a9dc035e914";
	hidden: boolean = false;
	availableExtensions = ['avi', 'doc', 'fla', 'jpg', 'jpeg', 'mp3', 'mp4', 'pdf', 'png', 'ppt', 'psd',
		'rtf', 'svg', 'txt', 'xls', 'zip'];
	proposalId;
	subscribeUpladingFiles: any;
	proposal: any = {};
	ngUnsubscribe = new Subject();

	formGroup: UntypedFormGroup;
	currencies: any[];
	proposalAccepted: boolean = false;
	authUser;
	clientCompanyName: any;
	contractApprovedByProducer: boolean = false;
	finalPaymentDeadline: any;
	firstCondition: boolean = false;
	secondCondition: boolean = false;
	thirdCondition: boolean = false;
	currency: any;
	queryString: any;

	@ViewChild('refMTMUploadFile', { static: true }) refMTMUploadFile: any;
	views: any = {
		'grid': 1,
		'list': 2,
		'upload': 3
	};

	view: number = this.views['list'];

	constructor(private sane: DomSanitizer,
		private projectService: ProjectService,
		private overlayService: OverlayService,
		authService: AuthService,
		private router: Router,
		private proposalFileService: ProposalFileService,
		private commentService: ProposalCommentService,
		private activeRouter: ActivatedRoute,
		public userService: UserService,
		transferService: FileTransferService,
		private fb: UntypedFormBuilder,
		private translatePipe: TranslatePipe) {
   
    super(
      WithUploadBaseDependencies(authService, transferService),
      WithSignedURLUploadInfo({
        uploadPathGetter: () => `/api/projects/${this.invitationProjectId}/sections/${this.sectionId}/subsections/${this.subsectionId}/items/${this.proposalId}/`,
        labelGetter: () => `Proposal`,
        projectIdGetter: () => this.invitationProjectId,
        postUploadParamsGetter: () => ["proposalUpload", this.proposalId.toString().replace(/[^a-zA-Z0-9 ]/g, "")],
		resumablePreUploadSlug: 'preUploadFilesR'
      })
    )
	}

	ngOnInit() {
		this.invitationProjectId = this.activeRouter.parent.snapshot.params['projectId'];
		this.proposalId = this.activeRouter.parent.snapshot.params['proposalId'];
		this.projectService.getProjectById(this.invitationProjectId)
			.subscribe((res) => {
				this.currency = res.currency;
				this.initialize();
			}, (err) => console.log(err));
	}

	initialize() {
		this.authUser = this.authService.getAuthUser();
		this.loadFormGroup();

		this.currencies = this.userService.getCurrencies();
		if (this.invitationProjectId) {
			this.pageloadFileUploadControl();
		}

		this.getFiles();

		if (this.projectService.isOnloadedProposal) {
			this.init();
		} else {
			onLoadedProposal.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				proposal => {
					this.init();
				}
			)
		}
		this.getPropByUserName();
		onCheckFileList.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			() => {
				this.changeProposalStatus('UPLOADED', 'CREATED')
			}
		)
	}

	private init() {
		this.proposal = this.projectService.proposal;

		const convertFNC = (value) => {
			if (!value)
				return null;

			return DateHelperService.fromDateToDatepicker(DateHelperService.fromTimestampToDate(value));
		};

		this.proposal['downpaymentDeadline'] = convertFNC(this.proposal['downpaymentDeadline']);
		this.proposal['endDate'] = convertFNC(this.proposal['endDate']);

		this.loadFormGroup();


		if (!this.authUser.isAdmin && this.proposalAccepted == false) {
			this.checkProposalAccepted();
		}
	}

	ngOnDestroy() {
		if (this.formGroup.value.downpaymentDeadline != null && this.formGroup.value.finalPaymentDeadline != null && this.formGroup.value.endDate != null) {
			this.proposal['downpaymentDeadline'] = DateHelperService.fromDatePickerToDate(this.formGroup.value.downpaymentDeadline);
			this.proposal['finalPaymentDeadline'] = DateHelperService.fromDatePickerToDate(this.formGroup.value.finalPaymentDeadline);
			this.proposal['endDate'] = DateHelperService.fromDatePickerToDate(this.formGroup.value.endDate);
		}

		if (this.subscribeUpladingFiles)
			clearInterval(this.subscribeUpladingFiles);

		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private loadFormGroup() {
		// Build FormGroup
		this.formGroup = this.fb.group({
			totalBudget: [this.proposal['budget'] ? this.proposal['budget'] : '', Validators.compose([Validators.required])],
			currency: [this.proposal['currency'] || this.currency, Validators.compose([Validators.required])],
			downpaymentRate: [this.proposal['downpaymentRate'] ? this.proposal['downpaymentRate'] : '', Validators.compose([Validators.required, Validators.min(10)])],
			downpaymentDeadline: [this.proposal['downpaymentDeadline'], Validators.compose([Validators.required])],
			endDate: [this.proposal['endDate'], Validators.compose([Validators.required])],
		});

		this.formGroup.get('currency').valueChanges.subscribe((val) => {
			this.onAmountBlur(null);
		});
		setTimeout(() => {
			this.onAmountBlur(null);
		}, 350);
	}

	private checkUploadingFiles() {
		if (this.newFiles.length > 0 && !this.subscribeUpladingFiles)
			this.subscribeUpladingFiles = setInterval(() => {
				if (this.newFiles.length <= 0) {
					clearInterval(this.subscribeUpladingFiles); // clear interval doesnt make it null
					this.subscribeUpladingFiles = null;
				}
				this.setViewPropsToFiles(this.files.filter(k => !k.displaySize));
			}, 1000);
	}

	private pageloadFileUploadControl() {
		this.syncBetweenPageAndUploadingFiles();
		this.checkUploadingFiles();
	}

	getFilesFromUploader(files): void {
		files = files.files;
		if (!files) {
			return;
		}
		this.prepareFilesForUpload(files);
		this.uploadFiles();
	}

	private uploadFiles(): void {
		this.startUpload();
		this.checkUploadingFiles();
		this.changeProposalStatus('CREATED', 'UPLOADED');
	}

	isProposalSaved(mtmUploadFile) {
		if (this.proposal['downpaymentDeadline'] != null &&
			this.proposal['finalPaymentDeadline'] != null &&
			this.proposal['currency'] != null &&
			this.proposal['currency'] != null &&
			this.proposal['budget'] != null &&
			this.proposal['downpaymentRate'] != null &&
			this.proposal['endDate'] != null) {

			const element = mtmUploadFile.fileInput.nativeElement;
			element.click();
		} else {
			this.overlayService.showError(this.translatePipe.transform('overlayPaymentDetailsError'), 'Error');
		}
		return false;
	}

	clickFileInput() {
		this.projectService.getProposalByUsername(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			if (proposal.status == 'ACCEPTED' || proposal.status == 'DECLINED') {
				this.overlayService.showError(this.translatePipe.transform('overlayProposalUploadError1') + proposal.status + this.translatePipe.transform('overlayProposalUploadError2'));
				return;
			}
			this.refMTMUploadFile.upload();
		});
	}

	hide() {
		this.hidden = true;
	}

	private getFiles() {
		this.proposalFileService.getProposalFiles(this.invitationProjectId, this.sectionId, this.subsectionId, this.proposalId).pipe(
			takeUntil(this.ngUnsubscribe))
			.subscribe((res: any[]) => {
				res = res.filter(f => f.projectId == this.invitationProjectId);
				if (!Array.isArray(res))
					return;

				this.setViewPropsToFiles(res);
				this.files = res;
				this.pageloadFileUploadControl();
			});
	}

	private setViewPropsToFiles(files: any[]) {
		files.map((f) => {
			f.uploadedDate = this.getUploadedDate(f.timeCreated);
			f.type = this.getIconUrl(f);
			f.displaySize = this.formatSizeUnits(f.size);
			//f.displayName = this.truncate(f.displayName);
		});
	}

	private getIconUrl(file: any) {
		
		const fileExtension = file.displayName.substr(file.displayName.lastIndexOf('.') + 1, 3);
		if (this.availableExtensions.indexOf(fileExtension) >= 0)
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/' + fileExtension + '.svg'));
		else
			return this.sane.sanitize(SecurityContext.RESOURCE_URL,this.sane.bypassSecurityTrustResourceUrl('assets/img/icons/file.svg'));
	}

	private truncate(fileName) {
		if (fileName.length > 20)
			return fileName.substring(0, 20) + '...' + fileName.substring(fileName.length - 4, fileName.length);
		return fileName;
	}

	private formatSizeUnits(bytes) {
		if (bytes >= 1073741824) {
			bytes = (bytes / 1073741824).toFixed(2) + ' GB';
		}
		else if (bytes >= 1048576) {
			bytes = (bytes / 1048576).toFixed(2) + ' MB';
		}
		else if (bytes >= 1024) {
			bytes = (bytes / 1024).toFixed(2) + ' KB';
		}
		else if (bytes > 1) {
			bytes = bytes + ' bytes';
		}
		else if (bytes == 1) {
			bytes = bytes + ' byte';
		}
		else {
			bytes = '0 byte';
		}
		return bytes;
	}

	private getUploadedDate(time: number): Date {
		return moment(time).toDate();
	}

	changeView(view: string): void {
		if (this.views[view] !== undefined) {
			this.view = this.views[view];
			this.getFiles();
		}
	}

	saveProposal() {
		if (this.formGroup.invalid) {
			this.overlayService.showError(this.translatePipe.transform('overlayPaymentProposal'), "Error");
			return;
		}
		let downpaymentDeadline = DateHelperService.fromDatePickerToDate(this.formGroup.value.downpaymentDeadline);
		let endDate = DateHelperService.fromDatePickerToDate(this.formGroup.value.endDate);
		this.proposal['downpaymentDeadline'] = DateHelperService.fromDateToApi(downpaymentDeadline);
		this.proposal['endDate'] = DateHelperService.fromDateToApi(endDate);
		this.proposal['currency'] = this.formGroup.value.currency;
		this.proposal['budget'] = this.formGroup.value.totalBudget.toString().replace(/[^\d.-]/g, '');
		this.proposal['downpaymentRate'] = this.formGroup.value.downpaymentRate;
		this.proposal['proposalContractApprovedDate'] = this.proposal['proposalContractApprovedDate'] ? this.proposal['proposalContractApprovedDate'] : new Date().getTime();
		this.projectService.uploadProposal(this.proposal, this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.refreshProposalInstance(res);
			this.reformatDates(downpaymentDeadline, endDate);
			this.getPropByUserName();
			this.overlayService.showSuccess(this.translatePipe.transform('overlayBudgetSave'), "Success")
		}, err => {
			this.overlayService.showError(this.translatePipe.transform('overlayBudgetSaveError'), "Error")
		});
	}

	reformatDates(downpaymentDeadline: Date, endDate: Date) {
		this.proposal['downpaymentDeadline'] = DateHelperService.fromDateToDatepicker(downpaymentDeadline);
		// this.proposal['finalPaymentDeadline'] = DateHelperService.fromDateToDatepicker(finalPaymentDeadline);
		this.proposal['endDate'] = DateHelperService.fromDateToDatepicker(endDate);
	}

	goToProposalDiscussionPage() {
		this.router.navigate(['projects', this.invitationProjectId, 'proposal', this.proposalId, 'discussion']);
	}

	private checkProposalAccepted() {
		this.projectService.checkProposalAccepted(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(res => {
			this.proposalAccepted = res;
		});
	}

	onRedirectToTCP(selector: string): boolean {
		return onRedirectToTCP_FNC(selector, [this.invitationProjectId, this.proposalId]);
	}

	private getPropByUserName() {
		let proposalDTO: any = {};
		this.projectService.getProposalByUsername(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			proposalDTO = proposal;
			this.clientCompanyName = proposal.clientCompanyName;
			this.contractApprovedByProducer = proposal.contractApprovedByProducer;
			if (proposal.contractApprovedByProducer) {
				this.firstCondition = proposal.contractApprovedByProducer;
				this.secondCondition = proposal.contractApprovedByProducer;
				this.thirdCondition = proposal.contractApprovedByProducer;
			}
			this.onChangedDueDate(DateHelperService.fromDateToDatepicker(DateHelperService.fromTimestampToDate(proposal.endDate)));
		})
	}

	onChangedDueDate(value) {
		this.finalPaymentDeadline = moment(DateHelperService.fromDatePickerToDate(value)).add('-1', 'd').toDate().toISOString();
	}

	changeProposalStatus(actualStatus: any, targetStatus: any) {
		let proposalDTO: any = {};
		this.projectService.getProposalByUsername(this.invitationProjectId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(proposal => {
			proposalDTO = proposal;
			if (proposalDTO.status == actualStatus)
				proposalDTO.status = targetStatus;
			this.clientCompanyName = proposal.clientCompanyName;
			this.projectService.saveUploadedProposal(proposalDTO, this.invitationProjectId).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(res => {
				this.refreshProposalInstance(res);
			});
		})

	}
	private refreshProposalInstance(proposal: any) {
		this.proposal = Object.assign({}, proposal);
		this.projectService.proposal = Object.assign({}, proposal);
	}


	checkedAllOtherTerms() {
		if (this.contractApprovedByProducer)
			return false;
		else
			return (this.firstCondition && this.secondCondition && this.thirdCondition);
	}

	totalBudgetFocused: boolean = false;
	onAmountBlur(e) {
		this.totalBudgetFocused = false;
		setTimeout(() => {
			this.formGroup.patchValue({
				totalBudget: (this.formGroup.value.totalBudget ? ((this.formGroup.value.currency === 'EUR' ? '€' : '$') + (this.formGroup.value.totalBudget.toString().replace(/[^\d.-]/g, '') || '')) : '')
			});
		}, 0);
	}

	onAmountFocused(e) {
		this.totalBudgetFocused = true;
		this.formGroup.patchValue({
			totalBudget: (this.formGroup.value.totalBudget ? parseInt((this.formGroup.value.totalBudget.toString().replace(/[^\d.-]/g, ''))) : '')
		});
	}
}
