import { AudioComp } from "../lib/audio-component";
import { utils } from "../lib/utils";
import { CommentList } from "./commentList";
import { Comment } from "./comment";
import { AnnotationPrivateCondition } from "../../../../interfaces";


export class Annotation extends AudioComp {
	id: string;
	shape: any;
	resolution: any;
	range: any;
	isOpen: boolean;
	comments: Comment[];
	commentList: CommentList;
	secondsActive: any;
	privateConditionKey: AnnotationPrivateCondition = null;

	constructor(data: any, image: any) {
		super(image);
		this.id = data.id || utils.guid();
		this.resolution = null;
		this.shape = null;
		this.range = data.range;
		this.secondsActive = this.buildSecondsActiveArray();
		this.privateConditionKey = data.privateConditionKey;

		this.buildComments(data);

		this.isOpen = false;
	}

	updateAnnotation(data) {
		this.resolution = data.resolution;
		this.shape = data.shape;
		this.range = data.range;

		this.buildComments(data);
		this.secondsActive = this.buildSecondsActiveArray();
	}

	buildComments(data) {
		this.commentList = new CommentList({ comments: data.comments, annotation: this }, this.plugin);
		this.comments = this.commentList.comments;
	}

	buildSecondsActiveArray() {
		var seconds = [];
		let rangeEnd;
		if (this.range.end == this.range.start) {
			this.range.end = this.range.end + 0.05;
		}
		if (this.range.stop) {
			rangeEnd = this.range.stop;
		}
		else {
			rangeEnd = this.range.end
		}
		if (rangeEnd) {
			for (var i = this.range.start; i <= rangeEnd; i = i + 0.01) {
				seconds.push(Math.round(i * 100) / 100);
			}
		} else {
			var start = this.range.start;

			seconds.push(start);
			// if (start < this.duration) seconds.push(start + 1);
		}
		return seconds;
	}

	get data() {
		return {
			id: this.id,
			shape: this.shape,
			comments: this.commentList.data,
			resolution: this.resolution,
			range: this.range,
			privateConditionKey: this.privateConditionKey
		}
	}

	open() {
		this.plugin.fire('annotationOpened', this.data);
	}

	hoverOpen() {
		this.plugin.fire('annotationHoveredIn', { annotation: this.data, type: "hover" });
		this.plugin.fire('annotationHoverOpened', { annotation: this.data }); // for annotation-bar comp
	}

	hoverClose() {
		this.plugin.fire('annotationHoveredOut', { annotation: this.data, type: "hover" });
		this.plugin.fire('annotationHoverClosed', { annotation: this.data }); // for annotation-bar comp
	}

	clickOpen() {
		// this.plugin.Amplitude.setSongPlayedPercentage(this.getPercentage(this.data.range.start));
		this.setCurrentTime(this.data.range.start);
		this.plugin.fire('annotationClickedIn', { annotation: this.data, type: "click" });
		// this.plugin.fire('annotationClickedOpened', {annotation: this.data}); // for annotation-bar comp
	}

	clickClose() {
		this.plugin.fire('annotationClickedOut', { annotation: this.data, type: "click" });
		// this.plugin.fire('annotationClickedClosed', {annotation: this.data}); // for annotation-bar comp
	}

	close() {
		this.plugin.fire('annotationClosed', this.data);
	}

	teardown(removeFromCollection = true) {
		this.close();
		if (this.commentList) this.commentList.teardown(removeFromCollection);
		if (removeFromCollection) this.plugin.annotationState.removeAnnotation(this);
	}

	static newFromData(commentStr, parentId, range, plugin, id = null, privateConditionKey: AnnotationPrivateCondition = null) {
		var comment = Comment.dataObj(commentStr, parentId, plugin);
		var data = {
			id: id,
			comments: [comment],
			range: range,
			privateConditionKey
		};
		return new Annotation(data, plugin);
	}



}
