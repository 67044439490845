<div class="rights-transfer-container">
  <div class="modal-header d-flex flex-column align-items-center">
    <h1 class="modal-title mb-2">{{ (isProjectManagerTransfer ? 'projectOwnershipTransfer_originalOwnershipTransfer': 'projectOwnershipTransfer') | translate}}</h1>
    <div class="text-center description" *ngIf="transferType === OwnershipTransferTypes.PROJECT && projects?.length">
      {{'projectOwnershipTransfer_description' | translate}}<br/>
      <em>{{projects[0].name}}</em>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="transferForm" (ngSubmit)="submit()">

      <div class="form-group">
        <label for="sourceUserId">{{'projectOwnershipTransfer_owner' | translate}}</label>

        <ng-select [items]="companyUsers" appearance="outline" [readonly]="true" [clearable]="false"
                   formControlName="sourceUserId" bindLabel="fullName"
                   bindValue="username" [searchable]="false" id="sourceUserId">
          <ng-template ng-label-tmp let-item="item">
            <div class="d-flex align-items-center">
              <mtm-user-profile-picture [forceLoadProfile]="true" [username]="item.username" [height]="22"
                                        [width]="22">
              </mtm-user-profile-picture>
              <span class="ml-2">{{ item.fullName }}</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="d-flex align-items-center">
              <span class="ml-2">{{ item.fullName }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group" *ngIf="transferType === OwnershipTransferTypes.WORKSPACE">
        <label for="selectedProjects">{{'projectOwnershipTransfer_assign' | translate}}</label>
        <ng-select [items]="projects" [multiple]="true" bindLabel="name" bindValue="id" [searchable]="false"
                   [clearable]="true" [selectableGroup]="true" [closeOnSelect]="false"
                   formControlName="selectedProjects"
                   [placeholder]="'Select Project'" id="selectedProjects">
          <ng-template ng-multi-label-tmp>
                        <span style="display:block"
                              class="ng-multi-label"> {{transferForm?.value?.selectedProjectIds?.length}}
                          {{'projectsSelected' | translate}} </span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/>
            <span class="ml-2 option-label" title="{{item.name | translate}}">{{item.name | translate}}</span>
          </ng-template>
        </ng-select>
      </div>


      <div class="form-group mb-3">
        <label for="targetUserIds">New Owner(s)</label>
        <ng-select [items]="availableTargets" appearance="outline" [multiple]="! isProjectManagerTransfer" bindLabel="label"
                   bindValue="username"
                   [searchable]="false" [clearable]="true" [selectableGroup]="true" [closeOnSelect]="false"
                   formControlName="targetUserIds" [placeholder]="'Select User'" id="targetUserIds"
                   (change)="onTargetUsersChange()">

          <ng-template ng-multi-label-tmp>
                        <span style="display:block"
                              class="ng-multi-label"> {{transferForm?.value?.targetUserIds?.length}}
                          {{'usersSelected' | translate}}
                        </span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="d-flex align-items-center">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" class="mr-2"/>
              <mtm-user-profile-picture [forceLoadProfile]="true" [username]="item.username" [height]="22"
                                        [width]="22">
              </mtm-user-profile-picture>
              <span class="ml-2" [title]="item.username">{{ item.label }}</span>
            </div>
          </ng-template>
        </ng-select>

        <div *ngIf="transferForm?.value?.targetUserIds?.length && !isProjectManagerTransfer">
          <div *ngFor="let user of selectedUsers" class="d-flex align-items-center px-3 py-2">
                        <span class="d-inline-block mr-2" role="button" (click)="removeSelectedUser(user.username)">
                          <i class="fa fa-times text-danger"></i>
                        </span>

            <mtm-user-profile-picture [forceLoadProfile]="true" [username]="user.username" [height]="22"
                                      [width]="22">
            </mtm-user-profile-picture>
            <span class="name ml-2" [title]="user.username">{{user.fullName?.trim() || user.username}}</span>
          </div>
        </div>

        <!--
        <div class="d-flex align-items-center">
            <button class="btn mt-2 btn-add-user px-0">
                <span class="d-inline-flex align-items-center justify-content-center mr-2 icon-wrapper">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                <span>Add user</span>
            </button>
        </div>
        -->
      </div>


      <div class="form-group" *ngIf="! isProjectManagerTransfer">
        <label class="label-delete-user"
               for="deleteSourceUser">{{'projectOwnershipTransfer_removeOwnerQuestion' | translate}}</label>

        <div class="d-flex align-items-center">
          <input type="checkbox" formControlName="deleteSourceUser" id="deleteSourceUser"/>
          <span class="ml-2 mr-5 span-delete">{{'delete' | translate}}</span>

          <mtm-user-profile-picture [forceLoadProfile]="true" [username]="sourceUser?.username" [height]="22"
                                    [width]="22">
          </mtm-user-profile-picture>
          <span class="ml-2 span-delete">{{ sourceUser?.fullName }}</span>
        </div>
      </div>

      <div class="buttons text-center">
        <button type="button" class="btn btn-cancel text-uppercase mr-3"
                (click)="cancel()">{{'cancel' | translate }}</button>
        <button type="submit" class="btn btn-save text-uppercase"
                [disabled]="isLoading">{{'save' | translate }}</button>
      </div>

    </form>

  </div>

</div>
