import { Component, Input, ViewChild, ElementRef } from "@angular/core";

@Component({
	selector: 'loading-data',
	templateUrl: './loading-data.component.html',
	styleUrls: ['./loading-data.component.scss']
})
export class LoadingDataComponent {
	@ViewChild('container', { static: true }) container: ElementRef;

	@Input() iconSize: number = 48;

	isDisplay = false;
	isLoading = false;
	isError = false;
	isInfo = false;

	globalClass = [];
	iconStyle = {};
	textStyle = {};

	loadingText = 'Loading...';
	errorText = 'An error while trying to retrieve data';
	infoText = '...';

	constructor() { }

	ngOnInit() {
		this.inputVariableChanges();
	}

	ngOnChanges() {
		this.inputVariableChanges();
	}

	private inputVariableChanges() {
		this.iconStyle = {
			'font-size': `${this.iconSize}px`,
			'color': 'red'
		}
		this.textStyle = {
			'font-size': `${this.iconSize / 2.5}px`
		}
	}

	public initParameters(loadingText, errorText, globalClass: string[] = []) {
		this.loadingText = loadingText;
		this.errorText = errorText;
		this.globalClass = globalClass;
	}

	private initStyle() {
		setTimeout(() => {
			if (!this.container) {
				return;
			}

			this.container.nativeElement.classList = [];
			this.globalClass.map(k => this.container.nativeElement.classList.add(k));

		}, 55)
	}

	private setNGParameters(isDisplay, isLoading, isError, isInfo) {
		this.isDisplay = isDisplay;
		this.isLoading = isLoading;
		this.isError = isError;
		this.isInfo = isInfo;

		this.initStyle();
	}

	public showLoading(loadingText?) {
		this.setNGParameters(true, true, false, false);
		if (loadingText)
			this.loadingText = loadingText;
	}

	public showError(errorText?) {
		this.setNGParameters(true, false, true, false);
		if (errorText)
			this.errorText = errorText;
	}

	public showInfo(infoText) {
		this.setNGParameters(true, false, false, true);
		this.infoText = infoText;
	}

	public hide() {
		this.setNGParameters(false, false, false, false);
	}

	public showInfoForArrayData(data, infoText) {
		!Array.isArray(data) || data.length <= 0 ? this.showInfo(infoText) : this.hide();
	}
}
