import { AfterViewInit, Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
	selector: 'mtm-project-description',
	templateUrl: './project-description.component.html',
	styleUrls: ['./project-description.component.scss']
})
export class ProjectDescription implements OnInit, AfterViewInit {
	@Input() value: any;

	isShow: boolean = false;
	isOverflow: boolean = false;

	constructor(private elementRef: ElementRef) { }

	ngOnInit(): void { }

	ngAfterViewInit(): void {
		setTimeout(() => {
			let tempElement = this.elementRef.nativeElement.getElementsByClassName('temp');
			if (tempElement.length) {
				let boundSize = tempElement[0].getBoundingClientRect();
				if (boundSize.height > 42) {
					this.isOverflow = true;
				}
			}
		}, 0);
	}
}
