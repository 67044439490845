<svg width="24" height="22" viewBox="0 0 24 22" fill="none" class="no-stroke" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.5808 3.64805C23.5747 2.8316 23.2476 2.05032 22.6703 1.47298C22.093 0.895645 21.3117 0.568601 20.4952 0.5625H3.08556C2.2691 0.568601 1.48782 0.895645 0.910482 1.47298C0.333145 2.05032 0.00610079 2.8316 0 3.64805V14.2415C0 14.4116 0.0675563 14.5747 0.187806 14.6949C0.308055 14.8152 0.471144 14.8827 0.641202 14.8827C0.811261 14.8827 0.97435 14.8152 1.0946 14.6949C1.21485 14.5747 1.2824 14.4116 1.2824 14.2415V3.64805C1.28193 3.31919 1.37141 2.99644 1.54115 2.71477C1.7109 2.43309 1.95443 2.2032 2.24542 2.04997C2.53642 1.89674 2.86378 1.82602 3.19208 1.84545C3.52037 1.86488 3.8371 1.97372 4.10799 2.1602C4.37888 2.34668 4.5936 2.60369 4.72894 2.90342C4.86427 3.20315 4.91507 3.53419 4.8758 3.86071C4.83654 4.18722 4.70872 4.49679 4.50619 4.75589C4.30366 5.015 4.03413 5.21378 3.72676 5.33073V3.64805C3.72676 3.478 3.6592 3.3149 3.53895 3.19465C3.4187 3.0744 3.25561 3.00685 3.08556 3.00685C2.9155 3.00685 2.7524 3.0744 2.63215 3.19465C2.5119 3.3149 2.44435 3.478 2.44435 3.64805V20.7274C2.44435 20.8975 2.5119 21.0606 2.63215 21.1808C2.7524 21.3011 2.9155 21.3686 3.08556 21.3686H20.4952C20.6653 21.3686 20.8284 21.3011 20.9486 21.1808C21.0689 21.0606 21.1364 20.8975 21.1364 20.7274V6.66755C21.8262 6.51663 22.4441 6.13541 22.8883 5.58661C23.3326 5.03781 23.5768 4.35413 23.5808 3.64805ZM3.72676 20.0668V6.73749H19.8657V20.0668H3.72676Z" />
    <path d="M11.0132 9.24402H9.28003V10.9772H11.0132V9.24402Z" />
    <path d="M14.157 9.24402H12.4238V10.9772H14.157V9.24402Z" />
    <path d="M7.86943 12.2285H6.13623V13.9617H7.86943V12.2285Z" />
    <path d="M11.0132 12.2285H9.28003V13.9617H11.0132V12.2285Z" />
    <path d="M14.157 12.2285H12.4238V13.9617H14.157V12.2285Z" />
    <path d="M7.86943 15.2131H6.13623V16.9462H7.86943V15.2131Z" />
    <path d="M11.0132 15.2131H9.28003V16.9462H11.0132V15.2131Z" />
    <path d="M14.157 15.2131H12.4238V16.9462H14.157V15.2131Z" />
    <path d="M17.3008 9.24402H15.5676V10.9772H17.3008V9.24402Z" />
    <path d="M17.3008 12.2285H15.5676V13.9617H17.3008V12.2285Z" />
</svg>