import { Injectable } from '@angular/core';
import { Http } from "@angular/http";
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { UploadService } from './upload.service';

const STYLE_ALIGNMENT = {
	'bottom-left': 1,
	'bottom-center': 2,
	'bottom-right': 3,
	'middle-left': 9,
	'middle-center': 10,
	'middle-right': 11,
	'top-left': 5,
	'top-center': 6,
	'top-right': 7
}

export interface SubtitleStyle {
	font_color: string,
	font_size: any,
	alignment?: number,
	text_background: any;
	text_shadow: any;
	font_family: any;
	hAlignment?: any;
	vAlignment?: any;
}

@Injectable({
	providedIn: 'root'
})
export class MovieTranslationService {
	baseUrl = environment.subtitleGenerationApiUrl;
	headers: any;

	constructor(private http: Http, private uploadService: UploadService) {
		this.headers = HelperService.getMovieSubtitleEndpointHeader();
	}

	createMovieSubtitle(section_id: string, subSectionId: string, directoryId: string, subtitleId: string, file_id: string, file_name: string, movie_url: string, project_id: string, username: string): Observable<Response> {
		const url = `${this.baseUrl}/subtitles-init/${subtitleId}/${subSectionId}/${directoryId}`;
		const data = { section_id, movie_url, file_id, file_name, project_id, username };
		return this.http.post(url, data, { headers: this.headers })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	saveTranslations(section_id: string, subSectionId: string, directoryId: string, subtitleId: string, data: any, project_id: string, file_id: string, username: string) {
		const url = `${this.baseUrl}/subtitle-item/${subtitleId}/${subSectionId}/${directoryId}`;
		data.subtitleJson = data.subtitleJson.map((t: any, idx: any) => {
			t.index = idx + 1;
			return t;
		})
		return this.http.put(url, { ...data, section_id, file_id, project_id, username }, { headers: this.headers })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	deleteTranslation(sectionId: string, subSectionId: string, directoryId: string, subtitleId: string, language: string, project_id: string, fileId: string, username: string) {
		const url = `${this.baseUrl}/subtitle-item/${subtitleId}/${subSectionId}/${directoryId}`;
		const data = { section_id: sectionId, language: language, project_id: project_id, file_id: fileId, username: username };
		return this.http.delete(url, { headers: this.headers, body: data })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	addMovieSubtitle(section_id: string, subSectionId: string, directoryId: string, subtitleId: string, language: string, project_id: string, file_id: string, username: string) {
		const url = `${this.baseUrl}/subtitle-translate/${subtitleId}/${subSectionId}/${directoryId}`;
		const data = { section_id: section_id, language: language, project_id: project_id, file_id: file_id, username: username };
		return this.http.post(url, data, { headers: this.headers })
			.pipe(
				map((response: any) => {
					let subtitle = response.json();
					subtitle.subtitles = subtitle.subtitles.map(s => {
						s.subtitleJson = !HelperService.isObjectEmpty(s.subtitleJson) ? s.subtitleJson.map(m => this.validateStartAndEndSubtitle(m)) : void 0;
						return s;
					});
					return subtitle;
				}),
				catchError(error => throwError(error.json ? error.json() : error))
			);;
	}

	burnVideoSubtitle(sectionId: string, projectId: any, subtitleId: any, subSectionId: any, directoryId: any, language: any, subtitleStyle: any, file_id: string, movieUrl: any, username: string, isCustomFont?: boolean) {
		const url = `${this.baseUrl}/burn-subtitles/${subtitleId}/${subSectionId}/${directoryId}`;
		const extractParams = {
			alignment: STYLE_ALIGNMENT[subtitleStyle.vAlignment + '-' + subtitleStyle.hAlignment],
			font_family: {
				is_custom: isCustomFont,
				name: subtitleStyle.font_family
			}
		}
		const styles: SubtitleStyle = {
			...subtitleStyle,
			...extractParams
		};
		const data = {
			username: username,
			file_id: file_id,
			section_id: sectionId,
			styling_params: styles,
			language: language,
			movie_url: movieUrl,
			project_id: projectId
		};
		return this.http.post(url, data, { headers: this.headers })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	deleteBurnSubtitle(sectionId: string, project_id: any, subtitleId: any, subSectionId: any, directoryId: any, language: any, subtitleStyle: any, fileId: string, movieUrl: any, username: string) {
		const url = `${this.baseUrl}/burn-subtitles/${subtitleId}/${subSectionId}/${directoryId}`;
		const extractParams = {
			alignment: STYLE_ALIGNMENT[subtitleStyle.vAlignment + '-' + subtitleStyle.hAlignment],
			font_family: {
				is_custom: false,
				name: subtitleStyle.font_family
			}
		}
		const styles: SubtitleStyle = {
			...subtitleStyle,
			...extractParams
		};
		const data = {
			username: username,
			section_id: sectionId,
			file_id: fileId,
			styling_params: styles,
			language: language,
			movie_url: movieUrl,
			project_id: project_id
		};
		return this.http.delete(url, { body: data, headers: this.headers })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	getFontByProject(project_id: any) {
		const url = `${this.baseUrl}/get-fonts-by-project`;
		return this.http.post(url, { project_id }, { headers: this.headers })
			.pipe(
				map(response => response.json()),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	uploadCustomFont(fonts, projectId) {
		const url = `${this.baseUrl}/upload-font`;
		return this.uploadService.uploadFiles(fonts, url, true, 'font_file', { project_id: projectId });
	}

	validateStartAndEndSubtitle(translateData) {
		let startArr = translateData.start.split(':'), endArr = translateData.end.split(':');
		if (!startArr.length) {
			startArr = ['00', '00', '00.000']
		}

		if (startArr.length === 1) {
			startArr[1] = "00";
			startArr[2] = "00.000";
		} else if (startArr.length === 2) {
			startArr[2] = "00.000";
		}

		if (endArr.length === 1) {
			endArr[1] = "00";
			endArr[2] = "00.000";
		} else if (endArr.length === 2) {
			endArr[2] = "00.000";
		}

		if (startArr[0].length < 2) {
			startArr[0] = '0' + startArr[0];
		}

		if (startArr[1].length < 2) {
			startArr[1] = '0' + startArr[1];
		}

		if (endArr[0].length < 2) {
			endArr[0] = '0' + endArr[0];
		}

		if (endArr[1].length < 2) {
			endArr[1] = '0' + endArr[1];
		}

		let startMsArr = startArr[2].split('.'), endMsArr = endArr[2].split('.');
		if (startMsArr[0].length < 2) {
			startMsArr[0] = '0' + startMsArr[0];
		}

		if (!startMsArr[1]) {
			startMsArr[1] = '000';
		} else if (startMsArr[1].length === 2) {
			startMsArr[1] = startMsArr[1] + '0';
		} else if (startMsArr[1].length === 1) {
			startMsArr[1] = startMsArr[1] + '00';
		}

		if (endMsArr[0].length < 2) {
			endMsArr[0] = '0' + endMsArr[0];
		}

		if (!endMsArr[1]) {
			endMsArr[1] = '000';
		} else if (endMsArr[1].length === 2) {
			endMsArr[1] = endMsArr[1] + '0';
		} else if (endMsArr[1].length === 1) {
			endMsArr[1] = endMsArr[1] + '00';
		}

		startMsArr[1] = startMsArr[1].slice(0, 3);
		endMsArr[1] = endMsArr[1].slice(0, 3);
		translateData.start = [startArr.slice(0, 2).join(':'), startMsArr.join('.')].join(':');
		translateData.end = [endArr.slice(0, 2).join(':'), endMsArr.join('.')].join(':');
		return translateData;
	}

	getSubtitle(subSectionId: string, directoryId: string, translationId: string) {
		const url = `${this.baseUrl}/subtitle/${translationId}/${subSectionId}/${directoryId}`;
		return this.http.get(url, { headers: this.headers })
			.pipe(
				map((response: any) => {
					let subtitle = response.json();
					if (subtitle.subtitles) {
						subtitle.subtitles = subtitle.subtitles.map(s => {
							s.subtitleJson = !HelperService.isObjectEmpty(s.subtitleJson) ? s.subtitleJson.map(m => this.validateStartAndEndSubtitle(m)) : void 0;
							return s;
						});
					}
					return subtitle;
				}),
				catchError(error => throwError(error.json ? error.json() : error))
			);
	}

	getOriginalLanguageSources() {
		const url = `${this.baseUrl}/speech-to-text-languages`;
		return this.http.get(url, { headers: this.headers })
			.pipe(
				map((response: any) => {
					let result = [];
					let data = response.json();
					for (const key in data) {
						result.push({ code: data[key], name: key });
					}
					return result;
				}),
				catchError(error => throwError(error.json ? error.json() : error))
			);;
	}

	getAvailableLanguageSources() {
		const url = `${this.baseUrl}/translate-languages`;
		return this.http.get(url, { headers: this.headers })
			.pipe(
				map((response: any) => {
					let result = [];
					let data = response.json();
					for (const key in data) {
						result.push({ code: data[key], name: key });
					}
					return result;
				}),
				catchError(error => throwError(error.json ? error.json() : error))
			);;
	}

	calculateTimeInMilliseconds(timeString: string) {
		const timeArr = timeString.split(':'), timeInSecondArr = timeArr[2].split('.');
		let totalInMilliSeconds = (parseInt(timeArr[0]) * 1000000000) + (parseInt(timeArr[1]) * 1000000) + (parseInt(timeInSecondArr[0]) * 1000) + (parseInt(timeInSecondArr[1]) * 1);
		return totalInMilliSeconds;
	}

	getTimeStrFromNumber(num: number) {
		const hours = Math.round(num / 1000000000), hourMod = num % 1000000000;
		const minutes = Math.round(hourMod / 1000000), minuteMod = hourMod % 1000000;
		const seconds = Math.round(minuteMod / 1000), secondMod = minuteMod % 1000;
		const milliseconds = Math.round(secondMod / 1);
		return `${hours}:${minutes}:${seconds}.${milliseconds < 100 && milliseconds > 9 ? '0' + milliseconds.toString() : milliseconds < 10 ? '00' + milliseconds.toString() : milliseconds}`;
	}
}