import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DummyDataService {
  filteredTimelines: any;
  filteredProjects: any
  filteredMilestones: any;
  authUser: any;
  scenario: string;
  mainTimeline: any;
  selectedMilestone: any;

  public timelineEmitter: EventEmitter<any> = new EventEmitter<any>();
  public filterEmitter: EventEmitter<any> = new EventEmitter<any>();
  public selectedFilter = new BehaviorSubject([]);
  public selectedfilterData = this.selectedFilter.asObservable();

  constructor() { }

  updateColor(timeline) {
    timeline.timelines.forEach(timeline => {
      timeline.items.forEach(element => {
        if (element.status == 'In Progress') {
          element.statusColor = '#fff'
        }
        if (element.status == 'Completed') {
          element.statusColor = '#43CB9A'
        }
        if (element.status == 'Delayed') {
          element.statusColor = '#F67373'
        }
      });
    });
  }

  updateMainTimeline(milesone) {
    this.timelineEmitter.emit(milesone);
  }
}
