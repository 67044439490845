<div class="compare-plan-container">
    <div class="text-center text-uppercase font-weight-bold my-5 main-info">
        <div class="mb-2 primary">{{'subscription_upgrade_comparePlan_warning_line1' | translate}} {{ newPlan.name }}
        </div>
        <div class="secondary">{{'subscription_upgrade_comparePlan_warning_line2' | translate}}</div>
    </div>
    <div class="d-flex align-items-center justify-content-center compare-plan-selector">

        <div class="d-flex flex-column w-25 mr-4 plan-item old-plan" *ngIf="prevPlan">
           
            <div class="p-4 text-center text-uppercase plan-info">
                <div class="text-uppercase font-weight-bold my-2 plan-name">{{ prevPlan.name }}</div>
                <div class="mb-3 value pricing">
                    <span class="font-weight-bold cost">{{subscriptionService.currency}} {{
                        prevPlan.basePrice[prevPeriod].value }}</span>
                    <span>{{'subscription_register_select_plan_perUserPerMonth'|translate}}</span>
                </div>

                <div class="mb-3 user-info">
                    <div class="mb-2 label">{{'subscription_register_select_plan_minimumUsers'|translate}}</div>
                    <div class="mb-2 value min-user">{{prevPlan.minUsers}}</div>
                    <div class="mb-2 label"> {{'subscription_register_select_plan_upTo'|translate}}</div>
                    <div class="value max-users">{{prevPlan.maxUsers}}
                        {{'subscription_register_select_plan_activeUsers'|translate}}</div>
                </div>

                <div class="storage-info">
                    <div class="mb-2 label">{{'subscription_register_select_plan_activeStorage'|translate}}</div>
                    <div class="mb-2 value storage-value">{{prevPlan.activeStorage.value +
                        prevPlan.activeStorage.metric}}</div>
                    <div class="mb-2 label">{{'subscription_register_select_plan_archival'|translate}}</div>
                    <div class="value archival-value">{{prevPlan.archivalStorage.value +
                        prevPlan.archivalStorage.metric}}</div>
                </div>
            </div>
            <div class="p-4 features" *ngIf=" prevPlan.features">
                <div class="label mb-2 feature-label">{{'subscription_register_select_plan_mainFeatures'|translate}}
                </div>
                <ul>
                    <li *ngFor="let feature of prevPlan.features">
                        {{'subscription_register_select_plan_'+prevPlan.code+'_'+feature|translate}}
                    </li>
                </ul>
            </div>
        </div>


        <div class="d-flex flex-column w-25 plan-item new-plan" *ngIf="newPlan">
            <span class="icon">✓</span>
            <div class="p-4 text-center text-uppercase plan-info">
                <div class="text-uppercase font-weight-bold my-2 plan-name">{{newPlan.name}}</div>
                <div class="mb-3 value pricing">
                    <span class="font-weight-bold cost">{{subscriptionService.currency}} {{
                        newPlan.basePrice[currentPeriod].value }}</span>
                    <span>{{'subscription_register_select_plan_perUserPerMonth'|translate}}</span>
                </div>

                <div class="mb-3 user-info">
                    <div class="mb-2 label">{{'subscription_register_select_plan_minimumUsers'|translate}}</div>
                    <div class="mb-2 value min-user">{{newPlan.minUsers}}</div>
                    <div class="mb-2 label"> {{'subscription_register_select_plan_upTo'|translate}}</div>
                    <div class="value max-users">{{newPlan.maxUsers}}
                        {{'subscription_register_select_plan_activeUsers'|translate}}</div>
                </div>

                <div class="storage-info">
                    <div class="mb-2 label">{{'subscription_register_select_plan_activeStorage'|translate}}</div>
                    <div class="mb-2 value storage-value">{{newPlan.activeStorage.value + newPlan.activeStorage.metric}}
                    </div>
                    <div class="mb-2 label">{{'subscription_register_select_plan_archival'|translate}}</div>
                    <div class="value archival-value">{{newPlan.archivalStorage.value + newPlan.archivalStorage.metric}}
                    </div>
                </div>
            </div>
            <div class="p-4 features" *ngIf="newPlan.features">
                <div class="label mb-2 feature-label">{{'subscription_register_select_plan_mainFeatures'|translate}}
                </div>
                <ul>
                    <li *ngFor="let feature of newPlan.features">
                        {{'subscription_register_select_plan_'+newPlan.code+'_'+feature|translate}}
                    </li>
                </ul>
            </div>
        </div>

    </div>

    <div class="text-center mt-4 foot-note">
        {{'subscription_upgrade_comparePlan_footerInfo' |
        translate:{'END_DATE':(currentSubscription.endDate*1000|mtmDateTime) } }}
    </div>

    <div class="text-center mt-5 upgrade-form-navigation mt-3">
        <div class="d-inline-flex flex-column">
            <a class="btn d-inline-block mb-3 mtm-button mtm-main-button" [routerLink]="['../confirm-plan']">
                {{'continue'|translate}}
            </a>
            <a href="" class="d-inline-block mb-3 cancel">
                {{'cancel'|translate}}
            </a>
        </div>
    </div>
</div>