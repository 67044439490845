
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Http, Request, RequestOptionsArgs, Headers, Response, RequestOptions, ConnectionBackend } from "@angular/http";
import { HelperService } from "../services/helper.service";
import { environment } from "../../../environments/environment";
import { EmitterService } from "../services/emitter.service";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
// import "rxjs/add/observable/empty";
// import { JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptor extends Http {
    constructor(backend: ConnectionBackend, defaultOptions: RequestOptions) {
        super(backend, defaultOptions);
    }

    request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.request(url, options));
    }

    get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.get(url, options));
    }

    post(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.post(url, body, options));
    }

    put(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.put(url, body, options));
    }

    delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.delete(url, options));
    }

    /**
     * Intercept any request
     * @param observable
     * @returns {Observable<Response>}
     */
    intercept(observable: Observable<Response>): Observable<Response> {

        return observable.pipe(catchError((err, source) => {

            // If error is 401
            if (err.status == 401) {

                localStorage.clear();

                // Redirect to Login Page - Reload the page
                //window.location.href = '/entry';
                return observableThrowError(err);
            }

            // If error is 503
            else if (err.status == 503) {

                // Check if token has expired
                if (localStorage.getItem('token')) {
                    let jwtHelper: JwtHelperService = new JwtHelperService();
                    let token = localStorage.getItem('token');
                    if (jwtHelper.isTokenExpired(token)) {
                        localStorage.clear();
                        window.location.href = '/entry';
                        return observableThrowError(err);
                    }
                }

                // Backend is down
                const message = { 'action': 'backendStatus.down' };
                localStorage.setItem('message', JSON.stringify(message));
                localStorage.removeItem('message');
                return observableThrowError(err);
            }

            // Other error
            else {

                // Check if token has expired
                if (localStorage.getItem('token')) {
                    let jwtHelper: JwtHelperService = new JwtHelperService();
                    let token = localStorage.getItem('token');
                    if (jwtHelper.isTokenExpired(token)) {
                        localStorage.clear();
                        window.location.href = '/entry';
                        return observableThrowError(err);
                    }
                }
                return observableThrowError(err);
            }

        }));
    }

}
