import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from "app/shared/services/emitter.service";

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {


    constructor(private router: Router) {

    }

    /**
     * Send a new message
     * @param message
     */
    send(message) {
        localStorage.setItem('message', JSON.stringify(message));
        localStorage.removeItem('message');
    }


    /**
     * Receive notification
     * @param ev
     */
    receive(ev) {

        if (ev.originalEvent.key != 'message')
            return;

        var message = JSON.parse(ev.originalEvent.newValue);

        if (!message)
            return;

        // If broadcast service sent, then process message
        if (ev && ev.data && ev.data.broadcastService)
            ev.data.broadcastService.process(message, ev);
    }


    /**
     * Process message
     * @param message
     * @param ev
     */
    process(message: any, ev: any = null) {

        // If message exists
        if (message && message.action) {
            console.log(message.action);
            switch (message.action) {

                // Logout user
                case 'user.logout':
                    this.router.navigate(['/entry']);

                // Default, just print message
                default:
                    console.log('default: ' + message.action)
            }
        }
    }

}
