<div class="container-fluid card py-1" *ngIf="!isTrainer">
    <div *ngIf="!showRegistrationFields">
        <div class="text-center my-4">
            <h6 class="font-weight-bold">{{'signInTitle' | translate}}</h6>
        </div>

        <div class="row d-flex align-items-center justify-content-center social-buttons">
            <button id="google-btn" class="btn btn-google mr-4" (click)="onGoogleLogin()"><i class="fa fa-google"
                    aria-hidden="true"></i>&nbsp;&nbsp;Google
            </button>
            <button id="azure-btn" class="btn btn-azure" (click)="onAzureLogin()"><i class="fa fa-windows"
                    aria-hidden="true"></i>&nbsp;&nbsp;Microsoft
            </button>
        </div>
        <div class="login-divider">
            <small class="text-uppercase font-weight-bold">{{'signInOr' | translate}}</small>
        </div>
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onNewSubmit()">
            <label class="form-group row has-float-label"
                [ngClass]="{'has-danger': loginForm.controls.email.invalid && submitted}">
                <input id="email-tb" class="form-control" type="text" formControlName="email" placeholder=" "
                    [ngClass]="{'form-control-danger': loginForm.controls.email.invalid && submitted}">
                <span *ngIf="!(submitted && loginForm.controls.email.invalid)">{{'signInEmail' | translate}}</span>
                <span *ngIf="submitted && loginForm.controls.email.invalid" class='text-danger'>Please enter a valid
                    email</span>
            </label>
            <label class="form-group row has-float-label"
                [ngClass]="{'has-danger': loginForm.controls.password.invalid && submitted}">
                <input id="password-tb" class="form-control" type="password" formControlName="password" placeholder=" "
                    [ngClass]="{'form-control-danger': loginForm.controls.password.invalid && submitted}" passwordToggle>
                <span *ngIf="!(submitted && loginForm.controls.password.invalid)">{{'signInPassword' |
                    translate}}</span>
                <span *ngIf="submitted && loginForm.controls.password.invalid" class='text-danger'>Password is
                    required</span>
            </label>
            <div class="text-center">
                <button id="signin-btn" type="submit" class="btn btn-lg btn-orange btn-block">
                    {{submitBtn | translate}}
                </button>
            </div>
            <div class="row justify-content-between my-over">
                <label class="form-check-label ml-20">
                    <input id="remember-me-cb" formControlName="remember_me" type="checkbox" class="form-check-input">
                    {{'signInRemember' | translate}}
                </label>
                <!--<a id="login-with-email-link" class="text-primary justify-self-right font-weight-bold" href="#" (click)="onLoginWithEmailModal()">Login with Email</a>-->
                <a id="forgot-your-details-link" class="text-primary justify-self-right font-weight-bold" href="#"
                    (click)="onOpenForgotPasswordModal()">{{'signInForgot' | translate}}</a>
            </div>

        </form>
        <div class="row" *ngIf="false">
            <button (click)="showVerifyModal()">Debug verify</button>
        </div>
        <div class="card-text options password-checker d-none" *ngIf="authUser?.companyId">
            <mtm-password-checker [companyId]="authUser.companyId" [showValidation]="loginForm.controls.password.dirty" [password]="loginForm.controls.password.value" (passwordValidated)="passwordValidated($event)"></mtm-password-checker>
        </div>

        <div *ngIf="fromOneShot" class="row justify-content-between">
            <label>
                {{'registerDoHaveAccount' | translate}}
                <span class="register" (click)="showRegistrationFields=!showRegistrationFields">{{'signUpButton' |
                    translate}}</span>
            </label>
        </div>

    </div>


    <div *ngIf="showRegistrationFields">
        <div class="text-center my-4" *ngIf="projectId">
            <h5 class="register-title">{{'registerTitle' | translate}}</h5>
            <h6 class="font-weight-bold">{{'registerSubTitle' | translate}}</h6>
        </div>
        <div class="text-center my-4" *ngIf="!projectId">
            <h6 class="font-weight-bold">Want to find out more about MTM?</h6>
        </div>
        <form [formGroup]="registrationForm">
            <div class="registrationArea">
                <label class="form-group row has-float-label"
                    [ngClass]="{'has-danger': registrationForm.controls['firstName'].hasError('required') && registrationForm.controls['firstName'].touched}">
                    <input id="firstName-new" class="form-control" type="text" formControlName="firstName"
                        placeholder=" "
                        [ngClass]="{'form-control-danger': registrationForm.controls['firstName'].hasError('required') && registrationForm.controls['firstName'].touched}">
                    <span
                        [ngClass]="{'text-danger': registrationForm.controls['firstName'].hasError('required') && registrationForm.controls['firstName'].touched}">
                        {{'registerFirstName' | translate}}</span>
                </label>
                <label class="form-group row has-float-label"
                    [ngClass]="{'has-danger': registrationForm.controls['lastName'].hasError('required') && registrationForm.controls['lastName'].touched}">
                    <input id="lastName-new" class="form-control" type="text" formControlName="lastName" placeholder=" "
                        [ngClass]="{'form-control-danger': registrationForm.controls['lastName'].hasError('required') && registrationForm.controls['lastName'].touched}">
                    <span
                        [ngClass]="{'text-danger': registrationForm.controls['lastName'].hasError('required') && registrationForm.controls['lastName'].touched}">
                        {{'registerLastName' | translate}}</span>
                </label>
            </div>

            <label class="form-group has-float-label"
                [ngClass]="{'has-danger': registrationForm.controls['company'].hasError('required') && registrationForm.controls['company'].touched}">
                <input class="form-control" type="text" formControlName="company" placeholder=" "
                    [ngClass]="{'form-control-danger': registrationForm.controls['company'].hasError('required') && registrationForm.controls['company'].touched}">
                <span
                    [ngClass]="{'text-danger': registrationForm.controls['company'].hasError('required') && registrationForm.controls['company'].touched}">
                    {{'registerCompany' | translate}}</span>
            </label>

            <label class="form-group has-float-label"
                [ngClass]="{'has-danger': registrationForm.controls['companyUrl'].hasError('required') && registrationForm.controls['companyUrl'].touched}">
                <input class="form-control" type="text" formControlName="companyUrl" placeholder="e.g company.com"
                    (keyup)="checkDomainNameMatch()" (change)="checkDomainNameMatch()"
                    [ngClass]="{'form-control-danger': registrationForm.controls['companyUrl'].hasError('required') && registrationForm.controls['companyUrl'].touched}">
                <span
                    [ngClass]="{'text-danger': registrationForm.controls['companyUrl'].hasError('required') && registrationForm.controls['companyUrl'].touched}">
                    {{'registerCompanyDomainName' | translate}}</span>
            </label>

            <label class="form-group has-float-label">
                <select class="form-control"
                    [ngClass]="{'has-danger-combo-box': isCountryValid() && registrationForm.controls['country'].touched ,'combo-box':!registrationForm.controls['country'].touched}"
                    *ngIf="listCountryCodes" formControlName="country" (change)="onSelectCountry($event)"
                    [ngModelOptions]="{standalone: true}">
                    <option value="">{{'searchCountry' | translate}}</option>
                    <option [value]="item.regionCode" *ngFor="let item of listCountryCodes">{{item.countryName}}
                    </option>
                </select>
            </label>

            <label class="form-group has-float-label" [ngClass]="{'has-danger': (registrationForm.controls['companyTaxId'].hasError('required') || registrationForm.controls['companyTaxId'].hasError('pattern'))
				    && registrationForm.controls['companyTaxId'].touched} ">
                <input class="form-control" type="text" formControlName="companyTaxId"
                    placeholder="{{vatPlaceHolder ? vatPlaceHolder : ('registerCompanyTaxId' | translate)}}" [ngClass]="{'form-control-danger': (registrationForm.controls['companyTaxId'].hasError('required') || registrationForm.controls['companyTaxId'].hasError('pattern') )
					    && registrationForm.controls['companyTaxId'].touched}">
                <span [ngClass]="{'text-danger': (registrationForm.controls['companyTaxId'].hasError('required') || registrationForm.controls['companyTaxId'].hasError('pattern'))
					&& registrationForm.controls['companyTaxId'].touched}"> {{'registerCompanyTaxId' | translate}}</span>
            </label>

            <label class="form-group has-float-label" [ngClass]="{'has-danger': (registrationForm.controls['email'].hasError('required') || registrationForm.controls['email'].hasError('pattern') || !isMatchDomainNameWithEmail)
				    && registrationForm.controls['email'].touched}">
                <input class="form-control" type="email" formControlName="email" placeholder="e.g name@company.com"
                    [ngClass]="{'form-control-danger': (registrationForm.controls['email'].hasError('required') || registrationForm.controls['email'].hasError('pattern') ||!isMatchDomainNameWithEmail)
					    && registrationForm.controls['email'].touched}" (change)="IsMatchDomainNameWithEmailOnChange()"
                    (keyup)="checkDomainNameMatch()">
                <span [ngClass]="{'text-danger': (registrationForm.controls['email'].hasError('required') || registrationForm.controls['email'].hasError('pattern') || !isMatchDomainNameWithEmail)
					 && registrationForm.controls['email'].touched}">{{'signInEmail' | translate}}</span>
            </label>

            <selector-mtm-tel-input [cardForm]=registrationForm [cardFormKey]="'phone'">
            </selector-mtm-tel-input>

            <mtm-terms-conditions-check [companyType]="companyType" #mtmTermsConditionCheck>
            </mtm-terms-conditions-check>

            <div class="text-center mt-3">
                <button id="register-btn" type="submit" class="btn btn-lg btn-orange btn-block"
                    [disabled]="registrationForm.invalid || !mtmTermsConditionCheck.isTermsCondition || !isMatchDomainNameWithEmail"
                    (click)="saveRegistration()">
                    {{'registerButton' | translate}}
                </button>
            </div>

            <div *ngIf="fromOneShot" class="row justify-content-between my-2 text-center">
                <label>
                    {{'registerAlreadyAccount' | translate}}
                    <span class="register"
                        (click)="showRegistrationFields=!showRegistrationFields">{{'registerSignInButton' |
                        translate}}</span>
                </label>
            </div>


        </form>
    </div>
</div>