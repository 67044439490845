import { Component, OnInit, OnDestroy } from '@angular/core';
import { commentFileAdd, commentFileTranscriptionGenerated } from 'app/shared/services/comments.service';
import { addSubtitleStart, importSubtitleStart, subtitleGenerated } from 'app/shared/services/subtitle-generations.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mtm-comment-file-uploader-container',
    templateUrl: './comment-file-uploader-container.component.html',
    styleUrls: ['./comment-file-uploader-container.component.scss']
})
export class CommentFileUploaderContainerComponent implements OnInit, OnDestroy {
    commentData: any = [];
    ngUnsubscribe = new Subject();

    constructor() {
        commentFileAdd.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
            this.commentData.push({
                guid: this.guid(),
                data
            });
        });

        commentFileTranscriptionGenerated.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
            this.commentData = this.commentData.filter(s => s.guid !== data.guid);
        });
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void { }

    guid() {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    trackByFn(index, item) {
        return item.id;
    }

}